import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import DefaultModal from './DefaultModal';
import CreateTeacherForm from 'src/pages/Teachers/CreateTeacherForm';
import UpdateTeacherForm from 'src/pages/Teachers/UpdateTeacherForm';
import { openNotification } from 'src/components/common/Notification';

import { useForm } from 'src/utils/hooks';
import { userTypVar, userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

function TeacherModal({ userInfo = null, popupMode, handleCancel, myInfo = false, refetch, ...rest }) {
  const [form] = Form.useForm();
  const defaultClass = useSelector(classStoreData);

  const initialState = {
    name: userInfo?.name,
    id: userInfo?.id,
    pw: null,
    phone: userInfo?.phone,
    email: userInfo?.email,
    memo: userInfo?.memo,
    address: userInfo?.address,
    deleted_working_days: userInfo?.deleted_working_days,
    working_hour: userInfo?.working_hour,
    current_salary: userInfo?.current_salary,
    date_of_entry: userInfo?.date_of_entry,
    resident_reg_no: userInfo?.resident_reg_no,
    account_number: userInfo?.account_number,
    academic_background: userInfo?.academic_background,
    marital_status: userInfo?.marital_status,
    children: userInfo?.children,
    blood_type: userInfo?.blood_type,
    report_income: userInfo?.report_income,
    special_category: userInfo?.special_category,
    campus_idx: userInfoVar()?.campus_idx,
    type: userTypVar().teacher,
  };

  const { onChange, onSubmit, values } = useForm(formCallback, initialState);
  const [values1, setValues1] = useState(initialState);

  const [mutate, { loading }] = useMutation(mutations.createUser.CREATE_USER, {
    refetchQueries: [
      {
        query: queries.getUser.GET_USERS,
        variables: { type: userTypVar().teacher },
      },
      {
        query: queries.getUser.GET_USER,
      },
    ],
    onCompleted(data) {
      if (data) {
        if (refetch) {
          refetch();
        }
        openNotification('등록 완료!');
        setValues1('');
        ActionCancel();
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];

      Modal.error({
        title: `${message}`,
      });

      //console.log('error', error);
    },
  });

  const [update, { loading: editing }] = useMutation(mutations.updateUser.UPDATE_USER, {
    variables: {
      ...values1,
      idx: parseInt(userInfo?.idx),
    },
    refetchQueries: [
      {
        query: queries.getUser.GET_USERS,
        variables: { type: userTypVar().teacher },
      },
      {
        query: queries.getUser.GET_USER,
      },
    ],

    onCompleted(data) {
      if (data) {
        if (refetch) {
          refetch();
        }
        openNotification('수정 완료!');
        setValues1('');
        handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function onDateChange(name, dateString) {
    setValues1((prev) => ({
      ...prev,
      [name]: dateString,
    }));
  }

  function formCallback() {
    form.validateFields().then((formData) => {
      const updatedata = {
        name: formData?.name,
        id: formData?.id,
        pw: formData?.pw,
        phone: formData?.phone,
        email: formData.email ? formData.email : null,
        memo: formData?.memo,
        address: formData?.address,
        deleted_working_days: formData?.deleted_working_days,
        working_hour: formData?.working_hour,
        current_salary: formData?.current_salary,
        date_of_entry: formData.date_of_entry,
        resident_reg_no: formData?.resident_reg_no,
        account_number: formData?.account_number,
        academic_background: formData?.academic_background,
        marital_status: formData?.marital_status ? formData?.marital_status : null,
        children: formData?.children,
        blood_type: formData?.blood_type,
        report_income: formData?.report_income,
        special_category: formData?.special_category,
        campus_idx: values.campus_idx,
        type: values.type,
      };
      setValues1(updatedata);
      popupMode === 'create'
        ? mutate({
            variables: updatedata,
          })
        : update();
    });

    // popupMode === 'create'
    //   ? mutate({
    //       variables: {
    //         name: values?.name,
    //         id: values?.id,
    //         pw: values?.pw,
    //         phone: values?.phone,
    //         email: values.email ? values.email : null,
    //         memo: values?.memo,
    //         address: values?.address,
    //         deleted_working_days :parseInt(values?.deleted_working_days),
    //         working_hour :parseInt(values?.working_hour),
    //         current_salary :parseInt(values?.current_salary),
    //         date_of_entry :values?.date_of_entry,
    //         resident_reg_no :values?.resident_reg_no,
    //         account_number :parseInt(values?.account_number),
    //         academic_background :values?.academic_background,
    //         marital_status :values?.marital_status? values?.marital_status : null,
    //         children :parseInt(values?.children),
    //         blood_type :values?.blood_type,
    //         report_income :parseInt(values?.report_income),
    //         special_category :values?.special_category,
    //         campus_idx: values.campus_idx,
    //         type: values.type,
    //       },
    //     })
    //   : update();
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  return (
    <>
      <DefaultModal
        className={`${defaultClass} instructor-information-modal`}
        title={myInfo ? '내정보' : '강사정보'}
        form={form}
        loading={popupMode === 'create' ? loading : editing}
        ActionCancel={popupMode === 'create' ? ActionCancel : handleCancel}
        popupMode={popupMode}
        width={65}
        {...rest}
      >
        <Form
          name="control-ref"
          layout="inline"
          form={form}
          onFinish={onSubmit}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
        >
          {popupMode === 'create' ? (
            <CreateTeacherForm onChange={onChange} formValues={values} form={form} onDateChange={onDateChange} />
          ) : (
            <UpdateTeacherForm userInfo={userInfo} onChange={onChange} form={form} onDateChange={onDateChange} />
          )}
        </Form>
      </DefaultModal>
    </>
  );
}

export default TeacherModal;
