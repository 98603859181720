import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Typography, Input, Button, InputNumber, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { ShoppingCartOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { koreanNumberFormat } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { bookCartVar, deleteBookCartData, changeBookCount, changeStudentBookCount, changeTeacherBookCount } from 'src/apollo/cart';
import { isAfterSchool, isLucid } from 'src/utils/index';

const { Title } = Typography;
const InputCount = styled.input`
  border: 1px solid #d9d9d9;
  :focus {
    outline: none;
  }
`;

const InputPrice = styled(InputNumber)`
  input {
    text-align: right;
  }
`;

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '80px',
  },
  {
    title: '내용',
    dataIndex: 'book_title',
    key: 'book_title',
  },
  {
    title: '권 수',
    dataIndex: 'count',
    key: 'count',
    render: (book_count, { key, setBookCartRefreshKey }) => {
      return (
        <InputCount
          type="number"
          value={book_count}
          min={1}
          max={50}
          onChange={(e) => {
            changeBookCount(key, e.target.value);
            setBookCartRefreshKey((prev) => prev + 1);
          }}
        />
      );
    },
  },
  {
    title: '금액',
    dataIndex: 'price',
    key: 'price',
    render: (text) => {
      return (
        <InputPrice
          value={text}
          disabled="disabled"
          size="small"
          readOnly
          controls={false}
          formatter={(number) => {
            return koreanNumberFormat(number) + '원';
          }}
        />
      );
    },
  },
];

const columnsAfterSchool = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '80px',
  },
  {
    title: '내용',
    dataIndex: 'book_title',
    key: 'book_title',
  },
  {
    title: '학생용',
    dataIndex: 'studentBookCount',
    key: 'studentBookCount',
    render: (book_count, { key, setBookCartRefreshKey }) => {
      return (
        <InputCount
          type="number"
          value={book_count}
          min={1}
          max={50}
          onChange={(e) => {
            changeStudentBookCount(key, e.target.value);
            setBookCartRefreshKey((prev) => prev + 1);
          }}
        />
      );
    },
  },
  {
    title: '교사용',
    dataIndex: 'teacherBookCount',
    key: 'teacherBookCount',
    render: (book_count, { key, setBookCartRefreshKey }) => {
      return (
        <InputCount
          type="number"
          value={book_count}
          min={1}
          max={50}
          onChange={(e) => {
            changeTeacherBookCount(key, e.target.value);
            setBookCartRefreshKey((prev) => prev + 1);
          }}
        />
      );
    },
  },
  {
    title: '총 권수',
    dataIndex: 'count',
    key: 'count',
  },
];

const BookCart = ({ onExpand, isExpandInvoice, bookCartChangedKey }) => {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  const [checkRowList, setcheckRowList] = useState([]);
  const [bookCartRefreshKey, setBookCartRefreshKey] = useState(0);

  const orgData = useMemo(() => {
    return bookCartVar();
  }, [bookCartRefreshKey, bookCartChangedKey]); //rendering 때문에 추가함.

  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const totalPrice = useMemo(() => {
    if (orgData) {
      return orgData.reduce((acc, cur) => acc + cur.price, 0);
    }
    return 0;
  }, [orgData]);

  const dataSource = useMemo(() => {
    if (orgData) {
      return orgData.map((item, key) => {
        return {
          ...item,
          no: key + 1,
          setBookCartRefreshKey,
        };
      });
    }
    return [];
  }, [orgData]);

  const realColumns = useMemo(() => {
    if (isAfterSchool(companyName)) {
      return columnsAfterSchool;
    } else if (isLucid(companyName)) {
      return columns.slice(0, -1);
    }
    return columns;
  }, [companyName]);

  function handleDeleteClick() {
    if (checkRowList.length === 0) {
      Modal.info({ title: '삭제할 항목을 선택하세요.' });
      return;
    }
    deleteBookCartData(checkRowList);
    setBookCartRefreshKey((prev) => prev + 1);
    setcheckRowList([]);
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col className="book-header-two-table" span={12} style={{ textAlign: 'left', margin: 0 }}>
          <Title level={5}>구매 내역( Invoice ) </Title>
        </Col>
        <Col className="book-header-two-table0icon" span={12} style={{ textAlign: 'right', margin: 0 }}>
          <div>
            <Button
              onClick={() => {
                onExpand(!isExpandInvoice);
              }}
              icon={
                isExpandInvoice ? (
                  <FullscreenExitOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                ) : (
                  <FullscreenOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                )
              }
            ></Button>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ textAlign: 'right', margin: 0 }}>
          <CustomTable
            className="bookorder-table-layout"
            dataSource={dataSource}
            pagination={false}
            columns={realColumns}
            size="small"
            color="#edf3fb"
            //scroll={{ y: 'calc(100vh - 254px)' }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        </Col>
      </Row>
      {!isAfterSchool(companyName) && !isLucid(companyName) && (
        <Row className="invoice-table-two-btm-one" gutter={[16, 16]}>
          <Col span={12} style={{ textAlign: 'right', margin: 0 }}>
            총 구매 금액 :
          </Col>
          <Col span={12} style={{ textAlign: 'right', margin: 0 }}>
            <Input value={koreanNumberFormat(totalPrice) + '원'} readOnly />
          </Col>
        </Row>
      )}

      <Row className="invoice-table-two-btm-two" gutter={[16, 16]}>
        <Col span={12} style={{ textAlign: 'right', margin: 0 }}>
          <Button type="danger" danger onClick={handleDeleteClick}>
            삭제
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: 'right', margin: 0 }}>
          <Button
            type="primary"
            icon={<ShoppingCartOutlined />}
            onClick={() => {
              if (dataSource?.length) {
                history.push('/book/order-pay');
              } else {
                Modal.info({
                  title: '구매할 상품을 추가하세요.',
                });
              }
            }}
          >
            주문 요청
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BookCart;
