import React, { useMemo, useState,useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { Col, Row, Radio, Input, Modal } from 'antd';
import styled from 'styled-components';
import { CREATE_CONSULT_LEVELTEST_FOR_METAEXAM } from 'src/operations/mutations/consult';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import moment from 'moment';
// const { Option } = Select;
const { confirm } = Modal;

const InnerWrap = styled.div`
  background-color: #fff;
  width: calc(100% - 25rem);
  // height: calc(100% - 10rem);
  height: auto;
  padding: 0rem 1rem;
  margin: 2rem auto;
  border-radius: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  & {
    .ant-alert-error {
      /* 
      background: #fff;
      border-color: #fff; */
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 18rem);
    height: calc(100% - 20rem);
    padding: 2rem 1rem;
    margin: 5rem auto;
    & {
      .inner-header {
        top: 0;
        position: relative;
        width: calc(90% - 10rem);
      }

      .btn.fixed_btn {
        bottom: 0;
        padding: 3rem 1rem;
        margin: 2rem auto;
        height: 60px;
        width: auto;
        a {
          top: -20px;
          position: relative;
        }
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 9) {
    height: auto;
    margin: 3rem auto;
    width: calc(100% - 40rem);
    padding: 0 1rem;

    & {
      .inner-header {
        top: 1rem;
        left: 0;
      }

      .btn.fixed_btn {
        bottom: 0;
        padding: 2rem 4rem;
        margin: 2rem auto;
        height: auto;
        width: auto;
        a {
          font-size: 1.4rem;
          position: relative;
        }
      }
    }
  }

  @media (max-width: 1920px) {
    .inner-header {
      width: calc(100% - 10rem);
      display: flex;
      position: relative;
      margin: 1rem 0;
      transform: translate(0px, 0px);
      top: 0rem;
      left: auto;
      h2 {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 10) {
    & {
      .inner-header {
        margin: 0 0 5rem;
      }
      .btn {
        padding: 1rem 1rem;
        margin: 0rem auto;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: auto;
    padding: 2rem 2rem;
    max-height: 100%;
    margin: 3rem auto;

    & {
      .inner-header {
        width: 100%;
        max-width: 1000px;
        margin: 0 0 1rem;

        h2 {
          font-size: 1.6rem;
        }
      }
      .btn.fixed_btn {
      }
    }
  }

  @media (max-width: 1280px) {
    height: 80%;
    max-width: 100%;
    max-height: 100%;
    width: 650px;
    margin: 5rem auto;
    & {
      .btn.fixed_btn {
        position: relative !important;
        bottom: 0;
        margin: 1rem auto 0;
      }
    }
  }

  @media (max-width: 1200px) {
    .inner-header {
      position: relative;
      top: 0;
      transform: translate(0, 0);
    }
  }

  @media (max-width: 1680px) and (aspect-ratio: 16 / 10) {
    & {
      .inner-header {
        top: 0;
        margin: 1rem 0 1rem;
      }

      .fixed_btn {
        bottom: 0;
      }
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    padding: 0rem 2rem;
    margin: 2rem auto;

    & {
      .inner-header {
        max-width: 800px;
        margin: 1rem 0 0;

        h2 {
          font-size: 1.6rem;
        }
      }

      .btn.fixed_btn {
        bottom: 1rem;
      }
    }
  }

  @media screen and (min-width: 1536px) and (max-height: 863px) {
    & {
      .inner-header {
        h2 {
          font-size: 1.5rem;
        }
      }

      #student-register {
        max-width: 1000px;
        tbody {
          tr {
            td {
              .ant-form-item {
                margin-right: 4rem;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1536px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 1350px) {
    height: calc(75vh - 3rem);
    margin: 5rem auto;
  }

  @media (max-width: 1400px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 100%;
    margin: 0 auto;
    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 800px;

        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 1450px) {
    // height: calc(70vh - 3rem);
    height: auto;
    margin: 3rem auto;
  }

  @media (max-width: 1366px) {
    width: 550px;
    height: auto;
    min-height: 650px;
    margin: 2rem auto 0;
    padding: 1rem 1rem;
    & {
      .inner-header {
        margin: 1rem auto;
        width: 100%;
      }

      .btn.fixed_btn {
        a {
          font-size: 1rem;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    margin: 2rem auto;
    min-height: 650px;
  }

  @media screen and (max-width: 1280px) and (aspect-ratio: 16 / 10) {
    margin: 2rem auto;
    width: 550px;
    // height: calc(75vh - 3rem);
    & {
      .btn.fixed_btn {
        bottom: 0;
        padding: 0.8rem 1rem;
        height: auto;
        max-height: 100%;
      }
      .inner-header {
        top: 0;
        width: 100%;
        margin: 0rem auto 0;
      }
    }
  }

  @media screen and (max-width: 1280px) and (orientation: portrait) {
    height: auto;
    min-height: 650px;
  }

  @media (max-width: 1280px) and (aspect-ratio: 16 / 9) {
    margin: 0rem auto;
    height: auto;
    max-width: 900px;
    max-height: 500px;

    & {
      .btn.fixed_btn {
        bottom: 0;
      }
      .inner-header {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 1200px) and (max-height: 800px) {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    & {
      .inner-header {
        top: 0;
        width: 100%;
      }
    }

    &.inner-header {
      width: 100%;
      top: 0;
      h2 {
        font-size: 1.4rem;
      }
      top: 0rem;
    }
  }

  @media (max-width: 1200px) {
    & {
      inner-header {
        width: 65%;
        top: 8rem;

        h2 {
          font-size: 1.3rem;
        }
      }
    }
  }

  @media (max-width: 1024px) and (aspect-ratio: 16 / 9) {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;

    & {
      .inner-header {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 100%;
    max-height: 100%;
    margin: 1rem auto 2rem;
    & {
      .btn.fixed_btn {
        bottom: 29%;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 2rem auto 0;
    padding: 0 1rem;
    width: 550px;
    height: 100%;
  }

  @media screen and (max-width: 1000px) {
    & {
      .inner-header {
      }
    }
  }

  /* for meta-user-info on mobile landscape */
  @media screen and (max-width: 1000px) and (orientation: landscape) {
    width: 550px;
  }

  @media screen and (max-width: 950px) {
    // width: 600px;
  }

  @media screen and (max-width: 800px) and (orientation: portrait) {
    height: 550px;
    margin: 0 auto;
  }

  /* Portrait */
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 100%;
    max-height: 100%;
    // width: 700px;
    height: 650px;
    margin: 3rem auto 2rem;
  }

  @media only screen and (min-width: 820px) and (max-width: 1180px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 100%;
    max-height: 100%;
    margin: 2rem auto;
    & {
      .inner-header {
        top: 0;
        position: relative;
        margin: 1rem auto 3rem;
      }
      .btn.fixed_btn {
        bottom: 0rem;
        position: relative !important;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    // width: 900px;
    // height: 600px;
    max-width: 100%;
    max-height: 100%;
    padding: 0 1rem;
    & {
      .inner-header {
        top: 0;
      }
      .btn.fixed_btn {
        bottom: 0%;
      }
    }
  }

  @media screen and (max-width: 820px) and (orientation: portrait) {
    & {
      .inner-header {
        width: 95%;
        top: 0;
        position: relative;
      }
    }
  }

  @media screen and (max-width: 800px) and (orientation: portrait) {
    height: auto;
    max-width: 100%;
    max-height: 100%;

    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);

    & {
      .inner-header {
        width: 100%;
        top: 0;
        position: relative;
      }
      .btn.fixed_btn {
        margin: 1rem auto;
        bottom: 0;
        max-width: 150px;
        position: relative !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    & {
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }

  @media screen and (max-width: 630px) {
    width: 500px;
    height: 700px;
    & {
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }

  @media screen and (max-width: 580px) {
    width: 430px;
    height: 700px;
    margin: 3rem auto;

    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);

    & {
      .inner-header {
        h2 {
          font-size: 1.2rem;
        }
      }
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 380px;
    height: 700px;
    margin: 2rem auto;
    & {
      .inner-header {
        h2 {
          font-size: 1.2rem;
        }
      }
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }

  @media screen and (max-width: 450px) {
    width: 350px;
    height: auto;
    padding: 1rem 1rem 2rem;
    & {
      .inner-header {
        h2 {
          font-size: 1.2rem;
        }
      }
      .btn.fixed_btn {
        bottom: -25px;
        position: relative !important;
      }
    }
  }

  @media screen and (max-width: 380px) {
    width: 300px;
    height: auto;

    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 35px;

    & {
      .inner-header {
        &.survey-header {
          h2 {
            font-size: 1.1rem;
          }
        }
      }
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }
`;

const Survey = () => {
  const params = queryString.parse(window.location.search);
  const company_idx = params.company_idx ? params.company_idx : null;
  const isWEnglish = company_idx === 1016 ? true : false;
  const [showDone, setShowDone] = useState(false);
  const [create, { loading }] = useMutation(CREATE_CONSULT_LEVELTEST_FOR_METAEXAM, {
    onCompleted(data) {
      if (data) {
        setShowDone(true);
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      console.log('error', error);
      alert('오류가 발생했습니다(2).');
    },
  });
  const [survey1, setsurvey1] = useState('1');
  const [survey2, setsurvey2] = useState('1');
  const [survey3, setsurvey3] = useState('');
  const [survey4, setsurvey4] = useState('');
  const [survey5, setsurvey5] = useState('1');
  const [survey6, setsurvey6] = useState('1');
  useEffect(()=>{
    if(survey1==='2'){
      setsurvey2('4')
      setsurvey3('0')
      setsurvey4('0')
    }
  },[survey1])
  const handleSubmit = () => {
    const newVariables = { survey1, survey2, survey3, survey4, survey5, survey6 };
    const preVariables = JSON.parse(localStorage.getItem('meta_create_order'));
    const variables = { ...newVariables, ...preVariables };
    console.log('preVariables', preVariables);
    // const msg = 'Hello`${preVariables}`';
    confirm({
      //title: `Your registration date ${preVariables.reserve_date} and time ${preVariables.schedule_time}`,
      title: `Your registration date ${preVariables.reserve_date}`,
      okText: '예',
      okType: 'primary',
      cancelText: '아니오',
      onOk() {
        create({ variables });
      },
      onCancel() {},
    });
    return false;
  };

  const reserveDate = useMemo(() => {
    if (localStorage.getItem('meta_create_order')) {
      const preVariables = JSON.parse(localStorage.getItem('meta_create_order'));
      if (preVariables.reserve_date) {
        const reserve_date = moment(preVariables.reserve_date).format('MM월 DD일');
        return reserve_date;
      }
    }
    return '';
  }, []);

  return (
    <>
      <div className="meta-close">
        <div style={{ justifyContent: 'start' }}>
          <img src="../../images/meta_exit.png" alt="closeImg" />
        </div>
      </div>
      <div className={isWEnglish ? 'meta-con register-con w-english-bg' : 'meta-con register-con'}>
        <Row className="meta-header">
          <Col>
            <h1 className="main-tit">{isWEnglish ? '' : 'MetaExam'} 신청</h1>
          </Col>
        </Row>
        {showDone ? (
          <InnerWrap>
            <div className="inner-header" style={{ fontSize: '40px', textAlign: 'center', wordBreak: 'keep-all' }}>
              <span style={{ width: '100%' }}>{reserveDate} 신청이 완료되었습니다.</span>
            </div>
          </InnerWrap>
        ) : (
          <InnerWrap>
            <div className="inner-header survey-header">
              <h2>영어 이력 조사</h2>
              <div className="date-wrap">
                <h2>{moment().format('YYYY.MM.DD')}</h2>
              </div>
            </div>

            <div className="survey-wrap">
              <div>
                <h2 className="survey-q">
                  <span className="circle-num">1</span>
                  과거에 영어를 배운 경험이 있다.
                </h2>
                <Radio.Group
                  name="survey1"
                  defaultValue={'1'}
                  className="survey-a"
                  onChange={(e) => {
                    setsurvey1(e.target.value);
                  }}
                  value={survey1}
                >
                  <Radio value={'1'}>있다</Radio>
                  <Radio value={'2'}>없다</Radio>
                </Radio.Group>
              </div>
{survey1==='1'&&<>
              <div className="survey-sub-q">
                <h2>만약 영어 경험이 있으면 :</h2>
                <Radio.Group
                  name="survey2"
                  defaultValue={'1'}
                  className="survey-a"
                  onChange={(e) => {
                    setsurvey2(e.target.value);
                  }}
                  value={survey2}
                  >
                  <Radio value={'1'}>어학원</Radio>
                  <Radio value={'2'}>보습학원</Radio>
                  <Radio value={'3'}>공부방</Radio>
                  <Radio value={'4'}>개인지도</Radio>
                </Radio.Group>
              </div>

              <div className="survey-sub-q">
                <h2>만약 영어 경험이 있으면 배운 기간은 :</h2>
                <div className="survey-a">
                  <Input
                    name="survey3"
                    className="partInput survey-a"
                    onChange={(e) => {
                      setsurvey3(e.target.value);
                    }}
                    value={survey3}
                    />{' '}
                  <span>년 </span>
                  <Input
                    name="survey4"
                    className="partInput survey-a"
                    onChange={(e) => {
                      setsurvey4(e.target.value);
                    }}
                    value={survey4}
                    />{' '}
                  <span>개월</span>
                </div>
              </div>
                    </>}
            </div>

            <div className="survey-wrap single-survey">
              <h2 className="survey-q">
                <span className="circle-num">2</span>
                학생은 해외 경험이 있다.
              </h2>
              <Radio.Group
                name="survey5"
                defaultValue={'1'}
                className="survey-a"
                onChange={(e) => {
                  setsurvey5(e.target.value);
                }}
                value={survey5}
              >
                <Radio value={'1'}>있다</Radio>
                <Radio value={'2'}>없다</Radio>
              </Radio.Group>
            </div>

            <div className="survey-wrap single-survey">
              <h2 className="survey-q">
                <span className="circle-num">3</span>
                중학교 과정을 배워도 문제 없다.
              </h2>
              <Radio.Group
                name="survey6"
                defaultValue={'1'}
                className="survey-a"
                onChange={(e) => {
                  setsurvey6(e.target.value);
                }}
                value={survey6}
              >
                <Radio value={'1'}>있다</Radio>
                <Radio value={'2'}>없다</Radio>
              </Radio.Group>
            </div>

            <div
              className="btn fixed_btn request_btn"
              onClick={() => {
                handleSubmit();
              }}
            >
              <a href="javascript:;">신청 완료</a>
            </div>
          </InnerWrap>
        )}
        {!isWEnglish && (
          <div className="report-footer-con">
            <h5>
              Powered by {'     '}
              <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
              </span>
            </h5>
            <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default Survey;
