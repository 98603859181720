import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Layout, Menu, Typography, Spin } from 'antd';
import FullScreenButton from 'src/components/common/FullScreenButton';
import { MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined, MobileOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { useApolloClient } from '@apollo/client';
import ReturnToManagerBar from 'src/components/common/ReturnToManagerBar';
import OpenAppModal from '../Modal/OpenAppModal';
import styled from 'styled-components';

import { isLogedIn, userInfoVar, userVar } from 'src/apollo/cache';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from '../../utils';
import { storage, useModal } from 'src/utils/hooks';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

const { Header: HeaderWrapper } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

const HeaderAICoach = styled(HeaderWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: 'space-between';
  height: 64px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  /* background-image: ${(props) => !props.isStatic && `url('/images/cloud.png')`};
  background-position: ${(props) => !props.isStatic && `center bottom`};
  background-repeat: ${(props) => !props.isStatic && 'no-repeat'}; */
`;

const ImgButton = styled.img`
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Header = ({ collapsed, onCollapse, isStatic = false }) => {
  const params = queryString.parse(window.location.search);
  const [appData, setAppData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const token = getCurrentToken();
  const client = useApolloClient();
  const { data } = useQueryProxy(USER_ME, { variables: { token } });

  const onLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      isLogedIn(false);
      userInfoVar(null);
      client.clearStore();
      client.cache.gc();
      storage.removeItem('culp_token');
      window.location.reload();
    } else {
      return;
    }
  };

  const returnId = useMemo(() => {
    return window.localStorage.getItem('culp_return_id');
  }, []);

  useEffect(() => {
    if (params.toApp && isMobile) {
      const curAppUrl = params.toApp;
      let culptoken = localStorage.getItem('culp_token')
        ? JSON.parse(localStorage.getItem('culp_token'))
        : {
            accessToken: '',
            refreshToken: '',
          };
      params.toApp = undefined;
      history.replace({
        search: queryString.stringify(params),
      });
      const url = `${curAppUrl}://oauth${location.pathname}?accessToken=${culptoken.accessToken}&refreshToken=${culptoken.refreshToken}`;
      setTimeout(() => {
        setAppData({
          url,
        });
      }, 1000);
    }
  }, [params.toApp]);

  const rightContent = (
    <>
      <Menu
        key="user"
        mode="horizontal"
        style={{ height: '100%', background: 'transparent', border: 0, flex: 8, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Menu.Item key="info" style={{ paddingTop: '10px' }}>
          <FullScreenButton
            element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
            activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
          />
        </Menu.Item>
        {/*  */}
        <SubMenu
          key="SubMenu"
          title={
            <>
              <Avatar

              //src="//image.zuiidea.com/photo-1487412720507-e7ab37603c6f.jpeg?imageView2/1/w/200/h/200/format/webp/q/75|imageslim"
              />
              <span style={{ color: '#000', marginLeft: 10 }}>
                <span className="username">
                  {data?.me?.name} <Text strong>({data?.me?.id})</Text>
                </span>
                <span onClick={onLogout} style={{ color: '#999', marginLeft: 20 }}>
                  로그아웃
                  <LogoutOutlined
                    style={{
                      marginLeft: 5,
                      verticalAlign: 'middle',
                      fontSize: 25,
                      color: '#ccc',
                    }}
                    key="logout"
                  />
                </span>
              </span>
            </>
          }
        >
          {/* <Menu.Item key="SignOut" onClick={onLogout}>
            <span>Sign out</span>
          </Menu.Item> */}
        </SubMenu>
      </Menu>
    </>
  );

  return (
    <>
      {returnId && <ReturnToManagerBar returnId={returnId} />}
      <HeaderAICoach
        isStatic={isStatic}
        style={{
          position: isStatic ? 'fixed' : 'relative',
          // justifyContent: 'start',
          // alignItems: 'center',
          // height: '75px',
          // padding: '25px 65px',
          backgroundColor: '#e1efff',
          color: '#DEEBF7',
          fontSize: '40px',
        }}
      >
        <div style={{ visibility: `${userInfoVar()?.level_test === 1 ? 'hidden' : 'visible'}`, flex: 1 }}>
          <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            {collapsed ? (
              <MenuFoldOutlined
                style={{
                  fontSize: 35,
                  color: '#000',
                }}
                className="trigger"
                onClick={onCollapse}
                color="#000"
              />
            ) : (
              <MenuUnfoldOutlined
                style={{
                  fontSize: 35,
                  color: '#000',
                }}
                className="trigger"
                onClick={onCollapse}
                color="#000"
              />
            )}
          </div>
        </div>
        {!isStatic ? <>{rightContent}</> : null}
      </HeaderAICoach>
      {appData !== null && <OpenAppModal visible={true} handleClose={() => setAppData(null)} url={appData?.url} />}
    </>
  );
};

export default Header;
