import React, { useState, useEffect } from 'react';
import ImageRowBox from '../ImageRowBox';
import ExWordBox from '../ExWordBox';
import SoundButton from '../SoundButton';
import { WriteImageSoundPopup, WriteAlphabetImageSoundPopup } from '../WritePopup';
import _ from 'lodash';
import SoundWords from '../SoundWordsPM';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PopUp from '../PopUp';
import { useHistory } from 'react-router-dom';
import PageNum from '../PageNum';
import ImageSentectBox from '../ImageSentectBox';
import ImageSelecetWord from '../ImageSelectWord';
import { useStudyCreate } from '../../../utils/pm/api';
import * as S from 'src/pages/PhonicsMonster/style';
import { userInfoVar } from 'src/apollo/cache';
// import {useMyScript} from "./common/MyScriptHook";

const getSoundSrc = (obj) => {
  if (!_.isNil(obj)) {
    let sounds = [];
    if (obj.qSound) {
      sounds.push(`https://cdn.cloubot.com/PM/audio/sounds/${obj.qSound}.mp3`);
    } else {
      if (obj.qWord) {
        sounds.push(`https://cdn.cloubot.com/PM/audio/sounds/${obj.qWord}.mp3`);
      }
      /*
			if (!_.isNil(obj.exImage) && obj.exImage instanceof Array) {
				sounds = [...sounds, ...obj.exImage.map(item => `${item.word}.mp3`)];
			}
			*/
    }
    return sounds;
  }
  return '';
};

const veryGoodSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
const stepEndSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/dingdong.mp3';

const ImageSelectStage = ({ unitInfo, bookInfo, stages, steps, setSteps, directionEnd }) => {
  const {
    customData: { problem, type },
  } = stages;
  const soundSrc = getSoundSrc(problem[steps]);
  const [rightWords, setRightWords] = useState([]);
  const [rightSelectWord, setRightSelectWord] = useState(undefined);
  const [showPopUp, setShowPopUp] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [soundCorrectWrong, setSoundCorrectWrong] = useState(undefined);
  const history = useHistory();
  // const {myScriptEditorRef, script, onClear, error} = useMyScript();
  const [showWritePopUp, setShowWritePopUp] = useState(false);
  const [correctImageIndex, setCorrectImageIndex] = useState(-1);
  const [dingdongSound, setDingdongSound] = useState(undefined);
  const [greatSound, setGreatSound] = useState(undefined);

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  const handleCorrectWriteEnd = (correct) => {
    if (correct) {
      if (dingdongSound == stepEndSound) {
        setDingdongSound(undefined);
        setTimeout(() => {
          setDingdongSound(stepEndSound);
        }, 100);
      } else {
        setDingdongSound(stepEndSound);
      }
    } else {
      handleDingdongSoundEnd();
    }
  };

  const handleDingdongSoundEnd = () => {
    setDingdongSound(undefined);
    if (type === 'imgSelectSound') {
      // onClear();
    }

    setShowWritePopUp(false);
    setCorrectImageIndex(-1);

    if (steps < problem.length - 1) {
      setSteps((prev) => prev + 1);
    } else {
      //stage끝
      setPopUpType('correct');
      setShowPopUp(true);
    }
  };

  const handleCorrect = (word, index) => {
    //console.log("handleCorrect");
    if (type === 'imgSelectImg') {
      //setRightWords([problem[steps].qWord, word]);
      setRightSelectWord(word);
      setGreatSound(veryGoodSound);
    } else if (type === 'imgSelectSound' || type === 'imgAlphabetSelectSound') {
      setCorrectImageIndex(index);
      setGreatSound(veryGoodSound);
      // setTimeout(() => {
      // 	setShowWritePopUp(true);
      // }, 1000);
    } else {
      setSoundCorrectWrong(veryGoodSound);
    }
  };

  const handleWrong = () => {
    setSoundCorrectWrong(woopsSound);
  };

  const handleStepEnd = () => {
    if (soundCorrectWrong === woopsSound) {
      setSoundCorrectWrong(undefined);
      return;
    }
    setSoundCorrectWrong(undefined);
    if (steps < problem.length - 1) {
      setSteps((prev) => prev + 1);
    } else {
      //stage끝
      setPopUpType('correct');
      setShowPopUp(true);
    }
  };

  const handleGreatSoundEnd = () => {
    setGreatSound(undefined);
    if (type === 'imgSelectImg') {
      setRightWords([problem[steps].qWord, rightSelectWord]);
    } else {
      // if (error) {
      // 	setDingdongSound(stepEndSound);
      // } else {
      setShowWritePopUp(true);
      // }
    }
  };

  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };

  return (
    <>
      {
        {
          imgSelectSentence: <ImageSentectBox steps={steps} problem={problem} onCorrect={handleCorrect} onWrong={handleWrong} />,
          selectWord: (
            <ImageSelecetWord steps={steps} problem={problem} onCorrect={handleCorrect} onWrong={handleWrong} directionEnd={directionEnd} />
          ),
          imgSelectImg: (
            <ImageCommonSelect
              type={type}
              problem={problem}
              steps={steps}
              soundSrc={soundSrc}
              handleCorrect={handleCorrect}
              handleWrong={handleWrong}
              rightWords={rightWords}
              handleStepEnd={handleStepEnd}
            />
          ),
          imgSelectWord: (
            <ImageCommonSelect
              type={type}
              problem={problem}
              steps={steps}
              soundSrc={soundSrc}
              handleCorrect={handleCorrect}
              handleWrong={handleWrong}
              rightWords={rightWords}
              handleStepEnd={handleStepEnd}
            />
          ),
          imgSelectSound: (
            <>
              <ImageCommonSelect
                type={type}
                problem={problem}
                steps={steps}
                soundSrc={soundSrc}
                handleCorrect={handleCorrect}
                handleWrong={handleWrong}
                rightWords={rightWords}
                handleStepEnd={handleStepEnd}
                directionEnd={directionEnd}
              />
              {showWritePopUp && (
                <WriteImageSoundPopup
                  image={
                    correctImageIndex !== -1
                      ? problem[steps].exImage[correctImageIndex].image
                      : 'https://cdn.cloubot.com/PM/image/level1/apple.png'
                  }
                  word={problem[steps].qSound}
                  userScript={{}}
                  showWritePopUp={showWritePopUp}
                  editorRef={{}}
                  style={{
                    background: `url(https://cdn.cloubot.com/PM/images/Write_${problem[steps].qSound}.png) center center no-repeat`,
                  }}
                  onClear={{}}
                  onCorrectEnd={handleCorrectWriteEnd}
                  onClose={() => {
                    // onClear();
                    setCorrectImageIndex(-1);
                    setShowWritePopUp(false);
                  }}
                  isEnd={!_.isNil(problem[steps].isEnd) ? problem[steps].isEnd : false}
                />
              )}
            </>
          ),
          imgAlphabetSelectSound: (
            <>
              <ImageCommonSelect
                type={type}
                problem={problem}
                steps={steps}
                soundSrc={soundSrc}
                handleCorrect={handleCorrect}
                handleWrong={handleWrong}
                rightWords={rightWords}
                handleStepEnd={handleStepEnd}
                directionEnd={directionEnd}
              />

              {showWritePopUp && (
                <WriteAlphabetImageSoundPopup
                  alphabet={correctImageIndex !== -1 ? problem[steps].exImage[correctImageIndex].alphabet : ''}
                  word={problem[steps].qSound}
                  image={correctImageIndex !== -1 ? problem[steps].exImage[correctImageIndex].wordImage : '/images/level1/apple.png'}
                  mp3={soundSrc}
                  step={steps}
                  total={problem.length}
                  onEnd={handleCorrectWriteEnd}
                  onClose={() => {
                    setCorrectImageIndex(-1);
                    setShowWritePopUp(false);
                  }}
                />
              )}
            </>
          ),
        }[type]
      }

      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      {<SoundEffectPM url={soundCorrectWrong} onEnd={handleStepEnd} />}
      <SoundEffectPM url={!_.isNil(dingdongSound) ? dingdongSound : undefined} onEnd={handleDingdongSoundEnd} />
      <SoundEffectPM url={!_.isNil(greatSound) ? greatSound : undefined} onEnd={handleGreatSoundEnd} />
    </>
  );
};

export default ImageSelectStage;

const ImageCommonSelect = ({ type, problem, steps, soundSrc, handleCorrect, handleWrong, rightWords, handleStepEnd, directionEnd }) => {
  const isSoundBox = ['imgSelectSound', 'imgAlphabetSelectSound'].indexOf(type) !== -1;

  const [questionWordSound, setQuestionWordSound] = useState(undefined);
  const handleQuestionWordSoundEnd = () => {
    setQuestionWordSound(undefined);
  };

  useEffect(() => {
    console.log('ImageCommonSelect===>', directionEnd);
    if (!_.isNil(directionEnd) && directionEnd === true) {
      console.log('check===>', soundSrc);
      setQuestionWordSound(soundSrc.length > 0 ? soundSrc[0] : undefined);
    } //랜더링 문제 때문에 디펜던씨 변경.

    // eslint-disable-next-line
  }, [directionEnd, steps]);

  return (
    <>
      <S.ImageSelectWrap id="lv3_review3_content" className="main_content row2">
        <S.ImageSelectTopBox className="top_box" style={isSoundBox ? { height: '15vh' } : {}}>
          <PageNum
            num={steps + 1}
            total={problem.length}
            style={{ top: '10px', position: 'absolute', right: '1rem', fontSize: '1.3rem', fontWeight: 'bold' }}
          />
          <S.ImageSelectQuestionBox
            className="question_box"
            style={isSoundBox ? { width: '0px', border: 'none' } : type === 'imgSelectWord' ? { border: 'none' } : {}}
          >
            {
              {
                imgSelectImg: <img style={{ width: 'auto', height: 'auto', maxWidth: '60%' }} src={problem[steps].qImage} alt="" />,
                imgSelectWord: (
                  <ExWordBox
                    word={problem[steps].qWord}
                    direction={_.isNil(problem[steps].direction) ? 'left' : problem[steps].direction}
                  />
                ),
                imgSelectSound: <SoundButton src={soundSrc} />,
                imgAlphabetSelectSound: <SoundButton src={soundSrc} />,
              }[type]
            }

            {type === 'imgSelectImg' ? <SoundButton src={soundSrc} /> : undefined}
          </S.ImageSelectQuestionBox>
        </S.ImageSelectTopBox>
        <ImageRowBox steps={steps} problem={problem} onCorrect={handleCorrect} onWrong={handleWrong} />
      </S.ImageSelectWrap>
      {!isSoundBox && <SoundWords words={rightWords} onEnd={handleStepEnd} />}

      {isSoundBox && (
        <SoundEffectPM
          url={
            !_.isNil(questionWordSound)
              ? questionWordSound.startsWith('https')
                ? questionWordSound
                : `https://cdn.cloubot.com/PM/audio/sounds/${questionWordSound}`
              : undefined
          }
          onEnd={handleQuestionWordSoundEnd}
        />
      )}
    </>
  );
};
