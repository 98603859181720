import React from "react";
import {isVowel} from "../../utils/pm/util";
import {useParams} from "react-router-dom";
import * as S from 'src/pages/PhonicsMonster/style';
let bookNameUnitStage = undefined;
let bookunitstagechecklist = undefined;
let bookunitstagechecklistValue = {};
let bookunitstagechecklistAdd = undefined;
let exceptionWord = undefined;
let exceptionchecklist = {};
let bookunitstagechecklistAddExecp = undefined;

const BlockWordBox = ({onClick, selected, word}) => {
	const {level, unit, stage} = useParams();

	if (level == "3a" && unit == (1 || 2) && stage == (2 || 4 || 5 || 6 || 7 || 9 || 11)) {
		bookNameUnitStage = true;
	}

	if (level == "3b" && unit == (6 || 7 || 8) && stage == (2 || 4 || 5 || 6 || 7 || 9 || 11)) {
		bookNameUnitStage = true;
	}

	if (level == "4a" && unit == 5 && stage == 9) {
		bookNameUnitStage = true;
	}

	if (level == "4b" && unit == 9 && stage == 9) {
		bookunitstagechecklistValue = {o: true, w: true, u: true};
		bookunitstagechecklist = true;
	}

	if (level == "4b" && unit == 6 && stage == 9) {
		bookunitstagechecklistValue = {a: true, i: true, y: true};
		bookunitstagechecklist = true;
	}

	if (level == "4b" && unit == 7 && stage == 9) {
		bookunitstagechecklistValue = {e: true, a: true};
		bookunitstagechecklist = true;
	}

	if (level == "4b" && unit == 10 && stage == 9) {
		bookunitstagechecklistValue = {o: true};
		bookunitstagechecklist = true;
	}

	if (level == "2a" && unit == 1 && stage == 9) {
		bookunitstagechecklistValue = {a: true, o: true, w: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			dad: [{loopkey1: 1, loopkey2: 2}],
			bad: [{loopkey1: 1, loopkey2: 2}],
			sad: [{loopkey1: 1, loopkey2: 2}],
			bag: [{loopkey1: 1, loopkey2: 2}],
			tag: [{loopkey1: 1, loopkey2: 2}],
			wag: [{loopkey1: 1, loopkey2: 2}],
			jam: [{loopkey1: 1, loopkey2: 2}],
			ram: [{loopkey1: 1, loopkey2: 2}],
			ham: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "4a" && unit == 5 && stage == 9) {
		bookunitstagechecklistValue = {c: true, g: true};
		bookunitstagechecklist = true;
		bookunitstagechecklistAdd = {
			cage: [{c: true, loopkey: 0}],
			city: [{c: true, loopkey: 0}],
			bicycle: [{c: true, loopkey: 2}],
			candy: [{c: true, loopkey: 0}],
			cold: [{c: true, loopkey: 0}],
			Giraffe: [{G: true, loopkey: 0}],
			Gorilla: [{G: true, loopkey: 0}],
			gum: [{G: true, loopkey: 0}],
		};
	}

	if (level == "4b" && unit == 8 && stage == 9) {
		bookunitstagechecklistValue = {a: true, o: true, w: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			road: [{o: true, a: true, loopkey1: 1, loopkey2: 2}],
			soap: [{o: true, a: true, loopkey1: 1, loopkey2: 2}],
			coat: [{o: true, a: true, loopkey1: 1, loopkey2: 2}],
			boat: [{o: true, a: true, loopkey1: 1, loopkey2: 2}],
			blow: [{o: true, w: true, loopkey1: 2, loopkey2: 3}],
			toast: [{o: true, a: true, loopkey1: 1, loopkey2: 2}],
			window: [{o: true, w: true, loopkey1: 4, loopkey2: 5}],
		};
	}

	if (level == "4a" && unit == 4 && stage == 9) {
		bookunitstagechecklistValue = {a: true, o: true, w: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			whale: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			bath: [{o: true, a: true, loopkey1: 2, loopkey2: 3}],
			thin: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			white: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			teeth: [{o: true, a: true, loopkey1: 3, loopkey2: 4}],
			wheel: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			whisk: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			thick: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			three: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			mouth: [{o: true, a: true, loopkey1: 3, loopkey2: 4}],
		};
	}

	if (level == "4a" && unit == 3 && stage == 9) {
		bookunitstagechecklistValue = {a: true, o: true, w: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			ship: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			fish: [{o: true, a: true, loopkey1: 2, loopkey2: 3}],
			chip: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			shark: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
			lunch: [{o: true, a: true, loopkey1: 3, loopkey2: 4}],
			shape: [{o: true, a: true, loopkey1: 0, loopkey2: 1}],
		};
	}

	if (level == "4a" && unit == 2 && stage == 9) {
		bookunitstagechecklistValue = {a: true, o: true, w: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			dress: [{loopkey1: 0, loopkey2: 1}],
			brick: [{loopkey1: 0, loopkey2: 1}],
			grass: [{loopkey1: 0, loopkey2: 1}],
			brave: [{loopkey1: 0, loopkey2: 1}],
			crow: [{loopkey1: 0, loopkey2: 1}],
			bride: [{loopkey1: 0, loopkey2: 1}],
			crab: [{loopkey1: 0, loopkey2: 1}],
			grape: [{loopkey1: 0, loopkey2: 1}],
			dragon: [{loopkey1: 0, loopkey2: 1}],
			brick: [{loopkey1: 0, loopkey2: 1}],
		};
	}

	if (level == "4a" && unit == 1 && stage == 9) {
		bookunitstagechecklistValue = {a: true, o: true, w: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			sled: [{loopkey1: 0, loopkey2: 1}],
			slide: [{loopkey1: 0, loopkey2: 1}],
			blade: [{loopkey1: 0, loopkey2: 1}],
			slide: [{loopkey1: 0, loopkey2: 1}],
			flame: [{loopkey1: 0, loopkey2: 1}],
			flag: [{loopkey1: 0, loopkey2: 1}],
			slide: [{loopkey1: 0, loopkey2: 1}],
			flute: [{loopkey1: 0, loopkey2: 1}],
			blue: [{loopkey1: 0, loopkey2: 1}],
			black: [{loopkey1: 0, loopkey2: 1}],
		};
	}

	if (level == "3b" && unit == 9 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			can: [{loopkey1: -1, loopkey2: -1}],
			cane: [{loopkey1: 3, loopkey2: 3}],
			tap: [{loopkey1: -1, loopkey2: -1}],
			tape: [{loopkey1: 3, loopkey2: 3}],
			kit: [{loopkey1: -1, loopkey2: -1}],
			kite: [{loopkey1: 3, loopkey2: 3}],
			pin: [{loopkey1: -1, loopkey2: -1}],
			pine: [{loopkey1: 3, loopkey2: 3}],
			hop: [{loopkey1: -1, loopkey2: -1}],
			hope: [{loopkey1: 3, loopkey2: 3}],
			pop: [{loopkey1: -1, loopkey2: -1}],
			pope: [{loopkey1: 3, loopkey2: 3}],
			tub: [{loopkey1: -1, loopkey2: -1}],
			tube: [{loopkey1: 3, loopkey2: 3}],
			cub: [{loopkey1: -1, loopkey2: -1}],
			cube: [{loopkey1: 3, loopkey2: 3}],
		};
	}

	if (level == "2a" && unit == 2 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			man: [{loopkey1: 1, loopkey2: 2}],
			cat: [{loopkey1: 1, loopkey2: 2}],
			man: [{loopkey1: 1, loopkey2: 2}],
			hat: [{loopkey1: 1, loopkey2: 2}],
			can: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "2a" && unit == 3 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			wig: [{loopkey1: 1, loopkey2: 2}],
			pin: [{loopkey1: 1, loopkey2: 2}],
			rip: [{loopkey1: 1, loopkey2: 2}],
			win: [{loopkey1: 1, loopkey2: 2}],
			bin: [{loopkey1: 1, loopkey2: 2}],
			hip: [{loopkey1: 1, loopkey2: 2}],
			win: [{loopkey1: 1, loopkey2: 2}],
			rip: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "2a" && unit == 4 && stage == 3) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			kit: [{loopkey1: 1, loopkey2: 2}],
			mix: [{loopkey1: 1, loopkey2: 2}],
			lid: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "2a" && unit == 4 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			kit: [{loopkey1: 1, loopkey2: 2}],
			mix: [{loopkey1: 1, loopkey2: 2}],
			lid: [{loopkey1: 1, loopkey2: 2}],
			Six: [{loopkey1: 1, loopkey2: 2}],
			Mix: [{loopkey1: 1, loopkey2: 2}],
			Hit: [{loopkey1: 1, loopkey2: 2}],
			hit: [{loopkey1: 1, loopkey2: 2}],
			fix: [{loopkey1: 1, loopkey2: 2}],
			kid: [{loopkey1: 1, loopkey2: 2}],
			lid: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "2b" && unit == 6 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			bug: [{loopkey1: 1, loopkey2: 2}],
			hug: [{loopkey1: 1, loopkey2: 2}],
			rug: [{loopkey1: 1, loopkey2: 2}],
			sun: [{loopkey1: 1, loopkey2: 2}],
			bun: [{loopkey1: 1, loopkey2: 2}],
			run: [{loopkey1: 1, loopkey2: 2}],
			nut: [{loopkey1: 1, loopkey2: 2}],
			hut: [{loopkey1: 1, loopkey2: 2}],
			cut: [{loopkey1: 1, loopkey2: 2}],
			Run: [{loopkey1: 1, loopkey2: 2}],
			Hug: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "2b" && unit == 7 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			tub: [{loopkey1: 1, loopkey2: 2}],
			cub: [{loopkey1: 1, loopkey2: 2}],
			mud: [{loopkey1: 1, loopkey2: 2}],
			bud: [{loopkey1: 1, loopkey2: 2}],
			gum: [{loopkey1: 1, loopkey2: 2}],
			hum: [{loopkey1: 1, loopkey2: 2}],
			cup: [{loopkey1: 1, loopkey2: 2}],
			pup: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "2b" && unit == 8 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			bed: [{loopkey1: 1, loopkey2: 2}],
			jet: [{loopkey1: 1, loopkey2: 2}],
			Ted: [{loopkey1: 1, loopkey2: 2}],
			ted: [{loopkey1: 1, loopkey2: 2}],
			Hen: [{loopkey1: 1, loopkey2: 2}],
			red: [{loopkey1: 1, loopkey2: 2}],
			pen: [{loopkey1: 1, loopkey2: 2}],
			Ten: [{loopkey1: 1, loopkey2: 2}],
			Ted: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "2b" && unit == 9 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			box: [{loopkey1: 1, loopkey2: 2}],
			pot: [{loopkey1: 1, loopkey2: 2}],
			log: [{loopkey1: 1, loopkey2: 2}],
			ox: [{loopkey1: 0, loopkey2: 1}],
			fox: [{loopkey1: 1, loopkey2: 2}],
		};
	}

	if (level == "3a" && unit == 2 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			same: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			game: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			cape: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			wave: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			cave: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			tape: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			cape: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
		};
	}

	if (level == "3a" && unit == 1 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			date: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			cake: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			Bake: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			Lake: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			late: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			case: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			vase: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
		};
	}

	if (level == "3a" && unit == 3 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			mice: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			line: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			bike: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			nine: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			rice: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			dice: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			hike: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
		};
	}

	if (level == "3a" && unit == 4 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			kite: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			hide: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			bite: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			hive: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			dive: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
		};
	}

	if (level == "3b" && unit == 6 && stage == 9) {
		bookunitstagechecklistValue = {e: true};
		bookunitstagechecklist = true;
		exceptionWord = "window";
		bookunitstagechecklistAddExecp = {
			dome: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			mole: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			pole: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			home: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			bone: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			cone: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			hole: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			mole: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
			hole: [{loopkey1: 1, loopkey2: 2, loopkey3: 3}],
		};
	}
	const getClassNameException = (fullWord, item, key, type = 1, bookunitstagechecklistAddExecp = {}) => {
		if (stage == 9 && level == "3b" && unit == 9) {
			return item == "e" ? "font_pink" : "font_blue";
		}
		if (["9"].includes(stage.toString()) && ["3a", "3b"].includes(level) && fullWord.length == 4 && key > 0) {
			return "font_pink";
		} else if (["9"].includes(stage.toString()) && ["3a", "3b"].includes(level) && fullWord.length == 4 && key == 0) {
			return "font_blue";
		}
		if (type == 2) {
			return bookunitstagechecklistAddExecp[word] &&
				(bookunitstagechecklistAddExecp[word][0]["loopkey1"] == key ||
					bookunitstagechecklistAddExecp[word][0]["loopkey2"] == key ||
					bookunitstagechecklistAddExecp[word][0]["loopkey3"] == key)
				? "font_pink"
				: "font_blue";
		}
		return isVowel(item) ? "font_pink" : "font_blue";
	};
	return (
		<li onClick={onClick}>
			<S.ImgSoundWriteAlphabetBtn row={word.length>3?2:1} gray={!selected} className={`alphabet_box click ${selected ? "yellow" : "gray"}`}>
				{word && bookNameUnitStage
					? bookunitstagechecklistAdd !== undefined
						? word.split("").map((item, key) => (
								<span
									data={`processone ${bookunitstagechecklistAdd[word][0]["loopkey"]}`}
									key={`block-word-list${key}`}
									style={{ color: bookunitstagechecklistAdd[word][0]["loopkey"] == key ? '#f368e0' : '#2e86de' }}
									className={bookunitstagechecklistAdd[word][0]["loopkey"] == key ? "font_pink" : "font_blue"}
								>
									{item}
								</span>
						  ))
						: word.split("").map((item, key) => (
								<span data="processone" key={`block-word-list${key}`} 
								style={{ color: key != 0 ? '#f368e0' : '#2e86de' }}
								className={key == 0 ? "font_blue" : "font_pink"}>
									{item}
								</span>
						  ))
					: bookunitstagechecklist
					? exceptionWord
						? word.split("").map((item, key) => (
								<span
									data={word}
									data1={item}
									data2={key}
									key={`block-word-list${key}`}
									style={{ color: getClassNameException(word, item, key, 2, bookunitstagechecklistAddExecp).includes('pink') ? '#f368e0' : '#2e86de' }}
									className={getClassNameException(word, item, key, 2, bookunitstagechecklistAddExecp)}
								>
									{item}
								</span>
						  ))
						: word.split("").map((item, key) => (
								<span data={word} key={`block-word-list${key}`}
								style={{ color: bookunitstagechecklistValue[item] ? '#f368e0' : '#2e86de' }}
								className={bookunitstagechecklistValue[item] ? "font_pink" : "font_blue"}>
									{item}
								</span>
						  ))
					: word.split("").map((item, key) => (
							// <span data="processthree" key={`block-word-list${key}`} className={getClassNameException(word, item, key)}>
							<span data="processthree" key={`block-word-list${key}`} className="">
								{item}
							</span>
					  ))}
			</S.ImgSoundWriteAlphabetBtn>
		</li>
	);
};

export default BlockWordBox;
