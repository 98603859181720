import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Col, Row, Modal, Typographym, Button } from 'antd';
import { PlayCircleFilled, SoundFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { useParams, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import VocaHeader from './VocaHeader';

const VocaDictation = () => {
  const ChkBtn = styled.button`
    width: 100px;
    height: 45px;
    border-radius: 15px;
    background: #5399d9;
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;
    border-color: transparent;
    outline: none;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  `;

  return (
    <>
      <div className="dictation-con">
        <div className="left-inner">
          <div className="inner">
            <div className="question-con">
              <h1>Listen and Type.</h1>
              <div className="paragraph-inner">
                <p>
                  Lego is a ******* toy that is **** of small ******* bricks. Children *** use Lego bricks to build **** different things,
                  such as ******, cars, and animals. Lego is a ***** way for children to use their *********** and ********. It is also a
                  great way for ********* to learn about ********* and *******-******.
                </p>
              </div>
              <ChkBtn>Check</ChkBtn>
            </div>
          </div>
        </div>
        <div className="right-inner">
          <div>
            <h1>Passage Dictation</h1>
          </div>
          <div className="result-wrap">
            <div className="result-box">
              <h3> Title: </h3>
              <div className="num-box"></div>
            </div>
            <div className="result-box" style={{ gridTemplateColumns: '1fr 3fr auto' }}>
              <h3> Tries: </h3>
              <div className="num-box"></div>
              <h4 style={{ marginLeft: '1rem' }}>
                Max Tries: <span>3</span>
              </h4>
            </div>

            <div className="result-box">
              <h3> Score: </h3>
              <div className="num-box"></div>
            </div>

            <div className="result-box">
              <h3> Space(%): </h3>
              <div className="num-box"></div>
            </div>
          </div>
          <div className="inner bottom">
            <div>
              <SoundFilled className="speaker-btn" />
            </div>
            <div>
              <PlayCircleFilled className="prev-btn" />{' '}
            </div>
            <div>
              <PlayCircleFilled className="play-btn" />{' '}
            </div>
            <div>
              <PlayCircleFilled />{' '}
            </div>
            <div>
              <ExclamationCircleFilled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VocaDictation;
