import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PopUp from '../PopUp';
import WritePopup from '../WritePopup';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PageNum from '../PageNum';
import _ from 'lodash';
import { alphabetBoxTypeChecker } from '../../../utils/pm/util';
import { useStudyCreate } from '../../../utils/pm/api';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
// import {useMyScript} from "./common/MyScriptHook";

const correctSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';

const LineWordStage = ({ bookInfo, stages, steps, setSteps }) => {
  const MAX_SELECT_COUNT = 3;
  const { problem } = stages;
  const [activeArr, setActiveArr] = useState([]);
  const [showPopUp, setShowPopUp] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [showWritePopUp, setShowWritePopUp] = useState(false);
  const [correctOrWrongSound, setCorrectOrWrongSound] = useState('');

  // const {myScriptEditorRef, script, onClear, error} = useMyScript();
  const history = useHistory();
  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: true },
      });
    },
  });

  const handleCheck = (idx, active) => {
    console.log('activeArr', activeArr);
    if (active) {
      setActiveArr([...activeArr, idx]);
    } else {
      const copy = _.cloneDeep(activeArr);
      const find = _.findIndex(copy, (el) => {
        return el === idx;
      });
      copy.splice(find, 1);
      setActiveArr(copy);
    }
  };

  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };

  const handleCorrectWriteEnd = () => {
    // onClear();
    setShowWritePopUp(false);
    setActiveArr([]);
    setSteps(steps + 1);
  };
  const handleSoundEnd = () => {
    if (correctSound === correctOrWrongSound) {
      setShowWritePopUp(true);
    }
    setCorrectOrWrongSound('');
  };
  const handlePopupClose = () => {
    // onClear();
    setActiveArr([]);
    setShowWritePopUp(false);
  };

  useEffect(() => {
    const checkAnswer = () => {
      if (!_.isNil(problem[steps])) {
        const copyActives = _.cloneDeep(activeArr);
        if (copyActives.length > 0) {
          copyActives.sort();
          const userAnswer = [];
          copyActives.forEach((item) => {
            userAnswer.push(problem[steps].spellings[item].alphabet);
          });
          if (userAnswer.join('') === problem[steps].answer) {
            return true;
          }
        }
      }
      return false;
    };
    const isAnswer = checkAnswer();
    if (isAnswer) {
      setCorrectOrWrongSound(correctSound);
    }
    if (activeArr.length === MAX_SELECT_COUNT) {
      if (!isAnswer) {
        setActiveArr([]);
        setCorrectOrWrongSound(wrongSound);
      }
    }
    // eslint-disable-next-line
  }, [activeArr]);

  useEffect(() => {
    if (steps > problem.length - 1) {
      //스테이지 완료
      setPopUpType('correct');
      setShowPopUp(true);
      return;
    }
  }, [problem, steps]);
  return (
    <>
      <S.ColumnFlex
        style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
        id="lv2_stage7_content"
        className="main_content"
      >
        <S.LineWordTopBox className="img_box  box_default_style" style={{ position: 'relative' }}>
          <PageNum
            num={steps + 1}
            total={problem && problem.length}
            style={{ zIndex: '1', position: 'absolute', top: '10px', right: '10px', fontWeight: 'bold', fontSize: '1.3rem' }}
          />
          <Image src={problem[Math.min(steps, problem.length - 1)].image} alt="보기 이미지" />
        </S.LineWordTopBox>
        <S.LineWordUL className="alphabet_wrap row1_wrap">
          {!showWritePopUp &&
            problem[Math.min(steps, problem.length - 1)].spellings &&
            problem[Math.min(steps, problem.length - 1)].spellings.map((item, key) => (
              <LineWordSpellingBox
                key={`spelling-box-${key}`}
                item={item}
                onClicked={handleCheck}
                idx={key}
                active={activeArr.indexOf(key) !== -1}
                clickedLength={activeArr.length}
                maxClickLength={MAX_SELECT_COUNT}
              />
            ))}
        </S.LineWordUL>
      </S.ColumnFlex>
      {showWritePopUp && (
        <WritePopup
          word={problem[Math.min(steps, problem.length - 1)].answer}
          userScript={{}}
          showWritePopUp={showWritePopUp}
          editorRef={{}}
          onClear={{}}
          onCorrectEnd={handleCorrectWriteEnd}
          onClose={handlePopupClose}
          error={{}}
        />
      )}
      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      <SoundEffectPM url={correctOrWrongSound} onEnd={handleSoundEnd} />
    </>
  );
};

export default LineWordStage;

const LineWordSpellingBox = ({ item, idx, active, onClicked, clickedLength, maxClickLength }) => {
  const handleClick = () => {
    if (!active && clickedLength >= maxClickLength) {
      return;
    }
    onClicked(idx, !active);
  };
  return (
    <S.LineWordLI onClick={handleClick}>
      <S.LineWordBtn
        type="button"
        row={alphabetBoxTypeChecker(1)}
        style={{ color: active ? (item.isPink ? '#f368e0' : '#2e86de') : 'rgba(0, 0, 0, 0.85)' }}
        gray={!active}
        className={`alphabet_box row1 gray ${active ? (item.isPink ? 'active font_pink' : 'active font_blue') : ''}`}
      >
        {item.alphabet}
      </S.LineWordBtn>
    </S.LineWordLI>
  );
};
const Image = styled.img`
  height: 45vh;
  @media (max-height: 500px) {
    height: 40vh;
  }
`;
