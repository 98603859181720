import React, {useState, useEffect} from "react";
import _ from "lodash";
import AlphabetTopArea from "./AlphabetTopArea";
import AlphabetDnDContextArea from "./AlphabetDnDContextArea";
import {ImagePopup} from "./PopUp";
import * as S from 'src/pages/PhonicsMonster/style';
const AlphabetDnD7 = ({customData, steps, setSteps}) => {
	const {problem} = customData;

	const [isDropEnds, setIsDropEnds] = useState(Array(2).fill(false));
	const [isShowPopup, setIsShowPopup] = useState(false);
	const [wordIndex, setWordIndex] = useState(0);

	const handleDragFinish = idx => {
		const copy = _.cloneDeep(isDropEnds);
		copy[idx] = true;
		setIsDropEnds(copy);
	};

	const handleNext = () => {
		if (wordIndex < problem[steps].words.length - 1) {
			setWordIndex(wordIndex + 1);
		} else {
			setSteps(steps + 1);
			setIsDropEnds([false, false]);
			setIsShowPopup(false);
			setWordIndex(0);
		}
	};

	useEffect(() => {
		if (isDropEnds.every(v => v === true)) {
			//setSteps(steps + 1);
			//setIsDropEnds([false, false]);
			setIsShowPopup(true);
		}
	}, [isDropEnds, steps, setSteps]);
	return (
		<>
			<S.RowFlex style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }} className="main_content  stage2_content  col2">
				<S.ImageWordSelectWriteBox className="left_box">
					<AlphabetTopArea title="Long OO" />
					<AlphabetDnDContextArea
						letters={problem[steps].long}
						soundBaseUrl="https://cdn.cloubot.com/PM/audio/sounds/level4/long/"
						onDragFinish={() => {
							handleDragFinish(0);
						}}
					/>
				</S.ImageWordSelectWriteBox>
				<S.ImageWordSelectWriteBox style={{ marginLeft: '24px' }} className="right_box">
					<AlphabetTopArea title="Short OO" />
					<AlphabetDnDContextArea
						letters={problem[steps].short}
						soundBaseUrl="https://cdn.cloubot.com/PM/audio/sounds/level4/short/"
						onDragFinish={() => {
							handleDragFinish(1);
						}}
					/>
				</S.ImageWordSelectWriteBox>
			</S.RowFlex>
			{isShowPopup && (
				<ImagePopup
					discImage={problem[steps].words[wordIndex].discImage}
					wordImage={problem[steps].words[wordIndex].wordImage}
					word={problem[steps].words[wordIndex].word}
					number={wordIndex + 1}
					total={problem[steps].words.length}
					sound={problem[steps].words[wordIndex].sound}
					onNext={handleNext}
				/>
			)}
		</>
	);
};

export default AlphabetDnD7;
