import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
const PhonicsNoticePopup2 = () => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (window.localStorage.getItem('phonics_notice') !== moment().format('YYYY-MM-DD')) {
      setVisible(true);
    }
  }, []);

  const handleDone = () => {
    window.localStorage.setItem('phonics_notice', moment().format('YYYY-MM-DD'));
    setVisible(false);
  };
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <Modal
      width="40vw"
      bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      visible={visible}
      title="Phonics Monster assign한 반에 학생 추가/제외시 주의사항"
      onCancel={handleClose}
      maskClosable={false}
      okText="오늘 하루 그만보기"
      cancelText="닫기"
      closable={false}
      onOk={handleDone}
    >
      <div style={{ textAlign: 'center' }}>
        <h3>1. assign한 반에 학생을 추가할 경우</h3>
        <div>①"반관리" 메뉴의 "학생추가/삭제"에서 추가할 학생을 선택한 후 저장합니다.</div>
        <div>②Phonics Monster Assign List의 Edit에서 추가할 학생을 선택한 후 저장합니다.</div>
        <br/>
        <h3>2. assign한 반에서 학생을 제외할 경우</h3>
        <div>①Phonics Monster Assign List의 Edit에서 제외할 학생을 선택한 후 저장합니다.</div>
        <div>②"반관리" 메뉴의 "학생추가/삭제"에서 제외할 학생을 선택한 후 저장합니다.</div>
        <br/>
        <Button
          onClick={() => {
            history.push('/classroom');
          }}
        >
          반관리 페이지로 이동하기
        </Button>
      </div>
    </Modal>
  );
};

export default PhonicsNoticePopup2;
