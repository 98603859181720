import { gql } from '@apollo/client';

export const CREATE_SCRIBO_SUBSCRIPTION_LIST = gql`
  mutation createScriboSubscriptionList($scribo_subscription_list: [ScriboSubscriptionListInput!]!) {
    createScriboSubscriptionList(scribo_subscription_list: $scribo_subscription_list) {
      idx
    }
  }
`;

export const CREATE_SCRIBO_SUBSCRIPTION = gql`
  mutation createScriboSubscription(
    $email: String!
    $license_start_date: String!
    $campus_idx: Int!
    $assigned_user_idx: Int
    $assigned_date: String
    $number_of_days: Int
  ) {
    createScriboSubscription(
      email: $email
      license_start_date: $license_start_date
      campus_idx: $campus_idx
      assigned_user_idx: $assigned_user_idx
      assigned_date: $assigned_date
      number_of_days: $number_of_days
    ) {
      idx
      email
      license_start_date
      campus_idx
      assigned_user_idx
      assigned_date
      number_of_days
      campus {
        idx
        name
      }
      user {
        idx
        name
      }
    }
  }
`;

export const UPDATE_SCRIBO_SUBSCRIPTION = gql`
  mutation updateScriboSubscription(
    $idx: Int!
    $email: String!
    $license_start_date: String!
    $campus_idx: Int!
    $assigned_user_idx: Int
    $assigned_date: String
    $number_of_days: Int
  ) {
    updateScriboSubscription(
      idx: $idx
      email: $email
      license_start_date: $license_start_date
      campus_idx: $campus_idx
      assigned_user_idx: $assigned_user_idx
      assigned_date: $assigned_date
      number_of_days: $number_of_days
    ) {
      idx
      email
      license_start_date
      campus_idx
      assigned_user_idx
      assigned_date
      number_of_days
      campus {
        idx
        name
      }
      user {
        idx
        name
      }
    }
  }
`;

export const UPDATE_SCRIBO_SUBSCRIPTION_ASSIGNMENT = gql`
  mutation updateScriboSubscriptionAssignment(
    $idx: Int!
    $assigned_user_idx: Int
    $assigned_date: String
    $number_of_days: Int
  ) {
    updateScriboSubscriptionAssignment(
      idx: $idx
      assigned_user_idx: $assigned_user_idx
      assigned_date: $assigned_date
      number_of_days: $number_of_days
    ) {
      idx
      email
      license_start_date
      campus_idx
      assigned_user_idx
      assigned_date
      number_of_days
      campus {
        idx
        name
      }
      user {
        idx
        name
      }
    }
  }
`;

export const DELETE_SCRIBO_SUBSCRIPTION = gql`
  mutation deleteScriboSubscription($idxs: [Int!]!) {
    deleteScriboSubscription(idxs: $idxs) {
      idx
    }
  }
`;
