import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Col, Row, Typography, Button, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { ShoppingCartOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { bookReturnCartVar, deleteBookReturnCartData, changeBookReturnCount, changeStudentBookReturnCount, changeTeacherBookReturnCount } from 'src/apollo/cart';
import { isAfterSchool } from 'src/utils/index';

const { Title } = Typography;
const InputCount = styled.input`
  border: 1px solid #d9d9d9;
  :focus {
    outline: none;
  }
`;

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '80px',
  },
  {
    title: '내용',
    dataIndex: 'book_title',
    key: 'book_title',
  },
  {
    title: '권 수',
    dataIndex: 'count',
    key: 'count',
    render: (book_count, { key, setBookCartRefreshKey }) => {
      return (
        <InputCount
          type="number"
          value={book_count}
          min={1}
          max={50}
          onChange={(e) => {
            changeBookReturnCount(key, e.target.value);
            setBookCartRefreshKey((prev) => prev + 1);
          }}
        />
      );
    },
  },
];

const columnsAfterSchool = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '80px',
  },
  {
    title: '내용',
    dataIndex: 'book_title',
    key: 'book_title',
  },
  {
    title: '학생용',
    dataIndex: 'studentBookCount',
    key: 'studentBookCount',
    render: (book_count, { key, setBookCartRefreshKey }) => {
      return (
        <InputCount
          type="number"
          value={book_count}
          min={1}
          max={50}
          onChange={(e) => {
            changeStudentBookReturnCount(key, e.target.value);
            setBookCartRefreshKey((prev) => prev + 1);
          }}
        />
      );
    },
  },
  {
    title: '교사용',
    dataIndex: 'teacherBookCount',
    key: 'teacherBookCount',
    render: (book_count, { key, setBookCartRefreshKey }) => {
      return (
        <InputCount
          type="number"
          value={book_count}
          min={1}
          max={50}
          onChange={(e) => {
            changeTeacherBookReturnCount(key, e.target.value);
            setBookCartRefreshKey((prev) => prev + 1);
          }}
        />
      );
    },
  },
  {
    title: '총 권수',
    dataIndex: 'count',
    key: 'count',
  },
];

const ReturnBookCart = ({ onExpand, isExpand, bookCartChangedKey }) => {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  const [checkRowList, setcheckRowList] = useState([]);
  const [bookCartRefreshKey, setBookCartRefreshKey] = useState(0);
  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };
  const orgData = useMemo(() => {
    return bookReturnCartVar();
  }, [bookCartRefreshKey, bookCartChangedKey]); //rendering 때문에 추가함.

  const dataSource = useMemo(() => {
    if (orgData) {
      return orgData.map((item, key) => {
        return {
          ...item,
          no: key + 1,
          setBookCartRefreshKey,
        };
      });
    }
    return [];
  }, [orgData]);

  const realColumns = useMemo(() => {
    if (isAfterSchool(companyName)) {
      return columnsAfterSchool;
    } 
    return columns;
  }, [companyName]);

  function handleDelete() {
    if (checkRowList.length === 0) {
      Modal.info({ title: '삭제할 항목을 선택하세요.' });
      return;
    }
    deleteBookReturnCartData(checkRowList);
    setBookCartRefreshKey((prev) => prev + 1);
    setcheckRowList([]);
  }
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col className="book-header-two-table" span={12} style={{ textAlign: 'left', margin: 0 }}>
          <Title level={5}>반품 내역</Title>
        </Col>
        <Col className="book-header-two-table0icon" span={12} style={{ textAlign: 'right', margin: 0 }}>
          <div>
            <Button
              onClick={() => {
                onExpand(!isExpand);
              }}
              icon={
                isExpand ? (
                  <FullscreenExitOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                ) : (
                  <FullscreenOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                )
              }
            ></Button>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ textAlign: 'right', margin: 0 }}>
          <CustomTable
            className="bookorder-table-layout"
            dataSource={dataSource}
            pagination={false}
            columns={realColumns}
            size="small"
            color="#edf3fb"
            //scroll={{ y: 'calc(100vh - 254px)' }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        </Col>
      </Row>

      <Row className="invoice-table-two-btm-two" gutter={[16, 16]}>
        <Col span={12} style={{ textAlign: 'right', margin: 0 }}>
          <Button type="primary" danger onClick={handleDelete}>
            삭제
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: 'right', margin: 0 }}>
          <Button
            type="primary"
            icon={<ShoppingCartOutlined />}
            onClick={() => {
              if (dataSource?.length) {
                history.push('/book/return-request');
              } else {
                Modal.info({
                  title: '반품할 책을 추가하세요.',
                });
              }
            }}
          >
            반품 요청
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ReturnBookCart;
