//apollo의 캐시 기능을 이용해서, 리덕스의 전역상태처럼 구현한다.
//리덕스보다 코딩양도 적고 훨씬 간단함.
import { makeVar } from '@apollo/client';

export const bookCartVar = makeVar([]);
export const bookReturnCartVar = makeVar([]);

export const deleteBookCartData = (keys) => {
  const currentBookCartData = bookCartVar();
  bookCartVar(currentBookCartData.filter((item) => !keys.includes(item.key)));
};

export const changeBookCount = (key, count) => {
  const currentBookCartData = bookCartVar();
  bookCartVar(
    currentBookCartData.map((item) => {
      if (item.key === key) {
        return { ...item, count, price: count * item.unit_price };
      }
      return { ...item };
    }),
  );
};

export const changeStudentBookCount = (key, studentBookCount) => {
  const currentBookCartData = bookCartVar();
  bookCartVar(
    currentBookCartData.map((item) => {
      if (item.key === key) {
        const count = parseInt(studentBookCount)+parseInt(item.teacherBookCount);
        return { ...item, studentBookCount: studentBookCount, count, price: count * item.unit_price };
      }
      return { ...item };
    }),
  );
};

export const changeTeacherBookCount = (key, teacherBookCount) => {
  const currentBookCartData = bookCartVar();
  bookCartVar(
    currentBookCartData.map((item) => {
      if (item.key === key) {
        const count = parseInt(item.studentBookCount)+parseInt(teacherBookCount);
        return { ...item, teacherBookCount:teacherBookCount, count, price: count * item.unit_price };
      }
      return { ...item };
    }),
  );
};

export const deleteBookReturnCartData = (keys) => {
  const currentBookCartData = bookReturnCartVar();
  bookReturnCartVar(currentBookCartData.filter((item) => !keys.includes(item.key)));
};

export const changeBookReturnCount = (key, count) => {
  const currentBookCartData = bookReturnCartVar();
  bookReturnCartVar(
    currentBookCartData.map((item) => {
      if (item.key === key) {
        return { ...item, count };
      }
      return { ...item };
    }),
  );
};

export const changeStudentBookReturnCount = (key, studentBookCount) => {
  const currentBookCartData = bookReturnCartVar();
  bookReturnCartVar(
    currentBookCartData.map((item) => {
      if (item.key === key) {
        const count = parseInt(studentBookCount)+parseInt(item.teacherBookCount);
        return { ...item, studentBookCount: studentBookCount, count };
      }
      return { ...item };
    }),
  );
};

export const changeTeacherBookReturnCount = (key, teacherBookCount) => {
  const currentBookCartData = bookReturnCartVar();
  bookReturnCartVar(
    currentBookCartData.map((item) => {
      if (item.key === key) {
        const count = parseInt(item.studentBookCount)+parseInt(teacherBookCount);
        return { ...item, teacherBookCount:teacherBookCount, count };
      }
      return { ...item };
    }),
  );
};