import { gql } from '@apollo/client';

export const GET_BOARD_LIST = gql`
  query getBoardList($bo_table: String!, $company_idx: Int) {
    getBoardList(bo_table: $bo_table, company_idx: $company_idx) {
      idx
      board_idx
      wr_subject
      wr_content
      wr_hit
      wr_datetime
      user {
        idx
        id
        name
        campus_idx
      }
      board {
        bo_table
      }
      boardFile {
        idx
        board_write_idx
        bf_file
        bf_fileName
        mimetype
        encoding
        bf_datetime
        path
      }
    }

    getBoardRole(bo_table: $bo_table) {
      bo_table
      bo_subject
      bo_list_level
      bo_read_level
      bo_write_level
    }
  }
`;

export const GET_BOARD_COMMENT_LIST = gql`
  query getBoardCommentList($board_write_idx: Int!) {
    getBoardCommentList(board_write_idx: $board_write_idx) {
      idx
      co_content
      co_name
    }
  }
`;
