import React, { useState, useEffect, useMemo } from "react";
import Sound from "react-sound";
// import { soundManager } from "soundmanager2";

const SoundWords = ({ words, onEnd }) => {
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const [soundIndex, setSoundIndex] = useState(0);
  // useMemo(() => {
  //   soundManager.setup({
  //     ignoreMobileRestrictions: true,
  //   });
  // }, [words]);
  const handleFinishPlay = () => {
    setPlayStatus(Sound.status.STOPPED);

    if (soundIndex === words.length - 1) {
      if (onEnd instanceof Function) {
        onEnd();
      }
    } else {
      setSoundIndex(soundIndex + 1);
      setPlayStatus(Sound.status.PLAYING);
    }
  };
  const handleError = (err) => {
    console.log(err);
    onEnd();
  };

  useEffect(() => {
    if (words) {
      setPlayStatus(Sound.status.PLAYING);
    } else {
      setPlayStatus(Sound.status.STOPPED);
      setSoundIndex(0);
    }
  }, [words]);

  return (
    <Sound
      url={words[soundIndex] ? words[soundIndex] : ""}
      playStatus={playStatus}
      onFinishedPlaying={handleFinishPlay}
      onError={handleError}
    />
  );
};

export default SoundWords;
