import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Col, Input, Row, Modal, Typography } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';

import BoardModal from 'src/components/Modal/BoardModal';
import Notice from 'src/components/Board/Notice';
import Question from 'src/components/Board/Question';
import Data from 'src/components/Board/Data';
import CustomButton from 'src/components/common/CustomButton';
import { openNotification } from 'src/components/common/Notification';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';

import { useModal } from 'src/utils/hooks';
import { userInfoVar, BOARD_ROLE } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const { Title, Text } = Typography;
const { confirm } = Modal;

const SeachBox = styled.div`
  margin: 0.5rem;
`;

function Board() {
  const { name } = useParams();
  const { state } = useLocation();
  const [boards, setBoards] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [searchValue, setSeachValue] = useState('');
  const [filterTable, setFilterTable] = useState(null);
  const company_idx = useMemo(() => userInfoVar().campus.company.idx, []);
  const boardName = useMemo(() => {
    return {
      campusNotice: `${userInfoVar().type === 4 ? '공지사항' : '공지사항(본사)'}`,
      managerNotice: `${userInfoVar().type === 2 ? '공지사항' : '공지사항(강사)'}`,
      managerData: `${userInfoVar().type === 4 ? '자료실' : '자료실(본사)'}`,
      notice: `${userInfoVar().type === 0 ? '공지사항' : '공지사항(학생)'}`,
      data: `${userInfoVar().type === 0 ? '자료실' : '자료실(학생)'}`,
      qa: '문의 게시판',
    };
  }, []);
  const { visible, showModal, handleCancel, popupMode } = useModal();

  const { data: fetchBoards, refetch } = useQuery(queries.board.GET_BOARD_LIST, {
    fetchPolicy: 'no-cache',
    variables: { bo_table: name, company_idx },
  });

  const [deleteBoard] = useMutation(mutations.board.DELETE_BOARD, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (state?.isWrite) {
      showModal('create');
    }
    //한번만 실행이 되어야 한다.
  }, [state]);

  useEffect(() => {
    if (fetchBoards) {
      BOARD_ROLE(fetchBoards?.getBoardRole);
      let number = fetchBoards.getBoardList.length ? fetchBoards.getBoardList.length : 1;
      setBoards(
        fetchBoards.getBoardList.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            no: number--,
            title: cur.wr_subject,
            name: cur.user.name,
            date: moment(cur.wr_datetime.replace('.000Z', '')).format('YYYY-MM-DD'),
            file: !!cur.boardFile.length,
            hit: cur.wr_hit,
            pageName: name,
          };

          return [...acc, obj];
        }, []),
      );
    }
  }, [fetchBoards, name]);

  const handleSearch = (value) => {
    setSeachValue(value);
    const filterTable = boards.filter((board) => {
      return Object.keys(board).some((field) => String(board[field]).toLowerCase().includes(value.toLowerCase()));
    });

    setFilterTable(filterTable);
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteBoard({
            variables: {
              boardWrite_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  return (
    <>
      <HeaderTitle level={4}>게시판 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="board-campusnotice-height">
            <SeachBox>
              <div
                className="board-campusnotice-height-dv"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                }}
              >
                <Title level={5}>{boardName[name]}</Title>
                <div>
                  <Input.Search
                    className="board-campusnotice-top-fields"
                    placeholder="검색어를 입력해 주세요"
                    allowClear
                    style={{ width: 250, marginRight: 10 }}
                    enterButton
                    onSearch={handleSearch}
                    onChange={onSeachChange}
                    value={searchValue}
                  />

                  <CustomButton onClick={() => handleSearch('')} style={{ marginRight: 10 }}>
                    <RedoOutlined />
                  </CustomButton>

                  {userInfoVar().type >= BOARD_ROLE()?.bo_write_level && (
                    <>
                      <CustomButton style={{ marginRight: 10 }} onClick={() => showModal('create')}>
                        <PlusOutlined style={{ color: '#52c419' }} />
                        <Text type={'success'}>등록</Text>
                      </CustomButton>
                      <CustomButton
                        danger
                        style={{
                          marginRight: 10,
                        }}
                        onClick={deleteConfirm}
                      >
                        삭제
                      </CustomButton>
                    </>
                  )}
                </div>
              </div>
            </SeachBox>

            {(name === 'notice' || name === 'managerNotice' || name === 'campusNotice') && (
              <Notice
                boards={filterTable === null ? boards : filterTable}
                rowSelection={rowSelection}
                role={userInfoVar().type >= BOARD_ROLE()?.bo_write_level}
              />
            )}
            {(name === 'data' || name === 'managerData') && (
              <Data
                boards={filterTable === null ? boards : filterTable}
                rowSelection={rowSelection}
                role={userInfoVar().type >= BOARD_ROLE()?.bo_write_level}
              />
            )}
            {name === 'qa' && <Question boards={filterTable === null ? boards : filterTable} rowSelection={rowSelection} />}
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <BoardModal visible={visible} popupMode={popupMode} handleCancel={handleCancel} refetch={refetch} />}
    </>
  );
}

export default Board;
