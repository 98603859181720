import React from 'react';
import { Button, Modal, Typography } from 'antd';
import { PrinterTwoTone } from '@ant-design/icons';
const { Title, Text } = Typography;

const RecordingDataModal = ({ data, isShow, onPrint, componentRef, onOk, hidePrint = false }) => {
  return (
    <Modal
      title="Recording"
      width="60%"
      visible={isShow}
      onOk={onOk}
      onCancel={onOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        !hidePrint && (
          <Button onClick={onPrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
            <PrinterTwoTone />
          </Button>
        ),
        <Button type="primary" key="2" onClick={onOk}>
          확인
        </Button>,
      ]}
    >
      <div ref={componentRef} className="show-print" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '10px',
          }}
        >
          {/* <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" />
          <Title level={3} style={{ padding: '0 10px' }}>
            English Vine
          </Title> */}
        </div>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px',
          }}
        >
          <Title level={3} style={{ padding: '10px' }}>
            Student Name: {data?.currentUserName}
          </Title>
          <Title level={3} style={{ padding: '10px', margin: 0 }}>
            Class: {data?.className}
          </Title>
        </div>
        <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
          Recording
        </Title>
        <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
          <span>accuracy: {data.accuracy ? data.accuracy : '0%'}</span>
          <span>wpm: {data.wpm ? data.wpm : 0}</span>
        </div>
      </div>
      <div className="hide-print" dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
      <div className="hide-print" style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
        <span>accuracy: {data.accuracy ? data.accuracy : '0%'}</span>
        <span>wpm: {data?.wpm ? data.wpm : 0}</span>
      </div>
    </Modal>
  );
};

export default RecordingDataModal;
