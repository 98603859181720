import React from 'react';
import { Modal, Button, Divider } from 'antd';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';

const ChatWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const ChatBox = styled.div`
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  max-width: 80%;
  background-color: ${(props) => (props.isUser ? '#0095ff' : '#eee')};
  color: ${(props) => (props.isUser ? '#fff' : '#333')};
  /* align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')}; */
`;
const IconBox = styled.div`
  padding: 10px 3px;
  border-radius: 5px;
  height: 40px;
  width: 60px;
  line-height: 20px;
  text-align: center;
  border: 1px solid ${(props) => (props.isUser ? '#0095ff' : 'gray')};
  color: ${(props) => (props.isUser ? '#0095ff' : 'gray')};
  margin: ${(props) => (props.isUser ? '0 0 0 3px' : '0 3px 0 0')};
`;
const AIChatModal = ({ visible, chatData, onCancel }) => {
  const chat2 = useMemo(() => {
    const tmp = [];
    if (chatData) {
      chatData.reduce((acc, cur, i) => tmp.push({ msg: cur.say, isUser: cur.who === '1', when: cur.idate }), []);
    }
    return tmp;
  }, [chatData]);

  return (
    <>
      <Modal
        visible={visible}
        title={'AI Chat Log'}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            닫기
          </Button>,
        ]}
      >
        {chatData?.map((thread, key) => {
          const isBot = thread.who === '1';
          return (
            <React.Fragment key={`message-item-key${key}`}>
              <DateDivider
                isShow={
                  key < chatData.length &&
                  moment(new Date(thread?.idate)).format('YYYY-MM-DD') !== moment(new Date(chatData[key - 1]?.idate)).format('YYYY-MM-DD')
                }
                date={`${moment(new Date(thread?.idate)).format('YYYY-MM-DD')}`}
              />
              <MessageItem isUser={isBot} name={'Ai'} message={thread.say} date={thread.idate} />
            </React.Fragment>
          );
        })}
      </Modal>
    </>
  );
};

export default AIChatModal;

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};

const MessageItemWrapper = styled.div`
  ${(props) =>
    props.isMe &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `}
`;
const ContentRowWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MessageItem = ({ isUser, message, date }) => {
  const mst = message?.split('\n');
  return (
    <MessageItemWrapper isMe={isUser}>
      <p>{isUser ? 'Student' : 'Ai'}</p>
      <ContentRowWrapper>
        {isUser ? (
          <>
            <TimeBlock date={date} />
            <MessageBlock isMe={isUser} message={message} />
          </>
        ) : (
          <>
            <MessageBlock isMe={isUser} message={message} />
            <TimeBlock date={date} />
          </>
        )}
      </ContentRowWrapper>
    </MessageItemWrapper>
  );
};

const TimeBlock = ({ date }) => {
  return (
    <p
      style={{
        color: '#777777',
        opacity: '0.7',
        position: 'relative',
      }}
    >
      {moment.utc(new Date(date)).format(' hh:mm:ss').replace('AM', '오전').replace('PM', '오후')}
    </p>
  );
};

const MessageBlockWrapper = styled.p`
  padding: 10px;
  border-radius: 10px;
  background: ${(props) => (props.isMe ? `#646A6D` : `#ECF1F3`)};
  ${(props) =>
    props.isMe
      ? css`
          color: #fff;
          margin-left: 10px;
        `
      : `margin-right:10px;`}
`;

const MessageBlock = ({ isMe, message }) => {
  return (
    <>
      <MessageBlockWrapper isMe={isMe} dangerouslySetInnerHTML={{ __html: message }}></MessageBlockWrapper>
    </>
  );
};
