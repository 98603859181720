import { gql } from '@apollo/client';

export const SEND_MESSAGE_SUBSCRIPTION = gql`
  subscription sendMessage($chat_room_idx: Int!) {
    sendMessage(chat_room_idx: $chat_room_idx) {
      idx
      chat_room_idx
      chat_user_idx
      chat_user_name
      content
      read_yn
      idate
    }
  }
`;

export const UPDATE_MESSAGE_SUBSCRIPTION = gql`
  subscription updateMessage($chat_room_idx: Int!) {
    updateMessage(chat_room_idx: $chat_room_idx) {
      chat_room_idx
      chat_user_idx
    }
  }
`;
