import React, { useEffect } from 'react';

const PayLetterCancel = () => {
  useEffect(() => {
    //window.self.close();
    window.open('', '_self').close();
  }, []);
  return <div></div>;
};

export default PayLetterCancel;
