import React, { useCallback } from 'react';
import { Modal, Form } from 'antd';
import {  useMutation } from '@apollo/client';
import { CREATE_CONSULT_LEVELTEST_FOR_METAEXAM } from 'src/operations/mutations/consult';
import { GET_CONSULT_LIST } from 'src/operations/queries/consult';
import AgainMetaExamForm from 'src/pages/Consulting/AgainMetaExamForm';
import DefaultModal from './DefaultModal';
const MetaAgainModal = ({ onCancel, campus_idx, ym, visible, orderIdx, againData, refetch,...rest }) => {
  const [form] = Form.useForm();

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const [create, { loading }] = useMutation(CREATE_CONSULT_LEVELTEST_FOR_METAEXAM, {
    onCompleted(data) {
      if (data) {
        alert('등록했습니다.');
        handleCancel();
        refetch();
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      console.log('error', error);
      alert('오류가 발생했습니다(2).');
    },
    refetchQueries: [
      {
        query: GET_CONSULT_LIST,
        variables: { campus_idx, ym },
      },
    ],
  });


   
  return (
    <>
      <DefaultModal
        title={`${againData?.english_name}(${againData?.name}) 추가시험 등록`}
        visible={visible}
        form={form}
        ActionCancel={handleCancel}
        // onOk={create}
        style={{ width: '50%' }}
        {...rest}
      >
        <AgainMetaExamForm form={form} againData={againData} orderIdx={orderIdx} campus_idx={campus_idx} onCreate={create}/>
      </DefaultModal>
    </>
  );
};
export default MetaAgainModal;
