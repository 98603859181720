import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import FullScreenButton from 'src/components/common/FullScreenButton';
import { userInfoVar } from 'src/apollo/cache';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { wordfind } from 'src/utils/wordfind';
import { useParams } from 'react-router-dom';
import { LEVEL_UP_STUDY_DATA } from 'src/operations/queries/externalStudy';
import { CREATE_EXTERNAL_USER_STUDY } from 'src/operations/mutations/externalUserStudy';
import LevelUpFinishPopUp from 'src/components/LevelUp/FinishPopUp';
import LevelUpExitPopUp from 'src/components/LevelUp/ExitPopUp';
import SoundWords from 'src/components/common/SoundWords';
import SoundEffect from 'src/components/common/SoundEffect';
import { Modal } from 'antd';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 600px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: 25px;
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 205px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 600px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 1000px;
    min-height: 500px;
  }
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const StageTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 30px 0;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const CardWrapper = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const PuzzleWrapper = styled(ColumnFlex)`
  width: 50%;
  padding: 0 20px;
  height: 100%;
  justify-content: center;
`;
const AlphabetBox = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  font-size: 20px;
  border: 2px solid #707070;
  margin: 0.5px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.shadow ? '#ed7d31' : '#000')};
  background-color: ${(props) => (props.disabled ? '#cecece' : props.selected ? '#fff18a' : '#fff')};
  @media (max-height: 800px) {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }
`;
const XButton = styled.div`
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const Front = styled.div`
  width: 270px;
  height: 90px;
  margin: 12px;
  font-size: 23px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fef189;
  background-image: url(${(props) => (props.done ? '/images/LevelUp/game/card_front_done.png' : '/images/LevelUp/game/card_front.png')});
  background-repeat: no-repeat;
  background-size: 270px 90px;
  /* padding: 32px 0; */
  @media (max-width: 1280px) {
    width: 190px;
    height: 63px;
    margin: 10px 5px;
    font-size: 20px;
    background-size: 190px 63px;
  }
  @media (max-height: 750px) {
    width: 190px;
    height: 63px;
    margin: 10px 5px;
    font-size: 20px;
    background-size: 190px 63px;
  }
`;
const BGMButton = styled.div`
  width: 35px;
  height: 35px;
  background-image: url(${(props) => (props.off ? '/images/LevelUp/study/bgm_off.png' : '/images/LevelUp/study/bgm_on.png')});
  border-radius: 16px;
  box-shadow: ${(props) => !props.off && '2px 2px 3px 1px rgba(0, 0, 0, 0.2)'};
  background-size: 35px 35px;
  background-repeat: no-repeat;
  cursor: pointer;
  /* float: right; */
  margin-right: 15px;
`;
const wordDummy = [
  { number: 1, eng: 'chair', kor: '의자', eng_audio: undefined, eng_image: undefined },
  { number: 2, eng: 'table', kor: '책상', eng_audio: undefined, eng_image: undefined },
  { number: 3, eng: 'floor', kor: '바닥', eng_audio: undefined, eng_image: undefined },
  { number: 4, eng: 'bed', kor: '침대', eng_audio: undefined, eng_image: undefined },
  { number: 5, eng: 'house', kor: '집', eng_audio: undefined, eng_image: undefined },
  { number: 6, eng: 'door', kor: '문', eng_audio: undefined, eng_image: undefined },
];
const [bgm, correct, finish] = ['/audio/LevelUp/Game_BGM.mp3', '/audio/LevelUp/Game_Correct.mp3', '/audio/LevelUp/Game_Finish_Victory.mp3'];
export default function CrossPuzzleGame({ location }) {
  const { state } = location;
  const { idx, assignedIdx } = useParams();

  const [shadowIndx, setShadowIndx] = useState(null);
  const [wordUrl, setWordUrl] = useState(undefined);
  const [puzzelData, setPuzzelData] = useState([]);
  const [answerData, setAnswerData] = useState([]);
  const [activePuzzel, setActivePuzzel] = useState([]);
  const [doneWord, setDoneWord] = useState([]);
  // const [seconds, setSeconds] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [sound, setSound] = useState(null);
  const [bgmOption, setBgmOption] = useState(bgm);
  const [clickCount, setClickCount] = useState(0);
  const initialDisableCheck = Array.from({ length: 10 }, () => Array(10).fill(false));
  const [disableCheck, setDisableCheck] = useState(initialDisableCheck);
  const { data } = useQuery(LEVEL_UP_STUDY_DATA, { variables: { type: 'levelup', book_code: String(idx) }, skip: !idx });
  const bgmToggle = () => {
    if (bgmOption) {
      setBgmOption(null);
      window.localStorage.setItem('levelup_bgm_toggle', 'off');
    } else {
      setBgmOption(bgm);
      window.localStorage.removeItem('levelup_bgm_toggle');
    }
  };
  useEffect(() => {
    const localbgmToggle = window.localStorage.getItem('levelup_bgm_toggle');
    if (localbgmToggle === 'off') {
      setBgmOption(null);
    } else {
      setBgmOption(bgm);
    }
  }, []);
  const [createExternalUserStudy, { loading: loadingSave }] = useMutation(CREATE_EXTERNAL_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const studyData = useMemo(() => {
    let tmp = [...wordDummy];
    if (data?.getExternalStudyData && data?.getExternalStudyData.length > 0) {
      tmp = data.getExternalStudyData.filter((e) => e.eng.length < 11 && !e.eng.includes(' '));
    }
    const size = tmp.length >= 8 ? 8 : tmp.length >= 4 ? 4 : 2;
    return _.sampleSize(tmp, size);
  }, [data]);
  let counter = doneWord.length;
  useEffect(() => {
    if (counter < answerData.length && counter !== 0) {
      setSound(correct);
    }
  }, [counter]);
  useEffect(() => {
    if (studyData.length > 0) {
      let wordsGame = studyData.map(function (e) {
        return e.eng;
      });
      let game = wordfind.newPuzzleLax(wordsGame, {
        allowedMissingWords: 2,
        maxGridGrowth: 10,
        fillBlanks: true,
        allowExtraBlanks: 'random',
        maxAttempts: 1000,
        height: 10,
        width: 10,
        orientations: ['horizontal', 'vertical'],
      });

      setPuzzelData(game);
      let answerData = wordfind.solve(game, wordsGame)['found'];
      setAnswerData(answerData);
      for (var i = 0; i < game.length; i++) {
        let tmp = [];
        for (var j = 0; j < game[i].length; j++) {
          tmp.push(false);
        }
        activePuzzel.push(tmp);
      }
      setActivePuzzel(activePuzzel);
    }
  }, [studyData]);
  const totalCount = useMemo(() => {
    if (answerData.length > 0) {
      return answerData.reduce((acc, cur) => {
        return acc + cur.overlap;
      }, 0);
    }
  }, [answerData]);
  // const handleSelect = (e) => {
  //   console.log(e);
  // };
  const selectWord = (i, j) => {
    if (totalCount <= clickCount && doneWord.length !== answerData.length && !activePuzzel[i][j]) {
      Modal.warning({
        title: 'You clicked the wrong letters. Try again.',
        okText: 'OK',
      });
      return;
    }
    if (doneWord.length === answerData.length) {
      return;
    }
    if (activePuzzel[i][j]) {
      activePuzzel[i][j] = false;
      setClickCount((prev) => prev - 1);
    } else {
      activePuzzel[i][j] = puzzelData[i][j];
      setClickCount((prev) => prev + 1);
    }
    const tmpaactivepuzzle = _.cloneDeep(activePuzzel);
    setActivePuzzel(tmpaactivepuzzle);
  };
  useEffect(() => {
    let tempDoneWords = [...doneWord];
    let word = '';
    let nofalse = [];
    let lastSoundIndx = [];
    let newDisableCheck = [...disableCheck];
    for (let p = 0; p < answerData.length; p++) {
      let x = answerData[p]['x'];
      let y = answerData[p]['y'];
      // if (activePuzzel[y][x]) {
      switch (answerData[p]['orientation']) {
        case 'horizontal':
          if (x + answerData[p]['overlap'] > puzzelData.length) {
            word = activePuzzel[y].slice(x);
          } else {
            word = activePuzzel[y].slice(x, x + answerData[p]['overlap']);
          }
          nofalse = word.map(function (e, idx) {
            return e ? '' : answerData[p]['word'][idx];
          });
          lastSoundIndx[p] = nofalse.join('');
          word = word.join('');
          if (word === answerData[p]['word']) {
            tempDoneWords.push(answerData[p]['word']);
            for (let i = y; i < y + 1; i++) {
              for (let j = x; j < x + answerData[p]['overlap']; j++) {
                newDisableCheck[i][j] = true;
              }
            }
          }
          break;
        case 'vertical':
          if (y + answerData[p]['overlap'] > puzzelData.length) {
            word = activePuzzel.slice(y);
          } else {
            word = activePuzzel.slice(y, y + answerData[p]['overlap']);
          }
          word = word.map(function (e) {
            return e[x];
          });
          nofalse = word.map(function (e, idx) {
            return e ? '' : answerData[p]['word'][idx];
          });
          lastSoundIndx[p] = nofalse.join('');
          word = word.join('');
          if (word === answerData[p]['word']) {
            tempDoneWords.push(answerData[p]['word']);
            for (let i = y; i < y + answerData[p]['overlap']; i++) {
              for (let j = x; j < x + 1; j++) {
                newDisableCheck[i][j] = true;
              }
            }
          }
          break;
      }
    }
    setDisableCheck(newDisableCheck);
    setDoneWord(Array.from(new Set(tempDoneWords)));
  }, [activePuzzel]);
  useEffect(() => {
    if (doneWord.length === answerData.length && doneWord.length !== 0) {
      setSound(finish);
      gotoNext();
      setTimeout(function () {
        setIsFinished(true);
      }, 2000);
    }
  }, [doneWord]);

  const gotoNext = async () => {
    const external_user_study_input = {
      user_idx: !_.isNil(userInfoVar()?.idx) ? parseInt(userInfoVar()?.idx) : 0,
      external_study_idx: state?.bookData?.book_no,
      // assigned_idx: parseInt(assignedIdx),
      type: 'levelup',
      // lesson_code: lessonCode,
      // stage_no: parseInt(stageNumber),
      article_data: state?.bookData || null,
      exam_total: 100,
      exam_correct: 100,
      stage_no: 5,
      stage_answers: JSON.stringify(doneWord),
    };

    if (state?.bookData?.assignedIdx) {
      external_user_study_input['assigned_idx'] = parseInt(state?.bookData?.assignedIdx);
    }

    await createExternalUserStudy({
      variables: { external_user_study_input },
    });

    // history.goBack();
  };

  const handleWordSoundEnd = () => {
    setWordUrl(undefined);
  };
  const handleSoundEnd = () => {
    setSound(undefined);
  };

  const speakWord = (ix) => {
    setWordUrl(studyData[ix].eng_audio);
    let tempShadow = JSON.parse(JSON.stringify(puzzelData));
    let word = studyData[ix].eng;
    for (var i = 0; i < puzzelData.length; i++) {
      for (var j = 0; j < puzzelData[i].length; j++) {
        if (puzzelData[i][j] === word[0]) {
          tempShadow[i][j] = true;
        }
      }
    }

    setShadowIndx(tempShadow);

    setTimeout(function () {
      setShadowIndx(null);
    }, 4000);
  };

  return (
    <>
      {visible && (
        <LevelUpExitPopUp assigned_idx={parseInt(state?.bookData?.assignedIdx)} visible={visible} setVisible={setVisible} idx={idx} />
      )}
      {isFinished ? (
        <LevelUpFinishPopUp idx={idx} assigned_idx={parseInt(state?.bookData?.assignedIdx)} />
      ) : (
        <Wrapper>
          <MainWrapper>
            <Main>
              <ColumnFlex style={{ width: '100%' }}>
                <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '0 0 0 50px' }}>
                  <StageTitle>
                    <TitleSpan color={'#453ec8'}>Word Search</TitleSpan>
                    Find the words and click on each letter.
                  </StageTitle>
                  <RowFlex>
                    <div>
                      <QuizNumber>
                        <span style={{ color: '#433fc8' }}>
                          {doneWord.length}/{answerData.length}
                        </span>
                      </QuizNumber>
                    </div>
                    <RowFlex style={{ height: '70px', justifyContent: 'start', alignItems: 'start', padding: '0 20px' }}>
                      <BGMButton off={!bgmOption} onClick={bgmToggle} />
                      <XButton
                        onClick={() => {
                          setVisible(true);
                        }}
                      />
                    </RowFlex>
                  </RowFlex>
                </RowFlex>
              </ColumnFlex>
              <RowFlex>
                <CardWrapper>
                  {studyData.map((e, i) => (
                    <Front onClick={() => speakWord(i)} done={doneWord.includes(e.eng)}>
                      {e.extra_data}
                    </Front>
                  ))}
                </CardWrapper>
                <PuzzleWrapper>
                  {puzzelData.map((e, i) => (
                    <RowFlex key={i} style={{ justifyContent: 'center' }}>
                      {e.map((e2, i2) => (
                        <AlphabetBox
                          disabled={disableCheck[i][i2]}
                          shadow={shadowIndx && shadowIndx[i][i2] === true}
                          key={i + i2}
                          onClick={() => selectWord(i, i2)}
                          selected={activePuzzel[i][i2]}
                        >
                          {e2}
                        </AlphabetBox>
                      ))}
                    </RowFlex>
                  ))}
                </PuzzleWrapper>
              </RowFlex>
            </Main>
          </MainWrapper>
        </Wrapper>
      )}
      <>
        <SoundWords words={wordUrl ? [wordUrl] : []} onEnd={handleWordSoundEnd} />
        <SoundEffect src={sound} replayCnt={1} onEnd={handleSoundEnd} />
        <SoundEffect src={bgmOption} replayCnt={0} volume={50} />
      </>
    </>
  );
}
