import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Typography, Row, Button, Space } from 'antd';
import { withRouter } from 'react-router-dom';
import { LeftOutlined, MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ASSIGNED_TEST_USER_SINGLE } from 'src/operations/queries/getTests';
import { useQuery } from '@apollo/client';
import { useReactToPrint } from 'react-to-print';
import { PrinterTwoTone } from '@ant-design/icons';
import * as diff_match_patch from 'diff-match-patch';
import CustomTable from 'src/components/common/CustomTable';
import { Link } from 'react-router-dom';
import File from 'src/components/common/File';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const dmp = new diff_match_patch();

const { Title } = Typography;

// function averageTimes(times,unit) {
//     if (!times) {
//         return false;
//     }
//     else {
//         var totalMilliseconds = 0, hours, minutes, seconds, milliseconds, parts;
//         for (var i = 0, len = times.length; i < len; i++) {
//             parts = times[i].split(':');
//             hours = parseInt(parts[0], 10) * 3600000;
//             minutes = parseInt(parts[1], 10) * 60000;
//             seconds = parseInt(parts[2].split('.')[0], 10) * 1000;
//             milliseconds = parseInt(parts[2].split('.')[1], 10);
//             totalMilliseconds += (hours + minutes + seconds + milliseconds);
//         }
//         if (!unit || unit.toLowerCase() == 'ms'){
//             return totalMilliseconds/times.length;
//         }
//         else if (unit.toLowerCase() == 's') {
//             return (totalMilliseconds/1000)/times.length;
//         }
//
//     }
// }

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  min-height: calc(100vh - 15vh);
`;

const StudentResult = (props) => {
  const [testUser, setTestUser] = useState({ assigned_test_answers: [], assigned_tests: { tests: { test_answers: [] } } });
  const [activeExtra, setActiveExtra] = useState(false);

  const companyName = useSelector(classStoreData);
  const isSmartEclass = useMemo(() => {
    return isAfterSchool(companyName);
  }, [companyName]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const queries = props.user_idx
    ? {
        idx: parseInt(props.id),
        user_idx: parseInt(props.user_idx),
      }
    : {
        idx: parseInt(props.id),
      };

  const { data, loading } = useQuery(ASSIGNED_TEST_USER_SINGLE, {
    fetchPolicy: 'no-cache',
    variables: queries,
  });

  useEffect(() => {
    if (data?.assignedTestUserSingle) {
      setTestUser(data?.assignedTestUserSingle);
    }
  }, [data]);

  const extraColumns = [
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>PDF</Space>;
      },
      dataIndex: 'code',
      key: 'pdf',
      width: 90,
      render: (text, record, i) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === text)[0];
        return answer?.['pdf'] ? (
          <File
            file={answer['pdf']}
            thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
            textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
          />
        ) : null;
      },
    },
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>Audio</Space>;
      },
      dataIndex: 'code',
      key: 'audio',
      width: 90,
      render: (text, record, i) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers'].filter((f) => f.code === text)[0];
        return answer?.['audio'] ? (
          <File
            file={answer['audio']}
            thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
            textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
          />
        ) : null;
      },
    },
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>Video</Space>;
      },
      dataIndex: 'code',
      key: 'video',
      width: 60,
      render: (text, record, i) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === text)[0];
        return answer?.['video'] ? (
          <File
            file={answer['video']}
            thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
            textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
          />
        ) : null;
      },
    },
  ];

  const columns = [
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>No</Space>;
      },
      dataIndex: 'code',
      key: 'code',
      width: 90,
    },
    // {
    //   title: () => {
    //     return (
    //       <Space style={{color:"#065aa9"}}>
    //         Question
    //       </Space>
    //     )
    //   },
    //   dataIndex: 'name',
    //   key: 'name',
    //   width: 150,
    //   render: (text, record, i) => {
    //     const answer = testUser["assigned_tests"]["tests"]["test_answers"].filter(f => f.code === record.code);
    //     return <Space size="middle">
    //       {answer[]}
    //     </Space>
    //   }
    // },
    {
      title: () => {
        return (
          <Space style={{ color: '#065aa9', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
            Correct Answer
          </Space>
        );
      },
      dataIndex: 'code',
      key: 'correct_answer',
      render: (text) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === text)[0];
        return (
          <Space size="middle" style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
            {answer?.['answer']}
          </Space>
        );
      },
    },
    {
      title: () => {
        return <Space style={{ color: '#065aa9', display: 'flex', justifyContent: 'center' }}>User Answer</Space>;
      },
      dataIndex: 'answer',
      key: 'user_answer',
      render: (text, record, i) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === record.code)[0];
        if (answer?.['option_count']) {
          return <Space style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>{text}</Space>;
        } else {
          dmp.Diff_Timeout = 5;
          dmp.Diff_EditCost = 5;
          const d = dmp.diff_main(answer?.['answer'] || '', text);
          dmp.diff_cleanupSemantic(d);
          let htmltext = [];
          d.forEach((element, i) => {
            if (element[0] === 0) {
              const filteredArrayl = element[1].split(' ').filter((value) => answer?.['answer'].split(' ').includes(value));
              htmltext.push(`<span style="color:black;">${element[1]}</span>`);
            } else if (element[0] === -1) {
              htmltext.push(`<span style="color:brown;">${element[1]}</span>`);
            } else if (element[0] === 1) {
              htmltext.push(`<span style="color:darkblue;">${element[1] && element[1] !== 'undefined' ? element[1] : ''}</span>`);
            }
          });

          return (
            <Space size="middle" style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div dangerouslySetInnerHTML={{ __html: `${htmltext.join('')}` }} />
            </Space>
          );
        }
      },
    },
    {
      title: () => {
        return (
          <div style={{ color: '#065aa9', display: 'flex', justifyContent: 'center' }}>
            <div>O/X</div>
          </div>
        );
      },
      dataIndex: 'is_correct',
      key: 'is_correct',
      width: isSmartEclass ? 'auto' : 70,
      render: (text) => {
        if (text) {
          return (
            <Space
              align="center"
              style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', color: '#2ea906' }}
            >
              O
            </Space>
          );
        } else {
          return (
            <Space
              align="center"
              style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', color: '#a90606' }}
            >
              X
            </Space>
          );
        }
      },
    },
  ];

  const buttonColumn = [
    {
      title: <Button type="primary" icon={<MenuOutlined />} onClick={() => setActiveExtra(!activeExtra)} />,
      key: 'action',
      width: 70,
      render: (text, record) => {
        return <Space size="middle" key={record.id}></Space>;
      },
    },
  ];

  const finalExtraColumn = isSmartEclass ? [] : activeExtra ? [...extraColumns, ...buttonColumn] : [...buttonColumn];

  const assignedRetakes = testUser?.['assigned_test_answer_retakes'] || [];
  const maxRetakesTaken = assignedRetakes.reduce((a, b) => (a.retries > b.retries ? a : b), {});
  const retakeColumns = maxRetakesTaken?.retries
    ? new Array(maxRetakesTaken?.retries).fill(0).reduce((a, b, i) => {
        return [
          ...a,
          {
            title: () => {
              return (
                <Space style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', color: '#065aa9' }}>
                  Retake Answer {i + 1}
                </Space>
              );
            },
            dataIndex: `retake_answer_${i}`,
            key: `retake_answer_${i}`,
            render: (text, record) => {
              let findAnswer = assignedRetakes.filter((f) => f.code === record.code && f.retries === i + 1);
              if (!findAnswer.length) {
                return '';
              } else {
                text = findAnswer.length ? findAnswer[0]['answer'] : '';

                const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === record.code)[0];
                if (answer?.['option_count']) {
                  return (
                    <Space style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                      {answer?.['answer']}
                    </Space>
                  );
                } else {
                  dmp.Diff_Timeout = 5;
                  dmp.Diff_EditCost = 5;
                  const d = dmp.diff_main(answer?.['answer'] || '', text);
                  dmp.diff_cleanupSemantic(d);
                  let htmltext = [];
                  d.forEach((element, i) => {
                    if (element[0] === 0) {
                      const filteredArrayl = element[1].split(' ').filter((value) => answer?.['answer'].split(' ').includes(value));
                      htmltext.push(`<span style="color:black;">${element[1]}</span>`);
                    } else if (element[0] === -1) {
                      htmltext.push(`<span style="color:brown;">${element[1]}</span>`);
                    } else if (element[0] === 1) {
                      htmltext.push(`<span style="color:darkblue;">${element[1] && element[1] !== 'undefined' ? element[1] : ''}</span>`);
                    }
                  });

                  return (
                    <Space size="middle" style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <div dangerouslySetInnerHTML={{ __html: `${htmltext.join('')}` }} />
                    </Space>
                  );
                }
              }
            },
          },
          {
            title: () => {
              return (
                <div style={{ color: '#065aa9', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div>Retake O/X {i + 1} </div>
                </div>
              );
            },
            dataIndex: `retake_is_correct_${i}`,
            key: `retake_is_correct_${i}`,
            width: 100,
            render: (text, record) => {
              let findAnswer = assignedRetakes.filter((f) => f.code === record.code && f.retries === i + 1);
              if (!findAnswer.length) {
                return '';
              }
              if (findAnswer[0].is_correct) {
                return (
                  <Space
                    align="center"
                    style={{ color: '#2ea906', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}
                  >
                    O
                  </Space>
                );
              } else {
                return (
                  <Space
                    align="center"
                    style={{ color: '#a90606', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}
                  >
                    X
                  </Space>
                );
              }
            },
          },
        ];
      }, [])
    : [];

  const copyColumns = [...columns];
  copyColumns.splice(1, 1);
  const realColumns = isSmartEclass ? [...copyColumns] : [...columns];

  const finalColumns = assignedRetakes.length
    ? [...realColumns, ...retakeColumns, ...finalExtraColumn]
    : [...realColumns, ...finalExtraColumn];

  return (
    <>
      <Col span={24} className="test-result-wrapper">
        <MainBlock ref={componentRef}>
          <Row gutter={[24, 16]}>
            <Col className="result-title-wrap" span={14} style={{ padding: 0 }}>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col className="result-title-a" span={14} style={{ padding: 0, paddingLeft: 10 }}>
                  <Title level={5} ecliple={true}>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Test Title :</span> {testUser.assigned_tests.title}{' '}
                  </Title>
                </Col>
                <Col className="result-title-b" span={10} style={{ padding: 2 }}>
                  <Title level={5}>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>날짜 :</span>{' '}
                    {moment(testUser.start_time).format('YYYY.MM.DD, HH:mm')}
                  </Title>
                </Col>
              </Row>
              <Row className="result-test-title fordesktoponly-result" style={{ margin: 0, marginTop: 15 }}>
                <Title level={5}>
                  <span style={{ color: '#065aa9', marginLeft: 10 }}>Test :</span> {testUser.assigned_tests.tests.title}{' '}
                </Title>
              </Row>
            </Col>
            <Col className="result-title-wrap formobileonly-result" span={16} style={{ padding: 0 }}>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col className="result-title-a-mb" span={24} style={{ padding: 0, paddingLeft: 10 }}>
                  <Title level={5}>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Test :</span> {testUser.assigned_tests.tests.title}{' '}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col className="result-title-wrap formobileonly-result" span={16} style={{ padding: 0 }}>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col className="result-title-b-mb" span={12} style={{ padding: 2 }}>
                  <Title level={5}>
                    <span style={{ color: '#ff7a1b', marginLeft: 10 }}>
                      Time - {moment.utc(moment(testUser.end_time).diff(moment(testUser.start_time))).format('HH:mm:ss')}
                    </span>{' '}
                  </Title>
                </Col>
                <Col className="result-title-c-mb" span={12} style={{ padding: 2 }}>
                  <Title level={5}>
                    <span style={{ color: '#de3333', marginLeft: 10 }}>Score : {testUser.score}</span>{' '}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col className="resultbtntop-wrap" span={10} style={{ padding: 2 }}>
              <Row gutter={[24, 16]} style={{ margin: 0 }} className="formobile-hide">
                <Col offset={2} span={10}>
                  <Title level={5}>
                    <span style={{ color: '#ff7a1b', marginLeft: 10 }}>
                      Time - {moment.utc(moment(testUser.end_time).diff(moment(testUser.start_time))).format('HH:mm:ss')}
                    </span>{' '}
                  </Title>
                </Col>
                <Col offset={2} span={10}>
                  <Title level={5}>
                    <span style={{ color: '#de3333', marginLeft: 10 }}>Score : {testUser.score}</span>{' '}
                  </Title>
                </Col>
              </Row>
              <Row className="resultbtntop" gutter={[24, 16]} style={{ margin: 0 }}>
                {!props.user_idx ? (
                  <>
                    <Col className="resultbtntop-a" span={10} offset={2}>
                      <Link to={`/test/new/${testUser['assigned_tests']['tests']['idx']}/preview`}>
                        <Button style={{ color: '#065aa9' }}>시험지 보기</Button>
                      </Link>
                    </Col>
                    <Button onClick={handlePrint} style={{ marginRight: 20, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
                      <PrinterTwoTone />
                    </Button>
                    <Col className="resultbtntop-b" span={8} style={{ padding: 2 }}>
                      <Button shape="round" size="default" icon={<LeftOutlined />} onClick={() => props.history.goBack()}>
                        Back
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col className="resultbtntop-a" span={10} offset={2}>
                    <Button onClick={handlePrint} style={{ marginRight: 20, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
                      <PrinterTwoTone />
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <CustomTable
            className="testresult-table"
            loading={loading}
            dataSource={testUser.assigned_test_answers}
            columns={finalColumns}
            size="small"
            bordered
            color="#edf3fb"
            pagination={{ hideOnSinglePage: true, position: ['bottomCenter'] }}
            //scroll={{ y: 'calc(100vh - 254px)' }}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(StudentResult);
