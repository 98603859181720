import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Tag, DatePicker } from 'antd';
// import { Link } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import CustomTable from 'src/components/common/CustomTable';
import { CAMPUS_STUDENT_BOOK_CREDIT_ASSIGNED_LIST_GROUP_BY_CLASS } from 'src/operations/queries/bookCredit';
// import { isAfterSchool } from 'src/utils/index';
// import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';

const BookCreditAssignedList = () => {
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const { data, loading } = useQuery(CAMPUS_STUDENT_BOOK_CREDIT_ASSIGNED_LIST_GROUP_BY_CLASS, {
    variables: { campus_idx: userInfoVar()?.campus_idx, ym: selectedMonth },
    skip: !userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });
//  const companyName = useSelector(classStoreData);
  const dataSource = useMemo(() => {
    let list = data?.getCampusStudentBookCreditAssignedListGroupByClass;
    // if (isAfterSchool(companyName)) {
    //   list = list?.filter((item) => {
    //     return item.book.book_sub[0].book_category?.name === 'package';
    //   });
    // }
    if (list) {
      return list.map((item, key) => {
        const { idx, regdate, book_code, book, credit_count } = item;
        return {
          key: `assigned_credit${idx}`,
          no: key + 1,
          regdate: moment(regdate).format('YYYY-MM-DD h:mm:ss a'),
          book_title: book?.book_sub[0]?.title || book_code,
          book_code,
          class_name: item?.class_name,
          credit_count,
          book_start_date: item?.book_start_datetime ? moment(item?.book_start_datetime).format('YYYY-MM-DD') : item?.start_datetime ? moment(item?.start_datetime).format('YYYY-MM-DD') : '',
          book_end_date: item?.book_end_datetime ? moment(item?.book_end_datetime).format('YYYY-MM-DD') : item?.end_datetime ? moment(item?.end_datetime).format('YYYY-MM-DD') : '',
          duration: `${item?.days ? item?.days+'일' : ''}`,
        };
      });
    }
    return [];
  }, [data]);
  return (
    <>
      <Row gutter={[24, 16]} className="bookcredit-top-head">
        <Col className="bookcredit-top-title" span={13} style={{ textAlign: 'left' }}>
          {/* <Title level={5}>컨텐츠 관리</Title> */}
        </Col>
        <Col className="bookcredit-top-rightform" span={11} style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}>
          <div className="purchase-select-option">
            <DatePicker
              onChange={(m) => {
                setSelectedMonth(m.format('YYYY-MM'));
              }}
              picker="month"
              inputReadOnly={true}
              format="YYYY년 M월"
              value={moment(`${selectedMonth}-01`)}
              allowClear={false}
            />
          </div>
          {/* <div>
            <label>총 구매 금액 :</label>
            <input type="text" />
          </div> */}
        </Col>
      </Row>
      <CustomTable
        className="bookcredit-table-table"
        loading={loading}
        pagination={{ pageSize: 12, position: ['bottomCenter'] }}
        dataSource={dataSource}
        columns={columnstabtwo}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
      />
    </>
  );
};

export default BookCreditAssignedList;

const columnstabtwo = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '등록 시간 통과',
    dataIndex: 'regdate',
    key: 'regdate',
  },
  {
    title: '상품 명',
    dataIndex: 'book_title',
    key: 'book_title',
  },
  {
    title: '반 명',
    dataIndex: 'class_name',
    key: 'class_name',
  },
  {
    title: '이용권 사용 수',
    dataIndex: 'credit_count',
    key: 'credit_count',
    className: 'tabtwo-clm-ls-four',
  },
  {
    title: '시작 날짜',
    dataIndex: 'book_start_date',
    key: 'book_start_date',
  },
  {
    title: '종료 날짜',
    dataIndex: 'book_end_date',
    key: 'book_end_date',
  },
  {
    title: '기간',
    dataIndex: 'duration',
    key: 'duration',
    className: 'tabtwo-clm-ls-three',
  },
  {
    title: '기간적용',
    dataIndex: 'duration2',
    key: 'duration2',
    render: (tags) => <span className="statustbl status-red status-blue"></span>,
  },
  {
    title: '이용권 수정',
    dataIndex: 'edit',
    key: 'columneight',
    className: 'taglistcolumn',
    render: (_, { book_code }) => {
      return (
        // <Link to={`/book-credit/assign/${book_code}`}>
        <Tag
          style={{ cursor: 'pointer' }}
          onClick={() => {
            alert('준비중입니다.');
          }}
          color="blue"
        >
          수정
        </Tag>
        // </Link>
      );
    },
  },
];
