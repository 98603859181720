import React, { useEffect, useMemo, useState } from 'react';
import { userInfoVar } from 'src/apollo/cache';
import styled from 'styled-components';
import { Col, Row, Typography, Modal, Button, Tag, Card, Image } from 'antd';
import { ShoppingCartOutlined, CalendarOutlined } from '@ant-design/icons';
import titleimagetwo from 'src/images/reload.png';
import SelectBookSeriesBox, { useBookSeriesBox } from 'src/components/BookStore/SelectBookSeriesBox';
import moment from 'moment';
import ReturnBookCart from 'src/components/BookStore/ReturnBookCart';
import BookOrderMenuGroup from 'src/components/common/BookOrderMenuGroup';
import { getDifferentTitle, isDifferentTitle } from 'src/utils';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import BookOrderTable from 'src/components/BookStore/BookOrderTable';
import { bookReturnCartVar } from 'src/apollo/cart';
import { isAfterSchool } from 'src/utils';
import { useHistory } from 'react-router-dom';
import { GET_CAMPUS_BOOK_INFO_LIST } from 'src/operations/queries/campus';
import { COMPANY_BOOK_STORE_PACKAGE_LIST } from 'src/operations/queries/company';
import { CDN_BASE_URL } from 'src/constants/common';
import { useQuery } from '@apollo/client';
import { settings } from 'src/dummy/settings';
moment.locale('en');
const englishMoment = moment();

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const { Title, Text } = Typography;

const resolveBookCartData = (
  bookTitle,
  books,
  root_book_code,
  cartMap,
  l,
  volObj,
  isUseCredit,
  isSmartEclass = false,
  isTeacher = false,
) => {
    const level_no = l;
    let level_name = l;
    const find = books.find((item) => parseInt(item[0]) === parseInt(l));
    if (find) {
      level_name = find[1].level_name && find[1].level_name !== '' ? find[1].level_name : l;
    }
    Object.entries(volObj).forEach(([v, num]) => {
      if (num && num.volume) {
        if ((isUseCredit && num?.days !== 0) || (!isUseCredit && num?.days === 0)) {
        let book_title = isDifferentTitle(root_book_code, parseInt(level_no))
          ? getDifferentTitle(`${root_book_code}-L${level_no}V${v}`)
          : `${bookTitle} ${books.length === 1 ? '' : level_name + '-'}${v}${num.days ? '-' + num.days : ''}`; //level이 하나밖에 없는 경우는 레벨제외
        const bookCartKey = `book-cart-${book_title}`;
        const existingCartItem = cartMap.get(bookCartKey);
        if (existingCartItem) {
          cartMap.set(bookCartKey, {
            key: bookCartKey,
            book_title,
            book_code: `${root_book_code}-L${level_no}V${v}`,
            count: existingCartItem.count + num.volume,
            days: num.days,
            type: 'single',
            studentBookCount: !isTeacher ? existingCartItem.studentBookCount + num.volume : existingCartItem.studentBookCount,
            teacherBookCount: isTeacher ? existingCartItem.teacherBookCount + num.volume : existingCartItem.teacherBookCount,
          });
        } else {
          cartMap.set(bookCartKey, {
            key: bookCartKey,
            book_title,
            book_code: `${root_book_code}-L${level_no}V${v}`,
            count: num.volume,
            days: num.days,
            type: 'single',
            studentBookCount: !isTeacher ? num.volume : 0,
            teacherBookCount: isTeacher ? num.volume : 0,
          });
        }
      } else {
        Modal.info({
          title: '유효한 교재 입력값을 선택하세요.',
        });
      }
    }
    });
    return cartMap;
};

const BookReturn = () => {
  const history = useHistory();
  const companyName = useSelector(classStoreData);
  const [bookItem, onBookChange] = useBookSeriesBox();
  const [bookCountObj, setBookCountObj] = useState({});
  const [bookCountObjForTeacher, setBookCountObjForTeacher] = useState({});
  const [isExpand, setIsExpand] = useState(false);
  const [bookCartChangedKey, setBookCartChangedKey] = useState(0);

  const { data: dataCampusBook } = useQuery(GET_CAMPUS_BOOK_INFO_LIST, {
    skip: !userInfoVar()?.campus?.idx,
    variables: {
      campus_idx: userInfoVar()?.campus?.idx,
    },
  });

  let company_idx = userInfoVar()?.campus?.company?.idx;
  const { data, loading } = useQuery(COMPANY_BOOK_STORE_PACKAGE_LIST, { variables: { company_idx }, skip: !company_idx });
  const bookSeries = useMemo(() => {
    const list = data?.companyBookStorePackageList?.filter((item) => item.book?.book_sub[0]?.category !== 6);
    if (list) {
      return list.reduce((acc, cur) => {
        if (['SM', 'SK'].includes(cur.book_code)) {
          const smarteclass = { ...cur, book_list: cur.book_list.filter((item) => item.level_no > 0) };
          const phonics = {
            ...cur,
            book: { ...cur.book, book_sub: [{ title: 'Phonics' }] },
            book_list: cur.book_list.filter((item) => item.level_no === 0),
          };
          return [
            ...acc,
            { ...smarteclass, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L1V1.jpg` },
            { ...phonics, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L0V1.jpg` },
          ];
        } else {
          return [...acc, { ...cur, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L1V1.jpg` }];
        }
      }, []);
    }
    return [];
  }, [data]);
  const bookSeries2 = (bookSeries &&isAfterSchool(companyName))? settings[companyName].bookSort(bookSeries) : bookSeries

  const [bookTitle, books, volumes, root_book_code, isUseCredit] = useMemo(() => {

    const getPrices = (code) => {
      if (dataCampusBook?.getCampusBookInfoList) {
        const findItem = dataCampusBook.getCampusBookInfoList.find((ele) => ele.book.code === code);
        if (findItem) {
          return findItem.prices;
        }
      }
      return null;
    };

    if (bookItem) {
      const bookItemObject = bookItem;
      const root_book_code = bookItemObject?.book_code;
      const bookTitle = bookItemObject?.book?.book_sub[0]?.title;
      const isUseCredit = bookItem.is_use_credit;
      if (bookItemObject.book_list instanceof Array && bookItemObject.book_list.length > 0) {
        const bookMap = new Map();
        let volList = new Set();
        for (const i of bookItemObject.book_list) {
          const bookPrices = getPrices(`${bookItemObject.book_code}-L${i.level_no}V${i.volume_no}`);
          if (bookMap.has(i.level_no)) {
            const item = bookMap.get(i.level_no);
            bookMap.set(i.level_no, { ...item, vol_list: [...item.vol_list, i], price_list: [...item.price_list, bookPrices] });
          } else {
            bookMap.set(i.level_no, { level_name: i.level_name, vol_list: [i], price_list: [bookPrices] });
          }
          volList = volList.add(i.volume_no);
        }
        if (isUseCredit) {
          return [bookTitle, Array.from(bookMap), Array.from(volList), root_book_code, isUseCredit];
        } else {
          return [bookTitle, Array.from(bookMap), Array.from(volList), root_book_code, isUseCredit];
        }
      }
    }
    return [];
  }, [bookItem]);

  const totalCurrentBookCount = useMemo(() => {
    const studentBookCnt = Object.entries(bookCountObj).reduce((acc, [, volval]) => {
      return acc + Object.values(volval).reduce((a, c) => a + c, 0);
    }, 0);
    const teacherBookCount = Object.entries(bookCountObjForTeacher).reduce((acc, [, volval]) => {
      return acc + Object.values(volval).reduce((a, c) => a + c, 0);
    }, 0);
    return studentBookCnt + teacherBookCount;
  }, [bookCountObj, bookCountObjForTeacher]);

  useEffect(() => {
    setBookCountObj({}); //초기화
    setBookCountObjForTeacher({}); //초기화
  }, [bookItem]);

  const handleCountChange = (l, v, number, type, defaultValue) => {
    const obj = { ...bookCountObj };
    if (!obj[l]) {
      obj[l] = {};
    }
    if (!obj[l][v]) {
      if (defaultValue) {
        obj[l][v] = {
          volume: 0,
          days: defaultValue,
        };
      } else {
        obj[l][v] = {
          volume: 0,
          days: 0,
        };
      }
    }
    if (type === 'volume') {
      obj[l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : 0;
    }
    if (type === 'days') {
      obj[l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : defaultValue;
    }
    setBookCountObj({ ...obj });
  };

  const handleCountChangeForTeacher = (l, v, number, type, defaultValue) => {
    const obj = { ...bookCountObjForTeacher };
    if (!obj[l]) {
      obj[l] = {};
    }
    if (!obj[l][v]) {
      if (defaultValue) {
        obj[l][v] = {
          volume: 0,
          days: defaultValue,
        };
      } else {
        obj[l][v] = {
          volume: 0,
          days: 0,
        };
      }
    }
    if (type === 'volume') {
      obj[l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : 0;
    }
    if (type === 'days') {
      obj[l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : defaultValue;
    }

    setBookCountObjForTeacher({ ...obj });
  };

  const handleMoveToCart = () => {
    if (totalCurrentBookCount === 0) {
      Modal.info({
        title: '책 권수를 선택하세요.',
      });
      return;
    }

    const bookReturnCartData = bookReturnCartVar();

    let cartMap = new Map();
    bookReturnCartData.forEach((cartItem) => {
      cartMap.set(cartItem.key, cartItem);
    });

    Object.entries(bookCountObj).forEach(([l, volObj]) => {
      cartMap = resolveBookCartData(
        bookTitle,
        books,
        root_book_code,
        cartMap,
        l,
        volObj,
        isUseCredit,
        isAfterSchool(companyName),
        false,
      );
    });

    Object.entries(bookCountObjForTeacher).forEach(([l, volObj]) => {
      cartMap = resolveBookCartData(
        bookTitle,
        books,
        root_book_code,
        cartMap,
        l,
        volObj,
        isUseCredit,
        isAfterSchool(companyName),
        true,
      );
    });

    const cartArray = Array.from(cartMap.values());

    bookReturnCartVar(cartArray);
    onBookChange('');
    setBookCartChangedKey((prev) => prev + 1);
  };

  const type = userInfoVar()?.type;
  if (isAfterSchool(companyName)) {
    if (type && ![1, 2, 3].includes(type)) {
      history.replace('/');
    }
  } else {
    if (type && type !== 2) {
      history.replace('/');
    }
  }
  const BookCoverImage = ({ src, href }) => {
    return (
      <section
        style={{
          position: 'relative',
          textAlign: 'center',
          paddingLeft: '20px',
          paddingRight: '20px',
          // height: '30%',
        }}
      >
        <Image
          preview={false}
          style={{ width: '100%', maxWidth: '188px', marginTop: '10px',border: '1px solid #dedede' }}
          src={src}
          fallback="/images/menu-icon/content.png"
        />
      </section>
    );
  };
  const Grid = styled(Card.Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 31vh;
    border: 1px solid #dedede;
  `;
  const handleBook = (val) => {
    onBookChange(val);
  };

  return (
    <Col className="bookstore-book-order-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
      <Row gutter={[16, 16]}>
        <Col span={12} style={{ textAlign: 'left' }}>
          {/* <HeaderTitle level={4}>Book Store(컨텐츠 구매)</HeaderTitle> */}
          <BookOrderMenuGroup currentMenu="book-return" />
        </Col>
        <Col className="topheader-image" span={12} style={{ textAlign: 'right' }}></Col>
      </Row>
      <MainBlock className="bookstore-book-order-mainblock">
        <Row gutter={[16, 16]}>
          <Col
            className="bookstore-book-order-mainblock-a"
            span={isExpand ? 0 : 16}
            style={{ textAlign: 'left', borderRight: '1px solid #aaa' }}
          >
            <Row gutter={[16, 16]} className="bookstore-header-top">
              <Col className="bookstore-heading" span={12} style={{ textAlign: 'left', margin: 0 }}>
                <Title className="mainheader-left" level={5}>
                  <div>교재 반품</div>
                  <div>
                    <img
                      src={titleimagetwo}
                      alt=""
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        onBookChange('');
                        setBookCountObj({}); //초기화
                        setBookCountObjForTeacher({}); //초기화
                      }}
                    />
                  </div>
                </Title>
              </Col>
              <Col className="select-right" span={12} style={{ textAlign: 'right', margin: 0 }}>
                {!isAfterSchool(companyName) && (
                  <SelectBookSeriesBox company_idx={userInfoVar()?.campus?.company?.idx} bookItemValue={bookItem} onChange={onBookChange} />
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <div className="book-order-main-wrapper" style={{ width: '100%' }}>
                <Col span={24} style={{ textAlign: 'left', margin: 0 }}>
                  <div className="calendra-outline">
                    <div className="calendra-outline-date-bold">
                      <CalendarOutlined /> Date
                    </div>
                    <div className="calendra-outline-date">{englishMoment.format('ddd, YYYY/MM/DD')}</div>
                  </div>
                </Col>
                <Col className="booktable-main-tbl-main" span={24} style={{ textAlign: 'left', margin: 0 }}>
                  {!books ? (
                    <>
                      <Text style={{ textAlign: 'center', fontSize: '1.3em' }}>반품할 책 시리즈를 선택하세요.</Text>
                      {isAfterSchool(companyName) && (
                        <>
                          <Card >
                            {bookSeries
                              ? (isAfterSchool(companyName) ? bookSeries2 : bookSeries).map((item) => {
                                  return (
                                    <Grid onClick={() => handleBook(item)} style={{ float: 'left'}}>
                                      <div>
                                        <BookCoverImage
                                          src={
                                            item?.book?.book_sub[0].cover_path ? item?.book?.book_sub[0].cover_path : item?.cover_full_path
                                          }
                                        />
                                        <h1 style={{ textAlign: 'center' }}>
                                          {item?.book?.book_sub[0].title ? item?.book?.book_sub[0].title : item?.book_code}
                                        </h1>
                                      </div>
                                    </Grid>
                                  );
                                })
                              : null}
                          </Card>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {isAfterSchool(companyName) && (
                        <div style={{ width: '100%', textAlign: 'right', marginBottom: 5 }}>
                          <Tag color="blue" style={{ fontSize: '14px' }}>
                            학생용
                          </Tag>
                        </div>
                      )}
                      <BookOrderTable
                        bookTitle={bookTitle}
                        volumes={volumes}
                        root_book_code={root_book_code}
                        books={books}
                        bookCountObj={bookCountObj}
                        isUseCredit={bookItem.is_use_credit}
                        onCountChange={handleCountChange}
                        isAfterSchool={['smart-eclass', 'sneakersedu'].includes(companyName)}
                      />
                      {isAfterSchool(companyName) && (
                        <>
                          <div style={{ width: '100%', textAlign: 'right', marginBottom: 5 }}>
                            <Tag color="blue" style={{ fontSize: '14px' }}>
                              교사용
                            </Tag>
                          </div>
                          <BookOrderTable
                            bookTitle={bookTitle}
                            volumes={volumes}
                            root_book_code={root_book_code}
                            books={books}
                            bookCountObj={bookCountObjForTeacher}
                            isUseCredit={bookItem.is_use_credit}
                            onCountChange={handleCountChangeForTeacher}
                            isAfterSchool={['smart-eclass', 'sneakersedu'].includes(companyName)}
                          />
                        </>
                      )}
                      <div style={{ marginTop: 10, textAlign: 'right' }}>
                        <Button type="default" icon={<ShoppingCartOutlined />} onClick={handleMoveToCart}>
                          장바구니저장
                        </Button>
                      </div>
                    </>
                  )}
                </Col>
              </div>
            </Row>
          </Col>
          <Col className="bookstore-book-order-mainblock-b" span={isExpand ? 24 : 8} style={{ textAlign: 'left', margin: 0 }}>
            <ReturnBookCart isExpand={isExpand} onExpand={setIsExpand} bookCartChangedKey={bookCartChangedKey} />
          </Col>
        </Row>
      </MainBlock>
    </Col>
  );
};

export default BookReturn;
