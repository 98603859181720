import React from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Assign, AssignExternalStudyUser, ResultsPage } from 'src/components/ExternalStudy/ExternalStudyEdit/';

const Manage = (props) => {
  const { type = 'add', id = undefined } = useParams();

  const getSwitchableRoute = (val, id) => {
    switch (val) {
      case 'assign':
        return <Assign />;
      case 'assign-edition':
        return <AssignExternalStudyUser />;
      case 'result':
        return <ResultsPage />;
      default:
        return <Assign />;
    }
  };

  return (
    <>
      <Row gutter={[24, 16]}>{getSwitchableRoute(type, id)}</Row>
    </>
  );
};

export default withRouter(Manage);
