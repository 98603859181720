import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import SoundButton from '../SoundButton';
import SoundEffectPM, { SoundEffectMulti } from 'src/components/pm/SoundEffectPM';
import { isVowel, alphabetBoxTypeChecker } from '../../../utils/pm/util';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import WritePopup from '../WritePopup';
import PopUp from '../PopUp';
import PageNum from '../PageNum';
import { useStudyCreate } from '../../../utils/pm/api';
// import {useMyScript} from "./common/MyScriptHook";
import * as S from 'src/pages/PhonicsMonster/style';
import { useHistory } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';

const defaultImage = 'https://cdn.cloubot.com/PM/image/default_monster_gray.png';
const veryGoodSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';

const PhonemeSelectAndDndStage = ({ unitInfo, bookInfo, stages, steps, setSteps, directionEnd }) => {
  const { problem } = stages;
  const [isCorrect, setIsCorrect] = useState(false);
  const [soundCorrectWrong, setSoundCorrectWrong] = useState('');
  const [soundUrls, setSoundUrls] = useState(undefined);

  const [selectNumber, setSelectNumber] = useState(0);
  const [dndNumber, setDndNumber] = useState(0);

  const selectTotal = useMemo(() => {
    if (problem) {
      return problem.reduce((acc, cur) => {
        if (cur.type === 'select') {
          return acc + 1;
        }
        return acc;
      }, 0);
    }
  }, [problem]);

  const dndTotal = useMemo(() => {
    if (problem) {
      return problem.reduce((acc, cur) => {
        if (cur.type === 'dnd') {
          return acc + 1;
        }
        return acc;
      }, 0);
    }
  }, [problem]);

  const suffledPhonemes = useMemo(() => {
    if (!_.isNil(problem[steps])) {
      return _.shuffle(problem[steps].candidate);
    }
    return [];
  }, [problem, steps]);

  useEffect(() => {
    if (selectTotal > 0) {
      if (problem[steps].type === 'select' && steps < selectTotal) {
        setSelectNumber(steps + 1);
      } else if (problem[steps].type === 'select') {
        setSelectNumber((prev) => prev + 1);
      }
    }
  }, [problem, steps, selectTotal]);

  useEffect(() => {
    if (dndTotal > 0) {
      if (problem[steps].type === 'dnd' && steps >= dndTotal) {
        setDndNumber(steps + 1 - dndTotal);
      } else if (problem[steps].type === 'dnd') {
        setDndNumber((prev) => prev + 1);
      }
    }
  }, [problem, steps, dndTotal]);

  const handleSelect = (value) => {
    if (value === problem[steps].answer.name) {
      setSoundCorrectWrong(veryGoodSound);
      setIsCorrect(true);
    } else {
      setSoundCorrectWrong(woopsSound);
    }
  };

  const handleSoundEnd = () => {
    if (soundCorrectWrong === veryGoodSound) {
      console.log('handleSoundEnd good');
      setSoundUrls([problem[steps].answer.url, problem[steps].word.sound]);
    }
    setSoundCorrectWrong('');
  };

  const handleStepEnd = () => {
    setSoundUrls(undefined);
    setIsCorrect(false);
    setSteps(steps + 1);
  };

  if (_.isNil(problem[steps])) {
    return undefined;
  }

  return (
    <>
      {
        {
          select: (
            <PhonemeSelect
              phonemes={suffledPhonemes}
              wordImage={problem[steps].word.image}
              sound={problem[steps].answer.url}
              isCorrect={isCorrect}
              onSelect={handleSelect}
              soundUrls={soundUrls}
              onStepEnd={handleStepEnd}
              directionEnd={directionEnd}
              num={selectNumber}
              total={selectTotal}
            />
          ),
          dnd: (
            <PhonemeDnd
              problem={problem}
              phonemes={suffledPhonemes}
              wordImage={problem[steps].word.image}
              sound={problem[steps].answer.url}
              correctAnswer={problem[steps].answer.name}
              word={problem[steps].word.name}
              wordSoundUrl={problem[steps].word.sound}
              steps={steps}
              setSteps={setSteps}
              unitInfo={unitInfo}
              bookInfo={bookInfo}
              directionEnd={directionEnd}
              num={dndNumber}
              total={dndTotal}
            />
          ),
        }[problem[steps].type]
      }
      <SoundEffectPM url={soundCorrectWrong} onEnd={handleSoundEnd} />
    </>
  );
};

export default PhonemeSelectAndDndStage;

const PhonemeSelect = ({ num, total, phonemes, isCorrect, wordImage, sound, onSelect, soundUrls, onStepEnd, directionEnd }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  phonemes.map((item, index) => console.log(item, index, ['ot', 'og', 'ox'].includes(item), 'phonemes'));
  return (
    <>
      <S.ColumnFlex
        style={{ position: 'relative', alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
        id="lv3_unit10_review3_content"
        className="main_content"
      >
        <PageNum
          num={num}
          total={total}
          style={{ zIndex: '1', position: 'absolute', top: '10px', right: '10px', fontWeight: 'bold', fontSize: '1.3rem' }}
        />
        <S.SelectAndDndTopBox className="img_box">
          <img style={{ height: '40vh' }} src={isCorrect ? wordImage : defaultImage} alt="숨김 이미지" />
          {!isCorrect && <SoundButton src={sound} style={{ position: 'relative', marginLeft: '15px' }} />}
        </S.SelectAndDndTopBox>
        <S.SelectAndDndBtnBox className="btn_box">
          {phonemes &&
            phonemes.map((item, index) =>
              ['ot', 'og', 'ox'].includes(item) ? (
                // <S.SelectAndDndBtnLi key={`phonemes-${index}`} isCorrect={isCorrect && selectedIndex === index}>
                //   <S.SelectAndDndBtn
                //     class={`${isCorrect && selectedIndex === index ? 'active' : ''}`}
                //     onClick={() => {
                //       if (!isCorrect) {
                //         onSelect(item);
                //         setSelectedIndex(index);
                //       }
                //     }}
                //   >{`- ${item}`}</S.SelectAndDndBtn>
                // </S.SelectAndDndBtnLi>
                <S.SelectAndDndBtnLi
                  onClick={() => {
                    if (!isCorrect) {
                      onSelect(item);
                      setSelectedIndex(index);
                    }
                  }}
                  key={`phonemes-${index}`}
                  isCorrect={isCorrect && selectedIndex === index}
                >
                  {/* <S.SelectAndDndBtn
                    class={`${isCorrect && selectedIndex === index ? 'active' : ''}`}
                    onClick={() => {
                      if (!isCorrect) {
                        onSelect(item);
                        setSelectedIndex(index);
                      }
                    }}
                  > */}
                  {`- ${item}`}
                  {/* </S.SelectAndDndBtn> */}
                </S.SelectAndDndBtnLi>
              ) : (
                <S.SelectAndDndBtnLi
                  onClick={() => {
                    if (!isCorrect) {
                      onSelect(item);
                      setSelectedIndex(index);
                    }
                  }}
                  key={`phonemes-${index}`}
                  isCorrect={isCorrect && selectedIndex === index}
                >
                  {/* <S.SelectAndDndBtn
                    class={`${isCorrect && selectedIndex === index ? 'active' : ''}`}
                    onClick={() => {
                      if (!isCorrect) {
                        onSelect(item);
                        setSelectedIndex(index);
                      }
                    }}
                  > */}
                    {`- ${item}`}
                    {/* </S.SelectAndDndBtn> */}
                </S.SelectAndDndBtnLi>
              ),
            )}
        </S.SelectAndDndBtnBox>
      </S.ColumnFlex>

      {soundUrls ? <SoundEffectMulti urls={soundUrls} onEnd={onStepEnd} /> : undefined}
      {directionEnd && <SoundEffectPM url={sound} />}
    </>
  );
};

const PhonemeDnd = ({
  num,
  total,
  phonemes,
  wordImage,
  sound,
  correctAnswer,
  word,
  wordSoundUrl,
  steps,
  setSteps,
  problem,
  bookInfo,
  unitInfo,
  directionEnd,
}) => {
  const [showWritePopUp, setShowWritePopUp] = useState(false);
  const [popUpType, setPopUpType] = useState(undefined);
  const [showPopUp, setShowPopUp] = useState(false);
  const [dropSound, setDropSound] = useState(undefined);
  const [wordSound, setWordSound] = useState(undefined); //맞췄을 때 다시 들려주는 용도임
  const [dropped, setDropped] = useState(false);
  // const {myScriptEditorRef, script, onClear, error} = useMyScript();

  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setPopUpType('correct');
      setShowPopUp(true);
    },
  });

  const front = _.cloneDeep(word).substring(0, 1);
  const dragEnd = (e) => {
    if (!e.destination || e.destination.index > 0 || e.destination.droppableId.length > 1) {
      setDropSound('wrong_sound1');
      return;
    }
    setDropped(true);
    setDropSound('mouse-click');
  };
  const handleCorrectWriteEnd = () => {
    // onClear();
    setShowWritePopUp(false);
    if (!_.isNil(problem[steps + 1])) setSteps((prev) => prev + 1);
    else {
      handleCreateStudy({
        lesson_code: window.sessionStorage.getItem('lesson_code'),
        stage_no: parseInt(bookInfo.stage),
        user_idx: parseInt(userInfoVar()?.idx),
        user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
      });
    }
  };
  const onSoundEffectEnd = () => {
    setShowPopUp(false);
    history.replace({
      pathname: `${window.location.pathname.split('/stage')[0]}`,
      search: `${window.location.search}`,
      state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
    });
  };

  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      //setShowWritePopUp(true);
      setWordSound(wordSoundUrl);
    }
    setDropSound(undefined);
  };

  const handleWordSoundEnd = () => {
    setWordSound(undefined);
    setShowWritePopUp(true);
  };

  const handlePopupClose = () => {
    // onClear();
    setShowWritePopUp(false);
    setDropped(false);
  };

  useEffect(() => {
    setDropped(false);
  }, [correctAnswer]);
  return (
    <>
      <S.RowFlex style={{ flex: '1 1', height: 'calc(100% - 70px)', alignSelf: 'stretch' }} className="main_content  stage4_content  col2">
        <S.BorderColumnBox
          className="left_box"
          style={{ flex: '1 1', position: 'relative', justifyContent: 'center', alignItems: 'center' }}
        >
          <div>
            <img style={{ height: '44vh', verticalAlign: 'middle' }} alt="images" src={wordImage} />
            {!dropped && <SoundButton src={sound} style={{ position: 'absolute', top: '10px', right: '10px' }} />}
          </div>
          <PageNum
            style={{ position: 'relative', top: '0px', left: '0px', textAlign: 'center', margin: 0, fontWeight: 600, fontSize: '20px' }}
            num={num}
            total={total}
          />
        </S.BorderColumnBox>
        <S.BorderColumnBox style={{ flex: '1 1', marginLeft: '24px' }} className="right_box">
          <DragDropContext onDragEnd={dragEnd}>
            <S.ImgDndAlphabetJoin className="alphabet_join">
              <S.ImgDndAlphabetP className="font_blue tab_p">{front}</S.ImgDndAlphabetP>
              <S.AlphabetWrap style={{ flex: 'unset' }} className="alphabet_wrap">
                <li>
                  <Droppable droppableId={front} type={word}>
                    {(provided, snapshot) => (
                      <S.AlphabetBox
                        style={{ backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        row={alphabetBoxTypeChecker(3)}
                        gray={!dropped}
                        className={dropped ? `alphabet_box row3 green` : `alphabet_box row3 gray`}
                      >
                        {dropped &&
                          correctAnswer &&
                          _.map(correctAnswer, (alphabet, idx2) => (
                            <span
                              key={`upper-alphabet-${idx2}`}
                              style={{ color: '#f368e0' }}
                              className={isVowel(alphabet) ? 'font_pink' : 'font_pink'}
                            >
                              {alphabet}
                            </span>
                          ))}
                      </S.AlphabetBox>
                    )}
                  </Droppable>
                </li>
              </S.AlphabetWrap>
            </S.ImgDndAlphabetJoin>
            <img
              style={{ height: '40px', marginBottom: '1vh' }}
              src="/images/pm/i_arrow_top.svg"
              alt="위로 향하는 화살표"
              className="top_arrow"
            />
            <S.AlphabetBottomWrap className="gray_bg_border_top">
              <S.AlphabetBottomWrapUl className="alphabet_wrap new_ul_tab">
                {phonemes &&
                  phonemes.map((item, idx) => (
                    <Droppable key={`droppable-${item}`} droppableId={`drop${item}`} isDropDisabled={true} type={front + item}>
                      {(provided) => (
                        <li ref={provided.innerRef} {...provided.droppableProps}>
                          <Draggable draggableId={`drag${item + idx}`} index={idx}>
                            {(provided) => (
                              <S.AlphabetBox
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={`phonemes-${idx}`}
                                row={alphabetBoxTypeChecker(3)}
                                hide={dropped && word === front + item}
                                className={`alphabet_box row3 green ${dropped && word === front + item ? 'area_hide' : ''}`}
                              >
                                {item &&
                                  _.map(item, (alphabet, idx2) => (
                                    <span
                                      data={item}
                                      key={`alphabet-${idx2}`}
                                      style={{ color: ['ag', 'am', 'ad'].includes(item) ? '#f368e0' : '#2e86de' }}
                                      className={['ag', 'am', 'ad'].includes(item) ? 'font_pink' : 'font_blue'}
                                    >
                                      {alphabet}
                                    </span>
                                  ))}
                                {provided.placeholder}
                              </S.AlphabetBox>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </li>
                      )}
                    </Droppable>
                  ))}
              </S.AlphabetBottomWrapUl>
            </S.AlphabetBottomWrap>
          </DragDropContext>
        </S.BorderColumnBox>
      </S.RowFlex>
      {showWritePopUp && (
        <WritePopup
          word={word}
          userScript={{}}
          showWritePopUp={showWritePopUp}
          editorRef={{}}
          onCorrectEnd={handleCorrectWriteEnd}
          onClear={{}}
          onClose={handlePopupClose}
          textInPink={correctAnswer}
          error={{}}
        />
      )}
      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={onSoundEffectEnd} /> : undefined}
      {directionEnd && <SoundEffectPM url={sound} />}
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      <SoundEffectPM url={wordSound} onEnd={handleWordSoundEnd} />
    </>
  );
};
