import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Col, Select } from 'antd';
import ClassSelectBox, { useCampusClass, useClassDate, useClassBooks } from 'src/components/common/ClassSelectBox';
import TeacherSelectBox, { useTeacherSelectBox } from 'src/components/common/TeacherSelectBox';
import { userInfoVar } from 'src/apollo/cache';
import { CLASS_STATUS_TYPE_IN_PRGRESS, CLASS_STATUS_TYPE_FINISH } from 'src/constants/common';
import { CLASS_STUDENT_LIST_FOR_REPORT_CARD } from 'src/operations/queries/getClass';
import { useMemo } from 'react';
const { Option } = Select;
const classTypes = [
  { value: 0, text: '진행반' },
  { value: 1, text: '종료반' },
];
const SearchClassComp = ({
  onSearchClassChange = undefined,
  onSearchStudentChange = undefined,
  onSearchClassDateChange = undefined,
  onSearchClassBookChange = undefined,
  onSearchClassTypeChange = undefined,
  defaultClassType = 0,
  hasStudentDropbox = false,
  hasTeacherDropBox = true,
  hideFinishedClass = false,
  hasBookDropBox = false,
  isAfterSchool = false,
}) => {
  const campus_idx = userInfoVar()?.campus_idx;
  const user_type = userInfoVar()?.type;
  const [classType, setClassType] = useState(defaultClassType);
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { classBooks, onClassBooksChange } = useClassBooks();
  const { dates: classDates, onClassDateChange } = useClassDate();
  const { selectedTeacherIdx, onTeacherChange } = useTeacherSelectBox();
  const [classStatusType, setClassStatusType] = useState(CLASS_STATUS_TYPE_IN_PRGRESS);

  const [refreshKey, setRefreshKey] = useState(0);
  const [studntUserIdx, setStudentUserIdx] = useState(0);
  const [classBookCodes, setClassBookCodes] = useState([]);

  const [getSelectClassStudents, { data: dataStudents, loading }] = useLazyQuery(CLASS_STUDENT_LIST_FOR_REPORT_CARD, {
    fetchPolicy: 'no-cache',
  });

  const handleChangeClassType = (val) => {
    setClassType(val);
    setClassStatusType(val === 1 ? CLASS_STATUS_TYPE_FINISH : CLASS_STATUS_TYPE_IN_PRGRESS);
    setRefreshKey(refreshKey + 1);
    onClassChange(0);
    setStudentUserIdx(0);
  };

  useEffect(() => {}, [dataStudents]);

  useEffect(() => {
    getSelectClassStudents({ variables: { class_idx: selectedClassIdx, hasReportCard: false } });
  }, [selectedClassIdx]);

  useEffect(() => {
    if (onSearchClassChange instanceof Function) {
      onSearchClassChange(selectedClassIdx);
    }
    setStudentUserIdx(0);
  }, [selectedClassIdx, onSearchClassChange]);

  useEffect(() => {
    setClassBookCodes([]);
  }, [selectedClassIdx]);

  useEffect(() => {
    if (onSearchStudentChange instanceof Function && hasStudentDropbox) {
      onSearchStudentChange(studntUserIdx);
    }
  }, [studntUserIdx, hasStudentDropbox, onSearchStudentChange]);

  useEffect(() => {
    if (onSearchClassDateChange instanceof Function) {
      onSearchClassDateChange(classDates);
    }
  }, [onSearchClassDateChange, classDates]);

  useEffect(() => {
    if (onSearchClassBookChange instanceof Function) {
      onSearchClassBookChange(classBookCodes, classBooks);
    }
  }, [onSearchClassBookChange, classBookCodes, hasBookDropBox]);

  useEffect(() => {
    if (onSearchClassTypeChange instanceof Function) {
      onSearchClassTypeChange(classType);
    }
  }, [onSearchClassTypeChange, classType]);
  const aftercode = useMemo(() => {
    const tmp = [];
    for (let i = 0; i < classBooks.length; i++) {
      tmp.push(i);
    }
    return tmp
  }, [classBooks]);
  return (
    <>
      {parseInt(user_type) === 2 && hasTeacherDropBox && (
        <Col span={3}>
          <TeacherSelectBox
            isAll={true}
            selectedTeacherIdx={selectedTeacherIdx}
            size={`default`}
            style={{ width: '100%' }}
            onChange={onTeacherChange}
          ></TeacherSelectBox>
        </Col>
      )}

      <Col span={3}>
        <Select size={`default`} style={{ width: '100%' }} value={classType} onChange={handleChangeClassType}>
          {classTypes.map(({ value, text }, key) => (
            <Option key={`class-type-option${key}`} value={value}>
              {text}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={6}>
        <ClassSelectBox
          style={{ visibility: `${hideFinishedClass && classType === 1 ? 'hidden' : 'visible'}` }}
          key={`refresh${refreshKey}`}
          onClassChange={onClassChange}
          onClassDateChange={onClassDateChange}
          onClassBooksChange={onClassBooksChange}
          selectedClassIdx={selectedClassIdx}
          campus_idx={campus_idx}
          isOnlyLatest={false}
          classStatusType={classStatusType}
          class_teacher_idx={selectedTeacherIdx}
          // firstRowDisplayValue="선택"
          firstRowDisplayValue={userInfoVar()?.company_name !== 'smart-eclass' ? '반 선택' : '선택'}
          hasClassBoookTitle={true}
        />
      </Col>
      {hasStudentDropbox && (
        <Col span={3}>
          <Select size={`default`} style={{ width: '100%' }} loading={loading} value={studntUserIdx} onChange={setStudentUserIdx}>
            <Option key={`class-student-option${'000'}`} value={0}>
              선택
            </Option>
            {dataStudents &&
              dataStudents?.classStudentList?.map(({ user: { idx, name } }, key) => (
                <Option key={`class-student-option${key}`} value={idx}>
                  {name}
                </Option>
              ))}
          </Select>
        </Col>
      )}

      {hasBookDropBox && (
        <Col span={3}>
          <Select
            size={`default`}
            style={{ width: 400, marginRight: 10 }}
            mode="multiple"
            value={isAfterSchool ? aftercode : classBookCodes}
            placeholder="교재를 선택해주세요."
            onChange={setClassBookCodes}
            disabled={isAfterSchool}
          >
            {classBooks.map((value, key) => (
              <Option key={`class-book-option${key}`} value={key}>
                {value?.book_sub[0]?.short_title || value?.book_sub[0]?.title}
              </Option>
            ))}
          </Select>
        </Col>
      )}
    </>
  );
};

export default SearchClassComp;

export const useSearchClass = () => {
  const [selectedClassIdx, setSelectedClassIdx] = useState(0);
  const onChange = (class_idx) => {
    setSelectedClassIdx(class_idx);
  };
  return { selectedClassIdx, onChange };
};

export const useSearchStudent = () => {
  const [selectedUserIdx, setSelectedUserIdx] = useState(0);
  const onChange = (user_idx) => {
    setSelectedUserIdx(user_idx);
  };
  return { selectedUserIdx, onChange };
};

export const useSeachClassDate = () => {
  const [dates, setDates] = useState([]);
  const onChange = (dateRange) => {
    setDates(dateRange);
  };
  return { dates, onChange };
};

export const useSearchClassType = () => {
  const [type, setType] = useState(0);
  const onChange = (type) => {
    setType(type);
  };
  return { selectedClassType: type, onChange };
};

export const useSearchClassBooks = () => {
  const [classBooks, setClassBooks] = useState([]);
  const [classBookCodes, setClassBookCodes] = useState([]);
  const onChange = (class_books_codes, class_books) => {
    setClassBookCodes(class_books_codes);
    setClassBooks(class_books);
  };
  return { classBooks, classBookCodes, onChange };
};
