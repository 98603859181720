import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import smsIcn from 'src/images/smsIcon2.png';
import katalkIcn from 'src/images/katalk-logo.png';
import msgIcn from 'src/images/msg-logo.png';
import SmsChargeModal from 'src/components/Modal/SmsChargeModal';
import { koreanNumberFormat } from 'src/utils';

const SmsSendBoard = ({ smsRemainedPoint, smsCount = 0, talkCount = 0 }) => {
  return (
    <>
      <Row className="SmsSendBoard_row">
        <Col>
          <img style={{ width: '30px', height: '30px', margin: 'auto 5px', display: 'block' }} src={smsIcn} alt="smsIcon" />
          <span>이번 달 사용 수 :</span>
          <div className="InputUsed">
            <span className="RemainNum">{smsCount}</span>
          </div>
        </Col>
        <Col>
          {/* <span>잔여 문자 수 :</span>
          <div className="InputUsed">
            <span className="TxtUsedNum">12</span>/<span className="TxtRemainNum">200</span>
          </div> */}
          <span>총 잔여 금액 :</span>
          <div className="InputUsed">
            <span className="TxtUsedNum">{koreanNumberFormat(smsRemainedPoint)}</span>
          </div>
        </Col>
      </Row>
      <Row className="SmsSendBoard_row">
        <Col>
          <img src={katalkIcn} style={{ width: '30px', height: '30px', margin: 'auto 5px', display: 'block' }} alt="kakaotalkIcn"></img>
          <span>이번 달 사용 수 :</span>
          <div className="InputUsed">
            <span className="RemainNum">{smsCount}</span>
          </div>
        </Col>
        <Col>
          {/* <span>잔여 카톡 수 :</span>
          <div className="InputUsed">
            <span className="TxtUsedNum">12</span>/<span className="TxtRemainNum">200</span>
          </div> */}
        </Col>
      </Row>
      <Row className="SmsSendBoard_row">
        <Col>
          {/* <img
            style={{ width: '30px', height: '30px', margin: 'auto 5px', display: 'block' }}
            src={'../../images/notify.png'}
            alt="msgIcon"
          />
          <span>이번 달 사용 수 :</span>
          <div className="InputUsed">
            <span className="RemainNum">-</span>
          </div> */}
        </Col>
        <Col>
          <span>문자충전 :</span>
          <SmsChargeModal />
        </Col>
      </Row>
    </>
  );
};

export default SmsSendBoard;
