import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import AddLangData from '../../pages/Contents/AddLangData'
import { useMutation } from '@apollo/client';
import ReactPlayer from 'react-player';
import FormItem from 'antd/lib/form/FormItem';
import DefaultModal from './DefaultModal';
import { ADD_STUDY_LANG_DATA } from 'src/operations/mutations/book';
import RecordRTC, { invokeSaveAsDialog, StereoAudioRecorder } from 'recordrtc';
import { captureUserMedia } from 'src/utils';
import { openNotification } from '../common/Notification';

const AddLangDataModel = ({ handleCancel,handlePopup,lessonCode,popupMode = 'create', prefix, level, volume, ...rest }) => {
  const [form] = Form.useForm();

  const [addStudyLang, { loading }] = useMutation(ADD_STUDY_LANG_DATA, {
    onCompleted(data) {
      if (data) {
        openNotification('컨텐츠 수정 완료!');
        //onFinish();
        handlePopup(false)
      }
    },
    onError(error) {
      console.log('error', error);
      alert('오류가 발생했습니다.');
    },
  });

  const handleSubmit = (value) => {
    let eng_audio = value?.file ? value?.file?.file?.name : ''
    let eng_image = value?.eng_image ? value?.eng_image?.file?.name : ''
    let eng_slice = value?.eng_slice ? value?.eng_slice : ''
    let eng_video = value?.eng_video ? value?.eng_video?.file?.name : ''
    let extra_data = value?.extra_data ? value?.extra_data : ''
    let kor_audio = value?.kor_audio ? value?.kor_audio?.file?.name : ''
    let tag = value?.tag ? value?.tag : ''

    const { study_data, eng, kor } = value;
    addStudyLang({ variables: { lang_data_input: { data_idx:study_data, eng, kor,eng_audio:eng_audio,no:0,
      eng_image:eng_image,
      eng_slice:eng_slice,
      eng_video:eng_video,
      extra_data:extra_data,
      kor_audio:kor_audio,
      tag:tag,
    }} });
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }
  return (
    <DefaultModal
      className="modalfolder-popup"
      //title={`${isSmartEclass ? '학교' : '학원'}정보`}
      title="Add Data"
      form={form}
      // loading={loadingCreate || loadingModify}
      ActionCancel={popupMode === 'create' ? ActionCancel : handleCancel}
      popupMode={popupMode}
      width={80}
      style={{ top: 20 }}
      {...rest}
    >
      <AddLangData
        form={form}
        onFinish={handleSubmit}
        prefix={prefix}
        level={level}
        volume={volume}
        lessonCode={lessonCode}
      ></AddLangData>
    </DefaultModal>
  );
};

export default AddLangDataModel;
