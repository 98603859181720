import React, { useEffect, useState } from 'react';
import { Alert, Form, Input, Modal, Select, DatePicker } from 'antd';
import styled from 'styled-components';
// import MetaCallTable from './MetaCallTable';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import SearchCampusModal from 'src/components/Modal/SearchCampusModal';
import { useQuery, useMutation } from '@apollo/client';
import { CAMPUS_SEARCH_LIST } from 'src/operations/queries/campus';
import { DAY_WISE_SCHEDULE_DATA, GET_SCHEDULE_OR_NOT } from 'src/operations/queries/getScheduleData';
import { META_EXAM_SCHEDULE_SLOT } from 'src/operations/mutations/schedule';

const { Option } = Select;

const InnerWrap = styled.div`
  background-color: #fff;
  // width: calc(100% - 25rem);
  width: 800px;
  height: calc(100% - 10rem);
  padding: 0rem 1rem;
  margin: 2rem auto;
  border-radius: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  & {
    .ant-alert-error {
      /* 
      background: #fff;
      border-color: #fff; */
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 18rem);
    height: calc(100% - 20rem);
    padding: 2rem 1rem;
    margin: 5rem auto;
    & {
      .inner-header {
        top: 0;
        position: relative;
        width: calc(90% - 10rem);
      }

      .btn.fixed_btn {
        bottom: 0;
        padding: 2rem 4rem;
        margin: 2rem auto;
        height: auto;
        width: auto;
        a {
          font-size: 2rem;
          position: relative;
        }
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 9) {
    height: calc(100vh - 26rem);
    width: 900px;
    //  width: calc(90% - 3rem);
    max-width: 2000px;
    margin: 3rem auto;

    & {
      .inner-header {
        top: 1rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1920px) {
    .inner-header {
      width: calc(100% - 10rem);
      display: flex;
      position: relative;
      margin: 1rem 0;
      transform: translate(0px, 0px);
      top: 0rem;
      left: auto;
      h2 {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 10) {
    & {
      .btn {
        padding: 1rem 1rem;
        margin: 2rem auto;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 9) {
    width: 800px;
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 750px;
    margin: 3rem auto;

    & {
      .inner-header {
        width: 100%;
        max-width: 1300px;
        padding: 0 4rem;

        h2 {
          font-size: 1.4rem;
        }
      }
      .btn.fixed_btn {
      }
    }
  }

  @media (max-width: 1280px) {
    height: 80%;
    max-width: 100%;
    max-height: 100%;
    width: 900px;
    & {
      #student-register .userInfo-wrap {
        margin: 2rem auto 0;
      }

      .btn.fixed_btn {
        position: relative !important;
        bottom: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    .inner-header {
      position: relative;
      top: 0;
      transform: translate(0, 0);
      width: 100%;
    }
  }

  @media (max-width: 1680px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 25rem);
    height: auto;

    & {
      .inner-header {
        top: 0;
        margin: 1rem 0 1rem;
        width: 650px;
      }

      .fixed_btn {
        bottom: 0;
      }
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    margin: 2rem auto;

    & {
      .inner-header {
        top: 0;
        width: 100%;
        max-width: 1150px;
        margin: 0 8rem;

        h2 {
          font-size: 1.4rem;
        }
      }

      .btn.fixed_btn {
        bottom: 0;
        margin: 2rem 0 1rem;
      }
    }
  }

  @media screen and (min-width: 1536px) and (max-height: 863px) {
    & {
      .inner-header {
        width: 650px;
        h2 {
          font-size: 1.5rem;
        }
      }

      #student-register {
        max-width: 1000px;
        tbody {
          tr {
            td {
              .ant-form-item {
                margin-right: 4rem;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1536px) {
    height: auto;
    max-height: 100%;
    margin: 3rem auto;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    height: calc(60vh - 3rem);
    margin: 5rem auto;

    & {
      .inner-header {
        width: 95%;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    height: 700px;
    margin: 5rem auto;
  }

  @media screen and (max-width: 1366px) {
    height: auto;
    // min-height: 700px;
    //  margin: 5rem auto;
    margin: 2rem auto 0;
    padding: 1rem 1rem;
    width: 600px;

    & {
      .inner-header {
        width: 100%;
        margin: 1rem auto;
        //  width: 650px;
      }

      .btn.fixed_btn {
        margin: 1rem auto;
        a {
          font-size: 1rem;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    //height: calc(60vh - 3rem);
    height: auto;
    margin: 3rem auto;
  }

  @media (max-width: 1400px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 100%;
    margin: 0 auto;
    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 800px;

        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) and (aspect-ratio: 16 / 10) {
    & {
      .inner-header {
        margin: 1rem 0 1rem;
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1280px) {
    width: 550px;
    min-height: 660px;

    & {
      .btn {
        margin: 1rem auto;
      }
    }
  }

  @media (max-width: 1280px) and (orientation: portrait) {
    // width: 800px;
    height: auto;
    min-height: 700px;
  }

  @media (max-width: 1280px) and (aspect-ratio: 16 / 10) {
    margin: 2rem auto;
    & {
      .btn.fixed_btn {
        bottom: 0;
        padding: 0.8rem 1rem;
        height: auto;
        max-height: 100%;
        margin: 1rem auto;
      }
      .inner-header {
        top: 0;
        width: 100%;
        padding: 0 2rem;
      }
    }
  }

  @media (max-width: 1280px) and (aspect-ratio: 16 / 9) {
    margin: 0rem auto;
    height: auto;

    max-height: 100%;

    & {
      .btn.fixed_btn {
        bottom: 0;
      }
      .inner-header {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 1200px) and (max-height: 800px) {
    & {
      .inner-header {
        top: 0;
        width: 100%;
      }
    }

    &.inner-header {
      width: 100%;
      top: 0;
      h2 {
        font-size: 1.4rem;
      }
      top: 0rem;
    }
  }

  @media (max-width: 1200px) {
    & {
      inner-header {
        width: 65%;
        top: 8rem;

        h2 {
          font-size: 1.3rem;
        }
      }

      .btn.fixed_btn {
      }
    }
  }

  @media (max-width: 1024px) and (aspect-ratio: 16 / 9) {
    width: 100%;
    max-height: 100%;
    margin: 0 auto;

    height: auto;
    & {
      .inner-header {
        width: 100%;
        max-width: 730px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 550px;
    // margin: 2rem auto 0;
  }

  @media (max-width: 1024px) and (orientation: portrait) {
    width: 550px;
    margin: 2rem auto;
  }

  @media screen and (max-width: 1024px) and (orientation: portrait) {
    height: auto;
  }

  @media screen and (max-width: 820px) and (orientation: portrait) {
    width: 550px;
    padding: 1rem 1rem 2rem;
    & {
      .inner-header {
      }
    }
  }

  @media screen and (max-width: 580px) {
    width: 430px;
  }

  @media screen and (max-width: 450px) {
    width: 350px;
    height: auto;
    margin: 2rem auto;
    & {
      .inner-header {
        h2 {
          font-size: 1.2rem;
        }
      }
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }

  @media screen and (max-width: 380px) {
    width: 300px;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 35px;
    & {
      .inner-header {
        h2 {
          font-size: 1.1rem;
        }
      }
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }
`;

// const formItemLayout = {
//   labelCol: {
//     xs: {
//       span: 24,
//     },
//     sm: {
//       span: 8,
//     },
//   },
//   wrapperCol: {
//     xs: {
//       span: 24,
//     },
//     sm: {
//       span: 16,
//     },
//   },
// };

// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 16,
//       offset: 8,
//     },
//   },
// };

const classOptions = {
  creo: ['초등 1학년', '초등 2학년', '초등 3학년', '초등 4학년', '초등 5학년', '초등 6학년'],
  default: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
};

const UserInfoNew = () => {
  const history = useHistory();
  const params = queryString.parse(window.location.search);
  const company_name = params.company ? params.company : 'creo';
  const company_idx = params.company_idx ? params.company_idx : null;
  const [modalVisible, setModalVisible] = useState(company_name === 'creo');
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [fillingError, setfillingError] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const [studentNamePart1, setstudentNamePart1] = useState('');
  const [studentNamePart2, setstudentNamePart2] = useState('');
  const [koreanName, setkoreanName] = useState('');
  const [dobY, setdobY] = useState('');
  const [dobM, setdobM] = useState('');
  const [dobD, setdobD] = useState('');
  const [phone1, setphone1] = useState('');
  const [phone2, setphone2] = useState('');
  const [phone3, setphone3] = useState('');
  const [schoolinfo, setschoolinfo] = useState();
  const [reservedate, setreservedate] = useState('');
  const [reservedateMoment, setreservedateMoment] = useState(null);
  const [campusidx, setcampusidx] = useState('');
  // const [campusname, setcampusname] = useState('');
  const [campusname, setcampusname] = useState('캠퍼스 선택');
  //const [campusname, setcampusname] = useState(61);
  const [dayname, setDayname] = useState('');
  // const [scheduleIdx, setScheduleIdx] = useState('');
  const [scheduleTime, setScheduleTime] = useState('사용 가능한 일정 선택');
  const [moveNext, setMoveNext] = useState(false);
  const [displaySchedule, setDisplaySchedule] = useState(false);
  const [campus, setCampus] = useState('');
  const [fscheduletime, setFscheduleTime] = useState('');
  // const [daywiseslotdata, setDayWiseSlotData] = useState('');

  const classOptionList = classOptions[company_name] || classOptions.default;
  const isWEnglish = company_idx === 1016 ? true : false;

  const { data: dataCampus, loading: loadingCampusList } = useQuery(CAMPUS_SEARCH_LIST, {
    variables: {
      company_name,
      search_type: 'name',
      search_text: company_name === 'creo' ? 'CREO ' : '',
    },
  });

  const { data: campusSchedule } = useQuery(GET_SCHEDULE_OR_NOT, {
    variables: {
      campus_idx: campusname,
      // campus_idx:487
    },
    skip: campusname === '캠퍼스 선택',
  });
  useEffect(() => {
    if (campusSchedule?.getScheduleornot === true || campusSchedule?.getScheduleornot === 'true') {
      setDisplaySchedule(true);
    } else {
      setDisplaySchedule(false);
    }
  }, [campusSchedule]);

  // const [scheduleDetail] = useMutation(SCHEDULE_DATA_ID_WISE, {
  //   onCompleted(data) {
  //     if (data) {
  //         const start_time = data?.getScheduleDataIdWise[0]?.start_time
  //         const end_time = data?.getScheduleDataIdWise[0]?.end_time
  //         const result = start_time  +"-"+   end_time;
  //         setFscheduleTime(result);
  //     } else {
  //       // alert('오류가 발생했습니다.');
  //     }
  //   },
  // });

  // useEffect(()=>{
  //   if(scheduleTime !== '' && scheduleTime !=='사용 가능한 일정 선택'){
  //     console.log("scheduleTime", scheduleTime)
  //     scheduleDetail({
  //       variables: {
  //         schedule_idx: scheduleTime?parseInt(scheduleTime):0,
  //       },
  //     });
  //   }
  // },[scheduleTime])

  // const [campusSchedule] = useMutation(GET_SCHEDULE_OR_NOT, {
  //   onCompleted(data) {
  //     if (data) {
  //       if (data.getScheduleornot == 'true' || data.getScheduleornot === true) {
  //         setDisplaySchedule(true);
  //       } else {
  //         setDisplaySchedule(false);
  //       }
  //     } else {
  //       alert('오류가 발생했습니다.');
  //     }
  //   },
  // });

  // useEffect(() => {
  //   if (campusname !== '캠퍼스 선택' || campusname == ' ') {
  //     campusSchedule({
  //       variables: {
  //         campus_idx: campusname,
  //         // campus_idx:488
  //       },
  //     });
  //   }
  // }, [campusname]);

  useEffect(() => {
    if (daywiseslot?.getDayWiseMetaSchedule.length == 0) {
      setScheduleTime('사용 가능한 일정 선택');
      setMoveNext(false);
    }
  }, [dayname]);

  useEffect(() => {
    if (displaySchedule) {
      if (campusname === '캠퍼스 선택') {
        setCampus('');
      } else {
        setCampus(campusname);
      }
    }
  }, [displaySchedule]);

  // const [daywiseslot] = useMutation(DAY_WISE_SCHEDULE_DATA, {
  //   onCompleted(data) {
  //     if (data) {
  //       setDayWiseSlotData(data)
  //     } else {
  //       alert('오류가 발생했습니다.');
  //     }
  //   },
  // });

  // useEffect(() => {
  //   if ((dayname !== '' || dayname !== undefined) && (campusname !== '캠퍼스 선택' || campusname !== ' ')) {
  //     // console.log("Api call")
  //     daywiseslot({
  //       variables: {
  //         campus_idx: campusname,
  //         day_name: dayname
  //       }
  //     })
  //   }
  // }, [dayname])
  const { data: daywiseslot, refetch } = useQuery(DAY_WISE_SCHEDULE_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: campus,
      // campus_idx: 487,
      date: reservedate,
    },
    skip: dayname === '' || campus === '',
  });

  useEffect(() => {
    if (scheduleTime !== '' && scheduleTime !== '사용 가능한 일정 선택' && campusname !== '캠퍼스 선택') {
      MetaScheduleslot({
        variables: {
          idx: campusname,
          //day_name: dayname,
          schedule_idx: scheduleTime,
          date: reservedate,
        },
      });
    }
  }, [scheduleTime]);

  const [MetaScheduleslot, { loading }] = useMutation(META_EXAM_SCHEDULE_SLOT, {
    onCompleted(data) {
      if (data) {
        console.log('data', data);
        if (data.scheduleMetaExam === true) {
          Modal.error({
            title: 'This time slot alredy exists',
          });
        } else {
          setMoveNext(true);
        }
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];
      Modal.error({
        title: `${message}`,
      });
    },
  });

  const onClose = () => {
    setfillingError(false);
  };
  const onSubmit = () => {
    if (moveNext || !displaySchedule) {
      if (
        !koreanName ||
        !studentNamePart1 ||
        !studentNamePart2 ||
        !dobY ||
        !dobM ||
        !dobD ||
        !phone1 ||
        !phone2 ||
        !phone3 ||
        !reservedate
      ) {
        setfillingError(true);
        seterrorMessage('Please fill all fields');
        return;
      }
      if (dobY < 2000 || isNaN(dobY) || dobY >= 2022) {
        setfillingError(true);
        seterrorMessage('Please enter a valid Year in date of birth');
        return;
      }
      if (isNaN(dobM) || dobM > 12 || dobM < 1) {
        setfillingError(true);
        seterrorMessage('Please enter a valid Month in date of birth');
        return;
      }
      if (isNaN(dobD) || dobD > 31 || dobD < 1) {
        setfillingError(true);
        seterrorMessage('Please enter a valid Day in date of birth');
        return;
      }
      if (isNaN(phone1) || isNaN(phone2) || isNaN(phone3)) {
        setfillingError(true);
        seterrorMessage('Please enter a valid phone number');
        return;
      }
      // if (isNaN(schoolinfo)) {
      //   setfillingError(true);
      //   seterrorMessage('Please enter a valid grade');
      //   return;
      // }

      setfillingError(false);
      seterrorMessage('');
      // var schedule_id;
      // if (scheduleTime === '사용 가능한 일정 선택') {
      //   schedule_id = 0;
      // } else {
      //   schedule_id = scheduleTime;
      // }
      const variables = {
        reserve_date: reservedate,
        student_name: `${studentNamePart1.toLowerCase().trim()} ${studentNamePart2.toLowerCase().trim()}`,
        korean_name: koreanName,
        birth_date: dobY + '-' + ('0' + dobM).slice(-2) + '-' + ('0' + dobD).slice(-2),
        phone: phone1 + phone2 + phone3,
        school_info: schoolinfo,
        //campus_idx: campusidx,
        campus_idx: campusname,
        // campus_idx:488,
        days: dayname,
        schedule_time: fscheduletime ? fscheduletime : '',
        schedule_idx: scheduleTime !== '' && scheduleTime !== '사용 가능한 일정 선택' ? scheduleTime : null,
      };
      localStorage.setItem('meta_create_order', JSON.stringify(variables));
      history.push('/MetaExam/Survey');
    }
  };
  function handleDateChange(date, changeDate) {
    if (campusname === 1310) {
      let allowDate = moment('2024-02-03 00:00:00');

      if (date < allowDate) {
        alert('레벨테스트 예약은 2월3일(토)부터 선택할 수 있습니다. \n 그 이전에 예약하길 원하시면 학원에 문의하시길 바랍니다. ');
        return;
      }
    }

    const dayName = new Date(changeDate).toLocaleString('en-us', { weekday: 'long' });

    console.log('changeDate', changeDate, date, dayName);
    setDayname(dayName);
    setreservedate(changeDate);
    setreservedateMoment(date);
  }
  const handleSubmit = (values) => {
    const variables = {};
    variables['phone'] = '';
    const keys = Object.keys(values);
    for (const key of keys) {
      if (key === 'reserve_date' || key === 'birth_date') {
        variables[key] = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'campus_name') {
        //pass
      } else if (key.includes('phone')) {
        variables['phone'] += values[key];
      } else if (key.includes('student_name_part2')) {
        variables['student_name'] = `${values['student_name'].toLowerCase()} ${values['student_name_part2'].toLowerCase()}`;
      } else {
        variables[key] = values[key];
      }
    }
    localStorage.setItem('meta_create_order', JSON.stringify(variables));
    history.push('/MetaExam/Survey');
  };
  const handleCancelModal = () => {
    setVisible(false);
  };

  const handleChooseCampus = (values) => {
    const { campus_name, campus_idx } = values;
    setcampusidx(campus_idx);
    setcampusname(campus_name);
    setVisible(false);
  };
  useEffect(() => {
    // if(dayname !== ' '){
    if (daywiseslot?.getDayWiseMetaSchedule.length == 0) {
      setScheduleTime('사용 가능한 일정 선택');
    }
    // }
  }, [dayname, daywiseslot]);
  return (
    <>
      <div className="meta-close">
        <div style={{ justifyContent: 'start' }}>
          <img src="../../images/meta_exit.png" alt="closeImg" />
        </div>
      </div>
      <div className={isWEnglish ? 'meta-con register-con w-english-bg' : 'meta-con register-con'}>
        {/*< div className="meta-header">
          <div style={{ justifyContent: 'start' }}>
            <h2>홍길동</h2>
          </div>
          <div className="phoneNum">
            <h2>010-1234-5678</h2>
          </div>
        </div> */}
        <div className="meta-header">
          <h1 className="main-tit">{isWEnglish ? '' : 'MetaExam'} 신청</h1>
        </div>
        <InnerWrap>
          <div className="inner-header">
            <h2>학생정보</h2>
            <div className="date-wrap">
              <h2>{moment().format('YYYY.MM.DD')}</h2>
            </div>
          </div>
          <table id="student-register">
            <tbody>
              <tr>
                <td>
                  <Form.Item label="학생이름(한글)">
                    <Input
                      onChange={(e) => {
                        setkoreanName(e.target.value);
                      }}
                      value={koreanName}
                      className="plainInput"
                      placeholder="홍길동"
                    />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item label="학생이름(영문)">
                    <Input
                      onChange={(e) => {
                        setstudentNamePart1(e.target.value);
                      }}
                      value={studentNamePart1}
                      className="partInput"
                      placeholder="Family Name"
                    />
                    <Input
                      onChange={(e) => {
                        setstudentNamePart2(e.target.value);
                      }}
                      value={studentNamePart2}
                      className="partInput"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Item label="학생 생년월일">
                    <Input
                      className="partInput"
                      maxLength="4"
                      placeholder="yyyy"
                      onChange={(e) => {
                        setdobY(e.target.value);
                      }}
                      value={dobY}
                    />
                    <Input
                      className="partInput"
                      maxLength="2"
                      placeholder="mm"
                      onChange={(e) => {
                        setdobM(e.target.value);
                      }}
                      value={dobM}
                    />
                    <Input
                      className="partInput"
                      maxLength="2"
                      placeholder="dd"
                      onChange={(e) => {
                        setdobD(e.target.value);
                      }}
                      value={dobD}
                    />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item label="학부모 연락처">
                    <Input
                      className="partInput"
                      maxLength="3"
                      placeholder="010"
                      onChange={(e) => {
                        setphone1(e.target.value);
                      }}
                      value={phone1}
                    />
                    <Input
                      className="partInput"
                      maxLength="4"
                      placeholder="1234"
                      onChange={(e) => {
                        setphone2(e.target.value);
                      }}
                      value={phone2}
                    />
                    <Input
                      className="partInput"
                      maxLength="4"
                      placeholder="5678"
                      onChange={(e) => {
                        setphone3(e.target.value);
                      }}
                      value={phone3}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Item label="캠퍼스 선택">
                    <Select
                      className="plainInput gradeInput"
                      placeholder="3학년"
                      value={campusname}
                      defaultValue="캠퍼스 선택"
                      onChange={(v) => setcampusname(v)}
                      //onChange={(v) => setcampusname(61)}
                    >
                      {dataCampus?.campusSearchList.map((item) => {
                        return (
                          <>
                            <Option value={item.idx}>{item.name}</Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </td>
                <td>
                  <Form.Item label="학년">
                    <Select className="plainInput gradeInput" placeholder="Select " value={schoolinfo} onChange={(v) => setschoolinfo(v)}>
                      {classOptionList.map((item) => {
                        return (
                          <>
                            <Option value={item}>{item}</Option>
                          </>
                        );
                      })}
                    </Select>
                    <div style={{ 'margin-top': '10px' }}>
                      <span>2025학년도 기준으로 학년을 선택해주세요</span>
                    </div>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Item label="테스트 신청 예약 날짜">
                    <DatePicker
                      onChange={(date, dateString) => {
                        handleDateChange(date, dateString);
                      }}
                      inputReadOnly
                      value={reservedateMoment}
                    />
                  </Form.Item>
                </td>
                {displaySchedule ? (
                  <td>
                    <Form.Item label="시간 선택">
                      <Select
                        className="plainInput gradeInput"
                        placeholder="사용 가능한 일정 선택"
                        value={scheduleTime}
                        defaultValue="사용 가능한 일정 선택"
                        onChange={(v) => setScheduleTime(v)}
                      >
                        {daywiseslot?.getDayWiseMetaSchedule.map((item) => {
                          return (
                            <>
                              <Option value={item.idx}>
                                {item.start_time} to {item.end_time}
                              </Option>
                            </>
                          );
                        })}
                      </Select>
                      {dayname !== ' ' ? (
                        daywiseslot?.getDayWiseMetaSchedule.length == 0 ? (
                          <label style={{ color: 'red', position: 'absolute', width: '100%', left: '0' }} className="alert-label-noTime">
                            예약 가능한 시간이 없습니다. 다른 날짜를 선택하세요.
                          </label>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </Form.Item>
                  </td>
                ) : (
                  ''
                )}
              </tr>
            </tbody>
          </table>
          {/*
          <Form {...formItemLayout} name="student-register" initialValues={{}} scrollToFirstError>
            <div className="userInfo-wrap">
              <div className="divided-form">
                <div className="common-form">
                  <Form.Item label="학생이름(한글)">
                    <Input className="plainInput" placeholder="홍길동" />
                  </Form.Item>
                </div>
                <div className="common-form">
                  <Form.Item label="학생이름(영문)">
                    <Input className="partInput" placeholder="Family Name" />
                    <Input className="partInput" placeholder="First Name" />
                  </Form.Item>
                </div>
              </div>

              <div className="divided-form">
                <div className="common-form threeInput-form">
                  <Form.Item label="학생 생년월일">
                    <Input className="partInput" maxlength="4" />
                    <Input className="partInput" maxlength="2" />
                    <Input className="partInput" maxlength="2" />
                  </Form.Item>
                </div>
                <div className="common-form threeInput-form">
                  <Form.Item label="학부모 연락처">
                    <Input className="partInput" maxlength="3" />
                    <Input className="partInput" maxlength="4" />
                    <Input className="partInput" maxlength="4" />
                  </Form.Item>
                </div>
              </div>

              <div className="divided-form">
                <div className="common-form">
                  <Form.Item label="유치원/학교명">
                    <Input className="plainInput" placeholder="" />
                  </Form.Item>
                </div>
                <div className="common-form">
                  <Form.Item label="학년">
                    <Input className="plainInput" placeholder="" />
                  </Form.Item>
                </div>
              </div>

              <div className="divided-form">
                <div className="common-form">
                  <Form.Item label="응시 예약 날짜">
                    <DatePicker />
                    <DatePicker  onChange={(date, dateString) => {
                        handleDateChange(date, dateString);
                      }}  inputReadOnly />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>*/}
          {fillingError && (
            <Alert closable style={{ position: 'absolute', bottom: '190px' }} onClose={onClose} message={errorMessage} type="error" />
          )}
          <div
            className="btn fixed_btn"
            //style={{ position: 'fixed' }}
            style={
              !displaySchedule
                ? { position: 'fixed' }
                : moveNext
                ? { position: 'fixed', cursor: 'pointer', opacity: '1' }
                : { position: 'fixed', cursor: 'not-allowed', opacity: '0.6' }
            }
            type="primary"
            onClick={() => {
              onSubmit();
            }}
          >
            <a
              href="javascript:;"
              style={
                !displaySchedule
                  ? { cursor: 'pointer', opacity: '0.6' }
                  : moveNext
                  ? { cursor: 'pointer', opacity: '1' }
                  : { cursor: 'not-allowed', opacity: '1' }
              }
            >
              다음
            </a>
          </div>
        </InnerWrap>
        {!isWEnglish && (
          <div className="report-footer-con">
            <h5>
              Powered by {'     '}
              <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
              </span>
            </h5>
            <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
          </div>
        )}
      </div>
      <SearchCampusModal
        company={params?.company ? params?.company : 'creo'}
        visible={visible}
        onCancel={handleCancelModal}
        onChoose={handleChooseCampus}
      />
      <Modal
        visible={modalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setModalVisible(false)}
        autoFocusButton="ok"
        closeIcon={null}
        closable={false}
      >
        <div className="modal-content">
          <div>CREO어학원 레벨테스트는 {isWEnglish ? '' : 'Meta '}Exam으로 진행됩니다.</div>
          <div>{isWEnglish ? '' : 'Meta '}Exam은 Lexile 인증 + 영어 진단 평가입니다.</div>

          <div>미국 학년 수준(AGL)과 유럽 평가 기준(CEFR)을 확인할 수 있는 테스트입니다. </div>
          <div>테스트는 CREO어학원 내 컴퓨터로 진행됩니다.</div>
          <br />
          <br />
          <div>Vocabulary : 20 문항 (10분)</div>
          <div>Reading : 30 문항 (20분)</div>
          <div>Listening : 20 문항 ( 15분)</div>
          <div>Grammar : 20 문항 (15분)</div>
          <br />
          <div>객관식 총 90 문항 (총 60분)</div>
        </div>
      </Modal>
    </>
  );
};

export default UserInfoNew;
