import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { getLixileScoreObject } from 'src/utils/index';
import LevelUpLibraryMenuGroup from 'src/components/common/LevelUpLibraryMenuGroup';
import { useSelector } from 'react-redux';
import { externalBookFavStoreData } from 'src/operations/store';
import _ from 'lodash';
import Carousels from 'src/components/LevelUp/Carousel';
import { EXTERNAL_STUDY_BOOK } from 'src/operations/queries/externalStudy';
import { useQuery } from '@apollo/client';

const { Carousel } = Carousels;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0 0 13px 13px;
  padding: 0 15px 25px;
  background-color: #ffffff;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
`;
const MainHead = styled.div`
  border-bottom: 1px solid #eae8e3;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 15px 10px;
  margin-bottom: 15px;
  font-size: 22px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainHeadBox = styled.div`
  display: flex;
  flex-direction: row;
`;
const SearchInput = styled(Input)`
  height: 35px;
  width: 150px;
  border-radius: 18px;
  border: 1px solid #dcd7cc;
  :hover {
    border: 1px solid #dcd7cc;
  }
`;
export default function FavoriteBookPage() {
  const externalBookFav = useSelector(externalBookFavStoreData);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [fetchBook, setFetchBook] = useState([]);
  const [error, setError] = useState(null);
  const [likeBookList, setLikeBookList] = useState();

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = (e) => {
    console.log(searchValue);
  };
  const setMxLevel = (lexile) => {
    const mx = getLixileScoreObject(lexile, 'A-1');
    return mx;
  };
  const setLexileValue = (lexile) => {
    if (lexile.includes('BR')) {
      return 0;
    } else if (lexile.includes('NP')) {
      return 1000;
    } else {
      return parseInt(lexile);
    }
  };
  const {
    data: userBook,
    loading,
    refetch,
  } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      type: 'levelup',
      is_fav: true,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    try {
      let list = userBook?.getExternalUserBook?.map((e, i) => {
        e.no = i + 1;
        e.lexileVal = !_.isNil(e.lexile_val) ? e.lexile_val : setLexileValue(e.lexile);
        e.mxObj = setMxLevel(e.lexileVal);
        e.level = e.mxObj?.mx;
        return e;
      });

      setFetchBook(list);
    } catch (e) {
      setError(e);
    }
  }, [userBook]);

  const books = useMemo(() => {
    if (loading) {
      return [];
    }

    let list = fetchBook?.filter((e, i) => e.is_fav);

    return searchValue !== '' ? list?.filter((item) => item.title.toLowerCase().includes(searchValue.toLowerCase())) : list;
  }, [fetchBook, searchValue, loading]);

  return (
    <Wrapper>
      {/* <Header>
        <div
          onClick={() => {
            history.push('/level-up');
          }}
          style={{ cursor: 'pointer' }}
        >
          {' '}
          <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} alt="logo" />
        </div>
        <HeadBox background="transparent" color={'#fff'} style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px' }}>
          <CloseButton
            onClick={() => {
              history.push('/level-up');
            }}
          >
            <CloseOutlined />
          </CloseButton>
        </HeadBox>
      </Header> */}
      <MainWrapper>
        <LevelUpLibraryMenuGroup currentMenu={'favorite'} />
        <Main>
          <MainHead>
            <MainHeadBox>총 {books?.length > 0 ? books?.length : '0'}권</MainHeadBox>
            <MainHeadBox style={{ fontSize: '15px', lineHeight: '30px' }}>
              <SearchInput
                onKeyUp={handleInputChange}
                allowClear
                bordered={false}
                placeholder="Search"
                suffix={<SearchOutlined onClick={handleSearch} style={{ border: 'none' }} />}
              />
            </MainHeadBox>
          </MainHead>
          {books?.length > 0 ?
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Carousel books={books?.length > 0 ? books : []} row={5} column={2} handleFav={refetch} />
          </div>:<div style={{width:'100%', textAlign:'center',fontSize:'25px',lineHeight:2}}>No Favorite Books!</div>}
        </Main>
      </MainWrapper>
    </Wrapper>
  );
}
