import 'react-app-polyfill/ie11';
import React, { useRef, useState } from 'react';
import { message } from 'antd';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import { userInfoVar } from 'src/apollo/cache';
import { CREATE_EXTERNAL_USER_STUDY } from 'src/operations/mutations/externalUserStudy';
import Epub from 'epubjs/lib/index';
import LevelUpFinishPopUp from 'src/components/LevelUp/FinishPopUp';
import _ from 'lodash';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

const ArrowButton = styled.button`
  display: inline-block;
  width: 45px;
  height: 50px;
  font-size: 40px;
  font-weight: bolder;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 999;
`;

const LeftArrow = styled(ArrowButton)`
  position: absolute;
  left: 1%;
  top: 50%;
  background-size: 45px 50px;
  background-repeat: no-repeat;
  background-image: url('/images/LevelUp/study/main/read_arrow_left.png');
  /* filter: opacity(50%); */
`;

const RightArrow = styled(ArrowButton)`
  position: absolute;
  right: 1%;
  top: 50%;
  background-size: 45px 50px;
  background-repeat: no-repeat;
  background-image: url('/images/LevelUp/study/main/read_arrow_right.png');
  /* filter: opacity(50%); */
`;
const ControlBox = styled(RowFlex)`
  height: 55px;
  width: 580px;
  border-radius: 23px;
  background-color: #f9b900;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 0 10px;
`;

const XButton = styled.div`
  position: absolute;
  right: 2%;
  top: 80px;
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
  float: right;
`;

const EpubReader = ({ location }) => {
  const { state } = location;
  const { id } = useParams();
  const [isAtEnd, setIsAtEnd] = React.useState(false); // [1
  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const renditionRef = useRef(null);
  const history = useHistory();
  const epubUrl = `/cdn/LEVELUP/extracted-books/${id}/OEBPS/package.opf`;

  React.useEffect(() => {
    if (epubUrl) {
      var book = new Epub(epubUrl);
      renditionRef.current = book.renderTo('area', {
        widht: '100%',
        height: '100%',
        allowScriptedContent: true,
        allowPopups: true,
        resizeOnOrientationChange: true,
      });

      renditionRef.current.on('rendered', (section) => {
        console.log('rendered', section);
        setIsLoading(false);
      });
      renditionRef.current.on('displayError', (error) => {
        console.log(error);
      });

      renditionRef.current.on('locationChanged', (location) => {
        console.log('locationChanged', location);
      });
      renditionRef.current.display();
    }
  }, [epubUrl]);

  const [createExternalUserStudy, { loading: loadingSave }] = useMutation(CREATE_EXTERNAL_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const onClose = async (finished) => {
    await renditionRef?.current?.destroy();
    finished ? setIsFinished(true) : history.push('/level-up');
  };

  const gotoNext = async (data) => {
    if (!state?.bookData) {
      history.goBack();
      return;
    }
    const external_user_study_input = {
      user_idx: !_.isNil(userInfoVar()?.idx) ? parseInt(userInfoVar()?.idx) : 0,
      external_study_idx: state?.bookData?.book_no,
      // assigned_idx: parseInt(assignedIdx),
      type: 'levelup',
      // lesson_code: lessonCode,
      // stage_no: parseInt(stageNumber),
      article_data: state?.bookData || null,
      exam_total: 100,
      exam_correct: 100,
      stage_no: 2,
      stage_answers: JSON.stringify(data?.stage_answers || []),
    };

    if (state?.bookData?.assignedIdx) {
      external_user_study_input['assigned_idx'] = parseInt(state?.bookData?.assignedIdx);
    }

    await createExternalUserStudy({
      variables: { external_user_study_input },
    });

    onClose(true);
  };

  const handlePrevPage = () => {
    setIsLoading(true);
    if (renditionRef?.current?.location?.start) {
      setIsAtEnd(false);
    }
    try {
      renditionRef?.current && renditionRef.current.prev();
    } catch (e) {
      console.log(e);
    }
  };

  const handleNextPage = () => {
    console.log(renditionRef?.current?.location);
    setIsLoading(true);
    if (renditionRef?.current?.location?.atEnd) {
      setIsAtEnd(true);
    }

    try {
      renditionRef?.current && renditionRef.current.next();
    } catch (e) {
      console.log(e);
    }
  };

  const handleEnd = () => {
    message.success('마지막 페이지입니다.');
    gotoNext({
      stage_answers: [],
    });
  };
  return (
    <>
      {isFinished ? (
        <LevelUpFinishPopUp idx={id} assigned_idx={parseInt(state?.bookData?.assignedIdx)} is_voca={state?.bookData.is_voca} />
      ) : (
        <Wrapper>
          <RowFlex>
            <div>
              <LeftArrow onClick={handlePrevPage} />
            </div>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div id="area" style={{ width: '160vh', height: '90vh', backgroundColor: '#fff' }}></div>
            </div>
            {isLoading ? (
              <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
                <img src="/images/loading.gif" alt="loading" />
              </div>
            ) : null}
            <div>
              <XButton onClick={() => onClose(false)} />
              <RightArrow
                onClick={() => {
                  if (isAtEnd) {
                    handleEnd();
                  } else {
                    handleNextPage();
                  }
                }}
              />
            </div>
          </RowFlex>
        </Wrapper>
      )}
    </>
  );
};

export default withRouter(EpubReader);
