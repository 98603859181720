import React, { useMemo, useState } from 'react';
import { Col, Row, Radio, Button, Dropdown, Menu } from 'antd';
import VolumeTab, { useVolumeTab } from './VolumeTabWinter';
import styled from 'styled-components';
import {
  BookCard,
  BookCover,
  BookMeta,
  BOOK_COL_STYLE,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  THUMBNAIL_BASE_URL,
  CREO_PPT_CDN_BASE_URL,
} from './CommonComp';

const CreoBookCon = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
`;
const CreoTitWrap = styled.div`
  height: 100%;
  background: #f0f3f9;
  display: flex;
`;
const BooksTitle = styled.div`
  width: 8rem;
  font-size: 1rem;
  margin-bottom: 0;
  padding: 1rem 0.5rem 2rem;
  margin: auto 0;
  text-align: center;
  @media (max-width: 1890px) {
    font-size: 0.8rem;
    width: 4vw;
  }
`;
const CardCon = styled.div`
  display: flex;
  /* width: 400px; */
  text-align: center;
  flex-direction: column;
  /* background-color: blue; */
`;
const SectionTitle = styled.h2`
  font-size: 1.05rem;
  margin: 0.5rem 0 0.5rem;
`;
const BookCardWrap = styled.div`
  display: flex;
  /* background-color: red; */
  border-bottom: 1px solid rgb(222, 222, 222);
  border-top: 0.5px solid rgb(222, 222, 222);
  /* width: 100%; */
  /* height: 80%; */
  height: 100%;
  align-items: center;

  @media screen and (max-width: 1024px) {
    & {
      flex-direction: column;
    }
  }
`;
const UnitWrap = styled.div`
  border: 1px solid #929982;
  width: 100%;
  margin: 0.7rem auto;
  border-radius: 10px;
  position: relative;
  left: 0rem;
  top: 0rem;
`;
const UnitH2 = styled.h2`
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 0.8em;
  transition: all 0.2s ease-in-out;
  color: #404337;

  :hover {
    border-radius: 10px;
    background: #929982;
    color: #fff;
    transition: all 0.2s ease-in-out;
  }
`;
const LEVELS = [1, 2];
const TITLES = ['Nate the Great(and The Musical Note)', 'Fantastic Mr.Fox'];
const SECTION1 = [1, 2];
const SECTION2 = [1];
const handleSetupFileDownload = (volume) => {
  //window.open(`http://admin.smart-eclass.co.kr/include/common/downFile3.php?filename=kids_tap_${volume}.zip`);
};

const WinterBook = () => {
  const { levelNo, onChangeLevelNo } = useVolumeTab();
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }} className="winterbook-con">
      <VolumeTab
        levelNo={levelNo}
        levels={LEVELS}
        titles={TITLES}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />

      {TITLES.filter((title) => (levelNo === 0 ? true : title === levelNo)).map((lv, i) => (
        <Row
          key={`row-${lv}`}
          gutter={[16, 16]}
          style={{
            margin: '0 10px',
            height: '300px',
          }}
          className="winterbook-row"
        >
          {(lv === 'Fantastic Mr.Fox' ? SECTION2 : SECTION1).map((vol) => (
            <Col
              span={8}
              key={`col-${lv}-${vol}`}
              style={{
                display: 'flex',
                border: '1px solid #eee',
              }}
              // className="CAWrap winterbookCAWrap"
            >
              <CreoBookCon>
                <CreoTitWrap>
                  <BooksTitle
                    level={1}
                    style={{
                      display: `${vol === 1 ? ' block' : ' none'}`,
                      width: '100%',
                      fontSize: '1rem',
                      padding: '1rem 0.5rem 2rem 0.5rem',
                      textAlign: 'center',
                    }}
                  >
                    {lv}
                  </BooksTitle>
                </CreoTitWrap>

                <CardCon>
                  <SectionTitle>{`Section ${vol}`}</SectionTitle>
                  <BookCardWrap>
                    <BookCard
                      cover={
                        <BookCover
                          style={{
                            backgroundImage: `url(
                              
                              https://cdn.cloubot.com/CR/cover/WIBC_L${lv === 'Fantastic Mr.Fox' ? 2 : 1}V${vol}.jpg
                              )`,
                          }}
                        />
                      }
                    >
                      {/* <BookMeta description={<EbookLinkGroup level={lv} volume={vol} />} /> */}
                    </BookCard>
                    <UnitGroup section={vol} num={lv === 'Fantastic Mr.Fox' ? 4 : 2} />
                  </BookCardWrap>
                </CardCon>
              </CreoBookCon>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default WinterBook;
const UnitGroup = ({ section, num }) => {
  return (
    <BookCard>
      {Array(num)
        .fill(0)
        .map((_, key) => {
          const lesson = key + 1;
          return (
            <UnitWrap className="unitunit" key={`lesson-list${key}`}>
              <UnitH2
                onClick={() => {
                  window.open(`https://cdn.cloubot.com/ppt/CR/CREO_WIBC_${num / 2}-${section}_U${lesson}L1/index.html`);
                }}
              >
                UNIT {lesson}
              </UnitH2>
            </UnitWrap>
          );
        })}
    </BookCard>
  );
};

const EbookLinkGroup = ({ level, volume }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, paddingBottom: 5 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          //window.open(`${SMART_ECLASS_CDN_BASE_URL}common/smarteclass/Smart_E_class_Level_${level}-${volume}_teacher_ppt.zip`);
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.4em', lineHeight: '1' }}
      >
        PPT
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          //const culp_token = window.localStorage.getItem('culp_token');
          //window.open(`${STUDY_TOOL_HOST}/SM-L${level}V${volume}U1D1?token=${culp_token}`, '_blank');
          alert('준비중입니다.');
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.6em' }}
      >
        E-learning
      </Button>
    </div>
  );
};
