import React, { useState, useMemo, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Row, Typography, Select, Anchor, Button, DatePicker, Modal, Table } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { koreanNumberFormat } from 'src/utils';
//import imagesearch from 'src/images/search.png';
import imageprinter from 'src/images/printer.png';
import imageexcel from 'src/images/xlsicon.png';
import BookOrderMenuGroup from 'src/components/common/BookOrderMenuGroup';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import SmartEclassInvoiceModal from 'src/components/Modal/order/SmartEclassInvoiceModal';
import CampusInvoiceModal from 'src/components/Modal/order/CampusInvoiceModal';
import { MAX_SEARCH_INTERVAL_DAYS, DATE_SEARCH_OPTIONS } from 'src/constants/common';
import { MY_ORDER_LIST, MY_ORDER_LIST_BY_DURATION } from 'src/operations/queries/pay';
import { DELETE_ORDERS } from 'src/operations/mutations/order';
import { DELIVERY_STATUS_REG, DELIVERY_STATUS_COMPLETE, DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_CAMPUS } from 'src/constants/common';
import StatusButton from 'src/pages/Order/comp/StatusButton';
import DeliveryTrackingModal from 'src/components/Modal/order/DeliveryTrackingModal';
import { ExportExcel } from 'src/utils/index';
import { useReactToPrint } from 'react-to-print';
import { PrintDummy } from 'src/components/common/Styles';
import { isAfterSchool,isPturn, isLucid } from 'src/utils';

const { RangePicker } = DatePicker;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const columns = [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '주문 날짜',
    dataIndex: 'order_date',
    key: 'order_date',
  },
  {
    title: '결제 날짜',
    dataIndex: 'pay_date',
    key: 'pay_date',
  },
  {
    title: '총 구매 수',
    dataIndex: 'order_count',
    key: 'order_count',
  },
  {
    title: '구매 금액',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => `${koreanNumberFormat(amount)} 원`,
  },
  {
    title: '주문조회',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (_, { receipt_url, invoice_info, onViewInvoice, delivery_status }) => (
      <StatusButton
        delivery_status={delivery_status}
        onClick={() => {
          onViewInvoice(invoice_info);
        }}
      >
        {DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_CAMPUS[delivery_status]}
      </StatusButton>
    ),
  },
  {
    title: '배송정보',
    dataIndex: 'delivery',
    key: 'delivery',
    render: (_, record) => {
      const delivery_status = record?.invoice_info?.order_payment?.delivery_status;
      // const invoice_number = record?.invoice_info?.order_payment?.invoice_number;
      // const company_code = record?.invoice_info?.order_payment?.delivery_company_info?.company_code;
      const { onViewDeliveryTracking, invoice_info } = record;

      return (
        <Button
          disabled={delivery_status !== DELIVERY_STATUS_COMPLETE}
          size="small"
          type="primary"
          onClick={() => {
            onViewDeliveryTracking(invoice_info);
          }}
        >
          배송정보
        </Button>
      );
    },
  },
];

const columnsForSmartEclass = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '주문 날짜',
    dataIndex: 'order_date',
    key: 'order_date',
    width: '110px',
  },
  {
    title: '주문 상품',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: '주문자',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: '총 구매 수',
    dataIndex: 'order_count',
    key: 'order_count',
  },
  {
    title: '신청 학교',
    dataIndex: 'school_name',
    key: 'school_name',
  },
  {
    title: '소속 지사',
    dataIndex: 'branch',
    key: 'branch',
  },
  {
    title: '주문조회',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (_, { onViewInvoice, invoice_info, delivery_status }) => (
      <StatusButton
        delivery_status={delivery_status}
        onClick={() => {
          onViewInvoice(invoice_info);
        }}
      >
        {DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_CAMPUS[delivery_status]}
      </StatusButton>
    ),
  },
  {
    title: '배송정보',
    dataIndex: 'delivery',
    key: 'delivery',
    render: (_, record) => {
      const delivery_status = record?.invoice_info?.order_payment?.delivery_status;
      // const invoice_number = record?.invoice_info?.order_payment?.invoice_number;
      // const company_code = record?.invoice_info?.order_payment?.delivery_company_info?.company_code;
      const { onViewDeliveryTracking, invoice_info } = record;

      return (
        <Button
          disabled={delivery_status !== DELIVERY_STATUS_COMPLETE}
          size="small"
          type="primary"
          onClick={() => {
            onViewDeliveryTracking(invoice_info);
          }}
        >
          배송정보
        </Button>
      );
    },
  },
];

const { Title } = Typography;
const { Option } = Select;
const DEFAULT_DATE_RANGE = [moment().subtract(1, 'months'), moment()];

const BookOrderList = () => {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  const printRef = useRef();

  const [dateSearchOption, setDateSearchOption] = useState(DATE_SEARCH_OPTIONS[0].value);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);

  const [invoiceVisible, setInvoiceVisible] = useState(false);
  const [deliveryTrackingVisible, setDeliveryTrackingVisible] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useState();

  //스마트이클래스에서만 체크 박스사용함.
  const [checkRowList, setcheckRowList] = useState([]);
  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
    getCheckboxProps: ({ key, invoice_info }) => {
      const {
        order_payment: { delivery_status },
      } = invoice_info;
      return {
        disabled: delivery_status === DELIVERY_STATUS_REG ? false : true,
        key,
      };
    },
  };

  const { data, loading, refetch } = useQuery(dateSearchOption === 0 ? MY_ORDER_LIST : MY_ORDER_LIST_BY_DURATION, {
    variables:
      dateSearchOption === 0 ? { ym: selectedMonth } : { start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD') },
    fetchPolicy: 'no-cache',
  });

  const [deleteOrders, { loadingDelete }] = useMutation(DELETE_ORDERS, {
    refetchQueries: [
      {
        query: dateSearchOption === 0 ? MY_ORDER_LIST : MY_ORDER_LIST_BY_DURATION,
        variables:
          dateSearchOption === 0
            ? { ym: selectedMonth }
            : { start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD') },
      },
    ],
    onCompleted: () => {
      Modal.info({ title: '삭제했습니다.' });
      setcheckRowList([]);
      refetch();
    },
    onError: (e) => {
      console.log(e);
      alert('오류가 발생했습니다.');
    },
  });

  const dataSource = useMemo(() => {
    const list = data?.MyOrderList || data?.MyOrderListByDuration;
    if (list) {
      return list.map((item, key) => {
        const amount = item?.order_payment[0]?.amount;
        const pay_date = item?.order_payment[0]?.idate;
        const receipt_url = item?.order_payment[0]?.receipt_url;
        const order_count = item?.order_list?.reduce((a, c) => a + parseInt(c.quantity), 0) || 0;

        return {
          key: `order-list-${item.idx}`,
          no: list.length - key,
          product_name: item?.order_payment[0]?.product_name,
          user_name: item?.order_payment[0]?.buyer_name,
          school_name: userInfoVar()?.campus?.name,
          branch: item?.campus?.type === '2' ? userInfoVar()?.campus?.name : item?.campus?.campus?.name,
          order_date: moment(item.idate).format('YYYY-MM-DD'),
          pay_date: moment(pay_date).format('YYYY-MM-DD'),
          order_count,
          amount,
          receipt_url,
          onViewInvoice: handleViewInvoice,
          onViewDeliveryTracking: handleViewDeliveryTracking,
          invoice_info: {
            //campus_name: item?.campus?.name,
            //delivery_price: item?.order_payment[0]?.delivery_price,
            order_list: item?.order_list,
            idate: item?.idate,
            //amount,
            order_payment: item?.order_payment[0],
          },
          delivery_status: item?.order_payment[0]?.delivery_status,
        };
      });
    }
    return [];
  }, [data]);
  const totalAmount = useMemo(() => {
    if (dataSource && dataSource.length > 0) {
      return dataSource.reduce((a, c) => a + c?.amount || 0, 0);
    }
    return 0;
  }, [dataSource]);

  function handleViewInvoice(info) {
    setInvoiceInfo(info);
    setInvoiceVisible(true);
  }
  function handleViewDeliveryTracking(info) {
    setInvoiceInfo(info);
    setDeliveryTrackingVisible(true);
  }
  function handleDisabledDate(current) {
    return current && current > moment().endOf('day');
  }

  function handleDelete() {
    if (checkRowList.length === 0) {
      Modal.info({ title: '삭제할 항목을 선택하세요.' });
      return;
    }
    Modal.confirm({
      title: '정말 삭제하시겠습니까?',
      onCancel: () => {
        //
      },
      onOk: () => {
        const idx_list = checkRowList.map((item) => {
          const [, , idx] = item.split('-');
          return parseInt(idx);
        });

        deleteOrders({ variables: { idx_list } });
      },
    });
  }
  function handleExportToExcel(e) {
    e.preventDefault();
    const realColumns = (isPturn(companyName) || isLucid(companyName))? columnsForSmartEclass : columns;
    const fileName = `주문내역`;
    ExportExcel(realColumns, dataSource, fileName);
  }
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const type = userInfoVar()?.type;
  if (isPturn(companyName)) {
    if (type && ![1, 2, 3].includes(type)) {
      history.replace('/');
    }
  } else {
    if (type && type !== 2) {
      history.replace('/');
    }
  }

  return (
    <>
      <Col className="orderadmin-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <Row gutter={[24, 16]}>
          <Col className="orderadmin-wrapper-main-a" span={8} style={{ textAlign: 'left' }}>
            {/* <HeaderTitle level={4}>Purchase Invoice(주문 관리)</HeaderTitle> */}
            <BookOrderMenuGroup currentMenu="book-order-list" />
          </Col>
          <Col className="orderadmin-imagesec" span={16} style={{ textAlign: 'left' }}>
            <Anchor>
              {/* <Link>
                <img src={imagesearch} alt="search" />
              </Link> */}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                <img src={imageprinter} alt="print" />
              </a>
              <a href="/" onClick={handleExportToExcel}>
                <img src={imageexcel} alt="excel" />
              </a>
            </Anchor>
          </Col>
        </Row>
        <MainBlock className="orderadmin-wrapper-mainblock">
          <Row gutter={[24, 16]} className="orderadmin-top-head">
            <Col className="orderadmin-top-title" span={8} style={{ textAlign: 'left' }}>
              <Title level={5}>주문 내역</Title>
            </Col>
            <Col className="orderadmin-top-rightform" span={16} style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}>
              <div className="purchase-select-option">
                {(isPturn(companyName) || isLucid(companyName)) && (
                  <>
                    <Button type="danger" style={{ background: 'red' }} onClick={handleDelete} loading={loadingDelete}>
                      삭제
                    </Button>
                    &nbsp; &nbsp;
                  </>
                )}
                <Select placeholder="선택" value={dateSearchOption} onChange={setDateSearchOption}>
                  {DATE_SEARCH_OPTIONS.map((item) => (
                    <Option key={`search-month-option${item.value}`} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                {dateSearchOption === 0 ? (
                  <DatePicker
                    onChange={(m) => {
                      setSelectedMonth(m.format('YYYY-MM'));
                    }}
                    picker="month"
                    value={moment(`${selectedMonth}-01`)}
                    allowClear={false}
                  />
                ) : (
                  <RangePicker
                    value={dateRange}
                    allowClear={false}
                    disabledDate={handleDisabledDate}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      const intervalDays = end.diff(start, 'days');
                      if (intervalDays > MAX_SEARCH_INTERVAL_DAYS) {
                        alert('검색 기간은 최대 3개월입니다.');
                        return false;
                      }
                      setDateRange(dates);
                    }}
                  />
                )}
              </div>
              {!isAfterSchool(companyName) && !isLucid(companyName) && (
                <div style={{ marginLeft: 10 }}>
                  <label>총 구매 금액 :</label>
                  <input type="text" style={{ textAlign: 'right' }} readOnly value={koreanNumberFormat(totalAmount)} /> 원
                </div>
              )}
            </Col>
          </Row>

          <CustomTable
            className="orderadmin-table-table"
            loading={loading}
            pagination={{ pageSize: 10, position: ['bottomCenter'] }}
            dataSource={dataSource}
            columns={isPturn(companyName) || isLucid(companyName) ? columnsForSmartEclass : columns}
            size="small"
            color="#edf3fb"
            scroll={{ y: 'calc(100vh - 254px)' }}
            rowSelection={(isPturn(companyName) || isLucid(companyName)) && rowSelection}
          />

          <PrintDummy ref={printRef}>
            <Table
              pagination={false}
              dataSource={dataSource}
              columns={(isPturn(companyName) || isLucid(companyName)) ? columnsForSmartEclass : columns}
              size="small"
            />
          </PrintDummy>
        </MainBlock>
      </Col>
      {isAfterSchool(companyName) || isLucid(companyName) ? (
        <SmartEclassInvoiceModal
          visible={invoiceVisible}
          info={invoiceInfo}
          onCancel={() => {
            setInvoiceVisible(false);
          }}
        />
      ) : (
        <CampusInvoiceModal
          visible={invoiceVisible}
          info={invoiceInfo}
          onCancel={() => {
            setInvoiceVisible(false);
          }}
        />
      )}

      <DeliveryTrackingModal
        visible={deliveryTrackingVisible}
        info={invoiceInfo}
        onCancel={() => {
          setDeliveryTrackingVisible(false);
        }}
      />
    </>
  );
};

export default BookOrderList;
