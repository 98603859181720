import React, { useEffect, useState } from 'react';
import { Input, Form, DatePicker, Typography, Radio, Select } from 'antd';
import FormField from 'src/components/common/FormField';
import moment from 'moment';
// import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { SurveyWrapper } from 'src/popups/LevelTestAppForm/LevelTestStyledComp';
import { userInfoVar } from 'src/apollo/cache';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
// import { GET_SCHEDULE_OR_NOT } from 'src/operations/mutations/schedule';
import { DAY_WISE_SCHEDULE_DATA, GET_SCHEDULE_OR_NOT } from 'src/operations/queries/getScheduleData';

const { Title } = Typography;
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};
const { Option } = Select;

// const checkboxLearningOptions = [
//   { label: '혼자 학습하는 것을 선호한다.', value: '1' },
//   { label: '그룹으로 학습하는 것을 선호한다.(경쟁심 동기부여)', value: '2' },
//   { label: '학습을 자기 주도적으로 한다.', value: '3' },
//   { label: '차분하고 꼼꼼한 성격이다.', value: '4' },
//   { label: '의욕은 있지만 꼼꼼하지 못하다.', value: '5' },
//   { label: '외향적인 성격으로 발표하는 것을 좋아한다.', value: '6' },
//   { label: '내성적인 성격으로 친구들 앞에서 이야기 하는 것을 어려워한다.', value: '7' },
// ];
const classOptions = {
  creo: ['초등 1학년', '초등 2학년', '초등 3학년', '초등 4학년', '초등 5학년', '초등 6학년'],
  default: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
};

const CreateMetaExamForm = ({ form, onCreate }) => {
  const defaultClass = useSelector(classStoreData);
  // const history = useHistory();
  const params = queryString.parse(window.location.search);
  const company_name = params.company ? params.company : 'creo';

  const [displaySchedule, setDisplaySchedule] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(-1);
  const [dayname, setDayname] = useState('');
  const [campus_id, setCampusId] = useState();
  const [schoolinfo, setschoolinfo] = useState();
  const [value1, setValue1] = useState('');
  const classOptionList = classOptions[defaultClass] || classOptions.default;
  useEffect(() => {
    const { campus_idx } = userInfoVar();
    form.setFieldsValue({ campus_idx });
  }, [form]);
  const onChangeVal1 =(e)=>{
    setValue1(e.target.value)
  }
  useEffect(()=>{
    if(value1==='2'){
      form.setFieldsValue({survey2:'4',survey3:'0',survey4:'0'})
    }
    if(value1==='1'){
      form.setFieldsValue({survey2:undefined,survey3:undefined,survey4:undefined})
    }
  },[value1])
  useEffect(() => {
    const { campus_idx } = userInfoVar();
    setCampusId(campus_idx);
  }, []);
  const { data: campusSchedule } = useQuery(GET_SCHEDULE_OR_NOT, {
    variables: {
      campus_idx: campus_id,
    },
    skip: !campus_id,
  });
  useEffect(() => {
    if (campusSchedule?.getScheduleornot === true || campusSchedule?.getScheduleornot === 'true') {
      setDisplaySchedule(true);
    } else {
      setDisplaySchedule(false);
    }
  }, [campusSchedule]);
  // const [campusSchedule] = useMutation(GET_SCHEDULE_OR_NOT, {
  //   onCompleted(data) {
  //     if (data) {
  //       if (data.getScheduleornot == "true" || data.getScheduleornot === true) {
  //         setDisplaySchedule(true)
  //       } else {
  //         setDisplaySchedule(false)
  //       }
  //     } else {
  //       alert('오류가 발생했습니다.');
  //     }
  //   },
  // });
  const { data: daywiseslot, refetch } = useQuery(DAY_WISE_SCHEDULE_DATA, {
    variables: {
      campus_idx: campus_id,
      // campus_idx: 487,
      // day_name: dayname
      date: dayname,
    },
    skip: !dayname,
  });

  const slots = daywiseslot?.getDayWiseMetaSchedule || [];

  const handleSubmit = (values) => {
    const variables = {};
    const keys = Object.keys(values);
    for (const key of keys) {
      if (key === 'reserve_date' || key === 'birth_date') {
        variables[key] = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'campus_name') {
        //pass
      } else if (key.includes('survey') && values[key] instanceof Array) {
        variables[key] = values[key].join('|');
      } else {
        variables[key] = values[key];
      }
    }
    variables['student_name'] = variables['student_name1'].toLowerCase().trim() + ' ' + variables['student_name2'].toLowerCase().trim();
    console.log(slots);
    console.log(scheduleTime);
    setDayname('');
    if (scheduleTime !== -1) {
      variables['schedule_idx'] = slots[scheduleTime].idx;
      variables['days'] = slots[scheduleTime].days;
    }

    onCreate({ variables });
    return false;
  };

  // useEffect(() => {
  //   if(dayname && campus_id) {
  //       campusSchedule({
  //         variables: {
  //           campus_idx: campus_id
  //         }
  //       })
  //   }else{
  //   }
  // }, [campus_id,dayname])
  function handleDateChange(date, changeDate) {
    const dayName = new Date(changeDate).toLocaleString('en-us', { weekday: 'long' });
    setDayname(changeDate);
  }
  return (
    <Form name="control-ref" layout="inline" form={form} onFinish={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
      <Form.Item
        style={{ display: 'none' }}
        name="campus_idx"
        rules={[
          {
            required: true,
            message: '학원을 선택해 주세요.',
          },
        ]}
      >
        <Input type="hidden" name="campus_idx" />
      </Form.Item>

      <FormField hasFeedback={true} title="학생이름(한글)">
        <Form.Item
          name="korean_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: '학생이름을 입력해 주세요.',
            },
          ]}
        >
          <Input placeholder="학생이름" size="large" name="korean_name"></Input>
        </Form.Item>
      </FormField>

      <FormField hasFeedback={true} title="Family Name">
        <Form.Item
          name="student_name1"
          rules={[
            {
              required: true,
              whitespace: true,
              message: '학생이름을 입력해 주세요.',
            },
          ]}
        >
          <Input placeholder="학생이름" size="large" name="student_name1"></Input>
        </Form.Item>
      </FormField>

      <FormField hasFeedback={true} title="First Name">
        <Form.Item
          name="student_name2"
          rules={[
            {
              required: true,
              whitespace: true,
              message: '학생이름을 입력해 주세요.',
            },
          ]}
        >
          <Input placeholder="학생이름" size="large" name="student_name2"></Input>
        </Form.Item>
      </FormField>

      <FormField hasFeedback={true} title="생년월일">
        <Form.Item
          name="birth_date"
          rules={[
            {
              required: true,
              message: '입력해주세요.',
            },
          ]}
        >
          <DatePicker size="large" name="birth_date" inputReadOnly />
        </Form.Item>
      </FormField>
      {/* <FormField hasFeedback={true} title="학교/학년">
        <Form.Item
          name="school_info"
          rules={[
            {
              required: true,
              whitespace: true,
              message: '입력해 주세요.',
            },
          ]}
        >
          <Input placeholder="학교/학년" size="large" name="school_info" />
        </Form.Item>
      </FormField> */}
      <FormField hasFeedback={true} title="학교/학년">
        <Form.Item name="school_info">
          <Select className="plainInput gradeInput" placeholder="Select " value={schoolinfo} onChange={(v) => setschoolinfo(v)}>
            {classOptionList.map((item, k) => {
              return (
                <Option key={k} value={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </FormField>
      <FormField hasFeedback={true} title="학부모전화">
        <Form.Item
          rules={[
            {
              required: true,
              whitespace: true,
              message: '숫자만 입력해 주세요.',
            },
          ]}
          name="phone"
        >
          <Input type="text" placeholder="숫자만입력(-제외)" size="large" name="phone" maxLength={13} />
        </Form.Item>
      </FormField>
      <FormField hasFeedback={true} title="시험예약일">
        <Form.Item
          name="reserve_date"
          rules={[
            {
              required: true,
              message: '예약날짜를 선택해 주세요.',
            },
          ]}
        >
          <DatePicker
            size="large"
            name="reserve_date"
            onChange={(date, dateString) => {
              handleDateChange(date, dateString);
            }}
            inputReadOnly
          />
        </Form.Item>
      </FormField>
      {displaySchedule ? (
        <FormField hasFeedback={true} title="테스트 신청 예약 날짜">
          <Form.Item className="time-dropdown">
            <Select
              className="plainInput gradeInput"
              placeholder="사용 가능한 일정 선택"
              value={scheduleTime !== -1 ? scheduleTime : '사용 가능한 일정 선택'}
              onChange={(v) => setScheduleTime(v)}
            >
              {slots.map((item, k) => {
                return (
                  <Option key={k} value={k}>
                    {item.start_time} to {item.end_time}
                  </Option>
                );
              })}
            </Select>
            {daywiseslot?.getDayWiseMetaSchedule ? (
              slots.length == 0 ? (
                <label style={{ color: 'red' }}>There is no slot available please choose different date</label>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </Form.Item>
        </FormField>
      ) : (
        ''
      )}

      <SurveyWrapper>
        <Title level={5}>1. 과거에 영어를 배운 경험이 있다.</Title>
        <Form.Item
          name="survey1"
          style={{ width: '90%' }}
          rules={[
            {
              required: true,
              whitespace: true,
              message: '선택해 주세요.',
            },
          ]}
          shouldUpdate={(prevValues, curValues) => prevValues.survey1 === curValues.survey1}
        >
          <Radio.Group name="survey1" onChange={onChangeVal1}>
            <Radio style={radioStyle} value={'1'}>
              있다
            </Radio>
            <Radio style={radioStyle} value={'2'}>
              없다
            </Radio>
          </Radio.Group>
        </Form.Item>

        {value1 === '1' && (
          <>
            <Title level={5}>만약 영어 경험이 있으면</Title>
            <Form.Item
              name="survey2"
              style={{ width: '90%' }}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '선택해 주세요.',
                },
              ]}
            >
              <Radio.Group name="survey2">
                <Radio style={radioStyle} value={'1'}>
                  어학원
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  보습학원
                </Radio>
                <Radio style={radioStyle} value={'3'}>
                  공부방
                </Radio>
                <Radio style={radioStyle} value={'4'}>
                  개인지도
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Title level={5}>만약 영어 경험이 있으면 배운 기간은 :</Title>
            <Form.Item name="survey3">
              <Input placeholder="년" name="survey3" style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}></Input>
            </Form.Item>
            <Form.Item name="survey4">
              <Input placeholder="개월" name="survey4" style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}></Input>
            </Form.Item>
          </>
        )}
        {value1 === '2' && (
          <div style={{display:'none', width:0,height:0}}>
            <Title level={5}>만약 영어 경험이 있으면</Title>
            <Form.Item
              name="survey2"
              style={{ width: '90%' }}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '선택해 주세요.',
                },
              ]}
            >
              <Radio.Group name="survey2">
                <Radio style={radioStyle} value={'4'}>
                  없음
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        )}
        <Title level={5}>2. 학생은 해외 경험이 있다.</Title>
        <Form.Item
          name="survey5"
          style={{ width: '90%' }}
          rules={[
            {
              required: true,
              message: '선택해 주세요.',
            },
          ]}
        >
          <Radio.Group name="survey5">
            <Radio style={radioStyle} value={'1'}>
              있다
            </Radio>
            <Radio style={radioStyle} value={'2'}>
              없다
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Title level={5}>3. 중학교 과정을 배워도 문제 없다.</Title>
        <Form.Item
          name="survey6"
          style={{ width: '90%' }}
          rules={[
            {
              required: true,
              message: '선택해 주세요.',
            },
          ]}
        >
          <Radio.Group name="survey6">
            <Radio style={radioStyle} value={'1'}>
              있다
            </Radio>
            <Radio style={radioStyle} value={'2'}>
              없다
            </Radio>
          </Radio.Group>
        </Form.Item>
      </SurveyWrapper>
    </Form>
  );
};

export default CreateMetaExamForm;
