import React from 'react';
import { Card, Col, Row, Space } from 'antd';

import MyPage from 'src/pages/MyPage';
import { HalfWrapper } from 'src/components/common/Styles';
import { CheckCircleOutlined } from '@ant-design/icons';
import CustomTable from 'src/components/common/CustomTable';
import * as dummy from 'src/dummy';
import CustomButton from 'src/components/common/CustomButton';

const heightStyle = {
  height: 'calc(100vh - 19vh)',
};

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
  },
  {
    title: 'Class',
    dataIndex: 'className',
    key: 'className',
  },
  {
    title: 'Schedule',
    dataIndex: 'schedule',
    key: 'schedule',
  },
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: '',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <a href="#none">
          <CheckCircleOutlined size="Large" />
        </a>
      </Space>
    ),
  },
];

function LevelTest() {
  return (
    <MyPage>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card
            title="공지사항"
            bordered={true}
            bodyStyle={{
              ...heightStyle,
              padding: '0 10px',
            }}
          >
            <div>
              <CustomButton style={{ marginRight: 10 }}>등록</CustomButton>
            </div>
            <CustomTable
              dataSource={dummy.board.classNameList}
              columns={columns}
              pagination={{ pageSize: 12 }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} style={heightStyle}>
            레벨테스트
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={[16, 16]}>
        <Col span={12}>
          <HalfWrapper>레벨</HalfWrapper>
        </Col>
        <Col span={12}>
          <HalfWrapper>테스트</HalfWrapper>
        </Col>
      </Row> */}
    </MyPage>
  );
}

export default LevelTest;
