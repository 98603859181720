import React, { useState, useMemo, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Col, Input, Button, DatePicker, Form, Row, Upload, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import DaumPostcode from 'react-daum-postcode';
import FormField from 'src/components/common/FormField';
import * as queries from 'src/operations/queries';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';
import { UPLOAD_FILE } from 'src/operations/mutations/uploadFileS3';
import { UploadOutlined } from '@ant-design/icons';
import { BOOK_LESSON_DATA } from 'src/operations/queries/book';


const postCodeStyle = {
  display: 'block',
  position: 'absolute',
  top: '30%',
  width: '400px',
  height: '500px',
  padding: '7px',
  zIndex: '1000',
  backgroundColor: '#ccc',
};

const AddLangData = ({ form, onFinish, prefix, level, volume, lessonCode }) => {
  const companyName = useSelector(classStoreData);
  const [upload] = useMutation(UPLOAD_FILE);
  const [uploadfile, setUploadFile] = useState()
  const { data, loading, refetch } = useQuery(BOOK_LESSON_DATA, { variables: { lesson_code: lessonCode } });
  const { Option } = Select;

  const [campus_title, teacher_title] = useMemo(() => {
    if (isAfterSchool(companyName)) {
      return ['학교', '선생님'];
    }
    return ['학원', '원장님'];
  }, [companyName]);
  const handleUplaod = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {
            singleUploadS3: { id, path, filename, mimetype, encoding },
          },
        } = await upload({ variables: { file: data } });
        resolve(id);
        setUploadFile(data.name)
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <Form
      name="control-ref"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 14 }}
      autoComplete="no"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FormItem name="study_data" label={`Select Study Data`} hasFeedback rules={[{ required: true }]}>
            <Select
              style={{ width: '100%', minWidth: 100 }}
              placeholder="Select Study Data"
            >
              {data?.getBookLessonData?.map((ele) => (
                <Option key={ele.idx} value={ele.idx}>
                  {ele.title}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="eng" label={`Eng`} hasFeedback rules={[{ required: true }]}>
            <Input name="eng" />
          </FormItem>

        </Col>
        {/* )} */}
        <Col span={24}>
          <FormItem name="kor" label={`Kor`} hasFeedback rules={[{ required: true }]}>
            <Input name="kor" />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="tag" label={`Tag`} hasFeedback rules={[{ required: false }]}>
            <Input name="tag" />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="eng_slice" label={`Eng Slice`} hasFeedback rules={[{ required: false }]}>
            <Input.TextArea
              name="eng_slice"
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="extra_data" label={`Extra Data`} hasFeedback rules={[{ required: false }]}>
            <Input.TextArea
              name="extra_data"
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormItem name="file" label={`Upload English Audio`} hasFeedback rules={[{ required: true }]}>
            <Upload
              accept="audio/*"
              name="file"
              multiple={false}
              action={handleUplaod}
              listType="text"
              showUploadList={{ showRemoveIcon: false }}
            >
              <Button
                type="primary"
                size="large"
                style={{ background: '#9b0000', marginLeft: 10, border: '1px solid #fff !important' }}
                shape="circle"
                icon={<UploadOutlined />}
              />
            </Upload>
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem name="eng_video" label={`Upload English Video`} hasFeedback rules={[{ required: false }]}>
            <Upload
              accept="video/*"
              name="eng_video"
              multiple={false}
              action={handleUplaod}
              listType="text"
              showUploadList={{ showRemoveIcon: false }}
            >
              <Button
                type="primary"
                size="large"
                style={{ background: '#9b0000', marginLeft: 10, border: '1px solid #fff !important' }}
                shape="circle"
                icon={<UploadOutlined />}
              />
            </Upload>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormItem name="eng_image" label={`Upload Image`} hasFeedback rules={[{ required: false }]}>
            <Upload
              accept="image/*"
              name="eng_image"
              multiple={false}
              action={handleUplaod}
              listType="text"
              showUploadList={{ showRemoveIcon: false }}
            >
              <Button
                type="primary"
                size="large"
                style={{ background: '#9b0000', marginLeft: 10, border: '1px solid #fff !important' }}
                shape="circle"
                icon={<UploadOutlined />}
              />
            </Upload>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name="kor_audio" label={`Upload Korean Audio`} hasFeedback rules={[{ required: false }]}>
            <Upload
              accept="audio/*"
              name="kor_audio"
              multiple={false}
              action={handleUplaod}
              listType="text"
              showUploadList={{ showRemoveIcon: true }}
            >
              <Button
                type="primary"
                size="large"
                style={{ background: '#9b0000', marginLeft: 10, border: '1px solid #fff !important' }}
                shape="circle"
                icon={<UploadOutlined />}
              />
            </Upload>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default AddLangData;
