import React, { useEffect, useState } from 'react';
import { Input, Select, DatePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { phoneNumberCheck } from 'src/utils';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { settings } from 'src/dummy/settings';
import moment from 'moment';

const UpdateTeacherForm = ({ userInfo, onChange, form, onDateChange }) => {
  const [phone, setPhone] = useState(userInfo?.phone ? userInfo?.phone : undefined);
  const company = useSelector(classStoreData);
  const { Option } = Select;

  const handlePhoneNoChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }

    //onChange(e);
  };

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }

    form.setFieldsValue({
      name: userInfo?.name ? userInfo?.name : undefined,
      book_code: '',
      id: userInfo?.id ? userInfo?.id : undefined,
      phone: phone,
      email: userInfo?.email ? userInfo?.email : undefined,
      memo: userInfo?.memo ? userInfo?.memo : undefined,
      address: userInfo?.address ? userInfo?.address : undefined,
      deleted_working_days: userInfo?.deleted_working_days ? userInfo?.deleted_working_days : undefined,
      working_hour: userInfo?.working_hour ? userInfo?.working_hour : undefined,
      account_number: userInfo?.account_number ? userInfo?.account_number : undefined,
      current_salary: userInfo?.current_salary ? userInfo?.current_salary : undefined,
      children: userInfo?.children ? userInfo?.children : undefined,
      report_income: userInfo?.report_income ? userInfo?.report_income : undefined,
      date_of_entry: userInfo?.date_of_entry ? moment(userInfo?.date_of_entry, 'YYYY-MM-DD') : undefined,
      resident_reg_no: userInfo?.resident_reg_no ? userInfo?.resident_reg_no : undefined,
      academic_background: userInfo?.academic_background ? userInfo?.academic_background : undefined,
      marital_status: userInfo?.marital_status ? userInfo?.marital_status : undefined,
      blood_type: userInfo?.blood_type ? userInfo?.blood_type : undefined,
      special_category: userInfo?.special_category ? userInfo?.special_category : undefined,
    });
  }, [form, userInfo?.email, userInfo?.id, userInfo?.memo, userInfo?.name, userInfo?.phone]);

  return (
    <>
      <FormField hasFeedback={true} title="이름">
        <FormItem name="name" rules={[{ required: true, message: '이름을 입력해 주세요.' }]} hasFeedback>
          <Input name="name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="ID">
        <FormItem name="id" rules={[{ required: true, message: '아이디를 입력해 주세요.' }]} hasFeedback>
          <Input name="id" autoComplete="new-password" disabled />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="비밀번호">
        <FormItem
          name="pw"
          rules={[
            {
              required: false,
              message: '비밀번호를 입력해 주세요.',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                  return Promise.resolve();
                } else if (value.length < 4) {
                  return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password name="pw" onChange={onChange} autoComplete="new-password" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="비밀번호 확인">
        <FormItem
          name="confirm"
          dependencies={['pw']}
          hasFeedback
          rules={[
            {
              required: false,
              message: '비밀번호 확인을 입력해 주세요.',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log('value', value);
                console.log("Value('password')", typeof getFieldValue('pw'));
                if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                  return Promise.resolve();
                } else if (value.length < 4) {
                  return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                }
                return Promise.reject('입력하신 비밀번호가 일치하지 않습니다.');
              },
            }),
          ]}
        >
          <Input.Password />
        </FormItem>
      </FormField>

      <FormField title="Email">
        <FormItem name="email" hasFeedback>
          <Input name="email" autoComplete="email" disabled />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="전화번호">
        <FormItem name="phone" rules={[{ required: false }]} hasFeedback>
          <Input name="phone" maxLength={13} onChange={handlePhoneNoChange} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="메모">
        <FormItem name="memo" rules={[{ required: false }]} hasFeedback>
          <Input.TextArea name="memo" />
        </FormItem>
      </FormField>
      {settings[company].showExtraField === 'true' ? (
        <>
          <FormField hasFeedback={false} title="주소">
            <FormItem name="address" rules={[{ required: false }]} hasFeedback>
              <Input.TextArea name="address" />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="근무요일">
            <FormItem name="deleted_working_days" rules={[{ required: false, message: '근무요일' }]} hasFeedback>
              <Input name="deleted_working_days" />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="근무시간">
            <FormItem name="working_hour" rules={[{ required: false, message: '근무시간' }]} hasFeedback>
              <Input name="working_hour" />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="현 급여">
            <FormItem name="current_salary" rules={[{ required: false, message: '현 급여' }]} hasFeedback>
              <Input name="current_salary" />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="입사일">
            <FormItem name="date_of_entry" rules={[{ required: false }]} hasFeedback>
              <DatePicker name="date_of_entry" onChange={(_, d) => onDateChange('date_of_entry', d)} style={{ width: '100%' }} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="주민등록번호">
            <FormItem name="resident_reg_no" rules={[{ required: false, message: '주민등록번호' }]} hasFeedback>
              <Input name="resident_reg_no" />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="계좌번호">
            <FormItem name="account_number" rules={[{ required: false, message: '계좌번호' }]} hasFeedback>
              <Input name="account_number" />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="최종학력(대학명/학과명시)">
            <FormItem name="academic_background" rules={[{ required: false, message: '최종학력(대학명/학과명시)' }]} hasFeedback>
              <Input name="academic_background" />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="결혼유무">
            <FormItem name="marital_status" style={{ width: '100%' }} rules={[{ required: false, message: '결혼유무' }]}>
              <Select
                mode="single"
                showArrow
                allowClear
                name="marital_status"
                size={`default`}
                placeholder="결혼유무 상태"
                style={{ width: '95%' }}
              >
                <Option key="single" value="single">
                  Single
                </Option>
                <Option key="married" value="married">
                  Married
                </Option>
              </Select>
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="자녀">
            <FormItem name="children" rules={[{ required: false, message: '자녀' }]} hasFeedback>
              <Input name="children" />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="혈액형">
            <FormItem name="blood_type" rules={[{ required: false, message: '혈액형' }]} hasFeedback>
              <Input name="blood_type" />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="소득신고방법">
            <FormItem name="report_income" rules={[{ required: false, message: '소득신고방법' }]} hasFeedback>
              <Input name="report_income" />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="특이사항">
            <FormItem name="special_category" rules={[{ required: false, message: '특이사항' }]} hasFeedback>
              <Input name="special_category" />
            </FormItem>
          </FormField>
        </>
      ) : (
        ''
      )}
      {/* <Col span={12}>
        <FormItem
          name="pw"
          rules={[{ required: true }]}
          label={`Password`}
          hasFeedback
          initialValue={userInfo?.pw}
        >
          <Input
            type="password"
            name="pw"
            onChange={onChange}
            autoComplete="current-password"
            disabled
          />
        </FormItem>
      </Col> */}
    </>
  );
};

export default UpdateTeacherForm;
