import React, { useState, useEffect } from 'react';
// import { Button } from 'antd';
import Sound from 'react-sound';
import styled from 'styled-components';
// import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
const PlayButton = styled.button`
  width: 45px;
  height: 45px;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: 45px 45px;
  border: none;
  background-color: transparent;
  filter: opacity(${(props) => (props.disabled ? '10%' : '100%')});
  /* cursor: pointer; */
  /* margin: 0 5px; */
`;
const LevelupAudioPlayer = ({ book, pages, onEnd, onStart, className, isPlaying }) => {
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const [currentPages, setCurrentPages] = useState(pages);
  const [soundIndex, setSoundIndex] = useState(0);
  const [src, setSrc] = useState(null);
  const [autoPlay, setAutoPlay] = useState(false);
  const lastPageChecked = React.useRef(-1);

  const isUrlExist = (url) => {
    if (url) {
      try {
        const http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        return http.status < 400;
      } catch (err) {
        console.log(err);
      }
    }
    return false;
  };

  useEffect(() => {
    if (isPlaying) {
      setAutoPlay(false);
      setPlayStatus(Sound.status.STOPPED);
    }
  }, [isPlaying]);

  useEffect(() => {
    var aSet = new Set(pages);
    var bSet = new Set(currentPages);
    var difference = new Set([...aSet].filter((x) => !bSet.has(x)));
    if (pages && difference.size > 0) {
      setSrc(null);
      setPlayStatus(Sound.status.STOPPED);
      setCurrentPages(pages);
      setSoundIndex(0);
    }
  }, [pages]);

  useEffect(() => {
    if (book && currentPages) {
      const paddedPage = currentPages[soundIndex].toString().padStart(2, '0');
      const url = `/cdn/LEVELUP/book_audio/${book.book_no}/${book.code}_p${paddedPage}.mp3`;

      if (lastPageChecked.current !== currentPages[soundIndex]) {
        lastPageChecked.current = currentPages[soundIndex];
        if (isUrlExist(url)) {
          setSrc(url);
        } else {
          if (soundIndex < currentPages.length - 1) {
            setSoundIndex(soundIndex + 1);
          } else {
            setSrc(null);
            if (soundIndex === currentPages.length - 1 && autoPlay) {
              setPlayStatus(Sound.status.STOPPED);
              if (onEnd instanceof Function) {
                onEnd(true);
              }
            }
          }
        }
      }
    }
  }, [book, currentPages, soundIndex, autoPlay]);

  useEffect(() => {
    console.log('autoPlay', autoPlay, src);
    if (autoPlay && src) {
      setTimeout(() => {
        handlePlay();
      }, 1000);
    }
  }, [autoPlay, src]);

  const handleFinishPlay = () => {
    setPlayStatus(Sound.status.STOPPED);
    if (soundIndex === currentPages.length - 1) {
      if (onEnd instanceof Function) {
        onEnd(false);
      }
    } else {
      setSoundIndex(soundIndex + 1);
      if (!autoPlay) {
        setPlayStatus(Sound.status.PLAYING);
      }
    }
  };

  const handlePlay = () => {
    if (!src) {
      if (soundIndex < currentPages.length - 1) {
        setSoundIndex(soundIndex + 1);
      } else if (soundIndex === currentPages.length - 1) {
        if (onEnd instanceof Function) {
          onEnd(false);
        }
      }

      return;
    }
    if (playStatus === Sound.status.STOPPED) {
      if (onStart instanceof Function) {
        onStart();
      }
      setPlayStatus(Sound.status.PLAYING);
    } else setPlayStatus(playStatus === Sound.status.PLAYING ? Sound.status.PAUSED : Sound.status.PLAYING);
  };

  const handleClick = () => {
    if (playStatus === Sound.status.PLAYING) {
      setPlayStatus(Sound.status.STOPPED);
      setAutoPlay(false);
    } else {
      setAutoPlay(true);
      setSoundIndex(0);
    }
  };

  return (
    <div className={className}>
      <PlayButton
        // type="primary"
        // shape="circle"
        url={
          playStatus === Sound.status.PLAYING
            ? '/images/LevelUp/study/main/full_audio_pause.png'
            : '/images/LevelUp/study/main/full_audio.png'
        }
        onClick={handleClick}
        disabled={isPlaying}
        // style={{ margin: '0 10px' }}
      />

      <Sound url={src ? src : ''} playStatus={playStatus} onFinishedPlaying={handleFinishPlay} onError={handleFinishPlay} />
    </div>
  );
};

export default LevelupAudioPlayer;
