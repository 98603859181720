import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Col, Row, Badge, DatePicker, Radio, Input, Select } from 'antd';
import moment from 'moment';

//import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE } from 'src/operations/queries/externalStudy';
import { userInfoVar } from 'src/apollo/cache';
import NewsOMacticMenuGroup from 'src/components/common/NewsOMacticMenuGroup';
const stageGroupColor = {
  v: '#ffce54',
  s: '#5d9cec',
  e: '#a0d468',
};

const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  {
    key: 'date',
    title: '학습일',
    dataIndex: 'date',
    width: 150,
    align: 'center',
  },
  {
    key: 'class',
    title: 'Class',
    dataIndex: 'class',
    width: 200,
    align: 'center',
  },
  {
    key: 'title',
    title: 'Title',
    dataIndex: 'title',
    align: 'center',
    render: (text, record) => {
      return (
        <>
          <Badge
            className="site-badge-count-109"
            count={record?.stage_group_code?.toUpperCase()}
            style={{ backgroundColor: stageGroupColor[record?.stage_group_code] ? stageGroupColor[record?.stage_group_code] : '#ff0000' }}
          />
          <span>&nbsp;{text}</span>
        </>
      );
    },
  },

  {
    key: 'complete',
    title: '완료/총학생',
    dataIndex: 'complete',
    width: 200,
    align: 'center',
  },
  {
    key: 'view',
    title: '확인',
    dataIndex: 'view',
    width: 200,
    align: 'center',
    render: (_, { class: className, classIdx, lessonCode, title: lessonTitle, idx, type, data_str }) => {
      if (type == 'newsomatic') {
        return (
          <Link to={{ pathname: `/external-study/homework/results/${classIdx}/${idx}`, state: { className, lessonTitle, lessonCode } }}>
            <Button>View</Button>
          </Link>
        );
      }
      if (type == 'newsomatic-assign') {
        return (
          <Link
            to={{
              pathname: `/external-study/homework/multiple/results/${classIdx}/${data_str}/${idx}`,
              state: { className, lessonTitle, lessonCode },
            }}
          >
            <Button>View</Button>
          </Link>
        );
      }
    },
  },
];

const PER_PAGE = 10;
const Learning = () => {
  //const history = useHistory();
  // const [type, setType] = useState('newsomatic-assign');
  const [localState, setLocalState] = UseHistoryState({
    searchDate: '',
    formatSearchDate: '',
    state: 'ing',
    searchClassName: '',
    type: 'newsomatic-assign',
    pageNumber: 0,
  });

  const campus_idx = userInfoVar()?.campus_idx;
  const { data: dataAssignedExternalStudyGroupDetail, loading: grouploadingdetail } = useQuery(ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE, {
    fetchPolicy: 'no-cache',
    variables: {
      start_date: localState.searchDate,
      campus_idx,
      state: localState.state,
      page: localState.pageNumber,
      search_text: localState.searchClassName,
      type: localState.type,
    },
  });
  const assignedExternalStudyHomeWork = useMemo(() => {
    if (
      dataAssignedExternalStudyGroupDetail !== undefined &&
      dataAssignedExternalStudyGroupDetail['assignedExternalStudyDate'] !== undefined
    ) {
      if (dataAssignedExternalStudyGroupDetail['assignedExternalStudyDate']['assigned_external_study'].length > 0) {
        return dataAssignedExternalStudyGroupDetail['assignedExternalStudyDate']['assigned_external_study'].map((item, key) => {
          let userlength = item['external_user_study'].length;

          let assignedLength = item['assigned_external_study_user'].length;

          let completeStudy = item['external_user_study'].map((item, idx) => {
            return item['status'];
          });
          let completeStudentCount = completeStudy.reduce((acc, cur) => {
            return acc + cur;
          }, 0);
          // `${completeStudentCount}/${userlength}`
          let articleData = JSON.parse(item['external_study_data']);

          if (assignedLength) {
            return {
              No: PER_PAGE * localState.pageNumber + key + 1,
              key: `class-lesson-row${item.idx}`,
              idx: item.idx,
              class: item['assigned_external_study_group']['class']['name'],
              lessonCode: 'lessonCode',
              classIdx: item.assigned_external_study_group.class.idx,
              title: articleData['editionId'] ? `Edition: ${moment(articleData['editionId']).format('YYYYMMDD')}` : '',
              date: moment(item.start_date).format('YYYY/MM/DD'),
              data_str: moment(item.start_date).format('YYYY-MM-DD'),
              complete: `${completeStudentCount}/${assignedLength}`,
              type: item['type'],
            };
          } else {
            return {
              No: PER_PAGE * localState.pageNumber + key + 1,
              key: `class-lesson-row${item.idx}`,
              idx: item.idx,
              class: item['assigned_external_study_group']['class']['name'],
              lessonCode: 'lessonCode',
              classIdx: item.assigned_external_study_group.class.idx,
              title: articleData['headline'] || '',
              date: moment(item.start_date).format('YYYY/MM/DD'),
              data_str: moment(item.start_date).format('YYYY-MM-DD'),
              complete: `${completeStudentCount}/${userlength}`,
              type: item['type'],
            };
          }
        });
      }
    }
  }, [dataAssignedExternalStudyGroupDetail, localState]);

  function handleDateSelect(date, dateString) {
    if (dateString == undefined) {
      localState.searchDate = new Date();
    }
    setLocalState((prev) => {
      return {
        ...prev,
        searchDate: dateString,
        formatSearchDate: moment(dateString).format('YY/MM/DD'),
        pageNumber: 0,
      };
    });
  }

  const handleinit = () => {
    setLocalState((prev) => {
      return {
        searchDate: '',
        formatSearchDate: '',
        state: '',
        searchClassName: '',
        pageNumber: 0,
      };
    });
  };

  const handleStateChange = (e) => {
    setLocalState((prev) => {
      return {
        ...prev,
        state: e.target.value,
        pageNumber: 0,
      };
    });
  };

  const handleTableChange = (pagination) => {
    setLocalState((prev) => {
      return {
        ...prev,
        pageNumber: pagination.current - 1,
      };
    });
  };

  return (
    <>
      <div className="learning-homework-res">
        <HeaderTitle className="learning-homework-haeding" level={4}>
        NEWSOMATIC Homework Result
        </HeaderTitle>
        <div className="learning-homework-top-inner" style={{display:'flex',justifyContent:'space-between',marginBottom:'5px'}}>
          <div><NewsOMacticMenuGroup currentMenu={'results'}/></div>
          <div>
          <Input
            placeholder="반명 검색"
            value={localState.searchClassName}
            onChange={(e) => {
              setLocalState((prev) => {
                return {
                  ...prev,
                  searchClassName: e.target.value,
                  pageNumber: 0,
                };
              });
            }}
            style={{ width: '150px' }}
          />
          <Radio.Group value={localState.state} onChange={handleStateChange}>
            {/* <Radio.Button value="">전체</Radio.Button> */}
            <Radio.Button value="ing">진행과제</Radio.Button>
            <Radio.Button value="end">종료과제</Radio.Button>
          </Radio.Group>
          <DatePicker onChange={handleDateSelect} value={localState.searchDate && moment(localState.searchDate)} />
          <Select value={localState.type} style={{ width: '200px' }} onChange={(v) => setLocalState((prev)=>{return{...prev,type:v }})}>
            <Select.Option value="newsomatic-assign">Newsomatic Edition</Select.Option>
            <Select.Option value="newsomatic">Newsomatic Article</Select.Option>
          </Select>
          </div>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="homeworkresult-halfwrapper resultpages-halfwrapper">
            <div
              className="homeworkresult-halfwrapper-dv"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              {/* <Title level={5}>학생 List</Title> */}
              {/* <Button style={{ color: '#289428' }}>+ 숙제출제일정</Button> */}
            </div>

            <CustomTable
              dataSource={assignedExternalStudyHomeWork}
              columns={columns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                current: localState.pageNumber + 1,
                total: dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.total || 0,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={grouploadingdetail}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Learning;
