import React, { useCallback, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { isVowel, alphabetBoxTypeChecker } from '../../utils/pm/util';
// import SoundButton from "./SoundButton";
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PageNum from './PageNum';
import AlphabetTopArea from './AlphabetTopArea';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
let bookNameUnitStage = undefined;
let bookNameUnitStageChecklist = undefined;
let bookNameUnitStageAdd = undefined;
const AlphabetDnD = ({
  upperWord,
  isCorrect,
  underWord,
  upperWordRight,
  underWordRight,
  isMagic,
  isShortVowel,
  customData,
  setSteps,
  steps,
  problems,
  setShowPopUp,
  setPopUpType,
  onWordSoundEnd,
  bookInfo,
}) => {
  const [complete, setComplete] = useState(Array(2).fill(false));
  const [exWord, setExWord] = useState(undefined);
  const { level, unit, stage } = useParams();

  const handleComplete = (idx) => {
    const copy = _.cloneDeep(complete);
    copy[idx] = true;
    setComplete(copy);
  };
  let stageproblemlength = problems.length;
  let loopproblemlen = [];

  if ((level == '3a' || '3b') && (unit == 1 || 2 || 3 || 4 || 6 || 7 || 8) && stage == 2) {
    problems.map((problemdata, key) => {
      if (problemdata.question !== undefined) {
        loopproblemlen.push(problemdata.question);
      }
    });
  }

  if (level == '2a' && unit == 6 && (stage == 2 || 3 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (level == '3b' && (unit == 6 || 7 || 8 || 9) && (stage == 2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (loopproblemlen.length !== 0) {
    stageproblemlength = loopproblemlen.length;
  }

  if (level == '3b' || unit == 9 || stage == 2) {
    bookNameUnitStageChecklist = { e: true };
    bookNameUnitStageAdd = 'excp';
  }

  useEffect(() => {
    if (complete.every((item) => item === true)) {
      if (problems[steps].exampleWord) {
        setPopUpType('exampleWord');
        setShowPopUp(true);
        setExWord(problems[steps].exSound);
      } else {
        setSteps((prev) => prev + 1);
      }
    }
  }, [complete]);
  useEffect(() => {
    setComplete(Array(2).fill(false));
  }, [underWord]);
  return (
    <>
      <S.RowFlex style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }} className="main_content  stage2_content  col2">
        <PageNum
          num={steps + 1}
          total={problems && stageproblemlength}
          style={{ top: '20px', position: 'absolute', right: '1.8rem', fontSize: '1.3rem', fontWeight: 'bold' }}
        />
        <S.ImageWordSelectWriteBox className="left_box">
          <AlphabetTopArea
            title={`Short Vowel`}
            soundUrl={
              upperWord &&
              (!_.isNil(customData.soundBaseUrl) ? `${customData.soundBaseUrl}${upperWord.join('')}.mp3` : `${upperWord.join('')}.mp3`)
            }
          />
          <AlphabetDnDContextArea
            isMagic={isMagic}
            upperWord={upperWord}
            underWord={underWord}
            isCorrect={isCorrect}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : undefined}
            onComplete={() => {
              handleComplete(0);
            }}
          />
        </S.ImageWordSelectWriteBox>
        <S.ImageWordSelectWriteBox style={{ marginLeft: '24px' }} className="right_box">
          <AlphabetTopArea
            isShortVowel={!_.isNil(isShortVowel) && isShortVowel === true ? true : false}
            title={`${_.isNil(isShortVowel) ? 'Long' : 'Short'} Vowel`}
            soundUrl={
              upperWordRight &&
              (!_.isNil(customData.soundBaseUrl)
                ? `${customData.soundBaseUrl}${upperWordRight.join('')}.mp3`
                : `${upperWordRight.join('')}.mp3`)
            }
          />
          <AlphabetDnDContextArea
            isMagic={isMagic}
            upperWord={upperWordRight}
            underWord={underWordRight}
            isCorrect={isCorrect}
            onComplete={() => {
              handleComplete(1);
            }}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : undefined}
          />
        </S.ImageWordSelectWriteBox>
      </S.RowFlex>
      {exWord && (
        <SoundEffectPM
          url={`https://cdn.cloubot.com/PM/audio/sounds/${exWord}.mp3`}
          onEnd={() => {
            setExWord(undefined);
            onWordSoundEnd();
          }}
        />
      )}
    </>
  );
};

export default AlphabetDnD;

const AlphabetDnDContextArea = ({ isMagic, upperWord, isCorrect, underWord, onComplete, soundBaseUrl }) => {
  const [dropSound, setDropSound] = useState(undefined);
  const [eachSound, setEachSound] = useState(undefined);
  const [upperAlphabetDropped, setUpperAlphabetDropped] = useState();
  const [underAlphabet, setUnderAlphabet] = useState([]);
  const handleDragEnd = useCallback(
    (result) => {
      const { destination } = result;
      if (_.isNil(destination) || destination.droppableId.startsWith('under_')) {
        setDropSound('wrong_sound1');
        return;
      }
      setDropSound('mouse-click');

      const droppedWord = destination.droppableId.split('_')[1];

      const copy = _.cloneDeep(underAlphabet);
      copy.splice(copy.indexOf(droppedWord), 1);
      setUnderAlphabet(copy);

      const copiedCorrect = _.cloneDeep(upperAlphabetDropped);
      copiedCorrect[upperWord.indexOf(droppedWord)] = true;
      setUpperAlphabetDropped(copiedCorrect);
    },
    [underAlphabet],
  );
  const handleDropSoundEnd = () => {
    setDropSound(undefined);
  };
  const handleEachSoundEnd = () => {
    console.log('handleEachSoundEnd');
    if (!_.isNil(upperAlphabetDropped) && upperAlphabetDropped.every((item) => item === true)) {
      onComplete();
    }
    setEachSound(undefined);
  };

  useEffect(() => {
    setUnderAlphabet(underWord);
  }, [underWord]);

  useEffect(() => {
    if (upperWord && upperWord.length > 0) {
      setUpperAlphabetDropped(Array(upperWord.length).fill(false));
    }
  }, [upperWord]);

  useEffect(() => {
    if (!_.isNil(upperAlphabetDropped) && upperAlphabetDropped.every((item) => item === true)) {
      setEachSound(`${_.isNil(soundBaseUrl) ? 'https://cdn.cloubot.com/PM/audio/sounds/' : soundBaseUrl}${upperWord.join('')}.mp3`);
    }
  }, [upperAlphabetDropped]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        {/* 윗부분, 드로퍼블영역 */}
        <S.AlphabetWrap className="alphabet_wrap">
          {_.map(upperWord, (item, index) => (
            <li key={`upper${item}`}>
              <Droppable droppableId={`upper_${item}`} type={item}>
                {(provided, snapshot) => (
                  <>
                    <S.AlphabetBox
                      className={`alphabet_box ${isMagic && item.length > 1 ? 'long-width' : ''} row1 ${
                        upperAlphabetDropped && upperAlphabetDropped[index] ? 'green' : 'gray'
                      }`}
                      row={alphabetBoxTypeChecker(isMagic && item.length > 1 ? item.length : 1)}
                      gray={!(upperAlphabetDropped && upperAlphabetDropped[index])}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
                      }}
                    >
                      {isMagic ? (
                        _.map(item, (word) => (
                          <span
                            key={`dropable${word}`}
                            data="one"
                            style={{
                              color: upperAlphabetDropped && upperAlphabetDropped[index] ? (word == 'e' ? '#f368e0' : '#2e86de') : '#eee',
                            }}
                            className={
                              upperAlphabetDropped && upperAlphabetDropped[index] ? (word == 'e' ? 'font_pink' : 'font_blue') : 'font_gray'
                            }
                          >
                            {word}
                          </span>
                        ))
                      ) : upperWord == 'a,t,e' || 'a,s,e' || 'a,k,e' ? (
                        <span
                          data="two"
                          style={{ color: upperAlphabetDropped && upperAlphabetDropped[index] ? '#f368e0' : '#eee' }}
                          className={`${
                            upperAlphabetDropped && upperAlphabetDropped[index]
                              ? `${isVowel(item) ? 'font_pink' : 'font_pink'}`
                              : 'font_gray'
                          }`}
                        >
                          {item}
                        </span>
                      ) : (
                        <span
                          data="three"
                          style={{
                            color: upperAlphabetDropped && upperAlphabetDropped[index] ? (isVowel(item) ? '#f368e0' : '#2e86de') : '#eee',
                          }}
                          className={`${
                            upperAlphabetDropped && upperAlphabetDropped[index]
                              ? `${isVowel(item) ? 'font_pink' : 'font_blue'}`
                              : 'font_gray'
                          }`}
                        >
                          {item}
                        </span>
                      )}
                    </S.AlphabetBox>
                    <ul style={{ display: 'none' }}>{provided.placeholder}</ul>
                  </>
                )}
              </Droppable>
            </li>
          ))}
        </S.AlphabetWrap>
        <Arrow src="/images/pm/i_arrow_top.svg" alt="위로 향하는 화살표" className="top_arrow" />
        {/* 아랫부분, 드래거블영역 */}
        <S.AlphabetBottomWrapUl borderTop={true} className="alphabet_wrap gray_bg_border_top">
          {_.map(underAlphabet, (item, idx) => (
            <Droppable key={`under${item}`} droppableId={`under_${item}`} type={item} isDropDisabled={true}>
              {(providedDrop) => (
                <li ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                  <Draggable draggableId={`underDrag${item}`} index={idx} type={item}>
                    {(provided) => (
                      <S.AlphabetBox
                        className={`alphabet_box ${isMagic && item.length > 1 ? 'long-width' : ''} row1 green`}
                        row={alphabetBoxTypeChecker(isMagic ? item.length : 1)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {isMagic ? (
                          bookNameUnitStage ? (
                            bookNameUnitStageAdd ? (
                              _.map(item, (word) => (
                                <span
                                  key={`dragable${word}`}
                                  style={{ color: bookNameUnitStageChecklist[item] == true ? '#f368e0' : '#2e86de' }}
                                  className={`font_${bookNameUnitStageChecklist[item] == true ? 'pink' : 'blue'}`}
                                >
                                  {word}
                                </span>
                              ))
                            ) : (
                              _.map(item, (word) => (
                                <span
                                  key={`dragable${word}`}
                                  style={{ color: isVowel(word) ? '#f368e0' : '#2e86de' }}
                                  className={`font_${isVowel(word) ? 'pink' : 'pink'}`}
                                >
                                  {word}
                                </span>
                              ))
                            )
                          ) : (
                            _.map(item, (word) => (
                              <span
                                key={`dragable${word}`}
                                style={{ color: isVowel(word) ? '#f368e0' : '#2e86de' }}
                                className={`font_${isVowel(word) ? 'pink' : 'blue'}`}
                              >
                                {word}
                              </span>
                            ))
                          )
                        ) : underAlphabet == 'a,d' || 'a,g' || 'a,m' ? (
                          <span style={{ color: '#f368e0' }} className={`font_${isVowel(item) ? 'pink' : 'pink'}`}>
                            {item}
                          </span>
                        ) : (
                          <span
                            style={{ color: isVowel(item) ? '#f368e0' : '#2e86de' }}
                            className={`font_${isVowel(item) ? 'pink' : 'blue'}`}
                          >
                            {item}
                          </span>
                        )}
                      </S.AlphabetBox>
                    )}
                  </Draggable>
                  {providedDrop.placeholder}
                </li>
              )}
            </Droppable>
          ))}
        </S.AlphabetBottomWrapUl>
      </DragDropContext>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      {eachSound && <SoundEffectPM url={eachSound} onEnd={handleEachSoundEnd} />}
    </>
  );
};
const Arrow = styled.img`
  height: 3rem;
  margin-bottom: 1vh;
  @media (max-height: 500px){
	  height: 2rem;
}
`;
