import React, { useState, useMemo } from 'react';
import { Button, Row, Col, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import AssignLesson from 'src/components/Offline/MainPage/AssignLesson';
import AssignLessonFC from 'src/components/Offline/MainPage/AssignLessonFC';
import { useHistory, Link } from 'react-router-dom';

import { getCurrentToken } from 'src/utils';
import { userVar } from 'src/apollo/cache';
import { useQueryProxy } from 'src/operations/proxy/user';
import * as queries from 'src/operations/queries';
//캠퍼스 원장용 대시보드 메인 페이지
const CampusPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  const history = useHistory();

  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  const userType = useMemo(() => userVar()[data?.me?.type] || userVar()[0], [data]);

  if (userType == 'fc') {
    return (
      <>
        <Row justify="space-between" className="buttonlisting-dashboard">
          <Col span={2} className="calendar-box">
            <HeaderTitle level={4}>Syllabus</HeaderTitle>
          </Col>
          <Col span={19} className="calendar-box"></Col>
          <Col span={2} className="calendar-box">
            {/*<Button
                  className="btn-schedule"
                  type={'primary'}
                  size="large"
                  style={{ background: "white", borderColor: "#B8E4F0", color : "#98BAE7", marginTop:"2%" }}
                >
                  <Link to="/offline/lesson/plan">Assigned List</Link> 
                </Button>*/}
          </Col>
          <Col span={1} className="calendar-box"></Col>
        </Row>

        <Row gutter={[24, 24]}>
          <AssignLessonFC />
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row justify="space-between" className="buttonlisting-dashboard">
          <Col span={2} className="calendar-box">
            <HeaderTitle level={4}>Syllabus</HeaderTitle>
          </Col>
          <Col span={19} className="calendar-box"></Col>
          <Col span={2} className="calendar-box">
            <Button
              className="btn-schedule"
              type={'primary'}
              size="large"
              style={{ background: 'white', borderColor: '#B8E4F0', color: '#98BAE7', marginTop: '2%' }}
            >
              {company !== 'smart-eclass' ? (
                <Tooltip title="배정 반 보기.">
                  <Link to="/offline/lesson/plan">Assigned List</Link>
                </Tooltip>
              ) : (
                <Link to="/offline/lesson/plan">Assigned List</Link>
              )}
            </Button>
          </Col>
          <Col span={1} className="calendar-box"></Col>
        </Row>

        <Row gutter={[24, 24]}>
          <AssignLesson />
        </Row>
      </>
    );
  }
};

export default CampusPage;
