import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import RecordButtonBox from '../RecordButtonBox';
import StorySoundButton from '../StorySoundButton';
import PopUp from '../PopUp';
import { useStudyCreate } from '../../../utils/pm/api';
import reactStringReplace from 'react-string-replace';
import styled from 'styled-components';
import * as S from 'src/pages/PhonicsMonster/style';
import { userInfoVar } from 'src/apollo/cache';
import { useMemo } from 'react';

let MainContent = styled.div`
  font-size: 20px;
  font-family: 'BalsamiqSans';
  user-select: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-self: stretch;
  flex: 1 1;
  flex-direction: column;
  height: calc(100% - 70px);
  & .top_box {
    font-size: 10px;
    font-family: 'BalsamiqSans';
    user-select: none;
    margin: 0;
    align-items: center;
    padding: 1.6rem 0;
    border-radius: 1.6rem 1.6rem 0 0;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-bottom: 0.8rem;
    background-size: contain;
    @media (max-height: 500px) {
      margin-bottom: 0;
    }
  }

  & .sentence {
    /* font-size: 10px; */
    font-family: 'BalsamiqSans';
    user-select: none;
    margin: 0;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    margin-bottom: 0.8rem;
    width: 80%;
    border-radius: 1.6rem;
    background-color: hsla(0, 0%, 100%, 0.5);
    min-height: 30vh;
    font-size: 3.4vh;
    overflow-y: auto;
    max-height: 45vh;
    padding-bottom: 39vh;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: #eee;
      border-radius: 3px;
    }
    @media (max-height: 720px) {
      max-height: 35vh;
      padding: 10px 0;
      padding-bottom: 29vh;
    }
    @media (max-height: 500px) {
      max-height: 30vh;
      padding-bottom: 24vh;
    }
  }

  

  & .play_box {
    font-size: 10px;
    font-family: 'BalsamiqSans';
    user-select: none;
    margin: 0;
    display: inline-flex;
    align-items: center;
    padding: 0.4rem 0.8rem;
    border-radius: 0.8rem;
    background-color: #333;
    @media (max-height: 500px) {
      padding: 0.2rem 0.5rem;
    }
  }

  & .play_box button {
    user-select: none;
    margin: 0;
    padding: 0;
    border: none;
    font-family: 'Alata', 'NanumSquareRound', sans-serif;
    color: #333;
    vertical-align: middle;
    outline: none;
    width: 2.8rem;
    background-color: transparent;
  }

  & .progress_list {
    font-size: 10px;
    font-family: 'BalsamiqSans';
    user-select: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 24rem;
    height: 1.6rem;
    margin-left: 0.4rem;
    border-radius: 1.6rem;
    background-color: #ff9f43;
    @media (max-height: 500px) {
      border-radius: 1.2rem;
      width: 20rem;
      height: 1.2rem;
    }
  }

  & .bottom_box {
    font-size: 10px;
    font-family: 'BalsamiqSans';
    user-select: none;
    margin: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.6rem 0;
    border-radius: 0 0 1.6rem 1.6rem;
    @media (max-height: 500px) {
      padding: 0 0 0.4rem;
    }
  }
`;
const Sentence = styled.p`
    user-select: none;
    margin: 0;
    padding: 0;
    font-size: 3.4vh;
    height: ${(props)=>props.length?'12vh':'6vh'};
`;
const RecordStage = ({ unitInfo, bookInfo, stages }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState('');
  const [setenceIdx, setSetenceIdx] = useState(0);
  const stageNumber = Number(window.location.pathname.split('/stage/')[1]);

  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setPopUpType('correct');
      setShowPopUp(true);
    },
  });

  const {
    customData: { sentences, sound, bgImage },
  } = stages;

  const handleRecordComplete = (data) => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
      exam_total: data.exam_total,
      exam_correct: data.exam_correct,
      wpm: data.wpm,
      recording_data: data.recording_data,
    });
  };

  const handleGoodJobSoundEnd = () => {
    history.replace({
      pathname: `${window.location.pathname.split('/stage')[0]}`,
      search: `${window.location.search}`,
      state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
    });
  };
  const src = useMemo(() => {
    const tmp = [];
    sentences.map((_, i) => tmp.push(`${sound}_${i + 1}.mp3`));
    return tmp;
  }, [sound, sentences]);
  return (
    <>
      <MainContent className="main_content  stage8_content">
        <div className="top_box" style={{ backgroundImage: `url(${bgImage})` }}>
          <RecordSentenceBox sentences={sentences} setenceIdx={setenceIdx} />
          <StorySoundButton src={src} setenceIdx={setenceIdx} setSetenceIdx={setSetenceIdx} />
        </div>

        <div className="bottom_box">
          <RecordButtonBox bookInfo={bookInfo} onRecordComplete={handleRecordComplete} sentences={sentences} />
        </div>
      </MainContent>

      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
    </>
  );
};

export default RecordStage;

const RecordSentenceBox = ({ sentences, setenceIdx }) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      const vhToPixels = (vh) => (vh * window.innerHeight) / 100;
      const moveSize = sentences.length<6?12:6
      scrollRef.current.scrollTo({
        top: setenceIdx * vhToPixels(moveSize),
        behavior: 'smooth',
      });
    }
  }, [setenceIdx]);
  return (
    <section ref={scrollRef} className="sentence">
      <div>
        {sentences.map((sentence, index) => (
          <Sentence length={sentences.length<6} key={index}>
            {reactStringReplace(sentence, /##(\w+)##/g, (match, i) => (
              <span key={i} style={{ color: '#000059', fontWeight: 'bold' }}>
                {match}
              </span>
            ))}
          </Sentence>
        ))}
      </div>

      {/* {sentences && sentences.map((item, key) => <p key={`sentence-item-${key}`}>{item}</p>)} */}
    </section>
  );
};
