export const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
  },
  {
    title: '학원명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '진행중',
    dataIndex: 'prev_cnt',
    key: 'prev_cnt',
    align: 'center',
  },
  {
    title: '신규구매',
    dataIndex: 'count',
    key: 'count',
    align: 'center',
  },
  {
    title: '기타발급',
    dataIndex: 'manual_cnt',
    key: 'manual_cnt',
    align: 'center',
  },
];

export const columnsCampusCredit = [
  {
    title: '구매날짜',
    dataIndex: 'day',
    key: 'day',
    align: 'center',
  },
  {
    title: '교재명',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
  },
  {
    title: '구매 권수',
    dataIndex: 'credit1_count',
    key: 'credit1_count',
    align: 'center',
  },
  {
    title: '이용권 수',
    dataIndex: 'credit2_count',
    key: 'credit2_count',
    align: 'center',
  },
];
