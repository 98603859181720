import React from 'react';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import NewReport from './NewReport';
import OldMetaReport from './OldMetaReport';

const MetaReport = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const company_idx = params.get('company_idx') ? Number(params.get('company_idx')) : null;
  const { data: dataUser } = useQuery(GET_USER_INFO, {
    skip: company_idx !== null,
  });
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 || company_idx === 1016 ? true : false;
  return <>{isWEnglish ? <NewReport /> : <OldMetaReport />}</>;
};

export default MetaReport;
