import React, { useLayoutEffect, useCallback } from 'react';
import { Form, Input, Button, Select, DatePicker, Modal } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CAMPUS_SMALL_LIST_FOR_TEST } from 'src/operations/queries/campus';
import { CREATE_CONSULT_FOR_LEVELTEST } from 'src/operations/mutations/consult';

const { Option } = Select;
const grades = ['초1', '초2', '초3', '초4', '초5', '초6', '중1', '중2', '중3'];

const CustomInput = ({ ...rest }) => {
  return <Input style={{ height: 50, borderRadius: '30px', backgroundColor: '#f6f6f6' }} {...rest} />;
};
const CustomSelect = ({ children, ...rest }) => {
  return (
    <Select size="large" style={{ borderRadius: '30px' }} {...rest}>
      {children}
    </Select>
  );
};

const ApplicationForm = styled.div`
  background-color: #fff;
  width: 476px;
  height: 462px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 30px;
    background-color: #f6f6f6;
  }
`;
const Top = styled.div`
  width: 100%;
  color: #fff;
  background: #03a9f4;
  padding: 68px 0px;
  text-align: center;
  z-index: 99;
  position: relative;
  background-image: url(/images/lt-app-form-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
`;

function disabledDate(current) {
  const isPast = current && moment().isAfter(current, 'day');

  if (isPast) {
    return true;
  }
  return current && [0, 6].includes(moment(current).day());
}

const EvineLevelTestAppForm = () => {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const openCompleteModal = () => {
    modal.info({
      title: '예약신청이 완료되었습니다. 해당 학원에서 연락드리겠습니다.',
      onOk: () => {
        window.self.close();
      },
    });
  };

  const { data: dataCampuses, loading } = useQuery(GET_CAMPUS_SMALL_LIST_FOR_TEST, { variables: { company_idx: 1004 } });
  const [create, { loading: loadingCreate }] = useMutation(CREATE_CONSULT_FOR_LEVELTEST, {
    onCompleted: () => {
      openCompleteModal();
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  useLayoutEffect(() => {
    window.resizeTo(600, 800);
  }, []);
  const onSubmit = useCallback(
    (values) => {
      const ticket_code = 'EVINE-LT';
      const { name: student_name, campus_idx, phone, reserve_date, grade: school_grade } = values;
      console.log(reserve_date, reserve_date.format('yy-MM-DD'));
      create({ variables: { campus_idx, phone, student_name, reserve_date, ticket_code, school_grade } });
    },
    [create],
  );
  const handleChangeParentPhone = (e) => {
    const value = e.target.value;
    const regEx = /[^0-9]/g;
    const numberOnlyValue = value.replace(regEx, '');
    form.setFieldsValue({ phone: numberOnlyValue });
  };
  return (
    <>
      <Top>
        <h1 style={{ fontWeight: 'bold', fontSize: 36, color: '#fff' }}>Placement Test 신청</h1>
      </Top>
      <ApplicationForm>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item
            style={{ width: 240 }}
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: '학생이름을 입력해 주세요.',
              },
            ]}
          >
            <CustomInput placeholder="학생이름" size="large" name="name" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                whitespace: true,
                message: '숫자만 입력해 주세요.',
              },
            ]}
            name="phone"
          >
            <CustomInput type="text" placeholder="학부모전화" size="large" name="phone" maxLength={13} onChange={handleChangeParentPhone} />
          </Form.Item>
          <Form.Item
            name="grade"
            rules={[
              {
                required: true,
                message: '학년을 선택해 주세요.',
              },
            ]}
          >
            <CustomSelect placeholder="학년 선택" name="grade">
              {grades.map((item, key) => (
                <Option key={`grade-opion-${key}`} value={item}>
                  {item}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item
            name="campus_idx"
            rules={[
              {
                required: true,
                message: '학원을 선택해 주세요.',
              },
            ]}
          >
            <CustomSelect placeholder="학원선택" name="campus_idx" loading={loading}>
              {dataCampuses &&
                dataCampuses.getCampusList.map((item) => (
                  <Option key={`grade-opion-${item.idx}`} value={item.idx}>
                    {item.name}
                  </Option>
                ))}
            </CustomSelect>
          </Form.Item>

          <Form.Item
            name="reserve_date"
            rules={[
              {
                required: true,
                message: '예약날짜를 선택해 주세요.',
              },
            ]}
          >
            <DatePicker style={{ height: 45, borderRadius: '30px', width: '100%' }} disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item>
            <div>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={loadingCreate}
                style={{
                  width: 240,
                  backgroundColor: '#8bc34a',
                  border: '0 solid #fff',
                  borderRadius: '30px',
                  fontWeight: 'bold',
                  height: 60,
                }}
              >
                SUBMIT
              </Button>
            </div>
          </Form.Item>
        </Form>
      </ApplicationForm>
      {contextHolder}
    </>
  );
};

export default EvineLevelTestAppForm;
