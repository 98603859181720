import React, { useState } from 'react';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import KeyboardEachButton, { BackspaceButton, EnterButton, ShiftButton, SpaceButton } from './KeyboardEachButton';
import _ from 'lodash';
import styled from 'styled-components';
import * as S from 'src/pages/PhonicsMonster/style';
const pressSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/pressing-a-key.mp3';

const Keyboard = ({
  onWriteEnd,
  disabled = false,
  getKey,
  setGetKey,
  enabledWritePreview = true,
  disableSound = false,
  imageWordWrite = false,
}) => {
  const [word, setWord] = useState([]);
  const [isCaps, setIsCaps] = useState(false);
  const [soundName, setSoundName] = useState('');
  //disabled 이면 투명레이어를 씌워주나? 키보드 터치를 막기 위한 용도...
  const topLine = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
  const midLine = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];
  const botLine = ['z', 'x', 'c', 'v', 'b', 'n', 'm'];

  const handleKeyPress = (item) => {
    if (disabled) return false;
    !disableSound && setSoundName(pressSound);
    // make sure to end the sound
    setTimeout(() => {
      setSoundName('');
    }, 500);

    if (isCaps) {
      item = item.toUpperCase();
    }

    !_.isNil(getKey) ? setGetKey([...getKey, item]) : setWord([...word, item]);
  };
  const handleSoundEnd = () => {
    setSoundName('');
  };

  const handleBackspaceKeyPress = () => {
    if (disabled) return false;
    !disableSound && setSoundName(pressSound);
    if (!_.isNil(getKey)) {
      getKey.pop();
      setGetKey([...getKey]);
    } else {
      word.pop();
      setWord([...word]);
    }
  };

  const handleEnterKeyPress = () => {

    if (disabled||disableSound) return false;
    if (!_.isNil(getKey)) {
      onWriteEnd(getKey);
      setGetKey([]);
    } else {
      onWriteEnd(word);
      setWord([]);
    }
  };

  const handleShiftKeyPress = () => {
    if (disabled) return false;
    setIsCaps(!isCaps);
  };

  const handleSpaceKeyPress = () => {
    if (disabled) return false;
    !disableSound && setSoundName(pressSound);
    // make sure to end the sound
    setTimeout(() => {
      setSoundName('');
    }, 500);

    !_.isNil(getKey) ? setGetKey([...getKey, ' ']) : setWord([...word, ' ']);
  };

  return (
    <>
      <KeyboardWrap imageWordWrite={imageWordWrite} className={`keyboard_wrap active ${disabled ? 'disabled' : ''}`}>
        {enabledWritePreview ? (
          <S.OutPut imageWordWrite={imageWordWrite} className="output_area">
            {getKey ? getKey : word}
          </S.OutPut>
        ) : (
          <Empty/>
        )}
        <KeyboardLine className="keyboard_line1">
          {_.map(topLine, (item) => (
            <KeyboardEachButton
              imageWordWrite={imageWordWrite}
              key={`keyborad-button-${item}`}
              item={item}
              onKeyPress={handleKeyPress}
              isCaps={isCaps}
            />
          ))}
          <BackspaceButton onKeyPress={handleBackspaceKeyPress} />
        </KeyboardLine>
        <KeyboardLine className="keyboard_line2" style={{ paddingLeft: '25px' }}>
          {_.map(midLine, (item) => (
            <KeyboardEachButton
              imageWordWrite={imageWordWrite}
              key={`keyborad-button-${item}`}
              item={item}
              onKeyPress={handleKeyPress}
              isCaps={isCaps}
            />
          ))}
          <EnterButton onKeyPress={handleEnterKeyPress} />
        </KeyboardLine>
        <KeyboardLine className="keyboard_line3">
          <ShiftButton onKeyPress={handleShiftKeyPress} isCaps={isCaps} />
          {_.map(botLine, (item) => (
            <KeyboardEachButton
              imageWordWrite={imageWordWrite}
              key={`keyborad-button-${item}`}
              item={item}
              onKeyPress={handleKeyPress}
              isCaps={isCaps}
            />
          ))}
          <SpaceButton onKeyPress={handleSpaceKeyPress} />
        </KeyboardLine>
      </KeyboardWrap>
      <SoundEffectPM url={soundName} onEnd={handleSoundEnd} />
    </>
  );
};

export default Keyboard;
const KeyboardWrap = styled.div`
  width: '100%';
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (orientation: portrait) {
    width: 100%;
  }
`;

const KeyboardLine = styled.ul`
  display: flex;
  justify-content: center;
  align-self: stretch;
  flex: 1 1;
  list-style: none;
  padding: 0;
  @media (orientation: portrait) {
    width: 100%;
    flex-wrap: wrap;
  }
  margin-bottom: 10px;
`;
const Empty = styled.div`
  height: 24px;
  @media (max-height: 500px) {
    height: 0;
  }
`;
