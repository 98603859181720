import React, { useState, useEffect } from 'react';
import { Typography, Checkbox, Input } from 'antd';
import { FolderFilled, LockOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Title } = Typography;

const FolderLock = (props) => (
  <span role="img" aria-label="folder" class="anticon anticon-folder" style={props.style}>
    <svg viewBox="64 64 896 896" focusable="false" data-icon="folder" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <path d="M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path>
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="lock"
        x="520"
        y="480"
        width="400px"
        height="400px"
        fill="rgb(74, 74, 74)"
        aria-hidden="true"
      >
        <path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path>
      </svg>
    </svg>
  </span>
);

const Folder = (props) => {
  const handleOnclick = () => {
    if (!props.renameActive) {
      props.onClick();
    }
  };

  const getfolderIcon = () => {
    if (!props.folder.is_public && !props.folder.is_default)
      return (
        <>
          <FolderLock style={props.thumbStyle} />
        </>
      );
    else return <FolderFilled style={props.thumbStyle} />;
  };

  const getDisplayItem = () => {
    switch (props.displayStyle) {
      case 'list':
        return (
          <div onClick={handleOnclick} style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
            {getfolderIcon()}
            <Title level={5} ellipsis={true} style={{ ...props.textStyle, flex: 1 }}>
              {props.folder.name}
            </Title>
            <Title level={5} ellipsis={true} style={props.textStyle}>{`${moment(new Date(props.folder.idate))
              .utcOffset('+0000')
              .format('lll')}`}</Title>
          </div>
        );
      case 'box':
      default:
        return (
          <div onClick={handleOnclick} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
            {getfolderIcon()}
            <Title level={5} ellipsis={true} style={props.textStyle}>
              {props.folder.name}
            </Title>
          </div>
        );
    }
  };

  const getDisplayItemSelected = () => {
    switch (props.displayStyle) {
      case 'list':
        return (
          <div onClick={handleOnclick} style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', cursor: 'pointer' }}>
            {getfolderIcon()}
            {props.checked && props.renameActive ? (
              <Input
                onChange={props.onChangeName}
                value={props.folder.new_name}
                defaultValue={props.folder.name}
                style={{ ...props.textStyle, flex: 1, fontSize: 18 }}
              />
            ) : (
              <Title level={5} ellipsis={true} style={{ ...props.textStyle, flex: 1 }}>
                {props.folder.name}
              </Title>
            )}

            <Title level={5} ellipsis={true} style={props.textStyle}>{`${moment(new Date(props.folder.idate))
              .utcOffset('+0000')
              .format('lll')}`}</Title>
          </div>
        );
        break;
      case 'box':
      default:
        return (
          <div onClick={handleOnclick} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
            {getfolderIcon()}
            {props.checked && props.renameActive ? (
              <Input
                onChange={props.onChangeName}
                value={props.folder.new_name}
                defaultValue={props.folder.name}
                style={{ ...props.textStyle, flex: 1, fontSize: 18 }}
              />
            ) : (
              <Title level={5} ellipsis={true} style={props.textStyle}>
                {props.folder.name}
              </Title>
            )}
          </div>
        );
        break;
    }
  };

  return (
    <>
      {props.selectable ? <Checkbox onChange={(e) => props.onChange(e, props.indx)} checked={props.checked}></Checkbox> : null}
      {props.popover ? getDisplayItem() : getDisplayItemSelected()}
    </>
  );
};

export default Folder;
