import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, ArrowLeftOutlined, SoundFilled } from '@ant-design/icons';
import * as axios from 'axios';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #443ec8;
  color: #fff;
  font-size: 25px;
  box-shadow: 0 2px 1px 2px #090989;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const StageTitle = styled.div`
  color: #fff;
  padding: 5px 45px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  background-color: #49ada1;
`;
const ContentBox = styled(ColumnFlex)`
  background-color: #daedeb;
  border-radius: 20px;
  font-size: 22px;
  font-weight: 600;
  padding: 0 20px 20px;
  text-align: center;
  align-items: center;
  width: 90%;
  height: 100%;
  line-height: 2;
`;
const ContentInnerBox = styled(RowFlex)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 20px 18px;
  background-color: #fff;
  border-radius: 20px;
`;
const Dot = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  border: 2px solid #787878;
  border-radius: 12px;
  /* background-color: ${(props) => (props.isClicked ? '#5b9bd5' : '#fff')}; */
  margin: 0 15px;
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const LeftBody = styled(ColumnFlex)`
  width: 18%;
  height: 95%;
  justify-content: space-between;
`;
const QuestionNumber = styled(ColumnFlex)`
  width: 50px;
  height: 50px;
  /* line-height: 50px; */
  font-size: 30px;
  color: #443dc7;
  background: #fff;
  border: 3px solid #443dc7;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
const MidBody = styled(ColumnFlex)`
  width: 33%;
  justify-content: space-between;
  height: 95%;
`;
const RightBody = styled(ColumnFlex)`
  width: 33%;
  height: 95%;
  justify-content: space-between;
`;

const WordListBox = styled(ColumnFlex)`
  height: 100%;
  font-size: 28px;
  align-items: center;
  justify-content: space-between;
`;
const MidWordBox = styled.div`
  border-radius: 10px;
  border: 3px #ffb901 solid;
  color: #595758;
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 2;
  height: 46px;
  font-weight: 600;
`;
const RightWordBox = styled.div`
  height: 50px;
  border-radius: 10px;
  border: 3px #443dc7 solid;
  color: #595758;
  width: 80%;
  text-align: center;
  font-size: 18px;
  line-height: 50px;
  font-weight: 600;
`;
const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  /* border: 1px red solid; */
  background-image: url(${(props) => props.bg});
  background-size: 72px 50px;
  background-repeat: no-repeat;
  /* background-color: ${(props) => (props.active ? 'red' : 'orange')}; */
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;

const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  max-width: 800px;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const ArrowIcon = styled.div`
  width: 52px;
  min-width: 52px;
  height: 45px;
  min-height: 45px;
  margin: 0 35px;
  background-image: url(${(props) => props.src});
  background-size: 52px 45px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
export default function EngStaVocaMatch({}) {
  const dummydata = [
    { number: 1, english: 'floor', korean: '바닥' },
    { number: 2, english: 'table', korean: '탁자' },
    { number: 3, english: 'bed', korean: '침대' },
    { number: 4, english: 'chair', korean: '의자' },
    { number: 5, english: 'house', korean: '집' },
  ];
  const [left, mid, right] = useMemo(() => {
    const left = _.shuffle(
      dummydata.map((e) => {
        return { number: e.number, audio: `${e.english}.mp3` };
      }),
    );
    const mid = _.shuffle(
      dummydata.map((e) => {
        return { number: e.number, english: e.english };
      }),
    );
    const right = _.shuffle(
      dummydata.map((e) => {
        return { number: e.number, korean: e.korean };
      }),
    );
    return [left, mid, right];
  }, [dummydata]);
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <Title>
              <TitleSpan color={'#453ec8'}>BOOK Title</TitleSpan>
              <TitleSpan>Volume</TitleSpan>
              <TitleSpan>Unit</TitleSpan>
              <TitleSpan>Lesson</TitleSpan>
              <TitleSpan>Lesson Name</TitleSpan>
            </Title>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '80%', width: '100%', alignItems: 'center' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '20px 50px' }}>
                <StageTitle>Voca Exercise</StageTitle>
                <NumberText>
                  Number{' '}
                  <QuizNumber>
                    <span style={{ color: '#433fc8' }}>{`03`}</span>/{`10`}
                  </QuizNumber>
                </NumberText>
              </RowFlex>
              {/* <SubTitle>Click the word and then click the meaning.</SubTitle> */}
              <ContentBox>
                Click the word and then click the meaning.
                <ContentInnerBox>
                  <LeftBody>
                    {left.map((e, i) => (
                      <RowFlex style={{ alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                        <QuestionNumber>{i + 1}</QuestionNumber>{' '}
                        <ImgButton>
                          <SoundFilled />
                        </ImgButton>
                        <Dot isClicked={e.number === 1} />
                      </RowFlex>
                    ))}
                  </LeftBody>
                  <MidBody>
                    <WordListBox>
                      {mid.map((e) => (
                        <RowFlex style={{ alignItems: 'center', width: '100%' }}>
                          <Dot isClicked={e.number === 1} />
                          <MidWordBox>{e.english}</MidWordBox>
                          <Dot isClicked={e.number === 1} />
                        </RowFlex>
                      ))}
                    </WordListBox>
                  </MidBody>
                  <RightBody>
                    <WordListBox>
                      {right.map((e) => (
                        <RowFlex style={{ alignItems: 'center', width: '100%' }}>
                          <Dot isClicked={e.number === 1} />
                          <RightWordBox>{e.korean}</RightWordBox>
                        </RowFlex>
                      ))}
                    </WordListBox>
                  </RightBody>
                </ContentInnerBox>
              </ContentBox>
            </ColumnFlex>
          </Main>
        </MainWrapper>
        <StageButtonWrapper>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_left.png'}></ArrowIcon>
          <RowFlex style={{ justifyContent: 'space-between', width: '45%', height: '100%' }}>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_flashcard.png`} active={false} isDone={true} />
              Flashcard
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_check.png`} active={false} isDone={true} />
              Check
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} active={false} isDone={true} />
              Typing
            </StageButtonText>
            <StageButtonText active={true}>
              <StageButton bg={`/images/LevelUp/study/sub/g_quiz.png`} active={true} isDone={false} />
              Quiz
            </StageButtonText>
          </RowFlex>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_right.png'}></ArrowIcon>
        </StageButtonWrapper>
      </Wrapper>
    </>
  );
}
