import React from 'react';
import ChatWindow from 'src/components/ChatGpt/chatWindow';
import { Modal } from 'antd';

function OpenAiModal({ visible, topic, userTopicId, userTopicStatus, handleCancel}) {
  return (
    <Modal
      title="Cloubot AI"
      visible={visible}
      width="60%"
      bodyStyle={{ padding: '0 15px 15px 15px', background: '#f5f5f5' }}
      headerStyle={{ background: '#f5f5f5' }}
      onCancel={handleCancel}
      footer={null}
    >
      <ChatWindow topic={topic} userTopicId={userTopicId} userTopicStatus={userTopicStatus} handleCancel={handleCancel}/>
    </Modal>
  );
}

export default OpenAiModal;
