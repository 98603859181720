import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper, ListTitleWrapper } from 'src/components/common/Styles';
//import Detail from './Detail';
import { useQuery, useMutation } from '@apollo/client';
import { COMPANY_REMAINED_SMS_POINT, BRANCH_REMAINED_SMS_POINT } from 'src/operations/queries/message';
import { GET_USER_INFO, GET_CAMPUS_LIST } from 'src/operations/queries/campus';
//import { openNotification } from 'src/components/common/Notification';
import moment from 'moment';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcelUserSheet } from 'src/utils/index';
import { useParams } from 'react-router-dom';
import BackButton from 'src/components/common/BackButton';
const { Title } = Typography;

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'left',
  },
  {
    title: '학교명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (text, record) => {
      return <span>{text}</span>;
    },
  },
  {
    title: '지역',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
  },
  {
    title: '선생님',
    dataIndex: 'director_name',
    key: 'director_name',
    align: 'center',
  },
  {
    title: '선생님연락처',
    dataIndex: 'director_phone',
    key: 'director_phone',
    align: 'center',
  },
  {
    title: '연락처',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
  },
];

const columnsForAfterSchool = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
    width: '60px',
  },
  {
    title: '학교명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (text, record) => {
      return <span>{text}</span>;
    },
  },
  {
    title: '지역',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
  },
  {
    title: '선생님',
    dataIndex: 'director_name',
    key: 'director_name',
    align: 'center',
  },
  {
    title: '선생님연락처',
    dataIndex: 'director_phone',
    key: 'director_phone',
    align: 'center',
  },
  {
    title: '포인트',
    dataIndex: 'point',
    key: 'point',
    align: 'center',
    render: (text) => {
      return <span style={{ color: text < 0 ? 'red' : 'black' }}>{text}</span>;
    },
  },
];


const SMSStatic = () => {
  const companyName = useSelector(classStoreData);
  const { idx } = useParams();
  const userinfo = userInfoVar();
  const hasBranch = useMemo(() => {
    //FC본사이면서..지사가 있는 경움.
    if (userinfo?.campus?.company?.has_branch === '1' && userinfo?.type === 4) {
      return true;
    }
    return false;
  }, [userinfo]);


  //https://github.com/apollographql/react-apollo/issues/3180
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: smsBranchData } = useQuery(BRANCH_REMAINED_SMS_POINT, {
    skip: userinfo?.type < 3 || !dataUser,
    variables: {
      branch_idx: parseInt(idx??dataUser.getUserData.campus_idx),
    },
  });
  
  const { data: dataCampus, loading: loadingCampusList } = useQuery(GET_CAMPUS_LIST, {
    skip: !dataUser,
    variables: {
      company_idx: dataUser && dataUser.getUserData.campus.fc_company_idx,
    },
  });


  const campusSubject = useMemo(() => {
    return isAfterSchool(companyName) ? '학교' : '학원';
  }, [companyName]);

  const realColumns = useMemo(() => {
    if (isAfterSchool(companyName)) {
      const colsForBranch = columnsForAfterSchool.map((item, key) => {
        return key === 1 ? { ...item, title: `${campusSubject}명` } : { ...item };
      });
      if (hasBranch) {
        const add = {
          title: '지사',
          dataIndex: 'branch_name',
          key: 'branch_name',
          align: 'center',
        };
        return [...colsForBranch.slice(undefined, 1), add, ...colsForBranch.slice(1)];
      }
      return colsForBranch;
    } else {
      return columns.map((item, key) => {
        return key === 1 ? { ...item, title: `${campusSubject}명` } : { ...item };
      });
    }
  }, [campusSubject, companyName, hasBranch]);

  const campusList = useMemo(() => {
    if (dataCampus) {
      return dataCampus.getCampusList
        .map((item, key) => {
          const { idx, type, name, phone, manager, regdate, member_count, region } = item;
          const { name: director_name, phone: director_phone } = manager;

          return {
            key: key,
            // No: isAfterSchool(companyName) ? key + 1 : parseInt(dataCampus.getCampusList.length) - parseInt(key),
            idx,
            type,
            name:name??director_name,
            phone:phone??director_phone,
            manager,
            director_name,
            director_phone,
            regdate,
            region,
            member_count,
            campusSubject,
            branch_name: item?.campus?.name || '',
            //client,
            //아래 두항목을 column에서 호출하려고 하다보니 어쩔 수 없이 전달
          };
        })
    }
    return [];
  }, [dataCampus,  , companyName]);
  const pointData = useMemo(() => {
    if (smsBranchData && campusList) {
      const smsd = smsBranchData.branchRemainedSmsPoint;
      // const campus = campusList.getCampusList;
      const tmp = _.map(smsd, function (smsitem, i) {
        const matchedItem = _.find(campusList, { idx: smsitem.campus_idx });
        if (matchedItem) {
          return _.assign({ No: i + 1 }, smsitem, matchedItem);
        } else {
          return smsitem;
        }
      });
      return tmp;
    } else return [];
  }, [smsBranchData, campusList]);

  function handleExportToExcel(e) {
    e.preventDefault();
    const time = moment(Date.now()).format('YYYY-MM-DD HH:mm')
    const fileName = `SMS point List(${time})`;
    const tmp = [...realColumns,{
      title: '충전포인트',
      dataIndex: '',
      key: '',
      align: 'center',
    }]
    ExportExcelUserSheet(tmp, pointData, fileName);
  }
  return (
    <>
      <HeaderTitle level={4}>SMS Point 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="campus-halfwrapper">
            <ListTitleWrapper>
              <Title level={5}>{campusSubject} 목록</Title>
              {isAfterSchool(companyName) && (
                <div>
                <a href="/" onClick={handleExportToExcel} className="excel-img">
                  <img style={{ height: '20px', width: '20px' }} src={imageexcel} alt="download" />
                </a>
                &nbsp;&nbsp;
               <BackButton />
               </div>
              )}
            </ListTitleWrapper>
            <CustomTable
              dataSource={pointData}
              columns={realColumns}
              pagination={{ pageSize: 10, position: ['bottomCenter'], showSizeChanger: false }}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              color="#edf3fb"
              loading={loadingCampusList}
            />
          </HalfWrapper>
        </Col>
      </Row>


    </>
  );
};

export default SMSStatic;
