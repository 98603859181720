import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import Sound from "react-sound";
import _ from "lodash";
import { useSelector } from "react-redux";
import { classStoreData } from "../../operations/store";


const SoundEffect = ({ src, onEnd, replayCnt = 1, volume}) => {
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const defaultClass = useSelector(classStoreData);
  const handleFinishPlay = () => {
    setPlayStatus(Sound.status.STOPPED);
    if (replayCnt === 0) {
      //무한반복임..
      setPlayStatus(Sound.status.PLAYING);
      return;
    }
    if (onEnd instanceof Function) {
      onEnd();
    }
  };

  useEffect(() => {
    if (src) {
      setPlayStatus(Sound.status.PLAYING);
    }
    return () => {
      setPlayStatus(Sound.status.STOPPED);
    };
  }, [src]);

  return _.isNil(volume) ? (
    <Sound
      url={src ? src : ""}
      playStatus={playStatus}
      onFinishedPlaying={handleFinishPlay}
      onError={handleFinishPlay}
    />
  ) : (
    <Sound
      //url={src ?isMobile?"":src : ""}
      url= {defaultClass === "lucid"?(src ?isMobile?"":src : ""):(src? src : "")}
      playStatus={playStatus}
      onFinishedPlaying={handleFinishPlay}
      volume={volume}
      onError={handleFinishPlay}
    />
  );
};

export default SoundEffect;
