import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import AlphabetTopArea from './AlphabetTopArea';
import AlphabetDnDContextArea from './AlphabetDnDContextArea';
import { ImagePopup } from './PopUp';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const AlphabetDnD4 = ({ customData, steps, setSteps }) => {
  const { problem } = customData;

  const [isDropEnds, setIsDropEnds] = useState(Array(2).fill(false));
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [alphabetAreaKey, setAlphabetAreaKey] = useState(0);

  const handleDragFinish = (idx) => {
    const copy = _.cloneDeep(isDropEnds);
    copy[idx] = true;
    setIsDropEnds(copy);
  };

  const handleNext = () => {
    setSteps(steps + 1);
    setIsDropEnds([false, false]);
    setIsShowPopup(false);
  };

  const handlePopupClose = () => {
    setIsDropEnds(Array(2).fill(false));
    setAlphabetAreaKey(alphabetAreaKey + 1);
    setIsShowPopup(false);
  };

  useEffect(() => {
    if (isDropEnds.every((v) => v === true)) {
      //setSteps(steps + 1);
      //setIsDropEnds([false, false]);
      setIsShowPopup(true);
    }
  }, [isDropEnds, steps, setSteps]);
  return (
    <>
      <S.RowFlex
        style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
        key={`alphabet-area-key${alphabetAreaKey}`}
        className="main_content  stage2_content  col2"
      >
        <BorderedArea className="left_box">
          <AlphabetTopArea title="Single Letter" />
          <AlphabetDnDContextArea
            letters={problem[steps].alphabets}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : 'https://cdn.cloubot.com/PM/audio/sounds/'}
            onDragFinish={() => {
              handleDragFinish(0);
            }}
          />
        </BorderedArea>
        <BorderedArea style={{ marginLeft: '24px' }} className="right_box">
          <AlphabetTopArea title="Double Letters" />
          <AlphabetDnDContextArea
            letters={problem[steps].double}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : 'https://cdn.cloubot.com/PM/audio/sounds/'}
            onDragFinish={() => {
              handleDragFinish(1);
            }}
          />
        </BorderedArea>
      </S.RowFlex>
      {isShowPopup && (
        <ImagePopup
          discImage={problem[steps].discImage}
          wordImage={problem[steps].wordImage}
          word={problem[steps].word}
          number={steps + 1}
          total={problem.length}
          sound={problem[steps].sound}
          onNext={handleNext}
          onClose={handlePopupClose}
        />
      )}
    </>
  );
};

export default AlphabetDnD4;

const BorderedArea = styled(S.BorderColumnBox)`
  flex: 1 1;
  position: relative;
`;
