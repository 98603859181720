import React from 'react';
import { userInfoVar } from 'src/apollo/cache';
import LevelUpFlashCard from 'src/components/LevelUp/Flashcard';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { LEVEL_UP_STUDY_DATA } from 'src/operations/queries/externalStudy';
import { CREATE_EXTERNAL_USER_STUDY } from 'src/operations/mutations/externalUserStudy';
import _ from 'lodash';

export default function LevelUpVocaCard({ location }) {
  const { state } = location;
  const { idx, assignedIdx } = useParams();
  const history = useHistory();

  const [createExternalUserStudy, { loading: loadingSave }] = useMutation(CREATE_EXTERNAL_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료
    },
    onError(error) {
      console.log('error', error);
    },
  });
  const gotoNext = async (data,cb) => {
    const external_user_study_input = {
      user_idx: !_.isNil(userInfoVar()?.idx) ? parseInt(userInfoVar()?.idx) : 0,
      external_study_idx: state?.bookData?.book_no,
      // assigned_idx: parseInt(assignedIdx),
      type: 'levelup',
      // lesson_code: lessonCode,
      // stage_no: parseInt(stageNumber),
      article_data: state?.bookData || null,
      exam_total: 100,
      exam_correct: 100,
      stage_no: 1,
      stage_answers: JSON.stringify(data?.stage_answers || []),
    };

    if (state?.bookData?.assignedIdx){
      external_user_study_input['assigned_idx'] = parseInt(state?.bookData?.assignedIdx);
    }

    await createExternalUserStudy({
      variables: { external_user_study_input },
    });
if(cb){
  cb();
}
    // history.goBack();
  };

  return <LevelUpFlashCard assigned_idx={parseInt(state?.bookData?.assignedIdx)} noImage={state?.bookData?.mx_level>4} type={'voca'} onEnd={gotoNext} bookData={state?.bookData} />;
}
