import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Col, Row, Form, Select, Input, Spin } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import ClassSelectBox, { useCampusClassForBookCredit } from 'src/components/common/ClassSelectBox';
import CustomButton from 'src/components/common/CustomButton';
import BookCreditSelectBox from './BookCreditSelectBox';
import { GET_CAMPUS_BOOK_CREDIT_COUNT } from 'src/operations/queries/bookCredit';

const { Option } = Select;

const AssignSearchForm = ({ company_idx, campus_idx, onSearch, onChangeSearchValues, form,companyName,loadingAssignBook,handleSubmitTicket }) => {
  const [selectedBookCredit, setSelectedBookCredit] = useState([]);
  //const [form] = Form.useForm();
  const { selectedClassInfo, onClassChange } = useCampusClassForBookCredit();
  const [getTiketCount, { data: dataTicketCount, loading: loadingTicketCount }] = useLazyQuery(GET_CAMPUS_BOOK_CREDIT_COUNT);

  useEffect(() => {
    if (dataTicketCount) {
      form.setFieldsValue({
        book_code_count: dataTicketCount.getCampusBookCreditCount,
      });
    } else {
      form.setFieldsValue({ book_code_count: 0 });
    }
  }, [dataTicketCount, form]);

  useEffect(() => {
    const [class_idx] = selectedClassInfo.split('|');
    const selectedClassIdx = parseInt(class_idx);
    form.setFieldsValue({ class_idx: selectedClassIdx });
  }, [form, selectedClassInfo]);
  // useEffect(() => {
  //   const [class_idx, book_code] = selectedClassInfo.split('|');
  //   const selectedClassIdx = parseInt(class_idx);
  //   if (selectedClassIdx > 0) {
  //     const [parentBookCode] = book_code.split('-');
  //     setSelectedBookCredit([parentBookCode, book_code]);
  //   } else {
  //     setSelectedBookCredit([]);
  //   }
  // }, [selectedClassInfo]);

  useEffect(() => {
    onChangeSearchValues();
  }, [selectedClassInfo, selectedBookCredit, onChangeSearchValues]);

  const handleClassChange = useCallback(
    (value) => {
      if (campus_idx) {
        console.log('handleClassChange', value);
        // const [,  begin_date, end_date] = value.split('|');
        
        // form.setFieldsValue({ begin_date, end_date });
        // getTiketCount({ variables: { campus_idx, book_code } });
        onClassChange(value);
      }
    },
    [campus_idx, onClassChange  ],
  );
  const handleBookCreditChange = useCallback(
    (value) => {
      if (company_idx) {
        const [, book_code, days] = value;
        form.setFieldsValue({ book_code, begin_date: '', end_date: '', days });
        getTiketCount({ variables: { campus_idx, book_code } });
        onChangeSearchValues();
      }
    },
    [company_idx, campus_idx, form, getTiketCount, onChangeSearchValues],
  );

  return (
    <Form className="form-wrapper-ticket" layout="horizontal" form={form} onFinish={onSearch} initialValues={{ st: 'name' }}>
      <Form.Item name="class_idx" hidden={true}>
        <Input name="class_idx" hidden={true} />
      </Form.Item>
      <Form.Item name="book_code" hidden={true}>
        <Input name="book_code" hidden={true} />
      </Form.Item>
      <Form.Item name="book_code_count" hidden={true}>
        <Input name="book_code_count" hidden={true} />
      </Form.Item>
      <Form.Item name="begin_date" hidden={true}>
        <Input name="begin_date" hidden={true} />
      </Form.Item>
      <Form.Item name="end_date" hidden={true}>
        <Input name="end_date" hidden={true} />
      </Form.Item>
      <Form.Item name="days" hidden={true}>
        <Input name="days" hidden={true} />
      </Form.Item>
      <Row className="form-row-ticket">
        <Col span={12} className="form-ticket-sec-a">
          <HeaderTitle className="form-ticket-heading" level={5} style={{ paddingBottom: 10 }}>
            학생 선택
          </HeaderTitle>

          <Row className="form-ticket-row-main" gutter={[0, 16]}>
            <Col span={4} className="form-ticket-col-main-lbl">
              <label>반</label>
            </Col>
            <Col span={20} className="form-ticket-col-main-field">
              <ClassSelectBox
                mode="book_credit"
                onClassChange={handleClassChange}
                selectedClassInfo={selectedClassInfo}
                campus_idx={campus_idx}
              />
            </Col>

            <Col span={4} className="form-ticket-col-main-lbl">
              <label>검색어</label>
            </Col>
            <Col span={20} className="form-ticket-col-main-field">
              <Input.Group compact>
                <Form.Item name="st">
                  <Select style={{ width: '100%',marginBottom:0 }}>
                    <Option value="name">이름</Option>
                    <Option value="id">아이디</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="sv">
                  <Input style={{ width: '100%',marginBottom:0 }} placeholder="검색어 입력" />
                </Form.Item>
              </Input.Group>
            </Col>
            <Col span={4} className="form-ticket-col-main-lbl" style={{marginTop:-30}}>
              {companyName === 'englishtap' && (
                <CustomButton
                  onClick={handleSubmitTicket}
                  style={{
                    marginRight: 10,
                    background: '#C4C4C4',
                    color: '#555555',
                    // marginTop:0
                  }}
                  loading={loadingAssignBook}
                >
                  이용권부여
                </CustomButton>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={12} className="form-ticket-sec-a">
          <HeaderTitle className="form-ticket-heading" level={5} style={{ paddingBottom: 10 }}>
            이용권 선택 &nbsp; {companyName === 'englishtap' &&<span style={{color:'red'}}> ET: English Tap, KTP: Kids Tap Phonics, LTR: Lime Tree Readers</span>}
          </HeaderTitle>
          <Row gutter={[0, 16]} className="form-ticket-row-main">
            <Col span={4} className="form-ticket-col-main-lbl">
              <label>교재선택</label>
            </Col>
            <Col span={20} className="form-ticket-col-main-field">
              <BookCreditSelectBox company_idx={company_idx} initSelectedValue={selectedBookCredit} onChange={handleBookCreditChange} />
            </Col>

            <Col span={4} className="form-ticket-col-main-lbl">
              <label>잔여갯수</label>
            </Col>
            <Col span={20} className="form-ticket-col-main-field">
              {loadingTicketCount ? <Spin size="small" /> : dataTicketCount ? dataTicketCount.getCampusBookCreditCount : 0}
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }} className="form-ticket-col-main">
              <CustomButton
                style={{
                  marginRight: 10,
                  background: '#C4C4C4',
                  color: '#555555',
                }}
                htmlType="submit"
              >
                검색
              </CustomButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default AssignSearchForm;
