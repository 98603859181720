import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Col, Row, DatePicker, Space, Input } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import * as queries from 'src/operations/queries';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils/index';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { useHistory } from 'react-router-dom';
import BookrMenuGroup from 'src/components/common/BookrMenuGroup';
import NewsOMacticMenuGroup from 'src/components/common/NewsOMacticMenuGroup';
const ExternalStudyAssignResultTable = styled(CustomTable)`
  colgroup {
    display: table-column-group !important;
  }
`;

const sortByDate = (isDescend) => (a, b) => {
  if (moment(a.org_date).isSame(b.org_date, 'day')) {
    return 0;
  }
  const compared = isDescend ? moment(a.org_date).isAfter(b.org_date, 'day') : moment(a.org_date).isBefore(b.org_date, 'day');
  if (compared) {
    return -1;
  }
  return 1;
};

const PortfolioList = () => {
  const { type } = useParams();
  // const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM'));
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const companyName = useSelector(classStoreData);
  const [localState, setLocalState] = UseHistoryState({
    searchDate: moment().format('YYYY-MM'),
    searchValue: '',
  });
  const {
    data: fetchExternalStuiesStatic,
    refetch,
    loading,
  } = useQuery(queries.externalStudy.EXTERNAL_PORTFOLIO_STATICS, {
    fetchPolicy: 'no-cache',
    variables: {
      class_idx: selectedClassIdx || null,
      type: type,
      ym: localState.searchDate ? moment(localState.searchDate).format('YYYY-MM') : null,
    },
  });
  const history = useHistory();
  const externalStuiesStatic = useMemo(() => {
    if (fetchExternalStuiesStatic?.getExternalStuiesStatic) {
      let fetchData = null;
      const class_idx = parseInt(selectedClassIdx);

      fetchData = fetchExternalStuiesStatic?.getExternalStuiesStatic;

      return fetchData.reduce((acc, cur, index) => {
        const totalCnt = cur?.assigned_external_study?.length;

        const obj = {
          key: cur.idx,
          No: index + 1,
          name: cur?.data?.user?.name,
          className: cur?.data?.class?.name,
          total: cur?.study_count,
          user_idx: cur?.data?.user?.idx,
          type: type,
          ym: localState.searchDate ? moment(localState.searchDate).format('YYYY-MM') : null,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchExternalStuiesStatic?.getExternalStuiesStatic, selectedClassIdx]);

  const handleDateChange = (date, dateString) => {
    setLocalState((prev) => ({
      ...prev,
      searchDate: dateString,
    }));
  };
  let columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      align: 'center',
    },
    {
      key: 'total',
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      width: 170,
      sorter: (a, b) => a.total - b.total,
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
    },
    {
      key: 'Edit',
      title: 'View',
      dataIndex: 'View',
      align: 'center',
      render: (_, row) => {
        const { user_idx, type, ym } = row;
        return (
          <Button
            onClick={() => {
              const url = type === 'bookr'
              ? `/my-bookr-study` : type==='levelup'? `/level-up/results/history`:'/my-external-study'
              history.push(
                `${url}?student_idx=${user_idx}&ym=${ym}`,
                { currentPage: localState.currentPage },
              );
            }}
          >
            <span>View </span>
          </Button>
        );
      },
    },
  ];
  return (
    <div className="manage-action-wrapper-main">
      <div
        className="action-wrapper-main-inner"
        style={{
          height: '10vh',
          width: '100%',
        }}
      >
        <Row>
          <HeaderTitle level={4}>
            {isAfterSchool(companyName) && type === 'bookr' ? (
              <>
                Records(전체도서 학습결과)
                <span style={{ paddingLeft: 10, fontSize: '0.7em' }}>
                  *배정되지 않은 도서를 포함하여 학습한 모든 도서의 학생별 학습결과를 확인하실 수 있습니다.
                </span>
              </>
            ) : (
              `${type.toUpperCase()} Portfolio`
            )}
          </HeaderTitle>
        </Row>
        <div className="toparea-main">{companyName !== 'englishtap' && <Space></Space>}</div>

        <div style={{ display:'flex',justifyContent:'space-between'}}>
          <div>
          {(type === 'newsomatic'&&<NewsOMacticMenuGroup currentMenu={'records'}/>)}
          </div>
          <div>
          <Space>
            <ClassSelectBox
              style={{ width: '200px' }}
              onClassChange={onClassChange}
              selectedClassIdx={selectedClassIdx}
              campus_idx={dataUser?.getUserData?.campus_idx}
            />
            <DatePicker
              style={{ marginLeft: '10px' }}
              picker="month"
              value={localState.searchDate ? moment(localState.searchDate) : null}
              onChange={(date, dateString) => {
                handleDateChange(date, dateString);
              }}
            />
            <Input
              placeholder="학생 검색"
              value={localState.searchValue}
              onChange={(e) => {
                setLocalState((prev) => {
                  return {
                    ...prev,
                    searchValue: e.target.value,
                  };
                });
              }}
              style={{ width: '150px', marginRight: 10 }}
            />
          </Space>
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <HalfWrapper className="books-result-halfwrapper">
            <ExternalStudyAssignResultTable
              style={{ width: '98%' }}
              dataSource={
                localState.searchValue ? externalStuiesStatic.filter((e) => e.name.includes(localState.searchValue)) : externalStuiesStatic
              }
              columns={columns}
              key={(r) => r.idx}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default PortfolioList;
