import React from 'react';
import { Col, Row } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import { BookCard, BookCover, BookMeta, BookTitle, BOOK_COL_STYLE, THUMBNAIL_BASE_URL } from './CommonComp';

const LEVELS = [1, 2, 3, 4, 5, 6];
const VOLUMES = [1, 2];
const EBOOK_BASE_URL = 'https://online.flipbuilder.com/sangbong91/';
const URL = [["yrao",	"crnm"],	["aftn",	"tcew"],	["goqa",	"unyf"],	["brel",	"afks"],	["ldtg",	"xgqt"],	["vewz",	"alxk"],]
const VocaTap = () => {
  const { levelNo, onChangeLevelNo } = useLevelTab();
  return (
    <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
      <LevelTab
        levelNo={levelNo}
        levels={LEVELS}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />
      {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv, key1) => (
        <Row gutter={[16, 16]} key={`row-key${key1}`} style={{ margin: '0 10px' }}>
          {VOLUMES.map((vol, key2) => (
            <Col span={4} key={`col-${key1}-${key2}`} style={{ ...BOOK_COL_STYLE, borderRight: `${vol === 2 ? '1px solid #dedede' : ''}` }}>
              <BookTitle level={5} style={{ textAlign: 'center' }}>
                VocaTap {`${lv}-${vol}`}
              </BookTitle>
              <BookCard
                onClick={() => {
                  window.open(EBOOK_BASE_URL+URL[lv-1][vol-1], '_blank', 'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes');
                }}
                style={{ marginLeft: 5, marginRight: 5 }}
                cover={
                  <BookCover
                    style={{
                      backgroundImage: `url(${THUMBNAIL_BASE_URL}vocatap_${lv}_${vol}.jpg)`,
                      border: '0px solid #fff',
                    }}
                  />
                }
              >
                <BookMeta />
              </BookCard>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default VocaTap;
