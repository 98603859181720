import React from 'react';
import { Tabs } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import styled from 'styled-components';

import Phonics from './Phonics';
import PhonicsMonster from './PhonicsMonster';
// import JunglePhonics from './JunglePhonics';
// import CreoAwesome from './CreoAwesome';
// import CreoAwesomeStarter from './CreoAwesomeStarter';
// import CreoTown from './CreoTown';
import ESLRainbow from './ESLRainbow';
// import Crossover from './Crossover';
import ESLCrossover from './ESLCrossover';
import WinterBook from './WinterBook';
// import { userInfoVar } from 'src/apollo/cache';

const BookLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  // min-width: 1200px;
  min-width: 100%;
  background-color: #fff;

  @media screen and (max-width: 1024px) {
    min-width: auto;
    width: 100%;
  }
`;

const SmartTabs = styled(Tabs)`
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    background-color: #e6fcf5 !important;
  }
`;
const { TabPane } = Tabs;
const CATEGORY = [
  { name: 'Phonics Booster', comp: <Phonics /> },
  { name: 'Phonics Monster', comp: <PhonicsMonster /> },
  { name: 'ESL Rainbows', comp: <ESLRainbow /> },
  { name: 'Crossover', comp: <ESLCrossover /> },
];

const Creo = () => {
  const getComponent = (key) => {
    return CATEGORY[key].comp;
  };
  return (
    <>
      <HeaderTitle level={4} style={{ fontSize: '1rem' }}>
        EBook
      </HeaderTitle>
      <BookLayout>
        <SmartTabs defaultActiveKey={0} type="card" size="large" onChange={() => {}} style={{ width: '100%' }}>
          {CATEGORY.map((item, key) => (
            <TabPane tab={item.name} key={key}>
              {getComponent(key)}
            </TabPane>
          ))}
        </SmartTabs>
      </BookLayout>
    </>
  );
};

export default Creo;