import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
import { DELETE_BOOK_RETURN } from 'src/operations/mutations/bookReturn';

// import moment from 'moment';
// import { koreanNumberFormat } from 'src/utils';
// import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';

const STATUS_SENTENCE = ['반품 접수 처리 중입니다.', '반품 완료되었습니다.'];

const BookReturnStatusModal = ({ visible, return_stat, bookData, comment, idx, onCancel, onRefetch }) => {
  //const companyName = useSelector(classStoreData);
  const dataSource = useMemo(() => {
    if (bookData) {
      return bookData.map((item, key) => {
        return {
          key: `book-list${item.idx}`,
          no: key + 1,
          name: item?.product_name || item?.product_code,
          count: item?.quantity,
        };
      });
    }
    return [];
  }, [bookData]);
  const [deleteBookReturn, { loading }] = useMutation(DELETE_BOOK_RETURN, {
    onCompleted: ({ deleteBookReturn: res }) => {
      if (res.success) {
        Modal.info({
          title: '반품취소 되었습니다.',
          onOk: () => {
            onRefetch();
            onCancel();
          },
        });
      } else {
        alert(res.message);
      }
    },
    onError: (e) => {
      console.log();
      alert('삭제중 오류가 발생했습니다.');
    },
  });
  return (
    <Modal
      visible={visible}
      title={`반품 교재 리스트`}
      onCancel={onCancel}
      width={'60%'}
      footer={
        <Button key="back" onClick={() => onCancel()}>
          확인
        </Button>
      }
    >
      <div>{STATUS_SENTENCE[return_stat]}</div>
      <CustomTable
        pagination={{ pageSize: 12, position: ['bottomCenter'] }}
        dataSource={dataSource}
        columns={columns}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
      />
      <p>{comment}</p>
      <div style={{ textAlign: 'center' }}>
        {return_stat === 0 && (
          <Button
            type="primary"
            key="back"
            loading={loading}
            onClick={() => {
              Modal.confirm({
                title: '정말 취소하시겠습니까?',
                onOk: () => {
                  deleteBookReturn({ variables: { idx } });
                },
                onCancel: () => {
                  //do nothing
                },
              });
            }}
          >
            반품취소
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default BookReturnStatusModal;

const columns = [
  {
    title: '번호',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '교재',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '권 수',
    dataIndex: 'count',
    key: 'count',
  },
];
