import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const LevelUpBookReportMenuGroup = ({ currentMenu }) => {
  const history = useHistory();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
      <MenuButton
        type={`${currentMenu === 'level-up-report-management' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/level-up/report/list');
        }}
      >
        Book List
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'level-up-report-assign-list' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/level-up/report/assign-list');
        }}
      >
        Book Report Assign List
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'level-up-report-result' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/level-up/report/result');
        }}
      >
        Book Report Result
      </MenuButton>
    </div>
  );
};

export default LevelUpBookReportMenuGroup;
