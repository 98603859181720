import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Row, Typography, Modal, Button, DatePicker, Radio, Checkbox, Form, Input, Tooltip, Space, Select } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ClassRoomModal from 'src/components/Modal/ClassRoomModal';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';

import UseHistoryState from 'src/components/common/UseHistoryState';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek, getMonthDateRange } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { userVar } from 'src/apollo/cache';

const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const options = [
  { label: '진행중', value: true },
  { label: '종료', value: false },
];

function AssignLesson() {
  /*const userType = userInfoVar()?.type;*/
  const [tab, setTab] = useState('main');
  const [classList, setClassList] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [totalCurrentStudyingStudent, setTotalCurrentStudyingStudent] = useState(0);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const [searchValue, setSeachValue] = useState('');
  const [assignLessonList, setassignLessonList] = useState([]);
  const [assignLessonListSearch, setassignLessonListSearch] = useState([]);

  const [InputFolder, setInputFolder] = useState('');

  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  const userType = useMemo(() => userVar()[data?.me?.type] || userVar()[0], [data]);

  const { visible, showModal, handleCancel, popupMode } = useModal();

  /*const { data: OfflineSyllabusTextbooklist,refetch, loading } = useQuery(queries.offlinesyllabus.OFFLINE_SYLLABUS_TEXTBOOK_LIST, {
      fetchPolicy: 'no-cache'
  });*/

  const {
    data: OfflineSyllabusProgramList,
    refetch,
    loadingprogram,
  } = useQuery(queries.offlinesyllabus.OFFLINE_TEXTBOOK_PROGRAM_LIST, {
    fetchPolicy: 'no-cache',
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRows.map((data) => data.Assign));
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const [deleteUser] = useMutation(mutations.offlinesyllabus.OFFLINE_ADD_SYLLABUS_LIST_BULK_DELETE, {
    onCompleted(data) {
      if (data) {
        refetch();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const columns = [
    {
      title: 'Level',
      dataIndex: 'Level',
      key: 'Level',
      align: 'center',
      sorter: (a, b) => a.Level.localeCompare(b.Level),
      width: '8%',
    },
    {
      title: 'Program Name',
      dataIndex: 'ProgramName',
      key: 'ProgramName',
      align: 'center',
      width: '30%',
    },
    {
      title: 'Course / Subject Title',
      dataIndex: 'Course_Subject_Title',
      key: 'Course_Subject_Title',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Last Update',
      dataIndex: 'Last_Update',
      key: 'Last_Update',
      align: 'center',
      render: (text, record) => <span style={{ color: 'orange' }}>{record.Last_Update}</span>,
    },
    {
      title: 'View Syllabus',
      key: 'Assign',
      align: 'center',
      render: (text, record) => (
        <Button style={{ background: '#B8E4F0' }}>
          <Link to={{ pathname: `/offline/adminedit/syllabus/${record.ProgramName}/${record.Level}/adminview` }}>View</Link>
        </Button>
      ),
    },
    {
      title: 'Edit Syllabus',
      dataIndex: 'Edit_Syllabus',
      selectedRowKeys: 'Edit_Syllabus',
      align: 'center',
      render: (text, record) => {
        var disabled = null;
        if (record.folder_type == 'publisher') {
          disabled = false;
        } else {
          disabled = true;
        }
        if (InputFolder !== '') {
          disabled = InputFolder == 'publisher' ? false : true;
        }
        return (
          <Space size="middle">
            {disabled ? (
              <Button disabled={true} style={{ background: '#B8E4F0' }}>
                <Link to={{ pathname: `/offline/edit/syllabus/${record.ProgramName}/${record.Level}/update` }}>Update</Link>
              </Button>
            ) : (
              <Button style={{ background: '#B8E4F0' }}>
                <Link to={{ pathname: `/offline/edit/syllabus/${record.ProgramName}/${record.Level}/update` }}>Update</Link>
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    let assignprogramlist = OfflineSyllabusProgramList?.offlineTextbookProgramList.map((loopitem, loopkey) => {
      let obj = {
        No: loopkey + 1,
        IDX: loopitem.idx,
        key: loopitem.idx,
        ProgramName: loopitem.programname,
        Bookcode: loopitem.offline_syllabus_textbook_bookcode,
        Course_Subject_Title: `${loopitem.offline_syllabus_textbook_booktitle}`,
        Level: loopitem.level,
        Last_Update: loopitem.updatedby.split('T')[0],
        Edit_Syllabus: loopitem.offline_syllabus_textbook_bookcode,
        Assign: loopitem.offline_syllabus_textbook_bookcode,
        folder_type: loopitem.folder_type,
      };
      return obj;
    });
    setassignLessonListSearch(assignprogramlist);
    setassignLessonList(assignprogramlist);
  }, [OfflineSyllabusProgramList]);

  /*useEffect(() => {
    if(OfflineSyllabusTextbooklist?.offlineTextbookList){
       let assignbooklistresult = OfflineSyllabusTextbooklist?.offlineTextbookList.map((loopitem,loopkey) => {
        let obj = {
          "No":loopkey+1,
          "key":loopitem.idx,
          "Level":loopitem.level,
          "Course_Subject_Title":`${loopitem.booktitle}`,
          "Last_Update":(loopitem.registerdate).split("T")[0],
          "Edit_Syllabus":loopitem.bookcode,
          "Assign":loopitem.bookcode
        }
        return obj;
       })
       setassignLessonListSearch(assignbooklistresult);
       setassignLessonList(assignbooklistresult);
    }
  },[OfflineSyllabusTextbooklist])*/

  const handleSearch = (e) => {
    if (e !== '') {
      let searchresult = assignLessonListSearch.map((loopitem, loopkey) => {
        let findSubjectTitlestring = loopitem.Course_Subject_Title.toLowerCase();
        let subjectTitlePattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');
        let findProgramname = loopitem.ProgramName.toLowerCase();
        let programNamePattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');
        if (findSubjectTitlestring.match(subjectTitlePattern) !== null || findProgramname.match(programNamePattern) !== null) {
          let obj = {
            No: loopkey,
            IDX: loopitem.IDX,
            key: loopitem.key,
            Level: loopitem.Level,
            ProgramName: loopitem.ProgramName,
            Course_Subject_Title: `${loopitem.Course_Subject_Title}`,
            Last_Update: loopitem.Last_Update,
            Edit_Syllabus: loopitem.bookcode,
            Assign: loopitem.Assign,
            folder_type: loopitem.folder_type,
          };
          return obj;
        }
      });
      setassignLessonList(
        searchresult.filter(function (e) {
          return e;
        }),
      );
    } else {
      console.log(e);
      window.location.reload();
      setassignLessonList(assignLessonListSearch);
    }
  };

  function handleLevelChange(e) {
    if (e !== '') {
      let searchresult = assignLessonListSearch.map((loopitem, loopkey) => {
        if (e == loopitem.Level) {
          let obj = {
            No: loopkey,
            IDX: loopitem.IDX,
            key: loopitem.key,
            Level: loopitem.Level,
            ProgramName: loopitem.ProgramName,
            Course_Subject_Title: `${loopitem.Course_Subject_Title}`,
            Last_Update: loopitem.Last_Update,
            Edit_Syllabus: loopitem.bookcode,
            Assign: loopitem.Assign,
            folder_type: loopitem.folder_type,
          };
          return obj;
        } else if (e == 'All') {
          let obj = {
            No: loopkey,
            IDX: loopitem.IDX,
            key: loopitem.key,
            Level: loopitem.Level,
            ProgramName: loopitem.ProgramName,
            Course_Subject_Title: `${loopitem.Course_Subject_Title}`,
            Last_Update: loopitem.Last_Update,
            Edit_Syllabus: loopitem.bookcode,
            Assign: loopitem.Assign,
            folder_type: loopitem.folder_type,
          };
          return obj;
        }
      });
      setassignLessonList(
        searchresult.filter(function (e) {
          return e;
        }),
      );
    } else {
      setassignLessonList(assignLessonListSearch);
    }
  }

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  const handleFolderChangepopup = (e) => {
    setInputFolder(e);
    if (e !== '') {
      let searchresult = assignLessonListSearch.map((loopitem, loopkey) => {
        if (e == loopitem.folder_type) {
          let obj = {
            No: loopkey,
            IDX: loopitem.IDX,
            key: loopitem.key,
            Level: loopitem.Level,
            ProgramName: loopitem.ProgramName,
            Course_Subject_Title: `${loopitem.Course_Subject_Title}`,
            Last_Update: loopitem.Last_Update,
            Edit_Syllabus: loopitem.bookcode,
            Assign: loopitem.Assign,
            folder_type: loopitem.folder_type,
          };
          return obj;
        }
      });
      setassignLessonList(
        searchresult.filter(function (e) {
          return e;
        }),
      );
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%', overflowY: 'hidden !important', overflowX: 'hidden !important' }}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY: 'hidden !important' }}>
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                &nbsp;Assign Lessons
              </Title>
              <div className="header-classroom-search">
                <Form
                  form={form}
                  initialValues={{
                    layout: formLayout,
                  }}
                >
                  <Space>
                    <span style={{ textAlign: 'center', display: 'flex', alignItems: 'center', marginRight: '10px', height: '32px' }}>
                      Folder:
                    </span>
                    <Select style={{ width: '120px' }} value={InputFolder} onChange={handleFolderChangepopup}>
                      <Option value="publisher">본사 컨텐츠</Option>
                      <Option value="campus">학원 컨텐츠</Option>
                      <Option value="me">개인 컨텐츠</Option>
                    </Select>
                    <Input.Search
                      className="inputsearch-classroom"
                      placeholder="Content Search"
                      allowClear
                      style={{ marginRight: 10, background: '#B8E4F0' }}
                      enterButton
                      onSearch={handleSearch}
                      onChange={onSeachChange}
                      value={searchValue}
                    />
                    <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                      <RedoOutlined />
                    </CustomButton>
                    <Select placeholder="level" onChange={handleLevelChange} style={{ width: '150px' }}>
                      <Option key="13" value="All">
                        All Level
                      </Option>
                      <Option value="Phonics">Phonics</Option>
                      <Option value="1">level 1</Option>
                      <Option value="2">level 2</Option>
                      <Option value="3">level 3</Option>
                      <Option value="4">level 4</Option>
                      <Option value="5">level 5</Option>
                      <Option value="6">level 6</Option>
                      <Option value="7">level 7</Option>
                      <Option value="8">level 8</Option>
                      <Option value="9">level 9</Option>
                      <Option value="10">level 10</Option>
                      <Option value="11">level 11</Option>
                      <Option value="12">level 12</Option>
                    </Select>
                    <Button
                      className="btn-schedule"
                      type={'primary'}
                      size="large"
                      //style={{background: "#47a6fd", borderColor: "#47a6fd"}}
                      style={{ background: '#73C2FB', borderColor: '#73C2FB', borderRadius: '5px' }}
                    >
                      <Link to="/offline/textbook/list">Book Settings</Link>
                    </Button>
                    <Button
                      className="btn-schedule"
                      type={'primary'}
                      size="large"
                      onClick={() => setTab('main')}
                      //style={{ background: "#127612", borderColor: "#127612" }}
                      style={{ background: '#4e9b4e', borderColor: '#1d641d', borderRadius: '5px' }}
                    >
                      <Link to="/offline/add/syllabus">Add Syllabus +</Link>
                    </Button>
                  </Space>
                  <span style={{ width: '10px' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </Form>
              </div>
            </div>

            <CustomTable
              rowClassName={{ background: '#B8E4F0' }}
              className="classroom-table-table"
              dataSource={assignLessonList}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: 12 }}
              size="small"
              color="#B8E4F0"
              style={{ float: 'center', borderColor: '#B8E4F0' }}
              bordered={true}
              scroll={{ y: 'calc(100vh - 254px)' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {},
                };
              }}
            />
            <CustomButton
              danger
              style={{
                position: 'relative',
                top: '-7%',
                left: '40px',
              }}
              className="assign-danger-btn"
              onClick={deleteConfirm}
            >
              Delete
            </CustomButton>
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
}

export default AssignLesson;
