import React, { useMemo } from 'react';
import { Col, Row,   Dropdown, Menu } from 'antd';
// import VolumeTab, { useVolumeTab } from './VolumeTab';

import {
  BookCard,
  BookCover,
  // BookMeta,
  BookTitle,
  // BOOK_COL_STYLE,
  // SMART_ECLASS_LINK_BUTTON_STYLE,
  // SMART_ECLASS_CDN_BASE_URL,
  // THUMBNAIL_BASE_URL,
  CREO_PPT_CDN_BASE_URL,
} from './CommonComp';

// const LEVELS = [1];
const SECTIONS = [1, 2,3,4,5];
const ReviewList = [3,4,5,10,13,14,15,18,19,20]

// const handleSetupFileDownload = (volume) => {
  //window.open(`http://admin.smart-eclass.co.kr/include/common/downFile3.php?filename=kids_tap_${volume}.zip`);
// };

const Phonics = () => {
  // const { levelNo, onChangeLevelNo } = useVolumeTab();
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      {/* <VolumeTab
        levelNo={levelNo}
        levels={LEVELS}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      /> */}

        <Row
          key={`row-${1}`}
          gutter={[16, 16]}
          style={{
            margin: '0 10px',
          }}
        >
          {SECTIONS.map((vol) => (
            <Col
              span={6}
              key={`col-0-${vol}`}
              style={{
                display: 'flex',
                border: '1px solid #eee',
              }}
              className="CAWrap phonicsCAWrap"
            >
              <div className="CreoBook-con">
                <div className="Creo-titWrap">
                  <BookTitle
                    level={2}
                    style={{
                      display: `${vol === 1 ? ' block' : ' none'}`,
                    }}
                    className="CreoBookTitle"
                  >
                    {`Phonics Booster`}
                  </BookTitle>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    textAlign: 'center',
                  }}
                  className={`bookCardCon ${0 < 4 && vol === 1 ? 'ddd' : ''}`}
                >
                  <h2 className="SectionTit" >{`Section ${vol}`}</h2>
                  <div className="bookCardWrap">
                    <BookCard
                      cover={
                        <BookCover
                          style={{
                            backgroundImage: `url(https://cdn.cloubot.com/PHB/cover/L0V1.png)`,
                          }}
                        />
                      }
                    >
                      {/* <BookMeta description={<EbookLinkGroup level={lv} volume={vol} />} /> */}
                    </BookCard>
                    <UnitGroup level={0} section={vol} />
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
    </div>
  );
};

export default Phonics;

const UnitGroup = ({ level, section }) => {
  return (
    <BookCard className="unit-con">
      {Array(4)
        .fill(0)
        .map((_, key) => {
          const unitNo = (section - 1) * 4 + key + 1;
          return (
            <div key={`lesson-list${key}`} className="unit-wrap">
              {/* <LessonDropdown level={level} unit={unitNo}> */}
                <h2
                onClick={()=>{
                  window.open(`https://cdn.cloubot.com/ppt/ESL/Phonics_Booster_U${unitNo}/index.html`,'_blank')
                }}
                >UNIT {unitNo}{ReviewList.includes(unitNo)&&' + Review'}</h2>
              {/* </LessonDropdown> */}
            </div>
          );
        })}
      {/* {level === 4 && (
        <div key={`lesson-list-review`} className="unit-wrap">
          <LessonDropdown level={4} unit={`review-${section}`}>
            <h2>Review</h2>
          </LessonDropdown>
        </div>
      )} */}
    </BookCard>
  );
};

const LessonDropdown = ({ level, unit, children }) => {
  const lessonCount = useMemo(() => {
    if (unit === 'review-1' || unit === 'review-2') {
      return 1;
    }
    if (level === 4) {
      return 3;
    }
    if (unit === 5 || unit === 10) {
      return 2;
    }
    return 3;
  }, [level, unit]);
  return (
    <Dropdown
      overlay={
        <Menu>
          {Array(lessonCount)
            .fill(0)
            .map((_, key) => (
              <Menu.Item key={key}>
                <a
                  href={`${CREO_PPT_CDN_BASE_URL}Phonics_Monster_Lv${level}_${unit}-${key + 1}/index.html`}
                  rel="noreferrer"
                  target="_blank"
                >
                  Lesson{key + 1}
                </a>
              </Menu.Item>
            ))}
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
    >
      {children}
    </Dropdown>
  );
};

// const EbookLinkGroup = ({ level, volume }) => {
//   return (
//     <div style={{ display: 'flex', justifyContent: 'space-between', width: 'auto', minWidth: '10rem', paddingTop: 5, paddingBottom: 5 }}>
//       <Button
//         onClick={(e) => {
//           e.stopPropagation();
//           //window.open(`${SMART_ECLASS_CDN_BASE_URL}common/smarteclass/Smart_E_class_Level_${level}-${volume}_teacher_ppt.zip`);
//         }}
//         style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.4em', lineHeight: '1' }}
//       >
//         PPT
//       </Button>
//       <Button
//         onClick={(e) => {
//           e.stopPropagation();
//           //const culp_token = window.localStorage.getItem('culp_token');
//           //window.open(`${STUDY_TOOL_HOST}/SM-L${level}V${volume}U1D1?token=${culp_token}`, '_blank');
//         }}
//         style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.6em', width: 'auto' }}
//       >
//         E-learning
//       </Button>
//     </div>
//   );
// };