import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, Spin } from 'antd';
import { SAVE_EXTERNAL_STUDY_GOAL } from 'src/operations/mutations/evineStudy';
import { useMutation } from '@apollo/client';

function RankModal({ url, handleClose, visible, getGoal }) {
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(true);
  const [goalCnt, setGoalCnt] = useState(1);

  const [setGoal, { loading: loadingPopular }] = useMutation(SAVE_EXTERNAL_STUDY_GOAL, {
    onCompleted: (res) => {
      if (res?.SaveExternalStudyGoal) {
        if (res?.SaveExternalStudyGoal?.success === true) {
          getGoal(goalCnt);
        }
      }
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  const handleChange = (e) => {
    setGoalCnt(e.target.value);
  };
  const handleGoal = () => {
    setGoal({ variables: { goal: parseInt(goalCnt), ym: '', type: 'bookr' } });
  };
  return (
    <>
      <div className="popup__alert">
        <Modal
          centered
          visible={visible}
          onCancel={handleClose}
          width={'auto'}
          footer={null}
          className="modal__goal_set"
          closeIcon={<div className="close_btn"></div>}
        >
          <h2 className="alert__title"> 이번 달 목표 </h2>
          <div class="float__box number_select">
            <p style={{ float: 'left' }}>
              <Input onChange={(e) => handleChange(e)} value={goalCnt} className="form_input_modal" />
              <span class="number_name">권</span>
            </p>
            <p style={{ float: 'right' }}>
              <img onClick={() => setGoalCnt(goalCnt + 1)} className="btn_num" src={'../images/icon_plus.png'}></img>
              <img onClick={() => (goalCnt > 1 ? setGoalCnt(goalCnt - 1) : '')} className="btn_num" src={'../images/icon_minus.png'}></img>
            </p>
          </div>
          <div className="btn_submit">
            <Button onClick={() => handleGoal()} className="btn_color_red">
              저장하기
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default RankModal;
