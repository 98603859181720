import React from 'react';
import { InputNumber } from 'antd';
import { Select } from 'antd';
const PackageOrderTable = ({ bookTitle, root_book_code, packageCountObj, onCountChange, prices, isUseCredit }) => {
  const { Option } = Select;
  return (
    <table className="booktable-main-tbl mytable_custome" style={{ marginBottom: 10 }}>
      <tr>
        <th>수량</th>
        <th>일</th>
        <th>합계</th>
      </tr>
      <tr key={`level-row-key0`}>
        <>
          <td key={`each-input-key0-0`}>
            <InputNumber
              placeholder="수량"
              min={0}
              max={100000}
              maxLength={6}
              keyboard={false}
              onChange={(number) => {
                onCountChange(number, 'volume', prices[0]?.days ? prices[0]?.days : 0);
              }}
              onKeyPress={(e) => {
                if (e.key >= 0 && e.key <= 9) {
                  return true;
                } else {
                  e.preventDefault();
                  return false;
                }
              }}
            />
          </td>
          <td key={`each-input-key0-1`}>
            {isUseCredit ? (
              <>
                <Select onChange={(e) => onCountChange(e, 'days')} defaultValue={prices?.length ? prices[0].days : 0}>
                  {prices?.map((item1, keys) => {
                    return (
                      <>
                        <Option key={keys} value={item1.days}>
                          {item1.days}
                        </Option>
                      </>
                    );
                  })}
                </Select>
                일
              </>
            ) : (
              '-'
            )}
          </td>
          <td>{packageCountObj.volume}</td>
        </>
      </tr>
    </table>
  );
};

export default PackageOrderTable;
