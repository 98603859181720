import styled from 'styled-components';

export const ApplicationForm = styled.div`
  background-color: #fff;
  width: 550px;
  height: 600px;
  margin-bottom: 0px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Top = styled.div`
  width: 100%;
  color: #333;
  background: #50dcd5;
  padding: 10px 0px;
  text-align: center;
  z-index: 99;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Bottom = styled.div`
  width: 100%;
  text-align: center;
  z-index: 99;
  padding-bottom: 20px;
`;

export const SurveyWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 350px;
`;
