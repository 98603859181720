import React, { useCallback, useState, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Row, Form } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import Tickets from 'src/pages/Tickets';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';

import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_CAMPUS_STUDENT_USERS } from 'src/operations/queries/getUser';

import { ASSIGN_BOOK_TO_STUDENTS } from 'src/operations/mutations/bookCredit';
import AssignSearchForm from './AssignSearchForm';

import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool, isPturn } from 'src/utils';

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #ffffff;
`;

function AssignTicket({isPopup = false, classidx=undefined} ) {
  const companyName = useSelector(classStoreData);
  const [form] = Form.useForm();
  const [selectedBookCodeInfo, setSelectedBookCodeInfo] = useState({
    book_code: '',
    count: 0,
  });
  const [studentRowKeys, setStudentRowKeys] = useState([]);
  const [beginDate, setBeginDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [days, setDays] = useState(0);

  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [getStudents, { data: dataStudents, loading: loadingStudents, refetch }] = useLazyQuery(GET_CAMPUS_STUDENT_USERS);
  const [assignBook, { loading: loadingAssignBook }] = useMutation(ASSIGN_BOOK_TO_STUDENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      refetch();
      openNotification('부여완료!');
      form.submit();
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const studentList = useMemo(() => {
    if (dataStudents) {
      const {st,sv} = form.getFieldValue();
      return dataStudents.getCampusStudentUsers.map((item) => {
        console.log('item', item.book_code_list);
        const class_name = item.class_student[0]?.class?.name;
        const classIdx = item.class_student[0]?.class?.idx;
        let bookCredit = '없음';
        let disabled = false;
        let issue_date = '';
        let start_date = '';
        let end_date = '';

        if (item.book_code_list && item.book_code_list.length > 0) {
          const foundItem = item.book_code_list.find((ele) => {
            return ele.book.code === selectedBookCodeInfo.book_code;
          });
          if (foundItem) {
            console.log('foundItem', foundItem);
            disabled = true;
            bookCredit = foundItem.study_type === 'LEARN' ? '부여완료' : '체험';
            issue_date = foundItem.issue_datetime ? moment(foundItem.issue_datetime).format('YYYY-MM-DD') : '';
            start_date = foundItem.start_datetime ? moment(foundItem.start_datetime).format('YYYY-MM-DD') : '';
            end_date = foundItem.end_datetime ? moment(foundItem.end_datetime).format('YYYY-MM-DD') : '';
          }
        }
        return {
          key: `row-student-${item.idx}`,
          idx: item.idx,
          name: `${item.name}${item.english_name? `(${item.english_name})` :''}`,
          id: item.id,
          school_grade: item.school_grade,
          class_name: class_name || '',
          class_idx: classIdx || 0,
          disabled,
          bookCredit,
          issue_date,
          start_date,
          end_date,
        };
      }).filter((e)=>sv?st==='name'?e.name.includes(sv):e.id.includes(sv):e);
    }
    return [];
  }, [dataStudents, selectedBookCodeInfo]);

  const handleSearch = useCallback(
    (formValues) => {
      if (dataUser) {
        console.log('handleSearch', formValues);
        const { class_idx, book_code, book_code_count, begin_date, end_date, days } = formValues;
        if (!book_code) {
          alert('교재를 선택하세요');
          return false;
        }


        let tempBDate = begin_date;
        let tempEDate = end_date;

        if ((begin_date === '' || end_date === '') && days !== 0) {
          tempBDate = moment().format('YYYY-MM-DD');
          tempEDate = moment().add(days, 'days').format('YYYY-MM-DD');
        }

        setBeginDate(tempBDate);
        setEndDate(tempEDate);
        setDays(days);
        setSelectedBookCodeInfo({ book_code, count: book_code_count });
        getStudents({
          variables: { campus_idx: dataUser.getUserData.campus_idx, class_idx },
        });
      }
    },
    [dataUser, getStudents],
  );

  const handleSubmitTicket = (value) => {
    const { book_code, count } = selectedBookCodeInfo;

    if (book_code === '') {
      alert('이용권을 선택하세요');
      return false;
    }

    if (studentRowKeys.length === 0) {
      alert('학생을 선택하세요');
      return false;
    }

    if (studentRowKeys.length > count) {
      alert('이용권이 부족합니다.');
      return false;
    }

    const user_idx = studentRowKeys.map((keyValue) => {
      const [, , idx] = keyValue.split('-');
      return parseInt(idx);
    });
    const selectedClassIdx = form.getFieldValue('class_idx');
    const studentClassIdx = studentList.filter((item) => user_idx.includes(item.idx)).map((item) => item.class_idx);

    // console.log(user_idx, beginDate, endDate, days);

    assignBook({
      variables: {
        assign_book_input: {
          campus_idx: dataUser.getUserData.campus_idx,
          book_code,
          user_idx,
          study_type: 'learn',
          start_date: beginDate,
          end_date: endDate,
          class_idx: selectedClassIdx || null,
          student_class_idx: studentClassIdx || [],
          days: days ? days : null,
        },
      },
      refetchQueries: [
        {
          query: GET_CAMPUS_STUDENT_USERS,
          variables: { campus_idx: dataUser.getUserData.campus_idx },
        },
      ],
    });
  };

  const handleSubmitExpTicket = () => {
    //
    const { book_code } = selectedBookCodeInfo;

    if (book_code === '') {
      alert('이용권을 선택하세요');
      return false;
    }

    if (studentRowKeys.length === 0) {
      alert('학생을 선택하세요');
      return false;
    }

    // if (studentRowKeys.length > count) {
    //   alert('이용권이 부족합니다.');
    //   return false;
    // }

    const user_idx = studentRowKeys.map((keyValue) => {
      const [, , idx] = keyValue.split('-');
      return parseInt(idx);
    });

    const expEnd = moment(beginDate).add(7, 'days').format('YYYY-MM-DD');

    console.log(user_idx, beginDate, expEnd);

    const selectedClassIdx = form.getFieldValue('class_idx');
    const studentClassIdx = studentList.filter((item) => user_idx.includes(item.idx)).map((item) => item.class_idx);

    assignBook({
      variables: {
        assign_book_input: {
          campus_idx: dataUser.getUserData.campus_idx,
          book_code,
          user_idx,
          study_type: 'exp',
          start_date: beginDate,
          end_date: expEnd,
          class_idx: selectedClassIdx || null,
          student_class_idx: studentClassIdx || [],
          days: days ? days : null,
        },
      },
      refetchQueries: [
        {
          query: GET_CAMPUS_STUDENT_USERS,
          variables: { campus_idx: dataUser.getUserData.campus_idx },
        },
      ],
    });
  };

  const handleChangeSearchValues = useCallback(() => {
    console.log('change');
    getStudents({ variables: { campus_idx: 0 } });
  }, [getStudents]);

  const rowSelection = {
    selectedRowKeys: studentRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setStudentRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.disabled,
      //name: record.name,
    }),
  }; 

  return (
    <Tickets isPopup={isPopup}>
      <MyInfoWrapper className="myinfowrapper-children">
        <AssignSearchForm
          campus_idx={dataUser?.getUserData?.campus_idx}
          company_idx={dataUser?.getUserData?.campus?.fc_company_idx}
          form={form}
          onSearch={handleSearch}
          onChangeSearchValues={handleChangeSearchValues}
          companyName={companyName}
          loadingAssignBook={loadingAssignBook}
          handleSubmitTicket={handleSubmitTicket}
        />
        <Row>
          <Col span={24}>
            <CustomTable
              className="tickettable"
              dataSource={studentList}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: 6, position: ['bottomCenter'] }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loadingStudents}
            />
            {companyName !== 'englishtap' && (
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  bottom: 10,
                  position: 'absolute',
                }}
              >
                <CustomButton
                  onClick={handleSubmitTicket}
                  style={{
                    marginRight: 10,
                    background: '#C4C4C4',
                    color: '#555555',
                  }}
                  loading={loadingAssignBook}
                >
                  이용권부여
                </CustomButton>
                <CustomButton
                  onClick={handleSubmitExpTicket}
                  style={{
                    marginRight: 10,
                    background: '#C4C4C4',
                    color: '#555555',
                  }}
                  loading={loadingAssignBook}
                >
                  체험부여
                </CustomButton>
              </div>
            )}
          </Col>
        </Row>
      </MyInfoWrapper>
    </Tickets>
  );
}

const columns = [
  {
    title: '이름',
    dataIndex: 'name',
    align: 'center',
    key: 'name',
  },
  {
    title: '학생ID',
    dataIndex: 'id',
    align: 'center',
    key: 'id',
  },
  {
    title: '학년',
    dataIndex: 'school_grade',
    align: 'center',
    key: 'school_grade',
  },

  // {
  //   title: '발급일',
  //   dataIndex: 'issue_date',
  //   align: 'center',
  //   key: 'issue_date',
  // },
  {
    title: '이용권 시작',
    dataIndex: 'start_date',
    align: 'center',
    key: 'start_date',
  },
  {
    title: '이용권 만료',
    dataIndex: 'end_date',
    align: 'center',
    key: 'end_date',
  },
  {
    title: 'Class',
    dataIndex: 'class_name',
    align: 'center',
    key: 'class_name',
  },
  {
    title: '이용권',
    dataIndex: 'bookCredit',
    align: 'center',
    key: 'bookCredit',
  },
];

export default AssignTicket;
