import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Col, Row, Typography, Modal, Button, DatePicker, Radio, Checkbox, Form, Input, Tooltip, Space, Select } from 'antd';
import { PlusOutlined, RedoOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ClassRoomModal from 'src/components/Modal/ClassRoomModal';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';

import UseHistoryState from 'src/components/common/UseHistoryState';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek, getMonthDateRange } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import XLSX from 'xlsx';
import exportFromJSON from 'export-from-json'

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;
const { value } = "";

const options = [
  { label: '진행중', value: true },
  { label: '종료', value: false },
];

function OfflineSyllabus() {
  const userType = userInfoVar()?.type;
  const [tab, setTab] = useState('main');
  const { bookprogramname,bookprogramlevel } = useParams();
  const [classList, setClassList] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [totalCurrentStudyingStudent, setTotalCurrentStudyingStudent] = useState(0);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const [searchValue, setSeachValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleBatch, setVisibleBatch] = useState(false);
  const history = useHistory();

  const [syllabusLessonObject, setsyllabusLessonObject] = useState([{"dayslesson":null,
                                                                    "lessontopic":null,
                                                                    "ppturl":null
                                                                  }]);
  const [syllabusLessonObjectInitialAdd, setsyllabusLessonObjectInitialAdd] = useState([]);

  const [addsyllabusinput,setAddsyllabusinput] = useState(null);

  const [SyllabusLevel, setSyllabusLevel] = useState([]);

  const [SyllbausBookCode,setSyllbausBookCode] = useState('');
  const [SyllbausLevel,setSyllbausLevel] = useState('');

  const { showModal, handleCancel, popupMode } = useModal();
  const [fields, setFields] = useState([]);
  const [Lastregisterdate, setLastregisterdate] = useState('');
  const [searchBookcode, setsearchBookcode] = useState('');

  const [Programname,setProgramname] = useState("bookprogram");
  const [Programlevel,setProgramlevel] = useState("");
  const [InputFolder, setInputFolder] = useState('');

  const [Lessoncount,setLessoncount] = useState(0);

  const [Inputlevel, setInputlevel] = useState('');
  const SyllabusLevelResult = SyllabusLevel.map(level => level)
  const [Validation, setValidation] = useState('Select ProgramName && Level');
  const [pageProgramName,setpageProgramName] = useState("");
  const [Programinputcreate,setPrograminputcreate] = useState("");

  const[TextbookLevelForm,setTextbookLevelForm] = useState([]);
  const TextbookLevelFormFilter = TextbookLevelForm.map(Level => Level)
  
  const[oldProgram,setoldProgram] = useState("");
  const[oldProgramLevel,setoldProgramLevel] = useState("");
  
  //const [getLessonlist, { data: allLessonList }] = useLazyQuery(queries.offlinesyllabus.OFFLINE_ADD_SYLLABUS_TEXTBOOK_LESSON_LIST);
  const [getfetchtextbookbycode, { data: fetchtextbookbycode }] = useLazyQuery(queries.offlinesyllabus.OFFLINE_SYLLABUS_TEXTBOOK_LIST_BY_CODE);

  /*const { data: OfflineSyllabusTextbooklist,refetch, loading } = useQuery(queries.offlinesyllabus.OFFLINE_SYLLABUS_TEXTBOOK_LIST, {
      fetchPolicy: 'no-cache'
  });*/

  const { data: OfflineLessonList,refetch } = useQuery(queries.offlinesyllabus.OFFLINE_ADD_SYLLABUS_TEXTBOOK_LESSON_LIST, {
      fetchPolicy: 'no-cache',
      variables : {
        bookprogramname : bookprogramname,
        bookprogramlevel : bookprogramlevel
      }
  });

  const { data: OfflinebookTitleWithLevel } = useQuery(queries.offlinesyllabus.OFFLINE_TEXTBOOK_TITLE_WITH_LEVEL, {
      fetchPolicy: 'no-cache'
  });
  
  const [offlineaddlesson] = useMutation(mutations.offlinesyllabus.OFFLINE_ADD_LESSON_SYLLABUS, {
    onCompleted(data) {
      if (data) {
        refetch();
        setValidation("Lesson Added Successfully");
        setVisible(false);
        //window.location.reload();
      }
    },
    onError(error) {
      setValidation("Please try Again !!!");
      //window.location.reload();
    },
  });

  const [offlineBulklesson] = useMutation(mutations.offlinesyllabus.OFFLINE_BULK_LESSON_SYLLABUS, {
    onCompleted(data) {
      if (data) {
        setValidation("Lesson Added Successfully");
        setValidation("");
        let currentlesson = syllabusLessonObjectInitialAdd.map((loopitem,loopkey) => {
            let obj = {
                  "no":loopkey+1,
                  "dayslesson":loopitem.dayslesson,
                  "lessontopic":loopitem.lessontopic,
                  "ppturl":loopitem.ppturl,
                  "programname":Programinputcreate,
                  "programlevel":SyllbausLevel == '' ? oldProgramLevel : SyllbausLevel,
                  "plantopic"    : loopitem.plantopic,
                  "planday"      : loopitem.planday,
                  "planlearningobj"   : loopitem.planlearningobj,
                  "textbookcode1"     : loopitem.textbookcode1,
                  "textbookcode2"     : loopitem.textbookcode2,
                  "lessonplancontent" : loopitem.lessonplancontent,
                  "homeworkcontent"   : loopitem.homeworkcontent
            }
            return obj;
        })
        setsyllabusLessonObject(currentlesson)
        setVisibleBatch(false)
        setLoading(false);
        history.push('/offline/lesson/assign')
      }
    },
    onError(error) {
      setValidation('Please try again !!!');
      setValidation('');
    },
  });

  useEffect(() => {
    setLoading(true);
    if(OfflinebookTitleWithLevel?.offlineTextbookTitleWithLevel){

      let levelformation = OfflinebookTitleWithLevel?.offlineTextbookTitleWithLevel.map((loopitem,loopkey) => {
            setInputlevel(loopitem.level);
            let obj = {
              "levelname" : loopitem.level == "Phonics" ? loopitem.level : ` level - ${loopitem.level}`,
              //"levelkey" : `${loopitem.bookcode}#${loopitem.booktitle}#${loopitem.level}`
              "levelkey" : `${loopitem.level}`
            }
            return obj;
      })
      setTextbookLevelForm(levelformation);
    }
  },[OfflinebookTitleWithLevel]);

  useEffect(() => {
    if(OfflineLessonList?.offlineAddTextbookSyllabusList){
      setsyllabusLessonObject([{"dayslesson":null,"lessontopic":null,"ppturl":null}])
      setLessoncount(1)
      let lessonlistdata = OfflineLessonList?.offlineAddTextbookSyllabusList.map((loopitem,loopkey) => {
              setoldProgram(loopitem.programname)
              setoldProgramLevel(loopitem.level)
              setPrograminputcreate(loopitem.programname)
              setpageProgramName(loopitem.programname)
              setProgramlevel(loopitem.level)
              setInputFolder(loopitem.folder_type)
              let obj = {
                "no":loopkey+1,
                "idx":loopitem.idx,
                "dayslesson":loopitem.days_lesson,
                "lessontopic":loopitem.lesson_topic,
                "ppturl":loopitem.ppturl,
                //"lessonplan":"",
                "programname":loopitem.programname,
                "programlevel":loopitem.level,
                "folder_type" : loopitem.folder_type
                //"plantopic"    : loopitem.plantopic,
                //"planday"      : loopitem.planday,
                //"planlearningobj"   : loopitem.planlearningobj,
                //"textbookcode1"     : loopitem.text_bookcode1,
                //"textbookcode2"     : loopitem.text_bookcode2,
                //"lessonplancontent" : loopitem.lessonplancontent,
                //"homeworkcontent"   : loopitem.homeworkcontent
              }
              return obj;
      })
      setsyllabusLessonObject(lessonlistdata)
      setsyllabusLessonObjectInitialAdd(lessonlistdata)
      setLessoncount(lessonlistdata.length)
    }
  },[OfflineLessonList]);

  /*const handlebookcodeChange = (e) => {
      if(e !== '' && e !== undefined){
        setValidation("")
        setsearchBookcode(e)
        setsyllabusLessonObject([])
            
        if(allLessonList !== undefined){
              let lessonlistdata = allLessonList?.offlineAddTextbookSyllabusList.map((loopitem,loopkey) => {
                let obj = {
                  "no":loopkey+1,
                  "dayslesson":loopitem.days_lesson,
                  "lessontopic":loopitem.lesson_topic,
                  "ppturl":loopitem.ppturl,
                  "lessonplan":""
                }
                return obj;
              })
              setsyllabusLessonObject(lessonlistdata)
        }

        if(OfflineSyllabusTextbooklist?.offlineTextbookList){
          let findlevel = OfflineSyllabusTextbooklist?.offlineTextbookList.map((loopitem,loopkey) => {
            if(e.toUpperCase() == loopitem.bookcode){
              return loopitem.level;
            }
          })
          setSyllabusLevel([... new Set(findlevel)].filter(ele => ele));
        }
        getfetchtextbookbycode({
          variables: {
            bookcode : e,
          },
        })
        if(fetchtextbookbycode?.offlineTextbookBybookcode){
          let registerdate = fetchtextbookbycode?.offlineTextbookBybookcode.map((loopitem,loopkey) => (loopitem.registerdate).split("T")[0])
          if(registerdate.length > 0){
            setLastregisterdate(registerdate[0])
          }else{
            setLastregisterdate("")
          }
        }
        setSyllbausBookCode(e)
      }      
  }*/

  const handlebooklevelChange = (e) => {
    setValidation("")
    setSyllbausLevel(e)
  }

  function lessonInputChange(e){
    if(e.target.value !== '' && e.target.value !== undefined){
      setAddsyllabusinput(parseInt(e.target.value))
    }else{
      setAddsyllabusinput(0)
    }
  }
  
  function inputhandleChange(e,i,columntype) {
    if(columntype == "dayslesson"){
      let totalobj = syllabusLessonObject;
      totalobj[i]['dayslesson'] = e.target.value;
      setsyllabusLessonObject(totalobj);
    }
    if(columntype == "lessontopic"){
      let totalobj = syllabusLessonObject;
      totalobj[i]['lessontopic'] = e.target.value;
      setsyllabusLessonObject(totalobj);
    }
    if(columntype == "ppturl"){
      let totalobj = syllabusLessonObject;
      totalobj[i]['ppturl'] = e.target.value;
      setsyllabusLessonObject(totalobj);
    }
  }
  
  const handleAddSaveLesson = () => {
      offlineaddlesson({
       variables : {
         programnameold : oldProgram,
         programlevelold : oldProgramLevel,
         programname  : Programinputcreate,
         programlevel : SyllbausLevel == '' ? oldProgramLevel : SyllbausLevel,
         lessonlist: syllabusLessonObject,
         folder_type : InputFolder == "publisher" ? '' : InputFolder,
         programtype : "update"
       } 
      })
  }

  const handleBulkSaveLesson = () => {
    setValidation('');
    setLoading(true);
    if (Programinputcreate !== '') {
      offlineBulklesson({
        variables: {
         programnameold : oldProgram,
         programlevelold : oldProgramLevel,
         programname  : Programinputcreate,
         programlevel : SyllbausLevel == '' ? oldProgramLevel : SyllbausLevel,
         lessonlist: syllabusLessonObjectInitialAdd,
         folder_type : InputFolder == "publisher" ? '' : InputFolder,
         programtype : "update"
        },
      });
    } else {
      setValidation('Select Program name & level');
    }
  };

  const ExcelUpload = ({ fileInputKey, saveData }) => {
  const props = {
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: (info) => {
      const file = info.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const ab = e.target.result;
          const wb = XLSX.read(ab, { type: 'array' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: ['dayslesson', 'lessontopic', 'ppturl', 'plantopic', 'planday', 'planlearningobj', 'textbookcode1', 'textbookcode2', 'lessonplancontent', 'homeworkcontent'] });
          
          if (data && data instanceof Array) {
            let formobject = data.filter((loopitem,loopkey) => loopkey !== 0);
            let currentlesson = formobject.map((loopitem,loopkey) => { 
                let obj = {
                      "no":loopkey+1,
                      "dayslesson"   : loopitem.hasOwnProperty('dayslesson') == true ? loopitem.dayslesson : '',
                      "lessontopic"  : loopitem.hasOwnProperty('lessontopic')  == true ? loopitem.lessontopic : '',
                      "ppturl"       : loopitem.hasOwnProperty('ppturl') == true ? loopitem.ppturl : '',
                      "programname"  : Programinputcreate,
                      "programlevel" : Programlevel,
                      "folder_type"  : InputFolder
                      //"plantopic"    : loopitem.hasOwnProperty('plantopic') == true ? loopitem.plantopic : '',
                      //"planday"      : loopitem.hasOwnProperty('planday')  == true ? loopitem.planday : '',
                      //"planlearningobj"   : loopitem.hasOwnProperty('planlearningobj') == true ? loopitem.planlearningobj : '',
                      //"textbookcode1"     : loopitem.hasOwnProperty('textbookcode1') == true ? loopitem.textbookcode1 : '',
                      //"textbookcode2"     : loopitem.hasOwnProperty('textbookcode2') == true ? loopitem.textbookcode2 : '',
                      //"lessonplancontent" : loopitem.hasOwnProperty('lessonplancontent')  == true ? loopitem.lessonplancontent : '',
                      //"homeworkcontent"   : loopitem.hasOwnProperty('homeworkcontent')  == true ? loopitem.homeworkcontent : '',
                }
                return obj;
            })
            console.log(currentlesson)
            setsyllabusLessonObjectInitialAdd(currentlesson);
            //setsyllabusLessonObject(currentlesson)
          } else {
            alert('오류가 발생했습니다.');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
      }
    },
  };
  return <Input size="small" key={`finle-input-${fileInputKey}`} type="file" {...props} name="files" style={{ height: 32 }} />;
};

 const columns = [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    render: (text, record) => (
          <span style={{color:"green"}}>{record.no}</span>
    ),
  },
  {
    title: 'Days / Lessons',
    dataIndex: 'dayslesson',
    key: 'dayslesson',
    align: 'center',
  },
  {
    title: 'Lesson Topic',
    dataIndex: 'lessontopic',
    key: 'lessontopic',
    align: 'center',
  },
  {
    title: 'PPT (url)',
    dataIndex: 'ppturl',
    key: 'ppturl',
    align: 'center',
    render: (text, record) => (
        <a href={record.ppturl} target="_blank" rel="noreferrer noopener">
           {record.ppturl}
        </a>
    ),
  },
  {
    title: 'Edit Lesson Plan',
    key: 'lessonplan',
    align: 'center',
    render: (text, record) => (
          <Button style={{color:"#249fe9",background:"#B8E4F0"}}>
            <Link to={{ pathname: `/offline/lesson/create/${record.idx}/${record.programlevel}`}}>Edit</Link>
          </Button>
    ),
  },
];

  const downloadBulklesson = (e) => {
    const data = "[{ sample: 'sample'}, { sample1: 'sample1' }]";  
    const fileName = 'OFFLINE_SYLLABUS_BULK_EXPORT'  
    const exportType = 'xls'
    if(syllabusLessonObject.length > 0){
      let scopeobj = syllabusLessonObject;
      let arrayobj = [];
      let trimsyllabuslesson = scopeobj.map((loopitem,loopkey) => {
        let obj = {
          dayslesson : loopitem.dayslesson,  
          lessontopic : loopitem.lessontopic,
          ppturl  : loopitem.ppturl,
          //plantopic : loopitem.plantopic,
          //planday : loopitem.planday,
          //planlearningobj : loopitem.planlearningobj,
          //textbookcode1 : loopitem.textbookcode1,
          //textbookcode2  : loopitem.textbookcode2,
          //lessonplancontent : loopitem.lessonplancontent,
          //homeworkcontent : loopitem.homeworkcontent
        }
        return obj;
      })
      exportFromJSON({ data : trimsyllabuslesson , fileName : 'OFFLINE_SYLLABUS_BULK_EXPORT', exportType : 'xls' })
    }
  }

  const handleFolderChangepopup = (e) => {
    setInputFolder(e);
  }
  
  return (
    <>
      <Row style={{width : "100%"}} gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY: 'auto' }}>
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Form
                form={form}
                initialValues={{
                  layout: formLayout,
                }}
              >
                <Space>
                <span style={{ width: 200,color:"#249fe9",fontSize: "20px",fontWeight : "bold" }}>&nbsp;Program Name : </span>
                <span style={{ width: 200,fontSize: "20px",fontWeight : "bold" }}>{Programinputcreate}</span>
                <span style={{ width: 160,color:"#249fe9",fontSize: "20px",fontWeight : "bold",marginLeft: "20px" }}>Level : </span>
                <span style={{ width: 160,fontSize: "20px",fontWeight : "bold" }}>{Programlevel}</span>   
                </Space>
              </Form>             
              <div className="header-classroom-search">
                  <Space>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} size="default" onClick={() => downloadBulklesson()}>
                      Download
                    </Button>
                    {/*<span
                      style={{
                        margin: 0,
                        border: '1px solid white',
                        float: 'right',
                        width: '220px',
                        cursor: 'pointer',
                        background: "orange"
                      }}
                      onClick={() => setVisibleBatch(true)}

                    >
                      <center>
                        <Title className="header-classroom-title" level={5} style={{ marginTop: '10px' }}>
                          + Batch Syllabus Register
                        </Title>
                      </center>
                    </span>*/}

                    <span><HeaderTitle level={4} style={{color: "green", marginRight:"20px"}}>{Lessoncount} Lessons</HeaderTitle></span>
                    <Button
                    className="btn-schedule"
                    type={'primary'}
                    size="large"
                    style={{ width:"100px",margin: 0,float: 'right', background: "orange", borderColor: "white" }}
                    onClick={() => setVisible(true)}
                  >
                    Edit
                  </Button>
                    <span></span><span></span><span></span><span></span><span></span><span></span>
                  </Space>
                  <Modal
                    title="Edit Lessons"
                    fontSize={20}
                    centered
                    visible={visible}
                    width={1000}
                    onSave={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    footer={null}
                  >
                  <Row>
                  <Col span={24}>
                        <Form
                      form={form}
                      initialValues={{
                        layout: formLayout,
                      }}
                    >
                      <Space>
                        <Input 
                          style={{ width: 250 }}               
                          value={Programinputcreate} onInput={e => setPrograminputcreate(e.target.value) }
                        />
                        <Select placeholder="level" onChange={handlebooklevelChange} style={{width : "150px"}}>
                        <Option key="0" value="Phonics">Phonics</Option>
                        <Option key="1" value="1">level 1</Option>
                        <Option key="2" value="2">level 2</Option>
                        <Option key="3" value="3">level 3</Option>
                        <Option key="4" value="4">level 4</Option>
                        <Option key="5" value="5">level 5</Option>
                        <Option key="6" value="6">level 6</Option>
                        <Option key="7" value="7">level 7</Option>
                        <Option key="8" value="8">level 8</Option>
                        <Option key="9" value="9">level 9</Option>
                        <Option key="10" value="10">level 10</Option>
                        <Option key="11" value="11">level 11</Option>
                        <Option key="12" value="12">level 12</Option>
                      </Select>
                      <span style={{ textAlign: 'center', display: 'flex', alignItems: 'center', marginRight: '10px', height: '32px' }}>
                          Folder:
                        </span>
                      <Select style={{width : "120px"}} value={InputFolder} onChange={handleFolderChangepopup}>
                          <Option value="campus">학원 컨텐츠</Option>
                          <Option value="me">개인 컨텐츠</Option>
                        </Select>                      
                        {/*<Select placeholder="level" defaultValue={Inputlevel} onChange={handlebooklevelChange} style={{width:"150px"}}>
                            {
                              TextbookLevelFormFilter.map((loopitem,loopkey) => <option key={loopkey} value={loopitem.levelkey} >{loopitem.levelname}</option>)
                            }
                        </Select>*/}
                        {/*<span style={{ width: 160,color:"#249fe9",fontSize: "20px",fontWeight : "bold" }}>Program Name : </span>
                        <span style={{ width: 160,fontSize: "20px",fontWeight : "bold" }}>{Programname}</span>
                        <span style={{ width: 160,color:"#249fe9",fontSize: "20px",fontWeight : "bold" }}>Level : </span>
                        <span style={{ width: 160,fontSize: "20px",fontWeight : "bold" }}>{Programlevel}</span>*/}

                      </Space>
                    </Form>
                    </Col>
                    </Row>
                    <Row>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                    </Row>
                    {/*<Row>
                    <Col span={8}></Col>
                    <Col span={12}><Title className="header-classroom-title" level={4} style={{color : "red"}}>{Validation}</Title></Col>
                    <Col span={4}></Col>
                    </Row>*/}
                    <Row style={{ border: '3px solid #8ec1e5',padding : "10px", background: "#d0dce5", borderColor: "#d0dce5" }}>
                    <Col span={1} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        No
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box"></Col>
                    <Col span={5} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;&nbsp;Days / Lessons
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box"></Col>
                    <Col span={5} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lesson Topic
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box"></Col>
                    <Col span={5} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PPT (url)
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box"></Col>
                    {/*<Col span={4} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;Activity Board
                      </Title>
                    </Col>*/}
                    {
                          syllabusLessonObjectInitialAdd.map((el, i) =>
                            <React.Fragment>
                              <Col span={1} className="calendar-box">
                                {i+1}
                              </Col>
                              <Col span={2} className="calendar-box"></Col>
                              <Col span={5} className="calendar-box">
                                <Input type={"text"} defaultValue={`${el.dayslesson}`} onInput={e => inputhandleChange(e,i,"dayslesson") }/>
                              </Col>
                              <Col span={1} className="calendar-box"></Col>
                              <Col span={8} className="calendar-box">
                               <Input type="text" defaultValue={`${el.lessontopic}`} onInput={e => inputhandleChange(e,i,"lessontopic")}/>
                              </Col>
                              <Col span={1} className="calendar-box"></Col>
                              <Col span={5} className="calendar-box">
                                <Input type="text" defaultValue={`${el.ppturl}`} onInput={e => inputhandleChange(e,i,"ppturl")}/>
                              </Col>
                              <Col span={1} className="calendar-box"></Col>
                              <br/><br/>
                            </React.Fragment>
                          )
                    }
                    <Col span={18} className="calendar-box"></Col>
                    <Button
                    className="btn-schedule"
                    type={tab === 'main' && 'primary'}
                    size="large"
                    style={{ margin: 5,float: 'right', borderRadius: "5px", background: "orange", borderColor: "orange" }}
                    onClick={() => setVisible(false)}
                  >
                    Cancel
                  </Button>
                    <Button
                    className="btn-schedule"
                    type={tab === 'main' && 'primary'}
                    size="large"
                    style={{ margin: 5,float: 'right', borderRadius: "5px" }}
                    onClick={handleAddSaveLesson}
                  >
                    Save
                  </Button>
                    </Row>
                  </Modal>


                  <Modal
                  title="Add Batch Syllabus Lessons"
                  fontSize={20}
                  centered
                  visible={visibleBatch}
                  width={1200}
                  onSave={() => setVisibleBatch(false)}
                  onCancel={() => setVisibleBatch(false)}
                  footer={null}
                >

                  <Row>
                    <Col span={12}>
                      <Space>
                        <span style={{ width: 160, color: '#249fe9', fontSize: '15px', fontWeight: 'bold' }}>Program Name : </span>
                        <Input
                          style={{ width: 250 }}
                          value={Programinputcreate}
                          onInput={(e) => setPrograminputcreate(e.target.value)}
                        />
                        <Select placeholder="level" onChange={handlebooklevelChange} style={{width : "150px"}}>
                        <Option key="0" value="Phonics">Phonics</Option>
                        <Option key="1" value="1">level 1</Option>
                        <Option key="2" value="2">level 2</Option>
                        <Option key="3" value="3">level 3</Option>
                        <Option key="4" value="4">level 4</Option>
                        <Option key="5" value="5">level 5</Option>
                        <Option key="6" value="6">level 6</Option>
                        <Option key="7" value="7">level 7</Option>
                        <Option key="8" value="8">level 8</Option>
                        <Option key="9" value="9">level 9</Option>
                        <Option key="10" value="10">level 10</Option>
                        <Option key="11" value="11">level 11</Option>
                        <Option key="12" value="12">level 12</Option>
                      </Select>
                      </Space>
                    </Col>
                    <Col span={8}>
                      <ExcelUpload setLoading={setLoading} fileInputKey={0} />
                    </Col>
                    <Col span={1}></Col>
                    <Col span={3}>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} size="default" onClick={() => downloadBulklesson()}>
                        Download
                    </Button>
                    </Col>
                  </Row>
                  <br/>
                  <Row style={{ border: '3px solid #8ec1e5', padding: '10px', borderColor: '#d0dce5' }}>
                    <Col span={1} className="calendar-box" style={{background: '#d0dce5'}}>
                      <Title className="header-classroom-title" level={5}>
                        No
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box" style={{background: '#d0dce5'}}></Col>
                    <Col span={5} className="calendar-box" style={{background: '#d0dce5'}}>
                      <Title className="header-classroom-title" level={5}>
                        Days / Lessons
                      </Title>
                    </Col>
                    <Col span={1} className="calendar-box" style={{background: '#d0dce5'}}></Col>
                    <Col span={6} className="calendar-box" style={{background: '#d0dce5'}}>
                      <Title className="header-classroom-title" level={5}>
                        Lesson Topic
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box" style={{background: '#d0dce5'}}></Col>
                    <Col span={5} className="calendar-box" style={{background: '#d0dce5'}}>
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PPT (url)
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box" style={{background: '#d0dce5'}}></Col>
                    {syllabusLessonObjectInitialAdd.map((el, i) => (
                      <React.Fragment>
                        <Col span={1} className="calendar-box">
                          {i + 1}
                        </Col>
                        <Col span={2} className="calendar-box"></Col>
                        <Col span={5} className="calendar-box">
                          <label>{el.dayslesson}</label>
                          {/*<Input value={} onChange={(e) => inputhandleChangeBulk(i, e, 'dayslesson')} />*/}
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <Col span={8} className="calendar-box">
                          <label>{el.lessontopic}</label>
                          {/*<Input value={} onChange={(e) => inputhandleChangeBulk(i, e, 'lessontopic')} />*/}
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <Col span={5} className="calendar-box">
                          <label>{el.ppturl}</label>
                          {/*<Input value={} onChange={(e) => inputhandleChangeBulk(i, e, 'ppturl')} />*/}
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                    <Col span={18} className="calendar-box"></Col>
                    <Button
                    className="btn-schedule"
                    type={tab === 'main' && 'primary'}
                    size="large"
                    style={{ margin: 5,float: 'right', borderRadius: "5px", background: "orange", borderColor: "orange" }}
                    onClick={() => setVisibleBatch(false)}
                  >
                    Cancel
                  </Button>
                    <Button
                      className="btn-schedule"
                      type={tab === 'main' && 'primary'}
                      size="large"
                      style={{ margin: 5, float: 'right', borderRadius: '5px' }}
                      onClick={handleBulkSaveLesson}
                    >
                      Save
                    </Button>
                  </Row>

                </Modal>


              </div>
            </div>

            <CustomTable
              className="classroom-table-table"
              dataSource={syllabusLessonObject}
              columns={columns}
              pagination={{ pageSize: 10 }}
              size="small"
              color="#edf3fb"
              style={{ float: 'center' }}
              scroll={{ y: 'calc(100vh - 254px)' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {},
                };
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
}

export default OfflineSyllabus;
