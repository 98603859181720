import React, { useState, useEffect, useRef } from 'react';
import { Col, Typography, Row, Button, Card, Input, Image } from 'antd';
import styled from 'styled-components';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import clip from 'src/sounds/clip-sound.mp3';
import clipGood from 'src/sounds/Good_job.mp3';
import clipTry from 'src/sounds/Try Again.mp3';
import 'react-simple-keyboard/build/css/index.css';
import useSound from 'use-sound';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  margin-top: 10px;
  height: calc(100vh - 94px);
`;

const TextBody = styled.div`
  border: 1px solid #000000e0;
  background: #c0daf1;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 5px;
  height: 60px;
  display: flex;
  padding-right: 80px;
  @media (max-height: 700px) {
    margin-bottom: 20px;
  }
`;

const CardText = styled.div`
  color: #3293ef;
  font-size: 25px;
  margin-bottom: 0;
  text-align: center;
  @media (max-height: 700px) {
    font-size: 20px;
  }
  @media (max-width: 1080px) {
    font-size: 20px;
  }
`;

const AnswerText = styled.div`
  color: #3293ef;
  font-size: 20px;
  margin-bottom: 0;
  text-align: center;
  @media (max-height: 700px) {
    font-size: 20px;
  }
`;

const StyledInput = styled(Input)`
  color: #fff;
  z-index: 1;
  font-size: 30px;
  width: 90%;
  &::selection {
    color: #fff;
    background: #fff;
  }
`;

const TotalBox = styled.span`
  color: rgb(255, 122, 27);
  border: 1px solid #6b5c5c;
  padding: 5px 10px;
  border-radius: 5px;
`;

const ScoreBox = styled.span`
  color: #de3333;
  border: 1px solid #de3333ab;
  padding: 5px 10px;
  border-radius: 5px;
`;

const quesData = [
  {
    no: 1,
    eng: '<Hello>! [Hello]!',
    kor: '안녕 안녕',
    eng_audio: '/audio/Sentence VB1 UNIT1 A/0.mp4',
  },
  {
    no: 2,
    eng: '[<What>]’[s] <your> [name]?',
    kor: '넌 이름이 뭐니?',
    eng_audio: '/audio/Sentence VB1 UNIT1 A/1.mp4',
  },
  {
    no: 3,
    eng: 'My <name> [<is>] [Noonoo].',
    kor: '내 이름은 누누야.',
    eng_audio: '/audio/Sentence VB1 UNIT1 A/2.mp4',
  },
  {
    no: 4,
    eng: '[See] <you> later, [<Sammie>].',
    kor: '나중에 봐 새미야.',
    eng_audio: '/audio/Sentence VB1 UNIT1 A/3.mp4',
  },
  {
    no: 5,
    eng: '[<Good>] bye, [<Noonoo>].',
    kor: '잘 가 누누.',
    eng_audio: '/audio/Sentence VB1 UNIT1 A/4.mp4',
  },
];

const getUpdatedList = (quesData) => {
  for (let i = 0; i < quesData.length; i++) {
    let randDot = quesData[i]['eng'];
    randDot = randDot.replace(/[<>\[\]]*/g, '');
    quesData[i]['qns'] = randDot.split(' ');
    quesData[i]['ans'] = randDot;
    quesData[i]['eng_audio'] = quesData[i]['eng_audio'];
    quesData[i]['kor'] = quesData[i]['kor'];
  }

  return quesData;
};

// const data = getUpdatedList(quesData);

const ListenPage = ({ propsStageType, gotoNext, data }) => {
  const [total, setTotal] = useState(0);
  const [score, setScore] = useState(100);
  const [done, setDone] = useState(0);
  const [tries, setTries] = useState(4);
  const [stageType, setStageType] = useState(propsStageType ? propsStageType : 1);
  const [collapsed, setCollapsed] = useState(false);
  const [error, setError] = useState(false);
  const [answer, setAnswer] = useState('');
  const [selectedAns, setSelectedAns] = useState([]);
  const [ques, setQues] = useState('');
  const [indx, setIndx] = useState(0);
  const [text, setText] = useState('');
  const [playing, setPlaying] = useState(false);
  const [options, setOptions] = useState([]);
  const [play] = useSound(clip);
  const [playGood] = useSound(clipGood);
  const [playTry] = useSound(clipTry);

  useEffect(() => {
    if (data && data[done]) {
      setQues(data[done]['kor']);
      setAnswer(data[done]['ans']);
      const allOptions = data
        .filter((d, i) => i !== done)
        .reduce((a, b) => {
          return b.qns.concat(a.qns ? a.qns : a);
        });

      let restQ = allOptions.sort(() => Math.random() - 0.5).splice(0, 20 - data[done]['qns'].length);
      restQ = restQ.concat(data[done]['qns']);
      restQ = restQ.sort(() => Math.random() - 0.5);
      setOptions(restQ);
      setPlaying(true);
      setTries(4);
      setTotal(data.length);
    }
  }, [done, data]);

  const handleCheck = () => {
    if (!tries) return;
    if (selectedAns.join(' ') === answer) {
      // setScore(Math.round(((done+1)/total)*100));
      setSelectedAns([]);
      playGood();

      goNext();
    } else {
      // setScore(score ? score-10: 0);
      const splitAns = answer.split(' ');
      let wrongIndx = 0;
      for (let i = 0; i < answer.length; i++) {
        if (selectedAns[i] !== splitAns[i]) {
          selectedAns[i] = '';
          wrongIndx = i;
        }
      }

      setError(true);
      setTimeout(function () {
        setError(false);
      }, 1000);
      setTries((prev) => prev - 1);
      setScore((prev) => prev - 10);
      setIndx(wrongIndx);
      playTry();
      if (tries < 2) {
        goNext();
      }
    }
  };

  const goNext = () => {
    setTimeout(() => {
      if (done < data.length - 1) {
        setScore((prev) => prev + 100);
        setIndx(0);
        setDone(done + 1);
      } else {
        if (gotoNext) {
          const calculatedScore = Math.round((score / (total * 100)) * 100);
          gotoNext({ exam_total: 100, exam_correct: calculatedScore, message: `Your total score is ${calculatedScore}` });
        }
      }
    }, 1000);
  };

  const handleSelect = (i, value) => {
    selectedAns[i] = value;
    setSelectedAns(selectedAns);
    play();
    if (indx < data[done]['qns'].length - 1) {
      setIndx(indx + 1);
    }
  };

  const handleUnSelect = (i) => {
    let tempS = selectedAns;
    delete tempS[i];
    tempS[i] = undefined;
    play();
    setSelectedAns(tempS);
    setIndx(i);
  };

  const onKeyPress = (button) => {
    button = button.key ? button.key : button;

    /**
     * If you want to handle the shift and caps lock buttons
     */
    play();
    if (button === '{enter}' || button.toLowerCase() === 'enter') handleCheck();
  };

  return (
    <>
      <Col className="listenword-wrap" span={24} style={{ textAlign: 'left', margin: 0 }}>
        <MainBlock
          className="listenword-wrap-height scrollscroll"
          onKeyDown={onKeyPress}
          tabIndex={0}
          style={{ outline: 'none' }}
          onClick={() => collapsed && setCollapsed(false)}
        >
          <Row gutter={[24, 16]} style={{ padding: 10 }} className="listenword-txt">
            <Col span={16} className="listenword-heading">
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col className="listenword-heading-main" span={24} style={{ padding: 10, paddingLeft: 10 }}>
                  <Title level={2} ellipsis>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Unscramble the Sentence:</span>{' '}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col
              className="listenword-txt"
              span={8}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 10 }}
            >
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                <ScoreBox>Try : {tries}</ScoreBox>
              </Title>
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                <TotalBox>
                  {done + 1} / {total}
                </TotalBox>
              </Title>
              {collapsed ? (
                <MenuFoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(false)}
                />
              ) : (
                <MenuUnfoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(true)}
                />
              )}
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col className="fordesktoponly" span={2} style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}>
              {data[done] && stageType === 1 ? (
                <>
                  <Image
                    height={40}
                    width={40}
                    style={{ cursor: 'pointer', marginRight: 10 }}
                    src="/images/speak.png"
                    preview={false}
                    onClick={() => setPlaying(true)}
                  />
                  <ReactPlayer
                    url={`https://cdn.cloubot.com${data[done]['eng_audio']}`}
                    width="0"
                    height="0"
                    playing={playing}
                    onEnded={() => setPlaying(false)}
                  />
                </>
              ) : null}
            </Col>
            <Col className="searchkeyboard" span={20} style={{ padding: 0 }}>
              {stageType === 2 ? (
                <Title level={4} style={{ width: '100%', background: '#c0daf1', padding: 10, borderRadius: 10 }}>
                  {ques}
                </Title>
              ) : null}
              <TextBody gutter={[10, 10]} className="searchtextbox">
                {data[done]['qns']
                  ? data[done]['qns'].map((a, i) => (
                      <Col
                        className="textfield"
                        key={i}
                        span={4}
                        onClick={() => handleUnSelect(i)}
                        style={{
                          margin: 3,
                          flex: 1,
                          padding: 5,
                          cursor: 'pointer',
                          marginRight: 10,
                          borderRadius: 10,
                          backgroundColor: '#fff',
                          borderColor: `${error ? '#da3a3a' : '#fdaa11'}`,
                          borderStyle: 'solid',
                          borderWidth: indx === i || error ? 3 : 0,
                        }}
                      >
                        <AnswerText>{selectedAns[i] ? selectedAns[i] : ''}</AnswerText>
                      </Col>
                    ))
                  : null}
                <Col
                  className="searchtextbox-btn"
                  style={{ padding: 0, overflow: 'visible', float: 'right', position: 'absolute', right: 0 }}
                >
                  <Button type="primary" onClick={handleCheck} style={{ float: 'right', width: '100%', height: '50px', right: 10 }}>
                    Check
                  </Button>
                </Col>
              </TextBody>

              <Row gutter={[24, 16]} style={{ padding: 10, borderRadius: 10, border: '1px solid #aad8ff' }} className="btnkeyboard">
                {options.map((a, i) => (
                  <Col className="keykey" onClick={() => handleSelect(indx, a)} key={i} span={4}>
                    <Card style={{ backgroundColor: '#dfe3ea', cursor: 'pointer', borderRadius: 10 }} bodyStyle={{ padding: '20px 10px' }}>
                      <CardText>{a}</CardText>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </MainBlock>
        {collapsed ? (
          <>
            <Card
              title="Word List"
              extra={
                <MenuFoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(false)}
                />
              }
              style={{
                width: 300,
                position: 'absolute',
                right: 0,
                height: 'calc(100% - 30px)',
                backgroundColor: '#dfe3ea',
                top: 20,
                opacity: 0.85,
                zIndex: 50,
              }}
            >
              {data.map((d, i) => (
                <Title level={4} key={i} style={{ marginBottom: 5, color: '#333333d9' }}>{`  ${i + 1}. ${done > i ? d.ans : '-'}`}</Title>
              ))}
            </Card>
          </>
        ) : null}
      </Col>
    </>
  );
};

export default ListenPage;
