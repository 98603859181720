import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Modal } from 'antd';
import XLSX from 'xlsx';
import fileDownload from 'js-file-download';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import ClassAndMemberMenuGroup from 'src/components/common/ClassAndMemberMenuGroup';

import { GET_EXIST_USERS, GET_USERS } from 'src/operations/queries/getUser';
import { CREATE_STUDENTS } from 'src/operations/mutations/createUser';
import { userTypVar } from 'src/apollo/cache';

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
    width: 70,
  },
  {
    title: '학생 이름',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'ID',
    dataIndex: 'ID',
    key: 'ID',
    align: 'center',
  },
  {
    title: 'Password',
    dataIndex: 'password',
    key: 'password',
    align: 'center',
  },
  {
    title: '학생연락처',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
  },
  {
    title: '학교',
    dataIndex: 'school_name',
    key: 'school_name',
    align: 'center',
  },
  {
    title: '학년',
    dataIndex: 'grade',
    key: 'grade',
    align: 'center',
  },
  {
    title: '부모님 성함',
    dataIndex: 'parent_name',
    key: 'parent_name',
    align: 'center',
  },
  {
    title: '부모님 연락처',
    dataIndex: 'parent_phone',
    key: 'parent_phone',
    align: 'center',
  },
  {
    title: '주소',
    dataIndex: 'address',
    key: 'address',
    align: 'center',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
    align: 'center',
  },
  {
    title: '가입일',
    dataIndex: 'regdate',
    key: 'regdate',
    align: 'center',
  },
  {
    title: '중복체크',
    dataIndex: 'is_exist',
    key: 'is_exist',
    align: 'center',
    render: (text) => {
      return text ? '중복' : '사용가능';
    },
  },
];

const StudentsBatchCommon = () => {
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [checkRowList, setcheckRowList] = useState([]);
  const history = useHistory();

  const [saveStudents, { loadingSave }] = useMutation(CREATE_STUDENTS, {
    refetchQueries: [
      {
        query: GET_USERS,
        variables: { type: userTypVar().student },
      },
    ],
    onCompleted(data) {
      console.log('saveStudent onCompleted', data);
      if (data) {
        Modal.info({
          title: (
            <>
              아이디 등록이 처리되었습니다.
              <br />
              학생관리메뉴에서 확인 가능합니다.
            </>
          ),
          onOk: () => {
            history.push('/students');
          },
        });
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];
      Modal.error({
        title: `${message}`,
      });
    },
  });

  const handleFileSelect = (data) => {
    const data2 = data?.map((item, key) => {
      return {
        key: key,
        No: key + 1,
        name: item.name || '',
        ID: String(item.ID || ''),
        password: String(item.password || ''),
        phone: String(item.phone || ''),
        school_name: String(item?.school_name || ''),
        grade: String(item.grade || ''),
        parent_name: String(item?.parent_name || ''),
        parent_phone: String(item?.parent_phone || ''),
        address: String(item?.address || ''),
        email: String(item?.email || ''),
        regdate: item?.regdate || '',
        is_exist: item.isExist,
      };
    });
    setFileData(data2);
  };
  const handleExcelFileDownload = () => {
    (async () => {
      const blob = await fetch('https://cdn.cloubot.com/SM/common/regstration-form.xlsx').then((r) => r.blob());
      fileDownload(blob, 'registration.xlsx');
    })();
  };
  const rowSelection = {
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
    getCheckboxProps: ({ is_exist }) => {
      return {
        disabled: is_exist,
      };
    },
  };
  const handleSaveStudents = () => {
    if (checkRowList.length === 0) {
      Modal.error({
        title: `학생을 선택하세요.`,
      });

      return false;
    }

    Modal.confirm({
      title: `아이디를 저장하시겠습니까?`,
      onOk: () => {
        const student_list = fileData
          .filter((item1) => checkRowList.includes(item1.key))
          .map((item2) => {
            return {
              id: item2.ID,
              name: item2.name,
              pw: item2.password,
              phone: item2.phone,
              school_name: item2?.school_name,
              grade: item2.grade,
              parent_name: item2?.parent_name,
              parent_phone: item2?.parent_phone,
              address: item2?.address,
              email: item2?.email,
              regdate: item2?.regdate,
            };
          });
        saveStudents({ variables: { student_list } });
      },
    });
  };
  return (
    <>
      <ClassAndMemberMenuGroup currentMenu="students-batch" />
      <HeaderTitle level={4}>학생 일괄 등록</HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY: 'auto' }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button type="primary" onClick={handleExcelFileDownload}>
                  학생등록엑셀양식 다운로드 받기
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ padding: 5 }}>
              <Col span={6}>
                <ExcelUpload onFileSelect={handleFileSelect} setLoading={setLoading} fileInputKey={fileInputKey} />
              </Col>
              <Col span={6}>
                <Button
                  style={{ height: '100%' }}
                  onClick={() => {
                    setFileInputKey((prev) => prev + 1);
                  }}
                >
                  목록삭제
                </Button>
              </Col>
              <Col span={6}></Col>
              <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button style={{ height: '100%' }} type="primary" onClick={handleSaveStudents} loading={loadingSave}>
                  저장
                </Button>
              </Col>
            </Row>
            <CustomTable
              loading={loading}
              className="classroom-table-table"
              dataSource={fileData}
              columns={columns}
              scroll={{ y: 'calc(100vh - 254px)' }}
              pagination={false}
              size="small"
              color="#edf3fb"
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default StudentsBatchCommon;

const ExcelUpload = ({ onFileSelect, setLoading, fileInputKey }) => {
  const [users, setUsers] = useState([]);
  const [getUsers, { data, loading }] = useLazyQuery(GET_EXIST_USERS, { fetchPolicy: 'no-cache' });
  useEffect(() => {
    if (!loading && data && users) {
      onFileSelect(
        users.map((item) => {
          return { ...item, isExist: data?.getExistUsers?.map((item) => item.id.toLowerCase()).includes(item.ID?.toLowerCase()) || false };
        }),
      );
    }
    // eslint-disable-next-line
  }, [data, loading, users]);
  useEffect(() => {
    if (users && users.length > 0) {
      getUsers({ variables: { user_ids: users.map((item) => item.ID) } });
    }
    // eslint-disable-next-line
  }, [users]);
  useEffect(() => {
    setLoading(loading);
    // eslint-disable-next-line
  }, [loading]);
  useEffect(() => {
    setUsers([]);
  }, [fileInputKey]);
  const props = {
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: (info) => {
      const file = info.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          //https://stackoverflow.com/questions/53163552/format-date-with-sheetjs
          const ab = e.target.result;
          const wb = XLSX.read(ab, { type: 'binary', cellText: false, cellDates: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: [
              'number',
              'name',
              'ID',
              'password',
              'phone',
              'school_name',
              'grade',
              'parent_name',
              'parent_phone',
              'address',
              'email',
              'regdate',
            ],
            raw: false,
            dateNF: 'yyyy-mm-dd',
          });
          if (data && data instanceof Array) {
            const filtered_data = data.slice(2);
            console.log('filtered_data', filtered_data);
            setUsers(filtered_data);
          } else {
            alert('오류가 발생했습니다.');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setUsers([]);
      }
    },
  };
  return <Input key={`finle-input-${fileInputKey}`} type="file" {...props} name="files" />;
};
