import React from 'react';
import { Tabs } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import styled from 'styled-components';

import IntoReading from './IntoReading';

const BookLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  min-width: 1200px;
  background-color: #fff;
`;

const SmartTabs = styled(Tabs)`
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    background-color: #e6fcf5 !important;
  }
`;
const { TabPane } = Tabs;


const IntoReadingEbook = () => {
  const CATEGORY = [
    { name: 'Into Reading', comp: <IntoReading /> },
  ];
  const getComponent = (key) => {
    return CATEGORY[key].comp;
  };

  
  
  return (
    <>
      <HeaderTitle level={4}>EBook</HeaderTitle>
      <BookLayout>
        <SmartTabs defaultActiveKey={0} type="card" size="large" onChange={() => {}} style={{ width: '100%' }}>
          {CATEGORY.map((item, key) => (
            <TabPane tab={item.name} key={key}>
              {getComponent(key)}
            </TabPane>
          ))}
        </SmartTabs>
      </BookLayout>
    </>
  );
};

export default IntoReadingEbook;