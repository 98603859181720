import styled from 'styled-components';
import { Col, Row, Radio, Card, Typography, Button } from 'antd';

const { Meta } = Card;
const { Title } = Typography;

export const BookCard = styled(Card)`
  margin-left: 5px;
  margin-right: 5px;
  border: 0px solid #fff;

  .ant-card-cover {
    border: 0px solid #fff;
  }
  .ant-card-body {
    padding: 5px;
    min-height: 40px;
    text-align: center;
    border: 0px solid #fff;

    @media screen and (max-width: 1024px) {
      & {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
export const BookMeta = styled(Meta)`
  .ant-card-body {
    padding: 5px;
  }

  .ant-card-meta-title {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 1px;
  }
`;

export const BookMetaBottom = styled(Meta)`
  .ant-card-body {
    padding: 5px;
  }
  .ant-card-meta-detail {
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
  }
  .ant-card-meta-title {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 1px;
  }
`;

export const BookTitle = styled(Title)`
  text-align: center;
  background-color: #f0f3f9;
  width: 100%;
  padding: 8px 0;
`;

export const BookCover = styled.div`
  //cursor: pointer;
  width: 139px;
  min-width: 100px;
  height: 180px;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  position: relative;
  // left: 50%;
  //  transform: translate(-50%, -3%);
`;

export const BOOK_COL_STYLE = {
  borderTop: '1px solid #dedede',
  borderBottom: '1px solid #dedede',
  borderLeft: '1px solid #dedede',
  borderRight: '1px solid #dedede',
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: 15,
};

export const SMART_ECLASS_LINK_BUTTON_STYLE = {
  width: '45%',
  fontWeight: 'bold',
  color: '#868e96',
  backgroundColor: '#F0F3F9',
};

export const SMART_ECLASS_CDN_BASE_URL = 'https://cdn.cloubot.com/CR/';
export const CREO_PPT_CDN_BASE_URL = `https://creo.class-boom.com/cdn/ppt/CR/`;

export const THUMBNAIL_BASE_URL = `${SMART_ECLASS_CDN_BASE_URL}cover/`;

export const CREO_CDN_BASE_URL_PHONICS = 'https://cdn.cloubot.com/CR/Phonics/';
export const THUMBNAIL_BASE_URL_PHONICS = `${CREO_CDN_BASE_URL_PHONICS}cover/`;

export const ESL_RAINBOW_CDN_BASE_URL = 'https://cdn.cloubot.com/ESL/';
export const THUMBNAIL_BASE_URL_ESL_RAINBOW = `${ESL_RAINBOW_CDN_BASE_URL}cover/`;
export const ESL_PPT_CDN_BASE_URL = `https://creo.class-boom.com/cdn/ppt/ESL/`;
