import React from 'react';
import { Button } from 'antd';
import { FileTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import CustomTable from 'src/components/common/CustomTable';

function Question({ boards, rowSelection }) {
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '5%',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: '10%',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: '답변',
      dataIndex: 'answer',
      key: 'answer',
      align: 'center',
      width: '10%',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '10%',
    },
    {
      title: 'File',
      key: 'file',
      dataIndex: 'file',
      align: 'center',
      width: '5%',
      render: (isFile) => (
        <>
          {isFile ? (
            <FileTwoTone size="Large" style={{ fontSize: '20px' }} />
          ) : (
            <FileTwoTone twoToneColor="gray" style={{ fontSize: '20px' }} />
          )}
        </>
      ),
    },
    {
      title: 'Hit',
      dataIndex: 'hit',
      key: 'hit',
      align: 'center',
      width: '5%',
    },

    {
      title: '상세보기',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (text, record) => {
        return (
          <Button>
            <Link to={`/board/${record.pageName}/${text.key}`}>View</Link>
          </Button>
        );
      },
    },
  ];

  return (
    <CustomTable
      dataSource={boards}
      columns={columns}
      pagination={{ pageSize: 12 }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      size="small"
      color="#edf3fb"
      scroll={{ y: 'calc(100vh - 254px)' }}
    />
  );
}

export default Question;
