import { gql } from '@apollo/client';

export const BOOK_LESSON_INFO = gql`
  query bookLessonInfo($lessonCode: String!) {
    bookLessonInfo(lessonCode: $lessonCode) {
      stage_group_code
      stage {
        no
        name
        name_abbr
        category_name
      }
      book {
        parent_code
      }
    }
  }
`;

export const BOOK_LIST_BY_PREFIX = gql`
  query bookListByPrefix($prefix: String!) {
    bookListByPrefix(prefix: $prefix) {
      code
      level_no
      level_name
      volume_name
      book_sub {
        title
        cover_path
        ebook_path
        book_category {
          no
          name
        }
      }
    }
  }
`;

export const BOOK_LIST_BY_USER_IDX = gql`
  query bookListByUserIdx($user_idx: Int!) {
    bookListByUserIdx(user_idx: $user_idx) {
      code
      level_no
      level_name
      volume_name
      book_sub {
        title
        cover_path
        ebook_path
        book_category {
          no
          name
        }
      }
    }
  }
`;

export const BOOK_LIST_FOR_DASHBOARD = gql`
  query bookListByUserIdx($user_idx: Int!) {
    bookListByUserIdx(user_idx: $user_idx, is_current: true) {
      book_sub {
        title
        cover_path
        ebook_path
        book_category {
          no
        }
      }
    }
  }
`;

export const BOOK_LIST_BY_PREFIX_FOR_CONTENTS = gql`
  query bookListByPrefix($prefix: String!) {
    bookListByPrefix(prefix: $prefix) {
      code
      level_no
      level_name
      volume_no
      volume_name
      book_sub {
        title
        short_title
      }
      book_lesson {
        code
        unit_no
        day_no
        title
        stage_group_code
      }
    }
  }
`;

export const BOOK_STUDY_LANG_DATA = gql`
  query getStudyLangData($lesson_code: String!) {
    getStudyLangData(lesson_code: $lesson_code) {
      eng
      eng_audio
      kor
      eng_sentence
      eng_slice
      slice_list
      data_idx
      no
      study_data {
        category
        study_contents {
          code
        }
      }
    }
  }
`;

export const BOOK_STUDY_QUIZ_DATA = gql`
  query getStudyQuizData($lesson_code: String!) {
    getStudyQuizData(lesson_code: $lesson_code) {
        no
        data_idx
        question_type
        pre_question
        pre_question_text
        question
        question_text
        question_image
        question_audio
        question_video
        extra_data
        answer
        option
        tag
        option_list

    }
  }
`;
export const BOOK_LESSON_DATA = gql`
  query getBookLessonData($lesson_code: String!) {
    getBookLessonData(lesson_code: $lesson_code) {
    idx
    study_contents_code
    lesson_code
    category
    title
    }
  }
`;
export const LEVEL_UP_POINT = gql`
query getLevelupPoints($user_idx: Int!){
  getLevelupPoints(user_idx: $user_idx)
}
`;