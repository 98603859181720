import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Form, InputNumber, Button, DatePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { format } from 'date-fns';
import moment from 'moment';

const ScriboSubscriptionAssignModal = ({ visible, handleCancel, campusId, campusStudents, selectedScriboSubscription }) => {
  const [form] = Form.useForm();
  const [userOptions, setUserOptions] = useState([]);
  const [values, setValues] = useState({
    assigned_user_idx: selectedScriboSubscription?.assigned_user_idx,
    assigned_date: selectedScriboSubscription?.assigned_date
      ? format(new Date(selectedScriboSubscription.assigned_date), 'yyyy-MM-dd')
      : undefined,
    number_of_days: selectedScriboSubscription?.number_of_days,
  });
  const [dateValidationError, setDateValidationError] = useState();

  useEffect(() => {
    setUserOptions(
      campusStudents.map((student) => {
        return {
          label: student.name,
          value: student.idx,
        };
      }),
    );
  }, [campusStudents]);

  const [updateScriboSubscription, { loading }] = useMutation(mutations.scribo.UPDATE_SCRIBO_SUBSCRIPTION_ASSIGNMENT, {
    variables: {
      ...values,
      assigned_user_idx: values.assigned_user_idx ? parseInt(values.assigned_user_idx) : null,
      number_of_days: values.number_of_days ? parseInt(values.number_of_days) : null,
      idx: selectedScriboSubscription.idx,
    },

    refetchQueries: [
      {
        query: queries.scribo.FETCH_SCRIBO_SUBSCRIPTION_LIST_FOR_CAMPUS,
        variables: {
          campus_idx: campusId,
        },
      },
    ],

    onCompleted(data) {
      if (data) {
        openNotification('Scribo 이용 배정이 업데이트되었습니다!');
        handleCancel();
      }
    },

    onError(error) {
      console.log('error', error);
    },
  });

  const onChange = (event) => {
    if (event.target.name === 'email') {
      setValues({
        ...values,
        [event.target.name]: event.target.value ? event.target.value : '',
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value ? event.target.value : event.target.checked,
      });
    }
  };

  function handleSubscriptionStartDateChange(date, changeDate) {
    setDateValidationError();
    setValues({ ...values, assigned_date: changeDate ? format(new Date(changeDate), 'yyyy-MM-dd') : '' });
  }

  function handleNoOfDaysChange(days) {
    setDateValidationError();
    setValues({ ...values, number_of_days: days ? days : 0 });
  }

  const onSubmit = async () => {
    const licenseStartDate = new Date(selectedScriboSubscription.license_start_date);
    const licenseEndDate = new Date(new Date(selectedScriboSubscription.license_start_date).getTime() + 365 * 24 * 60 * 60 * 1000);
    const subscriptionStartDate = new Date(values.assigned_date);
    const subscriptionEndDate = new Date(new Date(values.assigned_date).getTime() + values.number_of_days * 24 * 60 * 60 * 1000);

    if (subscriptionStartDate < licenseStartDate || subscriptionEndDate > licenseEndDate) {
      setDateValidationError('무효한 이용 시작 날짜 및 이용 기간입니다.');
      return;
    }
    updateScriboSubscription();
  };

  return (
    <>
      <Modal
        entered
        title={`수정하기 및 Scribo 이용 배정 삭제하기`}
        visible={visible}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          initialValues={{
            assigned_user_idx: selectedScriboSubscription?.assigned_user_idx,
            assigned_date: selectedScriboSubscription.assigned_date ? moment(selectedScriboSubscription.assigned_date, 'YYYY-MM-DD') : '',
            number_of_days: selectedScriboSubscription?.number_of_days,
          }}
        >
          <FormField hasFeedback={false} title="학생">
            <FormItem name="assigned_user_idx" rules={[{ required: false }]} hasFeedback>
              <select name="assigned_user_idx" style={{ width: '100%' }} className="ant-input" onChange={onChange}>
                <option key={''} value={''}>
                  {'학생 선택하기'}
                </option>
                {userOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="이용 시작 날짜">
            <FormItem name="assigned_date" rules={[{ required: false }]} hasFeedback>
              <DatePicker
                name="assigned_date"
                initialValues={selectedScriboSubscription.assigned_date && moment(selectedScriboSubscription.assigned_date, 'YYYY-MM-DD')}
                onChange={(date, dateString) => {
                  handleSubscriptionStartDateChange(date, dateString);
                }}
              />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="이용 기간">
            <FormItem name="number_of_days" rules={[{ required: false }]} hasFeedback>
              <InputNumber
                name="number_of_days"
                min={1}
                max={365}
                onChange={(days) => {
                  handleNoOfDaysChange(days);
                }}
                onKeyPress={(e) => {
                  if (e.key >= 0 && e.key <= 9) {
                    return true;
                  } else {
                    e.preventDefault();
                    return false;
                  }
                }}
              />
            </FormItem>
          </FormField>
          {dateValidationError && <div style={{ color: '#ff4d4f' }}>{`${dateValidationError}`}</div>}
        </Form>
      </Modal>
    </>
  );
};

ScriboSubscriptionAssignModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default ScriboSubscriptionAssignModal;
