import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typography } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import VocaHeader from './VocaHeader';
import VocaUnscramble from './VocaUnscramble';
import VocaDictation from './VocaDictation';
import VocaFlashcard from './VocaFlashcard';
const VocaFullWidth = () => {
  return (
    <>
      <section className="vocatool-con">
        <VocaHeader />
        <div className="vocatool-body no-process-bar">
          <div className="innner">
            <VocaDictation />
          </div>
        </div>
      </section>
    </>
  );
};

export default VocaFullWidth;
