import React from 'react';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import ForSinsu from './payment';
import MetaConsult from './normal';
import { useQuery } from '@apollo/client';
export default function ConsulthingMeta() {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  return (<>{dataUser?.getUserData?.campus_idx===513?<ForSinsu/>:<MetaConsult/>}</>)
  // return (
  //   <>
  //     <MetaConsult />
  //   </>
  // );
}
