import React, { useEffect, useMemo, useState } from 'react';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Typography, Modal, Button, Tag, Card, Image, Popover } from 'antd';
import { ShoppingCartOutlined, CalendarOutlined } from '@ant-design/icons';
import titleimagetwo from 'src/images/reload.png';
import titleimageBack from 'src/images/icon_arrow_big.png';
import moment from 'moment';
import BookCart from 'src/components/BookStore/BookCart';
import BookOrderMenuGroup from 'src/components/common/BookOrderMenuGroup';
import BookOrderTable from 'src/components/BookStore/BookOrderTable';
import BookGroupOrderTable from 'src/components/BookStore/BookGroupOrderTable';
import PackageOrderTable from 'src/components/BookStore/PackageOrderTable';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { getDifferentTitle, isDifferentTitle } from 'src/utils';
import { bookCartVar } from 'src/apollo/cart';
import { isAfterSchool, isLucid, isPturn } from 'src/utils';
import { useQuery } from '@apollo/client';
import { GET_CAMPUS_BOOK_INFO_LIST } from 'src/operations/queries/campus';
import { COMPANY_BOOK_STORE_PACKAGE_LIST } from 'src/operations/queries/company';
import { CDN_BASE_URL } from 'src/constants/common';
import { settings } from 'src/dummy/settings';
// import SimplePopup from 'src/popups/SimplePopup';
moment.locale('en');
const englishMoment = moment();

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const { Title, Text } = Typography;

const resolveBookCartData = (
  bookTitle,
  bookPrice,
  books,
  root_book_code,
  cartMap,
  l,
  volObj,
  isUseCredit,
  shoppingMallProduct,
  isSmartEclass = false,
  isTeacher = false,
) => {
  const level_no = l;
  let level_name = l;
  const find = books.find((item) => parseInt(item[0]) === parseInt(l));
  if (find) {
    level_name = find[1].level_name && find[1].level_name !== '' ? find[1].level_name : l;
  }
  Object.entries(volObj).forEach(([v, num]) => {
    if (num && num.volume) {
      if ((isUseCredit && num?.days !== 0) || (!isUseCredit && num?.days === 0)) {
        let book_title = isDifferentTitle(root_book_code, parseInt(level_no))
          ? getDifferentTitle(`${root_book_code}-L${level_no}V${v}`)
          : `${bookTitle} ${books.length === 1 ? '' : level_name + '-'}${v}${num.days ? '-' + num.days : ''}`; //level이 하나밖에 없는 경우는 레벨제외
        let price = bookPrice;
        //중간에 삭제할 경우가 생겨서 key 중복이 발생할 수 있음.
        if (isUseCredit && num?.days !== 0) {
          if (find[1]?.price_list[v - 1]?.length > 0) {
            let final_price = find[1]?.price_list[v - 1]?.find((i) => {
              if (i.days === num.days) {
                return i.price;
              } else {
                return 0;
              }
            });
            price = final_price?.price ? final_price?.price : 0;
          }
        } else {
          if (find[1]?.price_list[v - 1]?.[0]?.price) {
            price = find[1]?.price_list[v - 1]?.[0]?.price;
          }
        }
        const bookCartKey = `book-cart-${book_title}`;
        const existingCartItem = cartMap.get(bookCartKey);
        if (existingCartItem) {
          cartMap.set(bookCartKey, {
            key: bookCartKey,
            book_title,
            book_code: `${root_book_code}-L${level_no}V${v}`,
            count: existingCartItem.count + num.volume,
            unit_price: price,
            price: existingCartItem.price + price * num.volume,
            days: num.days,
            type: 'single',
            shoppingMallProduct: shoppingMallProduct,
            studentBookCount: !isTeacher ? existingCartItem.studentBookCount + num.volume : existingCartItem.studentBookCount,
            teacherBookCount: isTeacher ? existingCartItem.teacherBookCount + num.volume : existingCartItem.teacherBookCount,
          });
        } else {
          cartMap.set(bookCartKey, {
            key: bookCartKey,
            book_title,
            book_code: `${root_book_code}-L${level_no}V${v}`,
            count: num.volume,
            unit_price: price,
            price: price * num.volume,
            days: num.days,
            type: 'single',
            shoppingMallProduct: shoppingMallProduct,
            studentBookCount: !isTeacher ? num.volume : 0,
            teacherBookCount: isTeacher ? num.volume : 0,
          });
        }
      } else {
        Modal.info({
          title: '유효한 교재 입력값을 선택하세요.',
        });
      }
    }
  });
  return cartMap;
};

const resolvePackageCartData = (
  bookTitle,
  bookPrice,
  root_book_code,
  cartMap,
  packageCountObj,
  isUseCredit,
  shoppingMallProduct,
  isSmartEclass = false,
  isTeacher = false,
) => {
  if (packageCountObj?.volume && ((isUseCredit && packageCountObj?.days !== 0) || (!isUseCredit && packageCountObj?.days === 0))) {
    let book_title = `${bookTitle} Package${packageCountObj.days ? '-' + packageCountObj.days : ''}`;
    let price = 0;
    if (Array.isArray(bookPrice)) {
      let final_price = bookPrice.find((i) => {
        if (i.days === packageCountObj.days) {
          return i.price;
        } else {
          return 0;
        }
      });

      price = final_price?.price ? final_price?.price : 0;
    } else {
      price = bookPrice;
    }

    const bookCartKey = `book-cart-${book_title}`;
    const existingCartItem = cartMap.get(bookCartKey);
    if (existingCartItem) {
      cartMap.set(bookCartKey, {
        key: bookCartKey,
        book_title,
        book_code: `${root_book_code}`,
        count: packageCountObj.volume,
        unit_price: price,
        price: price * packageCountObj.volume,
        days: packageCountObj.days,
        type: 'package',
        shoppingMallProduct: shoppingMallProduct,
        studentBookCount: !isTeacher ? existingCartItem.studentBookCount + packageCountObj.volume : existingCartItem.studentBookCount,
        teacherBookCount: isTeacher ? existingCartItem.teacherBookCount + packageCountObj.volume : existingCartItem.teacherBookCount,
      });
    } else {
      cartMap.set(bookCartKey, {
        key: bookCartKey,
        book_title,
        book_code: `${root_book_code}`,
        count: packageCountObj.volume,
        unit_price: price,
        price: price * packageCountObj.volume,
        days: packageCountObj.days,
        type: 'package',
        shoppingMallProduct: shoppingMallProduct,
        studentBookCount: !isTeacher ? packageCountObj.volume : 0,
        teacherBookCount: isTeacher ? packageCountObj.volume : 0,
      });
    }
  } else {
    Modal.info({
      title: '유효한 패키지 입력값을 선택하세요.',
    });
  }
  return cartMap;
};

const resolveBookGroupCartData = (
  bookTitle,
  bookPrice,
  books,
  root_book_code,
  cartMap,
  bookGroupCountObj,
  isUseCredit,
  shoppingMallProduct,
  isLucid = false,
  isTeacher = false,
) => {
  if (bookGroupCountObj) {
    Object.entries(bookGroupCountObj).forEach(([group, gItem]) => {
      Object.entries(gItem).forEach(([level, lItem]) => {
        Object.entries(lItem).forEach(([volume, vItem]) => {
          vItem.books.forEach((book) => {
            const level_no = book.level_no;
            const v = book.volume_no;
            const find = books.find((item) => parseInt(item[0]) === parseInt(level_no));
            if (vItem && vItem.volume) {
              if ((isUseCredit && vItem?.days !== 0) || (!isUseCredit && vItem?.days === 0)) {
                let book_title = book.volume_title;
                let price = bookPrice;
                //중간에 삭제할 경우가 생겨서 key 중복이 발생할 수 있음.
                if (isUseCredit && vItem?.days !== 0) {
                  book_title = `${bookTitle} ${vItem.days ? '-' + vItem.days : ''}`;
                  if (find[1]?.price_list[v - 1]?.length > 0) {
                    let final_price = find[1]?.price_list[v - 1]?.find((i) => {
                      if (i.days === vItem.days) {
                        return i.price;
                      } else {
                        return 0;
                      }
                    });
                    price = final_price?.price ? final_price?.price : 0;
                  }
                } else {
                  if (find[1]?.price_list[v - 1]?.[0]?.price) {
                    price = find[1]?.price_list[v - 1]?.[0]?.price;
                  }
                }

                if (isLucid) {
                  book_title = `${book_title} ${isTeacher ? '교사용' : '학생용'}`;
                }

                const bookCartKey = `book-cart-${book_title}`;
                const existingCartItem = cartMap.get(bookCartKey);
                if (existingCartItem) {
                  cartMap.set(bookCartKey, {
                    key: bookCartKey,
                    book_title,
                    book_code: `${root_book_code}-L${level_no}V${v}`,
                    count: existingCartItem.count + vItem.volume,
                    unit_price: price,
                    price: existingCartItem.price + price * vItem.volume,
                    days: vItem.days,
                    type: 'single',
                    shoppingMallProduct: shoppingMallProduct,
                    studentBookCount: !isTeacher ? existingCartItem.studentBookCount + vItem.volume : existingCartItem.studentBookCount,
                    teacherBookCount: isTeacher ? existingCartItem.teacherBookCount + vItem.volume : existingCartItem.teacherBookCount,
                  });
                } else {
                  cartMap.set(bookCartKey, {
                    key: bookCartKey,
                    book_title,
                    book_code: `${root_book_code}-L${level_no}V${v}`,
                    count: vItem.volume,
                    unit_price: price,
                    price: price * vItem.volume,
                    days: vItem.days,
                    type: 'single',
                    shoppingMallProduct: shoppingMallProduct,
                    studentBookCount: !isTeacher ? vItem.volume : 0,
                    teacherBookCount: isTeacher ? vItem.volume : 0,
                  });
                }
              } else {
                Modal.info({
                  title: '유효한 교재 입력값을 선택하세요.',
                });
              }
            }
          });
        });
      });
    });
  }
  return cartMap;
};

const BookOrder = () => {
  const history = useHistory();
  const companyName = useSelector(classStoreData);
  const [bookItem, onBookChange] = useState('');
  const [bookCountObj, setBookCountObj] = useState({});
  const [bookCountObjForTeacher, setBookCountObjForTeacher] = useState({});
  const [bookGroupCountObj, setBookGroupCountObj] = useState({});
  const [bookGroupCountObjForTeacher, setBookGroupCountObjForTeacher] = useState({});
  const [packageCountObj, setPackageCountObj] = useState({});
  const [bookCartChangedKey, setBookCartChangedKey] = useState(0);
  const [isExpandInvoice, setIsExpandInvoice] = useState(false);

  const { data: dataCampusBook } = useQuery(GET_CAMPUS_BOOK_INFO_LIST, {
    skip: !userInfoVar()?.campus?.idx,
    variables: {
      campus_idx: userInfoVar()?.campus?.idx,
    },
  });
  let company_idx = userInfoVar()?.campus?.company?.idx;
  const { data, loading } = useQuery(COMPANY_BOOK_STORE_PACKAGE_LIST, { variables: { company_idx }, skip: !company_idx });
  const bookSeries = useMemo(() => {
    const list = data?.companyBookStorePackageList;
    if (list) {
      return list.reduce((acc, cur) => {
        if (['SM', 'SK'].includes(cur.book_code)) {
          const smarteclass = { ...cur, book_list: cur.book_list.filter((item) => item.level_no > 0) };
          const phonics = {
            ...cur,
            book: { ...cur.book, book_sub: [{ title: 'Coco Phonics' }] },
            book_list: cur.book_list.filter((item) => item.level_no === 0),
          };
          return [
            ...acc,
            { ...smarteclass, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L1V1.jpg` },
            { ...phonics, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L0V1.jpg` },
          ];
        } else if (['LC'].includes(cur.book_code)) {
          const soil = {
            ...cur,
            book: { ...cur.book, book_sub: [{ title: 'Soil' }] },
            book_list: cur.book_list.filter((item) => item.level_no === 0),
          };
          const seed = {
            ...cur,
            book: { ...cur.book, book_sub: [{ title: 'Seed' }] },
            book_list: cur.book_list.filter((item) => item.level_no === 1),
          };
          const root = {
            ...cur,
            book: { ...cur.book, book_sub: [{ title: 'Root' }] },
            book_list: cur.book_list.filter((item) => item.level_no === 2),
          };
          const leaf = {
            ...cur,
            book: { ...cur.book, book_sub: [{ title: 'Leaf' }] },
            book_list: cur.book_list.filter((item) => item.level_no === 3 || (item.level_no === 4 && item.volume_no <= 15)),
          };
          const stem = {
            ...cur,
            book: { ...cur.book, book_sub: [{ title: 'Stem' }] },
            book_list: cur.book_list.filter((item) => item.level_no === 5 || (item.level_no === 4 && item.volume_no > 15)),
          };
          const flower = {
            ...cur,
            book: { ...cur.book, book_sub: [{ title: 'Flower' }] },
            book_list: cur.book_list.filter((item) => item.level_no === 6 || item.level_no === 7),
          };
          return [
            ...acc,
            { ...seed, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L0V1.jpg` },
            { ...soil, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L1V1.jpg` },
            { ...root, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L2V1.jpg` },
            { ...leaf, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L3V1.jpg` },
            { ...stem, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L5V1.jpg` },
            { ...flower, cover_full_path: `${CDN_BASE_URL}/${cur?.book_code}/cover/L6V1.jpg` },
          ];
        } else {
          return [
            ...acc,
            {
              ...cur,
              cover_full_path: cur?.book?.book_sub[0]?.cover_path
                ? cur?.book?.book_sub[0]?.cover_path
                : `${CDN_BASE_URL}/${cur?.book_code}/cover/L1V1.jpg`,
            },
          ];
        }
      }, []);
    }
    return [];
  }, [data]);
  const bookSeries2 = bookSeries && isAfterSchool(companyName) ? settings[companyName].bookSort(bookSeries) : bookSeries;
  const [bookTitle, bookPrice, books, volumes, root_book_code, isUseCredit, shoppingMallProduct] = useMemo(() => {
    const getPrice = (code) => {
      if (dataCampusBook?.getCampusBookInfoList) {
        const findItem = dataCampusBook.getCampusBookInfoList.find((ele) => ele.book.code === code);
        if (findItem) {
          return findItem.price;
        }
      }
      return null;
    };

    const getPrices = (code) => {
      if (dataCampusBook?.getCampusBookInfoList) {
        const findItem = dataCampusBook.getCampusBookInfoList.find((ele) => ele.book.code === code);
        if (findItem) {
          return findItem.prices;
        }
      }
      return null;
    };

    if (bookItem) {
      const bookItemObject = bookItem;
      const root_book_code = bookItemObject?.book_code;
      const bookTitle = bookItemObject?.book?.book_sub[0]?.title;
      let price = getPrice(root_book_code);
      let prices = getPrices(root_book_code);
      let minRootPrice = prices?.reduce((acc, cur) => (acc.price < cur.price ? acc.price : cur.price), 0);
      const isUseCredit = bookItem.is_use_credit;
      const shoppingMallProduct = bookItem.shopping_mall_product;
      if (bookItemObject.book_list instanceof Array && bookItemObject.book_list.length > 0) {
        const bookMap = new Map();
        let volList = new Set();
        for (const i of bookItemObject.book_list) {
          let bookPrices = getPrices(`${bookItemObject.book_code}-L${i.level_no}V${i.volume_no}`);
          const minPrice = bookPrices?.reduce((acc, cur) => (acc.price < cur.price ? acc.price : cur.price), 0);
          bookPrices = minRootPrice !== 0 && minPrice === 0 ? prices : bookPrices;

          console.log('bookPrices', bookPrices, minPrice, minRootPrice, prices);

          if (bookMap.has(i.level_no)) {
            const item = bookMap.get(i.level_no);
            bookMap.set(i.level_no, { ...item, vol_list: [...item.vol_list, i], price_list: [...item.price_list, bookPrices] });
          } else {
            bookMap.set(i.level_no, { level_name: i.level_name, vol_list: [i], price_list: [bookPrices] });
          }
          volList = volList.add(i.volume_no);
        }
        if (isUseCredit) {
          return [bookTitle, prices, Array.from(bookMap), Array.from(volList), root_book_code, isUseCredit, shoppingMallProduct];
        } else {
          return [bookTitle, price, Array.from(bookMap), Array.from(volList), root_book_code, isUseCredit, shoppingMallProduct];
        }
      } else {
        if (isUseCredit) {
          return [bookTitle, prices, null, null, root_book_code, isUseCredit, shoppingMallProduct];
        } else {
          return [bookTitle, price, null, null, root_book_code, isUseCredit, shoppingMallProduct];
        }
      }
    }
    return [];
  }, [bookItem, dataCampusBook]);
  const totalCurrentBookCount = useMemo(() => {
    const studentBookCnt = Object.entries(bookCountObj).reduce((acc, [, volval]) => {
      return acc + Object.values(volval).reduce((a, c) => a + c, 0);
    }, 0);
    const teacherBookCount = Object.entries(bookCountObjForTeacher).reduce((acc, [, volval]) => {
      return acc + Object.values(volval).reduce((a, c) => a + c, 0);
    }, 0);
    return studentBookCnt + teacherBookCount;
  }, [bookCountObj, bookCountObjForTeacher]);

  const totalCurrentBookGroupCount = useMemo(() => {
    const studentBookGroupCnt = Object.entries(bookGroupCountObj).reduce((gacc, [, gval]) => {
      return (
        gacc +
        Object.entries(gval).reduce((acc, [, volval]) => {
          return (
            acc +
            Object.values(volval).reduce((a, c) => {
              return a + (c.volume !== undefined && !Number.isNaN(c.volume) ? c.volume : c);
            }, 0)
          );
        }, 0)
      );
    }, 0);
    const teacherBookGroupCount = Object.entries(bookGroupCountObjForTeacher).reduce((gacc, [, gval]) => {
      return (
        gacc +
        Object.entries(gval).reduce((acc, [, volval]) => {
          return (
            acc +
            Object.values(volval).reduce((a, c) => {
              return a + (c.volume !== undefined && !Number.isNaN(c.volume) ? c.volume : c);
            }, 0)
          );
        }, 0)
      );
    }, 0);
    return studentBookGroupCnt + teacherBookGroupCount;
  }, [bookGroupCountObj, bookGroupCountObjForTeacher]);

  useEffect(() => {
    setBookCountObj({}); //초기화
    setBookCountObjForTeacher({}); //초기화
    setPackageCountObj({});
    setBookGroupCountObj({}); //초기화
    setBookGroupCountObjForTeacher({}); //초기화
  }, [bookItem]);

  const handleCountChange = (l, v, number, type, defaultValue) => {
    const obj = { ...bookCountObj };
    if (!obj[l]) {
      obj[l] = {};
    }
    if (!obj[l][v]) {
      if (defaultValue) {
        obj[l][v] = {
          volume: 0,
          days: defaultValue,
        };
      } else {
        obj[l][v] = {
          volume: 0,
          days: 0,
        };
      }
    }
    if (type === 'volume') {
      obj[l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : 0;
    }
    if (type === 'days') {
      obj[l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : defaultValue;
    }
    setBookCountObj({ ...obj });
  };

  const handleCountChangeForTeacher = (l, v, number, type, defaultValue) => {
    const obj = { ...bookCountObjForTeacher };
    if (!obj[l]) {
      obj[l] = {};
    }
    if (!obj[l][v]) {
      if (defaultValue) {
        obj[l][v] = {
          volume: 0,
          days: defaultValue,
        };
      } else {
        obj[l][v] = {
          volume: 0,
          days: 0,
        };
      }
    }
    if (type === 'volume') {
      obj[l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : 0;
    }
    if (type === 'days') {
      obj[l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : defaultValue;
    }

    setBookCountObjForTeacher({ ...obj });
  };

  const handlePackageCountChange = (number, type, defaultValue = 0) => {
    const obj = { ...packageCountObj };

    if (type === 'volume') {
      obj[type] = number && !Number.isNaN(number) ? parseInt(number) : 0;
      if (!obj['days']) {
        obj['days'] = defaultValue;
      }
    }
    if (type === 'days') {
      obj[type] = number && !Number.isNaN(number) ? parseInt(number) : 0;
    }
    setPackageCountObj({ ...obj });
  };

  const handleGroupCountChange = (groupVolumeItem, groupDivisionLabel, group, number, type, defaultValue) => {
    const g = group.groupLevelLabel;
    const l = groupVolumeItem.groupVolumeLabel;
    const v = groupDivisionLabel;
    const obj = { ...bookGroupCountObj };
    if (!obj[g]) {
      obj[g] = {};
    }
    if (!obj[g][l]) {
      obj[g][l] = {};
    }
    if (!obj[g][l][v]) {
      if (defaultValue) {
        obj[g][l][v] = {
          volume: 0,
          days: defaultValue,
          books: groupVolumeItem.volumeLevelBooks,
        };
      } else {
        obj[g][l][v] = {
          volume: 0,
          days: 0,
          books: groupVolumeItem.volumeLevelBooks,
        };
      }
    }
    if (type === 'volume') {
      obj[g][l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : 0;
    }
    if (type === 'days') {
      obj[g][l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : defaultValue;
    }
    setBookGroupCountObj({ ...obj });
  };

  const handleGroupCountChangeForTeacher = (groupVolumeItem, groupDivisionLabel, group, number, type, defaultValue) => {
    const g = group.groupLevelLabel;
    const l = groupVolumeItem.groupVolumeLabel;
    const v = groupDivisionLabel;
    const obj = { ...bookGroupCountObjForTeacher };
    if (!obj[g]) {
      obj[g] = {};
    }
    if (!obj[g][l]) {
      obj[g][l] = {};
    }
    if (!obj[g][l][v]) {
      if (defaultValue) {
        obj[g][l][v] = {
          volume: 0,
          days: defaultValue,
          books: groupVolumeItem.volumeLevelBooks,
        };
      } else {
        obj[g][l][v] = {
          volume: 0,
          days: 0,
          books: groupVolumeItem.volumeLevelBooks,
        };
      }
    }
    if (type === 'volume') {
      obj[g][l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : 0;
    }
    if (type === 'days') {
      obj[g][l][v][type] = number && !Number.isNaN(number) ? parseInt(number) : defaultValue;
    }

    setBookGroupCountObjForTeacher({ ...obj });
  };

  const handleMoveToCart = () => {
    if (totalCurrentBookCount === 0 && (packageCountObj?.volume ? packageCountObj?.volume : 0) === 0 && totalCurrentBookGroupCount === 0) {
      Modal.info({
        title: '책 권수를 선택하세요.',
      });
      return;
    }

    const bookCartData = bookCartVar();

    let cartMap = new Map();
    bookCartData.forEach((cartItem) => {
      cartMap.set(cartItem.key, cartItem);
    });

    Object.entries(bookCountObj).forEach(([l, volObj]) => {
      cartMap = resolveBookCartData(
        bookTitle,
        bookPrice,
        books,
        root_book_code,
        cartMap,
        l,
        volObj,
        isUseCredit,
        shoppingMallProduct,
        isAfterSchool(companyName),
        false,
      );
    });

    Object.entries(bookCountObjForTeacher).forEach(([l, volObj]) => {
      cartMap = resolveBookCartData(
        bookTitle,
        bookPrice,
        books,
        root_book_code,
        cartMap,
        l,
        volObj,
        isUseCredit,
        shoppingMallProduct,
        isAfterSchool(companyName),
        true,
      );
    });

    if (packageCountObj && packageCountObj.volume) {
      cartMap = resolvePackageCartData(
        bookTitle,
        bookPrice,
        root_book_code,
        cartMap,
        packageCountObj,
        isUseCredit,
        shoppingMallProduct,
        isAfterSchool(companyName),
        false,
      );
    }

    if (bookGroupCountObj) {
      cartMap = resolveBookGroupCartData(
        bookTitle,
        bookPrice,
        books,
        root_book_code,
        cartMap,
        bookGroupCountObj,
        isUseCredit,
        shoppingMallProduct,
        isLucid(companyName),
        false,
      );
    }

    if (bookGroupCountObjForTeacher) {
      cartMap = resolveBookGroupCartData(
        bookTitle,
        bookPrice,
        books,
        root_book_code,
        cartMap,
        bookGroupCountObjForTeacher,
        isUseCredit,
        shoppingMallProduct,
        isLucid(companyName),
        true,
      );
    }

    const cartArray = Array.from(cartMap.values());
    if (
      (isAfterSchool(companyName) || isLucid(companyName)) &&
      cartArray.some((x) => x.type === 'package') &&
      cartArray.some((x) => x.type === 'single')
    ) {
      Modal.info({
        title: '패키지와 개별 교재의 레벨/볼륨은 함께 있을 수 없습니다.',
      });
      return;
    } else if (cartArray.some((x) => x.shoppingMallProduct === true) && cartArray.some((x) => x.shoppingMallProduct === false)) {
      Modal.info({
        title: '쇼핑몰 상품과 회사의 상품은 함께 있을 수 없습니다.',
      });
      return;
    }

    bookCartVar(cartArray);
    onBookChange('');
    setBookCartChangedKey((prev) => prev + 1);
  };

  const type = userInfoVar()?.type;
  if (isAfterSchool(companyName)) {
    if (type && ![1, 2, 3].includes(type)) {
      history.replace('/');
    }
  } else {
    if (type && type !== 2) {
      history.replace('/');
    }
  }
  const BookCoverImage = ({ src, href }) => {
    return (
      <section
        style={{
          position: 'relative',
          textAlign: 'center',
          paddingLeft: '20px',
          paddingRight: '20px',
          // height: '30%',
        }}
      >
        <Image
          preview={false}
          style={{ width: '100%', maxWidth: '188px', marginTop: '10px', border: '1px solid #dedede' }}
          src={src}
          fallback="/images/menu-icon/content.png"
        />
      </section>
    );
  };
  const Grid = styled(Card.Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 31vh;
    border: 1px solid #dedede;
  `;
  const handleBook = (val) => {
    onBookChange(val);
  };
  return (
    <>
      {/* {isAfterSchool(companyName)&&<SimplePopup />} */}
      <Col className="bookstore-book-order-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{ textAlign: 'left' }}>
            {/* <HeaderTitle level={4}>Book Store(컨텐츠 구매)</HeaderTitle> */}
            <BookOrderMenuGroup currentMenu="book-order" />
          </Col>
          <Col className="topheader-image" span={12} style={{ textAlign: 'right' }}></Col>
        </Row>
        <MainBlock className="bookstore-book-order-mainblock">
          <Row gutter={[16, 16]}>
            <Col
              className="bookstore-book-order-mainblock-a"
              span={isExpandInvoice ? 0 : 16}
              style={{ textAlign: 'left', borderRight: '1px solid #aaa' }}
            >
              <Row gutter={[16, 16]} className="bookstore-header-top">
                <Col className="bookstore-heading" span={12} style={{ textAlign: 'left', margin: 0 }}>
                  <Title className="mainheader-left" level={5}>
                    <div>컨텐츠 구매( Purchase )</div>
                    <div>
                      <img
                        src={bookItem ? titleimageBack : titleimagetwo}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          onBookChange('');
                          setBookCountObj({}); //초기화
                          setBookCountObjForTeacher({}); //초기화
                        }}
                        alt=""
                      />
                    </div>
                    {!isPturn(companyName)&&<>
                    &nbsp;
                    <Popover content={popOverContent} title='상품 구매 방법' trigger='click'>
                      <Button size='small' style={{margin:'auto 0'}}>상품 구매 방법</Button>
                    </Popover>
                    </>}
                  </Title>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <div className="book-order-main-wrapper" style={{ width: '100%' }}>
                  <Col span={24} style={{ textAlign: 'left', margin: 0 }}>
                    <div className="calendra-outline">
                      <div className="calendra-outline-date-bold">
                        <CalendarOutlined /> Date
                      </div>
                      <div className="calendra-outline-date">{englishMoment.format('ddd, YYYY/MM/DD')}</div>
                    </div>
                  </Col>
                  <Col className="booktable-main-tbl-main" span={24} style={{ textAlign: 'left', margin: 0 }}>
                    {!bookItem ? (
                      <>
                        <Card title={`구매할 ${isPturn(companyName) ? '책 시리즈를' : '상품을'} 선택하세요.`}>
                          {bookSeries
                            ? (isAfterSchool(companyName) && bookSeries2 ? bookSeries2 : bookSeries).map((item) => {
                                return (
                                  <Grid onClick={() => handleBook(item)} style={{ float: 'left' }}>
                                    <div>
                                      <BookCoverImage
                                        src={
                                          item?.book?.book_sub[0].cover_path ? item?.book?.book_sub[0].cover_path : item?.cover_full_path
                                        }
                                      />
                                      <h1 style={{ textAlign: 'center' }}>
                                        {item?.book?.book_sub[0].title ? item?.book?.book_sub[0].title : item?.book_code}
                                      </h1>
                                      {!isPturn(companyName)&&<h4 style={{ textAlign: 'center' }}>￦{item.price}</h4>}
                                    </div>
                                  </Grid>
                                );
                              })
                            : null}
                        </Card>
                        {isAfterSchool(companyName) && <p>1시 이전에 주문하면 당일 발송됩니다. 1시 이후에 주문하면 익일 발송됩니다.</p>}
                      </>
                    ) : (
                      <>
                        {isAfterSchool(companyName) && (
                          <>
                            <p>1시 이전에 주문하면 당일 발송됩니다. 1시 이후에 주문하면 익일 발송됩니다.</p>
                            <div style={{ width: '100%', textAlign: 'right', marginBottom: 5 }}>
                              <Tag color="blue" style={{ fontSize: '14px' }}>
                                학생용
                              </Tag>
                            </div>
                          </>
                        )}
                        {!books || bookItem.book?.book_sub[0]?.book_category?.name === 'package' ? (
                          <PackageOrderTable
                            bookTitle={bookTitle}
                            root_book_code={root_book_code}
                            prices={bookPrice}
                            packageCountObj={packageCountObj}
                            isUseCredit={bookItem.is_use_credit}
                            onCountChange={handlePackageCountChange}
                          />
                        ) : isLucid(companyName) && root_book_code === 'LC' ? (
                          <BookGroupOrderTable
                            bookTitle={bookTitle}
                            volumes={volumes}
                            isReserch={false}
                            books={books}
                            bookCountObj={bookGroupCountObj}
                            isUseCredit={bookItem.is_use_credit}
                            onCountChange={handleGroupCountChange}
                          />
                        ) : (
                          <BookOrderTable
                            bookTitle={bookTitle}
                            volumes={volumes}
                            root_book_code={root_book_code}
                            books={books}
                            bookCountObj={bookCountObj}
                            isUseCredit={bookItem.is_use_credit}
                            onCountChange={handleCountChange}
                            isAfterSchool={['smart-eclass', 'sneakersedu'].includes(companyName)}
                          />
                        )}
                        {isAfterSchool(companyName) && books && (
                          <>
                            <div style={{ width: '100%', textAlign: 'right', marginBottom: 5 }}>
                              <Tag color="blue" style={{ fontSize: '14px' }}>
                                교사용
                              </Tag>
                            </div>
                            <BookOrderTable
                              bookTitle={bookTitle}
                              volumes={volumes}
                              root_book_code={root_book_code}
                              books={books}
                              bookCountObj={bookCountObjForTeacher}
                              isUseCredit={bookItem.is_use_credit}
                              onCountChange={handleCountChangeForTeacher}
                              isAfterSchool={['smart-eclass', 'sneakersedu'].includes(companyName)}
                            />
                          </>
                        )}
                        {isLucid(companyName) && books && root_book_code === 'LC' && (
                          <>
                            <div style={{ width: '100%', textAlign: 'right', marginBottom: 5 }}>
                              <Tag color="blue" style={{ fontSize: '14px' }}>
                                교사용
                              </Tag>
                            </div>
                            <BookGroupOrderTable
                              bookTitle={bookTitle}
                              volumes={volumes}
                              isReserch={true}
                              books={books}
                              bookCountObj={bookGroupCountObjForTeacher}
                              isUseCredit={bookItem.is_use_credit}
                              onCountChange={handleGroupCountChangeForTeacher}
                            />
                          </>
                        )}
                        <div style={{ marginTop: 10, textAlign: 'right' }}>
                          <Button type="default" icon={<ShoppingCartOutlined />} onClick={handleMoveToCart}>
                            장바구니저장
                          </Button>
                        </div>
                      </>
                    )}
                  </Col>
                </div>
              </Row>
            </Col>
            <Col className="bookstore-book-order-mainblock-b" span={isExpandInvoice ? 24 : 8} style={{ textAlign: 'left', margin: 0 }}>
              <BookCart onExpand={setIsExpandInvoice} isExpandInvoice={isExpandInvoice} bookCartChangedKey={bookCartChangedKey} />
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default BookOrder;

const popOverContent = () => (
  <div>
    <p>1. 구매할 상품을 선택합니다.</p>
    <p>2. 구매한 상품의 수량을 입력합니다.</p>
    <p>3. [장바구니저장] 버튼을 클릭합니다.</p>
    <p>4. 구매 내역 확인 후 [주문 요청] 버튼을 클릭합니다.</p>
    <p>5. [결제하기] 버튼을 클릭하여 결제를 완료합니다. </p>
  </div>
);
