import React, { useMemo } from 'react';

/*import ManagerPage from './ManagerPage';
import StudentPage from './StudentPage';*/
import CampusPage from './CampusPage';
/*import HeadManagerPage from './HeadManagerPage';
import AdminPage from './AdminPage';
import BranchPage from './BranchPage';*/

import { getCurrentToken } from 'src/utils';
import { userVar } from 'src/apollo/cache';
import { useQueryProxy } from 'src/operations/proxy/user';
import * as queries from 'src/operations/queries';

const DashBoard = () => {
  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  const userType = useMemo(() => userVar()[data?.me?.type] || userVar()[0], [data]);

  const userPage = {
    //student: <StudentPage />,
    //manager: <ManagerPage />, //강사
    campus: <CampusPage />, //원장
    //branch: <BranchPage />, //지사
    //fc: <HeadManagerPage />,
    //admin: <AdminPage />,
  };

  //return <>{userPage[userType]}</>;
  return <><CampusPage /></>;
};

export default DashBoard;
