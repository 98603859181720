import React from 'react';
import { Button, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { openNotification } from '../common/Notification';
import * as mutations from 'src/operations/mutations';
import { userInfoVar } from 'src/apollo/cache';
import { useState } from 'react';

function MoveStudentModal({ visible, handleCancel, class_idx, users, refetch }) {
  const { selectedClassIdx, onClassChange } = useCampusClass(0);
  const campus_idx = userInfoVar()?.campus_idx;
  const [block, setBlock] = useState(false);
  const [moveStudent] = useMutation(mutations.classRoom.MOVE_STUDENT_TO_OTHER_CLASS, {
    onCompleted: () => {
      // openNotification('이동 완료!');
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });
  const handleMoveStudent = () => {
    if (class_idx === selectedClassIdx) {
      alert('기존 반과 다른 반을 선택하세요.');
      return;
    } else if (class_idx && selectedClassIdx && users.length > 0) {
      setBlock(true);
      users.map((e) =>
        moveStudent({
          variables: {
            class_idx: class_idx,
            user_idx: e,
            target_class_idx: selectedClassIdx,
          },
        }),
      );
    }
    handleCancel();
    setBlock(false);
    openNotification('이동 완료!');
    setTimeout(() => {
      refetch();
      window.location.reload();
    }, 500);
  };
  return (
    <>
      <Modal
        visible={visible}
        title={'Class 이동'}
        // onCanel={() => {
        //   handleCancel();
        // }}
        width={`50%`}
        footer={[
          <Button key="button" disabled={block} onClick={handleMoveStudent} type="primary">
            확인
          </Button>,
          <Button
            key="back"
            onClick={() => {
              handleCancel();
            }}
          >
            취소
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {' '}
          <h3>*Class 이동 전 주의 사항*</h3>
          <h4>Class를 이동하기 전에, 먼저 이동할 학생이 사용중인 프로그램의 Assigned Group List에서 학생을 체크 해제해야 합니다.</h4>
          <h4>이렇게 해야 Class 이동 후에도 현재 Class에서 오늘까지 학습한 기록이 저장됩니다. </h4>
          <h4>이 과정을 생략하고 Class를 이동하면 현재 Class에서 학습한 기록이 모두 삭제되어 복구할 수 없으니 주의하시기 바랍니다.</h4>
          <br />
          <h4>※학생 체크 해제 경로 : Assigned Group List → 현재 Class 과제 Edit 클릭 → √ 해제 후 저장</h4>
          <br />
          <div style={{ width: '50%' }}>
            <ClassSelectBox
              firstRowDisplayValue="Class 선택"
              onClassChange={onClassChange}
              selectedClassIdx={selectedClassIdx}
              campus_idx={campus_idx}
            />
          </div>
          <br />
          <div>위의 Class로 {users.length}명의 학생을 이동하시겠습니까?</div>
        </div>
      </Modal>
    </>
  );
}

export default MoveStudentModal;
