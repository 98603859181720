import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
const { Title } = Typography;

export const HeaderTitle = styled(Title)`
  display: flex;
  align-items: center;
  padding-top: 1rem;
`;

export const TableHeaderWrapper = styled.div`
  padding: 12px;
  background: #fff;
  min-height: calc(100vh - 144px);
`;

export const HalfWrapper = styled.div`
  padding: 12px;
  background: #fff;
  border-radius: 10px;
  min-height: calc(100vh - 18vh);
`;

export const PageWrapper = styled.div`
  padding: 12px;
  background: #fff;
  box-shadow: 2px 1px 15px 10px #dddddd;
  min-height: calc(100vh - 18vh);
`;

export const StudentHalfWrapper = styled.div`
  background: #fff;
  box-shadow: 2px 1px 15px 10px #dddddd;
  min-height: calc(100vh - 18vh);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TodoBlock = styled.div`
  padding: 6px;
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 2px 1px 15px 10px #dddddd;
  min-height: calc(100vh - 68vh);
`;

export const HRLine = styled.hr`
  border: none;
  border-top: 3px dotted #bcbcbc;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
`;

export const DetailContents = styled.div`
  & + & {
    margin-left: 10px;
  }

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}%;
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

${(props) =>
    props.size &&
    css`
      font-size: ${props.size}rem;
    `}
`;

export const FieldContents = styled.span`
  & + & {
    margin-left: 1.5rem;
  }

  ${(props) =>
    props.margin &&
    css`
      & + & {
        margin-left: ${props.margin}em;
      }
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}%;
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

${(props) =>
    props.size &&
    css`
      font-size: ${props.size}rem;
    `}
`;

export const DivBlock = styled.div`
  display: flex;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  ${(props) => {
    const justifyContent = props.justifyContent || 'space-between';
    return css`
      justify-content: ${justifyContent};
    `;
  }}
`;

export const NoticeBlock = styled.div`
  padding: 12px;
  background: #fff;
  box-shadow: 2px 1px 15px 10px #dddddd;
  min-height: calc(100vh - 52.5vh);
`;

export const ListTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
export const TableBtnsWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
  flex-direction: row-reverse;
`;

export const NoticeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

export const PrintDummy = styled.div`
  display: none;
  @media print {
    padding: 10px;
    display: block;
  }
`;

export const ElibraryTable = styled(CustomTable)`
  table {
    border-collapse: collapse;
  }
  thead[class*='ant-table-thead'] th {
    border: 1px solid #ddd;
  }
  thead[class*='ant-table-thead'] th:first-child {
    border-left: 0px solid #ddd;
  }
  thead[class*='ant-table-thead'] th:last-child {
    border-right: 0px solid #ddd;
  }
  thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px !important;
  }
`;
