import React from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';

import Add from './add';
import Assign from './assign';
import EditAssign from './edit-assign';

const ManageAITopic = (props) => {
  const { type = 'add', id = undefined, class_idx } = useParams();

  const handleGoBack = () => {
    props.history.goBack();
  };

  const getSwitchableRoute = (val, id) => {
    switch (val) {
      case 'edit-assign':
        return <EditAssign id={parseInt(id)} class_idx={class_idx} isEdit={true} onClickGoback={handleGoBack} />;
      case 'assign':
        return <Assign onClickGoback={handleGoBack} />;
      case 'edit':
        return <Add id={parseInt(id)} isEdit={true} onClickGoback={handleGoBack} />;
      case 'add':
      default:
        return <Add onClickGoback={handleGoBack} />;
    }
  };

  return (
    <>
      <Row gutter={[24, 16]}>{getSwitchableRoute(type, id)}</Row>
    </>
  );
};

export default withRouter(ManageAITopic);
