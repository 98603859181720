export const notice = [
  {
    key: '1',
    No: '1',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '2',
    No: '2',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '3',
    No: '3',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '4',
    No: '4',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '5',
    No: '5',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '6',
    No: '6',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '7',
    No: '7',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '8',
    No: '8',
    title: '제목입니다',
    date: '12020-09-01',
  },
  {
    key: '9',
    No: '9',
    title: '제목입니다',
    date: '12020-09-01',
  },
  {
    key: '10',
    No: '10',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '11',
    No: '11',
    title: '제목입니다',
    date: '2020-09-01',
  },
  {
    key: '12',
    No: '12',
    title: '제목입니다',
    date: '2020-09-01',
  },
];

export const teacher = [
  {
    key: '1',
    No: '1',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '2',
    No: '2',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '3',
    No: '3',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '4',
    No: '4',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '5',
    No: '5',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '6',
    No: '6',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '7',
    No: '7',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '8',
    No: '8',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '9',
    No: '9',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '10',
    No: '10',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '11',
    No: '11',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '12',
    No: '12',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '13',
    No: '13',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '14',
    No: '14',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
  {
    key: '15',
    No: '15',
    name: '강사명',
    id: 'id123456',
    phone: '010-0000-0000',
    email: 'email@email.com',
  },
];

export const className = [
  {
    key: '1',
    className: '초등3학년-04',
    schedule: '월, 수, 금 14:00 ~ 16:00',
    content: '교재명',
  },
  {
    key: '2',
    className: '초등2학년-02',
    schedule: '화, 목 16:00 ~ 18:00',
    content: '교재명',
  },
  {
    key: '3',
    className: '초등3학년-06',
    schedule: '월, 수, 금 10:00 ~ 12:00',
    content: '교재명',
  },
];

export const classNameList = [
  {
    key: '1',
    No: '1',
    className: '초등3학년-04',
    schedule: '월, 수, 금 14:00 ~ 16:00',
    content: '교재명',
  },
  {
    key: '2',
    No: '2',
    className: '초등2학년-02',
    schedule: '화, 목 16:00 ~ 18:00',
    content: '교재명',
  },
  {
    key: '3',
    No: '3',
    className: '초등3학년-06',
    schedule: '월, 수, 금 10:00 ~ 12:00',
    content: '교재명',
  },
];

export const studing = [
  {
    key: '1',
    className: 'English 1',
    start_date: '2021-01-13',
    end_date: '2021-01-20',
    teacher: '선생님',
    level: '3',
  },
];

export const classRoomStudentList = [
  {
    key: '1',
    name: '홍길동',
    id: 'ID',
    enName: 'English Name',
    useTicket: '○',
  },
  {
    key: '2',
    name: '홍길동',
    id: 'ID',
    enName: 'English Name',
    useTicket: 'X',
  },
  {
    key: '3',
    name: '홍길동',
    id: 'ID',
    enName: 'English Name',
    useTicket: '○',
  },
  {
    key: '4',
    name: '홍길동',
    id: 'ID',
    enName: 'English Name',
    useTicket: '○',
  },
];

export const learningList = [
  {
    key: '1',
    No: '1',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '2',
    No: '2',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '3',
    No: '3',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '4',
    No: '4',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '5',
    No: '5',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '6',
    No: '6',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '7',
    No: '7',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '8',
    No: '8',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '9',
    No: '9',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '10',
    No: '10',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '11',
    No: '11',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '12',
    No: '12',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '13',
    No: '13',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '14',
    No: '14',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '15',
    No: '15',
    class: '반이름',
    name: '학생1',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
];

export const learnStudy = [
  {
    key: '1',
    date: '2020-00-00',
    lessonName: '레슨명',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '2',
    date: '2020-00-00',
    lessonName: '레슨명',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '3',
    date: '2020-00-00',
    lessonName: '레슨명',
    attendance: '○',
    complete: '○',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '4',
    date: '2020-00-00',
    lessonName: '레슨명',
    attendance: '○',
    complete: '○',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '5',
    date: '2020-00-00',
    lessonName: '레슨명',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
  {
    key: '6',
    date: '2020-00-00',
    lessonName: '레슨명',
    attendance: '○',
    complete: 'X',
    step1: true,
    step2: true,
    step3: true,
    step4: false,
    test: '00 / 00',
  },
];

export const assignTicketList = [
  {
    key: '1',
    name: '학생이름',
    id: '학생 아이디',
    enName: 'English Name',
    class: 'Class01',
    studentPhone: '010-0000-0000',
    parentPhone: '010-0000-0000',
  },
  {
    key: '2',
    name: '학생이름',
    id: '학생 아이디',
    enName: 'English Name',
    class: 'Class01',
    studentPhone: '010-0000-0000',
    parentPhone: '010-0000-0000',
  },
  {
    key: '3',
    name: '학생이름',
    id: '학생 아이디',
    enName: 'English Name',
    class: 'Class01',
    studentPhone: '010-0000-0000',
    parentPhone: '010-0000-0000',
  },
  {
    key: '4',
    name: '학생이름',
    id: '학생 아이디',
    enName: 'English Name',
    class: 'Class01',
    studentPhone: '010-0000-0000',
    parentPhone: '010-0000-0000',
  },
  {
    key: '5',
    name: '학생이름',
    id: '학생 아이디',
    enName: 'English Name',
    class: 'Class01',
    studentPhone: '010-0000-0000',
    parentPhone: '010-0000-0000',
  },
  {
    key: '6',
    name: '학생이름',
    id: '학생 아이디',
    enName: 'English Name',
    class: 'Class01',
    studentPhone: '010-0000-0000',
    parentPhone: '010-0000-0000',
  },
  {
    key: '7',
    name: '학생이름',
    id: '학생 아이디',
    enName: 'English Name',
    class: 'Class01',
    studentPhone: '010-0000-0000',
    parentPhone: '010-0000-0000',
  },
  {
    key: '8',
    name: '학생이름',
    id: '학생 아이디',
    enName: 'English Name',
    class: 'Class01',
    studentPhone: '010-0000-0000',
    parentPhone: '010-0000-0000',
  },
];

export const RecallicketList = [
  {
    key: '1',
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useState: ['부여완료'],
    recallState: ['가능'],
    name: '학생이름',
    id: '학생 아이디',
  },
  {
    key: '2',
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useState: ['생성완료'],
    recallState: ['가능'],
    name: '학생이름',
    id: '학생 아이디',
  },
  {
    key: '3',
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useState: ['부여완료'],
    recallState: ['불가'],
    name: '학생이름',
    id: '학생 아이디',
  },
  {
    key: '4',
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useState: ['생성완료'],
    recallState: ['가능'],
    name: '학생이름',
    id: '학생 아이디',
  },
  {
    key: '5',
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useState: ['부여완료'],
    recallState: ['가능'],
    name: '학생이름',
    id: '학생 아이디',
  },
  {
    key: '6',
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useState: ['부여완료'],
    recallState: ['불가'],
    name: '학생이름',
    id: '학생 아이디',
  },
  {
    key: '7',
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useState: ['생성완료'],
    recallState: ['가능'],
    name: '학생이름',
    id: '학생 아이디',
  },
  {
    key: '8',
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useState: ['부여완료'],
    recallState: ['불가'],
    name: '학생이름',
    id: '학생 아이디',
  },
];

export const TicketStatisticsList = [
  {
    key: '1',
    no: 1,
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    ticketNumber: '2020-00-00',
    name: '학생이름',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useTicketDay: '2020-00-00',
    useState: ['부여완료'],
  },
  {
    key: '2',
    no: 2,
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    ticketNumber: '2020-00-00',
    name: '학생이름',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useTicketDay: '2020-00-00',
    useState: ['사용완료'],
  },
  {
    key: '3',
    no: 3,
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    ticketNumber: '2020-00-00',
    name: '학생이름',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useTicketDay: '2020-00-00',
    useState: ['생성완료'],
  },
  {
    key: '4',
    no: 4,
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    ticketNumber: '2020-00-00',
    name: '학생이름',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useTicketDay: '2020-00-00',
    useState: ['부여완료'],
  },
  {
    key: '5',
    no: 5,
    bookName: '교재명',
    chapter: '목록',
    ticketType: '레벨테스트',
    ticketNumber: '2020-00-00',
    name: '학생이름',
    assignDay: '2020-00-00',
    studyStartDay: '2020-00-00',
    useTicketDay: '2020-00-00',
    useState: ['생성완료'],
  },
];

export const ConsultingsList = [
  {
    key: '1',
    no: 1,
    name: '학생이름',
    id: '학생ID',
    type: '상담신청',
    manager: '담당자명',
    state: ['상담신청'],
  },
  {
    key: '2',
    no: 2,
    name: '학생이름',
    id: '학생ID',
    type: '상담신청',
    manager: '담당자명',
    state: ['진행중'],
  },
  {
    key: '3',
    no: 3,
    name: '학생이름',
    id: '학생ID',
    type: '상담신청',
    manager: '담당자명',
    state: ['상담종료'],
  },
  {
    key: '4',
    no: 4,
    name: '학생이름',
    id: '학생ID',
    type: '상담신청',
    manager: '담당자명',
    state: ['상담종료'],
  },
  {
    key: '5',
    no: 5,
    name: '학생이름',
    id: '학생ID',
    type: '상담신청',
    manager: '담당자명',
    state: ['상담종료'],
  },
  {
    key: '6',
    no: 6,
    name: '학생이름',
    id: '학생ID',
    type: '상담신청',
    manager: '담당자명',
    state: ['상담종료'],
  },
  {
    key: '7',
    no: 7,
    name: '학생이름',
    id: '학생ID',
    type: '상담신청',
    manager: '담당자명',
    state: ['상담종료'],
  },
];

export const PopupList = [
  {
    key: '1',
    no: 1,
    title: '팝업이름',
    sdate: '2020-11-20',
    edate: '2020-12-20',
  },
  {
    key: '2',
    no: 2,
    title: '팝업이름',
    sdate: '2020-11-20',
    edate: '2020-12-20',
  },
  {
    key: '3',
    no: 3,
    title: '팝업이름',
    sdate: '2020-11-20',
    edate: '2020-12-20',
  },
  {
    key: '4',
    no: 4,
    title: '팝업이름',
    sdate: '2020-11-20',
    edate: '2020-12-20',
  },
  {
    key: '5',
    no: 5,
    title: '팝업이름',
    sdate: '2020-11-20',
    edate: '2020-12-20',
  },
  {
    key: '6',
    no: 6,
    title: '팝업이름',
    sdate: '2020-11-20',
    edate: '2020-12-20',
  },
  {
    key: '7',
    no: 7,
    title: '팝업이름',
    sdate: '2020-11-20',
    edate: '2020-12-20',
  },
];

export const PointList = [
  {
    key: '1',
    no: 1,
    name: '학생명',
    userId: '학생 ID',
    point: '100',
  },
  {
    key: '2',
    no: 2,
    name: '학생이름',
    userId: '학생 ID',
    point: '0',
  },
];

export const PointDetailList = [
  {
    key: '1',
    date: 1,
    contents: '포인트 내용',
    point: 100,
  },
  {
    key: '2',
    date: 2,
    contents: '포인트 내용2',
    point: -10,
  },
];

export const BoardlList = [
  {
    key: '1',
    title: '게시판 제목',
    name: '홍길동',
    date: '2020-11-20',
    hit: 98,
  },
  {
    key: '2',
    title: '공지사항 게시판 타이틀',
    name: 'goodzzong',
    date: '2020-11-20',
    hit: 101,
  },
];
