import React, { useState } from 'react';
import { Button, Row } from 'antd';
import styled from 'styled-components';

import { HeaderTitle } from 'src/components/common/Styles';
import Student from 'src/components/DashBoard/MainPage/Student';
import SubPage from 'src/components/DashBoard/SubPage';
import { Redirect } from 'react-router-dom';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AdminPage = () => {
  const [tab, setTab] = useState(false);
  const onChangeTab = () => {
    setTab((prev) => !prev);
  };

  return <Redirect to="/fcManager" />;

  // return (
  //   <>
  //     <div
  //       style={{
  //         display: 'flex',
  //         justifyContent: 'space-between',
  //         height: '6vh',
  //       }}
  //     >
  //       <HeaderTitle level={4}>FC 관리</HeaderTitle>
  //     </div>

  //     <Row gutter={[24, 16]}>{!tab ? <Student /> : <SubPage />}</Row>
  //   </>
  // );
};

export default AdminPage;
