import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, Select, Spin, message, Modal, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import styled from 'styled-components';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { openNotification } from 'src/components/common/Notification';
import { useHistory } from 'react-router-dom';
import FullScreenButton from 'src/components/common/FullScreenButton';
import 'react-simple-keyboard/build/css/index.css';
import htmlToDraft from 'html-to-draftjs';
import * as axios from 'axios';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import ReactPlayer from 'react-player';
import * as diff_match_patch from 'diff-match-patch';
import { captureUserMedia } from 'src/utils';
const dmp = new diff_match_patch();
const { Title: TypoTitle } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const dummyData = [
  {
    page: 0,
    recording: '1',
    text: ``,
  },
];
const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Header = styled(RowFlex)`
  justify-content: start;
  align-items: center;
  height: 75px;
  padding: 16px 65px 16px 45px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;
const ImgButton = styled.img`
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 5px;
`;
const StageTitle = styled.div`
  border-bottom: 1px solid #ed7d31;
  width: 100%;
  padding: 10px 30px;
`;
const StageTitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#0070c0')};
  font-size: 25px;
  font-weight: 600;
  padding: 0 45px;
  @media (max-width: 1280px) {
    font-size: 22px;
    padding: 40px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 20px 0;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 5px 30px 15px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;

const LeftBody = styled.div`
  width: 60%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
`;

const TextBox = styled.div`
  flex: 1;
  border: 2px #ff9900 solid;
  height: 50%;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  color: #797979;
  flex: 1;
  background-color: white;
  border-radius: 15px;
  overflow: auto;
`;

const Paginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: auto;
  padding: 10px 20px;
  border-radius: 15px;
  /* background-color: #c7e3fd; */
`;

const PageButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.3em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: 6px 20px;
  margin: 0 10px;
`;

const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #fff;
  background: #ed7d31;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const LoadingScreen = styled.div`
  height: 100%;
  background: #ffffff88;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightBody = styled(ColumnFlex)`
  width: 40%;
  padding: 0 20px;
  height: 85%;
  justify-content: space-between;
`;
const InfoBox = styled(ColumnFlex)`
  color: #000000;
  align-items: center;
  font-weight: 600;
  border: 2px #ff9900 solid;
  border-radius: 15px;
  /* height: 50%; */
  font-size: 25px;
  padding-bottom: 20px;
`;
const InfoHead = styled.div`
  background-color: #eaaa3e;
  border: 3px #eaaa3e solid;
  text-align: center;
  color: #fff;
  width: 101%;
  padding: 10px 0;
  border-radius: 15px;
  margin-top: -2px;
`;
const InfoContent = styled(RowFlex)`
  color: ${(props) => props.color};
  justify-content: space-between;
  padding: 15px 45px;
  width: 100%;
`;
const RecordBox = styled(ColumnFlex)`
  border-radius: 15px;
  background-color: #f3db4c;
  align-items: center;
  padding: 20px 25px;
`;
const SoundBarWrapper = styled.div`
  width: 100%;
  position: relative;
`;
const SoundBar = styled.input`
  width: 90%;
  margin: 1rem;
  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  ::-webkit-slider-runnable-track {
    height: 5px;
    background: #6e93c1;
    background-size: var(--background-size, 0%) 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
  }
  ::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: #ccc;
    border-radius: 50%;
    margin-top: -6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  }
  :-moz-range-progress {
    background-color: #293043;
    border-radius: 5px;
  }
  ::-moz-range-track {
    background-color: #d7d7d7;
    border-radius: 5px;
  }
  :-moz-range-thumb {
    width: 15px;
    height: 15px;
    cursor: pointer;
    background: #293043;
    border: solid white 1px;
    border-radius: 50%;
    margin-top: -6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  }
`;
const ButtonWrapper = styled(RowFlex)`
  margin: 0 auto;
`;
const RecordButton = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Button = styled.div`
  cursor: pointer;
  border-radius: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  height: 40px;
  padding: 8px 45px;
  @media (max-width: 1280px) {
    height: 32px;
    font-size: 1.2em;
    padding: 6px 25px;
  }
`;
export default function LevelUpWPM({ recordData = [], defaultLevel, defaultChatList, idx, onShowRecording }) {
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState(EditorState.createEmpty());
  const [textRest, setTextResp] = useState(EditorState.createEmpty());
  const [currentLessonText, setCurrentLessonText] = useState([]);
  const [firstPageNo, setFirstPageNo] = useState(0);
  const [isMobile, setIsMobile] = useState(useCheckMobileScreen());
  const [doneLessonText, setDoneLessonText] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [currentText, setCurrentText] = useState([]);
  const [lastText, setLastText] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState([]);
  const [startPageIndx, setStartPageIndx] = useState(-1);
  const [sttResp, setSttResp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSize, setImageSize] = useState('100%');
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [recordingData, setRecordignData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [lastBlob, setLastBlob] = useState(null);
  const recordAudio = useRef(null);
  const [blobChunks, setBlobChunks] = useState([]);
  const mainBlockRef = useRef(null);
  const currentSttRequest = useRef([]);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const currentSttText = useRef([]);
  const nextItemRef = useRef(null);
  const [currentProgressSlider, setCurrentProgressSlider] = useState(0);
  const [currentDoneValue, setCurrentDoneValue] = useState(null);
  const [processCallbackLoading, setProcessCallbackLoading] = useState([]);
  const [data, setData] = useState(dummyData);
  const currentTextValue = currentText.map((e) => e.text).join('\n');
  const [apiSuccess, setApiSuccess] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const word_count = recordData.length > 0 ? recordData[currentPage]['text'].split(' ').length : 0;
  return (
    <>
      <Modal
        title={'Match recording'}
        visible={showModal}
        width="100%"
        bodyStyle={{ height: 'calc(100vh - 200px)', overflowY: 'auto', padding: 10 }}
        style={{ top: 20, height: '80%' }}
        closable={true}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Title level={4} style={{ color: 'darkblue', marginTop: '10px', marginLeft: '20px' }}>
                Extra Text
              </Title>
              <Title level={4} style={{ color: 'brown', marginTop: '10px', marginLeft: '20px' }}>
                Missed Text
              </Title>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button type="primary" key="back" style={{ margin: 10 }} onClick={() => setShowModal(false)}>
                Done
              </Button>
              <Title level={3} style={{ margin: 10 }}>
                WPM:
              </Title>
              <Title level={3} style={{ margin: 10 }}>
                Accuracy: %
              </Title>
            </div>
          </div>
        }
        onCancel={() => setShowModal(false)}
      >
        <div style={{ display: 'flex', height: '100%', fontWeight: 600, color: '#383838' }}>
          <div style={{ flex: 1, background: 'beige', marginRight: 10 }}>
            <Title level={3} style={{ textAlign: 'center' }}>
              Content
            </Title>
            <Editor
              editorState={text}
              editorStyle={{
                width: '100%',
                height: 'calc(100% - 20px)',
                backgroundColor: 'beige',
                padding: 10,
                fontSize: '18px',
              }}
              toolbarClassName="hide-print"
              wrapperClassName="essay-wrapper"
              editorClassName="essay-editor"
              readOnly={true}
              toolbarHidden
            />
          </div>
          <div style={{ flex: 1, backgroundColor: '#93babb', fontWeight: 600, color: '#383838' }}>
            <Title level={3} style={{ textAlign: 'center' }}>
              Your Response
            </Title>
            <Editor
              editorState={textRest}
              rootStyle={{ backgroundColor: '#93babb' }}
              editorStyle={{
                width: '100%',
                height: 'calc(100% - 20px)',
                backgroundColor: '#93babb',
                padding: 10,
                fontSize: '18px',
              }}
              style={{ backgroundColor: '#93babb' }}
              toolbarClassName="hide-print"
              wrapperClassName="essay-wrapper"
              editorClassName="essay-editor"
              readOnly={true}
              toolbarHidden
            />
          </div>
        </div>
      </Modal>
      <Wrapper>
        <Header>
          <div
            onClick={() => {
              history.push('/level-up');
            }}
            style={{ cursor: 'pointer' }}
          >
            {' '}
            <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} />
          </div>
          <HeadBox background="transparent" color={'#fff'} style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px' }}>
            <FullScreenButton
              element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
              activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
            />
            ID: {userInfoVar()?.id}
            <CloseButton onClick={{}}>
              <CloseOutlined />
            </CloseButton>
          </HeadBox>
        </Header>
        <MainWrapper>
          <Main>
            <StageTitle>
              <StageTitleSpan>BOOK Title</StageTitleSpan>
              <StageTitleSpan>Unit</StageTitleSpan>
              <StageTitleSpan>Lesson</StageTitleSpan>
              <StageTitleSpan color={'#7030a0'}>Topic, title</StageTitleSpan>
            </StageTitle>
            <Title>Fluency / Speed Reading WPM</Title>
            <RowFlex style={{ height: '95%' }}>
              <LeftBody>
                <TextBox></TextBox>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Paginate>
                    <PageButton
                      color={'#ffffff'}
                      back={'#ed7d31'}
                      onClick={() => {
                        // if (page > 0) {
                        //   setPage(page - 1);
                        // }
                      }}
                    >
                      <ArrowLeftOutlined />
                    </PageButton>

                    {recordData?.length
                      ? recordData.map((chat, index) => {
                          return (
                            <PageButton
                              key={index}
                              // color={page === index ? '#ffffff' : '#000000'}
                              // back={page === index ? '#ed7d31' : '#ffffff'}
                              // onClick={() => setPage(index)}
                            >
                              {index + 1}
                            </PageButton>
                          );
                        })
                      : ''}

                    <PageButton
                      color={'#ffffff'}
                      back={'#ed7d31'}
                      onClick={() => {
                        // if (page < recordData.length - 1) {
                        //   setPage(page + 1);
                        // }
                      }}
                    >
                      <ArrowRightOutlined />
                    </PageButton>
                  </Paginate>
                </div>
              </LeftBody>
              <RightBody>
                <InfoBox>
                  <InfoHead>WPM / Accuracy</InfoHead>
                  <InfoContent color={'#c55a11'}>
                    <span>Word Count :</span>
                    <span>80</span>
                  </InfoContent>
                  <InfoContent color={'#595959'}>
                    <span>WPM :</span>
                    <span>112</span>
                  </InfoContent>
                  <InfoContent color={'#548235'}>
                    <span>Accuracy :</span>
                    <span>98</span>
                  </InfoContent>
                </InfoBox>
                <RecordBox>
                  <SoundBarWrapper>
                    <SoundBar type="range" min={0} max={100} step={1} value={currentProgressSlider} />
                  </SoundBarWrapper>
                  <ButtonWrapper>
                    <RecordButton url={'/images/external/stop-icon.png'} width={'64px'} height={'68px'} />
                    <RecordButton
                      url={playing ? '/images/external/stop-play.png' : '/images/external/play-icon.png'}
                      width={'64px'}
                      height={'68px'}
                    />
                    <RecordButton url={'/images/external/upload-icon.png'} width={'64px'} height={'68px'} />
                  </ButtonWrapper>
                </RecordBox>
                <RowFlex style={{ justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
                  <Button back={'#5399d9'} color={'white'} onClick={{}}>
                    Results
                  </Button>
                </RowFlex>
              </RightBody>
            </RowFlex>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
