import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { BOOK_STUDY_QUIZ_DATA } from 'src/operations/queries/book';
import { useQuery } from '@apollo/client';
const { Option } = Select;


const getStageGroupName = (stageGroupCode,code) => {
  switch (stageGroupCode) {
    case 'voca':
      return 'word';
    case 'sentence':
      return 'sentence';
    case 'quiz':
        return code.split('~')[0];
    default:
      return stageGroupCode;
  }
};

const SelectLesson = ({ lessons, lessonCode, onChangeLesson }) => {
  const [lessonArr, setLessonArr] = useState(lessons);
  useEffect(()=>{
  let lessondata = [];
  for(let i=0;i<lessons.length;i++){
    console.log("less",i);
    lessondata.push({
      "__typename": lessons[i].__typename,
      "code": lessons[i].code +'~quiz',
      "unit_no": lessons[i].unit_no,
      "day_no": lessons[i].day_no,
      "title": "Quiz",
      "stage_group_code":"quiz"
    })
  }
  setLessonArr([...lessonArr,...lessondata])
},[lessons])

  return (
    <Select size="default" value={lessonCode} style={{ display: 'block', width: '50%' }} onChange={onChangeLesson}>
      {lessonArr &&
        lessonArr.map((lesson) => (
          <Option key={`lesson-option-${lesson.code}`} value={lesson.code}>
          {/* <Option key={`lesson-option-${lesson.code}`} value={lesson.code}> */}
            {`${lesson.title || lesson.code}(${getStageGroupName(lesson.stage_group_code,lesson.code)})`}
          </Option>
        ))}
    </Select>
  );
};

export default SelectLesson;
