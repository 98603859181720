import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import store from 'src/operations/store';
import queryString from 'query-string';

import App from './App';
import getClient from './apollo/client';
import { saveToken, saveExternalToken } from './utils';

import * as serviceWorker from './serviceWorker';

const params = queryString.parse(window.location.search);

if (params.token) {
  const token = params.token[0] === '"' ? JSON.stringify(JSON.parse(JSON.parse(params.token))) : params.token;
  saveToken(token);

  if (params.external_token) {
    saveExternalToken(params.external_token);
  } else {
    saveExternalToken(null);
  }
  setTimeout(() => {
    window.location.href =
      window.location.pathname +
      `?${Object.keys(params)
        .filter((e) => e !== 'token' && e !== 'external_token')
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`;
  }, 500);
} else {
  const client = getClient();

  ReactDOM.render(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ApolloProvider>,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
