import React from 'react';
import { Button } from 'antd';

function CustomButton({ children, disabled, ...rest }) {
  return (
    <>
      <Button {...rest} disabled={!!disabled}>
        {children}
      </Button>
    </>
  );
}

export default CustomButton;
