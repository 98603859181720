import { gql } from '@apollo/client';

export const EVINE_SENTENCE_LIST = gql`
  query get($lessonCode: String!){
    getSentenceList(lessonCode: $lessonCode) {
      no
      eng
      kor
      audio
    }
  }
`;

export const EVINE_WORD_LIST = gql`
  query get($lessonCode: String!){
    getVocaList(lessonCode: $lessonCode) {
      no
      eng
      kor
      audio
    }
  }
`;

export const EVINE_BOOK_LESSON_INFO = gql`
  query getBookLessonInfo($lessonCode: String!){
    bookLessonInfo(lessonCode: $lessonCode) {
      code
      stage_group_code
      title
      stage {
        no
        is_complete
        study_tool {
          idx
          name
        }
      }
    }
  }
`;

export const EVINE_STUDY_DATA = gql`
  query getStudyData($study_tool_idx: Int!, $lesson_code: String!){
    getStudyData(study_tool_idx: $study_tool_idx, lesson_code: $lesson_code) {
      idx
      title
      study_lang_data {
        no
        eng
        kor
        eng_slice
        eng_image
        eng_video
        eng_audio
        kor_audio
        eng_sentence
        slice_list
      }
      study_quiz_data{
        no
        question_type
        pre_question
        pre_question_text
        question
        question_text
        question_image
        question_audio
        question_video
        extra_data
        answer
        option
        tag
        option_list
      }
    }
  }
`;

export const QUIZ_STUDY_DATA = gql`
  query getQuizData($daily_test_group_assigned_idx: Int!, $user_idx: Int!){
    getQuizData(daily_test_group_assigned_idx: $daily_test_group_assigned_idx, user_idx: $user_idx)
  }
`;

