import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Row, Typography, Modal, Button, DatePicker, Radio, Checkbox, Form, Input, Tooltip, Space, Select } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ClassRoomModal from 'src/components/Modal/ClassRoomModal';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';

import UseHistoryState from 'src/components/common/UseHistoryState';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek, getMonthDateRange } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;

const options = [
  { label: '진행중', value: true },
  { label: '종료', value: false },
];

function AssignLesson() {
  const userType = userInfoVar()?.type;
  const [tab, setTab] = useState('main');
  const [classList, setClassList] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [totalCurrentStudyingStudent, setTotalCurrentStudyingStudent] = useState(0);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const [searchValue, setSeachValue] = useState('');

  const [AssignedListData, setAssignedListData] = useState([]);
  const [AssignedListDataAdd, setAssignedListDataAdd] = useState([]);

  const { visible, showModal, handleCancel, popupMode } = useModal();

  const { data: OfflineSyllabusLessonAssigned,refetch, loading } = useQuery(queries.offlinesyllabus.OFFLINE_TEXTBOOK_LESSON_ASSIGNED, {
      fetchPolicy: 'no-cache'
  });

  const [ClassFilter,setClassFilter] = useState([]);
  const [LevelFilter,setLevelFilter] = useState([]);
  
  const ClassFilterResult = ClassFilter.map(Level => Level);
  const LevelFilterResult = LevelFilter.map(ClassFilter => ClassFilter);
  

  const [deleteUser] = useMutation(mutations.offlinesyllabus.OFFLINE_SYLLABUS_ASSIGNED_LIST_BULK_DELETE, {
    onCompleted(data) {
      if (data) {
        refetch();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {    
    if(OfflineSyllabusLessonAssigned?.offlineTextLessonAssigned){
      let getClassName = [];
      let getLevelName = [];
      let AssignedResultData = OfflineSyllabusLessonAssigned?.offlineTextLessonAssigned.map((loopitem,loopkey) => {
          getClassName.push(loopitem.name);
          getLevelName.push(loopitem.level);
          let obj = {
            "No":loopkey+1,
            "key":loopitem.rowidx,
            "Last_Updated_Date":(loopitem.updatedby).split("T")[0],
            "Row_Title":loopitem.assign_title,
            "Class":loopitem.name,
            "Class_idx":loopitem.assign_class_idx,
            "Course_programname":loopitem.programname,
            "Course_bookcode":loopitem.bookcode,
            "Course_Subject_Title":loopitem.programname+' / '+loopitem.booktitle,
            "Course_level":loopitem.level,
            "No_Of_Students":loopitem.noofstudent,
            "No_Of_Lessons":loopitem.lessonno,
            "Assign":""
          }
          return obj;
      })
      setAssignedListData(AssignedResultData)
      setAssignedListDataAdd(AssignedResultData)
      setClassFilter([...new Set(getClassName)])
      setLevelFilter([...new Set(getLevelName)])
    }
  },[OfflineSyllabusLessonAssigned])

  const handlelevenChange = (e) => {
    if(e !== ''){
      let AssignedLevelData = AssignedListDataAdd.map((loopitem,loopkey) => {
          if(e == loopitem.Course_level){
              let obj = {
              "No":loopitem.No,
              "key":loopitem.key,
              "Last_Updated_Date":loopitem.Last_Updated_Date,
              "Row_Title":loopitem.Row_Title,
              "Class":loopitem.Class,
              "Class_idx":loopitem.Class_idx,
              "Course_programname":loopitem.Course_programname,
              "Course_bookcode":loopitem.Course_bookcode,
              "Course_Subject_Title":loopitem.Course_Subject_Title,
              "Course_level":loopitem.Course_level,
              "No_Of_Students":loopitem.No_Of_Students,
              "No_Of_Lessons":loopitem.No_Of_Lessons,
              "Assign":""
            }
            return obj;
          }else if(e == "All"){
            let obj = {
              "No":loopitem.No,
              "key":loopitem.key,
              "Last_Updated_Date":loopitem.Last_Updated_Date,
              "Row_Title":loopitem.Row_Title,
              "Class":loopitem.Class,
              "Class_idx":loopitem.Class_idx,
              "Course_programname":loopitem.Course_programname,
              "Course_bookcode":loopitem.Course_bookcode,
              "Course_Subject_Title":loopitem.Course_Subject_Title,
              "Course_level":loopitem.Course_level,
              "No_Of_Students":loopitem.No_Of_Students,
              "No_Of_Lessons":loopitem.No_Of_Lessons,
              "Assign":""
            }
            return obj;
          }
      })
      setAssignedListData(AssignedLevelData.filter(out => out))
    }else{
      setAssignedListData(AssignedListDataAdd)
    }
  }

  const handClassNameChange = (e) => {
    if(e !== ''){
      let AssignedClassNameData = AssignedListDataAdd.map((loopitem,loopkey) => {
          if(e == loopitem.Class){
              let obj = {
              "No":loopitem.No,
              "key":loopitem.key,
              "Last_Updated_Date":loopitem.Last_Updated_Date,
              "Row_Title":loopitem.Row_Title,
              "Class":loopitem.Class,
              "Class_idx":loopitem.Class_idx,
              "Course_programname":loopitem.Course_programname,
              "Course_bookcode":loopitem.Course_bookcode,
              "Course_Subject_Title":loopitem.Course_Subject_Title,
              "Course_level":loopitem.Course_level,
              "No_Of_Students":loopitem.No_Of_Students,
              "No_Of_Lessons":loopitem.No_Of_Lessons,
              "Assign":""
            }
            return obj;
          }
      })
      setAssignedListData(AssignedClassNameData.filter(out => out))
    }else{
      setAssignedListData(AssignedListDataAdd)
    }
  }

  const handContentSearch = (e) => {
    if(e !== ''){
      let AssignedClassNameData = AssignedListDataAdd.map((loopitem,loopkey) => {  
        let findClass = (loopitem.Class).toLowerCase();
        let classPattern = new RegExp('(\\w*'+`${e.toLowerCase()}`+'\\w*)','gi')

        let findLastupdate = (loopitem.Last_Updated_Date).toLowerCase();
        let lastUpdatePattern = new RegExp('(\\w*'+`${e.toLowerCase()}`+'\\w*)','gi')

        let findrowTitle = (loopitem.Row_Title).toLowerCase();
        let rowTitlePattern = new RegExp('(\\w*'+`${e.toLowerCase()}`+'\\w*)','gi')

        let findNoOfStudent = (loopitem.Row_Title).toLowerCase();
        let NoOfStudentPattern = new RegExp('(\\w*'+`${e.toLowerCase()}`+'\\w*)','gi')
        
        let findNoOfLessons = (loopitem.No_Of_Lessons).toLowerCase();
        let NoOfLessonPattern = new RegExp('(\\w*'+`${e.toLowerCase()}`+'\\w*)','gi')
        
        if(findClass.match(classPattern) !== null || findLastupdate.match(lastUpdatePattern) !== null || findNoOfLessons.match(NoOfLessonPattern) !== null || findrowTitle.match(rowTitlePattern) !== null || findNoOfStudent.match(NoOfStudentPattern) !== null){
          let obj = {
              "No":loopitem.No,
              "key":loopitem.key,
              "Last_Updated_Date":loopitem.Last_Updated_Date,
              "Row_Title":loopitem.Row_Title,
              "Class":loopitem.Class,
              "Class_idx":loopitem.Class_idx,
              "Course_programname":loopitem.Course_programname,
              "Course_bookcode":loopitem.Course_bookcode,
              "Course_Subject_Title":loopitem.Course_Subject_Title,
              "Course_level":loopitem.Course_level,
              "No_Of_Students":loopitem.No_Of_Students,
              "No_Of_Lessons":loopitem.No_Of_Lessons,
              "Assign":""
            }
            return obj;
        }
      })
      setAssignedListData(AssignedClassNameData.filter(out => out))
    }else{
      setAssignedListData(AssignedListDataAdd)
    }
  }

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const columns = [
  {
    title: 'Last Updated Date',
    dataIndex: 'Last_Updated_Date',
    key: 'Last_Updated_Date',
    align: 'center',
    render: (text, record) => (
          <span style={{color:"orange"}}>{record.Last_Updated_Date}</span>
    ),
  },
  {
    title: 'Class',
    dataIndex: 'Class',
    key: 'Class',
    align: 'center',
  },
  {
    title: 'Textbook',
    dataIndex: 'Course_Subject_Title',
    key: 'Course_Subject_Title',
    align: 'center',
  },
  {
    title: 'No of Students',
    dataIndex: 'No_Of_Students',
    key: 'No_Of_Students',
    align: 'center',
  },
  {
    title: 'No of Lessons',
    dataIndex: 'No_Of_Lessons',
    key: 'No_Of_Lessons',
    align: 'center',
  },
  {
    title: 'View Syllabus',
    dataIndex: 'Assign',
    key: 'Assign',
    align: 'center',
    render: (text, record) => (
      <Button style={{background:"#B8E4F0"}}>
        <Link to={{ pathname: `/offline/editview/syllabus/${record.Course_programname}/${record.Course_level}/`}}>View</Link>
      </Button>
    ),
  },
  {
    title: 'Assign',
    dataIndex: 'Assign',
    key: 'Assign',
    align: 'center',
    render: (text, record) => (
      <Button style={{background:"#B8E4F0"}}>
        <Link to={{ pathname: `/offline/assign/edit/${record.Course_level}/${record.Course_programname}/${record.Class_idx}/${record.Row_Title}/${record.key}`}}>Edit</Link>
      </Button>
    ),
  }
];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  return (
    <>
      <Row gutter={[16, 16]} style={{width : "100%",overflowY:"hidden !important",overflowX:"hidden !important" }}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY:"hidden !important" }}>
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                &nbsp;&nbsp;Assigned Lesson Plan
              </Title>
              <div className="header-classroom-search">
              <Form
                form={form}
                initialValues={{
                  layout: formLayout,
                }}
              >
                <Space>
                <Form.Item
                    name=""
                    label=""
                    rules={[
                      {
                        message: 'Please select gender!',
                      },
                    ]}
                  >
                    <Select placeholder="Class Name" onChange={handClassNameChange}>
                        {
                          ClassFilterResult.map((loopclassname,loopkey) => <option key={loopkey} value={loopclassname}>{loopclassname}</option>)
                        }
                    </Select>
                  </Form.Item>

                <Form.Item>
                  <Input.Search
                        className="inputsearch-classroom"
                        placeholder="Content Search"
                        allowClear
                        style={{ width: 250, marginRight: 10 }}
                        enterButton
                        onSearch={handContentSearch}
                        onChange={onSeachChange}
                        value={searchValue}
                      />
                      <CustomButton style={{ marginRight: 10 }} onClick={() => handContentSearch('')}>
                        <RedoOutlined />
                      </CustomButton>
                  </Form.Item>
                <Form.Item
                    name=""
                    label=""
                    rules={[
                      {
                        message: 'Please select gender!',
                      },
                    ]}
                  >
                    <Select placeholder="level" onChange={handlelevenChange} style={{width : "150px"}}>
                        <Option key="13" value="All">All Level</Option>
                        <Option key="0" value="Phonics">Phonics</Option>
                        <Option key="1" value="1">level 1</Option>
                        <Option key="2" value="2">level 2</Option>
                        <Option key="3" value="3">level 3</Option>
                        <Option key="4" value="4">level 4</Option>
                        <Option key="5" value="5">level 5</Option>
                        <Option key="6" value="6">level 6</Option>
                        <Option key="7" value="7">level 7</Option>
                        <Option key="8" value="8">level 8</Option>
                        <Option key="9" value="9">level 9</Option>
                        <Option key="10" value="10">level 10</Option>
                        <Option key="11" value="11">level 11</Option>
                        <Option key="12" value="12">level 12</Option>
                    </Select>

                    {/*<Select placeholder="level" style={{width:"150px"}} onChange={handlelevenChange}>
                        {
                          LevelFilterResult.map((looplevelname,loopkey) => <option key={loopkey} value={looplevelname} >{looplevelname}</option>)
                      }
                    </Select>*/}
                  </Form.Item>
                  <span></span><span></span>
                  </Space>
                  </Form>
              </div>
            </div>
            <CustomTable
              className="classroom-table-table"
              dataSource={AssignedListData}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: 12 }}
              size="small"
              color="#edf3fb"
              style={{ float: 'center' }}
              scroll={{ y: 'calc(100vh - 254px)' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {},
                };
              }}
            />
            <CustomButton
              danger
              style={{
                position: "relative",
                top: "-8%",
                left: "60px"
              }}
              onClick={deleteConfirm}
            >
            Delete
            </CustomButton>
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
}

export default AssignLesson;
