import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button, Form, Input } from 'antd';
import { useQuery, useMutation } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from '../common/Notification';

import { UPDATE_CAMPUS_BOOK_DISPLAY_PRICE, DELETE_CAMPUS_BOOK_DISPLAY_PRICE } from 'src/operations/mutations/campus';
import { GET_CAMPUS_BOOK_INFO_LIST } from 'src/operations/queries/campus';
import { GET_COMPANY_BOOK_DISPLAY_LIST } from 'src/operations/queries/company';

function CampusPriceSettingModal({ visible, handleCancel, company_idx, campus_idx, campus_name }) {
  const [form] = Form.useForm();
  const [books, setBooks] = useState([]);
  const [bookdata, setBookData] = useState(false);

  const { data: fetchCompanyBooks } = useQuery(GET_COMPANY_BOOK_DISPLAY_LIST, {
    variables: {
      company_idx: company_idx,
    },
  });

  const { data: fetchCampusBooks, refetch } = useQuery(GET_CAMPUS_BOOK_INFO_LIST, {
    skip: !campus_idx,
    variables: {
      campus_idx: campus_idx,
    },
  });

  useEffect(() => {
    const companyBookSeries = fetchCompanyBooks?.getCompanyBookDisplayList.filter((item) => item.book.parent_code == null);
    const companyBookSeriesMap = new Map();
    companyBookSeries?.forEach((element) => {
      companyBookSeriesMap.set(element.book.code, element);
    });

    const campusBookSeries = fetchCampusBooks?.getCampusBookInfoList.filter((item) => {
      if (item.book.parent_code == null) {
        const bookSeries = companyBookSeriesMap.get(item.book.code);
        if (bookSeries?.shopping_mall_product === false) {
          return true;
        }
      }
      return false;
    });
    const campusBooks = campusBookSeries?.reduce((acc, cur) => {
      const bookSeries = companyBookSeriesMap.get(cur.book.code);
      const children = fetchCampusBooks?.getCampusBookInfoList
        .filter((item) => {
          return cur.book.code === item.book.parent_code;
        })
        .map((ele) => {
          return {
            key: ele.idx,
            volume: ele.book.code,
            price: ele.price ? ele.price : 0,
            prices: ele.prices,
            parent: ele.book.parent_code,
            is_bundle: bookSeries?.is_bundle,
            is_use_credit: bookSeries?.is_use_credit,
          };
        });
      const obj = {
        key: cur.idx,
        volume: cur.book.code,
        price: cur.price ? cur.price : 0,
        prices: cur.prices,
        parent: null,
        is_bundle: bookSeries?.is_bundle,
        is_use_credit: bookSeries?.is_use_credit,
        children: children,
      };

      return [...acc, obj];
    }, []);

    setBooks(campusBooks);
  }, [fetchCompanyBooks, fetchCampusBooks]);

  const [updateGoodsPrice] = useMutation(UPDATE_CAMPUS_BOOK_DISPLAY_PRICE, {
    onCompleted(data) {
      if (data) {
        openNotification('수정 완료!');
        handleCancel(true);
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    refetch();
  }, [bookdata]);

  const [deleteBook] = useMutation(DELETE_CAMPUS_BOOK_DISPLAY_PRICE, {
    onCompleted(data) {
      if (data) {
        setBookData((prevVal) => !prevVal);
        openNotification('수정 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function onChange(record) {
    const price = parseInt(record.value);
    const selectRecordKey = Number(record.key);
    setBooks((prev) => {
      return prev.map((val) => {
        if (val.key === selectRecordKey) {
          if ((val.prices && val.prices.length) || val.price!==undefined) {
            if (record.index === -1) {
              return { ...val, ...{ price: price } };
            } else {
              let prices = val.prices.map((item, itemIndex) => {
                if (record.type === 'price') {
                  return itemIndex === record.index ? { ...item, ...{ price: price } } : item;
                } else if (record.type === 'days') {
                  return itemIndex === record.index ? { ...item, ...{ days: price } } : item;
                } else {
                  return item;
                }
              });
              return { ...val, ...{ prices: prices } };
            }
          } else {
            return val;
          }
        } else if (val.key !== selectRecordKey && val.hasOwnProperty('children')) {
          return {
            ...val,
            children: val.children.map((child) => {
              if (child.key === selectRecordKey) {
                if ((child.prices && child.prices.length) || child.price!==undefined) {
                  if (record.index === -1) {
                    return { ...child, ...{ price: price } };
                  } else {
                    let prices = child.prices.map((item, itemIndex) => {
                      if (record.type === 'price') {
                        return itemIndex === record.index ? { ...item, ...{ price: price } } : item;
                      } else if (record.type === 'days') {
                        return itemIndex === record.index ? { ...item, ...{ days: price } } : item;
                      } else {
                        return item;
                      }
                    });
                    return { ...child, ...{ prices: prices } };
                  }
                } else {
                  return child;
                }
              } else {
                return child;
              }
            }),
          };
        } else {
          return val;
        }
      });
    });
  }

  function handleFormSubmit() {
    updateGoodsPrice({
      variables: {
        goods: books,
      },
      refetchQueries: [
        {
          query: GET_CAMPUS_BOOK_INFO_LIST,
          skip: !campus_idx,
          variables: { campus_idx: campus_idx },
        },
      ],
    });
  }

  const columns = [
    {
      title: '교재명',
      dataIndex: 'volume',
      key: 'volume',
      align: 'center',
    },
    {
      title: '가격',
      dataIndex: 'prices',
      key: 'prices',

      render: (text, record) => {
          return record.is_use_credit ? (
            <>
              {record.prices.map((items, index) => {
                return (
                  <>
                    <label>일</label>
                    <Input
                      type="number"
                      defaultValue={items.days}
                      onChange={(e) => {
                        onChange({
                          value: e.target.value,
                          key: items.campus_bd_idx,
                          index: index,
                          type: 'days',
                        });
                      }}
                      style={{ width: '30%' }}
                      // addonAfter="원"
                    />
                    <label>가격</label>
                    <Input
                      type="number"
                      defaultValue={items.price}
                      onChange={(e) => {
                        onChange({
                          value: e.target.value,
                          key: items.campus_bd_idx,
                          index: index,
                          type: 'price',
                        });
                      }}
                      style={{ width: '30%' }}
                      addonAfter="원"
                    />
                    {record.prices.length - 1 > 0 && (
                      <>
                        <Button className="" onClick={() => removeItem(record, items, index, items?.children ? true : false)}>
                          -
                        </Button>
                      </>
                    )}
                    {record.prices.length - 1 === index && (
                      <>
                        <Button className="" onClick={() => addPrice(record)}>
                          +
                        </Button>
                      </>
                    )}
                    <br />
                  </>
                );
              })}
            </>
          ) : (
            <>
              <label>가격</label>
              <Input
                defaultValue={record.price}
                type="number"
                onChange={(e) => {
                  onChange({
                    value: e.target.value,
                    key: record.key,
                    index: -1,
                  });
                }}
                style={{ width: '30%' }}
                addonAfter="원"
              />
            </>
          );
      },
    },
  ];
  function addPrice(val) {
    let obj = [
      {
        book_code: val.book_code,
        campus_bd_idx: val.key,
        days: 0,
        idx: null,
        price: 0,
      },
    ];
    if (val.parent) {
      let index = books.findIndex((ele) => ele.volume === val.parent);
      let booksCopy = [...books];
      let childIndex = booksCopy[index].children.findIndex((childItem) => childItem.key === val.key);
      booksCopy[index].children[childIndex].prices = [...booksCopy[index].children[childIndex].prices, ...obj];
      setBooks(booksCopy);
    } else {
      let index = books.findIndex((ele) => ele.key === val.key);
      if (index >= 0) {
        let nw = [...books];

        nw[index].prices = [...nw[index].prices, ...obj];

        setBooks(nw);
      }
    }
  }
  function removeItem(record, val, index1, isChild) {
    if (!val.idx) {
      if (record.parent) {
        let index = books.findIndex((ele) => ele.volume === record.parent);
        let booksCopy = JSON.parse(JSON.stringify(books));
        let childIndex = booksCopy[index].children.findIndex((childItem) => childItem.key === record.key);
        booksCopy[index].children[childIndex].prices.splice(index1, 1);
        setBooks(booksCopy);
      } else {
        let index = books.findIndex((ele) => ele.key === record.key);
        if (index >= 0) {
          let newbook = JSON.parse(JSON.stringify(books));
          newbook[index].prices.splice(index1, 1);
          setBooks(newbook);
        }
      }
    } else {
      deleteBook({
        variables: {
          idx: val?.idx,
        },
      });
    }
  }
  return (
    <>
      <Modal
        entered
        title={`${campus_name} 상품가격 관리`}
        visible={visible}
        onCancel={handleCancel}
        okText="확인"
        cancelText="취소"
        width={'90%'}
        style={{ top: 20 }}
        footer={[
          <Button key="button" type="primary" onClick={handleFormSubmit}>
            수정
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Row gutter={[16, 16]} className="price-management-tbl">
          <Col span={24}>
            <Form form={form} component={false}>
              <CustomTable
                columns={columns}
                dataSource={books}
                color="#edf3fb"
                textAlign="left"
                pagination={false}
                scroll={{ y: 'calc(100vh - 254px)' }}
                bordered
              />
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

CampusPriceSettingModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  myBookSeries: PropTypes.array,
  campusInfo: PropTypes.objectOf.isRequired,
};

export default CampusPriceSettingModal;
