import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Switch, Space, Input, Button, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import { isPturn } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import TalkingTutorMenuGroup from 'src/components/common/TalkingTutorMenuGroup';
import { AI_TOPIC_FINISH_COUNT } from 'src/operations/queries/aiTopic';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import { ExportExcel } from 'src/utils/index';
import moment from 'moment';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  /* background-color: #ffda00; */
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 100%;
  /* max-width: 1250px; */
  /* min-width: 1000px; */
  /* max-height: 680px; */
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 100%;
    min-width: 900px;
  }

  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
export default function ExtReadersPage() {
  const history = useHistory();
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);


  const [localState, setLocalState] = UseHistoryState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    selectedClass: -1,
    pg: { current: 1, pageSize: 10 },
    searchValue: '',
  });

  const { data, loading: writingLoading } = useQuery(AI_TOPIC_FINISH_COUNT, {
    variables: {
      start_date: localState.startDate,
      end_date: localState.endDate,
      class_idx: localState.selectedClass?.idx ? localState.selectedClass?.idx : null,
      type: 'ai-talking-tutor',
    },
  });
  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: dataUser?.getUserData?.campus_idx,
    fetchPolicy: 'no-cache',
  });
  const studyData = useMemo(() => {
    if (data?.aiTopicFinishCount) {
      return data?.aiTopicFinishCount.map((item, key) => {
        const { class_name, cnt, finish_time, name,user_idx } = item;
        const time =
          (parseInt(finish_time / 3600) > 0 ? parseInt(finish_time / 3600) + '시간 ' : '') +
          (parseInt((finish_time % 3600) / 60) > 0 ? parseInt((finish_time % 3600) / 60) + '분 ' : '') +
          (parseInt(finish_time % 60) + '초');
        return {
          no: key + 1,
          key: `writing-coach-list-${key}`,
          class_name,
          time,
          name,
          cnt,
          user_idx
        };
      });
    }
    return [];
  }, [data]);
  const filteredData = useMemo(() => {
    if (studyData) {
      if (localState.searchValue) {
        return studyData.filter((e) => e.name.toLowerCase().includes(localState.searchValue.toLowerCase()));
      } else return studyData;
    }
  }, [localState.searchValue, studyData]);

  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: moment(dateString).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(dateString).endOf('month').format('YYYY-MM-DD'),
      };
    });
  };
  const onSeachChange = (val) => {
    if (val.target.value) {
      setLocalState((prev) => {
        return {
          ...prev,
          searchValue: val.target.value,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          searchValue: '',
        };
      });
    }
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Student',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      align: 'center',
      render: (text) => {
        return <span style={{ color: 'blue' }}>{text}</span>;
      },
    },
    {
      title: 'Class Name',
      dataIndex: 'class_name',
      key: 'class_name',
      align: 'center',
    },
    {
      title: 'Count',
      dataIndex: 'cnt',
      key: 'cnt',
      align: 'center',
      sorter: (a, b) => parseInt(a.cnt) > parseInt(b.cnt),
    },
    {
      title: 'Study Time',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: 'Learning Results',
      dataIndex: 'user_idx',
      key: 'user_idx',
      align: 'center',
      render: (text)=>{
        return <button style={{backgroundColor:'#6FB242', color:'#fff',border:'none',padding:'5px 10px', cursor:'pointer',borderRadius:5}} onClick={()=>{history.push(`/ai-talking-tutor/result/${text}?date=${moment(localState?.startDate).format('YYYY-MM')}`)}} >View</button>
      }
    },
  ];
  const handleChange = (e) => {
    setLocalState((prev) => {
      return { ...prev, selectedClass: classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0] };
    });
  };
  function handleExportToExcel() {
    const fileName = `Individual Results`;
    ExportExcel(columns, filteredData, fileName);
  }
  return (
    <>
      <Wrapper>
        <TalkingTutorMenuGroup currentMenu={'extreaders'} />
        <MainWrapper>
          <Main className="cust-extra-reader">
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle> AI Speaking Coach : <span style={{ color: '#00B0F0' }}>Individual Results</span></SubTitle>
                {/* <CloseButton
                  onClick={() => {
                    history.push('/level-up');
                  }}
                >
                  <CloseOutlined />
                </CloseButton> */}
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {localState.selectedClass?.idx >= 0 ? (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                ) : (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                )}
                &nbsp; &nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search Student"
                  allowClear
                  style={{ width: 250 }}
                  onChange={onSeachChange}
                  enterButton
                />
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  picker="month"
                  value={moment(localState.startDate)}
                  allowClear={false}
                />
                &nbsp;
                <Icon onClick={handleExportToExcel} url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              loading={writingLoading}
              dataSource={filteredData}
              pagination={{ ...localState.pg, showSizeChanger: true, position: ['bottomCenter'] }}
              onChange={(pagination) => {
                setLocalState((prev) => {
                  return { ...prev, pg: pagination };
                });
              }}
              columns={columns}
              size="small"
              color="#edf3fb"
              // borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
