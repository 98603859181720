import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Radio, Input, Button, DatePicker, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { useQuery } from '@apollo/client';
import { SINGLE_ASSIGNED_EXTERNAL_STUDY } from 'src/operations/queries/externalStudy';
import moment from 'moment';
import BackButton from 'src/components/common/BackButton';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  /* background-color: #ffda00; */
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`

  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const LiteracyBtn = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
`;
const LiteracyBtn2 = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;
const WPMBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;

const IBTBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
};
export default function StarWordsResultPage() {
  const { idx } = useParams();
  const { data: externalStudy, loading: grouploadingdetail } = useQuery(SINGLE_ASSIGNED_EXTERNAL_STUDY, {
    fetchPolicy: 'no-cache',
    variables: {
      idx: parseInt(idx),
    },
  });
  const filteredData = useMemo(() => {
    if (externalStudy) {
      const bookDetail = externalStudy?.singleAssignedExternalStudy?.external_study_data
        ? JSON.parse(externalStudy?.singleAssignedExternalStudy?.external_study_data)
        : [];
      return externalStudy?.singleAssignedExternalStudy?.external_user_study?.map((ele, i) => {
        const bookreportData = JSON.parse(ele?.data) || {
          status: 0,
          recording_data: '{}',
        };
        let quizStage = ele?.external_study_stage?.filter((item) => item.stage_no === 3) || [];
        quizStage = quizStage[0] || {};

        const firstStudyTime = ele?.external_study_stage?.reduce((acc, cur) => {
          if (acc === null) {
            return cur?.study_date;
          }
          if (moment(cur?.study_date).isBefore(moment(acc))) {
            return cur?.study_date;
          }
          return acc;
        }, null);
        const timeDiff = moment.utc(ele?.last_study_timestamp).diff(moment.utc(firstStudyTime || ele?.study_date));
        let finalTime;
        if (timeDiff) {
          const totalTimeInSeconds = timeDiff / 1000;
          finalTime = formatTime(totalTimeInSeconds);
        } else {
          finalTime = '00:00:00';
        }
        const [hours, minutes, seconds] = finalTime.split(':').map(Number);
        let res = hours > 0 || minutes > 0 || seconds > 0 ? true : false;
        let activitiesStage = ele?.external_study_stage?.filter((item) => [1, 2, 4, 5].includes(item.stage_no)) || [];
        let is_voca = ele?.assigned_external_study?.external_study_data
          ? JSON.parse(ele?.assigned_external_study?.external_study_data).is_voca
          : 0;
        console.log(ele);
        const obj = {
          no: `${i + 1}`,
          student_name: ele?.user?.name,
          assigned_date: moment.utc(ele?.study_date).format('YYYY. MM. DD'),
          last_study_date:
            ele?.status === 2 && ele?.last_study_timestamp ? moment.utc(ele?.last_study_timestamp).format('YYYY-MM-DD HH:mm:ss') : '-',
          book_title: bookDetail?.title,
          spent_time: res ? finalTime : '-',
          quiz: quizStage?.exam_total ? `${quizStage?.exam_correct}/${quizStage?.exam_total}` : '-',
          wpm: ele?.wpm,
          accuracy: ele?.exam_correct,
          user_idx: ele?.user?.idx,
          recording_data: ele?.recording_data,
          classIdx: externalStudy?.singleAssignedExternalStudy?.assigned_external_study_group?.class?.idx,
          external_study_idx: ele?.idx,
          activity: activitiesStage?.length > 3 || (!is_voca&&ele?.status===2) ? 'O' : '',
          book_report: bookDetail?.bookreport,
          bookReportData: bookreportData,
          quiz_data: ele?.external_study_quiz_data.map((quiz, i) => {
            return {
              ...quiz,
              options: quiz.options ? JSON.parse(quiz.options) : undefined,
              is_correct: ele?.external_study_stage.filter((e)=>e.stage_no===3)[0]?.external_study_stage_answer[i]?.is_correct,
              user_answer: ele?.external_study_stage.filter((e)=>e.stage_no===3)[0]?.external_study_stage_answer[i]?.user_answer,
            };
          }),
        };
        return obj;
      });
    }
    return [];
  }, [externalStudy]);
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'student_name',
      key: 'student_name',
      align: 'center',
      sorter: (a, b) => a.student_name && a.student_name.localeCompare(b.student_name),
      render: (text)=>{return <span style={{color:'blue'}}>{text}</span>}
    },
    {
      title: 'Completion Time',
      dataIndex: 'last_study_date',
      key: 'last_study_date',
      align: 'center',
      render: (text, record) => {
        return <span style={{ color: '#70AD47' }}>{record?.last_study_date}</span>;
      },
    },
    {
      title: 'Learning Time',
      dataIndex: 'spent_time',
      key: 'spent_time',
      align: 'center',
      render: (text, record) => {
        return <span>{record?.spent_time}</span>;
      },
    },
    // {
    //   title: 'Activity',
    //   dataIndex: 'activity',
    //   key: 'activity',
    //   align: 'center',
    //   render: (text) => {
    //     return text ? <span style={{ color: 'green' }}>완료</span> : <span style={{ color: 'orange' }}>미완료</span>;
    //   },
    // },
  ];


  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Level Up Result Page</SubTitle>
                <BackButton />
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {filteredData && (
                  <div style={{ fontSize: '16px' }}>
                    <span style={{ color: '#0070C0' }}>Assigned Date :</span>
                    &nbsp;
                    {filteredData[0]?.assigned_date}
                    &nbsp; &nbsp;
                    <span style={{ color: '#0070C0' }}>Title :</span>
                    &nbsp;
                    {filteredData[0]?.book_title}
                    &nbsp; &nbsp;
                  </div>
                )}
                <span style={{ color: '#0070C0', fontSize: '16px' }}>Class :</span>
                &nbsp; &nbsp;
                <RowFlex
                  style={{
                    width: '180px',
                    height: '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#000000',
                    backgroundColor: '#F5F9FD',
                    fontSize: '16px',
                  }}
                >
                  {externalStudy?.singleAssignedExternalStudy?.assigned_external_study_group?.class.name}
                </RowFlex>
                &nbsp; &nbsp;
                <Icon url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              pagination={{ pageSize: 10, position: ['bottomCenter'] }}
              dataSource={filteredData}
              columns={columns}
              size="small"
              // color="#ffebc2"
              // borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
