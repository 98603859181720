import React, { useState } from 'react';
import { Button, Modal, List } from 'antd';
import styled from 'styled-components';

const ReceivedUserBtn = styled(Button)`
  background: #eff5fb;
  color: #369;
  border: 1px solid #bec8cd;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #eff5fb;
    background: #369;
    transition: all 0.15s ease-in-out;
  }
`;

const SmsReceivedUserModal = ({ smsList }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const modalSentMsg_show = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} title="받은사람" footer={null}>
        <List
          size="small"
          bordered
          dataSource={smsList}
          renderItem={(item) => <List.Item>{item?.receiver_user?.name || item?.receiver}</List.Item>}
        />
      </Modal>
      <ReceivedUserBtn onClick={modalSentMsg_show}> 받은 사람 </ReceivedUserBtn>
    </>
  );
};

export default SmsReceivedUserModal;
