import React, { useState, useEffect } from 'react';
// import {DefaultScripter} from "./Scripter";
import _ from 'lodash';
import { isVowel } from '../../utils/pm/util';
import Keyboard from './Keyboard';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useParams } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const correctSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/correct.mp3';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/wrong.mp3';

let bookNameUnitStage = undefined;
let bookNameUnitStageChecklist = undefined;
let bookNameUnitStageAdd = undefined;
const MagicWriteWrapper = ({ problem, steps, isAfter = false, onFinish, setSteps, setIsAfter, onScriptLoaded }) => {
  const number = 2;
  const stepIndex = steps < problem.length ? steps : problem.length - 1;
  const short = problem[stepIndex]['short'];
  const long = problem[stepIndex]['long'];

  const [loadCompletes, setLoadCompletes] = useState(Array(number).fill(false));
  const [correctWrongSound, setCorrectWrongSound] = useState(undefined);
  const [firstReset, setFirstReset] = useState(false);
  const [secondReset, setSecondReset] = useState(false);
  const [userAnswerComplete, setUserAnswerComplete] = useState(Array(number).fill(false));
  const { level, unit, stage } = useParams();

  const [getKey, setGetKey] = useState([]);
  const [answerClicked, setAnswerClicked] = useState(false);
  const [leftSide, setLeftSide] = useState(true);
  useEffect(() => {
    if (_.isNil(getKey)) {
      setGetKey([]);
    }
  }, [getKey]);
  
  const handleWriteEnd = () => {
    // if (answerClicked) {
    //   alert('잠시 뒤에 시도하세요');
    // } else {
      const userInputs = getKey && getKey instanceof Array ? getKey.join('') : '';
      if (leftSide) {
        setAnswerClicked(true);

        if (userInputs === problem[stepIndex]['short']) {
          setCorrectWrongSound(correctSound);
          const copy = _.cloneDeep(userAnswerComplete);
          copy[0] = true;
          setUserAnswerComplete(copy);
          setLeftSide((prev) => !prev);
        } else {
          setCorrectWrongSound(wrongSound);
          // setFirstReset(true);
        }
      } else {
        setAnswerClicked(true);
        if (userInputs === problem[stepIndex]['long']) {
          setCorrectWrongSound(correctSound);
          const copy = _.cloneDeep(userAnswerComplete);
          copy[1] = true;
          setUserAnswerComplete(copy);
          setLeftSide((prev) => !prev);
        } else {
          setCorrectWrongSound(wrongSound);
          // setSecondReset(true);
        }
      }
      setGetKey([]);
    // }
  };

  if (level == '3b' && unit == 9 && stage == 5) {
    bookNameUnitStage = true;
    bookNameUnitStageChecklist = { e: true };
    bookNameUnitStageAdd = true;
  }

  const handleSoundEnd = () => {
    if (correctWrongSound === wrongSound) {
      setFirstReset(false);
      setSecondReset(false);
    } else {
      if (userAnswerComplete.every((item) => item === true)) {
        setFirstReset(true);
        setSecondReset(true);
        setSteps(steps + 1);
      }
    }
    setTimeout(() => {
      setAnswerClicked(false);
    }, 1200);
    setCorrectWrongSound(undefined);
  };

  useEffect(() => {
    setFirstReset(false);
    setSecondReset(false);
    setIsAfter(false);
    setUserAnswerComplete(Array(number).fill(false));
    // eslint-disable-next-line
  }, [steps]);

  useEffect(() => {
    if (loadCompletes.every((item) => item === true)) {
      onScriptLoaded();
    }
    // eslint-disable-next-line
  }, [loadCompletes]);

  useEffect(() => {
    if (userAnswerComplete.every((item) => item === true)) {
      setIsAfter(true);
    }
    // eslint-disable-next-line
  }, [userAnswerComplete]);

  useEffect(() => {
    if (steps >= problem.length) {
      onFinish();
    }
    // eslint-disable-next-line
  }, [problem, steps]);

  return (
    <>
      <S.ColumnFlex
        style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)', justifyContent: 'space-between' }}
        id="lv3_unit9_stage5_content"
        className="main_content col2_arrow  boxstyle_none"
      >
        <S.RowFlex style={{ height: '50%' }}>
          <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '1 1', height: '100%' }} className="left_box">
            <BorderedArea active={leftSide} className="top_area">
              <Image
                src={`https://cdn.cloubot.com/PM/image/level3/image_${problem[stepIndex]['short']}.png`}
                alt={problem[stepIndex]['short']}
              />
              <WordBox isAfter={true} className="word_box active">
                {bookNameUnitStage && bookNameUnitStageAdd
                  ? _.map(short, (item, itemkey) => (
                      <span
                        style={{ color: bookNameUnitStageChecklist[item] == true ? '#f368e0' : '#2e86de' }}
                        className={bookNameUnitStageChecklist[item] == true ? 'font_pink' : 'font_blue'}
                      >
                        {item}
                      </span>
                    ))
                  : _.map(short, (item) => (
                      <span style={{ color: isVowel(item) ? '#f368e0' : '#2e86de' }} className={isVowel(item) ? 'font_pink' : 'font_blue'}>
                        {item}
                      </span>
                    ))}
              </WordBox>
            </BorderedArea>
            {/* <BorderedArea style={{ margin: 0 }} className="bottom_area pen_keyboard_wrap">
            </BorderedArea> */}
          </S.ColumnFlex>

          <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '0 1', margin: '0 16px', height: '100%' }} className="center_box">
            <MiddleArea className="top_area">
              <img style={{ height: '40px' }} src="/images/pm/i_arrow_right.svg" alt="오른쪽으로 향하는 화살표" />
              <div>
                +<span>e</span>
              </div>
            </MiddleArea>
            {/* <MiddleArea style={{ margin: 0 }} className="plus_alphabet">
            </MiddleArea> */}
          </S.ColumnFlex>

          <S.ColumnFlex style={{ alignSelf: 'stretch', flex: '1 1', height: '100%' }} className="right_box">
            <BorderedArea active={!leftSide} className="top_area">
              <Image
                src={
                  isAfter
                    ? `https://cdn.cloubot.com/PM/image/level3/image_${problem[stepIndex]['long']}.png`
                    : 'https://cdn.cloubot.com/PM/image/default_monster_gray.png'
                }
                alt={isAfter ? 'before' : problem[stepIndex]['long']}
              />
              <WordBox isAfter={isAfter} className={`word_box ${isAfter ? 'active' : ''}`}>
                {bookNameUnitStage && bookNameUnitStageAdd
                  ? _.map(long, (item, itemkey) => (
                      <span className={bookNameUnitStageChecklist[item] == true ? 'font_pink' : 'font_blue'}>{item}</span>
                    ))
                  : _.map(long, (item) => <span className={isVowel(item) ? 'font_pink' : 'font_blue'}>{item}</span>)}
              </WordBox>
            </BorderedArea>
            {/* <BorderedArea style={{ margin: 0 }} className="bottom_area pen_keyboard_wrap">
            </BorderedArea> */}
          </S.ColumnFlex>
        </S.RowFlex>
        <S.PenKeyboardWrapMain className="bottom_box">
          <S.PenKeyboardWrap style={{ padding: '10px 0' }} className="pen_keyboard_wrap">
            {/* <SwitchButtonBox toggleDevice={handleToggle} device={false} /> */}
            <Keyboard getKey={getKey} setGetKey={setGetKey} onWriteEnd={handleWriteEnd} disableSound={answerClicked} />
          </S.PenKeyboardWrap>
        </S.PenKeyboardWrapMain>
      </S.ColumnFlex>
      <SoundEffectPM url={correctWrongSound} onEnd={handleSoundEnd} />
    </>
  );
};

export default MagicWriteWrapper;
const BorderedArea = styled(S.BorderColumnBox)`
  padding: 1.2rem;
  justify-content: center;
  flex: 1 1;
  margin-bottom: 10px;
  height: 100%;
  border-color: ${(props) => !props.active && '#d9d9d9'};
  @media (max-height: 500px) {
    padding: 0.4rem;
    margin-bottom: 0px;
  }
`;
const WordBox = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  padding: 0;
  text-align: center;
  visibility: ${(props) => (props.isAfter ? 'visible' : 'hidden')};
  @media (max-height: 500px) {
    font-size: 25px;
  }
`;
const MiddleArea = styled(S.ColumnFlex)`
  justify-content: center;
  align-items: center;
  flex: 1 1;
  margin-bottom: 10px;
  height: 100%;
  font-size: 32px;
  font-weight: 600;
`;
const Image = styled.img`
  height: 19vh;
  object-fit: contain;
  @media (max-height: 500px) {
    height: 13vh;
  }
`;
