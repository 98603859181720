import React from 'react';
import TimeBlock from './TimeBlock';
import styled, { css } from 'styled-components';
import MessageBlock from './MessageBlock';

const MessageItemWrapper = styled.div`
  ${(props) =>
    props.isMe &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `}
`;
const ContentRowWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => (props.isMe ? 'end' : 'start')};
`;

const MessageItem = ({ isMe, name, message, date, readYN, unreadCount, urls, type = '', currentFileList = [] }) => {
  return (
    <>
      <MessageItemWrapper
        isMe={isMe}
        className={
          name !== 'AI봇'
            ? 'chat-detail user-chat-detail'
            : type === 'ai-talking-tutor' && name == 'AI봇'
            ? 'chat-detail ai-chat-detail'
            : ''
        }
      >
        {/* <p className={name !== 'AI봇' ? "user-detail" : ""}>{isMe ? '나' : name}</p> */}
        <p className={name !== 'AI봇' ? 'user-detail' : type === 'ai-talking-tutor' && name == 'AI봇' ? 'ai-detail' : ''}>
          {isMe ? '나' : name}
        </p>
        <ContentRowWrapper
          className={type === 'ai-talking-tutor' ? 'msg_content_wrapper' : ''}
          isAITalkingTutor={type === 'ai-talking-tutor'}
        >
          {isMe ? (
            <>
              <TimeBlock date={date} unreadCount={unreadCount} />
              <MessageBlock type={type} isMe={isMe} message={message} urls={urls} currentFileList={currentFileList} />
            </>
          ) : (
            <>
              <MessageBlock type={type} isMe={isMe} message={message} urls={urls} currentFileList={currentFileList} />
              <TimeBlock date={date} />
            </>
          )}
        </ContentRowWrapper>
      </MessageItemWrapper>
    </>
  );
};

export default MessageItem;
