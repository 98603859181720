import { gql } from '@apollo/client';

export const FOLDERS_LIST = gql`
  query foldersList($folder_idx: Int, $is_default: Boolean, $q: String) {
    foldersList(folder_idx: $folder_idx, is_default: $is_default, q: $q) {
      idx
      name
      is_public
      is_default
      folders {
        idx
        name
        idate
        user {
          idx
          name
        }
      }
      user {
        idx
        name
      }
      campus {
        idx
        name
      }
      company {
        idx
        name
      }
      idate
    }
  }
`;
