import { gql } from '@apollo/client';

export const GET_LESSON_INFO = gql`
  query bookLessonInfo($lessonCode: String!) {
    bookLessonInfo(lessonCode: $lessonCode) {
      stage {
        no
        name
        study_tool {
          url
        }
      }
    }
  }
`;

export const STUDENT_BOOK_STUDY_LESSON_DATA = gql`
  query getUserStudyLesson($user_idx: Int!, $lesson_code: String!) {
    getUserStudyLesson(user_idx: $user_idx, lesson_code: $lesson_code) {
      stage_no
      book_lesson {
        stage {
          no
          name
        }
      }
      user_study_answer {
        is_correct
        question_no
        user_answer
      }
    }
  }
`;
