import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool,isPturn, getDashboardTitle } from 'src/utils';
import DashBoardAfterSchool from './DashBoardAfterSchool';

import { HeaderTitle } from 'src/components/common/Styles';

const TodoBlock = styled.div`
  padding: 6px;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 2px 1px 5px 5px #dddddd;
  min-height: calc((100vh - 145px) / 2);
`;

const HeadManagerPage = () => {
  const companyName = useSelector(classStoreData);
  return (
    <>
      <Row justify="space-between" className="buttonlisting-dashboard">
        <HeaderTitle level={4}>{isPturn(companyName) ?( '주문 통계'):(getDashboardTitle(companyName))}</HeaderTitle>
      </Row>

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <TodoBlock className="dashboard-todoblock-listing">
            {isPturn(companyName) ? (
              <DashBoardAfterSchool companyName={companyName} />
            ) : (
              <HeaderTitle level={5}>통계화면 추가 예정</HeaderTitle>
            )}
          </TodoBlock>
        </Col>
      </Row>
    </>
  );
};

export default HeadManagerPage;
