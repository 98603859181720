import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Typography, Button, Modal, Select, Space } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PhonicsInfo from "src/PhonicsInfo";
import stageComplete from "src/constants/stageComplete";
import * as queries from 'src/operations/queries';

const { Option } = Select;
const { Title, Text } = Typography;
const HomeWorkTable = styled(CustomTable)`
  border-top: 3px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
  }
`;


const sortByName = (a, b) => {
  //이름순 정렬
  if (a.name === b.name) {
    return 0;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
};
const Resultgroup = () => {
  const { start_date,book_idx,class_idx } = useParams();
  /*const unitdata = PhonicsInfo.find(element => {
    return (element.level == level && element.unit == unit);
  })*/
  const { state } = useLocation();
  const {
    data: fetchClassStudent,
    loading: classStudentsLoading,
    refetch: classStudentsRefetch,
  } = useQuery(queries.getClass.CLASS_STUDENT_LIST, {
    fetchPolicy: 'no-cache',
    pollInterval: 3600000,
    variables: { class_idx: parseInt(class_idx) },
  });
  const nameData = useMemo(()=>{
    const tmp =[]
if(fetchClassStudent){
  fetchClassStudent.classStudentList.map((e)=>{
    tmp.push({username:e.user.name,userid:e.user.id})
  })
}
return tmp
  },[fetchClassStudent])
  const {
    data: fetchAssignedPhonicsDetails,
    refetch: assginedPhonicsDetailRefetch,
    fetchdetailloading,
  } = useQuery(queries.phonicsUnitList.FETCH_ASSIGNED_PHONICS_STUDENT_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: { 
      input: book_idx, 
      input_start_date : start_date,
      input_class_idx : parseInt(class_idx)
    },
  });
  let stageCount = 12;
  let findbooklessonno = (book_idx).split('');
  let findbooklessonnumber = findbooklessonno[findbooklessonno.length-1];
  let maximumstages = 0;
  let startpoint = 0;
  let endpoint = 0;  
  const getColumnHeading = (lessonType, stageCount) => {
    let lessonHeading = [];
    //let len = stageCount;
    if(findbooklessonnumber == 1){
      if(maximumstages == 6){startpoint = 1;endpoint = 6;}else{startpoint = 1;endpoint = 4;}
    }else{
      if(maximumstages == 6){startpoint = 7;endpoint = 12;}else{startpoint = 5;endpoint = 8;}
    }

    let len = maximumstages;
    //let start = (stageCount/2) +1;
    let start = startpoint;
    
    if(lessonType == 1) {
      //len = stageCount/2;
      //start = 1;
    }
    for (let i = startpoint; i < len+start; i++) {
      lessonHeading.push({
          title: 'S'+(i),
          dataIndex: 'idx',
          key: 'No',
          align: 'center',
          width: 100,
          render: (text, record) => {
            if(i == 12){
              if(record['stage'+(i)]){
                return <span style={{ color: '#40c057' }}>{record['stage'+(i)]}/100</span>; //점수가 없고 했는지만 체크하는 스테이지임
              }
              return '--';
            }
            if(record['stage'+(i)] == 'Completed') {
              if(i == 8 && record.s8_url !== '' && record.s8_url !== null){
                return <a href={`${record.s8_url}`} target="_blank"><span style={{ color: '#40c057' }}>
                <Button type="primary">완료</Button></span></a>; //점수가 없고 했는지만 체크하는 스테이지임
              }else{
                return <span style={{ color: '#40c057' }}>완료</span>; //점수가 없고 했는지만 체크하는 스테이지임
              }
            }
            if (record['stage'+(i)] == 'Not Started') {
              if(i == 8 && record.s8_url !== '' && record.s8_url !== null){
                return <a href={`${record.s8_url}`} target="_blank"><span style={{ color: '#ffa94d' }}>
                <Button type="primary">미수행</Button></span></a>; //점수가 없고 했는지만 체크하는 스테이지임
              }else{
                return <span style={{ color: '#ffa94d' }}>미수행</span>; //아직 안 한 거임
              }
            }
            return <span style={{ color: '#ffa94d' }}>{record['stage'+(i)]}</span>; //아직 안 한 거임
          },
        });
    }
    return lessonHeading;
  }
  let student_level = null;
  let student_unit  = null;
  let student_title = null;
  let results = [];
  if(fetchAssignedPhonicsDetails?.AssignedPhonicsStudentDetail !== undefined && fetchAssignedPhonicsDetails?.AssignedPhonicsStudentDetail !== null&&nameData.length){
    let assigned_unit_student_details = fetchAssignedPhonicsDetails?.AssignedPhonicsStudentDetail;
    let student_detail_processing = [];

    let stagesarray = { 
                          "s1":"stage1","s2":"stage2","s3":"stage3","s4":"stage4","s5":"stage5",
                          "s6":"stage6","s7":"stage7","s8":"stage8","s9":"stage9","s10":"stage10",
                          "s11":"stage11","s12":"stage12", "stage_count": "stage_count",
                          "completion_date":"completion_date", "s12_score":"s12_score", "s8_url" : "s8_url"
                      };
    assigned_unit_student_details.map((loopdata,key) => {
      let studentname = nameData.filter((e)=>e.userid===loopdata.id)[0]?.username|| 'removed student'
      let unit_status_details = JSON.parse(loopdata.stages_completed_status);
      student_level = loopdata.level;
      student_unit  = loopdata.unit;
      student_title = loopdata.unit_title;
      let resultobject = {};
          if(unit_status_details.length > 0){
            unit_status_details[0].map((loopdata,key) => {
              for(let [subkey,subvalue] of Object.entries(unit_status_details[0][key])){
                 resultobject[stagesarray[subkey]] = subvalue;
              }
            })
            stageCount = resultobject.stage_count;
            student_detail_processing.push({
                            "number":key+1,
                            "studentname":studentname,
                            "stage_count":stageCount,
                            "stage1":resultobject.stage1,
                            "stage2":resultobject.stage2,
                            "stage3":resultobject.stage3,
                            "stage4":resultobject.stage4,
                            "stage5":resultobject.stage5,
                            "stage6":resultobject.stage6,
                            "stage7":resultobject.stage7,
                            "stage8":resultobject.stage8,
                            "stage9":resultobject.stage9,
                            "stage10":resultobject.stage10,
                            "stage11":resultobject.stage11,
                            "stage12":resultobject.s12_score,
                            "s8_url" : resultobject.s8_url,
                            "completion_date":resultobject.completion_date,
                          })
            maximumstages = stageCount
          }
        })  
        results = student_detail_processing;
  }
  //const LessonTwoHeading = getColumnHeading(2, stageCount);
  const LessonOneHeading = getColumnHeading(1, stageCount);
  const columns = [
      {
        title: 'No',
        dataIndex: 'idx',
        key: 'No',
        align: 'left',
        width: 150,
        render: (text, record) => {
          return <Space size="middle">{record.number}</Space>;
        },
      },
      {
        title: 'Student Name',
        dataIndex: 'idx',
        key: 'No',
        align: 'left',
        width: 150,
        render: (text, record) => {
          return <Space size="middle">{record.studentname}</Space>;
        },
      },
      {
        title: `Lesson ${findbooklessonnumber}`,
        dataIndex: 'idx',
        key: 'No',
        children : LessonOneHeading
        
      },
    {
        title: '완료날짜',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
        width: 200,
        render: (text, record) => {
                    let loopbookcode = book_idx.split("");
          let findbooklesson = loopbookcode[loopbookcode.length-1];
          let loopdateshow = false;
          if(findbooklesson.toString() == "1"){
            if(record.stage_count == 6){
              if(record.stage1 == "Completed" && record.stage2 == "Completed" && record.stage3 == "Completed" && record.stage4 == "Completed" && record.stage5 == "Completed" && record.stage6 == "Completed"){
                loopdateshow = true;
              }
            }
            if(record.stage_count == 4){
              if(record.stage1 == "Completed" && record.stage2 == "Completed" && record.stage3 == "Completed" && record.stage4 == "Completed"){
                loopdateshow = true;
              }
            }
          }

          if(findbooklesson.toString() == "2"){
            if(record.stage_count == 6){
              if(record.stage7 == "Completed" && record.stage8 == "Completed" && record.stage9 == "Completed" && record.stage10 == "Completed" && record.stage11 == "Completed" && record.stagetage12 !== ''){
                loopdateshow = true;
              }
            }
            if(record.stage_count == 4){
              if(record.stage5 == "Completed" && record.stage6 == "Completed" && record.stage7 == "Completed" && record.stage8 == "Completed"){
                loopdateshow = true;
              }
            }
          }

          if(loopdateshow == true){
            if(record.completion_date){
              return  <span style={{ color: '#40c057' }}>{moment(record.completion_date.split('+')[0]).format('YYYY-MM-DD HH:mm')}</span>;
            }
            return '';
          }else{
            return '';
          }
        },
    }
  ];
  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>Phonics Results</HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="learning-halfwrapper learning-hs">
            <div
              className="learning-halfwrapper-top-header"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              <Title level={5} style={{width:"100%", textAlign:"left"}}>
              {book_idx}( <Text type="success">{start_date}</Text> )
              </Title>
            </div>
            <HomeWorkTable
              dataSource={results}
              columns={columns}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Resultgroup;
