import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// import {SingleScripterOnly} from "./Scripter";
import PageNum from './PageNum';
import SentenceImageSelectBox from './SentenceImageSelectBox';
import SwitchButtonBox from './SwitchButtonBox';
import Keyboard from './Keyboard';
import PopUp from './PopUp';
import { useLocation } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/wrong-error-sound.mp3';
const MAX_WRONG_COUNT = 2;

const SingleReviewType = ({ stages, steps, clickSoundEnd, setSteps, onFinish, setClickSound }) => {
  const {
    customData: { problem },
  } = stages;
  const [word, setWord] = useState([]);
  const [selectedWord, setSelectedWord] = useState('');
  const [reviewAnswerReset, setReviewAnswerReset] = useState(false);
  const [mode, setMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const [wrongCount, setWrongCount] = useState(0);
  const resstage = location.pathname.split('/');

  const handleToggle = () => {
    setMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (isError) {
      setMode(false);
    }
  }, [isError]);

  const handleWriteEnd = () => {
    if (!_.isEmpty(word) && !_.isEmpty(selectedWord)) {
      let userAnswer = word.join('').toLowerCase();
      const realAnswer = problem[steps].sentenceFormat.replace('##word##', selectedWord).toLowerCase();

      if (userAnswer[userAnswer.length - 1] !== '.') {
        userAnswer = userAnswer.concat('.');
      }
      if (userAnswer === realAnswer) {
        const correctSound = _.sample(['https://cdn.cloubot.com/PM/audio/sound-effect/excellent.mp3', 'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3', 'https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3']);
        setReviewAnswerReset(true);
        if (steps >= problem.length - 1) {
          //마지막 스텝은 Goodjob만?
          onFinish();
          return;
        } else {
          setClickSound(correctSound);
        }
        setSteps(steps + 1);
      } else {
        if (wrongCount >= MAX_WRONG_COUNT - 1) {
          setClickSound(woopsSound);
          setWrongCount(0);
          if (steps >= problem.length - 1) {
            //마지막 스텝은 Goodjob만?
            onFinish();
            return;
          }
          setSteps(steps + 1);
        } else {
          setWrongCount(wrongCount + 1);
          setClickSound(wrongSound);
        }
      }
    }
  };
  const handleChangeWord = (sWord) => {
    setSelectedWord(sWord);
  };
  const handleScriptLoaded = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    setWord([]);
    setSelectedWord('');
  }, [steps]);
  useEffect(() => {
    setReviewAnswerReset(false);
  }, [clickSoundEnd]);
  return (
    <>
      <S.ColumnFlex
        style={{
          alignSelf: 'stretch',
          flex: '1 1',
          // height: 'calc(100% - 70px)'
        }}
        id="lv3_review8_content"
        className="main_content  row2"
      >
        <TopBox className="top_box">
          <SentenceImageSelectBox
            stepProblem={problem[steps] ? problem[steps] : undefined}
            onChangeWord={handleChangeWord}
            selectedWord={selectedWord}
          />
          <PageNum num={_.isNil(steps) ? 0 : steps + 1} total={_.isNil(stages.customData.problem) ? 0 : stages.customData.problem.length} />
        </TopBox>
        <S.PenKeyboardWrapMain className="bottom_box">
          <p style={{ fontWeight: '600', fontSize: '22px', margin: 0, padding: '20px 20px 0' }} className="qeustion_txt">
          Type the sentence above.
          </p>
          <S.PenKeyboardWrap style={{ padding: '10px 0' }} className="pen_keyboard_wrap">
          <Keyboard imageWordWrite={true} getKey={word} setGetKey={setWord} onWriteEnd={handleWriteEnd} />
          </S.PenKeyboardWrap>
        </S.PenKeyboardWrapMain>
      </S.ColumnFlex>
      {/* {isLoading && <PopUp popUpType="loading" />} */}
    </>
  );
};

export default SingleReviewType;
const TopBox = styled(S.SelectAndDndTopBox)`
  margin-bottom: 16px;
`;
const BottomBox = styled(S.Dnd4BottomBox)``;
