import { gql } from '@apollo/client';

export const OFFLINE_SYLLABUS_TEXTBOOK_LIST = gql`
   query offlineTextbookList{
    offlineTextbookList{
      idx
      create_user_idx
      company_idx
      lesson_topic
      lesson_count
      bookcode
      booktitle
      publisher
      subject
      skillname
      lessonno
      registerdate
      level
      memo
      folder_type
    }
 }`;


 export const OFFLINE_ADD_SYLLABUS_TEXTBOOK_LESSON_LIST = gql`
   query offlineAddTextbookSyllabusList($bookprogramname: String!,$bookprogramlevel: String!){
    offlineAddTextbookSyllabusList(bookprogramname:$bookprogramname,bookprogramlevel:$bookprogramlevel){
      idx
      create_user_idx
      company_idx
      programname
      offline_syllabus_textbook_bookcode
      offline_syllabus_textbook_booktitle
      level
      days_lesson
      lesson_topic
      ppturl
      plantopic
      planday
      planlearningobj
      text_bookcode1
      text_bookcode2
      lessonplancontent
      homeworkcontent
      extrahomeworkcontent
      folder_type
    }
 }`;


  export const OFFLINE_ADD_SYLLABUS_TEXTBOOK_LESSON_LIST_ID = gql`
   query offlineAddTextbookSyllabusListID($bookprogramrowid: Int!,$bookprogramlevel: String!){
    offlineAddTextbookSyllabusListID(bookprogramrowid:$bookprogramrowid,bookprogramlevel:$bookprogramlevel){
      idx
      create_user_idx
      company_idx
      programname
      offline_syllabus_textbook_bookcode
      offline_syllabus_textbook_booktitle
      level
      days_lesson
      lesson_topic
      ppturl
      plantopic
      planday
      planlearningobj
      text_bookcode1
      text_bookcode2
      lessonplancontent
      homeworkcontent
      extrahomeworkcontent
      folder_type
    }
 }`;

export const OFFLINE_SYLLABUS_TEXTBOOK_LIST_BY_CODE = gql`
   query offlineTextbookBybookcode($bookcode: String!){
    offlineTextbookBybookcode(bookcode: $bookcode){
      idx
      create_user_idx
      company_idx
      lesson_topic
      bookcode
      booktitle
      publisher
      subject
      skillname
      lessonno
      registerdate
      level
      memo
      folder_type
    }
 }`;

export const OFFLINE_ADD_SYLLABUS_LESSON_LIST = gql`
   query offlineAddSyllabusList{
    offlineAddSyllabusList{
      idx
      create_user_idx
      company_idx
      programname
      offline_syllabus_textbook_bookcode
      offline_syllabus_textbook_booktitle
      level
      days_lesson
      lesson_topic
      ppturl
      activityboard
    }
 }`;

export const OFFLINE_TEXTBOOK_TITLE_WITH_LEVEL = gql`
   query offlineTextbookTitleWithLevel{
    offlineTextbookTitleWithLevel{
      idx,
      lesson_topic,
      bookcode,
      booktitle,
      publisher,
      subject,
      skillname,
      lessonno,
      registerdate,
      level,
      memo
    }
 }`;

export const OFFLINE_TEXTBOOK_PROGRAM_LIST = gql`
   query offlineTextbookProgramList{
    offlineTextbookProgramList{
      idx
      programname
      offline_syllabus_textbook_bookcode
      offline_syllabus_textbook_booktitle
      level
      updatedby
      folder_type
    }
 }`;

 export const OFFLINE_TEXTBOOK_LESSON_ASSIGNED = gql`
   query offlineTextLessonAssigned{
    offlineTextLessonAssigned{
      updatedby
      offline_syllabus_add_syllabus_idx
      rowidx
      assign_class_idx
      assign_title
      name
      offline_syllabus_textbook_bookcode
      offline_syllabus_textbook_booktitle
      level
      programname
      noofstudent
      lessonno
      bookcode
      booktitle
    }
 }`;

export const OFFLINE_SYLLABUS_ACTIVITY_BOARD = gql`
   query offlineSyllabusActivityBoardList($bookrowid: Int!,$booklevel: String!){
    offlineSyllabusActivityBoardList(bookrowid: $bookrowid,booklevel: $booklevel){
      idx
      create_user_idx
      company_idx
      programname
      booklevel
      lessonname
      lessontopic
      lessonppturl
      enrolltitle
      enrollname
      enrolldate
      content
      enrollhit
      bf_file
      bf_fileName
      mimetype
      encoding
      path
    }
 }`;

export const OFFLINE_SYLLABUS_LESSON_PLAN = gql`
   query offlineSyllabusLessonPlanList($bookrowid: Int!,$booklevel: String!){
    offlineSyllabusLessonPlanList(bookrowid: $bookrowid,booklevel: $booklevel){
      idx
      create_user_idx
      company_idx
      programname
      booklevel
      lessonname
      lessontopic
      lessonppturl
      plantopic
      planday
      planlearningobj
      text_bookcode1
      text_bookcode2
      lessonplancontent
      homeworkcontent
      extrahomeworkcontent
    }
 }`;


 export const OFFLINE_SYLLABUS_PROGRAM_LEVEL = gql`
   query offlineSyllabusProgramWithLevel($bookrowid: Int!,$booklevel: String!){
    offlineSyllabusProgramWithLevel(bookrowid: $bookrowid,booklevel: $booklevel){
      idx
      programname
      level
    }
 }`;

export const OFFLINE_ASSIGNED_SYLLABUS_LESSON_PLAN = gql`
   query AssignedOfflineSyllabusFetch($input_param_idx:Int!, $input_param_class_idx:Int!, $input_param_title: String!){
    AssignedOfflineSyllabusFetch(input_param_idx: $input_param_idx, input_param_class_idx: $input_param_class_idx, input_param_title: $input_param_title){
      lessonidx
      extra_homework
      start_date
      end_date
      start_time
      title
      class_idx
      class_name
      user_idx
      unit_assign_date
      book_idx
      code
      level
      unit
      unit_title
    }
 }`;

export const DASHBOARD_CLASS_OFFLINE_SYLLABUS_DATEWISE_LIST = gql`
  query OfflineAssignedDailySchedue($input: String!, $start: String, $end: String) {
    OfflineAssignedDailySchedue(input: $input, start: $start, end: $end) {
      offline_assign_date
      count
    }
  }
`;



