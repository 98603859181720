import { gql } from '@apollo/client';

export const AI_TOPICS_LIST_BY_GROUP_ID = gql`
  query aiTopicsListByGroupId($groupIdx: Int!) {
    aiTopicsListByGroupId(groupIdx: $groupIdx) {
      aiTopics {
        idx
        topic
        company_idx
        campus_idx
        user_idx
        class_idx
        idate
        assigned_count
        data
        user {
          idx
          name
        }
        campus {
          idx
          name
        }
        group_detail
      }
    }
  }
`;

export const AI_Talking_TOPICS_LIST = gql`
  query aiTalkingGroupList($folderType: String, $type: String, $q: String, $page: Int, $take: Int, $level: String, $content_idx: Int) {
    aiTalkingGroupList(folderType: $folderType, type: $type, q: $q, page: $page, take: $take, level: $level, content_idx: $content_idx) {
      total
      page
      take
      aiTopicsGroup {
        idx
        group_name
        level
        content_idx
        assistants_data
        ai_topic {
          idx
          topic
          data
        }
      }
    }
  }
`;
export const GET_CONTENT_LIST = gql`
  query getContentList($type: String) {
    getContentList(type: $type) {
      idx
      content_name
    }
  }
`;

export const AI_TALKING_DETAIL = gql`
  query aiTalkingDetail($groupIdx: Int!) {
    aiTalkingDetail(groupIdx: $groupIdx) {
      idx
      group_name
      level
      assistants_id
      assistants_data
      files_list
      ai_topic_detail
      vector_idx
    }
  }
`;
