import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, Select, Spin, message, Modal, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import styled from 'styled-components';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { openNotification } from 'src/components/common/Notification';
import { useHistory } from 'react-router-dom';
import FullScreenButton from 'src/components/common/FullScreenButton';
import 'react-simple-keyboard/build/css/index.css';
import htmlToDraft from 'html-to-draftjs';
import * as axios from 'axios';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import ReactPlayer from 'react-player';
import * as diff_match_patch from 'diff-match-patch';
import { captureUserMedia } from 'src/utils';
const dmp = new diff_match_patch();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const dummyData = [
  {
    page: 0,
    recording: '1',
    text: ``,
  },
];
const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Header = styled(RowFlex)`
  justify-content: start;
  align-items: center;
  height: 75px;
  padding: 16px 65px 16px 45px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;
const ImgButton = styled.img`
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 5px;
`;
const StageTitle = styled.div`
  border-bottom: 1px solid #ed7d31;
  width: 100%;
  padding: 10px 30px;
`;
const StageTitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#0070c0')};
  font-size: 25px;
  font-weight: 600;
  padding: 0 45px;
  @media (max-width: 1280px) {
    font-size: 22px;
    padding: 40px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 20px 0;
  text-align: center;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 5px 30px 15px;
  background-color: #fdf9df;
  max-width: 1500px;
  min-width: 1250px;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;

const HalfBody = styled.div`
  width: 50%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const TextBox = styled.div`
  flex: 1;
  border: 3px solid ${(props) => (props.original ? '#599ad5' : '#ff9900')};
  height: 95%;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  color: #797979;
  flex: 1;
  background-color: white;
  border-radius: 5px;
  overflow: auto;
`;

const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #fff;
  background: #ed7d31;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
const TextWrapper = styled(RowFlex)`
  justify-content: space-between;
  padding: 10px 25px;
`;
const TextDiv = styled.div`
  padding: 0 25px;
  font-size: 22px;
  font-weight: 500;
  @media (max-width: 1280px) {
    font-size: 18px;
    padding: 0 20px;
  }
`;
const Button = styled.div`
  cursor: pointer;
  border-radius: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  height: 40px;
  padding: 8px 45px;
  @media (max-width: 1280px) {
    height: 32px;
    font-size: 1.2em;
    padding: 6px 25px;
  }
`;
export default function LevelUpWPMResult({ recordData = [], defaultLevel, defaultChatList, idx, onShowRecording }) {
  const history = useHistory();
  return (
    <Wrapper>
      <Header>
      <div onClick={()=>{history.push('/level-up')}} style={{cursor:'pointer'}}>
          {' '}
          <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} />
        </div>
        <HeadBox background="transparent" color={'#fff'} style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px' }}>
          <FullScreenButton
            element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
            activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
          />
          ID: {userInfoVar()?.id}
          <CloseButton onClick={{}}>
            <CloseOutlined />
          </CloseButton>
        </HeadBox>
      </Header>
      <MainWrapper>
        <Main>
          <StageTitle>
            <StageTitleSpan>BOOK Title</StageTitleSpan>
            <StageTitleSpan>Unit</StageTitleSpan>
            <StageTitleSpan>Lesson</StageTitleSpan>
            <StageTitleSpan color={'#7030a0'}>Topic, title</StageTitleSpan>
          </StageTitle>
          <RowFlex style={{ height: '80%' }}>
            <HalfBody>
              <Title>Original</Title>
              <TextBox original></TextBox>
            </HalfBody>
            <HalfBody>
              <Title>Yours</Title>
              <TextBox></TextBox>
            </HalfBody>
          </RowFlex>
          <TextWrapper>
            <RowFlex>
              <TextDiv>
                Extra text is <span style={{ color: '#3862be' }}>blue</span>.
              </TextDiv>
              <TextDiv>
                Missed text is <span style={{ color: '#ff0000' }}>red</span>.
              </TextDiv>
            </RowFlex>
            <RowFlex>
              <TextDiv>
                Accuracy : <span style={{ color: '#336e1d' }}>85%</span>
              </TextDiv>
              <TextDiv>
                WPM : <span style={{ color: '#560da6' }}>120</span>
              </TextDiv>
            </RowFlex>
          </TextWrapper>
          <RowFlex style={{ justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
            <Button back={'#f57927'} color={'white'} onClick={{}}>
              Back
            </Button>
          </RowFlex>
        </Main>
      </MainWrapper>
    </Wrapper>
  );
}
