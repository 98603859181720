import React from 'react';
import Writing from './Writing';

const AiTalkingStudy = () => {

  return (
    <>
      <Writing />
    </>
  );
};

export default AiTalkingStudy;
