import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, ArrowLeftOutlined, SoundFilled } from '@ant-design/icons';
import * as axios from 'axios';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height:660px;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #443ec8;
  color: #fff;
  margin-top: 20px;
  font-size: 25px;
  box-shadow: 0 2px 1px 2px #090989;
`;

const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;

const LeftBody = styled(ColumnFlex)`
  width: 55%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 85%;
`;
const StageTitle = styled.div`
  color: #fff;
  padding: 5px 45px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  background-color: #49ada1;
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const RightBody = styled(ColumnFlex)`
  width: 45%;
  padding: ${(props) => (props.sentence ? '0 15px' : '0 20px')};
  height: 95%;
  /* justify-content: space-between; */
`;

const InfoBox = styled(ColumnFlex)`
  color: #000000;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: 2px #daedeb solid;
  border-radius: 25px;
  /* height: 50%; */
  font-size: 30px;
  width: 100%;
  height: 90%;
  cursor: pointer;
  /* @media (max-height: 960px) {
      max-width: 400px;
      max-height: 400px;
    } */
`;
const English = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #453ec8;
  width: 100%;
  height: 45%;
  text-align: center;
  font-size: 40px;
`;
const Korean = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #daedeb;
  color: #49ada1;
  border-radius: 22px;
  width: 100%;
  height: 55%;
`;
const WordListBox = styled(ColumnFlex)`
  background-color: #ffb900;
  border-radius: 25px;
  border: 2px #fff solid;
  height: 90%;
  color: #000000;
  font-weight: 700;
  font-size: 28px;
  align-items: center;
  padding: ${(props) => (props.sentence ? '25px 5px' : '25px')};
  justify-content: space-between;
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.05);
`;
const WordBox = styled.div`
  border-radius: 10px;
  border: 2px #fff solid;
  background-color: ${(props) => (props.active ? '#fef189' : '#fff')};
  color: #595758;
  margin-top: 10px;
  width: 90%;
  text-align: center;
  font-size: 18px;
  line-height: 1.8;
`;
const SetenceBox = styled.div`
  border-radius: 10px;
  border: 2px #eaaa3e solid;
  background-color: ${(props) => (props.active ? '#fbe5d6' : '#fff')};
  color: ${(props) => (props.active ? '#cb0000' : '#000000')};
  margin-top: 10px;
  width: 85%;
  text-align: center;
  font-size: 17px;
  line-height: 1.8;
`;
const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  background-image: url(${(props)=>props.bg});
  background-size:  72px 50px;
  background-repeat: no-repeat;
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;

const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  max-width: 800px;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const ArrowIcon = styled.div`
  width: 52px;
  min-width: 52px;
  height: 45px;
  min-height: 45px;
  margin: 0 35px;
  background-image: url(${(props)=>props.src});
  background-size:  52px 45px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
export default function EngStaFlashCard({ type = 'voca' }) {
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <Title>
              <TitleSpan color={'#453ec8'}>BOOK Title</TitleSpan>
              <TitleSpan>Volume</TitleSpan>
              <TitleSpan>Unit</TitleSpan>
              <TitleSpan>Lesson</TitleSpan>
              <TitleSpan>Lesson Name</TitleSpan>
            </Title>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '93%' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '20px 50px' }}>
                <StageTitle>{type === 'voca'?'Voca':'Sentence'} Exercise</StageTitle>
                <NumberText>
                  Number{' '}
                  <QuizNumber>
                    <span style={{ color: '#433fc8' }}>{`03`}</span>/{`10`}
                  </QuizNumber>
                </NumberText>
              </RowFlex>
              <RowFlex style={{ height: '100%', padding: '0 50px' }}>
                <LeftBody>
                  <InfoBox>
                    <English>{type === 'voca' ? `bed` : `We sleep in a bed.`}</English>
                    <Korean>{type === 'voca' ? `침대` : `우리는 침대에서 잠을 잔다.`}</Korean>
                  </InfoBox>
                  <ImgButton>
                    <SoundFilled />
                  </ImgButton>
                </LeftBody>
                <RightBody sentence={type === 'sentence'}>
                  <WordListBox sentence={type === 'sentence'}>
                    <div style={{ marginBottom: '15px' }}>{type === 'voca' ? `Word` : `Key Sentences`} List</div>
                    {type === 'voca' ? (
                      <>
                        <WordBox active={false}>chair</WordBox>
                        <WordBox active={false}>table</WordBox>
                        <WordBox active={false}>floor</WordBox>
                        <WordBox active={true}>bed</WordBox>
                        <WordBox active={false}>house</WordBox>
                        <WordBox active={false}>door</WordBox>
                        <WordBox active={false}>cat</WordBox>
                        <WordBox active={false}>closet</WordBox>
                      </>
                    ) : (
                      <>
                        <SetenceBox active={false}>I live in a big house.</SetenceBox>
                        <SetenceBox active={false}>Our furniture in our house is new.</SetenceBox>
                        <SetenceBox active={false}>Sit on the chair.</SetenceBox>
                        <SetenceBox active={false}>That is a big table.</SetenceBox>
                        <SetenceBox active={true}>We sleep in a bed.</SetenceBox>
                        <SetenceBox active={false}>Let's wait in the living room.</SetenceBox>
                      </>
                    )}
                  </WordListBox>
                </RightBody>
              </RowFlex>
            </ColumnFlex>
          </Main>
        </MainWrapper>
        <StageButtonWrapper>
        <ArrowIcon src={'/images/LevelUp/study/sub/arrow_left.png'}>
          </ArrowIcon>
          <RowFlex style={{ justifyContent: 'space-between', width: '45%', height: '100%' }}>
           <StageButtonText active={false}>
            <StageButton bg={`/images/LevelUp/study/sub/g_flashcard.png`} active={false} isDone={true} />
              Flashcard
            </StageButtonText>
            <StageButtonText active={false}>
            <StageButton bg={`/images/LevelUp/study/sub/g_check.png`} active={false} isDone={true} />
              Check
            </StageButtonText>
            <StageButtonText active={true}>
            <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} active={true} isDone={false} />
              Typing
            </StageButtonText>
            <StageButtonText active={false}>
            <StageButton bg={`/images/LevelUp/study/sub/g_quiz.png`} active={false} isDone={false} />
              Quiz
            </StageButtonText>
          </RowFlex>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_right.png'}>
          </ArrowIcon>
        </StageButtonWrapper>
      </Wrapper>
    </>
  );
}
