import * as axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import * as queries from 'src/operations/queries';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import BookrRecording from 'src/pages/LevelUp/Recording/Tool';
import { useLocation } from 'react-router';
import _ from 'lodash';
import { Col, message, Row } from 'antd';
import Loading from 'src/components/common/Loading';
import { EXTERNAL_STUDY_BOOK } from 'src/operations/queries/externalStudy';
import { useQuery } from '@apollo/client';

const Manage = () => {
  const { id } = useParams();
  const location = useLocation();
  const assigned_idx = location.state?.assigned_idx;
  const idx = location.state?.idx;
  const [bookData, setBookData] = useState(null);
  const [book, setBook] = useState(null);
  const token = getCurrentToken();
  const { data: userData } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });

  const { data: userBook } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      type: 'levelup',
      book_no: parseInt(id),
    },
    fetchPolicy: 'no-cache',
  });

  const fetchBookText = async () => {
    try {
      const data = await axios.get(`https://cdn.cloubot.com/LEVELUP/texts/${id}.json`);
      setBookData(data.data);
    } catch (e) {
      console.log(e);
      message.error('Book data not found');
    }
  };
  useEffect(() => {
    fetchBookText();
  }, []);

  useEffect(() => {
    try {
      const book = userBook?.getExternalUserBook.find((item) => parseInt(item.book_no) === parseInt(id));
      setBook(book);
    } catch (e) {
      console.log(e);
    }
  }, [id, userBook]);

  return (
    <>
      {bookData && book && userData ? (
        <BookrRecording
          dataNew={bookData}
          // have to change
          articleData={book}
          assignedIdx={assigned_idx || null}
          idx={idx || null}
          //
          userData={userData?.me}
          articleId={id}
          code={id}
        />
      ) : (
        <Col span={24}>
          <Loading />
        </Col>
      )}
    </>
  );
};

export default withRouter(Manage);
