import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form } from 'antd';
import DefaultModal from './DefaultModal';
import { Modal } from 'antd';
import CreatePlacementTestForm from 'src/pages/Consulting/CreatePlacementTestForm';
import CreateMetaScheduleForm from 'src/pages/Schedule/CreateMetaScheduleForm';
import { META_SCHEDULE } from 'src/operations/mutations/schedule';

const CreateScheduleModal = ({ schedule, visible, onCancel, refetch, campus_idx, ym, ...rest }) => {
  const [form] = Form.useForm();
  const [closeForm, setCloseForm] = useState(false);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
    setCloseForm(true);
  }, [form, onCancel]);

  useEffect(() => {
    if (visible) {
      setCloseForm(false);
    }
  }, [visible]);

  const [createMetaSchedule, { loading }] = useMutation(META_SCHEDULE, {
    onCompleted(data) {
      if (data) {
        if (data.metaSchedule[0].exists_day) {
        }
        const existsDay = data?.metaSchedule[0]?.exists_day ? JSON.parse(data?.metaSchedule[0]?.exists_day) : '';
        const scheduleDay = data?.metaSchedule[0]?.days ? JSON.parse(data?.metaSchedule[0]?.days) : '';
        var msg;
        if (existsDay.length > 0 && scheduleDay.length > 0) {
          msg = `Schedule successfully for ${scheduleDay.toString()} but not schedule successfully for ${existsDay.toString()} because there is alredy schedule is exists`;
        } else if (scheduleDay.length > 0) {
          msg = `Schedule successfully for ${scheduleDay.toString()}`;
        } else {
          msg = `Not schedule for ${existsDay.toString()}`;
        }
        handleCancel();
        refetch();
        //title: `Your registration date ${preVariables.reserve_date}`,
        Modal.success({
          title: msg,
        });
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];
      Modal.error({
        title: `${message}`,
      });
    },
  });

  return (
    <DefaultModal
      title="등록"
      form={form}
      ActionCancel={handleCancel}
      popupMode="create"
      loading={loading}
      style={{ width: '80%' }}
      visible={visible}
      {...rest}
      className="schedule-popup"
    >
      <CreateMetaScheduleForm scheduledata={schedule} form={form} onCreate={createMetaSchedule} closeForm={closeForm} />
    </DefaultModal>
  );
};

export default CreateScheduleModal;
