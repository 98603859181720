import React, { useState, useRef, useEffect, useMemo, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useStudyCreate } from '../../../utils/pm/api';
import * as S from 'src/pages/PhonicsMonster/style';
import PopUp from '../PopUp';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { userInfoVar } from 'src/apollo/cache';

const getBgColor = (hex) => {
  return `rgb(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5), 16)}, 1)`;
};

const ColorStage = ({ bookInfo, unitInfo, stages, steps, setSteps }) => {
  const { problem, answer } = stages;
  const [drawSound, setDrawSound] = useState(undefined);
  const itemColors = useMemo(() => {
    if (problem.length > 0) {
      const colors = _.shuffle([
        '#c92a2a',
        '#e67700',
        '#ffe066',
        '#66a80f',
        '#1c7ed6',
        '#862e9c',
        '#e64980',
        '#22b8cf',
        '#12b886',
        '#d9480f',
      ]);
      return colors.slice(0, problem.length);
    }
    return [];
  }, [problem]);
  console.log('itemColors', itemColors, getBgColor(itemColors[0]), answer);
  const history = useHistory();
  const [showPopUp, setShowPopUp] = useState(false);
  const [opacity, setOpacity] = useState(Array(problem.length).fill(0));
  const [done, setDone] = useState(undefined);
  const [canvasRect, setCanvasRect] = useState({ width: 100, height: 100 });
  const [greatSound, setGreatSound] = useState(undefined);
  const paintRefs = useRef(Array(problem.length).fill(false));
  const circleRefs = useRef();
  const canvasRefs = useMemo(() => Array.from({ length: problem.length }).map(() => createRef()), [problem]);

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  const ctxRefs = useRef();

  const drawRefs = useRef(Array(problem.length).fill(false));

  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };

  const handleMouseUp = (item, index) => {
    paintRefs.current[index] = false;
  };
  const handleMouseDown = (item, index) => {
    paintRefs.current[index] = true;
  };
  const handleMouseMove = (item, index) => {
    if (answer.indexOf(item) === -1) {
      return false;
    }

    if (done[index] === true) {
      return false;
    }
    if (paintRefs.current[index] === true) {
      if (opacity[index] < 1) {
        console.log('쓱쓱');
        setDrawSound('https://cdn.cloubot.com/PM/audio/sound-effect/pencil-sound.mp3');
        const copyOpacity = _.cloneDeep(opacity);
        copyOpacity[index] += 0.01;
        setOpacity(copyOpacity);
      } else {
        const copyDone = _.cloneDeep(done);
        copyDone[index] = true;
        setDone(copyDone);
        //여기서 Great!!
        setGreatSound('https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3');
        console.log('done!!!!');
      }
    }
  };

  const getPosition = (evt) => {
    const cur = evt.currentTarget.getBoundingClientRect();

    const clientX = evt.changedTouches ? evt.changedTouches[0].clientX : evt.clientX;
    const clientY = evt.changedTouches ? evt.changedTouches[0].clientY : evt.clientY;

    const x = Math.round(clientX - cur.x);
    const y = Math.round(clientY - cur.y);

    return { x: x, y: y };
  };

  const startDraw = (item, index, evt) => {
    if (answer.indexOf(item) === -1) {
      return false;
    }
    drawRefs.current[index] = true;
    ctxRefs.current[index].beginPath();
    const pos = getPosition(evt);
    ctxRefs.current[index].moveTo(pos.x, pos.y);
  };

  const draw = (item, index, evt) => {
    if (answer.indexOf(item) === -1) {
      return false;
    }
    if (drawRefs.current[index] === true) {
      console.log('draw', index);
      const pos = getPosition(evt);
      console.log('pos', pos);
      ctxRefs.current[index].lineTo(pos.x, pos.y);
      ctxRefs.current[index].lineCap = 'round';
      ctxRefs.current[index].lineWidth = '10';
      ctxRefs.current[index].strokeStyle = itemColors[index]; //"#ff0000";
      ctxRefs.current[index].stroke();
    }
  };

  const finishDraw = (item, index) => {
    if (answer.indexOf(item) === -1) {
      return false;
    }
    drawRefs.current[index] = false;
  };

  const isDone = (index) => {
    if (!_.isNil(done) && done[index]) {
      if (answer.indexOf(problem[index]) !== -1) {
        return true;
      }
    }
    return false;
  };

  const handleGreatSoundEnd = () => {
    setGreatSound(undefined);
    if (!_.isNil(done) && done.every((item) => item === true)) {
      setShowPopUp(true);
    }
  };

  useEffect(() => {
    const init = problem.map((item) => (answer.indexOf(item) === -1 ? true : false));
    setDone(init);
  }, [problem, answer]);

  useEffect(() => {
    setCanvasRect({ width: circleRefs.current.offsetWidth, height: circleRefs.current.offsetHeight });

    ctxRefs.current = canvasRefs.map((item) => {
      return item.current.getContext('2d');
    });
    console.log('ctx', ctxRefs.current);
  }, [canvasRefs]);

  return (
    <>
      <S.ColorMainBox className="main_content onewrap">
        <S.ColorPaintWordsWrap className="paintwords_wrap">
          <S.ColorPaintUl className="paintwords_box">
            {problem.map((item, key) => (
              <S.ColorPaintLi num={key} className="paintwords_item" key={`paint-word-item-key${key}`}>
                <S.ColorPaintWord
                  ref={circleRefs}
                  className="paintword"
                  style={{ backgroundColor: `rgb(255, 0, 0, 0)`, position: 'relative', border: 'none' }}
                  onMouseUp={() => handleMouseUp(item, key)}
                  onMouseDown={() => handleMouseDown(item, key)}
                  onMouseMove={() => handleMouseMove(item, key)}
                  onMouseLeave={() => handleMouseUp(item, key)}
                  onTouchStart={() => handleMouseDown(item, key)}
                  onTouchEnd={() => handleMouseUp(item, key)}
                  onTouchMove={() => handleMouseMove(item, key)}
                  onTouchLeave={() => handleMouseUp(item, key)}
                >
                  {isDone(key) ? (
                    <>
                      <canvas
                        ref={canvasRefs[key]}
                        width={canvasRect.width}
                        height={canvasRect.height}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: '0',
                          borderRadius: '50%',
                          border: '0.6rem solid #4f4a75',
                          backgroundColor: `${isDone(key) ? getBgColor(itemColors[key]) : 'rgb(255,255,255, 0.5)'}`,
                        }}
                        onMouseUp={() => finishDraw(item, key)}
                        onMouseDown={(e) => startDraw(item, key, e)}
                        onMouseMove={(e) => draw(item, key, e)}
                        onMouseLeave={() => finishDraw(item, key)}
                        onTouchStart={(e) => startDraw(item, key, e)}
                        onTouchEnd={() => finishDraw(item, key)}
                        onTouchMove={(e) => draw(item, key, e)}
                        onTouchLeave={() => finishDraw(item, key)}
                      />
                      <Alphabet word={item} />
                    </>
                  ) : (
                    <>
                      <Alphabet word={item} />
                      <canvas
                        ref={canvasRefs[key]}
                        width={canvasRect.width}
                        height={canvasRect.height}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: '0',
                          borderRadius: '50%',
                          border: '0.6rem solid #4f4a75',
                          backgroundColor: `${isDone(key) ? 'rgb(255,0, 0, 1)' : 'rgb(255,255,255, 0.5)'}`,
                        }}
                        onMouseUp={() => finishDraw(item, key)}
                        onMouseDown={(e) => startDraw(item, key, e)}
                        onMouseMove={(e) => draw(item, key, e)}
                        onMouseLeave={() => finishDraw(item, key)}
                        onTouchStart={(e) => startDraw(item, key, e)}
                        onTouchEnd={() => finishDraw(item, key)}
                        onTouchMove={(e) => draw(item, key, e)}
                        onTouchLeave={() => finishDraw(item, key)}
                      />
                    </>
                  )}
                </S.ColorPaintWord>
              </S.ColorPaintLi>
            ))}
          </S.ColorPaintUl>
        </S.ColorPaintWordsWrap>
      </S.ColorMainBox>
      <SoundEffectPM url={greatSound} onEnd={handleGreatSoundEnd} />
      {showPopUp ? <PopUp popUpType={'correct'} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}

      <SoundEffectPM
        url={drawSound}
        onEnd={() => {
          setDrawSound(undefined);
        }}
      />
    </>
  );
};

export default ColorStage;

const Alphabet = ({ word }) => {
  return (
    <p
      className="word"
      style={{
        display: 'table-cell',
        height: '100%',
        fontSize: '5vh',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        cursor: 'default',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        zIndex: '0',
      }}
    >
      {word}
    </p>
  );
};
