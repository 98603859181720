import React, {useState, useEffect, useMemo} from "react";
import _ from "lodash";
import BlockWordBox from "./BlockWordBox";
import SoundButton from "./SoundButton";
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import reactStringReplace from "react-string-replace";
import * as S from 'src/pages/PhonicsMonster/style';
const SentenceSoundBox = ({problem, onCorrectSelect}) => {
	const [soundName, setSoundName] = useState();
	const [selected, setSelected] = useState(undefined);
	const [isCorrectSelected, setIsCorrectSelected] = useState(false);
	const [playAutoSound, setPlayAutoSound] = useState(false);

	const randomEventOdd = useMemo(() => {
		return Math.floor(Math.random() * 2) + 0;
		// eslint-disable-next-line
	}, [problem]);

	const handleWordClick = sel => {
		if (isCorrectSelected) return;
		setSelected(sel);
	};

	const handleCorrectClick = () => {
		if (isCorrectSelected) return;
		setSoundName("correct");
		setIsCorrectSelected(true);
	};

	const handleWrongClick = () => {
		if (isCorrectSelected) return;
		setSoundName("wrong");
	};

	const handleSoundEnd = () => {
		if (soundName === "correct") {
			onCorrectSelect();
		}
		setSoundName(undefined);
	};

	useEffect(() => {
		setSoundName(undefined);
		setSelected(undefined);
		setIsCorrectSelected(false);
	}, [problem]);

	useEffect(() => {
		if(isCorrectSelected) {
			setTimeout(()=>{
				setPlayAutoSound(true);
			}, 5000);
		}
		else {
			setPlayAutoSound(false);
		}
	}, [isCorrectSelected, playAutoSound]);
	return (
		<>
			<S.RowFlex style={{justifyContent:'center', alignItems:'center',height:'100%',fontWeight:'600',fontSize:'2.6rem'}} className="txt">
				{reactStringReplace(problem.sentenceFormat, "##word##", match => (
					<>
						<S.RowFlex style={{alignItems:'center'}} className="alphabet_speaker_area">
							<S.ImgSoundWriteAlphabetWrap className="alphabet_wrap">
								<BlockWordBox
									onClick={() => {
										handleWordClick(true);
										randomEventOdd ? handleCorrectClick() : handleWrongClick();
									}}
									selected={selected !== undefined ? selected : false}
									word={randomEventOdd ? problem.correctWord : problem.wrongWord}
								/>
								<BlockWordBox
									onClick={() => {
										handleWordClick(false);
										!randomEventOdd ? handleCorrectClick() : handleWrongClick();
									}}
									selected={selected !== undefined ? !selected : false}
									word={!randomEventOdd ? problem.correctWord : problem.wrongWord}
								/>
							</S.ImgSoundWriteAlphabetWrap>
							<S.ColumnFlex style={{alignSelf:'stretch', marginRight:'16px', justifyContent:'center'}} className="speaker_wrap">
								<div>
									{!isCorrectSelected && <SoundButton
										src={
											randomEventOdd
												? `${["June", "Ted"].indexOf(problem.correctWord) === -1 ? problem.correctWord.toLowerCase() : problem.correctWord}.mp3`
												: `${["June", "Ted"].indexOf(problem.wrongWord) === -1 ? problem.wrongWord.toLowerCase() : problem.wrongWord}.mp3`
										}
									/>}
								</div>
								<div style={{marginTop:'32px'}}>
									{!isCorrectSelected &&
									<SoundButton
										src={
											!randomEventOdd
												? `${["June", "Ted"].indexOf(problem.correctWord) === -1 ? problem.correctWord.toLowerCase() : problem.correctWord}.mp3`
												: `${["June", "Ted"].indexOf(problem.wrongWord) === -1 ? problem.wrongWord.toLowerCase() : problem.wrongWord}.mp3`
										}
									/>}
								</div>
							</S.ColumnFlex>
						</S.RowFlex>
					</>
				))}
			</S.RowFlex>
			{ (isCorrectSelected && playAutoSound) && <SoundEffectPM url={`${["June", "Ted"].indexOf(problem.correctWord) === -1 ? "https://cdn.cloubot.com/PM/audio/sounds/"+problem.correctWord.toLowerCase() : "https://cdn.cloubot.com/PM/audio/sounds/"+problem.correctWord}.mp3`}  />}
			<SoundEffectPM url={!_.isNil(soundName) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${soundName}.mp3` : undefined} onEnd={handleSoundEnd} />
		</>
	);
};

export default SentenceSoundBox;
