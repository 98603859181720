import React, { useState } from 'react';
import { Radio } from 'antd';

const LevelTab = ({ onChage, levelNo, levels }) => {
  return (
    <Radio.Group
      value={levelNo}
      style={{ marginBottom: 16, marginLeft: 16, display: 'block' }}
      buttonStyle="solid"
      onChange={onChage}
      size="middle"
    >
      <Radio.Button value={0} style={{ width: 75, textAlign: 'center' }}>
        전체
      </Radio.Button>
      {levels.map((lv, key) => (
        <Radio.Button key={`radio-level-key${key}`} value={lv} style={{ width: 75, textAlign: 'center' }}>
          Level {lv}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default LevelTab;

export const useLevelTab = () => {
  const [levelNo, setLevelNo] = useState(0);
  const onChangeLevelNo = (no) => {
    setLevelNo(no);
  };
  return { levelNo, onChangeLevelNo };
};
