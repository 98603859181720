import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { PoweroffOutlined, SearchOutlined } from '@ant-design/icons';
import FullScreenButton from 'src/components/common/FullScreenButton';
import { Select, Spin, Input, Typography } from 'antd';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import LeftMenu from 'src/components/LevelUp/LeftMenu';
import Carousels from 'src/components/LevelUp/Carousel';
import { getLixileScoreObject } from 'src/utils/index';
import { EXTERNAL_STUDY_BOOK } from 'src/operations/queries/externalStudy';
import { GET_STUDENT_USER, GET_TEACHER_USER } from 'src/operations/queries/getUser';
import { useQuery } from '@apollo/client';
// import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
const { CarouselItem } = Carousels;
const { Option } = Select;
const { Text } = Typography;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffa700;
`;
const Header = styled(RowFlex)`
  justify-content: start;
  align-items: center;
  height: 75px;
  padding: 16px 65px 16px 45px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #fff;
  /* background: #ed7d31; */
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
const MainWrapper = styled(RowFlex)`
  height: calc(100vh - 145px);
  /* align-items: center; */
  /* width: 85%; */
  max-width: 1250px;
  /* min-width: 1200px; */
  /* max-height: 680px; */
  margin: 0 auto;
  background-color: #ffda00;
  padding: 24px 20px;
  @media (max-width: 1280px) {
    width: 1000px;
    min-width: 1000px;
    min-height: 620px;
  }
`;
const RightBox = styled(ColumnFlex)`
  padding: 0 15px;
  min-width: 1050px;
  @media (max-width: 1280px) {
    padding-left: 5px;
    min-width: 800px;
  }
`;
const TopicList = styled(RowFlex)`
  padding: 0 20px;
  max-width: 780px;
  overflow-x: scroll;
  height: 65px;
  flex: 1;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ed8c3d;
    border-radius: 4px;
  }
  @media (max-width: 1280px) {
    max-width: 630px;
  }
`;
const TopicButton = styled.div`
  color: ${(props) => (props.active ? '#fff' : '#999999')};
  background-color: ${(props) => (props.active ? '#ed8c3d' : '#fff')};
  border: ${(props) => (props.active ? '1px solid #ffbe03' : '2px solid #e6e7ee')};
  height: 46px;
  border-radius: 23px;
  font-size: 20px;
  padding: 0 13px;
  line-height: 41px;
  cursor: pointer;
  margin-left: 5px;
  white-space: nowrap;
  @media (max-width: 1280px) {
    height: 40px;
    font-size: 15px;
    line-height: 38px;
  }
`;
const SelectBox = styled(Select)`
  height: 46px;
  width: 130px;
  border-radius: 23px;
  border: 1px solid #dcd7cc;
  background-color: #fff;
  font-size: 20px;
  padding: 5px;
  margin-left: 20px;
  text-align: center;
  @media (max-width: 1280px) {
    height: 40px;
    font-size: 15px;
    width: 110px;
    margin-left: 10px;
  }
`;
const SelectOption = styled(Option)`
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
`;
const BookWrapper = styled(ColumnFlex)`
  width: 100%;
  height: calc(100vh - 285px);
  overflow-y: scroll;
  padding: 0 30px;
  ::-webkit-scrollbar {
    width: 7px;
    height: 8px;
    background-color: #fff;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ed8c3d;
    border-radius: 4px;
  }
  @media (max-width: 1280px) {
    padding: 0 5px;
    min-height: 500px;
  }
`;
const BottomShadow = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  box-shadow: 3px 8px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
`;
const ArrowBackGround = styled(RowFlex)`
  width: 45px;
  height: 45px;
  border: 2px solid #e6e7ee;
  border-radius: 23px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  /* margin-top: 5px; */
  z-index: 5;
  @media (max-width: 1280px) {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
`;
const ArrowButton = styled.button`
  display: inline-block;
  width: 19px;
  height: 32px;
  font-size: 40px;
  font-weight: bolder;
  background: none;
  border: none;
  cursor: pointer;
  background-size: 19px 32px;
  background-repeat: no-repeat;
`;
const LeftArrow = styled(ArrowButton)`
  background-image: url('/images/book/arrow-prev.png');
`;

const RightArrow = styled(ArrowButton)`
  background-image: url('/images/book/arrow-next.png');
  /* margin-left: 5px; */
  /* margin-right: 5px; */
`;

const SearchInput = styled(Input)`
  height: 50px;
  width: 200px;
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid #dcd7cc;
  padding: 15px 20px;
  font-size: 15px;
  :hover {
    background-color: #ffffff;
    border: 2px solid #dcd7cc;
  }
`;

const ImgButton = styled.img`
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const useCheckScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return [width > 1600 ? 1 : width / 1600, height > 900 ? 1 : height / 900];
};
export default function LevelUpLibrary() {
  let width = useCheckScreen()[0];
  // let height = useCheckScreen()[1];
  const [searchValue, setSearchValue] = useState('');
  const [selectedTopic, setSelectedTopic] = useState({ value: 'all', no: 1 });
  const [userData, setUserData] = useState(null);
  const [topics, setTopics] = useState([{ title: 'All', value: 'all', no: 1 }]);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [sortby, setSortby] = useState('lowlevel');
  const [books, setBooks] = useState([]);
  const [level, setLevel] = useState(undefined);
  // const [dropDownlevel, setDropDownLevel] = useState([]);
  const [filteredbook, setFilteredbook] = useState([]);
  const [error, setError] = useState(null);
  const { lv } = queryString.parse(window.location.search.replace('?', ''));
  const history = useHistory();
  useEffect(() => {
    if (lv) {
      setLevel(parseInt(lv));
    }
  }, [lv]);
  // useEffect(() => {
  //   const baseOptions = [
  //     { title: 'Title', value: 'title' },
  //     { title: 'Low mx. to High', value: 'lowlevel' },
  //     { title: 'High mx. to Low', value: 'highlevel' },
  //   ];

  //   const additionalOptions = level === -1
  //     ? []
  //     : [
  //         { title: 'Fiction', value: 'fiction' },
  //         { title: 'Non-Fiction', value: 'nonfiction' },
  //       ];
  //   setDropDownLevel([...baseOptions, ...additionalOptions]);
  // }, [level]);

  const handleSelectTopic = (v) => {
    if (selectedTopic.no === v.no) {
      setSelectedTopic({ value: 'all', no: 1 });
    } else setSelectedTopic(v);
  };
  const handleSort = (v) => {
    console.log('v===>>>', v);
    setSortby(v);
  };
  const {
    data: userBook,
    loading,
    refetch,
  } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: { type: 'levelup', level: `${level < 1 ? 'PH' : level}` },
    fetchPolicy: 'no-cache',
  });
  const topicRef = useRef(null);
  const bookListRef = useRef(null);
  useEffect(() => {
    // console.log(pageWidth)
    if (topicRef.current) {
      if (selectedTopic.no < 4) {
        topicRef.current.scrollLeft = 0;
      } else if (selectedTopic.no > 16) {
        topicRef.current.scrollLeft = topicRef.current.scrollWidth;
      }
    }
  }, [selectedTopic]);
  useEffect(() => {
    bookListRef.current.scrollTop = 0;
  }, [selectedTopic, level, sortby]);
  const topicRight = () => {
    const pageWidth = topicRef.current.offsetWidth;
    topicRef.current.scrollLeft = topicRef.current.scrollLeft + pageWidth * 0.9;
  };
  const topicLeft = () => {
    const pageWidth = topicRef.current.offsetWidth;
    topicRef.current.scrollLeft = topicRef.current.scrollLeft - pageWidth * 0.9;
  };
  const setMxLevel = (lexile) => {
    const mx = getLixileScoreObject(lexile, 'A-1');
    return mx;
  };
  const setLexileValue = (lexile) => {
    if (lexile.includes('BR')) {
      return 0;
    } else if (lexile.includes('NP')) {
      return 1000;
    } else {
      return parseInt(lexile);
    }
  };

  let getUserType = GET_STUDENT_USER;

  if (userInfoVar() && userInfoVar()?.type !== '0' && userInfoVar()?.type !== 0) {
    getUserType = GET_TEACHER_USER;
  }

  useQuery(getUserType, {
    variables: {},
    onCompleted: (data) => {
      if (data?.getUserData) {
        let tempData = data?.getUserData;
        if (tempData.class_teacher) {
          tempData = Object.assign({}, tempData, { classes: tempData.class_teacher.map((i) => i.class) });
        } else {
          tempData = Object.assign({}, tempData, { classes: tempData.class_student.map((i) => i.class) });
        }
        setCurrentLevel(tempData?.classes[0]?.levelup_level);
        setUserData(tempData);
      }
    },
  });

  useEffect(() => {
    try {
      let list = userBook?.getExternalUserBook?.map((e, i) => {
        e.no = i + 1;
        e.lexileVal = !_.isNil(e.lexile_val) ? e.lexile_val : setLexileValue(e.lexile);
        e.mxObj = setMxLevel(e.lexileVal);
        e.level =
          e.mx_level !== undefined
            ? e.mx_level === 'PH'
              ? parseInt(e.is_interactive) === 0
                ? 0
                : -1
              : parseInt(e.mx_level)
            : e.mxObj?.mx;
        return e;
      });
      setBooks(list);
    } catch (e) {
      setError(e);
    }
  }, [userBook]);
  const currentLevelBooks = useMemo(() => {
    if (loading) return [];
    if (books?.length) {
      const currentLevelBooks = books?.filter((e) => e.level === level);

      setTopics(
        [{ title: 'All', value: 'all', no: 1 }].concat(
          currentLevelBooks
            .map((e, i) => {
              return { title: e.category1, value: e.category1, no: i + 2 };
            })
            .filter((e, i, self) => self.findIndex((t) => t.value === e.value) === i),
        ),
      );

      return currentLevelBooks;
    } else {
      return [];
    }
    // }, [books, level, loading, externalBookFav, selectedTopic]);
  }, [books, level, loading]);

  useEffect(() => {
    if (currentLevelBooks?.length) {
      const topicFiltered = currentLevelBooks?.filter((e) => {
        if (selectedTopic.value === 'all') {
          return true;
        } else {
          return e.category1 === selectedTopic.value || e.category2 === selectedTopic.value || e.category3 === selectedTopic.value;
        }
      });

      setFilteredbook(topicFiltered);
    }
  }, [selectedTopic, currentLevelBooks]);

  const chunkData = useMemo(() => {
    // let sortedList = _.sortBy(filteredbook, sortby === 'title' ? 'title' : 'lexile_val').reverse();
    let sortedList = _.sortBy(filteredbook, 'title');
    if (sortby === 'highlevel') {
      sortedList = _.sortBy(sortedList.reverse(), 'lexile_val').reverse();
    }
    if (sortby === 'lowlevel') {
      sortedList = _.sortBy(sortedList, 'lexile_val');
    }
    const tmp = [];
    // if (selectedTopic !== 'all') {
    //   const filtered = books.filter((e) => e.topic === selectedTopic);
    //   for (let i = 0; i < filtered.length; i += 5) {
    //     const chunk = filtered.slice(i, i + 5);
    //     tmp.push(chunk);
    //   }
    // } else {
    // sortedList = sortby === 'lowlevel' ? sortedList.reverse() : sortedList;

    if (sortby === 'fiction' || sortby === 'nonfiction') {
      sortedList = sortedList.filter((ele) => ele?.genre === (sortby === 'fiction' ? 'Fiction' : 'Non-Fiction'));
    }

    for (let i = 0; i < sortedList.length; i += 5) {
      //  const chunk = (sortby === 'lowlevel' ? sortedList.reverse() : sortedList).slice(i, i + 5);
      let chunk = sortedList.slice(i, i + 5);
      tmp.push(chunk);
    }
    // }
    return tmp;
  }, [
    filteredbook,
    sortby,
    // , selectedTopic,
  ]);
  const getHandleData = () => {
    refetch();
  };
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = () => {
    if(searchValue.length>1)
    {history.push(`/level-up/search?myLevel=${currentLevel === 'PH' ? 0 : parseInt(currentLevel)}&keyword=${searchValue}`);}
    else{
      alert('Please write a search term of at least 2 characters.')
    }
  };
  const sortList = useMemo(() => {
    if (level === -1) {
      return [
        { title: 'Lowest', value: 'lowlevel' },
        { title: 'Highest', value: 'highlevel' },
        { title: 'A to Z', value: 'title' },
      ];
    } else
      return [
        { title: 'Lowest', value: 'lowlevel' },
        { title: 'Highest', value: 'highlevel' },
        { title: 'A to Z', value: 'title' },
        { title: 'Fiction', value: 'fiction' },
        { title: 'Non-Fiction', value: 'nonfiction' },
      ];
  }, [level]);
  return (
    <Wrapper>
      <Header>
        <div
          onClick={() => {
            history.push('/level-up');
          }}
          style={{ cursor: 'pointer' }}
        >
          {' '}
          <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} alt="level up logo" />
        </div>
        <HeadBox
          background="transparent"
          color={'#fff'}
          style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px', top: '15px', alignItems: 'center' }}
        >
          <FullScreenButton
            element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
            activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
          />
          <SearchInput
            onChange={handleInputChange}
            onPressEnter={handleSearch}
            allowClear
            enterButton
            bordered={false}
            placeholder="Search"
            suffix={<SearchOutlined onClick={handleSearch} style={{ border: 'none', fontSize: '15px' }} />}
          />
          <span style={{ color: '#999', marginLeft: 5, fontSize: '15px' }}>
            <span className="username">
            {userData?.name.length>5?`${userData?.name.slice(0,5)}...`:userData?.name} <Text strong>({userData?.id.length>9?`${userData?.id.slice(0,9)}...`:userData?.id})</Text>
            </span>
          </span>
          <CloseButton
            onClick={() => {
              history.push('/');
            }}
          >
            <PoweroffOutlined />
          </CloseButton>
        </HeadBox>
      </Header>
      <MainWrapper>
        <LeftMenu level={level} setLevel={setLevel} isLibrary={true} myLevel={parseInt(userInfoVar()?.type) !== 0?-2:currentLevel === 'PH' ? 0 : parseInt(currentLevel)} isStudent={parseInt(userInfoVar()?.type)===0}/>
        <RightBox>
          <ColumnFlex style={{ width: '100%' }}>
            <RowFlex style={{ padding: '0 0 5px', marginBottom: '10px' }}>
              <ArrowBackGround style={{ marginRight: '-20px' }}>
                <LeftArrow onClick={topicLeft} />
              </ArrowBackGround>
              <TopicList ref={topicRef}>
                {topics.map((e, i) => (
                  <TopicButton
                    key={i}
                    onClick={() => handleSelectTopic({ value: e.value, no: e.no })}
                    active={selectedTopic.value === e.value}
                    value={e.value}
                  >
                    {e.title}
                  </TopicButton>
                ))}
              </TopicList>
              <ArrowBackGround style={{ marginLeft: '-20px' }}>
                <RightArrow onClick={topicRight} />
              </ArrowBackGround>
              <SelectBox value={sortby} onChange={handleSort} dropdownStyle={{ borderRadius: '15px' }} bordered={false}>
                {sortList ? (
                  sortList.map((e, i) => (
                    <SelectOption key={i} value={e.value}>
                      {e.title}
                    </SelectOption>
                  ))
                ) : (
                  <></>
                )}
              </SelectBox>
            </RowFlex>
            <BookWrapper ref={bookListRef}>
              {loading && <Spin style={{ marginLeft: '10px' }} size="small" />}
              {filteredbook.length === 0 ? (
                <>No books for this topic.</>
              ) : (
                chunkData.map((chunk, j) => (
                  <ColumnFlex key={j}>
                    <RowFlex style={{ justifyContent: 'flex-start', padding: `0 ${width>=0.8?'20px': '10px'}`, alignItems: 'end' }}>
                      {chunk.map((e, i) => (
                        <CarouselItem key={i} data={e} margin={'0 13px'} handleFav={getHandleData} />
                      ))}
                    </RowFlex>
                    <BottomShadow />
                  </ColumnFlex>
                ))
              )}
            </BookWrapper>
          </ColumnFlex>
        </RightBox>
      </MainWrapper>
    </Wrapper>
  );
}
