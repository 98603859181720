import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { Input, Form, DatePicker, Typography, Checkbox, Radio, Select, TimePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FormField from 'src/components/common/FormField';
import moment from 'moment';
import { SurveyWrapper } from 'src/popups/LevelTestAppForm/LevelTestStyledComp';
import { userInfoVar } from 'src/apollo/cache';
import Schema from 'async-validator';
import CheckboxDropdownComponent, { createStyles } from 'react-checkbox-dropdown';

const { Option } = Select;
const options = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Satuarday', 'Sunday'].map((item) => ({
  value: item,
  label: item,
}));

const UpdateMetaScheduleForm = ({ scheduleData, form, onCreate, closeForm, scheduledata }) => {
  // console.log('userInfo----', scheduleData);
  const [beginTime, setBeginTime] = useState();
  const [endTime, setEndTime] = useState();
  const [days, setDays] = useState();
  const [days1, setDays1] = useState([]);
  const [sysNo, setSysNo] = useState();
  const [sysNo1, setSysNo1] = useState();

  const [beginTimeError, setBeginTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [daysError, setDaysError] = useState(false);
  const [daysError1, setDaysError1] = useState(false);
  const [sysNoError, setSysNoError] = useState(false);

  const [campus_id, setCampusId] = useState();
  const { campus_idx } = userInfoVar();

  form.setFieldsValue({
    days1: scheduleData?.days ? scheduleData?.days : undefined,
    no_of_system: scheduleData?.no_of_system ? scheduleData?.no_of_system : undefined,
    start_time: scheduleData?.start_time ? moment(scheduleData?.start_time, 'HH:mm') : '',
    end_time: scheduleData?.end_time ? moment(scheduleData?.end_time, 'HH:mm') : '',
    // start_time: scheduleData?.start_time ? scheduleData?.start_time : undefined,
    // end_time: scheduleData?.end_time ? scheduleData?.end_time : undefined
  });

  useEffect(() => {
    if (scheduleData?.days) {
      const dayres = scheduleData.days.split(',');
      var daylist = [];
      for (let i = 0; i < dayres.length; i++) {
        var daydropdown = { value: dayres[i], label: dayres[i] };
        daylist.push(daydropdown);
      }
      setDays1(daylist);
    }
    if (scheduleData?.no_of_system) {
      console.log('scheduleData===', scheduleData?.no_of_system);
    }
  }, [scheduleData]);

  useEffect(() => {
    // setDays1(scheduleData?.days);
    //console.log("scheduleData?.no_of_system",(scheduleData?.no_of_system). toString())
    // const system_no = ((scheduleData?.no_of_system).toString())
    setSysNo(scheduleData?.no_of_system);
    setBeginTime(scheduleData?.start_time);
    setEndTime(scheduleData?.end_time);
  }, [scheduleData?.no_of_system]);

  useEffect(() => {
    setSysNo(sysNo1);
  }, [sysNo1]);

  useEffect(() => {
    setCampusId(campus_idx);
  }, [campus_idx]);

  function onTimeChange(name, timeString) {
    if (name == 'start_time') {
      scheduleData['start_time'] = timeString;
      setBeginTime(timeString);
    }
    if (name == 'end_time') {
      scheduleData['end_time'] = timeString;
      setEndTime(timeString);
    }

    //
    //   form.setFieldsValue({
    // start_time: scheduleData?.start_time ? moment(scheduleData?.start_time, 'HH:mm') : '',
    // end_time: scheduleData?.end_time ? moment(scheduleData?.end_time, 'HH:mm') : '',
    //   })
  }
  useEffect(() => {
    if (closeForm == true) {
      setBeginTimeError(false);
      setEndTimeError(false);
      setDaysError(false);
      setSysNoError(false);
      setDaysError1(false);
    }
  }, [closeForm]);

  const handleEditSubmit = (values) => {
    console.log('handle submit', scheduleData?.no_of_system);
    const variables = {};
    if (beginTime == ' ' || beginTime == undefined) {
      setBeginTimeError(true);
    }
    // if (endTime == " " || endTime == undefined) {
    //   setEndTimeError(true)
    // }
    // if (days == " " || days == undefined) {
    // setDaysError(true)
    // }
    // if (days1.length == 0 || days1.length == undefined) {
    //   setDaysError1(true)
    // }
    // if (sysNo == " " || sysNo == undefined) {
    //   setSysNoError(true)
    // }
    // if (beginTime && endTime && days && sysNo) {
    // console.log("beginTime",sysNo);
    // return
    if (beginTime && endTime && sysNo) {
      variables['idx'] = scheduleData?.idx;
      variables['start_time'] = beginTime;
      variables['end_time'] = endTime;
      // variables['days'] = days
      variables['days'] = JSON.stringify(days1);
      // variables['no_of_system'] = sysNo.toString();
      variables['no_of_system'] = parseInt(sysNo);
      variables['campus_idx'] = campus_id;
      //   variables['meta_schedule'] = JSON.stringify(scheduledata)
      onCreate({ variables });
      return false;
    }
  };
  useEffect(() => {
    Schema.warning = function () {};
  }, []);

  const handleSys = (e) => {
    scheduleData['no_of_system'] = parseInt(e.target.value);
    setSysNo(e.target.value);
  };
  return (
    <>
      <div className="update-schedule">
        <Form name="control-ref" layout="inline" form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
          <Form.Item
            style={{ display: 'none' }}
            name="campus_idx"
            rules={[
              {
                required: true,
                message: '학원을 선택해 주세요.',
              },
            ]}
          >
            <Input type="hidden" name="campus_idx" />
          </Form.Item>
          <div className={days1.length == 0 ? 'unfill-day day-dropdown' : 'fill-day day-dropdown'} disabled={true}>
            <FormField hasFeedback={true} title="요일 선택">
              <Form.Item>
                <CheckboxDropdownComponent
                  displayText="요일 선택"
                  // options={options}
                  // onChange={(option) => {
                  //   if (!days1.includes(option)) {
                  //     const newValue = [...days1, option];
                  //     setDays1(newValue);
                  //   }
                  // }}
                  // onDeselectOption={(option) => {
                  //   const filteredOptions = days1.filter((item) => item.value !== option.value);
                  //   setDays1(filteredOptions);
                  // }}
                  value={days1}
                  displayTags
                  isStrict={false}
                />
                {daysError1 ? <label style={{ color: 'red' }}>요일을 선택하세요</label> : ''}
              </Form.Item>
            </FormField>
          </div>
          <FormField hasFeedback={true} title="시스템 수" value="dsfdsfdsgdsgdfgfh">
            <Form.Item name="no_of_system">
              {/* <Input placeholder="시스템 수" size="large" name="no_of_system" onChange={(e) => (setSysNoError(false))}></Input> */}
              <Input placeholder="시스템 수" size="large" name="no_of_system" onChange={(e) => handleSys(e)}></Input>
            </Form.Item>
            {sysNoError ? <label style={{ color: 'red' }}>시스템 번호를 입력하십시오</label> : ''}
          </FormField>

          <FormField hasFeedback={true} title="시작 시간">
            <FormItem name="start_time" hasFeedback style={{ width: '90%' }}>
              <TimePicker
                name="start_time"
                onChange={(_, t) => (onTimeChange('start_time', t), setBeginTimeError(false))}
                placeholder="00:00"
                format={'HH:mm'}
                disabled={true}
              />
            </FormItem>
            {beginTimeError ? <label style={{ color: 'red' }}>시작 시간을 선택하세요</label> : ''}
          </FormField>

          <FormField hasFeedback={true} title="종료 시간">
            <FormItem name="end_time" hasFeedback style={{ width: '90%' }}>
              <TimePicker
                name="end_time"
                onChange={(_, t) => (onTimeChange('end_time', t), setEndTimeError(false))}
                placeholder="00:00"
                format={'HH:mm'}
                disabled={true}
              />
            </FormItem>
            {endTimeError ? <label style={{ color: 'red' }}>종료 시간을 선택하세요</label> : ''}
          </FormField>
        </Form>
        <div className="ant-modal-footer cust-footer">
          <Button key="submit" type="primary" onClick={() => handleEditSubmit()}>
            닫기
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpdateMetaScheduleForm;
