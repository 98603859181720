import React, { useRef, useMemo, useEffect } from 'react';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
/**
 *
 * @param {userAnswer, correctCheck,handleScore,resultData}
 * userAnswer = arr. 유저가 적은 답
 * correctCheck = arr. key에 맞게 기본은 undefined로, 각 문제를 채점을 했는지 여부를 확인하기 위해서 사용. true, false가 전달.
 * 만약에 한번에 채점이 되기를 바란다면, score를 인자로 다시 전달 받아서 사용하도록 구성하면 됨. score는 모두 푼 다음에만 undefined이 아니게 생성되기 때문.
 */
const UserAnswerListBox = ({ userAnswer, correctCheck, handleScore, score, resultData, problem, showAnswer, problemNumber }) => {
  const scrollRef = useRef(null);
  const handleDisplayScore = () => {
    handleScore('true');
  };
  const currnetAnswer = useMemo(() => {
    return userAnswer ? userAnswer.map((a) => a) : [];
  }, [userAnswer, problemNumber]);
  useEffect(() => {
    if (scrollRef.current) {
      const vhToPixels = (vh) => (vh * window.innerHeight) / 100;
      scrollRef.current.scrollTo({
        top: problemNumber * vhToPixels(11),
        behavior: 'smooth',
      });
    }
  }, [problemNumber]);
  // console.log(correctCheck, 'correctCheck');
  // console.log(problem, 'problem');
  // console.log(currnetAnswer, 'an');
  return (
    <>
      <S.TestAnswerArea ref={scrollRef} className="answer_area" id="answer_area_scroll">
        {currnetAnswer.length &&
          currnetAnswer.map((item, key) => (
            <S.TestAnswer key={`user-answer-list-${key} ${_.isNil(correctCheck[key]) ? '' : correctCheck[key] ? 'pass' : 'fail'}`}>
              <S.TestAnswerNum className={`answer_num ${_.isNil(correctCheck[key]) ? '' : correctCheck[key] ? 'pass' : 'fail'}`}>
                {key + 1}
              </S.TestAnswerNum>
              <S.TestAnswerTxt className={`answer_txt `}>
                {correctCheck[key] === false && `${item} : `}{' '}
                <span style={{ color: !correctCheck[key] && 'red' }}>{item === '' ? '' : problem[key]['word']}</span>
              </S.TestAnswerTxt>
              {/* <S.TestAnswerTxt style={{color:'000'}} className={`answer_txt `}>{`${item} ${correctCheck[key] || _.isNil(correctCheck[key]) || !showAnswer ? "" : ": " + problem[key]["word"]}`}</S.TestAnswerTxt> */}
            </S.TestAnswer>
          ))}
      </S.TestAnswerArea>
      {/* <S.TestScoreBtn
        onClick={handleDisplayScore}
        finished={score !== undefined && resultData == true}
        className={score !== undefined && resultData == true ? 'show-score-button' : 'disable-score-button'}
      >
        학습 완료
      </S.TestScoreBtn> */}
    </>
  );
};

export default UserAnswerListBox;
