import React, { useMemo, useRef } from 'react';
import { Form, Input, Button, Modal, Typography, Radio, Row, Col, Select, Checkbox } from 'antd';
import { PrinterTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import * as queries from 'src/operations/queries';

const { Title } = Typography;
const { Option } = Select;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const PrintWrapper = styled.div`
  width: 100%;
  @media print {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const PrintBanner = styled.div`
  display: none;
  @media print {
    display: block;
    background-image: url(/images/lt-app-form-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #eee;
  }
`;

function CreoSurveyModal({ handleCancel, visible, orderIdx, surVeyIdx }) {
  const printAreaComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printAreaComponentRef.current,
  });

  const { data: fetchOrder } = useQuery(queries.order.GET_ORDER_ITEM2, {
    skip: !orderIdx,
    variables: { idx: parseInt(orderIdx) },
  });

  const orderInfo = useMemo(() => {
    if (fetchOrder) {
      const order = { ...fetchOrder.orderItemByIdx };
      return {
        idx: order.idx,
        name: order.name,
        parent_phone: order.parent_phone,
        school_grade: order.user.school_grade,
        school_name: order.campus.name,
        school_info: order.school_info,
        idate: order.idate,
      };
    }
    return {};
  }, [fetchOrder]);

  const orderSurveyAnswerList = useMemo(() => {
    if (fetchOrder) {
      if (fetchOrder.orderItemByIdx?.order_survey_answer) {
        const orderSurveyAnswers = fetchOrder.orderItemByIdx?.order_survey_answer.reduce((acc, cur) => {
          const { answer, memo } = cur;
          if (acc[cur.no]) {
            acc[cur.no].push({ answer, memo });
          } else {
            acc[cur.no] = [{ answer, memo }];
          }

          return { ...acc };
        }, {});
        return orderSurveyAnswers;
      }
    }
    return {};
  }, [fetchOrder]);

  //console.log('orderSurveyAnswerList', orderSurveyAnswerList, orderSurveyAnswerList[2][0]);

  if (!orderSurveyAnswerList[1]) {
    return false;
  }

  return (
    <>
      <Modal
        wrapClassName="survey-modal"
        style={{ top: 20 }}
        visible={visible}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 style={{ paddingTop: '20px', paddingBottom: '10px', color: '#efefef' }}>입학상담설문</h2>
            <Button
              onClick={handlePrint}
              style={{ marginTop: 20, marginRight: 100, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}
            >
              <PrinterTwoTone />
              Print
            </Button>
          </div>
        }
        onCancel={handleCancel}
        width={'50%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <PrintWrapper ref={printAreaComponentRef} className="survey-print-wrapper">
          <PrintBanner>
            <h2 style={{ paddingTop: '25px', paddingBottom: '25px', color: '#efefef', textAlign: 'center' }}>입학상담설문</h2>
          </PrintBanner>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CustomTable
                pagination={false}
                bordered
                style={{ width: '100%', marginBottom: 24 }}
                dataSource={[
                  {
                    key: 1,
                    school_name: orderInfo.school_name,
                    school_grade: orderInfo.school_grade,
                    school_info: orderInfo.school_info,
                    name: orderInfo.name,
                    parent_phone: orderInfo.parent_phone,
                  },
                ]}
                columns={columns}
                scroll={{ y: 'calc(100vh - 254px)' }}
                size="small"
                color="#edf3fb"
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>1.과거에 영어를 배운 경험이 있다.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey1" value={orderSurveyAnswerList[1][0]?.answer}>
                <Radio style={radioStyle} value={"1"}>있다</Radio>
                <Radio style={radioStyle} value={"2"}>없다</Radio>
              </Radio.Group>
            </Col>
          </Row>
          {orderSurveyAnswerList[1][0]?.answer === '1' && (
          <>
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>만약 영어 경험이 있으면 :</Title>
            </Col>
            <Col>
              <Radio.Group name="survey2" value={orderSurveyAnswerList[2][0]?.answer}>
                <Radio style={radioStyle} value={"1"}>어학원</Radio>
                <Radio style={radioStyle} value={"2"}>보습학원</Radio>
                <Radio style={radioStyle} value={"3"}>공부방</Radio>
                <Radio style={radioStyle} value={"4"}>개인지도</Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>만약 영어 경험이 있으면 배운 기간은 :</Title>
            </Col>
            <Col>
              
                        <Input
                          value={orderSurveyAnswerList[3] ? orderSurveyAnswerList[3][0]?.answer : ''}
                          style={{ display: 'inline-block', width: '10%', marginLeft: '10px' }}
                          readOnly
                        />&nbsp; 년

                        <Input
                          value={orderSurveyAnswerList[4] ? orderSurveyAnswerList[4][0]?.answer : ''}
                          style={{ display: 'inline-block', width: '10%', marginLeft: '10px' }}
                          readOnly
                        />&nbsp; 개월
            </Col>
          </Row>
          </>
        )}
          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>2. 학생은 해외 경험이 있다.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey5" value={orderSurveyAnswerList[5][0]?.answer}>
                <Radio style={radioStyle} value={"1"}>있다</Radio>
                <Radio style={radioStyle} value={"2"}>없다</Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>3. 중학교 과정을 배워도 문제 없다.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey6" value={orderSurveyAnswerList[6][0]?.answer}>
                <Radio style={radioStyle} value={"1"}>있다</Radio>
                <Radio style={radioStyle} value={"2"}>없다</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </PrintWrapper>
      </Modal>
    </>
  );
}

const columns = [
  {
    title: '학원',
    dataIndex: 'school_name',
    key: 'school_name',
    align: 'center',
  },
  {
    title: '학교/학년',
    dataIndex: 'school_info',
    key: 'school_info',
    align: 'center',
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '학부모 연락처',
    dataIndex: 'parent_phone',
    key: 'parent_phone',
    align: 'center',
  },
];

export default CreoSurveyModal;
