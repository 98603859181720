import React from 'react';
import { useParams } from 'react-router-dom';

import { SyllabusMain, SyllabusAdd, SyllabusEdit, SyllabusAssigned, SyllabusAssignedDetail } from 'src/components/Test';

const Syllabus = (props) => {
  const { type = 'main', id } = useParams();

  const getSwitchableRoute = (type, id = null) => {
    switch (type) {
      case 'main':
        return <SyllabusMain />;
      case 'add':
        return <SyllabusAdd />;
      case 'edit':
        return <SyllabusEdit />;
      case 'assigned':
        return id ? <SyllabusAssignedDetail /> : <SyllabusAssigned />;
      default:
        return <SyllabusMain />;
    }
  };

  return (
    <>
      {getSwitchableRoute(type, id)}
      {/* {type === 'main' ? <SyllabusMain /> : type === 'add' ? <SyllabusAdd /> : <SyllabusAssigned />} */}
    </>
  );
};

export default Syllabus;
