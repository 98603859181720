import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import MenuItemAddModal from 'src/components/Modal/MenuItemAddModal';
import MenuItemEditModal from 'src/components/Modal/MenuItemEditModal';
import MenuItemDeleteModal from 'src/components/Modal/MenuItemDeleteModal';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, EditOutlined, DeleteOutlined, CaretRightOutlined } from '@ant-design/icons';
import { openNotification } from '../common/Notification';
import * as mutations from 'src/operations/mutations';

const { Title } = Typography;

function MenuSettingModal({ visible, handleCancel, fcInfo }) {
  const userTypeOptions = [
    { value: '0', label: '학생' },
    { value: '1', label: '강사' },
    { value: '2', label: '원장' },
    { value: '3', label: '지사장' },
    { value: '4', label: '본사' },
    { value: '6', label: '학부모' },
  ];

  const [selectedUserType, setSelecteUserType] = useState();
  const [selectedMenuId, setSelectedMenuId] = useState({});
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const [level1DataSource, setLevel1DataSource] = useState([]);
  const [level1Items, setLevel1Items] = useState([]);
  const [level2DataSource, setLevel2DataSource] = useState([]);
  const [level2Items, setLevel2Items] = useState([]);
  const [level3DataSource, setLevel3DataSource] = useState([]);
  const [level3Items, setLevel3Items] = useState([]);
  const [level1RowSelect, setLevel1RowSelect] = useState();
  const [level2RowSelect, setLevel2RowSelect] = useState();
  const [level1SelectedItem, setLevel1SelectedItem] = useState();
  const [level2SelectedItem, setLevel2SelectedItem] = useState();
  const { visible: addVisible, showModal: showAddModal, handleCancel: handleAddCancel } = useModal();
  const { visible: editVisible, showModal: showEditModal, handleCancel: handleEditCancel } = useModal();
  const { visible: deleteVisible, showModal: showDeleteModal, handleCancel: handleDeleteCancel } = useModal();
  const [routeItems, setRouteItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [nextIndex, setNextIndex] = useState({});
  const [parentId, setParentId] = useState({});
  const [userType, setUserType] = useState();

  const [fetchMenuList, { data: companyUserMenuListData, loading: companyUserMenuListLoading }] = useLazyQuery(
    queries.company.GET_COMPANY_USER_MENU_LIST,
  );

  const { data: routeItemListData, loading: routeItemListLoading } = useQuery(queries.routeItem.GET_ROUTE_ITEM_LIST, {});

  const [createCompanyDefaultMenus, { loading: createCompanyDefaultMenusLoading }] = useMutation(
    mutations.company.CREATE_COMPANY_DEFAULT_MENUS,
    {
      refetchQueries: [
        {
          query: queries.company.GET_COMPANY_USER_MENU_LIST,
          variables: {
            company_idx: fcInfo.idx,
            user_type: selectedUserType,
          },
        },
      ],
    },
  );

  useEffect(() => {
    if (routeItemListData?.routeItemList) {
      setRouteItems(routeItemListData?.routeItemList);
    }
  }, [routeItemListData]);

  const onUserTypeChange = (e) => {
    setUserType(e.target.value);
    setSelecteUserType(e.target.value);
    setLevel1RowSelect();
    setLevel1SelectedItem();
    setLevel2RowSelect();
    setLevel2SelectedItem();
  };

  useEffect(() => {
    fetchMenuList({
      variables: {
        company_idx: fcInfo.idx,
        user_type: selectedUserType,
      },
    });
    setSelectedMenuId(companyUserMenuListData?.companyUserMenuList[0]?.idx);
    setSelectedMenuItems(companyUserMenuListData?.companyUserMenuList[0]?.company_user_menu_item);
  }, [fetchMenuList, fcInfo, selectedUserType, companyUserMenuListData]);

  useEffect(() => {
    if (selectedUserType) {
      setLevel1DataSource(selectedMenuItems?.filter((item) => item.parent_id === null).sort((a, b) => a.index - b.index));
    } else {
      setLevel1DataSource([]);
    }
    if (level1SelectedItem) {
      setLevel2DataSource(selectedMenuItems?.filter((item) => item.parent_id === level1SelectedItem).sort((a, b) => a.index - b.index));
    } else {
      setLevel2DataSource([]);
    }
    if (level2SelectedItem) {
      setLevel3DataSource(selectedMenuItems?.filter((item) => item.parent_id === level2SelectedItem).sort((a, b) => a.index - b.index));
    } else {
      setLevel3DataSource([]);
    }
  }, [selectedUserType, selectedMenuItems, level1SelectedItem, level2SelectedItem]);

  const handleL1TableRowClick = (selected) => {
    setLevel1RowSelect(selected.index);
    setLevel1SelectedItem(selected.idx);
    setLevel2DataSource(selectedMenuItems.filter((item) => item.parent_id === selected.idx).sort((a, b) => a.index - b.index));
    setLevel2RowSelect();
    setLevel2SelectedItem();
  };

  const handleL2TableRowClick = (selected) => {
    setLevel2RowSelect(selected.index);
    setLevel2SelectedItem(selected.idx);
    setLevel3DataSource(selectedMenuItems.filter((item) => item.parent_id === selected.idx).sort((a, b) => a.index - b.index));
  };

  const addMenuItemClick = (level) => {
    if (!level) return;
    if (level === 1) {
      setParentId(null);
      setNextIndex(level1DataSource?.length + 1);
    } else if (level === 2) {
      setParentId(level1SelectedItem);
      setNextIndex(level2DataSource?.length + 1);
    } else if (level === 3) {
      setParentId(level2SelectedItem);
      setNextIndex(level3DataSource?.length + 1);
    }
    showAddModal();
  };

  const editMenuItemClick = (menuItem) => {
    setSelectedItem(menuItem);
    showEditModal();
  };

  const deleteMenuItemClick = (menuItem) => {
    setSelectedItem(menuItem);
    showDeleteModal();
  };

  const [updateCompanyUserMenuItemList, { loading: updateCompanyUserMenuItemListLoading }] = useMutation(
    mutations.company.UPDATE_COMPANY_USER_MENU_ITEM_LIST,
    {
      refetchQueries: [
        {
          query: queries.company.GET_COMPANY_USER_MENU_LIST,
          variables: {
            company_idx: fcInfo.idx,
            user_type: selectedUserType,
          },
        },
      ],

      onCompleted(data) {
        if (data) {
          openNotification('메뉴 항목이 업데이트되었습니다!');
          //handleCancel();
        }
      },
      onError(error) {
        console.log('error', error);
      },
    },
  );

  const handleSave = () => {
    updateCompanyUserMenuItemList({
      variables: {
        menu_items: [].concat(level1DataSource, level2DataSource, level3DataSource),
      },
    });
  };

  const createDefaultMenuClick = () => {
    createCompanyDefaultMenus({
      variables: {
        company_idx: fcInfo.idx,
      },
    });
    setSelecteUserType();
    setUserType('');
  };

  const DragHandleL1 = sortableHandle(({ active }) => <MenuOutlined style={{ cursor: 'grab', color: active ? 'blue' : '#999' }} />);
  const SortableItemL1 = sortableElement((props) => <tr {...props} />);
  const SortableContainerL1 = sortableContainer((props) => <tbody {...props} />);

  const DragHandleL2 = sortableHandle(({ active }) => <MenuOutlined style={{ cursor: 'grab', color: active ? 'blue' : '#999' }} />);
  const SortableItemL2 = sortableElement((props) => <tr {...props} />);
  const SortableContainerL2 = sortableContainer((props) => <tbody {...props} />);

  const DragHandleL3 = sortableHandle(({ active }) => <MenuOutlined style={{ cursor: 'grab', color: active ? 'blue' : '#999' }} />);
  const SortableItemL3 = sortableElement((props) => <tr {...props} />);
  const SortableContainerL3 = sortableContainer((props) => <tbody {...props} />);

  const getColumnsL1 = () => {
    return [
      {
        title: '분류',
        dataIndex: '',
        width: 50,
        className: 'drag-visible',
        render: (d, dd, i) => (
          <>
            <DragHandleL1 active={level1Items.includes(i)} />
          </>
        ),
      },
      {
        title: '항목명',
        dataIndex: 'item_name',
        key: 'item_name',
        align: 'center',
        className: 'drag-visible',
      },
      {
        title: '운영',
        dataIndex: 'parent_id',
        key: 'parent_id',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              <span>
                <Button
                  onClick={() => {
                    editMenuItemClick(record);
                  }}
                  size="small"
                  icon={<EditOutlined />}
                />
              </span>
              &nbsp;
              <span>
                <Button
                  onClick={() => {
                    deleteMenuItemClick(record);
                  }}
                  size="small"
                  icon={<DeleteOutlined />}
                />
              </span>
              {record ? (
                <>
                  &nbsp;
                  <span>
                    <Button
                      onClick={(e) => {
                        handleL1TableRowClick(record);
                      }}
                      size="small"
                      icon={<CaretRightOutlined />}
                    />
                  </span>
                </>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];
  };

  const getColumnsL2 = () => {
    return [
      {
        title: '분류',
        dataIndex: '',
        width: 50,
        className: 'drag-visible',
        render: (d, dd, i) => (
          <>
            <DragHandleL2 active={level2Items.includes(i)} />
          </>
        ),
      },
      {
        title: '항목명',
        dataIndex: 'item_name',
        key: 'item_name',
        align: 'center',
        className: 'drag-visible',
      },
      {
        title: '운영',
        dataIndex: 'parent_id',
        key: 'parent_id',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              <span>
                <Button
                  onClick={() => {
                    editMenuItemClick(record);
                  }}
                  size="small"
                  icon={<EditOutlined />}
                />
              </span>
              &nbsp;
              <span>
                <Button
                  onClick={() => {
                    deleteMenuItemClick(record);
                  }}
                  size="small"
                  icon={<DeleteOutlined />}
                />
              </span>
              {record ? (
                <>
                  &nbsp;
                  <span>
                    <Button
                      onClick={() => {
                        handleL2TableRowClick(record);
                      }}
                      size="small"
                      icon={<CaretRightOutlined />}
                    />
                  </span>
                </>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];
  };

  const getColumnsL3 = () => {
    return [
      {
        title: '분류',
        dataIndex: '',
        width: 50,
        className: 'drag-visible',
        render: (d, dd, i) => (
          <>
            <DragHandleL3 active={level3Items.includes(i)} />
          </>
        ),
      },
      {
        title: '항목명',
        dataIndex: 'item_name',
        key: 'item_name',
        align: 'center',
        className: 'drag-visible',
      },
      {
        title: '운영',
        dataIndex: 'parent_id',
        key: 'parent_id',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              <span>
                <Button
                  onClick={() => {
                    editMenuItemClick(record);
                  }}
                  size="small"
                  icon={<EditOutlined />}
                />
              </span>
              &nbsp;
              <span>
                <Button
                  onClick={() => {
                    deleteMenuItemClick(record);
                  }}
                  size="small"
                  icon={<DeleteOutlined />}
                />
              </span>
            </>
          );
        },
      },
    ];
  };

  const merge = (a, b, i = 0) => {
    let aa = [...a];
    return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
  };

  const onL1SortEnd = ({ oldIndex, newIndex }) => {
    let tempDataSource = level1DataSource;

    if (oldIndex !== newIndex) {
      if (!level1Items.length) {
        let movingItem = tempDataSource[oldIndex];
        tempDataSource.splice(oldIndex, 1);
        tempDataSource = merge(tempDataSource, [movingItem], newIndex);
      } else {
        let filteredItems = [];
        level1Items.forEach((d) => {
          filteredItems.push(tempDataSource[d]);
        });
        let newData = [];
        tempDataSource.forEach((d, i) => {
          if (!level1Items.includes(i)) {
            newData.push(d);
          }
        });
        tempDataSource = [...newData];
        tempDataSource = merge(tempDataSource, filteredItems, newIndex);
      }
      setLevel1DataSource(
        tempDataSource.map((item, i) => {
          return { ...item, index: i + 1 };
        }),
      );
      setLevel1Items([]);
    }
  };

  const onL2SortEnd = ({ oldIndex, newIndex }) => {
    let tempDataSource = level2DataSource;

    if (oldIndex !== newIndex) {
      if (!level2Items.length) {
        let movingItem = tempDataSource[oldIndex];
        tempDataSource.splice(oldIndex, 1);
        tempDataSource = merge(tempDataSource, [movingItem], newIndex);
      } else {
        let filteredItems = [];
        level2Items.forEach((d) => {
          filteredItems.push(tempDataSource[d]);
        });
        let newData = [];
        tempDataSource.forEach((d, i) => {
          if (!level2Items.includes(i)) {
            newData.push(d);
          }
        });
        tempDataSource = [...newData];
        tempDataSource = merge(tempDataSource, filteredItems, newIndex);
      }
      setLevel2DataSource(
        tempDataSource.map((item, i) => {
          return { ...item, index: i + 1 };
        }),
      );
      setLevel2Items([]);
    }
  };

  const onL3SortEnd = ({ oldIndex, newIndex }) => {
    let tempDataSource = level3DataSource;

    if (oldIndex !== newIndex) {
      if (!level3Items.length) {
        let movingItem = tempDataSource[oldIndex];
        tempDataSource.splice(oldIndex, 1);
        tempDataSource = merge(tempDataSource, [movingItem], newIndex);
      } else {
        let filteredItems = [];
        level3Items.forEach((d) => {
          filteredItems.push(tempDataSource[d]);
        });
        let newData = [];
        tempDataSource.forEach((d, i) => {
          if (!level3Items.includes(i)) {
            newData.push(d);
          }
        });
        tempDataSource = [...newData];
        tempDataSource = merge(tempDataSource, filteredItems, newIndex);
      }
      setLevel3DataSource(
        tempDataSource.map((item, i) => {
          return { ...item, index: i + 1 };
        }),
      );
      setLevel3Items([]);
    }
  };

  const DraggableContainerL1 = (props) => (
    <SortableContainerL1 useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onL1SortEnd} {...props} />
  );

  const DraggableBodyRowL1 = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = level1DataSource?.findIndex((x) => x.index === restProps['data-row-key']);
    return (
      <SortableItemL1
        index={index}
        {...restProps}
        selected={level1Items.length}
        onClick={(e) => {
          if (e.ctrlKey || e.metaKey) {
            level1Items.includes(index) ? level1Items.splice(level1Items.indexOf(index), 1) : level1Items.push(index);
            setLevel1Items(level1Items);
          } else {
            setLevel1Items([]);
          }
        }}
        className={className}
      />
    );
  };

  const DraggableContainerL2 = (props) => (
    <SortableContainerL2 useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onL2SortEnd} {...props} />
  );

  const DraggableBodyRowL2 = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = level1DataSource?.findIndex((x) => x.index === restProps['data-row-key']);
    return (
      <SortableItemL2
        index={index}
        {...restProps}
        selected={level2Items.length}
        onClick={(e) => {
          if (e.ctrlKey || e.metaKey) {
            level2Items.includes(index) ? level2Items.splice(level2Items.indexOf(index), 1) : level2Items.push(index);
            setLevel2Items(level2Items);
          } else {
            setLevel2Items([]);
          }
        }}
        className={className}
      />
    );
  };

  const DraggableContainerL3 = (props) => (
    <SortableContainerL3 useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onL3SortEnd} {...props} />
  );

  const DraggableBodyRowL3 = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = level3DataSource?.findIndex((x) => x.index === restProps['data-row-key']);
    return (
      <SortableItemL3
        index={index}
        {...restProps}
        selected={level3Items.length}
        onClick={(e) => {
          if (e.ctrlKey || e.metaKey) {
            level3Items.includes(index) ? level3Items.splice(level3Items.indexOf(index), 1) : level3Items.push(index);
            setLevel3Items(level3Items);
          } else {
            setLevel3Items([]);
          }
        }}
        className={className}
      />
    );
  };

  return (
    <>
      <Modal
        entered
        title={`${fcInfo.name} 메뉴 관리`}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleSave}
        okText="확인"
        cancelText="취소"
        width={'100%'}
        loading={updateCompanyUserMenuItemListLoading}
      >
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <select
              name="userType"
              value={userType}
              style={{ width: '100%', marginBottom: '10px' }}
              className="ant-input"
              onChange={onUserTypeChange}
            >
              <option key={''} value={''}>
                {'사용자 유형 설정'}
              </option>
              {userTypeOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            {!companyUserMenuListLoading && selectedUserType && !selectedMenuId && (
              <Button
                type="primary"
                onClick={(e) => {
                  createDefaultMenuClick();
                }}
                loading={createCompanyDefaultMenusLoading}
              >
                Create Default Menus
              </Button>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>레벨1</Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={(e) => {
                    addMenuItemClick(1);
                  }}
                  disabled={!selectedUserType}
                >
                  + 항목 추가
                </Button>
              </Col>
            </Row>
            <CustomTable
              dataSource={level1DataSource}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={companyUserMenuListLoading}
              columns={getColumnsL1()}
              rowKey="index"
              components={{
                body: {
                  wrapper: DraggableContainerL1,
                  row: DraggableBodyRowL1,
                },
              }}
              rowClassName={(record, index) => (record.index === level1RowSelect ? 'clickRowStyle' : '')}
            />
          </Col>
          <Col span={8}>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>레벨2</Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={(e) => {
                    addMenuItemClick(2);
                  }}
                  disabled={!level1RowSelect}
                >
                  + 항목 추가
                </Button>
              </Col>
            </Row>
            <CustomTable
              dataSource={level2DataSource}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={companyUserMenuListLoading}
              columns={getColumnsL2()}
              rowKey="index"
              components={{
                body: {
                  wrapper: DraggableContainerL2,
                  row: DraggableBodyRowL2,
                },
              }}
              rowClassName={(record, index) => (record.index === level2RowSelect ? 'clickRowStyle' : '')}
            />
          </Col>
          <Col span={8}>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>레벨3</Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={(e) => {
                    addMenuItemClick(3);
                  }}
                  disabled={!level2RowSelect}
                >
                  + 항목 추가
                </Button>
              </Col>
            </Row>
            <CustomTable
              dataSource={level3DataSource}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={companyUserMenuListLoading}
              columns={getColumnsL3()}
              rowKey="index"
              components={{
                body: {
                  wrapper: DraggableContainerL3,
                  row: DraggableBodyRowL3,
                },
              }}
            />
          </Col>
        </Row>
      </Modal>

      {addVisible && (
        <MenuItemAddModal
          handleCancel={handleAddCancel}
          visible={addVisible}
          routeItems={routeItems}
          fcInfo={fcInfo}
          companyUserMenuIdx={selectedMenuId}
          parentId={parentId}
          nextIndex={nextIndex}
          selectedUserType={selectedUserType}
        />
      )}
      {editVisible && (
        <MenuItemEditModal
          handleCancel={handleEditCancel}
          visible={editVisible}
          routeItems={routeItems}
          fcInfo={fcInfo}
          menuItem={selectedItem}
          selectedUserType={selectedUserType}
        />
      )}
      {deleteVisible && (
        <MenuItemDeleteModal
          handleCancel={handleDeleteCancel}
          visible={deleteVisible}
          routeItems={routeItems}
          fcInfo={fcInfo}
          menuItem={selectedItem}
          selectedUserType={selectedUserType}
        />
      )}
    </>
  );
}

MenuSettingModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  fcInfo: PropTypes.objectOf.isRequired,
};

export default MenuSettingModal;
