import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Button, Modal, Input, Form } from 'antd';
import reactStringReplace from 'react-string-replace';

const { TextArea } = Input;

const SmsModal = ({ onMessageClick, data, isTalk }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [selectedMessageInfo, setSelectedMessageInfo] = useState({ txt: '', code: '' });
  const savedMessages = useMemo(() => {
    if (data) {
      return data.map((item) => item);
    }
    return [];
  }, [data]);
  useEffect(() => {
    if (isModalVisible && isTalk) {
      if (!data || data.length === 0) {
        Modal.info({ title: '알림톡 템플릿이 없습니다. 관리자에게 문의하세요.' });
      }
    }
  }, [isTalk, isModalVisible, data]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleMessageClick = (msg, code) => {
    onMessageClick(msg, code);
    setIsModalVisible(false);
  };

  const handleEditOk = (txt, code) => {
    setIsEditVisible(false);
    handleMessageClick(txt, code);
  };
  const handleEditCancel = () => {
    //
    setIsEditVisible(false);
  };

  return (
    <>
      <Modal title="Basic Modal" className="smsModal-con" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <h1 style={{ color: '#fff', position: 'relative', left: '2%', fontWeight: '600', margin: '15px auto' }}>
          {isTalk && `알림톡 `}메시지 불러오기
        </h1>
        <div className="smsModal-con">
          <div className="ant-row">
            {savedMessages &&
              savedMessages.map((item, key) => (
                <div key={`saved-msg-${key}`} className="ant-col ant-col-7">
                  <div
                    className="sms-view-msg"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (isTalk && item.txt.includes('#{')) {
                        setIsEditVisible(true);
                        setSelectedMessageInfo({ txt: item.txt, code: item?.code });
                      } else {
                        handleMessageClick(item.txt, item?.code);
                      }
                    }}
                  >
                    <TextArea style={{ height: '100%', resize: 'none', cursor: 'pointer' }} readOnly value={item.txt} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
      <EditModal visible={isEditVisible} onOk={handleEditOk} onCancel={handleEditCancel} {...selectedMessageInfo} />
      <Button type="primary" onClick={showModal} style={{ fontSize: '0.8rem', marginLeft: '0.8rem' }}>
        {isTalk ? '알림톡' : '메시지'} 불러오기
      </Button>
    </>
  );
};

export default SmsModal;

const EditModal = ({ txt, code, visible, onOk, onCancel, ...rest }) => {
  const [values, setValues] = useState({});
  const [form] = Form.useForm();
  const divRef = useRef();
  const handleOk = () => {
    //onOk(txt, code);
  };
  const handleSubmit = (e) => {
    onOk(divRef.current.textContent.replace(/##/gi, '\n'), code);
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value ? event.target.value : '',
    });
  };
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);
  return (
    <Modal title="알림톡 수정" visible={visible} onOk={handleOk} onCancel={onCancel} footer={null} {...rest}>
      <Form form={form} onFinish={handleSubmit}>
        <div ref={divRef}>
          {txt.split('\n').map((i, key) => {
            return (
              <>
                {reactStringReplace(i, /(#{[^{][^}]*})/g, (match, ii) => {
                  return (
                    <>
                      <Form.Item name={`txt${key}${ii}`} noStyle={true}>
                        <input
                          name={`txt${key}${ii}`}
                          type="text"
                          style={{ width: '55px' }}
                          onChange={handleChange}
                          value={values[`txt${key}${ii}`]}
                        />
                      </Form.Item>
                      <span style={{ display: 'none' }}>{values[`txt${key}${ii}`]}</span>
                    </>
                  );
                })}
                <br />
                <span style={{ display: 'none' }}>##</span>
              </>
            );
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" htmlType="submit">
            확인
          </Button>
          &nbsp;
          <Button onClick={onCancel}>취소</Button>
        </div>
      </Form>
    </Modal>
  );
};
