import React, { useState, useEffect } from 'react';
import { Row, message, Col } from 'antd';
// import { useParams } from 'react-router-dom';
import { MainPage } from 'src/components/Recording';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { USER_ME, GET_STUDENT_USER, GET_TEACHER_USER } from 'src/operations/queries/getUser';
import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { VIDEOS_LIST } from 'src/operations/queries/getVideos';
import { CREATE_VIDEOS, DELETE_VIDEOS } from 'src/operations/mutations/createFiles';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import * as tus from 'tus-js-client';
import Loading from 'src/components/common/Loading';

const Recording = () => {
  // let { type } = useParams();
  // if( type == undefined) {    For now Single page
  //   type = 'list'
  // }

  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [classList, setClassList] = useState([]);
  const [myList, setMyList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [classIdx, setClassIdx] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [q, setq] = useState('');
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const token = getCurrentToken();
  const { data } = useQueryProxy(USER_ME, { variables: { token } });
  const [getList1, { data: list1, loading: list1Loading }] = useLazyQuery(VIDEOS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      is_mine: true,
      is_public: isPublic,
      class_idx: classIdx,
      q: q != '' ? q : null,
      take: 10,
      page: page,
      from_date: dateRange ? dateRange[0] : null,
      to_date: dateRange ? dateRange[1] : null,
    },
  });
  const [getList2, { data: list2, loading: list2Loading }] = useLazyQuery(VIDEOS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      is_mine: false,
      is_public: isPublic,
      class_idx: classIdx,
      q: q != '' ? q : null,
      take: 10,
      page: page2,
      from_date: dateRange ? dateRange[0] : null,
      to_date: dateRange ? dateRange[1] : null,
    },
  });
  const [optionsList, setOptionsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ idx: 0 });
  const [videoUploading, setVideoUploding] = useState(0);

  const [createVideos] = useMutation(CREATE_VIDEOS, {
    onCompleted(data) {
      message.success(`New Video has been added`);
    },
  });
  const [deleteVideos] = useMutation(DELETE_VIDEOS);

  const [classQuery, { data: classData }] = useLazyQuery(GET_TYPE_CLASS);

  useEffect(() => {
    setMyList([]);
    setOtherList([]);
    setPage(0);
    setPage2(0);
    if (!classIdx && !isPublic) {
      getList1();
    } else {
      getList1();
      getList2();
    }
  }, [classIdx, q, isPublic, dateRange]);

  useEffect(() => {
    if (list1) {
      setMyList((prev) => [...prev, ...list1.videosList]);
    }
  }, [list1]);

  useEffect(() => {
    if (list2) {
      setOtherList((prev) => [...prev, ...list2.videosList]);
    }
  }, [list2]);

  const handleUploadVideos = async (file) => {
    return new Promise(async (resolve, reject) => {
      setVideoUploding(1);
      try {
        let vimeoData = { upload: { approach: 'tus', size: file.size }, privacy: { embed: 'public' }, name: file.name };

        const rawResponse = await fetch('https://api.vimeo.com/me/videos', {
          method: 'POST',
          headers: {
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
            'Content-Type': 'application/json',
            Authorization: 'bearer 5ab37f96092e3c815e8b4d58d711c71f',
          },
          body: JSON.stringify(vimeoData),
        });

        const tusUploadIntent = await rawResponse.json();
        var upload = new tus.Upload(file, {
          uploadUrl: tusUploadIntent?.upload.upload_link,
          headers: {
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
            Authorization: 'bearer 5ab37f96092e3c815e8b4d58d711c71f',
          },
          onError: function (error) {
            console.log('Failed because: ' + error);
            setVideoUploding(0);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setVideoUploding(percentage);
          },
          onSuccess: function () {
            console.log('Download %s from %s', upload.file.name, upload.url);
            setVideoUploding(0);
          },
        });

        // Start the upload by default
        upload.start();
        createVideoMutation({
          variables: {
            title: tusUploadIntent.name,
            video_id: tusUploadIntent.uri,
            data: JSON.stringify(tusUploadIntent),
            is_shared: false,
            class_idx: classIdx,
            is_public: isPublic,
            type: 1,
          },
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const createVideoMutation = async (data) => {
    const res = await createVideos(data);
    setMyList([]);
    setPage(0);
    getList1();
  };

  const deleteVideoMutation = async (data) => {
    const res = await deleteVideos(data);
    setMyList([]);
    setPage(0);
    getList1();
  };

  let getUserType = GET_STUDENT_USER;

  if (data?.me && data?.me?.type !== '0' && data?.me?.type !== 0) {
    getUserType = GET_TEACHER_USER;
  }

  useQuery(getUserType, {
    variables: {},
    onCompleted: (data) => {
      if (data?.getUserData) {
        let tempData = data?.getUserData;
        if (tempData.class_teacher) {
          tempData = Object.assign({}, tempData, { classes: tempData.class_teacher.map((i) => i.class) });
        } else {
          tempData = Object.assign({}, tempData, { classes: tempData.class_student.map((i) => i.class) });
        }
        setUserData(tempData);
      }
    },
  });
  useEffect(() => {
    if (data?.me) {
      setUser(data.me);

      if ([2, 3, 4, 5].includes(parseInt(data?.me?.type))) {
        classQuery({
          variables: {
            campus_idx: data.me?.campus_idx,
          },
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (classData?.classList) {
      setClassList(classData?.classList);
    }
  }, [classData]);

  useEffect(() => {
    if (selectedItem) {
      switch (selectedItem.idx) {
        case 'general':
          setClassIdx(null);
          setIsPublic(true);
          break;
        case 'private':
          setClassIdx(null);
          setIsPublic(false);
          break;
        default:
          setIsPublic(false);
          if (selectedItem.idx) setClassIdx(parseInt(selectedItem.idx.substring(4)));
      }
    }
  }, [selectedItem]);

  // useEffect(() => {
  //   if (userDataObj?.data?.getUserData) {
  //     let tempData = userDataObj?.data?.getUserData;
  //     if (tempData.class_teacher) {
  //       tempData = Object.assign({}, tempData, { classes: tempData.class_teacher.map((i) => i.class) });
  //     } else {
  //       tempData = Object.assign({}, tempData, { classes: tempData.class_student.map((i) => i.class) });
  //     }
  //     setUserData(tempData);
  //   }
  // }, [userDataObj, setUserData]);

  useEffect(() => {
    if (userData) {
      let tempList = [{ idx: `general`, name: 'General(전체공개)' }];
      tempList = tempList.concat(userData.classes.map((item) => ({ idx: `cal-${item.idx}`, name: item.name })));
      tempList = tempList.concat(
        classList
          .reduce((acc, cur) => {
            //이름이 같은 반은 최신 반 하나만 보여준다.
            // const find = acc.find((ele) => ele.name === cur.name);
            // if (find) {
            //   return [...acc];
            // }
            return [...acc, cur];
          }, [])
          .map((item) => ({ idx: `cal-${item.idx}`, name: item.name })),
      );
      tempList.push({ idx: `private`, name: 'Private' });
      setOptionsList(tempList);
      setSelectedItem({ idx: `private`, name: 'Private' });
    }
  }, [userData, classList, setOptionsList, setSelectedItem]);

  // useEffect(() => {
  //   if (userDataObj?.data?.getUserData) {
  //     let tempData = userDataObj?.data?.getUserData;
  //     let tempList = [{ idx: `general`, name: 'General' }];
  //     if (tempData.class_teacher) {
  //       tempData = Object.assign({}, tempData, { classes: tempData.class_teacher.map((i) => i.class) });
  //     } else {
  //       tempData = Object.assign({}, tempData, { classes: tempData.class_student.map((i) => i.class) });
  //     }
  //     tempList = tempList.concat(tempData.classes.map((item) => ({ idx: `cal-${item.idx}`, name: item.name })));
  //     tempList = tempList.concat(classList.map((item) => ({ idx: `cal-${item.idx}`, name: item.name })));
  //     tempList.push({ idx: `private`, name: 'Private' });
  //     setOptionsList(tempList);
  //     setSelectedItem({ idx: `private`, name: 'Private' });
  //     setUserData(tempData);
  //   }
  // }, [userDataObj, classList, setSelectedItem, setUserData, setOptionsList]);

  const handleSelectedValueChange = (e, v) => {
    setSelectedItem({ idx: e, name: v.children });
  };

  console.log(dateRange);

  return (
    <>
      {user ? (
        <Row gutter={[24, 16]}>
          {!videoUploading ? (
            <MainPage
              user={user}
              onUploadViedos={handleUploadVideos}
              userData={userData}
              myList={myList}
              list1Loading={list1Loading}
              setPage={setPage}
              otherList={selectedItem.idx !== 'private' ? otherList : []}
              list2Loading={list2Loading}
              setPage2={setPage2}
              setDateRange={setDateRange}
              optionsList={optionsList}
              selectedItem={selectedItem}
              onSelectedValueChange={handleSelectedValueChange}
              createVideo={createVideoMutation}
              deleteVideos={deleteVideoMutation}
              setQuery={setq}
              isPublic={isPublic}
              classIdx={classIdx}
            />
          ) : (
            <Col span={24} style={{ textAlign: 'right', margin: 0 }}>
              <Loading style={{ width: '100%' }} />
            </Col>
          )}
        </Row>
      ) : null}
    </>
  );
};

export default Recording;
