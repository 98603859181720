import { gql } from '@apollo/client';

export const CREATE_EXTERNAL_USER_STUDY = gql`
  mutation createExternalUserStudy($external_user_study_input: ExternalUserStudyInput!) {
    createExternalUserStudy(external_user_study_input: $external_user_study_input) {
      idx
    }
  }
`;

export const SYNC_BOOKR_STUDY_DATA = gql`
  mutation syncBookrStudyData($bookId: Int, $fromDate: String!, $toDate: String!, $assigned_idx: Int, $user_idx: Int) {
    syncBookrStudyData(bookId: $bookId, fromDate: $fromDate, toDate: $toDate, assigned_idx: $assigned_idx, user_idx: $user_idx)
  }
`;

export const FAVORITE_BOOK = gql`
  mutation toggleExternalStudyFavourite($book_id: Int!, $type: String!) {
    toggleExternalStudyFavourite(book_id: $book_id, type: $type)
  }
`;

export const GET_LIKE_LIST = gql`
  mutation getLikeBooks($user_idx: Int!, $type: String!) {
    getLikeBooks(user_idx: $user_idx, type: $type)
  }
`;
export const GET_CONTINUE_LIST = gql`
  mutation getContinueBooks($user_idx: Int!, $type: String!) {
    getContinueBooks(user_idx: $user_idx, type: $type)
  }
`;

export const GET_POPULAR_LIST = gql`
  mutation getPopularBooks($user_idx: Int!, $type: String!) {
    getPopularBooks(user_idx: $user_idx, type: $type)
  }
`;

export const SAVE_EXTERNAL_STUDY_GOAL = gql`
  mutation SaveExternalStudyGoal($goal: Int!, $ym: String!, $type: String!) {
    SaveExternalStudyGoal(goal: $goal, ym: $ym, type: $type) {
      message
      success
    }
  }
`;
