import React from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Button } from 'antd';
import { CREATE_TEST, ASSIGN_TEST, ASSIGN_TEST_EDIT, EDIT_TEST } from 'src/operations/mutations/createTests';
import { Add } from 'src/components/Test';
import { openNotification } from 'src/components/common/Notification';

function WorkBookModal({ popupMode, handleCancel, visible, testIdx }) {
  const [editTest] = useMutation(EDIT_TEST, {
    onCompleted(data) {
      if (data) {
        openNotification('수정 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleTestEdit = async (data) => {
    try {
      await editTest({ variables: { ...data, idx: parseInt(testIdx) } });

      //props.history.push('/test/manage/list?refresh=true');
    } catch (e) {
      console.log(e);
    }
  };

  const handleGoBack = () => {
    //props.history.goBack();
  };
  return (
    <>
      <Modal
        visible={visible}
        title={'Test Management( 시험지 수정 )'}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Add popupMode={popupMode} onClickSave={handleTestEdit} id={parseInt(testIdx)} isEdit={true} onClickGoback={handleGoBack} />
      </Modal>
    </>
  );
}

export default WorkBookModal;
