import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import FullScreenButton from 'src/components/common/FullScreenButton';
import { userInfoVar } from 'src/apollo/cache';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 1000px;
  }
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const StageTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 30px 0;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const Body = styled(RowFlex)`
  width: 100%;
  padding: 0 30px;
  height: 85%;
`;
const LeftBody = styled.div`
  width: 50%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85%;
`;
const RightBody = styled(ColumnFlex)`
  width: 50%;
  padding: 0 20px;
  height: 100%;
`;

const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  background-image: url(${(props) => props.bg});
  background-size: 72px 50px;
  background-repeat: no-repeat;
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;
const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  max-width: 800px;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const ArrowIcon = styled.div`
  width: 52px;
  min-width: 52px;
  height: 45px;
  min-height: 45px;
  margin: 0 35px;
  background-image: url(${(props) => props.src});
  background-size: 52px 45px;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const AlphabetBox = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  border: 2px solid #707070;
  background-color: #fff;
`;
const dummy = {
  data: {
    getStudyData: {
      idx: 7164,
      title: '',
      lesson_code: 'EL-L9V3U1D1',
      category: '60',
      study_contents: {
        code: '60-VC-001',
        title: 'Voca',
      },
      study_lang_data: [
        {
          no: 1,
          eng: 'impression',
          kor: '느낌',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/impression.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/impression.mp3',
          kor_audio: null,
          eng_sentence: 'impression',
          slice_list: [],
        },
        {
          no: 2,
          eng: 'squint',
          kor: '눈을 가늘게 뜨다',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/squint.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/squint.mp3',
          kor_audio: null,
          eng_sentence: 'squint',
          slice_list: [],
        },
        {
          no: 3,
          eng: 'sloppy',
          kor: '엉성한',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/sloppy.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/sloppy.mp3',
          kor_audio: null,
          eng_sentence: 'sloppy',
          slice_list: [],
        },
        {
          no: 4,
          eng: 'transplant',
          kor: '이식하다',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/transplant.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/transplant.mp3',
          kor_audio: null,
          eng_sentence: 'transplant',
          slice_list: [],
        },
        {
          no: 5,
          eng: 'splatter',
          kor: '튀다',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/splatter.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/splatter.mp3',
          kor_audio: null,
          eng_sentence: 'splatter',
          slice_list: [],
        },
        {
          no: 6,
          eng: 'rumor',
          kor: '소문',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/rumor.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/rumor.mp3',
          kor_audio: null,
          eng_sentence: 'rumor',
          slice_list: [],
        },
        {
          no: 7,
          eng: 'chatter',
          kor: '수다를 떨다',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/chatter.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/chatter.mp3',
          kor_audio: null,
          eng_sentence: 'chatter',
          slice_list: [],
        },
        {
          no: 8,
          eng: 'slither',
          kor: '스르르 나아가다',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/slither.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/slither.mp3',
          kor_audio: null,
          eng_sentence: 'slither',
          slice_list: [],
        },
        {
          no: 9,
          eng: 'either',
          kor: '어느 하나',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/either.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/either.mp3',
          kor_audio: null,
          eng_sentence: 'either',
          slice_list: [],
        },
        {
          no: 10,
          eng: 'enemy',
          kor: '적',
          eng_slice: null,
          eng_image: '/EL/img/voca/980/enemy.jpg',
          eng_video: null,
          eng_audio: '/EL/audio/voca/980/enemy.mp3',
          kor_audio: null,
          eng_sentence: 'enemy',
          slice_list: [],
        },
      ],
      study_quiz_data: [],
    },
  },
};

export default function CrossPuzzleGame() {
  const [selected, setSelected] = useState([null, null]);
  const [seconds, setSeconds] = useState(0);
  const history = useHistory();
  const mixed = useMemo(() => {
    const tmp = [];
    if (dummy.data.getStudyData) {
      dummy.data.getStudyData.study_lang_data.map((e, i) => {
        tmp.push({ english: e.eng, korean: e.kor, key: i, eng_audio: e.eng_audio });
      });
    }
    return _.shuffle(tmp);
  }, [dummy]);
  console.log(mixed, 'data');
  useEffect(() => {
    const timerId = setTimeout(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);
    return () => clearTimeout(timerId);
  }, [seconds]);
  const handleSelect = (e) => {
    console.log(e);
  };
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '80%', width: '100%', alignItems: 'center' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '0 50px' }}>
                <StageTitle>Click the letters and solve the puzzle.</StageTitle>
                <NumberText>
                  Number{' '}
                  <QuizNumber>
                    <span style={{ color: '#433fc8' }}>{`03`}</span>/{`10`}
                  </QuizNumber>
                </NumberText>
              </RowFlex>
              <Body>
                <LeftBody>
                  <CardWrapper>
                    {mixed.map((e) => (
                      <Front>{e.korean}</Front>
                    ))}
                  </CardWrapper>
                </LeftBody>
                <RightBody>
                  <div>
                    word box
                    <AlphabetBox>a</AlphabetBox>
                  </div>
                </RightBody>
              </Body>
            </ColumnFlex>
          </Main>
        </MainWrapper>
        <StageButtonWrapper>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_left.png'}></ArrowIcon>
          <RowFlex style={{ justifyContent: 'space-between', width: '45%', height: '100%' }}>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_flashcard.png`} active={false} isDone={true} />
              Flashcard
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_check.png`} active={false} isDone={true} />
              Check
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} active={false} isDone={true} />
              Typing
            </StageButtonText>
            <StageButtonText active={true}>
              <StageButton bg={`/images/LevelUp/study/sub/g_quiz.png`} active={true} isDone={false} />
              Quiz
            </StageButtonText>
          </RowFlex>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_right.png'}></ArrowIcon>
        </StageButtonWrapper>
      </Wrapper>
    </>
  );
}

const Front = styled.div`
  width: 270px;
  height: 90px;
  margin: 12px;
  font-size: 23px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fef189;
  background-image: url('/images/LevelUp/game/card_front.png');
  background-repeat: no-repeat;
  background-size: 270px 90px;
  /* padding: 32px 0; */
  @media (max-width: 1280px) {
    width: 190px;
    height: 63px;
    margin: 10px 5px;
    font-size: 20px;
    background-size: 190px 63px;
  }
`;
