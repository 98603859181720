import React, { useState, useMemo } from 'react';
import { Col, Row, Radio, Button, Modal, Image } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import { BookCard, BookCover, BookMeta, BookTitle, BOOK_COL_STYLE, THUMBNAIL_BASE_URL } from './CommonComp';
import styled from 'styled-components';
const LEVELS = [1, 2, 3, 4, 5, 6];
// LEVELS = GRADES
const VOLUMES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
// VOLUMES = MODULES
const UNITS = [0, '1+2', 3, 4, '5+6'];
const UNITSHIGH = [0, '1+2', 3, 4, 5];
// UNITS = STORIES
// const GROUPS = ['SB', 'WB'];
const IMAGE_NAMES = {
  title: 'Into Reading',
};
const RESOURCES_LIST = {
  3: [
    'Into Reading G3 Anchor Chart.zip',
    'Into Reading G3 Blend and Read, General Vocabulary.zip',
    'Into Reading G3 Module Assessment.zip',
    'Into Reading G3 Practice & Application.zip',
    "Into Reading G3 Reader's Theater.zip",
    'Into Reading G3 Reading Remake.zip',
    'Into Reading G3 School to Home Connection.zip',
    'Into Reading G3 Selection Quizzes.zip',
    'Into Reading G3 weekly assessment.zip',
    'Into Reading G3 Wordlist & graphic organizer.zip',
    'G3 Glossary of Professional Terms.pdf',
    'G3 Scope and Sequence.pdf',
  ],
  4: [
    'Into Reading G4 Anchor Chart.zip',
    'Into Reading G4 Display & Engage.zip',
    'Into Reading G4 Graphic organizer & High Frequency Word card.zip',
    'Into Reading G4 Module assessment.zip',
    'Into Reading G4 printable resources.zip',
    "Into Reading G4 Reader's Theater.zip",
    'Into Reading G4 School to home Connection.zip',
    'Into Reading G4 Selection quizzes.zip',
    'Into Reading G4 Weekly assessment.zip',
    'G4 Scope and Sequence.pdf',
    'Into Reading G4 Instructional Routines.pdf',
    'Into Reading G4 Language Differences.pdf',
  ],
};
const DownloadBtn = styled(Button)`
  border: none;
`;
const IntoReading = () => {
  const [groupNo, setGroupNo] = useState(0);
  const { levelNo, onChangeLevelNo } = useLevelTab();
  const [visible, setVisible] = useState(false);
  const [lvMd, setLvMd] = useState([undefined, undefined]);
  const handleChangeGroup = (evt) => {
    const {
      target: { value },
    } = evt;
    setGroupNo(value);
    // onChangeLevelNo(0);
  };
  const coverGenerate = (grade, module) => {
    let vol = module;
    if (grade === 3 && module > 4) {
      vol = 4;
    } else if (grade > 3) {
      vol = 1;
    }
    return THUMBNAIL_BASE_URL + 'L' + grade + 'V' + vol + '.jpg';
  };
  const handlePopUp = (lv, module) => {
    if (visible) {
      setVisible(false);
      setLvMd([undefined, undefined]);
    } else {
      setVisible(true);
      setLvMd([lv, module]);
    }
  };

  const handleOpenBook = (lv, vol, unit) => {
    window.open(
      `https://cdn.cloubot.com/ppt/ELT/into_reading_${lv}-${vol}_story${encodeURIComponent(unit)}/index.html`,
      '_blank',
      'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes',
    );
  };

  return (
    <>
      <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
        <LevelTab
          levelNo={levelNo}
          levels={LEVELS}
          onChage={(evt) => {
            const {
              target: { value },
            } = evt;
            onChangeLevelNo(value);
          }}
        />
        <Radio.Group
          value={groupNo}
          style={{ marginBottom: 16, marginLeft: 16 }}
          buttonStyle="solid"
          onChange={handleChangeGroup}
          size="middle"
        >
          {/* {GROUPS.map((groupName, key) => (
            <Radio.Button key={`radio-level-key${key}`} value={key} style={{ width: 160, textAlign: 'center' }}>
              {groupName}
            </Radio.Button>
          ))} */}
        </Radio.Group>
        {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv) => (
          <>
            {VOLUMES.map((module) => (
              <Row key={`row-${module}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
                {(lv < 3 ? UNITS : UNITSHIGH).map((vol) => (
                  <Col
                    span={3}
                    key={`col-${module}-${vol}`}
                    style={{
                      ...BOOK_COL_STYLE,
                      borderRight: `${vol === 6 ? '1px solid #dedede' : ''}`,
                    }}
                  >
                    {vol === 0 ? (
                      <div
                        onClick={() => {
                          handlePopUp(lv, module);
                        }}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          fontSize: '20px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          cursor: 'pointer',
                        }}
                      >
                        <div>
                          Grade {lv}.{module}{' '}
                        </div>
                        <button
                          style={{ backgroundColor: 'transparent', fontSize: '15px', border: '1px solid #d9d9d9', cursor: 'pointer' }}
                        >
                          Teacher's
                          <br />
                          Resources
                        </button>
                      </div>
                    ) : (
                      <>
                        <BookTitle level={5} style={{ textAlign: 'center' }}>
                          {`Story ${vol}`}
                        </BookTitle>
                        <BookCard
                          onClick={() => {
                            handleOpenBook(lv, module, vol);
                          }}
                          cover={
                            <BookCover
                              style={{
                                backgroundImage: `url(${coverGenerate(lv, module)}`,
                                border: '0px solid #fff',
                              }}
                            />
                          }
                        ></BookCard>
                      </>
                    )}
                  </Col>
                ))}
              </Row>
            ))}
          </>
        ))}
      </div>
      <Modal
        title={"Into Reading Teacher's Resources"}
        onCancel={() => {
          handlePopUp(lvMd[0], lvMd[1]);
        }}
        visible={visible}
        footer={[
          <Button
            onClick={() => {
              handlePopUp(lvMd[0], lvMd[1]);
            }}
          >
            닫기
          </Button>,
        ]}
      >
        {parseInt(lvMd[0]) < 3 ? (
          <>
            <div style={{ fontSize: 16, textAlign: 'center', color: 'blue', fontWeight: 'bold' }}>
              Grade {lvMd[0]} Module {lvMd[1]}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/Into_Reading_G${lvMd[0]}_Handwriting_Phonological_Awareness.zip`,
                    '_blank',
                  );
                }}
              >
                Into_Reading G{lvMd[0]} Handwriting & Phonological Awareness
              </DownloadBtn>
              <div style={{ fontSize: 16, textAlign: 'center', marginTop: 10, color: 'blue', fontWeight: 'bold' }}>Teacher's Resources</div>

              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/G${lvMd[0]}_Module${lvMd[1]}_Assessment_AK.pdf`,
                    '_blank',
                  );
                }}
              >
                G{lvMd[0]} Module{lvMd[1]} Assessment AK
              </DownloadBtn>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/G${lvMd[0]}_Module${lvMd[1]}_Assessment.pdf`,
                    '_blank',
                  );
                }}
              >
                G{lvMd[0]} Module{lvMd[1]} Assessment
              </DownloadBtn>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/G${lvMd[0]}_Module${lvMd[1]}_Narrative_Writing_Rubric.pdf`,
                    '_blank',
                  );
                }}
              >
                G{lvMd[0]} Module{lvMd[1]} Narrative Writing Rubric
              </DownloadBtn>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/G${lvMd[0]}_module${lvMd[1]}-1_wordlist.pdf`,
                    '_blank',
                  );
                }}
              >
                G{lvMd[0]} module{lvMd[1]}-1 wordlist
              </DownloadBtn>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/G${lvMd[0]}_module${lvMd[1]}-2_wordlist.pdf`,
                    '_blank',
                  );
                }}
              >
                G{lvMd[0]} module{lvMd[1]}-2 wordlist
              </DownloadBtn>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/G${lvMd[0]}_module${lvMd[1]}-3_wordlist.pdf`,
                    '_blank',
                  );
                }}
              >
                G{lvMd[0]} module{lvMd[1]}-3 wordlist
              </DownloadBtn>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/Into_Reading_G${lvMd[0]}_Family_Letter_module${lvMd[1]}.pdf`,
                    '_blank',
                  );
                }}
              >
                Into Reading G{lvMd[0]} Family Letter module{lvMd[1]}
              </DownloadBtn>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/Into_Reading_G${lvMd[0]}_module${lvMd[1]}_Blend_and_Read.pdf`,
                    '_blank',
                  );
                }}
              >
                Into Reading G{lvMd[0]} module{lvMd[1]} Blend and Read
              </DownloadBtn>
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/module_${lvMd[1]}/Into_reading_G${lvMd[0]}_module${lvMd[1]}_Reader's_Theater.pdf`,
                    '_blank',
                  );
                }}
              >
                Into reading G{lvMd[0]} module{lvMd[1]} Reader's Theater
              </DownloadBtn>
            </div>
          </>
        ) : parseInt(lvMd[0]) < 5 ? (
          <>
            <div style={{ fontSize: 16, textAlign: 'center', color: 'blue', fontWeight: 'bold' }}>Grade {lvMd[0]} Teacher's Resources</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {RESOURCES_LIST[lvMd[0]].map((e)=>
              
              <DownloadBtn
                onClick={() => {
                  window.open(
                    `https://cdn.cloubot.com/ELT/description/Grade${lvMd[0]}/${e}`,
                    '_blank',
                  );
                }}
              >
                {e.split('.')[0]}
              </DownloadBtn>
              )}
            </div>
          </>
        ) : (
          '준비중입니다'
        )}
      </Modal>
    </>
  );
};

export default IntoReading;
