import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, Form, Input, Select, Upload } from 'antd';
import { ArrowRightOutlined, UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from '../common/BackButton';
import { openNotification } from 'src/components/common/Notification';
import * as mutations from 'src/operations/mutations';
import { userInfoVar } from 'src/apollo/cache';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { UPLOAD_FILE } from 'src/operations/mutations/uploadFileS3';

const { Title } = Typography;

const onHandleRowClick = (row, index, user) => {
  if (window.Android && row.isMeeting) {
    if (user.type === 1) {
      window.Android.joinZoomClass(JSON.stringify(user));
    } else {
      window.Android.startZoomClass(JSON.stringify(user));
    }
  }
};
const TestBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    width: 50,
    className: 'pad-4',
  },
  {
    title: 'Answers',
    dataIndex: 'answers',
    key: 'answers',
    ellipsis: true,
    className: 'pad-4',
    width: 200,
  },
  {
    title: 'Options',
    dataIndex: 'options',
    key: 'options',
    ellipsis: true,
    className: 'pad-4',
    width: 200,
  },
  {
    title: 'Images',
    dataIndex: 'images',
    key: 'images',
    ellipsis: true,
    className: 'pad-4',
    width: 200,
  },
  {
    title: 'Audio',
    dataIndex: 'audio',
    key: 'audio',
    ellipsis: true,
    className: 'pad-4',
    width: 200,
  },
];

const EditTest = () => {
  const [fileList, setFileList] = useState([]);
  const [imageFileList, setImageFile] = useState([]);
  const [audioFileList, setAudioFile] = useState([]);
  const [pdfFileList, setPdfFile] = useState([]);

  const [listData, setListData] = useState([]);
  const [loadingCreate, setLoadinCreate] = useState(false);
  const [form] = Form.useForm();
  const { groupIdx, groupCode, testIdx } = useParams();
  const [upload] = useMutation(UPLOAD_FILE);
  const [uploadfile, setUploadFile] = useState();
  const [excelfile, setExcelFile] = useState();
  const [pdffile1, setPdfFile1] = useState();
  const [initialFormValues, setInitialFormValues] = useState({
    code: groupCode,
    folderType: userInfoVar()?.type > 1 ? 'publisher' : 'me',
    files: [],
    excel_files: "",
    image_files: [],
    audio_files: [],
    zip_paper_files: "",
  });

  const [updateTestIntoGroup, { loading }] = useMutation(mutations.createTests.UPDATE_TEST_INTO_GROUP, {
    variables: { ...initialFormValues },

    update(proxy, result) {
      const { updateTestIntoGroup } = result.data;
      const updatedResult = JSON.parse(updateTestIntoGroup);

      setListData(
        updatedResult
          .reduce((acc, cur, index) => {
            const obj = {
              No: index + 1,
              answers: cur?.answer,
              options: cur?.q_option?.split("|")?.length,
              images: cur?.image_id ? cur?.image_id?.split("/")[8] : "-",
              audio: cur?.audio_id ? cur?.audio_id?.split("/")[8] : "-"
            };
            return [...acc, obj];
          }, []),
      );
    },
    onCompleted: () => {
      form.resetFields();
      setInitialFormValues({
        name: null,
        folderType: userInfoVar()?.type > 1 ? 'publisher' : 'me',
        files: [],
        excel_files: "",
        image_files: [],
        audio_files: [],
        zip_paper_files: "",
      });
      setLoadinCreate(false);
      openNotification('등록 완료!');
    },
    onError(error) {
      console.log('error', error);
    },
  });


  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setInitialFormValues((prev) => ({
          code: groupCode,
          folderType: formData?.folderType,
          files: prev?.files,
          excel_files: excelfile,
          image_files: prev?.image_files,
          audio_files: prev?.audio_files,
          zip_paper_files: pdffile1,
          groupCode: parseInt(groupIdx),
          testIdx: parseInt(testIdx)
        }));

        setLoadinCreate(true);
        updateTestIntoGroup();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  const imageProps = {
    multiple: true,
    onRemove: (file) => {
      setImageFile((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      setInitialFormValues((prev) => {
        const index = prev.files.indexOf(file);
        const newImageFile = prev.image_files.slice();
        newImageFile.splice(index, 1);

        return {
          name: form.getFieldValue('name'),
          folderType: form.getFieldValue('folderType'),
          files: newImageFile,
        };
      });
    },
    beforeUpload: (file) => {
      setImageFile((prev) => [...prev, file]);
      setInitialFormValues((prev) => {
        return {
          name: form.getFieldValue('name'),
          folderType: form.getFieldValue('folderType'),
          files: [...prev.files, file],
        };
      });
      return false;
    },
    imageFileList,
  };


  const audioProps = {
    multiple: true,
    onRemove: (file) => {
      setAudioFile((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      setInitialFormValues((prev) => {
        const index = prev.files.indexOf(file);
        const newImageFile = prev.audio_files.slice();
        newImageFile.splice(index, 1);

        return {
          name: form.getFieldValue('name'),
          folderType: form.getFieldValue('folderType'),
          files: newImageFile,
        };
      });
    },
    beforeUpload: (file) => {
      setAudioFile((prev) => [...prev, file]);
      setInitialFormValues((prev) => {
        return {
          name: form.getFieldValue('name'),
          folderType: form.getFieldValue('folderType'),
          files: [...prev.files, file],
        };
      });
      return false;
    },
    audioFileList,
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [form, initialFormValues]);

  const handleExcelUpload = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {
            singleUploadS3: { id, path, filename, mimetype, encoding },
          },
        } = await upload({ variables: { file: data } });
        setExcelFile(path)
        resolve(id);
      } catch (e) {
        reject(e);
      }
    });
  }
  const handlePdfUpload = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {
            singleUploadS3: { id, path, filename, mimetype, encoding },
          },
        } = await upload({ variables: { file: data } });
        setPdfFile1(path)
        resolve(id);
      } catch (e) {
        reject(e);
      }
    });
  }

  return (
    <>
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItem: 'center',
          paddingTop: 15,
          paddingBottom: 10,
        }}
      >
        <BackButton />
      </Col>
      <Col className="addnew-testmanagment" span={24}>
        <TestBlock className="addnew-testmanagment-testblock-add">
          <Row gutter={[24, 16]}>
            <Col span={8} className="testmanagment-sectiona">
              <TestSectionBlock className="testmanagment-sectiona-testsectionbk">
                <Title level={5}>
                  <span>Edit Test</span>
                </Title>
                <Form
                  form={form}
                  labelCol={{
                    span: 10,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  layout="horizontal"
                  style={{
                    textAlign: 'right',
                    minHeight: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}
                  onFinish={handleFormSubmit}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item label="Test G Code" name="code" style={{ marginBottom: 5 }}>
                    <Input style={{ opacity: 0.5, cursor: "not-allowed" }} />
                  </Form.Item>
                  <Form.Item name="folderType" label="Folder Type" style={{ marginBottom: 5 }} rules={[{ required: true }]}>
                    <Select
                      style={{ width: 150, color: '#065aa9' }}
                      //defaultValue="me"
                      placeholder="Select a folder type"
                      optionFilterProp="children"
                    >
                      {userInfoVar()?.type > 1 && <Select.Option value="publisher">FC 본사</Select.Option>}
                      <Select.Option value="campus">우리 학원 컨텐츠</Select.Option>
                      <Select.Option value="me">내 개인 컨텐츠</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="excel_files"
                    label="Excel file"
                    valuePropName="excel_files"
                    style={{ marginBottom: 5 }}
                  >
                    <Upload action={handleExcelUpload} name="excel_files">
                      <Button icon={<UploadOutlined />}>Click to uplodad</Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    name="zip_paper_files"
                    label="Pdf file"
                    valuePropName="zip_paper_files"
                    style={{ marginBottom: 5 }}
                  >
                    <Upload action={handlePdfUpload} name="zip_paper_files">
                      <Button icon={<UploadOutlined />}>Click to uplodad</Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    name="image_files"
                    label="Zip images"
                    valuePropName="image_files"
                    style={{ marginBottom: 5 }}
                  >
                    <Upload {...imageProps} name="image_files">
                      <Button icon={<UploadOutlined />}>Click to uplodad</Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    name="audio_files"
                    label="Zip Audio"
                    valuePropName="audio_files"
                    style={{ marginBottom: 5 }}
                  // rules={[{ required: true }]}
                  >
                    <Upload {...audioProps} name="audio_files">
                      <Button icon={<UploadOutlined />}>Click to uplodad</Button>
                    </Upload>
                  </Form.Item>

                  <Row>
                    <Col
                      className="syllabus-add-btn-desktop"
                      span={24}
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <Button loading={loadingCreate} type="primary" htmlType="submit" shape="round" size="large">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </TestSectionBlock>
            </Col>

            <Col span={16} className="testmanagment-sectionb syllabusarow">
              <div className="syllabusarow-arrow">
                <ArrowRightOutlined style={{ fontSize: '2.5rem', color: '#40A9FF' }} />
              </div>
              <TestSectionBlock className="testmanagment-sectionb-tsb">
                <Row gutter={[8, 8]} align="top">
                  <Col span={12}>
                    <Title level={5}>Answer Sheet</Title>

                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}></Col>
                </Row>
                <CustomTable
                  className="syllabustbl"
                  dataSource={listData}
                  columns={columns}
                  scroll={{ y: 'calc(100vh - 254px)' }}
                  size="small"
                  bordered
                  pagination={{ pageSize: 10 }}
                  color="#edf3fb"
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => onHandleRowClick(record, rowIndex),
                    };
                  }}
                />
              </TestSectionBlock>
              <Row>
                <Col
                  className="syllabus-add-btn-mobile"
                  span={14}
                  style={{
                    textAlign: 'right',
                  }}
                >
                  <Button
                    loading={loadingCreate}
                    onClick={() => handleFormSubmit()}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    size="large"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default EditTest;