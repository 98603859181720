import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPICS_LIST } from 'src/operations/queries/aiTopic';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import AITopicMenuGroup from 'src/components/common/AITopicMenuGroup';
import { userInfoVar } from 'src/apollo/cache';

const { Title } = Typography;
const { Search } = Input;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const TopicList = (props) => {
  const query = new URLSearchParams(props.location.search);
  const searchPage = query.get('page');
  const isRefresh = query.get('refresh');
  const [aiTopics, setAITopics] = useState([]);
  const [selectType, setSelectType] = useState('me');
  const [q, setQ] = useState(null);
  const [page, setPage] = useState(searchPage ? parseInt(searchPage) : 0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [checkedList, setCheckedList] = useState(new Array(aiTopics.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      type: 'publisher',
      text: null,
      page: 0,
    },
    'we_search',
  );

  const [ch, setCh] = useState(
    aiTopics.map((e) => {
      const obj = {
        id: e.id,
        value: false,
      };
      return obj;
    }),
  );

  const { data, loading, refetch } = useQuery(AI_TOPICS_LIST, {
    fetchPolicy: 'no-cache',
    variables: { folderType: searchValue.type, type: 'ai-topic', q: searchValue.text, page: searchValue.page, take },
  });
  //pageSize: take, total, current: searchValue.page + 1
  useEffect(() => {
    if (data?.aiTopicsList) {
      setAITopics(
        data?.aiTopicsList.aiTopics.map((item, key) => {
          return { ...item, item_no: take * searchValue.page + key + 1 };
        }),
      );
      setTotal(data?.aiTopicsList.total);
      //props.history.replace({ search: `?page=${page}` });
    }

    if (isRefresh) {
      //refetch();
      //props.history.replace({ search: '' });
    }
  }, [data, page, props.history, searchPage, isRefresh]);

  function onChange(e, index, record) {
    checkedList[index] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
    setCh([...ch, { id: record.idx, value: e.target.checked }]);
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(aiTopics.length).fill(true));
      setCh(
        aiTopics.map((topic) => {
          return { id: topic.idx, value: true };
        }),
      );
    } else {
      setCheckedList([]);
      setCh([]);
    }
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: 100,
      render: (_, { item_no }) => item_no,
    },
    {
      title: '주제',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
    },
    {
      title: '폴더 유형',
      dataIndex: 'idate',
      align: 'center',
      key: 'date',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{record?.company_idx ? '본사 컨텐츠' : record?.campus_idx ? '학원 컨텐츠' : '개인 컨텐츠'}</Space>;
      },
    },
    {
      title: '동작',
      key: '',
      align: 'center',
      width: 200,
      render: (text, record) => {
        const disabled = (userInfoVar()?.type <= 3 && searchValue.type === 'publisher') || record.assigned_count > 0;
        return (
          <>
            <Space size="middle">
              {disabled ? (
                <Button disabled={true} style={{ color: '#cccccc' }}>
                  수정하기
                </Button>
              ) : (
                <Link to={`/ai-topic/manage/edit/${record.idx}`}>
                  <Button style={{ color: '#1890ff' }}>수정하기</Button>
                </Link>
              )}
            </Space>
            &nbsp;
            <Space size="middle">
              {disabled ? (
                <Button disabled={true} style={{ color: '#cccccc' }}>
                  삭제
                </Button>
              ) : (
                <Button
                  className="ai-topicmanagmentdelete-btn"
                  danger
                  onClick={() => {
                    handleAITopicDelete(record);
                  }}
                >
                  삭제
                </Button>
              )}
            </Space>
          </>
        );
      },
    },
    {
      title: () => {
        return <Checkbox onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'right',
      key: 'action',
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Checkbox checked={checkedList[index]} onChange={(e) => onChange(e, index, record)}></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
  ];

  const getColumns = () => {
    if (userInfoVar()?.type > 3) {
      return columns.slice(0, -1);
    }
    return columns;
  };

  const handleAITopicDelete = (item) => {
    const idxs = [item.idx];
    props.onAITopicDelete({ idxs });
    setTimeout(() => {
      setCheckedList([]);
      refetch();
    }, 1000);
  };

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  const handleAssignData = () => {
    let data = [];
    aiTopics.forEach((item, index) => {
      ch.forEach((checked, index2) => {
        if (item.idx === checked.id && checked.value) {
          data.push(item);
        }
      });
    });
    localStorage.setItem('selectedAITopics', JSON.stringify(data));
  };

  return (
    <>
      {userInfoVar()?.type <= 3 ? (
        <AITopicMenuGroup currentMenu="ai-topic-management" />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}></div>
      )}
      <Col className="contentai-topic-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="ai-topicmanagement-mailblock">
          <Row gutter={[24, 8]} style={{ margin: 0 }}>
            <Col className="contentai-topic-title" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>AI Topic 관리</Title>
            </Col>
            <Col
              className="contentai-topic-headitems"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <span style={{ textAlign: 'center', display: 'flex', alignItems: 'center', marginRight: '10px', height: '32px' }}>
                폴더 유형:
              </span>
              <Select
                style={{ width: 150, color: '#065aa9' }}
                defaultValue={searchValue.type}
                placeholder="폴더 유형 선택"
                optionFilterProp="children"
                onChange={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      type: val,
                    };
                  })
                }
              >
                <Select.Option value="publisher">본사 컨텐츠</Select.Option>
                <Select.Option value="campus">학원 컨텐츠</Select.Option>
                <Select.Option value="me">개인 컨텐츠</Select.Option>
              </Select>
              <Search
                className="headitems-search"
                placeholder="검색어 입력하기"
                //onSearch={(val) => setQ(val)}
                defaultValue={searchValue.text}
                onSearch={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      text: val,
                    };
                  })
                }
                allowClear
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
              />

              <Link to="/ai-topic/manage/add" className="headitems-add">
                <Button style={{ color: '#289428' }}>Add +</Button>
              </Link>
              {(userInfoVar()?.type === 1 || userInfoVar()?.type === 2) && (
                <Link to="/ai-topic/manage/assign" className="headitems-assign" style={{ pointerEvents: !ch.length ? 'none' : '' }}>
                  <Button
                    style={{ color: !ch.length ? '#cccccc' : '#2790ff', marginLeft: '1em', marginRight: '1em' }}
                    className="ai-topicmanagmentassign-btn"
                    onClick={handleAssignData}
                  >
                    배정하기
                  </Button>
                </Link>
              )}
            </Col>
          </Row>
          <CustomTable
            className="contentai-topic-table"
            loading={loading}
            dataSource={aiTopics}
            columns={getColumns()}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: take, total, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(TopicList);
