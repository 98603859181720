import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { DELIVERY_COMPANY_LIST } from 'src/operations/queries/delivery';

const DeliveryCompanyListSelectBox = ({ code, onChange }) => {
  const { data, loading } = useQuery(DELIVERY_COMPANY_LIST);
  const options = useMemo(() => {
    const defaults = [
      {
        label: '선택',
        value: '',
      },
    ];
    const list = data?.deliveryCompanyListFromDB;
    if (list) {
      return [
        ...defaults,
        ...list.map(({ company_code, company_name, phone_number }) => {
          return {
            label: company_name,
            value: company_code,
            phone_number,
          };
        }),
      ];
    }
    return defaults;
  }, [data]);
  return <Select style={{ width: '100px' }} options={options} loading={loading} value={code} onChange={onChange} />;
};

export default DeliveryCompanyListSelectBox;

export const useDeliveryCompany = (code = '') => {
  const [selectedCompanyCode, setSelectedCompanyCode] = useState(code);
  const [selectedCompanyPhoneNumber, setSelectedCompanyPhoneNumber] = useState('');

  const onCompanyChange = (code, opts) => {
    setSelectedCompanyCode(code);
    setSelectedCompanyPhoneNumber(opts?.phone_number);
  };
  return [selectedCompanyCode, selectedCompanyPhoneNumber, onCompanyChange];
};
