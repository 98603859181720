import styled from 'styled-components';
import { Col, Row, Radio, Card, Typography, Button } from 'antd';

const { Meta } = Card;
const { Title } = Typography;

export const BookCard = styled(Card)`
  margin-left: 5px;
  margin-right: 5px;
  border: 0px solid #fff;
  .ant-card-cover {
    border: 0px solid #fff;
  }
  .ant-card-body {
    padding: 5px;
    min-height: 40px;
    text-align: center;
    border: 0px solid #fff;
  }
`;
export const BookMeta = styled(Meta)`
  .ant-card-body {
    padding: 5px;
  }

  .ant-card-meta-title {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 1px;
  }
`;
export const BookTitle = styled(Title)`
  text-align: center;
  background-color: #f0f3f9;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #dedede;
`;
export const BookCover = styled.div`
  cursor: pointer;
  width: 139px;
  min-width: 100px;
  height: 180px;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #eee;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const BOOK_COL_STYLE = {
  borderTop: '1px solid #dedede',
  borderBottom: '1px solid #dedede',
  borderLeft: '1px solid #dedede',
  paddingBottom: 5,
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: 15,
};

export const SMART_ECLASS_LINK_BUTTON_STYLE = {
  width: '45%',
  fontWeight: 'bold',
  color: '#868e96',
  backgroundColor: '#F0F3F9',
};

export const SMART_ECLASS_CDN_BASE_URL = 'https://cdn.cloubot.com/SM/';
export const SNEAKERS_CDN_BASE_URL = 'https://cdn.cloubot.com/SK/';

export const THUMBNAIL_BASE_URL = `${SMART_ECLASS_CDN_BASE_URL}cover/`;
