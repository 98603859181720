import React, { useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { Col, Row, Typography, Modal, Button, DatePicker, Radio, Checkbox, Form, Input, Tooltip, Space, Select } from 'antd';
import moment from 'moment';
import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import OfflineTextBookList from 'src/components/Offline/MainPage/OfflineTextBookList';
/*import SubPage from 'src/components/Offline/SubPage';*/
import { useHistory, Link } from 'react-router-dom';
import CustomButton from 'src/components/common/CustomButton';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import * as mutations from 'src/operations/mutations';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import * as queries from 'src/operations/queries';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { userVar } from 'src/apollo/cache';

//캠퍼스 원장용 대시보드 메인 페이지
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { value } = "";
const CampusPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');

  const [Inputbookcode, setInputbookcode] = useState('');
  const [Inputbooktitle, setInputbooktitle] = useState('');
  const [Inputbookpublisher, setInputbookpublisher] = useState('');
  const [Inputsubject, setInputsubject] = useState('');
  const [Inputskillname, setInputskillname] = useState('');
  const [Inputtotalnumber, setInputtotalnumber] = useState('');
  const [Inputdatepick, setInputdatepick] = useState('');
  const [Inputlevel, setInputlevel] = useState('');
  const [text, setText] = useState(EditorState.createEmpty());
  const [Inputmemo, setInputmemo] = useState('');
  const [InputFolder, setInputFolder] = useState('');

  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  const userType = useMemo(() => userVar()[data?.me?.type] || userVar()[0], [data]);

  const [Inputvalidation, setInputvalidation] = useState('');

  const [addtextbook] = useMutation(mutations.offlinesyllabus.OFFLINE_ADD_TEXTBOOK_OFFLINESYLLABUS, {
    onCompleted(data) {
      //if (data?.OfflineSyllabusAddTextbook == true) {
        setInputvalidation("Textbook Added Successfully !!!");
        setVisible(false);
        setInputvalidation("");
        //window.location.reload();  
      /*}else{
        setInputvalidation("Textbook Added Successfully !!!");
        TextbookaddResult("success");
        setVisible(false);
        setInputvalidation("");
      }*/
    },
    onError(error) {
      setInputvalidation("Please try Again !!!");
      //window.location.reload();
    },
  });

 function handlesavebooklist(){
    setInputvalidation('');
    if(Inputbookcode !== '' && Inputbooktitle !== '' && Inputbookpublisher !== '' && Inputsubject !== '' && Inputskillname !== '' && Inputtotalnumber !== '' && Inputdatepick !== '' && Inputlevel !== '' && text !== ''){
      addtextbook({
            variables: {
              bookcode  : Inputbookcode,
              booktitle : Inputbooktitle,
              publisher : Inputbookpublisher,
              subject   : Inputsubject,
              skillname : Inputskillname,
              pageno    : parseInt(Inputtotalnumber),
              registerdate: Inputdatepick,
              level : Inputlevel, 
              memo  : draftToHtml(convertToRaw(text.getCurrentContent())),
              folder_type : InputFolder
            },
      });
    }else{
      setInputvalidation("Kindly fill the all Text book Fields.!!!");
    }
  }

  const handlesavebooklistcancel = () => {
    setInputvalidation("")
    setInputbookcode("")
    setInputbooktitle("")
    setInputbookpublisher("")
    setInputsubject("")
    setInputskillname("")
    setInputtotalnumber("")
    setInputdatepick("")
    setInputlevel("")
    setInputmemo("")
    setVisible(false);
    /*window.location.reload();*/
  }

  const textbooklistrefetch = (e) => {
    window.location.reload();
  }

  const handleDateChange = (date, dateString) => {
    setInputdatepick(dateString)
  };

  const handleLevelChange = (e) =>{
    setInputlevel(e);
  }

  const handleFolderChangepopup = (e) => {
    setInputFolder(e);
  }

  const onChange = (text) => {
    setText(text);
  };

  return (
    <>
      <Row justify="space-between" className="buttonlisting-dashboard">
        <Col span={2} className="calendar-box">
          <HeaderTitle level={4}>Textbook List</HeaderTitle>
        </Col>
        <Col span={16} className="calendar-box">
        </Col>
        <Col span={1} className="calendar-box">
          <Button
              className="btn-schedule"
              type={'primary'}
              size="large"
              style={{ background: "white", color : "#1890ff", marginTop:"17%" }}
            >
              <Link style={{color : "#1890ff"}} to={`/offline/lesson/assign`}>Syllabus Main</Link> 
            </Button>
        </Col>
        <Col span={1} className="calendar-box"></Col>
        <Col span={2} className="calendar-box">
          <Button
              className="btn-schedule"
              type={'primary'}
              size="large"
              style={{ background: "white", borderColor: "green", color : "green", marginTop:"8%" }}
            >
              <Text type={'success'} onClick={() => setVisible(true)}>+ Add Textbook</Text> 
            </Button>
        </Col>
        <Col span={1}></Col>
        <Modal
        title="Text Book Management"
        centered
        visible={visible}
        width={1100}
        onSave={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Row>
        <Col span={17} className="calendar-box">
          <Title className="header-classroom-title" level={3}>
              <span style={{ marginRight: "10%", color : "green" }}> + &nbsp;&nbsp;Add Textbook</span>
          </Title>
        </Col>
        <Col span={7} className="calendar-box">     
          <Button
            className="btn-schedule"
            type={tab === 'main' && 'primary'}
            size="large"
            style={{ margin: 2,float: 'right', background: "#4e9b4e", borderColor: "#1d641d" }}
            onClick={(e) => textbooklistrefetch(e)}
            onClose={e => e.preventDefault()}
          >
            Textbook List
          </Button>
          </Col>
        </Row>
        <Row>
        <Col span={7}></Col>
        <Col span={12} className="calendar-box">
          <Title level={4} style={{color:"red"}}>{Inputvalidation}</Title>
          </Col>
        <Col span={5}></Col>
        </Row>
        <br/>
        <Row style={{ border: '3px solid #8ec1e5',padding : "10px", background: "#d0dce5", borderColor: "#d0dce5" }}>
        <Col span={6} className="calendar-box">
        <Form
          form={form}
          initialValues={{
            layout: formLayout,
          }}
          style={{ marginTop: "4%"}}
        >
            <Form.Item label="Book Code">
              <Input value={Inputbookcode} onInput={e => setInputbookcode(e.target.value) } />
            </Form.Item>
            <Form.Item label="Book Title">
              <Input value={Inputbooktitle} onInput={e => setInputbooktitle(e.target.value) } />
            </Form.Item>
            <Form.Item label="Publisher">
              <Input value={Inputbookpublisher} onInput={e => setInputbookpublisher(e.target.value) } />
            </Form.Item>
            <Form.Item label="Subject">
              <Input value={Inputsubject} onInput={e => setInputsubject(e.target.value) } />
            </Form.Item>
            <Form.Item label="Skill Name">
              <Input value={Inputskillname} onInput={e => setInputskillname(e.target.value) } />
            </Form.Item>
            <Form.Item label="Num. of lessons">
              <Input type="number" value={Inputtotalnumber} onInput={e => setInputtotalnumber(e.target.value) } />
            </Form.Item>
        </Form>
        </Col>
        <Col span={1} className="calendar-box">
        </Col>
        <Col span={17} className="calendar-box">
        <Form
          form={form}
          initialValues={{
            layout: formLayout,
          }}
          style={{ marginTop: "4%"}}
        >
            <Form.Item label="Register Date">
              <DatePicker 
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString);
                }} />
            </Form.Item>
            <Form.Item
              name=""
              label="Level"
              rules={[
                {
                  message: 'Please select gender!',
                },
              ]}
              style={{width:"36%"}}
            >
              <Select onChange={handleLevelChange}>
                <Option value="Phonics">Phonics</Option>
                <Option value="1">level 1</Option>
                <Option value="2">level 2</Option>
                <Option value="3">level 3</Option>
                <Option value="4">level 4</Option>
                <Option value="5">level 5</Option>
                <Option value="6">level 6</Option>
                <Option value="7">level 7</Option>
                <Option value="8">level 8</Option>
                <Option value="9">level 9</Option>
                <Option value="10">level 10</Option>
                <Option value="11">level 11</Option>
                <Option value="12">level 12</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name=""
              label="Folder"
              rules={[
                {
                  message: 'Please select gender!',
                },
              ]}
              style={{width:"36%"}}
            >
              <>
              {userType == 'fc' ? (
                <Select value={InputFolder} onChange={handleFolderChangepopup}>
                  <Option value="publisher">본사 컨텐츠</Option>
                  <Option value="campus">학원 컨텐츠</Option>
                  <Option value="me">개인 컨텐츠</Option>
                </Select>
              ) : (
                <Select value={InputFolder} onChange={handleFolderChangepopup}>
                  <Option value="campus">학원 컨텐츠</Option>
                  <Option value="me">개인 컨텐츠</Option>
                </Select>
              )}
              </>
            </Form.Item>

            <Form.Item label="Memo">
              <Editor
                editorState={text}
                style={{ width: '100%'}}
                rootStyle={{ height: 'calc(100% - 50px)', width: '100%', minHeight: 300 }}
                editorStyle={{ backgroundColor: '#fff',minHeight : 120 }}
                toolbarClassName="hide-print"
                wrapperClassName="essay-wrapper"
                onEditorStateChange={onChange}
                toolbar={{
                  options: ['inline','fontSize', 'fontFamily', 'textAlign', 'link']
                }}
              />
              {/*<TextArea
                value={value}                                                                                                                                   
                placeholder=""
                autoSize={{ minRows: 9, maxRows: 9 }}                                                                                     
                value={Inputmemo} onInput={e => setInputmemo(e.target.value) }
              />*/}
            </Form.Item>
        </Form>
        </Col>
        <Col span={11} className="calendar-box">
        </Col>                    
        <Col span={11} className="calendar-box">
          <Button
            className="btn-schedule"
            type={tab === 'main' && 'primary'}
            size="large"
            style={{ margin: 2,float: 'right',borderRadius : "5px", }}
            onClick={handlesavebooklist}
            onClose={e => e.preventDefault()}
          >
            Save
          </Button>
          <Button
            className="btn-schedule"
            type={tab === 'main' && 'primary'}
            size="large"
            style={{ margin: 2,float: 'right', borderRadius : "5px", background: "#ff8d13", borderColor: "#ff8d13" }}
            onClick={handlesavebooklistcancel}
            onClose={e => e.preventDefault()}
          >
            Cancel
          </Button>
          </Col>
        </Row>
      </Modal>
      </Row>

      <Row gutter={[24, 16]}><OfflineTextBookList 
                            Textbook_Result={Inputvalidation}/></Row>
    </>
  );
};

export default CampusPage;
