import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SoundButton from '../SoundButton';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PopUp from '../PopUp';
import PageNum from '../PageNum';
import { useStudyCreate } from '../../../utils/pm/api';
import _ from 'lodash';
import reactStringReplace from 'react-string-replace';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { alphabetBoxTypeChecker } from '../../../utils/pm/util';
import { userInfoVar } from 'src/apollo/cache';
const veryGoodSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';

const WordSelectStage = ({ unitInfo, bookInfo, stages, steps, setSteps }) => {
  const { problem } = stages;
  const [isCorrect, setIsCorrect] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  // to avoid stuck while clicking on wrong answer after clicking right answer
  const [soundEnd, setSoundEnd] = useState(true);
  const [soundCorrectWrong, setSoundCorrectWrong] = useState('');

  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  const answer = !_.isNil(problem[steps].answer) ? problem[steps].answer : problem[steps].word;

  const isImageOnly = _.isNil(problem[steps].questionFormat) ? false : true;

  const handleWordClick = (word) => {
    if (word === answer) {
      setIsCorrect(true);
      setSoundCorrectWrong(veryGoodSound);
      setSoundEnd(false);
    } else if (soundEnd) {
      setSoundCorrectWrong(woopsSound);
    }
  };
  const handleSoundCorrectWrongEnd = () => {
    if (soundCorrectWrong === veryGoodSound) {
      if (steps < problem.length - 1) {
        setSteps(steps + 1);
        setIsCorrect(false);
        setSoundEnd(true);
      } else {
        setShowPopUp(true);
      }
    }
    setSoundCorrectWrong('');
  };

  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };
  return (
    <>
      <S.RowFlex
        style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
        className={`main_content col2 ${isImageOnly ? 'review1_content' : 'lv4_st3_wrap'}`}
      >
        <LeftBox isImageOnly={isImageOnly} className="left_box">
          <LeftArea image={problem[steps].image} word={problem[steps].word} isCorrect={isCorrect} isImageOnly={isImageOnly} />
          <PageNum
            num={_.isNil(steps) ? 0 : steps + 1}
            total={_.isNil(problem) ? 0 : problem.length}
            style={{ fontSize: '20px', fontWeight: 600, textAlign: 'center', margin: 0, padding: 0 }}
          />
        </LeftBox>

        <RightBox isImageOnly={isImageOnly} className="right_box">
          {isImageOnly ? (
            <>
              <QuestionBox problem={problem[steps]} isCorrect={isCorrect} />
              <Arrow
                src="/images/pm/i_arrow_top.svg"
                alt="위로 향하는 화살표"
                className="top_arrow"
              />
              <ExWordBox2 problem={problem[steps]} isCorrect={isCorrect} answer={answer} onWordClick={handleWordClick} />
            </>
          ) : (
            <ExWordBox problem={problem[steps]} isCorrect={isCorrect} answer={answer} onWordClick={handleWordClick} />
          )}
        </RightBox>
      </S.RowFlex>
      {<SoundEffectPM url={soundCorrectWrong} onEnd={handleSoundCorrectWrongEnd} />}
      {showPopUp ? <PopUp popUpType={'correct'} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
    </>
  );
};

export default WordSelectStage;

const LeftArea = ({ image, word, isCorrect, isImageOnly }) => {
  if (isImageOnly) {
    return (
      <>
        <SoundButton src={`https://cdn.cloubot.com/PM/audio/sounds/${word}.mp3`} />
        <S.RowFlex style={{ justifyContent: 'center', alignItems: 'center', flex: '1 1' }}>
          <img style={{ width: '50%', height: 'auto' }} src={image} alt="" />
        </S.RowFlex>
      </>
    );
  }
  return (
    <>
      {isCorrect ? (
        <div style={{ height: '30vh' }} className="img active">
          <img style={{ height: '100%', objectFit: 'contain' }} src={image} alt="" />
        </div>
      ) : (
        <S.RowFlex style={{ justifyContent: 'center' }} className="btn active">
          <SoundButton
            // style={{ height: '140px', width: '140px' }}
            height={'30vh'}
            src={`https://cdn.cloubot.com/PM/audio/sounds/${word}.mp3`}
          />
        </S.RowFlex>
      )}
    </>
  );
};

const ExWordBox = ({ problem, isCorrect, answer, onWordClick }) => {
  return (
    <S.AlphabetWrap className="alphabet_wrap">
      {problem &&
        problem.exWords &&
        problem.exWords.map((item, key) => (
          <AlphabetWrapLi
            key={`word-list-key-${key}`}
            onClick={() => {
              onWordClick(item.replace(/#/g, ''));
            }}
          >
            <AlphabetBox
              active={isCorrect && item.replace(/#/g, '') === answer}
              className={`alphabet_box click ${isCorrect && item.replace(/#/g, '') === answer ? 'active' : ''}`}
              type="button"
            >
              {isCorrect && item.replace(/#/g, '') === answer?
              <span style={{color:'#2e86de'}}>{item.replace(/#/g, '')}</span>:
              reactStringReplace(item, /##(\w+)##/g, (match) => (
                <span style={{ color: '#f368e0' }} className="strong">
                  {match}
                </span>
              ))}
            </AlphabetBox>
          </AlphabetWrapLi>
        ))}
    </S.AlphabetWrap>
  );
};

const QuestionBox = ({ problem, isCorrect }) => {
  if (_.isNil(problem) || _.isNil(problem.questionFormat)) {
    return false;
  }
  const isBlank = problem.questionFormat.indexOf('##') !== -1 ? true : false;
  const regEx = isBlank ? /##(\w+)##/g : /@(\w+)@/g;
  return (
    <S.AlphabetWrap className="alphabet_wrap">
      <span style={{ fontWeight: 600, fontSize: 38, color: '#2e86de' }} className="font_blue">
        {reactStringReplace(problem.questionFormat, regEx, (match) => (
          <span style={{ paddingBottom: 5, borderBottom: '4px solid #ee5353', color: '#f368e0' }} className="font_pink font_line">
            <span style={{ color: !isCorrect && 'transparent' }} className={`${!isCorrect ? 'hide' : ''}`}>
              {match}
            </span>
          </span>
        ))}
      </span>
    </S.AlphabetWrap>
  );
};

const ExWordBox2 = ({ problem, isCorrect, answer, onWordClick }) => {
  return (
    <S.AlphabetBottomWrap className="gray_bg_border_top">
      <S.AlphabetWrap className="alphabet_wrap row1_wrap" style={{ width: '94%' }}>
        {problem &&
          problem.exWords &&
          problem.exWords.map((item, key) => (
            <li
              style={{ margin: '12px 8px' }}
              key={`word-list-key-${key}`}
              onClick={() => {
                onWordClick(item.replace(/#/g, ''));
              }}
            >
              <ReviewAlphabetBox
                blue={isCorrect && item.replace(/#/g, '') === answer}
                row={alphabetBoxTypeChecker(item.indexOf(' ') !== -1 ? 7 : 2)}
                className={`alphabet_box ${item.indexOf(' ') !== -1 ? 'row6' : 'row2'} blue click  ${
                  isCorrect && item.replace(/#/g, '') === answer ? 'active' : ''
                }`}
              >
                {item.indexOf(' ') !== -1 ? (
                  item.split(' ').map((item2, key2) => (
                    <span style={{ color: key2 === 0 ? '#666' : '#2e86de' }} className={`${key2 === 0 ? 'font_dark' : 'font_blue'}`}>
                      {item2}
                      {key2 === 0 && <>&nbsp;</>}
                    </span>
                  ))
                ) : (
                  <span style={{ color: '#2e86de' }} className="font_blue">
                    {item}
                  </span>
                )}
              </ReviewAlphabetBox>
            </li>
          ))}
      </S.AlphabetWrap>
    </S.AlphabetBottomWrap>
  );
};
const LeftBox = styled(S.BorderColumnBox)`
  flex: 1 1;
  position: relative;
  padding: ${(props) => props.isImageOnly && '16px 0'};
  text-align: ${(props) => !props.isImageOnly && 'center'};
  justify-content: ${(props) => !props.isImageOnly && 'space-around'};
  align-items: center;
`;
const RightBox = styled(S.BorderColumnBox)`
  flex: 1 1;
  position: relative;
  margin-left: 24px;
  justify-content: ${(props) => !props.isImageOnly && 'center'};
`;
const AlphabetWrapLi = styled.li`
  padding: 15px 10px;
  min-width: calc(50% - 24px);
  margin: 0;
`;
const AlphabetBox = styled.button`
  display: block;
  padding: 5px 10px;
  width: calc(100% - 32px);
  border: 6px solid ${(props) => (props.active ? '#00d2d3' : '#eee')};
  background-color: #fff;
  box-shadow: 0 6px 0 ${(props) => (props.active ? '#00a3a4' : '#d5d5d5')};
  height: 52px;
  margin-top: -6px;
  box-sizing: content-box;
  font-weight: 800;
  font-size: 38px;
  cursor: pointer;
  white-space: nowrap;
  -moz-user-select: none;
  -ms-user-select: none;
`;
const ReviewAlphabetBox = styled(S.AlphabetBox)`
  border-radius: 0;
  margin: 8px;
  align-items: center;
  border: 6px solid ${(props) => (props.blue ? '#48dbfb' : '#00d2d3')};
  box-shadow: 0 6px 0 ${(props) => (props.blue ? '#0abde3' : '#00a3a4')};
  @media (max-height: 720px){
    border: 5px solid ${(props) => (props.blue ? '#48dbfb' : '#00d2d3')};
    box-shadow: 0 5px 0 ${(props) => (props.blue ? '#0abde3' : '#00a3a4')};
        width: ${(props)=>2.2*(props.row?props.row:1)}rem;
        height: ${(props)=>props.row===3.21?'5.2rem':'3.06rem'};
        font-size: 1.9rem;
        margin: -0.2rem 0.2rem 0;
    }
  @media (max-height: 500px){
   margin: 4px;
  }
`;
const Arrow = styled.img`
  height: 3rem;
  margin-bottom: 1vh;
  @media (max-height: 500px){
	  height: 2rem;
}
`;