import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Row, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_DATE_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import BackButton from 'src/components/common/BackButton';
import ViewAnswerModal from '../../components/Modal/ViewAnswerModal';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const DetailsByDate = (props) => {
  const { idx } = useParams();

  const [ai_topic_user_assignments, setAssignedAITopics] = useState([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [userTopicId, setUserTopicId] = useState();
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      page: 0,
    },
    'ai_date_details',
  );
  const { data, loading } = useQuery(AI_TOPIC_DATE_ASSIGNMENT_DETAILS, {
    variables: { idx: parseInt(idx) },
  });

  useEffect(() => {
    if (data?.aiTopicDateAssignmentDetails) {
      setAssignedAITopics(
        data?.aiTopicDateAssignmentDetails.ai_topic_user_assignments.map((item, index) => {
          return { ...item, no: index + 1 };
        }),
      );
      setSelectedDate(data?.aiTopicDateAssignmentDetails?.start_date?.substr(0, 10));
      setTotal(data?.aiTopicDateAssignmentDetails.ai_topic_user_assignments.length);
    }
  }, [data]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    {
      title: '주제',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic.topic}</Space>;
      },
    },
    {
      title: '사용자',
      dataIndex: 'user',
      key: 'user',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.user.name}</Space>;
      },
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'class',
      width: 300,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.class.name}</Space>;
      },
    },
    {
      title: '상태',
      dataIndex: 'status',
      width: 100,
      key: 'status',
      align: 'center',
      render: (text, { status }) => {
        return <Space size="middle">{status === 0 ? '시작하지 않음' : status === 1 ? '시작함' : status === 2 ? '답변함' : '적합함'}</Space>;
      },
    },
    {
      title: '동작',
      key: '',
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setTopic(record.ai_topic.topic);
                setUserTopicId(record.idx);
                setTopicAnswer(record.answer);
                setViewAnswerModal(true);
              }}
            >
              보기
            </Button>
          </Space>
        );
      },
    },
  ];

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>AI Topic Assignment Details By Date ({selectedDate})</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <div style={{ margin: 'auto 0' }}>
                <BackButton />
              </div>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={ai_topic_user_assignments}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>
        {viewAnswerModal && (
          <ViewAnswerModal
            visible={viewAnswerModal}
            topic={topic}
            userTopicId={userTopicId}
            topicAnswer={topicAnswer}
            handleCancel={() => {
              setViewAnswerModal(false);
            }}
          />
        )}
      </Col>
    </>
  );
};

export default withRouter(DetailsByDate);
