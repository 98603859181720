import React from 'react';

import DefaultLayout from 'src/layouts/DefaultLayout';
import FcManager from 'src/pages/FcManager';
import ContentManagement from 'src/pages/FcManager/ContentManagement';
import ScriboSubscriptionManagement from 'src/pages/FcManager/ScriboSubscriptionManagement';
import OpenAiUsage from 'src/pages/FcManager/OpenAiUsage';
import QuestionExtractor from 'src/pages/QuestionExtractor';
import TestDocumentList from 'src/pages/QuestionExtractor/list';
import MetaExamStaticsReadCloubot from 'src/pages/MetaExam/MetaExamStaticsReadCloubot';
import MetaExamStaticsDetails from 'src/pages/MetaExam/MetaExamStaticsDetails';
import AITalkingTutorLayout from 'src/layouts/AITalkingTutorLayout';
import AddAITalkingTutor from 'src/pages/AITalkingTutor/AddGroupList';
import AiTalkingTutor from 'src/pages/AITalkingTutor/Assign/TopicList';
import AiContentList from 'src/pages/AITalkingTutor/Assign/ContentList'
import EditTalkingTutor from 'src/pages/AITalkingTutor/EditGroupList';
import BookDescriptionPage from 'src/pages/LevelUp/BookDescription';
import LevelUpLayout from 'src/layouts/LevelUpLayout';
const Administrator = () => {
  return (
    <>
      <DefaultLayout exact path="/fcManager" component={FcManager} />
      <DefaultLayout exact path="/contentManagement" component={ContentManagement} />
      <DefaultLayout exact path="/scriboSubscriptionManagement" component={ScriboSubscriptionManagement} />
      <DefaultLayout exact path="/openAiUsage" component={OpenAiUsage} />
      <DefaultLayout exact path="/question/extractor/add" component={QuestionExtractor} />
      <DefaultLayout exact path="/question/extractor/edit/:id" component={QuestionExtractor} />
      <DefaultLayout exact path="/question/extractor/list" component={TestDocumentList} />
      <DefaultLayout exact path="/meta-statics/all" component={MetaExamStaticsReadCloubot} />
      <DefaultLayout exact path="/meta-statics/details/:type/:campus_idx/:value" component={MetaExamStaticsDetails} />
      <DefaultLayout exact path="/" component={FcManager} />
      <LevelUpLayout exact path="/level-up/description/:idx" component={BookDescriptionPage} removeMargin={true} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/topiclist" isHeaderStatic={false} component={AiTalkingTutor} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/edit/:idx" isHeaderStatic={false} component={EditTalkingTutor} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/contentlist" isHeaderStatic={false} component={AiContentList} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/add" isHeaderStatic={false} component={AddAITalkingTutor} />
    </>
  );
};

export default Administrator;
