import React, { useMemo, useState } from 'react';
import { Col, Typography } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';

const { Title } = Typography;
const STUDY_PARTS = {
  Vocabulary: ['w1', 'w2', 'w3', 'game'],
  Sentence: ['ks1', 'ks2'],
  Speaking: ['speak'],
  Reading: ['rq'],
  Listening: ['lq'],
  Grammar: ['gq'],
};

const PHONICS_STUDY_PARTS = {
  Vocabulary: ['w1', 'w2'],
  Game: ['wu', 'game'],
};

const AchievementGraph = ({ cols, myGradeData, classGradeData, isPhonics }) => {
  const [refresh, setRefresh] = useState(0);

  const graphData = useMemo(() => {
    if (myGradeData && cols && classGradeData) {
      const colsForGraph = cols.map((col) => {
        let name = '';
        for (const [key, val] of Object.entries(isPhonics ? PHONICS_STUDY_PARTS : STUDY_PARTS)) {
          if (val.includes(col)) {
            name = key;
            break;
          }
        }
        return name;
      });

      const final = [...new Set(colsForGraph)];

      setRefresh((prev) => prev + 1); //랜더링 문제 때문에 추가함.

      return final.map((part_name) => {
        const gradeInfo = Object.entries(myGradeData).reduce(
          (acc, cur) => {
            const [key, grade] = cur;
            if (!isNaN(grade)) {
              const parts = isPhonics ? PHONICS_STUDY_PARTS : STUDY_PARTS;
              if (parts[part_name]?.includes(key)) {
                acc.sum += grade;
                acc.count++;
              }
            }
            return { ...acc };
          },
          { sum: 0, count: 0 },
        );

        const { sum, count } = gradeInfo;
        const myGrade = count > 0 ? Math.round(sum / count) : 0;

        const { class_sum, class_count } = Object.entries(classGradeData).reduce(
          (acc, cur) => {
            const [key, grade] = cur;
            if (!isNaN(grade)) {
              const parts = isPhonics ? PHONICS_STUDY_PARTS : STUDY_PARTS;
              if (parts[part_name].includes(key)) {
                acc.class_sum += grade;
                acc.class_count++;
              }
            }
            return { ...acc };
          },
          { class_sum: 0, class_count: 0 },
        );

        const classGrade = count > 0 ? Math.round(class_sum / class_count) : 0;

        return {
          name: part_name,
          '내 점수': myGrade,
          '반 점수': classGrade,
        };
      });
    }
  }, [myGradeData, classGradeData, cols, isPhonics]);
  // const myGradeData2 = useMemo(()=>{
  //   const tmp ={Vocabulary: 0,
  //     Sentence: 0,
  //     Speaking: 0,
  //     Reading: 0,
  //     Listening: 0,}

  //     if(myGradeData) {myGradeData.map((e)=>{
  //       tmp.Vocabulary += e.words;
  //       tmp.Sentence += e.sentences;
  //       tmp.Speaking += e.speak;
  //       tmp.Reading += e.reading;
  //       tmp.Listening += e.listening;

  //     })}
  //     return tmp;
  // },[myGradeData])
  const graphData2 = useMemo(() => {
    const tmp = [];
    ['Vocabulary', 'Sentence', 'Speaking', 'Reading', 'Listening'].map((e) => {
      if (Object.keys(myGradeData).includes(e) && Object.keys(classGradeData).includes(e)) {
        // console.log(myGradeData[e],classGradeData[e],e,'kkk')
        tmp.push({ name: e, '내 점수': isNaN(Math.round(myGradeData[e]))?'':Math.round(myGradeData[e]), '반 점수': isNaN(Math.round(classGradeData[e]))?'':Math.round(classGradeData[e]) });
      }
    });
    return tmp
  }, [myGradeData, classGradeData, isPhonics]);
  // console.log(myGradeData,'myGradeData')
  // console.log(graphData2, 'gd');
  return (
    <Col span={24}>
      <Title level={4}>성취도 그래프</Title>
      <div className="graph-wrapper" style={{ maxWidth: 550, margin: '0 auto' }}>
        <ResponsiveContainer width="100%" height={200} key={`graph-container-${refresh}`}>
          <BarChart
            width={200}
            height={100}
            data={graphData2}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            style={{ fontSize: '9px' }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[0, 100]} />
            <Legend wrapperStyle={{ fontSize: '10px' }} />
            <Bar dataKey="내 점수" fill="#8884d8" label={{ position: 'top' }} barSize={20} />
            <Bar dataKey="반 점수" fill="#82ca9d" label={{ position: 'top' }} barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Col>
  );
};

export default AchievementGraph;
