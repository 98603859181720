import { gql } from '@apollo/client';

export const GET_TEST_AND_ELIBRARY_RESULT_FOR_REPORTCARD = gql`
  query getTestReportList($class_idx: Int!, $user_idx: Int!, $start_date: String!, $end_date: String!) {
    getTestReportList(class_idx: $class_idx, user_idx: $user_idx, start_date: $start_date, end_date: $end_date) {
      idx
      title
      assigned_test_users {
        user_idx
        score
      }
      tests {
        total_questions
      }
    }
    # studentElibraryStudyStatis(user_idx: $user_idx, start: $start_date, end: $end_date) {
    #   read_book_count
    #   average_grade
    # }
  }
`;

export const GET_ELIBRARY_RESULT_FOR_REPORTCARD = gql`
  query studentElibraryStudyStatis($user_idx: Int!, $start_date: String!, $end_date: String!) {
    studentElibraryStudyStatis(user_idx: $user_idx, start: $start_date, end: $end_date) {
      read_book_count
      average_grade
      recording_grade
      goal_count
      book_count_per_weeks
    }
  }
`;

export const REPORTCARD_LIST_FOR_STUDENT = gql`
  query culpReportCardListForStudent($class_idx: [Int!]!, $user_idx: Int!) {
    culpReportCardListForStudent(class_idx: $class_idx, user_idx: $user_idx) {
      idx
      comment_ko
      comment_en
      study_status_result
      score_result
      idate
      class_idx
      class {
        name
        book_code
        class_book {
          idx
          book_code
          begin_date
          book {
            book_sub {
              title
            }
          }
        }
        book {
          book_sub {
            title
          }
        }
      }
      user {
        idx
        name
      }
    }
  }
`;

export const STUDENT_REPORTCARD_LIST = gql`
  query culpStudentReportCardList($user_idx: Int!) {
    culpStudentReportCardList(user_idx: $user_idx) {
      idx
      comment_ko
      comment_en
      study_status_result
      score_result
      idate
      class_idx
      class {
        name
        class_book{
          book_code
          book{
            book_sub{
              title
            }
          }
        }
        class_teacher {
          user {
            name
          }
        }
        book_code
        book {
          book_sub {
            title
          }
        }
      }
      user {
        idx
        name
      }
    }
  }
`;
