import React, { useState, useMemo, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Checkbox, Button, Dropdown, Menu, Space, Input, Pagination } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CustomTable from 'src/components/common/CustomTable';
import { useHistory } from 'react-router-dom';
import * as axios from 'axios';
import * as _ from 'lodash';
import BookrMenuGroup from 'src/components/common/BookrMenuGroup';
import { isAfterSchool } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const Bookr = () => {
  //
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //
  const [searchValue, setSeachValue] = useState('');
  const history = useHistory();
  // const [filterTable, setFilterTable] = useState(null);
  // const query = new URLSearchParams(props.location.search);
  // const searchPage = query.get('page');
  // const isRefresh = query.get('refresh');
  const [pg, setPg] = useState({ current: 1, pageSize: 10 });
  // const [checkedList, setCheckedList] = useState(new Array(books.length).fill(false));
  const [ch, setCh] = useState(
    books.map((e) => {
      const obj = {
        id: e.id,
        value: false,
      };
      return obj;
    }),
  );
  // const [searchValue, setSearchValue] = UseHistoryState(
  //   {
  //     level: 1,
  //     text: null,
  //     page: 0,
  //   },
  //   'we_search',
  // );
  const companyName = useSelector(classStoreData);
  const setBookLevel = (book) => {
    if (book.terms.includes(21)) {
      return { ...book, level: 1, cefr: setBookCEFR(book) };
    } else if (book.terms.includes(22)) {
      return { ...book, level: 2, cefr: setBookCEFR(book) };
    } else if (book.terms.includes(23)) {
      return { ...book, level: 3, cefr: setBookCEFR(book) };
    } else if (book.terms.includes(24)) {
      return { ...book, level: 4, cefr: setBookCEFR(book) };
    } else if (book.terms.includes(25)) {
      return { ...book, level: 5, cefr: setBookCEFR(book) };
    } else if (book.terms.includes(26)) {
      return { ...book, level: 6, cefr: setBookCEFR(book) };
    } else {
      return { ...book, level: 0, cefr: setBookCEFR(book) };
    }
  };
  const setBookCEFR = (book) => {
    if (book.terms.includes(5949)) {
      return 'pre-A1';
    } else if (book.terms.includes(5909)) {
      return 'A1';
    } else if (book.terms.includes(5910)) {
      return 'A2';
    } else if (book.terms.includes(5911)) {
      return 'B1';
    } else if (book.terms.includes(5912)) {
      return 'B2';
    } else if (book.terms.includes(5913)) {
      return 'C1';
    } else if (book.terms.includes(5914)) {
      return 'C2';
    }
  };
  const fetchBooks = async () => {
    try {
      setLoading(true);
      const {
        data: {
          result: { list },
        },
      } = await axios.get('https://cdn.cloubot.com/BOOKR/books.json');
      list.map((e, i) => {
        e.no = i + 1;
      });
      setBooks(
        list.map((book) => {
          return setBookLevel(book);
        }),
      );
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchBooks();
  }, []);

  const [level, setLevel] = useState('All');
  const [type, setType] = useState('All');
  const [filteredbook, setFilteredbook] = useState([]);
  const bookSelf = () => {
    //divide in 10 book list
    if (!loading) {
      if (level === 'All' && type === 'All' && searchValue === '') {
        const list = books.reduce((acc, book, key) => {
          // const index = Math.floor(key);
          // book.no = key + 1;
          acc.push(book);
          return acc;
        }, []);
        setFilteredbook(list);
      } else {
        const list2 = books.reduce((acc, cur, key) => {
          const levelfilter = level !== 'All' ? parseInt(cur.level) === parseInt(level) : true;
          const typefilter = type !== 'All' ? cur.type === type : true;
          const searchfilter = searchValue !== '' ? cur.title.toLowerCase().includes(searchValue.toLowerCase()) : true;
          if (levelfilter && typefilter && searchfilter) {
            // cur.no = key + 1;
            acc.push(cur);
          }
          return acc;
        }, []);
        setFilteredbook(list2);
      }
    }
  };
  useEffect(() => {
    bookSelf();
  }, [books, type, level, searchValue, loading]);
  // useEffect(() => {
  //   // setFilterTable(null);
  //   handleSearch(searchValue);
  //   // const filter =
  // }, [level]);
  // useEffect(() => {
  //   // setCheckedList(new Array(filterTable===null?books.length:filterTable.length).fill(false));
  //   setCh(bookSelf);
  // }, [filterTable]);
  useEffect(() => {
    setPg({
      ...pg,
      current: 1,
    });
  }, [filteredbook, level, type]);

  useEffect(() => {
    setCh(
      filteredbook.map((e) => {
        const obj = {
          id: e.id,
          value: false,
        };
        return obj;
      }),
    );
  }, [books, filteredbook]);

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  // const handleSearch = (value) => {
  //   setSeachValue(value);
  //   const filter = bookSelf.filter((book) => {
  //     return book.title.toLowerCase().includes(value.toLowerCase());
  //   });
  // };
  // const handleSearch = (value) => {
  //   setSeachValue(value);
  //   const filterTable = bookSelf.filter((book) => {
  //     return Object.keys(book).some((field) => {
  //       console.log('flter-->', book[field]);
  //       return String(book[field]).toLowerCase().includes(value.toLowerCase());
  //     });
  //   });
  //   console.log(filterTable, 'ft');
  //   setFilterTable(filterTable);
  // };
  function onChange(e, idx) {
    // checkedList[idx] = e.target.checked;
    ch[ch.findIndex((element) => element.id === idx)] = { id: idx, value: e.target.checked };
    setCh(JSON.parse(JSON.stringify(ch)));
  }
  const currentPage = (i) => {
    return Math.floor(i / pg.pageSize) === pg.current - 1;
  };
  function onCheckAllChange(e) {
    if (e.target.checked) {
      const arr2 = _.cloneDeep(ch);
      const checked = filteredbook
        .filter((e, i) => {
          return currentPage(i);
        })
        .map((e) => {
          const obj = {
            id: e.id,
            value: true,
          };
          return obj;
        });
      const unChecked = arr2.filter((e, i) => {
        return !currentPage(i);
      });
      setCh([...checked, ...unChecked].sort((a, b) => a.id - b.id));
    } else {
      setCh([]);
    }
  }
  if (loading) {
    return <div className="wait-comment">로딩중...</div>;
  }
  if (error) {
    return <div className="wait-comment">에러가 발생했습니다.</div>;
  }
  const handleAssignData = () => {
    let data = [];
    filteredbook.forEach((item, index) => {
      ch.forEach((checked, index2) => {
        if (item.id === checked.id && checked.value) {
          data.push(item);
        }
      });
    });
    if (data.length > 0) {
      localStorage.setItem('selectedBooks', JSON.stringify(data));
      history.push('/bookr/assign');
    } else {
      alert('도서를 선택해주세요');
    }
  };

  const lv = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setLevel('All')}>
          All
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setLevel('1')}>
          level 1
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setLevel('2')}>
          level 2
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setLevel('3')}>
          level 3
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setLevel('4')}>
          level 4
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setLevel('5')}>
          level 5
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setLevel('6')}>
          level 6
        </a>
      </Menu.Item>
    </Menu>
  );
  const ty = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setType('All')}>
          All
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setType('book')}>
          Book
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setType('flashcard')}>
          Flashcard
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setType('karaoke')}>
          Song
        </a>
      </Menu.Item>
    </Menu>
  );
  const typeFx = (type) => {
    if (type === 'All') return 'Type';
    if (type === 'book') return 'Book';
    if (type === 'karaoke') return 'Song';
    if (type === 'flashcard') return 'FlashCard';
  };
  const columns = [
    {
      title: () => {
        const tmp = ch
          .filter((e, i) => {
            return currentPage(i);
          })
          .map((e) => {
            return e.value;
          });
        return <Checkbox checked={tmp.filter((s) => s).length === pg.pageSize} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox
              id={record.id}
              checked={ch[ch.findIndex((element) => element.id === record.id)]?.value}
              onChange={(e) => onChange(e, record.id)}
            ></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (text, record, idx) => {
        return <div>{(pg.current - 1) * pg.pageSize + idx + 1}</div>;
      },
    },
    {
      title: 'Image',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 130,
      padding: 0,
      render: (text, record) => {
        const url = record.coverShortUrls?.original_s || record.coverUrls?.original_s || record.coverUrls.original;
        return <img style={{ width: '65px' }} src={url} />;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => a.title && a.title.localeCompare(b.title),
    },

    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      width: 100,
    },
    {
      title: 'CEFR',
      dataIndex: 'cefr',
      key: 'id',
      align: 'center',
      width: 100,
      render: (text, record) => {
        const noDataCase = (lv) => {
          switch (lv) {
            case 6:
              return 'B2';
            case 5:
              return 'B1';
            case 4:
              return 'A2';
            case 3:
              return 'A1';

            default:
              return 'pre-A1';
          }
        };
        return <div>{text ? text : noDataCase(record.level)}</div>;
      },
    },

    {
      title: 'Book Code',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 120,
    },
  ];

  return (
    <>
      <div className="library-con" style={{ margin: '0 auto' }}>
        <div className="main-inner-wrap library float__box">
          <div className="section__inner">
            {(isAfterSchool(companyName) || ['classboom', 'cleveredu2'].includes(companyName)) && <BookrMenuGroup currentMenu={'assign'} />}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="library__top float__box">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="float__right">
                  <Dropdown overlay={lv} trigger={['click']}>
                    <Link onClick={(e) => e.preventDefault()}>
                      <Space>
                        {level === 'All' ? 'Level' : level}
                        <DownOutlined />
                      </Space>
                    </Link>
                  </Dropdown>
                </div>
                <div className="float__right">
                  <Dropdown overlay={ty} trigger={['click']}>
                    <Link onClick={(e) => e.preventDefault()}>
                      <Space>
                        {typeFx(type)}
                        <DownOutlined />
                      </Space>
                    </Link>
                  </Dropdown>
                </div>
                {/* <Link to="/bookr/assign" className="headitems-add"> */}
                <Button style={{ color: '#289428', marginLeft: 5 }} onClick={handleAssignData}>
                  Assign
                </Button>
                {/* </Link> */}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input.Search
                  className="inputsearch-classroom"
                  placeholder="title search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  value={searchValue}
                />
                {/* <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                  <RedoOutlined />
                </CustomButton> */}
              </div>
            </div>

            <div className="library__content">
              <div className="library__box">
                <CustomTable
                  className="contenttest-table"
                  dataSource={filteredbook}
                  columns={columns}
                  loading={loading}
                  bordered
                  scroll={{ y: 'calc(100vh - 244px)' }}
                  pagination={pg}
                  onChange={(pagination) => {
                    setPg(pagination);
                  }}
                  size="big"
                  color="#edf3fb"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Bookr);
