import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// import {MidiumScripter} from "./Scripter";
import PageNum from './PageNum';
import PopUp from './PopUp';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import SwitchButtonBox from './SwitchButtonBox';
import Keyboard from './Keyboard';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';

const completeSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/dingdong.mp3';
const oopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
const greatSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3';
const tryAgainSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/try-again.mp3';
const MAX_WRONG_COUNT = 2;

const PhonemeImageWrite = ({ problem, steps, setSteps, onFinish }) => {
  const [answerComplete, setAnswerComplete] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadCompletes, setLoadCompletes] = useState([]);
  const [correctWrongSound, setCorrectWrongSound] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const [wrongCount, setWrongCount] = useState(0);
  // const [mode, setMode] = useState(false);
  const [getKey, setGetKey] = useState([]);
  const [currentKeyboardStep, setCurrentKeyboardStep] = useState(0);

  const safeSteps = steps > problem.length - 1 ? problem.length - 1 : steps;
  const currentActiveStep = steps * 2 + currentKeyboardStep;
  const currentWords = problem[safeSteps].words || [];

  useEffect(() => {
    if (steps) {
      setCurrentKeyboardStep(0);
    }
  }, [steps]);

  // const handleToggle = () => {
  // 	setMode(prevMode => !prevMode);
  // };

  // useEffect(() => {
  // 	if (isError) {
  // 		setMode(false);
  // 	}
  // }, [isError]);

  const handleMyScriptLoaded = (index) => {
    const copy = _.cloneDeep(loadCompletes);
    copy[index] = true;
    setLoadCompletes(copy);
  };
  const handleAnswerComplete = (index, isCorrect) => {
    console.log('answerComplete', answerComplete);
    const copy = _.cloneDeep(answerComplete);
    copy[index] = true;
    setAnswerComplete(copy);
    setCorrectWrongSound(isCorrect ? greatSound : oopsSound);

    if (currentKeyboardStep < 2 - 1) {
      setCurrentKeyboardStep(currentKeyboardStep + 1);
    }
  };
  const handleWrong = () => {
    setWrongCount(wrongCount + 1);
    if (wrongCount >= MAX_WRONG_COUNT - 1) {
      handleAnswerComplete(currentKeyboardStep, false);
      setWrongCount(0);
    } else {
      setCorrectWrongSound(tryAgainSound);
    }
  };

  const handleSoundEnd = () => {
    setCorrectWrongSound(undefined);
    if (correctWrongSound == completeSound) {
      setSteps(steps + 1);
    } else if (correctWrongSound === oopsSound || correctWrongSound === greatSound) {
      console.log('great', answerComplete);
      if (answerComplete.length > 0 && answerComplete.every((item) => item === true)) {
        if (steps >= problem.length - 1) {
          onFinish();
        } else {
          setCorrectWrongSound(completeSound);
        }
      }
    }
  };

  useEffect(() => {
    if ((loadCompletes.length > 0 && loadCompletes.every((item) => item === true)) || isError) {
      setIsLoading(false);
    }
  }, [loadCompletes]);

  useEffect(() => {
    setAnswerComplete(Array(problem[safeSteps].words.length).fill(false));
    setLoadCompletes(Array(problem[safeSteps].words.length).fill(false));
    // eslint-disable-next-line
  }, [safeSteps]);

  // useEffect(() => {
  // 	if (answerComplete.length > 0 && answerComplete.every(item => item === true)) {
  // 		if (steps >= problem.length - 1) {
  // 			//onFinish();
  // 		} else {
  // 			//setCorrectWrongSound(completeSound);
  // 			//setSteps(steps + 1);
  // 		}
  // 	}
  // 	// eslint-disable-next-line
  // }, [answerComplete]);

  return (
    <>
      <MainBox className="lv4_review8_content main_content onewrap">
        <S.BorderColumnBox style={{height:'50%', justifyContent:'space-evenly'}}>
          <PageNum num={safeSteps + 1} total={problem.length} style={{position: 'absolute', top: '20px', right: '20px',fontWeight:'bold',fontSize:'1.3rem'}} />
          <ExTxt className="ex_text">
            {_.map(problem[safeSteps].phoneme, (item) =>
              item !== '-' ? <strong style={{ color: '#2e86de' }}>{item}</strong> : <>{item}</>,
            )}
            {/* {isError ? (
						<SwitchButtonBox style={{marginRight: "50px", marginTop: "5px"}} toggleDevice={handleToggle} device={false} />
					) : (
						<SwitchButtonBox style={{marginRight: "50px", marginTop: "5px"}} toggleDevice={handleToggle} device={true} />
					)} */}
          </ExTxt>
          <S.ColumnFlex style={{ justifyContent: 'space-around' }} className="word">
            <S.RowFlex style={{ justifyContent: 'center' }} className="top">
              <div className="img_list">
                {/* {mode ? (
								problem[safeSteps].words.map((item, key) => (
									<p key={`image-key${key}`} className="img_box active">
										<img src={`${problem[safeSteps].imageBaseUrl}image_${item}.png`} alt="보기 이미지" />
									</p>
								))
							) : ( */}
                <ImgBox key={`image-key1`} className="img_box active">
                  <img
                    style={{ height: '15vh' }}
                    src={`${problem[safeSteps].imageBaseUrl}image_${currentWords[currentKeyboardStep]}.png`}
                    alt="보기 이미지"
                  />
                </ImgBox>
                {/* )} */}
              </div>
            </S.RowFlex>
          </S.ColumnFlex>
        </S.BorderColumnBox>
        <S.PenKeyboardWrapMain className="bottom_box" style={{ marginTop: 15 }}>
          <S.PenKeyboardWrap style={{ padding: '10px 0' }} className="pen_keyboard_wrap">
            <Keyboard
              enabledWritePreview={true}
              imageWordWrite={true}
              getKey={getKey}
              setGetKey={setGetKey}
              onWriteEnd={() => {
                currentWords[currentKeyboardStep] === getKey.join('') ? handleAnswerComplete(currentKeyboardStep, true) : handleWrong();
                setGetKey([]);
              }}
            />
          </S.PenKeyboardWrap>
        </S.PenKeyboardWrapMain>
      </MainBox>
      <SoundEffectPM url={correctWrongSound} onEnd={handleSoundEnd} />
      {/* {isLoading && <PopUp popUpType="loading" />} */}
    </>
  );
};

export default PhonemeImageWrite;

const MainBox = styled(S.ColumnFlex)`
  /* justify-content: space-around; */
  position: relative;
  align-self: stretch;
  height: calc(100% - 70px);
`;
const ExTxt = styled.p`
  font-size: 50px;
  text-align: center;
  color: #aaa;
  margin: 0;
  padding: 0;
`;
const ImgBox = styled.p`
  border: 3px solid #feca57;
  padding: 10px 30px;
  border-radius: 16px;
  margin: 0;
`;
