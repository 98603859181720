import { gql } from '@apollo/client';

export const VIDEOS_LIST = gql`
  query videosList(
    $is_mine: Boolean!
    $is_public: Boolean
    $type: Int
    $class_idx: Int
    $q: String
    $take: Int
    $page: Int
    $from_date: String
    $to_date: String
  ) {
    videosList(
      is_mine: $is_mine
      is_public: $is_public
      type: $type
      class_idx: $class_idx
      q: $q
      take: $take
      page: $page
      from_date: $from_date
      to_date: $to_date
    ) {
      idx
      title
      video_id
      data
      is_public
      is_shared
      user {
        idx
        name
      }
      class {
        idx
      }
      idate
    }
  }
`;
