import React, { useState, useMemo, useEffect } from 'react';
// import { Switch, Link } from 'react-router-dom';
import { Form, Input, Alert } from 'antd';
import styled from 'styled-components';
import { LOGIN_FOR_META_EXAM } from 'src/operations/queries/getLevelTest';
import { useLazyQuery } from '@apollo/client';
import Loading from 'src/components/common/Loading';
import { Redirect } from 'react-router-dom';
import { storage } from 'src/utils/hooks';
import { isLogedIn } from 'src/apollo/cache';
// import { settings } from 'src/dummy/settings.js';
// import { GET_USER_INFO } from 'src/operations/queries/campus';
// const { Option } = Select;

const InnerWrap = styled.div`
  background-color: #fff;
  width: 60%;
  height: auto;
  min-height: 650px;
  padding: 2rem 1rem;
  margin: 5rem auto;
  border-radius: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  & {
    .login-top-logo {
      width: 80%;
      margin: 0 auto;
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    min-height: 800px;
    height: 30rem;
    & {
      .inner-header {
        top: 0;
        position: relative;
        width: calc(90% - 10rem);
      }

      .btn.fixed_btn {
        bottom: 0;
        padding: 2rem 4rem;
        margin: 2rem auto;
        height: auto;
        width: auto;
        a {
          font-size: 2rem;
          position: relative;
        }
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 9) {
    min-height: 800px;
    height: 30rem;
    & {
      .inner-header {
        top: 1rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1920px) {
    .inner-header {
      width: calc(100% - 10rem);
      display: flex;
      position: relative;
      margin: 1rem 0;
      transform: translate(0px, 0px);
      top: 0rem;
      left: auto;
      h2 {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 10) {
    & {
      .btn {
        padding: 1rem 1rem;
        margin: 2rem auto;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 9) {
    width: 1000px;
    padding: 2rem 2rem;
    max-height: 750px;
    margin: 3rem auto;

    & {
      .inner-header {
        width: 100%;
        max-width: 1300px;
        padding: 0 4rem;

        h2 {
          font-size: 1.4rem;
        }
      }
      .btn.fixed_btn {
      }
    }
  }

  @media (max-width: 1280px) {
    height: 80%;
    max-width: 100%;
    max-height: 100%;
    width: 900px;
    & {
      #student-register .userInfo-wrap {
        margin: 2rem auto 0;
      }

      .btn.fixed_btn {
        position: relative !important;
        bottom: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    .inner-header {
      position: relative;
      top: 0;
      transform: translate(0, 0);
      width: 100%;
    }
  }

  @media (max-width: 1680px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 25rem);
    height: auto;

    & {
      .inner-header {
        top: 0;
        margin: 1rem 0 1rem;
        width: 650px;
      }

      .fixed_btn {
        bottom: 0;
      }
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    margin: 2rem auto;

    & {
      .inner-header {
        top: 0;
        width: 100%;
        max-width: 1150px;
        margin: 0 8rem;

        h2 {
          font-size: 1.4rem;
        }
      }

      .btn.fixed_btn {
        bottom: 0;
        margin: 2rem 0 1rem;
      }
    }
  }

  @media screen and (min-width: 1536px) and (max-height: 863px) {
    & {
      .inner-header {
        width: 650px;
        h2 {
          font-size: 1.5rem;
        }
      }

      #student-register {
        max-width: 1000px;
        tbody {
          tr {
            td {
              .ant-form-item {
                margin-right: 4rem;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1536px) {
    height: auto;
    margin: 3rem auto;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    height: calc(60vh - 3rem);
    margin: 5rem auto;

    & {
      .inner-header {
        width: 95%;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    height: 700px;
    margin: 5rem auto;
  }

  @media screen and (max-width: 1366px) {
    height: auto;
    // min-height: 700px;
    //  margin: 5rem auto;
    margin: 2rem auto 0;
    padding: 1rem 1rem;
    width: 600px;

    & {
      .inner-header {
        width: 100%;
        margin: 1rem auto;
        //  width: 650px;
      }

      .btn.fixed_btn {
        margin: 1rem auto;
        a {
          font-size: 1rem;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    //height: calc(60vh - 3rem);
    height: auto;
    margin: 3rem auto;
  }

  @media (max-width: 1400px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 100%;
    margin: 0 auto;
    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 800px;

        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) and (aspect-ratio: 16 / 10) {
    & {
      .inner-header {
        margin: 1rem 0 1rem;
      }
    }
  }

  @media (max-width: 1366px) {
    width: 800px;
    min-height: auto;
  }

  @media (max-width: 1280px) {
    width: 800px;
    min-height: auto;
    & {
      .btn {
        margin: 1rem auto;
      }
    }
  }

  @media (max-width: 1280px) and (orientation: portrait) {
    // width: 800px;
    height: auto;
  }

  @media (max-width: 1280px) and (aspect-ratio: 16 / 10) {
    margin: 2rem auto;
    width: 800px;
    min-height: auto;
    & {
      .btn.fixed_btn {
        bottom: 0;
        padding: 0.8rem 1rem;
        height: auto;
        max-height: 100%;
        margin: 1rem auto;
      }
      .inner-header {
        top: 0;
        width: 100%;
        padding: 0 2rem;
      }
    }
  }

  @media (max-width: 1280px) and (aspect-ratio: 16 / 9) {
    margin: 0rem auto;
    height: auto;

    max-height: 100%;

    & {
      .btn.fixed_btn {
        bottom: 0;
      }
      .inner-header {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 1200px) and (max-height: 800px) {
    & {
      .inner-header {
        top: 0;
        width: 100%;
      }
    }

    &.inner-header {
      width: 100%;
      top: 0;
      h2 {
        font-size: 1.4rem;
      }
      top: 0rem;
    }
  }

  @media (max-width: 1200px) {
    & {
      inner-header {
        width: 65%;
        top: 8rem;

        h2 {
          font-size: 1.3rem;
        }
      }

      .btn.fixed_btn {
      }
    }
  }

  @media (max-width: 1024px) and (aspect-ratio: 16 / 9) {
    width: 100%;
    max-height: 100%;
    margin: 0 auto;

    height: auto;
    & {
      .inner-header {
        width: 100%;
        max-width: 730px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 550px;
    // margin: 2rem auto 0;
  }

  @media (max-width: 1024px) and (orientation: portrait) {
    width: 550px;
    margin: 2rem auto;
  }

  @media screen and (max-width: 1024px) and (orientation: portrait) {
    height: auto;
  }

  @media screen and (max-width: 820px) and (orientation: portrait) {
    width: 550px;
    min-height: auto;
    padding: 1rem 1rem 2rem;
    justify-content: center;
  }

  @media screen and (max-width: 580px) {
    width: 430px;
  }

  @media screen and (max-width: 450px) {
    width: 350px;
    height: auto;
    margin: 2rem auto;
    & {
      .inner-header {
        h2 {
          font-size: 1.2rem;
        }
      }
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }

  @media screen and (max-width: 380px) {
    width: 300px;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 35px;
    & {
      .inner-header {
        h2 {
          font-size: 1.1rem;
        }
      }
      .btn.fixed_btn {
        bottom: 0;
        position: relative !important;
      }
    }
  }
`;

const MetaLogin = () => {
  //this is login there is no user info

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const company_idx = params.get('company_idx') ? Number(params.get('company_idx')) : null;
  const isWEnglish = company_idx === 1016 ? true : false;
  const [loginLevelTest, { loading, data: dataLogin, error }] = useLazyQuery(LOGIN_FOR_META_EXAM);
  const [form] = Form.useForm();
  const [studentNamePart1, setstudentNamePart1] = useState('');
  const [studentNamePart2, setstudentNamePart2] = useState('');
  const [phone, setphone] = useState('');
  const [dob, setdob] = useState('');
  const [fillingError, setfillingError] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const onClose = () => {
    setfillingError(false);
  };
  const onSubmit = () => {
    if (!phone || !studentNamePart1 || !studentNamePart2 || !dob) {
      setfillingError(true);
      seterrorMessage('Please fill all fields');
      return;
    }
    if (isNaN(phone)) {
      setfillingError(true);
      seterrorMessage('Please enter a valid phone number');
      return;
    }
    if (isNaN(dob)) {
      setfillingError(true);
      seterrorMessage('Please enter a valid date of birth in yymmdd format');
      return;
    }
    if (window.confirm(`Is your name ${studentNamePart1.toLowerCase()} ${studentNamePart2.toLowerCase()}?`)) {
      setfillingError(false);
      seterrorMessage('');
      const variables = {
        phone: '010' + phone,
        student_name: `${studentNamePart1.toLowerCase()} ${studentNamePart2.toLowerCase()}`,
        dob: '20' + dob.slice(0, 2) + '-' + dob.slice(2, 4) + '-' + dob.slice(4, 6),
        type: 'meta',
      };
      localStorage.setItem(
        'meta_login_data',
        JSON.stringify({
          phone: phone,
          studentNamePart1,
          studentNamePart2,
          dob,
        }),
      );
      loginLevelTest({ variables: variables });
      form.resetFields();
    } else return;
  };

  const subdomain = useMemo(() => {
    if (window.location.hostname) {
      return window.location.hostname.split('.')[0];
    }
    return '';
  }, []);

  const data = useMemo(() => {
    if (dataLogin) {
      localStorage.removeItem('meta_login_data');
      storage.setItem('culp_token', JSON.stringify(dataLogin.loginForLevelTest));
      if (window.Android && window.Android.SaveUserToken) {
        window.Android.SaveUserToken(JSON.stringify(dataLogin.loginForLevelTest));
      }
      isLogedIn(!!dataLogin?.loginForLevelTest);
      return dataLogin;
    }
    return undefined;
  }, [dataLogin]);

  useEffect(() => {
    if (error) {
      alert(error?.message || '잘못된 정보입니다.');
      window.location.reload();
      return;
    }
  }, [error]);
  useEffect(() => {
    const meta_login_data = JSON.parse(localStorage.getItem('meta_login_data'));
    if (meta_login_data) {
      setstudentNamePart1(meta_login_data.studentNamePart1);
      setstudentNamePart2(meta_login_data.studentNamePart2);
      setdob(meta_login_data.dob);
      setphone(meta_login_data.phone);
    }
  }, []);
  const metaGuideShown = useMemo(() => {
    return (storage.getItem(`meta_guide_shown${data?.orderItemList[0].idx}`) || '0') === '1';
  }, [data?.orderItemList]);
  if (data?.orderItemList) {
    storage.setItem('meta_idx_list', JSON.stringify(data.orderItemList.map((order) => order.idx)));
    storage.setItem(`meta_guide_shown${data?.orderItemList[0].idx}`, '1');
  }
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {data?.orderItemList ? (
            <Redirect
              to={{
                pathname: metaGuideShown ? `/MetaExam/MetaExamType` : `/MetaExam/InstructionalGuideMeta`,
                state: { idx_list: data.orderItemList.map((order) => order.idx) },
              }}
            />
          ) : (
            <>
              <div className={isWEnglish ? 'meta-con meta-full-con w-english-bg' : 'meta-con meta-full-con'}>
                <InnerWrap>
                  <div className="inner-header login-header">
                    {!isWEnglish && <img src="../../images/metalogin_logo.png" alt="login_top_logo" className="login-top-logo" />}
                  </div>

                  <div className="metalogin-wrap">
                    <h1>Login</h1>
                    <Form.Item style={{ justifyContent: 'center' }} name="text" label="Name ">
                      <Input
                        bordered={true}
                        onChange={(e) => {
                          setstudentNamePart1(e.target.value);
                        }}
                        value={studentNamePart1}
                        className="partInput"
                        placeholder="Family Name"
                        style={{ marginRight: '0.9rem' }}
                      />
                      <Input
                        onChange={(e) => {
                          setstudentNamePart2(e.target.value);
                        }}
                        value={studentNamePart2}
                        className="partInput"
                        placeholder="First Name"
                      />
                    </Form.Item>

                    <Form.Item style={{ justifyContent: 'center' }} name="text" label="Student ID">
                      <Input
                        onChange={(e) => {
                          setdob(e.target.value);
                        }}
                        value={dob}
                        className="partInput"
                        maxLength="6"
                        placeholder="yymmdd"
                        style={{ marginRight: '5px' }}
                      />
                      {/* <DatePicker className="partInput" style={{ marginRight: '5px' }} /> */}
                      -
                      <Input
                        className="partInput"
                        style={{ marginLeft: '5px' }}
                        maxLength="8"
                        placeholder="12345678"
                        onChange={(e) => {
                          setphone(e.target.value);
                        }}
                        value={phone}
                      />
                    </Form.Item>

                    {fillingError && (
                      <Alert closable onClose={onClose} message={errorMessage} style={{ position: 'absolute', top: '50px' }} type="error" />
                    )}

                    <div
                      className="btn enter_btn"
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      <a href="javascript:;">Enter</a>
                    </div>
                  </div>
                  <img src="../../images/metalogin_footer.png" alt="login_btm_logo" className="login-btm-logo" />
                </InnerWrap>
                {!isWEnglish && (
                  <div className="report-footer-con">
                    <h5>
                      Powered by {'     '}
                      <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                        Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
                      </span>
                    </h5>
                    <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default MetaLogin;
