import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { userInfoVar } from 'src/apollo/cache';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FAVORITE_BOOK } from 'src/operations/mutations/externalUserStudy';
import { useMutation } from '@apollo/client';
import { ScheduleTwoTone } from '@ant-design/icons';
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CarouselContainer = styled.div`
  width: ${(props) => props.row * 174 + 'px'};
  margin: 0 18px;
  overflow: hidden;
  @media (max-width: 1280px) {
    width: ${(props) => props.row * 143 + 'px'};
  }
`;
const RowBox = styled(RowFlex)`
  transition: transform 0.5s ease-in-out;
  transform: translateX(-${(props) => props.currentPage * 174 * props.row}px);
  @media (max-width: 1280px) {
    transform: translateX(-${(props) => props.currentPage * 143 * props.row}px);
  }
`;
const CarouselWrapper = styled.div`
  display: flex;
  align-items: end;
`;
const BottomShadowWrapper = styled(RowFlex)`
  width: 100%;
  min-width: ${(props) => props.row * 174 + 'px'};
  justify-content: center;
  @media (max-width: 1280px) {
    min-width: ${(props) => props.row * 143 + 'px'};
  }
`;
const BottomShadow = styled.div`
  width: 90%;
  height: 10px;
  border-radius: 5px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  box-shadow: 3px 7px 5px rgba(0, 0, 0, 0.4);
  margin-bottom: 15px;
  min-width: ${(props) => props.row * 174 - 15 + 'px'};
  @media (max-width: 1280px) {
    min-width: ${(props) => props.row * 143 - 15 + 'px'};
  }
`;
const Carouselitem = styled.div`
  width: 154px;
  margin: ${(props) => props.margin};
  background-color: #fff;
  /* min-height: 205px; */
  /* border: 1px solid #7d7970; */
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 123px;
    /* height: 160px; */
  }
`;

const CarouselImageBox = styled.div`
  width: 154px;
  /* min-height: 154px; */
  /* background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: 151px 154px; */
  cursor: pointer;
  @media (max-width: 1280px) {
    width: 123px;
    /* min-height: 120px; */
    /* background-size: 118px 120px; */
  }
`;
const CarouselImg = styled.img`
  width: 154px;
  min-height: 154px;
  max-height: 210px;
  @media (max-width: 1280px) {
    width: 123px;
    min-height: 120px;
    max-height: 163px;
    /* background-size: 118px 120px; */
  }
`;
const BottomOutBox = styled(RowFlex)`
  width: 154px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 5px 3px 0;
  /* margin-top: 5px; */
  @media (max-width: 1280px) {
    padding: 5px 2px 0;
    width: 123px;
  }
`;
const CarouselBottomBox = styled.div`
  /* align-items: center;
  justify-content: center; */
  /* height: 35px; */
  text-align: center;
  color: #000;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  /* width: 154px; */
  font-size: 14px;
  @media (max-width: 1280px) {
    /* width: 120px; */
    /* height: 30px; */
    font-size: 13px;
  }
`;
const LVLIKEBOX = styled(RowFlex)`
  z-index: 1;
  left: 0px;
  margin-top: ${(props) => (props.isComicBook ? '-68px' : '-35px')};
  position: relative;
  width: 100%;
  justify-content: space-between;
  padding: 0 5px;
  align-items: end;
`;
const ComicBox = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  /* font-weight: 800; */
  height: 30px;
  line-height: 30px;
  /* width: 50px; */
  text-align: center;
  /* background-color: #fdff7d; */
  border-radius: 5px;
  padding: 0 5px;
  background-color: #92d050;
  min-width: 90px;
  margin-bottom: 3px;
  @media (max-width: 1280px) {
    /* margin-bottom: 2px; */
    min-width: 65px;
    font-size: 10px;
  }
`;
const LVBox = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #575757;
  /* font-weight: 800; */
  height: 30px;
  line-height: 30px;
  /* width: 50px; */
  text-align: center;
  /* background-color: #fdff7d; */
  border-radius: 5px;
  padding: 0 5px;
  background-color: rgba(255, 255, 255, 0.8);
  min-width: 90px;
  @media (max-width: 1280px) {
    /* margin-bottom: 2px; */
    min-width: 65px;
    font-size: 10px;
  }
`;
const LikeBox = styled(RowFlex)`
  font-size: 12px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  color: #575757;
  /* font-size: 17px; */
  height: 30px;
  /* width: 60px; */
  /* line-height: 30px; */
  padding: 0 5px;
  /* margin-bottom: 1px; */
  justify-content: end;
  align-items: center;
  @media (max-width: 1280px) {
    /* margin-bottom: 2px; */
  }
`;

const ArrowButton = styled.button`
  display: inline-block;
  width: 19px;
  height: 32px;
  font-size: 40px;
  font-weight: bolder;
  background: none;
  border: none;
  cursor: pointer;
  background-size: 19px 32px;
  background-repeat: no-repeat;
`;

const LeftArrow = styled(ArrowButton)`
  background-image: url('/images/book/arrow-prev.png');
  filter: grayscale(${(props) => (props.firstPage ? '100%' : 0)});
`;

const RightArrow = styled(ArrowButton)`
  background-image: url('/images/book/arrow-next.png');
  filter: grayscale(${(props) => (props.lastPage ? '100%' : 0)});
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Dot = styled.span`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#9a7357' : '#c9c2b4')};
  cursor: pointer;
`;
const LikeImage = styled.div`
  width: 17px;
  height: 15px;
  background-size: 17px 15px;
  background-image: url(${(props) => (props.toggle ? '/images/book/heart-on.png' : '/images/book/heart-off.png')});
  background-repeat: no-repeat;
  /* margin-left: 5px; */
  cursor: pointer;
  /* @media (max-width: 1280px) {
    width: 20px;
    height: 17px;
    background-size: 20px 17px;
  } */
`;
const CarouselitemCats = styled.div`
  width: 164px;
  margin: 0 14px;
  background-color: #fff;
  box-sizing: border-box;
  color: #212121;
  :hover {
    color: #0092ff;
  }
  @media (max-width: 1151px) {
    margin: 0 10px;
  }
`;
const CarouselImageBoxCats = styled.div`
  width: 164px;
  cursor: pointer;
  position: relative;
`;
const CarouselImgCats = styled.img`
  width: 164px;
  min-height: 164px;
  max-height: 210px;
  border-radius: 10px;
`;
const BottomOutBoxCats = styled(RowFlex)`
  width: 164px;
  height: 44px;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
  padding: 0 2px;
`;
const CarouselBottomBoxCats = styled.div`
  font-family: 'Be Vietnam Pro';
  text-align: start;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  line-height: 1.1;
`;
const LVLIKEBOXCats = styled(RowFlex)`
  z-index: 1;
  left: 0px;
  margin-top: -42px;
  position: relative;
  width: 100%;
  justify-content: space-between;
  padding: 0 12px;
  align-items: end;
`;
const LikeBoxCats = styled(RowFlex)`
  font-size: 12px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  color: #575757;
  height: 30px;
  padding: 0 5px;
  justify-content: end;
  align-items: center;
`;
const LikeImageCats = styled.div`
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-image: url(${(props) => (props.toggle ? '/images/cats/icon_heart_red.png' : '/images/cats/icon_heart.png')});
  background-repeat: no-repeat;
  cursor: pointer;
`;
const MarkCats = styled.img`
  width: 60px;
  height: 38px;
  position: absolute;
  top: 12px;
  right: -8px;
`;
const CarouselItem = ({ hideLike = false, data, margin = '0 10px', handleFav = () => {} }) => {
  const [favBook, { loading: loadingDelete }] = useMutation(FAVORITE_BOOK, {
    onCompleted: (res) => {
      handleFav();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  const handleLike = async (e) => {
    e.preventDefault();
    await favBook({ variables: { book_id: data?.book_no, type: 'levelup' } });
  };
  const isComicBook = data?.category2 === 'Graphic Novels' || data?.category3 === 'Graphic Novels' || data?.category4 === 'Graphic Novels';

  const bookState = useMemo(() => {
    if (!data?.selfStudy && data?.assigned_idx) {
      return { bookData: { ...data, assignedIdx: data?.assigned_idx }, assigned_idx: data?.assigned_idx };
    } else {
      return { bookData: data, assigned_idx: null };
    }
  }, [data]);

  return (
    <Link
      to={{
        pathname:
          userInfoVar()?.type > 0 && userInfoVar()?.company_name !== 'gnb'
            ? `/level-up/description/${data?.code}`
            : `/level-up/${data?.is_voca ? 'study' : `${data?.is_interactive ? 'epub-' : ''}reader`}/${data?.code}`,
        state: bookState,
      }}
    >
      <Carouselitem margin={margin}>
        <CarouselImageBox url={`https://cdn.cloubot.com/LEVELUP/covers/${data?.code}.png`} alt={`Item ${data?.title}`}>
          <CarouselImg src={`https://cdn.cloubot.com/LEVELUP/covers/${data?.code}.png`} />
          <LVLIKEBOX isComicBook={isComicBook || (!data?.selfStudy && data?.assigned_idx)}>
            <ColumnFlex>
              {isComicBook && <ComicBox>Comic Book</ComicBox>}

              {data?.level !== -1 ? (
                <LVBox>
                  {data?.level > 0 ? `mx ${String(data?.level).padStart(2, '0')}` : 'PH'}
                  {/* {!!data?.lexileVal && `(${data?.lexileVal}L)`} */}(
                  {data?.lexileVal < 0 ? `BR${Math.abs(data?.lexileVal)}L` : `${data?.lexile_val}L`})
                </LVBox>
              ) : (
                <div></div>
              )}
            </ColumnFlex>

            <ColumnFlex>
              {!data?.selfStudy && data?.assigned_idx && (
                <ScheduleTwoTone
                  style={{
                    padding: '3px',
                    background: 'rgba(255, 255, 255, 0.57)',
                    fontSize: '20px',
                    borderRadius: '5px',
                    zIndex: '111',
                    marginBottom: '3px',
                  }}
                />
              )}

              {!hideLike && (
                <LikeBox>
                  {/* {data?.fav_cnt || 0} */}
                  <LikeImage onClick={handleLike} toggle={data?.is_fav > 0} />
                </LikeBox>
              )}
            </ColumnFlex>
          </LVLIKEBOX>
        </CarouselImageBox>
        <BottomOutBox>
          <CarouselBottomBox>{data?.title}</CarouselBottomBox>
        </BottomOutBox>
      </Carouselitem>
    </Link>
  );
};
const CatsCarouselItem = ({ hideLike = false, data, margin = '0 10px', handleFav = () => {}, done = false, inprogress = false }) => {
  const [favBook, { loading: loadingDelete }] = useMutation(FAVORITE_BOOK, {
    onCompleted: (res) => {
      handleFav();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });
  const handleLike = async (e) => {
    e.preventDefault();
    await favBook({ variables: { book_id: data?.book_no, type: 'levelup' } });
  };
  const isComicBook = data?.category2 === 'Graphic Novels' || data?.category3 === 'Graphic Novels' || data?.category4 === 'Graphic Novels';

  const bookState = useMemo(() => {
    if (!data?.selfStudy && data?.assigned_idx) {
      return { bookData: { ...data, assignedIdx: data?.assigned_idx }, assigned_idx: data?.assigned_idx };
    } else {
      return { bookData: data, assigned_idx: null };
    }
  }, [data]);
  let markurl = null;
  if (done === true) {
    markurl = '/images/cats/mark_done.png';
  }
  if (inprogress === true) {
    markurl = '/images/cats/mark_inprogress.png';
  }

  return (
    <Link
      to={{
        pathname:
          userInfoVar()?.type > 0 && userInfoVar()?.company_name !== 'gnb'
            ? `/level-up/description/${data?.code}`
            : `/level-up/${data?.is_voca ? 'study' : `${data?.is_interactive ? 'epub-' : ''}reader`}/${data?.code}`,
        state: bookState,
      }}
    >
      <CarouselitemCats margin={margin}>
        <CarouselImageBoxCats url={`https://cdn.cloubot.com/LEVELUP/covers/${data?.code}.png`} alt={`Item ${data?.title}`}>
          <CarouselImgCats src={`https://cdn.cloubot.com/LEVELUP/covers/${data?.code}.png`} />
          {markurl && <MarkCats src={markurl} />}
          <LVLIKEBOXCats isComicBook={isComicBook || (!data?.selfStudy && data?.assigned_idx)}>
            <ColumnFlex></ColumnFlex>

            <ColumnFlex>
              {!data?.selfStudy && data?.assigned_idx && (
                <ScheduleTwoTone
                  style={{
                    padding: '3px',
                    background: 'rgba(255, 255, 255, 0.57)',
                    fontSize: '20px',
                    borderRadius: '5px',
                    zIndex: '111',
                    marginBottom: '3px',
                  }}
                />
              )}

              {!hideLike && (
                <LikeBoxCats>
                  <LikeImageCats onClick={handleLike} toggle={data?.is_fav > 0} />
                </LikeBoxCats>
              )}
            </ColumnFlex>
          </LVLIKEBOXCats>
        </CarouselImageBoxCats>
        <BottomOutBoxCats>
          <CarouselBottomBoxCats>{data?.title}</CarouselBottomBoxCats>
        </BottomOutBoxCats>
      </CarouselitemCats>
    </Link>
  );
};

const Carousel = ({ books, row = 4, column = 1, handleFav = () => {} }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages_minus1 = Math.ceil(books.length / (row * column)) - 1;
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages_minus1));
  };
  const slicedBooks = useMemo(() => {
    const tmp = [];
    for (let i = 0; i < totalPages_minus1 + 1; i++) {
      const tmp2 = [];
      const showItems = books.slice(i * (row * column), (i + 1) * (row * column));

      for (let j = 0; j < showItems.length; j += row) {
        const chunk = showItems.slice(j, j + row);
        tmp2.push(chunk);
        // if(i===totalPages_minus1){console.log(tmp2);}
      }
      // if(i===totalPages_minus1){
      //     let deficiency = column-Math.ceil(showItems.length/row)
      //     const tmpArr = Array.from({length:deficiency},()=>[])
      //     console.log(tmpArr,'tmp')
      //     tmp2.push(...tmpArr)
      // }

      tmp.push(tmp2);
    }
    return tmp;
  }, [books, row, column]);
  useEffect(() => {
    setCurrentPage(0);
  }, [row, column]);
  return (
    <RowFlex>
      <LeftArrow firstPage={currentPage === 0} onClick={handlePrevPage}>
        {''}
      </LeftArrow>
      <CarouselContainer row={row} column={column}>
        <RowBox currentPage={currentPage} row={row} column={column}>
          {slicedBooks.map((e, i) => (
            <ColumnFlex key={currentPage + 'key' + i}>
              {e.map((sliced) => (
                <React.Fragment key={currentPage + 'key' + i + 'key' + sliced[0]?.code}>
                  <CarouselWrapper>
                    {sliced.map((item, idx) => (
                      <CarouselItem data={item} key={currentPage + 'key' + idx + 1} handleFav={handleFav} />
                    ))}
                  </CarouselWrapper>
                  <BottomShadowWrapper row={row}>
                    <BottomShadow row={row} />
                  </BottomShadowWrapper>
                </React.Fragment>
              ))}
            </ColumnFlex>
          ))}
        </RowBox>
        <Pagination>
          {Array.from({ length: Math.ceil(books.length / (row * column)) }).map((_, index) => (
            <Dot
              key={index}
              onClick={() => {
                setCurrentPage(index);
              }}
              active={index === currentPage}
            />
          ))}
        </Pagination>
      </CarouselContainer>
      <RightArrow lastPage={currentPage === totalPages_minus1} onClick={handleNextPage}>
        {''}
      </RightArrow>
    </RowFlex>
  );
};
export default { Carousel, CarouselItem, CatsCarouselItem };
