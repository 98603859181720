import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { getClass } from 'src/operations/queries';
import moment from 'moment';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { CLASS_STATUS_TYPE_ALL, CLASS_STATUS_TYPE_IN_PRGRESS, CLASS_STATUS_TYPE_FINISH } from 'src/constants/common';

const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
const { Option } = Select;
const classInfoDefaultValue = `0|''|''|''`;
const baseStyle = { width: '95%' };
function ClassSelectBox({
  mode = 'basic',
  style = {},
  onClassChange,
  onClassDateChange,
  onClassBooksChange,
  selectedClassIdx,
  selectedClassInfo,
  campus_idx,
  message = false,
  isOnlyLatest = true, //이름이 같은 반이 여러개인 경우 최신 반만 가져옴
  classStatusType = CLASS_STATUS_TYPE_ALL,
  firstRowDisplayValue = '반 선택',
  class_teacher_idx = undefined,
  hasClassBoookTitle = false,
  ...rest
}) {
  const { data, loading } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    skip: !campus_idx,
    variables: { campus_idx },
    fetchPolicy: 'no-cache',
  });
  const selectClassOptions = useMemo(() => {
    const defautlAllOption = message ? (
      <Option key={`class-options-0`} value={mode === 'basic' ? '' : classInfoDefaultValue}>{`반선택`}</Option>
    ) : (
      <Option key={`class-options-0`} value={mode === 'basic' ? 0 : classInfoDefaultValue}>
        {firstRowDisplayValue}
      </Option>
    );
    if (data) {
      const newClassList = [...data.classList];
      const classListLastest = isOnlyLatest
        ? newClassList.reduce((acc, cur) => {
            const findElement = acc.find((ele) => ele.name === cur.name);
            if (!findElement) {
              return [...acc, cur];
            }
            return [...acc];
          }, [])
        : newClassList;

      const classListData = classListLastest
        .filter((ele) => {
          if ([CLASS_STATUS_TYPE_IN_PRGRESS, CLASS_STATUS_TYPE_FINISH].includes(classStatusType)) {
            return classStatusType === CLASS_STATUS_TYPE_IN_PRGRESS
              ? moment().isSameOrBefore(ele.end_date, 'day')
              : moment().isAfter(ele.end_date, 'day');
          }
          return true;
        })
        .filter((ele) => {
          if (class_teacher_idx) {
            if (ele?.class_teacher?.map((item) => item.user_idx)?.includes(class_teacher_idx)) {
              return true;
            }
            return false;
          }
          return true;
        });

      return [
        defautlAllOption,
        ...classListData.map((item) => (
          <Option
            key={`class-options-${item.idx}`}
            value={
              mode === 'basic'
                ? item.idx
                : `${item.idx}|${item.book_code}|${moment(item.begin_date).format('YYYY-MM-DD')}|${moment(item.end_date).format(
                    'YYYY-MM-DD',
                  )}`
            }
          >
            {`${item.name}${hasClassBoookTitle ? `(${item?.book?.book_sub[0]?.short_title || item?.book?.book_sub[0]?.title})` : ''}`}
          </Option>
        )),
      ];
    }
    return [defautlAllOption];
  }, [message, mode, data, isOnlyLatest, classStatusType, class_teacher_idx, firstRowDisplayValue, hasClassBoookTitle]);
  function handleClassChange(idx) {
    if (onClassDateChange instanceof Function) {
      const findClass = data.classList.find((ele) => ele.idx === idx);
      if (findClass) {
        onClassDateChange([findClass.begin_date, findClass.end_date]);
      }
    }

    if (onClassBooksChange instanceof Function) {
      const findClass = data.classList.find((ele) => ele.idx === idx);
      if (findClass) {
        let tmp = {};
        tmp[findClass.book_code] = {
          ...findClass.book,
          begin_date: findClass.begin_time,
          book_code: findClass.book_code,
        };
        findClass?.class_book?.forEach((item) => {
          tmp[item?.book_code] = {
            ...item?.book,
            begin_date: item?.begin_date,
            book_code: item?.book_code,
          };
        });
        onClassBooksChange(Object.values(tmp));
      }
    }

    onClassChange(idx);
  }
  return (
    <Select
      showSearch
      size={`default`}
      loading={loading}
      placeholder={firstRowDisplayValue}
      onChange={handleClassChange}
      style={{ ...baseStyle, ...style }}
      value={mode === 'basic' ? selectedClassIdx : selectedClassInfo}
      filterOption={(input, option) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      {...rest}
    >
      {selectClassOptions}
    </Select>
  );
}

export default ClassSelectBox;

export const useCampusClass = (obj = {}) => {
  const [selectedClassIdx, setSelectedClassIdx] = useState(0);
  const [searchClassIdx, setSearchClassIdx] = UseHistoryState(obj.key, obj.title);
  const onClassChange = (classIdx) => {
    setSearchClassIdx(classIdx);
    setSelectedClassIdx(classIdx);
  };
  return { selectedClassIdx, onClassChange, searchClassIdx };
};

export const useCampusClassForBookCredit = () => {
  const [selectedClassInfo, setSelectedClassInfo] = useState(classInfoDefaultValue);
  const onClassChange = (classInfo) => {
    setSelectedClassInfo(classInfo);
  };
  return { selectedClassInfo, onClassChange };
};

export const useClassDate = () => {
  //반의 시작 종료 날짜 세팅.
  const [dates, setDates] = useState([]);
  const onClassDateChange = (classDates) => {
    setDates(classDates);
  };
  return { dates, onClassDateChange };
};

export const useClassBooks = () => {
  const [classBooks, setClassBooks] = useState([]);
  const onClassBooksChange = (classBooks) => {
    setClassBooks(classBooks);
  };
  return { classBooks, onClassBooksChange };
};
