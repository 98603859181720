import React, { useEffect, useMemo, useRef } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { GET_NEWSOMATIC_TOKEN } from 'src/operations/queries/getUser';

const TodoCategory = styled.div`
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  padding: 0;
`;

//현재 진행 중인 반의 책을 보여주는 걸로 하자 일단은..
const NewsomaticButton = (props) => {
  const [LoadToken, { data }] = useLazyQuery(GET_NEWSOMATIC_TOKEN);
  const form = useRef();
  const token = useMemo(() => {
    return window.localStorage.getItem('culp_token');
  }, []);

  const getLevel = (level) => {
    if (!level) return null;
    return level % 3 === 0 ? 3 : level % 3;
  };

  const getType = (user_type) => {
    switch (user_type) {
      case '0':
        return 0;
      case '1':
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    if (data?.getNewsomaticToken) {
      form.current.submit();
    }
  }, [data]);

  return (
    <>
      <form ref={form} action="https://app.newsomatic.net/sso/cloudbot" method="post" target="_blank">
        <input name="signature" type="hidden" value={data?.getNewsomaticToken?.signature || ''} />
        <input name="token" type="hidden" value={token || ''} />
        <input name="user_email" type="hidden" value={data?.getNewsomaticToken?.user_email || ''} />
        <input name="user_id" type="hidden" value={data?.getNewsomaticToken?.user_id || ''} />
        <input name="user_name" type="hidden" value={data?.getNewsomaticToken?.user_name || ''} />
        <input name="user_phone" type="hidden" value={data?.getNewsomaticToken?.user_phone || ''} />
        <input name="user_type" type="hidden" value={getType(data?.getNewsomaticToken?.user_type) || 0} />
        <input name="level" type="hidden" value={getLevel(parseInt(data?.getNewsomaticToken?.level)) || ''} />
        <input name="nounce" type="hidden" value={data?.getNewsomaticToken?.nounce || ''} />
        {/* <input name="class_id" type="hidden" value={data?.getNewsomaticToken?.class_id || null} /> */}
        <input
          name="classes"
          type="hidden"
          value={data?.getNewsomaticToken?.classes ? JSON.stringify(data?.getNewsomaticToken?.classes) : null}
        />
        <input name="campus_id" type="hidden" value={data?.getNewsomaticToken?.campus_id || null} />
        <input name="campus_name" type="hidden" value={data?.getNewsomaticToken?.campus_name || null} />
        <input name="article_id" type="hidden" value={data?.getNewsomaticToken?.article_id || null} />
        <input name="assigned_idx" type="hidden" value={data?.getNewsomaticToken?.assigned_idx || null} />
      </form>
      {props?.type === 'link' ? (
        <Link to="#" onClick={LoadToken}>
          News-O-Matic
        </Link>
      ) : (
        <TodoCategory style={{ backgroundColor: props?.backgroundColor }} onClick={LoadToken}>
          {props?.text || 'News-O-Matic'}
        </TodoCategory>
      )}
    </>
  );
};

export default NewsomaticButton;
