import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import * as axios from 'axios';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const StageTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 30px 0;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const Body = styled(ColumnFlex)`
  width: 90%;
  padding: 0 30px;
  height: 80%;
  align-items: center;
  /* justify-content: space-between; */
`;
const QuestionBox = styled(RowFlex)`
  font-size: 25px;
  color: #000000;
  align-items: center;
  padding: 0 40px;
  line-height: 80px;
  height: 80px;
  border-radius: 10px;
  width: 95%;
  border: 1px solid #a1cec9;
  background-color: #daedeb;
  @media (max-height: 960px) {
    font-size: 23px;
    line-height: 70px;
    height: 70px;
    border-radius: 10px;
  }
  `;
const ChoiceBox = styled(RowFlex)`
  padding: 15px 0 15px 60px;
  width: 90%;
  `;
const ChoiceOption = styled.div`
  color: #000000;
  background-color: #e8e8f4;
  border: 1px solid #a09de2;
  border-left: none;
  border-radius: 30px;
  height: 60px;
  font-size: 30px;
  width: 100%;
  line-height: 60px;
  padding-left: 70px;
  cursor: pointer;
  @media (max-height: 960px) {
    font-size: 25px;
    line-height: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;
const ChoiceNumber = styled.div`
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  color: #453ec8;
  background: #fff;
  border: 2px solid #453ec8;
  border-radius: 30px;
  text-align: center;
  margin-right: -50px;
  z-index: 1;
  @media (max-height: 960px) {
    font-size: 25px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;

const ChoiceWrapper = styled(ColumnFlex)`
  padding: 20px 0;
  width:90%;
`;
const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  /* border: 1px red solid; */
  background-image: url(${(props)=>props.bg});
  background-size:  72px 50px;
  background-repeat: no-repeat;
  /* background-color: ${(props) => (props.active ? 'red' : 'orange')}; */
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;
const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  max-width: 800px;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const ArrowIcon = styled.div`
  /* font-size: 40px; */
  width: 52px;
  min-width: 52px;
  height: 45px;
  min-height: 45px;
  /* color: #453ec8; */
  /* font-weight: bolder; */
  margin: 0 35px;
  background-image: url(${(props)=>props.src});
  background-size:  52px 45px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const dummydata = {
    question: 'I sleep in a _______.',
    url: 'https://images.costco-static.com/ImageDelivery/imageService?profileId=12026540&itemId=1570087-847&recipeName=680',
    option: [
      { number: 1, text: 'floor' },
      { number: 2, text: 'table' },
      { number: 3, text: 'bed' },
      { number: 4, text: 'chair' },
    ],
  };
export default function EngStaVocaQuiz({}) {
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <Title>
              <TitleSpan color={'#453ec8'}>BOOK Title</TitleSpan>
              <TitleSpan>Volume</TitleSpan>
              <TitleSpan>Unit</TitleSpan>
              <TitleSpan>Lesson</TitleSpan>
              <TitleSpan>Lesson Name</TitleSpan>
            </Title>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '80%', width: '100%', alignItems: 'center' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '0 50px 20px' }}>
                <StageTitle>10. Choose the right answer.</StageTitle>
                <NumberText>
                  Number{' '}
                  <QuizNumber>
                    <span style={{ color: '#433fc8' }}>{`03`}</span>/{`10`}
                  </QuizNumber>
                </NumberText>
              </RowFlex>
                <Body>
                  <QuestionBox>{dummydata.question}</QuestionBox>
                  <ChoiceWrapper>
                    {dummydata.option.map((e) => (
                      <ChoiceBox>
                        <ChoiceNumber>{e.number}</ChoiceNumber>
                        <ChoiceOption>{e.text}</ChoiceOption>
                      </ChoiceBox>
                    ))}
                  </ChoiceWrapper>
                </Body>
            </ColumnFlex>
          </Main>
        </MainWrapper>
        <StageButtonWrapper>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_left.png'}>
            {/* <ArrowLeftOutlined /> */}
          </ArrowIcon>
          <RowFlex style={{ justifyContent: 'space-between', width: '45%', height: '100%' }}>
           <StageButtonText active={false}>
            <StageButton bg={`/images/LevelUp/study/sub/g_flashcard.png`} active={false} isDone={true} />
              Flashcard
            </StageButtonText>
            <StageButtonText active={false}>
            <StageButton bg={`/images/LevelUp/study/sub/g_check.png`} active={false} isDone={true} />
              Check
            </StageButtonText>
            <StageButtonText active={false}>
            <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} active={false} isDone={true} />
              Typing
            </StageButtonText>
            <StageButtonText active={true}>
            <StageButton bg={`/images/LevelUp/study/sub/g_quiz.png`} active={true} isDone={false} />
              Quiz
            </StageButtonText>
          </RowFlex>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_right.png'}>
            {/* <ArrowRightOutlined /> */}
          </ArrowIcon>
        </StageButtonWrapper>
      </Wrapper>
    </>
  );
}
