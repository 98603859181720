import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { HeaderTitle } from 'src/components/common/Styles';

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ButtonBlock = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MenuButton = styled(Button)`
  background-color: #f1f3f5;
  border-bottom: 0px solid #fff;
`;

const selectedStyle = {
  backgroundColor: '#a4d0e9',
  fontWeight: 'bold',
  color: '#FFF',
};

function Tickets({ children,isPopup=false }) {
  const {
    location: { pathname },
  } = useHistory();

  return (
    <>
      {!isPopup&&<MenuWrapper className="ticket-menu-tab-main">
        <HeaderTitle className="mainheading-ticket" level={4}>이용권 관리</HeaderTitle>
        <ButtonBlock className="wrapper-tab-ticket">
          {/* <MenuButton type={pathname === '/addTicket' && `primary`}>
            <Link to="/addTicket">이용권 생성</Link>
          </MenuButton> */}
          <MenuButton className="ticket-tab" type="default" style={pathname === '/assignTicket' ? selectedStyle : {}}>
            <Link to="/assignTicket">이용권 부여</Link>
          </MenuButton>
          <MenuButton className="ticket-tab" type="default" style={pathname === '/ticketStatus' ? selectedStyle : {}}>
            <Link to="/ticketStatus">이용권 현황</Link>
          </MenuButton>
          <MenuButton className="ticket-tab" type="default" style={pathname === '/recallTicket' ? selectedStyle : {}}>
            <Link to="/recallTicket">이용권 회수</Link>
          </MenuButton>
          <MenuButton className="ticket-tab" type="default" style={pathname === '/ticketStatistics' ? selectedStyle : {}}>
            <Link to="/ticketStatistics">이용권 현황 통계</Link>
          </MenuButton>
        </ButtonBlock>
      </MenuWrapper>}
      {children}
    </>
  );
}

export default Tickets;
