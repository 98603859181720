import React from 'react';
import { Col, Form, Input, Row } from 'antd';

import DefaultModal from './DefaultModal';
import FormItem from 'antd/lib/form/FormItem';

import { useForm } from 'src/utils/hooks';

function PasswordModal({ userInfo, popupMode, handleCancel, ...rest }) {
  const [form] = Form.useForm();

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  return (
    <>
      <DefaultModal
        title="비밀번호 변경하기"
        form={form}
        ActionCancel={ActionCancel}
        popupMode={popupMode}
        width={30}
        {...rest}
      >
        <Form
          form={form}
          name="control-ref"
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FormItem
                name="title"
                label={`현재 비밀번호`}
                hasFeedback
                rules={[{ required: true }]}
                style={{ width: '90%' }}
              >
                <Input name="title" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name="title"
                label={`새 비밀번호`}
                hasFeedback
                rules={[{ required: true }]}
                style={{ width: '90%' }}
              >
                <Input name="title" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name="title"
                label={`비밀번호 확인`}
                hasFeedback
                rules={[{ required: true }]}
                style={{ width: '90%' }}
              >
                <Input name="title" />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </DefaultModal>
    </>
  );
}

export default PasswordModal;
