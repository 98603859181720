import React, { useMemo } from 'react';
import { InputNumber } from 'antd';
import { getDifferentTitle } from 'src/utils';
import { Select } from 'antd';
const BookOrderTable = ({ bookTitle, volumes, root_book_code, books, bookCountObj, onCountChange, isUseCredit,isAfterSchool=false }) => {
  const { Option } = Select;
  const totalCurrentBookCount = useMemo(() => {
    return Object.entries(bookCountObj).reduce((acc, [, volval]) => {
      return acc + Object.values(volval).reduce((a, c) => a + (c.volume!==undefined && !Number.isNaN(c.volume) ? c.volume : c), 0);
    }, 0);
  }, [bookCountObj]);
  const books2 =[...books]
  root_book_code==='LTRS'&&books2.shift()
  return (
    <table className="booktable-main-tbl mytable_custome" style={{ marginBottom: 10 }}>
      <tr>
        <th colSpan={volumes ? 3 + volumes.length : 3}>볼륨</th>
      </tr>
      <tr>
        {books2.length === 1 ? (
          <th colSpan={2}>책</th>
        ) : (
          <>
            <th>레벨</th>
            <th>서적</th>
          </>
        )}
        {volumes &&
          volumes.map((vol) => {
            let volume_name = vol;
            if (['SM', 'SK', 'TCL'].includes(root_book_code)) {
              if (books2.length === 1) {
                //레벨이 하나만 있는 경우임
                volume_name = getDifferentTitle(`${root_book_code}-L${books2[0][0]}V${vol}`);
              }
            }
            return <th key={`volumes-key${vol}`}>{volume_name}</th>;
          })}
        <th>합계</th>
      </tr>
      {books2 &&
        books2.map((item, key) => (
          <tr key={`level-row-key${key}`}>
            {books2.length === 1 && !root_book_code.includes('ET') ?  (
              <>
                <td colSpan={2}>{bookTitle}</td>
              </>
            ) : (
              <>
                <td>레벨 {root_book_code==='LTRS'?item[1].level_name.split('')[1]:item[1].level_name || item[0]}</td>
                <td>
                  {bookTitle} {item[1].level_name || item[0]}
                </td>
              </>
            )}

            {item[1].vol_list.map((item2, key2) => (
              <>
                <td key={`each-input-key${key}-${key2}`}>
                  <tr className="row_block">
                    <td className="row_text">
                      <InputNumber
                        placeholder='수량'
                        min={0}
                        // max={50}
                        maxLength={3}
                        keyboard={false}
                        onChange={(number) => {
                          onCountChange(item[0], item2.volume_no, number, 'volume',  isUseCredit ? item[1]?.price_list[key2][0]?.days : 0);
                        }}
                        onKeyPress={(e) => {
                          if (e.key >= 0 && e.key <= 9) {
                            return true;
                          } else {
                            e.preventDefault();
                            return false;
                          }
                        }}
                      />{ isUseCredit ? (
                        <>
                        <Select
                          onChange={(e) => onCountChange(item[0], item2.volume_no, e, 'days', 0 )} 
                          defaultValue={item[1]?.price_list[0]?.length ? item[1]?.price_list[key2][0].days : 0}
                        >
                          {item[1]?.price_list[key2]?.map((item1, keys) => {
                            return (
                              <>
                                <Option key={keys} value={item1.days}>
                                  {item1.days}
                                </Option>
                              </>
                            );
                          })}
                        </Select>
                        일
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                </td>
              </>
            ))}
            <td>
              {Object.entries(bookCountObj).reduce((acc, [level, vol]) => {
                if (parseInt(level) === parseInt(item[0])) {
                  return acc + Object.values(vol).reduce((a, c) => a + (c.volume!==undefined && !Number.isNaN(c.volume) ? c.volume : c), 0);
                }
                return acc;
              }, 0)}
            </td>
          </tr>
        ))}
      <tr>
        <td colSpan="2">합계</td>
        {volumes &&
          volumes.map((vol) => (
            <td key={`vol-total${vol}`}>
              {Object.entries(bookCountObj).reduce((acc, [, volVal]) => {
                return (
                  acc +
                  Object.entries(volVal)
                    .filter(([i]) => parseInt(i) === parseInt(vol))
                    .reduce((a, [, c]) => a + (c.volume!==undefined && !Number.isNaN(c.volume) ? c.volume : c), 0)
                );
              }, 0)}
            </td>
          ))}
        <td>{totalCurrentBookCount}</td>
      </tr>
    </table>
  );
};

export default BookOrderTable;
