import React, { useEffect, useCallback, useMemo } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import CustomCheckBox, { useCheckBox } from './CustomCheckBox';

const ColorTable = styled(Table)`
  border-top: 3px solid ${(props)=>props.borderColor};
  thead[class*='ant-table-thead'] th {
    background: ${(props) => props.color || ''} !important;
  }
  .ant-table-small .ant-table-selection-column {
    width: ${(props) => (props.isEndCheckBox ? '3px' : '46px')};
    min-width: ${(props) => (props.isEndCheckBox ? '3px' : '46px')};
  }
`;

function CustomTable({ children, color,borderColor='#a4d0e9', dataSource, columns, pagination, onChange, rowSelection, ...rest }) {
  const { checkedAll, onCheckAll, onCheckEach, onPageChange, isEachChecked, setTotalKeys, selectedRowKeys, indeterminate } = useCheckBox(
    [],
  );

  useEffect(() => {
    if (rowSelection?.onEndCheckChange) {
      rowSelection.onEndCheckChange(selectedRowKeys);
    }
  }, [selectedRowKeys, rowSelection]);

  const customRowSelection = useMemo(() => {
    if (rowSelection?.isEndCheckBox && rowSelection.onEndCheckChange) {
      const { isEndCheckBox, onEndCheckChange, ...rest } = rowSelection;
      return {
        hideSelectAll: true,
        columnWidth: 0,
        renderCell: () => {
          return '';
        },
        ...rest,
      };
    }
    return rowSelection;
  }, [rowSelection]);
  const customColumns = useMemo(() => {
    if (columns && rowSelection?.isEndCheckBox) {
      return [
        ...columns,
        {
          title: <CustomCheckBox onChange={onCheckAll} checked={checkedAll} indeterminate={indeterminate} />,
          align: 'center',
          dataIndex: 'select',
          key: 'select',
          width: '46px',
          render: (_, record) => {
            return (
              <CustomCheckBox
                onChange={(e) => {
                  onCheckEach(e.target.checked, record.key);
                }}
                checked={isEachChecked(record.key)}
              />
            );
          },
        },
      ];
    }
    return columns;
  }, [columns, rowSelection, onCheckAll, onCheckEach, isEachChecked, checkedAll, indeterminate]);

  const customOnChange = useCallback(
    (pagination, filters, sorter, extra) => {
      if (rowSelection?.isEndCheckBox) {
        onPageChange(pagination, filters, sorter, extra);
      }
      if (onChange) {
        onChange(pagination, filters, sorter, extra);
      }
    },
    [onChange, onPageChange, rowSelection],
  );

  useEffect(() => {
    if (dataSource && rowSelection?.isEndCheckBox) {
      const pageSize = pagination?.pageSize || 10;
      setTotalKeys(dataSource.filter((_, key) => key < pageSize).map((item) => item.key));
    }
  }, [dataSource, setTotalKeys, pagination, rowSelection]);
  return (
    <>
      <ColorTable
      borderColor={borderColor}
        color={color}
        dataSource={dataSource}
        pagination={pagination}
        columns={customColumns}
        onChange={customOnChange}
        rowSelection={customRowSelection}
        isEndCheckBox={rowSelection?.isEndCheckBox ? true : false}
        {...rest}
      >
        {children}
      </ColorTable>
    </>
  );
}

export default CustomTable;
