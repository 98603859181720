import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { Form } from 'antd';
import DefaultModal from './DefaultModal';
import { openNotification } from 'src/components/common/Notification';
import CreateCampusForm from 'src/pages/Campuses/CreateCampusForm';
import ModifyCampusForm from 'src/pages/Campuses/ModifyCampusForm';
import { CREATE_CAMPUS, UPDATE_CAMPUS_INFO } from 'src/operations/mutations/campus';
import { GET_CAMPUS_BOOK_CONTENTS_SHORT } from 'src/operations/queries/company';
import { GET_CAMPUS_LIST, GET_CAMPUS_BOOK_INFO_LIST, GET_CAMPUS_INFO } from 'src/operations/queries/campus';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const CampusModal = ({ campusInfo, handleCancel, popupMode = 'create', company_idx, campus_idx, ...rest }) => {
  const companyName = useSelector(classStoreData);
  const isSmartEclass = useMemo(() => {
    return isAfterSchool(companyName);
  }, [companyName]);
  const [form] = Form.useForm();
  const initialValues = { type: '3', company_idx: company_idx };

  const [createCampus, { loading: loadingCreate }] = useMutation(CREATE_CAMPUS, {
    onCompleted: () => {
      openNotification('등록 완료!');
      ActionCancel();
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const handleSubmit = (values) => {
    const variables = {};
    const keys = Object.keys(values);
    // const bookInfo = [];
    // let allBookPrices = [];
    for (let i = 0; i < keys.length; i++) {
      // if (keys[i].includes('campus_display_book__')) {
      if (!keys[i].includes('campus_display_book')) {
        variables[keys[i]] = values[keys[i]];
      }

      if (keys[i].includes('campus_display_book')) {
        const [, book_code, key, index, idx] = keys[i].split('__');
        let element = {};
        element[key] = values[keys[i]];

        // let bookExistIndex = allBookPrices.findIndex((ele) => ele.book_code === book_code)

        // if(bookExistIndex > -1) {
        //   if(allBookPrices[bookExistIndex].prices[index]) {
        //     allBookPrices[bookExistIndex].prices[index][key] = parseInt(values[keys[i]])
        //   } else {
        //     allBookPrices[bookExistIndex].prices.push({idx: idx, days: key === 'days' ? parseInt(values[keys[i]]) : 0, price: key === 'price' ? parseInt(values[keys[i]]) : 0})
        //   }
        // } else {
        //   allBookPrices.push({book_code : book_code, prices: [{idx: idx, days: key === 'days' ? values[keys[i]] : 0, price: key === 'price' ? parseInt(values[keys[i]]) : 0}]})
        // }
        // const [, book_code] = keys[i].split('__');
        // bookInfo.push({
        //   book_code,
        //   price: parseInt(values[keys[i]]),
        //   // prices:
        // });
      } else {
        variables[keys[i]] = keys[i] === 'fran_cost' ? parseInt(values[keys[i]]) : values[keys[i]];
      }
    }
    // variables['book_info'] = bookInfo;
    // variables['book_info'] = JSON.stringify(allBookPrices);
    createCampus({
      variables,
      refetchQueries: [
        {
          query: GET_CAMPUS_LIST,
          variables: { company_idx: company_idx },
        },
      ],
    });
  };

  const [updateCampusInfo, { loading: loadingModify }] = useMutation(UPDATE_CAMPUS_INFO, {
    onCompleted: () => {
      openNotification('수정완료!');
      handleCancel();
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const handleSubmitForModify = (values) => {
    const variables = {};
    const keys = Object.keys(values);
    // const bookInfo = [];
    // let allBookPrices = [];
    for (let i = 0; i < keys.length; i++) {
      if (!keys[i].includes('campus_display_book')) {
        variables[keys[i]] = values[keys[i]];
      }
      // if (keys[i].includes('campus_display_book__')) {
      if (keys[i].includes('campus_display_book')) {
        const [, book_code, key, index, idx] = keys[i].split('__');
        let element = {};
        element[key] = values[keys[i]];
        // let bookExistIndex = allBookPrices.findIndex((ele) => ele.book_code === book_code)
        // if(bookExistIndex > -1) {
        //   if(allBookPrices[bookExistIndex].prices[index]) {
        //     allBookPrices[bookExistIndex].prices[index][key] = parseInt(values[keys[i]])
        //   } else {
        //     allBookPrices[bookExistIndex].prices.push({idx:idx, days: key === 'days' ? parseInt(values[keys[i]]) : 0, price: key === 'price' ? parseInt(values[keys[i]]) : 0})
        //   }
        // } else {
        //   allBookPrices.push({book_code : book_code, prices: [{idx:idx,days: key === 'days' ? values[keys[i]] : 0, price: key === 'price' ? parseInt(values[keys[i]]) : 0}]})
        // }
        // const [, book_code] = keys[i].split('__');
        // bookInfo.push({
        //   campus_idx,
        //   book_code,
        //   price: parseInt(values[keys[i]]),
        // });
      } else {
        variables[keys[i]] = keys[i] === 'fran_cost' ? parseInt(values[keys[i]]) : values[keys[i]];
      }
    }
    // variables['book_info'] = JSON.stringify(allBookPrices);
    // variables['book_info'] = bookInfo;
    updateCampusInfo({
      variables,
      refetchQueries: [
        {
          query: GET_CAMPUS_LIST,
          variables: { company_idx: company_idx },
        },
        {
          query: GET_CAMPUS_BOOK_INFO_LIST,
          variables: { campus_idx },
        },
        {
          query: GET_CAMPUS_INFO,
          variables: { campus_idx },
        },
        {
          query: GET_CAMPUS_BOOK_CONTENTS_SHORT,
          variables: { campus_idx },
        },
      ],
    });
  };
  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }
  return (
    <DefaultModal
      className="modalfolder-popup"
      title={`${isSmartEclass ? '학교' : '학원'}정보`}
      form={form}
      loading={loadingCreate || loadingModify}
      ActionCancel={popupMode === 'create' ? ActionCancel : handleCancel}
      popupMode={popupMode}
      width={80}
      style={{ top: 20 }}
      {...rest}
    >
      {popupMode === 'create' ? (
        <CreateCampusForm
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues}
          company_idx={campusInfo?.fc_company_idx}
          campus_idx={campus_idx}
        />
      ) : (
        <ModifyCampusForm form={form} onFinish={handleSubmitForModify} company_idx={campusInfo?.fc_company_idx} campus_idx={campus_idx} />
      )}
    </DefaultModal>
  );
};
export default CampusModal;
