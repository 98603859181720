import React from 'react';
import { Space, Spin } from 'antd';

const Loading = () => {
  return (
    <Space
      size="middle"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Spin size="large" />
    </Space>
  );
};

export default Loading;
