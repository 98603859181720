import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { isVowel, alphabetBoxTypeChecker } from '../../utils/pm/util';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as S from 'src/pages/PhonicsMonster/style';
let bookNameUnitStage = undefined;
const AlphabetDnD2 = ({ upperWord, question, setSteps, customData }) => {
  const [isDropped, setIsDropped] = useState(false);
  const [droppedAlphabet, setDroppedAlphabet] = useState('');
  const [dropSound, setDropSound] = useState(undefined);
  const [soundName, setSoundName] = useState(undefined);
  const [alphabets, setAlphabets] = useState([]);
  const [isShowAnswer, setIsShowAnswer] = useState(false);
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const { level, unit, stage } = useParams();

  if (level == '3a' && (unit == 1 || 2) && (stage == 2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (level == '3b' && (unit == 6 || 7 || 8 || 9) && (stage == 2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      const word = droppedAlphabet + question;
      if (word === 'june') {
        setSoundName('J_une_June');
      } else {
        setSoundName(`${droppedAlphabet}_${question}_${droppedAlphabet + question}`);
      }

      setTimeout(() => {
        setIsShowAnswer(true);
      }, 2500);
    }
    setDropSound(undefined);
  };

  const handleSoundEnd = () => {
    setSoundName(undefined);
    setIsDropped(false);
    setIsDragDisabled(false);
    setIsShowAnswer(false);
  };

  const handleDragEnd = useCallback(
    (result) => {
      const { destination, source } = result;

      if (_.isNil(destination)) {
        setDropSound('wrong_sound1');
        return;
      }

      if (destination.droppableId === source.droppableId) {
        return;
      }

      setDropSound('mouse-click');
      const dropped = result.draggableId.slice(-1);
      setIsDropped(true);
      setIsDragDisabled(true);
      if (!_.isNil(dropped)) {
        setDroppedAlphabet(dropped);
        const copy = _.cloneDeep(alphabets);
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].name === dropped) {
            copy[i].isDropped = true;
          }
        }
        setAlphabets(copy);
      }
    },
    [alphabets],
  );

  useEffect(() => {
    if (!_.isNil(upperWord) && upperWord.length > 0) {
      setAlphabets(
        upperWord.map((item) => {
          return { isDropped: false, name: item };
        }),
      );
    }
  }, [upperWord]);

  useEffect(() => {
    if (alphabets.every((item) => item.isDropped) && alphabets.length > 0) {
      if (isDropped === false) {
        setSteps((prev) => prev + 1);
      }
    }
    // eslint-disable-next-line
  }, [alphabets, isDropped]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <S.ColumnFlex
          style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
          className="main_content  stage2-2_content  row2_arrow"
        >
          <Box className="top_box">
            <S.AlphabetWrap className="alphabet_wrap">
              <AlphabetDropZone isDropped={isDropped} droppedAlphabet={droppedAlphabet} />
              <li>
                <div style={{ margin: '0 8px' }} className="sign">
                  +
                </div>
              </li>
              <li>
                {bookNameUnitStage
                  ? _.map(question, (item) => (
                      <span key={item} style={{ color: '#f368e0' }} className={`font_${isVowel(item) ? 'pink' : 'pink'}`}>
                        {item}
                      </span>
                    ))
                  : _.map(question, (item) => (
                      <span key={item} style={{ color: '#2e86de' }} className={`font_${isVowel(item) ? 'blue' : 'blue'}`}>
                        {item}
                      </span>
                    ))}
              </li>
              <li>
                <div style={{ margin: '0 8px' }} className="sign">
                  =
                </div>
              </li>
              <AnswerWordBox isDropped={isDropped} answerWord={droppedAlphabet + question} isShowAnswer={isShowAnswer} />
            </S.AlphabetWrap>
          </Box>
          <Arrow src="/images/pm/i_arrow_top.svg" alt="위로 향하는 화살표" />
          <Box className="bottom_box">
            <AlphabetDraggableZone alphabets={alphabets} isDragDisabled={isDragDisabled} />
          </Box>
        </S.ColumnFlex>
      </DragDropContext>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      <SoundEffectPM
        url={
          !_.isNil(soundName)
            ? `${!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : 'https://cdn.cloubot.com/PM/audio/sounds/'}${soundName}.mp3`
            : undefined
        }
        onEnd={handleSoundEnd}
      />
    </>
  );
};

export default AlphabetDnD2;

export const AlphabetDropZone = ({ isDropped, droppedAlphabet }) => {
  return (
    <Droppable droppableId={'change'}>
      {(provided, snapshot) => (
        <li ref={provided.innerRef}>
          <S.AlphabetBox
            gray={!isDropped}
            row={alphabetBoxTypeChecker(1)}
            className={`alphabet_box row1 ${isDropped ? 'green' : 'gray'}`}
            style={{
              backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
            }}
          >
            {bookNameUnitStage ? (
              <span style={{ color: '#2e86de' }} className="font_blue">
                {isDropped ? droppedAlphabet : ''}
              </span>
            ) : (
              <span style={{ color: '#f368e0' }} className="font_pink">
                {isDropped ? droppedAlphabet : ''}
              </span>
            )}
          </S.AlphabetBox>
          <span style={{ display: 'none' }}>{provided.placeholder}</span>
        </li>
      )}
    </Droppable>
  );
};

export const AlphabetDraggableZone = ({ alphabets, isDragDisabled }) => {
  return (
    <S.AlphabetWrap className="alphabet_wrap row1_wrap">
      {_.map(alphabets, (item, index) => (
        <Droppable key={item.name} droppableId={item.name + 'drop'} isDropDisabled={true}>
          {(provided) => (
            <li ref={provided.innerRef}>
              <Draggable key={index} draggableId={item.name} index={index} isDragDisabled={isDragDisabled}>
                {(provided, snapshot) => (
                  <S.AlphabetBox
                    row={alphabetBoxTypeChecker(1)}
                    hide={item.isDropped}
                    className={`alphabet_box row1 green ${item.isDropped ? 'area_hide' : ''}`}
                    key={index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <span style={{ color: '#2e86de' }} className="font_blue">
                      {item.name}
                    </span>
                  </S.AlphabetBox>
                )}
              </Draggable>
              {provided.placeholder}
            </li>
          )}
        </Droppable>
      ))}
    </S.AlphabetWrap>
  );
};

export const AnswerWordBox = ({ isDropped, answerWord, isShowAnswer }) => {
  return (
    <li>
      <S.AlphabetBox
        row={alphabetBoxTypeChecker(4)}
        gray={!(isDropped && isShowAnswer)}
        className={`alphabet_box row3 ${isDropped && isShowAnswer ? 'green' : 'gray'}`}
      >
        {isDropped &&
          isShowAnswer &&
          (bookNameUnitStage
            ? _.map(answerWord, (item, key) => (
                <span style={{ color: key == 0 ? '#2e86de' : '#f368e0' }} key={key} className={`font_${key == 0 ? 'blue' : 'pink'}`}>
                  {item}
                </span>
              ))
            : _.map(answerWord, (item, key) => (
                <span style={{ color: key == 0 ? '#f368e0' : '#2e86de' }} key={key} className={`font_${key == 0 ? 'pink' : 'blue'}`}>
                  {item}
                </span>
              )))}
      </S.AlphabetBox>
    </li>
  );
};
const Box = styled(S.BorderRowBox)`
  flex: 2 1;
  justify-content: center;
  font-size: 38px;
  font-weight: 800;
  align-items: center;
  @media (max-height: 500px) {
    font-size: 25px;
  }
`;
const Arrow = styled.img`
  height: 3rem;
  margin-bottom: 1vh;
  @media (max-height: 500px) {
    height: 2rem;
  }
`;
