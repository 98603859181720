import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal } from 'antd';

import CreateBoardForm from 'src/pages/Board/CreateBoardForm';
import { openNotification } from '../common/Notification';

import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';
import UpdateBoardForm from 'src/pages/Board/UpdateBoardForm';
import { userInfoVar } from 'src/apollo/cache';

function BoardModal({ boardInfo = null, popupMode, handleCancel, visible, refetch }) {
  const [form] = Form.useForm();

  const { name } = useParams();

  const company_idx = useMemo(() => userInfoVar().campus.company.idx, []);
  const company_name = userInfoVar()?.company_name;

  const initialState = {
    wr_subject: boardInfo ? boardInfo?.wr_subject : '',
    wr_content: boardInfo ? boardInfo?.wr_content : '',
    bo_table: name,
    files: [],
  };

  const [values, setValues] = useState(initialState);

  const [createBoard, { loading }] = useMutation(mutations.board.CREATE_BOARD_WRITE, {
    fetchPolicy: 'no-cache',
    variables: values,
    onCompleted: (data) => {
      if (data) {
        refetch();
        openNotification('등록 완료!');
        ActionCancel();
      }
    },
  });

  const [updateBoard, { loading: editing }] = useMutation(mutations.board.UPDATE_BOARD_WRITE, {
    variables: { ...values, board_write_idx: boardInfo?.idx },
    onCompleted(data) {
      console.log('data', data);
      if (data) {
        refetch();
        openNotification('수정 완료!');
        ActionCancel();
      }
    },
    onError(error) {
      console.log('values', values);
      console.log('error', error);
    },
  });

  function ActionCancel() {
    form.resetFields();
    setValues(initialState);
    handleCancel();
  }

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setValues((prev) => ({
          wr_subject: formData.wr_subject,
          wr_content: formData.wr_content,
          bo_table: name,
          files: prev.files,
          // category: data.category,
        }));

        popupMode === 'create' ? createBoard() : updateBoard();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  return (
    <>
      <Modal
        visible={visible}
        className={`${company_name}`}
        title={`${popupMode === 'create' ? '등록' : '수정'}`}
        onCancel={handleCancel}
        width={'60%'}
        footer={[
          <Button key="submit" type="primary" loading={popupMode === 'create' ? loading : editing} onClick={handleFormSubmit}>
            {popupMode === 'create' ? '등록' : '수정'}
          </Button>,
          <Button key="back" onClick={() => ActionCancel()}>
            닫기
          </Button>,
        ]}
      >
        <Form form={form} name="control-ref" layout={{ labelCol: { span: 8 }, wrapperCol: { span: 16 } }}>
          {popupMode === 'create' ? (
            <CreateBoardForm handleFileValue={setValues} form={form} />
          ) : (
            <UpdateBoardForm form={form} handleFileValue={setValues} boardInfo={boardInfo} />
          )}
        </Form>
      </Modal>
    </>
  );
}

export default BoardModal;
