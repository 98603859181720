import React, { useState, useEffect, useMemo } from 'react';
import { Layout, Input, Select, Space, Button, Upload } from 'antd';
import { PlusOutlined, SoundOutlined, CloseOutlined, ArrowDownOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import ModalForCmaker from './ModalForCmaker';
import ModalForExtend from './ModalForExtend';

const fetchLessonData = async (lessonCode) => {
  let res = await fetch(`https://s3.ap-northeast-2.amazonaws.com/ai-file-aws.cloubot.com/${lessonCode}`);
  let data = await res.json();
  return data;
};

const Cmaker = () => {
  const { path, lesson } = queryString.parse(window.location.search.replace('?', ''));

  const [isOpen, setIsOpen] = useState(false);
  const [lessonData, setLessonData] = useState(null);
  const [currentStage, setCurrentStage] = useState(0);
  const [currentPart, setCurrentPart] = useState(0);
  const openModal = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    const getLessonData = async () => {
      const data = await fetchLessonData(`${path}/${lesson}.json`);
      setLessonData(data);
    };

    if (!lessonData) {
      getLessonData();
    }
  }, [lessonData]);

  let stageData = useMemo(() => {
    let stageData = [];
    if (lessonData) {
      let dialogs = lessonData.dialog;
      let dialogsKeys = Object.keys(dialogs);
      let stageKeys = Object.keys(lessonData.stage);

      for (let i = 0; i < stageKeys.length; i++) {
        let stage = lessonData.stage[stageKeys[i]];
        let stageDno = stage.dno;

        let stageDialogs = dialogsKeys.filter((a) => a.startsWith(stageDno));

        let tempPart = {};
        tempPart[stageKeys[i]] = {
          dno: stageDno,
          header: [stageKeys[i]],
        };
        stageData.push({
          key: stageKeys[i],
          data: {
            ...lessonData.stage[stageKeys[i]],
            part: lessonData.stage[stageKeys[i]].part ? lessonData.stage[stageKeys[i]].part : tempPart,
          },
          stageDno: stageDno,
          stageDialogs: stageDialogs,
        });
      }

      return stageData;
    }
  }, [lessonData]);

  const handleUplaod = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        let formData = new FormData();
        formData.append('file', data.file);
        let res = await fetch('https://api.cloubot.com/api/v1/file/upload', {
          method: 'POST',
          body: formData,
        });
        let { id } = await res.json();

        resolve(id);
      } catch (e) {
        reject(e);
      }
    });
  };

  // const currentDialog = useMemo(() => {
  //   if (stageData.length > 0) {
  //     let currentDialog = stageData[currentStage].stageDialogs[currentPart];
  //     return currentDialog;
  //   }
  // }, [currentStage, currentPart]);

  console.log(stageData);

  {
    /* 
    a buttons(.rotate-arrow) that having up and down arrow, is for change box position.
    and  +↓ buttons are meaning 'add this box to bottom'. 
  */
  }
  return (
    <>
      {lessonData && (
        <div className="cmaker-wrapper">
          {isOpen ? <ModalForCmaker isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
          <section className="cmaker-container top init">
            <h1 className="wrap-tit">Init</h1>
            <div className="inner-wrap-new">
              <h3> start </h3>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {lessonData.start?.welcome ? (
                  lessonData.start?.welcome.map((a) => {
                    if (a.startsWith('<audio'))
                      return (
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <Input disabled type="text" placeholder="Type the words..." value={a} />
                          <Upload
                            accept="audio/*"
                            name="file"
                            multiple={true}
                            action={handleUplaod}
                            listType="text"
                            showUploadList={{ showRemoveIcon: false }}
                          >
                            Audio
                          </Upload>
                        </div>
                      );
                    else return <Input type="text" placeholder="Type the words..." value={a} />;
                  })
                ) : (
                  <Input type="text" placeholder="Type the words..." />
                )}
              </div>

              <Button type="primary" style={{ width: '60px' }}>
                <PlusOutlined />
              </Button>
            </div>
          </section>

          <section className="cmaker-container top dialogue">
            <h1 className="wrap-tit">dialogue</h1>
            <div className="inner-wrap-new">
              <div className="middle-tab">
                {stageData.map((a, i) => (
                  <div
                    onClick={() => {
                      setCurrentStage(i);
                    }}
                    className={i === currentStage ? 'active' : ''}
                  >
                    {a.key || ''}
                  </div>
                ))}
                <div onClick={openModal}>
                  {' '}
                  <PlusOutlined />{' '}
                </div>
              </div>

              <div className="middle-tab-btm-con ">
                <div className="inner-wrap-new stg-tit-wrap">
                  <h3> stage header </h3>
                  <Input type="text" placeholder="type the header name" />
                  <div className="multiple-btn-wrap">
                    <Button type="primary">
                      <ArrowsAltOutlined className="rotate-arrow" />
                    </Button>

                    <Button type="primary">
                      <SoundOutlined />
                    </Button>
                    <Button type="primary">
                      <PlusOutlined />
                    </Button>
                  </div>
                </div>

                <div className="middle-tab inner-tab">
                  {Object.keys(stageData[currentStage]?.data?.part).map((kk, ii) => (
                    <div
                      onClick={() => {
                        setCurrentPart(ii);
                      }}
                      className={ii === currentPart ? 'active' : ''}
                    >
                      {stageData[currentStage]?.data.part[kk]['header'][0] || ''}
                    </div>
                  ))}
                  <div onClick={openModal}>
                    <PlusOutlined />{' '}
                  </div>
                </div>

                <div className="con">
                  <div className="middle-tab">
                    <div className="active">DNO</div>
                    <div>S1</div>
                    <div className="delete-tab">
                      {' '}
                      <CloseOutlined />
                    </div>
                  </div>

                  <div className="con inner-con">
                    {/* first box(question) start */}
                    <div
                      className="inner-wrap-new"
                      style={{
                        background: '#252525',
                        alignItems: 'center',
                        gridTemplateColumns: '1fr 4.2fr',
                        marginTop: '1rem',
                        border: '2px solid gray',
                      }}
                    >
                      <Select
                        defaultValue="question"
                        options={[
                          {
                            value: 'pre',
                            label: 'pre',
                          },
                          {
                            value: 'question',
                            label: 'question',
                          },
                        ]}
                      />

                      <div style={{ display: 'grid', gridTemplateColumns: '4fr 2fr', alignItems: 'center' }}>
                        <div className="multiple-input-wrap">
                          <Input type="text" placeholder="Type the answer" />
                        </div>
                        <div className="multiple-btn-wrap">
                          <Button type="primary">
                            <ArrowsAltOutlined className="rotate-arrow" />
                          </Button>
                          <Button type="danger">
                            <CloseOutlined />
                          </Button>

                          <Button type="primary">
                            <SoundOutlined />
                          </Button>
                          <Button type="primary">
                            <PlusOutlined />
                          </Button>
                          <Select
                            defaultValue="EXTEND"
                            options={[
                              {
                                value: 'IMAGE',
                                label: 'IMAGE',
                              },
                              {
                                value: 'lessonPhrase',
                                label: 'lessonPhrase',
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>

                    {/* toggle area start */}
                    <div className="add-wrap" style={{ width: '100%' }}>
                      {/* from right dropdown: img, lessonphrase start */}
                      <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
                        <span className="closeBtn">
                          <CloseOutlined />
                        </span>

                        <h3>IMAGE</h3>
                        <div className="box" style={{ display: 'grid', gridTemplateColumns: '5fr 2fr' }}>
                          <div className="multiple-input-wrap">
                            <Button type="primary" style={{ position: 'relative' }}>
                              upload
                            </Button>
                            <Input type="file" placeholder="upload" />
                          </div>
                          <div className="multiple-btn-wrap">
                            <Button type="primary">
                              <ArrowsAltOutlined className="rotate-arrow" />
                            </Button>
                            <Button type="default">
                              <CloseOutlined />
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
                        <span className="closeBtn">
                          <CloseOutlined />
                        </span>
                        <h3>lessonPhrase</h3>
                        <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr' }}>
                          <div className="multiple-input-wrap">
                            <Input type="text" placeholder="type the number for lessonPhraseActive" />
                          </div>
                        </div>
                      </div>
                      {/* from right dropdown: img, lessonphrase end */}

                      {/* from left: question, pre start */}
                      <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
                        <h3>pre</h3>
                        <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr' }}>
                          <div className="multiple-input-wrap">
                            <Input type="text" placeholder="Type the answer" />
                          </div>
                          <div className="multiple-btn-wrap">
                            <Button type="primary">
                              <ArrowsAltOutlined className="rotate-arrow" />
                            </Button>
                            <Button type="danger">
                              <CloseOutlined />
                            </Button>
                            <Button type="primary">
                              <PlusOutlined />
                            </Button>
                            <Button type="default">NEXT</Button>
                          </div>
                        </div>
                      </div>

                      <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
                        <h3>question</h3>
                        <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr' }}>
                          <div className="multiple-input-wrap">
                            <Input type="text" placeholder="Type the answer" />
                          </div>
                          <div className="multiple-btn-wrap">
                            <Button type="primary">
                              <ArrowsAltOutlined className="rotate-arrow" />
                            </Button>
                            <Button type="danger">
                              <CloseOutlined />
                            </Button>
                            <Button type="primary">
                              <PlusOutlined />
                            </Button>
                            <Select
                              defaultValue="EXTEND"
                              options={[
                                {
                                  value: '1',
                                  label: '1',
                                },
                                {
                                  value: '2',
                                  label: '2',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* from left: question, pre start */}
                    {/* toggle area end */}
                    {/* first box(question)  end*/}

                    {/* second box(correct) start*/}
                    <div
                      className="inner-wrap-new"
                      style={{
                        background: '#252525',
                        alignItems: 'center',
                        marginTop: '1rem',
                        gridTemplateColumns: '1fr 4.2fr',
                        border: '2px solid gray',
                      }}
                    >
                      <h3 style={{ color: '#fff' }}> correct </h3>

                      <div style={{ display: 'grid', gridTemplateColumns: '4fr 2fr' }}>
                        <div className="multiple-input-wrap">
                          <Input type="text" placeholder="Type the answer" />
                        </div>
                        <div className="multiple-btn-wrap">
                          <Button type="primary">
                            <ArrowsAltOutlined className="rotate-arrow" />
                          </Button>
                          <Button type="danger">
                            <CloseOutlined />
                          </Button>
                          <Button type="primary">
                            <PlusOutlined />
                            <ArrowDownOutlined />
                          </Button>
                          <Select
                            defaultValue="EXTEND"
                            options={[
                              {
                                value: 'Ignore',
                                label: 'Ignore',
                              },
                              {
                                value: 'isKeyword',
                                label: 'isKeyword',
                              },
                              {
                                value: 'similarReplace',
                                label: 'similarReplace',
                              },
                            ]}
                          />

                          <Button type="primary">
                            <PlusOutlined />
                          </Button>
                        </div>

                        <div style={{ marginTop: '1rem' }} className="multiple-input-wrap">
                          <Input type="text" placeholder="Type the reaction" />
                        </div>
                        <div style={{ marginTop: '1rem' }} className="multiple-btn-wrap">
                          <Button type="primary">
                            <ArrowsAltOutlined className="rotate-arrow" />
                          </Button>
                          <Button type="danger">
                            <CloseOutlined />
                          </Button>
                          <Button type="primary">
                            <PlusOutlined />
                            <ArrowDownOutlined />
                          </Button>
                          <Button type="default">NEXT</Button>
                        </div>
                      </div>
                    </div>

                    {/* toggle area start */}
                    <ModalForExtend />
                    {/* toggle area end */}
                    {/* second box(correct) end */}

                    {/* third box start */}
                    <div
                      className="inner-wrap-new"
                      style={{
                        background: '#252525',
                        alignItems: 'center',
                        gridTemplateColumns: '1fr 4.2fr',
                        marginTop: '1rem',
                        border: '2px solid gray',
                      }}
                    >
                      <Select
                        defaultValue="wrong"
                        options={[
                          {
                            value: 'wrong else',
                            label: 'wrong else',
                          },
                          {
                            value: 'maxWrong',
                            label: 'maxWrong',
                          },
                          {
                            value: 'wrong final',
                            label: 'wrong final',
                          },
                          {
                            value: 'reprompt',
                            label: 'reprompt',
                          },
                        ]}
                      />

                      <div style={{ display: 'grid', gridTemplateColumns: '4fr 2fr', alignItems: 'center' }}>
                        {/* first line input */}
                        <div className="multiple-input-wrap">
                          <Input type="text" placeholder="Type the answer" />
                        </div>
                        <div className="multiple-btn-wrap">
                          <Button type="primary">
                            <ArrowsAltOutlined className="rotate-arrow" />
                          </Button>
                          <Button type="danger">
                            <CloseOutlined />
                          </Button>

                          <Button type="primary">
                            <ArrowDownOutlined />

                            <PlusOutlined />
                          </Button>

                          <Select
                            defaultValue="EXTEND"
                            options={[
                              {
                                value: 'Ignore',
                                label: 'Ignore',
                              },
                              {
                                value: 'isKeyword',
                                label: 'isKeyword',
                              },
                              {
                                value: 'similarReplace',
                                label: 'similarReplace',
                              },
                            ]}
                          />
                          <Button type="primary">
                            <PlusOutlined />
                          </Button>
                        </div>

                        {/* second line input */}
                        <div className="multiple-input-wrap" style={{ marginTop: '1rem' }}>
                          <Input type="text" placeholder="Type the reaction" />
                        </div>
                        <div className="multiple-btn-wrap" style={{ marginTop: '1rem' }}>
                          <Button type="primary">
                            <ArrowsAltOutlined className="rotate-arrow" />
                          </Button>
                          <Button type="danger">
                            <CloseOutlined />
                          </Button>
                          <Button type="primary">
                            <ArrowDownOutlined />
                            <PlusOutlined />
                          </Button>
                          <Button type="default">NEXT</Button>
                        </div>
                      </div>
                    </div>
                    {/* */}

                    {/* toggle area(ignore, iskeyword, similarReplace) start*/}
                    <ModalForExtend />
                    {/* toggle area(ignore, iskeyword, similarReplace) end*/}

                    {/* toggle area(wrong else, maxWrong, wrong final, reprompt) start*/}
                    <div className="add-wrap" style={{ width: '100%', border: '1px solid gray' }}>
                      <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
                        <h3>wrong else</h3>

                        <div style={{ display: 'grid', gridTemplateColumns: '4fr 2fr', alignItems: 'center' }}>
                          <div className="multiple-input-wrap">
                            <Input type="text" placeholder="Type the answer" />
                          </div>
                          <div className="multiple-btn-wrap">
                            <Button type="primary">
                              <ArrowsAltOutlined className="rotate-arrow" />
                            </Button>
                            <Button type="danger">
                              <CloseOutlined />
                            </Button>
                            <Button type="primary">
                              <PlusOutlined />
                            </Button>
                            <Select
                              defaultValue="EXTEND"
                              options={[
                                {
                                  value: 'Ignore',
                                  label: 'Ignore',
                                },
                                {
                                  value: 'isKeyword',
                                  label: 'isKeyword',
                                },
                                {
                                  value: 'similarReplace',
                                  label: 'similarReplace',
                                },
                              ]}
                            />{' '}
                            <Button type="primary">
                              <PlusOutlined />
                            </Button>
                          </div>

                          <div className="multiple-input-wrap" style={{ marginTop: '1rem' }}>
                            <Input type="text" placeholder="Type the reaction" />
                          </div>
                          <div className="multiple-btn-wrap" style={{ marginTop: '1rem' }}>
                            <Button type="primary" style={{ position: 'relative' }}>
                              <ArrowsAltOutlined className="rotate-arrow" />
                            </Button>
                            <Button type="danger">
                              <CloseOutlined />
                            </Button>
                            <Button type="primary">
                              <PlusOutlined />
                            </Button>
                            <Button type="default">NEXT</Button>
                          </div>
                        </div>
                      </div>

                      <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
                        <h3>maxWrong</h3>
                        <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr' }}>
                          <div className="multiple-input-wrap">
                            <Input type="text" placeholder="Type the answer" />

                            <Button type="primary" style={{ position: 'relative' }}>
                              <ArrowsAltOutlined className="rotate-arrow" />
                            </Button>
                            <Button type="danger">
                              <CloseOutlined />
                            </Button>
                            <Button type="default">NEXT</Button>
                            <Button type="primary" style={{ marginRight: '2rem' }}>
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
                        <h3>wrong final</h3>
                        <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr' }}>
                          <div className="multiple-input-wrap">
                            <Input type="text" placeholder="Type the answer" />
                            <Button type="primary" style={{ position: 'relative' }}>
                              <ArrowsAltOutlined className="rotate-arrow" />
                            </Button>
                            <Button type="danger">
                              <CloseOutlined />
                            </Button>
                            <Button type="default">NEXT</Button>
                            <Button type="primary" style={{ marginRight: '2rem' }}>
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
                        <h3>reprompt</h3>
                        <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr' }}>
                          <div className="multiple-input-wrap">
                            <Input type="text" placeholder="Type the answer" />
                            <Button type="primary" style={{ position: 'relative' }}>
                              <ArrowsAltOutlined className="rotate-arrow" />
                            </Button>
                            <Button type="danger">
                              <CloseOutlined />
                            </Button>
                            <Button type="default">NEXT</Button>
                            <Button type="primary" style={{ marginRight: '2rem' }}>
                              <PlusOutlined />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* toggle area(wrong else, maxWrong, wrong final, reprompt) end */}
                    {/* .con inner-con end */}
                  </div>

                  <div className="add-dno-con">
                    <div>
                      <h1>add dno</h1>
                    </div>
                  </div>
                </div>
                {/* .con end*/}

                <div className="inner-wrap-new stg-tit-wrap" style={{ padding: '3rem 0' }}>
                  <h3> stage footer </h3>
                  <Input type="text" placeholder="type the footer name" />

                  <div className="multiple-btn-wrap">
                    <Button type="primary" style={{ position: 'relative' }}>
                      <ArrowsAltOutlined className="rotate-arrow" />
                    </Button>
                    <Button type="primary">
                      <SoundOutlined />
                    </Button>
                    <Button type="primary">
                      <PlusOutlined />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Cmaker;
