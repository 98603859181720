import React from 'react';
import LearningMenuJson from 'src/constants/LearningMenuJson';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { learningMenuSliceActions } from 'src/operations/store';
const LearningMenu = (props) => {
  const menuType = useSelector((state) => state.learningMenu.menu_type);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const menu = LearningMenuJson.find((item, index) => {
      return item.slug == e;
    });
    dispatch(learningMenuSliceActions.learningMenuUpdate({ menu_type: e, title: menu.value }));
  };
  return (
    <div style={{ width: '100%' }}>
      <Select
        style={{ width: 300, textAlign: 'left' }}
        defaultValue={menuType}
        className=""
        placeholder="Please Select"
        optionFilterProp="children"
        dropdownMatchSelectWidth="{true}"
        onChange={handleChange}
      >
        {LearningMenuJson.map((item, index) => {
          return (
            <Select.Option key={index} value={item.slug}>
              {item.value}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
export default LearningMenu;
