import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, SoundFilled } from '@ant-design/icons';
import * as axios from 'axios';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EngStaFlashCard from 'src/components/EngSta/Flashcard';
export default function EngStaVocaCard({}) {
  return <EngStaFlashCard type={'voca'} />;
}
