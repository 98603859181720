import React from 'react';
import { Row, Modal } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_TEST, ASSIGN_TEST, ASSIGN_TEST_EDIT, EDIT_TEST } from 'src/operations/mutations/createTests';
import { withRouter } from 'react-router';

import { Add, Assign, AssignEdit } from 'src/components/Test';

const Manage = (props) => {
  const { type = 'add', id = undefined } = useParams();
  const { state } = useLocation();

  const [createTest, { loading }] = useMutation(CREATE_TEST);
  const [editTest, { loading4 }] = useMutation(EDIT_TEST);
  const [assignTest, { loading2 }] = useMutation(ASSIGN_TEST);
  const [assignTestEdit, { loading3 }] = useMutation(ASSIGN_TEST_EDIT);

  const handleGoBack = () => {
    props.history.goBack();
  };

  const handleTestCreate = async (data) => {
    try {
      await createTest({ variables: data });
      props.history.push('/test/manage/list?refresh=true');
    } catch (e) {
      console.log(e);
    }
  };

  const handleTestAssign = async (data) => {
    try {
      await assignTest({ variables: data });
      //props.history.push('/test/manage/list');
      Modal.info({
        title: '시험지가 배정되었습니다. Test Results에서 배정한 시험지를 확인할 수 있습니다.',
        onOk: () => {
          props.history.goBack();
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleTestAssignEdit = async (data) => {
    try {
      await assignTestEdit({ variables: data });
      props.history.push('/test/manage/result?refresh=true');
    } catch (e) {
      console.log(e);
    }
  };

  const handleTestEdit = async (data) => {
    try {
      await editTest({ variables: { ...data, idx: parseInt(id) } });
      //props.history.push('/test/manage/list?refresh=true');
      props.history.goBack();
    } catch (e) {
      console.log(e);
    }
  };

  const getSwitchableRoute = (val, id) => {
    switch (val) {
      case 'assign':
        return <Assign id={parseInt(id)} onClickSave={handleTestAssign} title={state?.title} />;
      case 'edit':
        return <Add onClickSave={handleTestEdit} id={parseInt(id)} isEdit={true} onClickGoback={handleGoBack} />;
      case 'assign-edit':
        return <AssignEdit id={parseInt(id)} onClickSave={handleTestAssignEdit} onClickGoback={handleGoBack} />;
      case 'add':
      default:
        return <Add onClickSave={handleTestCreate} onClickGoback={handleGoBack} />;
    }
  };

  return (
    <>
      <Row gutter={[24, 16]}>{getSwitchableRoute(type, id)}</Row>
    </>
  );
};

export default withRouter(Manage);
