import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Button, Input, Radio } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'react-qr-code';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ASSIGNED_TEST_SINGLE } from 'src/operations/queries/getTests';
import { useQuery } from '@apollo/client';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const TestBlock = styled.div`
  margin-top: 12px;
`;

const PrintWrapper = styled(Document)`
  @media print {
  }
`;

const TestSectionBlock = styled.div`
  background: #fff;
`;

const Preview = (props) => {
  const [test, setTest] = useState({ test_answers: [] });
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(400);
  const printAreaComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printAreaComponentRef.current,
  });

  let myDocument = React.createRef();

  const { data } = useQuery(ASSIGNED_TEST_SINGLE, {
    variables: { idx: parseInt(props.id) },
  });

  useEffect(() => {
    if (myDocument && myDocument.current) {
      setPageWidth(myDocument.current.offsetWidth);
    }

    if (data?.assignedTestsSingle) {
      setTest(data?.assignedTestsSingle.tests);
    }
  }, [myDocument, data]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    console.log('onDocumentLoadSuccess', numPages);
  }

  const getQRPage = () => {
    return (
      <Row gutter={[24, 16]}>
        <Col span={24} style={{ textAlign: 'center', padding: '20px', paddingTop: '20px', paddingBottom: '20px', background: '#fff' }}>
          <QRCode
            value={JSON.stringify({
              id: `${props.id}`,
              type: 'assigned_test',
            })}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Col className="testpreview-wrapper" span={24} style={{ margin: 0 }}>
        <TestBlock className="testpreview-mainblock">
          <Row gutter={[24, 16]}>
            <Col className="pdffullscreen active inactive" span={24}>
              <TestSectionBlock className="pdffullscreen-scroll" ref={myDocument}>
                <PrintWrapper
                  file={test.main_pdf ? test.main_pdf.path : ''}
                  onLoadSuccess={onDocumentLoadSuccess}
                  ref={printAreaComponentRef}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                      <Page
                        key={page}
                        pageNumber={page}
                        scale={1}
                        renderAnnotationLayer={false}
                        renderInteractiveForms={false}
                        renderTextLayer={false}
                        width={pageWidth}
                      />
                    ))}
                  {getQRPage(test)}
                </PrintWrapper>

                <Button
                  type="primary"
                  onClick={() => {
                    handlePrint();
                  }}
                  shape="circle"
                  style={{
                    background: '#3b99ff',
                    position: 'absolute',
                    bottom: '60px',
                    right: '60px',
                  }}
                  icon={<PrinterOutlined />}
                />
              </TestSectionBlock>
            </Col>
          </Row>
          {}
        </TestBlock>
      </Col>
    </>
  );
};

export default withRouter(Preview);
