import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import OpenAppModal from 'src/components/Modal/OpenAppModal';

const BlankAppPage = () => {
  const params = queryString.parse(window.location.search);
  const [appData, setAppData] = useState(null);
  const history = useHistory();

  const handleClose = () => {
    setAppData(null);
    history.push('/');
  };

  useEffect(() => {
    if (params.toApp && isMobile) {
      let culptoken = localStorage.getItem('culp_token')
        ? JSON.parse(localStorage.getItem('culp_token'))
        : {
            accessToken: '',
            refreshToken: '',
          };
      history.replace({
        search: queryString.stringify(params),
      });
      const url = `culpmobile://oauth/login?accessToken=${culptoken.accessToken}&refreshToken=${culptoken.refreshToken}`;
      setTimeout(() => {
        setAppData({
          url,
        });
      }, 1000);
    }

    if (!isMobile) {
      history.push('/');
    }
  }, [params.toApp]);

  return <OpenAppModal visible={true} handleClose={handleClose} url={appData?.url} waitTime={10000} />;
};

export default BlankAppPage;
