import React from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
// import * as dummy from 'src/dummy';

import { SyllabusAssign } from 'src/components/Test';

const Manage = () => {
  const { id } = useParams();
  // if( id !== undefined) {
  //   let testData = dummy.test.test.filter((e) => {
  //     return e.No === id;
  //   })[0];
  // }

  return (
    <>
      <Row gutter={[24, 16]}>
        <SyllabusAssign id={id} />
      </Row>
    </>
  );
};

export default Manage;
