import React, { useEffect, useState } from 'react';
import { Modal, Image } from 'antd';
import moment from 'moment';
// import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';
// import { isAfterSchool,isPturn } from 'src/utils';
// import { userInfoVar } from 'src/apollo/cache';
const SimplePopup = () => {
  const [visible, setVisible] = useState(false);
  // const company = useSelector(classStoreData);
  // const userType = userInfoVar()?.type;

  useEffect(() => {
    if (
      // window.localStorage.getItem(isAfterSchool(company) ? 'smart-eclassroom-popup_231017' : 'level-up-popup_230830') !==
      window.localStorage.getItem('popup_241015') !== moment().format('YYYY-MM-DD')
    ) {
      setVisible(true);
    }
  }, []);

  const handleDone = () => {
    window.localStorage.setItem(
      'popup_241015',
      moment().format('YYYY-MM-DD'),
    );
    setVisible(false);
  };
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <Modal
      width="50vw"
      bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      visible={visible}
      onCancel={handleClose}
      maskClosable={false}
      okText="오늘 하루 그만보기"
      cancelText="닫기"
      // cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      onOk={handleDone}
    >
      {<Image src={'/images/popup/popup_241015.png'} preview={false} />}
    </Modal>
  );
};

export default SimplePopup;
