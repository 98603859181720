import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Modal, Row, Col, Radio, Select } from 'antd';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useLazyQuery } from '@apollo/client';
import { PLACE_ORDER } from 'src/operations/queries/pay';
import { userInfoVar } from 'src/apollo/cache';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import moment from 'moment';

const priceOptions = [
  { label: '10,000원', value: 10000 },
  { label: '30,000원', value: 30000 },
  { label: '50,000원', value: 50000 },
  { label: '100,000원', value: 100000 },
];

const PAY_METHODS = [{ label: '신용카드', value: 'creditcard' }];

const ChargeBtn = styled(Button)`
  color: #fff;
  background: #458018;
  padding: 0 10px;
  position: relative;
  left: 8%;
  border-radius: 5px;
  font-weight: 600;

  &:hover {
    color: #458018;
    background: #fff;
    border: 1px solid #458018;
  }

  @media (max-width: 900px) {
    left: 0%;
  }
`;

const SmsChargeModal = () => {
  const companyName = useSelector(classStoreData);
  const ediDate = moment(Date.now()).format('yyyyMMDDhhmmss');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [payMethod, setPayMethod] = useState(PAY_METHODS[0].value);
  const [orderIdx, setOrderIdx] = useState(0);
  const [price, setPrice] = useState();
  const orderIdxRef = useRef();
  const [placeOrder, { data: dataPlaceOrder, loading }] = useLazyQuery(PLACE_ORDER);
  const [orderUniqueId, setOrderUniqueId] = useState(`oid-${uuidv4()}`);
  const [productName, setProductName] = useState('SMS 포인트 충전');

  const item_list = {
    code: 'SMS-POINT',
    name: 'SMS 포인트 충전',
    count: 1,
    price: price,
  };
  const initialState = useMemo(() => {
    return {
      item_list: item_list,
      comment: '',
      delivery_price: 0,
      ediDate: ediDate,
      amount: price,
      product_name: productName,
    };
  }, [price]);
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    setValues({ ...values, amount: price });
  }, [price]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSmsPointFill = () => {
    console.log('price', price);
    if (!price) {
      Modal.info({ title: '충전금액을 선택하세요.' });
      return;
    }
    const item_list = {
      code: 'SMS-POINT',
      name: 'SMS 포인트 충전',
      count: 1,
      price: price,
    };
    const orderUniqueId = `oid-${uuidv4()}`;

    const buyer_info = `${userInfoVar()?.name || ''}||||`;

    placeOrder({
      variables: {
        pgcode: payMethod,
        order_idx: orderIdx,
        order_no: orderUniqueId,
        user_id: userInfoVar()?.idx?.toString(),
        product_name: productName,
        amount: price,
        delivery_price: 0,
        item_list,
        buyer_info,
        order_type: '1', //상품 종류: SMS충전은 '1'임
        extra_data: JSON.stringify({
          ...values,
          returnUrl: window.location.origin + '/sms-manage',
          cancelUrl: window.location.origin + '/sms-manage',
        }),
      },
    });
  };

  const handlePayComplete = (e) => {
    console.log('handlePayComplete', e.data);
    console.log('e.origin : ' + e.origin);
    if (e.data === 'complete') {
      alert('결제가 완료되었습니다.');
      handleOk();
    }
  };

  useEffect(() => {
    const payResp = dataPlaceOrder?.placeOrder;
    if (payResp) {
      if (payResp.success) {
        orderIdxRef.current = payResp.info?.order_idx;
        handleOk();
        window.open(
          `/i-frame/${btoa(
            process.env.REACT_APP_BACKEND_HOST +
              `/${companyName === 'englishtap' ? 'inicis' : 'nicepay'}/payment/` +
              payResp.info?.order_idx,
          )}`,
          '_self',
        );
        return;
      }
      console.log('error', dataPlaceOrder);
      alert('결제진행중 오류가 발생했습니다.');
    }
  }, [dataPlaceOrder]);

  useEffect(() => {
    window.addEventListener('message', handlePayComplete);
    return () => {
      window.removeEventListener('message', handlePayComplete);
    };
  }, []);

  return (
    <>
      <Modal
        visible={isModalVisible}
        width={'50%'}
        onOk={handleOk}
        onCancel={handleCancel}
        title={<div style={{ textAlign: 'center' }}>충전하기</div>}
        footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleSmsPointFill} type="primary" loading={loading}>
              충전하기
            </Button>
            <Button onClick={handleCancel}>&nbsp;취소&nbsp;</Button>
          </div>
        }
        className="smsmanage-modal"
      >
        <Row gutter={[16, 16]}>
          <Col span={6}>충전금액</Col>
          <Col span={18}>
            <Radio.Group options={priceOptions} value={price} size="small" onChange={(e) => setPrice(Number(e.target.value))} />
          </Col>
          <Col span={6}>결제방법</Col>
          <Col span={18}>
            <Select defaultValue="card" value={payMethod} onChange={setPayMethod} options={PAY_METHODS} />
          </Col>
        </Row>
      </Modal>
      <ChargeBtn onClick={showModal}> &#43; 충전 </ChargeBtn>
    </>
  );
};

export default SmsChargeModal;
