import React from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const BackButton = ({ styles = null, size }) => {
  const history = useHistory();
  return (
    <Button className={styles || ''} shape="round" size={size || 'default'} icon={<LeftOutlined />} onClick={() => history.goBack()}>
      Back
    </Button>
  );
};

export default BackButton;
