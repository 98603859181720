import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Typography, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { settings } from 'src/dummy/settings';

const { Title } = Typography;
const ItemTitle = styled.span`
  font-weight: bold;
`;

const itemStyle = {
  borderLeft: '2px solid #eee',
  borderBottom: '2px solid #eee',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 10,
  fontSize: '0.8em',
};

const BasicInfoTable = ({ user_name, dateRange, basicInfo ,eclassbooks }) => {
  const companyName = useSelector(classStoreData);

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
      <Col span={8} style={{ backgroundColor: '#74c0fc' }}>
        <img src={settings[companyName]?.logo} style={{ width: '80%' }} alt="logo" />
        <Title level={2} style={{ color: '#fff', textAlign: 'center' }}>
          Report Card
        </Title>
      </Col>
      <Col span={16}>
        <Row gutter={[16, 16]} style={{ border: '2px solid #eee' }}>
          <Col span={24} style={{ paddingLeft: 10, paddingTop: 10, paddingBottom: 10, fontSize: '0.8em' }}>
            {basicInfo?.campus_name} {basicInfo?.teacher_name} 선생님
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ rowGap: 0 }}>
          <Col span={4} style={{ ...itemStyle }}>
            <ItemTitle>이름</ItemTitle>
          </Col>
          <Col span={8} style={{ ...itemStyle }}>
            {user_name}
          </Col>
          <Col span={4} style={{ ...itemStyle }}>
            <ItemTitle>반</ItemTitle>
          </Col>
          <Col span={8} style={{ ...itemStyle, borderRight: '2px solid #eee' }}>
            {basicInfo?.class_name}
          </Col>
          <Col span={4} style={{ ...itemStyle }}>
            <ItemTitle>교재</ItemTitle>
          </Col>
          <Col span={8} style={{ ...itemStyle }}>
            {eclassbooks? eclassbooks:basicInfo?.book_title}
          </Col>
          <Col span={4} style={{ ...itemStyle }}>
            <ItemTitle>학습기간</ItemTitle>
          </Col>
          <Col span={8} style={{ ...itemStyle, borderRight: '2px solid #eee' }}>
            {dateRange && `${moment(dateRange[0]).format('yyyy-MM-DD')}~${moment(dateRange[1]).format('yyyy-MM-DD')}`}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BasicInfoTable;
