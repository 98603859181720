import { gql } from '@apollo/client';

export const ELIBRARY_BOOK_LIST = gql`
  query ($level: Int, $page: Int, $take: Int, $evine_level: String) {
    elibrarybookList(level: $level, page: $page, take: $take, evine_level: $evine_level) {
      total
      page
      book_list {
        code
        level_no
        level_name
        stat
        sp_company_idx
        ticket_term1
        ticket_term2
        ticket_term3
        parent_code
        prefix
        evine_level
        book_sub {
          idx
          bidx
          book_code
          book_category {
            idx
            no
            name
            category
          }
          publisher
          title
          introduction
          cover_path
          ebook_path
          additional_data
          type
          no
          is_favourite
        }
        book_lesson {
          code
          book_code
          unit_no
          unit_name
          day_no
          day_name
          title
          introduction
          stat
          idate
          ddate
          stage_group_code
          class_lesson {
            idx
            class_idx
            lesson_code
            study_date
            begin_time
            end_time
            volume
            unit
            lesson_cnt
          }
          stage {
            no
            group_code
            name
            is_complete
          }
        }
      }
    }
  }
`;

export const ASSIGNED_ELIBRARY_GROUP_LIST = gql`
  query assignedElibraryGroupList($class_idx: Int) {
    assignedElibraryGroupList(class_idx: $class_idx) {
      idx
      title
      start_time
      user {
        idx
        name
      }

      class {
        idx
        name
      }

      assigned_elibrary {
        idx
        start_date
        user_study {
          idx
        }
      }

      start_date
      end_date
      start_time
      idate
    }
  }
`;

export const ASSIGNED_ELIBRARY_GROUP = gql`
  query assignedElibraryGroup($idx: Int!) {
    assignedElibraryGroup(idx: $idx) {
      idx
      title
      start_time
      user {
        idx
        name
      }

      class {
        idx
        name
      }

      assigned_elibrary {
        idx
        start_date
        book_lesson {
          book {
            code
            book_sub {
              idx
              book_code
              title
            }
            book_lesson {
              code
            }
          }
        }

        assigned_elibrary_users {
          user {
            idx
          }
        }
      }

      start_date
      end_date
      start_time
      idate
    }
  }
`;

export const ASSIGNED_ELIBRARY_DETAILS = gql`
  query assignedElibrary($assigned_elibrary_group_idx: Int, $page: Int, $take: Int) {
    assignedElibrary(assigned_elibrary_group_idx: $assigned_elibrary_group_idx, page: $page, take: $take) {
      idx
      start_date
      limit_time
      book_lesson {
        code
        title
      }
      assigned_elibrary_group {
        class {
          name
        }
      }
      user_study {
        idx
        stage_no
        exam_total
        exam_correct
        study_date
        recording_data
        wpm
        user {
          idx
          name
        }
      }

      assigned_elibrary_users {
        user {
          idx
          name
        }
        status
      }
    }
  }
`;

export const ASSIGNED_ELIBRARY_DETAILS_WITH_DATE = gql`
  query assignedElibraryDate($start_date: String!, $page: Int, $take: Int, $state: String, $search_text: String) {
    assignedElibraryDate(start_date: $start_date, page: $page, take: $take, state: $state, search_text: $search_text) {
      total
      page
      take
      assigned_elibrary {
        idx
        start_date
        limit_time
        book_lesson {
          code
          title
        }
        assigned_elibrary_group {
          class {
            idx
            name
          }
        }
        user_study {
          idx
          stage_no
          exam_total
          exam_correct
          study_date
          recording_data
          wpm
          recording_filepath
          user {
            idx
            name
          }
        }

        assigned_elibrary_users {
          user {
            idx
            id
            name
          }
          status
        }
      }
    }
  }
`;

export const SINGLE_ASSIGNED_ELIBRARY = gql`
  query singleAssignedElibrary($idx: Int!) {
    singleAssignedElibrary(idx: $idx) {
      idx
      start_date
      limit_time
      book_lesson {
        code
        title
      }
      assigned_elibrary_group {
        class {
          name
        }
      }
      user_study {
        idx
        stage_no
        exam_total
        exam_correct
        study_date
        recording_data
        wpm
        recording_filepath
        user {
          idx
          name
        }
      }

      assigned_elibrary_users {
        user {
          idx
          id
          name
        }
        status
      }
    }
  }
`;
