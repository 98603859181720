import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip, Select, Checkbox, Space, Table, DatePicker } from 'antd';
import { useQuery } from '@apollo/client';
import { useHistory,useLocation, Link } from 'react-router-dom';
import * as queries from 'src/operations/queries';
import queryString from 'query-string';
import moment from 'moment';
import { userInfoVar } from 'src/apollo/cache';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 15px 45px;
  align-items: center;
  width: 100%;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 20px 13px;
  padding: 20px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Title = styled.div`
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
`;
const AssignButton = styled(RowFlex)`
  border: 2px solid #ffbe00;
  border-radius: 5px;
  padding: 5px 25px;
  color: #000000;
  background-color: #ffffff;
  height: 32px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
`;
const TableWrapper = styled(ColumnFlex)`
  margin: 10px 20px;
  border: 2px solid #2294f8;
`;
const TableHead = styled(RowFlex)`
  background-color: #363ad9;
  color: #fff;
  width: 100%;
  justify-content: space-between;
  font-size: 18px;
  padding: 8px 10px;
`;
const RedRow = styled.tr`
  background-color: #ffe9e5 !important;
`;
const YellowRow = styled.tr`
  background-color: #fff4c7 !important;
`;
const SKILL_LIST = [
  'Cause and Effect',
  'Character, Setting, and Plot',
  'Classify/Categorize',
  'Compare and Contract',
  'Convey Information',
  'Interpret Graphics or Text Features',
  'Main Idea and Details',
  'Make Inferences',
  'Recall',
  'Sequence Events',
  'Vocabulary',
  'Writing Conventions',
];
const { RangePicker } = DatePicker;
export default function StatReportAssign({ user }) {
  const location = useLocation();
  const {class_idx,user_idx} = location.state;
  // const { class_idx,user_idx } = queryString.parse(window.location.search);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedUser, setSelectedUser] = useState(-1);
  const [skillCheckedList, setSkillCheckedList] = useState();
  const [selectedSkills, setSelectedSkills] = useState([])
  const [ skilled_books, setSkilled_books] = useState([])
  const [bookIdxArr, setBookIdxArr] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
// console.log(classIdx,useIdx,location,'location test')
  useEffect(() => {
    // const currentDate = moment();
    // const firstDay = currentDate.clone().startOf('month');
    // const lastDay = currentDate.clone().endOf('month');
    // // const startDateFormatted = firstDay.format('YYYY-MM-DD');
    // const endDateFormatted = lastDay.format('YYYY-MM-DD');

    const curDate = new Date();
    let year = curDate.getFullYear();
    const curmonth = (curDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 and ensure two digits
    const curday = curDate.getDate().toString().padStart(2, '0');
    let month = curDate.getMonth() + 1;
    let day = curDate.getDate();

    // month -= 3;

    // if (month < 1) {
    //   year -= 1;
    //   month += 12;
    // }

    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    setStartDate(`${year-1}-${curmonth}-${curday}`);
    setEndDate(`${year}-${curmonth}-${curday}`);
  }, []);
  const classQuery = useQuery(queries.getClass.GET_TYPE_CLASS, {
    variables: {
      state: 'ing',
    },
  });

  const skillList = useQuery(queries.getClass.GET_SKILL, {
    variables: {
      class_idx: selectedClass?selectedClass?.idx:class_idx,
      start_date: startDate,
      end_date: endDate,
      user_idx:selectedUser?selectedUser?.user?.idx:user_idx
    },
    skip: !selectedClass&&!selectedUser
  });
  const { data, loading } = useQuery(queries.getClass.CLASS_STUDENT_LIST, {
    variables: { class_idx: parseInt(selectedClass?.idx) },skip:!selectedClass
  });
  useEffect(()=>{
    if(classQuery?.data?.classList&&class_idx){
      setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(class_idx))[0]);
    }
  },[class_idx])
  useEffect(()=>{
    if(user_idx&&data){
      setSelectedUser(data?.classStudentList?.filter((student)=>student?.user?.idx === parseInt(user_idx))[0])
    }
  },[user_idx,data])
  useEffect(() => {
    if (skillList?.data?.getSkill) {
      const skill = JSON.parse(skillList?.data?.getSkill);
      setSkillCheckedList(new Array(skill.length).fill(false));
    }
  }, [skillList]);

  useEffect(() => {
    const pastCheck = (date) => {
      const today = moment();
      return moment(today).isBefore(date);
    };
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
      if (selectedClass?.idx) {
        const classData =  classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(selectedClass?.idx))[0]
        if(classData == undefined){
          const obj = {
            idx:JSON.parse(selectedClass?.idx)
          }
          setSelectedClass(obj)
        }
        // else{
        //   setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(selectedClass?.idx))[0]);
        // }
      }
    }
    
  }, [classQuery, selectedClass]);
  // useEffect(() => {
  //   const pastCheck = (date) => {
  //     const today = moment();
  //     return moment(today).isBefore(date);
  //   };
  //   if (classQuery?.data?.classList) {
  //     setClasses(classQuery?.data?.classList);
  //     if (class_idx) {
  //       const classData =  classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(class_idx))[0]
  //       if(classData == undefined){
  //         const obj = {
  //           idx:JSON.parse(class_idx)
  //         }
  //         setSelectedClass(obj)
  //       }else{
  //         setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(class_idx))[0]);
  //       }
  //     }
  //   }
  //   if(user_idx){
  //     setSelectedUser(data?.classStudentList?.filter((student)=>student?.user?.idx === parseInt(user_idx))[0])
  //   }
  // }, [classQuery, class_idx,user_idx,data]);

  function handleChange(value) {
    setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === value)[0]);
  }
  function handleUserChange(value){
    setSelectedUser(data?.classStudentList?.filter((student)=>student?.user?.idx === parseInt(value))[0])
  }
  function onChange(e, idx, record) {
    setBookIdxArr([...bookIdxArr, record]);
    skillCheckedList[idx] = e.target.checked;
    setSkillCheckedList(JSON.parse(JSON.stringify(skillCheckedList)));
    let idxs = selectedSkills

    if(e.target.checked) {
      setSelectedSkills([...selectedSkills, ...[idx]])
      idxs = [...idxs, ...[idx]]
    } else {
      let selectedSkillIndex = selectedSkills.findIndex((b) => b == idx);
      if(selectedSkillIndex > -1) {
        idxs.splice(selectedSkillIndex, 1)
        selectedSkills.splice(selectedSkillIndex, 1);
        setSelectedSkills(selectedSkills)
      }
    }

    let book_ids = []
    const skill = JSON.parse(skillList?.data?.getSkill);
    for(let i=0; i< skill?.length; i++) {
       if(idxs.includes(i)) {
        book_ids = [...book_ids, ...skill[i].book_ids]
       }
    }

    setSkilled_books(book_ids)
  }
  function onCheckAllChange(e) {
    if (e.target.checked) {
      const skill = JSON.parse(skillList?.data?.getSkill);
      const newCheckedList = skillCheckedList.map(() => true);
      setSkillCheckedList(newCheckedList);
      setBookIdxArr(skill);
      let book_ids = []
      for(let i=0; i< skill?.length; i++) {
        book_ids = [...book_ids, ...skill[i].book_ids]
      }
      setSkilled_books(book_ids)
    } else {
      const newCheckedList = skillCheckedList.map(() => false);
      setSkillCheckedList(newCheckedList);
      setBookIdxArr([]);
      setSkilled_books([])
    }
  }
  const getRowClassName = (text) => {
    if (text?.rate) {
      const { rate } = text;
      return parseInt(rate) >= 80 ? '' : parseInt(rate) >= 70 ? 'yellow-row' : 'red-row';
    }
    return '';
  };

  const dataSrc = useMemo(() => {
    if (skillList?.data?.getSkill) {
      const parseSkill = JSON.parse(skillList?.data?.getSkill);
      const res = parseSkill?.map((ele, i) => {
        // console.log("ele",ele);
        const obj = {
          index: i,
          skill: ele?.skills == null ? "NO SKILL":ele.skills,
          completed: ele?.complete + '/' + ele?.totalAttmp,
          rate: ele?.totalAttmp===0?'-':Math.round((ele?.complete / ele?.totalAttmp) * 100),
          book_idx: ele.book_id,
          book_ids:ele?.book_ids
        };
        return obj;
      });
      return res;
    }
  }, [skillList]);
  const columns = [
    {
      title: () => {
        return <Checkbox onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (record) => {
        return (
          <Space size="middle">
            <Checkbox
              checked={skillCheckedList ? skillCheckedList[record?.index] : ''}
              onChange={(e) => onChange(e, record?.index, record)}
              disabled={record.skill==="NO SKILL"}
            ></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
    {
      title: 'SKILL',
      dataIndex: 'skill',
      align: 'center',
      key: 'skill',
      sorter: (a, b) => a.skill.localeCompare(b.skill),
      render: (text) => {
        return <span style={{ color: '#17aafb' }}>{text}</span>;
      },
    },
    {
      title: 'CORRECT/TOTAL',
      dataIndex: 'completed',
      align: 'center',
      key: 'completed',
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'ANSWER RATE',
      dataIndex: 'rate',
      align: 'center',
      key: 'rate',
      sorter: (a, b) => a.rate-b.rate,
      render: (text) => {
        const color = parseInt(text) >= 80 ? '#217570' : parseInt(text) >= 70 ? '#ef861c' : '#cf2918';
        return <span style={{ color: color }}>{text!=='-' ? text+'%' : '-'}</span>;
      },
    },
  ];

  function onRangeChange(dates, dateStrings) {
    console.log("dateStrings",dateStrings);
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  }
  return (
    <Wrapper>
      <MainWrapper>
        <Main>
          <RowFlex>
            <Title>Literacy Report</Title>
            <RowFlex style={{ marginRight: '20px' }}>
              {/* <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
                <Select
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: '100%', minWidth: 180 }}
                  placeholder="Class"
                  onChange={handleChange}
                  value={classData[0]?.name}
                  // defaultValue={ classData[0]?.name}
                >
                  {classes.map((classInfo) => (
                    <Select.Option key={classInfo.idx} value={classInfo.idx}>
                      {classInfo.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
              {selectedClass ? <Select
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: '100%', minWidth: 180 }}
                placeholder="Class"
                onChange={handleChange}
                value={selectedClass?.name}
              >
                {classes.map((classInfo) => (
                  <Select.Option key={classInfo.idx} value={classInfo.idx}>
                    {classInfo.name}
                  </Select.Option>
                ))}
              </Select>
                :
                <Select
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: '100%', minWidth: 180 }}
                  placeholder="Class"
                  onChange={handleChange}
                >
                  {classes.map((classInfo) => (
                    <Select.Option key={classInfo.idx} value={classInfo.idx}>
                      {classInfo.name}
                    </Select.Option>
                  ))}
                </Select>
              }
               &nbsp;&nbsp;
                {user_idx  ? <Select
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                style={{ width: '100%', minWidth: 180 }}
                placeholder="Student"
                onChange={handleUserChange}
                value={selectedUser?.user?.name}
              >
                {data?.classStudentList?.map((student) => (
                  <Select.Option key={student?.user?.idx} value={student?.user?.idx}>
                    {student?.user?.name}
                  </Select.Option>
                ))}
              </Select>
                :
                ''
              }
              &nbsp;
              &nbsp;
              <BackButton/>
              
            </RowFlex>
          </RowFlex>
          <TableWrapper>
            <TableHead style={{alignItems:'center'}}>
              <RowFlex style={{alignItems:'center'}}>
              <span>READING COMPREHENSION: SKILLS</span>&nbsp;&nbsp;
              <Tooltip title='Choose skills' color='rgba(255, 190, 0, 0.3)'>
              {selectedClass !== -1 ? (
                <Link to={{ pathname: `/level-up/booklist`, state: { selectedClass: selectedClass?.idx, level: selectedClass?.levelup_level, selectedUser:selectedUser?.user?.idx, bookIdxArr,skilled_books,skills:skillList?.data?.getSkill } }}>
                  <AssignButton>Search Books</AssignButton>
                </Link>
              ) : (
                <AssignButton
                  onClick={() => {
                    alert('Select the Class.');
                  }}
                >
                  Search Books
                </AssignButton>
              )}
              </Tooltip>
              </RowFlex>
              {startDate && endDate ? (
                <RangePicker
                  onChange={onRangeChange}
                  name="rangeDate"
                  defaultValue={[moment(startDate), moment(endDate)]} // Remove the single quotes
                />
              ) : (
                ''
              )}
            </TableHead>
            <Table
              dataSource={dataSrc}
              columns={columns}
              scroll={{ y: 'calc(100vh - 340px)' }}
              // scroll={{ y: 'calc(100vh - 224px)' }}
              pagination={false}
              size="small"
              rowClassName={getRowClassName}
              components={{
                body: {
                  row: (props) => {
                    const { className, ...restProps } = props;

                    if (getRowClassName(restProps.record) === 'yellow-row') {
                      return <YellowRow className={className} {...restProps} />;
                    } else if (getRowClassName(restProps.record) === 'red-row') {
                      return <RedRow className={className} {...restProps} />;
                    } else {
                      return <tr className={className} {...restProps} />;
                    }
                  },
                },
              }}
            />
          </TableWrapper>
        </Main>
      </MainWrapper>
    </Wrapper>
  );
}
