import React, { useEffect, useState } from 'react';
import { Modal, Typography, Divider, Col, Row } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
import MessageBox from 'src/components/DashBoard/SubPage/MessageBox';
import MessageItem from 'src/components/DashBoard/SubPage/MessageItem';

const { Text } = Typography;

function BookReportAnswerModal({ visible, handleCancel, chatList, topic }) {
  return (
    <Modal
      title={`Topic: ${topic}`}
      visible={visible}
      width="50%"
      bodyStyle={{ padding: '0 15px 15px 15px', background: '#f5f5f5' }}
      headerStyle={{ background: '#f5f5f5' }}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        <MessageBox
          id="MessageBox"
          style={{
            height: '55vh',
            overflow: 'auto',
            border: '1px solid #1890ff',
            margin: '2px 0 20px 0',
            backdround: '#fff',
          }}
        >
          <InfiniteScroll
            scrollableTarget="MessageBox"
            style={{ display: 'flex', flexDirection: 'column' }}
            inverse={true}
            hasMore={false}
            dataLength={chatList?.length}
          >
            {chatList?.map((thread, key) => {
              const isMe = Number(userInfoVar()?.id) === thread.user;
              return (
                <React.Fragment key={`message-item-key${key}`}>
                  <DateDivider
                    isShow={
                      key < chatList.length && moment(new Date(thread?.created)).format('YYYY-MM-DD') !== moment(new Date(chatList[key - 1]?.created)).format('YYYY-MM-DD')
                    ||
                    moment(new Date(thread?.idate)).format('YYYY-MM-DD') !== moment(new Date(chatList[key - 1]?.idate)).format('YYYY-MM-DD')
                    }
                    date={thread?.created ? `${moment(new Date(thread?.created)).format('YYYY-MM-DD')}` : `${moment(new Date(thread?.idate)).format('YYYY-MM-DD')}`}
                  />
                  <MessageItem
                    isMe={isMe}
                    name={thread.chat_user_name}
                    message={thread.content ? thread.content : thread.completion_text}
                    urls={thread.urls}
                    date={thread?.created ? thread?.created : thread?.idate?.split("T")[0]}
                    readYN={true}
                    unreadCount={0}
                  />
                </React.Fragment>
              );
            })}
          </InfiniteScroll>
        </MessageBox>
      </div>
    </Modal>
  );
}

export default BookReportAnswerModal;

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};
