import React, { useState, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
//import CustomTable from 'src/components/common/CustomTable';
import { userInfoVar, userTypVar } from 'src/apollo/cache';
import TableTransfer from 'src/components/common/TableTransfer';
import { CREATE_CHAT_ROOM } from 'src/operations/mutations/messenger';
import { GET_STUDENT_LIST, GET_CAMPUS_STUDENT_LIST } from 'src/operations/queries/messenger';
import { checkIfCampusUser } from 'src/utils';

const columns = [
  {
    title: '반이름',
    align: 'center',
    dataIndex: 'className',
    key: 'className',
  },
  {
    title: '학생ID',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '이름',
    align: 'center',
    dataIndex: 'name',
    key: 'name',
  },
];

const StudentListModal = ({ visible, onCancel, onChatStart, teacher_idx }) => {
  const [selectKeys, setSelectKeys] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [direction, setDirection] = useState([]);

  const [createRoom, { loading: loadingCreate }] = useMutation(CREATE_CHAT_ROOM, {
    onCompleted(data) {
      if (data) {
        console.log('CREATE_CHAT_ROOM onCompleted', data);
        onChatStart(data);
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });
  // const handleStart = (user_idx) => {
  //   const chat_user_idx_list = [parseInt(user_idx), parseInt(teacher_idx)];
  //   createRoom({ variables: { chat_user_idx_list } });
  // };

  const userType = userInfoVar()?.type;

  const { data, loadding: loadingStudent } = useQuery(checkIfCampusUser(userType) ? GET_CAMPUS_STUDENT_LIST : GET_STUDENT_LIST, {
    variables: checkIfCampusUser(userType) ? { type: userTypVar().student } : { teacher_idx: parseInt(teacher_idx) },
  });

  console.log(data);

  const students = useMemo(() => {
    const list = [];
    if (data?.classTeachers) {
      for (let i = 0; i < data.classTeachers.length; i++) {
        const className = data?.classTeachers[i]?.class?.name;
        const users = data.classTeachers[i].class.class_student.map((item, key) => ({ ...item.user, className, key: item.user.idx }));
        console.log('users=>', users);
        list.push(...users);
      }
    } else if (data?.getUsers) {
      for (let item of data?.getUsers) {
        const className = item.class_student[0]?.class?.name;
        console.log('--->', item);
        list.push({ key: item.idx, id: item.id, name: item.name, className });
      }
    }

    return list;
  }, [data]);

  const onChange = (targetKeys, direction) => {
    setTargetKeys(targetKeys);
    setSelectKeys(targetKeys);
    setDirection(direction);
  };

  const handleStartChat = () => {
    if (!direction || !selectKeys.length) {
      Modal.warning({
        title: '학생을 선택해 주세요.',
        okText: '확인',
      });
      return;
    }

    const chat_user_idx_list = [...targetKeys, parseInt(teacher_idx)];
    console.log('chat_user_idx_list', chat_user_idx_list);

    createRoom({ variables: { chat_user_idx_list } });
  };

  return (
    <Modal
      className="modallistingdashboard"
      visible={visible}
      title={`학생목록`}
      onCancel={onCancel}
      width={`50%`}
      footer={[
        <Button key="start" type="primary" onClick={handleStartChat}>
          시작
        </Button>,
        <Button key="back" onClick={onCancel}>
          닫기
        </Button>,
      ]}
    >
      {/* <CustomTable dataSource={students} columns={columns} pagination={{ pageSize: 5 }} size="small" color="#edf3fb" /> */}
      <TableTransfer
        loading={loadingStudent}
        dataSource={students}
        titles={['', '']}
        targetKeys={targetKeys}
        //showSearch={true}
        onChange={onChange}
        filterOption={(inputValue, item) => item.userid.indexOf(inputValue) !== -1 || item.name.indexOf(inputValue) !== -1}
        leftColumns={columns}
        rightColumns={columns}
        locale={{ itemUnit: '명', itemsUnit: '명' }}
      />
    </Modal>
  );
};

export default StudentListModal;
