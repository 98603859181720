import React, { useState } from 'react';
import { Row, Col, DatePicker, TimePicker, Button, Checkbox, Space } from 'antd';
import moment from 'moment';

const ReserveTxt = ({ sendingCount, loadingSend, onSend }) => {
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [checked, setChecked] = useState(false);

  const toggleCheck = (e) => {
    setChecked(e.target.checked);
  };
  const handleSend = () => {
    if (!checked) {
      if (!date) {
        alert('날짜를 선택하세요.');
        return;
      }
      if (!time) {
        alert('시간을 선택하세요.');
        return;
      }
    }
    const reserved_date = checked ? '' : `${date.format('YYYYMMDD')}${time.format('HHmmss')}`; //서버에서 사용하는 날짜 형식에 맞춰줌.
    onSend(checked, reserved_date);
  };

  return (
    <>
      <Row style={{ justifyContent: 'center' }}>
        <Col className="picker-con">
          <Space direction="vertical" size={12}>
            <div style={{ width: '100%', display: 'inline-flex' }}>
              <DatePicker
                placeholder="예약일"
                onChange={setDate}
                value={date}
                disabledDate={(currentDate) => {
                  if (moment(currentDate).isBefore(moment().format('YYYY-MM-DD'))) {
                    return true;
                  }
                  return false;
                }}
              />
            </div>
            <TimePicker format="h:mm" value={time} onChange={setTime} />
          </Space>
        </Col>
      </Row>
      <div className="reserveOption_Divide">
        <Row className="reserveOptionWrap">
          <Col>
            <span className="toSendMsg_tit"> 즉시 보내기 : </span> <Checkbox checked={checked} onChange={toggleCheck} />
          </Col>
        </Row>
        <Row className="reserveOptionWrap">
          <Col>
            <span> 보내는 문자 : </span>
            <div className="InputUsed" style={{ border: '1px solid #333;' }}>
              <span className="ToSendNum">{sendingCount}</span>
            </div>
          </Col>
        </Row>
        <Row className="reserveBtnWrap">
          <Button className="cancelBtn"> 취소 </Button>
          <Button className="sendBtn" onClick={handleSend} loading={loadingSend}>
            {' '}
            {loadingSend ? '전송중' : '보내기'}{' '}
          </Button>
        </Row>
      </div>
    </>
  );
};

export default ReserveTxt;
