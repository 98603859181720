//참고사이트 : https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
import { Observable } from '@apollo/client';

const promiseToObservable = (promise) =>
  new Observable((subscriber) => {
    console.log('-----promiseToObservable-----');
    promise.then(
      (value) => {
        console.log('promiseToObservable value===>', value);
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err),
    );
    return subscriber; // this line can removed, as per next comment
  });
export default promiseToObservable;
