import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, SoundFilled, CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import * as axios from 'axios';
import _ from 'lodash';
import Timer from 'src/components/LevelUp/Timer';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #5b9bd5;
  color: #fff;
  font-size: 25px;
  position: absolute;
  @media (max-width: 1280px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
`;
const StageTitle = styled.div`
  border-bottom: 1px solid #ed7d31;
  width: 100%;
  padding: 10px 30px;
`;
const StageTitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#0070c0')};
  font-size: 25px;
  font-weight: 600;
  padding: 0 45px;
  @media (max-width: 1280px) {
    font-size: 22px;
    padding: 40px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #7030a0;
  padding: 30px 0;
  @media (max-width: 1280px) {
    font-size: 20px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 5px 30px 15px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;

const TopBody = styled(ColumnFlex)`
  width: 100%;
  padding: 20px;
  /* justify-content: space-between; */
  align-items: center;
  height: 40%;
  @media (max-width: 1280px) {
    height: 45%;
  }
`;

const TimeNumber = styled.span`
  color: #ff9900;
`;
const ScoreNumber = styled.span`
  color: #4472c5;
`;
const InfoBox = styled(ColumnFlex)`
  color: #000000;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: 2px #5b9bd5 solid;
  border-radius: 10px;
  font-size: 30px;
  width: 100%;
  height: 90%;
  background-color: #fbfbfb;
  @media (max-width: 1280px) {
    height: 95%;
  }
`;
const EmptyBoxWrapper = styled(RowFlex)`
width: 100%;
justify-content: center;
padding: 0 40px;
flex-wrap: wrap;
`;
const DirectionText = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
  width: 100%;
  @media(max-width:1280px){
    font-size: 17px;
    padding: 10px 20px;
  }
`;
const TopInnerBox = styled(RowFlex)`
width: 100%;
align-items: center;
padding: 20px;
@media(max-width:1280px){
   padding: 10px 20px;
  }
`;
const BottomBody = styled(ColumnFlex)`
  width: 100%;
  padding: 20px;
  height: 45%;
`;

const WordListBox = styled(RowFlex)`
  background-color: #fff9f3;
  border-radius: 25px;
  border: 2px #ff9900 solid;
  height: 90%;
  color: #2e75b6;
  font-size: 28px;
  align-items: center;
  padding: 25px 40px;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1280px) {
    height: 95%;
  }
`;
const EmptyWordBox = styled(ColumnFlex)`
  border-radius: 25px;
  border: 3px #74c5f3 dashed;
  /* background-color: #64a6f7; */
  color: #fff;
  width: 150px;
  font-size: 17px;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin: 5px 0 5px 15px;
  cursor: pointer;
  @media(max-width:1280px){
    width: 130px;
    height: 40px;
    font-size: 15px;
  }
`;
const WordBox = styled(ColumnFlex)`
  border-radius: 25px;
  border: 3px #74c5f3 dashed;
  background-color: #64a6f7;
  color: #fff;
  width: 150px;
  font-size: 17px;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  cursor: pointer;
  @media(max-width:1280px){
    width: 130px;
    height: 40px;
    font-size: 15px;
  }
`;
const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  border-radius: 15px;
  /* border: 1px red solid; */
  background-image: url(${(props)=>props.bg});
  background-size:  72px 50px;
  background-repeat: no-repeat;
  /* background-color: ${(props) => (props.active ? 'red' : 'orange')}; */
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;
export default function LevelUpSentenceDnD({}) {
  const dummy = ['This is a book.', 'I like cats.'];
  const sliced = useMemo(() => {
    const tmp = [];
    if (dummy.length > 0) {
      dummy.map((e) => {
        const replacer = e.replace(/[.,?!]/g, '');
        const slicer = replacer.split(' ').map((word, i) => {
          return { word: word, number: i + 1 };
        });

        tmp.push(_.shuffle(slicer));
      });
    }
    return tmp;
  }, [dummy]);
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <StageTitle>
              <StageTitleSpan>BOOK Title</StageTitleSpan>
              <StageTitleSpan>Unit</StageTitleSpan>
              <StageTitleSpan>Lesson</StageTitleSpan>
              <StageTitleSpan color={'#7030a0'}>Topic, title</StageTitleSpan>
            </StageTitle>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '93%', paddingTop: '13px' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Title>Listen and Repeat.</Title>
                <RowFlex style={{ fontSize: '18px', justifyContent: 'right' }}>
                  Time:&nbsp;{' '}
                  <TimeNumber>
                    <Timer />
                  </TimeNumber>
                  &nbsp;&nbsp;&nbsp;Number:&nbsp;
                  <ScoreNumber>
                    {`3`}/{`10`}
                  </ScoreNumber>
                </RowFlex>
              </RowFlex>
              <TopBody>
                <InfoBox>
                  <DirectionText>Drag and Drop.</DirectionText>
                  <TopInnerBox>
                    <ImgButton>
                      <SoundFilled />
                    </ImgButton>
                    <EmptyBoxWrapper >
                      {sliced[0].map((e) => (
                        <EmptyWordBox key={e.number}></EmptyWordBox>
                      ))}
                    </EmptyBoxWrapper>
                  </TopInnerBox>
                </InfoBox>
              </TopBody>
              <BottomBody>
                <WordListBox>
                  <>
                    {sliced[0].map((e) => (
                      <WordBox key={e.number}>{e.word}</WordBox>
                    ))}
                  </>
                </WordListBox>
              </BottomBody>
              <RowFlex style={{ justifyContent: 'space-between', fontSize: '50px', height: '50px', width: '100%', marginTop: '10px' }}>
                <CaretLeftFilled style={{ color: '#bfbfbf', cursor: 'pointer' }} />
                <RowFlex style={{ height: '50px', alignItems: 'center', fontSize: '25px', justifyContent: 'center' }}>
                <StageButton bg={`/images/LevelUp/study/sub/g_flashcard.png`} active={false} isDone={true} />
                    <ArrowRightOutlined style={{ margin: '0 10px' }} />
                    <StageButton bg={`/images/LevelUp/study/sub/g_check.png`} active={true} isDone={false} />
                    <ArrowRightOutlined style={{ margin: '0 10px' }} />
                    <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} active={false} isDone={false} />
                    <ArrowRightOutlined style={{ margin: '0 10px' }} />
                    <StageButton bg={`/images/LevelUp/study/sub/g_quiz.png`} active={false} isDone={false} />
                </RowFlex>
                <CaretRightFilled style={{ color: '#eaaa3e', cursor: 'pointer' }} />
              </RowFlex>
            </ColumnFlex>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
