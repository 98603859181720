import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, ArrowLeftOutlined, SoundFilled } from '@ant-design/icons';
import * as axios from 'axios';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Timer from 'src/components/LevelUp/Timer';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #443ec8;
  color: #fff;
  font-size: 35px;
  box-shadow: 0 2px 1px 2px #090989;
`;

const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  /* min-height: 550px; */
  min-height: 650px;
  /* @media (max-width: 1280px) { */
  /* width: 95%; */
  /* min-width: 800px; */
  /* } */
`;
const StageTitle = styled.div`
  color: #fff;
  padding: 5px 30px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  background-color: #49ada1;
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  background-color: #fff;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
  width: 55px;
`;
const Body = styled(ColumnFlex)`
  background-color: #daedeb;
  border-radius: 20px;
  font-size: 22px;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  align-items: center;
  /* width: 100%; */
  height: 100%;
  margin: 0 20px;
  `;
const InnerBox = styled(RowFlex)`
height: 100%;
  width: 100%;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  @media (max-width: 1280px) {
    padding: 10px 20px;
  }
`;
const PassageBox = styled.div`
  width: 90%;
  height: 100%;
  padding: 20px 25px;
  text-align: start;
  background-color: #fff;
  border-radius: 20px;
`;

const StageButton = styled.div`
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px red solid;

  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;
const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  max-width: 800px;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const ArrowIcon = styled.div`
  font-size: 40px;
  color: #453ec8;
  font-weight: bolder;
  padding: 0 35px;
  cursor: pointer;
`;
export default function EngStaPassageDictation({}) {
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <Title>
              <TitleSpan color={'#453ec8'}>BOOK Title</TitleSpan>
              <TitleSpan>Volume</TitleSpan>
              <TitleSpan>Unit</TitleSpan>
              <TitleSpan>Lesson</TitleSpan>
              <TitleSpan>Lesson Name</TitleSpan>
            </Title>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '90%', width: '100%', padding: '0 20px 20px 30px' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '20px 50px' }}>
                <StageTitle>Passage Dictation</StageTitle>
                <NumberText>
                  Number{' '}
                  <QuizNumber>
                    <span style={{ color: '#433fc8' }}>{`03`}</span>/{`10`}
                  </QuizNumber>
                </NumberText>
              </RowFlex>
              <Body>
                <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '0px 50px' }}>
                  <div style={{paddingLeft:'100px'}}>
                  <NumberText>
                    Space{' '}
                    <QuizNumber>
                      <span style={{ color: '#453fc7' }}>{'30'}%</span>
                    </QuizNumber>
                  </NumberText>{' '}
                  </div>
                  <RowFlex style={{ fontSize: '18px', justifyContent: 'right' }}>
                    <NumberText>
                      Tries{' '}
                      <QuizNumber>
                        <span style={{ color: '#433fc8' }}>{`02`}</span>/{`05`}
                      </QuizNumber>
                    </NumberText>
                    <NumberText>
                      Score{' '}
                      <QuizNumber>
                        <span style={{ color: '#433fc8' }}>80</span>
                      </QuizNumber>
                    </NumberText>
                    <NumberText>
                      Time{' '}
                      <QuizNumber>
                        <span style={{ color: '#ff005c' }}>
                          <Timer />
                        </span>
                      </QuizNumber>
                    </NumberText>{' '}
                    &nbsp;&nbsp;&nbsp;
                  </RowFlex>
                </RowFlex>
                <InnerBox>
                  <ImgButton>
                    <SoundFilled />
                  </ImgButton>
                  <PassageBox>
                  text **** text ****
                  </PassageBox>
                </InnerBox>
              </Body>
            </ColumnFlex>
          </Main>
        </MainWrapper>
        <StageButtonWrapper>
          <ArrowIcon>
            <ArrowLeftOutlined />
          </ArrowIcon>
          <RowFlex style={{ justifyContent: 'space-between', width: '35%', height: '100%' }}>
            <StageButtonText active={false}>
              <StageButton isDone={true} />
              Flashcard
            </StageButtonText>
            <StageButtonText active={true}>
              <StageButton isDone={true} />
              Check
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton isDone={true} />
              Typing
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton isDone={true} />
              Quiz
            </StageButtonText>
          </RowFlex>
          <ArrowIcon>
            <ArrowRightOutlined />
          </ArrowIcon>
        </StageButtonWrapper>
      </Wrapper>
    </>
  );
}
