import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { Modal, Form, Input, Button } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';
import { phoneNumberCheck } from 'src/utils';
import { useForm } from 'src/utils/hooks';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { GET_CAMPUS_INFO_SHORT } from 'src/operations/queries/campus';

const { USER_EXIST_CHECK } = queries.getUser;
const { CREATE_BRANCH, UPDATE_BRANCH } = mutations.company;

const BranchModal = ({ visible, handleCancel, popupMode, onSuccess, idx = null }) => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState('');
  const { data: dataBranch } = useQuery(GET_CAMPUS_INFO_SHORT, { skip: !idx, fetchPolicy: 'no-cache', variables: { campus_idx: idx } });

  const { onChange, onSubmit, values } = useForm(formCallback, {
    name: '',
    phone: '',
    address: '',
    fax: '',
    userId: '',
    userPassword: '',
  });

  const [isUserId, { data }] = useLazyQuery(USER_EXIST_CHECK);

  useEffect(() => {
    if (dataBranch?.getCampusInfo) {
      console.log('getCampusInfo', dataBranch.getCampusInfo);
      const { name, phone, address, fax, user } = dataBranch.getCampusInfo;
      form.setFieldsValue({
        name,
        phone,
        address,
        fax,
        userId: user[0]?.id,
      });
    }
  }, [dataBranch]);

  const [createBranch, { loading }] = useMutation(CREATE_BRANCH, {
    variables: {
      branch_info: values,
    },

    onCompleted(data) {
      if (data) {
        openNotification('등록 완료!');
        handleCancel();
        onSuccess();
      }
    },
    onError(error) {
      console.log('error', error);
      alert('지사 등록 중 오류가 발생했습니다.');
    },
  });

  const [updateBranch, { loading: loadingUpdate }] = useMutation(UPDATE_BRANCH, {
    onCompleted(data) {
      if (data) {
        openNotification('수정 완료!');
        handleCancel();
        onSuccess();
      }
    },
    onError(error) {
      console.log('error', error);
      alert('지사 수정 중 오류가 발생했습니다.');
    },
  });

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
    onChange(e);
  };

  function formCallback(values) {
    if (popupMode === 'create') {
      createBranch();
    } else {
      if (!idx) {
        alert('오류 발생!');
      }
      console.log('수정', form.getFieldsValue());
      const branch_info = form.getFieldsValue();

      updateBranch({ variables: { idx: parseInt(idx), branch_info } });
    }
  }

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }

    form.setFieldsValue({
      phone: phone ? phone : undefined,
    });
  }, [form, onChange, phone]);

  const write_text = useMemo(() => {
    return popupMode === 'create' ? '등록' : '수정';
  }, [popupMode]);

  const disabledField = useMemo(() => {
    return popupMode === 'create' ? false : true;
  }, [popupMode]);

  return (
    <>
      <Modal
        entered
        title={`지사 ${write_text}`}
        visible={visible}
        onCancel={handleCancel}
        width={'70%'}
        footer={[
          <Button key="button" loading={disabledField ? loadingUpdate : loading} type="primary" onClick={() => form.submit()}>
            {write_text}
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="no"
        >
          <FormField hasFeedback={true} title="업체명">
            <FormItem name="name" rules={[{ required: true, message: '업체명을 입력해 주세요.' }]} hasFeedback>
              <Input name="name" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="연락처">
            <FormItem name="phone" rules={[{ required: false }]} hasFeedback>
              <Input name="phone" onChange={(e) => handleChange(e)} maxLength={13} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="아이디">
            <FormItem
              name="userId"
              rules={[
                { required: true, message: '아이디를 입력하세요.' },
                {
                  validator: async (rule, value) => {
                    isUserId({
                      variables: {
                        userId: value,
                      },
                    });

                    if (data?.isUserId.idx) {
                      return Promise.reject([rule.message]);
                    }
                  },
                  message: '이미 존재하는 ID 입니다.',
                },
              ]}
              hasFeedback
            >
              <Input name="userId" autoComplete="new-password" onChange={onChange} maxLength={13} disabled={disabledField} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={!disabledField} title="비밀번호">
            <FormItem
              name="userPassword"
              rules={[
                { required: !disabledField, message: '비밀번호를 입력하세요.' },
                {
                  pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
                  validator: async (rule, value) => {
                    if (!disabledField && value.match(rule.pattern) !== null) {
                      return Promise.reject([rule.message]);
                    }
                  },
                  message: '한글은 입력할 수 없습니다.',
                },
              ]}
              hasFeedback
            >
              <Input.Password
                autoComplete="new-password"
                name="userPassword"
                onChange={onChange}
                maxLength={20}
                placeholder={`${disabledField ? '변경하려면 새로 입력하세요.' : ''}`}
              />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="주소">
            <FormItem name="address" rules={[{ required: false }]} hasFeedback>
              <Input name="address" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="Fax">
            <FormItem name="fax" rules={[{ required: false }]} hasFeedback>
              <Input name="fax" onChange={onChange} />
            </FormItem>
          </FormField>
        </Form>
      </Modal>
    </>
  );
};

BranchModal.prototype = {
  visible: PropTypes.bool.isRequired,
  popupMode: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default BranchModal;
