import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Typography, Button, Input, Modal } from 'antd';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import BookOrderMenuGroup from 'src/components/common/BookOrderMenuGroup';
import { GET_USER } from 'src/operations/queries/getUser';
import { getHumanReadablePhoneNumber } from 'src/utils';
import { bookReturnCartVar } from 'src/apollo/cart';
import { CREATE_BOOK_RETURN } from 'src/operations/mutations/bookReturn';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const { Title } = Typography;
const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;
const ReturnSubTitle = styled(Title)`
  margin-top: 10px;
`;

//반품요청확인 페이지
const BookReturnRequest = () => {
  const companyName = useSelector(classStoreData);
  const [bookReturnComment, setBookReturnComment] = useState('');
  const bookReturnCartData = useMemo(() => {
    const bookCartData = bookReturnCartVar();
    const bookItems = [];
    if (isAfterSchool(companyName)) {
      bookCartData.forEach((bookCartItem) => {
        if (bookCartItem.studentBookCount > 0) {
          bookItems.push({
            book_title: `${bookCartItem.book_title} 학생용`,
            book_code: bookCartItem.book_code,
            count: bookCartItem.studentBookCount,
            days: bookCartItem.days,
          });
        }
        if (bookCartItem.teacherBookCount > 0) {
          bookItems.push({
            book_title: `${bookCartItem.book_title} 교사용`,
            book_code: bookCartItem.book_code,
            count: bookCartItem.teacherBookCount,
            days: bookCartItem.days,
          });
        }
      });
      return bookItems;
    } else {
      return bookCartData;
    }
  }, []);

  const history = useHistory();

  const { data: userInfo } = useQuery(GET_USER, { variables: { more_company_info: true } });
  const [createBookReturn, { loading: loadingReturn }] = useMutation(CREATE_BOOK_RETURN, {
    onCompleted: ({ createBookReturn: res }) => {
      if (res.success) {
        Modal.info({
          title: '반품신청이 완료되었습니다.',
          onOk: () => {
            //반품 내역 페이지로 이동
            bookReturnCartVar([]);
            history.push('/book/return/list');
          },
        });
      } else {
        alert(res.message);
      }
    },
    onError: (e) => {
      console.log(e);
      alert('오류가 발생했습니다.');
    },
  });

  const productTitle = useMemo(() => {
    if (bookReturnCartData) {
      let name = bookReturnCartData[0].book_title;
      const itemCount = bookReturnCartData.length - 1;
      if (itemCount > 0) {
        name = `${name} 외 ${itemCount}건`;
      }
      return name;
    }
    return '';
  }, [bookReturnCartData]);

  function handleBookReturnRequest() {
    const book_list = bookReturnCartData?.map((item) => {
      return {
        code: item?.book_code,
        name: item?.book_title,
        quantity: item?.count ? Number(item?.count) : 0,
        price: item?.price ? Number(item?.price) : 0,
      };
    });
    createBookReturn({
      variables: {
        book_return_input: {
          title: productTitle,
          comment: bookReturnComment,
          book_list,
        },
      },
    });
  }
  return (
    <Col className="bookstore-book-order-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
      <Row gutter={[16, 16]}>
        <Col span={12} style={{ textAlign: 'left' }}>
          {/* <HeaderTitle level={4}>교재 반품</HeaderTitle> */}
          <BookOrderMenuGroup currentMenu="book-return" />
        </Col>
        <Col className="topheader-image" span={12} style={{ textAlign: 'right' }}></Col>
      </Row>
      <MainBlock className="bookstore-book-order-mainblock">
        <Row gutter={[16, 16]}>
          <Col className="bookstore-book-order-mainblock-a" span={24} style={{ textAlign: 'left' }}>
            <Row gutter={[16, 16]} className="bookstore-header-top">
              <Col className="bookstore-heading" span={12} style={{ textAlign: 'left', margin: 0 }}>
                <Title className="mainheader-left" level={5}>
                  교재 반품 신청
                </Title>
              </Col>
              <Col className="select-right" span={12} style={{ textAlign: 'right', margin: 0 }}></Col>
            </Row>
            <Row gutter={[16, 16]}>
              <div className="book-order-main-wrapper" style={{ width: '100%' }}>
                <Col className="booktable-main-tbl-main" span={24} style={{ textAlign: 'left', margin: 0 }}>
                  <table className="table-t-one-a">
                    <thead>
                      <tr>
                        <th style={{ width: '100px' }}>No.</th>
                        <th>반품내역</th>
                        <th>권수</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookReturnCartData &&
                        bookReturnCartData.map((item, key) => (
                          <tr key={`book-return-list-${key}`}>
                            <td style={{ textAlign: 'center' }}>{key + 1}</td>
                            <td style={{ textAlign: 'center' }}>{item?.book_title}</td>
                            <td>{item?.count} 권</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Col>
                <Col span={24} className="tablebookstore-2s">
                  <ReturnSubTitle className="mainheader-left" level={5}>
                    주문자정보
                  </ReturnSubTitle>
                  <table className="table-t-two">
                    <tr>
                      <th style={{ width: '200px' }}>주문자</th>
                      <td>{userInfo?.getUserData?.name}</td>
                      <th style={{ width: '200px' }}>휴대전화</th>
                      <td>{getHumanReadablePhoneNumber(userInfo?.getUserData?.phone)}</td>
                    </tr>
                    <tr>
                      <th style={{ width: '200px' }}>남기실 말씀</th>
                      <td colSpan={3}>
                        <Input
                          placeholder="영문40자, 한글 20자까지 가능"
                          maxLength={40}
                          value={bookReturnComment}
                          onChange={(e) => {
                            setBookReturnComment(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                </Col>
                <Col span={24} className="tablebookstore-2s">
                  <ReturnSubTitle className="mainheader-left" level={5}>
                    배송지 정보
                  </ReturnSubTitle>
                  <table className="table-t-two">
                    <tr>
                      <th style={{ width: '200px' }}>받으시는 분</th>
                      <td>{isAfterSchool(companyName) ? '(주) 상일이엔피' : userInfo?.getUserData?.campus?.company?.name}</td>
                      <th style={{ width: '200px' }}>휴대전화</th>
                      <td>{userInfo?.getUserData?.campus?.company?.phone}</td>
                    </tr>
                    <tr>
                      <th style={{ width: '200px' }}>배송지</th>
                      <td colSpan={3}>{userInfo?.getUserData?.campus?.company?.address}</td>
                    </tr>
                  </table>
                  <div style={{ marginTop: 10, textAlign: 'center' }}>
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      뒤로가기
                    </Button>{' '}
                    <Button onClick={handleBookReturnRequest} type="primary" loading={loadingReturn}>
                      신청하기
                    </Button>
                  </div>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
      </MainBlock>
    </Col>
  );
};

export default BookReturnRequest;
