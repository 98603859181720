import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, Space, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import { DownloadOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import CustomTable from 'src/components/common/CustomTable';
import { ASSIGNED_TEST_SINGLE } from 'src//operations/queries/getTests';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import _lo from 'lodash';
import { rank, ExportExcel } from 'src/utils';

const { Title } = Typography;

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Acolumnsndroid.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  min-height: calc(100vh - 15vh);
`;

const Details = (props) => {
  const [assigned_test, setAssignedTest] = useState(null);
  const [testUsers, setTestUsers] = useState([]);
  const [columns, setColumns] = useState([]);

  const expandableColumnExist = testUsers.find((user) => user?.children_data?.length > 0);

  const { data } = useQuery(ASSIGNED_TEST_SINGLE, {
    variables: { idx: parseInt(props.id) },
  });
  const handleDownload = async () => {
    // let blob = await fetch(assigned_test.tests.main_pdf.path).then((r) => r.blob());
    // FileSaver.saveAs(blob, assigned_test.tests.main_pdf.name);
    const fileName = assigned_test.tests.code;
    const excel_columns = columns.map((item) => {
      return {
        ...item,
        title:
          item.title instanceof Function
            ? item.title()?.props?.children?.props
              ? item.title()?.props?.children?.props?.children
              : item.title()?.props?.children
            : item.title,
      };
    });

    const testAnswers = data?.assignedTestsSingle.tests.test_answers;
    const assignedTestUsers = data?.assignedTestsSingle.assigned_test_users;
    const newData = testUsers.map((item, key) => {
      
      const obj = {};
      const keys = Object.keys(item);
      for (let i = 0; i < keys.length; i++) {
        if (isNaN(keys[i])) {
          obj[keys[i]] = item[keys[i]];
        } else {
          if(key===testUsers.length-1){
            obj[keys[i]] = item[keys[i]]+'%'
          }
         else{   obj[keys[i]] = item[keys[i]] ? 'O' : 'X';}
        }
      }
      if (assignedTestUsers.length !== key) {
        obj['correct_count'] = `${item.score}/${testAnswers?.length}`;
        obj['score'] = ((100 * item.score) / testAnswers.length).toFixed();
      } else {
        obj['correct_count'] = `--`;
      }

      return obj;
    }
    );
const newData2 = []
for(let i=0;i<newData.length;i++){
  newData2.push(newData[i])
  if(newData[i].children&&newData[i].children.length>0)
  {
   newData[i].children.map(
    (item, key) => {
      
      const obj = {};
      const keys = Object.keys(item);
      const questionLength = keys.filter((e)=>!isNaN(e)).length
      for (let i = 0; i < keys.length; i++) {
        if (isNaN(keys[i])) {
          obj[keys[i]] = item[keys[i]];
        } else {
            obj[keys[i]] = item[keys[i]] ? 'O' : 'X';
        }
      }
      obj['name'] =newData[i].name + ' retry'+(key+1)
      obj['score'] = '--'
      obj['correct_count'] = `${item.score}/${questionLength}`
       newData2.push({...obj});
    }
   )
  }
}
    ExportExcel(excel_columns, newData2, fileName);
    // console.log( newData,newData2)
  };

  useEffect(() => {
    if (data?.assignedTestsSingle) {
      setAssignedTest(data?.assignedTestsSingle);

      let lastRow = { idx: 0, name: 'Average', score: 0, totalTime: 0, score_total: 0, totalTime_total: 0 };
      const assignedTestUsers = data?.assignedTestsSingle.assigned_test_users;
      const testAnswers = data?.assignedTestsSingle.tests.test_answers;

      let columsData = [
        {
          title: () => {
            return <Space style={{ color: '#065aa9' }}>ID</Space>;
          },
          key: 'id',
          fixed: 'left',
          width: 50,
          render: (text, record, i) => {
            if (assignedTestUsers.length !== i || record.retake) {
              return <Space size="middle">{!record.retake ? i + 1 : ''}</Space>;
            } else {
              return {
                children: <Space style={{ background: '#efefef', padding: 0 }}></Space>,
                props: {
                  style: { background: '#efefef' },
                },
              };
            }
          },
        },
        {
          title: () => {
            return <Space style={{ color: '#065aa9' }}>Name</Space>;
          },
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 120,
          render: (text, record, i) => {
            if (assignedTestUsers.length !== i || record.retake) {
              if (assignedTestUsers[i]?.start_time) {
                return (
                  <Space
                    size="large"
                    style={{ color: '#065aa9', cursor: 'pointer' }}
                    onClick={() => props.history.push(`/test/new/${assignedTestUsers[i].idx}/manager`)}
                  >
                    {record.name}
                  </Space>
                );
              } else {
                return (
                  <Space
                    size="large"
                    style={{ color: '#065aa9', cursor: 'pointer' }}
                    onClick={() => {
                      Modal.info({
                        title: '시혐 결과가 없습니다.',
                        onOk() {},
                      });
                    }}
                  >
                    {record.name}
                  </Space>
                );
              }
            } else {
              return {
                children: <Space style={{ background: '#efefef', padding: 0 }}>{record.name}</Space>,
                props: {
                  style: { background: '#efefef' },
                },
              };
            }
          },
        },
        {
          title: () => {
            return (
              <div style={{ color: '#065aa9' }}>
                <div>Score</div>
              </div>
            );
          },
          dataIndex: 'score',
          key: 'score',
          fixed: 'left',
          width: 70,
          render: (text, record, i) => {
            if (assignedTestUsers.length !== i || record.retake) {
              return (
                <Space align="center">{_lo.isNil(record.score) ? '미응시' : record.retake?'--': ((100 * record.score) / testAnswers.length).toFixed()}</Space>
              );
            } else {
              //마지막 줄 Total 인 경우임.
              return {
                children: (
                  <Space style={{ background: '#efefef', padding: 0, color: text < 60 ? '#f36513' : '#222' }}>{`${record.score}`}</Space>
                ),
                props: {
                  style: { background: '#efefef' },
                },
              };
            }
          },
        },
        {
          title: () => {
            return (
              <div style={{ color: '#065aa9' }}>
                <div>정답 수</div>
              </div>
            );
          },
          dataIndex: 'correct_count',
          key: 'correct_count',
          fixed: 'left',
          width: 70,
          render: (text, record, i) => {
            if (assignedTestUsers.length !== i || record.retake) {
              // const questionLength = Object.keys(record).filter((e)=>!isNaN(e))
              return (
                <Space align="center">{_lo.isNil(record.score)  ? '--' : `${record.score}/${record.question_count}`}</Space>
              );
            } else {
              return {
                children: <Space style={{ background: '#efefef', padding: 0 }}>--</Space>,
                props: {
                  style: { background: '#efefef' },
                },
              };
            }
          },
        },
        {
          title: '소요시간',
          dataIndex: 'totalTime',
          key: 'totalTime',
          fixed: 'left',
          width: 70,
          render: (text, record, i) => {
            if (assignedTestUsers.length !== i || record.retake) {
              return <Space size="middle">{_lo.isNil(record.score) || record.retake ? '--' : `${record.totalTime} min`}</Space>;
            } else {
              return {
                children: <Space style={{ background: '#efefef', padding: 0 }}>{record.totalTime} min</Space>,
                props: {
                  style: { background: '#efefef' },
                },
              };
            }
          },
        },
        {
          title: '순위',
          dataIndex: 'ranking',
          key: 'ranking',
          fixed: 'left',
          width: 70,
          sorter: (a, b) => {
            //마지막 열인 경우암.
            //마지막열은 제일 마지막에 나와야 하므로 정렬에서 제외함
            if (a.idx === 0 || b.idx === 0 || a.retake || b.retake) {
              return 0;
            }
            if (!a.score) {
              return -1;
            }
            if (a.score === b.score) {
              return 0;
            }
            return a.score < b.score ? 1 : -1;
          },
          render: (_, record, i) => {
            if (assignedTestUsers.length !== i || record.retake) {
              const scoreArr = assignedTestUsers.map((ele) => ele.score);
              return rank(record.score, scoreArr);
            } else {
              return {
                children: <Space style={{ background: '#efefef', padding: 0 }}></Space>,
                props: {
                  style: { background: '#efefef' },
                },
              };
            }
          },
        },
      ];

      testAnswers.forEach((element) => {
        columsData.push({
          title: element.code,
          dataIndex: element.code,
          key: element.code,
          width: 50,
          render: (text, record, i) => {
            if (assignedTestUsers.length !== i || record.retake) {
              const sure = record[`${element.code}_data`]?.sure;
              const answer = record[`${element.code}_data`]?.answer;
              const { assigned_test_user } = record;
              //table sorting 할 때 값이 제대로 나오지 않아 변경함.
              // update on the sorting check
              // if (assignedTestUsers[i]['assigned_test_answers'].length && text !== undefined) {
              if (assigned_test_user && assigned_test_user['assigned_test_answers']?.length && text !== undefined) {
                if (text) {
                  let color = sure < 100 ? '#0070C0' : '#222';
                  return (
                    <Space
                      size="middle"
                      style={{
                        color: color,
                        fontWeight : sure < 100 && 'bold'
                      }}
                    >
                      O
                    </Space>
                  );
                } else {
                  let color = sure < 100 ? '#0070C0' : '#222';
                  color = answer === '' ? 'hotpink' : color;
                  return (
                    <Space
                      size="middle"
                      style={{
                        color: color,
                        fontWeight : sure < 100 && 'bold'
                      }}
                    >
                      X
                    </Space>
                  );
                }
              } else {
                return <Space size="middle">--</Space>;
              }
            } else {
              return {
                children: <Space style={{ background: '#efefef', padding: 0, color: text < 60 ? '#f36513' : '#222' }}>{`${text}%`}</Space>,
                props: {
                  style: { background: '#efefef' },
                },
              };
            }
          },
        });
      });

      let listUsers = assignedTestUsers.map((testU) => {
        const totalTime = testU.end_time ? moment(testU.end_time).diff(moment(testU.start_time), 'minutes') : 0;
        let tempUser = {
          key: testU.user.idx,
          idx: testU.user.idx,
          name: testU.user.name,
          score: testU.score,
          totalTime,
          assigned_test_user: testU,
          question_count: testAnswers.length,
        };
        testAnswers.forEach((element) => {
          let filtered = testU.assigned_test_answers.filter((e) => e.code === element.code);
          tempUser[element.code] = filtered.length ? filtered[0].is_correct : null;
          tempUser[`${element.code}_sure`] = filtered.length ? filtered[0].sure : 100;
          tempUser[`${element.code}_data`] = filtered.length ? filtered[0] : null;
          lastRow[`${element.code}_total`] = lastRow[`${element.code}_total`] ? lastRow[`${element.code}_total`] : 0;
          lastRow[`${element.code}_total`] += tempUser[element.code] ? 1 : 0;
          lastRow[element.code] = ((lastRow[`${element.code}_total`] / assignedTestUsers.length) * 100).toFixed(0);
        });

        if (testU.assigned_test_answer_retakes.length) {
          let retakesList = JSON.parse(JSON.stringify(testU.assigned_test_answer_retakes));
          const maxRetakesTaken = retakesList.reduce((a, b) => (a.retries > b.retries ? a : b), {});
          tempUser['children'] = maxRetakesTaken?.retries
            ? new Array(maxRetakesTaken?.retries).fill(0).reduce((a, b, i) => {
                let retakesAnswers = {};
                let retakeScore = 0;
                retakesList
                  .filter((f) => f.retries === i + 1)
                  .forEach((retake) => {
                    retakesAnswers[retake.code] = retake.is_correct;
                    retakesAnswers[`${retake.code}_sure`] = retake.sure || 100;
                    retakesAnswers[`${retake.code}_data`] = retake || null;
                    if (retake.is_correct) {
                      retakeScore += 1;
                    }
                  });
                return [
                  ...a,
                  {
                    ...retakesAnswers,
                    score: retakeScore,
                    assigned_test_user: testU,
                    question_count:Object.keys(retakesAnswers).length/3,
                    retake: true,
                    idx: `retake-${tempUser.idx}-${i}`,
                    key: `retake-${tempUser.idx}-${i}`,
                  },
                ];
              }, [])
            : [];

        }
        lastRow['score_total'] += testU.score;
        lastRow['score'] = ((lastRow['score_total'] / (assignedTestUsers.length ))).toFixed(0);
        lastRow['totalTime_total'] += totalTime;
        lastRow['totalTime'] = (lastRow['totalTime_total'] / assignedTestUsers.length).toFixed(2);
        lastRow['question_count'] = testAnswers.length;
        return tempUser;
      });

      listUsers.push(lastRow);

      setTestUsers(listUsers);

      setColumns(columsData);
    }
  }, [data]);
  return (
    <>
      {assigned_test ? (
        <Col className="test-result-wrapper" span={24}>
          <MainBlock className="testdetails-mainblock">
            <Row gutter={[24, 16]}>
              <Col className="result-title-wrap" span={16} style={{ padding: 0 }}>
                <Row gutter={[24, 16]} style={{ margin: 0 }}>
                  <Col className="result-title-a" span={12} style={{ padding: 0, paddingLeft: 10 }}>
                    <Title level={5}>
                      <span style={{ color: '#065aa9', marginLeft: 10 }}>Test Title:</span>
                      {assigned_test.title}
                    </Title>
                  </Col>
                  <Col className="result-title-b" span={12} style={{ padding: 2 }}>
                    <Title level={5}>
                      <span style={{ color: '#065aa9', marginLeft: 10 }}>날짜 :</span>
                      {moment(assigned_test.start_time).format('YYYY-MM-DD')}
                    </Title>
                  </Col>
                </Row>
                <Row className="result-test-title fordesktoponly-result" style={{ margin: 0, marginTop: 15 }}>
                  <Title level={5}>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Test :</span>
                    {assigned_test.tests.code}
                  </Title>
                </Row>
              </Col>
              <Col className="result-title-wrap formobileonly-result" span={16} style={{ padding: 0 }}>
                <Row gutter={[24, 16]} style={{ margin: 0 }}>
                  <Col className="result-title-a-mb" span={8} style={{ padding: 0, paddingLeft: 10 }}>
                    <Title level={5}>
                      <span style={{ color: '#065aa9', marginLeft: 10 }}>출제자 :</span> {assigned_test.user.name}
                    </Title>
                  </Col>
                  <Col className="result-title-b-mb" span={8} style={{ padding: 2 }}>
                    <Link to={`/test/new/${assigned_test.tests.idx}/preview`}>
                      <Button style={{ color: '#065aa9' }}>시험지 보기</Button>
                    </Link>
                  </Col>
                  <Col className="result-title-c-mb" span={8} style={{ padding: 2 }}>
                    <Title level={5}>
                      <span style={{ color: '#065aa9', marginLeft: 10 }}>Test :</span>
                      {assigned_test.tests.code}
                    </Title>
                  </Col>
                </Row>
              </Col>
              <Col className="resultbtntop-wrap" span={8} style={{ padding: 2 }}>
                <Row gutter={[24, 16]} style={{ margin: 0 }} className="formobile-hide">
                  <Col offset={2} span={10}>
                    <Title level={5}>
                      <span style={{ color: '#065aa9', marginLeft: 10 }}>출제자 :</span> {assigned_test.user.name}
                    </Title>
                  </Col>
                  <Col offset={2} span={10}>
                    <Link to={`/test/new/${assigned_test.tests.idx}/preview`}>
                      <Button style={{ color: '#065aa9' }}>시험지 보기</Button>
                    </Link>
                  </Col>
                </Row>
                <Row className="resultbtntop" gutter={[24, 16]} style={{ margin: 0 }}>
                  <Col className="resultbtntop-a" span={10} offset={2} style={{ padding: 2 }}>
                    <Button shape="round" size="default" icon={<LeftOutlined />} onClick={() => props.history.goBack()}>
                      Back
                    </Button>
                  </Col>
                  <Col span={12} style={{ padding: 2 }}>
                    {/* 항목이  너무 많아서 인쇄시에 짤린다. */}
                    {/* <Button type="primary" shape="round" icon={<PrinterTwoTone />}>
                      Print
                    </Button>
                    &nbsp; */}
                    <Button type="primary" shape="round" onClick={handleDownload} icon={<DownloadOutlined />} size="default">
                      Download
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <CustomTable
              className="testresult-table"
              dataSource={testUsers}
              key={(r) => r.idx}
              columns={columns}
              bordered
              size="small"
              color="#edf3fb"
              pagination={{ hideOnSinglePage: true }}
              scroll={{ y: 'calc(100vh - 400px)' }}
            />
            <Row gutter={[24, 16]}>
              <Col>
                <PlusOutlined /> : 2차 시험 점수 확인
              </Col>
              <Col> O/X : 100% 확실</Col>
              <Col>
                <span style={{ color: '#0070C0' }}>O/X</span> : 확실하지 않음
              </Col>
              <Col>
                <span style={{ color: 'hotpink' }}>X</span> : 입력한 답 없음
              </Col>
              <Col>
                <span style={{ color: '#f36513' }}>●</span> : 평균 점수 60% 이하
              </Col>
            </Row>
          </MainBlock>
        </Col>
      ) : null}
    </>
  );
};

export default withRouter(Details);
