import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';
import { useQuery} from '@apollo/client';
import { GET_USER_INFO } from 'src/operations/queries/campus';

// const { Option } = Select;

const menuText = <span>Click here to start</span>;

const InnerWrap = styled.div`
  background-color: #fff;
  width: calc(100% - 0rem);
  height: calc(100% - 10rem);
  padding: 0rem 1rem;
  margin: 0rem auto;
  border-radius: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 18rem);
    height: calc(100% - 20rem);
    padding: 2rem 1rem;
    margin: 0rem auto;

    & {
      .fixed_btm_btn {
        bottom: 10rem;
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    height: 90%;
    width: 100%;
    max-width: 2000px;
  }

  @media (max-width: 1920px) and (aspect-ratio: 16/9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 500px;
    margin: 0rem auto;

    & {
      .meta-header {
        margin: 0 auto;
      }
    }
    .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
      top: 55px;
      transform: scale(2);
    }
    .btn {
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16/9) {
    height: 70%;
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    .btn.confirm_btn {
      margin: 0.5rem auto 0;
      align-items: center;
      max-height: 45px;
    }

    .time-wrap {
      padding-left: 2rem;
    }

    .question-wrap {
      padding: 0.5rem;
    }

    & {
      h1.main-tit {
        font-size: 1.8rem;
      }
    }
  }

  @media (max-width: 1366px) {
  }

  @media (max-width: 1200px) and (aspect-ratio: 16/9) {
    height: 72%;
    width: 100%;
    max-width: 1000px;
    max-height: 500px;
    position: absolute;
    left: 50%;
    top: 53.5%;
  }

  @media (max-width: 1200px) and (max-height: 800px) {
    height: 70%;
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    & {
      .finished-con.type-reading-con {
        width: 100%;
      }
    }
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    background-color: #fff;
    width: calc(100% - 0rem);
    height: 76vh;
    padding: 2rem 1rem;
    margin: 3rem auto;

    .sample-q-con .meta-header.test-header {
      padding: 0;
    }

    .meta-header.test-header .question-wrap h2 {
      width: 80px;
    }

    .ant-tooltip-placement-left {
      .ant-tooltip-arrow {
        width: 2rem;
        height: 2rem;
        right: 0;
        left: -0.5rem;
      }

      .ant-tooltip-arrow-content {
        transform: translateX(-6.53553391px) rotate(45deg) scale(2);
      }
    }

    .ant-tooltip-placement-right {
      left: 490px !important;
      .ant-tooltip-arrow {
        width: 2rem;
        height: 2rem;
        right: 0;
        left: -0rem;
      }

      .ant-tooltip-arrow-content {
        transform: translateX(-6.53553391px) rotate(45deg) scale(2);
      }
    }
  }

  @media (max-width: 1280px) {
    margin: 3rem auto;
  }

  @media (max-width: 1024px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  }

  @media (max-width: 1024px) {
    margin: 1rem auto;
  }
`;

const MetaSampleQuestion = () => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx===1016 ? true : false;
  return (
    <>
      <div className={isWEnglish ? 'meta-con meta-full-con w-english-bg' : 'meta-con meta-full-con'}>
        <div className="meta-header">
          <h1 className="main-tit">Sample Question</h1>
        </div>

        <div className="sample-q-con">
          <div className="meta-header test-header">
            {/*<div className="metaexam-arrow">
              <h3>menu explaination</h3>
              <span className="metaexam-arrow-square" />
            </div> */}
            <div className="burger-wrap">
              <img src="../images/meta_hamburger.png" alt="burger_meta" />
              <h2 style={{ marginBottom: '0' }}>Question List</h2>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <div>
                <div className="time-wrap">
                  <h2>Time: </h2>
                  <h2 className="num">10:00</h2>
                </div>
              </div>
              <div style={{ marginLeft: '5px' }}>
                <div className="question-wrap">
                  <h2>Question: </h2>
                  <h2 className="num">10/30</h2>
                </div>
              </div>
            </div>
          </div>
          <InnerWrap>
            {/*
            <div className="metaexam-arrow context-explain">
              <h3>lolem ipsum</h3>
              <span className="metaexam-arrow-square" />
  </div>*/}
            <div className="meta-header readingTest-header">
              <h1 className="main-tit" style={{ color: '#' }}>
                Reading Passage
              </h1>
            </div>
            <div className="readingtest-con">
              <div className="readingtest-wrap passage-con">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eget augue quis quam volutpat varius ut non tellus. Aliquam
                  tortor nulla, tempus sit amet cursus ac, maximus id ante. Morbi diam sapien, scelerisque vitae laoreet sed, mollis eget
                  ligula. Maecenas finibus ante erat, et pharetra augue blandit in. Maecenas ullamcorper elementum ante, sed dictum nisl
                  sollicitudin et. Vivamus ullamcorper hendrerit tortor, sit amet ornare mauris condimentum id. Vivamus nisi purus, sagittis
                  consequat sapien at, consequat scelerisque sapien. Proin fermentum mi at ligula fermentum commodo. Integer suscipit nunc
                  felis, sit amet suscipit lectus sodales vel. Fusce finibus mi non sapien efficitur posuere. Aenean gravida nec purus eget
                  euismod. Aliquam erat volutpat. Suspendisse dignissim a lectus sed pharetra. Praesent pulvinar at augue in vehicula. Ut
                  consectetur nisi vel nisl vehicula ultricies. Vestibulum elementum et sapien in egestas. Pellentesque tempor velit sed
                  aliquet vehicula. Mauris blandit nisi non consectetur tempor. Cras in venenatis augue. Morbi id diam arcu. Nunc dictum
                  metus ut mauris accumsan maximus. Morbi ultrices enim sit amet massa rhoncus venenatis.
                </p>
              </div>
              <div className="readingtest-wrap">
                {/* <div className="metaexam-arrow question-box-explain">
                  <h3>question box explaination</h3>
                  <span className="metaexam-arrow-square" />
                </div>
                <div className="metaexam-arrow answer-explain">
                  <h3>answer sheet explaination</h3>
                  <span className="metaexam-arrow-square" />
</div>*/}
                <div className="reading-q-con">
                  <h1>10. Find the correct answer in the Passage.</h1>
                </div>

                <div className="reading-a-con">
                  <div className="reading-a-wrap">
                    <div className="reading-a-num">
                      <h3>a</h3>
                    </div>
                    <div className="reading-a">
                      <h3>apple</h3>
                    </div>
                  </div>
                  <div className="reading-a-wrap">
                    <div className="reading-a-num">
                      <h3>b</h3>
                    </div>
                    <div className="reading-a">
                      <h3>peach</h3>
                    </div>
                  </div>
                  <div className="reading-a-wrap">
                    <div className="reading-a-num">
                      <h3>c</h3>
                      <img src="../images/check.png" alt="img_meta" className="checkedImg" />
                    </div>
                    <div className="reading-a">
                      <h3>banana</h3>
                    </div>
                  </div>
                  <div className="reading-a-wrap">
                    <div className="reading-a-num">
                      <h3>d</h3>
                    </div>
                    <div className="reading-a">
                      <h3>orange</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* bottom btn */}
            <div className="process-btn-con">
              <span>
                <img src="../images/back_meta.png" alt="back_meta" />
              </span>

              <span style={{ marginLeft: 'auto' }}>
                <img src="../images/next_meta.png" alt="next_meta" />
              </span>
            </div>
          </InnerWrap>
        </div>
        <Tooltip
          getPopupContainer={(trigger) => trigger.parentNode}
          defaultVisible="true"
          visible="true"
          trigger="focus"
          placement="topRight"
          title={menuText}
        >
          <Button className="btn confirm_btn start_btn" style={{ background: '#fff', display: 'flex' }}>
            <Link to="./ReadingTest">Start</Link>
          </Button>
        </Tooltip>
        {!isWEnglish && (
        <div className="report-footer-con">
          <h5>
            Powered by {'     '}
            <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
              Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
            </span>
          </h5>
          <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
        </div>
        )}
      </div>
    </>
  );
};

export default MetaSampleQuestion;
