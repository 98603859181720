import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Typography, Space, Checkbox, Select, Row, Button, List, DatePicker, Input, Upload,Tooltip } from 'antd';
import { StarOutlined, VideoCameraOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import FileManagerModal from 'src/components/Modal/FileManagerModal';
import VimeoVideo from 'src/components/common/VimeoVideo';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import moment from 'moment';

const { RangePicker } = DatePicker;

const { Title } = Typography;
const { Search } = Input;

const MainBlock = styled.div`
  background: #fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
`;

const ListBlock = styled.div`
  padding: 10px;
  background: #fff;
  overflow-y: scroll;
`;

const ShadowBlock = styled.div`
  box-shadow: rgb(221, 221, 221) 2px 1px 5px 5px;
  border-radius: 10px;
`;

const PageBlock = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
`;

const HeadBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  background: #edf3fbc4;
`;

const MyListVideo = ({ item, i, checkedList, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <List.Item
      key={item.idx}
      style={{ padding: 10 }}
      extra={
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <div>
            <Space style={{ color: 'orangered', marginRight: 10 }}>Youtube</Space>
            <Checkbox style={{ textAlign: 'left' }}></Checkbox>
          </div>
          <Button type="text" size="large" icon={<StarOutlined />} />
        </div>
      }
    >
      <List.Item.Meta
        style={{ marginBottom: 5 }}
        avatar={
          <>
            <Checkbox style={{ marginRight: 10 }} checked={checkedList[i]} onChange={(e) => onChange(e, i)}></Checkbox>
            <VimeoVideo item={item} width={120} open={isOpen} handleCloseClick={() => setIsOpen(false)} />
          </>
        }
        title={
          <>
            <a href={item.href} onClick={() => setIsOpen(!isOpen)}>
              {item.title === '' ? 'Untitled' : item.title}
            </a>
          </>
        }
        description={
          <>
            <div>{item.description}</div>
            <div>Uploaded By: {item.user.name}</div>
            <div>Date: {moment(item.idate.replace('.000Z', '')).format('lll')}</div>
          </>
        }
      />
    </List.Item>
  );
};

const OtherListVideo = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <List.Item
      key={item.idx}
      style={{ padding: 10 }}
      extra={
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Button type="text" size="large" icon={<StarOutlined />} />
        </div>
      }
    >
      <List.Item.Meta
        style={{ marginBottom: 5 }}
        avatar={
          <VimeoVideo item={item} width={120} open={isOpen} handleCloseClick={() => setIsOpen(false)} style={{ cursor: 'pointer' }} />
        }
        title={
          <>
            <a href={item.href} onClick={() => setIsOpen(!isOpen)}>
              {item.title === '' ? 'Untitled' : item.title}
            </a>
          </>
        }
        description={
          <>
            <div>{item.description}</div>
            <div>Uploaded By: {item.user.name}</div>
            <div>Date: {moment(item.idate.replace('.000Z', '')).format('lll')}</div>
          </>
        }
      />
    </List.Item>
  );
};

const MainPage = (props) => {
  const [checkedList, setCheckedList] = useState(new Array(props.myList ? props.myList.length : 0).fill(false));
  const [selectAll, setSelectAll] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const history = useHistory();
  const companyName = useSelector(classStoreData);

  useEffect(() => {
    if (videoFile && videoFile.length) {
      console.log(videoFile);
      props.createVideo({
        variables: {
          title: videoFile[0]?.videos.title,
          video_id: videoFile[0]?.videos.video_id,
          data: videoFile[0]?.videos.data,
          is_shared: false,
          class_idx: props.classIdx,
          is_public: props.isPublic,
          type: 1,
        },
      });
    }
  }, [videoFile]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(props.myList.length).fill(true));
      setSelectAll(true);
    } else {
      setSelectAll(false);
      setCheckedList(new Array(props.myList.length).fill(false));
    }
  }

  const ListHeader = ({ text, isShowAll }) => (
    <HeadBlock>
      <Title level={5}>{text}</Title>
      {isShowAll ? (
        <Checkbox checked={selectAll} onChange={onCheckAllChange}>
          Select All
        </Checkbox>
      ) : null}
    </HeadBlock>
  );

  const onHandleRecordingClick = (data = '') => {
    if (window.Android) {
      window.Android.startRecordingActivity(data);
    } else {
      history.push('/recording/start');
    }
  };

  return (
    <>
      <Col span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="vtube-mainblock">
          <Row gutter={[24, 8]}>
            <Col className="vtube-title-sec" span={10} xs={6} md={10} style={{ textAlign: 'left' }}>
              <Title level={4}>V-Tube</Title>
            </Col>
            <Col
              className="fieldsection-vtube"
              span={14}
              xs={18}
              md={14}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              {companyName !== "smart-eclass"?
              <Tooltip title="영상이 저장된 위치를 선택하세요.">
               <Select value={props.selectedItem.idx} style={{ width: 200, marginRight: 20 }} onChange={props.onSelectedValueChange}
               >
               {props.optionsList.map((item) => (
                 <Select.Option key={item.idx} value={item.idx}>
                   {item.name}
                 </Select.Option>
               ))}
             </Select>
             </Tooltip>
              :
              <Select value={props.selectedItem.idx} style={{ width: 200, marginRight: 20 }} onChange={props.onSelectedValueChange}>
                {props.optionsList.map((item) => (
                  <Select.Option key={item.idx} value={item.idx}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>}
              {companyName !== "smart-eclass"?
                <Tooltip title="검색할 내용을 입력하세요.">
               <Search
               onChange={(val) => props.setQuery(val.target.value)}
               placeholder="Input search text"
               style={{ width: 250, paddingTop: 0, paddingBottom: 0, height: 32, marginRight: 20 }}
             />
             </Tooltip>
              :
              <Search
                onChange={(val) => props.setQuery(val.target.value)}
                placeholder="Input search text"
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, height: 32, marginRight: 20 }}
              />
              }
              {companyName !== "smart-eclass"?
               <Tooltip title="검색할 기간을 입력하세요."><RangePicker style={{ paddingTop: 0, paddingBottom: 0, height: 32 }} onChange={(v) => props.setDateRange(v)} /></Tooltip>:
              <RangePicker style={{ paddingTop: 0, paddingBottom: 0, height: 32 }} onChange={(v) => props.setDateRange(v)} />}
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col span={12} style={{ textAlign: 'left' }} className="vtube-mainblock-sec-a">
              <ShadowBlock className="shadowblock-a">
                <ListHeader text={props.user.type === 0 ? 'Student Video' : 'Teacher Video'} isShowAll={true} />
                <ListBlock className="listblock-shadowblock-a">
                  <List
                    itemLayout="vertical"
                    size="large"
                    loading={props.list1Loading}
                    loadMore={
                      props.myList.length > 0 && props.myList.length % 10 === 0 ? (
                        <div
                          style={{
                            textAlign: 'center',
                            marginTop: 12,
                            height: 32,
                            lineHeight: '32px',
                          }}
                        >
                          <Button onClick={() => props.setPage((prev) => prev + 1)}>loading more</Button>
                        </div>
                      ) : null
                    }
                    pagination={false}
                    dataSource={props.myList}
                    renderItem={(item, i) => <MyListVideo item={item} i={i} onChange={onChange} checkedList={checkedList} />}
                  />
                </ListBlock>
                <PageBlock>
                  <div>
                    <Button
                      type="primary"
                      size="large"
                      style={{ background: 'orange' }}
                      onClick={async () => {
                        await props.deleteVideos({
                          variables: {
                            idxs: checkedList.map((item, i) => (item ? props.myList[i].idx : false)).filter((item) => item),
                          },
                        });
                        onCheckAllChange({ target: { checked: false } });
                      }}
                      shape="circle"
                      icon={companyName !== "smart-eclass"? <Tooltip title="버튼을 클릭하면 선택한 영상이 삭제됩니다."><DeleteOutlined /></Tooltip>:<DeleteOutlined />}
                    />
                    {props.user.type === 0 ? (
                      <Upload
                        accept="video/*"
                        name="file"
                        multiple={true}
                        action={props.onUploadViedos}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        <Button
                          type="primary"
                          size="large"
                          style={{ background: '#6666e2', marginLeft: 10 }}
                          shape="circle"
                          icon={<UploadOutlined />}
                        />
                      </Upload>
                    ) : (
                      <FileManagerModal
                        onSelectedFiles={(video) => setVideoFile(video)}
                        isNotRenderFileList={true}
                        multiselect={false}
                        type="primary"
                        size="large"
                        selectedSpan={24}
                        fileViewType="text"
                        shape="circle"
                        buttonStyle={{ background: '#6666e2', marginLeft: 10 }}
                        fileTextStyle={{ width: '50px' }}
                        selectable={true}
                        filterMimeType="video/"
                        title=""
                        modalTitle="Select Video"
                        companyName={companyName}
                      />
                    )}
                  </div>
                  <Button
                    type="primary"
                    size="large"
                    style={{ background: 'lightseagreen' }}
                    onClick={() => onHandleRecordingClick(JSON.stringify({ options: props.optionsList }))}
                    shape="circle"
                    icon={companyName !== "smart-eclass"?<Tooltip title="버튼을 클릭하여 영상을 녹화하세요."><VideoCameraOutlined /></Tooltip>:<VideoCameraOutlined />}
                  />
                </PageBlock>
              </ShadowBlock>
            </Col>
            <Col span={12} style={{ textAlign: 'left' }} className="vtube-mainblock-sec-b">
              <ShadowBlock className="shadowblock-b">
                <ListHeader text={props.selectedItem.name} isShowAll={false} />
                <ListBlock className="listblock-shadowblock-b">
                  <List
                    pagination={false}
                    loading={props.list2Loading}
                    loadMore={
                      props.otherList.length > 0 && props.otherList.length % 10 === 0 ? (
                        <div
                          style={{
                            textAlign: 'center',
                            marginTop: 12,
                            height: 32,
                            lineHeight: '32px',
                          }}
                        >
                          <Button onClick={() => props.setPage2((prev) => prev + 1)}>loading more</Button>
                        </div>
                      ) : null
                    }
                    itemLayout="vertical"
                    size="large"
                    dataSource={props.otherList}
                    renderItem={(item) => <OtherListVideo item={item} />}
                  />
                </ListBlock>
              </ShadowBlock>
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default MainPage;
