import React from 'react';
import { Col, Row, Tabs } from 'antd';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ReportCardAdminListTab from './ReportCardAdminListTab';
import ReportCardListView from './ReportCardListView';

const { TabPane } = Tabs;

const ReportCardAdmin = () => {
  return (
    <>
      <HeaderTitle level={4}>Report Card</HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <Tabs defaultActiveKey={0} type="card" size="large" onChange={() => {}} style={{ width: '100%' }}>
              <TabPane tab="성적표 관리" key={0}>
                <ReportCardAdminListTab />
              </TabPane>
              <TabPane tab="개인별 성적표 보기" key={1}>
                <ReportCardListView />
              </TabPane>
            </Tabs>
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ReportCardAdmin;
