import React, { useLayoutEffect } from 'react';
import { Col, Row, Spin, Tabs } from 'antd';
import styled from 'styled-components';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import BackButton from 'src/components/common/BackButton';
import CustomTable from 'src/components/common/CustomTable';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { userInfoVar } from 'src/apollo/cache';
import scrollIntoView from 'scroll-into-view';
import moment from 'moment';

const { TabPane } = Tabs;

const HomeWorkTable = styled(CustomTable)`
  border-top: 3px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
  }
`;
const HomeWorkHeaderTable = styled(CustomTable)`
  border-top: 3px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
  }
`;

const StudyResultListPresenter = ({
  title,
  data,
  columns,
  loading,
  classcolumns,
  classdata,
  bookCodeList,
  currentActiveBookCode,
  onClickTab,
}) => {
  const companyName = useSelector(classStoreData);

  useLayoutEffect(() => {
    setTimeout(() => {
      try {
        const target = document.querySelector(`.today.row-${currentActiveBookCode}`);

        scrollIntoView(target, {
          time: 500,
          align: {
            top: 0,
            topOffset: 10,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }, [currentActiveBookCode]);

  return (
    <>
      <Row justify="space-between">
        <Col span={12}>
          <HeaderTitle level={4}>{title}</HeaderTitle>
        </Col>
        <Col style={{ paddingTop: 5 }}>
          <Col span={12} style={{ paddingTop: 5, textAlign: 'right' }}>
            <BackButton />
          </Col>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="class-result-halfwrap">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            ></div>
            {userInfoVar().type !== 0 && (
              <HomeWorkHeaderTable
                className="homeworkheadertable"
                dataSource={classdata}
                columns={classcolumns}
                pagination={false}
                size="small"
                color="#edf3fb"
              />
            )}

            {loading ? (
              <div style={{ textAlign: 'center', height: '100%' }}>
                <Spin />
              </div>
            ) : (
              <Tabs
                tabPosition="left"
                style={{
                  height: 'calc(100vh - 254px)',
                }}
                defaultActiveKey={currentActiveBookCode}
                onChange={(key) => onClickTab(parseInt(key))}
              >
                {bookCodeList.map((item, key) => (
                  <TabPane tab={item?.book_sub[0]?.title || item.code} key={key}>
                    <HomeWorkTable
                      rowClassName={(record, index) => {
                        return moment(record?.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
                          ? `row-${key} today`
                          : `row-${key} test`;
                      }}
                      dataSource={data}
                      columns={columns}
                      pagination={false}
                      size="small"
                      color="#edf3fb"
                      scroll={{ y: 'calc(100vh - 254px)' }}
                      loading={loading}
                    />
                  </TabPane>
                ))}
              </Tabs>
            )}
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default StudyResultListPresenter;
