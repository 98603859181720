import { gql } from '@apollo/client';

export const DELIVERY_COMPANY_LIST = gql`
  query deliveryCompanyListFromDB {
    deliveryCompanyListFromDB {
      company_code
      company_name
      phone_number
    }
  }
`;
export const DELIVERY_TRACKING_INFO = gql`
  query deliveryTrackingInfo($t_code: String!, $t_invoice: String!) {
    deliveryTrackingInfo(t_code: $t_code, t_invoice: $t_invoice) #json object 형태로 반환
  }
`;
