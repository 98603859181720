import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Form, Select, Checkbox, Space, Table, DatePicker,Button } from 'antd';
import { useQuery } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import * as queries from 'src/operations/queries';
import queryString from 'query-string';
import moment from 'moment';
import { userInfoVar } from 'src/apollo/cache';
import CustomTable from 'src/components/common/CustomTable';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 20px 13px;
  padding: 20px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Title = styled.div`
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
`;
const AssignButton = styled(RowFlex)`
  border: 1px soild #48608a;
  border-radius: 5px;
  padding: 5px 25px;
  color: #eff5fb;
  background-color: #1890ff;
  height: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const TableWrapper = styled(ColumnFlex)`
  margin: 10px 20px;
  border: 2px solid #2294f8;
`;
const TableHead = styled(RowFlex)`
  background-color: #363ad9;
  color: #fff;
  width: 100%;
  justify-content: space-between;
  font-size: 18px;
  padding: 8px 10px;
`;
const RedRow = styled.tr`
  background-color: #ffe9e5 !important;
`;
const YellowRow = styled.tr`
  background-color: #fff4c7 !important;
`;
const SKILL_LIST = [
  'Cause and Effect',
  'Character, Setting, and Plot',
  'Classify/Categorize',
  'Compare and Contract',
  'Convey Information',
  'Interpret Graphics or Text Features',
  'Main Idea and Details',
  'Make Inferences',
  'Recall',
  'Sequence Events',
  'Vocabulary',
  'Writing Conventions',
];
const { RangePicker } = DatePicker;
// export default function Skills({ user }) {
 export default function Skills() {
  const history = useHistory();
  const { class_idx,user_idx } = queryString.parse(window.location.search);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedUser, setSelectedUser] = useState(-1);
  const [checkedList, setCheckedList] = useState(new Array(SKILL_LIST.length).fill(false));
  const [skillCheckedList, setSkillCheckedList] = useState();
  const [selectedSkills, setSelectedSkills] = useState([])
  const [ skilled_books, setSkilled_books] = useState([])
  const [bookIdxArr, setBookIdxArr] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // const currentDate = moment();
    // const firstDay = currentDate.clone().startOf('month');
    // const lastDay = currentDate.clone().endOf('month');
    // // const startDateFormatted = firstDay.format('YYYY-MM-DD');
    // const endDateFormatted = lastDay.format('YYYY-MM-DD');

    const curDate = new Date();
    let year = curDate.getFullYear();
    const curmonth = (curDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 and ensure two digits
    const curday = curDate.getDate().toString().padStart(2, '0');
    let month = curDate.getMonth() + 1;
    let day = curDate.getDate();

    // month -= 3;

    // if (month < 1) {
    //   year -= 1;
    //   month += 12;
    // }

    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    setStartDate(`${year-1}-${curmonth}-${curday}`);
    setEndDate(`${year}-${curmonth}-${curday}`);
  }, []);
  const skillList = useQuery(queries.getClass.GET_SKILL, {
    variables: {
      skip: !endDate,
      start_date: startDate,
      end_date: endDate,
      user_idx:parseInt(user_idx)
    },
  });

  useEffect(() => {
    if (skillList?.data?.getSkill) {
      const skill = JSON.parse(skillList?.data?.getSkill);
      setSkillCheckedList(new Array(skill.length).fill(false));
    }
  }, [skillList]);

  const getRowClassName = (text) => {
    if (text?.rate) {
      const { rate } = text;
      return parseInt(rate) >= 80 ? '' : parseInt(rate) >= 70 ? 'yellow-row' : 'red-row';
    }
    return '';
  };

  const dataSrc = useMemo(() => {
    if (skillList?.data?.getSkill) {
      const parseSkill = JSON.parse(skillList?.data?.getSkill);
      const res = parseSkill?.map((ele, i) => {
        console.log("ele",ele?.skills == null);
        const obj = {
          index: i,
          skill: ele?.skills == null ? "NO SKILL":ele.skills,
          completed: ele?.complete + '/' + ele?.totalAttmp,
          rate: (ele?.complete / ele?.totalAttmp) * 100,
          book_idx: ele.book_id,
          book_ids:ele?.book_ids
        };
        return obj;
      });
      return res;
    }
  }, [skillList]);
  const columns = [
    {
      title: 'SKILL',
      dataIndex: 'skill',
      align: 'center',
      key: 'skill',
      sorter: (a, b) => a.skill.localeCompare(b.skill),
      render: (text) => {
        return <span style={{ color: '#17aafb' }}>{text}</span>;
      },
    },
    {
      title: 'CORRECT/TOTAL',
      dataIndex: 'completed',
      align: 'center',
      key: 'completed',
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'ANSWER RATE',
      dataIndex: 'rate',
      align: 'center',
      key: 'rate',
      sorter: (a, b) => a.rate.localeCompare(b.rate),
      render: (text) => {
        const color = parseInt(text) >= 80 ? '#217570' : parseInt(text) >= 70 ? '#ef861c' : '#cf2918';
        return <span style={{ color: color }}>{text ? Math.round(text) : 0}%</span>;
      },
    },
  ];

  function onRangeChange(dates, dateStrings) {
    console.log("dateStrings",dateStrings);
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  }
  return (
    <Wrapper>
      <MainWrapper>
        <Main>
          <RowFlex>
            <Title>Literacy Report</Title>
            <RowFlex style={{ marginRight: '20px' }}>
              &nbsp;&nbsp;
              <Button
                type="secondary"
                shape="round"
                size="large"
                style={{ marginRight: 10 }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </Button>

            </RowFlex>
          </RowFlex>
          <TableWrapper>
            <TableHead>
              <span>READING COMPREHENSION: SKILLS</span>
              {startDate && endDate ? (
                <RangePicker
                  style={{ marginBottom: 10 }}
                  onChange={onRangeChange}
                  name="rangeDate"
                  defaultValue={[moment(startDate), moment(endDate)]} // Remove the single quotes
                />
              ) : (
                ''
              )}
            </TableHead>
            <Table
              dataSource={dataSrc}
              columns={columns}
              scroll={{ y: 'calc(100vh - 340px)' }}
              // scroll={{ y: 'calc(100vh - 224px)' }}
              pagination={false}
              size="small"
              rowClassName={getRowClassName}
              components={{
                body: {
                  row: (props) => {
                    const { className, ...restProps } = props;

                    if (getRowClassName(restProps.record) === 'yellow-row') {
                      return <YellowRow className={className} {...restProps} />;
                    } else if (getRowClassName(restProps.record) === 'red-row') {
                      return <RedRow className={className} {...restProps} />;
                    } else {
                      return <tr className={className} {...restProps} />;
                    }
                  },
                },
              }}
            />
          </TableWrapper>
        </Main>
      </MainWrapper>
    </Wrapper>
  );
}
