import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { checkIfCampusUser } from 'src/utils';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const ClassAndMemberMenuGroup = ({ currentMenu }) => {
  const userType = userInfoVar()?.type;
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
      <MenuButton 
        type={`${currentMenu === 'class' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/classroom');
        }}
      >
        반관리
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'student' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/students');
        }}
      >
        학생관리
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'students-batch' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/students-batch');
        }}
      >
        학생일괄등록
      </MenuButton>
      {checkIfCampusUser(userType) && (
        <>
          &nbsp;
          <MenuButton
            type={`${currentMenu === 'teacher' ? 'primary' : 'default'} `}
            onClick={() => {
              history.push('/teachers');
            }}
          >
            강사관리
          </MenuButton>
        </>
      )}
    </div>
  );
};

export default ClassAndMemberMenuGroup;
