export const CDN_BASE_URL = 'https://cdn.cloubot.com';
export const CLASS_STATUS_TYPE_ALL = 0;
export const CLASS_STATUS_TYPE_IN_PRGRESS = 1;
export const CLASS_STATUS_TYPE_FINISH = 2;

export const MAX_SEARCH_INTERVAL_DAYS = 93;
export const DATE_SEARCH_OPTIONS = [
  {
    name: '월선택',
    value: 0,
  },
  {
    name: '기간선택',
    value: 1,
  },
];

export const DATE_SEARCH_OPTIONS_FOR_AFTER_SCHOOL = [
  {
    name: '전체',
    value: -1,
  },
  {
    name: '월선택',
    value: 0,
  },
  {
    name: '기간선택',
    value: 1,
  },
];

export const BOOK_RETURN_STATS = ['반품접수', '반품완료'];

export const DELIVERY_STATUS_REG = '0'; //주문
export const DELIVERY_STATUS_READY = '1'; //주문 확인(출고준비)
export const DELIVERY_STATUS_COMPLETE = '2'; //송장번호입력(출고완료)

export const DELIVERY_STATUS_STATUS_COLOR_LIST = [
  '#4dabf7', //접수(주문서)
  '#ff922b', //주문서확인(출고준비)
  '#e03131', //송장입력(출고완료)
];

export const DELIVERY_STATUS_STATUS_TEXT_LIST = [
  '주문서', //접수(주문서)
  '출고준비', //주문서확인(출고준비)
  '출고완료', //송장입력(출고완료)
];

export const DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_DASHBOARD = [
  '주문접수', //접수(주문서)
  '출고준비', //주문서확인(출고준비)
  '출고완료', //송장입력(출고완료)
];

export const DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_CAMPUS = [
  '주문서', //접수(주문서)
  '접수', //주문서확인(출고준비)
  '출고', //송장입력(출고완료)
];

//진행단계 [level 1: 배송준비중, 2: 집화완료, 3: 배송중, 4: 지점 도착, 5: 배송출발, 6:배송 완료]
export const DELEVERY_LEVEL_TEXT_LIST = [
  '', //level 1부터 있어서, 0은 무시..
  '배송준비중',
  '집화완료',
  '배송중',
  '지점 도착',
  '배송출발',
  '배송 완료',
];

//SMS 관련 상수
export const MSG_TYPE_SMS = '0';
export const MSG_TYPE_LMS = '1';
export const MSG_TYPE_MMS = '2';
export const MSG_TYPE_TALK = '3';
export const MSG_FEE_LIST = {
  0: 12,
  1: 35,
  2: 35,
  3: 9,
};
export const MSG_TYPE_TEXT_LIST = {
  0: '단문',
  1: '장문',
  2: 'MMS',
  3: '알림톡',
};
export const MAX_SMS_BYTES = 90;

export const BOOK_REPORT_DEFAULT_FILL = `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

If there are no errors then just write "There are no errors in the writing." and do not include the corrections.

Now you proofread and edit below passage:

((topic))`;
export const BOOK_REPORT_REVISE_FILL =
  'Can you rewrite this writing with very easy words without showing corrections and explanations in korean  with the difficulty level of ((level)) "((topic))"?';

export const BOOK_REPORT_DEFAULT_FILL_LIST = {
  'Grade 1': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

If there are no errors then just write "There are no errors in the writing." and do not include the corrections.

Now you proofread and edit below passage:

((topic))`,
  'Grade 4': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

If there are no errors then just write "There are no errors in the writing." and do not include the corrections.

Now you proofread and edit below passage:

((topic))`,
  'Grade 7': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

If there are no errors then just write "There are no errors in the writing." and do not include the corrections.

Now you proofread and edit below passage:

((topic))`,
  'Grade 11': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

 If there are no errors then just write "There are no errors in the writing." and do not include the corrections.

Now you proofread and edit below passage:

((topic))`,
  'College Level': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

Only if there is errors in the writing, you need to correct and explain the errors. If there is no errors, you do not need to correct and explain the errors.

If there are no errors then just write "There are no errors in the writing." and do not include the corrections.

Now you proofread and edit below passage:

((topic))`,
};

export const BOOK_REPORT_CHECK_FILL = `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

Do not show any ‘correct’ sentences with no mistake. This means we do not need to see the sentences with no mistakes. 

At last, we need to evaluate the book report in 5 criteria in English and Korean.

1.Give me a comment on how well the student did overall.

2.Any improvement the student needs to do.

3.What are strong points of the writing.

4.What are weak points that you could give suggestion.

5.Give me a motivation comments.

Evaluation in Korean also

Count the errors and let know the total numbers at the end. 

If there are no errors then just write "There are no errors in the writing." and do not include the corrections.

Now you proofread and edit below passage: ((topic))`;

export const BOOK_REPORT_CHECK_FILL_LIST = {
  'Grade 1': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

 Do not show any ‘correct’ sentences with no mistake. This means we do not need to see the sentences with no mistakes. 

 At last, we need to evaluate the book report in 5 criteria in English and Korean.
 
 1.Give me a comment on how well the student did overall.
 
 2.Any improvement the student needs to do.
 
 3.What are strong points of the writing.
 
 4.What are weak points that you could give suggestion.
 
 5.Give me a motivation comments.
 
 Evaluation in Korean also
 
 Count the errors and let know the total numbers at the end. 
 
 If there are no errors then just write "There are no errors in the writing." and do not include the corrections.
 
 Now you proofread and edit below passage: ((topic))`,
  'Grade 4': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

 Do not show any ‘correct’ sentences with no mistake. This means we do not need to see the sentences with no mistakes. 

 At last, we need to evaluate the book report in 5 criteria in English and Korean.
 
 1.Give me a comment on how well the student did overall.
 
 2.Any improvement the student needs to do.
 
 3.What are strong points of the writing.
 
 4.What are weak points that you could give suggestion.
 
 5.Give me a motivation comments.
 
 Evaluation in Korean also
 
 Count the errors and let know the total numbers at the end. 
 
 If there are no errors then just write "There are no errors in the writing." and do not include the corrections.
 
 Now you proofread and edit below passage: ((topic))`,
  'Grade 7': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

 Do not show any ‘correct’ sentences with no mistake. This means we do not need to see the sentences with no mistakes. 

 At last, we need to evaluate the book report in 5 criteria in English and Korean.
 
 1.Give me a comment on how well the student did overall.
 
 2.Any improvement the student needs to do.
 
 3.What are strong points of the writing.
 
 4.What are weak points that you could give suggestion.
 
 5.Give me a motivation comments.
 
 Evaluation in Korean also
 
 Count the errors and let know the total numbers at the end. 
 
 If there are no errors then just write "There are no errors in the writing." and do not include the corrections.
 
 Now you proofread and edit below passage: ((topic))`,
  'Grade 11': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

 Do not show any ‘correct’ sentences with no mistake. This means we do not need to see the sentences with no mistakes. 

 At last, we need to evaluate the book report in 5 criteria in English and Korean.
 
 1.Give me a comment on how well the student did overall.
 
 2.Any improvement the student needs to do.
 
 3.What are strong points of the writing.
 
 4.What are weak points that you could give suggestion.
 
 5.Give me a motivation comments.
 
 Evaluation in Korean also
 
 Count the errors and let know the total numbers at the end. 
 
 If there are no errors then just write "There are no errors in the writing." and do not include the corrections.
 
 Now you proofread and edit below passage: ((topic))`,
  'College Level': `I am an ESL English teacher. Please help me carefully correct my students' writing by following these detailed instructions closely.

Checklist for the Correction:

1. Capitalization: Ensure that the first word in a sentence and proper nouns are capitalized.

2. Subject-Verb Agreement: Check that the subject and verb agree in number (singular or plural).

3. Singular and Plural Nouns: Verify the correct use of singular and plural nouns.

4. Spelling Errors: Correct any spelling mistakes.

5. Other Grammatical Errors: Identify and correct additional grammar mistakes, such as tense, prepositions, and articles.

6. Sentence Structure and Mechanics: Ensure proper sentence structure, punctuation (e.g., periods, commas), and spacing.


Key Guidelines for the Correction Process:

▶Strikethrough for Errors: Mark any incorrect parts of the student's sentence using strikethrough. This should be visible only in the "My Writing" section.

▶Bold for Corrections: In the "Corrections" section, show the corrected version of the sentence with the corrected parts in bold.

▶Explanations: After each correction, provide a clear explanation of the error to help the student understand.

▶Review Checklist: After making corrections, double-check against the checklist to ensure all types of errors are addressed.

If there are errors then format the corrections as follows, but do not include this example:


+ My Writing:

   Display my student's writing here in "My Writing" and mark the incorrect parts with a strikethrough.

   Ensure that only the incorrect parts are marked with a strikethrough.

 ------

+ Corrections:

   Display the corrected version of the text from "My Writing" in "Corrections," with the corrected parts indicated in bold.

   Ensure that you mark only the corrected parts in bold, in the version of the text that you corrected for me.

 ------

+ Explanation:

   Briefly list explanations for each error in the "Explanation" section.

 ------

+ Korean Explanation:

   Translate the "Explanation" section into Korean.

 ------

+ Total Error Count: Display the total number of errors you have fixed here in "Total Error Count" as a number only.

 ------

 Do not show any ‘correct’ sentences with no mistake. This means we do not need to see the sentences with no mistakes. 

 At last, we need to evaluate the book report in 5 criteria in English and Korean.
 
 1.Give me a comment on how well the student did overall.
 
 2.Any improvement the student needs to do.
 
 3.What are strong points of the writing.
 
 4.What are weak points that you could give suggestion.
 
 5.Give me a motivation comments.
 
 Evaluation in Korean also
 
 Count the errors and let know the total numbers at the end. 
 
 If there are no errors then just write "There are no errors in the writing." and do not include the corrections.
 
 Now you proofread and edit below passage: ((topic))`,
};

export const BOOK_REPORT_REVISE_FILL_LIST = {
  'Grade 1': `Can you rewrite this writing using very easy words at a first-grade level in polite way without showing corrections 
  and explanations in English and ((language))  with the difficulty level of ((level)) "((topic))"?`,
  'Grade 4': `Can you rewrite this writing using very easy words at a first-grade level in polite way without showing corrections 
  and explanations in English and ((language))  with the difficulty level of ((level)) "((topic))"?`,
  'Grade 7': `Can you rewrite this writing using very easy words in formal way without showing corrections
  and explanations in English and ((language)) with the difficulty level of ((level)) "((topic))"?`,
  'Grade 11': `Can you rewrite this writing using very easy words in formal way without showing corrections 
  and explanations in English and ((language))  with the difficulty level of ((level)) "((topic))"?`,
  'College Level': `Can you rewrite this writing using very easy words in formal way without showing corrections
  and explanations in English and ((language))  with the difficulty level of ((level)) "((topic))"?`,
};

export const BOOK_REPORT_WORD_LIST = {
  'Grade 1': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
Explain the definition in Korean with part of speech and give me synonyms and 
give 2 example sentences in English and Korean in the format of 
"The Word:", 
"Meaning:", 
"Korean Meaning: ", 
"Sentence(1):", 
"Translation in Korean:",
"Sentence(2):", 
"Translation in Korean:",
"Synonyms".`,
  'Grade 4': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
Explain the definition in Korean with part of speech and give me synonyms and 
give 2 example sentences in English and Korean in the format of 
"The Word:", 
"Meaning:", 
"Korean Meaning: ", 
"Sentence(1):", 
"Translation in Korean:",
"Sentence(2):", 
"Translation in Korean:",
"Synonyms".`,
  'Grade 7': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
Explain the definition in Korean with part of speech and give me synonyms and 
give 2 example sentences in English and Korean in the format of 
"The Word:", 
"Meaning:", 
"Korean Meaning: ", 
"Sentence(1):", 
"Translation in Korean:",
"Sentence(2):", 
"Translation in Korean:",
"Synonyms".`,
  'Grade 11': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
Explain the definition in Korean with part of speech and give me synonyms and 
give 2 example sentences in English and Korean in the format of 
"The Word:", 
"Meaning:", 
"Korean Meaning: ", 
"Sentence(1):", 
"Translation in Korean:",
"Sentence(2):", 
"Translation in Korean:",
"Synonyms".`,
  'College Level': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
Explain the definition in Korean with part of speech and give me synonyms and 
give 2 example sentences in English and Korean in the format of 
"The Word:", 
"Meaning:", 
"Korean Meaning: ", 
"Sentence(1):", 
"Translation in Korean:",
"Sentence(2):", 
"Translation in Korean:",
"Synonyms".`,
};

export const BOOK_REPORT_COMMENT_LIST = {
  full: `As an English teacher responsible for revising and providing feedback on essay assignments, I kindly request your assistance in evaluating this piece of writing. Please offer a concise opinion on the quality of the writing, assessing whether it is well-crafted. Additionally, confirm the presence of a clear topic sentence and a concluding sentence in both English and ((language)).
  Furthermore, examine the factual accuracy and logical coherence of the essay and explain the reason, making necessary corrections and providing explanations for your revisions. Analyze the organization and voice employed in the writing and assess whether the writer has utilized appropriate word choices in detail. 
Estimate the Toefl score of this writing in scale of 0 to 30.
All Titles should be bold font.
  Summarize your feedback in the following format:
  “Opinion:”,
  
  "---"

  “Topic / Concluding Sentence:”

  "---"

  “Facts and Logic:”

  "---"

  “Voice:”

  "---"

  “Organization:”

  "---"

  “Word Choice:”

  "---" 

  “++ Strong Points: “
  "++"

  “++ Weak Points: “

  "---"
  
  “Suggestions: “

  "---"
  
  “TOEFL Score:”

  “----"

  Please explain in English and Korean how to enhance this writing by listing specific suggestions in detail in English and ((language)). Additionally, identify its strong and weak points in English and ((language)) in a language suitable for ((level)) level. Here is the original essay for reference:
  “((topic))”`,
  min: `As an English teacher responsible for revising and providing feedback on essay assignments, I kindly request your assistance in evaluating this piece of writing. Please offer a concise opinion on the quality of the writing, assessing whether it is well-crafted. Additionally, confirm the presence of a clear topic sentence and a concluding sentence in both English and ((language)).
  Furthermore, examine the factual accuracy and logical coherence of the essay and explain the reason, making necessary corrections and providing explanations for your revisions. Analyze the organization and voice employed in the writing and assess whether the writer has utilized appropriate word choices in detail. 
Estimate the Toefl score of this writing in scale of 0 to 30.
All Titles should be bold font.
  Summarize your feedback in the following format:
  “Opinion:”

  "---"

  “Topic / Concluding Sentence:”

  "---"

  “Facts and Logic:”

  "---"

  “Voice:”

  "---"

  “Organization:”

  "---"

  “Word Choice:”

  "---" 

  “++ Strong Points: “
  "++"

  “++ Weak Points: “

  "---"
  
  “Suggestions: “

  "---"
  
  “TOEFL Score:”

  “----"

  Please explain in English and Korean how to enhance this writing by listing specific suggestions in detail in English and ((language)). Additionally, identify its strong and weak points in English and ((language)) in a language suitable for ((level)) level. Here is the original essay for reference:
  “((topic))”`,
};

export const MXLEVEL_TO_AGL = {
  0: 'K1 ~ K2',
  1: 'K3',
  2: '1.1 ~ 1.2',
  3: '1.3 ~ 1.6',
  4: '1.7 ~ 2.2',
  5: '2.3 ~ 2.7',
  6: '2.8 ~ 3.3',
  7: '3.4 ~ 4.1',
  8: '4.2 ~ 4.8',
  9: '4.9 ~ 6.0',
  10: '6.1 ~ 7.4',
  11: '7.5 ~ 10.0',
  12: '10.1 ~ 13.5',
};

export const MXLEVEL_TO_LEXILE = {
  0: 'Phonics',
  1: 'BR100 ~ 0',
  2: '0L ~ 100L',
  3: '110L ~ 250L',
  4: '260L ~ 390L',
  5: '400L ~ 490L',
  6: '500L ~ 600L',
  7: '610L ~ 700L',
  8: '710L ~ 780L',
  9: '790L ~ 900L',
  10: '910L ~ 1,000L',
  11: '1,010L ~ 1,160L',
  12: '1,170L ~ 1,350L',
};
