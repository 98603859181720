import { gql } from '@apollo/client';

export const CREATE_REPORT_CARD = gql`
  mutation createCulpReportCard(
    $class_idx: Int!
    $user_idx: Int!
    $sdate: DateTime!
    $edate: DateTime!
    $comment_ko: String
    $comment_en: String
    $study_status_result: JSONObject
    $score_result: JSONObject
  ) {
    createCulpReportCard(
      class_idx: $class_idx
      user_idx: $user_idx
      sdate: $sdate
      edate: $edate
      comment_ko: $comment_ko
      comment_en: $comment_en
      study_status_result: $study_status_result
      score_result: $score_result
    ) {
      idx
    }
  }
`;
