import React, { useMemo, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { HalfWrapper, HeaderTitle } from 'src/components/common/Styles';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { useModal } from 'src/utils/hooks';
import BranchModal from 'src/components/Modal/BranchModal';
import { GET_BRANCH_LIST } from 'src/operations/queries/company';
import { useHistory } from 'react-router-dom';
// 
import { useLoginTo } from 'src/utils/hooks';
// 
const { Title } = Typography;

const Branches = () => {
// 
const loginTo = useLoginTo(false);
// 
  const history = useHistory();
  const { visible, showModal, handleCancel } = useModal();
  const [popupMode, setPopupMode] = useState('create');
  const [currentCampusIdx, setCurrentCampusIdx] = useState(null);
  const { data, loading, refetch } = useQuery(GET_BRANCH_LIST);
  console.log('data--->', data);
  const handleSuccess = () => {
    refetch();
  };
  const handleModifiedPopup = (idx) => {
    setCurrentCampusIdx(idx);
    showModal();
    setPopupMode('update');
  };
  const dataBranch = useMemo(() => {
    if (data?.branchList) {
      return data?.branchList.map((item, key) => {
        return {
          key: `branch-list-${key}`,
          No: key + 1,
          branchName: item.name,
          phone: item.phone,
          address: item.address,
          idx: item.idx,
          setModifiedPopup: handleModifiedPopup,
          // 
          id:item.user[0].id,
          loginTo,
          //
        };
      });
    }
    return [];
  }, [data]);
  const branchColumn = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: '5%',
    },
    {
      title: '지사명',
      dataIndex: 'branchName',
      key: 'branchName',
      align: 'center',
      render: (text, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              record.setModifiedPopup(record.idx);
            }}
          >
            {text}&nbsp;
            <CustomButton size="small" icon={<EditOutlined />} />
          </span>
        );
      },
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
    },
    {
      title: 'SMS Point',
      dataIndex: 'login',
      key: 'login',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <CustomButton
              type="primary"
              size="small"
              onClick={() => {
                history.push(`/smsstatic/${record.idx}`);
              }}
            >
              View
            </CustomButton>
          </>
        );
      },
    },
    // 
    {
      title: '로그인',
      dataIndex: 'login',
      key: 'login',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <CustomButton
              type="primary"
              size="small"
              onClick={() => {
                //loginTo(record.manager.id, record.client, record.loginUser);
                record.loginTo(record.id);
              }}
            >
              로그인
            </CustomButton>
          </>
        );
      },
    },
    // 
  ];
  
  return (
    <>
      <HeaderTitle level={4}>지사 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>LIST</Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setCurrentCampusIdx(null);
                    showModal();
                    setPopupMode('create');
                  }}
                >
                  + 신규추가
                </Button>
              </Col>
            </Row>
            <CustomTable
              dataSource={dataBranch}
              pagination={{ pageSize: 10 }}
              columns={branchColumn}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              color="#edf3fb"
              loading={loading}
            />
          </HalfWrapper>
        </Col>
      </Row>
      {visible && (
        <BranchModal visible={visible} handleCancel={handleCancel} onSuccess={handleSuccess} popupMode={popupMode} idx={currentCampusIdx} />
      )}
    </>
  );
};

export default Branches;

