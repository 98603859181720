import React, { useMemo, useRef } from 'react';
import { Button, Modal, Typography } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';

import CustomTable from 'src/components/common/CustomTable';
import { koreanNumberFormat, getHumanReadablePhoneNumber } from 'src/utils';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { DELIVERY_STATUS_READY, DELIVERY_STATUS_REG } from 'src/constants/common';
import { isAfterSchool, isLucid } from 'src/utils';

const { Title } = Typography;
const InfoTitle = styled(Title)`
  margin-bottom: 1px !important;
`;
const InfoTable = styled.table`
  margin-top: 0;
  margin-bottom: 15px;
`;
const TitleForPrint = styled(Title)`
  display: none;
  @media print {
    display: block;
    text-align: center;
  }
`;

const PrintWrapper = styled.div`
  @media print {
    padding: 20px;
  }
`;

const InvoiceModal = ({ visible, info, loadingUpdate, onCancel, onUpdateInvoice }) => {
  const printfRef = useRef();
  const companyName = useSelector(classStoreData);
  const durationCheck = (code)=>{
    if(companyName==='englishtap' &&code){
      if(['ET','LTR','KTP'].includes(code.split('-')[0]))
        {return '90'} else{ return '-'}
    }
    else return '30'
  }
  const dataSource = useMemo(() => {
    if (info?.order_list) {
      return info?.order_list.map((item, key) => {
        return {
          key: `invoice-detail-${key}`,
          no: `${key + 1}`,
          name: item?.product_name || item.product_code,
          count: item?.quantity,
          amount: item?.sum_price,
          duration:durationCheck(item?.product_code),
        };
      });
    }
    return [];
  }, [info]);
  const handlePrint = useReactToPrint({
    content: () => printfRef.current,
  });

  return (
    <Modal
      visible={visible}
      title={`${info?.campus_name} 구매내역`}
      onCancel={onCancel}
      width={'60%'}
      footer={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <Button
            type="primary"
            key="back"
            onClick={() => {
              onViewDelivery(true);
            }}
          >
            배송결제정보
          </Button> */}
          {/* <Button key="back" onClick={() => onCancel()}>
            확인
          </Button> */}
          {onUpdateInvoice && [DELIVERY_STATUS_REG, DELIVERY_STATUS_READY].includes(info?.order_payment?.delivery_status) && (
            <Button
              type={info?.order_payment?.delivery_status === DELIVERY_STATUS_REG ? 'primary' : 'danger'}
              loading={loadingUpdate}
              onClick={() => {
                onUpdateInvoice(
                  info?.order_payment?.idx,
                  info?.order_payment?.delivery_status === DELIVERY_STATUS_REG ? DELIVERY_STATUS_READY : DELIVERY_STATUS_REG,
                );
              }}
            >
              {info?.order_payment?.delivery_status === DELIVERY_STATUS_REG ? '주문서확인' : '주문서확인 취소'}
            </Button>
          )}

          <Button type="primary" onClick={handlePrint}>
            주문서출력
          </Button>
        </div>
      }
    >
      <PrintWrapper ref={printfRef}>
        <TitleForPrint level={4}>{`${info?.campus_name} 구매내역`}</TitleForPrint>
        <div>{moment(info?.idate).format('YYYY-MM-DD')}</div>
        <CustomTable
          pagination={{ pageSize: 12, position: ['bottomCenter'] }}
          dataSource={dataSource}
          columns={isAfterSchool(companyName) || isLucid(companyName) ? columnsForSmartEclass : columns}
          size="small"
          color="#edf3fb"
          scroll={{ y: 'calc(100vh - 254px)' }}
        />
        {!isAfterSchool(companyName) && !isLucid(companyName) && (
          <div style={{ textAlign: 'right' }}>
            <label>배송비 : </label>
            <input type="text" readOnly value={koreanNumberFormat(info?.delivery_price)} style={{ textAlign: 'right' }} /> 원 &nbsp; &nbsp;
            <label>총 구매 금액 : </label>
            <input type="text" readOnly value={koreanNumberFormat (info?.amount)} style={{ textAlign: 'right' }} /> 원
          </div>
        )}

        <InfoTitle level={5}>배송정보</InfoTitle>
        <InfoTable className="table-t-three">
          <tbody>
            <tr>
              <th style={{ width: '200px' }}>수취인</th>
              <td>{info?.order_payment?.buyer_name}</td>
              <th style={{ width: '200px' }}>휴대전화</th>
              <td>{getHumanReadablePhoneNumber(info?.order_payment?.buyer_tel)}</td>
            </tr>
            <tr>
              <th style={{ width: '100px' }}>배송지</th>
              <td colSpan="3">{info?.order_payment?.buyer_addr}</td>
            </tr>
            <tr>
              <th style={{ width: '100px' }}>배송시 요청사항</th>
              <td colSpan="3">{info?.order_payment?.delivery_comment}</td>
            </tr>
          </tbody>
        </InfoTable>
        <InfoTitle level={5}>결제방법</InfoTitle>
        <InfoTable className="table-t-three">
          <tbody>
            <tr>
              <th style={{ width: '100px' }}>결제방법</th>
              <td colSpan="3">{info?.order_payment?.pay_method?.includes('card') ? '신용카드' : '후불정산'}</td>
            </tr>
          </tbody>
        </InfoTable>
      </PrintWrapper>
    </Modal>
  );
};

export default InvoiceModal;

const columns = [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '내용',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '이용권 기간',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: '권 수',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: '금액',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => koreanNumberFormat(text),
  },
];

const columnsForSmartEclass = [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '내용',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '권 수',
    dataIndex: 'count',
    key: 'count',
  },
];
