import React from 'react';
import { Col, Row, Form, Input, Button, DatePicker } from 'antd';
import styled from 'styled-components';
import FormItem from 'antd/lib/form/FormItem';

import MyPage from 'src/pages/MyPage';
import CustomButton from 'src/components/common/CustomButton';

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

function MyInfo() {
  return (
    <MyPage>
      <Row>
        <Col span={24}>
          <MyInfoWrapper>
            <Form>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <FormItem
                    name="name"
                    rules={[
                      { required: true, message: '학생이름을 입력해 주세요.' },
                    ]}
                    label={`학생 이름`}
                    hasFeedback
                  >
                    <Input name="name" />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    name="birthday"
                    rules={[{ required: true }]}
                    label={`생년월일`}
                    hasFeedback
                  >
                    <DatePicker name="birthday" placeholder="생년월일" />
                  </FormItem>
                </Col>

                {/* <Col span={12}>
              <Row>
                <Col span={4}>
                  <label>학생 ID</label>
                </Col>
                <Col span={20}>Goodzzong</Col>
              </Row>
            </Col> */}

                <Col span={12}>
                  <FormItem
                    name="id"
                    rules={[{ required: true }]}
                    label={`학생 ID`}
                    hasFeedback
                  >
                    <Input name="id" />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem name="pw" label={`Password`} hasFeedback>
                    <CustomButton style={{ width: 150 }}>변경하기</CustomButton>
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    name="phone"
                    rules={[{ required: true }]}
                    label={`학생 연락처`}
                    hasFeedback
                  >
                    <Input name="phone" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    name="parent_phone"
                    rules={[{ required: true }]}
                    label={`부모님 전화`}
                    hasFeedback
                  >
                    <Input name="parent_phone" />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: 30,
                    }}
                  >
                    <CustomButton size="large" style={{ width: 200 }}>
                      등록
                    </CustomButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </MyInfoWrapper>
        </Col>
      </Row>
    </MyPage>
  );
}

export default MyInfo;
