import React, { useCallback, useMemo, useState } from 'react';
import { Tabs, Button } from 'antd';

import SmsTabSent from './SmsTabSent';
import LoadingLayer from 'src/components/common/LoadingLayer';
import SmsChargeHistoryContainer from './SmsChargeHistoryContainer';
import SmsTxtHistoryContainer from './SmsTxtHistoryContainer';

import { userInfoVar } from 'src/apollo/cache';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_SMS_ACCOUNT } from 'src/operations/mutations/message';
import { GET_USER_SMS_ACCOUNT_INFO } from 'src/operations/queries/getUser';
import { CAMPUS_REMAINED_SMS_POINT } from 'src/operations/queries/message';

const { TabPane } = Tabs;

function SmsManage() {
  const [tabKey, setTabKey] = useState('1');
  console.log('userInfoVar', userInfoVar());
  const { data, loading, refetch } = useQuery(GET_USER_SMS_ACCOUNT_INFO, {
    variables: { idx: parseInt(userInfoVar()?.idx) },
    skip: !userInfoVar()?.idx,
    fetchPolicy: 'no-cache',
  });
  const { data: dataSmsPoint, refetch: refetchPoint } = useQuery(CAMPUS_REMAINED_SMS_POINT, {
    variables: { campus_idx: parseInt(userInfoVar()?.campus_idx) },
    skip: !userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });
  const [create, { loadingCreate }] = useMutation(CREATE_SMS_ACCOUNT, {
    onCompleted: ({ createSmsAccount }) => {
      const { success, message } = createSmsAccount;
      if (success) {
        //alert('성공');
        console.log('계정 생성 성공');
        refetch();
      } else {
        alert(message);
      }
    },
    onError: () => {
      alert('error');
    },
  });
  const smsAccountInfo = useMemo(() => {
    const item = data?.getUserDataByIdx;
    if (item) {
      if (!item.ione_sms_account_info) {
        console.log('---------계정생성---------');
        create(); //없으면 계정생성..
      }
      return item.ione_sms_account_info;
    }
    return null;
  }, [data]);

  const smsRemainedPoint = useMemo(() => {
    return dataSmsPoint?.campusRemainedSmsPoint || 0;
  }, [dataSmsPoint]);

  const openSmsRegisterUrl = useCallback(() => {
    if (smsAccountInfo) {
      window.open(`https://smsmsgr.ione24.com/_outside_support/hp_auth.aspx?${smsAccountInfo}`);
    } else {
      alert('등록정보가 없습니다. 관리자에게 문의하세요.');
    }
  }, [smsAccountInfo]);

  return (
    <>
      {(loading || loadingCreate) && <LoadingLayer style={{ zIndex: 3000 }} />}
      <div className="smsManageWrap" style={{ background: '#ffffff', width: '100%', margin: '40px 0', paddingBottom: '23px' }}>
        <h2 className="sms-tit">
          Messages(SMS){' '}
          <div style={{ paddingTop: 5 }}>
            <Button type="primary" onClick={openSmsRegisterUrl} style={{ display: 'inline-block' }} loading={loadingCreate} size="small">
              발신번호등록
            </Button>
          </div>
        </h2>

        <Tabs activeKey={tabKey} onChange={setTabKey} className="testTab" closable={false}>
          <TabPane tab="문자발송" key="1">
            {/* <Form> */}
            <SmsTabSent smsAccountInfo={smsAccountInfo} smsRemainedPoint={smsRemainedPoint} refetchPoint={refetchPoint} />
            {/* </Form> */}
          </TabPane>

          <TabPane tab="문자내역" key="2" style={{ paddingBottom: '35px' }}>
            <SmsTxtHistoryContainer smsRemainedPoint={smsRemainedPoint} />
          </TabPane>

          <TabPane tab="구매내역" key="3" style={{ paddingBottom: '35px' }}>
            <SmsChargeHistoryContainer smsRemainedPoint={smsRemainedPoint} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default SmsManage;
