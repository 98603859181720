import React, { useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useReactToPrint } from 'react-to-print';
import { Modal, Typography, Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { PrinterTwoTone } from '@ant-design/icons';
import StatusCheckTableLucid from './components/StatusCheckTableLucid';
import { GET_CLASSS_INFO_FOR_REPPORT } from 'src/operations/queries/getClass';
import BasicInfoTableLucid from './components/BasicInfoTableLucid';
import StudyCompleteTableLucid from './components/StudyCompleteTableLucid';
import FinalTestInputTableLucid from './components/FinalTestInputTableLucid';

const PrintWrapper = styled.div`
  width: 100%;
  @media print {
    width: 100%;
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
    background-color: #fff;
  }
`;

const ReportCardViewModalLucid = ({ class_idx, user_name, user_idx, dateRange, visible, onCancel, reportCardItem }) => {
  const printAreaComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printAreaComponentRef.current,
  });
  const { data } = useQuery(GET_CLASSS_INFO_FOR_REPPORT, { variables: { class_idx }, skip: !class_idx });
  const basicInfo = useMemo(() => {
    if (data) {
      return {
        campus_name: data?.classInfo?.campus?.name,
        class_name: data?.classInfo?.name,
        book_title: data?.classInfo?.book?.book_sub[0]?.title,
        teacher_name: data?.classInfo?.class_teacher[0]?.user?.name,
        campus_name: data?.classInfo?.campus?.name,
        is_phonics: parseInt(data?.classInfo?.book?.level_no) > 0 ? false : true,
      };
    }
    return null;
  }, [data]);

  const [book_codes, classBooks] = useMemo(() => {
    const book_codes = [];
    const classBooks = [];
    console.log(reportCardItem);
    if (reportCardItem && reportCardItem?.score_result) {
      const JSONScoreResult = JSON.parse(reportCardItem?.score_result);
      console.log(JSONScoreResult);
      if (JSONScoreResult?.book_codes) {
        JSONScoreResult?.book_codes.forEach((item) => {
          book_codes.push(item);
        });
      }
      if (JSONScoreResult?.classBooks) {
        JSONScoreResult?.classBooks.forEach((item) => {
          classBooks.push(item);
        });
      }
    }
    return [book_codes, classBooks];
  }, [reportCardItem]);

  console.log('basicInfo', classBooks);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={'60%'}
      footer={null}
      title={
        <div style={{ textAlign: 'right', marginRight: '50px' }}>
          <Button onClick={handlePrint}>
            <PrinterTwoTone />
            Print
          </Button>
        </div>
      }
    >
      <PrintWrapper ref={printAreaComponentRef}>
        <BasicInfoTableLucid user_name={user_name} dateRange={dateRange} basicInfo={basicInfo} />
        <Row gutter={[16, 16]} style={{ rowGap: '5px' }}>
          <StudyCompleteTableLucid
            bookCode={data?.classInfo?.book_code}
            class_idx={class_idx}
            user_idx={user_idx}
            book_codes={book_codes}
            classBooks={classBooks}
          />
          <Col span={24}>
            <FinalTestInputTableLucid
              class_idx={class_idx}
              user_idx={user_idx}
              dateRange={dateRange}
              onChangeScore={() => {}}
              result={reportCardItem?.score_result}
            />
          </Col>
          <Col span={24}>
            <StatusCheckTableLucid onChangeStatus={() => {}} data={reportCardItem} basicInfo={basicInfo} />
          </Col>
        </Row>
      </PrintWrapper>
    </Modal>
  );
};

export default ReportCardViewModalLucid;
