import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Row, Typography, Modal, Button, DatePicker, Radio, Tooltip } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ClassRoomModalForPturn from 'src/components/Modal/ClassRoomModalForPturn';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';

import UseHistoryState from 'src/components/common/UseHistoryState';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek, getMonthDateRange, checkIfCampusUser } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import ClassAndMemberMenuGroup from 'src/components/common/ClassAndMemberMenuGroup';

const { Title, Text } = Typography;
const { confirm } = Modal;

const options = [
  { label: <Tooltip title="현재 진행중인 반 리스트">진행중</Tooltip>, value: true },
  { label: <Tooltip title="종료된 반 리스트">종료</Tooltip>, value: false },
];

function ClassRoomManagerForSmartEclass() {
  const userType = userInfoVar()?.type;
  const [classList, setClassList] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [totalCurrentStudyingStudent, setTotalCurrentStudyingStudent] = useState(0);

  const { visible, showModal, handleCancel, popupMode } = useModal();

  const [localState, setLocalState] = UseHistoryState({
    page: 1,
    startDate: '',
    endDate: '',
    searchValue: '',
    className: '',
    teacher: '',
    start_date: '',
    end_date: '',
  });

  const {
    data,
    loading: getClassLoading,
    refetch,
  } = useQuery(queries.getClass.GET_TYPE_CLASS, {
    fetchPolicy: 'no-cache',
    variables: {
      start: localState.startDate,
      end: localState.endDate,
    },
  });

  const [deleteClass] = useMutation(mutations.classRoom.DELETE_CLASS, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });
  useEffect(() => {
    if (data) {
      const fileteredList = data.classList
        .reduce((acc, cur, index) => {
          const dayOfWeek = customDayOfWeek(cur);
          const book = cur?.book?.book_sub[0].title ||cur.book_title
          const books = []
          cur?.class_book.map((e)=>{
            const name = e.book.book_sub[0].title
            if(name!==book){books.push(name)}
          })
          
          const obj = {
            key: cur.idx,
            No: index + 1,
            className: cur.name,
            studentCount: `${cur?.class_student.filter((item) => item.stat === '1').length}명`,
            student_count_number: cur?.class_student.filter((item) => item.stat === '1').length || 0,
            teacher: cur.class_teacher[0]?.user?.name,
            yoil: dayOfWeek,
            time: !cur.begin_time || !cur.end_time ? '종일' : `${cur.begin_time} ~ ${cur.end_time}`,
            start_date: cur?.begin_date && moment(cur?.begin_date).format('YYYY-MM-DD'),
            end_date: cur?.end_date && moment(cur?.end_date).format('YYYY-MM-DD'),
            reg_date: cur?.idate && moment(cur?.idate).format('YYYY-MM-DD'),
            book_code: cur?.book_code,
            book_title: books.length>0?`${book} 외 ${books.length}권`:book,
          };
          return [...acc, obj];
        }, [])
        .filter((item) => {
          if (!inProgress) {
            if (item.end_date && moment().isAfter(item.end_date)) {
              return true;
            }
            return false;
          } else {
            if (!item.end_date || !moment().isAfter(item.end_date)) {
              return true;
            }
            return false;
          }
        })
        .map((item, key) => ({ ...item, No: key + 1 }));
      if (inProgress) {
        setTotalCurrentStudyingStudent(
          fileteredList.reduce((acc, cur) => {
            return acc + cur.student_count_number;
          }, 0),
        );
      }
      setClassList(fileteredList);
    }
  }, [data, inProgress]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const handleYearMonthSelect = (date, dateString) => {
    const { start, end } = getMonthDateRange(dateString);

    setLocalState((prev) => {
      return {
        page: 1,
        startDate: start,
        endDate: end,
        searchValue: dateString,
      };
    });
  };

  const handleClassList = () => {
    setLocalState((prev) => {
      return {
        page: 1,
        startDate: '',
        endDate: '',
        searchValue: '',
      };
    });
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '해당 반 및 관련된 데이터가 전부 삭제되며 복구가 어렵습니다. 정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteClass({
            variables: {
              class_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 반을 선택해 주세요.',
      okText: '확인',
    });
  }

  function handleTableChange(pagination, filters, sorter) {
    console.log('sorter', sorter);
    setLocalState((prev) => {
      return {
        ...prev,
        page: pagination.current,
        [sorter.field]: sorter.order,
      };
    });
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
    },
    {
      title: 'Class(반명)',
      dataIndex: 'className',
      key: 'className',
      defaultSortOrder: localState.className,
      align: 'center',
      sorter: (a, b) => a.className.localeCompare(b.className),
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '교재정보',
      dataIndex: 'book_code',
      key: 'book_code',
      align: 'center',
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{record.book_title} </Link>;
      },
    },
    {
      title: '수강생',
      dataIndex: 'studentCount',
      key: 'studentCount',
      align: 'center',
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '강사',
      dataIndex: 'teacher',
      key: 'teacher',
      align: 'center',
      defaultSortOrder: localState.teacher,
      sorter: (a, b) => a.teacher && a.teacher.localeCompare(b.teacher),
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '요일',
      dataIndex: 'yoil',
      key: 'yoil',
      align: 'center',
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: '기간',
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      defaultSortOrder: localState.start_date,
      sorter: (a, b) => a.start_date && a.start_date.localeCompare(b.start_date),
      render: (text, record) => `${record.start_date || ''} ~ ${record.end_date || ''}`,
    },
    {
      title: '상세보기',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Tooltip title={inProgress ? '학사일정을 변경할 수 있습니다.' : '새로운 교재로 변경하거나 일정을 수정할 수 있습니다.'}>
          <Button>
            <Link to={`/classroom/${text.key}`}>View </Link>
          </Button>
        </Tooltip>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'reg_date',
      key: 'reg_date',
      align: 'center',
    },
  ];

  return (
    <>
      <ClassAndMemberMenuGroup currentMenu="class" />
      <HeaderTitle level={4}>
        반 관리{' '}
        {inProgress && (
          <span style={{ fontSize: '0.7em', paddingLeft: '10px' }}>* 학습기간이 종료되면 종료반에서 리스트를 확인할 수 있습니다.</span>
        )}
      </HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                Class List
              </Title>
              <div className="header-classroom-search">
                <span level={6} style={{ paddingRight: '10px' }}>
                  수강생 : {totalCurrentStudyingStudent}명
                </span>
                <span style={{ paddingRight: '10px' }}>
                  <Radio.Group
                    options={options}
                    optionType="button"
                    value={inProgress}
                    buttonStyle="solid"
                    onChange={(e) => {
                      setInProgress(e.target.value);
                    }}
                  />
                </span>
                <DatePicker
                  picker="month"
                  placeholder="전체"
                  onChange={handleYearMonthSelect}
                  value={localState.searchValue !== '' ? moment(localState.searchValue) : ''}
                />
                <CustomButton style={{ marginRight: 10 }} onClick={() => handleClassList()}>
                  <RedoOutlined />
                </CustomButton>
                <Tooltip title="신규반을 등록하세요.">
                  <CustomButton style={{ marginRight: 10, border: '1px solid #52c419' }} onClick={() => showModal('create')}>
                    <PlusOutlined style={{ color: '#52c419' }} />
                    <Text type={'success'}>등록</Text>
                  </CustomButton>
                </Tooltip>

                {checkIfCampusUser(userType) && (
                  <>
                    <CustomButton
                      danger
                      style={{
                        marginRight: 10,
                      }}
                      onClick={deleteConfirm}
                    >
                      삭제
                    </CustomButton>
                  </>
                )}
              </div>
            </div>

            <CustomTable
              className="classroom-table-table"
              loading={getClassLoading}
              dataSource={classList}
              columns={columns}
              rowSelection={
                checkIfCampusUser(userType) && {
                  type: 'checkbox',
                  ...rowSelection,
                }
              }
              pagination={{
                pageSize: 10,
                current: localState.page,
                position: ['bottomCenter'],
              }}
              size="small"
              color="#edf3fb"
              scroll={{ x: 500 }}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <ClassRoomModalForPturn visible={visible} popupMode={popupMode} handleCancel={handleCancel} refetch={refetch} />}
    </>
  );
}

export default ClassRoomManagerForSmartEclass;
