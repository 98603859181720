import React, { useState } from 'react';
import {  useHistory } from 'react-router-dom';
import moment from 'moment';
import { Form, Input, DatePicker } from 'antd';
import styled from 'styled-components';
import SearchCampusModal from 'src/components/Modal/SearchCampusModal';
import { useQuery} from '@apollo/client';
import { GET_USER_INFO } from 'src/operations/queries/campus';
// const { Option } = Select;

const InnerWrap = styled.div`
  background-color: #fff;
  width: calc(100% - 10rem);
  height: calc(100% - 10rem);
  padding: 2rem 1rem;
  margin: 2rem auto;
  border-radius: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
  }



  @media (max-width: 1920px) {

    .inner-header {
      display: flex;
      position: fixed;
      margin: 1rem 0;
      transform: translate(10px, 0px);
      top: 10rem;
      left: auto;
      h2 {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16/9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 750px;
    margin: 3rem auto;

    & {
      .inner-header {
        top: 14rem;
        width: 100%;
        max-width: 1350px;

        h2 {
          font-size: 2rem;
        }
      }

      .btn.fixed_btn{
        bottom: 12rem;
      }
    }
  }

  @media (max-width: 1280px){
    &{
      #student-register .userInfo-wrap{
        margin: 2rem auto 0;
      }
    }
  }

  @media (max-width: 1200px){
    .inner-header{
        top: 10rem;
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16/9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 600px;
    margin: 2rem auto;

    & {
      .inner-header {
        top: 10rem;
        width: 100%;
        max-width: 1150px;

        h2 {
          font-size: 1.7rem;
        }
      }
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16/9) {
    height: 70%;
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 800px;

        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media (max-width: 1450px){
    &{
      #student-register{
        .divided-form{
          justify-content: center;
        }
      }

      .inner-header{

      }
    }
  }

  @media (max-width: 1366px){
    margin: 2rem auto;
  }

  @media (max-width: 1280px){
    margin: 0 auto;

    &{
      .btn.fixed_btn{
        bottom: 8rem;
      }
    }
  }


  @media (max-width: 1200px) and (max-height: 800px) {
    height: 64%;
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    &{
      .inner-header{
        top: 0;
        width: 100%;
      }
    }

    &.inner-header {
      width: 100%;
      top: 0;
      h2 {
        font-size: 1.4rem;
      }
      top: 0rem;
    }
  }


  @media (max-width: 1200px) {
    & {
      inner-header {
        width: 65%;
        top: 8rem;

        h2 {
          font-size: 1.3rem;
        }
      }
    }
  }

  @media (max-width: 1140px){
    &{
      .btn.fixed_btn{
        bottom: 0.5rem;
      }
    }
  }

  @media (max-width: 1024px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    position: absolute;
    left: 50%;
    top: 54%;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }
    }
  }

  @media (max-width: 1024px){
    height: 64%;
    width: 80%;

    &{
       .inner-header {
        top: 0;
        width: 90%;
      }

      .btn.fixed_btn {
        bottom: 2rem;
      }
    }
  }

  @media (max-width: 960px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 800px;
    max-height: 380px;
    position: absolute;
    left: 50%;
    top: 53%;
  }
`;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 16,
//       offset: 8,
//     },
//   },
// };

const MetaUserInfo = () => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx===1016 ? true : false;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    const variables = {};
    variables['phone'] = '';
    const keys = Object.keys(values);
    for (const key of keys) {
      if (key === 'reserve_date' || key === 'birth_date') {
        variables[key] = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'campus_name') {
        //pass
      } else if (key.includes('phone')) {
        variables['phone'] += values[key];
      } else if (key.includes('student_name_part2')) {
        variables['student_name'] = `${values['student_name'].toLowerCase()} ${values['student_name_part2'].toLowerCase()}`;
      } else {
        variables[key] = values[key];
      }
    }
    localStorage.setItem('meta_create_order', JSON.stringify(variables));
    history.push('/MetaExam/Survey');
  };
  const handleCancelModal = () => {
    setVisible(false);
  };

  const handleChooseCampus = (values) => {
    console.log('handleChooseCampus', values);
    const { campus_name, campus_idx } = values;
    form.setFieldsValue({ campus_name, campus_idx });
    setVisible(false);
  };
  return (
    <>
      <div className="meta-close">
        <div style={{ justifyContent: 'start' }}>
          <img src="../../images/meta_exit.png" alt="closeImg" />
        </div>
      </div>
      <div className={isWEnglish ? 'meta-con w-english-bg' : 'meta-con'}>
        <div className="meta-header">
          <h1 className="main-tit">{isWEnglish? '': 'MetaExam'} 신청</h1>
        </div>
        <InnerWrap>
          <div className="inner-header">
            <h2>학생정보</h2>
            <div className="date-wrap">
              <h2>2022.03.10</h2>
            </div>
          </div>

          <Form form={form} onFinish={handleSubmit} {...formItemLayout} name="student-register" initialValues={{}} scrollToFirstError>
            <div className="userInfo-wrap">
              <div className="common-form divided-form" style={{ marginTop: '0', marginBottom: '0', height: '56px' }}>
                <Form.Item
                  style={{ display: 'none' }}
                  name="campus_idx"
                  rules={[
                    {
                      required: true,
                      message: '학원을 선택해 주세요.',
                    },
                  ]}
                >
                  <Input type="hidden" name="campus_idx" />
                </Form.Item>

                <Form.Item style={{ marginTop: '0', marginBottom: '0' }} label="학생이름">
                  <div className="partInput_wrap phone_part_main name_parts_main">
                    <Form.Item
                      name="student_name"
                      className="name_parts1"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: '입력해 주세요.',
                        },
                      ]}
                    >
                      <Input className="partInput phone_inputs name_parts" placeholder="Family Name" name="student_name" />
                    </Form.Item>
                    <Form.Item
                      name="student_name_part2"
                      className="name_parts2"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: '입력해 주세요.',
                        },
                      ]}
                    >
                      <Input className="partInput phone_inputs name_parts" placeholder="First Name" name="student_name_part2" />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  style={{ marginTop: '0', marginBottom: '0' }}
                  label="학년"
                  name="school_info"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: '입력해 주세요.',
                    },
                  ]}
                >
                  <Input className="plainInput" maxLength="1" name="school_info" placeholder="예) 3학년 → 3" />
                  {/*<span className="ex-span">예) 3학년 → 3</span>*/}
                </Form.Item>
              </div>

              <div className="common-form plain-form" style={{ marginTop: '0', marginBottom: '0' }}>
                <Form.Item style={{ justifyContent: 'center', marginTop: '0', marginBottom: '0' }} label="학부모 연락처 ">
                  <div className="partInput_wrap phone_part_main">
                    <Form.Item
                      className="phone_parts"
                      name="phone_part1"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: '숫자만 입력해 주세요.',
                        },
                      ]}
                    >
                      <Input className="partInput phone_inputs" maxLength="3" name="phone_part1" />
                    </Form.Item>
                    <Form.Item
                      name="phone_part2"
                      className="phone_parts"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: '숫자만 입력해 주세요.',
                        },
                      ]}
                    >
                      <Input name="phone_part2" className="partInput phone_inputs" maxLength="4" />
                    </Form.Item>
                    <Form.Item
                      name="phone_part3"
                      className="phone_parts"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: '숫자만 입력해 주세요.',
                        },
                      ]}
                    >
                      <Input name="phone_part3" className="partInput phone_inputs" maxLength="4" />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
              <div className="common-form plain-form">
                <Form.Item
                  style={{ justifyContent: 'center', marginTop: '0', marginBottom: '0' }}
                  label="학생 생년월일 "
                  name="birth_date"
                  rules={[
                    {
                      required: true,
                      message: '입력해주세요.',
                    },
                  ]}
                >
                  <DatePicker name="birth_date" defaultValue={moment('2010-01-01')} inputReadOnly />
                </Form.Item>
              </div>
              <div className="common-form plain-form">
                <Form.Item
                  style={{ justifyContent: 'center', marginTop: '0', marginBottom: '0' }}
                  label="유치원/학교 명 "
                  name="campus_name"
                  rules={[
                    {
                      required: true,
                      message: '학원을 선택해 주세요.',
                    },
                  ]}
                >
                  <Input.Search
                    readOnly
                    type="text"
                    className="plainInput1"
                    placeholder=""
                    name="campus_name"
                    onSearch={() => {
                      setVisible(true);
                    }}
                  />
                  {/*<span className="ex-span">예) 동자 초등학교</span>*/}
                </Form.Item>
              </div>
              <div className="common-form plain-form">
                <Form.Item
                  style={{ justifyContent: 'center', marginTop: '0', marginBottom: '0' }}
                  label="응시 예약날짜 "
                  name="reserve_date"
                  rules={[
                    {
                      required: true,
                      message: '예약날짜를 선택해 주세요.',
                    },
                  ]}
                >
                  <DatePicker name="reserve_date" />
                </Form.Item>
              </div>
            </div>
          </Form>

          <div
            className="btn fixed_btn"
            style={{ position: 'fixed' }}
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            <a href="javascript:;">다음</a>
          </div>
        </InnerWrap>
      </div>

      <SearchCampusModal company={'creo'} visible={visible} onCancel={handleCancelModal} onChoose={handleChooseCampus} />
    </>
  );
};

export default MetaUserInfo;