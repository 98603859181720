import { gql } from '@apollo/client';

export const CREATE_TEST = gql`
  mutation createTest(
    $code: String!
    $title: String!
    $meta: [String!]!
    $answers: [JSONObject!]!
    $main_pdf_idx: Int!
    $extra_pdf_idx: Int
    $extra_audio_idx: Int
    $extra_video_idx: Int
    $total_questions: Int!
    $folder_type: String
    $class_idx: Int
    $test_document_idx: Int
    $type: Int
    $batch_type: String
    $test_type: String
    $form_id: String
  ) {
    createTest(
      code: $code
      title: $title
      meta: $meta
      answers: $answers
      main_pdf_idx: $main_pdf_idx
      extra_pdf_idx: $extra_pdf_idx
      extra_audio_idx: $extra_audio_idx
      extra_video_idx: $extra_video_idx
      total_questions: $total_questions
      folder_type: $folder_type
      class_idx: $class_idx
      test_document_idx: $test_document_idx
      type: $type
      batch_type: $batch_type
      test_type: $test_type
      form_id: $form_id
    ) {
      idx
    }
  }
`;

export const EDIT_TEST = gql`
  mutation updateTest(
    $idx: Int!
    $code: String
    $title: String
    $meta: [String!]
    $answers: [JSONObject!]
    $main_pdf_idx: Int
    $extra_pdf_idx: Int
    $extra_audio_idx: Int
    $extra_video_idx: Int
    $total_questions: Int
    $class_idx: Int
  ) {
    updateTest(
      idx: $idx
      code: $code
      title: $title
      meta: $meta
      answers: $answers
      main_pdf_idx: $main_pdf_idx
      extra_pdf_idx: $extra_pdf_idx
      extra_audio_idx: $extra_audio_idx
      extra_video_idx: $extra_video_idx
      total_questions: $total_questions
      class_idx: $class_idx
    ) {
      idx
    }
  }
`;

export const TEST_DELETE = gql`
  mutation deleteTest($idxs: [Int!]!) {
    deleteTest(idxs: $idxs)
  }
`;

export const ASSIGN_TEST = gql`
  mutation assignTests(
    $title: String!
    $start_time: String!
    $limit_time: Int!
    $user_idx_list: [Int!]!
    $class_idx: Int
    $test_idx: Int
    $retries_count: Int
  ) {
    assignTests(
      title: $title
      start_time: $start_time
      limit_time: $limit_time
      test_idx: $test_idx
      user_idx_list: $user_idx_list
      class_idx: $class_idx
      retries_count: $retries_count
    ) {
      idx
    }
  }
`;

export const ASSIGN_TEST_EDIT = gql`
  mutation updateAssignedTest(
    $idx: Int!
    $title: String!
    $start_time: String!
    $limit_time: Int!
    $user_idx_list: [Int!]!
    $class_idx: Int
    $retries_count: Int
  ) {
    updateAssignedTest(
      idx: $idx
      title: $title
      start_time: $start_time
      limit_time: $limit_time
      user_idx_list: $user_idx_list
      class_idx: $class_idx
      retries_count: $retries_count
    ) {
      idx
    }
  }
`;

export const ASSIGN_TEST_DELETE = gql`
  mutation deleteAssignedTest($idxs: [Int!]!) {
    deleteAssignedTest(idxs: $idxs)
  }
`;

export const DAILY_ASSIGN_TEST_DELETE = gql`
  mutation deleteDailyAssignedTest($idxs: [Int!]!) {
    deleteDailyAssignedTest(idxs: $idxs)
  }
`;

export const SAVE_TEST_ANSWERS = gql`
  mutation saveTestAnswers(
    $answers: [JSONObject!]!
    $answer_retakes: [JSONObject!]
    $start_time: String!
    $end_time: String!
    $score: Float!
    $assigned_test_user_idx: Int!
    $form_id: String
    $test_type: String
    $user_idx: Int
    $batch_type: String
  ) {
    saveTestAnswers(
      answers: $answers
      answer_retakes: $answer_retakes
      start_time: $start_time
      end_time: $end_time
      score: $score
      assigned_test_user_idx: $assigned_test_user_idx
      form_id: $form_id
      test_type: $test_type
      user_idx: $user_idx
      batch_type: $batch_type
    ) {
      idx
    }
  }
`;

export const CREATE_TEST_GROUP = gql`
  mutation createTestGroup($code: String!, $title: String!, $folderType: String!, $files: [Upload!]) {
    createTestGroup(code: $code, title: $title, folderType: $folderType, files: $files) {
      code
      title
      meta
      type
      main_pdf {
        idx
      }
      extra_pdf {
        idx
      }
      extra_audio {
        idx
      }
      extra_video {
        idx
      }
      total_questions
      test_group {
        name
      }
      user {
        idx
        name
      }
    }
  }
`;

export const ASSIGN_TEST_GROUP = gql`
  mutation assignTestsGroup(
    $class_idx: Int!
    $start_time: [String!]!
    $limit_time: Int!
    $user_idx_list: [Int!]!
    $test_group_idx: Int!
    $start_date: String!
    $end_date: String!
    $workbook_time: String!
    $retries_count: Int
  ) {
    assignTestsGroup(
      class_idx: $class_idx
      start_time: $start_time
      limit_time: $limit_time
      user_idx_list: $user_idx_list
      test_group_idx: $test_group_idx
      start_date: $start_date
      end_date: $end_date
      workbook_time: $workbook_time
      retries_count: $retries_count
    )
    #  {
    #   idx
    # }
  }
`;


export const DAILY_ASSIGN_TEST_GROUP = gql`
  mutation dailyAssignTestsGroup(
    $class_idx: Int!
    $start_time: [String!]!
    $limit_time: Int!
    $user_idx_list: [Int!]!
    $daily_test_group_idx: String!
    $start_date: String!
    $end_date: String!
    $workbook_time: String!
    $cut_line:Int!
    $no_of_retake:Int!
    $assigned_date:String!
  ) {
    dailyAssignTestsGroup(
      class_idx: $class_idx
      start_time: $start_time
      limit_time: $limit_time
      user_idx_list: $user_idx_list
      daily_test_group_idx: $daily_test_group_idx
      start_date: $start_date
      end_date: $end_date
      workbook_time: $workbook_time
      cut_line:$cut_line
      no_of_retake:$no_of_retake
      assigned_date:$assigned_date
    )
  }
`;

export const WORK_BOOK_DELETE = gql`
  mutation deleteWorkBook($idxs: [Int!]!) {
    deleteWorkBook(idxs: $idxs)
  }
`;
export const DAILY_WORK_BOOK_DELETE = gql`
  mutation dailyDeleteWorkBook($idxs: [Int!]!) {
    dailyDeleteWorkBook(idxs: $idxs)
  }
`;

export const WORK_BOOK_ASSIGNED_DELETE = gql`
  mutation deleteWorkBookAssigned($idxs: [Int!]!) {
    deleteWorkBookAssigned(idxs: $idxs)
  }
`;

export const ADD_STUDENT_IN_TESTS = gql`
  mutation addStudentInTests($class_idx: Int!, $students: [Int!]!, $testGroupIdx: Int!, $testGroupAssignedIdx: Int!) {
    addStudentInTests(class_idx: $class_idx, students: $students, testGroupIdx: $testGroupIdx, testGroupAssignedIdx: $testGroupAssignedIdx)
  }
`;

export const META_EXAM_SAVE_REPORT = gql`
  mutation Test($pdf_url: String!, $mx: String!, $assigned_test_user_idx: Int!, $order_id: Int!) {
    metaExamSaveReport(order_id: $order_id, pdf_url: $pdf_url, mx: $mx, assigned_test_user_idx: $assigned_test_user_idx)
  }
`;

export const CREATE_DAILY_TEST_GROUP = gql`
  mutation createDailyTestGroup($code: String!, $name: String!, $folderType: String!, $files: [Upload!],$metaData:String!,$noOfOnlineTest:Int!,$paperType:Int!) {
    createDailyTestGroup(code: $code, name: $name, folderType: $folderType, files: $files,metaData:$metaData, noOfOnlineTest:$noOfOnlineTest, paperType:$paperType)
  }
`;

export const Add_STUDENT = gql`
  mutation addStudent($daily_test_group_assigned_idx : [Int!]!, $user_idx: [Int!]!) {
    addStudent(user_idx: $user_idx, daily_test_group_assigned_idx: $daily_test_group_assigned_idx)
  }
`;
export const DELETE_STUDENT = gql`
  mutation deleteStudent($daily_test_group_assigned_idx : [Int!]!, $user_idx: [Int!]!) {
    deleteStudent(user_idx: $user_idx, daily_test_group_assigned_idx: $daily_test_group_assigned_idx)
  }
`;

export const ASSIGNED_TEST_DELETE = gql`
  mutation deleteAssignedTestGroup($idxs: [Int!]!) {
    deleteAssignedTestGroup(idxs: $idxs)
  }
`;

export const ASSIGNED_TEST_GROUP_DELETE = gql`
  mutation deleteDailyAssignedTestGroup($idxs: [Int!]!) {
    deleteDailyAssignedTestGroup(idxs: $idxs)
  }
`;

export const TEST_DETAIL_LIST = gql`
  mutation getTestDetailsList($idxs : [Int!]!) {
    getTestDetailsList(idxs: $idxs)
  }
`;

export const DAILY_TEST_DELETE = gql`
  mutation dailyTestDelete($groupIdx: Int!, $testIdx: Int!) {
    dailyTestDelete(testIdx: $testIdx, groupIdx: $groupIdx)
  }
`;
export const ADD_TEST_INTO_GROUP = gql`
  mutation addTestIntoGroup($code: String!, $name: String!, $folderType: String!, $files: [Upload!],$metaData:String!, $groupCode:Int!) {
    addTestIntoGroup(code: $code, name: $name, folderType: $folderType, files: $files,metaData:$metaData,groupCode:$groupCode)
  }
`;

export const UPDATE_TEST_INTO_GROUP = gql`
  mutation updateTestIntoGroup($code: String!,$folderType: String!, $files: [Upload!], $groupCode:Int!,$testIdx:Int!,$excel_files:String,$image_files:[Upload!],$audio_files:[Upload!],$zip_paper_files:String) {
    updateTestIntoGroup(code: $code,folderType: $folderType, files: $files,groupCode:$groupCode,testIdx:$testIdx,excel_files:$excel_files,image_files:$image_files,audio_files:$audio_files,zip_paper_files:$zip_paper_files)
  }
`;