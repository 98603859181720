import { gql } from '@apollo/client';

export const ESSAY_LIST = gql`
  query essayList($idx: Int, $dateStr: String, $endDateStr: String, $lesson_code: String) {
    essayList(idx: $idx, dateStr: $dateStr, endDateStr: $endDateStr, lesson_code: $lesson_code) {
      idx
      title
      content
      lesson_code
      status
      idate
      book_lesson {
        title
      }
    }
  }
`;
