import { gql } from '@apollo/client';

export const REQUEST_PAYLETTER = gql`
  query requestPayLetter(
    $pgcode: String!
    $order_no: String!
    $order_idx: Int
    $user_id: String!
    $product_name: String!
    $amount: Int!
    $delivery_price: Int!
    $item_list: [OrderItemListInput!]!
    $buyer_info: String!
    $order_type: String
  ) {
    requestPayLetter(
      pgcode: $pgcode
      order_no: $order_no
      order_idx: $order_idx
      user_id: $user_id
      product_name: $product_name
      amount: $amount
      delivery_price: $delivery_price
      item_list: $item_list
      buyer_info: $buyer_info
      order_type: $order_type
    ) {
      success
      info
    }
  }
`;

export const PLACE_ORDER = gql`
  query placeOrder(
    $pgcode: String!
    $order_no: String!
    $order_idx: Int
    $user_id: String!
    $product_name: String!
    $amount: Int!
    $delivery_price: Int!
    $item_list: [OrderItemListInput!]!
    $buyer_info: String!
    $order_type: String
    $extra_data: String
  ) {
    placeOrder(
      pgcode: $pgcode
      order_no: $order_no
      order_idx: $order_idx
      user_id: $user_id
      product_name: $product_name
      amount: $amount
      delivery_price: $delivery_price
      item_list: $item_list
      buyer_info: $buyer_info
      order_type: $order_type
      extra_data: $extra_data
    ) {
      success
      info
    }
  }
`;

export const MY_ORDER_LIST = gql`
  query MyOrderList($ym: String!) {
    MyOrderList(ym: $ym) {
      idx
      idate
      campus {
        campus {
          name
        }
      }
      order_payment {
        buyer_name
        product_name
        idate
        amount
        receipt_url
        delivery_status
        invoice_number
        buyer_postcode
        buyer_addr
        delivery_company_info {
          company_code
          company_name
        }
      }
      order_list {
        product_code
        product_name
        quantity
        sum_price
      }
    }
  }
`;

export const CHECK_ADDRESS_PTURN_FOR_ORDER = gql`
  query MyOrderListByDuration($start: String!, $end: String!) {
    MyOrderListByDuration(start: $start, end: $end) {
      order_payment {
        buyer_postcode
        buyer_addr
      }
    }
  }
`;
export const MY_ORDER_LIST_BY_DURATION = gql`
  query MyOrderListByDuration($start: String!, $end: String!) {
    MyOrderListByDuration(start: $start, end: $end) {
      idx
      idate
      campus {
        campus {
          name
        }
      }
      order_payment {
        buyer_name
        product_name
        idate
        amount
        receipt_url
        delivery_status
        invoice_number
        buyer_postcode
        buyer_addr
        delivery_company_info {
          company_code
          company_name
        }
      }
      order_list {
        product_code
        product_name
        quantity
        sum_price
      }
    }
  }
`;

export const MY_FC_ORDER_LIST = gql`
  query MyFCOrderList($ym: String!, $company_idx: Int!, $parent_idx: Int) {
    MyFCOrderList(ym: $ym, company_idx: $company_idx, parent_idx: $parent_idx) {
      idx
      idate
      order_payment {
        idx
        idate
        amount
        receipt_url
        pay_method
        delivery_price
        product_name
        buyer_name
        buyer_tel
        buyer_postcode
        delivery_comment
        buyer_addr
        pay_method
        delivery_status
        invoice_number
        delivery_company_info {
          company_code
          company_name
        }
      }
      campus {
        name
        type
        campus {
          name
        }
      }
      order_list {
        product_name
        product_code
        quantity
        sum_price
      }
      user {
        name
      }
    }
  }
`;

export const MY_FC_ORDER_LIST_BY_DURAION = gql`
  query MyFCOrderListByDuration($start: String!, $end: String!, $company_idx: Int!, $parent_idx: Int) {
    MyFCOrderListByDuration(start: $start, end: $end, company_idx: $company_idx, parent_idx: $parent_idx) {
      idx
      idate
      order_payment {
        idx
        idate
        amount
        receipt_url
        pay_method
        delivery_price
        product_name
        buyer_name
        buyer_tel
        buyer_postcode
        delivery_comment
        buyer_addr
        pay_method
        delivery_status
        invoice_number
        delivery_company_info {
          company_code
          company_name
        }
      }
      campus {
        name
        type
        campus {
          name
        }
      }
      order_list {
        product_name
        product_code
        quantity
        sum_price
      }
      user {
        name
      }
    }
  }
`;

export const MY_FC_ORDER_LIST_ALL = gql`
  query MyFCOrderListAll($company_idx: Int!, $parent_idx: Int, $page: Int, $take: Int, $search_type: String, $search_value: String) {
    MyFCOrderListAll(
      company_idx: $company_idx
      parent_idx: $parent_idx
      page: $page
      take: $take
      search_type: $search_type
      search_value: $search_value
    ) {
      total
      page
      take
      list {
        idx
        idate
        order_payment {
          idx
          idate
          amount
          receipt_url
          pay_method
          delivery_price
          product_name
          buyer_name
          buyer_tel
          buyer_postcode
          delivery_comment
          buyer_addr
          pay_method
          delivery_status
          invoice_number
          delivery_company_info {
            company_code
            company_name
          }
        }
        campus {
          name
          type
          campus {
            name
          }
        }
        order_list {
          product_name
          product_code
          quantity
          sum_price
        }
        user {
          name
        }
      }
    }
  }
`;
export const SHIPPING_ORDER_LIST = gql`
  query MyShippingList($company_idx: Int!) {
    MyShippingList(company_idx: $company_idx) {
      order_date
      payment_date
      pay_method
      sum_price
      delivery_status
      branch_name
      school_name
    }
  }
`;
