import React, { useState, useEffect,useMemo } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Body = styled(ColumnFlex)`
  width: 90%;
  padding: 0 30px;
  height: 80%;
  align-items: center;
`;
// const ImgButton = styled.div`
//   cursor: pointer;
//   width: 50px;
//   min-width: 50px;
//   height: 50px;
//   min-height: 50px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 35px;
//   background-color: #443ec8;
//   color: #fff;
//   font-size: 25px;
//   margin: 0 15px;
//   box-shadow: 0 2px 1px 2px #090989;
// `;
const QuestionBox = styled(RowFlex)`
  font-size:${(props) => (props.isLong ? '20px' : '25px')} ;
  color: #000000;
  align-items: center;
  /* justify-content: center; */
  padding: 0 40px;
  /* line-height: 80px; */
  height: 80px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #a1cec9;
  background-color: #daedeb;
  @media (max-height: 960px) {
    font-size:${(props) => (props.isLong ? '18px' : '23px')} ;
    /* line-height: 70px; */
    height: 70px;
    border-radius: 10px;
  }
`;
const ChoiceBox = styled(RowFlex)`
  padding: 15px 0  ;
  width: 100%;
`;
const ChoiceOption = styled(RowFlex)`
  color: #000000;
  background-color: ${(props) => (props.bgc ? props.bgc : '#eff5fb')};
  border: 1px solid #a09de2;
  border-left: none;
  border-radius: 30px;
  align-items: center;
  height: 60px;
  font-size:${(props) => (props.isLong ? '20px' : '23px')};
  width: 100%;
  /* line-height: 60px; */
  padding-left: 70px;
  cursor: pointer;
  @media (max-height: 960px) {
    font-size: ${(props) => (props.isLong ? '18px' : '21px')};
    /* line-height: 50px; */
    height: 50px;
    border-radius: 25px;
  }
`;
const ChoiceNumber = styled.div`
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  color: #453ec8;
  background: #fff;
  border: 2px solid #453ec8;
  border-radius: 30px;
  text-align: center;
  margin-right: -50px;
  z-index: 1;
  @media (max-height: 960px) {
    font-size: 25px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;

const ChoiceWrapper = styled(ColumnFlex)`
  padding: 20px;
  width: 100%;
`;
export default function LevelUpQuiz1({ checking,setChecking, currentQuiz, currentQuizOptions, questionNumber, handleTryCount }) {
  const [bgc, setBgc] = useState(new Array(currentQuizOptions.length).fill(null));
  const handleClick = (answer, i) => {
    if (!checking) {
      setChecking(true);
      const tmp = [...bgc];
      if (answer) {
        tmp[i] = '#53f292';
        setBgc(tmp);
      } else {
        tmp[i] = '#ff4568';
        setBgc(tmp);
      }
      handleTryCount(answer, i);
    } else {
      // alert('잠시 뒤에 시도하세요.');
      return;
    }
  };
  const optionLongText = useMemo(()=>{
    if(currentQuizOptions){
return currentQuizOptions.some((e)=>e.Text.length>=60)
    }
  },[currentQuizOptions])
  useEffect(() => {
    setBgc(new Array(currentQuizOptions.length).fill(null));
  }, [questionNumber, currentQuiz]);
  return (
    <>
      <Body>
        <RowFlex style={{ alignItems: 'center', width: '100%' }}>
          <QuestionBox isLong={currentQuiz?.question.length>100} dangerouslySetInnerHTML={{ __html: `<span>${currentQuiz?.question}</span>` }} />
        </RowFlex>
        <ChoiceWrapper>
          {currentQuizOptions?.map((e, i) => (
            <ChoiceBox onClick={() => handleClick(e?.Correct === 'True', i)}>
              <ChoiceNumber>{i + 1}</ChoiceNumber>
              <ChoiceOption isLong={optionLongText} bgc={bgc[i]}>{e.Text}</ChoiceOption>
            </ChoiceBox>
          ))}
        </ChoiceWrapper>
      </Body>
    </>
  );
}
