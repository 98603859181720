import React from 'react';
import { Button, Modal } from 'antd';

import Postcode from 'src/components/common/Postcode';

function PostcodeModal({ visible, handleCancel, setValues, form }) {
  return (
    <>
      <Modal
        className="postcode-class-modal"
        visible={visible}
        onCancel={handleCancel}
        width={`40%`}
        footer={[
          false,
          <Button
            key="back"
            className="ant-btn-primary"
            onClick={() => handleCancel()}
          >
            닫기
          </Button>,
        ]}
      >
        <Postcode
          setValues={setValues}
          form={form}
          handleCancel={handleCancel}
        />
      </Modal>
      1
    </>
  );
}

export default PostcodeModal;
