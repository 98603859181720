import React from 'react';

import LevelUpLayout from 'src/layouts/LevelUpLayout';
import DefaultLayout from 'src/layouts/DefaultLayout';
import ToolLayout from 'src/layouts/ToolLayout';
import BookrLayout from 'src/layouts/BookrLayout';
import AICoachLayout from 'src/layouts/AICoachLayout';

import DashBoard from 'src/pages/DashBoard';
import MyInfo from 'src/pages/MyPage/MyInfo';
import Test from 'src/pages/Test';
import TestDetails from 'src/pages/Test/TestDetails';
import Quiz from 'src/pages/Test/Quiz'
import LevelTest from 'src/pages/MyPage/LevelTest';
import StudyInfo from 'src/pages/MyPage/StudyInfo';
import Learning from 'src/pages/MyPage/Learning';
import OrderList from 'src/pages/MyPage/OrderList';
import Counseling from 'src/pages/MyPage/Counseling';
import PointList from 'src/pages/MyPage/PointList';
import ClassRoom from 'src/pages/ClassRoom';
import Folder from 'src/pages/Folder';
import IframePage from 'src/pages/Iframe';
import Tool from 'src/pages/Tool';
import PhonicsResultClassStudent from 'src/pages/PhonicsAssign/PhonicsResultClassStudent';
import AITopic from 'src/pages/AITopic';
import Ebook from 'src/pages/Ebook';
import Elibrary from 'src/pages/Elibrary';
import Recording from 'src/pages/Recording';
import RecordingStart from 'src/pages/Recording/RecordingStart';
import Board from 'src/pages/Board';
import BoardDetail from 'src/pages/Board/BoardDetail';
import Zoom from 'src/pages/Zoom';
import ExternalStudyHistory from 'src/pages/ExternalStudyHistory';
import ElibraryHistory from 'src/pages/ElibraryHistory';
import BookrHistory from 'src/pages/Bookr/History';
import ReportCard from 'src/pages/ReportCard';
import StudyResult from 'src/pages/StudyResult';

import StudentResults from 'src/pages/AiCoach/Result/Student'
import AITutorStudentResults from 'src/pages/AITalkingTutor/Result/Student'

import EngstaBookStudyPage from 'src/pages/EngSta/Library/Study';
import EngStaVocaCard from 'src/pages/EngSta/Voca/Flashcard';
import EngStaVocaMatch from 'src/pages/EngSta/Voca/Match';
import EngStaVocaType from 'src/pages/EngSta/Voca/Type';
import EngStaVocaQuiz from 'src/pages/EngSta/Voca/Quiz';
import EngStaVocaQuiz2 from 'src/pages/EngSta/Voca/Quiz2';
import EngStaVocaQuiz3 from 'src/pages/EngSta/Voca/Quiz3';
import EngStaSentenceCard from 'src/pages/EngSta/Sentence/Flashcard';
import EngStaSentenceRepeat from 'src/pages/EngSta/Sentence/Repeat';
import EngStaSentenceDnD from 'src/pages/EngSta/Sentence/DnD';
import EngStaPassageDictation from 'src/pages/EngSta/Sentence/Dictation';
import EngStaAIReport from 'src/pages/EngSta/AiReport'
import EngStaWPM from 'src/pages/EngSta/WPM'
import EngStaWPMResult from 'src/pages/EngSta/WPM/Result'
import CardMatchingGame from 'src/pages/EngSta/Game/CardMatch'
import CrossPuzzleGame from 'src/pages/EngSta/Game/CrossWord'
import AssignWritingResult from 'src/pages/LevelUp/Results/AssignWritingResult';
import AssignResult from 'src/pages/LevelUp/Results/AssignResult';
import Skills from 'src/pages/LevelUp/Results/Skills'
import AITalkingTutorLayout from 'src/layouts/AITalkingTutorLayout';

function Student() {
  return (
    <>
      <DefaultLayout path="/dashboard" component={DashBoard} />
      <DefaultLayout exact path="/classroom" component={ClassRoom} />
      <DefaultLayout exact path="/study-result/:class_idx" component={StudyResult} />
      <DefaultLayout exact path="/report-card" component={ReportCard} />
      <DefaultLayout exact path="/elibrary-history" component={ElibraryHistory} />
      <DefaultLayout exact path="/my-external-study" component={ExternalStudyHistory} />
      <DefaultLayout exact path="/my-bookr-study" component={BookrHistory} />
      <DefaultLayout exact path="/ebook/:rootbookcode" component={Ebook} />
      <BookrLayout exact path="/e-booklibrary" component={Elibrary} />
      <DefaultLayout exact path="/test/manage/:type" component={Test} />
      <DefaultLayout exact path="/test/new/:id/:type" component={TestDetails} />
      <DefaultLayout exact path="/daily/test/:group/:id/:type" component={Quiz} />
      <DefaultLayout exact path="/ai-topic/:type" component={AITopic} />
      <DefaultLayout exact path="/recording" component={Recording} />
      <DefaultLayout exact path="/recording/start" component={RecordingStart} />
      <DefaultLayout exact path="/zoom/manage/:type" component={Zoom} />
      <DefaultLayout exact path="/board/:name" component={Board} />
      <DefaultLayout exact path="/board/:name/:id" component={BoardDetail} />
      <DefaultLayout exact path="/i-frame/:url" component={IframePage} isHeaderStatic={true} />
      <DefaultLayout exact path="/mypage" component={MyInfo} />
      <DefaultLayout exact path="/mypage/levelTest" component={LevelTest} />
      <DefaultLayout exact path="/mypage/learning" component={Learning} />
      <DefaultLayout path="/mypage/studyInfo" component={StudyInfo} />
      <DefaultLayout path="/mypage/orders" component={OrderList} />
      <DefaultLayout path="/mypage/counseling" component={Counseling} />
      <DefaultLayout path="/mypage/points" component={PointList} />
      <ToolLayout exact path="/tool/:type/:code" component={Tool} />
      <DefaultLayout exact path="/mypage/results-class/:class_idx/:user_idx" component={PhonicsResultClassStudent} />
      <DefaultLayout exact path="/folder" component={Folder} />

      <LevelUpLayout exact path="/engsta/study/" removeMargin={true} component={EngstaBookStudyPage} />
      <LevelUpLayout exact path="/engsta/voca/card" removeMargin={true} component={EngStaVocaCard} />
      <LevelUpLayout exact path="/engsta/voca/match" removeMargin={true} component={EngStaVocaMatch} />
      <LevelUpLayout exact path="/engsta/voca/type" removeMargin={true} component={EngStaVocaType} />
      <LevelUpLayout exact path="/engsta/voca/quiz" removeMargin={true} component={EngStaVocaQuiz} />
      <LevelUpLayout exact path="/engsta/voca/quiz2" removeMargin={true} component={EngStaVocaQuiz2} />
      <LevelUpLayout exact path="/engsta/voca/quiz3" removeMargin={true} component={EngStaVocaQuiz3} />
      <LevelUpLayout exact path="/engsta/sentence/card" removeMargin={true} component={EngStaSentenceCard} />
      <LevelUpLayout exact path="/engsta/sentence/repeat" removeMargin={true} component={EngStaSentenceRepeat} />
      <LevelUpLayout exact path="/engsta/sentence/dnd" removeMargin={true} component={EngStaSentenceDnD} />
      <LevelUpLayout exact path="/engsta/sentence/dictation" removeMargin={true} component={EngStaPassageDictation} />
      <LevelUpLayout exact path="/engsta/aireport" removeMargin={true} isHeaderStatic={true} component={EngStaAIReport} />
      <LevelUpLayout exact path="/engsta/wpm" removeMargin={true} component={EngStaWPM} />
      <LevelUpLayout exact path="/engsta/wpm/result" removeMargin={true} component={EngStaWPMResult} />
      <LevelUpLayout exact path="/engsta/game/cardmatch" removeMargin={true} component={CardMatchingGame} />
      <LevelUpLayout exact path="/engsta/game/crosspuzzle" removeMargin={true} component={CrossPuzzleGame} />
      <LevelUpLayout exact path="/level-up/results/bookreport" component={AssignWritingResult} removeMargin={true} isHeaderStatic={false} />
      <LevelUpLayout exact path="/level-up/results/assignresult" component={AssignResult} removeMargin={true} isHeaderStatic={false} />
      <LevelUpLayout exact path="/level-up/result/skill" component={Skills} />

      <AICoachLayout exact path="/aicoach/result" isHeaderStatic={false} removeMargin={true} component={StudentResults} />
      <AITalkingTutorLayout exact path="/ai-talking-tutor/result" isHeaderStatic={false} removeMargin={true} component={AITutorStudentResults} />
      <DefaultLayout exact path="/" component={DashBoard} />
    </>
  );
}

export default Student;
