import React, { useRef, useMemo, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useReactToPrint } from 'react-to-print';
import { Modal, Typography, Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { PrinterTwoTone } from '@ant-design/icons';
import StatusCheckTable from './components/StatusCheckTable';
import FinalTestInputTable from './components/FinalTestInputTable';
import StudyCompleteTable from './components/StudyCompleteTable';
import BasicInfoTable from './components/BasicInfoTable';
import { GET_CLASSS_INFO_FOR_REPPORT } from 'src/operations/queries/getClass';
import html2canvas from 'html2canvas';

const { Title, Text } = Typography;
const PrintWrapper = styled.div`
  width: 100%;
  @media print {
    width: 100%;
    padding-left: 48px;
    padding-right: 48px;
    background-color: #fff;
    .report-one-page {
      page-break-after: always;
      padding-top: 20px;
    }
  }
`;

const ReportCardMultiViewModal = ({ visible, isPrint, isDownload, onCancel, dateRange, class_idx, reportList = [] }) => {
  const printAreaComponentRef = useRef();
  const downloadTargets = useRef([]);
  const handlePrint = useReactToPrint({
    content: () => printAreaComponentRef.current,
  });

  const handleDownloadImage = useCallback(async () => {
    if (!visible) return;
    if (downloadTargets && downloadTargets.current.length > 0) {
      const elements = downloadTargets.current;
      for (let i = 0; i < elements.length; i++) {
        const canvas = await html2canvas(elements[i]);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
          link.href = data;
          link.download = `reportimage${i}.jpg`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } else {
      alert('로딩중입니다.');
    }
  }, [downloadTargets, visible]);

  const { data } = useQuery(GET_CLASSS_INFO_FOR_REPPORT, { variables: { class_idx }, skip: !class_idx });
  const basicInfo = useMemo(() => {
    if (data) {
      return {
        class_name: data?.classInfo?.name,
        book_code: data?.classInfo?.book_code,
        book_title: data?.classInfo?.book?.book_sub[0]?.title,
        teacher_name: data?.classInfo?.class_teacher[0]?.user?.name,
        is_phonics: parseInt(data?.classInfo?.book?.level_no) > 0 ? false : true,
      };
    }
    return null;
  }, [data]);
  const handlePrintCallback = useCallback(() => {
    if (basicInfo && visible && handlePrint) {
      handlePrint();
    }
  }, [basicInfo, visible, handlePrint]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        handlePrintCallback();
      }, 1000 + reportList.length * 200);
    }
  }, [handlePrintCallback, reportList, isPrint]);

  useEffect(() => {
    if (isDownload) {
      setTimeout(() => {
        handleDownloadImage();
      }, 1000 + reportList.length * 200);
    }
  }, [handleDownloadImage, reportList, isDownload]);
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={'60%'}
      footer={null}
      title={
        <div style={{ textAlign: 'right', marginRight: '50px' }}>
          <Button onClick={handlePrint}>
            <PrinterTwoTone />
            Print
          </Button>
          &nbsp;
          <Button onClick={handleDownloadImage}>
            <PrinterTwoTone />
            Download
          </Button>
        </div>
      }
    >
      <PrintWrapper ref={printAreaComponentRef}>
        {reportList &&
          reportList.map((report, key) => (
            <div key={`report-list${report.idx}`} className="report-one-page" ref={(el) => (downloadTargets.current[key] = el)}>
              <BasicInfoTable user_name={report.user_name} dateRange={dateRange} basicInfo={basicInfo} />
              <Row gutter={[16, 16]} style={{ rowGap: '1px' }}>
                <StudyCompleteTable
                  bookCode={basicInfo?.book_code}
                  class_idx={class_idx}
                  user_idx={report.user_idx}
                  isPhonics={basicInfo?.is_phonics}
                />
                <Col span={24}>
                  <FinalTestInputTable
                    class_idx={class_idx}
                    user_idx={report.user_idx}
                    dateRange={dateRange}
                    onChangeScore={() => {}}
                    result={report?.score_result}
                  />
                </Col>
                <Col span={24}>
                  <Title level={4}>수업현황</Title>
                  <StatusCheckTable onChangeStatus={() => {}} data={report} />
                </Col>
                <Col span={24}>
                  <Title level={4}>Teacher's Comments</Title>
                  <Text style={{ border: '1px solid #a4d0e9', width: '100%', display: 'block', padding: 8 }}>{report?.comment_ko}</Text>
                </Col>
              </Row>
            </div>
          ))}
      </PrintWrapper>
    </Modal>
  );
};

export default ReportCardMultiViewModal;
