import React, { useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useReactToPrint } from 'react-to-print';
import { Modal, Typography, Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { PrinterTwoTone } from '@ant-design/icons';
import StatusCheckTable from './components/StatusCheckTable';
import FinalTestInputTable from './components/FinalTestInputTable';
import StudyCompleteTable from './components/StudyCompleteTable';
import BasicInfoTable from './components/BasicInfoTable';
import { GET_CLASSS_INFO_FOR_REPPORT } from 'src/operations/queries/getClass';

const PrintWrapper = styled.div`
  width: 100%;
  @media print {
    width: 100%;
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
    background-color: #fff;
  }
`;

const { Title, Text } = Typography;

const ReportCardViewModal = ({ class_idx, user_name, user_idx, dateRange, visible, onCancel, reportCardItem }) => {
  const printAreaComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printAreaComponentRef.current,
  });
  const { data } = useQuery(GET_CLASSS_INFO_FOR_REPPORT, { variables: { class_idx }, skip: !class_idx });
  const basicInfo = useMemo(() => {
    if (data) {
      return {
        campus_name: data?.classInfo?.campus?.name,
        class_name: data?.classInfo?.name,
        book_title: data?.classInfo?.book?.book_sub[0]?.title,
        teacher_name: data?.classInfo?.class_teacher[0]?.user?.name,
        is_phonics: parseInt(data?.classInfo?.book?.level_no) > 0 ? false : true,
      };
    }
    return null;
  }, [data]);
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={'60%'}
      footer={null}
      title={
        <div style={{ textAlign: 'right', marginRight: '50px' }}>
          <Button onClick={handlePrint}>
            <PrinterTwoTone />
            Print
          </Button>
        </div>
      }
    >
      <PrintWrapper ref={printAreaComponentRef}>
        <BasicInfoTable user_name={user_name} dateRange={dateRange} basicInfo={basicInfo} />
        <Row gutter={[16, 16]} style={{ rowGap: '5px' }}>
          <StudyCompleteTable
            bookCode={data?.classInfo?.book_code}
            class_idx={class_idx}
            user_idx={user_idx}
            isPhonics={basicInfo?.is_phonics}
          />
          <Col span={24}>
            <FinalTestInputTable
              class_idx={class_idx}
              user_idx={user_idx}
              dateRange={dateRange}
              onChangeScore={() => {}}
              result={reportCardItem?.score_result}
            />
          </Col>
          <Col span={24}>
            <Title level={4}>수업현황</Title>
            <StatusCheckTable onChangeStatus={() => {}} data={reportCardItem} />
          </Col>
          <Col span={24}>
            <Title level={4}>Teacher's Comments</Title>
            <Text style={{ border: '1px solid #a4d0e9', width: '100%', display: 'block', padding: 8 }}>{reportCardItem?.comment_ko}</Text>
          </Col>
        </Row>
      </PrintWrapper>
    </Modal>
  );
};

export default ReportCardViewModal;
