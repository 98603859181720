import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { userInfoVar } from 'src/apollo/cache';
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${(props) => props.bg && props.bg};
  color:${(props) => props.bg && '#fff'};
  border: 1px solid #fff;
  `;
const list = [
  { title: 'Assign', currentMenu: 'assign', url: '/star-words/lessonlist' },
  {
    title: 'Assigned Group List',
    currentMenu: 'grouplist',
    url: '/star-words/assigned-grouplist',
  },
  {
    title: 'Assigned List & Results',
    currentMenu: 'list',
    url: '/star-words/assignedlist',
  },
  { title: 'Total Assigned Result', currentMenu: 'readers', url: '/star-words/results' },
  { title: 'Individual Results', currentMenu: 'extreaders', url: '/star-words/results/extreaders' },
  // { title: 'Book Report', currentMenu: 'report', url: '/level-up/results' },
];
const StarWordsMenuGroup = ({ currentMenu }) => {
  const company = userInfoVar()?.company_name;
  const history = useHistory();
  
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
      {company!=='gnb'&&list.map((e) => {
        return (
          <>
            <MenuButton
              key={e.currentMenu}
              bg={currentMenu === e.currentMenu && '#1890ff'}
              onClick={() => {
                e.url && history.push(e.url);
              }}
            >
              {e.title}
            </MenuButton>
            &nbsp;
          </>
        );
      })}
    </div>
  );
};

export default StarWordsMenuGroup;
