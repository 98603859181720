import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Typography, Col, Row, DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { settings } from 'src/dummy/settings';

const { RangePicker } = DatePicker;
const ItemTitle = styled.span`
  font-weight: bold;
`;

const itemStyle = {
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 10,
  fontSize: '1.2em',
  fontWeight: 'bold',
};

const BasicInfoTableLucid = ({ user_name, dateRange, setDateRange, basicInfo, editable = false }) => {
  const companyName = useSelector(classStoreData);

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
      <Col span={16}>
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5, fontSize: '1.7em', fontWeight: 'bold' }}>
            LUCID ENGLISH
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ paddingLeft: 10, paddingTop: 5, paddingBottom: 5, fontSize: '1.5em', fontWeight: 'bold' }}>
            <span style={{ color: '#9b0000' }}>{`[${basicInfo?.campus_name}]`}</span> CAMPUS
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ rowGap: 0 }}>
          <Col span={8} style={{ ...itemStyle }}>
            <ItemTitle>● Name</ItemTitle>
          </Col>
          <Col span={16} style={{ ...itemStyle }}>
            : {user_name}
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ rowGap: 0 }}>
          <Col span={8} style={{ ...itemStyle }}>
            <ItemTitle>● Class</ItemTitle>
          </Col>
          <Col span={16} style={{ ...itemStyle }}>
            : {basicInfo?.class_name}
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ rowGap: 0 }}>
          <Col span={8} style={{ ...itemStyle }}>
            <ItemTitle>● Period</ItemTitle>
          </Col>
          <Col span={16} style={{ ...itemStyle }}>
            :{' '}
            {editable ? (
              <RangePicker
                defaultValue={[moment(dateRange[0], 'YYYY-MM-DD'), moment(dateRange[1], 'YYYY-MM-DD')]}
                format="YYYY-MM-DD"
                onChange={setDateRange}
              />
            ) : (
              <span>{dateRange && `${moment(dateRange[0]).format('yyyy-MM-DD')}~${moment(dateRange[1]).format('yyyy-MM-DD')}`}</span>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <img src={settings[companyName]?.logo} style={{ width: '80%' }} alt="logo" />
      </Col>
    </Row>
  );
};

export default BasicInfoTableLucid;
