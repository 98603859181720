import React from "react";
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const CrossWordsSpellingBox = ({item, x, y, active, onClicked, clickedLength, maxClickLength}) => {
	const handleClick = () => {
		if (!active && clickedLength >= maxClickLength) {
			return;
		}
		onClicked(x, y, !active);
	};
	return (
		// <li
		// 	className={`${active ? "active":""} ${item.isAnswer ? "answer":""}`}
		// 	onClick={handleClick}>{item.alphabet}
		// </li>

		<WordLi>
			<WordBtn type="button" active={active} answer={item.isAnswer} className={`${item.isAnswer ? "answer" : ""} ${active ? "active" : ""} `} onClick={handleClick}>
				{item.alphabet}
			</WordBtn>
		</WordLi>
	);
};

export default CrossWordsSpellingBox;

const WordLi = styled.li`
width: 100%;
height: 100%;
margin: 0;
padding: 0;
`;
const WordBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 2.4rem;
    background-color: ${props=>props.answer?"#54a0ff":props.active?'#ffeed8':'transparent'} ;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
	outline: none;
	border: none;
    border: 1px solid #e6e6e6;
	color: ${props=>props.answer&&"#fff"};
`;