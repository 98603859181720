import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import Tickets from 'src/pages/Tickets';
import CustomTable from 'src/components/common/CustomTable';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_CAMPUS_CREDIT_USED_STATUS_LIST } from 'src/operations/queries/bookCredit';

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    align: 'center',
    key: 'no',
  },
  {
    title: '교재',
    dataIndex: 'book_code',
    align: 'center',
    key: 'book_code',
  },
  {
    title: '이용권갯수',
    dataIndex: 'total',
    align: 'center',
    key: 'total',
  },
  {
    title: '부여완료',
    dataIndex: 'used',
    align: 'center',
    key: 'used',
  },
  {
    title: '잔여',
    dataIndex: 'unused',
    align: 'center',
    key: 'unused',
  },
];

function TicketStatus() {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data, loading } = useQuery(GET_CAMPUS_CREDIT_USED_STATUS_LIST, {
    skip: !dataUser,
    variables: { campus_idx: dataUser?.getUserData?.campus_idx },
  });
  const list = useMemo(() => {
    if (data) {
      const data2 = data.getCampusBookCreditUsedStatusList;
      return data2.map((item, key) => ({
        key: `status-list-${key}`,
        no: key + 1,
        book_code: item.book_code,
        total: item.total,
        used: item.total - item.unused,
        unused: item.unused,
      }));
    }
    return [];
  }, [data]);
  return (
    <Tickets>
      <Row>
        <Col span={24}>
          <MyInfoWrapper className="ticketStatus-tabtwo">
            <CustomTable
              className="tickettable"
              dataSource={list}
              columns={columns}
              pagination={{ hideOnSinglePage: true }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading}
            />
          </MyInfoWrapper>
        </Col>
      </Row>
    </Tickets>
  );
}

export default TicketStatus;
