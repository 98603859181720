import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import BookSettingModal from 'src/components/Modal/BookSettingModal';
import CustomTable from 'src/components/common/CustomTable';
import BookModal from 'src/components/Modal/BookModal';
import BookEditModal from 'src/components/Modal/BookEditModal';
import BookDeleteModal from 'src/components/Modal/BookDeleteModal';
import BookDetail from './BookDetail';
import { HalfWrapper, HeaderTitle } from 'src/components/common/Styles';
import { Button, Col, Row, Typography, Input } from 'antd';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';

const { Title } = Typography;

function ContentManagement() {
  const [bookList, setBookList] = useState([]);
  const [selectBook, setSelectBook] = useState(null);
  const [rowSelect, setRowSelect] = useState(0);
  const {
    visible: BookSettingVisible,
    showModal: BookSettingShowModal,
    handleCancel: handleBookSettingCancel,
    popupMode: BookSettingPopupMode,
  } = useModal();
  const { visible: BookVisible, showModal: BookShowModal, handleCancel: handleBookCancel, popupMode: BookPopupMode } = useModal();
  const {
    visible: BookEditVisible,
    showModal: BookEditShowModal,
    handleCancel: handleBookEditCancel,
    popupMode: BookEditPopupMode,
  } = useModal();
  const {
    visible: BookDeleteVisible,
    showModal: BookDeleteShowModal,
    handleCancel: handleBookDeleteCancel,
    popupMode: BookDeletePopupMode,
  } = useModal();
  const [search, { data: fetchBookSeries, loading: fetchBookDataLoading }] = useLazyQuery(queries.book.GET_BOOK_SERIES);
  const [searchText, setSearchText] = useState('');

  const handleSearch = () => {
    setSelectBook(null);
    const variables = { search_text: searchText.trim() };
    search({ variables, fetchPolicy: 'cache-first' });
  };

  const handleTableRowClick = ({ key }) => {
    setRowSelect(key);
    setSelectBook(fetchBookSeries.bookSeries.filter((book) => book.book_sub[0].idx === key)[0]);
  };

  useMemo(() => {
    if (fetchBookSeries?.bookSeries?.length) {
      setBookList(
        fetchBookSeries.bookSeries.reduce((acc, cur, index) => {
          const obj = {
            key: cur.book_sub[0].idx,
            no: index + 1,
            code: cur.code,
            stat: cur.stat,
            short_title: cur.book_sub[0].short_title,
            title: cur.book_sub[0].title,
            category: cur.book_sub[0].category,
            price: cur.book_sub[0].price,
            cover_path: cur.book_sub[0].cover_path,
            number_of_levels: cur.book_sub[0].number_of_levels,
            number_of_volumes: cur.book_sub[0].number_of_volumes,
            number_of_lessons: cur.book_sub[0].number_of_lessons,
            shopping_mall_product: cur.book_sub[0].shopping_mall_product,
            credit_system: cur.book_sub[0].credit_system,
            credit_duration: cur.book_sub[0].credit_duration,
          };

          return [...acc, obj];
        }, []),
      );
      if (!selectBook) {
        setSelectBook(fetchBookSeries.bookSeries[0]);
        handleTableRowClick({
          key: fetchBookSeries.bookSeries[0].book_sub[0].idx,
        });
      }
    } else {
      setBookList([]);
    }
  }, [fetchBookSeries]);

  useEffect(() => {
    handleSearch();
  }, []);

  const setRowClassName = (record) => {
    return record.key === rowSelect ? 'clickRowStyle' : '';
  };

  return (
    <>
      <HeaderTitle level={4}>컨텐츠 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <HalfWrapper>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>컨텐츠</Title>
              </Col>
              <Col>
                <Input.Search
                  placeholder="검색어 추가"
                  value={searchText}
                  loading={fetchBookDataLoading}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  enterButton="검색"
                  size="medium"
                  onSearch={handleSearch}
                />
              </Col>
              <Col>
                <Button type="primary" onClick={BookShowModal}>
                  + 컨텐츠 추가
                </Button>
              </Col>
            </Row>
            <CustomTable
              dataSource={bookList}
              pagination={{ pageSize: 5 }}
              columns={BookTable}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              color="#edf3fb"
              loading={fetchBookDataLoading}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleTableRowClick(record);
                  }, // click row
                };
              }}
              rowClassName={setRowClassName}
            />
          </HalfWrapper>
        </Col>
        <Col span={8}>
          <HalfWrapper>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>컨텐츠 상세</Title>
              </Col>
              <Col>
                {selectBook && (
                  <Button type="primary" onClick={BookEditShowModal}>
                    컨텐츠 수정
                  </Button>
                )}
              </Col>
              <Col>
                {selectBook && (selectBook?.book_sub[0]?.book_category?.name !== "package") && (
                  <Button type="primary" onClick={BookSettingShowModal}>
                    + 설정
                  </Button>
                )}
              </Col>
              <Col>
                {selectBook && (
                  <Button type="primary" danger onClick={BookDeleteShowModal}>
                    컨텐츠 삭제
                  </Button>
                )}
              </Col>
            </Row>
            {selectBook && <BookDetail bookInfo={selectBook} />}
          </HalfWrapper>
        </Col>
      </Row>

      {BookVisible && <BookModal visible={BookVisible} handleCancel={handleBookCancel} popupMode={'create'} />}
      {BookEditVisible && (
        <BookEditModal
          visible={BookEditVisible}
          handleCancel={handleBookEditCancel}
          popupMode={'create'}
          stat={selectBook.stat}
          bookInfo={selectBook.book_sub[0]}
          BookEditShowModal={BookEditShowModal}
          setSelectBook={setSelectBook}
        />
      )}
      {BookDeleteVisible && (
        <BookDeleteModal
          visible={BookDeleteVisible}
          handleCancel={handleBookDeleteCancel}
          popupMode={'create'}
          bookInfo={selectBook.book_sub[0]}
          BookDeleteShowModal={BookDeleteShowModal}
        />
      )}
      {BookSettingVisible && (
        <BookSettingModal
          visible={BookSettingVisible}
          handleCancel={handleBookSettingCancel}
          popupMode={BookSettingPopupMode}
          bookInfo={selectBook.book_sub[0]}
        />
      )}
    </>
  );
}

const BookTable = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: '5%',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '코드',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
  },
  {
    title: '가격',
    dataIndex: 'price',
    key: 'price',
    align: 'center',
  },
];

export default ContentManagement;
