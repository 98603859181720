import React, { useMemo } from 'react';
import { Radio } from 'antd';

const VolumeGroup = ({ levelItem, bookData, volumeNumber, onVolumeChange }) => {
  const volumes = useMemo(() => {
    if (levelItem && bookData) {
      return bookData
        .filter((book) => book.level_no === levelItem.no)
        .map((book2) => ({
          no: book2.volume_no,
          name: book2.book_sub[0] ? book2.book_sub[0]?.short_title || book2.book_sub[0]?.title : book2.volume_name,
        }));
    }
    return [];
  }, [levelItem, bookData]);
  return (
    <Radio.Group value={volumeNumber} style={{ marginBottom: 16 }} onChange={onVolumeChange}>
      {volumes &&
        volumes.map((volume, key) => (
          <Radio.Button key={`radio-vol-key${key}`} value={volume.no}>
            {volume.name}
          </Radio.Button>
        ))}
    </Radio.Group>
  );
};

export default VolumeGroup;
