import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Input, Modal, Select, Button as AButton, Form, Button, Divider, Layout, Progress } from 'antd';
import { AI_TALKING_TUTOR_COMPLETION } from 'src/operations/queries/message';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import {
  OPEN_AI_MESSAGE,
  CREATE_RUNS,
  MESSAGE_LIST,
  GET_STEPS,
  GET_MESSAGE,
  TRANSLATE_TEXT,
  CREATE_THREAD,
  UPDATE_THREAD_ID,
  RETRIEVE_RUN,
  SUBMIT_TOOL_OUTPUT,
} from 'src/operations/mutations/aiGroupTopic';
import { AI_USER_REQUEST_RESPONSE_LIST, AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import moment from 'moment';
import { openNotification } from '../../../components/common/Notification';
import InfiniteScroll from 'react-infinite-scroll-component';
import MessageItem from 'src/components/DashBoard/SubPage/MessageItem';
import MessageBox from 'src/components/DashBoard/SubPage/MessageBox';
import { SendOutlined } from '@ant-design/icons';
import { captureUserMedia } from 'src/utils';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import * as axios from 'axios';
import ReactPlayer from 'react-player';
import SoundEffect from 'src/components/common/SoundEffect';
import * as dummy from 'src/dummy';
import { useTextToSpeach } from 'src/utils/hooks';
const clickSound = '/audio/LevelUp/button_click.mp3';
const recordingStartSound = '/audio/LevelUp/Game_Wrong.mp3';

const { TextArea } = Input;
const { Option } = Select;
const { Sider } = Layout;

const parseMessage = (content) => {
  let value = '';
  let valueObj = {};
  if (content.length > 0) {
    for (let i = 0; i < content.length; i++) {
      if (content[i]?.text?.value.replace(/(\r\n|\n|\r)/gm, '').startsWith('{')) {
        let temp = JSON.parse(content[i]?.text?.value);
        for (let key in temp) {
          if (
            key.endsWith('image_url') ||
            key.endsWith('audio_url') ||
            key.startsWith('image_url') ||
            key.startsWith('audio_url') ||
            key.startsWith('image_') ||
            key.startsWith('audio_')
          ) {
            valueObj[key] = temp[key];
          } else if (key in valueObj) {
            valueObj[key] = valueObj[key] === temp[key] ? valueObj[key] : valueObj[key] + '\n' + temp[key];
          } else {
            valueObj[key] = temp[key];
          }
        }
      } else {
        value += content[i]?.text?.value;
      }
    }
  }

  return value === '' ? valueObj : value;
};

const getTextValue = (content) => {
  let text = typeof content === 'string' ? content : '';
  console.log('content', content);

  if (content?.greeting) {
    text = `${content?.greeting}\n${text}`;
  }

  if (content?.response_feedback) {
    text = `${content?.response_feedback}\n${text}`;
  }

  if (content?.feedback) {
    text = `${content?.feedback}\n${text}`;
  }

  if (content?.explanation) {
    text = `${content?.explanation}\n${text}`;
  }

  if (content?.details) {
    text = `${content?.details}\n${text}`;
  }

  if (content?.response) {
    text = `${content?.response}\n${text}`;
  }

  if (content?.question) {
    text = `${text}\n${content?.question}`;
  }

  if (content?.next_question) {
    text = `${text}\n${content?.next_question}`;
  }

  if (content?.questions) {
    text = content?.questions;
  }

  if (content?.answer) {
    text = content?.answer;
  }

  if (content?.prompt) {
    text = content?.prompt;
  }

  if (content?.chat) {
    text = content?.chat;
  }

  if (content?.debate) {
    text = content?.debate;
  }

  if (content?.message) {
    text = content?.message;
  }

  if (content?.ask) {
    text = `${text}\n${content?.ask}`;
  }

  if (content?.end_note) {
    text = `${text}\n${content?.end_note}`;
  }

  if (content?.note) {
    text = `${text}\n${content?.note}`;
  }

  if (content?.final_message) {
    text = `${text}\n${content?.final_message}`;
  }

  return text;
};

const mapContentData = (contentText, data) => {
  if (typeof contentText === 'string') {
    return [{ ...data, content: contentText }];
  }
  if (typeof contentText === 'object') {
    let result = [];
    let keys = Object.keys(contentText);
    let text = '';
    for (let i = 0; i < keys.length; i++) {
      if (typeof contentText[keys[i]] === 'object') {
        continue;
      }
      if (
        keys[i].endsWith('image_url') ||
        keys[i].endsWith('audio_url') ||
        keys[i].startsWith('image_url') ||
        keys[i].startsWith('audio_url') ||
        keys[i].startsWith('image_') ||
        keys[i].startsWith('audio_')
      ) {
        result.push({
          ...data,
          content: text,
          urls:
            keys[i].endsWith('image_url') || keys[i].startsWith('image_url') || keys[i].startsWith('image_')
              ? [{ type: 'image', url: contentText[keys[i]] }]
              : [{ type: 'audio', url: contentText[keys[i]] }],
        });
        text = '';
        continue;
      }
      text = text + '\n' + contentText[keys[i]];
    }
    if (text !== '') {
      result.push({ ...data, content: text });
    }
    return result;
  }
};

const TO_LESSON = `Repeat last thing you said again.`;
const PAUSE = `Let's stop the conversation. I need to take a break and we will continue later.`;
const STOP = `Stop the conversation.`;
const RESTART = `Understand the previous conversation and start from where we left off.`;
const REPEAT = `Can we go back to beginning of the conversation?, and ask me if I am ready to start.
If I say 'yes' then ask me the first question about our main topic.
If I say 'no' then ask me a few questions about the story one at a time that you have not ask me before.`;
// const EASY = `Say it again what you just said with easier words for first graders who do npt speak english well to undersatand.
// Don't make it too long and do not be complex and difficult. `;
const EASY = `Can you say the part right before the next question again with easier words?
I did not understand and before I go to the next question I would like to understand again.
And do not just give me the answer to the question and start asking next question.
Just say it again in easier language for 7 years old who doesn't speak English well. 
Don't make it too long and do not use difficult words.
Instead of giving me the answer, ask me again easier way.`;
// const TRANSLATE = `Can you say what you just said in Korean and afterwards go back to English mode?`;
// const TRANSLATE = `Can you say what you had responded to me just now  in Korean and afterwards go back to English mode? and
// Don't translate what I said`;
const TRANSLATE = `Can you say what you had responded to me just now in Korean and the question if you just asked, afterwards go back to English mode? and Don't translate what I said`;
const SHORT = `Can say what you said in short, concise, easy to understand way?`;
const ASK = `Translate this into English and go back and ask the last question again.`;
const LESSON = `please go back to original topic which was asked in the beginning of the conversation.
Ask if the student has any questions about the original topic.
If answer is yes then answer the question,  if no then ask the student if you want AI to ask a question about the topic.`;
const REVIEWSAVE = `Can you summarize our conversation? and 
tell what was the main point about our conversation. give
your opinion about how well our conversation in English was.

Give me a score of 1 through 10 of our English conversation in terms of English communication skill.

Check our conversation dialogue to see if I made any grammatical errors or wrong expression in English or wrong answers; and correct it, 
give me explanation for me one by one in a format of
+ Original :  ,
- Corrections : ,
* Explanation :
---------------
At the end give me an over all comment to encourage me learning English.
After that translate in Korean in same format.`;

export default function AiEssayCorrection(
  {
    // idx,
    // topicData,
    // status,
    // // thraedId,
    // assistantIdx,
    // className
  },
) {
  const { idx } = useParams();

  const {
    data: dataHistory,
    loading: loadingHistory,
    error: errorHistory,
  } = useQuery(AI_TOPIC_USER_ASSIGNMENT_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      idx: parseInt(idx),
    },
  });
  const [openAiMessage, { data: openAiMsg, loading: openAiMsgLoading }] = useMutation(OPEN_AI_MESSAGE);
  const [createRun, { data: runData, loading: runLoading }] = useMutation(CREATE_RUNS);
  const [getSteps, { data: stepsData, loading: stepLoading }] = useMutation(GET_STEPS);
  const [retrieveRun, { data: retrieveRunData, loading: retrieveRunLoading }] = useMutation(RETRIEVE_RUN);
  const [submitToolOutput, { data: submitToolData, loading: submitToolLoading }] = useMutation(SUBMIT_TOOL_OUTPUT);
  const [messageList, { data: msgList, loading: msgListLoading }] = useMutation(MESSAGE_LIST);
  const [getMsg, { data: msgData, loading: msgLoading }] = useMutation(GET_MESSAGE);
  const [updateAiRequestResponse, { data: completeAi, loading: aiComplete }] = useLazyQuery(AI_TALKING_TUTOR_COMPLETION);
  const [soundPlay, setSoundPlay] = useState(false);
  const [aiUserRequestResponseList, { data: dataRRHistory, loading: loadingRRHistory, error: errorRRHistory }] =
    useLazyQuery(AI_USER_REQUEST_RESPONSE_LIST);
  const [translateText, { data: translateData }] = useMutation(TRANSLATE_TEXT);

  const [updateThread, { loading: updateThreadLoding }] = useMutation(UPDATE_THREAD_ID);
  // const [messagesIds, setMessagesIds] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [promptActive, setPromptActive] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const [activeAnswer, setActiveAnswer] = useState(false);
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const [answer, setAnswer] = useState('');
  const [startTime, setStartTime] = useState(moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'));
  const [userTopicAssignmetStatus, setUserTopicAssignmetStatus] = useState(0);
  const [micBtnClick, setMicBtnClick] = useState(0);
  const [formControl] = Form.useForm();
  const [askForm] = Form.useForm();
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [runIdx, setRunIdx] = useState('');
  const [msgIdx, setMsgIdx] = useState('');
  const [percent, setPercent] = useState(0);
  const [currentUrl, setCurrentUrl] = useState('');
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [lastBlob, setLastBlob] = useState(null);
  const [active, setActive] = useState(false);
  const [speed, setSpeed] = useState(1.0);
  const [voice, setVoice] = useState('shimmer');
  const [lastMsg, setLastMsg] = useState('');
  const [changeIcon, setChangeIcon] = useState(false);
  const [disableBtnVal, setDisableBtnVal] = useState(false);
  const [sound, setSound] = useState(clickSound);
  const [disableBtn, setDisableBtn] = useState(false);
  const [micBtnChange, setMicBtnChange] = useState(false);
  const [showBtn, setShowBtn] = useState({ guideBtn: false, askBtn: false, closeBtn: false, clearBtn: false });
  const [pauseStop, setPauseStop] = useState({ pause: false, stop: false });
  const [lastUserMsg, setLastUserMsg] = useState('');
  const [reviewSave, setReviewSave] = useState(false);
  const [micValue, setMicValue] = useState('mice_1.png');
  const [playBtn, setPlayBtn] = useState('play-btn.svg');
  const [translateMic, setTranslateMic] = useState('transalte_mic.png');
  const [startRecording, setStartRecording] = useState(false);
  const [stopInterval, setStopInterval] = useState(0);
  const [thraedId, setThreadId] = useState('');
  const [assistantIdx, setAssistantIdx] = useState('');
  const [groupIdx, setGroupIdx] = useState('');
  const [topicIdx, setTopicIdx] = useState('');
  const [topicData, setTopicData] = useState(null);
  const [getMsgList, setMsgList] = useState(false);
  const [openPopup, setOpenPopup] = useState(true);
  const recordAudio = useRef(null);
  const recordAskAudio = useRef(null);
  const recorderStreams = useRef(null);
  const messageBoxRef = useRef(null);
  const [loadMoreMsg, setLoadMoreMsg] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [lastMsgId, setLastMsgId] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [imageAudio, setImageAudio] = useState({ image: false, audio: false });
  const autoSendRef = useRef([]);
  const history = useHistory();
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const [gptModel, setGptModel] = useState('gpt-4o');
  const [audioPaths, setAudioPaths] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  const seconds = totalSeconds % 60;
  const { isSpeaking, texts, speak, stop } = useTextToSpeach(speed, voice);

  const [createThread, { loading: assistantsLoading }] = useMutation(CREATE_THREAD, {
    onCompleted(data) {
      if (data) {
        if (data?.createThread?.id) {
          setThreadId(data?.createThread?.id);
          updateThread({
            variables: {
              idx: parseInt(idx),
              thread_id: data?.createThread?.id,
            },
          });
        }
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const [updateAITopicUserAssignment] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
    onCompleted(data) {
      if (data) {
        if (data?.updateAITopicUserAssignment?.status !== null) {
          setUserTopicAssignmetStatus(data?.updateAITopicUserAssignment?.status);
          if (data?.updateAITopicUserAssignment?.status === 2) {
            openNotification('답변이 저장되었습니다!');
          }
        }
      }
    },
  });
  const onDataAvailableCallback = useCallback(async (blob) => {
    setCurrentRecLenght((prev) => prev + 100);
  }, []);

  useEffect(() => {
    async function createThreadAsync() {
      await createThread({
        variables: {
          idx: parseInt(idx),
        },
      });
    }

    createThreadAsync();
  }, []);

  useEffect(() => {
    if (dataHistory?.aiTopicUserAssignmentDetails) {
      setTopicData({
        ...dataHistory?.aiTopicUserAssignmentDetails?.ai_topic,
        data: JSON.parse(
          dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.data ||
            dataHistory?.aiTopicUserAssignmentDetails?.ai_topic_date_assignment?.data,
        ),
      });
      setUserTopicAssignmetStatus(dataHistory?.aiTopicUserAssignmentDetails.status);
      setUserTopicAssignmetStatus(dataHistory?.aiTopicUserAssignmentDetails.status);
      setAnswer(dataHistory?.aiTopicUserAssignmentDetails?.answer);

      if (dataHistory?.aiTopicUserAssignmentDetails?.status === 2) {
        history.push('/');
      }
      if (dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.data) {
        let imageFileList = JSON.parse(dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.image_file_list || '[]');
        let audioFileList = JSON.parse(dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.audio_file_list || '[]');
        if (imageFileList.length) {
          setImageAudio((prevState) => ({
            ...prevState,
            image: true,
          }));
          setImagePaths(imageFileList);
        }
        if (audioFileList.length) {
          setImageAudio((prevState) => ({
            ...prevState,
            audio: true,
          }));
          setAudioPaths(audioFileList);
        }
        // const data = JSON.parse(
        //   dataHistory?.aiTopicUserAssignmentDetails?.ai_topic_date_assignment?.data ||
        //   dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.data,
        // );
      }
      if (dataHistory?.aiTopicUserAssignmentDetails?.ai_topic) {
        const groupDetail = JSON.parse(dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.group_detail);
        const assistants_data = JSON.parse(groupDetail?.assistants_data || '{}');
        setGptModel(assistants_data?.model);

        setAssistantIdx(groupDetail?.assistants_id);
        setTopicIdx(dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.idx);
        setGroupIdx(groupDetail?.idx);
      }
    }
  }, [dataHistory]);

  const processRecordCallback = useCallback(async (blob, val, cb) => {
    let headers;
    if (val === 'ask') {
      headers = { 'x-spoken-language': 'ko', 'Content-Type': 'audio/webm' };
    } else {
      headers = { 'Content-Type': 'audio/webm' };
    }
    try {
      if (blob !== undefined) {
        const res = await axios('https://a2vdkjqiwop2mrkfprrzmp7yqq0xdicc.lambda-url.ap-northeast-2.on.aws/', {
          method: 'POST',
          data: blob,
          headers,
        });
        if (val === 'ask') {
          askForm.setFieldValue('korean_text', res.data.text);
          askForm.submit();
        } else {
          if (val === STOP) {
            // setDisableBtn(false)
            setDisableBtnVal(true);
          } else {
            // setDisableBtn(true)
            setDisableBtnVal(false);
          }
          if (
            val === PAUSE ||
            val === STOP ||
            val === TO_LESSON ||
            val === REPEAT ||
            val === EASY ||
            val === TRANSLATE ||
            val === SHORT ||
            val === ASK ||
            val === LESSON ||
            val === REVIEWSAVE ||
            val === 'ask-stop-recording' ||
            val === 'clear' ||
            val === 'send-text'
          ) {
          } else {
            if (
              res?.data?.text === '' ||
              res?.data?.text.toLowerCase().startsWith('thank you for watching') ||
              res?.data?.text.toLowerCase().startsWith('thanks for watching')
            ) {
              speak('I am sorry, I did not understand what you said. Can you repeat that?', () => {
                setMicValue('robot.png');
              });
            } else {
              setCurrentText(res.data.text);
              if (cb) cb(res.data.text);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }, []);

  useEffect(() => {
    if (messageBoxRef.current && micValue !== 'mice_1.png') {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
    if (reviewSave) {
      if (percent == 100) {
        setReviewSave(false);
      }
    }
    if (chatList.length) {
      setLastMsg(chatList[chatList.length - 1]?.content);
    }
  }, [chatList, percent]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTotalSeconds((prevTotalSeconds) => prevTotalSeconds + 1);
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prevPercent) => {
        if (prevPercent < 100) {
          return prevPercent + 5;
        } else {
          clearInterval(interval);
          return prevPercent;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [percent]);

  useEffect(() => {
    if (startRecording) {
      const startTime = Date.now();
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const percentElapsed = (elapsedTime / 30000) * 100;
        if (percentElapsed <= 100) {
          setStopInterval(percentElapsed);
        } else {
          clearInterval(interval);
          setStopInterval(100);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startRecording]);
  useEffect(() => {
    if (startRecording && stopInterval == 100) {
      setPauseStop((prevState) => ({
        ...prevState,
        stop: true,
      }));
      setStartRecording(false);
      stopRecording(STOP);
      setMicBtnClick(0);
      setActive(false);
      setMicBtnChange(true);
      setMicValue('mice_1.png');
    }
  }, [stopInterval]);

  useEffect(() => {
    if (reviewSave && percent == 100) {
      setMicValue('mice_1.png');
      // if (recordAudio.current) {
      //     stopRecording();
      // }
      if (percent === 100) {
        history.goBack();
      }
    }
  }, [reviewSave, percent]);
  useEffect(() => {
    let interval;
    if (
      retrieveRunData?.retrieveRun?.status !== 'completed' &&
      retrieveRunData?.retrieveRun?.status !== 'requires_action' &&
      runIdx !== undefined &&
      runIdx !== null &&
      runIdx !== ''
    ) {
      interval = setInterval(() => {
        retrieveRun({
          variables: {
            thread_id: thraedId,
            run_id: runIdx,
            group_id: parseInt(groupIdx),
            topic_id: topicIdx,
          },
        });
      }, 1500);
    }

    if (retrieveRunData?.retrieveRun?.status === 'requires_action') {
      if (
        retrieveRunData?.retrieveRun?.required_action?.type === 'submit_tool_outputs' &&
        retrieveRunData?.retrieveRun?.output?.length > 0
      ) {
        submitToolOutput({
          variables: {
            thread_id: thraedId,
            run_id: runIdx,
            tool_outputs: retrieveRunData?.retrieveRun?.output ? JSON.stringify(retrieveRunData?.retrieveRun?.output) : '',
          },
        }).then((result) => {
          if (result?.data?.submitToolOutput?.id) {
            retrieveRun({
              variables: {
                thread_id: thraedId,
                run_id: runIdx,
                group_id: parseInt(groupIdx),
                topic_id: topicIdx,
              },
            }).then((res) => {
              if (res?.data?.retrieveRun?.status === 'completed') {
                if (msgIdx) {
                  const formVal = formControl.getFieldValue();
                  updateAiRequestResponse({
                    variables: {
                      topic: formVal?.topic,
                      prompt: currentText,
                      thread_id: thraedId,
                      tokens: JSON.stringify(res?.data?.retrieveRun?.retrieveRun?.usage),
                      top_p: 1,
                      userTopicId: parseInt(idx),
                      company_idx: userInfoVar()?.campus?.fc_company_idx,
                      userType: userInfoVar()?.type,
                      open_ai_message: gptModel,
                    },
                  });
                  getMsg({
                    variables: {
                      thread_id: thraedId,
                      message_id: msgIdx,
                    },
                  }).then((res) => {
                    const filtered_data = parseMessage(
                      res.data?.getMessage?.data.reduce((acc, ele) => {
                        let temp = ele.content.concat(acc);
                        return temp;
                      }, []),
                    );
                    const result = mapContentData(filtered_data, {
                      user: userInfoVar()?.id,
                      chat_user_id: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.id : 'AI봇',
                      chat_user_name: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.name : 'AI봇',
                      created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
                    });
                    if (res?.data?.getMessage?.data[0]?.metadata.type !== 'prompt') {
                      // const result = [
                      //   {
                      //     user: userInfoVar()?.id,
                      //     chat_user_name: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.name : 'AI봇',
                      //     content: getTextValue(filtered_data),

                      //     urls: filtered_data?.image_url
                      //       ? [
                      //           {
                      //             type: 'image',
                      //             url: filtered_data?.image_url,
                      //           },
                      //         ]
                      //       : filtered_data?.audio_url
                      //       ? [
                      //           {
                      //             type: 'audio',
                      //             url: filtered_data?.audio_url,
                      //           },
                      //         ]
                      //       : [],
                      //     created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
                      //   },
                      // ];

                      if (filtered_data?.audio_url) {
                        setAudioUrl(filtered_data?.audio_url);
                      }
                      setChatList([...chatList, ...result]);
                    }
                    setLastMsg(result[result.length - 1]?.content);
                    // setLastMsg(JSON.parse(res.data?.getMessage?.data[0]?.content[0]?.text?.value?.questions[0].image_url));

                    if (!reviewSave) {
                      let getTTSText = result.reduce((acc, ele) => {
                        return acc + ele.content;
                      }, '');

                      speak(
                        getTTSText,
                        () => {
                          setMicValue('robot.png');
                        },
                        audioPaths,
                      );
                    }
                    if (pauseStop?.stop || pauseStop?.pause || reviewSave) {
                      setDisableBtn(true);
                    } else {
                      setDisableBtn(false);
                    }
                  });
                }
              } else {
                console.log('error');
              }
            });
          } else {
            console.log('error');
          }
        });
      }
    }

    if (retrieveRunData?.retrieveRun?.status === 'completed') {
      if (msgIdx) {
        const formVal = formControl.getFieldValue();
        updateAiRequestResponse({
          variables: {
            topic: formVal?.topic,
            prompt: currentText,
            thread_id: thraedId,
            tokens: JSON.stringify(retrieveRunData?.retrieveRun?.usage),
            top_p: 1,
            userTopicId: parseInt(idx),
            company_idx: userInfoVar()?.campus?.fc_company_idx,
            userType: userInfoVar()?.type,
            open_ai_message: gptModel,
          },
        });
        getMsg({
          variables: {
            thread_id: thraedId,
            message_id: msgIdx,
          },
        }).then((res) => {
          const parseRes = parseMessage(
            res.data?.getMessage?.data.reduce((acc, ele) => {
              let temp = ele.content.concat(acc);
              return temp;
            }, []),
          );
          const result = mapContentData(parseRes, {
            user: userInfoVar()?.id,
            chat_user_id: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.id : 'AI봇',
            chat_user_name: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.name : 'AI봇',
            created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
          });
          if (res?.data?.getMessage?.data[0]?.metadata.type !== 'prompt') {
            // const result = [
            //   {
            //     user: userInfoVar()?.id,
            //     chat_user_name: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.name : 'AI봇',

            //     content: getTextValue(parseRes),
            //     urls: parseRes?.image_url
            //       ? [
            //           {
            //             type: 'image',
            //             url: parseRes?.image_url,
            //           },
            //         ]
            //       : parseRes?.audio_url
            //       ? [
            //           {
            //             type: 'audio',
            //             url: parseRes?.audio_url,
            //           },
            //         ]
            //       : [],
            //     created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
            //   },
            // ];
            if (parseRes?.audio_url) {
              setAudioUrl(parseRes?.audio_url);
            }
            setChatList([...chatList, ...result]);
          }
          // setLastMsg(parseRes?.question[0].image_url);
          setLastMsg(result[result.length - 1]?.content);
          // setLastMsg(res.data?.getMessage?.data[0]?.content[0]?.text?.value);

          if (!reviewSave) {
            let getTTSText = result.reduce((acc, ele) => {
              return acc + ele.content;
            }, '');
            speak(
              getTTSText,
              () => {
                setMicValue('robot.png');
              },
              audioPaths,
            );
          }
          if (pauseStop?.stop || pauseStop?.pause || reviewSave) {
            setDisableBtn(true);
          } else {
            setDisableBtn(false);
          }
        });
      }
    }

    return () => clearInterval(interval);
  }, [retrieveRunData]);

  useEffect(() => {
    if (thraedId) {
      messageList({
        variables: {
          thread_id: thraedId,
          order: 'desc',
        },
      });
    }
  }, [thraedId, getMsgList]);

  const fetchMessages = async () => {
    messageList({
      variables: {
        thread_id: thraedId,
        last_msg_id: lastMsgId,
        order: 'desc',
      },
    });
  };

  useEffect(() => {
    if (msgList?.messageList?.data?.length > 0) {
      console.log('msgList', msgList);
      if (msgList?.messageList?.has_more == true) {
        setHasMore(true);
        setLastMsgId(msgList?.messageList?.last_id);
      } else {
        setHasMore(false);
        setLastMsgId(null);
      }
      const promptMetadataArray = msgList?.messageList?.data?.filter((item) => item.metadata && item.metadata.type !== 'prompt');
      const rearrangedList = promptMetadataArray?.reverse();
      const result = rearrangedList?.reduce((acc, ele) => {
        const filtered_data = parseMessage(ele?.content);
        const newData = mapContentData(filtered_data, {
          user: ele?.role === 'user' ? userInfoVar()?.name : 'AI봇',
          chat_user_id: ele?.role === 'user' ? userInfoVar()?.id : 'AI봇',
          chat_user_name: ele?.role === 'user' ? userInfoVar()?.name : 'AI봇',
          created: new Date(ele.created_at * 1000).toISOString(),
        });
        return [...acc, ...newData];
      }, []);
      // setChatList(result)
      setChatList((prevChatList) => [...result, ...prevChatList]);
    }
  }, [msgList]);

  useEffect(() => {
    if (currentText || changeIcon) {
      setDisableBtn(false);
    }
  }, [currentText]);

  function getPromptMessage(prompt) {
    const possibleKeys = ['chat', 'question', 'debate'];
    for (const key of possibleKeys) {
      if (prompt.hasOwnProperty(key)) {
        return prompt[key];
      }
    }
    return '';
  }

  useEffect(() => {
    if (topicData) {
      formControl.setFieldsValue({
        title: topicData?.data?.book?.title,
        topic: topicData?.data?.book?.topic,
        message: topicData?.data?.book?.prompt ? getPromptMessage(topicData.data.book.prompt) : '',
      });
    }
  }, [topicData]);

  const handlePromptClick = async (val) => {
    let prmpt;
    let recording;

    switch (val) {
      case 'easy':
        prmpt = EASY;
        recording = EASY;
        break;
      case 'translate':
        prmpt = TRANSLATE;
        recording = TRANSLATE;
        break;
      case 'short':
        prmpt = SHORT;
        recording = SHORT;
        break;
      case 'lesson':
        prmpt = LESSON;
        recording = LESSON;
        break;
      case 'review-save':
        prmpt = REVIEWSAVE;
        recording = REVIEWSAVE;
        setActive(false);
        setReviewSave(true);
        setDisableBtn(true);
        setPercent(0);
        break;
      default:
        prmpt = val;
    }
    // if (val == "main") {
    //     prmpt = PROMPTS[0]
    // }
    if (recording) {
      setStartRecording(false);
      setCurrentUrl('');
      stop();
      stopRecording(recording);
    }
    // if (micBtnClick === 0 && (msgList?.messageList?.data?.length === 0 || msgList?.messageList?.data == undefined)) {
    if (micBtnClick === 0 && val !== 'review-save') {
      if (prmpt !== STOP || prmpt !== PAUSE) {
        setPauseStop({ pause: false, stop: false });
      }
      let curPrmpt;
      const book = topicData?.data?.book;
      const preInstructionPrompt = book?.pre_instruction_prompt;
      const prompt = book?.prompt;

      // if (pauseStop?.pause === true && lastUserMsg) {
      //   curPrmpt = lastUserMsg.content;

      if (pauseStop?.pause === true || pauseStop?.stop === true) {
        curPrmpt = RESTART;
      } else if (prompt?.question !== undefined) {
        const modalQuestion = preInstructionPrompt?.modal_question || '';
        curPrmpt = `${modalQuestion} ${prompt.question}`;
      } else if (prompt?.chat !== undefined) {
        const modalChat = preInstructionPrompt?.modal_chat || '';
        curPrmpt = `${modalChat} ${prompt.chat}`;
      } else {
        const modalDebate = preInstructionPrompt?.modal_debate || '';
        curPrmpt = `${modalDebate} ${prompt.debate}`;
      }
      openAiMessage({
        variables: {
          thread_id: thraedId,
          role: 'user',
          content: curPrmpt,
          metadata: { type: 'prompt' },
        },
      }).then((res) => {
        if (res?.data?.openaiMessage?.id) {
          setMsgIdx(res?.data?.openaiMessage?.id);
          createRun({
            variables: {
              assistant_id: assistantIdx,
              thread_id: thraedId,
              image_audio: JSON.stringify(imageAudio),
              model: gptModel,
            },
          }).then((result) => {
            if (result?.data?.createRuns?.id) {
              setRunIdx(result?.data?.createRuns?.id);
              // getSteps({
              //   variables: {
              //     thread_id: thraedId,
              //     run_id: result?.data?.createRuns?.id,
              //   },
              // });
              retrieveRun({
                variables: {
                  thread_id: thraedId,
                  run_id: result?.data?.createRuns?.id,
                  group_id: parseInt(groupIdx),
                  topic_id: topicIdx,
                },
              });
            }
          });
        }
      });
    } else {
      if (prmpt !== STOP || prmpt !== PAUSE) {
        setPauseStop({ pause: false, stop: false });
      }
      openAiMessage({
        variables: {
          thread_id: thraedId,
          role: 'user',
          content: prmpt,
          // content: ""
          metadata:
            prmpt == STOP ||
            prmpt == PAUSE ||
            prmpt == REPEAT ||
            prmpt == LESSON ||
            prmpt == EASY ||
            prmpt == TRANSLATE ||
            prmpt == SHORT ||
            prmpt == TO_LESSON
              ? {
                  type: 'prompt',
                }
              : {},
        },
      }).then((res) => {
        // const tmpChat = [];
        const filtered_data = parseMessage(res?.data?.openaiMessage?.content);
        const aiResponse = getTextValue(filtered_data);
        if (
          TO_LESSON == aiResponse ||
          LESSON == aiResponse ||
          PAUSE == aiResponse ||
          STOP == aiResponse ||
          REPEAT == aiResponse ||
          EASY == aiResponse ||
          TRANSLATE == aiResponse ||
          SHORT == aiResponse ||
          ASK == aiResponse
        ) {
          // setMicBtnChange(true)
        } else {
          if (val != 'review-save') {
            const tempChat = mapContentData(filtered_data, {
              user: userInfoVar()?.id,
              chat_user_id: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.id : 'AI봇',
              chat_user_name: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.name : 'AI봇',
              created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
            });
            setChatList([...chatList, ...tempChat]);
            let getTTSText = tempChat.reduce((acc, ele) => {
              return acc + ele.content;
            }, '');

            // if (val != "review-save") {

            speak(
              getTTSText,
              () => {
                setDisableBtn(false);
                setMicValue('robot.png');
              },
              audioPaths,
            );
            // }
          }
        }

        if (res?.data?.openaiMessage?.id) {
          setMsgIdx(res?.data?.openaiMessage?.id);
          createRun({
            variables: {
              assistant_id: assistantIdx,
              thread_id: thraedId,
              image_audio: val === 'review-save' ? undefined : JSON.stringify(imageAudio),
              model: gptModel,
            },
          }).then((result) => {
            if (result?.data?.createRuns?.id) {
              setRunIdx(result?.data?.createRuns?.id);
              // getSteps({
              //   variables: {
              //     thread_id: thraedId,
              //     run_id: result?.data?.createRuns?.id,
              //   },
              // });
              retrieveRun({
                variables: {
                  thread_id: thraedId,
                  run_id: result?.data?.createRuns?.id,
                  group_id: parseInt(groupIdx),
                  topic_id: topicIdx,
                },
              });
            }
          });
        }
      });
      setCurrentText('');
    }
    formControl.resetFields(['user_content', 'message']);
    setPromptActive(true);
  };

  const handleMicBtnClick = () => {
    setMicValue('mice_3.png');
    setDisableBtn(true);
    setSoundPlay(true);
    setSound(clickSound);
    setMicBtnClick(micBtnClick + 1);
    if (!active) {
      setActive(true);
      handlePromptClick('main');
      setDisableBtnVal(false);
      setMicBtnChange(false);
    } else if (recordAudio.current) {
      stopRecording();
      setStartRecording(false);
    } else {
      startRecord();
      setStartRecording(true);
    }
  };

  const handleClose = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      closeBtn: true,
    }));
  };
  const handleCloseCancel = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      closeBtn: false,
    }));
  };
  const handleClosClear = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      clearBtn: false,
    }));
  };
  const handleYes = async () => {
    setSoundPlay(true);
    setSound(clickSound);
    setChatList([]);
    setMicBtnClick(0);
    setActive(false);
    setPauseStop({
      pause: false,
      stop: false,
    });
    setShowBtn((prevState) => ({
      ...prevState,
      clearBtn: false,
    }));
    stop();
    await updateThread({
      variables: {
        idx: parseInt(idx),
        thread_id: 'null',
      },
    }).then(async (res) => {
      // Make sure this function is declared as async
      if (res?.data?.updateUserAssignmentThread) {
        stopRecording('clear');
        setMicValue('mice_1.png');
        setStartRecording(false);
        await createThread({
          variables: {
            idx: parseInt(idx),
          },
        });
      }
    });
  };

  const handleNo = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setShowBtn((prevState) => ({
      ...prevState,
      clearBtn: false,
    }));
  };

  const handleMainClose = () => {
    history.goBack();
  };
  const handleRestart = async () => {
    await updateThread({
      variables: {
        idx: parseInt(idx),
        thread_id: 'null',
      },
    }).then(async (res) => {
      // Make sure this function is declared as async
      if (res?.data?.updateUserAssignmentThread) {
        stopRecording('clear');
        setMicValue('mice_1.png');
        setStartRecording(false);
        await createThread({
          variables: {
            idx: parseInt(idx),
          },
        });
      }
    });
    setPauseStop({
      pause: false,
      stop: false,
    });
    window.location.reload();
  };
  const onSubmit = async (text) => {
    if (autoSendRef.current.length) {
      clearTimeout(autoSendRef.current[0]);
      autoSendRef.current = [];
    }
    if (recordAudio?.current) {
      stopRecording('send-text');
    }
    setMicValue('mice_3.png');
    setDisableBtn(true);
    setSoundPlay(true);
    setSound(clickSound);
    setChangeIcon(false);
    openAiMessage({
      variables: {
        thread_id: thraedId,
        role: 'user',
        content: text,
      },
    }).then((res) => {
      const tmpChat = [];

      const filtered_data = parseMessage(res?.data?.openaiMessage?.content);
      const array = {
        user: userInfoVar()?.id,
        chat_user_id: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.id : 'AI봇',
        chat_user_name: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.name : 'AI봇',
        content: getTextValue(filtered_data),
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      };
      tmpChat.push(array);
      setChatList([...chatList, ...tmpChat]);
      if (res?.data?.openaiMessage?.id) {
        setMsgIdx(res?.data?.openaiMessage?.id);
        createRun({
          variables: {
            assistant_id: assistantIdx,
            thread_id: thraedId,
            image_audio: JSON.stringify(imageAudio),
            model: gptModel,
          },
        }).then((result) => {
          if (result?.data?.createRuns?.id) {
            setRunIdx(result?.data?.createRuns?.id);
            // getSteps({
            //   variables: {
            //     thread_id: thraedId,
            //     run_id: result?.data?.createRuns?.id,
            //   },
            // });
            retrieveRun({
              variables: {
                thread_id: thraedId,
                run_id: result?.data?.createRuns?.id,
                group_id: parseInt(groupIdx),
                topic_id: topicIdx,
              },
            });
          }
        });
      }
    });
    setCurrentText('');
  };

  const handleReviewSave = (val) => {
    setMicValue('mice_1.png');
    // if (recordAudio?.current == null) {
    // } else {
    //     stopRecording();
    // }
    handleBtnClick(val);
    if (userTopicAssignmetStatus === 0) {
      updateAITopicUserAssignment({
        variables: {
          idx: parseInt(idx),
          start_time: startTime,
          end_time: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          status: 1,
          last_message: lastMsg,
        },
      });
    }
  };

  const handlePlayEnd = () => {
    if (audioUrl) {
      setCurrentUrl(audioUrl);
      if (micBtnChange) {
        setMicValue('mice_1.png');
      } else {
        if (!audioUrl) {
          setMicValue('stop2x.png');
        }
      }
      if (pauseStop?.stop || pauseStop?.pause) {
        // if (disableBtnVal) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
      // setCurrentUrl('');
      setAudioUrl('');
      if (!micBtnChange && !showBtn?.askBtn && !audioUrl) {
        startRecord();
        setStartRecording(true);
      }
      setPlayBtn('play-btn.svg');
    } else {
      if (micBtnChange) {
        setMicValue('mice_1.png');
      } else {
        setMicValue('stop2x.png');
      }
      if ((pauseStop?.stop || pauseStop?.pause) && disableBtnVal) {
        // if (disableBtnVal) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
      setCurrentUrl('');
      if (!micBtnChange && !showBtn?.askBtn) {
        startRecord();
        setStartRecording(true);
        // if (pauseStop?.stop || pauseStop?.pause) {
        //     setStartRecording(false);
        // } else {
        //     setStartRecording(true);

        // }
      }
      setPlayBtn('play-btn.svg');
    }
  };

  useEffect(() => {
    if (!isSpeaking && texts.length > 0) {
      handlePlayEnd();
    }
  }, [isSpeaking, texts]);

  const startRecord = () => {
    if (recordAudio.current) return;
    setSoundPlay(true);
    setSound(recordingStartSound);
    captureUserMedia({ audio: { echoCancellation: true } }, async (stream) => {
      recorderStreams.current = stream;
      recordAudio.current = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 48000,
        desiredSampRate: 16000,

        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,
        timeSlice: 100,
        ondataavailable: onDataAvailableCallback,
      });
      setLastBlob(null);
      recordAudio.current.startRecording();
    });
  };

  const handleReRecord = () => {
    if (autoSendRef.current.length) {
      clearTimeout(autoSendRef.current[0]);
      autoSendRef.current = [];
    }
    if (recordAudio.current) {
      stopRecording();
    }
    setMicValue('stop2x.png');
    setDisableBtn(false);
    setSoundPlay(true);
    setSound(clickSound);
    setMicBtnClick(0);
    setActive(true);
    setMicBtnChange(false);
    setCurrentText('');
    setLastBlob(null);
    startRecord();
    setStartRecording(true);
  };

  const startAskRecord = () => {
    captureUserMedia({ audio: { echoCancellation: true } }, async (stream) => {
      if (!recordAskAudio.current) recordAskAudio.current = [];
      recorderStreams.current = stream;
      recordAskAudio.current = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 48000,
        desiredSampRate: 16000,

        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,
        timeSlice: 100,
        ondataavailable: onDataAvailableCallback,
      });
      setLastBlob(null);
      recordAskAudio.current.startRecording();
    });
  };

  const stopRecording = (val) => {
    if (recordAudio.current != null) {
      recordAudio.current.stopRecording(async () => {
        let reader = new FileReader();

        reader.onload = async function (e) {
          processRecordCallback(recordAudio?.current?.getBlob(), val, (text) => {
            autoSendRef.current = [
              setTimeout(() => {
                onSubmit(text);
              }, 5000),
              new Date(),
            ];
          });
          setLastBlob(recordAudio.current.toURL());
          recordAudio.current.destroy();
          recordAudio.current = null;
          if (recorderStreams.current) {
            recorderStreams.current.getTracks().forEach((track) => track.stop());
            recorderStreams.current = null;
          }
        };
        try {
          reader?.readAsArrayBuffer(recordAudio?.current?.getBlob());
        } catch (error) {
          console.log('error', error);
        }
      });
    }
  };

  const stopAskRecording = (val) => {
    if (recordAskAudio.current != null) {
      recordAskAudio.current.stopRecording(async () => {
        let reader = new FileReader();

        reader.onload = async function (e) {
          processRecordCallback(recordAskAudio?.current?.getBlob(), val);
          setLastBlob(recordAskAudio.current.toURL());
          recordAskAudio.current.destroy();
          recordAskAudio.current = null;
          if (recorderStreams.current) {
            recorderStreams.current.getTracks().forEach((track) => track.stop());
            recorderStreams.current = null;
          }
        };

        reader.readAsArrayBuffer(recordAskAudio?.current?.getBlob());
      });
    }
  };

  const handleBtnClick = (val) => {
    setStartRecording(false);
    setCurrentUrl('');
    stop();
    if (val !== 'review-save') {
      setMicValue('mice_3.png');
      setDisableBtn(true);
    }
    setCurrentText('');
    setSound(clickSound);
    setSoundPlay(true);
    handlePromptClick(val);
    if (val == 'review-save') {
      setMicBtnChange(true);
      setDisableBtnVal(true);
    } else {
      setMicBtnChange(false);
    }
  };

  const handleSoundEnd = () => {
    setSound(undefined);
    setSoundPlay(false);
  };

  const handleRecording = (val) => {
    setStartRecording(false);
    setCurrentUrl('');
    stop();
    setMsgList(true);
    setMicValue('mice_3.png');
    setDisableBtn(true);
    handlePromptClick(val);
    setCurrentText('');
    stopRecording(val);
    if (val == PAUSE) {
      setPauseStop((prevState) => ({
        ...prevState,
        pause: true,
      }));
      setMicBtnClick(0);
      setActive(false);
      setMicBtnChange(true);
      let lastAiMsg = null;
      if (chatList !== undefined && chatList) {
        for (let i = chatList.length - 1; i >= 0; i--) {
          const msg = chatList[i];
          if (msg['chat_user_name'] === 'AI봇') {
            lastAiMsg = msg;
            break;
          }
        }
      }
      setLastUserMsg(lastAiMsg);
    }

    if (val == STOP) {
      setCurrentUrl('');
      stop();
      setPauseStop((prevState) => ({
        ...prevState,
        stop: true,
      }));
      setMicBtnClick(0);
      setActive(false);
      setMicBtnChange(true);
    }
    // if (val == PAUSE || val == STOP) {
    //     setMicBtnClick(0)
    //     setActive(false)
    //     setMicBtnChange(true)
    // }
    setSoundPlay(true);
    setSound(clickSound);
  };

  const handleMsgClear = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setCurrentText('');
  };
  const handleGuideBtn = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      guideBtn: true,
    }));
  };
  const handleCancel = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      askBtn: false,
    }));
    askForm.resetFields();
    setTranslateMic('transalte_mic.png');
    if (micValue == 'stop2x.png') {
      if (recordAudio.current) {
        stopRecording('ask');
        setStartRecording(false);
      } else {
        startRecord();
        setStartRecording(true);
      }
    }
  };
  const handleConvertText = async () => {
    setSoundPlay(true);
    setSound(clickSound);
    try {
      const res = await translateText({
        variables: {
          from_lang: 'ko',
          to_lang: 'en',
          text: askForm?.getFieldValue()?.korean_text,
        },
      });

      askForm.setFieldsValue({
        english_text: res?.data?.translateText?.dataObj?.TranslatedText,
      });
      askForm.submit();
      if (res?.data?.translateText?.dataObj?.TranslatedText) {
        speak(
          res?.data?.translateText?.dataObj?.TranslatedText,
          () => {
            setDisableBtn(false);
            setPlayBtn('pause-btn.svg');
            // setMicValue("robot.png")
          },
          audioPaths,
        );
      }
    } catch (error) {
      console.error('Error while translating:', error);
    }
  };
  const handleAskClear = () => {
    setSoundPlay(true);
    setSound(clickSound);
    askForm.resetFields();
  };
  const handleTransalte = () => {
    setSoundPlay(true);
    setSound(clickSound);
    const newTranslateMic = translateMic === 'transalte_mic.png' ? 'stop.png' : 'transalte_mic.png';
    setTranslateMic(newTranslateMic);
    if (recordAskAudio.current) {
      stopAskRecording('ask');
      setStartRecording(false);
    } else {
      // startRecord();
      startAskRecord();
      setStartRecording(true);
    }
  };

  const handleAsk = () => {
    // setCurrentUrl('')
    setShowBtn((prevState) => ({
      ...prevState,
      askBtn: true,
    }));
    setSound(clickSound);
    setSoundPlay(true);
    if (recordAudio.current) {
      stopRecording('ask-stop-recording');
    }
  };
  const handlePlay = () => {
    setSound(clickSound);
    setSoundPlay(true);
    if (askForm?.getFieldValue()?.english_text) {
      speak(
        askForm?.getFieldValue()?.english_text,
        () => {
          setDisableBtn(false);
          setPlayBtn('pause-btn.svg');
        },
        audioPaths,
      );
    }
  };
  const handleScroll = () => {
    if (thraedId) {
      setLoadMoreMsg(true);
    }
  };
  const handleLoadMore = () => {
    setSound(clickSound);
    setSoundPlay(true);
    if (hasMore && lastMsgId !== null) {
      fetchMessages();
    }
  };

  let guideVideo = dummy.test.guideVideos[6].videos;
  return (
    <>
      <div className={displayDrawer ? 'sidebar-overlay' : ''}></div>
      <div className="add-header">
        <h2>ai speaking coach</h2>
        <div className="right-section">
          <a type="primary" href={'https://cdn.cloubot.com/ppt/manual/ai-speaking-coach/index.html'} target="_blank" rel="noreferrer">
            <img width={104} marginTop={33} src="/images/talking-tutor/guide.png" alt="guide-icon" />
          </a>
          <Button type="primary">
            <img src="/images/talking-tutor/close-icon.png" alt="close-arrow" onClick={handleClose} />
          </Button>
        </div>
      </div>

      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        layout="vertical"
        className="ant-form ant-form-vertical user-lession"
        form={formControl}
      >
        <div className="middle-row">
          <div className="left-part">
            <div className="middle-input audio-input">
              <Form.Item name="title" label="Title" style={{ marginBottom: 5 }}>
                <Input name="title" disabled style={{ color: '#2e2e2e' }} />
              </Form.Item>
              <Form.Item name="topic" label="Topic" style={{ marginBottom: 5 }}>
                <TextArea name="topic" rows={2} disabled style={{ color: '#2e2e2e', resize: 'none' }} />
              </Form.Item>
              <Form.Item style={{ marginBottom: 5 }} label="Message">
                <div className="ant-col-24 msg-clear-btn" style={{ color: 'rgba(52, 151, 219, 1)' }}>
                  Clear Msg.&nbsp;&nbsp;
                  <img src="/images/talking-tutor/redo.png" alt="close-arrow" onClick={handleMsgClear} style={{ cursor: 'pointer' }} />
                </div>
                <TextArea
                  className="msg-input"
                  rows={2}
                  aria-expanded="false"
                  style={{ cursor: 'pointer', minHeight: '70px', resize: 'none' }}
                  onFocus={(e) => {
                    if (autoSendRef.current.length) {
                      clearTimeout(autoSendRef.current[0]);
                      autoSendRef.current = [];
                    }
                  }}
                  onChange={(e) => {
                    if (autoSendRef.current.length) {
                      clearTimeout(autoSendRef.current[0]);
                      autoSendRef.current = [];
                    }
                    const textContent = e.target.value.trim(); // Trim whitespace
                    if (textContent !== null && textContent !== '') {
                      setStartRecording(false);
                    }
                    setCurrentText(e.target.value);
                  }}
                  value={currentText}
                  onBlur={(e) => {
                    setCurrentText(e.target.value);
                  }}
                />
              </Form.Item>
              <div className="setting-btns setting-audio">
                <div className="setting-action-wrap">
                  <div className="guide-btn ant-col ant-col-4">
                    <img src="/images/talking-tutor/btn-guid-blue.png" onClick={handleGuideBtn} />
                    <p>Guide</p>
                  </div>
                  <div className="ant-col action_icons ant-col-18">
                    <div className="left-btn action-btn-wrap">
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/z_1.png"
                            alt="z_1-icon"
                            className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                            onClick={!disableBtn ? () => handleRecording(TO_LESSON) : undefined}
                          />
                        </div>
                        {/* <p>Repeat</p> */}
                      </div>
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/z_2.png"
                            alt="z_2-icon"
                            className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                            onClick={!disableBtn ? () => handleRecording(PAUSE) : undefined}
                          />
                        </div>
                        {/* <p>Pause</p> */}
                      </div>
                      <div className="action_item mice_item">
                        {currentText !== '' || changeIcon ? (
                          <AButton
                            type="primary"
                            shape="circle"
                            icon={<SendOutlined />}
                            onClick={() => onSubmit(currentText)}
                            style={{ height: 60, width: 60, transform: 'rotate(270deg)' }}
                            className="blink_me"
                          />
                        ) : (
                          <div className="img_wrap">
                            <img
                              src={`/images/talking-tutor/${micValue}`}
                              className={recordAudio.current !== null && !micBtnChange && micValue == 'stop2x.png' ? 'blink_me' : ''}
                              alt="mice_1-icon"
                              onClick={
                                !disableBtn || pauseStop?.pause || pauseStop?.stop || !reviewSave
                                  ? micValue == 'mice_3.png' || micValue == 'robot.png'
                                    ? undefined
                                    : handleMicBtnClick
                                  : undefined
                              }
                              style={
                                !disableBtn || pauseStop?.pause || pauseStop?.stop || !reviewSave
                                  ? { cursor: 'pointer', width: '56px', height: '60px' }
                                  : { cursor: 'not-allowed', width: '56px', height: '60px' }
                              }
                            />
                          </div>
                        )}
                        {/* <p style={{ marginTop: '4px' }}>Start</p> */}
                      </div>
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/z_5.png"
                            alt="z_5-icon"
                            className={disableBtn || micValue === 'stop2x.png' ? 'disabled-btn' : 'enabled-btn'}
                            onClick={disableBtn || micValue === 'stop2x.png' ? undefined : () => handleReRecord()}
                          />
                        </div>
                        {/* <p>Stop</p> */}
                      </div>
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/z_4.png"
                            alt="z_4-icon"
                            className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                            onClick={!disableBtn ? () => handleRecording(REPEAT) : undefined}
                          />
                        </div>
                        {/* <p>Restart</p> */}
                      </div>
                    </div>

                    <div className="left-btn leftt" style={{ display: 'flex' }}>
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/b_esay.png"
                            alt="b_esay-icon"
                            className={disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                            onClick={!disableBtn ? () => handleBtnClick('easy') : undefined}
                          />
                        </div>
                        <h6>Easy</h6>
                      </div>
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/b_translate.png"
                            alt="translate-icon"
                            className={disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                            onClick={!disableBtn ? () => handleBtnClick('translate') : undefined}
                          />
                        </div>
                        <h6>Translate</h6>
                      </div>
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/b_short.png"
                            alt="b_short-icon"
                            className={disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                            onClick={!disableBtn ? () => handleBtnClick('short') : undefined}
                          />
                        </div>
                        <h6>Short</h6>
                      </div>
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/b_ask.png"
                            alt="b_ask-icon"
                            className={disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                            onClick={!disableBtn ? () => handleAsk() : undefined}
                          />
                        </div>
                        <h6>Ask</h6>
                      </div>
                      <div className="action_item">
                        <div className="img_wrap">
                          <img
                            src="/images/talking-tutor/b_to_lesson.png"
                            alt="b_to lesson-icon"
                            className={disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                            onClick={!disableBtn ? () => handleBtnClick('lesson') : undefined}
                          />
                        </div>
                        <h6>To Lesson</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-part audio-right-part">
            <div className="dialog-wrap">
              <div className="clear-timer">
                <div className="clear-timer-box">
                  <div className="item">
                    <p>
                      Time{' '}
                      <span>{`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
                        2,
                        '0',
                      )}`}</span>
                    </p>
                  </div>
                  <div className="item">
                    <div className="clear-btn">
                      clear &nbsp;
                      <img
                        src="/images/talking-tutor/redo.png"
                        alt="close-arrow"
                        onClick={() => {
                          setShowBtn((prevState) => ({
                            ...prevState,
                            clearBtn: true,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_item_wrap">
                <Form.Item
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  name="dialog"
                  label="Dialogue"
                  style={{ marginBottom: 5 }}
                >
                  {!activeAnswer ? (
                    <>
                      <MessageBox
                        id="MessageBox"
                        style={{
                          height: '57vh',
                          overflow: 'auto',
                          margin: '15px 0',
                          backdround: '#fff',
                        }}
                        className="message-box"
                        ref={messageBoxRef}
                      >
                        <InfiniteScroll
                          scrollableTarget="MessageBox"
                          style={{ display: 'flex', flexDirection: 'column' }}
                          inverse={false}
                          hasMore={true}
                          dataLength={chatList?.length}
                          onScroll={handleScroll}
                          loader={
                            openAiMsgLoading ? (
                              <p style={{ textAlign: 'center' }}>
                                <b>Loading...</b>
                              </p>
                            ) : null
                          }
                          endMessage={
                            msgLoading || runLoading || stepLoading ? (
                              <p style={{ textAlign: 'center' }}>
                                <b>생각중...</b>
                              </p>
                            ) : null
                          }
                        >
                          {chatList?.map((thread, key) => {
                            const isMe = String(userInfoVar()?.id) === thread.chat_user_id;
                            return (
                              <React.Fragment key={`message-item-key${key}`}>
                                <DateDivider
                                  isShow={
                                    key < chatList?.length &&
                                    moment(new Date(thread?.created)).format('YYYY-MM-DD') !==
                                      moment(new Date(chatList[key - 1]?.created)).format('YYYY-MM-DD')
                                  }
                                  date={`${moment(new Date(thread?.created)).format('YYYY-MM-DD')}`}
                                />
                                {key === 0 &&
                                  (loadMoreMsg & hasMore ? (
                                    <div className="load-more-btn" onClick={handleLoadMore}>
                                      <img src="/images/talking-tutor/load_more.png" alt="cguide" />
                                      Load More Message
                                    </div>
                                  ) : (
                                    <></>
                                  ))}
                                <MessageItem
                                  type={'ai-talking-tutor'}
                                  isMe={isMe}
                                  name={thread.chat_user_name}
                                  message={thread.content}
                                  urls={thread.urls}
                                  currentFileList={[...imagePaths, ...audioPaths]}
                                  date={thread.created}
                                  readYN={true}
                                  unreadCount={0}
                                />
                              </React.Fragment>
                            );
                          })}
                          {(msgLoading || (micValue === 'mice_3.png' && !changeIcon)) && (
                            <p style={{ textAlign: 'center' }}>
                              <b>loading...</b>
                            </p>
                          )}
                        </InfiniteScroll>
                      </MessageBox>
                      {reviewSave ? (
                        <>
                          <p className="progress-text">It takes 20 seconds to the results</p>
                          <Progress
                            className="progress-bar"
                            type="line"
                            percent={percent}
                            format={() => `${Math.round(percent)}%`}
                            status="active"
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <Input.TextArea
                      style={{
                        height: '30vh',
                        overflow: 'auto',
                        border: '1px solid #00b598',
                        backdround: '#fff',
                        margin: '10px 0',
                        color: '#00b598',
                      }}
                      placeholder="답변하기"
                      name="answer"
                      onChange={(e) => setAnswer(e.target.value)}
                    />
                  )}
                </Form.Item>
                <div className="setting-wrap">
                  <div className="setting-wrap-inner">
                    <div className="setting_item">
                      <img src="/images/talking-tutor/speak_2.png" alt="speak-icon" />
                    </div>
                    <div className="setting_item">
                      <img
                        src="/images/talking-tutor/setting_c.png"
                        alt="setting-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setDisplayDrawer(true)}
                      />
                    </div>
                    <div className="setting_item">
                      <button
                        type="submit"
                        style={reviewSave ? { cursor: 'not-allowed', opacity: '0.5' } : {}}
                        className="ant-btn ant-btn-default review-save-btn"
                        onClick={reviewSave ? undefined : () => handleReviewSave('review-save')}
                      >
                        {' '}
                        <img src="/images/talking-tutor/exit_o.png" alt="exit-btn" />
                      </button>
                      <h6>Save</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">Powered by Cloubot</div>
      </Form>
      <ReactPlayer
        url={currentUrl}
        playing={currentUrl !== ''}
        width={0}
        height={0}
        loop={false}
        onEnded={handlePlayEnd}
        onError={handlePlayEnd}
      />
      {soundPlay ? <SoundEffect src={sound} onEnd={handleSoundEnd} /> : ''}
      {displayDrawer ? (
        <Sider
          trigger={null}
          className="company-sider"
          style={{
            height: '100%',
            overflowY: 'auto',
            right: 0,
            position: 'fixed',
            zIndex: '9999',
            backgroundColor: '#005d94',
            bottom: '0',
            width: 300,
            maxWidth: 300,
            minWidth: 300,
          }}
        >
          <div className="sidebar-main">
            <div className="setting-icon-wrap">
              <img src="/images/talking-tutor/setting_w.png" alt="setting" />
              <span>Setting</span>
            </div>
            <div>
              <img
                src="/images/talking-tutor/x_w.png"
                alt="setting"
                style={{ cursor: 'pointer' }}
                onClick={() => setDisplayDrawer(false)}
              />
            </div>
          </div>

          <div className="sidebar-inner">
            <div className="sidebar-inner-box">
              <div className="detail-box">
                <div>
                  <img src="/images/talking-tutor/girl.png" alt="setting" />
                </div>
                <div>
                  <h2>{userInfoVar()?.id}</h2>
                  <h2>Test</h2>
                  <h2>className</h2>
                </div>
              </div>
            </div>
            <div className="user-guid-wrap">
              {' '}
              <img src="/images/talking-tutor/i_w.png" alt="setting" />{' '}
              <h2
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://platform.openai.com/docs/assistants/overview', '_blank')}
              >
                User's guide
              </h2>
            </div>
            <div className="rank__wrap">
              <Form className="sidebar-form">
                <Form.Item name="Speed:" label="Speed:" style={{ marginBottom: 5 }}>
                  <Select className="select-form-control" placeholder="Select Speed" onChange={(e) => setSpeed(e)} defaultValue={speed}>
                    <Option value={0.7}>{0.7}</Option>
                    <Option value={0.8}>{0.8}</Option>
                    <Option value={0.9}>{0.9}</Option>
                    <Option value={1.0}>{1.0}</Option>
                    <Option value={1.1}>{1.1}</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="Change Voice" label="Change Voice" style={{ marginBottom: 5 }}>
                  <Select className="select-form-control" placeholder="Select Speed" onChange={(e) => setVoice(e)} defaultValue={voice}>
                    <Option value="alloy">alloy</Option>
                    <Option value="echo">echo</Option>
                    <Option value="fable">fable</Option>
                    <Option value="onyx">onyx</Option>
                    <Option value="nova">nova</Option>
                    <Option value="shimmer">shimmer</Option>
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Sider>
      ) : (
        ''
      )}
      <Modal visible={showBtn?.guideBtn} width={1100} className="guide-img-modal">
        <img
          className="modal-img"
          src="/images/talking-tutor/guide_full.png"
          onClick={() =>
            setShowBtn((prevState) => ({
              ...prevState,
              guideBtn: false,
            }))
          }
        ></img>
      </Modal>
      <Modal visible={showBtn?.askBtn} width={800} className="asked-modal" onCancel={handleCancel}>
        <div>
          <h1>Translator</h1>
        </div>
        <Form form={askForm}>
          <div className="title-wrap">
            <Form.Item name="korean_text" label="한국어(Korean)" style={{ width: '66%', marginBottom: '15px' }}>
              <Input name="korean_text" />
            </Form.Item>
            <div className="btn-wrap">
              <img
                src="/images/talking-tutor/redo.png"
                alt="close-arrow"
                onClick={handleAskClear}
                style={{ cursor: 'pointer', marginRight: '12px' }}
              />
              <img
                src={`/images/talking-tutor/${translateMic}`}
                alt="close-arrow"
                onClick={handleTransalte}
                style={{ cursor: 'pointer', marginRight: '12px', height: '38px' }}
                className={translateMic == 'stop.png' ? 'blink_me audio-record' : ''}
              />
              <button onClick={handleConvertText}>한/영</button>
            </div>
          </div>
          <div className="title-wrap">
            <Form.Item name="english_text" label="영어(English)" style={{ marginBottom: 5, width: '73%' }}>
              <Input name="english_text" />
            </Form.Item>
            {/* pause-btn.svg */}
            <img src={`/images/talking-tutor/${playBtn}`} alt="close-arrow" style={{ cursor: 'pointer' }} onClick={handlePlay} />
          </div>
        </Form>
      </Modal>
      <Modal visible={showBtn?.closeBtn} width={700} onCancel={handleCloseCancel} className="restart-task-modal">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            width: '660px',
            height: '276px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/images/popup/exit_popup.png)`,
          }}
        >
          <div style={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
            <button
              style={{
                backgroundColor: '#3DB230',
                border: 'none',
                color: '#fff',
                width: `${274 * 0.5}px`,
                height: `${113 * 0.5}px`,
                borderRadius: `${113 * 0.3}px`,
                fontSize: `${38 * 0.6}px`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                fontFamily: 'Noto Sans',
                fontWeight: '600',
                cursor:'pointer'
              }}
              onClick={handleMainClose}
            >
              YES
            </button>
            <button
              onClick={handleCloseCancel}
              style={{
                backgroundColor: '#f65656',
                border: '1px solid #ff0b0b',
                color: '#fff',
                width: `${274 * 0.5}px`,
                height: `${113 * 0.5}px`,
                borderRadius: `${113 * 0.3}px`,
                fontSize: `${38 * 0.6}px`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                fontFamily: 'Noto Sans',
                fontWeight: '600',
                cursor:'pointer'
              }}
            >
              NO
            </button>
          </div>
        </div>
      </Modal>
      <Modal visible={showBtn?.clearBtn} width={400} onCancel={handleClosClear} className="restart-task-modal">
        <h3>Are you sure you want to clear?</h3>

        <div className="modal-footer">
          <Button onClick={handleYes} className="ant-btn ant-btn-default">
            <span>Yes</span>
          </Button>
          <Button onClick={handleNo} className="ant-btn btn-primary">
            <span>No</span>
          </Button>
        </div>
      </Modal>
    </>
  );
}

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};
