import React from 'react';
import { Button } from 'antd';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { GET_MY_EBOOK_LIST } from 'src/operations/queries/getBook';

//현재 진행 중인 반의 책을 보여주는 걸로 하자 일단은..
const MyEbookLinkBtn = () => {
  const { data } = useQuery(GET_MY_EBOOK_LIST);
  return (
    <>
      {data?.myEbookList?.length > 0 ? (
        <Link
          to={{
            pathname: `https://lms-v2.talkingsam.com/data/ebook/AE-L${data?.myEbookList[0].level_no}V${data?.myEbookList[0].volume_no}/`,
          }}
          target="_blank"
        >
          <Button className="btn-messageicon" size="large">
            My Ebook
          </Button>
        </Link>
      ) : (
        <Button
          className="btn-messageicon"
          size="large"
          onClick={() => {
            alert('진행중인 수업이 없습니다.');
          }}
        >
          My Ebook
        </Button>
      )}
    </>
  );
};

export default MyEbookLinkBtn;
