import React, { useState, useMemo, useEffect } from 'react';
import { Col, Row, Select, Modal, Button, Input, Tooltip, Space, Switch, Popover } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { EditOutlined, SearchOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { openNotification } from 'src/components/common/Notification';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import ConsultFormModalMeta from 'src/components/Modal/ConsultFormModalMeta';
import CreoSurveyModal from 'src/components/Modal/CreoSurveyModal';
import MetaAgainModal from 'src/components/Modal/MetaAgainModal';
import CreateMetExamModal from 'src/components/Modal/CreateMetExamModal';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_CONSULT_LIST, GET_LEVEL_TEST_META_LIST } from 'src/operations/queries/consult';
import {
  ASSIGN_LEVEL_TEST,
  UPDATE_LEVEL_TEST,
  UPDATE_ASSIGN_LEVEL_TEST,
  ASSIGN_LEVEL_TEST_MULTI_META_EXAM,
  SEND_REPORT_MESSAGE_META,
  SEND_META_PAYMENT_LINK,
  DELETE_ORDER_FOR_PLACEMENT_TEST,
} from 'src/operations/mutations/consult';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { getYearMonthArray, getMetaExamId, getLixileScoreObject, ExportExcel } from 'src/utils';
import { useModal } from 'src/utils/hooks';
import { classStoreData } from 'src/operations/store';
import { useSelector } from 'react-redux';
import imageexcel from 'src/images/xlsicon.png';
import MetaExamMenuGroup from 'src/components/common/MetaExamMenuGroup';
import { META_EXAM_SAVE_REPORT } from 'src/operations/mutations/createTests';
import axios from 'axios';
import { storage } from 'src/utils/hooks';

const { Option } = Select;
const { Search } = Input;
const months = getYearMonthArray('2021-01', 6);
// const pageSize = 12;

const limitTime = (batch, type) => {
  let timeList = {
    A: {
      vocabulary: 10,
      grammar: 15,
      listening: 15,
      reading: 30,
    },
    B: {
      vocabulary: 10,
      grammar: 15,
      listening: 20,
      reading: 35,
    },
    C: {
      vocabulary: 10,
      grammar: 20,
      listening: 25,
      reading: 35,
    },
    PRE: {
      vocabulary: 10,
      grammar: 15,
      listening: 15,
      reading: 30,
    },
  };

  switch (batch) {
    case 'PRE-1':
    case 'PRE-2':
    case 'PRE-3':
      return timeList.A[type];
    case 'A-1':
    case 'A-2':
    case 'A-3':
      return timeList.A[type];
    case 'B-1':
    case 'B-2':
    case 'B-3':
      return timeList.B[type];
    case 'C-1':
    case 'C-2':
    case 'C-3':
      return timeList.C[type];
    default:
      return 0;
  }
};

const MxEditInput = ({ value, record, refetch, companyIdx }) => {
  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [saveReport, { loading: updateLoading }] = useMutation(META_EXAM_SAVE_REPORT);


  const getReport = async (order_id) => {
    const url = process.env.REACT_APP_BACKEND_HOST + '/meta/exam/generate-report';
    const auth = JSON.parse(storage.getItem('culp_token'));
    const headers = {
      'Content-Type': 'application/json',
      Authorization: auth.accessToken,
    };
    const reportData = await axios.post(
      url,
      {
        data: {
          order_id: order_id,
          mx_level: inputValue.toString(),
        },
      },
      { headers: headers },
    );
    setEdit(false);
    refetch();
    console.log(reportData?.data);
    return reportData;
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancel = () => {
    setEdit(false);
    setInputValue(value);
  };

  const handleSave = async () => {
    //exportToS3(record.user_id);
    getReport(record.order_idx);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div>
      {edit ? (
        <>
          <Input type="number" value={inputValue} onChange={handleChange} />
          <Button icon={<SaveOutlined />} loading={updateLoading} onClick={handleSave} />
          <Button icon={<CloseOutlined />} onClick={handleCancel} />
        </>
      ) : (
        <>
          <span>{value}</span>
          <Button type="text" icon={<EditOutlined />} onClick={handleEdit} />
        </>
      )}
    </div>
  );
};

const Consulting = () => {
  const [searchMonth, setSearchMonth] = useState(months[6]); //시험날짜 기준으로 필터링함
  const [visible, setVisible] = useState(false);
  const [visibleRegisterForm, setVisibleRegisterForm] = useState(false);
  const [orderIdx, setOrderIdx] = useState(0);
  const [q, setQ] = useState('');
  const [orderLevelTestIdx, setOrderLevelTestIdx] = useState(0);
  const [filterType, setFilterType] = useState('reserve_date');
  //const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectOrderIdx, setSelectOrderIdx] = useState(null);
  const [selectSurveyIdx, setSelectSurveyIdx] = useState(null);
  const { visible: surveyVisible, showModal: showSurveyModal, handleCancel: handleSurveyCancel } = useModal();
  const { visible: testAgainVisible, showModal: showTestAgainModal, handleCancel: handleTestAgainCancel } = useModal();
  // const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const handleAgainCancel = () => {
    setAgainData(null);
    setSelectOrderIdx(null);
    handleTestAgainCancel();
  };
  const [checkRowList, setcheckRowList] = useState([]);
  const defaultClass = useSelector(classStoreData);
  const [fetchStatus, setFetchStatus] = useState(false);
  const [testdate, setTestDate] = useState();
  const history = useHistory();
  const [againData, setAgainData] = useState(null);
  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
    getCheckboxProps: (record) => ({
      disabled: record.test_result?.status === 2 || record.isAllComplete || record.order_payment,
    }),
  };
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isCreo = dataUser?.getUserData?.campus?.fc_company_idx === 1010 ? true : false;
  const companyIdx = dataUser?.getUserData?.campus?.fc_company_idx;

  const {
    data: dataList,
    loading,
    refetch,
  } = useQuery(GET_CONSULT_LIST, {
    fetchPolicy: 'no-cache',
    skip: !dataUser,
    variables: { campus_idx: dataUser?.getUserData?.campus_idx, ym: searchMonth, take: pageSize, filter_type: filterType },
  });
  useEffect(() => {
    if (fetchStatus == true) {
      refetch();
    }
  }, [fetchStatus]);
  const [sendReportMessage, { loading: loadingSendReport }] = useMutation(SEND_REPORT_MESSAGE_META, {
    onCompleted(data) {
      if (data?.sendReportMessageMeta) {
        openNotification('보냈습니다.');
      }
    },
    onError(error) {
      console.log('createStudyReport error', error);
    },
  });
  const [sendPaymentLink, { loading: loadingsendPaymentLink }] = useMutation(SEND_META_PAYMENT_LINK, {
    onCompleted(data) {
      if (data?.sendMetaPaymentLink) {
        openNotification('보냈습니다.');
      }
    },
    onError(error) {
      console.log('sendPaymentLink error', error);
    },
  });
  const consultList = useMemo(() => {
    if (dataList) {
      return dataList.consultList
        .filter((e) => (filterType === 'reserve_date' ? e : e?.stat === '1'))
        .map((item, key) => {
          const filterReading = item?.order_leveltest?.filter(
            (item) => item?.assigned_tests?.assigned_test_users?.filter((item) => item?.extra_data).length > 0,
          );
          const totalScore = item?.order_leveltest.reduce((acc, cur) => {
            return acc + cur?.assigned_tests?.assigned_test_users[0]?.score || 0;
          }, 0);
          const testUser = filterReading.length
            ? filterReading[0].assigned_tests?.assigned_test_users?.filter((item) => item?.extra_data)
            : null;
          // const lexileScoreRemeinder = Math.round(lexileScore / 5) * 5;

          const lexileScoreRemeinder = testUser && testUser.length ? Math.round(JSON.parse(testUser[0].extra_data).ability / 5) * 5 : null;
          const isPreTest = (item?.order_leveltest[0]?.assigned_tests?.tests?.batch_type || '').startsWith('PRE');

          const batch_type = item?.order_leveltest?.length
            ? item?.order_leveltest.reduce((p, item, key) => {
                return item?.assigned_tests?.tests?.batch_type || p;
              }, '')
            : '';

          let mxVal = testUser && testUser.length ? JSON.parse(testUser[0].extra_data).mx : null;
          let reading_level_test = item?.order_leveltest?.find((item) => item?.assigned_tests?.tests?.test_type === 'reading');
          let lexile_score_val = testUser && testUser.length ? JSON.parse(testUser[0].extra_data).ability : null;

          let mxStr = '';

          if (mxVal !== null) {
            if (batch_type.startsWith('PRE')) {
              mxStr = mxVal ? (parseInt(mxVal) < 0 ? 'Phonics' : parseInt(mxVal) === 0 ? 'Phonics2' : parseInt(mxVal)) : '';
            } else {
              mxStr = mxVal;
            }
          } else if (lexile_score_val && !batch_type.startsWith('PRE')) {
            const mxobj = getLixileScoreObject(lexile_score_val, batch_type);
            mxStr = mxobj?.mx;
          } else if (batch_type.startsWith('PRE') && lexile_score_val) {
            const mxobj = getLixileScoreObject(reading_level_test?.assigned_tests?.assigned_test_users[0]?.score, batch_type);
            mxStr = mxobj?.mx ? (mxobj?.mx < 0 ? 'Phonics' : mxobj?.mx === 0 ? 'Phonics2' : mxobj?.mx) : '';
          }

          return {
            key: `consult-list-${item.idx}`,
            no: dataList.consultList.length - key,
            order_idx: item?.idx,
            stat: item?.stat,
            name: item?.name,
            phone_number: item?.parent_phone,
            school_info: item?.school_info,
            phone: getMetaExamId(item?.user?.birthday, item?.parent_phone),
            isAssigned: item?.order_leveltest[0]?.assigned_tests_idx ? true : false,
            assigned_tests_idx: item?.order_leveltest[0]?.assigned_tests_idx,
            order_leveltest_idx: item?.order_leveltest[0]?.idx,
            order_leveltest: item?.order_leveltest,
            reading_level_test: item?.order_leveltest?.find((item) => item?.assigned_tests?.tests?.test_type === 'reading'),
            tests_idx: item?.order_leveltest[0]?.assigned_tests?.tests?.idx,
            tests_list_idx: item?.order_leveltest.map((item, key) => {
              return item?.assigned_tests?.tests?.idx;
            }),
            isAllComplete: item?.order_leveltest.reduce((check, item, key) => {
              return item?.assigned_tests?.assigned_test_users[0]?.status !== 2 ? false : check && true;
            }, true),
            testStarted: item?.order_leveltest.reduce((check, item, key) => {
              return item?.assigned_tests?.assigned_test_users[0]?.status === 2 ? true : check || false;
            }, false),
            batch_type,
            test_date: item?.schedule
              ? moment(`${item?.order_leveltest[0]?.reserve_date.split('T')[0]} ${item?.schedule?.start_time}`).format('MM-DD HH:mm')
              : moment(item?.order_leveltest[0]?.reserve_date).format('YYYY-MM-DD'),
            test_result: item?.test_result,
            reserved_date: moment(item.idate).format('MM-DD'),
            reserved_timestamp: moment(item.idate),
            completed_date: item?.order_leveltest[0]?.assigned_tests?.assigned_test_users[0]?.end_time
              ? moment(item?.order_leveltest[0]?.assigned_tests?.assigned_test_users[0]?.end_time.split('T')[0]).format('MM-DD')
              : '',
            completed_timestamp: item?.order_leveltest[0]?.assigned_tests?.assigned_test_users[0]?.end_time
              ? moment(item?.order_leveltest[0]?.assigned_tests?.assigned_test_users[0]?.end_time)
              : null,
            user_idx: item?.user?.idx,
            dob: item?.user?.birthday,
            english_name: item?.user?.english_name,
            user_id: item?.user?.id,
            order_survey_idx: item?.order_survey[0]?.idx,
            lexile_score:
              lexileScoreRemeinder === null
                ? ''
                : lexileScoreRemeinder < 0
                ? `${isPreTest ? 'BR ' + Math.abs(lexileScoreRemeinder) : 'BR'}`
                : `${lexileScoreRemeinder}L`,
            lexile_score_val: testUser && testUser.length ? JSON.parse(testUser[0].extra_data).ability : null,
            totalScore: totalScore,
            mx_level: mxVal,
            mxStr,
            report_pdf_meta: testUser && testUser.length ? JSON.parse(testUser[0].extra_data).report_pdf_meta : null,
            assigned_test_user_idx: testUser && testUser.length ? testUser[0].idx : null,
            schedule: item?.schedule,
            extra_data: !!item?.extra_data,
            order_payment: item?.order_payment&&item?.order_payment.length > 0,
          };
        })
        .filter((item) => {
          if (q !== '') {
            return (
              item.name.toString().includes(q) ||
              item.phone.toString().includes(q) ||
              item.batch_type?.toString().includes(q) ||
              item.test_date?.toString().includes(q) ||
              item?.english_name.toLowerCase().includes(q)
            );
          } else {
            return true;
          }
        });
    }
    return [];
  }, [dataList, q, fetchStatus]);
  const [updateLevelTest, { loading: loadingUpdate }] = useMutation(UPDATE_LEVEL_TEST, {
    onCompleted: () => {
      openNotification('테스트 지정 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });
  //최초 지정(create)
  const [assignTest, { loading: loadingAssign }] = useMutation(ASSIGN_LEVEL_TEST, {
    onCompleted: (rdata) => {
      const assigned_tests_idx = rdata?.assignTests?.idx;
      if (assigned_tests_idx && orderLevelTestIdx) {
        updateLevelTest({
          variables: { idx: parseInt(orderLevelTestIdx), assigned_tests_idx },
          refetchQueries: [
            {
              query: GET_CONSULT_LIST,
              variables: { campus_idx: dataUser?.getUserData?.campus_idx, ym: searchMonth, take: pageSize },
            },
          ],
        });
        setOrderLevelTestIdx(0);
      } else {
        alert('error');
      }
      //openNotification('테스트 지정 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  //최초지정후 다시 변경할 때
  const [updateTestType, { loading: loadingTestUpdate }] = useMutation(UPDATE_ASSIGN_LEVEL_TEST, {
    onCompleted: () => {
      refetch();
      openNotification('테스트 변경 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  const [updateTestTypeMetaExam, { loading: loadingTestUpdateMeta }] = useMutation(ASSIGN_LEVEL_TEST_MULTI_META_EXAM, {
    onCompleted: () => {
      refetch();
      openNotification('테스트 변경 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  const [deletePlacementTest, { loading: loadingDelete }] = useMutation(DELETE_ORDER_FOR_PLACEMENT_TEST, {
    onCompleted: () => {
      Modal.info({
        title: '삭제했습니다.',
        onOk: () => {
          refetch();
          setcheckRowList([]);
        },
      });
    },
    onError: (e) => {
      console.log(e);
      alert('error');
    },
  });

  const showSurveyOpenModal = (order_idx, order_survey_idx) => {
    setSelectOrderIdx(order_idx);
    setSelectSurveyIdx(order_survey_idx);
    showSurveyModal();
  };
  const showAgainOpenModal = (record) => {
    setAgainData(record);
    setSelectOrderIdx(record?.order_idx);
    showTestAgainModal();
  };
  const sortByDate = (isDescend) => (a, b) => {
    if (moment(a.test_date).isSame(b.test_date, 'day')) {
      return 0;
    }
    const compared = isDescend ? moment(a.test_date).isAfter(b.test_date, 'day') : moment(a.test_date).isBefore(b.test_date, 'day');
    if (compared) {
      return -1;
    }
    return 1;
  };
  const sortByGrade = (isDescend) => (a, b) => a.school_info.localeCompare(b.school_info);

  const sortByResDate = (isDescend) => (a, b) => {
    const compared = isDescend
      ? a.reserved_timestamp.isAfter(b.reserved_timestamp, 'day')
      : a.reserved_timestamp.isBefore(b.reserved_timestamp, 'day');
    if (compared) {
      return -1;
    }
    return 1;
  };

  const sortByComDate = (isDescend) => (a, b) => {
    if (!a.completed_timestamp || !b.completed_timestamp) {
      return -1;
    }

    const compared = isDescend
      ? a.completed_timestamp.isAfter(b.completed_timestamp, 'day')
      : a.completed_timestamp.isBefore(b.completed_timestamp, 'day');
    if (compared) {
      return -1;
    }
    return 1;
  };

  const columns = [
    {
      title: 'No',
      align: 'center',
      dataIndex: 'no',
      width: 40,
    },
    {
      title: '시험예약일', //시간 들어가야 함
      align: 'center',
      dataIndex: 'test_date',
      sorter: sortByDate(false),
    },
    {
      title: '학생이름',
      align: 'center',
      dataIndex: 'english_name',
      key: 'english_name',
      render: (text, record) => {
        const { order_idx } = record;
        const { test_date } = record;
        return (
          <>
            {text}&nbsp;
            <CustomButton
              onClick={() => {
                handleClickEdit(order_idx);
                setTestDate(test_date);
              }}
              size="small"
              icon={<EditOutlined />}
            />
          </>
        );
      },
    },
    {
      title: '영문이름',
      align: 'center',
      dataIndex: 'name',
      key: 'phone',
      render: (text, record) => {
        return text;
      },
    },

    {
      title: '학년',
      align: 'center',
      dataIndex: 'school_info',
      key: 'school_info',
      sorter: sortByGrade(false),
    },
    {
      title: 'ID',
      align: 'center',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '설문조사',
      align: 'center',
      dataIndex: 'survey',
      key: 'survey',
      width: 50,
      render: (text, record) => {
        const { order_survey_idx, order_idx } = record;
        if (order_survey_idx) {
          return (
            <>
              <CustomButton
                onClick={() => {
                  showSurveyOpenModal(order_idx, order_survey_idx);
                }}
                size="small"
                icon={<SearchOutlined />}
              />
            </>
          );
        }
      },
    },

    {
      title: '신청일',
      align: 'center',
      dataIndex: 'reserved_date',
      key: 'reserved_date',
      sorter: sortByResDate(false),
    },
    {
      title: '시험완료일',
      align: 'center',
      dataIndex: 'completed_date',
      key: 'completed_date',
      sorter: sortByComDate(false),
    },
    {
      title: `결제${isCreo ? '' : ' 여부'}`,
      key: 'result',
      align: 'center',
      dataIndex: 'result',
      width: 120,
      render: (_, record) => {
        if (isCreo) {
          const { order_idx, phone_number, stat, order_payment, extra_data } = record;
          // console.log(order_idx, phone_number, stat, order_payment, extra_data, 'record');
          if (stat === '0')
            return (
              <Tooltip title="Test Type을 선택하세요.">
                <span style={{ color: 'orange', width: '50px', cursor: 'pointer', textAlign: 'center' }}>-</span>
              </Tooltip>
            );
          else if (order_payment && extra_data) {
            return <>결제 완료</>;
          } else if (stat === '3') {
            return <>2개월 미만 학생</>;
          } else if (stat === '4' || (stat === '1' && !order_payment && extra_data))
            return (
              <Button
                loading={loadingsendPaymentLink}
                onClick={() => {
                  sendPaymenturl(order_idx, phone_number);
                }}
                type="primary"
              >
                문자 발송
              </Button>
            );
          else return <>-</>;
        } else
          return (
            <div>
              <svg viewBox="-5 -5 30 30" height="30" width="30">
                <polygon stroke="#FFC105" points="12,3 4,20 20,20" className="triangle" fill="none" strokeWidth="2" />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
          );
      },
    },
    {
      title: 'Test Type',
      align: 'center',
      dataIndex: 'test_type',
      key: 'test_type',
      render: (_, record) => {
        let disabled = false;

        if (record.test_result?.status === 2) {
          //미시험
          disabled = true;
        }
        if (!record.isAssigned) {
          //미지정
          disabled = false;
        }
        let test_idx = record.tests_idx;
        test_idx = record.tests_list_idx
          .filter((item) => {
            return item;
          })
          .sort()
          .join(',');

        if (record.testStarted || (new Date(record.test_date).getTime() < new Date().getTime() && record.isAssigned)) {
          return record.batch_type;
        }

        return (
          <Tooltip title="시험예약일 당일에는 Test Type 변경이 불가능합니다. 반드시 시험예약일 이전에 변경하시기 바랍니다.">
            <SelectTestType
              disabled={disabled}
              onChange={(test_idx, tests) => {
                handleAssignTest(test_idx, tests, record, defaultClass);
              }}
              loading={loadingAssign || loadingUpdate || loadingTestUpdate || loadingTestUpdateMeta}
              value={test_idx}
            />
          </Tooltip>
        );
      },
    },
    {
      title: 'Lexile',
      key: 'lexile_score',
      align: 'center',
      dataIndex: 'lexile_score',
      width: 70,
      render: (text, record) => {
        if (!record.test_result || !record.isAllComplete) {
          return '';
        }
        if (text) {
          return <span onClick={() => history.push(`/test/${record?.reading_level_test?.assigned_tests_idx}`)}>{text}</span>;
        }
        return '';
      },
    },
    {
      title: 'MX Level',
      key: 'mx_level',
      align: 'center',
      dataIndex: 'mx_level',
      width: 110,
      render: (text, record) => {
        if (!record.test_result || !record.isAllComplete) {
          return '';
        }
        if (text !== null) {
          if (record.batch_type.startsWith('PRE')) {
            return text ? (
              <Tooltip title={`-1 입력시 Phonics로, 0 입력시 Phonics2로 수정됩니다.`}>
                <>
                  <MxEditInput
                    value={parseInt(text) < 0 ? 'Phonics' : parseInt(text) === 0 ? 'Phonics2' : parseInt(text)}
                    record={record}
                    refetch={refetch}
                    companyIdx={companyIdx}
                  />
                </>
              </Tooltip>
            ) : (
              ''
            );
          }
          return <MxEditInput value={text} record={record} refetch={refetch} companyIdx={companyIdx} />;
        }
        if (record.lexile_score_val && !record.batch_type.startsWith('PRE')) {
          const mxobj = getLixileScoreObject(record.lexile_score_val, record.batch_type);
          return <MxEditInput value={mxobj?.mx} record={record} refetch={refetch} companyIdx={companyIdx} />;
        } else if (record.batch_type.startsWith('PRE') && record.lexile_score_val) {
          const mxobj = getLixileScoreObject(record.lexile_score_val, record.batch_type);
          return mxobj?.mx ? (
            <Tooltip title={`-1 입력시 Phonics로, 0 입력시 Phonics2로 수정됩니다.`}>
              <>
                <MxEditInput
                  value={mxobj?.mx < 0 ? 'Phonics' : mxobj?.mx === 0 ? 'Phonics2' : mxobj?.mx}
                  record={record}
                  refetch={refetch}
                  companyIdx={companyIdx}
                />
              </>
            </Tooltip>
          ) : (
            ''
          );
        }
        return '';
      },
    },
    {
      title: '시험결과',
      key: 'result',
      align: 'center',
      dataIndex: 'result',
      render: (_, record) => {
        if (!record.isAssigned) {
          return '미지정';
        }
        if (!record.test_result || !record.isAllComplete) {
          return '미응시';
        }
        if (record.stat === '1' && record.extra_data && isCreo && !record.order_payment) {
          return <Tooltip title="결제 후 확인 가능합니다.">-</Tooltip>;
        }
        if (record.report_pdf_meta) {
          return (
            <a href={record.report_pdf_meta} target="_blank">
              보기
            </a>
          );
        }
        // const assigned_tests_idx = record?.test_result?.assigned_tests?.idx;

        return (
          <a href={`/MetaExam/MetaReport?id=${record.order_idx}&company_idx=${companyIdx}`} target="_blank">
            보기
          </a>
        );
      },
    },
    {
      title: '추가시험',
      ket: 'phone',
      align: 'center',
      dataIndex: 'phone',
      render: (_, record) => {
        if (!record.isAssigned || !record.test_result || !record.isAllComplete) {
          return <Tooltip title={'시험이 완료된 경우에만 추가시험 배치가 가능합니다.'}>-</Tooltip>;
        }
        return <Button onClick={() => showAgainOpenModal(record)}>등록</Button>;
      },
    },
  ];

  const columnsExcel = [
    {
      title: 'No',
      align: 'center',
      dataIndex: 'no',
      width: 40,
    },
    {
      title: '시험예약일', //시간 들어가야 함
      align: 'center',
      dataIndex: 'test_date',
      sorter: sortByDate(false),
    },
    {
      title: '학생이름',
      align: 'center',
      dataIndex: 'english_name',
      key: 'english_name',
      render: (text, record) => {
        const { order_idx } = record;
        return (
          <>
            {text}&nbsp;
            <CustomButton
              onClick={() => {
                handleClickEdit(order_idx);
              }}
              size="small"
              icon={<EditOutlined />}
            />
          </>
        );
      },
    },
    {
      title: '영문이름',
      align: 'center',
      dataIndex: 'name',
      key: 'phone',
      render: (text, record) => {
        return text;
      },
    },
    {
      title: '학년',
      align: 'center',
      dataIndex: 'school_info',
      key: 'school_info',
      sorter: sortByGrade(false),
    },
    {
      title: 'ID',
      align: 'center',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '신청일',
      align: 'center',
      dataIndex: 'reserved_date',
      key: 'reserved_date',
      sorter: sortByResDate(false),
    },
    {
      title: '완성일자',
      align: 'center',
      dataIndex: 'completed_date',
      key: 'completed_date',
      sorter: sortByComDate(false),
    },
    {
      title: '결제 여부',
      key: 'result',
      align: 'center',
      dataIndex: 'result',
      width: 50,
      render: (_) => {
        return (
          <div>
            <svg viewBox="-5 -5 30 30" height="30" width="30">
              <polygon stroke="#FFC105" points="12,3 4,20 20,20" className="triangle" fill="none" strokeWidth="2" />
              Sorry, your browser does not support inline SVG.
            </svg>
          </div>
        );
      },
    },
    {
      title: 'Test Type',
      align: 'center',
      dataIndex: 'batch_type',
      key: 'batch_type',
    },
    {
      title: 'Lexile',
      key: 'lexile_score',
      align: 'center',
      dataIndex: 'lexile_score',
      width: 70,
    },
    {
      title: 'MX Level',
      key: 'mx_level',
      align: 'center',
      dataIndex: 'mxStr',
      width: 50,
      render: (text, record) => {
        if (text) {
          if (record.batch_type.startsWith('PRE')) {
            return text ? (parseInt(text) < 0 ? 'Phonics' : parseInt(text) === 0 ? 'Phonics2' : parseInt(text)) : '';
          }
          return text;
        }
        if (record.lexile_score_val && !record.batch_type.startsWith('PRE')) {
          const mxobj = getLixileScoreObject(record.lexile_score_val, record.batch_type);
          return mxobj?.mx;
        } else if (record.batch_type.startsWith('PRE') && record.lexile_score_val) {
          const mxobj = getLixileScoreObject(record.reading_level_test?.assigned_tests?.assigned_test_users[0]?.score, record.batch_type);
          return mxobj?.mx ? (mxobj?.mx < 0 ? 'Phonics' : mxobj?.mx === 0 ? 'Phonics2' : mxobj?.mx) : '';
        }
        return '';
      },
    },
    {
      title: '시험결과',
      key: 'result',
      align: 'center',
      dataIndex: 'result',
      render: (_, record) => {
        if (!record.isAssigned) {
          return '미지정';
        }
        if (!record.test_result || !record.isAllComplete) {
          return '미응시';
        }
        if (record.report_pdf_meta) {
          return (
            <a href={record.report_pdf_meta} target="_blank">
              보기
            </a>
          );
        }
        const assigned_tests_idx = record?.test_result?.assigned_tests?.idx;

        return (
          <a href={`/MetaExam/MetaReport?id=${record.order_idx}&company_idx=${companyIdx}`} target="_blank">
            보기
          </a>
        );
      },
    },
  ];

  function handleClickEdit(order_idx) {
    setOrderIdx(order_idx);
    setVisible(true);
    setFetchStatus(false);
  }
  function handleAssignTest(test_idx, tests, record, defaultClass) {
    const title = `레벨테스트(${record.name})`;
    const start_time = record?.schedule ? record.test_date : `${record.test_date} 11:00`;
    console.log(title, start_time, test_idx);

    setOrderLevelTestIdx(record.order_leveltest_idx);

    test_idx = test_idx.split(',');
    test_idx = test_idx.filter((item, key) => {
      return item;
    });
    let params = [
      {
        title: title,
        start_time: start_time,
        limit_time: 60,
        user_idx: record.user_idx,
        test_idx: 0,
        order_idx: record.order_idx,
      },
    ];
    if (test_idx.length > 0) {
      let currentTests = tests.filter((item) => {
        return test_idx.includes(`${item.idx}`);
      });
      params = currentTests.map((item, key) => {
        return {
          title: title,
          start_time: start_time,
          limit_time: limitTime(item.batch_type, item.test_type),
          user_idx: record.user_idx,
          test_idx: Number(item.idx),
          order_idx: record.order_idx,
        };
      });
    }

    updateTestTypeMetaExam({
      variables: isCreo
        ? {
            list: params,
            // product_code: $product_code,
            // product_code_retest: $product_code_retest,
            product_price: 20000,
            product_price_retest: 0,
            // product_name: $product_name,
            retest_condition_month: 2,
            retest_condition_type: 'all',
            // returnUrl: $returnUrl,
            // cancelUrl: $cancelUrl
          }
        : { list: params },
      refetchQueries: [
        {
          query: GET_CONSULT_LIST,
          variables: { campus_idx: dataUser?.getUserData?.campus_idx, ym: searchMonth },
        },
      ],
    });
  }

  // const showModal = () => {
  //   setVisible(true);
  // };

  // const handleOk = () => {
  //   //
  // };

  // const handleCancel = () => {
  //   setVisible(false);
  // };

  function handleSelectedDelete() {
    if (checkRowList.length === 0) {
      Modal.info({ title: '삭제할 항목을 선택하세요.' });
      return;
    }
    Modal.confirm({
      title: '정말 삭제하시겠습니까?',
      onOk: () => {
        const selected_order_idx_list = checkRowList.map((item) => {
          const [, , idx] = item.split('-');
          return Number(idx);
        });
        deletePlacementTest({ variables: { idx_list: selected_order_idx_list } });
      },
    });
  }

  function handleStatisDownload() {
    const fileName = `Meta-${moment().format('YYYY-MM-DD')}`;
    ExportExcel(columnsExcel, consultList, fileName);
  }
  function sendSms() {
    if (checkRowList.length === 0) {
      Modal.info({ title: '항목을 선택하세요.' });
      return;
    }

    const selected_order_idx_list = checkRowList.map((item) => {
      const [, , idx] = item.split('-');
      return Number(idx);
    });
    const payCheck = (ele) => {
      let bool = true;
      if (isCreo && ele?.extra_data) {
        {
          bool = !ele.order_payment;
        }
      }
      return bool;
    };
    const inputs = consultList
      ?.filter((ele) => selected_order_idx_list.includes(ele?.order_idx) && ele?.report_pdf_meta && payCheck(ele))
      .map((item) => {
        return {
          phone: item?.phone_number?.replace(/[^0-9]/g, ''),
          name: item?.name,
          report_pdf_meta: item?.report_pdf_meta,
          mx_level: item?.mx_level,
          lexile_score: item?.lexile_score,
          lexile_score_val: item?.lexile_score_val,
          user_idx: dataUser?.getUserData?.idx,
          receiver_user_idx: item?.user_idx,
        };
      });
    if (inputs.length > 0) {
      Modal.confirm({
        title: 'Are you sure to send SMS?',
        onOk: () => {
          sendReportMessage({ variables: { inputs } });
        },
      });
    }
    return;
  }
  function sendPaymenturl(order_idx, phone_number) {
    if (order_idx && phone_number) {
      const url = process.env.REACT_APP_BACKEND_HOST + '/nicepay/payment/' + order_idx;
      Modal.confirm({
        title: 'Are you sure to send SMS?',
        onOk: () => {
          sendPaymentLink({ variables: { payment_link: url, phone: phone_number?.replace(/[^0-9]/g, '') } });
        },
      });
    }
    return;
  }
  const getStatus = (params) => {
    setFetchStatus(params);
  };
  const handleTableChange = (_, size) => {
    setPageSize(size);
  };
  return (
    <>
      <MetaExamMenuGroup currentMenu="metaExam" />
      <HeaderTitle level={4}>Meta Exam</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="placement-test-halfwrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 10,
              }}
            >
              <Space>
                <Tooltip
                  title={
                    filterType === 'reserve_date'
                      ? '클릭하면 월별로 시험을 완료한 리스트를 확인할 수 있습니다.'
                      : '클릭하면 시험을 예약한 리스트를 확인할 수 있습니다.'
                  }
                >
                  <Switch
                    checkedChildren="Completed Date"
                    unCheckedChildren="Reserved Date"
                    onChange={(checked) => setFilterType(checked ? 'completed_date' : 'reserve_date')}
                  />
                </Tooltip>
              </Space>
              <Search
                className="headitems-search"
                placeholder="Search by Id, Name, Phone"
                // onSearch={(val) => setQ(val)}
                onChange={(e) => setQ(e.target.value)}
                allowClear
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
              />
              <Tooltip title={`'전체' 선택시 전체 데이터 조회 가능합니다.`}>
                <Select
                  size={`default`}
                  style={{
                    width: 100,
                  }}
                  value={searchMonth}
                  onChange={(val) => {
                    setSearchMonth(val);
                  }}
                >
                  <Option key={`search-month-option-null`} value={null}>
                    전체
                  </Option>
                  {months &&
                    months.map((month, key) => (
                      <Option key={`search-month-option${key}`} value={month}>
                        {month}
                      </Option>
                    ))}
                </Select>
              </Tooltip>
              <CustomButton
                style={{ marginLeft: 10 }}
                onClick={() => {
                  setVisibleRegisterForm(true);
                }}
              >
                등록
              </CustomButton>
              <CustomButton type="danger" danger style={{ marginLeft: 10 }} onClick={handleSelectedDelete} loading={loadingDelete}>
                삭제
              </CustomButton>
              <img
                src={imageexcel}
                alt="download"
                style={{ width: 25, height: 25, cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}
                onClick={handleStatisDownload}
              />
            </div>

            {consultList.length === 0 && filterType !== 'filterType' ? (
              <div
                style={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '25px',
                  fontWeight: 'bold',
                }}
              >
                시험을 완료한 학생이 없습니다.
              </div>
            ) : (
              <CustomTable
                className="meta-exam"
                dataSource={consultList}
                loading={loading}
                columns={columns}
                pagination={{ pageSize:pageSize, onChange: handleTableChange, showSizeChanger: true, position: ['bottomCenter'] }}
                size="small"
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'fixed', bottom: '30px', right: '45px' }}>
              {isCreo && (
                <>
                  <Popover content={popOverContent} title="Meta Exam 응시료 결제 문자 전송 방법" trigger="click">
                    <Button type="primary" style={{ margin: 'auto 0' }}>
                      결제 문자 발송 방법
                    </Button>
                  </Popover>
                  &nbsp;
                </>
              )}
            </div>
          </HalfWrapper>
        </Col>
      </Row>

      <ConsultFormModalMeta
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        order_idx={orderIdx}
        campus_idx={dataUser?.getUserData?.campus_idx}
        searchMonth={searchMonth}
        onComplete={refetch}
        handleStatus={getStatus}
        testDetail={testdate}
      />

      {visibleRegisterForm && (
        <CreateMetExamModal
        visible={visibleRegisterForm}
          campus_idx={dataUser?.getUserData?.campus_idx}
          ym={searchMonth}
          onCancel={() => {
            setVisibleRegisterForm(false);
          }}
          refetch={refetch}
        />
      )}
      <MetaAgainModal
        visible={testAgainVisible}
        campus_idx={dataUser?.getUserData?.campus_idx}
        ym={searchMonth}
        onCancel={handleAgainCancel}
        orderIdx={selectOrderIdx}
        againData={againData}
        refetch={refetch}
      />

      <CreoSurveyModal visible={surveyVisible} handleCancel={handleSurveyCancel} orderIdx={selectOrderIdx} surVeyIdx={selectSurveyIdx} />
    </>
  );
};

export default Consulting;

const SelectTestType = ({ onChange, ...rest }) => {
  const defaultClass = useSelector(classStoreData);
  const { data: levelTestList } = useQuery(GET_LEVEL_TEST_META_LIST, {
    variables: { type: 'NA' },
  });
  const tests = useMemo(() => {
    if (levelTestList) {
      const tmp = levelTestList.testsList.tests
        .map(({ idx, title, batch_type, test_type }) => {
          let shortTitle = title.replace('Placement Test ', '');
          if (batch_type) {
            shortTitle += ' Type-' + batch_type;
          }
          return { idx, title: shortTitle, batch_type, test_type };
        })
        .sort((a, b) => a.batch_type.localeCompare(b.batch_type));
      const pre = tmp.filter((e) => e.batch_type === 'PRE-1');
      const tmp2 = tmp.filter((e) => e.batch_type !== 'PRE-1');

      return [...pre, ...tmp2];
    }
    return [];
  }, [levelTestList]);

  let batchTypes = {};
  for (let i = 0; i < tests.length; i++) {
    if (tests[i].batch_type) {
      batchTypes[tests[i].batch_type]
        ? batchTypes[tests[i].batch_type].push(tests[i].idx)
        : (batchTypes[tests[i].batch_type] = [tests[i].idx]);
    }
  }
  return (
    <Select
      style={{ minWidth: '100px' }}
      size="small"
      placeholder="select type"
      onChange={(val) => {
        onChange(val, tests);
      }}
      {...rest}
    >
      {Object.keys(batchTypes).length != 0 &&
        Object.keys(batchTypes).map((key) => (
          <Option key={`test-list-key${key}`} value={batchTypes[key].sort().join(',')}>
            Type-{key}
          </Option>
        ))}
    </Select>
  );
};

const popOverContent = () => (
  <div>
    <p>1. [Meta Exam]을 응시할 학생을 등록합니다.</p>
    <p>2. 등록한 학생의 [Test Type]을 선택합니다.</p>
    <p>3. [결제 문자 발송] 버튼을 클릭하면, 학부모 핸드폰으로 [Meta Exam 결제 링크]가 전송됩니다.</p>
    <p>4. 학부모님은 전송된 결제 링크를 통해 응시료를 결제하시면 완료됩니다.</p>
    <p>5. 학부모님 결제 완료 후 "새로 고침"(F5) 버튼을 누르면 [문자 발송]이 [결제 완료] 상태로 바뀐 것을 확인하실 수 있습니다.</p>
    <br />
    <p style={{ fontWeight: 'bold' }}>주의 사항</p>
    <p>1. 학생 등록시 [Meta Exam 결제 링크]를 전송받을 학부모님의 핸드폰 번호를 정확히 입력해야 합니다.</p>
    <p>2. 응시료를 결제해야 시험 결과를 볼 수 있으니, 시험 완료 전에 결제해 주시기 바랍니다.</p>
    <p>3. 시험 당일에는 [Test Type] 변경이 불가하니, [Test Type] 선택시 주의하시기 바랍니다.</p>
    <p>4. 2개월 이내 재시험을 보는 학생은 응시료 결제 링크를 발송하지 않아도 됩니다.</p>
  </div>
);
