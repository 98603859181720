import React, { useState, useMemo, useEffect } from 'react';
import { Col, Typography, Space, Row, Button, DatePicker, Tooltip } from 'antd';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_USER_ASSIGNMENT_LIST } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { useQuery, useMutation } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import WritingReportCoachMenuGroup from 'src/components/common/WritingReportCoachMenuGroup';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import BookReportAnswerModal from 'src/components/Modal/BookReportAnswerModal';
import GeneratePdfButton from 'src/components/common/GeneratePdfButton';
import BackButton from 'src/components/common/BackButton';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const CampusResults = (props) => {
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');
  const location = useLocation();
  const { idx: idxForTeacher } = useParams();
  const [assignedAITopics, setAssignedAITopics] = useState([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [currentChatList, setCurrentChatList] = useState([]);
  const [userTopicId, setUserTopicId] = useState();
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [localState, setLocalState] = UseHistoryState(
    {
      class_idx: 0,
      page: 0,
      class_type: 0, //class_type - 0: 진행, 1: 종료
      start_date: dayjs().subtract(3, 'M').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
    },
    'ai_topic_result',
  );
  dayjs.extend(customParseFormat);
  dayjs.extend(advancedFormat);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  const { data, loading, refetch } = useQuery(AI_TOPIC_USER_ASSIGNMENT_LIST, {
    // variables: { class_idx, page, take },
    variables: {
      type: 'book-report',
      student_idx: idxForTeacher ? parseInt(idxForTeacher) : null,
      start_date: localState.start_date ? localState.start_date : undefined,
      end_date: localState.end_date ? localState.end_date : undefined,
    },
    fetchPolicy: 'no-cache',
  });
  const token = getCurrentToken();
  const { loading: userLoading, data: userMe } = useQueryProxy(USER_ME, {
    variables: { token },
    skip: !token || data?.aiTopicUserAssignmentList.total!==0 || location?.state?.name,
    onError(error) {
      // isLogedIn(false);
      // history.push('/login');
      // setLoaded(true);
    },
  });
  useEffect(() => {
    if (data?.aiTopicUserAssignmentList) {
      const tmp = data?.aiTopicUserAssignmentList.ai_topic_user_assignments.sort(
        (a, b) => new Date(b.ai_topic_date_assignment.start_date) - new Date(a.ai_topic_date_assignment.start_date),
      );
      setAssignedAITopics(
        tmp.map((item, index) => {
          let ai_topic_date_assignmentData = JSON.parse(item?.ai_topic_date_assignment?.data || '{}');
          return {
            ...item,
            ai_topic: {
              ...item.ai_topic,
              data: JSON.parse(item.ai_topic.data || '{}'),
              topic: ai_topic_date_assignmentData?.revisedPrompt || item.ai_topic.topic,
            },
            no: tmp.length - index,
            start_date: data?.aiTopicDateAssignmentDetails?.start_date,
            rubric_data: JSON.parse(item.rubric_data),
            ai_request_responses: item.ai_request_responses.map((item) => {
              return {
                ...item,
                promt: item.prompt_text,
                content: item.completion_text,
                data: JSON.parse(item.data),
                created: item.idate,
              };
            }),
          };
        }),
      );

      setTotal(data?.aiTopicUserAssignmentList.total);
    }

    if (isRefresh) {
      refetch();
      props.history.replace({ search: '' });
    }
  }, [data, isRefresh]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    {
      title: 'Assigned Date',
      dataIndex: 'ai_topic_date_assignment',
      align: 'center',
      key: 'date',
      width: 150,
      sorter: (a, b) =>
        a.ai_topic_date_assignment.start_date && a.ai_topic_date_assignment.start_date.localeCompare(b.ai_topic_date_assignment.start_date),
      render: (text, record) => {
        return (
          <Space size="middle">
            <span style={{ fontWeight: 'bold' }}>{record.ai_topic_date_assignment.start_date.substr(0, 10)}</span>
          </Space>
        );
      },
    },
    {
      title: 'Title',
      key: '',
      // width: 250,
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="middle">
            <span style={{ fontWeight: 'bold', color: 'blue' }}>{record.ai_topic.data?.book?.topic}</span>
          </Space>
        );
      },
    },
    {
      title: 'Prompt',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip color="#fff" placement="topLeft" title={record.ai_topic.topic}>
            {record.ai_topic.topic}
          </Tooltip>
        );
      },
    },
    {
      title: 'Completeion Time',
      dataIndex: 'ai_topic_date_assignment',
      align: 'center',
      key: 'date',
      width: 150,
      // sorter: (a, b) =>
      //   a.ai_topic_date_assignment.start_date && a.ai_topic_date_assignment.start_date.localeCompare(b.ai_topic_date_assignment.start_date),
      render: (text, record) => {
        return record?.end_time ? (
          <Space size="middle">
            <span>
              {record?.end_time.slice(0, 10)} {record?.end_time.slice(11, 19)}
            </span>
          </Space>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Learning Time',
      key: '',
      width: 120,
      align: 'center',
      render: (text, record) => {
        // if (record?.rubric_data?.toefl) {
        //   return <Space size="middle">{record?.rubric_data?.toefl}</Space>;
        // } else return <Space size="middle">-</Space>;
        let start = new Date(record.start_time);
        let end = new Date(record.end_time);
        return record.end_time && record.start_time ? (
          <>
            {Math.floor((end - start) / 1000 / 60)}:{Math.floor((end - start) / 1000) % 60}
          </>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: 'TOEFL Score',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record?.rubric_data?.toefl) {
          return <Space size="middle">{record?.rubric_data?.toefl}</Space>;
        } else return <Space size="middle">-</Space>;
      },
    },
    {
      title: 'Rubric Score',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status < 1 || !record.rubric_data) {
          return <Space size="middle">-</Space>;
        }

        if (record.rubric_data) {
          return <Space size="middle">{record.rubric_data?.score}</Space>;
        }
      },
    },

    {
      title: 'ACC/WPM',
      key: 'wpm',
      dataIndex: 'wpm',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status < 2) {
          return <span>-</span>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => openRecordingDataModal({ ...record, accuracy: `${record.score}%` }, record?.user?.name)}>
                {record.score} / {text}
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Report PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        if (!record.rubric_data) {
          return <span>-</span>;
        } else {
          return (
            <Space size="middle">
              {record.rubric_data?.pdf_url ? (
                <GeneratePdfButton
                  saveData={async (url) => {
                    await handleGeneratePdf(record.idx, { ...record.rubric_data, pdf_url: url });
                  }}
                  url={`https://culp.cloubot.com/aicoach/report/generate/${record.idx}`}
                  pdfUrl={record.rubric_data?.pdf_url}
                  filename={`-writing-coach-report-result-${record.idx}`}
                />
              ) : (
                '-'
              )}
            </Space>
          );
        }
      },
    },
  ];

  const handleGeneratePdf = async (idx, data) => {
    await updateAITopicUserAssignment({
      variables: {
        idx,
        rubric_data: JSON.stringify(data),
      },
    });
    refetch();
  };

  function handleTableChange(pagination, filters) {
    setLocalState((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  const onChangeStartDate = (a, date) => {
    let clearCheck = a === null;
    if (clearCheck) {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: undefined,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: dayjs(date).format('YYYY-MM-DD'),
        };
      });
    }
  };
  const onChangeEndDate = (a, date) => {
    let clearCheck = a === null;
    if (clearCheck) {
      setLocalState((prev) => {
        return {
          ...prev,
          end_date: undefined,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          end_date: dayjs(date).format('YYYY-MM-DD'),
        };
      });
    }
  };
  const studentName = data?.aiTopicUserAssignmentList?.total!==0
    ? data?.aiTopicUserAssignmentList?.ai_topic_user_assignments[0]?.user?.name
    : location?.state?.name
    ? location?.state?.name
    : userMe?.me?.name;
  return (
    <>
      {/* <WritingReportCoachMenuGroup currentMenu="result" /> */}
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col
              className="contenttest-title-result"
              span={12}
              xs={4}
              md={6}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}
            >
              <Title level={4}>Writing Coach Result</Title>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Title style={{ margin: '0 5px' }} level={4}>
                  Student :{' '}
                </Title>
                <span style={{ fontSize: '15px' }}>
                  {' '}
                  {data?.aiTopicUserAssignmentList?.ai_topic_user_assignments[0]?.user?.name ?? location?.state?.name ?? userMe?.me?.name}
                </span>
              </div>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}
            >
              {idxForTeacher && (
                <>
                  <BackButton />
                  &nbsp; &nbsp;
                </>
              )}
              <DatePicker onChange={onChangeStartDate} value={dayjs(localState.start_date)} allowClear={false} />
              <div style={{ height: 32, display: 'flex', alignItems: 'center' }}>~</div>
              <DatePicker onChange={onChangeEndDate} value={dayjs(localState.end_date)} allowClear={false} />
              &nbsp;
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={assignedAITopics}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: localState.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>
        {viewAnswerModal && (
          <BookReportAnswerModal
            visible={viewAnswerModal}
            chatList={currentChatList}
            handleCancel={() => {
              setViewAnswerModal(false);
            }}
            topic={topic}
          />
        )}
        <RecordingDataModal
          data={{ ...recordingData, currentUserName, className: '' }}
          isShow={isRecordingVisible}
          onPrint={null}
          componentRef={null}
          onOk={handleOkForRecordingData}
          hidePrint={true}
        />
      </Col>
    </>
  );
};

export default withRouter(CampusResults);
