import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import FullScreenButton from 'src/components/common/FullScreenButton';
import { userInfoVar } from 'src/apollo/cache';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: 25px;
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const StageTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 30px 0;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;

const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  background-image: url(${(props) => props.bg});
  background-size: 72px 50px;
  background-repeat: no-repeat;
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;
const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  max-width: 800px;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const ArrowIcon = styled.div`
  width: 52px;
  min-width: 52px;
  height: 45px;
  min-height: 45px;
  margin: 0 35px;
  background-image: url(${(props) => props.src});
  background-size: 52px 45px;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const CardWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 1280px) {
    width:100%;
  }
  
`;

const dummy = [
  { english: 'cat', korean: '고양이' },
  { english: 'dog', korean: '개' },
  { english: 'tree', korean: '나무' },
  { english: 'book', korean: '책' },
  { english: 'sun', korean: '태양' },
  { english: 'moon', korean: '달' },
];
export default function CardMatchingGame() {
  const [selected, setSelected] = useState([null, null]);
  // const [seconds, setSeconds] = useState(0);
  const [answerList, setAnswerList] = useState([]);
  const history = useHistory();
  const mixed = useMemo(() => {
    const tmp = [];
    dummy.map((e, i) => {
      tmp.push({ isEnglish: true, text: e.english, idx: i, key: i + 'english' });
      tmp.push({ isEnglish: false, text: e.korean, idx: i, key: i + 'korean' });
    });
    return _.shuffle(tmp).map((e, i) => {
      e.checkidx = i;
      return e;
    });
  }, [dummy]);
  // useEffect(() => {
  //   const timerId = setTimeout(() => {
  //     setSeconds((prev) => prev + 1);
  //   }, 1000);
  //   return () => clearTimeout(timerId);
  // }, [seconds]);
  useEffect(() => {
    if (answerList.length === dummy.length) {
      alert('학습 완료');
    }
  }, [ answerList, dummy]);
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '80%', width: '100%', alignItems: 'center' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '0 50px' }}>
                <StageTitle>
                  <TitleSpan color={'#453ec8'}>Memory Game</TitleSpan> Click the letters and solve the puzzle.
                </StageTitle>
                <NumberText>
                  Number{' '}
                  <QuizNumber>
                    <span style={{ color: '#433fc8' }}>{`03`}</span>/{`10`}
                  </QuizNumber>
                </NumberText>
              </RowFlex>
              <CardWrapper>
                {mixed.map((e) => (
                  <Card
                  selected={selected}
                  setSelected={setSelected}
                  key={e.key}
                  vocadata={e}
                  answerList={answerList}
                  setAnswerList={setAnswerList}
                />
                ))}
              </CardWrapper>
            </ColumnFlex>
          </Main>
        </MainWrapper>
        <StageButtonWrapper>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_left.png'}></ArrowIcon>
          <RowFlex style={{ justifyContent: 'space-between', width: '45%', height: '100%' }}>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_flashcard.png`} active={false} isDone={true} />
              Flashcard
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_check.png`} active={false} isDone={true} />
              Check
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} active={false} isDone={true} />
              Typing
            </StageButtonText>
            <StageButtonText active={true}>
              <StageButton bg={`/images/LevelUp/study/sub/g_quiz.png`} active={true} isDone={false} />
              Quiz
            </StageButtonText>
          </RowFlex>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_right.png'}></ArrowIcon>
        </StageButtonWrapper>
      </Wrapper>
    </>
  );
}
const Card = ({ vocadata, key, setSelected, selected, answerList, setAnswerList }) => {
  const [modal, matchPopUp] = Modal.useModal();
  const rightMatchPopUp = () => {
    let secondsToGo = 7;
    const instance = modal.success({
      title: 'Good Job',
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 100);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 100);
  };
  const wrongMatchPopUp = () => {
    let secondsToGo = 7;
    const instance = modal.error({
      title: 'Try Again',
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 100);
    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 100);
  };
  const handleToggle = () => {
    if (!selected.includes(vocadata)) {
      if (!selected[0]) {
        // setClickToggle(true);
        setSelected([vocadata, null]);
      } else {
        if (selected[1]) {
          alert('잠시 뒤에 시도하세요.');
        } else {
          if (selected[0].idx === vocadata.idx) {
            // setClickToggle(true);
            setSelected([null, null]);
            setAnswerList((prev) => [...prev, vocadata.idx]);
            setTimeout(() => {
              rightMatchPopUp();
            }, 600);
          } else {
            setSelected([selected[0], vocadata]);
            setTimeout(() => {
              wrongMatchPopUp();
              setSelected([null, null]);
            }, 600);
          }
        }
      }
    }
  };
  return (
    <>
    {matchPopUp}
      {selected.includes(vocadata)|| answerList.includes(vocadata.idx)?
       <Front onClick={handleToggle} clickToggle={selected.includes(vocadata) || answerList.includes(vocadata.idx)} key={key}>
       {(selected.includes(vocadata) || answerList.includes(vocadata.idx)) && vocadata.text}
     </Front>:<Back onClick={handleToggle} clickToggle={selected.includes(vocadata) ||  answerList.includes(vocadata.idx)} key={key}></Back>}
    </>
  );
};

const Back = styled.div`
  width: 270px;
  height: 90px;
  margin: 12px;
  background-image: url('/images/LevelUp/game/card_back.png');
  background-repeat: no-repeat;
  background-size: 263px 90px;
  @media (max-width: 1280px) {
    width: 210px;
    height: 70px;
    margin: 20px;
    background-size: 204px 70px;
  }
`;

const Front = styled.div`
  width: 270px;
  height: 90px;
  margin: 12px;
  font-size: 23px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fef189;
  background-image: url('/images/LevelUp/game/card_front.png');
  background-repeat: no-repeat;
  background-size: 263px 90px;
  @media (max-width: 1280px) {
    width: 210px;
    height: 70px;
    margin: 20px;
    background-size: 204px 70px;
  }
`;
