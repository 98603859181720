// import React from 'react';
import React,{useEffect, useMemo, useState} from 'react';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Typography, Input, Button, Modal, Select, Tag, Space, Anchor } from 'antd';
import { Link } from 'react-router-dom';
import { HeaderTitle, HalfWrapper, ListTitleWrapper } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import imagesearch from '../../../src/images/search.png';
import imageprinter from '../../../src/images/printer.png';
import imageexcel from '../../../src/images/xlsicon.png';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import * as queries from 'src/operations/queries';
import { SHIPPING_ORDER_LIST} from 'src/operations/queries/pay';
import { GET_USER_INFO } from 'src/operations/queries/campus';

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const dataSource = [
  {
    key: '1',
    columnnumber: '79',
    columnone: '2021-10-02',
    columntwo: '교보서점',
    columnthree: '닌자 학원',
    columnfour: '2021-10-02',
    columnfive: '카드결제',
    columnsix: '320,000',
    columnseven: ['주문서'],
    columneight: ['주문서'],
  },
  {
    key: '2',
    columnnumber: '79',
    columnone: '2021-10-02',
    columntwo: '교보서점',
    columnthree: '닌자 학원',
    columnfour: '2021-10-02',
    columnfive: '카드결제',
    columnsix: '320,000',
    columnseven: ['주문서'],
    columneight: ['주문서'],
  },
  {
    key: '3',
    columnnumber: '79',
    columnone: '2021-10-02',
    columntwo: '교보서점',
    columnthree: '닌자 학원',
    columnfour: '2021-10-02',
    columnfive: '카드결제',
    columnsix: '320,000',
    columnseven: ['주문서'],
    columneight: ['주문서'],
  },
  {
    key: '4',
    columnnumber: '79',
    columnone: '2021-10-02',
    columntwo: '교보서점',
    columnthree: '닌자 학원',
    columnfour: '2021-10-02',
    columnfive: '카드결제',
    columnsix: '320,000',
    columnseven: ['주문서'],
    columneight: ['주문서'],
  },
];

const columns1 = [
  {
    title: 'No.',
    dataIndex: 'order_date',
    key: 'order_date',
    width: '60px',
  },
  {
    title: '주문 날짜',
    dataIndex: 'columnone',
    key: 'columnone',
  },
  {
    title: '지사',
    dataIndex: 'columntwo',
    key: 'columntwo',
  },
  {
    title: '학원 명',
    dataIndex: 'columnthree',
    key: 'columnthree',
  },
  {
    title: '결제 날짜',
    dataIndex: 'columnfour',
    key: 'columnfour',
  },
  {
    title: '결제수단',
    dataIndex: 'columnfive',
    key: 'columnfive',
  },
  {
    title: '합계',
    dataIndex: 'columnsix',
    key: 'columnsix',
  },
  {
    title: '결제상태',
    dataIndex: 'columnseven',
    key: 'columnseven',
    render: (tags) => (
      <>
        {tags.map((tag) => {
          return <span className="statustbl status-red status-blue"></span>;
        })}
      </>
    ),
  },
  {
    title: '배송상태',
    dataIndex: 'columneight',
    key: 'columneight',
    render: (tags) => (
      <>
        {tags.map((tag) => {
          return (
            <Select placeholder="수취완료" defaultValue="">
              <Option key="" value="">
                수취완료
              </Option>
            </Select>
          );
        })}
      </>
    ),
  },
];

const columns = [
  // {
  //   title: 'No',
  //   dataIndex: 'No',
  //   key: 'No',
  //   align: 'left',
  // },
  {
    title: 'Order Date',
    dataIndex: 'order_date',
    key: 'order_date',
    align: 'center',
    render: (item) => {
      return item ? moment(item).format('YYYY-MM-DD') : '';
    },
  },
  {
    title: 'Branch Name',
    dataIndex: 'branch_name',
    key: 'branch_name',
  },
  {
    title: 'Pay Method',
    dataIndex: 'pay_method',
    key: 'pay_method',
  },
  {
    title: 'School Name',
    dataIndex: 'school_name',
    key: 'school_name',
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    key: 'payment_date',
    align: 'center',
    render: (item) => {
      return item ? moment(item).format('YYYY-MM-DD') : '';
    },
  },
  {
    title: 'Sum Price',
    dataIndex: 'sum_price',
    key: 'sum_price',
  },
  {
    title: 'Payment Status',
    dataIndex: 'delivery_status',
    key: 'delivery_status',
    render: (item) => {
      if(item === 0){
        // return <span className="statustbl1 status-red status-blue"></span>;
        return <span>접수</span>
      }
      if(item === 1){
        return <span>주문서확인(출고준비)</span>
        // return <span className="statustbl2 status-red status-blue"></span>;
      }if(item === 2){
        return <span>송장입력(출고완료)</span>
        // return <span className="statustbl   status-red status-blue"></span>;
      }
    },

    // render: (item) => (
    //   <>
    //     {tags.map((tag) => {
    //       return <span className="statustbl status-red status-blue"></span>;
    //     })}
    //   </>
    // ),
  },
  {
    title: 'Delivery Status',
    dataIndex: 'delivery_status',
    key: 'delivery_status',
    render :(item) => {
      return (
        <Select placeholder="수취완료" defaultValue="">
              <Option key="" value="">
                {item}
              </Option>
            </Select>
      )
    }
    // render: (item) => (
    //   <>
    //     {item.map((tag) => {
    //       return (
    //         <Select placeholder="수취완료" defaultValue="">
    //           <Option key="" value="">
    //             수취완료
    //           </Option>
    //         </Select>
    //       );
    //     // })}
    //   </>
    // ),
  },
];


const { Title, Text } = Typography;
const { Option } = Select;

//
const Shipping = () => {
  const [shippingListData,setShippingListData] = useState([]);
  const [totamt,setTotAmt] = useState();
  const [displayList,setDisplayList] = useState(false);
  const [shippingFilter,setShippingFilter] = useState(false);
    const token = getCurrentToken();
    const { data } = useQueryProxy(queries.getUser.USER_ME, {
      variables: { token },
    });
    const { data: dataUser } = useQuery(GET_USER_INFO);
    const { data: shippingList} = useQuery(SHIPPING_ORDER_LIST, {
      variables: {
        company_idx: dataUser?.getUserData?.campus?.fc_company_idx,
      },
    });
  let myShippingList = useMemo(() => {
    if(shippingList){
      return shippingList.MyShippingList
      .map((item, key) => {
        const { order_date, payment_date, pay_method, sum_price,delivery_status,branch_name,school_name} = item;
        return {
          key: key,
          No: key + 1,
          order_date,
          payment_date,
          pay_method,
          sum_price,
          delivery_status,
          branch_name,
          school_name,
        };
      })
    }
  },[shippingList])

  const searchShippingList = (value) => {
    setTotAmt(value);
    setDisplayList(true)
       let filterResult  = myShippingList?.filter((item) =>{  
             if (value === '') {
              return item;
            } 
            else if (JSON.stringify(item?.sum_price)?.toLowerCase().includes(value?.toLowerCase())) {
              return item;
            }
        })
        setShippingFilter(filterResult)
  }
  const history = useHistory();
  const type = userInfoVar()?.type;
  if (type && type !== 4) {
    history.replace('/');
  }

  return (
    <Col className="ordershipping-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
      <Row gutter={[24, 16]}>
        <Col className="ordershipping-wrapper-main-a" span={12} style={{ textAlign: 'left' }}>
          <HeaderTitle level={4}>Shipping (주문 / 배송 관리)</HeaderTitle>
        </Col>
        <Col className="orderadmin-imagesec" span={12} style={{ textAlign: 'left' }}>
          <Anchor>
            <Link>
              <img src={imagesearch} />
            </Link>
            <Link>
              <img src={imageprinter} />
            </Link>
            <Link>
              <img src={imageexcel} />
            </Link>
          </Anchor>
        </Col>
      </Row>
      <MainBlock className="ordershipping-wrapper-mainblock">
        <Row gutter={[24, 16]} className="zoom-top-head">
          <Col className="bookcredit-top-title" span={8} style={{ textAlign: 'left' }}>
            <Title level={5}>주문 결제 / 배송 페이지</Title>
          </Col>
          <Col className="ordershipping-top-rightform" span={16} style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}>
            <div className="purchase-select-option">
              <Select placeholder="지역" defaultValue="" >
                    <Option key="" value="">
                        2021
                      </Option>
              </Select>

              <Select placeholder="10월" defaultValue="">
                <Option key="" value="">
                  10월
                </Option>
              </Select>
            </div>
            <div>
              <label>총 구매 금액 :</label>
              <input type="text" onChange={(e)=>searchShippingList(e.target.value)}/> 원
            </div>
          </Col>
        </Row>
        <CustomTable
          className="ordershipping-table-table"
          pagination={{ pageSize: 12 }}
          // dataSource={dataSource}
          dataSource={displayList?shippingFilter:myShippingList}
          columns={columns}
          size="small"
          color="#edf3fb"
          scroll={{ y: 'calc(100vh - 254px)' }}
        />
        <Link to={`/setting/pay`}>
          <div className="settingicon-table">구매페이지 설정</div>
        </Link>
      </MainBlock>
    </Col>
  );
};

export default Shipping;
