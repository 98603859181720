import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Col, Row, DatePicker, Modal, Typography, Space, Select } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import * as queries from 'src/operations/queries';
import { DELETE_AI_TOPIC_ASSIGNMENT_GROUP } from 'src/operations/mutations/aiTopic';
import LevelUpBookReportMenuGroup from 'src/components/common/LevelUpBookReportMenuGroup';

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const { Title } = Typography;
const ExternalStudyAssignResultTable = styled(CustomTable)`
  colgroup {
    display: table-column-group !important;
  }
`;

const sortByDate = (isDescend) => (a, b) => {
  if (moment(a.org_date).isSame(b.org_date, 'day')) {
    return 0;
  }
  const compared = isDescend ? moment(a.org_date).isAfter(b.org_date, 'day') : moment(a.org_date).isBefore(b.org_date, 'day');
  if (compared) {
    return -1;
  }
  return 1;
};

let columns = [];
if (window.location.pathname === '/level-up/report/result') {
  columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '등록일',
      dataIndex: 'date',
      align: 'center',
      width: 100,
      sorter: sortByDate(false),
    },
    {
      key: 'Title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
    },
  ];
} else {
  columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '등록일',
      dataIndex: 'date',
      align: 'center',
      width: 170,
      sorter: sortByDate(false),
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
    },
    {
      key: 'delete',
      title: 'Delete',
      dataIndex: 'delete',
      align: 'center',
      render: (_, row) => {
        const { key, done_count, onDeleteAITopicAssignGroup } = row;
        return (
          <Button
            type="danger"
            onClick={() => {
              onDeleteAITopicAssignGroup(key, done_count > 0);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];
}
const AssignList = ({ user }) => {
  const [classes, setClasses] = useState([]);
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { selectedClassIdx, onClassChange } = useCampusClass();

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  const {
    data: fetchAITopicGroupList,
    refetch,
    loading,
  } = useQuery(queries.aiTopic.AI_TOPIC_GROUP_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      class_idx: selectedClassIdx,
      type: 'book-report',
    },
  });

  const [deleteAITopicAssignmentGroup, { loading: loadingDelete }] = useMutation(DELETE_AI_TOPIC_ASSIGNMENT_GROUP, {
    onCompleted: (res) => {
      if (!res?.deleteAITopicAssignmentGroup) {
        alert('삭제 권한이 없습니다.');
        return;
      }
      refetch();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
  }, [classQuery]);

  const aiTopicGroupList = useMemo(() => {
    if (fetchAITopicGroupList?.aiTopicGroups) {
      let fetchData = null;
      const class_idx = parseInt(selectedClassIdx);

      if (class_idx > 0) {
        fetchData = fetchAITopicGroupList?.aiTopicGroups.ai_topic_groups.filter((item) => item?.class.idx === class_idx);
      } else {
        fetchData = fetchAITopicGroupList?.aiTopicGroups.ai_topic_groups;
      }

      return fetchData.reduce((acc, cur, index) => {
        const totalCnt = cur?.assigned_external_study?.length;

        const completed = cur?.assigned_external_study?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && moment(`${moment(cur?.start_time).format('YYYY-MM-DD')}`).isBefore(`${moment().format('YYYY-MM-DD')}`)) {
            acc++;
          }
          return acc;
        }, 0);

        const done_count = cur?.assigned_external_study?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && cur.user_study && cur.user_study.length > 0) {
            acc++;
          }
          return acc;
        }, 0);

        const obj = {
          title: `${cur?.title}`,
          date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          start: moment(cur?.begin_date).format('YYYY-MM-DD'),
          end: moment(cur?.end_date).format('YYYY-MM-DD'),
          //testTime: cur?.test_time,
          className: cur?.class?.name,
          total: `${completed} / ${totalCnt}`,
          No: index + 1,
          key: cur.idx,
          class_idx: cur?.class?.idx,
          done_count: done_count,
          type: cur?.type,
          onDeleteAITopicAssignGroup: handleAITopicAssignGroupDelete,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchAITopicGroupList, classes, selectedClassIdx]);

  //화살표 함수는 hoisting 이 안 되서...
  function handleAITopicAssignGroupDelete(ai_topic_group_idx, isAiTopicAssignResult) {
    console.log('ai_topic_group_idx', ai_topic_group_idx);
    Modal.confirm({
      icon: null,
      //title: 'Delete',
      content: (
        <Title level={5} style={{ textAlign: 'left', lineHeight: 2 }}>
          {isAiTopicAssignResult ? (
            <>
              이미 학습한 학생이 있습니다. <br /> 삭제하면 학생이 학습한 내용도 함께 삭제됩니다. <br />
              그래도 삭제하시겠습니까?
            </>
          ) : (
            <>삭제하시겠습니까?</>
          )}
        </Title>
      ),
      onOk: () => {
        deleteAITopicAssignmentGroup({ variables: { idx: ai_topic_group_idx } });
      },
      onCancel: () => {
        //do nothing
      },
    });
  }

  return (
    <>
      <LevelUpBookReportMenuGroup currentMenu="level-up-report-assign-list" />
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>Book Topic Assign</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              {/* <div className="manage-action-wrapper-main">
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
          width: '100%',
        }}
      >
        <HeaderTitle level={4}>AI Topic Assign List</HeaderTitle> */}
              <div className="toparea-main">
                <Space>
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    selectedClassIdx={selectedClassIdx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px' }}
                  />
                </Space>
              </div>
              {/* </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <HalfWrapper className="books-result-halfwrapper">
                <ExternalStudyAssignResultTable
                  style={{ width: '98%' }}
                  dataSource={aiTopicGroupList}
                  columns={columns}
                  key={(r) => r.idx}
                  pagination={false}
                  size="small"
                  color="#edf3fb"
                  scroll={{ y: 'calc(100vh - 254px)' }}
                  loading={loading || loadingDelete}
                />
              </HalfWrapper>
            </Col>
          </Row>
          {/* </div> */}
        </MainBlock>
      </Col>
    </>
  );
};

export default AssignList;
