import React, { useMemo } from 'react';
import { Button, Modal, Typography } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { useQuery } from '@apollo/client';
import { DELIVERY_TRACKING_INFO } from 'src/operations/queries/delivery';
import { DELEVERY_LEVEL_TEXT_LIST } from 'src/constants/common';

const { Title } = Typography;

const DeliveryTrackingModal = ({ visible, onCancel, info }) => {
  const [t_code, t_invoice, delivery_company_name] = useMemo(() => {
    if (info) {
      const company_code = info?.order_payment?.delivery_company_info?.company_code;
      const invoice_number = info?.order_payment?.invoice_number;
      const delivery_company_name = info?.order_payment?.delivery_company_info?.company_name;
      return [company_code, invoice_number, delivery_company_name];
    }
    return [null, null, null];
  }, [info]);
  const { data, loading, error } = useQuery(DELIVERY_TRACKING_INFO, {
    variables: { t_code, t_invoice },
    skip: !t_code || !t_invoice,
    fetchPolicy: 'no-cache',
  });
  const dataSource = useMemo(() => {
    const list = data?.deliveryTrackingInfo?.trackingDetails;
    if (list) {
      //console.log(list.reverse());
      //object 배열이라서 reverse 가 안 되서, reduce로 역순 정렬함.
      console.log(list);
      return list
        .reduce((acc, cur) => [cur, ...acc], [])
        .map((item, key) => {
          return {
            key: `order-list-${key}`,
            level: DELEVERY_LEVEL_TEXT_LIST[item?.level],
            time: item?.timeString,
            where: item?.where,
            desc: item?.kind,
          };
        });
    }
    return [];
  }, [data]);

  return (
    <Modal
      visible={visible}
      loading={loading}
      title={`${info?.order_payment?.product_name} 배송정보`}
      onCancel={onCancel}
      width={'60%'}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button key="back" onClick={() => onCancel()}>
            확인
          </Button>
        </div>
      }
    >
      {error?.message && <p>{error?.message}</p>}
      <Title level={5}>
        운송장 번호: {delivery_company_name} / {t_invoice}
      </Title>
      <CustomTable size="small" columns={columns} dataSource={dataSource} pagination={false} />
    </Modal>
  );
};

export default DeliveryTrackingModal;

const columns = [
  {
    title: '단계',
    dataIndex: 'level',
    key: 'level',
    align: 'center',
  },
  {
    title: '시간',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
  },
  {
    title: '현위치',
    dataIndex: 'where',
    key: 'where',
    align: 'center',
  },
  {
    title: '처리현황',
    dataIndex: 'desc',
    key: 'desc',
    align: 'center',
  },
];
