import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Layout, Menu, Typography, Spin } from 'antd';
import FullScreenButton from 'src/components/common/FullScreenButton';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  CloseCircleOutlined,
  SettingOutlined,
  ReloadOutlined,
  LoadingOutlined,
  RobotOutlined,
  MobileOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import OpenAiModal from '../Modal/OpenAiModal';
import CampusModal from '../Modal/CampusModal';
import TeacherModal from 'src/components/Modal/TeacherModal';
import StudentModal from '../Modal/StudentModal';
import VimeoVideo from 'src/components/common/VimeoVideo';
import ReturnToManagerBar from 'src/components/common/ReturnToManagerBar';
import OpenAppModal from '../Modal/OpenAppModal';
import styled from 'styled-components';

import { isLogedIn, userInfoVar, userVar } from 'src/apollo/cache';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from '../../utils';
import { storage, useModal } from 'src/utils/hooks';
import * as dummy from 'src/dummy';
import * as queries from 'src/operations/queries';
import { SYNC_CAMPUS_DATA } from 'src/operations/mutations/campus';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool,isPturn } from 'src/utils';
import { settings } from 'src/dummy/settings';

const { Header: HeaderWrapper } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

const HeaderLevelUp = styled(HeaderWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: 'space-between';
  height: 75px;
  /* height: 75*height +'px'; */
  min-height: 55px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  background-image: ${(props) => !props.isStatic && `url('/images/cloud.png')`};
  background-position: ${(props) => !props.isStatic && `center bottom`};
  background-repeat: ${(props) => !props.isStatic && 'no-repeat'};
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;

const ImgButton = styled.img`
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const useCheckScreen = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return height > 900 ? 1 : height / 900;
};
const Header = ({ collapsed, onCollapse, isStatic = false }) => {
  let height = useCheckScreen();
  const params = queryString.parse(window.location.search);
  const companyName = useSelector(classStoreData);
  const [headerBackground, setHeaderBackground] = useState('#fff');
  const [guideIndex, setGuideIndex] = useState(-1);
  const [guidePPT, setGuidePPT] = useState(null);
  const [guideText, setGuideText] = useState(null);
  const [appData, setAppData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const token = getCurrentToken();
  const client = useApolloClient();
  const userType = useMemo(() => {
    if (userInfoVar()) {
      return userVar()[userInfoVar().type];
    }
    return '';
  }, []);
  const { data } = useQueryProxy(USER_ME, { variables: { token } });
  const { data: userInfo } = useQuery(queries.getUser.GET_USER);
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const [openAiModal, setOpenAiModal] = useState(false);

  const [syncCampusData, { loading: loadingSync }] = useMutation(SYNC_CAMPUS_DATA);

  const onLogout = () => {
    if(window.confirm('로그아웃 하시겠습니까?')){
      isLogedIn(false);
      userInfoVar(null);
      client.clearStore();
      client.cache.gc();
      storage.removeItem('culp_token');
      window.location.reload();
    }
    else{
      return;
    }
  };
  useEffect(() => {
    if (isPturn(companyName)) {
      //학교, 강사만 영상 본다.
      if (companyName === 'englishtap') {
        if (['campus', 'manager'].includes(userType)) {
          switch (location.pathname) {
            case '/':
            case '/dashboard':
              setGuideIndex(1);
              break;
            case '/level-up/booklist':
              setGuideIndex(2);
              break;
            case '/level-up/assignedlist':
              setGuideIndex(3);
              break;
            case '/level-up/results/readers':
              setGuideIndex(4);
              break;
            case '/level-up/results/extreaders':
              setGuideIndex(5);
              break;
          }
        }
      } else if (['campus', 'manager'].includes(userType)) {
        switch (location.pathname) {
          case '/':
          case '/dashboard':
            setGuideIndex(1);
            break;
          case '/classroom':
            setGuideIndex(6);
            break;
          case '/book/order':
            setGuideIndex(2);
            break;
          case '/folder':
            setGuideIndex(4);
            break;
          case '/test/manage/list':
            setGuideIndex(5);
            break;
          case '/placement-test':
            setGuideIndex(0);
            break;
          case '/report-card':
            setGuideIndex(3);
            break;
          case '/sms-manage':
            setGuideIndex(8);
            break;
          case '/recording':
            setGuideIndex(9);
            break;
          case '/level-up/booklist':
            setGuideIndex(10);
            break;
          case '/level-up/results/readers':
            setGuideIndex(11);
            break;
          case '/level-up/results/extreaders':
            setGuideIndex(12);
            break;
          case '/level-up/assignedlist':
            setGuideIndex(13);
            break;
          default:
            break;
        }
      } else if (['student'].includes(userType)) {
        //학생용 영상
        switch (location.pathname) {
          case '/':
          case '/dashboard':
            setGuideIndex(7);
            break;
          case '/recording':
            setGuideIndex(9);
            break;
          default:
            break;
        }
      }
    } else if (companyName === 'creo') {
      //학교, 강사만 영상 본다.
      if (['campus'].includes(userType)) {
        switch (location.pathname) {
          case '/learning':
          case '/learning-class':
            setGuidePPT('creo-homework');
            setGuideText('Manual- Homework');
            break;
          case '/classroom':
            setGuidePPT('creo-class-mgmt');
            setGuideText('Manual- Classroom');
            break;
          case '/students':
          case '/students-batch':
            setGuidePPT('creo-student-mgmt');
            setGuideText('Manual- Students');
            break;
          case '/teachers':
            setGuidePPT('creo-teacher-mgmt');
            setGuideText('Manual- Teachers');
            break;
          case '/recording':
            setGuidePPT('V-tube-teachers');
            setGuideText('Manual- V-Tube');
            break;
          default:
            break;
        }
      } else if (['manager'].includes(userType)) {
        //학생용 영상
        switch (location.pathname) {
          case '/':
          case '/dashboard':
            setGuidePPT('creo-teacher-dashboard');
            setGuideText('Orientation');
            break;
          case '/learning':
          case '/learning-class':
            setGuidePPT('creo-homework');
            setGuideText('Manual- Homework');
            break;
          case '/classroom':
            setGuidePPT('creo-class-mgmt');
            setGuideText('Manual- Classroom');
            break;
          case '/students':
          case '/students-batch':
            setGuidePPT('creo-student-mgmt');
            setGuideText('Manual- Students');
            break;
          case '/recording':
            setGuidePPT('V-tube-students');
            setGuideText('Manual- V-Tube');
            break;
          default:
            break;
        }
      } else if (['student'].includes(userType)) {
        //학생용 영상
        switch (location.pathname) {
          case '/recording':
            setGuidePPT('V-tube-students');
            setGuideText('Manual- V-Tube');
            break;
          default:
            break;
        }
      }
    } else {
      if (['campus', 'manager'].includes(userType)) {
        switch (location.pathname) {
          case '/teachers':
            setGuideIndex(5);
            break;
          case '/classroom':
            setGuideIndex(4);
            break;
          case '/students':
            setGuideIndex(2);
            break;
          case '/test/manage/action/add':
            setGuideIndex(3);
            break;
          case '/test/manage/action/assign':
            setGuideIndex(0);
            break;
            break;
          default:
            if (/\/test\/manage\/action\/assign\/\d+/.test(location.pathname)) {
              setGuideIndex(0);
            } else if (/\/i-frame/.test(location.pathname)) {
              setGuideIndex(1);
            }
        }
      }
    }

    if (location.pathname === '/level-up/bookreport') {
      setHeaderBackground('transparent');
    }
  }, [location.pathname, companyName, userType]);

  const returnId = useMemo(() => {
    return window.localStorage.getItem('culp_return_id');
  }, []);
  const [isExistVideo, videoItem] = useMemo(() => {
    if (guideIndex === -1) {
      return [false, null];
    }
    if (isPturn(companyName)) {
      if (dummy.test.guideVideosForAfterSchool[companyName]?.[guideIndex]?.videos) {
        return [true, dummy.test.guideVideosForAfterSchool[companyName]?.[guideIndex]?.videos];
      }
    } else {
      if (dummy.test.guideVideos[guideIndex].videos) {
        return [true, dummy.test.guideVideos[guideIndex].videos];
      }
    }
    return [false, null];
  }, [companyName, guideIndex]);

  const [isExistPPT, pptLink] = useMemo(() => {
    if (!guidePPT) {
      return [false, null];
    }
    if (dummy.test.guidePPT[companyName] && dummy.test.guidePPT[companyName][guidePPT]) {
      return [true, dummy.test.guidePPT[companyName][guidePPT]];
    }
    return [false, null];
  }, [companyName, guidePPT]);

  const inputRef = useRef(null);

  const sharedProps = {
    style: {
      width: '100%',
    },
    defaultValue: '. ',
    ref: inputRef,
  };

  useEffect(() => {
    if (params.toApp && isMobile) {
      const curAppUrl = params.toApp;
      let culptoken = localStorage.getItem('culp_token')
        ? JSON.parse(localStorage.getItem('culp_token'))
        : {
            accessToken: '',
            refreshToken: '',
          };
      params.toApp = undefined;
      history.replace({
        search: queryString.stringify(params),
      });
      const url = `${curAppUrl}://oauth${location.pathname}?accessToken=${culptoken.accessToken}&refreshToken=${culptoken.refreshToken}`;
      setTimeout(() => {
        setAppData({
          url,
        });
      }, 1000);
    }
  }, [params.toApp]);

  const renderMobileButton = () => {
    let culptoken = localStorage.getItem('culp_token')
      ? JSON.parse(localStorage.getItem('culp_token'))
      : {
          accessToken: '',
          refreshToken: '',
        };

    return (
      <>
        {isMobile && !window.ReactNativeWebView ? (
          <Menu.Item
            key="mobile"
            onClick={() => {
              setAppData({
                url: `culpmobile://oauth/login?accessToken=${culptoken.accessToken}&refreshToken=${culptoken.refreshToken}`,
              });
            }}
          >
            <MobileOutlined style={{ fontSize: '1.6em', color: '#08c', verticalAlign: 'middle' }} />
          </Menu.Item>
        ) : null}
      </>
    );
  };

  const rightContent = (
    <>
      <Menu
        key="user"
        mode="horizontal"
        style={{ height: '100%', background: 'transparent', border: 0, flex: 8, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isExistVideo && companyName === 'englishtap' && (
          <Menu.Item key="info" style={{ paddingTop: '10px' }}>
            <VimeoVideo width={35} marginTop={14} thumpImage="/images/guide.png" item={videoItem} />{' '}
          </Menu.Item>
        )}
        {(location.pathname === '/' || location.pathname === '/dashboard') &&
          (userType === 'campus' || userType === 'manager' || userType === 'student' || userType === 'fc') && (
            <>
              <Menu.Item key="settings">
                <SettingOutlined style={{ fontSize: '1.6em', color: '#08c', verticalAlign: 'middle' }} onClick={() => showModal('edit')} />
              </Menu.Item>
            </>
          )}
        {/* {renderMobileButton()} */}
        {(location.pathname === '/' || location.pathname === '/dashboard') &&
          userType === 'campus' &&
          userInfo?.getUserData?.campus?.external_id &&
          settings[companyName]['syncActive'] && (
            <Menu.Item key="sync">
              {loadingSync ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: '1.6em', color: '#08c', verticalAlign: 'middle' }} />} />
              ) : (
                <ReloadOutlined style={{ fontSize: '1.6em', color: '#08c', verticalAlign: 'middle' }} onClick={() => syncCampusData()} />
              )}
            </Menu.Item>
          )}
        {isExistVideo && companyName !== 'englishtap' && (
          <Menu.Item key="info" style={{ paddingTop: '10px' }}>
            <VimeoVideo width={35} marginTop={14} thumpImage="/images/guide.png" item={videoItem} />{' '}
          </Menu.Item>
        )}
        {/* another guide video btn  */}
        {isAfterSchool(companyName) && ['campus', 'manager'].includes(userType) && ['/dashboard', '/'].includes(location.pathname) && (
          <Menu.Item key="info" style={{ paddingTop: '10px' }}>
            <VimeoVideo
              width={35}
              marginTop={14}
              thumpImage="/images/guide.png"
              item={dummy.test.guideVideosForAfterSchool[companyName]?.[14]?.videos}
            />{' '}
          </Menu.Item>
        )}
        <Menu.Item key="info" style={{ paddingTop: '10px' }}>
          <FullScreenButton
            element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
            activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
          />
        </Menu.Item>
        {/*  */}
        <SubMenu
          key="SubMenu"
          title={
            <>
              <Avatar

              //src="//image.zuiidea.com/photo-1487412720507-e7ab37603c6f.jpeg?imageView2/1/w/200/h/200/format/webp/q/75|imageslim"
              />
              <span style={{ color: '#000', marginLeft: 10 }}>
                <span className="username">
                  {data?.me?.name.length > 5 ? `${data?.me?.name.slice(0, 5)}...` : data?.me?.name}{' '}
                  <Text strong>({data?.me?.id.length > 9 ? `${data?.me?.id.slice(0, 9)}...` : data?.me?.id})</Text>
                </span>
                <span onClick={onLogout} style={{ color: '#999', marginLeft: 20 }}>
                  로그아웃
                  <LogoutOutlined
                    style={{
                      marginLeft: 5,
                      verticalAlign: 'middle',
                      fontSize: 25,
                      color: '#ccc',
                    }}
                    key="logout"
                  />
                </span>
              </span>
            </>
          }
        >
          {/* <Menu.Item key="SignOut" onClick={onLogout}>
            <span>Sign out</span>
          </Menu.Item> */}
        </SubMenu>
        {window.Android ? (
          <Menu.Item key="close" onClick={() => window.Android.closeApp()}>
            <span style={{ color: '#999' }}>
              <CloseCircleOutlined
                style={{
                  marginLeft: 20,
                  verticalAlign: 'middle',
                  fontSize: 25,
                  color: '#ccc',
                }}
                key="close"
              />
            </span>
          </Menu.Item>
        ) : null}
      </Menu>
    </>
  );

  return (
    <>
      {returnId && <ReturnToManagerBar returnId={returnId} />}
      <HeaderLevelUp
        height={height}
        isStatic={isStatic}
        style={{
          position: isStatic ? 'fixed' : 'relative',
        }}
      >
        <div style={{ visibility: `${userInfoVar()?.level_test === 1 ? 'hidden' : 'visible'}`, flex: 1 }}>
          <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            {collapsed ? (
              <MenuFoldOutlined
                style={{
                  fontSize: 35,
                }}
                className="trigger"
                onClick={onCollapse}
              />
            ) : (
              <MenuUnfoldOutlined
                style={{
                  fontSize: 35,
                }}
                className="trigger"
                onClick={onCollapse}
              />
            )}
            {!isStatic && (
              <div
                onClick={() => {
                  history.push('/level-up');
                }}
                style={{ cursor: 'pointer' }}
              >
                {' '}
                <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} />
              </div>
            )}
            {isExistPPT && (
              <h3
                style={{
                  height: '80%',
                  margin: '0 0 0 20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  textWrap: 'nowrap',
                }}
                onClick={() => {
                  window.open(pptLink, '_blank');
                }}
              >
                <img style={{ height: '70%', margin: '0 5px 0 0' }} src="/images/v-tube-guide.png"></img>
                {` ${guideText}`}
              </h3>
            )}
          </div>
        </div>
        {!isStatic ? <>{rightContent}</> : null}
      </HeaderLevelUp>

      {userType === 'fc' && (
        <TeacherModal userInfo={userInfo?.getUserData} popupMode="edit" handleCancel={handleCancel} visible={visible} myInfo={true} />
      )}
      {userType === 'campus' && (
        <CampusModal
          campusInfo={userInfo?.getUserData?.campus}
          popupMode="edit"
          handleCancel={handleCancel}
          visible={visible}
          campus_idx={userInfo?.getUserData?.campus?.idx}
        />
      )}
      {userType === 'manager' && (
        <TeacherModal userInfo={userInfo?.getUserData} popupMode="edit" handleCancel={handleCancel} visible={visible} myInfo={true} />
      )}

      {userType === 'student' && (
        <StudentModal userInfo={userInfo?.getUserData} popupMode="edit" handleCancel={handleCancel} visible={visible} myInfo={true} />
      )}
      <OpenAiModal
        visible={openAiModal}
        handleCancel={() => {
          setOpenAiModal(false);
        }}
      />
      {appData !== null && <OpenAppModal visible={true} handleClose={() => setAppData(null)} url={appData?.url} />}
    </>
  );
};

export default Header;
