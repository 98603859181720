import { createSlice, configureStore } from '@reduxjs/toolkit';

export const classSlice = createSlice({
  name: 'className',
  initialState: {
    value: 'default',
  },
  reducers: {
    updateClassSlice: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const learningMenuSlice = createSlice({
  name: 'learningMenu',
  initialState: {
    menu_type: '',
    title: '',
  },
  reducers: {
    learningMenuUpdate: (state, action) => {
      state.menu_type = action.payload.menu_type;
      state.title = action.payload.title;
    },
  },
});

export const externalBookFavSlice = createSlice({
  name: 'externalBookFav',
  initialState: {
    list: [],
  },
  reducers: {
    updateExternalBookFav: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { updateClassSlice } = classSlice.actions;
export const learningMenuSliceActions = learningMenuSlice.actions;
export const { updateExternalBookFav } = externalBookFavSlice.actions;
export const classStoreData = (state) => state.className.value;
export const learningMenuStoreData = (state) => state.learningMenu;
export const externalBookFavStoreData = (state) => state.externalBookFav.list;

export default configureStore({
  reducer: {
    className: classSlice.reducer,
    learningMenu: learningMenuSlice.reducer,
    externalBookFav: externalBookFavSlice.reducer,
  },
});
