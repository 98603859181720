import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DELETE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
// import BookList from '../../AiCoach/Assign/bookList';
import StatReportAssign from './statReportAssign';
import CampusResults from './campusResults';
import StudentResults from './studentResults';
import AssignList from './assignList';

const AITopicList = () => {
  const { type = 'list' } = useParams();
  const [deleteAITopicUserAssignment] = useMutation(DELETE_AI_TOPIC_USER_ASSIGNMENT);

  const handleAITopicAssignGroupDelete = async (data) => {
    try {
      await deleteAITopicUserAssignment({ variables: data });
    } catch (e) {
      console.log(e);
    }
  };

  const [user, setUser] = useState({});
  const token = getCurrentToken();
  const { data } = useQueryProxy(USER_ME, {
    variables: { token },
  });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);
  const returnPage = (type, usertype) => {
    if (usertype === 0) {
      switch (type) {
        case 'result':
          return <StudentResults user={user} />;
        default:
          return null;
      }
    } else {
      switch (type) {
        case 'result':
          return <CampusResults user={user} />;
        case 'statreportassign':
          return <StatReportAssign user={user} />;
        case 'assign-list':
          return <AssignList onAITopicAssignGroupDelete={handleAITopicAssignGroupDelete} user={user} />;
        default:
          return <AssignList onAITopicAssignGroupDelete={handleAITopicAssignGroupDelete} user={user} />;
        // return <BookList user={user} />;
      }
    }
  };
  return (
    <>
      <Row gutter={[24, 16]}>{returnPage(type, user.type)}</Row>
    </>
  );
};

export default AITopicList;
