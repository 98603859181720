import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Row, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter, useHistory } from 'react-router';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_DATE_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { useQuery, useMutation } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import BackButton from 'src/components/common/BackButton';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import AITalkingReportAnswerModal from 'src/components/Modal/AITalkingReportAnswerModal';
import { MESSAGE_LIST } from 'src/operations/mutations/aiGroupTopic';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const AITalkingDetailsByDate = (props) => {
  const { idx } = useParams();

  const [ai_topic_user_assignments, setAssignedAITopics] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [currentChatList, setCurrentChatList] = useState([]);
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [threadIdx, setThreadIdx] = useState();
  const [curUser, setCurUser] = useState();
  const [scoreClick, setScoreClick] = useState(false);
  const [lastMsgId, setLastMsgId] = useState(null);
  const [lastMsg, setLastMsg] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [aiTopic, setAiTopic] = useState();
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      page: 0,
    },
    'ai_date_details',
  );
  const parseMessage = (content) => {
    let value = '';
    let valueObj = {};
    if (content.length > 0) {
      for (let i = 0; i < content.length; i++) {
        if (content[i]?.text?.value.replace(/(\r\n|\n|\r)/gm, '').startsWith('{')) {
          let temp = JSON.parse(content[i]?.text?.value);
          for (let key in temp) {
            if (key.endsWith('image_url') || key.endsWith('audio_url')) {
              valueObj[key] = temp[key];
            } else if (key in valueObj) {
              valueObj[key] = valueObj[key] === temp[key] ? valueObj[key] : valueObj[key] + temp[key];
            } else {
              valueObj[key] = temp[key];
            }
          }
        } else {
          value += content[i]?.text?.value;
        }
      }
    }

    return value === '' ? valueObj : value;
  };

  const mapContentData = (contentText, data) => {
    if (typeof contentText === 'string') {
      return [{ ...data, content: contentText }];
    }
    if (typeof contentText === 'object') {
      let result = [];
      let keys = Object.keys(contentText);
      let text = '';
      for (let i = 0; i < keys.length; i++) {
        if (typeof contentText[keys[i]] === 'object') {
          continue;
        }
        if (keys[i].endsWith('image_url') || keys[i].endsWith('audio_url')) {
          result.push({
            ...data,
            content: text,
            urls: keys[i].endsWith('image_url')
              ? [{ type: 'image', url: contentText[keys[i]] }]
              : [{ type: 'audio', url: contentText[keys[i]] }],
          });
          text = '';
          continue;
        }
        text += contentText[keys[i]];
      }
      if (text !== '') {
        result.push({ ...data, content: text });
      }
      return result;
    }
  };

  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });
  const [messageList, { data: msgList, loading: msgListLoading }] = useMutation(MESSAGE_LIST);

  const { data, loading, refetch } = useQuery(AI_TOPIC_DATE_ASSIGNMENT_DETAILS, {
    variables: { idx: parseInt(idx) },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.aiTopicDateAssignmentDetails) {
      let ai_topic_date_assignmentData = JSON.parse(data?.aiTopicDateAssignmentDetails?.data || '{}');
      setAssignedAITopics(
        data?.aiTopicDateAssignmentDetails.ai_topic_user_assignments.map((item, index) => {
          return {
            ...item,
            ai_topic: {
              ...item.ai_topic,
              data: JSON.parse(item.ai_topic.data || '{}'),
              topic: ai_topic_date_assignmentData?.revisedPrompt || item.ai_topic.topic,
            },
            no: index + 1,
            start_date: data?.aiTopicDateAssignmentDetails?.start_date,
            // rubric_data: JSON.parse(item.rubric_data),
            // ai_request_responses: item.ai_request_responses.map((item) => {
            //   return {
            //     ...item,
            //     promt: item.prompt_text,
            //     content: item.completion_text,
            //     data: JSON.parse(item.data),
            //     created: item.idate.split('.')[0],
            //     // chat_user_name:
            //   };
            // }),
          };
        }),
      );
      setSelectedDate(data?.aiTopicDateAssignmentDetails?.start_date?.substr(0, 10));
      setTotal(data?.aiTopicDateAssignmentDetails.ai_topic_user_assignments.length);
    }
  }, [data]);

  useEffect(() => {
    if (lastMsg !== null && scoreClick) {
      if (lastMsg !== null) {
        const dateVal = new Date(lastMsg?.created_at * 1000);
        const summryMsg = {
          user: lastMsg?.role === 'user' ? curUser : 'AI봇',
          chat_user_name: lastMsg?.role === 'user' ? curUser : 'AI봇',
          content: lastMsg?.content[0]?.text?.value,
          urls: [],
          created: dateVal.toISOString(),
        };
        setCurrentChatList([summryMsg]);
      }
    }
  }, [lastMsg, scoreClick]);

  const fetchMessages = async () => {
    messageList({
      variables: {
        thread_id: threadIdx,
        last_msg_id: lastMsgId,
        order: 'desc',
      },
    }).then((res) => {
      if (res?.data?.messageList?.has_more == true) {
        setHasMore(true);
        setLastMsgId(res?.data?.messageList?.last_id);
        if (lastMsgId == null) {
          setLastMsg(res?.data?.messageList?.data[0]);
        }
      } else {
        // setLastMsg(res?.data?.messageList?.data[res?.data?.messageList?.data?.length - 1])
        setLastMsgId(null);
      }
      if (res?.data?.messageList?.data?.length > 0) {
        // const chatList = [];
        const promptMetadataArray = res?.data?.messageList?.data?.filter((item) => item.metadata && item.metadata.type !== 'prompt');
        const rearrangedList = promptMetadataArray?.reverse();
        const chatList =
          rearrangedList?.length > 0 &&
          rearrangedList?.reduce((acc, ele) => {
            const filtered_data = parseMessage(ele?.content);
            const newData = mapContentData(filtered_data, {
              user: ele?.role === 'user' ? curUser : 'AI봇',
              chat_user_id: ele?.role === 'user' ? curUser : 'AI봇',
              chat_user_name: ele?.role === 'user' ? curUser : 'AI봇',
              created: new Date(ele.created_at * 1000).toISOString(),
            });
            return [...acc, ...newData];
          }, []);
        if (scoreClick) {
          const lastValue = chatList[chatList.length - 1];
          setCurrentChatList([lastValue]);
          setHasMore(false);
        } else {
          // if (!res?.data?.messageList?.has_more) {
          if (lastMsg == null) {
            let finalChatList = chatList?.slice(0, -2);
            setCurrentChatList((prevChatList) => [...finalChatList, ...prevChatList]);
          } else {
            setCurrentChatList((prevChatList) => [...chatList, ...prevChatList]);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (hasMore && lastMsgId !== null && scoreClick && lastMsg == null) {
      fetchMessages();
    }
  }, [hasMore, lastMsgId, scoreClick, lastMsg]);
  useEffect(() => {
    if (viewAnswerModal && threadIdx) {
      fetchMessages();
      // messageList({
      //   variables: {
      //     thread_id: threadIdx
      //   }
      // }
      // ).then((res) => {
      //   const chatList = [];
      //   const promptMetadataArray = res?.data?.messageList?.data?.filter(item => item.metadata && item.metadata.type !== "prompt");
      //   const rearrangedList = promptMetadataArray?.reverse()
      //   rearrangedList?.length > 0 && rearrangedList?.map((item) => {
      //     const date = new Date(item?.created_at * 1000);
      //     chatList.push({
      //       user: item?.role === "user" ? curUser : "AI봇",
      //       chat_user_name: item?.role === "user" ? curUser : "AI봇",
      //       content: item?.content[0]?.text?.value,
      //       urls: [],
      //       created: date.toISOString(),
      //     });
      //   });
      //   if (scoreClick) {
      //     const lastValue = chatList[chatList.length - 1];
      //     setCurrentChatList([lastValue])
      //   } else {
      //     let finalChatList = chatList?.slice(0, -2);
      //     setCurrentChatList(finalChatList)
      //   }
      // }
      // )
    }
  }, [viewAnswerModal, threadIdx]);

  const handleMoreMsg = () => {
    if (hasMore && lastMsgId !== null) {
      fetchMessages();
    }
  };
  const handleDialogue = (record) => {
    setCurUser(record?.user?.id);
    setThreadIdx(record?.thread_id);
    setTopic(record.ai_topic.topic);
    setTopicAnswer(record.answer);
    setAiTopic(record.ai_topic);
    setScoreClick(false);
    setViewAnswerModal(true);
  };

  const handleFeedback = (record) => {
    setTopic(record.ai_topic.topic);
    setCurUser(record?.user?.id);
    setTopicAnswer(record.answer);
    setAiTopic(record.ai_topic);
    // setCurrentChatList(resChats);
    setScoreClick(true);
    setThreadIdx(record?.thread_id);
    setViewAnswerModal(true);
  };
  console.log('curUser', curUser);
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    // {
    //   title: 'A. Date',
    //   dataIndex: 'ai_topic_date_assignment',
    //   align: 'center',
    //   key: 'date',
    //   width: 100,
    //   render: (text, record) => {
    //     return <Space size="middle">{record.start_date.substr(0, 10)}</Space>;
    //   },
    // },
    {
      title: 'Stu_Name(id)',
      dataIndex: 'user',
      key: 'user',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="middle">
            {' '}
            <span style={{ color: 'blue', fontWeight: 'bold' }}>{record.user.name + '(' + record.user.id + ')'}</span>
          </Space>
        );
      },
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record?.ai_topic?.data?.book?.title}</Space>;
      },
    },
    {
      title: 'Completed Date',
      dataIndex: 'completed_date',
      key: 'completed_date',
      align: 'center',
      render: (text, record) => {
        if (record?.status == 1) {
          let time = record?.end_time.split('T');
          return (
            <Space size="middle">
              <span>
                {time[0]} {time[1].split('.')[0]}
              </span>
            </Space>
          );
        } else {
          return <Space size="middle">-</Space>;
        }
      },
    },
    {
      title: 'Duration Time',
      dataIndex: 'duration_time',
      key: 'duration_time',
      align: 'center',
      render: (text, record) => {
        const start_time = new Date(record?.start_time?.replace('Z', '+00:00'));
        const end_time = new Date(record?.end_time?.replace('Z', '+00:00'));
        const time_difference_ms = end_time - start_time;
        const hours = Math.floor(time_difference_ms / (1000 * 60 * 60));
        const minutes = Math.floor((time_difference_ms % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time_difference_ms % (1000 * 60)) / 1000);
        if (record?.status == 1) {
          return (
            <Space size="middle">
              {hours}:{minutes}:{seconds}
            </Space>
          );
        } else {
          return <Space size="middle">-</Space>;
        }
      },
    },
    {
      title: 'Dialogue',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => handleDialogue(record)}>보기</Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Feedback',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => handleFeedback(record)}>보기</Button>
            </Space>
          );
        }
      },
    },
  ];

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
            <div style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'end', display: 'flex' }}>
              <Title level={4}>
                AI Speaking Coach : <span style={{ color: '#00B0F0' }}>Result Page</span>
              </Title>
              {ai_topic_user_assignments && (
                <div style={{ fontSize: '16px', padding: '0 10px', display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#0070C0' }}>Assigned Date :</span>
                  &nbsp;
                  {selectedDate}
                  &nbsp; &nbsp;
                  <span style={{ color: '#0070C0' }}>Topic :</span>
                  &nbsp;
                  {ai_topic_user_assignments[0]?.ai_topic.topic}
                  &nbsp; &nbsp;
                  <span style={{ color: '#0070C0', fontSize: '16px' }}>Class :</span>
                  &nbsp; &nbsp;
                  <div
                    style={{
                      display: 'flex',
                      minWidth: '200px',
                      padding: '0 5px',
                      height: '32px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#000000',
                      backgroundColor: '#F5F9FD',
                      fontSize: '16px',
                    }}
                  >
                    {ai_topic_user_assignments[0]?.class.name}
                  </div>
                </div>
              )}
            </div>
            {/* <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            > */}
            <div style={{ margin: 'auto 0' }}>
              <BackButton />
            </div>
            {/* </Col> */}
          </div>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={ai_topic_user_assignments}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>

        {viewAnswerModal && (
          <AITalkingReportAnswerModal
            visible={viewAnswerModal}
            chatList={currentChatList}
            hasMore={hasMore}
            handleCancel={() => {
              setViewAnswerModal(false);
              setCurrentChatList([]);
              setLastMsg(null);
              setLastMsgId(null);
            }}
            topic={topic}
            aiTopic={aiTopic}
            loadMoreMsg={handleMoreMsg}
            lastMsgId={lastMsgId}
            showButton={scoreClick}
            msgListLoading={msgListLoading}
          />
        )}
        <RecordingDataModal
          data={{ ...recordingData, currentUserName, className: '' }}
          isShow={isRecordingVisible}
          onPrint={null}
          componentRef={null}
          onOk={handleOkForRecordingData}
          hidePrint={true}
        />
      </Col>
    </>
  );
};

export default withRouter(AITalkingDetailsByDate);
