import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Typography, Button, Modal, Select, Space } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PhonicsInfo from "src/PhonicsInfo";
import stageComplete from "src/constants/stageComplete";
import * as queries from 'src/operations/queries';

const { Option } = Select;
const { Title, Text } = Typography;
const HomeWorkTable = styled(CustomTable)`
  border-top: 3px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
  }
`;


const sortByName = (a, b) => {
  //이름순 정렬
  if (a.name === b.name) {
    return 0;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
};
const PhonicsResultClassStudent = () => {
  const { class_idx,user_idx } = useParams();
  /*const unitdata = PhonicsInfo.find(element => {
    return (element.level == level && element.unit == unit);
  })*/
  const { state } = useLocation();
  
  const {
    data: fetchAssignedPhonicsDetails,
    refetch: assginedPhonicsDetailRefetch,
    fetchdetailloading,
  } = useQuery(queries.phonicsUnitList.FETCH_ASSIGNED_PHONICS_STUDENT_CLASS_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: { 
      class_idx : Number(class_idx),
      user_idx : Number(user_idx)
    },
  });
  let stageCount = 12;
  const getColumnHeading = (lessonType, stageCount) => {
    let lessonHeading = [];
    let len = stageCount;
    let start = (stageCount/2) +1;
    if(lessonType == 1) {
      len = stageCount/2;
      start = 1;
    }
    for (let i = start; i <= len; i++) {
      lessonHeading.push({
          title: 'S'+(i),
          dataIndex: 'idx',
          key: 'No',
          align: 'center',
          width: 100,
          render: (text, record) => {
            if(i == 12){
              if(record['s12_score']){
                return <span style={{ color: '#40c057' }}>{record['s12_score']}/100</span>; //점수가 없고 했는지만 체크하는 스테이지임
              }
              return '--';
            }
            if(record['s'+(i)] == 'Completed') {
              if(i == 8 && record.s8_url !== '' && record.s8_url !== null){
                return <a href={`${record.s8_url}`} target="_blank"><span style={{ color: '#40c057' }}>
                <Button type="primary">완료</Button></span></a>; //점수가 없고 했는지만 체크하는 스테이지임
              }else{
                return <span style={{ color: '#40c057' }}>완료</span>; //점수가 없고 했는지만 체크하는 스테이지임
              }
            }
            if (record['s'+(i)] == 'Not Started') {
              return <span style={{ color: '#ffa94d' }}>미수행</span>; //아직 안 한 거임
            }
            if (record['s'+(i)] == 'N/A') {
              return <span style={{ color: '#ffa94d' }}>--</span>; //아직 안 한 거임
            }
            return <span style={{ color: '#ffa94d' }}>{record['s'+(i)]}</span>; //아직 안 한 거임
          },
        });
    }
    return lessonHeading;
  }
  let student_level = null;
  let student_unit  = null;
  let student_title = null;
  let results = [];
  if(fetchAssignedPhonicsDetails !== undefined){
    let assigned_unit_student_details = fetchAssignedPhonicsDetails?.AssignedPhonicsClassStudentDetail?.unit_details;
    let student_detail_processing = [];

    assigned_unit_student_details.map((loopdata,key) => {
      let studentname = loopdata.id;
      let unit_status_details = JSON.parse(loopdata.stages_completed_status);
      student_detail_processing.push({
                      "number":key+1,
                      "book_idx": loopdata.book_idx,
                      "start_date": loopdata.start_date,
                      "start_date_additional":(loopdata.start_date).split("T")[0],
                      ...unit_status_details
                    })
    })
    results = student_detail_processing; 
  }
  const LessonTwoHeading = getColumnHeading(2, stageCount);
  const LessonOneHeading = getColumnHeading(1, stageCount);
  const columns = [
      {
        title: 'No',
        dataIndex: 'idx',
        key: 'No',
        align: 'left',
        width: 150,
        render: (text, record) => {
          return <Space size="middle">{record.number}</Space>;
        },
      },
      {
        title: 'Start Date',
        dataIndex: 'idx',
        key: 'No',
        align: 'left',
        width: 150,
        sorter: (a, b) => a.start_date_additional.localeCompare(b.start_date_additional),
        render: (text, record) => {
          if(record.start_date){
            return  <Space size="middle">{record.start_date.substring(0, record.start_date.indexOf('T'))}</Space>;
          }
          return '';
        },
      },
      {
        title: 'Lesson',
        dataIndex: 'idx',
        key: 'No',
        align: 'left',
        width: 150,
        sorter: (a, b) => a.book_idx.localeCompare(b.book_idx),
        render: (text, record) => {
            return  <Space size="middle">{record.book_idx}</Space>;
        }
      },
      {
        title: 'Lesson 1',
        dataIndex: 'idx',
        key: 'No',
        children : LessonOneHeading
        
      },
      {
        title: 'Lesson 2',
        dataIndex: 'idx',
        key: 'No',
        children : LessonTwoHeading
    },
    {
        title: '완료날짜',
        dataIndex: 'date',
        key: 'date',
        align: 'center',
        width: 200,
        render: (text, record) => {
          let loopdateshow = false;
          if(record.stage_level == "1"){
            if(record.stage_length == 6){
              if(record.s1 == "Completed" && record.s2 == "Completed" && record.s3 == "Completed" && record.s4 == "Completed" && record.s5 == "Completed" && record.s6 == "Completed"){
                loopdateshow = true;
              }
            }
            if(record.stage_length == 4){
              if(record.s1 == "Completed" && record.s2 == "Completed" && record.s3 == "Completed" && record.s4 == "Completed"){
                loopdateshow = true;
              }
            }
          }

          if(record.stage_level == "2"){
            if(record.stage_length == 6){
              if(record.s7 == "Completed" && record.s8 == "Completed" && record.s9 == "Completed" && record.s10 == "Completed" && record.s11 == "Completed" && record.s12 == "Completed"){
                loopdateshow = true;
              }
            }
            if(record.stage_length == 4){
              if(record.s5 == "Completed" && record.s6 == "Completed" && record.s7 == "Completed" && record.s8 == "Completed"){
                loopdateshow = true;
              }
            }
          }
          if(loopdateshow == true){
            if(record.completion_date){
              return  <span style={{ color: '#40c057' }}>{moment(record.completion_date.split('+')[0]).format('YYYY-MM-DD HH:mm')}</span>;
            }
          return '';
          }else{
            return '';
          }
        },
    }
  ];
  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>Phonics Results</HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="learning-halfwrapper learning-hs">
            <div
              className="learning-halfwrapper-top-header"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              <Title level={5} style={{width:"100%", textAlign:"left"}}>
              Class: {fetchAssignedPhonicsDetails?.AssignedPhonicsClassStudentDetail?.class_name} ( <Text type="success">Student: {fetchAssignedPhonicsDetails?.AssignedPhonicsClassStudentDetail?.student_name}</Text> )
              </Title>
            </div>
            <HomeWorkTable
              dataSource={results}
              columns={columns}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default PhonicsResultClassStudent;
