import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Card } from 'antd';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import ReactPlayer from 'react-player';
import 'react-simple-keyboard/build/css/index.css';
import CustomButton from 'src/components/common/CustomButton';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #f5f9ff;
  margin-top: 10px;
  height: calc(100vh - 94px);
`;

const TotalBox = styled.span`
  color: rgb(255, 122, 27);
  border: 1px solid #6b5c5c;
  padding: 5px 10px;
  border-radius: 5px;
`;

const ScoreBox = styled.span`
  color: #de3333;
  border: 1px solid #de3333ab;
  padding: 5px 10px;
  border-radius: 5px;
`;

const ListenRepeatPage = ({ data = [], gotoNext = null }) => {
  const [total, setTotal] = useState(data.length);
  const [done, setDone] = useState(0);
  const [doneState, setDoneState] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [isDoneOnce, setIsDoneOnce] = useState(false);
  const [doneList, setDoneList] = useState([]);
  const [isComplate, setIsComplate] = useState(false);

  useEffect(() => {
    if (data && data[done]) {
      doneList[done] = true;
      setDoneList(doneList);
      setPlaying(true);
      setDoneState(0);
    }
  }, [data, done, doneList]);

  useEffect(() => {
    if (doneList.filter((d) => d).length === data.length && !isComplate && !playing && doneState > 1) {
      setIsComplate(true);
    }
  }, [data, doneList, isComplate, playing]);

  useEffect(() => {
    if (isComplate && !playing && !isDoneOnce) {
      setTimeout(() => {
        if (gotoNext) {
          gotoNext({});
          setIsDoneOnce(true);
        }
      }, 2000);
    }
  }, [isComplate, playing, isDoneOnce, gotoNext]);

  const onEndPlaying = () => {
    setPlaying(false);
    const dlist = doneList.filter((d) => d);
    if (dlist.length !== data.length && doneState > 1) {
      const listInd = doneList.map((d, i) => (d ? i : -1));
      const filtered = data.map((d, i) => (listInd.includes(i) ? false : i)).filter((d) => d);
      if (filtered.length) {
        setTimeout(setDone(filtered[0]), 1000);
      }
    } else if (doneState < 2) {
      setDoneState((prev) => prev + 1);
      setPlaying(true);
    }
  };

  return (
    <>
      <Col className="listenandrepeat" span={24} style={{ textAlign: 'left', margin: 0 }}>
        <MainBlock className="listenandrepeat-scroll scrollscroll">
          <Row gutter={[24, 16]} style={{ padding: 10 }}>
            <Col className="mainheading-spaces" span={16}>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col className="mainheading-spaces-col" span={24} style={{ padding: 10, paddingLeft: 10 }}>
                  <Title level={2} ellipsis>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Listen and Repeat :</span>{' '}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 10 }}>
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                <TotalBox>
                  {done + 1} / {total}
                </TotalBox>
              </Title>
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col className="listenandrepeat-content" span={12} style={{ padding: 10 }}>
              <Card
                className="listenandrepeat-content-listing"
                title="Sentence List"
                style={{ width: '100%' }}
                bodyStyle={{ height: 445, overflowY: 'auto' }}
              >
                {data.map((d, i) => (
                  <Title
                    level={4}
                    key={i}
                    onClick={() => setDone(i)}
                    style={{ padding: '5px', cursor: 'pointer', background: done === i ? '#efefef' : '#fff' }}
                  >{`  ${i + 1}. ${d.ans}`}</Title>
                ))}
              </Card>
            </Col>
            <Col
              className="listenandrepeat-audio"
              span={12}
              style={{ padding: 10, display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}
            >
              <div className={'flip-card-container'} style={{ width: '100%', marginBottom: 10 }}>
                <div className="front type2">
                  <p>{data[done]['kor']}</p>
                </div>
              </div>
              <ReactPlayer
                controls
                url={`https://cdn.cloubot.com${data[done]['eng_audio']}`}
                loop={false}
                height={50}
                width={'100%'}
                playing={playing}
                onEnded={onEndPlaying}
                onError={onEndPlaying}
              />
            </Col>
          </Row>
          {isComplate && isDoneOnce ? (
            <div style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 10 }}>
              <CustomButton
                type="primary"
                style={{ padding: '0 30px', margin: '0 10px' }}
                onClick={() => (gotoNext ? gotoNext({}, true) : null)}
              >
                다음 학습으로 이동
              </CustomButton>
            </div>
          ) : null}
        </MainBlock>
      </Col>
    </>
  );
};

export default ListenRepeatPage;
