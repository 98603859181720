import React, { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Col, Row, DatePicker, Modal, Typography, Space, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import { HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import * as queries from 'src/operations/queries';
import { DELETE_AI_TOPIC_ASSIGNMENT_GROUP } from 'src/operations/mutations/aiTopic';
import TalkingTutorMenuGroup from 'src/components/common/TalkingTutorMenuGroup';
import UseHistoryState from 'src/components/common/UseHistoryState';
import dayjs from 'dayjs';
const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const { Title } = Typography;
const ExternalStudyAssignResultTable = styled(CustomTable)`
  colgroup {
    display: table-column-group !important;
  }
`;

const sortByDate = (isDescend) => (a, b) => {
  if (moment(a.org_date).isSame(b.org_date, 'day')) {
    return 0;
  }
  const compared = isDescend ? moment(a.org_date).isAfter(b.org_date, 'day') : moment(a.org_date).isBefore(b.org_date, 'day');
  if (compared) {
    return -1;
  }
  return 1;
};

const AssignAITalkingList = ({ user }) => {
  const [classes, setClasses] = useState([]);
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { selectedClassIdx, onClassChange } = useCampusClass();
  // const [pg, setPg] = useState(dayjs());
  const history = useHistory();
  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });
  const [localState, setLocalState] = UseHistoryState({
    pg: { current: 0, pageSize: 10 },
    selectedClassIdx: 0,
    searchClassIdx: 0,
    start_date: dayjs(Date()).startOf('month').format('YYYY-MM-DD'),
    end_date: dayjs(Date()).endOf('month').format('YYYY-MM-DD'),
  });
  const {
    data: fetchAITopicGroupList,
    refetch,
    loading,
  } = useQuery(queries.aiTopic.AI_TOPIC_GROUP_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      class_idx: localState.selectedClassIdx,
      type: 'ai-talking-tutor',
      page: localState.pg?.current,
      start_date: localState.start_date,
      end_date: localState.end_date,
    },
  });

  const [deleteAITopicAssignmentGroup, { loading: loadingDelete }] = useMutation(DELETE_AI_TOPIC_ASSIGNMENT_GROUP, {
    onCompleted: (res) => {
      if (!res?.deleteAITopicAssignmentGroup) {
        alert('삭제 권한이 없습니다.');
        return;
      }
      refetch();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
  }, [classQuery]);
  const handleClass = (classIdx) => {
    setLocalState((prev) => {
      return {
        ...prev,
        selectedClassIdx: classIdx,
        searchClassIdx: classIdx,
      };
    });
  };
  useEffect(() => {
    const total = Math.ceil(fetchAITopicGroupList?.aiTopicGroups?.total / 10);
    if (localState?.pg.current + 1 > total) {
      setLocalState((prev) => {
        return {
          ...prev,
          pg: { current: 0, pageSize: 10 },
        };
      });
    }
  }, [fetchAITopicGroupList]);
  const aiTopicGroupList = useMemo(() => {
    if (fetchAITopicGroupList?.aiTopicGroups) {
      let fetchData = null;
      const class_idx = parseInt(selectedClassIdx);

      if (class_idx > 0) {
        fetchData = fetchAITopicGroupList?.aiTopicGroups.ai_topic_groups.filter((item) => item?.class.idx === class_idx);
      } else {
        fetchData = fetchAITopicGroupList?.aiTopicGroups.ai_topic_groups;
      }

      return fetchData.reduce((acc, cur, index) => {
        const totalCnt = cur?.ai_topic_date_assignments[0]?.ai_topic_user_assignments?.length;

        const completed = cur?.ai_topic_date_assignments[0]?.ai_topic_user_assignments?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && cur?.status > 0) {
            acc++;
          }
          return acc;
        }, 0);

        const obj = {
          title: cur?.title,
          date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          start: moment(cur?.start_date).format('YYYY-MM-DD'),
          end: moment(cur?.end_date).format('YYYY-MM-DD'),
          //testTime: cur?.test_time,
          className: cur?.class?.name,
          complete: `${completed} / ${totalCnt}`,
          No: index + 1,
          key: cur.idx,
          class_idx: cur?.class?.idx,
          done_count: completed,
          type: cur?.type,
          idx: cur?.ai_topic_date_assignments[0]?.idx,
          onDeleteAITopicAssignGroup: handleAITopicAssignGroupDelete,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchAITopicGroupList, classes, localState?.selectedClassIdx]);

  function handleAITopicAssignGroupDelete(ai_topic_group_idx, isAiTopicAssignResult) {
    Modal.confirm({
      icon: null,
      //title: 'Delete',
      content: (
        <Title level={5} style={{ textAlign: 'left', lineHeight: 2 }}>
          {isAiTopicAssignResult ? (
            <>
              이미 학습한 학생이 있습니다. <br /> 삭제하면 학생이 학습한 내용도 함께 삭제됩니다. <br />
              그래도 삭제하시겠습니까?
            </>
          ) : (
            <>삭제하시겠습니까?</>
          )}
        </Title>
      ),
      onOk: () => {
        deleteAITopicAssignmentGroup({ variables: { idx: ai_topic_group_idx } });
      },
      onCancel: () => {
        //do nothing
      },
    });
  }
  let columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: 'Created Date',
      dataIndex: 'date',
      align: 'center',
      width: 170,
      sorter: sortByDate(false),
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    {
      key: 'start',
      title: 'Start Date',
      dataIndex: 'start',
      align: 'center',
      width: 170,
      sorter: sortByDate(false),
    },
    {
      key: 'end',
      title: 'End Date',
      dataIndex: 'end',
      align: 'center',
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" color="#fff" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      key: 'no',
      title: 'Edit',
      dataIndex: 'no',
      align: 'center',
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              history.push(`/ai-talking-tutor/edit/${record?.class_idx}/${record?.key}`);
            }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      key: 'delete',
      title: 'Delete',
      dataIndex: 'delete',
      align: 'center',
      render: (_, row) => {
        const { key, done_count, onDeleteAITopicAssignGroup } = row;
        return (
          <Button
            style={{ color: '#FFCD9A', borderColor: '#FFCD9A' }}
            onClick={() => {
              onDeleteAITopicAssignGroup(key, done_count > 0);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];
  const onChangeDate = (a, date) => {
    let clearCheck = a===null;
    if (clearCheck) {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: undefined,
          end_date: undefined,
        };
      });
    }
    else{
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
          end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
        };
      });

    }
  };
  return (
    <>
      <TalkingTutorMenuGroup currentMenu="list" />
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>
                AI Speaking Coach : <span style={{ color: '#00B0F0' }}>Assigned Group List</span>
              </Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end', alignItems:'center' }}
            >
              <DatePicker style={{ marginRight:5}} placeholder='Select Month' format={'YYYY-MM'} picker="month" onChange={onChangeDate} value={localState.start_date&&dayjs(localState.start_date)}/>
              <div className="toparea-main">
                <Space>
                  <ClassSelectBox
                    onClassChange={handleClass}
                    selectedClassIdx={localState?.selectedClassIdx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px' }}
                  />
                </Space>
              </div>
              {/* </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <HalfWrapper className="books-result-halfwrapper">
                <ExternalStudyAssignResultTable
                  style={{ width: '98%' }}
                  dataSource={aiTopicGroupList}
                  columns={columns}
                  key={(r) => r.idx}
                  size="small"
                  color="#edf3fb"
                  scroll={{ y: 'calc(100vh - 254px)' }}
                  loading={loading || loadingDelete}
                  pagination={{
                    showSizeChanger: false,
                    pageSize: 10,
                    current: localState.pg?.current + 1,
                    total: fetchAITopicGroupList?.aiTopicGroups?.total || 0,
                    position: ['bottomCenter'],
                  }}
                  onChange={(pagination) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        pg: { current: pagination.current - 1, pageSize: pagination.pageSize },
                      };
                    });
                  }}
                />
              </HalfWrapper>
            </Col>
          </Row>
          {/* </div> */}
        </MainBlock>
      </Col>
    </>
  );
};

export default AssignAITalkingList;
