import React, { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PageNum from '../PageNum';
import SoundButton from '../SoundButton';
import { useStudyCreate } from '../../../utils/pm/api';
import PopUp from '../PopUp';
import * as S from 'src/pages/PhonicsMonster/style';
import { userInfoVar } from 'src/apollo/cache';
const ImageSoundStage = ({ bookInfo, unitInfo, stages, steps, setSteps }) => {
  const { problem } = stages;
  const safeSteps = _.isNil(steps) ? 0 : steps > problem.length - 1 ? problem.length - 1 : steps;

  const [isSoundEnd, setIsSoundEnd] = useState(false);

  const history = useHistory();
  const [showPopUp, setShowPopUp] = useState(false);

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  const handleSoundEnd = () => {
    setIsSoundEnd(true);
  };

  const handleNextClick = () => {
    if (steps < problem.length - 1) {
      setSteps(steps + 1);
      setIsSoundEnd(false);
    } else {
      setShowPopUp(true);
    }
  };

  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
    // history.goBack();
  };
  console.log(isSoundEnd,'end');
  return (
    <>
      <S.MainBox style={{ justifyContent: 'space-around', position: 'relative' }} className="main_content onewrap lv1_review1_content">
        <PageNum
          num={safeSteps + 1}
          total={_.isNil(problem) ? 0 : problem.length}
          style={{ top: '10px', position: 'absolute', right: '1rem', fontSize: '1.3rem', fontWeight: 'bold' }}
        />
        <div className="contents_inner_wrap">
          <S.ImageSoundImgWrap className="content_img">
            <img style={{ height: '40vh' }} src={problem[safeSteps].image} alt="" />
          </S.ImageSoundImgWrap>
        </div>
        <S.RowFlex className="bottom" style={{ position: 'relative', justifyContent: 'center' }}>
          <SoundButton src={problem[safeSteps].sound} onSoundEnd={handleSoundEnd} />
          {isSoundEnd && (
            <S.Button2
              onClick={(e) => {
                // e.preventDefault();
                handleNextClick();
              }}
              className="btn_type2"
              style={{ position: 'absolute', bottom: '5px', right: '5px' }}
            >
              <img src="/images/pm/i_next.svg" style={{ width: '80%' }} alt="next" />
            </S.Button2>
          )}
        </S.RowFlex>
      </S.MainBox>
      {showPopUp ? <PopUp popUpType={'correct'} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
    </>
  );
};

export default ImageSoundStage;
