import React from 'react';
import styled from 'styled-components';
import { Radio, Input, Button, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 650px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 20px;
`;

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '50px',
    align: 'center',
  },
  {
    title: 'A. Date',
    dataIndex: 'assigned_date',
    key: 'assigned_date',
    align: 'center',
  },
  {
    title: 'Stu_Name(id)',
    dataIndex: 'student_name',
    key: 'student_name',
    align: 'center',
    width:'120px',
    render: (text, record) => {
      return <span>{`${text}(${record.sutdent_id})`}</span>;
    },
    sorter: (a, b) => a.student_name && a.student_name.localeCompare(b.student_name),
  },
  {
    title: 'Topic/Prompt',
    dataIndex: 'topic',
    key: 'topic',
    align: 'center',
    width: '300px',
    render: (text) => {
      return (
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {text}
        </div>
      );
    },
    sorter: (a, b) => a.topic && a.topic.localeCompare(b.topic),
  },
  {
    title: 'WPM',
    dataIndex: 'wpm',
    key: 'wpm',
    align: 'center',
    render: (text) => {
      return text ? <Button style={{ color: 'red' }}>{text}</Button> : '';
    },
  },
  {
    title: 'Rubric',
    dataIndex: 'rubric',
    key: 'rubric',
    align: 'center',
    render: (text) => {
      return text ? <Button style={{ color: 'green' }}>{text}</Button> : '';
    },
  },
  {
    title: 'Original',
    dataIndex: 'original',
    key: 'original',
    align: 'center',
    render: (_, record) => {
      return record.original ? <Button>View</Button> : '';
    },
  },
  {
    title: 'Check',
    dataIndex: 'check',
    key: 'check',
    align: 'center',
    render: (_, record) => {
      return record.check ? <Button>View</Button> : '';
    },
  },
 
  {
    title: 'Revise',
    dataIndex: 'revise',
    key: 'revise',
    align: 'center',
    render: (_, record) => {
      return record.revise ? <Button>View</Button> : '';
    },
  },
  {
    title: 'PT/WPM',
    dataIndex: 'pt_wpm',
    key: 'pt_wpm',
    align: 'center',
    render: (text,record) => {
      return record.pt_wpm ? <Button style={{ color: 'blue' }}>{text}</Button> : '';
    },
  },
];
export default function LevelUpReportandWPMResultPage() {
  const history = useHistory();
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [localState, setLocalState] = UseHistoryState({
    startDate: '',
    endDate: '',
    state: 'ing',
    // searchClassName: '',
    // pageNumber: 0,
  });
  const handleStateChange = (e) => {
    setLocalState((prev) => {
      return {
        ...prev,
        state: e.target.value,
        // pageNumber: 0,
      };
    });
  };
  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: dateString,
        // pageNumber: 0,
      };
    });
  };
  const handleEndDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        endDate: dateString,
        // pageNumber: 0,
      };
    });
  };
  const dummy = [
    {
      no: 1,
      assigned_date: '23-07-01',
      student_name: 'student1',
      sutdent_id: 'id1',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '3/6',
      original: true,
      check: true,
      revise: true,
      pt_wpm: 92,
    },
    {
      no: 2,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 3,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 4,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 5,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 6,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 7,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 8,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 9,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 10,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 11,
      assigned_date: '23-07-01',
      student_name: 'student2',
      sutdent_id: 'id2',
      topic: 'Famous Tales: Write a summary Write a summaryWrite a summary',
      wpm: 80,
      rubric: '2/6',
      original: true,
      check: true,
      revise: false,
      pt_wpm: 92,
    },
    {
      no: 12,
      assigned_date: '23-07-01',
      student_name: 'student3',
      sutdent_id: 'id3',
      topic: 'Write a summary Write a summaryWrite a  summarysummarysummarysummarysummarysummary',
      wpm: 82,
      rubric: undefined,
      original: false,
      check: false,
      revise: false,
      pt_wpm: 92,
    },
  ];
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Title>e-Library Results Management System</Title>
          <RowFlex style={{ alignItems: 'center', justifyContent: 'end' }}>
            <Radio.Group value={localState.state} onChange={handleStateChange}>
              <Radio.Button value="ing">진행중</Radio.Button>
              <Radio.Button value="end">종료</Radio.Button>
              <Radio.Button value="">전체</Radio.Button>
            </Radio.Group>
            &nbsp;
            <Input
              placeholder="검색어 입력"
              value={localState.searchClassName}
              onChange={(e) => {
                setLocalState((prev) => {
                  return {
                    ...prev,
                    searchClassName: e.target.value,
                    pageNumber: 0,
                  };
                });
              }}
              style={{ width: '150px' }}
            />
            &nbsp;
            <Icon url={imageexcel} width={'20px'} height={'20px'} onClick={{}}></Icon>
          </RowFlex>
          <Main>
            <RowFlex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <SubTitle>Book Report / WPM</SubTitle>
              <RowFlex>
                <ClassSelectBox
                  onClassChange={onClassChange}
                  selectedClassIdx={selectedClassIdx}
                  campus_idx={dataUser?.getUserData?.campus_idx}
                  style={{ width: '250px', textAlign: 'left' }}
                />
                &nbsp;
                <DatePicker
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                />
                ~
                <DatePicker
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                />
              </RowFlex>
            </RowFlex>
            <CustomTable
              pagination={{ pageSize: 10, position: ['bottomCenter'] }}
              dataSource={dummy}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
