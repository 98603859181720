import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const UpdateBoardForm = ({ form, handleFileValue, boardInfo }) => {
  const [fileList, setFileList] = useState([]);

  const props = {
    multiple: true,
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      handleFileValue((prev) => {
        const index = prev.files.indexOf(file);
        const newFileList = prev.files.slice();
        newFileList.splice(index, 1);

        return {
          ...prev,
          files: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      setFileList((prev) => [...prev, file]);
      handleFileValue((prev) => ({
        ...prev,
        files: [...prev.files, file],
      }));
      return false;
    },
    fileList,
  };

  useEffect(() => {
    form.setFieldsValue({
      wr_subject: boardInfo?.wr_subject,
      wr_content: boardInfo?.wr_content,
      files: boardInfo?.boardFile,
    });
  }, [boardInfo?.boardFile, boardInfo?.wr_content, boardInfo?.wr_subject, form]);

  return (
    <>
      <Row justify="center">
        <Col span={18}>
          <Form.Item name="wr_subject" label="제목" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="wr_content" label="내용" rules={[{ required: true }]}>
            <Input.TextArea autoSize={{ minRows: 6 }} />
          </Form.Item>

          <Form.Item name="files" label="Upload File" valuePropName="logo" style={{ marginBottom: 5 }}>
            <Upload {...props} name="files">
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default UpdateBoardForm;
