import React from 'react';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import LearningCommon from './LearningCommon';
import LearningSmartEclass from './LearningSmartEclass';
import { isAfterSchool } from 'src/utils';

const Learning = () => {
  const companyName = useSelector(classStoreData);
  return isAfterSchool(companyName) ? <LearningSmartEclass /> : <LearningCommon />;
};

export default Learning;
