import React from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const isCompleteStage = (data, stage) => {
  if (!_.isEmpty(data) && data instanceof Array) {
    for (let i = 0; i < data.length; i++) {
      if (_.toNumber(data[i]) === _.toNumber(stage)) {
        return true;
      }
    }
  }
  return false;
};

const UnitLayout = ({ unit, unitInfo, lesson, handleClick, complteStageList }) => {
  // console.log('unitInfo', unitInfo);
  const history = useHistory();
  const getStage = (unitInfo) => {
    const stage = [];
    const onClick = (e, url) => {
      handleClick(e, url);
    };
    _.each(Object.keys(unitInfo.stage), (item) => {
      if (item <= Object.keys(unitInfo.stage).length / 2) {
        stage.push(item);
      }
    });

    // stageList는 완료시 off, 미완료시 on으로 표기 기본은 on
    // img_src는 tit_pass 혹은 tit01, tit02 등으로 표기
    return _.map(stage, (item, index) => {
      const length = Object.keys(unitInfo.stage).length;
      const itemNumber = _.toNumber(item);
      const stageIndex = lesson ? itemNumber + length / 2 : item;
      const stageNumberString = stageIndex.toString();
      const isComplete = isCompleteStage(complteStageList, stageNumberString);
      return (
        <Unitli key={index} isReview={length < 9}>
          <GoStageBtn
            url={`https://cdn.cloubot.com/PM/image/cover/stageList_bg0${index + 1}.png`}
            className={`${isComplete ? 'active' : ''}`}
            isComplete={isComplete}
            onClick={(e) => onClick(e, `${unit}/stage/${stageNumberString}${window.location.search}`)}
          >
            <img
              style={{ width: '48%' }}
              src={
                isComplete
                  ? `https://cdn.cloubot.com/PM/image/cover/stageList_tit_pass.png`
                  : `https://cdn.cloubot.com/PM/image/cover/stageList_tit${stageNumberString.padStart(2, '0')}.png`
              }
              alt={`Stage ${stageNumberString}`}
            />
            <ShortDescption>{unitInfo.stage[stageIndex].shortDescption}</ShortDescption>
          </GoStageBtn>
        </Unitli>
      );
    });
  };

  return (
    <>
      <S.Main>
        <S.MainInnerWrap className="stage_num_wrap container" >
          <S.StageDirection>
            <S.UnitButton>{`Unit ${unit}`}</S.UnitButton>
            <div>Choose the Stage</div>
          </S.StageDirection>
          <StageListWrap
            isReview={Object.keys(unitInfo.stage).length < 9}
            className={`stage_list_wrap ${Object.keys(unitInfo.stage).length < 9 ? 'review' : ''}`}
          >
            {getStage(unitInfo)}
          </StageListWrap>
        </S.MainInnerWrap>
      </S.Main>
    </>
  );
};

export default UnitLayout;

const StageListWrap = styled.ul`
  display: grid;
  grid-template: ${(props) => (props.isReview ? 'repeat(2, 1fr) / repeat(2, 1fr)' : 'repeat(2, 1fr) / repeat(3, 1fr)')};
  grid-gap: 2.4rem;
  align-self: stretch;
  flex: 1 1;
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 120px);
  @media (max-height: 768px) {
    grid-gap: 1.5rem;
    height: calc(100% - 70px);
  }
  @media (max-height: 500px) {
    grid-gap: 1.5rem;
  }
`;
const Unitli = styled.li`
  /* width: ${(props) => (props.isReview ? '48%' : '33%')}; */
`;
const GoStageBtn = styled(S.ColumnFlex)`
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 12px;
  border: 4px solid #fff;
  box-sizing: border-box;
  background-color: ${(props)=>props.isComplete?'#a8a8a889':'#d9eaf8'};
  background-position: 16px bottom;
  background-size: 20%;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.url});
  :hover {
    background-color: #abc0d1;
}
`;
const ShortDescption = styled.div`
  margin-top: 4px;
  font-size: 18px;
  color: #444;
  @media (max-height: 768px) {
    font-size: 16px;
  }
  @media (max-height: 500px) {
    font-size: 13px;
  }
`;
