import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typography } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';

const VocaCheck = () => {
  const num1 = [1, 2, 3, 4, 5];

  return (
    <>
      <div className="check-con">
        <header className="tool-header">
          <h2>Click the word and then click the meaning.</h2>
          <div className="num">
            <h3>
              <span>05</span>/<span>10</span>
            </h3>
          </div>
        </header>
        <div className="check-q-wrap">
          {num1.map((chk, idx) => {
            return (
              <>
                <div className="q q-row">
                  <div className="left-wrap">
                    <div className="q-num">
                      <h3>{chk}</h3>
                    </div>
                    <div className="q-word-box">
                      <h3>word</h3>
                    </div>
                    <div className="dot">
                      <span className="circle"></span>
                    </div>
                  </div>
                  <div className="center-wrap-for-line"></div>
                  <div className="right-wrap">
                    <div className="dot">
                      <span className="circle"></span>
                    </div>{' '}
                    <div className="q-word-box">
                      <h3>word</h3>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className="nav-btm">
        <Link to="#" className="checkprev prev">
          <ArrowLeftOutlined />
        </Link>
        <div className="pager">
          <span>
            <Link to="#" className="active">
              <h3>1</h3>
            </Link>
            <Link to="#">
              <h3>2</h3>
            </Link>
            <Link to="#">
              <h3>3</h3>
            </Link>
          </span>
        </div>
        <Link to="#">
          <ArrowRightOutlined />
        </Link>
      </div>{' '}
    </>
  );
};

export default VocaCheck;
