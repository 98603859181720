import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const AdminTalkingTutorMenu = ({ currentMenu }) => {
    const history = useHistory();

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
            <MenuButton
                type={`${currentMenu === 'group_list' ? 'primary' : 'default'} `}
                onClick={() => {
                    history.push('/ai-talking-tutor/topiclist');
                }}
            >
                Group List
            </MenuButton>
            &nbsp;
            <MenuButton
                type={`${currentMenu === 'content_list' ? 'primary' : 'default'} `}
                onClick={() => {
                    history.push('/ai-talking-tutor/contentlist');
                }}
            >
                Content List
            </MenuButton>
        </div>
    );
};

export default AdminTalkingTutorMenu;
