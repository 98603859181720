import React, { useState, useEffect } from 'react';
import { Input, Modal, Select, Spin, message, Tabs, Button as AButton, Tooltip } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import styled from 'styled-components';
import { openNotification } from 'src/components/common/Notification';
import moment from 'moment';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, RedoOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Timer from 'src/components/LevelUp/Timer';
import {
  BOOK_REPORT_DEFAULT_FILL,
  BOOK_REPORT_REVISE_FILL,
  BOOK_REPORT_DEFAULT_FILL_LIST,
  BOOK_REPORT_REVISE_FILL_LIST,
  BOOK_REPORT_COMMENT_LIST,
  BOOK_REPORT_WORD_LIST,
} from 'src/constants/common';
const { TextArea } = Input;
const { Option } = Select;
const Wrapper = styled.div`
  width: 100%;

  height: 100%;
  background-color: #453dd0;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled(RowFlex)`
  justify-content: start;
  height: 75px;
  padding: 25px 65px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;
const StageTitle = styled.div`
  /* border-bottom: 1px solid #4472c4; */
  width: 100%;
  color: #453dd0;
  font-size: 32px;
  font-weight: bold;
  padding: 15px 30px;
  margin-bottom: 15px;
  background-color: #f3db4c;
  @media (max-width: 1280px) {
    font-size: 25px;
  }
`;
const StageTitleSpan = styled.span`
  color: #000000;
  font-size: 25px;
  padding-left: 20px;
  @media (max-width: 1280px) {
    font-size: 20px;
    padding-left: 15px;
  }
`;
const Title = styled(RowFlex)`
  font-size: 24px;
  font-weight: 600;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 25px 25px 0 0;
  color: #ffffff;
  text-align: center;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
  justify-content: center;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 0 45px 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 0 0px 25px;
  background-color: #ffffff;
  max-width: 1650px;
  min-width: 1350px;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 980px;
  }
`;

const HalfBody = styled.div`
  width: 50%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 85%;
`;
const TutorInput = styled.div`
  flex: 1;
  border: 2px solid ${(props) => props.borderColor};
  height: 100%;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  color: #797979;
  flex: 1;
  background-color: white;
  border-radius: 0 0 25px 25px;
  overflow: auto;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  /* line-height: 30px; */
  @media (max-width: 1280px) {
    font-size: 13px;
    line-height: 24px;
  }
`;
const NumberWrapper = styled(RowFlex)`
  align-items: baseline;
`;
const NumberBox = styled(ColumnFlex)`
  width: 90px;
  height: 30px;
  border: 2px solid #b5b5b7;
  background-color: #fff;
  border-radius: 5px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 5px;
  color: ${(props) => props.color};
  font-weight: bold;
  @media (max-width: 1280px) {
    width: 65px;
    height: 25px;
    margin: 0 5px;
    font-size: 17px;
  }
`;
const TopicArea = styled(TutorInput)`
  color: #000000;
  font-weight: 500;
  /* border: 2px #0070c0 solid; */
  max-height: 70px;
`;
const InputArea = styled(TextArea)`
  resize: none;
  padding: 20px;
  font-size: 15px;
  color: #333232;
  font-weight: 600;
  border: 2px #453ec8 solid;
  border-radius: 0 0 25px 25px;
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #453ec8 solid;
    box-shadow: none;
    /* border-right-width: unset !important; */
  }
`;
const LVSelect = styled(Select)`
  font-size: 14px;
  font-weight: bold;
  border: 2px #453ec8 solid;
  border-radius: 5px;
  color: #453ec8;
  padding: 0 5px;
  width: 130px;
  height: 34px;
  .ant-select-arrow {
    color: #453ec8;
  }
  .ant-select-selection-item {
    font-weight: bold;
  }
  ::placeholder {
    color: #453ec8;
  }
`;
const LVOption = styled(Option)`
  font-size: 14px;
  font-weight: bold;
`;
const ClearButton = styled.div`
  border-radius: 20px;
  font-size: 1.1em;
  font-weight: bold;
  color: #ffffff;
  background-color: #ff005c;
  box-shadow: 0 2px 1px 2px #bf004e;
  padding: 9px 30px;
  @media (max-width: 1720px) {
    padding: 9px 20px;
    font-size: 1em;
    /* padding: 8px 15px; */
  }
`;
const Button = styled.div`
  cursor: pointer;
  border-radius: ${(props) => (props.radius ? '20px' : '10px')};
  font-size: 1.1em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: ${(props) => (props.radius ? '9px 30px' : '9px 18px')};
  border: 2px #443dc7 solid;
  @media (max-width: 1720px) {
    padding: ${(props) => (props.radius ? '9px 20px' : '8px 15px')};
    font-size: 1em;
    /* padding: 8px 15px; */
  }
  @media (max-width: 1280px) {
    font-size: 0.8em;
    font-weight: normal;
    padding: 8px 14px;
  }
`;

const Paginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* overflow: auto; */
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #daedeb;
`;

const PageButton = styled(RowFlex)`
  cursor: pointer;
  width: 2em;
  height: 2em;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 1.3em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  border: 2px solid ${(props) => (props.isPage ? props.color : 'none')};
  margin: 0 10px;
`;
const ArrowIcon = styled.div`
  width: 23px;
  min-width: 23px;
  height: 20px;
  min-height: 20px;
  background-image: url(${(props) => props.src});
  background-size: 23px 20px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #5a5757;
  /* background: #ed7d31; */
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const LoadingScreen = styled.div`
  height: 100%;
  background: #ffffff88;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function AiEssayCorrection({ defautTopic, defaultLevel, defaultChatList = [], idx, onShowRecording }) {
  const [level, setLevel] = useState(defaultLevel || null);
  const [defaultFill, setDefaultFill] = useState(BOOK_REPORT_DEFAULT_FILL);
  const [reviseFill, setReviseFill] = useState(BOOK_REPORT_REVISE_FILL);
  const [commentFill, setCommentFill] = useState(BOOK_REPORT_COMMENT_LIST['Grade 1']);
  const [chatList, setChatList] = useState(defaultChatList.length ? defaultChatList : []);
  const [currentText, setCurrentText] = useState('');
  const [currentWord, setCurrentWord] = useState('');
  const [page, setPage] = useState(
    defaultChatList.length ? defaultChatList.filter((chat) => chat.user === 'bot' && chat.data.type !== 'word').length - 1 : 0,
  );
  const [writingType, setWritingType] = useState('sentences');
  const [language, setLanguage] = useState('Korean');
  const [startTime, setStartTime] = useState(new Date());
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [rewriteModalVisible, setRewriteModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');
  const [rewriteModalText, setRewriteModalText] = useState('');
  const [topic, setTopic] = useState(defautTopic || '');
  const [editType, setEditType] = useState('check');
  const botChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type !== 'word');
  const wordChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type === 'word');
  const botCheckChat = botChats.filter((chat) => chat.data.type === 'check');
  const botReviseChat = botChats.filter((chat) => chat.data.type === 'revise');
  const botCommentChat = botChats.filter((chat) => chat.data.type === 'comment');
  const history = useHistory();

  useEffect(() => {
    if (level) {
      setDefaultFill(BOOK_REPORT_DEFAULT_FILL_LIST[level]);
      setReviseFill(BOOK_REPORT_REVISE_FILL_LIST[level]);
      setCommentFill(BOOK_REPORT_COMMENT_LIST[level]);
    }
  }, [level]);

  const [loadMoreMessages, { data, loading, error }] = useLazyQuery(OPENAI_COMPLETION_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (updatedData?.updateAITopicUserAssignment?.status) {
      if (updatedData?.updateAITopicUserAssignment?.status === 2) {
        openNotification('답변이 저장되었습니다!');
      } else if (updatedData?.updateAITopicUserAssignment?.status === 1) {
        onShowRecording(updatedData?.updateAITopicUserAssignment?.answer);
      }
    }
  }, [updatedData]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setCurrentText(value);
  };

  useEffect(() => {
    if (data?.getOpenAiCompletion?.text?.length > 0) {
      setChatList([
        ...chatList,
        {
          user: 'bot',
          chat_user_name: 'AI봇',
          prompt: currentText,
          content: data.getOpenAiCompletion?.text,
          urls: data.getOpenAiCompletion?.urls,
          created: data?.getOpenAiCompletion?.date,
          data: data?.getOpenAiCompletion?.chat?.data ? JSON.parse(data?.getOpenAiCompletion?.chat?.data) : null,
        },
      ]);
      setPage(botChats.length);
    }
  }, [data]);

  const handleCloseClick = () => {
    Modal.confirm({
      icon: null,
      title: 'Exit',
      content: (
        <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
          학습이 완료되지 않았습니다. 재접속시 기존 학습 이후부터 진행됩니다. 학습을 종료하시겠습니까?
        </Title>
      ),
      onOk: () => history.push('/'),
    });
  };

  const onSubmit = () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          level: level,
          topic: topic,
          type: 'check',
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');

    loadMoreMessages({
      variables: {
        prompt: defaultFill.replaceAll('((level))', level).replaceAll('((topic))', currentText).replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: parseInt(idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          topic: topic,
          type: 'check',
          currentText: currentText,
        }),
      },
    });
  };

  const onAnswerSubmit = () => {
    Modal.confirm({
      icon: null,
      title: '답변 제출',
      content: (
        <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
          과제를 제출하시겠습니까?
        </Title>
      ),
      onOk: () => {
        updateAITopicUserAssignment({
          variables: {
            idx: parseInt(idx),
            answer: rewriteModalText,
            start_time: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
            end_time: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
            status: 1,
          },
        });
      },
      onCancel: () => {},
    });
  };

  const onRevise = () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          level: level,
          topic: topic,
          type: 'revise',
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: reviseFill.replaceAll('((level))', level).replaceAll('((topic))', currentText).replaceAll('((language))', language),
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: parseInt(idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          topic: topic,
          type: 'revise',
          currentText: currentText,
        }),
      },
    });
  };

  const onComment = () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: commentFill.replaceAll('((level))', level).replaceAll('((topic))', currentText).replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'comment',
          currentText: currentText,
        }),
      },
    });
  };

  const onWordCheck = () => {
    if (currentWord.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    if (!level) {
      message.error('레벨을 선택해주세요.');
      return;
    }

    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentWord,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: BOOK_REPORT_WORD_LIST[level]
          .replaceAll('((level))', level)
          .replaceAll('((word))', currentWord)
          .replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'word',
          currentText: currentWord,
        }),
      },
    });
  };
  const handleWordClear = () => {
    setCurrentWord('');
    const tmp = chatList.filter((e) => (!e.data ? true : e.data?.type !== 'word'));
    setChatList(tmp);
  };
  //   const handleClear = ()=>{
  //     form.resetFields();
  //     console.log(form)
  //   }
  const onChangeLevel = (value) => {
    setLevel(value);
    console.log(value);
  };

  const onClear = () => {
    // setChatList([]);
    setCurrentText('');
    setPage(0);
  };

  const handleModalOpen = (type) => {
    setEditType(type);
    if (type === 'check') {
      setModalText(defaultFill);
    }
    if (type === 'revise') {
      setModalText(reviseFill);
    }
    setModalVisible(true);
  };

  const handleModalChange = (e) => {
    setModalText(e.target.value);
  };

  const handleModalOk = () => {
    setModalVisible(false);
    if (editType === 'check') {
      setDefaultFill(modalText);
    }
    if (editType === 'revise') {
      setReviseFill(modalText);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setModalText('');
  };

  const handleRewriteModalCancel = () => {
    setRewriteModalVisible(false);
    setRewriteModalText('');
  };

  const handleRewriteModalOk = () => {
    setRewriteModalVisible(false);
    // setReviseFill(rewriteModalText);
  };

  const handleRewriteModalChange = (e) => {
    setRewriteModalText(e.target.value);
  };

  const onRewrite = () => {
    setRewriteModalVisible(true);
    // setRewriteModalText('');
  };

  return (
    <Wrapper>
      <Header>
        <div
          onClick={() => {
            history.push('/level-up');
          }}
          style={{ cursor: 'pointer' }}
        >
          {' '}
          <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} />
        </div>
        <HeadBox background="transparent" color={'#5a5757'} style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px' }}>
          ID: {userInfoVar()?.id}
          <CloseButton
            onClick={() => {
              history.push('/');
            }}
          >
            <CloseOutlined />
          </CloseButton>
        </HeadBox>
      </Header>
      <MainWrapper>
        <Main>
          <StageTitle>
            BOOK Title <StageTitleSpan>Volume</StageTitleSpan>
            <StageTitleSpan>Unit</StageTitleSpan>
            <StageTitleSpan>Lesson</StageTitleSpan>
            <StageTitleSpan>Lesson Name</StageTitleSpan>
          </StageTitle>
          <RowFlex style={{ height: '100%', padding: '0 20px' }}>
            <HalfBody>
              <RowFlex style={{ justifyContent: 'center', paddingBottom: '1px' }}>
                <Title backgroundColor={'#49ada1'} style={{ marginTop: '6px' }}>
                  AI Writing Tutor
                </Title>
              </RowFlex>
              <TutorInput borderColor={'#49ada1'}>
                {botChats?.length && botChats[page]?.content
                  ? botChats[page]?.content.split('\n').map((line, index) => {
                      return (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      );
                    })
                  : ''}
              </TutorInput>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Paginate>
                  <PageButton
                    color={'#453ec8'}
                    // back={'#ed7d31'}
                    isPage={false}
                    onClick={() => {
                      if (page > 0) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    <ArrowIcon src={'/images/LevelUp/study/sub/arrow_left.png'} />
                  </PageButton>
                  <RowFlex style={{ overflowX: 'auto', width: '70%' }}>
                    {botChats?.length
                      ? botChats.map((chat, index) => {
                          return (
                            <PageButton
                              key={index}
                              isPage={true}
                              color={page === index ? '#ffffff' : '#453ec8'}
                              back={page === index ? '#453ec8' : '#ffffff'}
                              onClick={() => setPage(index)}
                            >
                              {index + 1}
                            </PageButton>
                          );
                        })
                      : ''}
                  </RowFlex>
                  <PageButton
                    color={'#453ec8'}
                    // back={'#ed7d31'}
                    isPage={false}
                    onClick={() => {
                      if (page < botChats.length - 1) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    <ArrowIcon src={'/images/LevelUp/study/sub/arrow_right.png'} />
                  </PageButton>
                </Paginate>
              </div>
            </HalfBody>
            <HalfBody>
              <Title style={{ margin: '5px 0 1px 0' }} backgroundColor={'#f69283'}>
                Essay Topic / Prompt
              </Title>
              <TopicArea borderColor={'#f69283'}>{topic}</TopicArea>

              <RowFlex style={{ justifyContent: 'space-between', marginTop: '10px', marginBottom: '1px', alignItems: 'center' }}>
                <Title style={{ width: '100%' }} backgroundColor={'#453ec8'}>
                  <div style={{ width: '34%', textAlign: 'center' }}>Editor</div>
                  <RowFlex style={{ width: '66%' }}>
                    <NumberWrapper>
                      <SubTitle>Word Count : </SubTitle>
                      <NumberBox color={'#48aea1'}>{currentText.split(' ').filter((word) => word !== '').length}</NumberBox>
                    </NumberWrapper>
                    <NumberWrapper>
                      <SubTitle>Time : </SubTitle>
                      <NumberBox color={'#fe015c'}>
                        <Timer />
                      </NumberBox>
                    </NumberWrapper>
                  </RowFlex>
                </Title>
              </RowFlex>
              <div style={{ flex: 1, resize: 'none', padding: '0' }}>
                {loading ? (
                  <LoadingScreen>
                    <Spin fontSize="24px" />
                  </LoadingScreen>
                ) : (
                  <InputArea
                    style={{ resize: 'none', height: '100%' }}
                    value={currentText}
                    onChange={handleInputChange}
                    disabled={!level || loading}
                    placeholder={!level ? 'Select Your Grade' : ''}
                  />
                )}
              </div>

              <RowFlex style={{ justifyContent: 'space-between', marginTop: '12px', alignItems: 'center', padding: '10px 10px 0' }}>
                <ClearButton onClick={onClear}>CLEAR</ClearButton>

                <LVSelect
                  dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                  placeholder={` Grade `}
                  onChange={onChangeLevel}
                  bordered={false}
                  value={level}
                >
                  {[
                    { value: 'Grade 1', label: 'Basic A' },
                    { value: 'Grade 4', label: 'Basic B' },
                    {
                      value: 'Grade 7',
                      label: 'Intermediate',
                    },
                    {
                      value: 'Grade 11',
                      label: 'Advanced',
                    },
                    { value: 'College Level', label: 'College' },
                  ].map((e) => (
                    <LVOption key={e.value} value={e.value}>
                      {e.value}
                    </LVOption>
                  ))}
                </LVSelect>
                <Button disabled={loading} onClick={onSubmit} back={'#d0d1e6'} color={'#453ec8'} radius={false}>
                  1. CHECK
                </Button>
                <Button back={'#b1b4dd'} color={'#453ec8'} onClick={onRevise} radius={false}>
                  2. REVISE
                </Button>
                <Button
                  back={'#6465cf'}
                  color={'white'}
                  radius={false}
                  onClick={() => {
                    if (botChats?.length) {
                      onAnswerSubmit();
                    }
                  }}
                >
                  3. UPLOAD
                </Button>
              </RowFlex>
            </HalfBody>
          </RowFlex>
        </Main>
      </MainWrapper>
      <Modal title="Edit script" visible={modalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
        <TextArea value={modalText} onChange={handleModalChange} rows={6} />
      </Modal>
      <Modal
        title=""
        visible={rewriteModalVisible}
        onOk={handleRewriteModalOk}
        onCancel={handleRewriteModalCancel}
        width={`80%`}
        footer={null}
        bodyStyle={{
          backgroundColor: '#453dd0',
        }}
      >
        <Title style={{ margin: '15px 0 0px 0', backgroundColor: '#ffda00' }}>Book Report: Final Draft</Title>
        <RowFlex
          style={{ height: '100%', background: '#fff', padding: '10px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
        >
          <HalfBody>
            <Tabs defaultActiveKey="1" onChange={(e) => setCurrentTab(e)} value={currentTab} type="card" style={{ height: '100%' }}>
              <Tabs.TabPane tab="Original" key="1" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                <TutorInput value={currentText} style={{ height: '50vh' }}>
                  {currentText}
                </TutorInput>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Check" key="2" style={{ height: '100%' }}>
                <TutorInput style={{ height: '50vh' }}>
                  {botCheckChat?.length && botCheckChat[botCheckChat?.length - 1]?.content
                    ? botCheckChat[botCheckChat?.length - 1]?.content.split('\n').map((line, index) => {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </TutorInput>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Comment" key="3" style={{ height: '100%' }}>
                <TutorInput style={{ height: '50vh' }}>
                  {botCommentChat?.length && botCommentChat[botCommentChat?.length - 1]?.content
                    ? botCommentChat[botCommentChat?.length - 1]?.content.split('\n').map((line, index) => {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </TutorInput>
              </Tabs.TabPane>
            </Tabs>
          </HalfBody>
          <HalfBody>
            <Title style={{ margin: '5px 0 10px 0', textAlign: 'center' }}>Final Draft</Title>
            <InputArea value={rewriteModalText} onChange={handleRewriteModalChange} style={{ height: '50vh' }} />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <Button
                back={'#6ac5b8'}
                color={'white'}
                style={{ width: '100px', borderRadius: '20px', textAlign: 'center' }}
                onClick={handleRewriteModalOk}
              >
                Save
              </Button>
            </div>
          </HalfBody>
        </RowFlex>
      </Modal>
    </Wrapper>
  );
}
