import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, Pagination } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { ELIBRARY_BOOK_LIST } from 'src/operations/queries/elibraryBookList';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ELibraryMenuGroup from 'src/components/common/eLibraryMenuGroup';

const { Title } = Typography;
const { Search } = Input;

// const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
// };

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const ElibraryAssignBooks = (props) => {
  const query = new URLSearchParams(props.location.search);
  const searchPage = query.get('page');
  const isRefresh = query.get('refresh');

  const [books, setBooks] = useState([]);
  // const [selectType, setSelectType] = useState(1);
  // const [q, setQ] = useState(null);
  const [page, setPage] = useState(searchPage ? parseInt(searchPage) : 0);
  const [take, setTake] = useState(1000);
  const [total, setTotal] = useState(0);
  // const [selectedBooks, setSelectedBooks] = useState([]);
  const [checkedList, setCheckedList] = useState(new Array(books.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      level: 1,
      text: null,
      page: 0,
    },
    'we_search',
  );

  const { data, refetch } = useQuery(ELIBRARY_BOOK_LIST, {
    fetchPolicy: 'no-cache',
    variables: { level: searchValue.level, page: 0, take: 1000 },
  });
  console.log('searchValue', searchValue);
  useEffect(() => {
    if (data?.elibrarybookList) {
      setBooks(data?.elibrarybookList.book_list);
      setTotal(data?.elibrarybookList.total);
      setCheckedList(new Array(data?.elibrarybookList.book_list.length).fill(true));
      
      //props.history.replace({ search: `?page=${page}` });
    }

    if (isRefresh) {
      //refetch();
      //props.history.replace({ search: '' });
    }
  }, [data, page, props.history, searchPage, isRefresh]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    console.log('checkedList', checkedList);
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(books.length).fill(true));
    } else {
      setCheckedList([]);
    }
    console.log(checkedList);
  }
  const handleAssignData = () => {
    let data = [];
    books.forEach((item, index) => {
      checkedList.forEach((checked, index2) => {
        if (index === index2 && checked) {
          console.log('item', item);
          data.push(item);
        }
      });
    });
    localStorage.setItem('selectedBooks', JSON.stringify(data));
  };
  const columns = [
    {
      title: () => {
        return <Checkbox checked={checkedList.length === books.length} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
    {
      title: 'Code',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{record.book_lesson[0].code}</Space>;
      },
    },
    {
      title: 'Level',
      dataIndex: 'level_no',
      align: 'center',
      key: 'level',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{record.level_no}</Space>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.book_sub[0].title}</Space>;
      },
    },
  ];
  const levelList = [
    {
      id: 1,
      value: 1,
    },
    {
      id: 2,
      value: 2,
    },
    {
      id: 3,
      value: 3,
    },
    {
      id: 4,
      value: 4,
    },
    {
      id: 5,
      value: 5,
    },
    {
      id: 6,
      value: 6,
    },
    {
      id: 7,
      value: 7,
    },
    {
      id: 8,
      value: 8,
    },
    {
      id: 9,
      value: 9,
    },
    {
      id: 10,
      value: 10,
    },
    {
      id: 11,
      value: 11,
    },
    {
      id: 12,
      value: 12,
    },
  ];

  // const handleTestDelete = () => {
  //   const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
  //   const idxs = books.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
  //   props.onTestDelete({ idxs });
  //   setTimeout(() => {
  //     setCheckedList([]);
  //     refetch();
  //   }, 1000);
  // };

  function handleTableChange(pagination, filters) {
    console.log('pagination', pagination);
    console.log('filters', filters);
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }
  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="elibrary-main-block">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>Elibrary</Title>
              <ELibraryMenuGroup currentMenu={'assign'}/>
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Form.Item label="Level" className="headitems-select">
                <Select
                  style={{ width: 150, color: '#065aa9' }}
                  defaultValue={searchValue.level}
                  placeholder="Select a level"
                  optionFilterProp="children"
                  onChange={(val) =>
                    setSearchValue((prev) => {
                      return {
                        ...prev,
                        level: val,
                      };
                    })
                  }
                >
                  {levelList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.value}>
                        {item.id}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Link to="/books/manage/action/assign" className="headitems-add">
                <Button style={{ color: '#289428', marginLeft: 5 }} onClick={handleAssignData}>
                  Assign
                </Button>
              </Link>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            dataSource={books}
            columns={columns}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: take, total, current: searchValue.page + 1 }}
            size="small"
            color="#edf3fb"
            //onChange={(a) => setPage(a.current - 1)}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => onHandleRowClick(record, rowIndex),
            //   };
            // }}
            onChange={handleTableChange}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(ElibraryAssignBooks);
