import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Iframe from '@trendmicro/react-iframe';
import { GENERATE_PDF_FILE } from 'src/operations/mutations/uploadFileS3';
import { FOLDERS_LIST } from 'src/operations/queries/getFolders';
import { CREATE_FOLDERS } from 'src/operations/mutations/createFolders';
import { CREATE_TEST } from 'src/operations/mutations/createTests';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_FILES } from 'src/operations/mutations/createFiles';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { ApolloConsumer } from '@apollo/client';
import { getCurrentToken } from 'src/utils';
import { withRouter } from "react-router"
import { Radio, Modal, Button } from 'antd';
import moment from 'moment';

const IframePage = ({ client, history }) => {
  const { width = '100%', height="820px", url } = useParams();
  const [selectType, setSelectType] = useState('me');
  const [modalVisible, setModalVisible] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [total_questions, setTotalQuestions] = useState(0);
  const [filename, setFileName] = useState("data.pdf");
  const [title, setTitle] = useState("");
  const [folder_idx, setFolderIdx] = useState(null);
  const [file_folder_idx, setFileFolderIdx] = useState(null);
  const [htmlContent, setHtmlContent] = useState(null);
  const [folders, setFolders] = useState([]);
  const [is_default, setIsDefault] = useState(null);
  const [user, setUser] = useState({});

  const token = getCurrentToken();
  const { data: userData } = useQueryProxy(USER_ME, {
    variables: { token },
  });

  useEffect(() => {
    if (userData?.me) {
      setUser(userData.me);
    }
  }, [userData]);

  let reader = new window.FileReader();

  const [upload, {loading: loadingUpload}] = useMutation(GENERATE_PDF_FILE);

  const {data:folderData} = useQuery(FOLDERS_LIST, { variables: { folder_idx:null, is_default } });
  const [folderRefetch, {data:subFolderData}] = useLazyQuery(FOLDERS_LIST, { variables: { folder_idx, is_default } });

  const [createFile] = useMutation(CREATE_FILES);

  const [createFolder] = useMutation(CREATE_FOLDERS);

  const [createTest, {loading}] = useMutation(CREATE_TEST);

  const handleIframeTask = async (e) => {
    // if (e.origin !== 'https://localhost:3000') {
    //   return;
    // }

    const eventData = e.data;
    if (eventData.source === 'alist') {
      // doc.output('dataurlnewwindow', {filename:"data.pdf"})
      const answers = JSON.parse(eventData.payload.answers);
      setHtmlContent(eventData.payload.htmlContent);
      setTitle(answers.title);
      setFileName(`${answers.title}.pdf`);
      setAnswers(answers.data.map((d, i) => ({
        answer: d.split(",").map(d => {
          let tmp = document.createElement("DIV");
          tmp.innerHTML = d;
          return tmp.textContent || tmp.innerText || "";
        }),
        code: i+1, id:i,
        option_count: d.length>1 || isNaN(d.split(",")[0]) ? 0 : 5
      })));
      setTotalQuestions(answers.data.length);
      setModalVisible(true);
    }
    // else if (eventData.source === 'alist-print') {
    //   console.log(window.Android);
    //   if(window.Android && window.Android.print){
    //     window.Android.print(eventData.payload.htmlContent);
    //   }
    // }
  };
  

  
  window.addEventListener('message', handleIframeTask);

  useEffect(() => {
    if (folderData?.foldersList) {
      setFolders(folderData.foldersList);
      selectFolderType(folderData.foldersList, selectType)
    }
  }, [folderData, selectType]);

  const handleFolderCreate = async (name, is_public) => {
    try {
      await createFolder({ variables: { folder_idx, name, is_public, is_default: true } });

      client.clearStore();
      client.cache.gc();
      folderRefetch();
    } catch (e) {
      console.log(e);
    }
}

  useEffect(() => {
    if (subFolderData?.foldersList) {
      const singleFolder = (subFolderData?.foldersList?.filter((a) => a.name === "Test Center"));
      if (singleFolder.length) {
        setFileFolderIdx(singleFolder[0].idx);
      }else{
        handleFolderCreate("Test Center", true)
      }
    }
  }, [subFolderData]);

  const selectFolder = (val) => {
    setSelectType(val.target.value);
    selectFolderType(folders, val.target.value);
  }

  const selectFolderType = (mFolders, val) => {
    switch(val) {
      case "me" : {
        const singleFolder = mFolders.filter((a) => a.campus && a.user && !a.company);
        if (singleFolder.length) {
          setFolderIdx(singleFolder[0].idx)
          folderRefetch()
        }
        break;
      }
      case "campus" : {
        const singleFolder = mFolders.filter((a) => a.campus && !a.user && !a.company);
        if (singleFolder.length) {
          setFolderIdx(singleFolder[0].idx)
          folderRefetch()
        }
        break;
      }
      case "publisher" : {
        const singleFolder = mFolders.filter((a) => !a.campus && !a.user && a.company);
        if (singleFolder.length) {
          setFolderIdx(singleFolder[0].idx)
          folderRefetch()
        }
        break;
      }
    }
  }


  const onGeneratePdf = async () => {
    const {data : {generatePdfS3 : {id, mimetype, encoding}}} = await upload({ variables: { html:htmlContent, filename } });
    const { data : {createFiles: { idx }}} = await createFile({ variables: { name:filename, path:id, mime_type: mimetype, encoding, folder_idx: file_folder_idx, video_idx:null, is_public:true } });

    await createTest({ variables: {title, meta: [], code:`T-${moment().format('YYMMDDHHMMSS')}`, main_pdf_idx: idx, answers, total_questions, folder_type:selectType} });
    history.push('/test/manage/list?refresh=true')
  }

  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", margin: "0 -16px"}}>
      <Iframe src={atob(url)} style={{height:"calc(100vh)", maxWidth: width, overflow:"scroll"}}  sandbox={{allowTopNavigation:true,allowScripts:true, allowDownloads: true, allowSameOrigin:true, allowModals:true, allowPopups:true, allowForms:true}}/>

      <Modal
          title="Choose Folder to save"
          centered
          visible={modalVisible}
          footer={[
            <Button key="back" onClick={() => setModalVisible(false)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading || loadingUpload} onClick={onGeneratePdf}>
              Submit
            </Button>,
          ]}
        >
        <Radio.Group name="radiogroup" defaultValue="me" onChange={selectFolder}>
          <Radio value="me">내 개인 컨텐츠</Radio>
          {[3, 2].includes(parseInt(user.type)) ? <Radio value="campus">우리 학원 컨텐츠</Radio> : null}
          {[4, 5].includes(parseInt(user.type)) ? <Radio value="publisher">FC 본사</Radio> : null}
        </Radio.Group>
      </Modal>
    </div>
  );
};

const WithApolloClient = (props) => (
  <ApolloConsumer>
    {client => <IframePage client={client} {...props}/>}
  </ApolloConsumer>
);

export default withRouter(WithApolloClient);
