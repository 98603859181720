import { gql } from '@apollo/client';

export const CREATE_USER_STUDY = gql`
  mutation createUserStudy(
    $user_study_input: UserStudyInput!
  ) {
    createUserStudy(
        user_study_input: $user_study_input
    ) {
      idx
    }
  }
`;