import React, { useState } from 'react';
import _ from 'lodash';
import AlphabetDnDContextNewArea from './AlphabetDnDContextNewArea';

const AlphabetDnD8 = ({ customData, onEnd }) => {
  const { problem } = customData;
  const handleDragFinish = () => {
    //setSteps(steps + 1);
    onEnd(problem);
  };
  return (
    <>
      <div className="main_content  stage2_content  col2">
        <div className="" style={customData?.style}>
          <AlphabetDnDContextNewArea
            letters={[problem]}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : '/sounds/'}
            onDragFinish={handleDragFinish}
          />
          <p style={{ textAlign: 'center', marginTop: '10px' }}> {customData?.title} </p>
        </div>
      </div>
    </>
  );
};

export default AlphabetDnD8;
