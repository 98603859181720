import { gql } from '@apollo/client';

export const AI_TOPICS_LIST = gql`
  query aiTopicsList($folderType: String, $type: String, $q: String, $page: Int, $take: Int, $level: String) {
    aiTopicsList(folderType: $folderType, type: $type, q: $q, page: $page, take: $take, level: $level) {
      total
      page
      take
      aiTopics {
        idx
        topic
        company_idx
        campus_idx
        user_idx
        class_idx
        idate
        assigned_count
        data
        group_detail
        user {
          idx
          name
        }
        campus {
          idx
          name
        }
      }
    }
  }
`;

export const AI_TOPICS_SINGLE = gql`
  query aiTopicsSingle($idx: Int!) {
    aiTopicsSingle(idx: $idx) {
      idx
      topic
      company_idx
      campus_idx
      user_idx
      class_idx
      data
      idate
      user {
        idx
        name
      }
      campus {
        idx
        name
      }
    }
  }
`;

export const AI_TOPIC_GROUP_LIST = gql`
  query aiTopicGroups($class_idx: Int, $q: String, $page: Int, $take: Int, $type: String, $start_date: String, $end_date: String) {
    aiTopicGroups(class_idx: $class_idx, q: $q, page: $page, take: $take, type: $type, start_date: $start_date, end_date: $end_date) {
      total
      page
      take
      ai_topic_groups {
        idx
        title
        start_date
        end_date
        start_time
        idate
        user {
          idx
          name
        }
        class {
          idx
          name
        }
        ai_topic_date_assignments {
          idx
          start_date
          limit_time
          ai_topic_user_assignments {
            idx
            class_idx
            start_time
            end_time
            answer
            score
            status
            idate
            ai_topic {
              idx
              topic
            }
            user {
              idx
              name
            }
          }
        }
      }
    }
  }
`;
export const AI_TOPIC_FINISH_COUNT = gql`
  query aiTopicFinishCount($start_date: String, $end_date: String, $type: String!, $class_idx: Int) {
  aiTopicFinishCount(start_date:$start_date, end_date:$end_date, type:$type, class_idx:$class_idx) 
}
`;

export const AI_TOPIC_GROUP_DETAILS = gql`
  query aiTopicSingleGroup($idx: Int!) {
    aiTopicSingleGroup(idx: $idx) {
      idx
      title
      start_date
      end_date
      start_time
      user {
        idx
        name
      }
      class {
        idx
        name
      }
      ai_topic_date_assignments {
        idx
        start_date
        limit_time
        data
        ai_topic_user_assignments {
          idx
          class_idx
          start_time
          end_time
          answer
          score
          status
          idate
          ai_topic {
            idx
            topic
          }
          user {
            idx
            name
          }
        }
      }
    }
  }
`;

export const AI_TOPIC_DATE_ASSIGNMENT_DETAILS = gql`
  query aiTopicDateAssignmentDetails($idx: Int!) {
    aiTopicDateAssignmentDetails(idx: $idx) {
      idx
      start_date
      limit_time
      data
      ai_topic_user_assignments {
        idx
        class_idx
        start_time
        end_time
        answer
        score
        wpm
        recording_data
        status
        rubric_data
        idate
        thread_id
        ai_request_responses {
          prompt_text
          completion_text
          data
          idate
        }
        ai_topic {
          idx
          topic
          data
          level_up_code
          image_file_list
          audio_file_list
        }
        user {
          idx
          id
          name
        }
        class {
          idx
          name
        }
      }
    }
  }
`;

export const AI_TOPIC_USER_ASSIGNMENT_LIST = gql`
  query aiTopicUserAssignmentList(
    $class_idx: Int
    $q: String
    $page: Int
    $take: Int
    $type: String
    $student_idx: Int
    $start_date: String
    $end_date: String
  ) {
    aiTopicUserAssignmentList(
      class_idx: $class_idx
      q: $q
      page: $page
      take: $take
      type: $type
      student_idx: $student_idx
      start_date: $start_date
      end_date: $end_date
    ) {
      total
      page
      take
      ai_topic_user_assignments {
        idx
        class_idx
        start_time
        end_time
        answer
        score
        wpm
        recording_data
        status
        type
        rubric_data
        idate
        thread_id
        ai_request_responses {
          prompt_text
          completion_text
          data
          idate
        }
        ai_topic {
          idx
          topic
          data
          level_up_code
          image_file_list
          audio_file_list
        }
        user {
          idx
          id
          name
        }
        class {
          idx
          name
        }
        ai_topic_date_assignment {
          idx
          start_date
          limit_time
          data
        }
      }
    }
  }
`;

export const AI_TOPIC_DATE_ASSIGNMENT_LIST = gql`
  query aiTopicDateAssignmentList($class_idx: Int, $page: Int, $take: Int, $type: String, $start_date: String, $end_date: String) {
    aiTopicDateAssignmentList(class_idx: $class_idx, page: $page, take: $take, type: $type, start_date: $start_date, end_date: $end_date) {
      total
      page
      take
      ai_topic_date_assignments {
        idx
        data
        start_date
        limit_time
        idate
        ai_topic {
          idx
          topic
          data
          level_up_code
        }
        ai_topic_group {
          idx
          class {
            idx
            name
          }
        }
        ai_topic_user_assignments {
          idx
          user_idx
          status
        }
      }
    }
  }
`;

export const AI_TOPIC_USER_ASSIGNMENT_DETAILS = gql`
  query aiTopicUserAssignmentDetails($idx: Int!) {
    aiTopicUserAssignmentDetails(idx: $idx) {
      idx
      class_idx
      start_time
      end_time
      answer
      score
      wpm
      status
      rubric_data
      idate
      class {
        name
      }
      ai_topic {
        idx
        topic
        data
        level_up_code
        group_detail
        image_file_list
        audio_file_list
      }
      user {
        idx
        id
        name
        campus {
          name
        }
      }
      ai_topic_date_assignment {
        idx
        start_date
        limit_time
        data
        ai_topic_group {
          class {
            name
          }
        }
      }
      ai_request_responses {
        idx
        prompt_text
        completion_text
        image_urls
        idate
        data
      }
    }
  }
`;

export const AI_USER_REQUEST_RESPONSE_LIST = gql`
  query aiUserRequestResponseList {
    aiUserRequestResponseList {
      prompt_text
      completion_text
      image_urls
      idate
    }
  }
`;

export const LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS = gql`
  query levelUpAiTopicUserAssignmentDetails($external_study_idx: Int) {
    levelUpAiTopicUserAssignmentDetails(external_study_idx: $external_study_idx) {
      idx
      study_date
      idate
      data
      aiTopic
      assigned_idx
      external_study_data
      user {
        idx
        id
        name
        campus {
          name
        }
      }
      ai_request_response {
        prompt_text
        completion_text
        image_urls
        idate
        data
        idx
      }
      assigned_external_study {
        idx
        external_study_data
      }
      class_detail
    }
  }
`;
export const LEVELUP_AI_TOPIC_LIST = gql`
  query levelupAiTopicList($external_study_idx: Int!) {
    levelupAiTopicList(external_study_idx: $external_study_idx)
  }
`;
