import React from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Assign, ResultsPage,AssignExternalStudyUser } from 'src/components/ExternalStudy';

const Manage = (props) => {
  const { type = 'add', id = undefined } = useParams();

  const handleGoBack = () => {
    props.history.goBack();
  };


  const getSwitchableRoute = (val, id) => {
    switch (val) {
      case 'assign':
        return <Assign />;
     case 'assignExternalStudyUser':
        return <AssignExternalStudyUser/>;
      case 'result':
        return <ResultsPage />;
      default:
        return <Assign onClickGoback={handleGoBack} />;
    }
  };

  return (
    <>
      <Row gutter={[24, 16]}>{getSwitchableRoute(type, id)}</Row>
    </>
  );
};

export default withRouter(Manage);
