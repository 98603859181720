import { gql } from '@apollo/client';

export const OFFLINE_ASSIGN_SYLLABUS_TO_STUDENTS = gql`
  mutation OfflineAssignedSyllabusStudents(
    $lessonidx: [Int!]!
    $extra_homework : [String!]!
    $class_idx: Int
    $limit_time: Int!
    $start_time: [String!]!
    $start_date: String!
    $end_date: String!
    $books_list: [String!]!
    $booktitle: String!
    $booklevel: String!
    $bookprogram: String!
    $user_idx_list: [Int!]!
    $elibrary_time: String!
    $rowidx: Int
  ) {
    OfflineAssignedSyllabusStudents(
      lessonidx  : $lessonidx
      extra_homework : $extra_homework
      class_idx  : $class_idx
      limit_time : $limit_time
      start_time : $start_time
      books_list : $books_list
      booktitle  :  $booktitle
      booklevel  :  $booklevel
      bookprogram: $bookprogram
      user_idx_list: $user_idx_list
      start_date : $start_date
      end_date   : $end_date
      elibrary_time: $elibrary_time
      rowidx: $rowidx
    )
  }
`;

export const OFFLINE_ADD_TEXTBOOK_OFFLINESYLLABUS = gql`
  mutation OfflineSyllabusAddTextbook(
    $bookcode  : String!,
    $booktitle : String!,
  	$publisher : String!,
    $subject   : String!,
    $skillname : String!,
    $pageno    : Int,
    $registerdate: String!,
    $level : String!, 
    $memo  : String!,
    $folder_type : String!,
  ) {
    OfflineSyllabusAddTextbook(
      bookcode  : $bookcode,
      booktitle : $booktitle,
  	  publisher : $publisher,
      subject   : $subject,
      skillname : $skillname,
      pageno    : $pageno,
      registerdate: $registerdate,
      level : $level, 
      memo  : $memo,
      folder_type : $folder_type
    )
  }
`;

export const OFFLINE_SYLLABUS_TEXTBOOL_LIST_BULK_DELETE = gql`
  mutation DeleteOfflineTextBook($idx_list: [Int!]!) {
    DeleteOfflineTextBook(idx_list: $idx_list)
  }
`;


export const OFFLINE_ADD_LESSON_SYLLABUS = gql`
  mutation OfflineAddSyllabus(
    $programnameold  : String!,
    $programlevelold : String!,
    $programname  : String!,
    $programlevel : String!,
    $lessonlist: [JSONObject!]!,
    $folder_type : String!,
    $programtype : String!
  ) {
    OfflineAddSyllabus(
      programnameold  : $programnameold,
      programlevelold : $programlevelold,
      programname  : $programname,
      programlevel : $programlevel,
      lessonlist   : $lessonlist,
      folder_type  : $folder_type
      programtype  : $programtype
    )
  }
`;

export const OFFLINE_ADD_LESSON_SYLLABUS_UPDATE = gql`
  mutation OfflineAddSyllabusUpdate(
    $programnameold  : String!,
    $programlevelold : String!,
    $programname  : String!,
    $programlevel : String!,
    $lessonlist: [JSONObject!]!,
    $folder_type : String!,
    $programtype : String!
  ) {
    OfflineAddSyllabusUpdate(
      programnameold  : $programnameold,
      programlevelold : $programlevelold,
      programname  : $programname,
      programlevel : $programlevel,
      lessonlist   : $lessonlist,
      folder_type  : $folder_type
      programtype  : $programtype
    )
  }
`;



export const OFFLINE_BULK_LESSON_SYLLABUS = gql`
  mutation OfflineBulkSyllabus(
    $programnameold  : String!,
    $programlevelold : String!,
    $programname  : String!,
    $programlevel : String!,
    $lessonlist: [JSONObject!]!,
    $folder_type : String!,
    $programtype : String!
  ) {
    OfflineBulkSyllabus(
      programnameold  : $programnameold,
      programlevelold : $programlevelold,
      programname  : $programname,
      programlevel : $programlevel,
      lessonlist   : $lessonlist,
      folder_type  : $folder_type
      programtype  : $programtype
    )
  }
`;

export const OFFLINE_LESSON_ACTIVITY_BOARD = gql`
  mutation offlinelessonActivityboard(
      $programrowno  : Int!,
      $activityid : Int!,
      $booklevel     : String!,
      $enrolltitle   : String!,
      $enrollname    : String!,
      $enrolldate    : String!,
      $content       : String!, 
      $files         : [Upload!],
  ) {
    offlinelessonActivityboard(
      programrowno  :  $programrowno,
      booklevel      : $booklevel,
      enrolltitle    : $enrolltitle,
      enrollname     : $enrollname,
      enrolldate     : $enrolldate,
      content        : $content,
      files          : $files,
      activityid     : $activityid
    )
  }
`;
export const DELETE_OFFLINE_BOARD_FILE = gql`
  mutation deleteActivityBoardFile($boardFile_idx: Int!) {
    deleteActivityBoardFile(boardFile_idx: $boardFile_idx)
  }
`;
export const OFFLINE_LESSON_PLAN_ENTRY = gql`
  mutation offlineSyllabusLessonPlanEntry(
      $programrowno  : Int!,
      $booklevel     : String!,
      $plantopic     : String!,
      $planday       : String!,
      $planlearningobj    : String!,
      $plantextbook1      : String!,
      $plantextbook2      : String!,
      $lessonplancontent  : String!, 
      $homeworkcontent    : String!,
      $extrahomeworkcontent : String!,
  ) {
    offlineSyllabusLessonPlanEntry(
      programrowno   : $programrowno
      booklevel      : $booklevel,
      plantopic      : $plantopic,
      planday        : $planday,
      planlearningobj    : $planlearningobj,
      plantextbook1      : $plantextbook1,
      plantextbook2      : $plantextbook2,
      lessonplancontent  : $lessonplancontent,
      homeworkcontent    : $homeworkcontent,
      extrahomeworkcontent : $extrahomeworkcontent
    )
  }
`;

export const OFFLINE_SYLLABUS_ASSIGNED_LIST_BULK_DELETE = gql`
  mutation DeleteOfflineAssignedList($idx_list: [Int!]!) {
    DeleteOfflineAssignedList(idx_list: $idx_list)
  }
`;

export const OFFLINE_SYLLABUS_ACTIVITY_LIST_BULK_DELETE = gql`
  mutation DeleteOfflineActivity($idx_list: [Int!]!) {
    DeleteOfflineActivity(idx_list: $idx_list)
  }
`;


export const OFFLINE_ADD_SYLLABUS_LIST_BULK_DELETE = gql`
  mutation DeleteOfflineAddSyllabusList($idx_list: [Int!]!) {
    DeleteOfflineAddSyllabusList(idx_list: $idx_list)
  }
`;
