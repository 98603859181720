import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Col, Row, DatePicker, Modal, Typography, Radio, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import CustomButton from 'src/components/common/CustomButton';
// import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import { HalfWrapper } from 'src/components/common/Styles';
// import { GET_USER_INFO } from 'src/operations/queries/campus';
import * as queries from 'src/operations/queries';
import { PHONICS_DELETE_ASSIGN_ELIBRARY_BULK } from 'src/operations/mutations/createPhonicsAssignElibrary';
import PhonicsMenuGroup from 'src/components/common/PhonicsMenuGroup';
import UseHistoryState from 'src/components/common/UseHistoryState';
// import { customDayOfWeek, getMonthDateRange } from 'src/utils';
import { openNotification } from 'src/components/common/Notification';
// import * as mutations from 'src/operations/mutations';
// import { userInfoVar } from 'src/apollo/cache';
import PhonicsNoticePopup2 from 'src/popups/PhonicsNoticePopup2';

const { Title } = Typography;
const { confirm } = Modal;
const options = [
  { label: <Tooltip title="진행중">진행중</Tooltip>, value: '1' },
  { label: <Tooltip title="종료">종료</Tooltip>, value: '2' },
];
const ElibraryAssignResultTable = styled(CustomTable)`
  colgroup {
    display: table-column-group !important;
  }
`;

const ResultsPage = () => {
  const [checkRowList, setcheckRowList] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const PER_PAGE = 10;
  const [localState, setLocalState] = UseHistoryState({
    page: 1,
    date: '',
    type: 1,
  });
  const {
    data: AssignedPhonicsUnitList,
    refetch,
    loading,
  } = useQuery(queries.phonicsUnitList.ASSIGNED_PHONICS_LIST_GROUP, {
    fetchPolicy: 'no-cache',
    variables: {
      input: 'grouplist',
      date: localState.date,
      page: localState.page,
      take: PER_PAGE,
      type: localState.type,
    },
  });
  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '등록일',
      dataIndex: 'date',
      align: 'center',
      width: 170,
    },
    {
      key: 'className',
      title: '반명',
      dataIndex: 'className',
      align: 'center',
    },
    {
      key: 'units',
      title: '레벨',
      dataIndex: 'units',
      align: 'center',
    },
    {
      key: 'students_count',
      title: '학생수',
      dataIndex: 'students_count',
      align: 'center',
    },
    {
      key: 'days',
      title: '요일',
      dataIndex: 'days',
      align: 'center',
    },
    {
      key: 'start_time',
      title: '시간',
      dataIndex: 'start_time',
      align: 'center',
    },
    {
      key: 'period',
      title: '학습기간',
      dataIndex: 'period',
      align: 'center',
    },
    {
      key: 'Edit',
      title: '수정',
      dataIndex: 'Edit',
      align: 'center',
      render: (_, row) => {
        const { key, className, class_idx, title, done_count } = row;
        if (done_count === 0) {
          return (
            <Link
              to={{
                pathname: `/learningschedule/levels/manage/action/edit/assign/${class_idx}/${title}/${key}`,
                state: { className, title },
              }}
            >
              <Button>Edit</Button>
            </Link>
          );
        } else {
          return <></>;
        }
      },
    },
  ];
  let onloadfetchdata = [];
  if (AssignedPhonicsUnitList !== undefined) {
    onloadfetchdata = AssignedPhonicsUnitList.AssignedPhonicsGroupList.user_lessons;
  }

  const assignedPhonicsGroupList = useMemo(() => {
    if (AssignedPhonicsUnitList?.AssignedPhonicsGroupList?.user_lessons) {
      let fetchData = onloadfetchdata;
      return fetchData.reduce((acc, cur, index) => {
        const obj = {
          title: `${cur?.title}`,
          date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          start: moment(cur?.start_date).format('YYYY-MM-DD'),
          end: moment(cur?.end_date).format('YYYY-MM-DD'),
          className: cur?.class_name,
          total: 0,
          No: PER_PAGE * (localState.page - 1) + index + 1,
          key: `${cur?.idx}`,
          class_idx: `${cur?.class_idx}`,
          done_count: 0,
          period: cur?.all_data?.period,
          days: cur?.all_data?.days.join(', '),
          students_count: cur?.all_data?.student_count,
          units: `${cur?.all_data?.units[0]} -- ${cur?.all_data?.units[cur?.all_data?.units.length - 1 || 0]}`,
          start_time: cur?.all_data?.inputtime
            ? (cur?.all_data?.inputtime).split('T')[1].split(':')[0] + ':' + (cur?.all_data?.inputtime).split('T')[1].split(':')[1]
            : cur?.start_time,
        };
        return [...acc, obj];
      }, []);
    }
    return [];
  }, [AssignedPhonicsUnitList, localState]);
  const [deleteAssignList] = useMutation(PHONICS_DELETE_ASSIGN_ELIBRARY_BULK, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: 'Are you sure you want to delete',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk() {
          deleteAssignList({
            variables: {
              idx_list: checkRowList.map((x) => +x),
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };
  function infoConfirm() {
    Modal.warning({
      title: 'Please select the one you want to delete.',
      okText: '확인',
    });
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };
  const handleDateChange = (_, date_string) => {
    setLocalState((prev) => {
      return {
        ...prev,
        page: 1,
        date: date_string,
      };
    });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setLocalState((prev) => {
      return {
        ...prev,
        page: pagination.current,
      };
    });
  };
  return (
    <>
    <PhonicsNoticePopup2/>
    <div className="manage-action-wrapper-main">
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
          width: '100%',
        }}
      >
        <PhonicsMenuGroup currentMenu="assign-list" />
      </div>
      <Row gutter={[16, 16]}>
        <Col className="orderadmin-top-title" span={8} style={{ textAlign: 'left' }}>
          <Title level={5}>Phonics Assign List</Title>
        </Col>
        <Col span={24}>
          <HalfWrapper className="books-result-halfwrapper">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <div className="header-classroom-search" style={{ width: '100%', marginRight: '15px', textAlign: 'right' }}>
                <span style={{ paddingRight: '10px' }}>
                  <Radio.Group
                    options={options}
                    optionType="button"
                    value={localState.type.toString()}
                    buttonStyle="solid"
                    onChange={(e) => {
                      setLocalState((prev) => {
                        return {
                          ...prev,
                          page: 1,
                          type: Number(e.target.value),
                        };
                      });
                    }}
                  />
                </span>
                <DatePicker
                  placeholder="월별 검색"
                  initialValues={localState.date && moment(localState.study_date, 'YYYY-MM-DD')}
                  value={localState.date && moment(localState.date, 'YYYY-MM-DD')}
                  onChange={handleDateChange}
                />
                <>
                  <CustomButton
                    danger
                    style={{
                      marginRight: 10,
                    }}
                    onClick={deleteConfirm}
                  >
                    삭제
                  </CustomButton>
                </>
              </div>
            </div>
            <ElibraryAssignResultTable
              style={{ width: '98%' }}
              dataSource={assignedPhonicsGroupList}
              columns={columns}
              key={(r) => r.idx}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                current: localState.page,
                total: AssignedPhonicsUnitList?.AssignedPhonicsGroupList?.total || 0,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading}
              onChange={handleTableChange}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default ResultsPage;
