import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { Modal, Button, Typography, Input, Col, Row, Select } from 'antd';
import StatusCheckTableLucid from './components/StatusCheckTableLucid';
import FinalTestInputTableLucid from './components/FinalTestInputTableLucid';
import StudyCompleteTableLucid from './components/StudyCompleteTableLucid';
import BasicInfoTableLucid from './components/BasicInfoTableLucid';
import { GET_CLASSS_INFO_FOR_REPPORT } from 'src/operations/queries/getClass';
import { CREATE_REPORT_CARD } from 'src/operations/mutations/reportcard';
import { CLASS_STUDENT_LIST_FOR_REPORT_CARD } from 'src/operations/queries/getClass';

const ReportCardWriteModalLucid = ({
  class_idx,
  classBooks,
  user_name,
  user_idx,
  dateRange,
  visible,
  onCancel,
  refetchReport,
  reportCardItem,
  classBookCodes,
}) => {
  const [selectedDate, setSelectedDate] = useState(dateRange ? dateRange : [moment(new Date()), moment(new Date())]);
  const [refresh, setRefresh] = useState(0);
  const [score, setScore] = useState({});
  const [studyStatus, setStudyStatus] = useState({});
  const [teachersCommentKo, setTeachersCommentKo] = useState();
  const { data } = useQuery(GET_CLASSS_INFO_FOR_REPPORT, { variables: { class_idx }, skip: !class_idx });
  const [createRepotCard, { loading: loadingCreate }] = useMutation(CREATE_REPORT_CARD, {
    onCompleted: (res) => {
      if (res?.createCulpReportCard?.idx) {
        setScore({});
        setStudyStatus({});
        setTeachersCommentKo('');
        setRefresh((prev) => prev + 1);
        refetchReport();
        Modal.info({
          title: '저장했습니다.',
          onOk() {
            onCancel();
          },
        });
      }
    },
    onError: (e) => {
      alert('저장중 오류가 발생했습니다.');
      console.log('error===>', e);
    },
    refetchQueries: [
      {
        query: CLASS_STUDENT_LIST_FOR_REPORT_CARD,
        variables: { class_idx, hasReportCard: true },
      },
    ],
  });

  useEffect(() => {
    if (dateRange) {
      setSelectedDate(dateRange);
    }
  }, [dateRange]);

  const basicInfo = useMemo(() => {
    if (data) {
      return {
        class_name: data?.classInfo?.name,
        book_title: data?.classInfo?.book?.book_sub[0]?.title,
        teacher_name: data?.classInfo?.class_teacher[0]?.user?.name,
        campus_name: data?.classInfo?.campus?.name,
        is_phonics: parseInt(data?.classInfo?.book?.level_no) > 0 ? false : true,
      };
    }
    return null;
  }, [data]);

  const handleSave = useCallback(() => {
    const [sdate, edate] = selectedDate.map((d) => d.toDate());
    const score_result = {
      ...score,

      book_codes: classBookCodes.map((v) => classBooks[v]?.book_code),
      classBooks: classBookCodes.map((v) => classBooks[v]),
    };
    const study_status_result = {
      ...studyStatus,
    };
    const comment_ko = teachersCommentKo;

    const dataForCreate = {
      class_idx: parseInt(class_idx),
      user_idx: parseInt(user_idx),
      sdate,
      edate,
      score_result,
      study_status_result,
      comment_ko,
    };
    console.log('dataForCreate===>', dataForCreate);
    createRepotCard({ variables: { ...dataForCreate } });
  }, [class_idx, user_idx, selectedDate, score, studyStatus, teachersCommentKo]);

  const handleChangeScore = (scoreObj) => {
    setScore(scoreObj);
  };

  const handleChangeStatus = (status) => {
    setStudyStatus({ ...studyStatus, ...status });
  };

  const handleCancel = () => {
    setScore({});
    setStudyStatus({});
    setTeachersCommentKo('');
    setRefresh((prev) => prev + 1);
    onCancel();
  };

  useEffect(() => {
    if (reportCardItem?.comment_ko) {
      setTeachersCommentKo(reportCardItem?.comment_ko);
    }
  }, [reportCardItem]);

  return (
    <Modal
      title={
        <div style={{ textAlign: 'right', paddingRight: 40 }}>
          <Button type="primary" loading={loadingCreate} onClick={handleSave}>
            저장하기
          </Button>
        </div>
      }
      maskClosable={false}
      visible={visible}
      onCancel={handleCancel}
      width={'60%'}
      footer={null}
    >
      <BasicInfoTableLucid
        user_name={user_name}
        dateRange={selectedDate}
        basicInfo={basicInfo}
        setDateRange={setSelectedDate}
        editable={true}
      />
      <Row gutter={[16, 16]}>
        <Col span={24} className="border-up-thick">
          <StudyCompleteTableLucid
            bookCode={data?.classInfo?.book_code}
            class_idx={class_idx}
            user_idx={user_idx}
            book_codes={classBookCodes.map((v) => classBooks[v]?.book_code)}
            classBooks={classBookCodes.map((v) => classBooks[v])}
            study_range={selectedDate}
          />
        </Col>
        <Col span={24}>
          <FinalTestInputTableLucid
            key={`final-test-input-table${refresh}`}
            class_idx={class_idx}
            user_idx={user_idx}
            dateRange={selectedDate}
            onChangeScore={handleChangeScore}
            bookTitle={basicInfo?.book_title}
            result={reportCardItem?.score_result}
            editable={true}
          />
        </Col>
        <Col span={24}>
          <StatusCheckTableLucid
            key={`status-check-table${refresh}`}
            onChangeStatus={handleChangeStatus}
            data={reportCardItem}
            editable={true}
            basicInfo={basicInfo}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ReportCardWriteModalLucid;
