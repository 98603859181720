import { gql } from '@apollo/client';

export const SCHEDULE_DATA = gql`
  query getMetaSchedule($campus_idx: Int!) {
    getMetaSchedule(campus_idx: $campus_idx) {
      idx
      campus_idx
      days
      start_time
      end_time
      no_of_system
      idate
    }
  }
`;

export const DAY_WISE_SCHEDULE_DATA = gql`
  query getDayWiseMetaSchedule($campus_idx: Int!, $date: String!) {
    getDayWiseMetaSchedule(campus_idx: $campus_idx, date: $date) {
      idx
      campus_idx
      days
      start_time
      end_time
      no_of_system
      idate
    }
  }
`;
export const GET_SCHEDULE_OR_NOT = gql`
  query getScheduleornot($campus_idx: Int!) {
    getScheduleornot(campus_idx: $campus_idx)
  }
`;
