import { gql } from '@apollo/client';

export const CREATE_USER_VACATION_HISTORY = gql`
  mutation createUserVacationHistory($input: UserVacationHistoryInput!) {
    createUserVacationHistory(input: $input) {
      idx
      id
    }
  }
`;

export const RETURN_TO_CAMPUS = gql`
  mutation returnToCampus($input: UserVacationHistoryInput!) {
    returnToCampus(input: $input) {
      idx
      id
    }
  }
`;
