import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

const FieldChecker = styled.span`
  color: red;
  &:after {
    content: '* ';
  }
`;

const FormField = ({ hasFeedback, title, children, span = 12, subFirstSpan = 8, subSecondSpan = 16 }) => {
  return (
    <Col span={span} style={{ paddingBottom: 15 }}>
      <Row>
        <Col xl={subFirstSpan} xs={12}>
          {hasFeedback ? <FieldChecker /> : <>&nbsp; </>}
          {title}
        </Col>
        <Col span={subSecondSpan}>{children}</Col>
      </Row>
    </Col>
  );
};

export default FormField;
