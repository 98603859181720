import { gql } from '@apollo/client';

export const CREATE_BOARD_WRITE = gql`
  mutation createBoardWrite($wr_subject: String!, $wr_content: String!, $bo_table: String!, $files: [Upload!]) {
    createBoardWrite(wr_subject: $wr_subject, wr_content: $wr_content, bo_table: $bo_table, files: $files) {
      idx
      board_idx
      wr_subject
      wr_content
      wr_hit
      wr_datetime
      user {
        idx
        id
        name
        campus_idx
      }
      boardFile {
        idx
        board_write_idx
        bf_file
        bf_fileName
        mimetype
        encoding
        bf_datetime
      }
    }
  }
`;

export const UPDATE_BOARD_WRITE = gql`
  mutation updateBoardWrite($board_write_idx: Int!, $wr_subject: String!, $wr_content: String!, $bo_table: String!, $files: [Upload!]) {
    updateBoardWrite(
      board_write_idx: $board_write_idx
      wr_subject: $wr_subject
      wr_content: $wr_content
      bo_table: $bo_table
      files: $files
    ) {
      idx
      board_idx
      wr_subject
      wr_content
      wr_hit
      wr_datetime
      user {
        idx
        id
        name
        campus_idx
      }
      boardFile {
        idx
        board_write_idx
        bf_file
        bf_fileName
        mimetype
        encoding
        bf_datetime
      }
    }
  }
`;

export const DELETE_BOARD = gql`
  mutation deleteBoard($boardWrite_idx_list: [Int!]!) {
    deleteBoard(boardWrite_idx_list: $boardWrite_idx_list)
  }
`;

export const CREATE_BOARD_COMMENT = gql`
  mutation createBoardComment($board_idx: Int!, $board_write_idx: Int!, $user_idx: Int!, $co_content: String!, $co_name: String!) {
    createBoardComment(
      board_idx: $board_idx
      board_write_idx: $board_write_idx
      user_idx: $user_idx
      co_content: $co_content
      co_name: $co_name
    ) {
      idx
      co_content
      co_name
    }
  }
`;

export const DELETE_BOARD_FILE = gql`
  mutation deleteBoardFile($boardFile_idx: Int!) {
    deleteBoardFile(boardFile_idx: $boardFile_idx)
  }
`;


export const UPDATE_HIT = gql`
  mutation updateHit($boardWrite_idx: Int!) {
    updateHit(boardWrite_idx: $boardWrite_idx)
  }
`;