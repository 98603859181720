import React from 'react';
import { Modal, Image } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
const StudyNoticePopupForStudents = ({ visible }) => {
  return (
    <NoticeModal
      width="30vw"
      bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      visible={visible}
      title={null}
      // onCancel={handleClose}
      maskClosable={false}
      // okText="학습 종료"
      // cancelText="닫기"
      closable={false}
      // onOk={handleDone}
      footer={null}
    >
      <div style={{ textAlign: 'center' }}>
        <Image
          style={{ cursor:'pointer'}}
          preview={false}
          src="/images/popup/see_you.png"
          onClick={() => {
            window.location.reload();
          }}
        />
      </div>
    </NoticeModal>
  );
};

export default StudyNoticePopupForStudents;
const NoticeModal = styled(Modal)`
  border-radius: 50px;
  top:calc(100vh - 70%);
  .ant-modal-content {
    border-radius: 50px;
  }
  .ant-modal-body {
    padding: 0;
    border-radius: 50px;
  }
`;
