import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EVINE_BOOK_LESSON_INFO, EVINE_STUDY_DATA } from 'src/operations/queries/getEvineLesson';
import { CREATE_USER_STUDY } from 'src/operations/mutations/userStudy';
import { useQueryProxy } from 'src/operations/proxy/user';
import { ListPage, VocaPage, ListenPage, QuizPage, ListenRepeatPage, EssayPage, ListenWordPage } from 'src/components/Tool';
import { getCurrentToken } from 'src/utils';
import * as queries from 'src/operations/queries';
const { Title } = Typography;

const wordData = [
  {
    no: 1,
    eng: 'ant',
    kor: '개미',
    eng_audio: '/audio/Voca/ant.mp4',
  },
  {
    no: 2,
    eng: 'apple',
    kor: '사과',
    eng_audio: '/audio/Voca/apple.mp4',
  },
  {
    no: 3,
    eng: 'album',
    kor: '앨범',
    eng_audio: '/audio/Voca/album.mp4',
  },
  {
    no: 4,
    eng: 'bus',
    kor: '버스',
    eng_audio: '/audio/Voca/bus.mp4',
  },
  {
    no: 5,
    eng: 'bat',
    kor: '야구방망이',
    eng_audio: '/audio/Voca/bat.mp4',
  },
  {
    no: 6,
    eng: 'ball',
    kor: '공',
    eng_audio: '/audio/Voca/ball.mp4',
  },
  {
    no: 7,
    eng: 'cat',
    kor: '고양이',
    eng_audio: '/audio/Voca/cat.mp4',
  },
  {
    no: 8,
    eng: 'cup',
    kor: '컵',
    eng_audio: '/audio/Voca/cup.mp4',
  },
  {
    no: 9,
    eng: 'candy',
    kor: '캔디',
    eng_audio: '/audio/Voca/candy.mp4',
  },
];

const getUpdatedList = (quesData, all) => {
  for (let i = 0; i < quesData.length; i++) {
    let randDot = quesData[i]['eng'];
    randDot = randDot.replace(/[<>\[\]]*/g, '');
    quesData[i]['qns'] = randDot.split(' ');
    quesData[i]['ans'] = randDot;
    randDot = randDot
      .trim()
      .split('')
      .map((v) => ((Math.random() - 0.2 > 0.2 || all) && v.match(/^[0-9a-zA-Z]+$/) ? '⬤' : v))
      .join('');
    quesData[i]['ques'] = randDot;
    quesData[i]['eng_audio'] = quesData[i]['eng_audio'];
    quesData[i]['kor'] = quesData[i]['kor'];
  }

  return quesData;
};

const defaultData = getUpdatedList(wordData, false);

const titleData = {
  voca: 'Words',
  sentence: ' Key Sentence',
  Essay: 'Essay',
};

const Manage = (props) => {
  const { type = 'voca', code = undefined } = useParams();
  const [indx, setIndx] = useState(1);
  const [activeB, setActiveB] = useState(false);
  const [dataSet, setDataset] = useState(false);
  const [bookInfo, setBookInfo] = useState(null);
  const [isBookInfo, setIsBookInfo] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lessonActualData, setLessonActualData] = useState([]);
  const [lessonData, setLessonData] = useState([]);

  const [getBookInfo, { data: dataBookInfo, loading }] = useLazyQuery(EVINE_BOOK_LESSON_INFO, { fetchPolicy: 'no-cache' });
  const [getStudyData, { data: dataStudy, loading: loading2 }] = useLazyQuery(EVINE_STUDY_DATA, { fetchPolicy: 'no-cache' });

  const [createUserStudy] = useMutation(CREATE_USER_STUDY);

  const token = getCurrentToken();
  const { data: userData } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });

  useEffect(() => {
    if (!isBookInfo && code) {
      getBookInfo({
        variables: {
          lessonCode: code,
        },
      });
      setIsBookInfo(true);
    } else if ((code && code === 'all') || !code) {
      setLessonData(defaultData);
      setLessonActualData(defaultData);
      setDataLoaded(true);
      if (props.onChangeTitle) {
        props.onChangeTitle('Essay');
      }
    }

    if (dataBookInfo?.bookLessonInfo) {
      let filteredLessonInfo = JSON.parse(JSON.stringify(dataBookInfo?.bookLessonInfo));
      filteredLessonInfo['stage'] = filteredLessonInfo.stage.filter((f) => f.study_tool);
      console.log(filteredLessonInfo);
      setBookInfo(filteredLessonInfo);
      const isComplete = filteredLessonInfo.stage?.every((stageItem) => stageItem.is_complete);
      console.log('isComplete', isComplete);
      let newIndx = indx;
      if (!dataSet) {
        for (let index = 0; index < filteredLessonInfo.stage.length; index++) {
          const element = filteredLessonInfo.stage[index];
          if (element.is_complete && newIndx < filteredLessonInfo.stage.length) {
            newIndx += 1;
          }
        }
        if (!isComplete) {
          setIndx(newIndx);
        }
        setDataset(true);
      }
      if (props.onChangeTitle) {
        props.onChangeTitle(titleData[filteredLessonInfo.stage_group_code]);
        if (filteredLessonInfo.stage_group_code === 'essay') {
          props.onSetHideHeader(true);
        }
      }
      getStudyData({
        variables: {
          lesson_code: code,
          study_tool_idx: filteredLessonInfo.stage[newIndx - 1].study_tool.idx,
        },
      });
    }
  }, [type, code, dataBookInfo, indx, isBookInfo, getBookInfo, getStudyData, props, dataSet]);

  useEffect(() => {
    if (dataStudy?.getStudyData) {
      setLessonActualData(JSON.parse(JSON.stringify(dataStudy?.getStudyData?.study_lang_data)));
      setLessonData(
        getUpdatedList(JSON.parse(JSON.stringify(dataStudy?.getStudyData?.study_lang_data)), bookInfo.stage_group_code === 'voca'),
      );
      setDataLoaded(true);
    }
  }, [dataStudy, bookInfo]);

  const handleGoBack = (v) => {
    handleUserStudySave(v, goBackCallback);
  };

  const updateNextCallback = useCallback(() => {
    setIndx((prev) => prev + 1);
  }, []);

  const goBackCallback = useCallback(() => {
    props.history.goBack();
  }, [props.history]);

  const handleGoNext = (v, noPopup = false) => {
    if (noPopup) {
      if (indx < 3 && type !== 'essay') {
        handleUserStudySave(v, updateNextCallback);
      } else {
        handleUserStudySave(v, goBackCallback);
      }
    } else if (indx < 3 && type !== 'essay') {
      Modal.destroyAll();
      Modal.confirm({
        icon: null,
        title: 'Next',
        content: (
          <Title level={4}>
            {`${v.message ? `${v.message}` : ''}`}
            <br />
            {`Good job!, Let\'s go to next stage.`}
          </Title>
        ),
        onOk: () => handleUserStudySave(v, updateNextCallback),
      });
    } else {
      Modal.destroyAll();
      Modal.confirm({
        icon: null,
        title: 'Exit',
        content: (
          <Title level={4}>
            {`${v.message ? `${v.message}` : ''}`}
            <br />
            {` Are you sure you want to exit?`}
          </Title>
        ),
        onOk: () => handleUserStudySave(v, goBackCallback),
        okText: 'Yes',
        cancelText: 'No',
      });
    }
  };

  const handleChangeIndx = (i) => {
    setActiveB(false);
    setIndx(i);
  };
  const handleUserStudySave = async (studyData = null, c) => {
    if (studyData) {
      try {
        delete studyData['message'];
        await createUserStudy({
          variables: {
            user_study_input: {
              ...studyData,
              user_idx: parseInt(userData.me.idx),
              lesson_code: code,
              stage_no: bookInfo.stage[indx - 1]['no'],
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
    c();
  };

  const getSwitchableRoute = (val) => {
    switch (val) {
      case 'voca':
        switch (indx) {
          case 1:
            return <VocaPage onClickGoback={handleGoBack} gotoNext={handleGoNext} data={lessonData} />;
          case 2:
            return <QuizPage onClickGoback={handleGoBack} gotoNext={handleGoNext} data={lessonData} />;
          case 3:
            return <ListenPage onClickGoback={handleGoBack} type="voca" data={lessonData} gotoNext={handleGoNext} listenStageType={2} />;
          default:
            return <VocaPage onClickGoback={handleGoBack} gotoNext={handleGoNext} data={lessonData} />;
        }

      case 'sentence':
        switch (indx) {
          case 1:
            return <ListenRepeatPage onClickGoback={handleGoBack} data={lessonData} gotoNext={handleGoNext} />;
          case 2:
            return (
              <ListenPage onClickGoback={handleGoBack} type="sentence" gotoNext={handleGoNext} data={lessonData} listenStageType={1} />
            );
          case 3:
            return (
              <ListenWordPage onClickGoback={handleGoBack} type="sentence" data={lessonData} gotoNext={handleGoNext} propsStageType={2} />
            );
          default:
            return <ListenRepeatPage onClickGoback={handleGoBack} data={lessonData} gotoNext={handleGoNext} />;
        }

      case 'word':
        return <VocaPage onClickGoback={handleGoBack} data={lessonData} />;
      case 'quiz':
        return <QuizPage onClickGoback={handleGoBack} data={lessonData} />;
      case 'listen':
        return <ListenPage onClickGoback={handleGoBack} data={lessonData} />;
      case 'listen-repeat':
        return <ListenRepeatPage onClickGoback={handleGoBack} data={lessonData} />;
      case 'translate':
        return <ListenPage onClickGoback={handleGoBack} data={lessonData} listenStageType={2} />;
      case 'translate-word':
        return <ListenWordPage onClickGoback={handleGoBack} data={lessonData} propsStageType={1} />;
      case 'essay':
        return (
          <EssayPage
            gotoNext={handleGoNext}
            onClickGoback={handleGoBack}
            data={lessonData}
            code={code}
            userData={userData}
            bookTitle={bookInfo.title}
          />
        );
      default:
        return <ListPage onClickGoback={handleGoBack} />;
    }
  };

  const getBelowNavigator = (val) => {
    switch (val) {
      case 'voca':
        return (
          <Col span={24} className={`tail__section ${activeB ? 'active' : ''}`} style={{ padding: 0 }}>
            <div className="tail__layer" onClick={() => setActiveB(false)}></div>
            <div className={`tail__box ${activeB ? 'active' : ''}`}>
              <button type="button" className="tail__btn" deluminate_imagetype="png" onClick={() => setActiveB(!activeB)}></button>
              {activeB ? (
                <div className="section__inner">
                  <h3 className="tail__title">
                    <img src="/images/words.png" alt="voca" style={{ maxWidth: 500 }} />
                  </h3>
                  <ul className="tail__process">
                    <li onClick={() => handleChangeIndx(1)}>
                      <img className={`${indx === 1 ? 'active' : ''}`} src="/images/wordintro.png" alt="Intro" />
                    </li>
                    <li onClick={() => handleChangeIndx(2)}>
                      <img className={`${indx === 2 ? 'active' : ''}`} src="/images/wordpractice.png" alt="Intro" />
                    </li>
                    <li onClick={() => handleChangeIndx(3)}>
                      <img className={`${indx === 3 ? 'active' : ''}`} src="/images/wordtype.png" alt="Intro" />
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </Col>
        );
      case 'sentence':
        return (
          <Col span={24} className={`tail__section ${activeB ? 'active' : ''}`} style={{ padding: 0 }}>
            <div className="tail__layer" onClick={() => setActiveB(false)}></div>
            <div className={`tail__box ${activeB ? 'active' : ''}`}>
              <button type="button" className="tail__btn" deluminate_imagetype="png" onClick={() => setActiveB(!activeB)}></button>
              {activeB ? (
                <div className="section__inner">
                  <h3 className="tail__title">
                    <img src="/images/keysentences.png" alt="WORDS" style={{ maxWidth: 500 }} />
                  </h3>
                  <ul className="tail__process">
                    <li onClick={() => handleChangeIndx(1)}>
                      <img className={`${indx === 1 ? 'active' : ''}`} src="/images/keysentencesay.png" alt="Intro" />
                    </li>
                    <li onClick={() => handleChangeIndx(2)}>
                      <img className={`${indx === 2 ? 'active' : ''}`} src="/images/keysentencedictation.png" alt="Intro" />
                    </li>
                    <li onClick={() => handleChangeIndx(3)}>
                      <img className={`${indx === 3 ? 'active' : ''}`} src="/images/keysentenceunscramble.png" alt="Intro" />
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </Col>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Row gutter={[24, 16]}>
        {dataLoaded ? getSwitchableRoute(type) : null}
        {getBelowNavigator(type)}
      </Row>
    </>
  );
};

export default withRouter(Manage);
