import React, { useEffect, useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { isVowel, alphabetBoxTypeChecker } from '../../utils/pm/util';
import SoundButton from './SoundButton';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as S from 'src/pages/PhonicsMonster/style';
let bookNameUnitStage = undefined;
let bookNameUnitStageAdd = undefined;
let bookNameUnitStageExcep = undefined;
const isConsonantExist = (c, arr) => {
  const consonantArr = arr.map((v) => v.substr(0, 1));

  return consonantArr.indexOf(c) !== -1;
};

const AlphabetDnD3 = ({ upperWord, directionEnd, stages, setSteps }) => {
  const [vowel, setVowel] = useState(undefined);
  const [consonant, setConsonant] = useState(undefined);
  const [isCorrect, setIsCorrect] = useState([false, false]);
  const [wordIndex, setWordIndex] = useState(0);

  const [dropSound, setDropSound] = useState(undefined);
  const [eachSound, setEachSound] = useState(undefined);
  const { level, unit, stage } = useParams();
  console.log(vowel,consonant,isCorrect,wordIndex,eachSound,dropSound,upperWord)
  if (level == '3b' && unit == 9 && stage == 2) {
    bookNameUnitStage = true;
    bookNameUnitStageAdd = true;
    bookNameUnitStageExcep = { e: true };
  }

  const handleDragEnd = useCallback(
    (result) => {
      console.log('handleDragEnd', result);
      const { destination, source } = result;
      if (_.isNil(destination)) {
        setDropSound('wrong_sound1');
        return;
      }

      if (destination.droppableId === source.droppableId) {
        return;
      }

      setDropSound('mouse-click');
      const copy = _.cloneDeep(isCorrect);

      if (destination.droppableId === 'consonant') {
        copy[0] = true;
      } else {
        copy[1] = true;
      }

      if (copy.every((v) => v)) {
        const copyVowel = _.cloneDeep(vowel);
        copyVowel.splice(copyVowel.indexOf(upperWord[wordIndex].substr(1)), 1);
        setVowel(copyVowel);
        if (!isConsonantExist(upperWord[wordIndex].substr(0, 1), upperWord.slice(wordIndex + 1))) {
          const copyConsonant = _.cloneDeep(consonant);
          copyConsonant.splice(copyConsonant.indexOf(upperWord[wordIndex].substr(0, 1)), 1);
          setConsonant(copyConsonant);
        }
      }
      setIsCorrect(copy);
    },
    [isCorrect],
  );

  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      console.log('handleDropSoundEnd', isCorrect);
      if (isCorrect.every((v) => v)) {
        setEachSound(upperWord[wordIndex]);
      }
    }
    setDropSound(undefined);
  };
  const handleEachSoundEnd = () => {
    if (isCorrect.every((v) => v)) {
      if (wordIndex < upperWord.length - 1) {
        setWordIndex(wordIndex + 1);
      } else {
        setSteps((prev) => prev + 1);
      }
    }
    setEachSound(undefined);
  };

  useEffect(() => {
    const aWord = [];
    const aVowel = [];
    _.each(upperWord, (item) => {
      // 중복된 것들이 들어가지 않도록 거르기... 중복된 key값이 들어가는 경우에는 drag에서 문제가 생김
      if (aWord.indexOf(item.substring(0, 1)) === -1) aWord.push(item.substring(0, 1));
      aVowel.push(item.substring(1));
    });
    setConsonant(aWord);
    setVowel(aVowel);
  }, [upperWord]);

  useEffect(() => {
    if (upperWord[wordIndex]) {
      setIsCorrect([false, false]);

      setEachSound(upperWord[wordIndex]);
    }
  }, [wordIndex, upperWord]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <S.ColumnFlex
          style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
          className="main_content  stage2-2_content  row2_arrow"
        >
          <Box className="top_box" style={{ position: 'relative' }}>
            <SoundButton src={`${upperWord[wordIndex]}.mp3`} style={{ position: 'absolute', top: '10px', right: '10px' }} />
            <S.AlphabetWrap className="alphabet_wrap">
              <Droppable droppableId="consonant" type={!_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(0, 1)}>
                {(provided, snapshot) => (
                  <li ref={provided.innerRef}>
                    <S.AlphabetBox
                      row={alphabetBoxTypeChecker(1)}
                      gray={!isCorrect[0]}
                      className={`alphabet_box row1 ${isCorrect[0] ? 'green' : 'gray'}`}
                      style={{
                        backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
                      }}
                    >
                      {bookNameUnitStage && bookNameUnitStageAdd ? (
                        <span
                          style={{ color: bookNameUnitStageExcep[upperWord[wordIndex].substr(0, 1)] == true ? '#f368e0' : '#2e86de' }}
                          className={`font_${bookNameUnitStageExcep[upperWord[wordIndex].substr(0, 1)] == true ? 'pink' : 'blue'}`}
                        >
                          {isCorrect[0] ? !_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(0, 1) : undefined}
                        </span>
                      ) : (
                        <span style={{ color: '#2e86de' }} className="font_blue">
                          {isCorrect[0] ? !_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(0, 1) : undefined}
                        </span>
                      )}
                    </S.AlphabetBox>
                    <ul style={{ display: 'none' }}>{provided.placeholder}</ul>
                  </li>
                )}
              </Droppable>

              <li>
                <div style={{ margin: '0 8px' }} className="sign">
                  +
                </div>
              </li>

              <Droppable droppableId="vowel" type={!_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(1)}>
                {(provided, snapshot) => (
                  <li ref={provided.innerRef}>
                    <S.AlphabetBox
                      row={alphabetBoxTypeChecker(4)}
                      gray={!isCorrect[1]}
                      className={`alphabet_box row3 ${isCorrect[1] ? 'blue' : 'gray'}`}
                      style={{
                        backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
                      }}
                    >
                      {bookNameUnitStage && bookNameUnitStageAdd
                        ? _.map(!_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(1), (item, itemkey) => (
                            <span
                              key={item}
                              style={{ color: isCorrect[1] ? (bookNameUnitStageExcep[item] == true ? '#f368e0' : '#2e86de') : undefined }}
                              className={isCorrect[1] ? `font_${bookNameUnitStageExcep[item] == true ? 'pink' : 'blue'}` : undefined}
                            >
                              {isCorrect[1] ? item : undefined}
                            </span>
                          ))
                        : _.map(!_.isNil(upperWord[wordIndex]) && upperWord[wordIndex].substr(1), (item) => (
                            <span
                              key={item}
                              style={{ color: isCorrect[1] ? (isVowel(item) ? '#f368e0' : '#2e86de') : undefined }}
                              className={isCorrect[1] ? `font_${isVowel(item) ? 'pink' : 'blue'}` : undefined}
                            >
                              {isCorrect[1] ? item : undefined}
                            </span>
                          ))}
                    </S.AlphabetBox>
                    <ul style={{ display: 'none' }}>{provided.placeholder}</ul>
                  </li>
                )}
              </Droppable>

              <li>
                <div style={{ margin: '0 8px' }} className="sign">
                  =
                </div>
              </li>

              <li>
                <S.AlphabetBox
                  row={alphabetBoxTypeChecker(4)}
                  style={{
                    borderColor: isCorrect.every((v) => v) && '#feca57',
                    boxShadow: isCorrect.every((v) => v) && '0 6px 0 #ff9f43',
                    backgroundColor: isCorrect.every((v) => v) && '#fff',
                  }}
                  gray={!isCorrect.every((v) => v)}
                  className={`alphabet_box row3 ${isCorrect.every((v) => v) ? 'yellow' : 'gray'}`}
                >
                  {bookNameUnitStage && bookNameUnitStageAdd
                    ? _.map(upperWord[wordIndex], (item, itemkey) => (
                        <span
                          data="item1"
                          key={item}
                          style={{ color: bookNameUnitStageExcep[item] == true ? '#f368e0' : '#2e86de' }}
                          className={bookNameUnitStageExcep[item] == true ? 'font_pink' : 'font_blue'}
                        >
                          {isCorrect.every((v) => v) ? item : undefined}
                        </span>
                      ))
                    : _.map(upperWord[wordIndex], (item) => (
                        <span
                          style={{ color: isVowel(item) ? '#f368e0' : '#2e86de' }}
                          data="item2"
                          key={item}
                          className={isVowel(item) ? 'font_pink' : 'font_blue'}
                        >
                          {isCorrect.every((v) => v) ? item : undefined}
                        </span>
                      ))}
                </S.AlphabetBox>
              </li>
            </S.AlphabetWrap>
          </Box>
          <Arrow src="/images/pm/i_arrow_top.svg" alt="위로 향하는 화살표" />
          <Box className="bottom_box" style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <S.AlphabetWrap className="alphabet_wrap row1_wrap" style={{ width: '30%' }}>
              {_.map(consonant, (item, index) => (
                <Droppable key={item + index} droppableId={item + 'drop'} isDropDisabled={true} type={consonant && consonant[index]}>
                  {(provided) => (
                    <li ref={provided.innerRef}>
                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided) => (
                          <S.AlphabetBox
                            row={alphabetBoxTypeChecker(1)}
                            key={item}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="alphabet_box row1 green"
                          >
                            {bookNameUnitStage && bookNameUnitStageAdd ? (
                              <span
                                style={{ color: bookNameUnitStageExcep[item] == true ? '#f368e0' : '#2e86de' }}
                                className={`font_${bookNameUnitStageExcep[item] == true ? 'pink' : 'blue'}`}
                              >
                                {item}
                              </span>
                            ) : (
                              <span style={{ color: '#2e86de' }} className="font_blue">
                                {item}
                              </span>
                            )}
                          </S.AlphabetBox>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </li>
                  )}
                </Droppable>
              ))}
            </S.AlphabetWrap>

            <S.AlphabetWrap className="alphabet_wrap row1_wrap" style={{ width: '70%',height:'100%' }}>
              {_.map(vowel, (item, index) => (
                <Droppable key={item} droppableId={item + 'drop'} isDropDisabled={true} type={vowel && vowel[index]}>
                  {(provided) => (
                    <li ref={provided.innerRef}>
                      <Draggable key={index + item} draggableId={item} index={index}>
                        {(provided) => (
                          <S.AlphabetBox
                            row={alphabetBoxTypeChecker(4)}
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // style={{margin:'0.3rem'}}
                            className="alphabet_box row1 long-width blue"
                          >
                            {bookNameUnitStage && bookNameUnitStageAdd
                              ? _.map(item, (word) => (
                                  <span
                                    data="item5"
                                    data1={item}
                                    data2={word}
                                    key={word}
                                    style={{ color: bookNameUnitStageExcep[word] == true ? '#f368e0' : '#2e86de' }}
                                    className={`font_${bookNameUnitStageExcep[word] == true ? 'pink' : 'blue'}`}
                                  >
                                    {word}
                                  </span>
                                ))
                              : _.map(item, (word) => (
                                  <span
                                    style={{ color: isVowel(word) ? '#f368e0' : '#2e86de' }}
                                    data="item6"
                                    key={word}
                                    className={isVowel(word) ? 'font_pink' : 'font_blue'}
                                  >
                                    {word}
                                  </span>
                                ))}
                          </S.AlphabetBox>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </li>
                  )}
                </Droppable>
              ))}
            </S.AlphabetWrap>
          </Box>
        </S.ColumnFlex>
      </DragDropContext>

      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      {directionEnd && eachSound && (
        <SoundEffectPM
          url={
            !_.isNil(eachSound)
              ? `${
                  !_.isNil(stages.customData.soundBaseUrl) ? stages.customData.soundBaseUrl : 'https://cdn.cloubot.com/PM/audio/sounds/'
                }${eachSound}.mp3`
              : undefined
          }
          onEnd={handleEachSoundEnd}
        />
      )}
    </>
  );
};

export default AlphabetDnD3;
const Box = styled(S.BorderRowBox)`
  flex: 2 1;
  justify-content: center;
  font-size: 38px;
  font-weight: 800;
  align-items: center;
  @media (max-height: 500px) {
    font-size: 25px;
  }
`;
const Arrow = styled.img`
  height: 3rem;
  margin-bottom: 1vh;
  @media (max-height: 500px) {
    height: 2rem;
  }
`;
