import * as axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import * as queries from 'src/operations/queries';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import BookrRecording from 'src/pages/Bookr/Recording/Tool';
import { useLocation } from 'react-router';
import _ from 'lodash';
import { Col, message, Row } from 'antd';
import Loading from 'src/components/common/Loading';
const Manage = (props) => {
  const { type = 'recording', id } = useParams();
  const location = useLocation();
  const articleData = location.state?.articleData;
  const assigned_idx = location.state?.assigned_idx;
  const idx = location.state?.idx;
  const [bookData, setBookData] = useState(null);
  const [book, setBook] = useState(null);
  const token = getCurrentToken();
  const { data: userData } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  props.onSetHideHeader(true);
  const fetchBook = async (id) => {
    try {
      const data = await axios.get(`https://cdn.cloubot.com/BOOKR/books.json`);
      console.log('data', data);
      const book = data.data.result.list.find((item) => parseInt(item.id) === parseInt(id));
      setBook(book);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchBookText = async () => {
    try {
      const data = await axios.get(`https://cdn.cloubot.com/BOOKR/texts/${id}.json`);
      setBookData(data.data);
    } catch (e) {
      console.log(e);
      message.error('Book data not found');
    }
  };
  useEffect(() => {
    fetchBookText();
  }, []);

  useEffect(() => {
    if (articleData) {
      setBook(articleData);
    } else {
      fetchBook(id);
    }
  }, [articleData, id]);

  console.log(assigned_idx, idx);
  return (
    <>
      <Row gutter={[32, 16]}>
        {bookData && book && userData ? (
          <BookrRecording
            dataNew={bookData}
            // have to change
            articleData={book}
            assignedIdx={assigned_idx || null}
            idx={idx || null}
            //
            userData={userData?.me}
            articleId={id}
            code={id}
          />
        ) : (
          <Col span={24}>
            <Loading />
          </Col>
        )}
      </Row>
    </>
  );
};

export default withRouter(Manage);
