import React, { useState, useEffect, useMemo } from 'react';
import { Col, Typography, Select, Row, Button, Input, DatePicker, Modal } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import XLSX from 'xlsx';
import fileDownload from 'js-file-download';
import { Link } from 'react-router-dom';
import CustomTable from 'src/components/common/CustomTable';
import { SMARTECLASS_PLACEMENT_TEST_LIST, GET_LEVEL_TEST_LIST } from 'src/operations/queries/consult';
import {
  CREATE_CONSULT_LEVELTEST_MULTI_FOR_SMARTECLASS,
  DELETE_ORDER_FOR_PLACEMENT_TEST,
  ASSIGN_LEVEL_TEST_MULTI,
  UPDATE_ASSIGN_LEVEL_TEST,
} from 'src/operations/mutations/consult';
import { useQuery, useMutation } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import TestCenterMenuGroup from 'src/components/common/TestCenterMenuGroup';
import CustomButton from 'src/components/common/CustomButton';
import SmartEclassRegisterPlacementTestModal from 'src/components/Modal/SmartEclassRegisterPlacementTestModal';
import { getHumanReadablePhoneNumber } from 'src/utils';
import UseHistoryState from 'src/components/common/UseHistoryState';

const { Title, Text } = Typography;
const { Search } = Input;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const getPlacementTest = (school_info, testOptions) => {
  const findItem = testOptions?.find((ele) => {
    if (['초1', '초2'].includes(school_info)) {
      return ele.label === 'Type P';
    } else if (['초3', '초4'].includes(school_info)) {
      return ele.label === 'Type A';
    } else if (['초5'].includes(school_info)) {
      return ele.label === 'Type B';
    } else if (['초6'].includes(school_info)) {
      return ele.label === 'Type C';
    } else {
      return false;
    }
  });

  if (!findItem) {
    return testOptions[0];
  }
  return findItem;
};

const PlacementTest = () => {
  const [fileInputKey, setFileInputKey] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [dateForMulti, setDateForMulti] = useState();
  const [registgerModalVisible, setRegisterModalVisible] = useState(false);
  //const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const [checkRowList, setcheckRowList] = useState([]);

  const [searchValue, setSearchValue] = UseHistoryState(
    {
      text: '',
      page: 0,
      selectedMonth: moment().format('YYYY-MM'),
    },
    'placement_search233',
  );

  console.log('searchValue', searchValue);

  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
    getCheckboxProps: (record) => {
      return {
        disabled: false,
        key: record.key,
      };
    },
  };

  const { data: levelTestList } = useQuery(GET_LEVEL_TEST_LIST);

  const testOptions = useMemo(() => {
    const options = [{ label: 'select', value: 0 }];
    if (levelTestList) {
      return [
        //...options,
        ...levelTestList.testsList.tests
          .map(({ idx, title }) => {
            const shortTitle = title.replace('Placement Test ', '');
            return { label: shortTitle, value: idx };
          })
          .reverse(),
      ];
    }
    return options;
  }, [levelTestList]);

  const { data, loading, refetch } = useQuery(SMARTECLASS_PLACEMENT_TEST_LIST, {
    variables: { campus_idx: userInfoVar()?.campus_idx, ym: searchValue?.selectedMonth },
    skip: !userInfoVar()?.campus_idx || !searchValue?.selectedMonth,
  });
  const dataSource = useMemo(() => {
    const list = data?.smarteclassPlacementTestList;

    if (list) {
      return list
        .map((item, key) => {
          return {
            key: item.idx,
            idx: item.idx,
            no: list.length - key,
            student_name: item.name,
            phone: item.parent_phone,
            grade: item.school_info,
            regdate: moment(item.idate).format('YYYY-MM-DD'),
            testOptions,
            tests_idx: item?.order_leveltest[0]?.assigned_tests?.tests?.idx || 0,
            isAssigned: item?.order_leveltest[0]?.assigned_tests_idx ? true : false,
            test_result: item?.test_result,
            handleAssignTest,
          };
        })
        .filter((item2) => {
          if (searchValue.text) {
            return item2.student_name.includes(searchValue.text);
          }
          return true;
        });
    }
    return [];
  }, [data, testOptions, searchValue.text]);

  //최초지정후 다시 변경할 때
  const [updateTestType, { loading: loadingTestUpdate }] = useMutation(UPDATE_ASSIGN_LEVEL_TEST, {
    onCompleted: () => {
      refetch();
      Modal.info({ title: '변경했습니다.' });
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  const [assignPlacementTestMulti, { loadingAssignMulti }] = useMutation(ASSIGN_LEVEL_TEST_MULTI, {
    onCompleted: () => {
      Modal.info({
        title: '저장했습니다.',
        onOk: () => {
          refetch();
        },
      });
    },
    onError: (e) => {
      console.log(e);
      alert('오류가 발생했습니다.');
    },
    refetchQueries: [
      {
        query: SMARTECLASS_PLACEMENT_TEST_LIST,
        variables: { campus_idx: userInfoVar()?.campus_idx, ym: searchValue.selectedMonth },
      },
    ],
  });

  const [createBatch, { loading: loadingBatch }] = useMutation(CREATE_CONSULT_LEVELTEST_MULTI_FOR_SMARTECLASS, {
    onCompleted: ({ createConsultLevelTestMultiForSmartEclass: results }) => {
      if (results) {
        console.log('result', results);
        const params = results.map(({ user, order_leveltest, school_info }) => {
          const testItem = getPlacementTest(school_info, testOptions);
          return {
            title: `Placement Test ${testItem.label}`,
            start_time: moment(order_leveltest[0]?.reserve_date).format('YYYY-MM-DD'),
            limit_time: 60,
            user_idx: user.idx,
            test_idx: testItem.value,
            order_leveltest_idx: order_leveltest[0]?.idx,
          };
        });
        //여기서 시험 배정하는 부분 한 번 더 호출해야 한다.
        assignPlacementTestMulti({
          variables: { list: params },
        });

        // Modal.info({
        //   title: '저장했습니다.',
        //   onOk: () => {
        //     refetch();
        //   },
        // });
      } else {
        Modal.info({ title: '오류가 발생했습니다.' });
      }
      setExcelData([]);
      setDateForMulti(undefined);
      setFileInputKey((prev) => prev + 1);
    },
    onError: (e) => {
      console.log(e);
      alert('오류가 발생했습니다.');
      setExcelData([]);
      setFileInputKey((prev) => prev + 1);
    },
  });

  const [deletePlacementTest, { loading: loadingDelete }] = useMutation(DELETE_ORDER_FOR_PLACEMENT_TEST, {
    onCompleted: () => {
      Modal.info({
        title: '삭제했습니다.',
        onOk: () => {
          refetch();
          setcheckRowList([]);
        },
      });
    },
    onError: (e) => {
      console.log(e);
      alert('error');
    },
  });
  function hanldeSaveData(excel_data) {
    setExcelData(excel_data);
  }
  function handleSaveBatch() {
    if (excelData.length === 0) {
      Modal.info({ title: '파일을 선택하세요.' });
      return;
    }

    if (!dateForMulti) {
      Modal.info({ title: '응시일을 선택하세요.' });
      return;
    }

    const list = excelData.map(({ name, phone, grade }) => {
      return {
        student_name: name,
        phone,
        school_grade: `초${grade}`,
      };
    });
    createBatch({ variables: { list, test_date: moment(dateForMulti).format('YYYY-MM-DD') } });
  }

  const handleExcelFileDownload = () => {
    (async () => {
      const blob = await fetch('https://cdn.cloubot.com/SM/common/placement-register.xlsx').then((r) => r.blob());
      fileDownload(blob, 'placement-register.xlsx');
    })();
  };
  function handleDelete() {
    Modal.confirm({
      title: '정말 삭제하시겠습니까?',
      onOk: () => {
        if (checkRowList.length === 0) {
          Modal.info({ title: '삭제할 항목을 선택하세요.' });
          return;
        }
        console.log('checkRowList', checkRowList);

        deletePlacementTest({ variables: { idx_list: checkRowList } });
      },
      onCancel: () => {
        //do nothing
      },
    });
  }
  function handleAssignTest(test_idx, record) {
    const assigned_tests_idx = record?.test_result?.assigned_tests?.idx;
    if (assigned_tests_idx) {
      updateTestType({
        variables: { idx: assigned_tests_idx, test_idx },
        refetchQueries: [
          {
            query: SMARTECLASS_PLACEMENT_TEST_LIST,
            variables: { campus_idx: userInfoVar()?.campus_idx, ym: searchValue.selectedMonth },
          },
        ],
      });
    }
  }

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  return (
    <>
      <TestCenterMenuGroup currentMenu="placement-test" />
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testmanagement-mailblock">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>Placement Test</Title>
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button type="primary" onClick={handleExcelFileDownload}>
                등록엑셀양식 다운로드 받기
              </Button>
            </Col>
          </Row>
          <Row gutter={[24, 8]} style={{ marginBottom: 5, marginTop: 5 }}>
            <Col span={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <ExcelUpload fileInputKey={fileInputKey} saveData={hanldeSaveData} />
              <DatePicker placeholder="응시일선택" size="small" style={{ height: 32 }} onChange={setDateForMulti} value={dateForMulti} />
              <Button type="primary" loading={loadingBatch || loadingAssignMulti} onClick={handleSaveBatch}>
                저장
              </Button>
            </Col>
            <Col span={6} />
            <Col className="contenttest-headitems" span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Search
                placeholder="학생이름검색"
                defaultValue={searchValue.text}
                onSearch={(val) => {
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      page: 0,
                      text: val,
                    };
                  });
                }}
              />
              <DatePicker
                size="small"
                style={{ height: 32 }}
                onChange={(m) => {
                  //setSelectedMonth(m.format('YYYY-MM'));
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      selectedMonth: m.format('YYYY-MM'),
                    };
                  });
                }}
                picker="month"
                value={moment(`${searchValue.selectedMonth}-01`)}
                allowClear={false}
              />
              <CustomButton
                style={{ marginRight: 10, border: '1px solid #52c419' }}
                onClick={() => {
                  setRegisterModalVisible(true);
                }}
              >
                <PlusOutlined style={{ color: '#52c419' }} />
                <Text type={'success'}>등록</Text>
              </CustomButton>
              <CustomButton
                loading={loadingDelete}
                danger
                style={{
                  marginRight: 10,
                }}
                onClick={handleDelete}
              >
                삭제
              </CustomButton>
            </Col>
          </Row>

          <CustomTable
            className="contenttest-table"
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ position: ['bottomCenter'], current: searchValue.page + 1 }}
            size="small"
            color="#edf3fb"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            onChange={handleTableChange}
          />
        </MainBlock>
      </Col>
      <SmartEclassRegisterPlacementTestModal
        visible={registgerModalVisible}
        testOptions={testOptions}
        onCancel={() => {
          setRegisterModalVisible(false);
        }}
        campus_idx={userInfoVar()?.campus_idx}
        selectedMonth={searchValue.selectedMonth}
        onRefetch={() => {
          refetch();
        }}
      />
    </>
  );
};

export default PlacementTest;

const ExcelUpload = ({ fileInputKey, saveData }) => {
  const props = {
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: (info) => {
      const file = info.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const ab = e.target.result;
          const wb = XLSX.read(ab, { type: 'array' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: ['number', 'name', 'phone', 'grade'] });
          if (data && data instanceof Array) {
            const filtered_data = data.slice(2);
            saveData(filtered_data);
          } else {
            alert('오류가 발생했습니다.');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
      }
    },
  };
  return <Input size="small" key={`finle-input-${fileInputKey}`} type="file" {...props} name="files" style={{ height: 32 }} />;
};
const SelectTestType = ({ testOptions, ...rest }) => {
  return <Select size="small" placeholder="select type" defaultValue={0} options={testOptions} {...rest} />;
};

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: '80px',
  },
  {
    title: '학생이름',
    dataIndex: 'student_name',
    key: 'student_name',
    align: 'center',
    width: '120px',
  },
  {
    title: '부모전화',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
    render: (text) => getHumanReadablePhoneNumber(text),
  },
  {
    title: '학년',
    dataIndex: 'grade',
    key: 'grade',
    align: 'center',
  },
  {
    title: '신청일',
    dataIndex: 'regdate',
    key: 'regdate',
    align: 'center',
  },
  {
    title: 'Test Type',
    dataIndex: 'test_type',
    key: 'test_type',
    align: 'center',
    render: (_, record) => {
      let disabled = true;
      const { testOptions, tests_idx, isAssigned, test_result, handleAssignTest } = record;
      if (!isAssigned) {
        //미지정
        disabled = false;
      }
      if (!test_result || test_result.status === 1) {
        //미시험
        disabled = false;
      }

      return (
        <SelectTestType
          disabled={disabled}
          testOptions={testOptions}
          value={tests_idx}
          onChange={(test_idx) => {
            handleAssignTest(test_idx, record);
          }}
        />
      );
    },
  },
  {
    title: '시험결과',
    dataIndex: 'view',
    key: 'view',
    align: 'center',
    render: (_, record) => {
      if (!record.isAssigned) {
        return '미지정';
      }
      if (!record.test_result || record.test_result.status === 1) {
        return '미응시';
      }
      const assigned_tests_idx = record?.test_result?.assigned_tests?.idx;

      return <Link to={`/test/new/${assigned_tests_idx}/result-level-test`}>보기</Link>;
    },
  },
];
