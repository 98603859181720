import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Select, Typography, Tabs, Tooltip, Input, Button } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BookSelectRegister from './BookSelectRegister';
import BookSelectAssignStudents from './BookSelectAssignStudents';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { openNotification } from 'src/components/common/Notification';
import { DELETE_BOOK_CREDIT } from 'src/operations/mutations/bookCredit';
import { GET_BOOK_CREDIT_LIST } from 'src/operations/queries/bookCredit';
const { Option } = Select;
const { Title } = Typography;
const columns = [
  {
    title: 'book code',
    dataIndex: 'book_code',
    key: 'book_code',
    align: 'center',
  },
  {
    title: '갯수',
    dataIndex: 'cnt',
    key: 'cnt',
    align: 'center',
  },
  {
    title: 'type',
    dataIndex: 'credit_type',
    key: 'credit_type',
    align: 'center',
    render: (text) => {
      if (text === '1') {
        return 'auto';
      } else {
        return 'manual';
      }
    },
  },
  {
    title: 'Days',
    dataIndex: 'days',
    key: 'days',
    align: 'center',
  },
];

const columnsStudents = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'AssignedBook',
    dataIndex: 'book_code_list',
    key: 'book_code_list',
    align: 'center',
    render: (text, record) => {
      let fullText = text.map((item, key) => {
        return moment(item.end_datetime).isAfter(moment()) && <>{item?.book?.code || ''} | </>;
      });
      return (
        <Tooltip title={fullText}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{fullText}</div>
        </Tooltip>
      );
    },
  },
];

const CreateBookCreditForm = ({
  campusList,
  contentList,
  bookCreditList,
  students,
  loadingCreate,
  onChangeCampus,
  onRegister,
  refetchAll,
}) => {
  const [campus_idx, setCampus_idx] = useState(0);
  const [student, setStudent] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedBook, setSelectedBook] = useState(undefined);
  const [deleteBook, setDeleteBook] = useState(null);
  const [rowSelect, setRowSelect] = useState(0);
  const [count, setCount] = useState(null);
  const filteredStudents = useMemo(() => {
    if (!search || search === '') {
      return students;
    }
    return students.filter((item) => item.name.includes(search) || item.id.includes(search));
  }, [search, students]);
  const [deleteBookCredit, { loading: loadingDelete }] = useMutation(DELETE_BOOK_CREDIT, {
    onCompleted: () => {
      openNotification('삭제 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });
  const handleDeleteCredit = async () => {
    if (count > deleteBook.cnt) {
      alert('보유중인 수량이하의 값을 입력하세요.');
      return;
    }
    await deleteBookCredit({
      variables: { book_code: deleteBook.book_code, credit_count: count, campus_idx },
      refetchQueries: [
        {
          query: GET_BOOK_CREDIT_LIST,
          variables: { campus_idx },
        },
      ],
    });
  };
  const handleRegister = async (book_code, book_count, book_days) => {
    if (!campus_idx) {
      alert('학원을 선택하세요.');
      return false;
    }
    await onRegister({ campus_idx, book_code, book_count, book_days });
  };
  const filterOption = (input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
  const setRowClassName = (record) => {
    return record.key === rowSelect ? 'clickRowStyle' : '';
  };
  const handleTableRowClick = (record) => {
    setRowSelect(record.key);
    setDeleteBook(record);
  };
  useEffect(() => {
    setCount(null);
  }, [deleteBook]);
  const deleteCntOptions = () => {
    const options = [];
    if (deleteBook) {
      for (let i = 1; i <= deleteBook.cnt; i++) {
        options.push(
          <Option key={i} value={i}>
            {i}
          </Option>,
        );
      }
    }
    return options;
  };
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Select
            showSearch
            placeholder="학원선택"
            style={{ width: '100%' }}
            filterOption={filterOption}
            onChange={(value) => {
              setCampus_idx(value);
              onChangeCampus(value);
            }}
          >
            {campusList &&
              campusList.map((item, key) => (
                <Option key={`campus-option-key${key}`} value={item.idx}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Create Credit" key="1">
          <Row gutter={16}>
            <Col span={12}>
              <Title level={5}>이용권 목록</Title>
              <CustomTable
                dataSource={bookCreditList}
                columns={columns}
                pagination={{ pageSize: 5, showSizeChanger: false }}
                size="small"
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
              />
            </Col>
            <Col span={12}>
              <Title level={5}>이용권 발급</Title>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Select placeholder="교재 선택" onChange={(e) => setSelectedBook(e)}>
                  {contentList &&
                    contentList.map((e) => {
                      return <Option value={e.book.code}>{e.book.code}</Option>;
                    })}
                </Select>
                {contentList &&
                  selectedBook &&
                  contentList
                    .filter((e) => e.book.code === selectedBook)
                    .map((item, key) => (
                      <BookSelectRegister
                        key={`BookSelectRegister${key}`}
                        item={item}
                        num={key}
                        loading={loadingCreate}
                        onRegister={handleRegister}
                      />
                    ))}
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Delete Credit" key="3">
          <Row gutter={16}>
            <Col span={12}>
              <Title level={5}>이용권 목록</Title>
              <CustomTable
                dataSource={bookCreditList.filter((e) => parseInt(e.credit_type) !== 1)}
                columns={columns}
                pagination={{ pageSize: 5, showSizeChanger: false }}
                size="small"
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
                rowClassName={setRowClassName}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      handleTableRowClick(record);
                    }, // click row
                  };
                }}
              />
            </Col>
            <Col span={12}>
              <Title level={5}>이용권 삭제</Title>
              <div style={{ display: 'flex', width: '50%', justifyContent: 'left' }}>
                {/* <Select placeholder="교재 선택" onChange={(e) => setSelectedBook(e)}>
                  {contentList &&
                    contentList.map((e) => {
                      return <Option value={e.book.code}>{e.book.code}</Option>;
                    })}
                </Select> */}
                {contentList && deleteBook && (
                  <>
                    <Select value={count} placeholder="수량 선택" onChange={(e) => setCount(e)}>
                      {deleteCntOptions()}
                    </Select>
                    {count > 0 && (
                      <Button style={{ marginLeft: 20 }} loading={loadingDelete} onClick={handleDeleteCredit} type="primary">
                        삭제
                      </Button>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Assign to Student" key="2">
          <Row gutter={16}>
            <Col span={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <Title level={5} style={{ minWidth: '100px' }}>
                  Student List
                </Title>

                <Input.Search placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
              </div>
              <CustomTable
                rowSelection={{
                  type: 'radio',
                  onChange: (selectedRowKeys, selectedRows) => {
                    setStudent(selectedRows[0]);
                  },
                }}
                dataSource={filteredStudents}
                columns={columnsStudents}
                pagination={{ pageSize: 5 }}
                size="small"
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
              />
            </Col>
            <Col span={12}>
              <Title level={5}>이용권 발급</Title>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Select placeholder="교재 선택" onChange={(e) => setSelectedBook(e)}>
                  {contentList &&
                    contentList.map((e) => {
                      return <Option value={e.book.code}>{e.book.code}</Option>;
                    })}
                </Select>
                {contentList &&
                  selectedBook &&
                  contentList
                    .filter((e) => e.book.code === selectedBook)
                    .map((item, key) => (
                      <BookSelectAssignStudents
                        key={`BookSelectRegister${key}`}
                        item={item}
                        num={key}
                        loading={loadingCreate}
                        student={student}
                        campus_idx={campus_idx}
                        refetchAll={refetchAll}
                      />
                    ))}
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default CreateBookCreditForm;
