import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, ArrowLeftOutlined, SoundFilled } from '@ant-design/icons';
import * as axios from 'axios';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Timer from 'src/components/LevelUp/Timer';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #443ec8;
  color: #fff;
  font-size: 35px;
  box-shadow: 0 2px 1px 2px #090989;
`;

const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  /* min-height: 550px; */
  min-height: 650px;
  /* @media (max-width: 1280px) { */
  /* width: 95%; */
  /* min-width: 800px; */
  /* } */
`;
const StageTitle = styled.div`
  color: #fff;
  padding: 5px 30px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  background-color: #49ada1;
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const TopBody = styled(ColumnFlex)`
  background-color: #daedeb;
  border: 2px solid #a1cec9;
  border-radius: 20px;
  font-size: 22px;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  align-items: center;
  /* width: 100%; */
  height: 40%;
  margin: 0 20px;
  @media (max-width: 1280px) {
    height: 45%;
  }
`;
const TopInnerBox = styled(RowFlex)`
  width: 100%;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  @media (max-width: 1280px) {
    padding: 10px 20px;
  }
`;
const EmptyBoxWrapper = styled(RowFlex)`
  width: 90%;
  height: 100%;
  align-items: center;
  padding: 25px 18px;
  background-color: #fff;
  border-radius: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;
const BottomBody = styled(ColumnFlex)`
  width: 100%;
  padding: 20px;
  height: 45%;
`;

const WordListBox = styled(RowFlex)`
  background-color: #fff0f1;
  border-radius: 25px;
  border: 2px #ffd3d2 solid;
  height: 90%;
  color: #ffffff;
  font-size: 28px;
  align-items: center;
  padding: 25px 40px;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1280px) {
    height: 95%;
  }
`;
const EmptyWordBox = styled(ColumnFlex)`
  border-radius: 25px;
  border: 3px #453ec8 dashed;
  /* background-color: #64a6f7; */
  color: #fff;
  width: 150px;
  font-size: 17px;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin: 5px 0 5px 15px;
  cursor: pointer;
  box-shadow: 0 2px 1px 2px #090888;
  @media (max-width: 1280px) {
    width: 130px;
    height: 40px;
    font-size: 15px;
  }
`;
const WordBox = styled(ColumnFlex)`
  border-radius: 25px;
  /* border: 3px #74c5f3 dashed; */
  background-color: #453ec8;
  color: #fff;
  width: 150px;
  font-size: 17px;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  cursor: pointer;
  box-shadow: 0 2px 1px 2px #090888;
  @media (max-width: 1280px) {
    width: 130px;
    height: 40px;
    font-size: 15px;
  }
`;
const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  background-image: url(${(props) => props.bg});
  background-size: 72px 50px;
  background-repeat: no-repeat;
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;

const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  max-width: 800px;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const ArrowIcon = styled.div`
  width: 52px;
  min-width: 52px;
  height: 45px;
  min-height: 45px;
  margin: 0 35px;
  background-image: url(${(props) => props.src});
  background-size: 52px 45px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
export default function EngStaSentenceDnD({}) {
  const dummy = ['This is a book.', 'I like cats.'];
  const sliced = useMemo(() => {
    const tmp = [];
    if (dummy.length > 0) {
      dummy.map((e) => {
        const replacer = e.replace(/[.,?!]/g, '');
        const slicer = replacer.split(' ').map((word, i) => {
          return { word: word, number: i + 1 };
        });

        tmp.push(_.shuffle(slicer));
      });
    }
    return tmp;
  }, [dummy]);
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <Title>
              <TitleSpan color={'#453ec8'}>BOOK Title</TitleSpan>
              <TitleSpan>Volume</TitleSpan>
              <TitleSpan>Unit</TitleSpan>
              <TitleSpan>Lesson</TitleSpan>
              <TitleSpan>Lesson Name</TitleSpan>
            </Title>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '90%', width: '100%', padding: '0 20px 20px 30px' }}>
              <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '20px 50px' }}>
                <StageTitle>Listen and Repeat.</StageTitle>
                <RowFlex style={{ fontSize: '18px', justifyContent: 'right' }}>
                  <NumberText>
                    Time{' '}
                    <QuizNumber>
                      <span style={{ color: '#ff005c' }}>
                        <Timer />
                      </span>
                    </QuizNumber>
                  </NumberText>{' '}
                  &nbsp;&nbsp;&nbsp;
                  <NumberText>
                    Number{' '}
                    <QuizNumber>
                      <span style={{ color: '#433fc8' }}>{`03`}</span>/{`10`}
                    </QuizNumber>
                  </NumberText>
                </RowFlex>
              </RowFlex>
              <TopBody>
                Drag and Drop.
                <TopInnerBox>
                  <ImgButton>
                    <SoundFilled />
                  </ImgButton>
                  <EmptyBoxWrapper>
                    {sliced[0].map((e) => (
                      <EmptyWordBox key={e.number}></EmptyWordBox>
                    ))}
                  </EmptyBoxWrapper>
                </TopInnerBox>
              </TopBody>
              <BottomBody>
                <WordListBox>
                  <>
                    {sliced[0].map((e) => (
                      <WordBox key={e.number}>{e.word}</WordBox>
                    ))}
                  </>
                </WordListBox>
              </BottomBody>
            </ColumnFlex>
          </Main>
        </MainWrapper>
        <StageButtonWrapper>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_left.png'}></ArrowIcon>
          <RowFlex style={{ justifyContent: 'space-between', width: '45%', height: '100%' }}>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_flashcard.png`} active={false} isDone={true} />
              Flashcard
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_check.png`} active={false} isDone={true} />
              Check
            </StageButtonText>
            <StageButtonText active={false}>
              <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} active={false} isDone={true} />
              Typing
            </StageButtonText>
            <StageButtonText active={true}>
              <StageButton bg={`/images/LevelUp/study/sub/g_quiz.png`} active={true} isDone={false} />
              Quiz
            </StageButtonText>
          </RowFlex>
          <ArrowIcon src={'/images/LevelUp/study/sub/arrow_right.png'}></ArrowIcon>
        </StageButtonWrapper>
      </Wrapper>
    </>
  );
}
