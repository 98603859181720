import React, { useMemo } from 'react';
import { Col, Row, Tag } from 'antd';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import Tickets from 'src/pages/Tickets';
import CustomTable from 'src/components/common/CustomTable';
// import * as dummy from 'src/dummy';

import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_CAMPUS_STUDENT_ASSIGNED_TICKET_LIST } from 'src/operations/queries/bookCredit';

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

const getTicketUseState = (tiketItem) => {
  return ['부여완료'];
};

function TicketStatistics() {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: dataTicket, loading: loadingTicket } = useQuery(GET_CAMPUS_STUDENT_ASSIGNED_TICKET_LIST, {
    skip: !dataUser,
    variables: {
      campus_idx: dataUser && dataUser.getUserData.campus_idx,
    },
  });
  const tikectList = useMemo(() => {
    if (dataTicket) {
      return dataTicket.getCampusStudentAssignedTicketList.map((ticket, key) => ({
        key: `row-stat-ticket-${ticket.idx}`,
        no: key + 1,
        bookName: ticket.book_code,
        //chapter: '목록',
        ticketType: ticket.book_credit.study_type === 'EXP' ? '체험' : '학습',
        ticketNumber: ticket.book_code,
        name: ticket.user.name,
        assignDay: moment(ticket.book_credit.idate).format('YYYY-MM-DD'),
        studyStartDay: moment(ticket.book_credit.issue_datetime).format('YYYY-MM-DD'),
        useTicketDay: ticket.book_start_datetime ? moment(ticket.book_start_datetime).format('YYYY-MM-DD') : '미정',
        useState: getTicketUseState(ticket),
      }));
    }
    return [];
  }, [dataTicket]);
  return (
    <Tickets>
      <Row>
        <Col span={24}>
          <MyInfoWrapper className="ticketStatus-tabfour">
            <CustomTable
              className="tickettable"
              dataSource={tikectList}
              columns={columns}
              pagination={{ pageSize: 6 }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loadingTicket}
            />
          </MyInfoWrapper>
        </Col>
      </Row>
    </Tickets>
  );
}

const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    align: 'center',
    key: 'no',
  },
  {
    title: '교재',
    dataIndex: 'bookName',
    align: 'center',
    key: 'bookName',
  },
  // {
  //   title: '목록',
  //   dataIndex: 'chapter',
  //   align: 'center',
  //   key: 'chapter',
  // },
  {
    title: '이용권 종류',
    dataIndex: 'ticketType',
    align: 'center',
    key: 'ticketType',
  },
  {
    title: '이용권 번호',
    dataIndex: 'ticketNumber',
    align: 'center',
    key: 'ticketNumber',
  },
  {
    title: '학생이름',
    dataIndex: 'name',
    align: 'center',
    key: 'name',
  },
  // {
  //   title: '발급일',
  //   dataIndex: 'assignDay',
  //   align: 'center',
  //   key: 'assignDay',
  // },
  {
    title: '이용권 시작',
    dataIndex: 'studyStartDay',
    align: 'center',
    key: 'studyStartDay',
  },
  // {
  //   title: '사용일',
  //   dataIndex: 'useTicketDay',
  //   align: 'center',
  //   key: 'useTicketDay',
  // },
  {
    title: '사용여부',
    key: 'useState',
    align: 'center',
    dataIndex: 'useState',
    render: (tags) => (
      <span>
        {tags.map((tag) => {
          let color = 'green';

          if (tag === '부여완료') {
            color = 'volcano';
          }
          if (tag === '사용완료') {
            color = 'gray';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  },
];

export default TicketStatistics;
