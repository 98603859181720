import React, { useEffect, useMemo } from 'react';
import { Menu } from 'antd';
import { userInfoVar } from 'src/apollo/cache';
import { useMutation } from '@apollo/client';
import { SWITCH_COMPANY } from 'src/operations/mutations/company';
import { useLoginTo } from 'src/utils/hooks';

const SwitchAccountButton = () => {
  const [changeCompany, { data }] = useMutation(SWITCH_COMPANY);
  const loginTo = useLoginTo(true);

  const switchCompany = useMemo(() => userInfoVar().campus.company.switchCompany, []);

  useEffect(() => {
    if (data) {
      window.localStorage.setItem('culp_token', JSON.stringify(data.switchCompany));

      window.location.href = '/';
    }
  }, [data]);

  const handleChangeCLick = (company_idx) => {
    window.localStorage.setItem('culp_switch_return_id', userInfoVar().id);
    changeCompany({ variables: { company_idx, campus_idx: userInfoVar().campus.idx } });
  };

  const culpSwitchAccountId = useMemo(() => {
    return window.localStorage.getItem('culp_switch_return_id') || null;
  }, []);

  const handleReturnClick = () => {
    window.localStorage.removeItem('culp_switch_return_id');
    loginTo(culpSwitchAccountId);
  };

  return (
    <>
      {(switchCompany?.length > 0 || culpSwitchAccountId) && <Menu.Divider />}
      {switchCompany?.length > 0 && (
        <Menu.SubMenu
          key="switch"
          title={
            <>
              <img src="/images/menu-icon/c-cloud.png" alt="" style={{ width: '25px', height: '25px' }} />
              &nbsp;Switch Account
            </>
          }
        >
          {switchCompany.map((company) => (
            <Menu.Item key={company.idx} onClick={() => handleChangeCLick(company.idx)}>
              {company.name}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      )}
      {culpSwitchAccountId && (
        <Menu.Item key="return" onClick={handleReturnClick}>
          <>
            <img src="/images/menu-icon/c-cloud.png" alt="" style={{ width: '25px', height: '25px' }} />
            &nbsp;Return to Previous Account
          </>
        </Menu.Item>
      )}
    </>
  );
};

export default SwitchAccountButton;
