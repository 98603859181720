import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, List, Form, Checkbox, DatePicker, Select, Modal, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { GET_TYPE_CLASS, CLASS_STUDENT_LIST } from 'src/operations/queries/getClass';
import styled from 'styled-components';
import moment from 'moment';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { ASSIGN_AI_TOPIC_TO_STUDENTS } from 'src/operations/mutations/aiTopic';
import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek } from 'src/utils';
import * as queries from 'src/operations/queries';
import _ from 'lodash';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

let assignedStartDate = null;
let assignedEndDate = null;
let onRangeChangePageload = false;
const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];
const levelList = [
  { value: 'Grade 1', label: 'Basic A' },
  { value: 'Grade 4', label: 'Basic B' },
  {
    value: 'Grade 7',
    label: 'Intermediate',
  },
  {
    value: 'Grade 11',
    label: 'Advanced',
  },
  { value: 'College Level', label: 'College' },
];
const AITopicBlock = styled.div`
  background: #fff;
`;

const AITopicSectionBlock = styled.div`
  background: #fff;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

const WritingCoachEdit = () => {
  const history = useHistory();
  const [aiTopics, setAITopics] = useState([]);
  const [formControl] = Form.useForm();
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [threeForm] = Form.useForm();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [selectYoil, setSelectYoil] = useState([]);
  const [selectStudents, setSelectStudents] = useState([]);
  const [aiTopicTime, setAITopicTime] = useState('00:00');
  const [events, setEvents] = useState([]);
  const [listData, setListData] = useState([]);
  const [rangeDate, setRangeDate] = useState({ start: null, end: null });
  const [checkedListAll, setcheckedListAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionidx, setOptionidx] = useState(null);
  const [reportVisible, setReportVisible] = useState([false, false]);
  // const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const [page, setPage] = useState(1);
  const [classNameSelected, setClassNameSelected] = useState('Class');
  const { class_idx, id } = useParams();
  const { data: aiTopicGroupDetails, loading: loadingGroupInfo } = useQuery(queries.aiTopic.AI_TOPIC_GROUP_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: { idx: parseInt(id) },
  });
  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(CLASS_STUDENT_LIST);

  const [assignAITopicsToStudents] = useMutation(ASSIGN_AI_TOPIC_TO_STUDENTS, {
    onCompleted: () => {
      openNotification('등록 완료!');
      history.goBack();
    },
  });

  //
  const handleOpenModal = (idx, editable) => {
    setReportVisible([true, editable]);
    setOptionidx(idx);
  };
  const handleCloseModal = () => {
    setReportVisible([false, false]);
    setOptionidx(null);
  };
  //

  useEffect(() => {
    history.listen((location) => {
      window.location.reload(true);
    });
  }, [history]);

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }
  }, [classQuery, fetchStudents]);

  useEffect(() => {
    getSelectClassStudents({
      variables: { class_idx: parseInt(class_idx) },
    });
  }, [class_idx]);

  useEffect(() => {
    if (aiTopicGroupDetails?.aiTopicSingleGroup) {
      let testtimeinput1 = '';
      let testtimeinput2 = '';
      const {
        aiTopicSingleGroup: { ai_topic_date_assignments },
      } = aiTopicGroupDetails;
      const assignedAITopicUserList = [];
      const beforeList = _.sortBy(ai_topic_date_assignments, 'idx');
      const tmpcheck = beforeList[beforeList.length - 1]?.ai_topic_user_assignments?.map((e) => e.user.idx);
      setCheckedList([...tmpcheck]);
      let dateList = [];
      const tmpoptions = [];
      ai_topic_date_assignments?.forEach((dateAssignment, dateIndex) => {
        testtimeinput1 = dateAssignment.start_date.split('T')[1].split(':')[0];
        testtimeinput2 = dateAssignment.start_date.split('T')[1].split(':')[1];
        const editable = dateAssignment.ai_topic_user_assignments.every((e) => e.status === 0);
        const data = dateAssignment.data ? JSON.parse(dateAssignment.data) : {};
        const { level, sentences, feedback, revise, copyPaste, version, topic, idx, prompt } = data;
        let obj = {
          No: dateIndex + 1,
          idx: dateAssignment.ai_topic_user_assignments[0]?.ai_topic.idx,
          topic: dateAssignment.ai_topic_user_assignments[0]?.ai_topic.topic
            ? `${dateAssignment.ai_topic_user_assignments[0]?.ai_topic.topic}`
            : `Topic ${dateIndex + 1}`,
          days: dateAssignment.start_date,
          key: dateIndex,
          data,
          editable,
        };
        dateList.push(obj);
        tmpoptions.push({ level, sentences, feedback, revise, copyPaste, version, title: topic, idx, prompt });
        dateAssignment.ai_topic_user_assignments.forEach((user_assignments) => {
          if (!assignedAITopicUserList.includes(user_assignments.user.idx)) {
            assignedAITopicUserList.push(user_assignments.user.idx);
          }
        });
      });
      setOptions(tmpoptions);
      const assignedAITopicClassname = aiTopicGroupDetails?.aiTopicSingleGroup?.class;
      let start_date = aiTopicGroupDetails.aiTopicSingleGroup.start_date.split('T')[0];
      let end_date = aiTopicGroupDetails.aiTopicSingleGroup.end_date.split('T')[0];
      setListData(dateList);
      setAITopics(dateList);
      let selectYoil = dateList.map((loopdata) => loopdata.days.split('T')[0]);
      setSelectYoil(selectYoil);
      onCheckCalendareList(selectYoil);
      setRangeDate({
        start: start_date,
        end: end_date,
      });

      assignedStartDate = start_date;
      assignedEndDate = end_date;
      setAITopicTime(`${testtimeinput1}:${testtimeinput2}`);
      setClassNameSelected(assignedAITopicClassname.name);
      setSelectedClass(assignedAITopicClassname.idx);
    }
  }, [aiTopicGroupDetails]);
  console.log(options, 'options');
  function onChange(e, item, idx) {
    // checkedList[idx] = e.target.checked;
    if (e.target.checked) {
      setCheckedList([...checkedList, item.user.idx]);
    } else {
      setCheckedList(checkedList.filter((e) => e !== item.user.idx));
    }
    // setCheckedList(JSON.parse(JSON.stringify(checkedList)));
    onCheckStudents();
  }

  function onCheckAllChange(e) {
    const tmp = students.map((e) => e.user.idx);
    if (checkedList.length === students.length) {
      setCheckedList([]);
      setcheckedListAll(false);
      onCheckStudents();
    } else {
      setCheckedList([...tmp]);
      setcheckedListAll(true);
      onCheckStudents();
    }
  }

  function onCheckCalendareList(selectYoil) {
    setEvents(
      selectYoil.map((item, i) => {
        return {
          date: item,
          color: '#378006',
          title: `${i + 1}회차`,
          key: i,
        };
      }),
    );
  }

  function onCheckGenerateList(yoil) {
    onCheckStudents();
    let aiTopicDate = [];
    const newListData = aiTopics
      ?.filter((e) => e?.days)
      ?.map((item, index) => {
        let loopobject = {
          No: `${index + 1}회차`,
          idx: item.idx,
          topic: `${item.topic}`,
          days: yoil[index],
          key: index,
          data: item.data ? item.data : {},
          editable: item.editable,
        };
        aiTopicDate.push(`${moment(`${yoil[index]}`).format('YYYY-MM-DD')}`);
        return loopobject;
      });
    setRangeDate({
      start: assignedStartDate,
      end: assignedEndDate,
    });
    setSelectYoil(aiTopicDate);
    setAITopics(newListData);
    setListData(newListData);
  }

  function handleChange(value) {
    if (checkedList.length > 0) {
      let tempcheck = checkedList;
      tempcheck.map((loopdata, key) => {
        checkedList[key] = false;
      });
    }
    setCheckedList(new Array(checkedList.length).fill(false));
    let tempchecklist = onCheckStudents();
    if (tempchecklist.length > 0) {
      setCheckedList([]);
      setcheckedListAll(false);
      onCheckStudents();
    } else {
      setCheckedList([]);
      setcheckedListAll(false);
      onCheckStudents();
    }
    if (onRangeChangePageload == false) {
      if (fetchStudents?.classStudentList && classQuery?.data?.classList) {
        setClasses(classQuery?.data?.classList);
        setStudents(fetchStudents?.classStudentList);
      }
      setSelectedClass(value);
    } else {
      setSelectedClass(value);
    }
  }

  const onCheckStudents = (data) => {
    const astAdd = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    setSelectStudents(students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user));
    return students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user);
  };

  function onRangeChange(dates, dateStrings) {
    assignedStartDate = dateStrings[0];
    assignedEndDate = dateStrings[1];
    if (selectedClass) {
      let dayOfWeek = '';
      if (selectedClass.day_of_week) {
        dayOfWeek = customDayOfWeek(selectedClass);
      }
      if (secondForm.getFieldValue('day_of_week')) {
        dayOfWeek = secondForm.getFieldValue('day_of_week').join(', ');
      } else if (!dayOfWeek) {
        dayOfWeek = '일, 월, 화, 수, 목, 금, 토';
      }
      const selectYoil = getDateRangeData(dateStrings[0], dateStrings[1], dayOfWeek);
      setRangeDate({
        start: dateStrings[0],
        end: dateStrings[1],
      });
      setSelectYoil(selectYoil);
      onCheckCalendareList(selectYoil);
      onCheckGenerateList(selectYoil);
    }
  }

  function onTimeChange(time, TimeStrings) {
    setAITopicTime(TimeStrings);
  }

  function getDateRangeData(param1, param2, dayOfWeek) {
    //param1은 시작일, param2는 종료일이다.
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const res_day = [];
    const ss_day = new Date(param1);
    const ee_day = new Date(param2);

    let count = 0;
    while (ss_day.getTime() <= ee_day.getTime()) {
      let _mon_ = ss_day.getMonth() + 1;
      _mon_ = _mon_ < 10 ? '0' + _mon_ : _mon_;

      let _day_ = ss_day.getDate();
      _day_ = _day_ < 10 ? '0' + _day_ : _day_;

      let yoil = ss_day.getDay();

      if (dayOfWeek.includes(week[yoil]) && aiTopics[count]) {
        res_day.push(ss_day.getFullYear() + '-' + _mon_ + '-' + _day_);
        count++;
      }

      ss_day.setDate(ss_day.getDate() + 1);
    }
    return res_day;
  }

  const handleFormSubmit = () => {
    if (selectYoil.length < aiTopics.length) {
      var answer = window.confirm(`${selectYoil.length} topic으로 이동하시겠습니까?`);
      if (answer) {
        saveAssignedTopics();
      }
    } else {
      saveAssignedTopics();
    }
  };
  const saveAssignedTopics = () => {
    let studentlength = selectStudents.map((students) => students.idx);
    let selectedstudentlist = [];
    if (studentlength.length == 0) {
      let currentselectionstudent = onCheckStudents();
      selectedstudentlist = currentselectionstudent.map((stddata) => stddata.idx);
    } else {
      selectedstudentlist = selectStudents.map((students) => students.idx);
    }
    if (selectedstudentlist.length > 0) {
      let selectedAITopics = Array.from(listData);
      formControl
        .validateFields()
        .then(async (formData) => {
          await assignAITopicsToStudents({
            variables: {
              class_idx: selectedClass,
              start_time: selectYoil, // 클래스별 설정된 요일 별 날자 체크(요일 없을시 하루간격)
              limit_time: 1440,
              user_idx_list: checkedList,
              type: 'book-report',
              external_data: selectedAITopics.splice(0, selectYoil.length).map((item, index) => {
                const current = options.filter((e) => e.idx === item.idx)[0];
                return {
                  ...item.data,
                  version: current.version,
                  sentences: current.sentences,
                  feedback: current.feedback,
                  revise: current.revise ,
                  revisedPrompt: current.revisedPrompt,
                  level: current.level,
                  copyPaste: current.copyPaste,
                  // date: selectYoil[index],
                  // time: aiTopicTime,
                  idx: item.idx,
                };
              }),
              start_date: rangeDate?.start, // 달력 시작일
              end_date: rangeDate?.end, // 달력 마지막일
              study_time: aiTopicTime,
              rowidx: parseInt(id),
            },
          });
          firstForm.resetFields();
          formControl.resetFields();
          secondForm.resetFields();
          threeForm.resetFields();
          setClasses([]);
          setStudents([]);
          setSelectedClass(-1);
          setSelectYoil([]);
          setSelectStudents([]);
          setAITopics([]);
          setEvents([]);
          setListData([]);
          setRangeDate({ start: null, end: null });
          // history.push('/ai-topic/assign-list');
        })
        .catch((errorInfo) => {
          console.error('errorInfo', errorInfo);
        });
    } else {
      alert('Kindly select student list.');
    }
  };

  function showUpdateConfirm(changeInfo) {
    handleEventChange(changeInfo);
  }

  function handleEventChange(changeInfo, type = '') {
    const {
      start,
      extendedProps: { key },
    } = changeInfo.event.toPlainObject();

    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item.date = start;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item = start;
        }
        return item;
      });
    });
  }
  function handleDateChange(date, changeDate, searchIndex) {
    const name = `topicDate-${searchIndex}`;
    formControl.setFieldsValue({
      [name]: changeDate && moment(changeDate, 'YYYY-MM-DD'),
    });
    // console.log('date', date);
    // const searchIndex = index - 1;
    setEvents((prev) => {
      return prev.map((item, index) => {
        // console.log(index,searchIndex,'check on event')
        if (index === searchIndex) {
          item.date = changeDate;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        // console.log(index,searchIndex,'check on yoil')
        if (index === searchIndex) {
          item = changeDate;
        }
        return item;
      });
    });
  }

  useEffect(() => {
    const list = [];
    events.map((item) => {
      list.push(item.date);
    });
    onCheckGenerateList(list);
  }, [events]);

  const columns = [
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: 80,
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: '주제',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      render: (_, record) => {
        return <>{record?.data?.topic}</>;
      },
    },
    {
      title: '날짜',
      dataIndex: 'days',
      align: 'center',
      key: 'days',
      width: 200,
      render: (text, record) => {
        const names = `topicDate-${record.key}`;
        formControl.setFieldsValue({
          [names]: text && moment(text, 'YYYY-MM-DD'),
        });
        return (
          <>
            <Form.Item style={{ margin: 'auto' }} name={`topicDate-${record.key}`}>
              <DatePicker
                name={`topicDate-${record.key}`}
                initialValues={text && moment(text, 'YYYY-MM-DD')}
                //onChange={handleDateChange}
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString, record.key);
                }}
                clearIcon={null}
                disabled={moment(text) <= moment()}
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Report Option',
      dataIndex: 'idx',
      align: 'center',
      key: 'idx',
      width: 120,
      render: (text, record) => {
        return (
          <>
            <Button
              // disabled={!record.editable}
              onClick={() => {
                handleOpenModal(text, record.editable);
              }}
            >
              {record.editable ? 'Edit' : 'View'}
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Col className="test-syllabus-wrapper" span={24} style={{ marginTop: '10px' }}>
        <AITopicBlock>
          <Row style={{ padding: 10 }}>
            <Title level={5}>
              <span style={{ color: '#289428' }}>Auto Assign</span>
            </Title>
          </Row>
          <Row className="test-syllabus-box-listing" gutter={[24, 16]}>
            <Col className="test-syllabus-box-a" span={5} style={{ paddingLeft: 20 }}>
              <AITopicSectionBlock className="test-syllabus-box-a-testsectionblock">
                <Title level={5}>&nbsp;</Title>
                <Form
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="vertical"
                  form={firstForm}
                >
                  <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
                    <Select
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '100%', minWidth: 100 }}
                      placeholder={classNameSelected}
                      onChange={handleChange}
                      disabled
                    >
                      {classes.map((classInfo) => (
                        <Option selected={true} key={classInfo.idx} value={classInfo.idx}>
                          {classInfo.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <List
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>Name</label>
                        <div>
                          전체 선택 &nbsp;{' '}
                          <Checkbox checked={checkedList.length === students.length} onChange={onCheckAllChange}></Checkbox>
                        </div>
                      </div>
                    }
                    footer={<div></div>}
                    bordered
                    style={{ height: 'calc(100vh - 274px)', overflowY: 'auto' }}
                    dataSource={students}
                    renderItem={(item, index) => (
                      <List.Item style={{ display: 'flex' }}>
                        <span>{item.user.name}</span>{' '}
                        <Checkbox
                          key={index}
                          checked={checkedList && checkedList.includes(item?.user?.idx)}
                          onChange={(e) => onChange(e, item, index)}
                        ></Checkbox>
                      </List.Item>
                    )}
                  />
                </Form>
              </AITopicSectionBlock>
            </Col>
            <Col className="test-syllabus-box-b" span={10} style={{ textAlign: 'center', paddingRight: 20 }}>
              <AITopicSectionBlock>
                <Form form={secondForm}>
                  <Title level={5}>AI Topic Date / Time</Title>
                  <FormItem name="day_of_week">
                    <Checkbox.Group options={yoilOptions} name="day_of_week" />
                  </FormItem>
                  <RangePicker
                    style={{ marginBottom: 10 }}
                    onChange={onRangeChange}
                    name="rangeDate"
                    value={rangeDate.start && [moment(rangeDate.start), moment(rangeDate.end)]}
                    disabled={moment(rangeDate.start) < moment() ? [true, false] : [false, false]}
                    clearIcon={null}
                  />
                </Form>
                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    contentHeight={250}
                    expandRows={false}
                    initialView="dayGridMonth"
                    selectable={true}
                    events={events}
                    editable={true}
                    eventContent={(eventInfo, index) => {
                      return (
                        <>
                          <div
                            style={{
                              backgroundColor: eventInfo.backgroundColor,
                              color: 'white',
                              borderRadius: 10,
                              width: 20,
                              textAlign: 'center',
                              marginRight: 5,
                            }}
                          >
                            {eventInfo.event.title}
                          </div>
                          {/* <div>{classType[eventInfo.textColor]}</div> */}
                        </>
                      );
                    }}
                    eventChange={showUpdateConfirm}
                  />
                </CalendarBlock>

                <Form
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  layout="horizontal"
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                  form={threeForm}
                >
                  <Form.Item label="Time">
                    <DatePicker
                      picker="time"
                      size="large"
                      format="HH:mm"
                      inputReadOnly={true}
                      showTime={{ value: moment(`${aiTopicTime}`, 'HH:mm') }}
                      onChange={onTimeChange}
                    />
                  </Form.Item>

                  {/* <Form.Item>
                    <Button type="primary" onClick={() => onCheckGenerateList(selectYoil)}>
                      Done
                    </Button>
                  </Form.Item> */}
                </Form>
              </AITopicSectionBlock>
            </Col>

            <Col className="test-syllabus-box-c" span={9} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Title level={5}>Confirmation</Title>
              <Form form={formControl} component={false}>
                <CustomTable
                  dataSource={listData}
                  columns={columns}
                  bordered
                  scroll={{ y: 'calc(100vh - 244px)' }}
                  pagination={{ pageSize: 10 }}
                  size="small"
                  color="#edf3fb"
                />

                <div>
                  <Button
                    type="secondary"
                    shape="round"
                    size="large"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    돌아가기
                  </Button>
                  <Button type="primary" shape="round" size="large" htmlType="submit" onClick={handleFormSubmit}>
                    저장하기
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </AITopicBlock>
      </Col>
      <BookReportOptionModal
        title={<div style={{ fontSize: '20px', color: '#000000' }}>Writing Coach Option</div>}
        visible={reportVisible[0]}
        onCancel={handleCloseModal}
        footer={null}
        // width={900}
      >
        <BookReportOptions
          optionidx={optionidx}
          options={options}
          editable={reportVisible[1]}
          setOptions={setOptions}
          close={handleCloseModal}
        />
      </BookReportOptionModal>
    </>
  );
};

export default WritingCoachEdit;
const BookReportOptions = ({ close, options, setOptions, optionidx, editable }) => {
  const [prompt, setPrompt] = useState(null);
  const [level, setLevel] = useState('Grade 7');
  const [version, setVersion] = useState('long');
  const [sentences, setSentences] = useState(60);
  const [feedback, setFeedback] = useState(true);
  const [revise, setRevise] = useState(false);
  const [copyPaste, setCopyPaste] = useState(true);
  const currentData = options.filter((e) => e.idx === optionidx)[0];
  useEffect(() => {
    if (currentData) {
      setLevel(currentData.level);
      setVersion(currentData.version);
      setSentences(currentData.sentences);
      setFeedback(currentData.feedback);
      setRevise(currentData.revise);
      setCopyPaste(currentData.copyPaste);
    }
  }, [currentData]);
  const handleSave = () => {
    const tmp = _.cloneDeep(options);

    if (optionidx) {
      const currentidx = tmp.findIndex((e) => e.idx === optionidx);
      tmp[currentidx] = {
        ...tmp[currentidx],
        feedback: feedback,
        sentences: sentences,
        level: level,
        revise: revise,
        revisedPrompt: prompt,
        version: version,
        copyPaste: copyPaste,
        edited: true,
      };
      setOptions(tmp);
    }
    close();
  };

  return (
    <ModalWrapper>
      <RowFlex style={{ height: '100%', justifyContent: 'space-between', alignItems: 'stretch', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flex: 1,
            paddingRight: '20px',
            // height:'100%',
            minHeight: '200px',
            width: '70%',
            // borderRight: '1px solid #5a789a',
            marginRight: '20px',
          }}
        >
          {optionidx && (
            <>
              <RowFlex style={{ padding: '0 0 12px', width: '100%' }}>
                <ModalLabel style={{ width: '100px', minWidth: '100px' }}>Assignment :</ModalLabel>
                <Form.Item style={{ marginBottom: 0, width: '100%' }}>
                  <Input placeholder="Topic" value={currentData?.title} disabled />
                </Form.Item>
              </RowFlex>
              <ColumnFlex>
                <ModalLabel style={{ paddingBottom: '9px' }}>Topic / Prompt :</ModalLabel>
                <Form.Item style={{ marginBottom: 0 }} name="prompt">
                  <Input.TextArea
                    placeholder={currentData?.prompt || 'Prompt'}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    disabled={!editable}
                  />
                </Form.Item>
              </ColumnFlex>
            </>
          )}
          {/* <ModalLabel style={{ color: '#2E75B6', padding: '10px 0 5px 0' }}>Student</ModalLabel> */}
          <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
            <RowFlex>
              <ModalLabel style={{ width: '130px' }}>Level :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={level}
                  onChange={(e) => {
                    setLevel(e);
                  }}
                  bordered={false}
                  disabled={!editable}
                >
                  {levelList.map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Min. Words :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={sentences}
                  onChange={(e) => {
                    setSentences(e);
                  }}
                  bordered={false}
                  disabled={!editable}
                >
                  {[
                    { value: 60, label: 'Min 60 words' },
                    { value: 100, label: 'Min 100 words' },
                    {
                      value: 150,
                      label: 'Min 150 words',
                    },
                    {
                      value: 250,
                      label: 'Min 250 words',
                    },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>
          <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
            <RowFlex>
              <ModalLabel style={{ width: '130px' }}>Student Report :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={version}
                  onChange={(e) => {
                    setVersion(e);
                  }}
                  bordered={false}
                  disabled={!editable}
                >
                  {[
                    { value: 'long', label: 'Long Ver.' },
                    { value: 'short', label: 'Short Ver.' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Feedback Button :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e);
                  }}
                  bordered={false}
                  disabled={!editable}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>

          <RowFlex style={{ width: '100%', justifyContent: 'space-between' }}>
            <RowFlex>
              <ModalLabel style={{ width: '130px' }}>Copy & Paste :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={copyPaste}
                  onChange={(e) => {
                    setCopyPaste(e);
                  }}
                  bordered={false}
                  disabled={!editable}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>

            <RowFlex style={{ minWidth: '250px', justifyContent: 'space-between' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Revise Button :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={revise}
                  onChange={(e) => {
                    setRevise(e);
                  }}
                  bordered={false}
                  disabled={!editable}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>
          {/* <ColumnFlex>
            <RowFlex>
              &nbsp; &nbsp; &nbsp;
              <Form.Item style={{ marginBottom: 0, width: '50%' }}>
                <Select
                  style={{ alignItems: 'center', width: '200px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  placeholder={` Due Date `}
                  onChange={(e) => {
                    setDueDate(e);
                  }}
                  bordered={false}
                >
                  {[1, 2, 3, 4, 5, 6].map((e) => (
                    <Option key={e} value={e}>
                      {e}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </ColumnFlex> */}
        </div>
      </RowFlex>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Button style={{ marginRight: 10 }} onClick={close}>
          Cancel
        </Button>
        <Button type="primary" onClick={editable && handleSave}>
          {' '}
          {editable ? 'Save' : 'OK'}{' '}
        </Button>
      </div>
    </ModalWrapper>
  );
};

const ColumnFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const ModalWrapper = styled(ColumnFlex)`
  min-height: 350px;
  justify-content: space-between;
`;
const BookReportOptionModal = styled(Modal)`
  .ant-modal-header {
    background-color: #ffda00;
    border-radius: 20px 20px 0 0;
  }
  .ant-modal-content {
    border: 3px solid #fff2cc;
    border-radius: 20px;
    background-color: #fff;
  }
  width: 860px;
  min-width: 860px;
  @media (max-width: 900px) {
    min-width: 750px;
    width: 750px;
  }
`;
const ModalLabel = styled.div`
  color: #000000;
  font-weight: bold;
  padding-right: 10px;
`;
