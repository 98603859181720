import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

import MyPage from 'src/pages/MyPage';

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #fff;
  min-height: calc(100vh - 144px);
`;

function PointList() {
  return (
    <MyPage>
      <Row>
        <Col span={24}>
          <MyInfoWrapper>포인트 내역</MyInfoWrapper>
        </Col>
      </Row>
    </MyPage>
  );
}

export default PointList;
