import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { getClass } from 'src/operations/queries';
const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
const { Option } = Select;
const defaultClassVaule = JSON.stringify([]);

const ClassGroupSelectBox = ({ campus_idx, class_teacher_idx, style, classValue, onClassChange }) => {
  const { data, loading } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    skip: !campus_idx,
    variables: { campus_idx },
    fetchPolicy: 'no-cache',
  });
  const selectClassOptions = useMemo(() => {
    const defautlAllOption = <Option key={`class-options-0`} value={defaultClassVaule}>{`선택`}</Option>;
    const classList = data?.classList;
    if (classList) {
      const filteredClassList = classList.filter((ele) => {
        if (class_teacher_idx) {
          if (ele?.class_teacher?.map((item) => item.user_idx)?.includes(class_teacher_idx)) {
            return true;
          }
          return false;
        }
        return true;
      });
      const classGroup = filteredClassList.reduce((acc, cur) => {
        const findIndex = acc.findIndex((ele) => ele.name === cur.name);
        if (findIndex !== -1) {
          acc[findIndex].list.push(cur.idx);
        } else {
          acc.push({ name: cur.name, list: [cur.idx] });
        }
        return [...acc];
      }, []);
      return [
        defautlAllOption,
        ...classGroup.map((item, key) => (
          <Option key={`class-options-${key + 1}`} value={JSON.stringify(item.list)}>
            {item.name}
          </Option>
        )),
      ];
    }
    return [defautlAllOption];
  }, [data, class_teacher_idx]);

  useEffect(() => {
    if (onClassChange) {
      onClassChange(JSON.stringify([]));
    }
  }, [class_teacher_idx]);

  return (
    <Select size={`default`} loading={loading} placeholder="반 선택" onChange={onClassChange} style={{ ...style }} value={classValue}>
      {selectClassOptions}
    </Select>
  );
};

export default ClassGroupSelectBox;

export const useCampusClass = () => {
  const [selectedClassValue, setSelectedClassValue] = useState(defaultClassVaule);
  const onClassChange = (val) => {
    setSelectedClassValue(val);
  };
  return { selectedClassValue, onClassChange };
};
