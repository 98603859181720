import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Typography, Modal, Button, Space, Switch } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_META_STATICS_COMPANY_CAMPUS } from 'src/operations/queries/company';
import { META_STATICS_DETAILS_LIST,META_STATICS_DETAILS_LIST_FOR_RETESTER } from 'src/operations/queries/consult';
// import { META_EXAM_SAVE_REPORT } from 'src/operations/mutations/createTests';
import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcelUserSheet } from 'src/utils/index';
// import CustomButton from 'src/components/common/CustomButton';
// import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
// import { getYearMonthArray, getHumanReadablePhoneNumber, getMetaExamId, getLixileScoreObject, ExportExcel } from 'src/utils';
// import { storage } from 'src/utils/hooks';
// import axios from 'axios';
const COMMISION_CALCULATE = {
  creo: {
    prePrice: 10000,
    preCreoCommision: 4500,
    preSemtleCommision: 0,
    abcPrice: 13000,
    abcCreoCommision: 6000,
    abcSemtleCommision: 0,
  },
  semtle: {
    prePrice: 12000,
    preCreoCommision: 2470,
    preSemtleCommision: 4650,
    abcPrice: 15000,
    abcCreoCommision: 3300,
    abcSemtleCommision: 6200,
  },
};
const MetaExamStaticsDetails = () => {
  const [selectedDate, setSelectedDate] = useState();
  const { Title } = Typography;
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { pathname, state } = useLocation();
  const { campus_name, yeardate: date, isCreo } = state;
  const [filterType, setFilterType] = useState('reserve_date');
  let pathArr = pathname.split('/');
  const {
    data: dataList,
    loading,
    refetch1,
  } = useQuery(META_STATICS_DETAILS_LIST, {
    fetchPolicy: 'no-cache',
    skip: !dataUser,
    variables: {
      campus_idx: parseInt(pathArr[4]),
      fetchtype: pathArr[5],
      gettype: pathArr[3],
      filter_type: filterType,
    },
  });
  const {
    data: dataListForRetesters,
    loading2,
    refetch2,
  } = useQuery(META_STATICS_DETAILS_LIST_FOR_RETESTER, {
    fetchPolicy: 'no-cache',
    skip: !dataUser || pathArr[3] !== 'month' || filterType !== 'completed_date' || dataUser?.getUserData?.idx !== 1000078,
    variables: {
      campus_idx: parseInt(pathArr[4]),
      filter_type: 'completed_date',
      from_date: moment(pathArr[5]).add(-6, 'months').startOf('month').format('YYYY-MM-DD'),
      to_date: moment(pathArr[5]).endOf('month').format('YYYY-MM-DD'),
    },
  });
  const { _, refetch } = useQuery(GET_META_STATICS_COMPANY_CAMPUS, {
    skip: !dataUser,
    variables: {
      company_idx: dataUser?.getUserData.campus.fc_company_idx,
      selected_date: selectedDate ? selectedDate : '',
    },
  });
  const consultList = useMemo(() => {
    if (dataList) {
      return dataList?.getMetaStaticsDetails
        .map((item, key) => {
          const extraData = item?.test_result?.extra_data ? JSON.parse(item?.test_result?.extra_data) : [];
          const type = item?.order_leveltest[0]?.assigned_tests?.tests?.batch_type;
          return {
            key: `consult-list-${item.idx}`,
            no: key + 1,
            order_idx: item?.idx,
            stat: item?.stat,
            english_name: item?.name,
            name: item?.user.english_name,
            phone: item?.parent_phone,
            isAssigned: item?.order_leveltest[0]?.assigned_tests_idx ? true : false,
            assigned_tests_idx: item?.order_leveltest[0]?.assigned_tests_idx,
            order_leveltest_idx: item?.order_leveltest[0]?.idx,
            tests_idx: item?.order_leveltest[0]?.assigned_tests?.tests?.idx,
            tests_list_idx: item?.order_leveltest.map((item, key) => {
              return item?.assigned_tests?.tests?.idx;
            }),
            test_date: moment(item?.order_leveltest[0]?.reserve_date).format('yy-MM-DD'),
            test_result: item?.test_result,
            end_time: moment(item?.test_result?.end_time).format('yy-MM-DD'),
            reserved_date: moment(item.idate).format('yy-MM-DD'),
            user_idx: item?.user?.idx,
            order_survey_idx: item?.order_survey[0]?.idx,
            id: `${moment(item?.user.birthday.split('T')[0]).format('YYMMDD')}-${item?.parent_phone.slice(3)}`,
            school_info: item?.school_info,
            mx_level: extraData?.mx || '',
            type: type,
          };
        })
        .sort((a, b) => {
          if (a.test_result === b.test_result) {
            return 0;
          }
          return a.test_result > b.test_result ? 1 : -1;
        });
    }
    return [];
  }, [dataList]);
  useEffect(() => {
    let d = new Date().toISOString();
    setSelectedDate(d.split('T')[0]);
  }, []);
  useEffect(() => {
    refetch();
  }, [selectedDate]);

  const MetaStaticsDetailTable = styled(CustomTable)`
    border-top: 3px solid #a4d0e9;
    thead[class*='ant-table-thead'] th {
      border-left: 1px solid #a4d0e9;
      border-top: 1px solid #a4d0e9;
    }
  `;

  const columns = [
    {
      title: 'No',
      align: 'center',
      dataIndex: 'no',
      width: 40,
    },
    {
      title: 'ID',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '예약일',
      align: 'center',
      dataIndex: 'test_date',
      key: 'id',
    },
    {
      title: '응시일',
      align: 'center',
      dataIndex: 'end_time',
      key: 'id',
    },

    {
      title: '이름',
      align: 'center',
      dataIndex: 'name',
      key: 'phone',
    },
    {
      title: '영문이름',
      align: 'center',
      dataIndex: 'english_name',
      key: 'phone',
    },
    {
      title: '학년',
      align: 'center',
      dataIndex: 'school_info',
      key: 'phone',
    },
    {
      title: 'Type',
      align: 'center',
      dataIndex: 'type',
      key: 'phone',
    },
    {
      title: 'mx Level',
      key: 'phone',
      align: 'center',
      dataIndex: 'mx_level',
      width: 100,
      // render: (text, record) => {
      //   if (!record.test_result || !record.isAllComplete) {
      //     return '';
      //   }
      //   if (text) {
      //     return <MxEditInput value={text} record={record} refetch={refetch} companyIdx={companyIdx} />;
      //   }
      //   if (record.lexile_score_val) {
      //     const mxobj = getLixileScoreObject(record.lexile_score_val, record.batch_type);
      //     return <MxEditInput value={mxobj?.mx} record={record} refetch={refetch} companyIdx={companyIdx} />;
      //   }
      //   return '';
      // },
    },
  ];
  const columnsForReTester = [
    {
      title: '학원',
      align: 'center',
      dataIndex: 'campus',
      key: 'campus',
    },
    {
      title: 'ID',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '이름',
      align: 'center',
      dataIndex: 'name',
      key: 'phone',
    },
    {
      title: '재응시일',
      align: 'center',
      dataIndex: 'end_time',
      key: 'id',
    },
    {
      title: 'Type',
      align: 'center',
      dataIndex: 'type',
      key: 'phone',
    },
  ];
  function handleExportToExcel(e) {
    e.preventDefault();
    const fileName = `${date} ${campus_name} Meta Exam statics`;
    const tmpData = consultList.filter((e) => e.stat === '1');
    // const tmpColumns = [...columns,{title:,}]
    ExportExcelUserSheet(columns, tmpData, fileName);
  }

  //

  const reTesterList = useMemo(() => {
    const arr = [];
    if (dataListForRetesters) {
      const groupedByIdAndName = dataListForRetesters.getMetaStaticsDetails.reduce((acc, obj) => {
        const tmpobj = {
          campus:campus_name,
          id: `${moment(obj?.user.birthday.split('T')[0]).format('YYMMDD')}-${obj?.parent_phone.slice(3)}`,
          name: obj?.user.english_name,
          type: obj?.order_leveltest[0]?.assigned_tests?.tests?.batch_type,
          end_time:moment(obj?.test_result?.end_time).format('yy-MM-DD'),
        };
        const key = `${tmpobj.id}_${tmpobj.name}_${tmpobj.type}`;
        acc[key] = acc[key] || [];
        acc[key].push(tmpobj);
        return acc;
      }, {});

      for (const id in groupedByIdAndName) {
        const group = groupedByIdAndName[id];

        if (group.length > 1) {
          const groupArr = group.map((obj) => ({ id: obj.id, name: obj.name, end_time: obj.end_time, type: obj.type, check: false }));
          for (let i = 0; i < groupArr.length - 1; i++) {
            groupArr[i].check = moment(groupArr[i].end_time).isSameOrBefore(moment(groupArr[i + 1].end_time).add(6, 'months'));
          }
          const tmp = groupArr.filter((e) => e.check && e.end_time.includes(pathArr[5]));
          tmp.length > 0 && arr.push(tmp);
        }
      }
    }
    return arr;
  }, [pathArr, dataListForRetesters, dataUser]);
  console.log(reTesterList,'reTesterList');
  function handleExportToRetestersExcel(e) {
    e.preventDefault();
    const fileName = `${campus_name} ${pathArr[5]} 재응시자목록`;
    const tmpData = reTesterList.flat();
    // const tmpColumns = [...columns,{title:,}]
    ExportExcelUserSheet(columnsForReTester, tmpData, fileName);
  }
  //
  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>
          {date} {campus_name} MetaExam Statics Details
        </HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          {dataUser?.getUserData?.idx === 1000078 && (
            <>
              <a href="/" onClick={handleExportToExcel} className="excel-img">
                <img style={{ height: '20px', width: '20px' }} src={imageexcel} alt="download" />
              </a>
              {filterType === 'completed_date' && pathArr[3] === 'month' &&(
                <a href="/" onClick={handleExportToRetestersExcel} className="excel-img">
                  &nbsp; &nbsp;
                  <Button>재응시자목록 다운로드</Button>
                </a>
              )}
              &nbsp; &nbsp;
            </>
          )}
          <Space style={{ marginRight: 10 }}>
            <Switch
              checkedChildren="Completed Date"
              unCheckedChildren="Reserved Date"
              onChange={(checked) => setFilterType(checked ? 'completed_date' : 'reserve_date')}
            />
          </Space>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="learning-halfwrapper learning-hs">
            <div
              className="learning-halfwrapper-top-header"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              <Title level={5} style={{ width: '100%', textAlign: 'left' }}></Title>
            </div>
            <MetaStaticsDetailTable
              dataSource={consultList}
              columns={columns}
              pagination={true}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </HalfWrapper>
        </Col>
      </Row>{' '}
    </>
  );
};
export default MetaExamStaticsDetails;
