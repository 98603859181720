import React, { useMemo, useEffect } from 'react';
import { Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router';

import CustomTable from 'src/components/common/CustomTable';
import TeacherCalendar from './TeacherCalendar';
import TodaySchedule from 'src/pages/DashBoard/TodaySchedule';

import { useQueryProxy } from 'src/operations/proxy/user';
import { userInfoVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
const { Title } = Typography;

const TodoBlock = styled.div``;

const NoticeBlock = styled.div``;

const Fake_data = [
  {
    date: '14:00',
    name: 123123123123,
    title: ' Zoom Class!',
    percent: 'Click to start',
    status: 5,
    isMeeting: true,
  },
];

const Manager = () => {
  const history = useHistory();
  const { data: fetchNoticeBoard, loading: noticeLoading } = useQueryProxy(queries.board.GET_BOARD_LIST, {
    variables: {
      bo_table: 'managerNotice',
      company_idx: userInfoVar()?.campus?.company?.idx,
    },
  });

  const companyname = userInfoVar()?.company_name;
  

  const noticeData = useMemo(() => {
    if (fetchNoticeBoard) {
      let boaradNumber = fetchNoticeBoard?.getBoardList?.length || 0;
      return fetchNoticeBoard.getBoardList.reduce((acc, cur, index) => {
        const obj = {
          key: cur.idx,
          no: boaradNumber--,
          title: cur.wr_subject,
          name: cur.user.name,
          date: moment(cur.wr_datetime.replace('.000Z', '')).format('YYYY-MM-DD'),
          pageName: cur.board.bo_table,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchNoticeBoard]);

  return (
    <>
      <Col span={11} className="calendar-box">
        <TeacherCalendar />
      </Col>

      <Col span={13} className="calendar-box-two">
        <TodoBlock className="calendar-box-two-a">
          <TodaySchedule Fake_data={Fake_data} />
        </TodoBlock>
        <NoticeBlock className="dashboard-listing calendar-box-two-b">
          <Title level={5}>Notice</Title>
          <div className="button-edit">
            <button
              onClick={() => {
                history.push({
                  pathname: `/board/notice`,
                  state: { isWrite: true },
                });
              }}
            >
              글쓰기
            </button>
          </div>
          <CustomTable
            pagination={{ pageSize: 5 }}
            dataSource={noticeData}
            loading={noticeLoading}
            scroll={{ y: 'calc(100vh - 254px)' }}
            columns={noticeColumns}
            size="small"
            color="#edf3fb"
          />
        </NoticeBlock>
      </Col>
    </>
  );
};

const noticeColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'left',
    width: '7%',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (text, record) => {
      return <Link to={`/board/${record.pageName}/${record.key}`}>{text}</Link>;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '20%',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: '20%',
  },
];

export default Manager;
