import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Row, Button, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_USER_ASSIGNMENT_LIST } from 'src/operations/queries/aiTopic';
import { GET_TYPE_CLASS } from 'src//operations/queries/getClass';
import { useQuery, ApolloConsumer } from '@apollo/client';
import moment from 'moment';
import OpenAiModal from 'src/components/Modal/OpenAiModal';
import ViewAnswerModal from 'src/components/Modal/ViewAnswerModal';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 10px;
`;

const StudentResults = (props) => {
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');

  const [classes, setClasses] = useState([]);
  const [assigned_Users, setAssignedUsers] = useState([]);
  const [class_idx, setClassIdx] = useState(null);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [openAiModal, setOpenAiModal] = useState(false);
  const [topic, setTopic] = useState();
  const [userTopicId, setUserTopicId] = useState();
  const [userTopicStatus, setUserTopicStatus] = useState();
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');

  const { data, refetch } = useQuery(AI_TOPIC_USER_ASSIGNMENT_LIST, {
    variables: { class_idx, page, take, type: 'ai-topic' },
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (data?.aiTopicUserAssignmentList) {
      setAssignedUsers(
        data?.aiTopicUserAssignmentList.ai_topic_user_assignments.map((item, index) => {
          return { ...item, no: index + 1 };
        }),
      );
      setTotal(data?.aiTopicUserAssignmentList.total);
    }

    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
    if (isRefresh) {
      refetch();
      props.client.clearStore();
      props.client.cache.gc();
      props.history.replace({ search: '' });
    }
  }, [data, classQuery, isRefresh, props, refetch]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    {
      title: '주제',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic.topic}</Space>;
      },
    },
    {
      title: '날짜',
      dataIndex: 'date',
      align: 'center',
      key: 'date',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic_date_assignment.start_date.substr(0, 10)}</Space>;
      },
    },
    {
      title: '상태',
      dataIndex: 'status',
      width: 100,
      key: 'status',
      align: 'center',
      render: (text, { status }) => {
        return <Space size="middle">{status === 0 ? '시작하지 않음' : status === 1 ? '시작함' : status === 2 ? '답변함' : '적합함'}</Space>;
      },
    },
    {
      title: '동작',
      key: '',
      width: 100,
      render: (text, record) => {
        const startT = moment(new Date(`${record.ai_topic_date_assignment.start_date?.replace('Z', '')}+09:00`));
        if (moment().isAfter(startT)) {
          if (record.status === 2) {
            return (
              <Space size="middle">
                <Button
                  onClick={() => {
                    setTopic(record.ai_topic.topic);
                    setUserTopicId(record.idx);
                    setTopicAnswer(record.answer);
                    setViewAnswerModal(true);
                  }}
                >
                  보기
                </Button>
              </Space>
            );
          } else if (record.status === 1) {
            return (
              <Space size="middle">
                <Button
                  onClick={() => {
                    setTopic(record.ai_topic.topic);
                    setUserTopicId(record.idx);
                    setUserTopicStatus(record.status);
                    setOpenAiModal(true);
                  }}
                >
                  다시 열기
                </Button>
              </Space>
            );
          } else {
            return (
              <Space size="middle">
                <Button
                  onClick={() => {
                    setTopic(record.ai_topic.topic);
                    setUserTopicId(record.idx);
                    setUserTopicStatus(record.status);
                    setOpenAiModal(true);
                  }}
                >
                  열기
                </Button>
              </Space>
            );
          }
        } else {
          return <Space>아직 열지 않음</Space>;
        }
      },
    },
  ];

  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]}>
            <Col className="contenttest-title-result" span={6} style={{ textAlign: 'left' }}>
              <Title level={4}>AI Topic 배정 결과</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Select
                style={{ width: 150, color: '#065aa9', marginRight: 10 }}
                placeholder="Select a Class"
                onChange={(idx) => setClassIdx(idx)}
              >
                {classes.map((item) => (
                  <Select.Option key={item.idx} value={item.idx} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            dataSource={assigned_Users}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            scroll={{ y: 'calc(100vh - 254px)' }}
            pagination={{ pageSize: take, total }}
            size="small"
            color="#edf3fb"
            onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>
        {openAiModal && (
          <OpenAiModal
            visible={openAiModal}
            topic={topic}
            userTopicId={userTopicId}
            userTopicStatus={userTopicStatus}
            handleCancel={() => {
              setOpenAiModal(false);
              refetch();
            }}
          />
        )}
        {viewAnswerModal && (
          <ViewAnswerModal
            visible={viewAnswerModal}
            topic={topic}
            userTopicId={userTopicId}
            topicAnswer={topicAnswer}
            handleCancel={() => {
              setViewAnswerModal(false);
              refetch();
            }}
          />
        )}
      </Col>
    </>
  );
};

export default withRouter(StudentResults);
