import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Select } from 'antd';
import styled from 'styled-components';
import { HalfWrapper } from 'src/components/common/Styles';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { STUDENT_BOOK_LIST } from 'src/operations/queries/study';

const { Title } = Typography;
const { Option } = Select;
const StudyTitle = styled(Title)`
  border-bottom: 1px solid #aaa;
`;
const StudyTable = styled.table`
  border: 1px solid #eee;
  border-collapse: collapse;
  width: 100%;
  padding: 0;
  border: 0;
  border-spacing: 0;
  th {
    background-color: #f0f3f9;
    border: 1px solid #d3ddeb;
    height: 32px;
  }
  td {
    border: 1px solid #d3ddeb;
    height: 32px;
    text-align: center;
  }
`;

const CustomSelect = ({ children, ...rest }) => {
  return (
    <Select size="middle" style={{ width: '200px' }} {...rest}>
      {children}
    </Select>
  );
};

const OnlineStudyResult = () => {
  const { user_idx } = useParams();
  const [selectedBookCode, setSelectedBookCode] = useState();
  const { data: dataBook, loading: loadingBook } = useQuery(STUDENT_BOOK_LIST, {
    skip: !user_idx,
    variables: { user_idx: parseInt(user_idx) },
  });

  const books = useMemo(() => {
    if (dataBook?.studentBookList) {
      return dataBook?.studentBookList.map((item) => {
        return { name: item.book_code, code: item.book_code };
      });
    }
    return [];
  }, [dataBook]);
  useEffect(() => {
    if (books && books.length > 0) {
      setSelectedBookCode(books[0].code);
    }
  }, [books]);

  // const { data: dataResult, loading: loadingResult } = useQuery(STUDENT_BOOK_STUDY_RESULT, {
  //   skip: !user_idx || !selectedBookCode,
  //   variables: { user_idx: parseInt(user_idx), book_code: selectedBookCode },
  // });

  const handleChange = (value) => {
    setSelectedBookCode(value);
  };

  // console.log('dataResult', dataResult);

  return (
    <>
      <div style={{ width: '100%', padding: 5 }}>
        <CustomSelect onChange={handleChange} value={selectedBookCode} loading={loadingBook}>
          {books.map((item, key) => (
            <Option key={`book-opion-${key}`} value={item.code}>
              {item.name}
            </Option>
          ))}
        </CustomSelect>
      </div>
      <HalfWrapper style={{ minHeight: 'calc(100vh - 25vh)', width: '98%', marin: '0 1%' }}>
        <StudyTitle level={3}>온라인 학습현황</StudyTitle>
        <StudyTable style={{ marginBottom: 10 }}>
          <thead>
            <tr>
              <th>이름</th>
              <th>교재정보</th>
              <th>학습영역</th>
              <th>학습레슨수</th>
              <th>수행률</th>
              <th>평균점수</th>
              <th>학업성취도</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4">스마트학생</td>
              <td rowSpan="4">SMART E CLASS LEVEL 1-4</td>
              <td>Total</td>
              <td>5/16</td>
              <td>31%</td>
              <td>99</td>
              <td>그래프</td>
            </tr>
            <tr>
              <td>Voca</td>
              <td>5/16</td>
              <td>31%</td>
              <td>99</td>
              <td>그래프</td>
            </tr>
            <tr>
              <td>Reading</td>
              <td>5/16</td>
              <td>31%</td>
              <td>99</td>
              <td>그래프</td>
            </tr>
            <tr>
              <td>Listening</td>
              <td>5/16</td>
              <td>31%</td>
              <td>99</td>
              <td>그래프</td>
            </tr>
          </tbody>
        </StudyTable>
        <StudyTitle level={3}>온라인 학습 상세 점수</StudyTitle>
        <StudyTable>
          <thead>
            <tr>
              <th>교재정보</th>
              <th>Unit</th>
              <th>Lesson 1</th>
              <th>Lesson 2</th>
              <th>Lesson 3</th>
              <th>Lesson 4</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4">SMART E CLASS LEVEL 1-4</td>
              <td>Unit 1</td>
              <td>100</td>
              <td>100</td>
              <td>100</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Unit 2</td>
              <td>100</td>
              <td>100</td>
              <td>100</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Unit 3</td>
              <td>100</td>
              <td>100</td>
              <td>100</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Unit 4</td>
              <td>100</td>
              <td>100</td>
              <td>100</td>
              <td>100</td>
            </tr>
          </tbody>
        </StudyTable>
      </HalfWrapper>
    </>
  );
};

export default OnlineStudyResult;
