import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox, Input, Button, DatePicker, Space } from 'antd';
import * as _ from 'lodash';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { Link, useHistory } from 'react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { DELETE_ASSIGN_EXTERNAL_STUDY } from 'src/operations/mutations/assignExternalStudy';
import { ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE } from 'src/operations/queries/externalStudy';
import * as queries from 'src/operations/queries';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
// import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 225px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 700px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 650px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 20px; */
`;
const DeleteBtn = styled(Button)`
  background-color: #f67b28;
  color: #fff;
  border-radius: 5px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 16px;
`;
const ResultBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;
const EditBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;
const SendBtn = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
  width: 90px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
// const dummy = [
//   {
//     assigned_date: '2023-06-30',
//     class_name: 'test class1',
//     classIdx: 13108,
//     idx: 1111,
//     due_date: '2023-07-01',
//     title: 'The Bear',
//     completion: 1,
//     total: 2,
//   },
//   {
//     assigned_date: '2023-07-01',
//     class_name: 'test class2',
//     classIdx: 13187,
//     idx: 2222,
//     due_date: '2023-07-03',
//     title: 'The Bear',
//     completion: 0,
//     total: 10,
//   },
// ];
export default function LevelUpAssignedResult() {
  const history = useHistory();
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [ch, setCh] = useState([]);

  const [localState, setLocalState] = UseHistoryState({
    date: '',
    // searchClassName: '',
    // pageNumber: 0,
  });

  const { data: dataAssignedExternalStudyGroupDetail, loading: grouploadingdetail } = useQuery(ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE, {
    fetchPolicy: 'no-cache',
    variables: {
      start_date: localState.date,
      campus_idx: dataUser?.getUserData?.campus_idx,
      // state: 'all',
      page: pg.current,
      search_text: localState.searchClassName,
      type: 'levelup',
    },
  });
  useEffect(() => {
    if (
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate &&
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study.length > 0
    ) {
      setCh(
        dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study.map((e, i) => {
          const obj = {
            no: i + 1,
            idx: e.idx,
            value: false,
          };
          return obj;
        }),
      );
    }
  }, [dataAssignedExternalStudyGroupDetail]);
  const filteredData = useMemo(() => {
    let tmp = [];
    if (
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate &&
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study.length > 0
    ) {
      tmp = dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study;
    } else return tmp;
    if (selectedClassIdx && selectedClassIdx !== 0) {
      // if (dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study.length > 0) {
      tmp = tmp.filter((e) => parseInt(e.assigned_external_study_group.class.idx) === parseInt(selectedClassIdx));
      // }
    }
    if (localState.searchClassName) {
      tmp = tmp.filter((e) => e.class_name.includes(localState.searchClassName));
    }
    // if (localState.date) {
    //   tmp = tmp.filter((e) => String(e.assigned_date) === String(localState.date));
    // }
    // console.log(tmp,'tmp after 4th check')
    tmp.map(
      (e, i) => (
        (e.no = i + 1),
        (e.class_name = e.assigned_external_study_group.class.name),
        ((e.bookData = JSON.parse(e.external_study_data)),
        (e.completion = e.external_user_study.reduce((acc, cur) => {
          return acc + cur.status;
        }, 0))),
        (e.assigned_date = e.start_date.slice(0, 10))
      ),
    );
    return tmp;
  }, [localState, selectedClassIdx, dataAssignedExternalStudyGroupDetail]);
  console.log(filteredData);
  const handleDateChange = (date, dateString) => {
    if (dateString == undefined) {
      localState.date = new Date();
    }
    setLocalState((prev) => {
      return {
        ...prev,
        date: dateString,
        // formatSearchDate: moment(dateString).format('YY/MM/DD'),
        // pageNumber: 0,
      };
    });

    // setLocalState((prev) => {
    //   return {
    //     ...prev,
    //     date: dateString,
    //     // pageNumber: 0,
    //   };
    // });
  };
  useEffect(() => {
    setPg({
      ...pg,
      current: 0,
    });
  }, [filteredData]);
  const currentPage = (i) => {
    return Math.floor(i / pg.pageSize) === pg.current;
  };
  function onChangeCheck(e, record) {
    ch[ch.findIndex((element) => element.no === record.no)] = { no: record.no, idx: record.idx, value: e.target.checked };
    setCh(JSON.parse(JSON.stringify(ch)));
  }

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'A.Date',
      dataIndex: 'assigned_date',
      key: 'assigned_date',
      align: 'center',
      sorter: (a, b) => a.assigned_date.localeCompare(b.assigned_date),
      render: (text) => {
        return <span>{text}</span>;
      },
    },

    {
      title: 'Class Name',
      dataIndex: 'class_name',
      key: 'class_name',
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      render: (_, record) => {
        return <span>{record.bookData.title}</span>;
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      align: 'center',
      render: (_, record) => {
        const due = moment(record.assigned_date).add(record.bookData.dueDate, 'days').format('YYYY-MM-DD');
        return record.bookData.dueDate ? <span>{due}</span> : <span>-</span>;
      },
    },
    {
      title: 'Completion',
      dataIndex: 'completion',
      key: 'completion',
      align: 'center',
      width: '110px',
      render: (text, record) => {
        const total = record.external_user_study.length;
        return (
          <span>
            {String(text).padStart(String(total).length, '0')}/{total}
          </span>
        );
      },
    },
    {
      title: 'Result',
      dataIndex: 'completion',
      key: 'completion',
      align: 'center',
      width: '120px',
      render: (text, record) => {
        return parseInt(text) === 0 ? (
          <Link
            to={{
              pathname: `/level-up/assignedlist/results/${record.idx}`,
              state: { classIdx: record?.assigned_external_study_group?.class?.idx, class_name: record.class_name },
            }}
          >
            <ResultBtn
            // onClick={() => {
            //   history.push(`/level-up/assignedlist/results/?classIdx=${record.classIdx}&className=${record.class_name}`);
            // }}
            >
              Result
            </ResultBtn>
          </Link>
        ) : (
          <>-</>
        );
      },
    },
    {
      title: () => {
        const tmp = ch
          .filter((e, i) => {
            return currentPage(i);
          })
          .map((e) => {
            return e.value;
          });
        return <Checkbox checked={tmp.filter((s) => s).length === Math.min(ch.length, pg.pageSize)} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox
              id={record.idx}
              checked={ch[ch.findIndex((element) => element.no === record.no)]?.value}
              onChange={(e) => onChangeCheck(e, record)}
            ></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
  ];
  function onCheckAllChange(e) {
    if (e.target.checked) {
      const arr2 = _.cloneDeep(ch);
      const checked = filteredData
        .filter((e, i) => {
          return currentPage(i);
        })
        .map((e) => {
          const obj = {
            no: e.no,
            idx: e.assigned_idx,
            value: true,
          };
          return obj;
        });
      const unChecked = arr2.filter((e, i) => {
        return !currentPage(i);
      });
      setCh([...checked, ...unChecked].sort((a, b) => a.no - b.no));
    } else {
      setCh(
        filteredData.map((e, i) => {
          const obj = {
            no: e.no,
            idx: e.assigned_idx,
            value: false,
          };
          return obj;
        }),
      );
    }
  }
  useEffect(() => {
    setCh(
      filteredData.map((e, i) => {
        const obj = {
          no: e.no,
          idx: e.assigned_idx,
          value: false,
        };
        return obj;
      }),
    );
  }, [filteredData]);
  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <LevelUpMenuGroup currentMenu={'result'} />
        </RowFlex>
        <MainWrapper>
          <Main>
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Assigned List</SubTitle>
                <CloseButton
                  onClick={() => {
                    history.push('/level-up');
                  }}
                >
                  <CloseOutlined />
                </CloseButton>
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                <ClassSelectBox
                  onClassChange={onClassChange}
                  selectedClassIdx={selectedClassIdx}
                  campus_idx={dataUser?.getUserData?.campus_idx}
                  style={{ width: '200px', textAlign: 'left' }}
                  firstRowDisplayValue={'Class'}
                />
                &nbsp;&nbsp;&nbsp;
                <Input
                  placeholder="Search"
                  value={localState.searchClassName}
                  onChange={(e) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        searchClassName: e.target.value,
                        pageNumber: 0,
                      };
                    });
                  }}
                  style={{ width: '190px' }}
                />
                &nbsp;&nbsp;&nbsp;
                <DatePicker
                  placeholder="Assigned Date"
                  onChange={(d, ds) => {
                    handleDateChange(d, ds);
                  }}
                />
                &nbsp;&nbsp;&nbsp;
                {/* <DeleteBtn>Delete</DeleteBtn>&nbsp;&nbsp; */}
                <Icon url={imageexcel} width={'20px'} height={'20px'} onClick={() => {}}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              pagination={{ pageSize: 10, position: ['bottomCenter'] }}
              dataSource={filteredData}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
            <RowFlex style={{ justifyContent: 'end', width: '100%', padding: '10px' }}>
              <SendBtn>Send</SendBtn>
            </RowFlex>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
