import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import moment from 'moment';
import CustomTable from 'src/components/common/CustomTable';
import { koreanNumberFormat } from 'src/utils';

const CampusInvoiceModal = ({ visible, info, onCancel }) => {
  const dataSource = useMemo(() => {
    if (info?.order_list) {
      return info?.order_list.map((item, key) => {
        console.log(item);
        return {
          key: `invoice-detail-${key}`,
          no: `${key + 1}`,
          name: item?.product_name || item.product_code,
          count: item?.quantity,
          amount: item?.sum_price || 0,
          duration: '30',
        };
      });
    }
    return [];
  }, [info]);
  return (
    <Modal
      visible={visible}
      title={`${info?.order_payment?.product_name} 구매내역`}
      onCancel={onCancel}
      width={'60%'}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() => {
              if (info?.receipt_url) {
                window.open(info?.receipt_url, 'receipt', 'width=200, height=200, top=50, left=50');
              } else {
                alert('영수증 오류');
              }
            }}
          >
            영수증 보기
          </Button>
          <Button onClick={() => onCancel()}>확인</Button>
        </div>
      }
    >
      <div>{moment(info?.idate).format('YYYY-MM-DD')}</div>
      <CustomTable
        pagination={{ pageSize: 12, position: ['bottomCenter'] }}
        dataSource={dataSource}
        columns={columns}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
      />
      {/* <div style={{ textAlign: 'right' }}>
        <label>배송비 : </label>
        <input type="text" readOnly value={koreanNumberFormat(info?.delivery_price)} style={{ textAlign: 'right' }} /> 원 &nbsp; &nbsp;
        <label>총 구매 금액 : </label>
        <input type="text" readOnly value={koreanNumberFormat(info?.amount)} style={{ textAlign: 'right' }} /> 원
      </div> */}
    </Modal>
  );
};

export default CampusInvoiceModal;

const columns = [
  {
    title: '번호',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
    align: 'center',
  },
  {
    title: '교재',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '권 수',
    dataIndex: 'count',
    key: 'count',
    align: 'center',
  },
  {
    title: '금액',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    render: (price) => koreanNumberFormat(price),
  },
];
