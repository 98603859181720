import React, { useState, useEffect, useMemo } from 'react';
import { Col, Typography, Space, Row, Button, Select, Checkbox, Radio } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { ASSIGNED_TEST_LIST,DAILY_ASSIGNED_TEST_LIST } from 'src/operations/queries/getTests';
import { DAILY_ASSIGN_TEST_DELETE } from 'src/operations/mutations/createTests';

import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { userInfoVar } from 'src/apollo/cache';
import DailyTestCenterMenuGroup from '../common/DailyTestCenterMenuGroup';

const { Title } = Typography;
const classOptions = [
  { label: '진행반', value: 0 },
  { label: '종료반', value: 1 },
];

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const TestResult = (props) => {
  // const query = new URLSearchParams(props.location.search);
  // const isRefresh = query.get('refresh');

  const [classes, setClasses] = useState([]);
  const [assigned_tests, setAssignedTests] = useState([]);
  const [class_idx, setClassIdx] = useState(0);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [checkedList, setCheckedList] = useState(new Array(assigned_tests?.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      class_idx: 0,
      page: 0,
      class_type: 0, //class_type - 0: 진행, 1: 종료
    },
    'test_result',
  );
  const [assignDailyTestDelete] = useMutation(DAILY_ASSIGN_TEST_DELETE);


  const { data, loading, refetch } = useQuery(DAILY_ASSIGNED_TEST_LIST, {
    // const { data, loading, refetch } = useQuery(ASSIGNED_TEST_LIST, {
    // variables: { class_idx, page, take },
    variables: { class_idx: searchValue.class_idx, class_type: searchValue.class_type },
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (data?.assignedTestsResultList) {
      setAssignedTests(data?.assignedTestsResultList?.assigned_tests);
      setTotal(data?.assignedTestsResultList.total);
    }

    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    // if (isRefresh) {
    //   refetch();
    //   props.history.replace({ search: '' });
    // }
  }, [data, classQuery]);

  const assignedTestsResult = useMemo(() => {
    if (data?.assignedTestsResultList) {
        return data?.assignedTestsResultList?.reduce((acc, cur, index) => {
            const obj = {
                assigned_date: `${moment(cur?.assigned_date).format('YYYY-MM-DD')}`,
                calss: cur?.class_idx_details?.name,
                test_series_title: cur?.test_group_detail?.name,
                // start_date:`${moment(cur?.idate).format('YYYY-MM-DD')}`,
                // end_date:`${moment(cur?.idate).format('YYYY-MM-DD')}`,
                tests: cur?.test_group_detail?.no_of_test,
                paper_test: cur?.test_group_detail?.testPdfDetail,
                complete: cur?.test_group_detail?.no_of_test,
                result: "",
                No: index + 1,
                key: cur.idx,
                idx: cur.idx,
                class_idx_details: cur?.class_idx_details
            };

            return [...acc, obj];
        }, []);
    }
}, [data])

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(assigned_tests.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }
  // const handleTestAssignDelete = async() => {
  //   const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
  //   const idxs = assigned_tests.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
  //   if(idxs){
  //     await assignDailyTestDelete({ variables:{
  //       idxs:idxs
  //     } });
  //   }
  //   // props.onTestAssignDelete({ idxs });
  //   setTimeout(() => {
  //     setCheckedList([]);
  //     refetch();
  //     classQuery.refetch();
  //   }, 1000);
  // };

  function setCurrentState() {
    setSearchValue((prev) => {
      return {
        ...prev,
        class_type: searchValue.class_type,
      };
    });
  }

  const columns = [
    {
        title: 'No',
        dataIndex: 'No',
        key: 'No',
        width: 50,
    },
    {
        title: 'Assigneed Date',
        dataIndex: 'assigned_date',
        key: 'assigned_date',
        width: 110,
        align: 'center',
    },
    {
        title: 'Class',
        dataIndex: 'calss',
        key: 'calss',
        width: 100,
        align: 'center',
        render: (text, record) => {
            return (
                <Link to={`/test/syllabus/${record.test_group_idx}/assign?classIdx=${record?.class_idx}`}>
                    <Space size="middle" style={{ color: '#1890ff' }}>
                        {text}
                    </Space>
                </Link>
            );
        },
    },
    {
        title: 'Test Series Title',
        dataIndex: 'test_series_title',
        key: 'test_series_title',
        width: 80,
        align: 'center',
    },
    {
        title: 'Tests',
        dataIndex: 'tests',
        key: 'tests',
        width: 120,
        align: 'center',
    },
    {
        title: 'Completed',
        dataIndex: 'complete',
        width: 100,
        key: 'complete',
        align: 'center',
    },
    {
        title: 'Result',
        dataIndex: 'result',
        key: 'result',
        width: 120,
        align: 'center',
        render: (text, record) => {
            return (
                <Button>
                    <Link to={`/daily/test/result/${record?.idx}/${record?.class_idx_details.idx}`}>Open</Link>
                </Button>
            );
        },
    },
];

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  return (
    <>
      <DailyTestCenterMenuGroup currentMenu="test-result"/>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>Test Results</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Radio.Group
                options={classOptions}
                optionType="button"
                value={searchValue.class_type}
                buttonStyle="solid"
                onChange={(e) => {
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      class_type: e.target.value,
                    };
                  });
                }}
              />
              &nbsp; &nbsp;
              {/* <Button danger onClick={handleTestAssignDelete}>
                Delete
              </Button> */}
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            // dataSource={assigned_tests}
            dataSource={assignedTestsResult}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => onHandleRowClick(record, rowIndex),
              };
            }}
          />
        </MainBlock>
      </Col>
    </>
  );
};
export default TestResult;
