import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { getCurrentToken } from 'src/utils';
import { Link } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import BookrOpenModal from 'src/components/Modal/BookrOpenModal';
import { isMobile } from 'react-device-detect';

const TodoCategory = styled.div`
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  padding: 0;
`;

//현재 진행 중인 반의 책을 보여주는 걸로 하자 일단은..
const BookrButton = (props) => {
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState('');

  const baseApi = props.book_id ? `bookrclass://book/${props.book_id}` : 'bookrclass://app';
  const token = getCurrentToken();

  const linkUrl = useMemo(() => {
    let pathArr = pathname.split('/');
    pathArr = pathArr.filter((item) => item !== '');
    if (pathArr.length < 1) pathArr = ['dashboard'];
    let returnUrl = `${window.location.origin}/bookr/redirect/${userInfoVar()?.idx}/${
      props.book_id ? props.book_id : 0
    }/${0}/${pathArr.join('|')}`;
    if (window.ReactNativeWebView) {
      returnUrl = `culpmobile://oauth/bookr/redirect/${userInfoVar()?.idx}/${props.book_id ? props.book_id : 0}/${0}/${pathArr.join('|')}`;
    }

    return `${baseApi}`;
  }, [pathname, props.book_id, token]);
  const handleClicked = () => {
    setUrl(linkUrl);
    setIsModalOpen(true);
    // window.location.href = linkUrl;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {props?.type === 'link' ? (
        <a href="#" onClick={handleClicked}>
          Bookr
        </a>
      ) : (
        <TodoCategory style={{ backgroundColor: props?.backgroundColor }} onClick={handleClicked}>
          {props?.text || 'Bookr'}
        </TodoCategory>
      )}
      {isModalOpen && <BookrOpenModal url={url} visible={isModalOpen} handleClose={handleCloseModal} />}
    </>
  );
};

export default BookrButton;
