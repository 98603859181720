import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, Pagination } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { ELIBRARY_BOOK_LIST } from 'src/operations/queries/elibraryBookList';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import PhonicsInfo from 'src/PhonicsInfo';
import LearningMenu from 'src/components/Phonics/LearningMenu';
import { useSelector } from 'react-redux';
import * as queries from 'src/operations/queries';
import PhonicsMenuGroup from 'src/components/common/PhonicsMenuGroup';
import { HeaderTitle } from 'src/components/common/Styles';
const { Title } = Typography;
const { Search } = Input;

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

let dropdownunit = [];
let templevelselection = [];
let templevelnumber = '';
let unitdropdownvalue = null;
let filterCodeLevelUnitUnitTitleShape = [];
let filterCodeLevelUnitUnitTitle = [];

const ElibraryAssignBooks = (props) => {
  const query = new URLSearchParams(props.location.search);
  const searchPage = query.get('page');
  const isRefresh = query.get('refresh');

  const [books, setBooks] = useState([]);
  const [PhonicsbooksByUnit, setPhonicsbooksByUnit] = useState([]);
  const [selectType, setSelectType] = useState(1);
  const [q, setQ] = useState(null);
  const [page, setPage] = useState(searchPage ? parseInt(searchPage) : 0);
  const [take, setTake] = useState(1000);
  const [total, setTotal] = useState(0);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [checkedList, setCheckedList] = useState(new Array(books.length).fill(false));
  const [selectEvine, setSelectEvine] = useState(true);
  const [Unitvalue, setUnitvalue] = useState([]);

  const menuType = useSelector((state) => state.learningMenu.menu_type);
  const title = useSelector((state) => state.learningMenu.title);
  const [phonicsDBList, setphonicsDBList] = useState([]);

  const { data: fetchPhonicsUnitList, loading: loadingPhonicsUnitList } = useQuery(queries.phonicsUnitList.PHONICS_LIST_GROUP, {
    fetchPolicy: 'no-cache',
    variables: { input: 'list' },
  });
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      level: 1,
      text: null,
      page: 0,
    },
    'we_search',
  );
  const [evineLevel, setEvineLevel] = UseHistoryState(
    {
      level: 'EA1',
      text: null,
      page: 0,
    },
    'ev_search',
  );

  const { data, refetch } = useQuery(ELIBRARY_BOOK_LIST, {
    fetchPolicy: 'no-cache',
    variables: selectEvine ? { evine_level: evineLevel.level, page: 0, take: 1000 } : { level: searchValue.level, page: 0, take: 1000 },
  });

  const levelList = [
    {
      id: 0,
      value: 'ALL',
    },
    {
      id: 1,
      value: '1A',
    },
    {
      id: 2,
      value: '1B',
    },
    {
      id: 3,
      value: '2A',
    },
    {
      id: 4,
      value: '2B',
    },
    {
      id: 5,
      value: '3A',
    },
    {
      id: 6,
      value: '3B',
    },
    {
      id: 7,
      value: '4A',
    },
    {
      id: 8,
      value: '4B',
    },
  ];

  let UnitList = [
    /*{
      id: 0,
      value: "ALL",
    },
    {
      id: 1,
      value: "1",
    },
    {
      id: 2,
      value: "2",
    },
    {
      id: 3,
      value: "3",
    },
    {
      id: 4,
      value: "4",
    },
    {
      id: 5,
      value: "5",
    },
    {
      id: 6,
      value: "6",
    },
    {
      id: 7,
      value: "7",
    },
    {
      id: 8,
      value: "8",
    }*/
  ];

  useEffect(() => {
    if (fetchPhonicsUnitList !== undefined) {
      if (filterCodeLevelUnitUnitTitle.length == 0) {
        let lastloopcount = null;
        fetchPhonicsUnitList.PhonicsUnitListGroup.map((phonicsdata, key) => {
          filterCodeLevelUnitUnitTitle[phonicsdata.code] = {
            code: phonicsdata.code,
            level: phonicsdata.level,
            unit: phonicsdata.unit,
            unit_title: phonicsdata.unit_title,
          };
          if (key == Object.keys(filterCodeLevelUnitUnitTitle).length) {
            lastloopcount = true;
          }
        });
        if (lastloopcount !== null) {
          filterCodeLevelUnitUnitTitleShape = [];
          for (const [key, value] of Object.entries(filterCodeLevelUnitUnitTitle)) {
            filterCodeLevelUnitUnitTitleShape.push({
              code: filterCodeLevelUnitUnitTitle[key].code,
              level: filterCodeLevelUnitUnitTitle[key].level,
              unit: filterCodeLevelUnitUnitTitle[key].unit,
              unit_title: filterCodeLevelUnitUnitTitle[key].unit_title,
            });
          }
          setBooks([...new Set(filterCodeLevelUnitUnitTitleShape.filter((item) => item.code))]);
          setTotal(Object.keys([...new Set(filterCodeLevelUnitUnitTitleShape.filter((item) => item.code))]).length);
          setCheckedList(
            new Array(Object.keys([...new Set(filterCodeLevelUnitUnitTitleShape.filter((item) => item.code))]).length).fill(true),
          );
        }
      }
    }
    if (isRefresh) {
      //refetch();
      //props.history.replace({ search: '' });
    }
  }, [data, page, props.history, searchPage, isRefresh, fetchPhonicsUnitList]);

  const handleChange = (e) => {
    setUnitvalue([]);
    if (e == 'ALL') {
      setBooks([...new Set(filterCodeLevelUnitUnitTitleShape.filter((item) => item.code))]);
      setUnitvalue([]);
      setTotal([...new Set(filterCodeLevelUnitUnitTitleShape.filter((item) => item.code))].length);
      setCheckedList(new Array([...new Set(filterCodeLevelUnitUnitTitleShape.filter((item) => item.code))].length).fill(true));
      unitdropdownvalue = null;
    } else {
      unitdropdownvalue = null;
      templevelnumber = e;
      let PhonicsInforesult = [...new Set(filterCodeLevelUnitUnitTitleShape.filter((item) => item.code))].filter(function (value, key) {
        return value.level == e;
      });
      setBooks([...new Set(PhonicsInforesult.filter((item) => item.code))]);
      setPhonicsbooksByUnit([...new Set(PhonicsInforesult.filter((item) => item.code))]);
      dropdownunit = PhonicsInforesult.map((Phonicsdata, key) => Phonicsdata.unit);
      templevelselection.push([...new Set(PhonicsInforesult.filter((item) => item.code))]);
      setUnitvalue([...new Set(dropdownunit)]);
      setTotal([...new Set(PhonicsInforesult.filter((item) => item.code))].length);
      setCheckedList(new Array([...new Set(PhonicsInforesult.filter((item) => item.code))].length).fill(true));
    }
  };

  const handleChangeUnit = (e) => {
    if (templevelselection[0] !== undefined) {
      let tempData = [...new Set(filterCodeLevelUnitUnitTitleShape.filter((item) => item.code))];
      setPhonicsbooksByUnit(tempData);
      let PhonicsInfounitresult = tempData.filter(function (value, key) {
        return value.unit == e && value.level == templevelnumber;
      });
      setBooks([...new Set(PhonicsInfounitresult.filter((item) => item.code))]);
      setTotal([...new Set(PhonicsInfounitresult.filter((item) => item.code))].length);
      setCheckedList(new Array([...new Set(PhonicsInfounitresult.filter((item) => item.code))].length).fill(true));
      unitdropdownvalue = null;
    }
  };

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    console.log('checkedList', checkedList);
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(books.length).fill(true));
    } else {
      setCheckedList([]);
    }
    console.log(checkedList);
  }
  const handleAssignData = () => {
    let data = [];
    books.forEach((item, index) => {
      checkedList.forEach((checked, index2) => {
        if (index === index2 && checked) {
          console.log('item', item);
          data.push(item);
        }
      });
    });
    localStorage.setItem('selectedBooks', JSON.stringify(data));
  };
  const columns = [
    {
      title: () => {
        return <Checkbox checked={checkedList.length === books.length} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'left',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
          </Space>
        );
      },
      width: 60,
    },
    {
      title: 'Code',
      dataIndex: 'idx',
      key: 'No',
      align: 'left',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{record.code}</Space>;
      },
    },
    {
      title: 'Level',
      dataIndex: `${selectEvine ? 'level' : 'level'}`,
      align: 'left',
      key: `${selectEvine ? 'level' : 'level'}`,
      width: 60,
      render: (text, record) => {
        return <Space size="middle">{selectEvine ? record.level : record.level}</Space>;
      },
    },
    {
      title: 'Unit',
      dataIndex: 'idx',
      key: 'No',
      align: 'left',
      width: 60,
      render: (text, record) => {
        return <Space size="middle">{record.unit}</Space>;
      },
    },
    {
      title: 'Unit Title',
      dataIndex: 'idx',
      key: 'No',
      align: 'left',
      width: 250,
      render: (text, record) => {
        return <Space size="middle">{record.unit_title}</Space>;
      },
    },
  ];

  const handleTestDelete = () => {
    const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    const idxs = books.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
    props.onTestDelete({ idxs });
    setTimeout(() => {
      setCheckedList([]);
      refetch();
    }, 1000);
  };

  function handleTableChange(pagination, filters) {
    console.log('pagination', pagination);
    console.log('filters', filters);
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }
  return (
    <>
      <div className="learning-homework-res">
        <HeaderTitle className="learning-homework-haeding" level={4}>
          <LearningMenu />
        </HeaderTitle>
        {menuType.length > 0 && <PhonicsMenuGroup currentMenu="assign" />}
      </div>
      <Col className="orderadmin-top-title" span={8} style={{ textAlign: 'left' }}>
        {menuType.length > 0 && <Title level={5}> {title} Assign </Title>}
      </Col>
      <Col className="contenttest-wrapper" span={24}>
        <MainBlock className="mainblock-elibrary">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6}></Col>
            {menuType == 'phonics' && (
              <Col className="contenttest-headitems elibrary-col" span={12} xs={20} md={18} style={{ float: 'right', textAlign: 'right' }}>
                <Link to="/learningschedule/levels/manage/action/assign" className="headitems-add elibrary-four" style={{ float: 'right' }}>
                  <Button className="elibrary-check-three" onClick={handleAssignData}>
                    Assign
                  </Button>
                </Link>
                {/*<Form.Item label="Unit" className="headitems-select elibrary-three" style={{ float: 'right' }}>
                  <Select
                    className="elibrary-check-two"
                    defaultValue={unitdropdownvalue}
                    placeholder=""
                    style={{ width: '100px' }}
                    optionFilterProp="children"
                    onChange={handleChangeUnit}
                  >
                    {Unitvalue !== undefined &&
                      Unitvalue.length > 0 &&
                      Unitvalue.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>*/}
                <Form.Item label="Level" className="headitems-select elibrary-three" style={{ float: 'right' }}>
                  <Select
                    className="elibrary-check-two"
                    placeholder="ALL"
                    style={{ width: '100px' }}
                    optionFilterProp="children"
                    onChange={handleChange}
                  >
                    {levelList.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.value}>
                          {item.value}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          {menuType == 'phonics' && (
            <CustomTable
              className="contenttest-table"
              dataSource={books}
              columns={columns}
              bordered
              pagination={{ pageSize: take, total, current: searchValue.page + 1 }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              //onChange={(a) => setPage(a.current - 1)}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => onHandleRowClick(record, rowIndex),
              //   };
              // }}
              onChange={handleTableChange}
            />
          )}
          {menuType == '' && <h2 style={{ textAlign: 'center', minHeight: '500px' }}> Please select an option</h2>}
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(ElibraryAssignBooks);
