import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  query login($id: String!, $pw: String!, $campus_idx: Int, $ingnore_pw: String) {
    login(id: $id, pw: $pw, campus_idx: $campus_idx, ingnore_pw: $ingnore_pw) {
      accessToken
      refreshToken
      expire_at
    }
  }
`;

export const USER_ME = gql`
  query meQuery($token: String!) {
    me(token: $token) {
      id
      name
      idx
      type
      level_test
      campus_idx
      company_name
      campus {
        idx
        name
        external_id
        fc_company_idx
        meta_schedule
        etc
        company {
          idx
          name
          has_branch
          is_auto_schedule
          ai_app_id
          switchCompany {
            idx
            name
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($type: String!, $class_idx: Int, $stat: String) {
    getUsers(type: $type, class_idx: $class_idx, stat: $stat) {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      deleted_working_days
      working_hour
      current_salary
      date_of_entry
      resident_reg_no
      account_number
      academic_background
      marital_status
      children
      blood_type
      report_income
      special_category
      type
      campus_idx
      department
      idate
      lexile_level
      class_student {
        idx
        class {
          idx
          name
          book_code
          begin_date
          idate
          book {
            book_sub {
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_STUDENT_USER = gql`
  query getUserData {
    getUserData {
      id
      name
      idx
      type
      lexile_level
      school_grade
      class_student {
        idx
        class {
          idx
          name
          levelup_level
        }
      }
      campus {
        idx
        name
      }
    }
  }
`;

export const GET_TEACHER_USER = gql`
  query getUserData {
    getUserData {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate
      class_teacher {
        idx
        class {
          idx
          name
          levelup_level
        }
      }
      campus {
        idx
        name
      }
    }
  }
`;

export const USER_EXIST_CHECK = gql`
  query isUserId($userId: String!) {
    isUserId(userId: $userId) {
      idx
    }
  }
`;

export const GET_CAMPUS_STUDENT_USERS = gql`
  query getCampusStudentUsers($campus_idx: Int!, $class_idx: Int) {
    getCampusStudentUsers(campus_idx: $campus_idx, class_idx: $class_idx) {
      idx
      id
      name
      english_name
      phone
      parent_phone
      school_grade
      class_student {
        class {
          name
          idx
        }
      }
      book_code_list {
        book {
          code
        }
        study_type
        issue_datetime
        start_datetime
        end_datetime
      }
    }
  }
`;

export const GET_STUDENT_HISTORY_LIST = gql`
  query classTeachersForStudent($student_idx: Int!) {
    classTeachersForStudent(student_idx: $student_idx) {
      idx
      class_idx
      user_idx
      begin_date
      end_date
      idate
      class {
        name
        begin_date
        end_date
        class_teacher {
          user {
            idx
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUserData($more_company_info: Boolean = false) {
    getUserData {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate
      lexile_level
      campus {
        idx
        fc_company_idx
        address
        address2
        postcode
        external_id
        company {
          idx
          is_auto_schedule
          name @include(if: $more_company_info)
          phone @include(if: $more_company_info)
          address @include(if: $more_company_info)
        }
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query getUserDataByIdx($idx: Int!) {
    getUserDataByIdx(idx: $idx) {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate
      stat
      lexile_level
    }
  }
`;
export const GET_USER_BY_IDX = gql`
  query getUserDataByIdx($idx: Int!) {
    getUserDataByIdx(idx: $idx) {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate
      lexile_level
    }
  }
`;

export const GET_EXIST_USERS = gql`
  query getExistUsers($user_ids: [String!]!) {
    getExistUsers(user_ids: $user_ids) {
      id
    }
  }
`;

export const GET_USER_SMS_ACCOUNT_INFO = gql`
  query getUserDataByIdx($idx: Int!) {
    getUserDataByIdx(idx: $idx) {
      idx
      id
      ione_sms_account_info
    }
  }
`;

export const GET_STUDENT_USERS_FOR_SMS = gql`
  query getUsers($type: String!) {
    getUsers(type: $type) {
      idx
      id
      name
      phone
      parent_phone
    }
  }
`;

export const GET_NEWSOMATIC_TOKEN = gql`
  query getNewsomaticToken($article_id: String, $assigned_idx: Int, $editionId: String) {
    getNewsomaticToken(article_id: $article_id, assigned_idx: $assigned_idx, editionId: $editionId)
  }
`;

export const GET_SCRIBO_TOKEN = gql`
  query getScriboToken {
    getScriboToken
  }
`;

export const GET_SCHEDULE_DATA = gql`
  query getScheduleData($campus_idx: Int!) {
    getScheduleData(campus_idx: $campus_idx) {
      campus_idx
      days
      start_time
      end_time
      no_of_system
      idate
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refresh($token: String!) {
    refresh(token: $token) {
      accessToken
      refreshToken
      expire_at
    }
  }
`;

export const READ_USER_SERVER_SAVED_DATA = gql`
  query readUserServerSavedData($idx: String!) {
    readUserServerSavedData(idx: $idx)
  }
`;
