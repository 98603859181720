import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Form, Input, Button, Checkbox, Switch, Row, Col } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';
import { useForm } from 'src/utils/hooks';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const MenuItemAddModal = ({ visible, handleCancel, fcInfo, routeItems, companyUserMenuIdx, parentId, nextIndex, selectedUserType }) => {
  const [form] = Form.useForm();
  const [showIcon, setShowIcon] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [routeItemOptions, setRouteItemOptions] = useState([]);

  useEffect(() => {
    setRouteItemOptions(
      routeItems.map((routeItem) => {
        return {
          label: routeItem.name,
          route: routeItem.route,
          value: routeItem.idx,
        };
      }),
    );
  }, []);

  const { onChange, onSubmit, values } = useForm(formCallback, {
    route_idx: '',
    item_name: '',
    show_icon: false,
    icon_url: '',
    is_hidden: false,
  });

  const [addCompanyUserMenuItem, { loading }] = useMutation(mutations.company.ADD_COMPANY_USER_MENU_ITEM, {
    variables: {
      menu_item_info: {
        ...values,
        show_icon: showIcon,
        is_hidden: isHidden,
        route_idx: parseInt(values.route_idx),
        company_user_menu_idx: companyUserMenuIdx,
        parent_id: parentId,
        index: nextIndex,
      },
    },

    refetchQueries: [
      {
        query: queries.company.GET_COMPANY_USER_MENU_LIST,
        variables: {
          company_idx: fcInfo.idx,
          user_type: selectedUserType,
        },
      },
    ],

    onCompleted(data) {
      if (data) {
        openNotification('메뉴 항목이 추가되었습니다!');
        //handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const onRouteItemChange = (e) => {
    onChange(e);
  };

  const handleShowIconChange = (e) => {
    setShowIcon(e.target.checked);
  };

  const handleIsHiddenChange = (e) => {
    setIsHidden(e);
  };

  function formCallback() {
    addCompanyUserMenuItem();
  }

  return (
    <>
      <Modal
        entered
        title={`메뉴 항목 추가`}
        visible={visible}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          initialValues={{
            route_idx: '',
            item_name: '',
            show_icon: false,
            icon_url: '',
            is_hidden: false,
          }}
        >
          <FormField hasFeedback={true} title="경로 항목">
            <FormItem name="route_idx" rules={[{ required: true, message: '경로 항목 선택' }]} hasFeedback>
              <select name="route_idx" style={{ width: '100%' }} className="ant-input" onChange={onRouteItemChange}>
                <option key={''} value={''}>
                  {'경로 항목 선택'}
                </option>
                {routeItemOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label} ({option.route})
                  </option>
                ))}
              </select>
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="항목명">
            <FormItem name="item_name" rules={[{ required: true, message: 'Please enter item name.' }]} hasFeedback>
              <Input name="item_name" onChange={onChange} maxLength={100} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="아이콘 표시">
            <FormItem name="show_icon" hasFeedback>
              <Checkbox name="show_icon" onChange={handleShowIconChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="아이콘 URL">
            <FormItem
              name="icon_url"
              rules={[
                { required: false },
                {
                  type: 'url',
                  message: '유효한 URL을 입력하세요',
                },
              ]}
              hasFeedback
            >
              <Input name="icon_url" onChange={onChange} maxLength={500} disabled={!showIcon} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="숨김">
            <FormItem name="is_hidden" valuePropName="checked" rules={[{ required: false }]} hasFeedback>
              <Switch name="is_hidden" onChange={handleIsHiddenChange} />
            </FormItem>
          </FormField>
        </Form>
      </Modal>
    </>
  );
};

MenuItemAddModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default MenuItemAddModal;
