import React, { useMemo, useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import CreateBookCreditForm from 'src/pages/Credits/CreateBookCreditForm';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_BOOK_CREDIT_LIST } from 'src/operations/queries/bookCredit';
import { GET_COMPANY_CAMPUS_AND_CONTENTS } from 'src/operations/queries/company';
import { GET_CAMPUS_STUDENT_USERS } from 'src/operations/queries/getUser';
import { CREATE_BOOK_CREDIT } from 'src/operations/mutations/bookCredit';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { openNotification } from 'src/components/common/Notification';

const CreateBookCreditModal = ({ visible, onClose }) => {
  const [isCreated, setIsCreated] = useState(false);
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: dataCompany } = useQuery(GET_COMPANY_CAMPUS_AND_CONTENTS, {
    skip: !dataUser,
    variables: {
      company_idx: dataUser?.getUserData.campus.fc_company_idx,
    },
  });

  const [getBookCreditList, { data: dataBookCredit, refetch }] = useLazyQuery(GET_BOOK_CREDIT_LIST);
  const bookCreditList = useMemo(() => {
    if (dataBookCredit) {
      return dataBookCredit.getBookCreditList.map((item, key) => {
        return { ...item, key: `book-credit-list${key}` };
      });
    }
    return [];
  }, [dataBookCredit]);

  const [getStudents, { data: dataStudents, loading: loadingStudents, refetch: refetchStudent }] = useLazyQuery(GET_CAMPUS_STUDENT_USERS);
  const students = useMemo(() => {
    if (dataStudents) {
      return dataStudents.getCampusStudentUsers.map((item, key) => {
        return { ...item, key: `student-list${key}` };
      });
    }
    return [];
  }, [dataStudents]);

  const [createBookCredit, { loading: loadingCreate }] = useMutation(CREATE_BOOK_CREDIT, {
    onCompleted: () => {
      openNotification('발급 완료!');
      setIsCreated(true);
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  const handleRefetchAll = () => {
    refetch();
    refetchStudent();
  };

  const handleChangeCampus = (value) => {
    getBookCreditList({ variables: { campus_idx: parseInt(value) } });
    getStudents({ variables: { campus_idx: parseInt(value) } });
  };
  const handleRegister = async (values) => {
    const { book_code, campus_idx, book_count, book_days } = values;
    const book_credit_input = {
      campus_idx,
      book_code,
      credit_count: book_count,
      credit_type: '2', //create manually
      book_days,
    };
    await createBookCredit({
      variables: { book_credit_input },
      refetchQueries: [
        {
          query: GET_BOOK_CREDIT_LIST,
          variables: { campus_idx },
        },
      ],
    });
  };
  const handleClose = () => {
    onClose(isCreated);
  };

  useEffect(() => {
    if (visible) {
      setIsCreated(false);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="이용권 관리"
      onCancel={handleClose}
      width={`${80}%`}
      footer={[
        <Button key="back" onClick={handleClose}>
          닫기
        </Button>,
      ]}
    >
      <CreateBookCreditForm
        campusList={dataCompany?.getCampusList}
        contentList={dataCompany?.getCompanyBookContentList}
        bookCreditList={bookCreditList}
        students={students}
        onChangeCampus={handleChangeCampus}
        onRegister={handleRegister}
        loadingCreate={loadingCreate}
        refetchAll={handleRefetchAll}
      />
    </Modal>
  );
};

export default CreateBookCreditModal;
