import React, { useState } from 'react';
import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import Campus from 'src/components/Offline/MainPage/CampusView';
/*import SubPage from 'src/components/Offline/SubPage';*/
import { useHistory } from 'react-router-dom';
//캠퍼스 원장용 대시보드 메인 페이지
const CampusPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  const history = useHistory();

  return (
    <>
      <Row justify="space-between" className="buttonlisting-dashboard">
        <HeaderTitle level={4}>Lesson Plan</HeaderTitle>
      </Row>

      <Row gutter={[24, 16]}><Campus /></Row>
    </>
  );
};

export default CampusPage;
