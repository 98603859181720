import React, { useState, useMemo } from 'react';
import { Col, Row, Typography, Space, DatePicker } from 'antd';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { HalfWrapper } from 'src/components/common/Styles';
import StudentHomeworkMenuGroup from 'src/components/common/StudentHomeworkMenuGroup';
import { ElibraryTable } from 'src/components/common/Styles';
import { STUDENT_ELIBRARY_STUDY_LIST } from 'src/operations/queries/study';
import { userInfoVar } from 'src/apollo/cache';

const { Title } = Typography;
const getStageGrade = (stageNo, stages) => {
  const found = stages.find((item) => item.stage_no === stageNo);
  if (found) {
    return found?.grade || '완료';
  }
  return null;
};

const ElibraryHistory = () => {
  const [month, setMonth] = useState(moment());
  const user_idx = userInfoVar()?.idx;
  const { data, loading } = useQuery(STUDENT_ELIBRARY_STUDY_LIST, {
    variables: { user_idx: parseInt(user_idx), ym: month.format('YYYY-MM') },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });
  const studyData = useMemo(() => {
    if (data?.studentElibraryStudyList) {
      return data?.studentElibraryStudyList
        .sort((a, b) => {
          if (moment(a.last_study_date).isSameOrAfter(b.last_study_date)) {
            return -1;
          }
          return 1;
        })
        .map((item, key) => {
          const { publisher, title, last_study_date, stage_info } = item;
          const date = moment(last_study_date).format('M월D일');
          //Stage Number 이게 왜 이러냐... 왔다갔다한다..
          //word: 22, reading: 23, quiz: 24, practice: 25, game: 26, recording: 27
          //word: 1, reading: 2, quiz: 3, practice: 4, game: 5, recording: 6
          const word = getStageGrade(22, stage_info) || getStageGrade(1, stage_info) || '미완료';
          const reading = getStageGrade(23, stage_info) || getStageGrade(2, stage_info) || '미완료';
          const quiz = getStageGrade(24, stage_info) || getStageGrade(3, stage_info) || '미완료';
          const practice = getStageGrade(25, stage_info) || getStageGrade(4, stage_info) || '미완료';
          const game = getStageGrade(26, stage_info) || getStageGrade(5, stage_info) || '미완료';
          const recording = getStageGrade(27, stage_info) || getStageGrade(6, stage_info) || '미완료';
          return {
            key: `elibrary-list-${key}`,
            date,
            title,
            publisher,
            word,
            reading,
            quiz,
            practice,
            game,
            recording,
          };
        });
    }
    return [];
  }, [data]);
  return (
    <>
      <StudentHomeworkMenuGroup currentMenu="elibrary" />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                전자도서관 학습결과
              </Title>
            </div>
            <Space direction="vertical">
              <DatePicker
                allowClear={false}
                inputReadOnly={true}
                onChange={(date) => {
                  setMonth(date);
                }}
                picker="month"
                value={month}
              />
            </Space>
            <ElibraryTable
              loading={loading}
              dataSource={studyData}
              columns={columns}
              pagination={{
                pageSize: 10,
                position: ['bottomCenter'],
              }}
              size="small"
              color="#edf3fb"
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ElibraryHistory;

const columns = [
  {
    title: '날짜',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: '10%',
  },
  {
    title: '출판사',
    dataIndex: 'publisher',
    key: 'publisher',
    align: 'center',
  },
  {
    title: '책제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '영역별점수',
    children: [
      {
        title: 'Word',
        dataIndex: 'word',
        key: 'word',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Reading',
        dataIndex: 'reading',
        key: 'reading',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Quiz',
        dataIndex: 'quiz',
        key: 'quiz',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Word Practice',
        dataIndex: 'practice',
        key: 'practice',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Word Game',
        dataIndex: 'game',
        key: 'game',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Recording',
        dataIndex: 'recording',
        key: 'recording',
        align: 'center',
        width: '10%',
      },
    ],
  },
];
