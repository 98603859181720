import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { PlayCircleTwoTone, SoundFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useQuery } from '@apollo/client';
import * as queries from 'src/operations/queries';
import SoundWords from 'src/components/common/SoundWords';
import LevelUpFinishPopUp from 'src/components/LevelUp/FinishPopUp';
import LevelUpExitPopUp from 'src/components/LevelUp/ExitPopUp';
const { LEVEL_UP_STUDY_DATA } = queries.externalStudy;
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 630px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #443ec8;
  color: #fff;
  margin-top: 20px;
  font-size: 25px;
  box-shadow: 0 2px 1px 2px #090989;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const XButton = styled.div`
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const StageTitle = styled.div`
  color: #fff;
  padding: 5px 45px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  background-color: #49ada1;
`;
const Title = styled(RowFlex)`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  align-items: baseline;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 650px;
  @media (max-width: 1280px) {
    max-width: 1100px;
    min-width: 600px;
    width: 95%;
    min-height: 600px;
  }
`;

const LeftBody = styled(ColumnFlex)`
  width: 55%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 85%;
`;

const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const RightBody = styled(ColumnFlex)`
  width: 45%;
  padding: ${(props) => (props.sentence ? '0 15px' : '0 20px')};
  height: 95%;
  
  /* justify-content: space-between; */
`;

const InfoBox = styled(ColumnFlex)`
  color: #000000;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: 2px #daedeb solid;
  border-radius: 25px;
  /* height: 50%; */
  font-size: ${(props) => `${Math.max(props.height, props.width) * 30}px`};
  width: 100%;
  height: 90%;
  cursor: pointer;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* @media (max-height: 960px) {
      max-width: 400px;
      max-height: 400px;
    } */
`;
const English = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #453ec8;
  width: 100%;
  height: ${(props) => (props.fullHeight ? '100%' : '45%')};
  text-align: center;
  font-size: 40px;
`;
const Korean = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #daedeb;
  color: #49ada1;
  border-radius: 22px;
  width: 100%;
  height: ${(props) => (props.fullHeight ? '100%' : '55%')};
`;
const WordListBox = styled(ColumnFlex)`
  background-color: #ffb900;
  border-radius: 25px;
  border: 2px #fff solid;
  height: 90%;
  color: #000000;
  font-weight: 700;
  font-size: 28px;
  align-items: center;
  padding: ${(props) => (props.sentence ? '25px 5px' : '25px')};
  justify-content: space-between;
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.05);
`;
const WordMidBox = styled(ColumnFlex)`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ed8c3d;
    border-radius: 4px;
  }
`;
const WordBox = styled.div`
  border-radius: 10px;
  border: 2px #fff solid;
  background-color: ${(props) => (props.active ? '#fef189' : '#fff')};
  color: #595758;
  margin-top: 10px;
  width: 90%;
  text-align: center;
  font-size: 21px;
  line-height: 1.6;
  cursor: ${(props) => (props.studied ? 'pointer' : 'default')};
`;
const SetenceBox = styled.div`
  border-radius: 10px;
  border: 2px #eaaa3e solid;
  background-color: ${(props) => (props.active ? '#fbe5d6' : '#fff')};
  color: ${(props) => (props.active ? '#cb0000' : '#000000')};
  margin-top: 10px;
  width: 85%;
  text-align: center;
  font-size: 17px;
  line-height: 1.8;
`;
const PlayCircle = styled(PlayCircleTwoTone)`
  z-index: 10;
  font-size: 70px;
  opacity: 0.6;
`;
const sentenceDummy = [
  { no: 1, eng: 'I live in a big house.', kor: '나는 큰 집에 살아요.', eng_audio: undefined, eng_image: undefined },
  {
    no: 2,
    eng: 'Our furniture in our house is new.',
    kor: '우리 집에 있는 가구는 새 것이예요.',
    eng_audio: undefined,
    eng_image: undefined,
  },
  { no: 3, eng: 'Sit on the chair.', kor: '의자에 앉으세요.', eng_audio: undefined, eng_image: undefined },
  { no: 4, eng: 'That is a big table.', kor: '그건 큰 책상이예요.', eng_audio: undefined, eng_image: undefined },
  { no: 5, eng: 'We sleep in a bed.', kor: '우리는 침대에서 자요.', eng_audio: undefined, eng_image: undefined },
  { no: 6, eng: "Let's wait in the living room.", kor: '거실에서 함께 기다려요.', eng_audio: undefined, eng_image: undefined },
];
const wordDummy = [
  { no: 1, eng: 'chair', kor: '의자', eng_audio: undefined, eng_image: undefined },
  { no: 2, eng: 'table', kor: '책상', eng_audio: undefined, eng_image: undefined },
  { no: 3, eng: 'floor', kor: '바닥', eng_audio: undefined, eng_image: undefined },
  { no: 4, eng: 'bed', kor: '침대', eng_audio: undefined, eng_image: undefined },
  { no: 5, eng: 'house', kor: '집', eng_audio: undefined, eng_image: undefined },
  { no: 6, eng: 'door', kor: '문', eng_audio: undefined, eng_image: undefined },
];
const useCheckScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return [width > 1600 ? 1 : width / 1600, height > 900 ? 1 : height / 900];
};
export default function LevelUpFlashCard({ type = 'voca', onEnd, bookData, noImage, assigned_idx = null }) {
  let width = useCheckScreen()[0];
  let height = useCheckScreen()[1];
  const [currentNum, setCurrentNum] = useState(0);
  const [studyNum, setStudyNum] = useState(1);
  const [wordUrl, setWordUrl] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const [finishVisible, setFinishVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [lastStudyNum, setLastStudyNum] = useState(1);
  const { idx } = useParams();
  const { data } = useQuery(LEVEL_UP_STUDY_DATA, { variables: { type: 'levelup', book_code: String(idx) }, skip: !idx });
  const studyData = useMemo(() => {
    if (type !== 'voca') {
      return sentenceDummy;
    } else {
      if (data?.getExternalStudyData && data?.getExternalStudyData.length > 0) {
        return data.getExternalStudyData;
      } else return wordDummy;
    }
  }, [data]);
  const length = studyData.length;
  // console.log(currentNum,'currentNum');
  // const history = useHistory();
  // useEffect(() => {
  //   if (currentNum === 4) {
  //     setStudyNum((prev) => prev + 1);
  //     setWordUrl(undefined);
  //     setIsPlaying(false);
  //   }
  // }, [currentNum]);
  useEffect(() => {
    if (studyNum > length) {
      onEnd({
        type: 'levelup',
        stage_answers: studyData.map((v) => {
          return { no: v.no, answer: v.eng };
        }),
      },setFinishVisible(true));
      // setTimeout(() => {
      //   setFinishVisible(true);
      // }, 500);
    }
  }, [studyNum]);
  const handleClickBox = () => {
    if (studyNum > length) {
      return;
    }

    setIsPlaying(true);
    if (!isPlaying) {
      if (played > 2) {
        if (studyNum === lastStudyNum) {
          setLastStudyNum((prev) => prev + 1);
        }
        setStudyNum((prev) => prev + 1);
        setCurrentNum(0);
        setPlayed(0);
        setTimeout(() => {
          setIsPlaying(false);
        }, 500);
      } else {
        setCurrentNum((prev) => prev + 1);
        setWordUrl(studyData[studyNum - 1].eng_audio);
      }
    }
    // setIsPlaying(false);
  };
  useEffect(() => {
    if (currentNum > 0) {
      // noImage?
      setTimeout(() => {
        handleClickBox();
      }, 850);
      // :
      // handleClickBox();
    }
  }, [currentNum, played]);

  useEffect(() => {
    if (currentNum === 0 && isPlaying) {
      setIsPlaying(false);
    }
  }, [currentNum, isPlaying]);
  const handleSoundEnd = () => {
    setWordUrl(undefined);
    setIsPlaying(false);
    setPlayed((prev) => prev + 1);
  };
  const handleClickWord = (num) => {
    setStudyNum(num);
  };
  return (
    <>
      {visible && <LevelUpExitPopUp assigned_idx={assigned_idx} visible={visible} setVisible={setVisible} idx={idx} />}
      {studyNum > length ? (
        finishVisible && <LevelUpFinishPopUp assigned_idx={bookData.assignedIdx || assigned_idx} idx={idx} />
      ) : (
        <Wrapper>
          <MainWrapper>
            <Main>
              <Title>
                <span>
                  <TitleSpan color={'#453ec8'}>{bookData?.title || ''}</TitleSpan>
                  {/* <TitleSpan>{bookData?.title || ''}</TitleSpan> */}
                </span>
                <XButton
                  onClick={() => {
                    setVisible(true);
                  }}
                />
              </Title>
              <ColumnFlex style={{ justifyContent: 'space-between', height: '93%' }}>
                <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '20px 50px' }}>
                  <RowFlex style={{ alignItems: 'center', fontSize: '16px', fontWeight: '600' }}>
                    <StageTitle>{type === 'voca' ? 'Voca' : 'Sentence'} Exercise</StageTitle>
                    &nbsp;&nbsp;Click the play button.
                  </RowFlex>
                  <NumberText>
                    Number{' '}
                    <QuizNumber>
                      <span style={{ color: '#433fc8' }}>{studyNum <= length ? studyNum : length}</span>/{length}
                    </QuizNumber>
                  </NumberText>
                </RowFlex>
                <RowFlex style={{ height: '90%', padding: '0 50px' }}>
                  <LeftBody>
                    <InfoBox
                      width={width}
                      height={height}
                      url={
                        noImage
                          ? currentNum === 0
                            ? '/images/LevelUp/study/main/click_owl.png'
                            : null
                          : currentNum === 0
                          ? studyData[studyNum - 1].eng_image&&studyData[studyNum - 1].eng_image.replace(' ','%20')
                          : ''
                      }
                      onClick={!isPlaying && currentNum === 0 && handleClickBox}
                    >
                      {currentNum === 0 && <PlayCircle twoToneColor="#66ABA1" />}
                      {currentNum > 0 && <English>{currentNum !== 2 && studyData[studyNum - 1].eng}</English>}
                      {currentNum > 0 && <Korean>{currentNum !== 1 && studyData[studyNum - 1].kor}</Korean>}
                    </InfoBox>
                    <ImgButton
                      onClick={() => {
                        setWordUrl(studyData[studyNum - 1].eng_audio);
                      }}
                    >
                      <SoundFilled />
                    </ImgButton>
                  </LeftBody>
                  <RightBody sentence={type === 'sentence'}>
                    <WordListBox sentence={type === 'sentence'} width={width} height={height}>
                      <div style={{ marginBottom: `${height * 15}px` }}>{type === 'voca' ? `Word` : `Key Sentences`} List</div>
                      <WordMidBox>
                        {studyData.map((e, i) => (
                          <WordBox
                            key={i}
                            active={e.no === studyNum}
                            studied={lastStudyNum >= e.no}
                            onClick={() => {
                              lastStudyNum >= e.no && handleClickWord(e.no);
                            }}
                          >
                            {e.eng}
                          </WordBox>
                        ))}
                      </WordMidBox>
                    </WordListBox>
                  </RightBody>
                </RowFlex>
              </ColumnFlex>
            </Main>
          </MainWrapper>
        </Wrapper>
      )}
      <SoundWords words={wordUrl ? [wordUrl] : []} onEnd={handleSoundEnd} />
    </>
  );
}
