import ReactDOM from 'react-dom';
import { Col, Row, Table } from 'antd';
import styled from 'styled-components';
import React, { useState, useMemo, useEffect } from 'react';
import { GET_ORDER_ITEM_LIST_META_REPORT, GET_META_EXAM_AVG_SCORE } from 'src/operations/queries/getLevelTest';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { getMetaExamId, getLixileScoreObject, getCefr } from 'src/utils';
// import { GENERATE_PDF_FILE_META } from 'src/operations/mutations/uploadFileS3';
import { META_EXAM_SAVE_REPORT } from 'src/operations/mutations/createTests';
import axios from 'axios';
import { GET_USER_INFO } from 'src/operations/queries/campus';

const InnerWrap = styled.div`
  width: 100%;
  max-width: 2000px;
  height: calc(100% - 7rem);
  padding: 0rem 0rem;
  margin: 0rem auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    max-width: 2600px;
  }

  @media (max-width: 1200px) and (max-height: 800px) and (orientation: landscape) {
    width: 100%;
    max-width: 1200px;
    padding: 0;
  }

  @media (max-width: 800px) and (max-height: 1200px) and (orientation: portrait) {
    width: 100%;
    max-width: 800px;
    padding: 0;
  }
`;

const ReportWrap = styled.div`
  width: calc(100% - 12rem);
  height: calc(100% - 12rem);
  padding: 1rem 0 0;
  margin: 0rem auto 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) and (max-height: 1200px) {
    width: 100%;
    max-width: 750px;
  }

  .ant-table-thead > tr > th {
    background: #dfdcd8;
    color: #548ca3;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .ant-table-tbody > tr > td {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .ant-table-cell {
    padding: 5px 0;
  }
`;

const PrintWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media print {
    page-break-before: always;
  }
`;

const LEXILE_RANGE = {
  'PRE-1': 'BR800L ~ 180L',
  'A-1': 'BR200L ~ 865L',
  'A-2': 'BR200L ~ 865L',
  'A-3': 'BR200L ~ 865L',
  'B-1': '250L ~ 1,440L',
  'B-2': '250L ~ 1,440L',
  'B-3': '250L ~ 1,440L',
  'C-1': '650L ~ 1,540L',
  'C-2': '650L ~ 1,540L',
  'C-3': '650L ~ 1,540L',
};

const NewReport = () => {
  // const [upload, { loading: loadingUpload }] = useMutation(GENERATE_PDF_FILE_META);
  const [saveReport, { loading: updateLoading }] = useMutation(META_EXAM_SAVE_REPORT);
  const [globalMx, setglobalMx] = useState(0);
  const [uploadTos3, setuploadTos3] = useState(false);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const order_id = params.get('id') ? Number(params.get('id')) : null;
  const company_idx = params.get('company_idx') ? Number(params.get('company_idx')) : null;
  const forceMXLevel = params.get('mx_level') ? Number(params.get('mx_level')) : null;
  const history = useHistory();
  const client = useApolloClient();
  const location = useLocation();
  const [userObj, setUserObj] = useState({});
  const [lexileScore, setlexileScore] = useState(0);
  const [batchType, setBatchType] = useState('');
  const [lastDate, setlastDate] = useState('');
  const [testIdxList, settestIdxList] = useState([]);
  const [tableData, settableData] = useState([]);
  const [mxchangeText, setmxchangeText] = useState(
    'The avg. score in the same group in other skill tests is similar to the reading level.',
  );
  const [devPercentage, setdevPercentage] = useState({ vocabulary: 50, reading: 50, grammar: 50, listening: 50 });

  const isPreTest = useMemo(() => {
    if (batchType.startsWith('PRE')) {
      return true;
    }
    return false;
  }, [batchType]);

  if (!location?.state?.idx_list && localStorage.getItem('meta_idx_list')) {
    location.state = { idx_list: JSON.parse(localStorage.getItem('meta_idx_list')) };
  }
  if (order_id) {
    location.state = { idx_list: [order_id] };
  }
  const { data: dataUser } = useQuery(GET_USER_INFO, {
    skip: company_idx !== null,
  });
  // when called from the PDF generate lambda there will be no user data
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 || company_idx === 1016 ? true : false;
  const { loading, data } = useQuery(GET_ORDER_ITEM_LIST_META_REPORT, { variables: { idx_array: location?.state?.idx_list } });
  const companyIdx = company_idx ? company_idx : dataUser?.getUserData?.campus?.company?.idx;

  const {
    data: avgData,
    loading: avgDataLoading,
    error,
  } = useQuery(GET_META_EXAM_AVG_SCORE, {
    variables: { idx_array: testIdxList, grade: userObj.grade, user_idx: userObj.user_idx, batch_type: batchType, order_idx: order_id },
    skip: !userObj.user_idx || !batchType,
  });
  const avgScoreData = useMemo(() => {
    if (avgData) {
      return avgData?.metaExamAvgScore?.json;
    }
    return {};
  }, [avgData]);
  useEffect(() => {
    if (userObj.id && uploadTos3) {
      exportToS3(userObj.id);
    }
  }, [userObj, uploadTos3]);
  const exportToS3 = (filename) => {
    let url =
      window.location.protocol +
      '//' +
      window.location.host +
      location.pathname +
      '?id=' +
      location.state.idx_list[0] +
      `&company_idx=${companyIdx}`;

    if (forceMXLevel !== null) {
      url += '&mx_level=' + forceMXLevel;
    }
    return new Promise(async (resolve, reject) => {
      try {
        const fileData = await axios.post('https://llf63tb67l3wj7nqerumdipm440stovy.lambda-url.ap-northeast-2.on.aws', {
          url: url,
          fileName: '-' + filename + '.pdf',
        });
        const fileUrl = fileData.data;
        // const fullUploader = await upload({ variables: { url: url, filename: '-' + filename + '.pdf' } });
        const variables = {
          order_id: Number(order_id),
          mx: forceMXLevel !== null ? forceMXLevel.toString() : globalMx.toString(),
          pdf_url: fileUrl,
          // pdf_url: fullUploader?.data?.generatePdfS3Meta?.id,
          assigned_test_user_idx: levels['reading']?.assigned_test_user_idx,
        };
        try {
          await saveReport({ variables: variables });
          window.location = fileUrl;
          // window.location = fullUploader?.data?.generatePdfS3Meta?.id;
          return;
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  const lexileScoreObject = useMemo(() => {
    if (batchType !== '') {
      return getLixileScoreObject(lexileScore, batchType);
    }
    return null;
  }, [batchType, lexileScore]);

  useEffect(() => {
    if (batchType !== '') {
      const cefr = getCefr(lexileScore);
      let dev = 0;
      let mx = Number(lexileScoreObject?.mx);
      if (levels && Object.keys(levels).length != 0) {
        dev += Number(levels['vocabulary']?.score) - Number(lexileScoreObject?.mean_values?.vocabulary);
        dev += Number(levels['listening']?.score) - Number(lexileScoreObject?.mean_values?.listening);
        if (levels['grammar']) dev += Number(levels['grammar']?.score) - Number(lexileScoreObject?.mean_values?.grammar);
        // Add reading too here

        setdevPercentage({
          vocabulary: Number(levels['vocabulary']?.score) - Number(lexileScoreObject?.mean_values?.vocabulary),
          reading: 0, // Not using now
          listening: Number(levels['listening']?.score) - Number(lexileScoreObject?.mean_values?.listening),
          grammar: Number(levels['grammar']?.score) - Number(lexileScoreObject?.mean_values?.grammar),
        });
      }
      let mxDev = dev;
      if (isPreTest) {
        if (dev <= -5 && mx > -1) {
          setmxchangeText('Your Lexile Measure is lower than other two skills by one mx level.');
          mx--;
          mxDev = mxDev + 5;
        } else if (dev >= 5) {
          setmxchangeText('Your Lexile Measure is lower than other two skills by one mx level.');
          mx++;
          mxDev = mxDev - 5;

          if (mx < 5 && dev >= 10) {
            mx++;
            mxDev = mxDev - 5;
          }
        } else {
          setmxchangeText('Your Lexile Measure did not get effected by two other skill scores.');
        }

        setglobalMx(mx);
        settableData([
          {
            key: '1',
            attempts: avgScoreData?.attempts,
            cefr: cefr.cefr,
            agl:
              dev > 5
                ? isNaN(lexileScoreObject?.agl)
                  ? lexileScoreObject?.agl
                  : parseFloat(lexileScoreObject?.agl) + 0.4
                : lexileScoreObject?.agl,
            grl: lexileScoreObject?.grl,
            dev: mxDev,
            actual_dev: dev,
            mx: forceMXLevel !== null ? forceMXLevel.toString() : mx,
          },
        ]);
      } else {
        if (dev <= -6) {
          setmxchangeText(
            'In your Lexile measure group, your total score in the other three skills is lower than that of other students by one mx-level. Your mx-level has been lowered by one level.',
          );
          mx--;
          mxDev = mxDev + 6;
        }
        if (dev >= 6) {
          setmxchangeText(
            'In your Lexile measure group, your total score in the other three skills is higher than that of other students by one mx-level. Your mx-level has been upgraded by one level.',
          );
          mx++;
          mxDev = mxDev - 6;

          // if (mx < 6 && dev >= 12) {
          //   mx++;
          //   mxDev = mxDev - 6;
          // }
        }
        setglobalMx(mx);
        settableData([
          {
            key: '1',
            attempts: avgScoreData?.attempts,
            cefr: cefr.cefr,
            agl:
              dev >= 6
                ? isNaN(lexileScoreObject?.agl)
                  ? lexileScoreObject?.agl
                  : parseFloat(lexileScoreObject?.agl) + 0.5
                : lexileScoreObject?.agl,
            grl: lexileScoreObject?.grl,
            dev: mxDev,
            actual_dev: dev,
            mx: forceMXLevel !== null ? forceMXLevel.toString() : mx,
          },
        ]);
      }
    }
  }, [lexileScore, batchType, avgScoreData, isPreTest]);
  const levels = useMemo(() => {
    let levels = {};
    if (data) {
      for (let i = 0; i < data?.orderItemListByIdx?.length; i++) {
        if (data?.orderItemListByIdx[i].order_leveltest) {
          for (let j = 0; j < data?.orderItemListByIdx[i]?.order_leveltest?.length; j++) {
            const item = data?.orderItemListByIdx[i]?.order_leveltest[j];
            let done = false;
            if (item?.assigned_tests?.assigned_test_users) {
              if (item?.assigned_tests?.assigned_test_users[0].status !== 1) {
                done = true;
              }
            }
            if (
              Object.keys(userObj).length === 0 &&
              item?.assigned_tests?.assigned_test_users?.length > 0 &&
              item?.assigned_tests?.assigned_test_users[0]?.user
            ) {
              const newUserObj = item?.assigned_tests?.assigned_test_users[0]?.user;
              setUserObj({
                id: getMetaExamId(newUserObj.birthday, newUserObj?.parent_phone),
                korean_name: newUserObj.english_name,
                name: newUserObj.name,
                grade: item?.order?.school_info,
                user_idx: newUserObj.idx,
              });
            }
            if (item?.assigned_tests?.tests?.test_type) {
              levels[item?.assigned_tests?.tests?.test_type] = {
                title: `${item?.assigned_tests?.tests?.title}`,
                assigned_tests_idx: item?.assigned_tests?.idx,
                done,
                batch_type: `${item?.assigned_tests?.tests?.batch_type}`,
                score: item?.assigned_tests?.assigned_test_users[0].score,
                total: `${item?.assigned_tests?.tests?.test_answers?.length}`,
                assigned_test_user_idx: item?.assigned_tests?.assigned_test_users[0].idx,
              };
              if (item?.assigned_tests?.tests?.test_type == 'reading' && item?.assigned_tests?.assigned_test_users[0].extra_data) {
                let ability = JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data)?.ability;
                setlexileScore(Math.round(ability / 5) * 5);
                setBatchType(item?.assigned_tests?.tests?.batch_type);
                if (!JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data)?.report_pdf_meta) {
                  setTimeout(() => {
                    setuploadTos3(true);
                  }, 2000);
                } else if (JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data)?.report_pdf_meta) {
                  // window.location = JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data)?.report_pdf_meta;
                }
              }
              if (!testIdxList.includes(item?.assigned_tests?.tests?.idx)) {
                settestIdxList((prevState) => [...prevState, item?.assigned_tests?.tests?.idx]);
              }
            }
            if (
              item?.assigned_tests?.assigned_test_users[0]?.end_time &&
              lastDate < item?.assigned_tests?.assigned_test_users[0]?.end_time
            ) {
              setlastDate(item?.assigned_tests?.assigned_test_users[0]?.end_time);
            }
          }
        }
      }
    }
    return levels;
  }, [data]);

  const columns = [
    {
      title: 'ATTEMPT',
      dataIndex: 'attempts',
      key: 'attempts',
    },
    {
      title: 'CEFR',
      dataIndex: 'cefr',
      key: 'cefr',
    },
    {
      title: 'ATOS/AR',
      dataIndex: 'agl',
      key: 'agl',
    },
    {
      title: 'Std. DEV.',
      dataIndex: 'dev',
      key: 'dev',
      render: (text, record) => {
        // if (parseInt(text) !== parseInt(record.actual_dev)) {
        //   return <span>{`${text} (${record.actual_dev})`}</span>;
        // } else {
        //   return <span>{text}</span>;
        // }

        return <span>{`${text} (${record.actual_dev})`}</span>;
      },
    },
    {
      title: 'MX LEVEL',
      dataIndex: 'mx',
      key: 'mx',
    },
  ];

  const columnsPre = [
    {
      title: 'ATTEMPT',
      dataIndex: 'attempts',
      key: 'attempts',
    },
    {
      title: 'CEFR',
      dataIndex: 'cefr',
      key: 'cefr',
    },
    {
      title: 'ATOS/AR',
      dataIndex: 'agl',
      key: 'agl',
    },
    {
      title: 'Std. DEV.',
      dataIndex: 'dev',
      key: 'dev',
      render: (text, record) => {
        return <span>{`${text} (${record.actual_dev})`}</span>;
      },
    },
    {
      title: 'MX LEVEL',
      dataIndex: 'mx',
      key: 'mx',
      render: (text, record) => {
        return <span>{parseInt(text) < 0 ? 'Phonics' : parseInt(text) === 0 ? 'Phonics2' : text}</span>;
      },
    },
  ];

  const reportChart = useMemo(() => {
    if (batchType.startsWith('PRE')) {
      return '../images/meta/pretest.png';
    }

    if (batchType.startsWith('A-')) {
      return '../images/meta/A.png';
    }

    if (batchType.startsWith('B-')) {
      return '../images/meta/B.png';
    }

    if (batchType.startsWith('C-')) {
      return '../images/meta/C.png';
    }
  }, [batchType]);

  if (!location?.state?.idx_list) {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/MetaExam/MetaLogin');
    return <Redirect to={`/MetaExam/MetaLogin`} />;
  }
  // const type = Object.keys(levels).length == 0 ? '' : levels[Object.keys(levels)[0]].batch_type;
  const calculateAvgScore = (avgScoreData, levels, type, grade = null) => {
    if (Object.keys(levels).length == 0) {
      return 0;
    }
    if (avgScoreData[type]?.total?.total_user_count == 0) {
      return 0;
    }
    if (!grade) {
      return (avgScoreData[type]?.total?.total_score / (levels[type].total * avgScoreData[type]?.total?.total_user_count)) * 100;
    }
    return (avgScoreData[type]?.total?.total_score / (levels[type].total * avgScoreData[type]?.total?.total_user_count)) * 100;
  };
  const lexileScoreRemeinder = Math.round(lexileScore / 5) * 5;
  const reportLogo = isWEnglish ? '../images/wenglish_logo.png' : '../images/Meta-reportLogo2.png';

  return (
    <>
      <div className="meta-report-con allPage weng">
        <InnerWrap>
          <div
            className="weng-lexile-logo-con"
            style={{ background: '#008670', margin: ' 0', padding: '5px 50px', width: '100%', display: 'flex' }}
          >
            <img src={reportLogo} alt="logo" height="45px" width="45px" />
            <div style={{ fontWeight: 700, fontSize: 40, flex: 1, textAlign: 'center', color: '#fff' }}>W-English Placement Test</div>
          </div>

          <ReportWrap>
            <div className="userInfo-con">
              <div className="userInfo  bold" style={{ width: '50%' }}>
                <Row>
                  <Col span={12}>
                    <span style={{ color: '#5295eb', float: 'left', fontWeight: '700' }}>Date: {lastDate.split('T')[0]}</span>
                  </Col>
                  <Col style={{ marginLeft: '15px' }} span={6}>
                    <span style={{ color: '#5295eb', fontWeight: '700' }}>Type: {batchType}</span>
                  </Col>
                </Row>

                <Row style={{ marginTop: '10px' }}>
                  {lexileScoreObject && <h2 style={{ fontSize: '16px', color: '#619561' }}>Lexile Range: {LEXILE_RANGE[batchType]}</h2>}
                </Row>
              </div>

              <div className="userInfo right">
                <Row>
                  <Col span={7}>
                    <span style={{ color: '#008670', fontWeight: '700' }}>Name</span>
                  </Col>
                  <Col span={17}>
                    <span style={{ color: '#008670', fontWeight: '700' }}>{userObj?.name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col span={7}>
                    <span style={{ color: '#008670', fontWeight: '700' }}>Grade</span>
                  </Col>
                  <Col span={17}>
                    <span style={{ color: '#008670', fontWeight: '700' }}>{userObj?.grade}</span>
                  </Col>
                </Row>
              </div>
            </div>
          </ReportWrap>

          <ReportWrap className="report-top" style={{ margin: '8px 0', padding: '1em 0' }}>
            <div className="reportInfo-con" style={{ justifyContent: 'space-between', verticalAlign: 'middle' }}>
              <div className="part-con" style={{ textAlign: 'center', left: 0, top: 0, transform: 'none', width: '50%' }}>
                <div className="userName" style={{ textTransform: 'capitalize', fontSize: '26px', fontWeight: '700' }}>
                  {userObj?.name}
                </div>
                <div style={{ textTransform: 'capitalize', fontSize: '26px', fontWeight: '700', marginTop: '10px', color: '#2e76b3' }}>
                  {userObj?.id}
                </div>
              </div>

              <div className="donut-wrap" style={{ marginBottom: '10px', marginRight: '20px' }}>
                <img src="../images/meta_w_lexile.png" alt="right_donut" style={{ width: '150px' }} />
                <div style={{ textAlign: 'center', marginTop: '-55px' }}>
                  <h2 className="measure-score">
                    {lexileScoreRemeinder < 0 ? `BR${Math.abs(lexileScoreRemeinder)}L` : `${lexileScoreRemeinder}L`}
                  </h2>
                </div>
                <div
                  style={{
                    marginTop: '30px',
                    color: '#5fc54c',
                    fontWeight: 700,
                    fontSize: '18px',
                  }}
                >
                  Lexile® Measures
                </div>
              </div>
            </div>
          </ReportWrap>
          {Object.keys(levels).length !== 0 && (
            <ReportWrap style={{ margin: '0px 0px', background: '#fff', border: '1px solid #008670', padding: '1em' }}>
              <div
                style={{
                  marginBottom: '40px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  background: '#fff',
                  paddingBottom: '30px',
                }}
              >
                {levels['vocabulary'] && (
                  <div className="card-con">
                    <div className="bg">
                      <div className="subject-con" style={{ textAlign: 'center' }}>
                        <h3>Vocabulary</h3>
                      </div>
                    </div>
                    <div className="donut-wrap" style={{ marginBottom: '10px' }}>
                      <img src="../images/meta_w_yellow.png" alt="right_donut" style={{ width: '120px' }} />
                      <div style={{ textAlign: 'center', marginTop: '-85px' }}>
                        <h3 className="measure-score">
                          {`${levels['vocabulary'].score ? levels['vocabulary'].score : 0}/${levels['vocabulary'].total}`}
                        </h3>
                        <h3
                          className="measure-score"
                          style={{
                            color: devPercentage.vocabulary > 0 ? '#f18a05' : devPercentage.vocabulary < 0 ? '#878787' : '#000000',
                          }}
                        >
                          {`${
                            levels['vocabulary'].score
                              ? devPercentage.vocabulary > 0
                                ? '+' + devPercentage.vocabulary
                                : devPercentage.vocabulary
                              : 0
                          }`}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}

                {levels['reading'] && (
                  <div className="card-con">
                    <div className="bg">
                      <div className="subject-con" style={{ textAlign: 'center' }}>
                        <h3>Reading</h3>
                      </div>
                    </div>
                    <div className="donut-wrap" style={{ marginBottom: '10px' }}>
                      <img src="../images/meta_w_blue.png" alt="right_donut" style={{ width: '120px' }} />
                      <div style={{ textAlign: 'center', marginTop: '-85px' }}>
                        <h3 className="measure-score">
                          {`${levels['reading'].score ? levels['reading'].score : 0}/${levels['reading'].total}`}
                        </h3>
                        <h3 className="measure-score">&nbsp;</h3>
                      </div>
                    </div>
                  </div>
                )}
                {levels['listening'] && (
                  <div className="card-con">
                    <div className="bg">
                      <div className="subject-con" style={{ textAlign: 'center' }}>
                        <h3>Listening</h3>
                      </div>
                    </div>
                    <div className="donut-wrap" style={{ marginBottom: '10px' }}>
                      <img src="../images/meta_w_green.png" alt="right_donut" style={{ width: '120px' }} />
                      <div style={{ textAlign: 'center', marginTop: '-85px' }}>
                        <h3 className="measure-score">
                          {`${levels['listening'].score ? levels['listening'].score : 0}/${levels['listening'].total}`}
                        </h3>
                        <h3
                          className="measure-score"
                          style={{
                            color: devPercentage.listening > 0 ? '#f18a05' : devPercentage.listening < 0 ? '#878787' : '#000000',
                          }}
                        >
                          {`${
                            levels['listening'].score
                              ? devPercentage.listening > 0
                                ? '+' + devPercentage.listening
                                : devPercentage.listening
                              : 0
                          }`}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
                {levels['grammar'] && (
                  <div className="card-con">
                    <div className="bg">
                      <div className="subject-con" style={{ textAlign: 'center' }}>
                        <h3>Grammar</h3>
                      </div>
                    </div>
                    <div className="donut-wrap" style={{ marginBottom: '10px' }}>
                      <img src="../images/meta_w_orange.png" alt="right_donut" style={{ width: '120px' }} />
                      <div style={{ textAlign: 'center', marginTop: '-85px' }}>
                        <h3 className="measure-score">
                          {`${levels['grammar'].score ? levels['grammar'].score : 0}/${levels['grammar'].total}`}
                        </h3>
                        <h3
                          className="measure-score"
                          style={{
                            color: devPercentage.grammar > 0 ? '#f18a05' : devPercentage.grammar < 0 ? '#878787' : '#000000',
                          }}
                        >
                          {`${
                            levels['grammar'].score ? (devPercentage.grammar > 0 ? '+' + devPercentage.grammar : devPercentage.grammar) : 0
                          }`}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <h6
                style={{
                  width: '100%',
                  color: '#434040',
                  fontSize: '14px',
                  marginTop: '-30px',
                  marginLeft: '10px',
                }}
              >
                * Number of correct / Deviation
              </h6>
            </ReportWrap>
          )}

          <ReportWrap style={{ marginTop: '10px', background: '#fff', padding: '5px 0' }}>
            <h3
              style={{
                textAlign: 'left',
                width: '100%',
                color: '#008670',
                fontSize: '18px',
                padding: '0 1em',
              }}
            >
              Level Chart
            </h3>
            <div style={{ width: '96%', border: '1px solid #008670', padding: '10px', margin: '0 auto' }}>
              <img src={reportChart} alt="report" style={{ width: '100%', maxHeight: '280px' }} />
              <div style={{ textAlign: 'center', marginTop: '5px' }}>
                <Table bordered tableLayout="fixed" pagination={false} columns={isPreTest ? columnsPre : columns} dataSource={tableData} />
              </div>
            </div>
          </ReportWrap>

          <div
            style={{
              background: '#fff',
              marginTop: '10px',
              padding: '20px 50px 10px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <img src={'/images/meta_group_.png'} alt="logo" height="30px" style={{ marginRight: '10px' }} />
            <img src={'/images/meta/metametrics_log.png'} alt="logo" height="30px" style={{ marginRight: '10px' }} />
            <img src={'/images/w_logo.jpg'} alt="logo" height="30px" />
            <img src={'/images/meta/lu-logo_old.png'} alt="logo" height="30px" style={{ marginRight: '10px' }} />
          </div>
          <div
            style={{
              background: '#fff',
              padding: '0px 50px 20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '10px',
            }}
          >
            METAMETRICS®, the METAMETRICS® logo and tagline, LEXILE®, LEXILE FRAMEWORK®, the LEXILE® logo are trademarks of MetaMetrics,
            Inc.,
            <br />
            Copyright © 2022 MetaMetrics, Inc. All rights reserved.
          </div>
        </InnerWrap>
      </div>
    </>
  );
};

export default NewReport;
