import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { COMPANY_BOOK_STORE_PACKAGE_LIST } from 'src/operations/queries/company';

const { Option } = Select;

const SelectBookSeriesBox = ({ company_idx, bookItemValue, onChange }) => {
  const { data, loading } = useQuery(COMPANY_BOOK_STORE_PACKAGE_LIST, { variables: { company_idx }, skip: !company_idx });
  const bookSeries = useMemo(() => {
    const list = data?.companyBookStorePackageList;
    if (list) {
      //전자도서관은 일단 제외...애매해서..
      //스마트이클래스(SM), 스니커즈(SK) 예외처리... 코코파닉스하고 같이 되어 있어서 분리해야 함..
      return list
       // .filter((ele) => ele.book_code !== 'EL')
        .reduce((acc, cur) => {
          if (['SM', 'SK'].includes(cur.book_code)) {
            const smarteclass = { ...cur, book_list: cur.book_list.filter((item) => item.level_no > 0) };
            const option = (
              <Option key={`book-seris${smarteclass.idx}-1`} value={JSON.stringify(smarteclass)}>
                {smarteclass?.book?.book_sub[0]?.title}
              </Option>
            );
            const phonics = {
              ...cur,
              book: { ...cur.book, book_sub: [{ title: 'Phonics' }] },
              book_list: cur.book_list.filter((item) => item.level_no === 0),
            };
            const option2 = (
              <Option key={`book-seris${phonics.idx}-2`} value={JSON.stringify(phonics)}>
                {`Coco Phonics`}
              </Option>
            );
            return [...acc, option, option2];
          } else {
            const option = (
              <Option key={`book-seris${cur.idx}`} value={JSON.stringify(cur)}>
                {cur?.book?.book_sub[0]?.title}
              </Option>
            );
            return [...acc, option];
          }
        }, []);
      // .map((item) => {
      //   return (
      //     <Option key={`book-seris${item.idx}`} value={JSON.stringify(item)}>
      //       {item?.book?.book_sub[0]?.title}
      //     </Option>
      //   );
      // });
    }
    return [];
  }, [data]);
  return (
    <Select value={bookItemValue} loading={loading} onChange={onChange}>
      <Option value="">선택</Option>
      {bookSeries && bookSeries.map((i) => i)}
    </Select>
  );
};

export default SelectBookSeriesBox;

export const useBookSeriesBox = () => {
  const [booSeriesInfo, setBookSeriesInfo] = useState('');
  const onChange = (item) => {
    setBookSeriesInfo(item);
  };
  return [booSeriesInfo, onChange];
};
