import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as S from 'src/pages/PhonicsMonster/style';
const VideoStage = ({ url }) => {
  const history = useHistory();
  const stageNumber = Number(window.location.pathname.split('/stage/')[1]);
  return (
    <ContentBox className="main_content stage1_content">
      <iframe title="iframe" src={url} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
      <S.Button2
        className="btn_type2"
        onClick={() => {
          history.push({
            pathname: `${window.location.pathname.split('/stage')[0]}`,
            search: `${window.location.search}`,
            state: { lesson: stageNumber > 5 },
          });
        }}
      >
        <img src="/images/pm/i_next.svg" alt="next" />
      </S.Button2>
    </ContentBox>
  );
};

export default VideoStage;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  width: 100%;
  height: calc(100% - 70px);
  margin-top: 10px;
  position: relative;
  @media (max-height: 768px) {
    height: calc(100% - 60px);
  }
  @media (max-height: 500px) {
    height: calc(100% - 50px);
  }
`;
