import React, { useMemo, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useMutation, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { TEST_DOCUMENTS_SINGLE } from 'src/operations/queries/testDocument';
import { CREATE_TEST_DOCUMENT, EDIT_TEST_DOCUMENT } from 'src/operations/mutations/testDocument';
import { GENERATE_PDF_FILE } from 'src/operations/mutations/uploadFileS3';
import { Button, Col, Upload, Row, Select, Input, Switch } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  UploadOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  PlusOutlined,
  DownOutlined,
  MinusOutlined,
  LoadingOutlined,
  RightCircleTwoTone,
} from '@ant-design/icons';
import * as axios from 'axios';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */

const TestSectionBlock = styled.div`
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const PER_PAGE = 10;
const SEARCH_TYPE_ALL = 0;
const SEARCH_TYPE_STUDENT = 1;
const SEARCH_TYPE_CLASS = 2;

const MAP_CIRCLE_TEXT_NUMBER = {
  '①': 1,
  '②': 2,
  '③': 3,
  '④': 4,
  '⑤': 5,
  '⑥': 6,
  '⑦': 7,
  '⑧': 8,
  '⑨': 9,
  '⑩': 10,
  '⑪': 11,
  '⑫': 12,
  '⑬': 13,
  '⑭': 14,
  '⑮': 15,
  '⑯': 16,
  '⑰': 17,
  '⑱': 18,
  '⑲': 19,
  '⑳': 20,
};

const OPTIONS_CHOICES = [
  {
    label: 'Multiple Choice',
    desc: 'If the answer is selected from the multiple answer choice provided along with the question',
    image: 'multipleChoice.png',
    value: 'multipleChoice',
  },
  { label: 'Grammar', desc: 'If the question is based on grammatical data ', image: 'grammar.png', value: 'grammar' },
  {
    label: 'Subjective',
    desc: 'If the question is related to a context (Eg: title of passage, meaning of passage, etc...)',
    image: 'subjective.png',
    value: 'subjective',
  },
  { label: 'True or False', desc: 'Statement validation', image: 'trueOrFalse.png', value: 'trueOrFalse' },
  {
    label: 'Fill in the blanks',
    desc: 'Question asked for filling blank data  ',
    image: 'fillInTheBlanks.png',
    value: 'fillInTheBlanks',
  },
  {
    label: 'Flow Based',
    desc: 'Questions related with the continuity of a content  ',
    image: 'flowBased.png',
    value: 'flowBased',
  },
  {
    label: 'Semantic Inference',
    desc: 'Questions asking for a language translation  ',
    image: 'semanticInference.png',
    value: 'semanticInference',
  },
  {
    label: 'Word Problem',
    desc: 'Question that is related to a particular word(Eg: Form of word, find omitted word etc...)',
    image: 'wordProblem.png',
    value: 'wordProblem',
  },
  {
    label: 'Order Based',
    desc: 'Questions related with sentence or context arrangement  ',
    image: 'orderBased.png',
    value: 'orderBased',
  },
  {
    label: 'Lecture',
    desc: 'If document do not have any questions, it will classified as lecture',
    image: 'lecture.png',
    value: 'lecture',
  },
  {
    label: 'Image Based',
    desc: 'Questions which are asked related to images, except answer is a multiple choice',
    image: 'imageBased.png',
    value: 'imageBased',
  },
  {
    label: 'Sentence Writing',
    desc: 'Questions that ask to write answers in sentence(s)',
    image: 'semanticWriting.png',
    value: 'semanticWriting',
  },
];

const QuestionComponent = ({ questionList }) => {
  return (
    <>
      {questionList.map((questionGroup, idx) => (
        <div key={idx} style={{ marginBottom: '10px', padding: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <div style={{ width: '100%', verticalAlign: 'middle', textAlign: 'left' }}>
              {questionGroup.group_name ? (
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: '#ebebeb',
                    marginBottom: '10px',
                    padding: '5px',
                    flexDirection: 'column',
                    pageBreakInside: 'avoid',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#ebebeb',
                      marginBottom: '10px',
                      padding: '5px',
                      width: '100%',
                    }}
                  >
                    {questionGroup.group_context}
                  </div>
                  <div style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: questionGroup.group_name }}></div>
                </div>
              ) : null}
              <div style={questionGroup.group_name ? { paddingLeft: '20px' } : {}}>
                {questionGroup.questions.map((qnsx, idxs) => (
                  <div key={`${idx}-${idxs}`} data-qns={qnsx['questionNumber']} style={{ marginTop: '10px', pageBreakInside: 'avoid' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '10px', marginBottom: '0px', fontWeight: '900' }}> Question:{qnsx['questionNumber']}</div>
                      <div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: qnsx['question'] }}></div>
                    </div>
                    {qnsx['informationText'] && qnsx['informationText'] != '<br>' ? (
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '10px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '10px',
                          border: '1px solid #ebebeb',
                        }}
                      >
                        <div
                          style={{ display: 'inline-block', flex: 1 }}
                          dangerouslySetInnerHTML={{ __html: qnsx['informationText'] }}
                        ></div>
                      </div>
                    ) : null}

                    {qnsx['answerChoices'] && qnsx['answerChoices'].length && (
                      <div style={{ paddingLeft: '20px', marginTop: '10px' }}>
                        <b style={{ marginRight: '10px' }}>Options:</b>
                        {qnsx['answerChoices'].map((ansx, idxsa) => (
                          <div key={`${idx}-${idxs}-${idxsa}`} style={{ marginTop: '10px', display: 'flex' }}>
                            {ansx}
                          </div>
                        ))}
                      </div>
                    )}

                    {idxs + 1 < questionGroup.questions.length && <hr style={{ marginTop: 10, borderTop: '1px solid blue' }} />}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </>
  );
};

const QuestionExtractor = () => {
  const { id = null } = useParams();
  const [saveTo, setSaveTo] = useState(userInfoVar()?.type > 3 ? 'admin' : 'publisher');
  const campus_idx = userInfoVar()?.campus_idx;
  const history = useHistory();
  const [questionList, setQuestionList] = useState([]);
  const [showExtra, setShowExtra] = useState(false);
  const [currentPlayUrl, setCurrentPlayUrl] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [scale, setScale] = useState(1);
  const [answerPage, setAnswerPage] = useState(1);
  const [htmlContent, setHtmlContent] = useState('');
  const [htmlStyle, setHtmlStyle] = useState('');
  const [htmlPages, setHtmlPages] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [noAnswerPdf, setNoAnswerPdf] = useState('');
  const [pageWidth, setPageWidth] = useState(400);
  const [fullScreen, setFullScreen] = useState(false);
  const [saveProcessing, setSaveProcessing] = useState(false);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [upload, { loading: loadingUpload }] = useMutation(GENERATE_PDF_FILE);
  const [initialFormValues, setInitialFormValues] = useState({
    code: `T-${moment().format('YYMMDDHHMMSS')}`,
    title: '',
    meta: [],
    form_id: '',
    batch_type: '',
    test_type: '',
  });

  const [loadTestDocumentStatus, { data, refetch }] = useLazyQuery(TEST_DOCUMENTS_SINGLE, {
    fetchPolicy: 'no-cache',
    variables: { idx: parseInt(id) },
  });

  useEffect(() => {
    if (id) {
      loadTestDocumentStatus();
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      console.log(data);
      if (data.testDocumentSingle && questionList.length === 0) {
        setInitialFormValues({
          code: data.testDocumentSingle.code,
          title: data.testDocumentSingle.title,
          meta: data.testDocumentSingle.meta.split(','),
        });
        if (!data.testDocumentSingle.company && !data.testDocumentSingle.campus && !data.testDocumentSingle.user) {
          setSaveTo('admin');
        } else {
          setSaveTo(data.testDocumentSingle.company ? 'publisher' : data.testDocumentSingle.campus ? 'campus' : 'me');
        }
        let groupQuestioinList = {};

        let sortedQuestionList = [];
        let questions = data.testDocumentSingle.questions.sort((a, b) => a.questionNumber - b.questionNumber);
        questions = questions.map((question, idx) => {
          question['answer'] = question['answer'].split('|');
          question['questionNumber'] = question['number'];
          question['answerChoices'] = question['options'].split('|');
          question['informationText'] = question['information_text'] || null;
          question['questionContext'] =
            question['question_context'] && question['question_context'] !== 'null' ? question['question_context'] : null;
          question['questionDirection'] = question['question_direction'] || null;
          question['questionType'] = question['question_type'] || '';
          question['answerDescription'] = question['answer_description'] || '';

          return question;
        });

        questions.forEach((question) => {
          if (question['questionContext']) {
            if (groupQuestioinList[question['questionContext']] === undefined) {
              groupQuestioinList[question['questionContext']] = [];
            }
            groupQuestioinList[question['questionContext']].push(question);
          } else {
            sortedQuestionList[question['questionNumber']] = { questions: [question], group_name: null, group_context: null };
          }
        });

        Object.keys(groupQuestioinList).forEach((group_name) => {
          let sortedGroupQuestionList = groupQuestioinList[group_name].sort((a, b) => {
            return a['questionNumber'] - b['questionNumber'];
          });
          sortedQuestionList[sortedGroupQuestionList[0]['questionNumber']] = {
            questions: sortedGroupQuestionList,
            group_name: group_name,
            group_context: sortedGroupQuestionList[0]['questionDirection'],
          };
        });

        setQuestionList(Object.values(sortedQuestionList));
        setNoAnswerPdf(data.testDocumentSingle.main_pdf);

        if (data.testDocumentSingle.extra_data) {
          let extra_data = JSON.parse(data.testDocumentSingle.extra_data);
          setAnswerPage(extra_data.answerPage || 1);
          setPdfUrl(extra_data.pdfUrl);
          setHtmlPages(extra_data.htmlPages || []);
          setHtmlStyle(extra_data.htmlStyle || '');
          if (extra_data.batch_type) {
            setInitialFormValues({
              code: data.testDocumentSingle.code,
              title: data.testDocumentSingle.title,
              meta: data.testDocumentSingle.meta.split(','),
              form_id: extra_data.form_id || '',
              batch_type: extra_data.batch_type || '',
              test_type: extra_data.test_type || '',
            });
          }
        }
      }
    }
  }, [data]);

  const [createTestDocument, { loading }] = useMutation(CREATE_TEST_DOCUMENT);
  const [editTestDocument, { loading: loadingEdit }] = useMutation(EDIT_TEST_DOCUMENT);

  const addQuestion = () => {
    setQuestionList([
      ...questionList,
      {
        questions: [
          {
            questionNumber: questionList.length + 1,
            questionType: '',
            questionContext: '',
            questionDirection: '',
            informationText: '',
            answer: [''],
            answerChoices: [''],
            answerDescription: '',
          },
        ],
        group_name: null,
        group_context: null,
      },
    ]);
  };

  const toCamelCase = (obj) => {
    return _.reduce(
      obj,
      (result, value, key) => {
        const finalValue = _.isPlainObject(value) || _.isArray(value) ? toCamelCase(value) : value;
        return { ...result, [_.camelCase(key)]: finalValue };
      },
      {},
    );
  };

  const getOptionsValue = (options) => {
    return OPTIONS_CHOICES.filter((option) => options.includes(option.value))[0] || '';
  };

  const handleUpload = async ({ file }) => {
    setUploadProcessing(true);
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await axios.post('https://question-extractor.cloubot.com/question_type_detection', formData);
    if (data.status_message === 'Success') {
      data.data = data.data.map((item) => {
        return {
          ...item,
          informationText: item.informationText ? item.informationText.trim().trim('<br>').trim('<br/>') : null,
          answerChoices: item.answerChoices ? item.answerChoices.map((option) => option.trim()) : [],
          answer: item.answer
            ? item.answer.map((a) => {
                let t = a.trim().replace(/(^[,\s]+)|([,\s]+$)/g, '');
                if (MAP_CIRCLE_TEXT_NUMBER[t]) {
                  t = MAP_CIRCLE_TEXT_NUMBER[t];
                }
                if (typeof t === 'string') {
                  t = t
                    .replace(/(^\([A-Z]+\))|(^[Ⓐ-Ⓩⓐ-ⓩ]+)|(^[1-9]+\.)/g, '')
                    .trim()
                    .replace(/(^[,\s]+)|^([:\s]+)|^([/\s]+)|([,\s]+$)|([/\s]+$)|([:\s]+$)/g, '')
                    .replace('→', ',');
                }
                return t;
              })
            : [],
        };
      });
      let groupQuestioinList = {};
      setInitialFormValues({
        ...initialFormValues,
        title: data.file_name.split('.')[0] || 'test',
      });
      setHtmlPages(data.pages ? data.pages : []);
      setHtmlStyle(data.extra_style ? data.extra_style : '');
      setHtmlContent(data.html_content);
      let sortedQuestionList = [];

      data.data.forEach((question) => {
        if (question['questionContext']) {
          if (groupQuestioinList[question['questionContext']] === undefined) {
            groupQuestioinList[question['questionContext']] = [];
          }
          groupQuestioinList[question['questionContext']].push(question);
        } else {
          sortedQuestionList[question['questionNumber']] = { questions: [question], group_name: null, group_context: null };
        }
      });

      Object.keys(groupQuestioinList).forEach((group_name) => {
        let sortedGroupQuestionList = groupQuestioinList[group_name].sort((a, b) => {
          return a['questionNumber'] - b['questionNumber'];
        });
        sortedQuestionList[sortedGroupQuestionList[0]['questionNumber']] = {
          questions: sortedGroupQuestionList,
          group_name: group_name,
          group_context: sortedGroupQuestionList[0]['questionDirection'],
        };
      });

      setQuestionList(Object.values(sortedQuestionList));

      const {
        data: {
          generatePdfS3: { id, mimetype, encoding },
        },
      } = await upload({
        variables: {
          html: data.html_content,
          filename: `${data.file_name.split('.')[0] || file.name.replace('.zip', '.pdf')}`,
        },
      });
      setPdfUrl(id);
    }
    setUploadProcessing(false);
  };

  const handleUplaodFile = (data, idx) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {
            singleUploadS3: { id, path, filename, mimetype, encoding },
          },
        } = await upload({ variables: { file: data } });
        resolve(id);
      } catch (e) {
        reject(e);
      }
    });
  };

  let myDocument = React.createRef();

  useEffect(() => {
    if (myDocument && myDocument.current) {
      setPageWidth(myDocument.current.offsetWidth);
    }
  }, [myDocument]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleSave = async () => {
    // try {
    setSaveProcessing(true);
    let main_pdf = noAnswerPdf;
    if (!noAnswerPdf && htmlPages.length > 0) {
      let htmlContentStr = `<html>
      <head>${htmlStyle}</head> ${renderToString(
        <>
          {htmlPages.slice(0, answerPage - 1).map((page, idx) => (
            <div key={idx} dangerouslySetInnerHTML={{ __html: page }} />
          ))}
        </>,
      )}</html>`;
      console.log(htmlContentStr);
      const {
        data: {
          generatePdfS3: { id: pdfId, mimetype, encoding },
        },
      } = await upload({
        variables: { html: htmlContentStr, filename: `${initialFormValues.title}.pdf` },
      });
      main_pdf = pdfId;
    }
    let questions = questionList.map((q) => {
      if (q.group_name) {
        return {
          ...q.questions[0],
          question: q.group_name,
          question_context: q.group_context,
          number: q.questions[0].questionNumber,
          information_text: null,
          question_direction: null,
          answer_description: null,
          meta: [],
          options: [],
          answer: [],
          is_group: 1,
          option_count: 0,
          questions: q.questions.map((q2) => ({
            ...q2,
            number: q2.questionNumber,
            question: q2.question,
            question_type: q2.questionType,
            question_context: q2.questionContext,
            question_direction: q2.questionDirection,
            information_text: q2.informationText,
            answer: q2.answer,
            answer_description: q2.answerDescription,
            options: q2.answerChoices,
            option_count: q2.answerChoices?.length || 0,
            meta: [],
          })),
        };
      } else {
        return {
          ...q.questions[0],
          number: q.questions[0].questionNumber,
          question: q.questions[0].question,
          question_type: q.questions[0].questionType,
          question_context: q.questions[0].questionContext,
          question_direction: q.questions[0].questionDirection,
          information_text: q.questions[0].informationText,
          answer: q.questions[0].answer,
          answer_description: q.questions[0].answerDescription,
          options: q.questions[0].answerChoices,
          option_count: q.questions[0].answerChoices?.length || 0,
          meta: [],
        };
      }
    });

    let extra_data = { htmlPages, htmlStyle, pdfUrl, answerPage };

    if (initialFormValues.form_id && initialFormValues.form_id !== '') {
      extra_data = {
        ...extra_data,
        form_id: initialFormValues.form_id,
      };
    }

    if (initialFormValues.batch_type && initialFormValues.batch_type !== '') {
      extra_data = {
        ...extra_data,
        batch_type: initialFormValues.batch_type,
      };
    }

    if (initialFormValues.test_type && initialFormValues.test_type !== '') {
      extra_data = {
        ...extra_data,
        test_type: initialFormValues.test_type,
      };
    }

    if (id) {
      await editTestDocument({
        variables: {
          ...initialFormValues,
          idx: parseInt(id),
          main_pdf,
          folder_type: saveTo,
          total_questions: questions.length,
          questions,
          extra_data,
        },
      });
    } else {
      await createTestDocument({
        variables: {
          ...initialFormValues,
          main_pdf,
          folder_type: saveTo,
          total_questions: questions.length,
          questions,
          extra_data,
        },
      });
    }
    setSaveProcessing(false);
    history.push('/question/extractor/list');
    // } catch (e) {
    //   console.log(e);
    //   console.log(questionList);
    // }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="question-extractor">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>
          <span style={{ paddingLeft: 10, fontSize: '0.7em' }}>Question Extractor.</span>
        </HeaderTitle>
        <div style={{ display: 'inline-block', width: 'auto', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
          <Button style={{ marginRight: '10px' }}>Question types</Button>
          <Button style={{ marginRight: '10px' }} onClick={addQuestion}>
            Add question
          </Button>
          <Button style={{ marginRight: '10px' }} onClick={() => setShowExtra(!showExtra)}>
            Toggle Extra
          </Button>
          <Input
            style={{ width: '100px' }}
            type="number"
            placeholder="Answer Page"
            value={answerPage}
            onChange={(e) => setAnswerPage(parseInt(e.target.value))}
          />
          <div style={{ display: 'inline-block', marginRight: '10px', marginLeft: '10px' }}>
            <Upload className="test-upload" onChange={handleUpload} multiple={false} beforeUpload={() => false} showUploadList={false}>
              <Button loading={uploadProcessing} icon={<UploadOutlined />}>
                Click to Upload
              </Button>
            </Upload>
          </div>
          <div style={{ display: 'inline-block', marginRight: '10px', marginLeft: '10px' }}>
            <Select value={saveTo} onChange={setSaveTo} style={{ minWidth: '150px' }}>
              {userInfoVar()?.type > 4 && <Select.Option value="admin">Admin</Select.Option>}
              <Select.Option value="publisher">FC 본사</Select.Option>
              <Select.Option value="campus">우리 학원 컨텐츠</Select.Option>
              <Select.Option value="me">내 개인 컨텐츠</Select.Option>
            </Select>
          </div>
          <Button type="primary" loading={saveProcessing} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col className="pdffullscreen active inactive" span={fullScreen ? 24 : 10}>
          <div className="openboxlayout">
            <Button type="primary" icon={<DownOutlined />}>
              Open
            </Button>
          </div>

          <TestSectionBlock style={{ height: '85vh', overflowY: 'auto', overflowX: 'hidden' }} ref={myDocument}>
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => (
                  <Page
                    key={page}
                    pageNumber={page}
                    scale={scale}
                    renderAnnotationLayer={true}
                    renderInteractiveForms={true}
                    renderTextLayer={true}
                    width={pageWidth}
                  />
                ))}
            </Document>
            <Button
              type="primary"
              onClick={() => setScale(scale - 0.2)}
              shape="circle"
              style={{
                background: '#3b99ff',
                position: 'absolute',
                top: '20px',
                right: '60px',
              }}
              icon={<ZoomOutOutlined />}
            />
            <Button
              type="primary"
              onClick={() => setScale(scale + 0.2)}
              shape="circle"
              style={{
                background: '#3b99ff',
                position: 'absolute',
                top: '20px',
                right: '100px',
              }}
              icon={<ZoomInOutlined />}
            />
          </TestSectionBlock>
        </Col>
        <Col style={{ background: '#fff', height: '85vh', overflow: 'auto' }} span={14}>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
            <b style={{ marginRight: '10px' }}> Title:</b>
            <Input
              onChange={(e) => setInitialFormValues({ ...initialFormValues, title: e.target.value })}
              value={initialFormValues.title}
            ></Input>
          </div>
          {showExtra && (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
                <b style={{ marginRight: '10px' }}> Test Type:</b>
                <Input
                  onChange={(e) => setInitialFormValues({ ...initialFormValues, test_type: e.target.value })}
                  value={initialFormValues.test_type}
                ></Input>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
                <b style={{ marginRight: '10px' }}> batch Type:</b>
                <Input
                  onChange={(e) => setInitialFormValues({ ...initialFormValues, batch_type: e.target.value })}
                  value={initialFormValues.batch_type}
                ></Input>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
                <b style={{ marginRight: '10px' }}> form ID:</b>
                <Input
                  onChange={(e) => setInitialFormValues({ ...initialFormValues, form_id: e.target.value })}
                  value={initialFormValues.form_id}
                ></Input>
              </div>
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}>
            <b style={{ marginRight: '10px' }}> Metadata:</b>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Metadata"
              onChange={(v) => setInitialFormValues({ ...initialFormValues, meta: v })}
              value={initialFormValues.meta}
            ></Select>
          </div>
          {questionList.map((questionGroup, idx) => (
            <div key={idx} style={{ marginBottom: '10px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <div style={{ width: '100%', verticalAlign: 'middle', textAlign: 'left' }}>
                  {questionGroup.group_name ? (
                    <div
                      style={{
                        display: 'flex',
                        backgroundColor: '#ebebeb',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '10px',
                        padding: '5px',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          backgroundColor: '#ebebeb',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '10px',
                          padding: '5px',
                          width: '100%',
                        }}
                      >
                        <b style={{ marginRight: '10px' }}> Group:</b>{' '}
                        <Input
                          style={{ display: 'inline-block' }}
                          defaultValue={questionGroup.group_context}
                          onChange={(e) => {
                            setQuestionList((prev) => {
                              const newList = [...prev];
                              newList[idx]['group_context'] = e.target.value;
                              return newList;
                            });
                          }}
                        ></Input>
                      </div>
                      <Editor
                        value={questionGroup.group_name}
                        onEditorChange={(html) => {
                          setQuestionList((prev) => {
                            const newList = [...prev];
                            newList[idx]['group_name'] = html;
                            return newList;
                          });
                        }}
                        style={{ display: 'inline-block', flex: 1 }}
                        inline={true}
                        init={{
                          skin: false,
                          content_css: false,
                          content_style: false,
                          toolbar: '',
                          menubar: false,
                        }}
                      />
                    </div>
                  ) : null}
                  <div style={questionGroup.group_name ? { paddingLeft: '20px' } : {}}>
                    {questionGroup.questions.map((qnsx, idxs) => (
                      <div key={`${idx}-${idxs}`} data-qns={qnsx['questionNumber']} style={{ marginTop: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <b style={{ marginRight: '10px' }}> Question:{qnsx['questionNumber']}</b>{' '}
                          <Input
                            style={{ display: 'inline-block' }}
                            value={qnsx['question']}
                            onChange={(e) => {
                              setQuestionList((prev) => {
                                const newList = [...prev];
                                newList[idx]['questions'][idxs]['question'] = e.target.value;
                                return newList;
                              });
                            }}
                          ></Input>
                          <Select
                            value={qnsx['questionType']}
                            onChange={(value) => {
                              setQuestionList((prev) => {
                                const newList = [...prev];
                                newList[idx]['questions'][idxs]['questionType'] = value;
                                return newList;
                              });
                            }}
                            style={{ marginLeft: '10px', minWidth: '100px' }}
                          >
                            {OPTIONS_CHOICES.map((option) => (
                              <Select.Option value={option.value} key={option.value}>
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                        {qnsx['informationText'] && qnsx['informationText'] != '<br>' ? (
                          <div
                            style={{
                              display: 'flex',
                              marginTop: '10px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: '10px',
                              border: '1px solid #ebebeb',
                            }}
                          >
                            <Editor
                              value={qnsx['informationText']}
                              onEditorChange={(html) => {
                                setQuestionList((prev) => {
                                  const newList = [...prev];
                                  newList[idx]['questions'][idxs]['informationText'] = html;
                                  return newList;
                                });
                              }}
                              style={{ display: 'inline-block', flex: 1 }}
                              inline={true}
                              init={{
                                width: '100%',
                              }}
                            />
                          </div>
                        ) : null}

                        <div
                          style={{
                            display: 'flex',
                            marginTop: '10px',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            marginBottom: '10px',
                            border: '1px solid #ebebeb',
                          }}
                        >
                          <div>
                            <Upload
                              accept="audio/*"
                              action={handleUplaodFile}
                              style={{ display: 'inline-block', flex: 1 }}
                              multiple={false}
                              file={qnsx['mp3']}
                              onChange={(newUrl) => {
                                console.log(newUrl);
                                setQuestionList((prev) => {
                                  const newList = [...prev];
                                  newList[idx]['questions'][idxs]['mp3'] = newUrl;
                                  return newList;
                                });
                              }}
                              showUploadList={{ showRemoveIcon: false }}
                            >
                              {qnsx['mp3'] ? (
                                <>
                                  <RightCircleTwoTone
                                    style={{ fontSize: '30px' }}
                                    onClick={() => {
                                      setCurrentPlayUrl(qnsx['mp3']);
                                      setPlaying(!playing);
                                    }}
                                  />
                                </>
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                          </div>
                          <div>
                            <Upload
                              accept="image/*"
                              action={handleUplaodFile}
                              style={{ display: 'inline-block', flex: 1, width: '50%' }}
                              listType="picture-card"
                              multiple={false}
                              file={qnsx['image']}
                              onChange={(newUrl) => {
                                console.log(newUrl);
                                setQuestionList((prev) => {
                                  const newList = [...prev];
                                  newList[idx]['questions'][idxs]['image'] = newUrl;
                                  return newList;
                                });
                              }}
                              showUploadList={{ showRemoveIcon: false }}
                            >
                              {qnsx['image'] ? <img src={qnsx['image']} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                          </div>
                        </div>

                        {qnsx['answerChoices'] && (
                          <div style={{ paddingLeft: '20px', marginTop: '10px' }}>
                            <b style={{ marginRight: '10px' }}>Options:</b>
                            {qnsx['answerChoices'].map((ansx, idxsa) => (
                              <div key={`${idx}-${idxs}-${idxsa}`} style={{ marginTop: '10px', display: 'flex' }}>
                                <Input
                                  style={{ display: 'inline-block' }}
                                  onChange={(e) => {
                                    setQuestionList((prev) => {
                                      const newList = [...prev];
                                      newList[idx]['questions'][idxs]['answerChoices'][idxsa] = e.target.value;
                                      return newList;
                                    });
                                  }}
                                  value={ansx}
                                ></Input>
                                {idxsa === qnsx['answerChoices'].length - 1 ? (
                                  <>
                                    <Button
                                      style={{ marginLeft: '10px' }}
                                      type="primary"
                                      shape="circle"
                                      onClick={() => {
                                        setQuestionList((prev) => {
                                          const newList = [...prev];
                                          newList[idx]['questions'][idxs]['answerChoices'][parseInt(idxsa) + 1] = '';
                                          return newList;
                                        });
                                      }}
                                      icon={<PlusOutlined />}
                                    ></Button>
                                    <Button
                                      style={{ marginLeft: '10px' }}
                                      shape="circle"
                                      onClick={() => {
                                        setQuestionList((prev) => {
                                          const newList = [...prev];
                                          newList[idx]['questions'][idxs]['answerChoices'].splice(idxsa, 1);
                                          return newList;
                                        });
                                      }}
                                      icon={<MinusOutlined />}
                                    ></Button>
                                  </>
                                ) : (
                                  <Button
                                    style={{ marginLeft: '10px' }}
                                    shape="circle"
                                    onClick={() => {
                                      setQuestionList((prev) => {
                                        const newList = [...prev];
                                        newList[idx]['questions'][idxs]['answerChoices'].splice(idxsa, 1);
                                        return newList;
                                      });
                                    }}
                                    icon={<MinusOutlined />}
                                  ></Button>
                                )}
                              </div>
                            ))}
                          </div>
                        )}

                        {qnsx['answer'] && (
                          <div style={{ paddingLeft: '20px' }}>
                            <b style={{ marginRight: '10px' }}>Answers:</b>
                            {qnsx['answer'].map((ansx, idxsa) => (
                              <div key={`${idx}-${idxs}-${idxsa}`} style={{ marginTop: '10px', display: 'flex' }}>
                                <Input
                                  style={{ display: 'inline-block' }}
                                  onChange={(e) => {
                                    setQuestionList((prev) => {
                                      const newList = [...prev];
                                      newList[idx]['questions'][idxs]['answer'][idxsa] = e.target.value;
                                      return newList;
                                    });
                                  }}
                                  value={ansx}
                                ></Input>
                                {idxsa === qnsx['answer'].length - 1 ? (
                                  <>
                                    <Button
                                      style={{ marginLeft: '10px' }}
                                      type="primary"
                                      shape="circle"
                                      onClick={() => {
                                        setQuestionList((prev) => {
                                          const newList = [...prev];
                                          newList[idx]['questions'][idxs]['answer'][parseInt(idxsa) + 1] = '';
                                          return newList;
                                        });
                                      }}
                                      icon={<PlusOutlined />}
                                    ></Button>
                                    {qnsx['answer'].length > 1 && (
                                      <Button
                                        style={{ marginLeft: '10px' }}
                                        shape="circle"
                                        onClick={() => {
                                          setQuestionList((prev) => {
                                            const newList = [...prev];
                                            newList[idx]['questions'][idxs]['answer'].splice(idxsa, 1);
                                            return newList;
                                          });
                                        }}
                                        icon={<MinusOutlined />}
                                      ></Button>
                                    )}
                                  </>
                                ) : (
                                  <Button
                                    style={{ marginLeft: '10px' }}
                                    shape="circle"
                                    onClick={() => {
                                      setQuestionList((prev) => {
                                        const newList = [...prev];
                                        newList[idx]['questions'][idxs]['answer'].splice(idxsa, 1);
                                        return newList;
                                      });
                                    }}
                                    icon={<MinusOutlined />}
                                  ></Button>
                                )}
                              </div>
                            ))}
                            {qnsx['answer'].length > 1 ? (
                              <Switch
                                style={{ marginTop: '10px' }}
                                checkedChildren="OR"
                                unCheckedChildren="AND"
                                checked={qnsx['condition'] === 'OR'}
                                onChange={(checked) => {
                                  setQuestionList((prev) => {
                                    const newList = [...prev];
                                    newList[idx]['questions'][idxs]['condition'] = checked ? 'OR' : 'AND';
                                    return newList;
                                  });
                                }}
                              />
                            ) : null}
                          </div>
                        )}

                        {idxs + 1 < questionGroup.questions.length && <hr style={{ marginTop: 10, borderTop: '1px solid blue' }} />}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </Col>
      </Row>
      <ReactPlayer
        url={currentPlayUrl}
        playing={playing}
        onError={(e) => {
          setPlaying(false);
        }}
        onEnded={() => {
          setPlaying(false);
        }}
        width="100%"
        height="0px"
        loop={false}
        config={{
          controls: true,
        }}
      />
    </div>
  );
};

export default QuestionExtractor;
