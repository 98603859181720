import React, { useEffect, useState } from 'react';
import { Col, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { phoneNumberCheck } from 'src/utils';

const CreateTeacherForm = ({ formValues, onChange, form }) => {
  const [phone, setPhone] = useState('');

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }

    onChange(e);
  };

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }

    form.setFieldsValue({
      phone: phone ? phone : undefined,
    });
  }, [form, formValues.phone, onChange, phone]);

  return (
    <>
      <FormField hasFeedback={true} title="이름">
        <FormItem name="name" rules={[{ required: true, message: '이름을 입력해 주세요.' }]} hasFeedback>
          <Input name="name" onChange={onChange} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="Email">
        <FormItem name="email" rules={[{ required: true, message: '이메일을 입력해 주세요.' }]} hasFeedback>
          <Input name="email" onChange={onChange} autoComplete="email" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="ID">
        <FormItem name="id" rules={[{ required: true, message: '아이디를 입력해 주세요.' }]} hasFeedback>
          <Input name="id" onChange={onChange} autoComplete="userid" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="Password">
        <FormItem name="pw" rules={[{ required: true, message: '비밀번호를 입력해 주세요.' }]} hasFeedback>
          <Input type="password" name="pw" onChange={onChange} autoComplete="current-password" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="전화번호">
        <FormItem name="phone" rules={[{ required: true, message: '전화번호를 입력해 주세요.' }]} hasFeedback>
          <Input name="phone" onChange={(e) => handleChange(e)} maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="팀 / 부서">
        <FormItem name="department" rules={[{ required: false }]}>
          <Input name="department" onChange={onChange} />
        </FormItem>
      </FormField>
    </>
  );
};

export default CreateTeacherForm;
