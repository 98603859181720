import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Select } from 'antd';
import PhonicsResultMenuGroup from 'src/components/common/PhonicsResultMenuGroup';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_LESSONS_FOR_PHONICS } from 'src/operations/queries/getClass';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { userInfoVar } from 'src/apollo/cache';
import UseHistoryState from 'src/components/common/UseHistoryState';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import { useHistory } from 'react-router';
import PhonicsMenuGroup from 'src/components/common/PhonicsMenuGroup';
const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  {
    key: 'class_name',
    title: '반명',
    dataIndex: 'class_name',
    align: 'center',
    sorter: true,
  },
  {
    key: 'student_name',
    title: '학생명',
    dataIndex: 'student_name',
    align: 'center',
    sorter: true,
  },
  {
    key: 'lesson_count',
    title: (
      <>
        학습 <br />
        Lesson 수
      </>
    ),
    dataIndex: 'lesson_count',
    align: 'center',
  },
  {
    key: 'complete',
    title: '수행율',
    dataIndex: 'complete',
    align: 'center',
  },
  {
    key: 'recent_study_date',
    title: '최근학습일',
    dataIndex: 'recent_study_date',
    align: 'center',
  },
  {
    key: 'recent_study_info',
    title: '최근학습정보',
    dataIndex: 'recent_study_info',
    align: 'center',
  },
  {
    key: 'study_status_view',
    title: (
      <>
        학습현황
        <br />
        상세보기
      </>
    ),
    dataIndex: 'study_status_view',
    align: 'center',
    render: (_, { class_idx, user_idx, student_name, complete_count, history }) => {
      if (!complete_count) {
        return '-';
      }
      return (
        <Button
          onClick={() => {
            history.push({
              pathname: `/learningschedule/results-class/${class_idx}/${user_idx}`,
              state: { user_name: student_name },
            });
          }}
          size="small"
        >
          상세보기
        </Button>
      );
    },
  },
];
const PER_PAGE = 10;
const SEARCH_TYPE_ALL = 0;
const SEARCH_TYPE_STUDENT = 1;
const SEARCH_TYPE_CLASS = 2;

const ResultClass = () => {
  const [searchType, setSearchType] = useState(SEARCH_TYPE_ALL);
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const campus_idx = userInfoVar()?.campus_idx;
  const history = useHistory();
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      class_idx: 0,
      search_type: SEARCH_TYPE_ALL,
      search_text: '',
      pageNumber: 0,
      sortOrder: '',
      sortField: '',
    },
    'hw_search',
  );

  const { data: dataUserLessons, loading } = useQuery(GET_USER_LESSONS_FOR_PHONICS, {
    variables: {
      skip: !campus_idx,
      campus_idx: campus_idx,
      class_idx: searchValue.class_idx,
      page: searchValue.pageNumber,
      search_text: searchValue.search_text,
      search_type: searchValue.search_type,
      sort_order: searchValue.sortOrder,
      sort_field: searchValue.sortField,
    },
  });
  const dataList = useMemo(() => {
    const list = dataUserLessons?.getUserLessonsPhonics?.user_lessons;
    if (list) {
      return list.map((item, key) => {
        const bookTitle = item?.class?.book?.book_sub[0]?.title || item?.class?.book_code;
        let study_info_caategory = '';
        if(item?.homework_result?.latest_info?.unit !== ''){
          study_info_caategory = (item?.homework_result?.latest_info?.unit).split('')[4]+(item?.homework_result?.latest_info?.unit).split('')[5]
        }
        
        return {
          No: PER_PAGE * searchValue.pageNumber + key + 1,
          key: `user-lesson-row${key}`,
          class_name: item?.class_name,
          class_idx: item?.class_idx,
          student_name: item?.student_name,
          lesson_count: `${item?.homework_result?.complete_count}/${item?.unit_count}`,
          complete: `${Math.round((100 * item?.homework_result?.complete_count) / item?.unit_count)}%`,
          recent_study_date: item?.homework_result?.latest_info?.last_date
            ? moment(item?.homework_result?.latest_info?.last_date).format('YYYY-MM-DD')
            : '없음',
          recent_study_info: item?.homework_result?.latest_info?.unit
            ? study_info_caategory+" "+item?.homework_result?.latest_info?.unit.slice(-4).replace('U', ' Unit ').replace('L', ' Lesson')
            : '없음',
          user_idx: item?.user_idx,
          complete_count: item?.homework_result?.complete_count,
          history: history,
        };
      });
    }
    return [];
  }, [dataUserLessons, searchValue]);
  useEffect(() => {
    onClassChange(searchValue.class_idx);
    // eslint-disable-next-line
  }, [searchValue.class_idx]);

  const handleClassChange = (idx) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        class_idx: idx,
        pageNumber: 0,
      };
    });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    const { order, field } = sorter;
    setSearchValue((prev) => {
      return {
        ...prev,
        pageNumber: pagination.current - 1,
        sortOrder: order || '',
        sortField: field,
      };
    });
  };
  const handleNameSearch = (sv) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        search_text: sv,
        search_type: searchType, //검색 버튼 눌렀을 때, 검색이 되도록 여기에 추가함.
        pageNumber: 0,
      };
    });
  };

  return (
    <>
      <PhonicsMenuGroup currentMenu="result" />
      <PhonicsResultMenuGroup currentMenu="class" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >

        <HeaderTitle level={4}>
          <span style={{ paddingLeft: 10, fontSize: '0.7em' }}>* 반을 기준으로 개인별 학습 현황을 종합적으로 확인하세요.</span>
        </HeaderTitle>
        <div style={{ display: 'inline-block', width: 'auto', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
          <ClassSelectBox
            onClassChange={handleClassChange}
            selectedClassIdx={selectedClassIdx}
            campus_idx={campus_idx}
            style={{ width: '200px', textAlign: 'left' }}
          />
          &nbsp;
          <label>
            <Select value={searchType} onChange={setSearchType} style={{ width: '90px' }}>
              <Select.Option value={SEARCH_TYPE_ALL}>전체</Select.Option>
              <Select.Option value={SEARCH_TYPE_STUDENT}>학생명</Select.Option>
              <Select.Option value={SEARCH_TYPE_CLASS}>반명</Select.Option>
            </Select>
          </label>
          &nbsp;
          <Input.Search
            placeholder="이름"
            type="text"
            style={{ width: '200px' }}
            defaultValue={searchValue.search_text}
            onSearch={handleNameSearch}
          />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper style={{ minHeight: 'calc(100vh - 25vh)' }}>
            <CustomTable
              dataSource={dataList}
              columns={columns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                current: searchValue.pageNumber + 1,
                total: dataUserLessons?.getUserLessonsPhonics?.total || 0,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ x: 530 }}
              loading={loading}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ResultClass;
