import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Col, Row, Typography, Modal, Button, DatePicker, Radio, Checkbox, Form, Input, Tooltip, Space, Select } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ClassRoomModal from 'src/components/Modal/ClassRoomModal';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';

import UseHistoryState from 'src/components/common/UseHistoryState';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek, getMonthDateRange } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { userVar } from 'src/apollo/cache';
const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;
const { value } = '';
const options = [
  { label: '진행중', value: true },
  { label: '종료', value: false },
];

function OfflineTextBookList({ Textbook_Result }) {
  //const userType = userInfoVar()?.type;
  const [tab, setTab] = useState('main');
  const [classList, setClassList] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [totalCurrentStudyingStudent, setTotalCurrentStudyingStudent] = useState(0);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const [searchValue, setSeachValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [lockedit, setlockedit] = useState(false);
  const [TextBookListData, setTextBookListData] = useState([]);
  const [TextBookListDataFilter, setTextBookListDataFilter] = useState([]);
  const [TextBookListPublisherFilter, setTextBookListPublisherFilter] = useState([]);
  const { showModal, handleCancel, popupMode } = useModal();
  const [text, setText] = useState(EditorState.createEmpty());

  const PublisherFilter = TextBookListPublisherFilter.map((Add) => Add);

  const [Inputbookcode, setInputbookcode] = useState('');
  const [Inputbooktitle, setInputbooktitle] = useState('');
  const [Inputbookpublisher, setInputbookpublisher] = useState('');
  const [Inputsubject, setInputsubject] = useState('');
  const [Inputskillname, setInputskillname] = useState('');
  const [Inputtotalnumber, setInputtotalnumber] = useState('');
  const [Inputdatepick, setInputdatepick] = useState('');
  const [Inputlevel, setInputlevel] = useState('');
  const [InputFolder, setInputFolder] = useState('');
  const [Inputmemo, setInputmemo] = useState('');
  const [Inputvalidation, setInputvalidation] = useState('');

  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  const userType = useMemo(() => userVar()[data?.me?.type] || userVar()[0], [data]);

  const {
    data: OfflineSyllabusTextbooklist,
    refetch,
    loading,
  } = useQuery(queries.offlinesyllabus.OFFLINE_SYLLABUS_TEXTBOOK_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [addtextbook] = useMutation(mutations.offlinesyllabus.OFFLINE_ADD_TEXTBOOK_OFFLINESYLLABUS, {
    onCompleted(data) {
      if (data) {
        refetch();
        setInputvalidation('Textbook Added Successfully !!!');
        setVisible(false);
        //window.location.reload();
      }
    },
    onError(error) {
      setInputvalidation('Please try Again !!!');
    },
  });

  const [getfetchtextbookbycode, { data: fetchtextbookbycode }] = useLazyQuery(
    queries.offlinesyllabus.OFFLINE_SYLLABUS_TEXTBOOK_LIST_BY_CODE,
  );

  useEffect(() => {
    if (Textbook_Result !== '') {
      refetch();
    }
  }, [Textbook_Result]);

  useEffect(() => {
    if (fetchtextbookbycode) {
      let bookobj = fetchtextbookbycode?.offlineTextbookBybookcode;
      setInputbookcode(bookobj[0].bookcode);
      setInputbooktitle(bookobj[0].booktitle);
      setInputbookpublisher(bookobj[0].publisher);
      setInputsubject(bookobj[0].subject);
      setInputskillname(bookobj[0].skillname);
      setInputtotalnumber(bookobj[0].lessonno);
      setInputdatepick(bookobj[0].registerdate);
      setInputlevel(bookobj[0].level);
      if (userType == 'fc') {
        setInputFolder(bookobj[0].folder_type);
      } else {
        setInputFolder(bookobj[0].folder_type == 'publisher' ? '' : bookobj[0].folder_type);
      }
      const { contentBlocks, entityMap } = htmlToDraft(bookobj[0].memo);
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setText(EditorState.createWithContent(contentState));

      /*setInputmemo(bookobj[0].memo)*/
      setVisible(true);
      setlockedit(true);
    }
  }, [fetchtextbookbycode]);

  const [deleteUser] = useMutation(mutations.offlinesyllabus.OFFLINE_SYLLABUS_TEXTBOOL_LIST_BULK_DELETE, {
    onCompleted(data) {
      if (data) {
        refetch();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const columns = [
    {
      title: 'Level',
      dataIndex: 'Level',
      key: 'Level',
      align: 'center',
      width: '8%',
      sorter: (a, b) => a.Level.localeCompare(b.Level),
    },
    {
      title: 'Book Code',
      dataIndex: 'Book_code',
      key: 'Book_code',
      align: 'center',
    },
    {
      title: 'Book Title',
      dataIndex: 'Book_Title',
      key: 'Book_Title',
      align: 'center',
    },
    {
      title: 'Publisher',
      dataIndex: 'Publisher',
      key: 'Publisher',
      align: 'center',
    },
    {
      title: 'No. of Lessons',
      dataIndex: 'No_of_Lessons',
      key: 'No_of_Lessons',
      align: 'center',
      render: (text, record) => <span style={{ color: '#249fe9' }}>{record.No_of_Lessons}</span>,
    },
    {
      title: 'Last Reg. Date',
      dataIndex: 'Last_reg_date',
      key: 'Last_reg_date',
      align: 'center',
      render: (text, record) => <span style={{ color: 'orange' }}>{record.Last_reg_date}</span>,
    },
    {
      title: 'Edit',
      key: '',
      align: 'center',
      render: (text, record) => (
        <Button disabled={lockedit} onClick={() => editbooklist(`${record.Book_code}`)}>
          <Text type={'success'}>Edit</Text>
        </Button>
      ),
    },
  ];

  let dataobj = [];
  let options = [];
  let filterpublisher = [];
  useEffect(() => {
    if (OfflineSyllabusTextbooklist?.offlineTextbookList) {
      let Textbooklistdata = OfflineSyllabusTextbooklist?.offlineTextbookList;
      const Textbooklistresult = OfflineSyllabusTextbooklist?.offlineTextbookList?.map((item, index) => {
        filterpublisher.push(item.publisher);
        let loopobject = {
          //"No":index+1,
          key: item.idx,
          Last_reg_date: item.registerdate.split('T')[0],
          Book_code: item.bookcode,
          Level: item.level,
          Book_Title: item.booktitle,
          Publisher: item.publisher,
          No_of_Lessons: item.lessonno,
          //"Lesson_Topic":item.lesson_topic,
          folder_type: item.folder_type,
        };
        return loopobject;
      });
      setTextBookListData(Textbooklistresult);
      setTextBookListDataFilter(Textbooklistresult);
      setTextBookListPublisherFilter([...new Set(filterpublisher)]);
    }
  }, [OfflineSyllabusTextbooklist]);

  function handlePublisherChange(e) {
    let bookdata = TextBookListDataFilter;
    let publisherfilterloop = [];
    const Textbooklistresultfilter = bookdata.map((item, index) => {
      if (e == item.Publisher) {
        let loopobject = {
          //"No":index+1,
          key: item.idx,
          Last_reg_date: item.Last_reg_date,
          Book_code: item.Book_code,
          Level: item.Level,
          Book_Title: item.Book_Title,
          Publisher: item.Publisher,
          No_of_Lessons: item.No_of_Lessons,
          folder_type: item.folder_type,
        };
        publisherfilterloop.push(loopobject);
        return loopobject;
      } else if (e == 'All') {
        let loopobject = {
          //"No":index+1,
          key: item.idx,
          Last_reg_date: item.Last_reg_date,
          Book_code: item.Book_code,
          Level: item.Level,
          Book_Title: item.Book_Title,
          Publisher: item.Publisher,
          No_of_Lessons: item.No_of_Lessons,
          folder_type: item.folder_type,
        };
        publisherfilterloop.push(loopobject);
        return loopobject;
      }
    });
    setTextBookListData(publisherfilterloop);
  }

  function handleLevelChange(e) {
    let bookdata = TextBookListDataFilter;
    const Textbooklistresultlevelfilter = bookdata.map((item, index) => {
      if (e == item.Level) {
        let loopobject = {
          //"No":index+1,
          key: item.idx,
          Last_reg_date: item.Last_reg_date,
          Book_code: item.Book_code,
          Level: item.Level,
          Book_Title: item.Book_Title,
          Publisher: item.Publisher,
          No_of_Lessons: item.No_of_Lessons,
          folder_type: item.folder_type,
        };
        return loopobject;
      } else if (e == 'All') {
        let loopobject = {
          //"No":index+1,
          key: item.idx,
          Last_reg_date: item.Last_reg_date,
          Book_code: item.Book_code,
          Level: item.Level,
          Book_Title: item.Book_Title,
          Publisher: item.Publisher,
          No_of_Lessons: item.No_of_Lessons,
          folder_type: item.folder_type,
        };
        return loopobject;
      }
    });
    setTextBookListData(
      Textbooklistresultlevelfilter.filter(function (e) {
        return e;
      }),
    );
  }

  function handleFolderChange(e) {
    let bookdata = TextBookListDataFilter;
    const Textbooklistresultlevelfilter = bookdata.map((item, index) => {
      if (e == item.folder_type) {
        let loopobject = {
          //"No":index+1,
          key: item.idx,
          Last_reg_date: item.Last_reg_date,
          Book_code: item.Book_code,
          Level: item.Level,
          Book_Title: item.Book_Title,
          Publisher: item.Publisher,
          No_of_Lessons: item.No_of_Lessons,
          folder_type: item.folder_type,
        };
        return loopobject;
      }
    });
    setTextBookListData(
      Textbooklistresultlevelfilter.filter(function (e) {
        return e;
      }),
    );
  }

  const handleSearch = (e) => {
    if (e !== '') {
      let bookdata = TextBookListDataFilter;
      const Textbooklistresultlevelfilter = bookdata.map((item, index) => {
        let findbookcode = item.Book_code.toLowerCase();
        let bookCodePattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');

        let finditemlevel = item.Level.toLowerCase();
        let itemlevelPattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');

        let findbookTitle = item.Book_Title.toLowerCase();
        let bookTitlePattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');

        let findPublisher = item.Publisher.toLowerCase();
        let publisherPattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');

        if (
          findbookcode.match(bookCodePattern) !== null ||
          finditemlevel.match(itemlevelPattern) !== null ||
          findbookTitle.match(bookTitlePattern) !== null ||
          findPublisher.match(publisherPattern) !== null
        ) {
          let loopobject = {
            //"No":index+1,
            key: item.idx,
            Last_reg_date: item.Last_reg_date,
            Book_code: item.Book_code,
            Level: item.Level,
            Book_Title: item.Book_Title,
            Publisher: item.Publisher,
            No_of_Lessons: item.No_of_Lessons,
          };
          return loopobject;
        }
      });
      setTextBookListData(
        Textbooklistresultlevelfilter.filter(function (e) {
          return e;
        }),
      );
    } else {
      setTextBookListData(TextBookListDataFilter);
    }
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  function handlesavebooklist() {
    setInputvalidation('');
    if (
      Inputbookcode !== '' &&
      Inputbooktitle !== '' &&
      Inputbookpublisher !== '' &&
      Inputsubject !== '' &&
      Inputskillname !== '' &&
      Inputtotalnumber !== '' &&
      Inputdatepick !== '' &&
      Inputlevel !== '' &&
      text !== ''
    ) {
      addtextbook({
        variables: {
          bookcode: Inputbookcode,
          booktitle: Inputbooktitle,
          publisher: Inputbookpublisher,
          subject: Inputsubject,
          skillname: Inputskillname,
          pageno: parseInt(Inputtotalnumber),
          registerdate: Inputdatepick,
          level: Inputlevel,
          memo: draftToHtml(convertToRaw(text.getCurrentContent())),
          folder_type: InputFolder == '' ? 'publisher' : InputFolder,
        },
      });
    } else {
      setInputvalidation('Kindly fill the all Text book Fields.!!!');
    }
  }

  const textbooklistrefetch = (e) => {
    refetch();
    setVisible(false);
    setlockedit(false);
  };

  const handleDateChange = (date, dateString) => {
    setInputdatepick(dateString);
  };

  const handlesavebooklistcancel = () => {
    setInputvalidation('');
    setInputbookcode('');
    setInputbooktitle('');
    setInputbookpublisher('');
    setInputsubject('');
    setInputskillname('');
    setInputtotalnumber('');
    setInputdatepick('');
    setInputlevel('');
    setInputmemo('');
    setVisible(false);
    setlockedit(false);
  };

  const handleLevelChangepopup = (e) => {
    setInputlevel(e);
  };

  const handleFolderChangepopup = (e) => {
    setInputFolder(e);
  };

  const editbooklist = (inputbookcode) => {
    setInputvalidation('');
    setlockedit(true);
    getfetchtextbookbycode({
      variables: {
        bookcode: inputbookcode,
      },
    });
  };

  useEffect(() => {
    setlockedit(false);
  });

  const onChange = (text) => {
    setText(text);
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY: 'auto' }}>
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                &nbsp;&nbsp;Book List
              </Title>
              <div className="header-classroom-search">
                <Form
                  form={form}
                  initialValues={{
                    layout: formLayout,
                  }}
                >
                  <Space>
                    <span style={{ textAlign: 'center', display: 'flex', alignItems: 'center', marginRight: '10px', height: '32px' }}>
                      Folder:
                    </span>
                    <Select
                      style={{ width: 150, color: '#065aa9' }}
                      defaultValue={searchValue.type}
                      placeholder="Select a folder"
                      optionFilterProp="children"
                      onChange={handleFolderChange}
                    >
                      <Select.Option value="publisher">본사 컨텐츠</Select.Option>
                      <Select.Option value="campus">학원 컨텐츠</Select.Option>
                      <Select.Option value="me">개인 컨텐츠</Select.Option>
                    </Select>
                    <Input.Search
                      className="inputsearch-classroom"
                      placeholder="Content Search"
                      allowClear
                      style={{ width: 200, marginRight: 10 }}
                      enterButton
                      onSearch={handleSearch}
                      onChange={onSeachChange}
                      value={searchValue}
                    />
                    <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                      <RedoOutlined />
                    </CustomButton>
                    <Select placeholder="Publisher" onChange={handlePublisherChange} style={{ width: '150px' }}>
                      <option key="0" value="All">
                        All Publisher
                      </option>
                      {PublisherFilter.map((address, loopkey) => (
                        <option key={loopkey} value={address}>
                          {address}
                        </option>
                      ))}
                    </Select>
                    <Select placeholder="level" onChange={handleLevelChange} style={{ width: '150px' }}>
                      <Option key="13" value="All">
                        All Level
                      </Option>
                      <Option key="0" value="Phonics">
                        Phonics
                      </Option>
                      <Option key="1" value="1">
                        level 1
                      </Option>
                      <Option key="2" value="2">
                        level 2
                      </Option>
                      <Option key="3" value="3">
                        level 3
                      </Option>
                      <Option key="4" value="4">
                        level 4
                      </Option>
                      <Option key="5" value="5">
                        level 5
                      </Option>
                      <Option key="6" value="6">
                        level 6
                      </Option>
                      <Option key="7" value="7">
                        level 7
                      </Option>
                      <Option key="8" value="8">
                        level 8
                      </Option>
                      <Option key="9" value="9">
                        level 9
                      </Option>
                      <Option key="10" value="10">
                        level 10
                      </Option>
                      <Option key="11" value="11">
                        level 11
                      </Option>
                      <Option key="12" value="12">
                        level 12
                      </Option>
                    </Select>
                    <span></span>
                    <span></span>
                    <span></span>
                  </Space>
                </Form>
              </div>
            </div>
            <CustomTable
              className="classroom-table-table"
              loading={loading}
              dataSource={TextBookListData}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: 12 }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {},
                };
              }}
            />
            <CustomButton
              danger
              style={{
                position: 'relative',
                top: '-7%',
                left: '40px',
              }}
              onClick={deleteConfirm}
            >
              Delete
            </CustomButton>
          </HalfWrapper>
        </Col>
        <Modal
          onClick="window.location.reload();"
          title="Text Book Management"
          centered
          visible={visible}
          width={1100}
          onSave={() => {
            setVisible(false);
            setlockedit(false);
          }}
          onCancel={() => {
            setVisible(false);
            setlockedit(false);
          }}
          footer={null}
        >
          <Row>
            <Col span={17} className="calendar-box">
              <Title className="header-classroom-title" level={3}>
                <span style={{ marginRight: '10%', color: 'green' }}> + &nbsp;&nbsp;Edit Textbook</span>
              </Title>
            </Col>
            <Col span={7} className="calendar-box">
              <Button
                className="btn-schedule"
                type={tab === 'main' && 'primary'}
                size="large"
                style={{ margin: 2, float: 'right', background: '#4e9b4e', borderColor: '#1d641d' }}
                onClick={(e) => textbooklistrefetch(e)}
                onClose={(e) => e.preventDefault()}
              >
                Textbook List
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={7}></Col>
            <Col span={12} className="calendar-box">
              <Title level={4} style={{ color: 'red' }}>
                {Inputvalidation}
              </Title>
            </Col>
            <Col span={5}></Col>
          </Row>
          <br />
          <Row style={{ border: '3px solid #8ec1e5', padding: '10px', background: '#d0dce5', borderColor: '#d0dce5' }}>
            <Col span={6} className="calendar-box">
              <Form
                form={form}
                initialValues={{
                  layout: formLayout,
                }}
                style={{ marginTop: '4%' }}
              >
                <Form.Item label="Book Code">
                  <Input disabled value={Inputbookcode} onInput={(e) => setInputbookcode(e.target.value)} />
                </Form.Item>
                <Form.Item label="Book Title">
                  <Input value={Inputbooktitle} onInput={(e) => setInputbooktitle(e.target.value)} />
                </Form.Item>
                <Form.Item label="Publisher">
                  <Input value={Inputbookpublisher} onInput={(e) => setInputbookpublisher(e.target.value)} />
                </Form.Item>
                <Form.Item label="Subject">
                  <Input value={Inputsubject} onInput={(e) => setInputsubject(e.target.value)} />
                </Form.Item>
                <Form.Item label="Skill Name">
                  <Input value={Inputskillname} onInput={(e) => setInputskillname(e.target.value)} />
                </Form.Item>
                <Form.Item label="Num. of lessons">
                  <Input type="number" value={Inputtotalnumber} onInput={(e) => setInputtotalnumber(e.target.value)} />
                </Form.Item>
              </Form>
            </Col>
            <Col span={1} className="calendar-box"></Col>
            <Col span={17} className="calendar-box">
              <Form
                form={form}
                initialValues={{
                  layout: formLayout,
                }}
                style={{ marginTop: '4%' }}
              >
                <Form.Item label="Register Date">
                  <DatePicker
                    value={moment(Inputdatepick, 'YYYY-MM-DD')}
                    onChange={(date, dateString) => {
                      handleDateChange(date, dateString);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name=""
                  label="Level"
                  rules={[
                    {
                      message: 'Please select gender!',
                    },
                  ]}
                  style={{ width: '36%' }}
                >
                  <>
                    <Select disabled value={Inputlevel} onChange={handleLevelChangepopup}>
                      <Option value="Phonics">Phonics</Option>
                      <Option value="1">level 1</Option>
                      <Option value="2">level 2</Option>
                      <Option value="3">level 3</Option>
                      <Option value="4">level 4</Option>
                      <Option value="5">level 5</Option>
                      <Option value="6">level 6</Option>
                      <Option value="7">level 7</Option>
                      <Option value="8">level 8</Option>
                      <Option value="9">level 9</Option>
                      <Option value="10">level 10</Option>
                      <Option value="11">level 11</Option>
                      <Option value="12">level 12</Option>
                    </Select>
                  </>
                </Form.Item>

                <Form.Item
                  name=""
                  label="Folder"
                  rules={[
                    {
                      message: 'Please select gender!',
                    },
                  ]}
                  style={{ width: '36%' }}
                >
                  <>
                    {userType == 'fc' ? (
                      <Select value={InputFolder} onChange={handleFolderChangepopup}>
                        <Option value="publisher">본사 컨텐츠</Option>
                        <Option value="campus">학원 컨텐츠</Option>
                        <Option value="me">개인 컨텐츠</Option>
                      </Select>
                    ) : (
                      <Select value={InputFolder} onChange={handleFolderChangepopup}>
                        <Option value="campus">학원 컨텐츠</Option>
                        <Option value="me">개인 컨텐츠</Option>
                      </Select>
                    )}
                  </>
                </Form.Item>

                <Form.Item label="Memo">
                  <Editor
                    editorState={text}
                    style={{ width: '100%' }}
                    rootStyle={{ height: 'calc(100% - 50px)', width: '100%', minHeight: 300 }}
                    editorStyle={{ backgroundColor: '#fff', minHeight: 120 }}
                    toolbarClassName="hide-print"
                    wrapperClassName="essay-wrapper"
                    onEditorStateChange={onChange}
                    toolbar={{
                      options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'link'],
                    }}
                  />
                  {/*<TextArea
                value={value}
                placeholder=""
                autoSize={{ minRows: 9, maxRows: 9 }}
                value={Inputmemo} onInput={e => setInputmemo(e.target.value) }
              />*/}
                </Form.Item>
              </Form>
            </Col>
            <Col span={11} className="calendar-box"></Col>
            <Col span={11} className="calendar-box">
              <Button
                className="btn-schedule"
                type={tab === 'main' && 'primary'}
                size="large"
                style={{ margin: 2, float: 'right', borderRadius: '5px' }}
                onClick={handlesavebooklist}
                onClose={(e) => e.preventDefault()}
              >
                Save
              </Button>
              <Button
                className="btn-schedule"
                type={tab === 'main' && 'primary'}
                size="large"
                style={{ margin: 2, float: 'right', borderRadius: '5px', background: '#ff8d13', borderColor: '#ff8d13' }}
                onClick={handlesavebooklistcancel}
                onClose={(e) => e.preventDefault()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal>
      </Row>
    </>
  );
}

export default OfflineTextBookList;
