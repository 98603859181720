import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { SYNC_BOOKR_STUDY_DATA } from 'src/operations/mutations/externalUserStudy';

const BookrRedirect = () => {
  const { bookId, assigned_idx, path, user_idx } = useParams();
  const [syncBookr, setSyncBookr] = useState(true);
  const history = useHistory();

  const [syncBookrStudyData] = useMutation(SYNC_BOOKR_STUDY_DATA, {
    onCompleted: (data) => {
      setSyncBookr(false);

      let splitPath = path.split('|');
      let finalPath = '/' + splitPath.join('/');

      history.push(finalPath);
    },
  });

  useEffect(() => {
    if (syncBookr) {
      const fromDate = bookId ? moment().subtract(1, 'days').format('YYYY-MM-DD') : moment().subtract(7, 'days').format('YYYY-MM-DD');
      const toDate = moment().add(1, 'days').format('YYYY-MM-DD');
      let data = {
        fromDate,
        toDate,
        user_idx: parseInt(user_idx),
      };

      if (bookId && bookId !== '0') {
        data = {
          ...data,
          bookId: parseInt(bookId),
        };
      }

      if (assigned_idx && assigned_idx !== '0') {
        data = {
          ...data,
          assigned_idx: parseInt(assigned_idx),
        };
      }

      syncBookrStudyData({
        variables: data,
      });
    }
  }, [syncBookr]);

  return (
    <div>
      <Spin spinning={true} style={{ width: '100%', textAlign: 'center' }} />
    </div>
  );
};

export default BookrRedirect;
