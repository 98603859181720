import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const PhonicsMenuGroup = ({ currentMenu}) => {
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }} >
      <MenuButton
        type={`${currentMenu === 'assign' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learningschedule/levels');
        }}
      >
        Assign
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'assign-list' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learningschedule/levels/manage/action/result');
        }}
      >
        Assign List
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'result' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learningschedule/results');
        }}
      >
        Result
      </MenuButton>
    </div>
  );
};

export default PhonicsMenuGroup;
