import React from 'react';
import { useParams } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_BOOKS_CODE } from 'src/operations/queries/company';
import StudyResultListContainer from 'src/components/StudyResult/StudyResultListContainer';

const StudyResult = () => {
  const { class_idx } = useParams();

  const { data: dataBook } = useQuery(GET_COMPANY_BOOKS_CODE, {
    skip: !userInfoVar()?.campus?.fc_company_idx,
    variables: {
      company_idx: userInfoVar()?.campus?.fc_company_idx,
      is_all: 1,
    },
  });
  return (
    <StudyResultListContainer
      class_idx={class_idx}
      user_idx={userInfoVar()?.idx}
      company_book_display={dataBook?.getCompanyBookContentList}
      user_login_id={userInfoVar()?.id}
    />
  );
};
export default StudyResult;
