import React, { useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Col, Input, Button, DatePicker, Form, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import DaumPostcode from 'react-daum-postcode';
import FormField from 'src/components/common/FormField';
import * as queries from 'src/operations/queries';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const postCodeStyle = {
  display: 'block',
  position: 'absolute',
  top: '30%',
  width: '400px',
  height: '500px',
  padding: '7px',
  zIndex: '1000',
  backgroundColor: '#ccc',
};
//const CreateCampusForm = ({ form, onFinish, initialValues, company_idx }) => {
const CreateCampusForm = ({ form, onFinish, initialValues, company_idx,campus_idx }) => {
  // const hasBranch = userInfoVar().campus?.company?.has_branch === '1' && userInfoVar().type === 4;
  // const { data: dataBranch, loading: loadingBranch } = useQuery(GET_BRANCH_LIST, { fetchPolicy: 'no-cache', skip: !hasBranch });
  // const branchOptions = useMemo(() => {
  //   const list = dataBranch?.branchList;
  //   if (list) {
  //     return list.map((item) => ({ label: item.name, value: item.idx }));
  //   }
  //   return [];
  // }, [dataBranch]);

  const companyName = useSelector(classStoreData);
  const [campus_title, teacher_title] = useMemo(() => {
    if (isAfterSchool(companyName)) {
      return ['학교', '선생님'];
    }
    return ['학원', '원장님'];
  }, [companyName]);
  const [isUserId, { data }] = useLazyQuery(queries.getUser.USER_EXIST_CHECK);
  const [showPostPop, setShowPostPop] = useState(false);
  //https://github.com/bernard-kms/react-daum-postcode
  const handleComplete = (data) => {
    setShowPostPop(false);
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    form.setFieldsValue({
      postcode: data.zonecode,
      address: fullAddress,
    });
  };
  const showDaumPostCode = () => {
    setShowPostPop(true);
  };

  return (
    <Form
      name="control-ref"
      layout="inline"
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      autoComplete="no"
    >
      <Row gutter={[16, 16]}>
        {/* hidden fields */}
        <FormItem name="type" rules={[{ required: true }]} hidden={true}>
          <Input name="type" hidden={true} />
        </FormItem>
        <FormItem name="company_idx" rules={[{ required: true }]} hidden={true}>
          <Input name="company_idx" hidden={true} />
        </FormItem>

        {/* {hasBranch && (
          <FormField hasFeedback={true} title={`지사`}>
            <FormItem name="parent_idx" rules={[{ required: true, message: `지사를 선택하세요.` }]} hasFeedback style={{ width: '90%' }}>
              <Select options={branchOptions} />
            </FormItem>
          </FormField>
        )} */}

        <FormField hasFeedback={true} title={`${campus_title}명`}>
          <FormItem
            name="name"
            rules={[{ required: true, message: `${campus_title}명을 입력하세요.` }]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input name="name" maxLength={30} />
          </FormItem>
        </FormField>

        <FormField title="홈페이지코드">
          <FormItem name="code" hasFeedback style={{ width: '90%' }}>
            <Input name="code" maxLength={30} />
          </FormItem>
        </FormField>

        <FormField
          //hasFeedback={true}
          title="전화번호"
        >
          <FormItem
            name="phone"
            //rules={[{ required: true, message: '전화번호를 입력하세요.' }]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input name="phone" maxLength={12} />
          </FormItem>
        </FormField>
        <FormField title="지역">
          <FormItem name="region" hasFeedback style={{ width: '90%' }}>
            <Input name="region" maxLength={20} />
          </FormItem>
        </FormField>
        {/* <FormField hasFeedback={true} title="가맹비">
          <FormItem name="fran_cost" rules={[{ required: true }]} hasFeedback style={{ width: '90%' }}>
            <Input name="fran_cost" maxLength={12} type="number" />
          </FormItem>
        </FormField> */}

        <FormField hasFeedback={true} title={teacher_title}>
          <FormItem
            name="director_name"
            rules={[{ required: true, message: `${teacher_title} 이름을 입력하세요.` }]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input name="director_name" maxLength={10} />
          </FormItem>
        </FormField>
        <FormField hasFeedback={true} title="핸드폰">
          <FormItem name="cellphone" hasFeedback style={{ width: '90%' }}>
            <Input name="cellphone" maxLength={15} />
          </FormItem>
        </FormField>
        <FormField hasFeedback={true} title="아이디">
          <FormItem
            name="manager_id"
            rules={[
              { required: true, message: '아이디를 입력하세요.' },
              {
                validator: async (rule, value) => {
                  isUserId({
                    variables: {
                      userId: value,
                    },
                  });

                  if (data?.isUserId.idx) {
                    return Promise.reject([rule.message]);
                  }
                },
                message: '이미 존재하는 ID 입니다.',
              },
            ]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input name="manager_id" autoComplete="new-password" maxLength={20} />
          </FormItem>
        </FormField>

        <FormField hasFeedback={true} title="비밀번호">
          <FormItem
            name="pw"
            rules={[
              { required: true, message: '비밀번호를 입력하세요.' },
              {
                pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
                validator: async (rule, value) => {
                  if (value.match(rule.pattern) !== null) {
                    return Promise.reject([rule.message]);
                  }
                },
                message: '한글은 입력할 수 없습니다.',
              },
            ]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input.Password name="pw" autoComplete="new-password" maxLength={20} />
          </FormItem>
        </FormField>
        <FormField title={`${teacher_title} 이메일`}>
          <FormItem name="email" hasFeedback style={{ width: '90%' }}>
            <Input name="email" maxLength={40} />
          </FormItem>
        </FormField>

        <FormField
          //hasFeedback={true}
          title="우편번호"
        >
          <FormItem
            name="postcode"
            //rules={[{ required: true, message: '우편번호를 입력하세요.' }]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input.Search name="postcode" maxLength={40} readOnly={true} onClick={showDaumPostCode} onSearch={showDaumPostCode} />
          </FormItem>
        </FormField>

        <FormField
          //hasFeedback={true}
          title="주소"
        >
          <FormItem
            name="address"
            //rules={[{ required: true }]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input name="address" maxLength={40} readOnly={true} />
          </FormItem>
        </FormField>
        <FormField
          //hasFeedback={true}
          title="상세주소"
        >
          <FormItem
            name="address2"
            //rules={[{ required: true, message: '상세주소를 입력하세요.' }]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input name="address2" maxLength={40} />
          </FormItem>
        </FormField>

        <FormField title="담당자">
          <FormItem name="manager_name" hasFeedback style={{ width: '90%' }}>
            <Input name="manager_name" maxLength={10} />
          </FormItem>
        </FormField>
        <FormField title={<span style={{ letterSpacing: -2 }}>담당자전화번호</span>}>
          <FormItem name="manager_phone" hasFeedback style={{ width: '90%' }}>
            <Input name="manager_phone" maxLength={12} />
          </FormItem>
        </FormField>
        <FormField title="담당자이메일">
          <FormItem name="manager_email" hasFeedback style={{ width: '90%' }}>
            <Input name="manager_email" maxLength={30} />
          </FormItem>
        </FormField>
        <FormField hasFeedback={true} title="가입일">
          <FormItem name="regdate" hasFeedback rules={[{ required: true, message: '가입일을 입력하세요.' }]} style={{ width: '90%' }}>
            <DatePicker style={{ width: '100%' }} />
          </FormItem>
        </FormField>
        <Col span={24} style={isAfterSchool(companyName) ? { display: 'none' } : {}}>
        </Col>
        <div className="daum-post-code-wrapper" style={{ ...postCodeStyle, display: showPostPop ? 'block' : 'none' }}>
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setShowPostPop(false);
              }}
            >
              X
            </Button>
          </div>
          <DaumPostcode onComplete={handleComplete} />
        </div>
      </Row>
    </Form>
  );
};

export default CreateCampusForm;
