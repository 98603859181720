import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Select, } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { userInfoVar } from 'src/apollo/cache';
import AdminTalkingTutorMenu from 'src/components/common/AdminTalkingTutorMenu';
import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { Input, Modal, Form } from 'antd';
import { AI_Talking_TOPICS_LIST, GET_CONTENT_LIST } from 'src/operations/queries/aiGroupTopic';
import { CREATE_CONTENT, GET_CONTENT, UPDATE_CONTENT, DELETE_AI_CONTENT } from 'src/operations/mutations/aiGroupTopic';
import { openNotification } from '../../../../components/common/Notification'

const { Option } = Select;

const AiContentList = () => {
    //
    const history = useHistory();
    const [formControl] = Form.useForm();
    const [contentList, setContentList] = useState([]);
    const [classes, setClasses] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [topicType, setTopicType] = useState('ai-talking-tutor');
    const [level, setLevel] = useState(-1);
    const [searchValue, setSeachValue] = useState('');
    const [folderType, setFolderType] = useState('me');
    const [contentIdx, setContentIdx] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [editIdx, setEditIdx] = useState(null);
    const [showError, setShowError] = useState(false);
    const [contentName, setContentName] = useState(false);
    const [pg, setPg] = useState({ current: 0, pageSize: 10 });
    const [ch, setCh] = useState(
        contentList.map((e) => {
            const obj = {
                idx: e.idx,
                value: false,
            };
            return obj;
        }),
    );
    useEffect(() => {
        if (level !== -1) {
            setPg({ current: 0, pageSize: 10 });
        }
        setPg({ ...pg, current: 0 });
    }, [folderType, topicType, level]);
    const [createContent] = useMutation(CREATE_CONTENT);
    const [updateContent] = useMutation(UPDATE_CONTENT);
    const [getContent] = useMutation(GET_CONTENT);


    const { data: getContentList, refetch } = useQuery(GET_CONTENT_LIST, { variables: { type: "ai-talking-tutor" } });
    const [deleteAIContent] = useMutation(DELETE_AI_CONTENT);

    const { data } = useQuery(AI_Talking_TOPICS_LIST, {
        fetchPolicy: 'no-cache',
        variables: {
            folderType: topicType === 'levelup' ? 'NA' : folderType,
            type: topicType,
            take: pg.pageSize,
            page: pg.current,
            q: searchValue,
            level: level !== -1 ? `"level": ${level},` : null,
            content_idx: contentIdx
            // class_idx: 0
        },
    });

    const classQuery = useQuery(GET_TYPE_CLASS, {
        variables: {},
    });

    useEffect(() => {
        if (classQuery?.data?.classList) {
            setClasses(classQuery?.data?.classList);
        }
    }, [classQuery?.data?.classList]);

    useEffect(() => {
        if (getContentList?.getContentList?.length > 0) {
            setContentList(
                getContentList?.getContentList?.map((item, key) => {
                    console.log("item", item);
                    return {
                        ...item,
                        item_no: key + 1,
                        idx: item?.idx,
                        content_name: item?.content_name
                    };
                }),
            );
        }
    }, [getContentList]);

    useEffect(() => {
        const fetchContent = async () => {
            if (contentIdx) {
                try {
                    const res = await getContent({
                        variables: {
                            idx: contentIdx
                        }
                    });
                    setContentList(
                        [{
                            item_no: 1,
                            idx: res?.data?.getContent?.idx,
                            content_name: res?.data?.getContent?.content_name
                        }]
                    )
                } catch (error) {
                    console.error("Error fetching content:", error);
                }
            }
        };

        fetchContent();
    }, [contentIdx]);

    useEffect(() => {
        setCh(
            contentList.map((e) => {
                const obj = {
                    idx: e.idx,
                    value: false,
                };
                return obj;
            }),
        );
    }, [contentList]);
    if (loading) {
        return <div className="wait-comment">로딩중...</div>;
    }
    if (error) {
        return <div className="wait-comment">에러가 발생했습니다.</div>;
    }

    const contentColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            width: 50,
            render: (text, record, idx) => {
                console.log("record", record);
                return <div>{pg.current * pg.pageSize + idx + 1}</div>;
            },
        },
        {
            title: 'Content Name',
            dataIndex: 'content_name',
            key: 'content_name',
            align: 'center',
            width: 400,
            sorter: (a, b) => a.group_name && a.group_name.localeCompare(b.group_name),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 50,
            render: (text, record) => {
                return (
                    <div className="table-btns">
                        <>
                            <Button size="small" onClick={() => handleContentEdit(record?.idx)}>
                                Edit
                            </Button>
                            <Button size="small" onClick={() => setShowDeleteModal(record)} className='delet-btn'>
                                Delete
                            </Button>
                        </>
                    </div>
                )
            },
        },
    ]

    const handleContentEdit = async (idx) => {
        setEditIdx(idx)
        await getContent({
            variables: {
                idx: idx
            }
        }).then((res) => {
            console.log("res", res?.data?.getContent?.content_name);
            formControl.setFieldsValue({
                name: res?.data?.getContent?.content_name
            });
            setShowModal(true)
        })
    }
    const handleSave = async () => {
        const filedVal = formControl?.getFieldValue()?.name
        if (editIdx === null) {
            await createContent({
                variables: {
                    name: filedVal
                }
            }).then((result) => {
                if (result?.data?.createContent?.idx) {
                    formControl.resetFields("")
                    setShowModal(false)
                    refetch()
                    // window.location.reload()
                }
            })
        } else {
            await updateContent({
                variables: {
                    idx: editIdx,
                    name: filedVal
                }
            }).then((res) => {
                if (res?.data?.updateContent) {
                    openNotification('콘텐츠가 성공적으로 업데이트되었습니다!');
                    formControl.resetFields("")
                    setShowModal(false)
                    refetch()
                }
            })
        }
    }
    const handleYes = async () => {
        if (showDeleteModal?.idx) {
            await deleteAIContent({
                variables: { idx: showDeleteModal?.idx },
            }).then((result) => {
                setShowDeleteModal(null);
                if (result?.error) {
                    setErrorMsg(result?.error?.deleteAITopiContent?.message);
                } else {
                    refetch();
                    openNotification('귀하의 기록이 성공적으로 삭제되었습니다!');
                }
            });
        }
    };
    return (
        <>
            {userInfoVar()?.type == 5 ?
                <>
                    <div className='speaking-tutor-wrap'>
                        <AdminTalkingTutorMenu currentMenu="content_list" />
                        <div style={{ display: 'flex', alignItems: 'baseline', gap: '16px' }}>

                            <Button style={{ marginRight: 10, marginTop: '10px' }} onClick={() => { setShowModal(true) }}>
                                Add
                            </Button>
                        </div>
                    </div>
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}></div>

            }
            <div className="level-up-library-con">
                <div className="main-inner-wrap float__box">
                    <div className="section__inner">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=" float__box">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h2>Assign Speaking tutor</h2>
                            </div>
                        </div>
                        {userInfoVar()?.type == 5 ?
                            <CustomTable
                                style={{ width: '100%' }}
                                pagination={{
                                    showSizeChanger: true,
                                    pageSize: pg.pageSize,
                                    current: pg.current + 1,
                                    total: data?.aiTopicsList?.total || 0,
                                    position: ['bottomCenter'],
                                }}
                                dataSource={contentList}
                                columns={contentColumns}
                                loading={loading}
                                bordered
                                scroll={{ y: 'calc(100vh - 274px)' }}
                                onChange={(pagination) => {
                                    setPg({
                                        current: pagination.current - 1,
                                        pageSize: pagination.pageSize,
                                    });
                                }}
                                size="small"
                                color="#edf3fb"
                            />
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
            {
                (showModal) && (
                    <Modal
                        className="instruction-modal password-modal"
                        visible={showModal}
                        style={{ top: '40%', width: '600px' }}
                        closable={true}
                        footer={null}
                        onCancel={() => (setShowModal(false), formControl.resetFields(""), setShowError(false))}
                        title={editIdx === null ? "Enter Content Name" : "Edit Content Name"}
                    >
                        <Form

                            layout="vertical"
                            form={formControl}
                            onFinish={handleSave}
                        >
                            <div className='instruction-btn-wrap'>
                                <Form.Item name="name" rules={[{ required: true, message: 'Please Enter Content Name' }]} style={{ marginBottom: 5, width: '100%' }}>
                                    <Input name="name" style={{ marginBottom: '10px' }} />
                                </Form.Item>
                                <Button htmlType="button" onClick={() => formControl.submit()}>
                                    <img src="/images/talking-tutor/save.png" alt="save-btn" />
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                )
            }
            <Modal
                visible={showDeleteModal}
                width={400}
                onCancel={() => {
                    setShowDeleteModal(null);
                }}
                className="restart-task-modal"
            >
                <h3>Are you sure you want to delete this record ?</h3>

                <div className="modal-footer">
                    <Button
                        onClick={() => {
                            setShowDeleteModal(null);
                        }}
                        className="ant-btn ant-btn-default"
                    >
                        <span>No</span>
                    </Button>
                    <Button onClick={handleYes} className="ant-btn btn-primary">
                        <span>Yes</span>
                    </Button>
                </div>
            </Modal>
            <Modal
                visible={errorMsg}
                width={400}
                onCancel={() => {
                    setErrorMsg(null);
                }}
                className="restart-task-modal"
            >
                <h3>{errorMsg}</h3>

                <div className="modal-footer">
                    <Button
                        onClick={() => {
                            setErrorMsg(null);
                        }}
                        className="ant-btn btn-primary"
                    >
                        <span>Ok</span>
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default withRouter(AiContentList);