import React, {useState, useEffect} from "react";
import Keyboard from "./Keyboard";
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
// import {DoubleScripter} from "./Scripter";
// import SwitchButtonBox from "./SwitchButtonBox";

const SwitchArea = ({onWriteEnd, numberOfEditer, steps, onScriptLoaded}) => {
	//여기서는 number가 항상 2다 1인 경우도 없다. 확장성 고려해서 했을 것 같은데 필요없어졌다.
	const [error, setError] = useState(false);
	const number = numberOfEditer || 2;
	// const [device, setDevice] = useState(false);
	// const handleToggle = () => {
	// 	setDevice(prev => !prev);
	// };

	// useEffect(() => {
	// 	if (error) {
	// 		setDevice(false);
	// 	}
	// }, [error]);

	// useEffect(() => {
	// 	setDevice(true);
	// }, [number]);

	return (
		<SwitchWrap className="pen_keyboard_wrap">
			{/* {error ? <SwitchButtonBox toggleDevice={handleToggle} device={false} /> : <SwitchButtonBox toggleDevice={handleToggle} device={true} />} */}
			{/* <DoubleScripter steps={steps} onWriteEnd={onWriteEnd} onLoaded={onScriptLoaded} isShow={device} onError={() => setError(true)} /> */}
			<Keyboard onWriteEnd={onWriteEnd}/>
		</SwitchWrap>
	);
};

export default SwitchArea;

export const useSwitchArea = (initWord = "") => {
	const [word, setWord] = useState(initWord);
	const onWriteEnd = a => {
		setWord(a.join(""));
		setTimeout(() => {
			setWord("");
		}, 500);
	};
	return {word, setWord, onWriteEnd};
};

const SwitchWrap = styled.div`
    position: absolute;
    margin: auto;
`;