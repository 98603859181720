import React, { useRef, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import { GET_NEWSOMATIC_TOKEN } from 'src/operations/queries/getUser';

function NewsOMaticStudyButton({ setData, visible, data, visible_assign }) {
  const buttonRef = useRef();
  const { data: tokenData } = useQuery(GET_NEWSOMATIC_TOKEN, {
    fetchPolicy: 'no-cache',
    variables: { article_id: `${data?.article_id}` || null, assigned_idx: data?.assigned_idx || null, editionId: data?.editionId || null },
    // variables: { article_id: `${data?.article_id}` || null, assigned_idx: data?.assigned_idx || null , editionId: "2023/01/05"},
    // skip: !data?.article_id,
  });

  const token = useMemo(() => {
    return window.localStorage.getItem('culp_token');
  }, []);

  useEffect(() => {
    if (visible) {
      if (tokenData?.getNewsomaticToken && visible && buttonRef.current) {
        buttonRef.current.click();
        setData(null);
      }
    } else {
      if (tokenData?.getNewsomaticToken && visible_assign && buttonRef.current) {
        buttonRef.current.click();
        setData(null);
      }
    }
  }, [tokenData, visible, visible_assign]);

  const getLevel = (level) => {
    if (!level) return null;
    return level % 3 === 0 ? 3 : level % 3;
  };

  const getType = (user_type) => {
    switch (user_type) {
      case '0':
        return 0;
      case '1':
        return 1;
      default:
        return 2;
    }
  };

  return (
    <>
      {visible ? (
        <>
          <div style={{ background: 'transparent', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <form target="_blank" style={{ width: '100%', height: '100%' }} action="https://app.newsomatic.net/sso/cloudbot" method="post">
              <input name="signature" type="hidden" value={tokenData?.getNewsomaticToken?.signature} />
              <input name="token" type="hidden" value={token} />
              <input name="user_email" type="hidden" value={tokenData?.getNewsomaticToken?.user_email} />
              <input name="user_id" type="hidden" value={tokenData?.getNewsomaticToken?.user_id} />
              <input name="user_name" type="hidden" value={tokenData?.getNewsomaticToken?.user_name} />
              <input name="user_phone" type="hidden" value={tokenData?.getNewsomaticToken?.user_phone} />
              <input name="user_type" type="hidden" value={getType(tokenData?.getNewsomaticToken?.user_type)} />
              <input name="level" type="hidden" value={getLevel(parseInt(tokenData?.getNewsomaticToken?.level))} />
              <input name="nounce" type="hidden" value={tokenData?.getNewsomaticToken?.nounce} />
              {/* <input name="class_id" type="hidden" value={tokenData?.getNewsomaticToken?.class_id || null} /> */}
              <input
                name="classes"
                type="hidden"
                value={tokenData?.getNewsomaticToken?.classes ? JSON.stringify(tokenData?.getNewsomaticToken?.classes) : null}
              />
              <input name="campus_id" type="hidden" value={tokenData?.getNewsomaticToken?.campus_id || null} />
              <input name="campus_name" type="hidden" value={tokenData?.getNewsomaticToken?.campus_name || null} />
              <input name="article_id" type="hidden" value={tokenData?.getNewsomaticToken?.article_id || null} />
              <input name="assigned_idx" type="hidden" value={tokenData?.getNewsomaticToken?.assigned_idx || null} />
              <button ref={buttonRef} type="submit" style={{ display: 'none' }} />
            </form>
            <Spin size="large" />
          </div>
        </>
      ) : visible_assign ? (
        <>
          <div style={{ background: 'transparent', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <form target="_blank" style={{ width: '100%', height: '100%' }} action="https://app.newsomatic.net/sso/cloudbot" method="post">
              {/* <form target="_blank" style={{ width: '100%', height: '100%' }} action={`https://app.newsomatic.net/edition/${tokenData?.getNewsomaticToken?.editionId}/en`} method="post"> */}
              <input name="signature" type="hidden" value={tokenData?.getNewsomaticToken?.signature} />
              <input name="token" type="hidden" value={token} />
              <input name="user_email" type="hidden" value={tokenData?.getNewsomaticToken?.user_email} />
              <input name="user_id" type="hidden" value={tokenData?.getNewsomaticToken?.user_id} />
              <input name="user_name" type="hidden" value={tokenData?.getNewsomaticToken?.user_name} />
              <input name="user_phone" type="hidden" value={tokenData?.getNewsomaticToken?.user_phone} />
              <input name="user_type" type="hidden" value={getType(tokenData?.getNewsomaticToken?.user_type)} />
              <input name="level" type="hidden" value={getLevel(parseInt(tokenData?.getNewsomaticToken?.level))} />
              <input name="nounce" type="hidden" value={tokenData?.getNewsomaticToken?.nounce} />
              {/* <input name="class_id" type="hidden" value={tokenData?.getNewsomaticToken?.class_id || null} /> */}
              <input
                name="classes"
                type="hidden"
                value={tokenData?.getNewsomaticToken?.classes ? JSON.stringify(tokenData?.getNewsomaticToken?.classes) : null}
              />
              <input name="campus_id" type="hidden" value={tokenData?.getNewsomaticToken?.campus_id || null} />
              <input name="campus_name" type="hidden" value={tokenData?.getNewsomaticToken?.campus_name || null} />
              <input name="edition_date" type="hidden" value={tokenData?.getNewsomaticToken?.editionId || null} />
              {/* <input name="article_id" type="hidden" value={tokenData?.getNewsomaticToken?.article_id || null} />
            <input name="assigned_idx" type="hidden" value={tokenData?.getNewsomaticToken?.assigned_idx || null} /> */}
              <button ref={buttonRef} type="submit" style={{ display: 'none' }} />
            </form>
            <Spin size="large" />
          </div>
        </>
      ) : (
        ''
      )}
    </>
    //     {visible && (
    // <div style={{ background: 'transparent', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
    //   <form target="_blank" style={{ width: '100%', height: '100%' }} action="https://app.newsomatic.net/sso/cloudbot" method="post">
    //     <input name="signature" type="hidden" value={tokenData?.getNewsomaticToken?.signature} />
    //     <input name="token" type="hidden" value={token} />
    //     <input name="user_email" type="hidden" value={tokenData?.getNewsomaticToken?.user_email} />
    //     <input name="user_id" type="hidden" value={tokenData?.getNewsomaticToken?.user_id} />
    //     <input name="user_name" type="hidden" value={tokenData?.getNewsomaticToken?.user_name} />
    //     <input name="user_phone" type="hidden" value={tokenData?.getNewsomaticToken?.user_phone} />
    //     <input name="user_type" type="hidden" value={getType(tokenData?.getNewsomaticToken?.user_type)} />
    //     <input name="level" type="hidden" value={getLevel(parseInt(tokenData?.getNewsomaticToken?.level))} />
    //     <input name="nounce" type="hidden" value={tokenData?.getNewsomaticToken?.nounce} />
    //     {/* <input name="class_id" type="hidden" value={tokenData?.getNewsomaticToken?.class_id || null} /> */}
    //     <input
    //       name="classes"
    //       type="hidden"
    //       value={tokenData?.getNewsomaticToken?.classes ? JSON.stringify(tokenData?.getNewsomaticToken?.classes) : null}
    //     />
    //     <input name="campus_id" type="hidden" value={tokenData?.getNewsomaticToken?.campus_id || null} />
    //     <input name="campus_name" type="hidden" value={tokenData?.getNewsomaticToken?.campus_name || null} />
    //     <input name="article_id" type="hidden" value={tokenData?.getNewsomaticToken?.article_id || null} />
    //     <input name="assigned_idx" type="hidden" value={tokenData?.getNewsomaticToken?.assigned_idx || null} />
    //     <button ref={buttonRef} type="submit" style={{ display: 'none' }} />
    //   </form>
    //   <Spin size="large" />
    // </div>
    //     )}
    // );
    // }
  );
}
export default NewsOMaticStudyButton;
