import React from 'react';
import { Button } from 'antd';
import StatusButton from './StatusButton';
import { DELIVERY_STATUS_STATUS_TEXT_LIST } from 'src/constants/common';
import InvoiceInputPopover from './InvoiceInputPopover';
import { koreanNumberFormat } from 'src/utils';

export const columns = [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '주문 날짜',
    dataIndex: 'idate',
    key: 'idate',
  },
  {
    title: '학원명',
    dataIndex: 'campus_name',
    key: 'campus_name',
  },
  {
    title: '결제 날짜',
    dataIndex: 'pay_date',
    key: 'pay_date',
  },
  {
    title: '총 구매 수',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: '결제상태',
    dataIndex: 'pay_status',
    key: 'pay_status',
  },
  {
    title: '구매 금액',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => koreanNumberFormat(text),
  },
  {
    title: '내역보기',
    dataIndex: 'view',
    key: 'view',
    className: 'taglistcolumn',
    render: (_, { onViewInvoice, invoice_info, delivery_status }) => (
      <StatusButton
        delivery_status={delivery_status}
        onClick={() => {
          onViewInvoice(invoice_info);
        }}
      >
        {DELIVERY_STATUS_STATUS_TEXT_LIST[delivery_status]}
      </StatusButton>
    ),
  },
  {
    title: '송장번호입력/수정',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (_, { invoice_info, loadingUpdateInvoiceNumber, handleUpdateInvoiceNumber }) => {
      console.log('invoice_info', invoice_info);
      const { order_payment } = invoice_info;
      return (
        <InvoiceInputPopover
          invoice_number={order_payment?.invoice_number}
          order_payment_idx={order_payment?.idx}
          company_code={order_payment?.delivery_company_info?.company_code || ''}
          onUpdateInvoice={handleUpdateInvoiceNumber}
          loadingUpdateInvoiceNumber={loadingUpdateInvoiceNumber}
        >
          <Button type="primary" size="small">
            입력/수정
          </Button>
        </InvoiceInputPopover>
      );
    },
  },
];

export const columnsFoEnglishTap = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '주문 날짜',
    dataIndex: 'idate',
    key: 'idate',
    width: '100px',
  },
  {
    title: '소속지사',
    dataIndex: 'branch',
    key: 'branch',
  },
  {
    title: '신청학원',
    dataIndex: 'campus_name',
    key: 'campus_name',
  },
  {
    title: '강사',
    dataIndex: 'buyer_name',
    key: 'buyer_name',
  },
  {
    title: '주문상품',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: '권수',
    dataIndex: 'count',
    key: 'count',
  },

  {
    title: '주문 금액',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => koreanNumberFormat(text),
  },

  {
    title: '내역보기',
    dataIndex: 'view',
    key: 'view',
    className: 'taglistcolumn',
    render: (_, { onViewInvoice, invoice_info, delivery_status }) => (
      <StatusButton
        delivery_status={delivery_status}
        onClick={() => {
          onViewInvoice(invoice_info);
        }}
      >
        {DELIVERY_STATUS_STATUS_TEXT_LIST[delivery_status]}
      </StatusButton>
    ),
  },
  {
    title: '송장번호입력/수정',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (_, { invoice_info, loadingUpdateInvoiceNumber, handleUpdateInvoiceNumber }) => {
      console.log('invoice_info', invoice_info);
      const { order_payment } = invoice_info;
      return (
        <InvoiceInputPopover
          invoice_number={order_payment?.invoice_number}
          order_payment_idx={order_payment?.idx}
          company_code={order_payment?.delivery_company_info?.company_code || ''}
          onUpdateInvoice={handleUpdateInvoiceNumber}
          loadingUpdateInvoiceNumber={loadingUpdateInvoiceNumber}
        >
          <Button type="primary" size="small">
            입력/수정
          </Button>
        </InvoiceInputPopover>
      );
    },
  },
];
export const columnsForSmartEclass = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '주문 날짜',
    dataIndex: 'idate',
    key: 'idate',
    width: '100px',
  },
  {
    title: '소속지사',
    dataIndex: 'branch',
    key: 'branch',
  },
  {
    title: '신청학교',
    dataIndex: 'campus_name',
    key: 'campus_name',
  },
  {
    title: '강사',
    dataIndex: 'buyer_name',
    key: 'buyer_name',
  },
  {
    title: '주문상품',
    dataIndex: 'product_name',
    key: 'product_name',
  },

  // {
  //   title: '총 구매 수',
  //   dataIndex: 'count',
  //   key: 'count',
  // },

  {
    title: '수량(학생용)',
    dataIndex: 'order_student_count',
    key: 'order_student_count',
  },

  {
    title: '수량(교사용)',
    dataIndex: 'order_teacher_count',
    key: 'order_teacher_count',
  },

  {
    title: '내역보기',
    dataIndex: 'view',
    key: 'view',
    className: 'taglistcolumn',
    render: (_, { onViewInvoice, invoice_info, delivery_status }) => (
      <StatusButton
        delivery_status={delivery_status}
        onClick={() => {
          onViewInvoice(invoice_info);
        }}
      >
        {DELIVERY_STATUS_STATUS_TEXT_LIST[delivery_status]}
      </StatusButton>
    ),
  },
  {
    title: '송장번호입력/수정',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (_, { invoice_info, loadingUpdateInvoiceNumber, handleUpdateInvoiceNumber }) => {
      console.log('invoice_info', invoice_info);
      const { order_payment } = invoice_info;
      return (
        <InvoiceInputPopover
          invoice_number={order_payment?.invoice_number}
          order_payment_idx={order_payment?.idx}
          company_code={order_payment?.delivery_company_info?.company_code || ''}
          onUpdateInvoice={handleUpdateInvoiceNumber}
          loadingUpdateInvoiceNumber={loadingUpdateInvoiceNumber}
        >
          <Button type="primary" size="small">
            입력/수정
          </Button>
        </InvoiceInputPopover>
      );
    },
  },
];

export const columnsForBranch = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '주문 날짜',
    dataIndex: 'idate',
    key: 'idate',
    width: '100px',
  },
  {
    title: '주문상품',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: '강사',
    dataIndex: 'buyer_name',
    key: 'buyer_name',
  },

  // {
  //   title: '총 구매 수',
  //   dataIndex: 'count',
  //   key: 'count',
  // },
  {
    title: '수량(학생용)',
    dataIndex: 'order_student_count',
    key: 'order_student_count',
  },

  {
    title: '수량(교사용)',
    dataIndex: 'order_teacher_count',
    key: 'order_teacher_count',
  },
  {
    title: '신청학교',
    dataIndex: 'campus_name',
    key: 'campus_name',
  },

  {
    title: '내역보기',
    dataIndex: 'view',
    key: 'view',
    className: 'taglistcolumn',
    render: (_, { onViewInvoice, invoice_info, delivery_status }) => (
      <StatusButton
        delivery_status={delivery_status}
        onClick={() => {
          onViewInvoice(invoice_info);
        }}
      >
        {DELIVERY_STATUS_STATUS_TEXT_LIST[delivery_status]}
      </StatusButton>
    ),
  },
];
export const columnsForBranchEnglishTap = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '주문 날짜',
    dataIndex: 'idate',
    key: 'idate',
    width: '100px',
  },
  {
    title: '주문상품',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: '강사',
    dataIndex: 'buyer_name',
    key: 'buyer_name',
  },

  {
    title: '권수',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: '신청학원',
    dataIndex: 'campus_name',
    key: 'campus_name',
  },

  {
    title: '내역보기',
    dataIndex: 'view',
    key: 'view',
    className: 'taglistcolumn',
    render: (_, { onViewInvoice, invoice_info, delivery_status }) => (
      <StatusButton
        delivery_status={delivery_status}
        onClick={() => {
          onViewInvoice(invoice_info);
        }}
      >
        {DELIVERY_STATUS_STATUS_TEXT_LIST[delivery_status]}
      </StatusButton>
    ),
  },
];
