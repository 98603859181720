import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { HeartOutlined, BookOutlined, CheckCircleOutlined, BarChartOutlined } from '@ant-design/icons';
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const MenuButton = styled.div`
  height: 50px;
  width: 24%;
  max-width: 360px;
  min-width: 250px;
  font-size: 30px;
  cursor: pointer;
  background-color: ${(props) => (props.current ? '#fff' : '#4d4c4c')};
  color: ${(props) => (props.current ? '#6f4e34' : '#d1b8a3')};
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  border-radius: 13px 13px 0 0;
  @media (max-width: 1280px) {
    font-size: 24px;
  }
  @media (max-width: 1200px) {
    font-size: 20px;
    min-width: 200px;
  }
`;
export default function LevelUpLibraryMenuGroup({ currentMenu, student_idx = null }) {
  const history = useHistory();
  return (
    <RowFlex style={student_idx ? { justifyContent: 'center' } : {}}>
      {!student_idx && (
        <>
          <MenuButton
            onClick={() => {
              history.push('/level-up/favorite');
            }}
            current={currentMenu === 'favorite'}
          >
            <span style={{ fontSize: '30px' }}>
              <HeartOutlined />
            </span>
            &nbsp;Favorite
          </MenuButton>
          <MenuButton
            onClick={() => {
              history.push('/level-up/inprogress');
            }}
            current={currentMenu === 'in_progress'}
          >
            <span style={{ fontSize: '30px' }}>
              <BookOutlined />
            </span>
            &nbsp;In Progress
          </MenuButton>
        </>
      )}
      <MenuButton
        onClick={() => {
          history.push(`/level-up/portfolio${student_idx ? `?student_idx=${student_idx}` : ''}`);
        }}
        current={currentMenu === 'portfolio'}
      >
        <span style={{ fontSize: '30px' }}>
          <CheckCircleOutlined />
        </span>
        &nbsp;Portfolio
      </MenuButton>
      <MenuButton
        onClick={() => {
          history.push(`/level-up/calendar${student_idx ? `?student_idx=${student_idx}` : ''}`);
        }}
        current={currentMenu === 'calendar'}
      >
        <span style={{ fontSize: '30px' }}>
          <BarChartOutlined />
        </span>
        &nbsp;Pts & Calendar
      </MenuButton>
    </RowFlex>
  );
}
