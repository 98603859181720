import React from 'react';
import { Table } from 'antd';

const SmsChargeHistory = ({ data, loading }) => {
  return <Table dataSource={data} loading={loading} columns={chargeCol} className="SmsChargeHistory_table" scroll={{ x: 240 }} />;
};
export default SmsChargeHistory;

const chargeCol = [
  {
    title: ' ',
    dataIndex: 'Num',
    key: 'Num',
  },
  {
    title: '충전 날짜',
    dataIndex: 'sentDate',
    key: 'sentDate',
  },
  // {
  //   title: '시간',
  //   dataIndex: 'sentTime',
  //   key: 'sentTime',
  // },
  {
    title: '금액',
    dataIndex: 'paidNum',
    key: 'paidNum',
  },
  // {
  //   title: '충전 형태',
  //   dataIndex: 'charge',
  //   key: 'charge',
  // },
  {
    title: '결제자',
    dataIndex: 'paidUser',
    key: 'paidUser',
  },
  {
    title: '비고',
    dataIndex: 'etc',
    key: 'etc',
  },
];
