import React, { useState, useEffect, useMemo } from 'react';
import { Col, Typography, Space, Row, Button, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter, useHistory } from 'react-router';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import { LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { useQuery, useMutation } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import BackButton from 'src/components/common/BackButton';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import BookReportAnswerModal from 'src/components/Modal/BookReportAnswerModal';
import GeneratePdfButton from 'src/components/common/GeneratePdfButton';
import queryString from 'query-string';
import moment from 'moment';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const DetailsByDate = (props) => {
  const { idx } = useParams();
  const { code } = queryString.parse(window.location.search.replace('?', ''));
  const [ai_topic_user_assignments, setAssignedAITopics] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [currentChatList, setCurrentChatList] = useState([]);
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      page: 0,
    },
    'ai_date_details',
  );

  const [levelUpUpdateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(
    LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT,
    {
      onError(error) {
        console.log('error', error);
      },
    },
  );
  const {
    data: aiTopicDataList,
    loading,
    refetch,
  } = useQuery(LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS, {
    variables: {
      external_study_idx: parseInt(idx),
    },
    fetchPolicy: 'no-cache',
  });
  const aiAssigmentList = useMemo(() => {
    if (aiTopicDataList?.levelUpAiTopicUserAssignmentDetails) {
      const topicData = aiTopicDataList?.levelUpAiTopicUserAssignmentDetails;
      const obj = {
        no: 1,
        start_date: topicData?.study_date,
        user: topicData?.user?.name,
        topic:
          topicData?.external_study_data?.external_study_data &&
          JSON.parse(topicData?.external_study_data?.external_study_data).revisedPrompt
            ? JSON.parse(topicData?.external_study_data?.external_study_data).revisedPrompt
            : topicData?.aiTopic[0]?.topic,
        data: topicData?.data !== null ? JSON.parse(topicData?.data) : {},
        request_response: topicData?.ai_request_response,
        idx: topicData?.idx,
        idate: topicData?.idate,
        // data:{"start_time":{"set":"2023-10-17T12:01:25.000Z"},"end_time":{"set":"2023-10-17T12:03:06.000Z"},"status":2,"wpm":28,"recording_data":"<p><span style=\"color:brown; text-decoration:line-through;\">fgfcg</span></p>"}
      };
      setSelectedDate(topicData?.study_date?.split('T')[0]);
      return [obj];
    }
    return [];
  }, [aiTopicDataList]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    {
      title: 'A. Date',
      dataIndex: 'start_date',
      align: 'center',
      key: 'date',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{moment(record?.start_date).format('YYYY-MM-DD')}</Space>;
      },
    },
    {
      title: 'Stu_Name(id)',
      dataIndex: 'user',
      key: 'user',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record?.user}</Space>;
      },
    },
    {
      title: 'Topic/Prompt',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <Tooltip placement="topLeft" color="#fff" title={record?.topic} size="middle">
            {record?.topic}
          </Tooltip>
        );
      },
    },
    // {
    //   title: 'Wpm',
    //   key: '',
    //   width: 100,
    //   render: (text, record) => {
    //     const recodingData = record?.data?.recording_data ? JSON.parse(record?.data?.recording_data) : {};
    //     return <Space size="middle">{recodingData?.wpm !== null ? recodingData.wpm : '-'}</Space>;
    //   },
    // },
    {
      title: 'Rubric',
      key: '',
      width: 100,
      render: (text, record) => {
        const rubric_data = record?.data?.rubric_data ? JSON.parse(record?.data?.rubric_data) : {};
        // if (record?.data?.status < 1 || Object.keys(record?.data).length === 0) {
        if (
          record?.data?.status < 1 ||
          (record?.data !== null && typeof record?.data === 'object' && Object.keys(record.data).length === 0)
        ) {
          return <Space size="middle">-</Space>;
        }

        if (rubric_data?.pdf_url || rubric_data?.pdf_url_teacher) {
          return <Space size="middle">{rubric_data?.score}</Space>;
        }
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                props.history.push('/level-up/results/rubric/' + record?.idx);
              }}
            >
              {rubric_data?.score || '-'}:Edit
            </Button>
          </Space>
        );
      },
    },
    {
      title: 'Original',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record?.data?.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record?.topic);
                  setTopicAnswer(record?.data?.answer);
                  setCurrentChatList(
                    record.request_response
                      .filter(
                        (item) =>
                          JSON.parse(item?.data).type === 'check' ||
                          JSON.parse(item?.data).type === 'comment' ||
                          JSON.parse(item?.data).type === 'revise',
                      )
                      .map((item) => {
                        return {
                          ...item,
                          content: JSON.parse(item?.data).currentText,
                        };
                      }),
                  );

                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Check',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record?.data?.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record?.topic);
                  setTopicAnswer(record?.data?.answer);
                  setCurrentChatList(record?.request_response.filter((item) => JSON.parse(item?.data).type === 'check'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Feedback',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record?.data?.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record?.topic);
                  setTopicAnswer(record?.data?.answer);
                  setCurrentChatList(record?.request_response.filter((item) => JSON.parse(item?.data).type === 'comment'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Rewrite',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record?.data?.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record?.topic);
                  setTopicAnswer(record.data?.answer);
                  setCurrentChatList([
                    {
                      content: record?.data?.answer,
                      prompt: record?.data?.answer,
                      idate: record?.idate?.split('T')[0],
                      // idate: record?.study_date?.split('.')[0],
                      data: '{}',
                    },
                  ]);
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Acc/WPM',
      key: 'wpm',
      dataIndex: 'wpm',
      width: 100,
      render: (text, record) => {
        const recordingData = record?.data?.recording_data ? JSON.parse(record?.data?.recording_data) : {};
        if (record?.data?.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => openRecordingDataModal({ ...recordingData, accuracy: `${recordingData?.score}%` }, record?.user)}>
                {recordingData?.score !== undefined ? recordingData.score : '-'}/
                {recordingData?.wpm !== undefined ? recordingData.wpm : '-'}
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Std.PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        if (!record?.data?.status) {
          return <span>-</span>;
        } else {
          return (
            <Space size="middle">
              <GeneratePdfButton
                saveData={async (url) => {
                  await handleGeneratePdf(record.idx, { ...JSON.parse(record?.data?.rubric_data || '{}'), pdf_url: url });
                }}
                url={`https://culp.cloubot.com/level-up/report/generate/${record.idx}?pdfType=student`}
                pdfUrl={JSON.parse(record?.data?.rubric_data || '{}')?.pdf_url}
                filename={`-level-up-report-result-${record.idx}`}
                isEditUrl={`/level-up/report/generate/${record.idx}?pdfType=student`}
              />
            </Space>
          );
        }
      },
    },
    {
      title: 'T.PDF',
      key: 'user',
      dataIndex: 'user',
      width: 90,
      align: 'center',
      render: (text, record) => {
        console.log('record', record);
        if (!record?.data?.status) {
          return <span>-</span>;
        } else {
          let commentFull = record?.request_response?.filter((item) => JSON.parse(item?.data)?.type === 'comment-full');
          return (
            <Space size="middle">
              <GeneratePdfButton
                saveData={async (url) => {
                  await handleGeneratePdf(record.idx, { ...JSON.parse(record?.data?.rubric_data || '{}'), pdf_url_teacher: url });
                }}
                url={`https://culp.cloubot.com/level-up/report/generate/${record.idx}?pdfType=teacher&type=${
                  commentFull.length > 0 ? 'comment-full' : 'comment'
                }`}
                pdfUrl={JSON.parse(record?.data?.rubric_data || '{}')?.pdf_url_teacher}
                filename={`-level-up-report-result-full-${record.idx}`}
                isEditUrl={`/level-up/report/generate/${record.idx}?pdfType=teacher&type=${
                  commentFull.length > 0 ? 'comment-full' : 'comment'
                }`}
              />
            </Space>
          );
        }
      },
    },
  ];

  const handleGeneratePdf = async (idx, data) => {
    await levelUpUpdateAITopicUserAssignment({
      variables: {
        idx,
        rubric_data: JSON.stringify(data),
      },
    });
    refetch();
  };

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>Book Report Assignment Details By Date ({selectedDate})</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <div style={{ margin: 'auto 0' }}>
                <BackButton />
              </div>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            // dataSource={ai_topic_user_assignments}
            dataSource={aiAssigmentList}
            // sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>
        {viewAnswerModal && (
          <BookReportAnswerModal
            visible={viewAnswerModal}
            chatList={currentChatList}
            handleCancel={() => {
              setViewAnswerModal(false);
            }}
            topic={topic}
          />
        )}
        <RecordingDataModal
          data={{ ...recordingData, currentUserName, className: '' }}
          isShow={isRecordingVisible}
          onPrint={null}
          componentRef={null}
          onOk={handleOkForRecordingData}
          hidePrint={true}
        />
      </Col>
    </>
  );
};

export default withRouter(DetailsByDate);
