import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button } from 'antd';
import { useQuery, useMutation } from '@apollo/client';

import TableTransfer from '../common/TableTransfer';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { openNotification } from '../common/Notification';

function GoodsModal({ visible, handleCancel, myBooks, fcInfo }) {
  const [books, setBooks] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [direction, setDirection] = useState([]);
  const fcBooks = myBooks.map((book) => book.code);

  const { data: fetchBooks } = useQuery(queries.book.GET_BOOK_LIST);
  const [targetGoods, {loading} ] = useMutation(mutations.company.CREATE_COMPANY_BOOK_DISPLAY_INFO, {
    update(proxy, result) {
      proxy.writeQuery({
        query: queries.company.GET_COMPANY_BOOK_DISPLAY_LIST,
        variables: {
          company_idx: fcInfo.idx,
        },
        data: {
          getCompanyBookDisplayList: [...result.data.createCompanyBookDisplayInfo],
        },
      });
    },
    onCompleted(data) {
      if (data) {
        openNotification('추가 완료!');
        handleCancel(true)
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (fetchBooks?.bookList) {
      const booksFilter = fetchBooks?.bookList.filter((book) => !book.parent_code);

      setBooks(
        booksFilter.reduce((acc, cur, index) => {
          const obj = {
            key: cur.code,
            code: cur.code,
            levelName: cur.level_name,
            volumeName: cur.volume_name,
          };

          return [...acc, obj];
        }, []),
      );

      setTargetKeys(fetchBooks?.bookList.filter((book) => !book.parent_code && fcBooks.includes(book.code)).map((item) => item.code));
    }
  }, [fetchBooks]);

  const onChange = (targetKeys, direction, moveKeys) => {
    setTargetKeys(targetKeys);
    setSelectKeys(targetKeys);
    setDirection(direction);
  };

  const handleStudentSubmit = () => {
    if (!direction || !selectKeys.length) {
      Modal.warning({
        title: '상품을 선택해 주세요.',
        okText: '확인',
      });
      return;
    }

    targetGoods({
      variables: {
        fc_company_idx: fcInfo?.idx,
        goods: targetKeys,
      },
    });

    setSelectKeys([]);
  };

  return (
    <>
      <Modal
        entered
        title={`${fcInfo.name} 상품리스트 관리`}
        visible={visible}
        onCancel={handleCancel}
        okText="확인"
        cancelText="취소"
        width={'90%'}
        style={{ top: 20 }}
        footer={[
          <Button key="button" loading={loading} onClick={handleStudentSubmit} type="primary">
            수정
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <TableTransfer
              titles={['전체 상품', '사용중인 상품']}
              dataSource={books}
              showSearch={true}
              targetKeys={targetKeys}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
              pagination={false}
              onChange={onChange}
              filterOption={(inputValue, item) => item.code.indexOf(inputValue) !== -1}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const leftTableColumns = [
  {
    key: 'code',
    dataIndex: 'code',
    title: '교재명',
  },
  {
    key: 'levelName',
    dataIndex: 'levelName',
    title: 'Unit',
  },
  {
    key: 'volumeName',
    dataIndex: 'volumeName',
    title: 'Lesson',
  },
];

const rightTableColumns = [
  {
    key: 'code',
    dataIndex: 'code',
    title: '교재명',
  },
  {
    key: 'levelName',
    dataIndex: 'levelName',
    title: 'Unit',
  },
  {
    key: 'volumeName',
    dataIndex: 'volumeName',
    title: 'Lesson',
  },
];

GoodsModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  myBooks: PropTypes.array,
  fcInfo: PropTypes.objectOf.isRequired,
};

export default GoodsModal;
