import React, { useState, useEffect, useRef } from 'react';
import { Col, Typography, Row, Card, Image } from 'antd';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { LeftCircleFilled, RightCircleFilled, MenuUnfoldOutlined, MenuFoldOutlined, CheckCircleOutlined } from '@ant-design/icons';
import 'react-simple-keyboard/build/css/index.css';
import ReactPlayer from 'react-player';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 15px;
  margin-top: 10px;
  background: #fff;
  height: calc(100vh - 94px);
`;

const TextBox = styled.p`
  font-size: 50px;
  margin-bottom: 0;
  @media (max-height: 700px) {
    font-size: 40px;
  }
`;

const TotalBox = styled.span`
  color: rgb(255, 122, 27);
  border: 1px solid #6b5c5c;
  padding: 5px 10px;
  border-radius: 5px;
`;

const VocaPage = ({ gotoNext, data = [] }) => {
  const [total, setTotal] = useState(data.length);
  const [score, setScore] = useState(0);
  const [done, setDone] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [indx, setIndx] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [ans, setAns] = useState('');
  const [ques, setQues] = useState('');

  useEffect(() => {
    if (data && data[done]) {
      setQues(data[done]['eng']);
      setAns(data[done]['kor']);
      setFlipped(false);
      setPlaying(true);
    }
  }, [done]);

  const handleClickMain = () => {
    if (flipped === false) {
      setFlipped(true);
      setTimeout(function () {
        setFlipped(false);
        if (indx < 2) {
          setIndx(indx + 1);
          setPlaying(true);
        } else {
          if (done < data.length - 1) {
            setIndx(0);
            setDone(done + 1);
          } else {
            if (gotoNext) {
              gotoNext({});
            }
          }
        }
      }, 1500);
    }
  };

  return (
    <>
      <Col className="voca-fluid" span={24} style={{ textAlign: 'left', margin: 0 }}>
        <MainBlock className="voca-fluid-container" onClick={() => collapsed && setCollapsed(false)}>
          <Row className="mainheadingtop" gutter={[24, 16]} style={{ padding: 10 }}>
            <Col className="mainheadingtop-menulist" span={16}>
              <Row className="mainheading-voca-container" gutter={[24, 16]} style={{ margin: 0 }}>
                <Col className="mainheading-voca" span={24} style={{ padding: 10, paddingLeft: 10 }}>
                  <Title level={3} ellipsis>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Click the card.</span>{' '}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col
              className="mainheading-voca-menu"
              span={8}
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 10 }}
            >
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                <TotalBox>
                  {done + 1} / {total}
                </TotalBox>{' '}
              </Title>
              {collapsed ? (
                <MenuFoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(false)}
                />
              ) : (
                <MenuUnfoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(true)}
                />
              )}
            </Col>
          </Row>
          <Row className="voca-blankspace" gutter={[24, 16]} style={{ padding: 10 }}>
            <Col span={5} style={{ padding: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <LeftCircleFilled
                style={{ color: '#9ec5e8', display: 'none', marginLeft: 10, fontSize: 60, cursor: 'pointer' }}
                onClick={() => setDone(done ? done - 1 : 0)}
              />
            </Col>
            <Col className="voca-word" span={14} style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Card
                className="voca-word-inner"
                style={{ width: '100%', borderRadius: 20 }}
                bodyStyle={{ background: '#9ec5e8', borderRadius: 20 }}
                onClick={handleClickMain}
                hoverable
              >
                <div className={'flip-card-container' + (flipped ? ' flipped' : '')}>
                  <div className="front">
                    <TextBox>{ques}</TextBox>
                  </div>
                  <div className="back">
                    <TextBox>{ans}</TextBox>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={5} style={{ padding: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <RightCircleFilled
                style={{ color: '#9ec5e8', display: 'none', marginLeft: 10, fontSize: 60, cursor: 'pointer' }}
                onClick={() => setDone(done < data.length - 1 ? done + 1 : data.length - 1)}
              />
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col span={24} style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
              <span style={{ color: `${indx === 0 ? '#424242' : '#898989'}`, margin: '0 3px', fontSize: 20 }}>⬤</span>
              <span style={{ color: `${indx === 1 ? '#424242' : '#898989'}`, margin: '0 3px', fontSize: 20 }}>⬤</span>
              <span style={{ color: `${indx === 2 ? '#424242' : '#898989'}`, margin: '0 3px', fontSize: 20 }}>⬤</span>
            </Col>
            <Col span={24} style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
              <Image
                height={40}
                width={40}
                style={{ cursor: 'pointer' }}
                src="/images/speak.png"
                preview={false}
                onClick={() => setPlaying(true)}
              />
              {data[done] ? (
                <ReactPlayer
                  url={`https://cdn.cloubot.com${data[done]['eng_audio']}`}
                  width="0"
                  height="0"
                  playing={playing}
                  onEnded={() => setPlaying(false)}
                />
              ) : null}
            </Col>
          </Row>
        </MainBlock>
        {collapsed ? (
          <>
            <Card
              title="Word List"
              extra={
                <MenuFoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(false)}
                />
              }
              style={{
                width: 300,
                position: 'absolute',
                right: 0,
                height: 'calc(100% - 30px)',
                backgroundColor: '#dfe3ea',
                top: 20,
                opacity: 0.85,
              }}
            >
              {data.map((d, i) => (
                <Title level={4} style={{ marginBottom: 5, color: '#333333d9' }}>
                  {`  ${i + 1}. ${d.eng}`}{' '}
                  {i <= done ? <CheckCircleOutlined style={{ color: `${done === i ? '#ec6937' : '#09b309'}` }} /> : null}
                </Title>
              ))}
            </Card>
          </>
        ) : null}
      </Col>
    </>
  );
};

export default VocaPage;
