import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLoginTo } from 'src/utils/hooks';
import { userInfoVar, userVar } from 'src/apollo/cache';

const BarBlock = styled.div``;

const VIEW_TEXT = {
  0: '학생화면보기 실행중',
  1: '강사화면보기 실행중',
  2: '원장화면보기 실행중',
};

const ReturnToManagerBar = ({ returnId }) => {
  const [isOver, setIsOver] = useState(false);
  const loginTo = useLoginTo(true);
  const type = userInfoVar()?.type;
  const viewText = useMemo(() => {
    if (type) {
      return VIEW_TEXT[type] || '보기 실행중';
    }
    return '보기 실행중';
  }, [type]);

  return (
    <BarBlock
      className="backbutton-pv"
      style={{ backgroundColor: `${isOver ? '#bebebe' : '#ff922b'}` }}
      onClick={() => {
        loginTo(returnId);
      }}
      onMouseEnter={() => {
        setIsOver(true);
      }}
      onMouseLeave={() => {
        setIsOver(false);
      }}
    >
      {isOver ? '끝내기' : viewText}
    </BarBlock>
  );
};

export default ReturnToManagerBar;
