import React, {useState, useEffect } from 'react';
// import { PlayCircleFilled, PauseCircleOutlined } from '@ant-design/icons';
// import moment from 'moment';
import styled from 'styled-components';
import {  useQuery } from '@apollo/client';
// import ReactPlayer from 'react-player';
// import { SAVE_TEST_ANSWERS } from 'src/operations/mutations/createTests';
import { useHistory, Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import useSound from 'use-sound';
import clipGuide from 'src/sounds/meta/2.mp3';
import { GET_USER_INFO } from 'src/operations/queries/campus';

const InnerWrap = styled.div`
  background-color: #fff;
  width: calc(95% - 23rem);
  height: calc(100vh - 17rem);
  padding: 2rem 1rem;
  margin: 0rem auto;
  border-radius: 0px;
  min-width: 750px;
  max-width: 1200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  left: 50%;
  top: 54%;
  transform: translate(-50%, -50%);

  &{
    .process-btn-con{
        a.ant-tooltip-open{
            img{
                border: 2px dashed orange;
            }
        }
    }
  }


  @media screen and (max-width: 2560px) {
    margin: 0 auto;
    height: 70%;
    &{
      .readingtest-con{
        .ant-tooltip{
            .ant-tooltip-content{
                .ant-tooltip-arrow{
                    left: 50%;
                    
                }
                .ant-tooltip-inner{
                    span{

                        }   
                }
            }
        }
      }
    }
  }

  @media screen and (max-width: 2560px) {
    margin: 0 auto;
    height: 70%;

    &{
      .readingtest-con{
        .ant-tooltip{
          .ant-tooltip-content{
            .ant-tooltip-inner{
              span{
                font-size: 1.2rem;
                line-height: 1.4;
                font-weight: 600;
              }
            }
          }
        }
      }

      .process-btn-con {
        .ant-tooltip{
          .ant-tooltip-content{
            .ant-tooltip-inner{
              span{
                font-size: 1.2rem;
                line-height: 1.4;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    margin: 0 auto;
    height: 65%;
    &{
      .meta-header{
        &.readingTest-header{
          margin: 1rem auto 0;
          top: -5%;
          h1.main-tit{
          }
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    margin: 0 auto;
    height: 70%;
    &{
      .meta-con.guide-con .meta-header{
        &.readingTest-header{
          top: -3%;
          margin: 1rem auto 0;
        }
      }
    }
  }

  @media (device-width: 2560px) and (max-width: 2560px) and (aspect-ratio: 16/10) {
    margin: 0 auto;
    height: 70%;
      &{
          .readingtest-con{
            height: 80%;
            .ant-tooltip{
                .ant-tooltip-content{
                    .ant-tooltip-arrow{
                        left: 50%;
                        transform: translateX(-50%) scale(5);
                        top: 9.8rem;
                    }
                    .ant-tooltip-inner{
                        span{
                            font-size: 1.6rem;
                            }   
                    }
                }
            }
          }

           .process-btn-con {
            .ant-tooltip{
                .ant-tooltip-content{
                    .ant-tooltip-arrow{
                        left: 92%;
                        transform: translateX(-50%) rotate(45deg) scale(2.5);
                        top: 2.8rem;
                        background-color: #ffb055;
                    }
                    .ant-tooltip-inner{
                        span{
                            font-size: 1.6rem;
                            }   
                    }
                }
            }
        }
  }}


  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
    margin: 0 auto;

    & {
      .process-btn-con {
        padding: 0 4rem;
        a {
          img {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1700px) {

  }

  @media screen and (min-width: 1301px) {
    margin: 0 auto;
    height: 70%;

    &{
      .meta-header{
        .readingTest-header{
          margin: 2rem auto 0;
          h1.main-tit{
          }
        }
      }
    }
  }

  @media screen and (max-width: 1280px){
    &{
      .meta-header.readingTest-header{
        top: 0;
      }
    }
  }
`;

const burgerData = ['1', '2', '3', '4', '5'];
const menuText = <span>1. Click here to start</span>;
const timeText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">1</span> It is the countdown clock.
  </span>
);
const questionText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">2</span> Question number / Total Items
  </span>
);
const picText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">3</span> This box is for the picture or the passages
  </span>
);
const nextText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">5</span> Click to go to the next question
  </span>
);
const answerText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">4</span> Click anywhere in this box
  </span>
);

const VocaTestSample = () => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx===1016 ? true : false;
  const [isMarked, setIsMarked] = useState(false);
  const history = useHistory();

  const [playStart, { stop: stopPlay }] = useSound(clipGuide, { volume: 0.5 });

  useEffect(() => {
    if (playStart) {
      setTimeout(() => {
        playStart();
      }, 1000);
    }
  }, [playStart]);

  const stopSoundAndLeave = () => {
    if (stopPlay) {
      stopPlay();
    }
    history.push('/MetaExam/ReadingTestSample');
  };

  return (
    <>
      <div className={isWEnglish ? 'meta-con guide-con w-english-bg' : 'meta-con guide-con'}>
        <div className="meta-header test-header">
          <div className="burger-wrap">
            <img src="../images/meta_hamburger.png" alt="burger_meta" />
            <h2 style={{ marginBottom: '0' }}>Question List</h2>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Tooltip
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultVisible="true"
              visible="true"
              trigger="focus"
              placement="topRight"
              title={timeText}
            >
              <div className="time-wrap" style={{ border: '2px dashed orange' }}>
                <h2>Time: </h2>
                <h2 className="num">10:00</h2>
              </div>
            </Tooltip>

            <Tooltip
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultVisible="true"
              visible="true"
              trigger="focus"
              placement="bottom"
              title={questionText}
            >
              <div className="question-wrap" style={{ border: '2px dashed orange' }}>
                <h2>Question: </h2>
                <h2 className="num">10/30</h2>
              </div>
            </Tooltip>
          </div>
        </div>
        <InnerWrap>
          <div className="meta-header readingTest-header">
            <h1 className="main-tit" style={{ color: '#' }}>
              Vocabulary
            </h1>
          </div>

          <div className="sampleTest-con readingtest-con">
            <Tooltip
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultVisible="true"
              visible="true"
              trigger="focus"
              placement="top"
              title={picText}
            >
              <div className="readingtest-wrap passage-con">
                <img src="/images/meta/metaapples.JPG" alt="sampleImgTest_image" style={{ width: '100%' }} />
              </div>
            </Tooltip>
            <div className="readingtest-wrap">
              <div className="reading-q-con">
                <h1>10. There are ________ apples.</h1>
              </div>
              <div className="reading-a-con">
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>a</h3>
                  </div>
                  <div className="reading-a">
                    <h3>one</h3>
                  </div>
                </div>
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>b</h3>
                  </div>
                  <div className="reading-a">
                    <h3>two</h3>
                  </div>
                </div>
                <Tooltip
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultVisible="true"
                  visible="true"
                  trigger="focus"
                  placement="bottom"
                  title={answerText}
                >
                  <div style={{ border: '1px dashed orange', padding: '0.1rem' }} onClick={() => setIsMarked(true)}>
                    <div className="reading-a-wrap">
                      <div className="reading-a-num">
                        <h3>c</h3>
                        {isMarked && <img src="../images/check.png" alt="burger_meta" className="checkedImg" />}
                      </div>
                      <div className="reading-a" style={{ width: '100%', display: 'flex' }}>
                        <h3>three</h3>
                        <span
                          className="clickAnywhereSpan"
                          style={{
                            marginLeft: 'auto',
                            marginRight: '10px',
                            width: 'auto',
                            textAlign: 'center',
                            color: 'orange',
                            fontWeight: '600',
                          }}
                        >
                          {/* <span className="guideNum">3</span>Click anywhere in this box  */}
                        </span>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>d</h3>
                  </div>
                  <div className="reading-a">
                    <h3>four</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* bottom btn */}

          <div className="process-btn-con">
            <Link to="/MetaExam/InstructionalGuideMeta">
              <img src="/images/back_meta.png" alt="back_meta" />
            </Link>

            <Tooltip
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultVisible="true"
              visible="true"
              trigger="focus"
              placement="left"
              title={nextText}
            >
              <a href="#" onClick={stopSoundAndLeave} style={{ marginLeft: 'auto' }}>
                <img src="/images/next_meta.png" alt="next_meta" />
              </a>
            </Tooltip>
          </div>
        </InnerWrap>
      </div>
    </>
  );
};

export default VocaTestSample;
