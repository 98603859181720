import { gql } from '@apollo/client';

export const CREATE_BOOK_SERIES = gql`
  mutation createBookSeries(
    $title: String!
    $code: String!
    $category: Int!
    $price: Int!
    $cover_path: String
    $number_of_levels: Int
    $number_of_volumes: Int
    $number_of_lessons: Int
    $shopping_mall_product: Boolean
    $credit_system: Boolean
    $credit_duration: Int
    $short_title: String
    $stat: Int!
  ) {
    createBookSeries(
      title: $title
      code: $code
      category: $category
      price: $price
      number_of_levels: $number_of_levels
      number_of_volumes: $number_of_volumes
      number_of_lessons: $number_of_lessons
      shopping_mall_product: $shopping_mall_product
      cover_path: $cover_path
      credit_system: $credit_system
      credit_duration: $credit_duration
      short_title: $short_title
      stat: $stat
    ) {
      code
      parent_code
      stat
      book_sub {
        idx
        title
        book_code
        category
        price
        no
        cover_path
        short_title
        number_of_levels
        number_of_volumes
        number_of_lessons
        shopping_mall_product
        credit_system
        credit_duration
        book_category {
          idx
          name
          no
        }
      }
    }
  }
`;

export const UPDATE_BOOK_SERIES = gql`
  mutation updateBookSeries(
    $title: String!
    $code: String!
    $category: Int!
    $price: Int!
    $cover_path: String
    $number_of_levels: Int
    $number_of_volumes: Int
    $number_of_lessons: Int
    $shopping_mall_product: Boolean
    $credit_system: Boolean
    $credit_duration: Int
    $short_title: String
    $stat: Int!
  ) {
    updateBookSeries(
      title: $title
      code: $code
      category: $category
      price: $price
      number_of_levels: $number_of_levels
      number_of_volumes: $number_of_volumes
      number_of_lessons: $number_of_lessons
      shopping_mall_product: $shopping_mall_product
      cover_path: $cover_path
      credit_system: $credit_system
      credit_duration: $credit_duration
      short_title: $short_title
      stat: $stat
    ) {
      code
      parent_code
      stat
      book_sub {
        idx
        title
        book_code
        category
        price
        no
        cover_path
        short_title
        number_of_levels
        number_of_volumes
        number_of_lessons
        shopping_mall_product
        credit_system
        credit_duration
        book_category {
          idx
          name
          no
        }
      }
    }
  }
`;

export const DELETE_BOOK_SERIES = gql`
  mutation deleteBookSeries($codes: [String!]!) {
    deleteBookSeries(codes: $codes) {
      code
    }
  }
`;

export const UPDATE_BOOK_SETTINGS = gql`
  mutation updateBookSettings(
    $code: String!
    $title: String!
    $price: Int!
    $short_title: String
    $level_name: String
    $volume_name: String
    $evine_level: String
    $sort_evine: Int
    $cover_path: String
    $introduction: String
    $author: String
    $publisher: String
    $genre: String
    $pages: Int
    $ebook_path: String
    $lexile: String
    $sort_lexile: Int
    $glr: String
    $sort_glr: Int
    $cooidx: Int
    $bidx: Int
    $recommend_flag: Int
    $orderno: Int
    $easy: Int
    $level2: Int
    $additional_data: String
  ) {
    updateBookSettings(
      code: $code
      title: $title
      price: $price
      short_title: $short_title
      level_name: $level_name
      volume_name: $volume_name
      evine_level: $evine_level
      sort_evine: $sort_evine
      cover_path: $cover_path
      introduction: $introduction
      author: $author
      publisher: $publisher
      genre: $genre
      pages: $pages
      ebook_path: $ebook_path
      lexile: $lexile
      sort_lexile: $sort_lexile
      glr: $glr
      sort_glr: $sort_glr
      cooidx: $cooidx
      bidx: $bidx
      recommend_flag: $recommend_flag
      orderno: $orderno
      easy: $easy
      level2: $level2
      additional_data: $additional_data
    ) {
      code
      parent_code
      level_no
      level_name
      volume_no
      volume_name
      evine_level
      sort_evine
      prefix
      stat
      has_leveltest
      has_textbook
      ticket_term1
      ticket_term2
      ticket_term3
      book_sub {
        idx
        title
        book_code
        category
        price
        no
        cover_path
        short_title
        introduction
        author
        publisher
        ebook_path
        cooidx
        bidx
        recommend_flag
        orderno
        pages
        lexile
        glr
        sort_lexile
        sort_glr
        genre
        easy
        level2
        additional_data
      }
    }
  }
`;

export const UPDATE_STUDY_LANG_DATA = gql`
  mutation updateStudyLangData($lang_data_input: StudyLangDataInput!) {
    updateStudyLangData(lang_data_input: $lang_data_input) {
      data_idx
      no
    }
  }
`;

export const UPDATE_STUDY_QUIZ_DATA = gql`
  mutation updateStudyQuizData($quiz_data_input: StudyQuizDataInput!) {
    updateStudyQuizData(quiz_data_input: $quiz_data_input) {
      data_idx
      no
    }
  }
`;
export const ADD_STUDY_LANG_DATA = gql`
  mutation addStudyLangData($lang_data_input: StudyLangDataInput!) {
    addStudyLangData(lang_data_input: $lang_data_input)
  }
`;
