import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Form, Input, Button, DatePicker, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { format } from 'date-fns';
import moment from 'moment';

const ScriboSubscriptionEditModal = ({ visible, handleCancel, popupMode, campusOptions, selectedScriboSubscription }) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    email: selectedScriboSubscription.email,
    license_start_date: selectedScriboSubscription.license_start_date
      ? format(new Date(selectedScriboSubscription.license_start_date), 'yyyy-MM-dd')
      : undefined,
    campus_idx: selectedScriboSubscription?.campus_idx,
  });

  const [updateScriboSubscription, { loading }] = useMutation(mutations.scribo.UPDATE_SCRIBO_SUBSCRIPTION, {
    variables: {
      ...values,
      campus_idx: parseInt(values.campus_idx),
      idx: selectedScriboSubscription.idx,
    },

    refetchQueries: [
      {
        query: queries.scribo.FETCH_SCRIBO_SUBSCRIPTION_LIST,
        variables: {
          campus_idx: parseInt(values.campus_idx),
        },
      },
    ],

    onCompleted(data) {
      if (data) {
        openNotification('Scribo 이용이 업데이트되었습니다!');
        handleCancel();
      }
    },

    onError(error) {
      console.log('error', error);
    },
  });

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value ? event.target.value : '',
    });
  };

  const onCampusChange = (value) => {
    setValues({
      ...values,
      campus_idx: value,
    });
  };

  function handleLicenseStartDateChange(date, changeDate) {
    setValues({ ...values, license_start_date: changeDate ? format(new Date(changeDate), 'yyyy-MM-dd') : '' });
  }

  const onSubmit = async () => {
    updateScriboSubscription();
  };

  return (
    <>
      <Modal
        entered
        title={`Scribo 이용 수정하기`}
        visible={visible}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          initialValues={{
            email: selectedScriboSubscription.email,
            license_start_date: selectedScriboSubscription.license_start_date
              ? moment(selectedScriboSubscription.license_start_date, 'YYYY-MM-DD')
              : '',
            campus_idx: selectedScriboSubscription?.campus_idx,
          }}
        >
          <FormField hasFeedback={true} title="이메일">
            <FormItem name="email" rules={[{ required: true, message: '이메일을 입력하세요.' }]} hasFeedback>
              <Input name="email" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="라이센스 시작 날짜">
            <FormItem name="license_start_date" rules={[{ required: true, message: '라이센스 시작 날짜를 선택하세요.' }]} hasFeedback>
              <DatePicker
                name="license_start_date"
                initialValues={
                  selectedScriboSubscription.license_start_date && moment(selectedScriboSubscription.license_start_date, 'YYYY-MM-DD')
                }
                onChange={(date, dateString) => {
                  handleLicenseStartDateChange(date, dateString);
                }}
              />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="학원">
            <FormItem name="campus_idx" rules={[{ required: true, message: '학원을 선택하세요.' }]} hasFeedback>
              <Select
                showSearch
                placeholder="학원을 선택하세요."
                value={values.campus_idx}
                disabled={selectedScriboSubscription?.assigned_user_idx}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                name="campus_idx"
                style={{ width: '100%' }}
                onChange={onCampusChange}
                options={campusOptions}
              />
            </FormItem>
          </FormField>
        </Form>
      </Modal>
    </>
  );
};

ScriboSubscriptionEditModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default ScriboSubscriptionEditModal;
