import React from 'react';
import { Button } from 'antd';

import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { HeaderTitle } from 'src/components/common/Styles';

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
`;

const MenuButton = styled(Button)`
  & + & {
    margin-left: 5px;
  }
`;

function MyPage({ children }) {
  const {
    location: { pathname },
  } = useHistory();

  return (
    <>
      <MenuWrapper>
        <HeaderTitle level={4}>마이페이지</HeaderTitle>
        <ButtonBlock>
          <MenuButton
            type={pathname === '/mypage' ? `primary` : ''}
            size="small"
          >
            <Link to="/mypage">개인등록정보</Link>
          </MenuButton>
          <MenuButton
            type={pathname === '/mypage/levelTest' && `primary`}
            size="small"
          >
            <Link to="/mypage/levelTest">레벨테스트</Link>
          </MenuButton>
          <MenuButton
            type={pathname === '/mypage/learning' && `primary`}
            size="small"
          >
            <Link to="/mypage/learning">진행중인 학습</Link>
          </MenuButton>
          <MenuButton
            type={pathname === '/mypage/studyInfo' && `primary`}
            size="small"
          >
            <Link to="/mypage/studyInfo">전체 학습 현황</Link>
          </MenuButton>
          <MenuButton
            type={pathname === '/mypage/orders' && `primary`}
            size="small"
          >
            <Link to="/mypage/orders">구매상담내역</Link>
          </MenuButton>
          <MenuButton
            type={pathname === '/mypage/counseling' && `primary`}
            size="small"
          >
            <Link to="/mypage/counseling">상담내역</Link>
          </MenuButton>
          <MenuButton
            type={pathname === '/mypage/points' && `primary`}
            size="small"
          >
            <Link to="/mypage/points">내 포인트</Link>
          </MenuButton>
        </ButtonBlock>
      </MenuWrapper>
      {children}
    </>
  );
}

export default MyPage;
