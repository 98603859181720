import React from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import * as dummy from 'src/dummy';
import {
//   Details,
//   New,
//   LevelTestNew,
//   LevelDetails,
//   Preview,
//   StudentLevelResult,
//   StudentResult,
//   StudentReportLevelTest,
//   StudentResultManager,""
DailyTestGroupQuiz
} from 'src/components/Test';

const Quiz = () => {
  const { type = 'quiz', id, group } = useParams();
  let testData = {};
  if (id !== undefined) {
    testData = dummy.test.test.filter((e) => {
      return e.No === id;
    })[0];
  }

  const getSwitchableRoute = (val) => {
    console.log("val",val);
    switch (val) {
      case 'quiz':
        return <DailyTestGroupQuiz id={id} groupName={group}/>;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Row gutter={[24, 16]}>{getSwitchableRoute(type)}</Row>
    </>
  );
};

export default Quiz;
