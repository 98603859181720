import React, { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Typography, Space, Row, Checkbox, Modal, Button, Input, Table, List } from 'antd';
import { Link } from 'react-router-dom';
import CustomButton from 'src/components/common/CustomButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import TestResultTable from './TestResultTable'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcelUserSheet } from 'src/utils/index';


const { Title } = Typography;

const ClassResult = () => {
  const [editScore, setEditScore] = useState(false);
  const [scoreDetail, setScoreDetail] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedStudentsIdx, setSelectedStudentsIdx] = useState([]);
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));

  const { idx, classIdx } = useParams()
  let maxTest = 0;
  let testDetail;
  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      width: 50,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 110,
      align: 'center',
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Link to={`/test/syllabus/${record.test_group_idx}/assign?classIdx=${record?.class_idx}`}>
            <Space size="middle" style={{ color: '#1890ff' }}>
              {text}
            </Space>
          </Link>
        );
      },
    },
    {
      title: 'Comp',
      dataIndex: 'comp',
      key: 'comp',
      width: 80,
      align: 'center',
    },
    {
      title: 'Avg. Tries',
      dataIndex: 'avg_tries',
      key: 'avg_tries',
      width: 120,
      align: 'center',
    },
  ];

  const { data: fetchResultList, refetch } = useQuery(queries.getTests.TEST_GROUP_RESULT_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      daily_test_group_assigned_idx: parseInt(idx)
    },
  });
  const { data: fetchTestDetail } = useQuery(queries.getTests.TEST_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      daily_test_group_assigned_idx: parseInt(idx)
    },
  });

  const [updateTestScore] = useMutation(mutations.updateTests.UPDATE_TEST_SCORE, {
    onCompleted: () => {
      openNotification('수정 완료!');
    },
  });
  const [addStudent] = useMutation(mutations.createTests.Add_STUDENT, {
    onCompleted: () => {
      setModalVisible(false)
      setSelectedStudentsIdx([])
      setCheckedList([])
      openNotification('수정 완료!');
      refetch()
    },
  });
  // const [deleteStudent] = useMutation(mutations.createTests.DELETE_STUDENT, {
  //   onCompleted: () => {
  //     setModalVisible(false)
  //     openNotification('수정 완료!');
  //     setSelectedStudentsIdx([])
  //     setCheckedList([])
  //     refetch()
  //   },
  // });
  const { data: fetchStudents } = useQuery(queries.getClass.CLASS_STUDENT_LIST, {
    variables: { class_idx: parseInt(classIdx) },
  });

  useEffect(() => {
    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }
  }, [fetchStudents])

  // const handleAdd = () => {
  //   addStudent({
  //     variables: {
  //       daily_test_group_assigned_idx: parseInt(idx),
  //       user_idx: selectedStudentsIdx
  //     }
  //   })
  // }
  // const handleDelete = () => {
  //   deleteStudent({
  //     variables: {
  //       daily_test_group_assigned_idx: parseInt(idx),
  //       user_idx: selectedStudentsIdx
  //     }
  //   })
  // }
  if (fetchTestDetail) {
    if (fetchTestDetail?.getTest) {

      fetchTestDetail?.getTest?.map((ele, i) => {
        const columnsObj = {
          title: ele.test_folder_name,
          // dataIndex: `test${i+1}`,
          // key: 'test',
          key: ele.idx,
          dataIndex: "Test",
          width: 120,
          align: 'center',
          type: "Test",
          i
        }
        columns.push(columnsObj)
      })
    }
  }
  const fetchTestGroupResultList = useMemo(() => {
    if (fetchResultList?.testGroupResultList) {
      return fetchResultList?.testGroupResultList?.reduce((acc, cur, index) => {
        testDetail = JSON.parse(cur?.test);
        if (maxTest < testDetail?.length) {
          maxTest = testDetail?.length
        }
        const obj = {
          idx: cur?.idx,
          name: cur?.uidx,
          id: cur?.id,
          comp: cur?.complete_test + "/" + cur?.total_test,
          avg_tries: 1,
          No: index + 1,
          key: cur.idx,
          test: testDetail,
        };
        return [...acc, obj];
      }, []);
    }
  }, [fetchResultList, editScore])


  const handleManualScore = () => {
    setEditScore(true)
  }
  const saveData = () => {
    updateTestScore({
      variables: {
        testDetail: JSON.stringify(scoreDetail)
      }
    })
  }
  const getUpdatedScoreData = (val) => {
    setScoreDetail(val)
  }
  function onChange(e, idx, userIdx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
    if (e.target.checked === true) {
      setSelectedStudentsIdx([...selectedStudentsIdx, userIdx])
    } else {
      const studentId = selectedStudentsIdx;
      const idx = studentId?.filter((ele) => ele !== userIdx)
      setSelectedStudentsIdx(idx);

    }
  }
  function onCheckAllChange(e) {
    const studentIdx = students?.map((ele) => ele.user?.idx)
    if (e.target.checked) {
      setCheckedList(new Array(students.length).fill(true));
      setSelectedStudentsIdx(studentIdx)
    } else {
      setCheckedList([]);
      setSelectedStudentsIdx([])
    }
  }

  function handleExportToExcel(e) {
    e.preventDefault();
    const fileName = `UserList`;
    ExportExcelUserSheet(columns, fetchTestGroupResultList, fileName);
  }
  return (
    <>
      <HeaderTitle level={4}>Assigned List</HeaderTitle>
      <Row gutter={[16, 16]} className='cust-assigned-student-test'>
        <Col span={24}>
          <HalfWrapper className="assigned-syllabus-halfwrapper">
            <div className='cust-header-content'
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center'
              }}
            >
              <Title level={4}></Title>
              <div className="assigned-syllabus-top-fields" style={{ display: 'inline-block', width: '50%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
                <CustomButton className="enter-score-btn" primary onClick={handleManualScore}>
                  + Enter SCore Manually
                </CustomButton>
                {/* <CustomButton className="add-student-btn" onClick={() => setModalVisible(true)}>
                  + Add/&nbsp;<span class="del-text">Del</span>&nbsp;Students
                </CustomButton> */}
              </div>
              <div>
                <a href="/" onClick={handleExportToExcel} className='excel-img'>
                  <img src={imageexcel} alt="download" />
                </a>
              </div>
            </div>

            <TestResultTable
              columns={columns}
              dataSource={fetchTestGroupResultList}
              scoreEdit={editScore}
            />

            {/* {modalVisible ? (
              <Modal
                title="Add/Delete Student"
                centered
                visible={modalVisible}
                onCancel={() => {
                  setModalVisible(false);
                }}
                footer={[]}
                className="paperTestPopup stud-add-del-modal"
              >
                <List
                  header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <label>Name</label>
                      <div>
                        전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                      </div>
                    </div>
                  }
                  footer={<div></div>}
                  bordered
                  dataSource={students}
                  renderItem={(item, idx) => (
                    <List.Item style={{ display: 'flex' }}>
                      <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx, item.user?.idx)}></Checkbox>
                    </List.Item>
                  )}
                />
                <div className='stud-add-del-btn'>
                  <Button onClick={handleAdd} className="add-btn">Add</Button>
                  <Button onClick={handleDelete} className='del-btn'>Delete</Button>
                </div>
              </Modal>
            ) : (
              ''
            )} */}
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ClassResult;
