import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typographym, Button } from 'antd';
import { PlayCircleFilled, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useParams, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import VocaHeader from './VocaHeader';

const VocaTypeC = () => {
  const answer = [
    { num: 1, answer: 'floor' },
    { num: 2, answer: 'table' },
    { num: 3, answer: 'bed' },
    { num: 4, answer: 'chair' },
  ];

  return (
    <>
      <div className="vocatool-body quiz-tool type-tool">
        <div className="flashcard-voca-con quiz typec">
          <div className="quiz-inner">
            <h1>Look at the picture and choose the right answer.</h1>
            <div className="wrap">
              <div className="left-wrap">
                {' '}
                <div className="inner top for-q">
                  <div className="q-img"></div>
                </div>
              </div>
              <div className="right-wrap">
                {' '}
                <div className="question-con">
                  <div className="inner">
                    {' '}
                    <h2>5. Where do you sleep?</h2>
                  </div>
                </div>
                <div className="answer-con">
                  {answer &&
                    answer.map((ans, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="q-num">
                              <h2>{ans.num}</h2>
                            </div>
                            <div className="q-word">
                              <h3>{ans.answer}</h3>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>{' '}
          <div className="nav-btm">
            <span>
              <PlayCircleFilled />
            </span>
          </div>{' '}
        </div>
      </div>
    </>
  );
};

export default VocaTypeC;
