import React, { useState, useEffect, useMemo } from 'react';
import { Input, Modal, Select, Spin, message, Tabs, Button as AButton, Tooltip, Upload } from 'antd';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import { READ_USER_SERVER_SAVED_DATA } from 'src/operations/queries/getUser';
import styled from 'styled-components';
import { UPDATE_USER_SERVER_SAVED_DATA } from 'src/operations/mutations/updateUser';
import moment from 'moment';

import { CloseOutlined, RedoOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import * as axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  BOOK_REPORT_CHECK_FILL,
  BOOK_REPORT_REVISE_FILL,
  BOOK_REPORT_CHECK_FILL_LIST,
  BOOK_REPORT_REVISE_FILL_LIST,
  BOOK_REPORT_COMMENT_LIST,
  BOOK_REPORT_WORD_LIST,
} from 'src/constants/common';
import { LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import { LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { openNotification } from 'src/components/common/Notification';
import Recording from './Recording';
import queryString from 'query-string';
import { useMobileOrientation } from 'src/utils/hooks';
const { TextArea } = Input;
const { Option } = Select;

const DEFAULT_SENTENCES = {
  sentences: 'Sentences',
  60: 'Min. 60 words',
  100: 'Min. 100 words',
  150: 'Min. 150 words',
  200: 'Min. 200 words',
  essay: 'Essay',
};

const StageTitleSpan = styled.span`
  color: '#00b0f0';
  font-size: 25px;
  padding-left: 50px;
  @media (max-width: 1280px) {
    font-size: 20px;
    padding-left: 40px;
  }
`;

const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 0 0px 25px;
  background-color: #ffffff;
  max-width: 1650px;
  min-width: 1350px;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 850px;
  }
`;

const TitleBox = styled.div`
  width: ${(props) => (props.isPortrait ? '96vw' : '40vw')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.theme ? props.theme : '#e1efff')};
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 10px;
`;
const ReWriteModal = styled(Modal)`
  .ant-modal-close-x {
    font-size: 24px;
    width: 40px;
    height: 40px;
    padding-right: 10px;
  }
  .ant-modal-header {
    background-color: #453dd0;
    border-radius: 0px;
    padding: 0px;
  }
  .ant-modal-content {
    border: 20px solid #453dd0;
    border-radius: 20px;
    background-color: #453dd0;
  }
  .ant-modal-body {
    border-radius: 0 0 20px 20px;
    background-color: #fff;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  height: 100%;
  background-color: #dfe7f5;

  & .ant-modal {
    top: 50px;
  }
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowColFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isPortrait ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StageTitle = styled(RowFlex)`
  /* border-bottom: 1px solid #4472c4; */
  width: 100%;
  color: #27666c;
  font-size: 28px;
  font-weight: bold;
  padding: 5px 30px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #2f5597;
  padding: 5px 10px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }

  & .span {
    font-size: 24px;
  }

  & .span.orange {
    color: #ef9442;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.isPortrait ? 'audo' : 'calc(100vh - 75px);')};
  min-height: ${(props) => (props.isPortrait ? '100vh' : 'auto')};
  align-items: center;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-repeat: no-repeat;
`;

const ImageButton = styled.img`
  max-height: 50px;
  cursor: pointer;
`;

const ImageButtonStep = styled.img`
  cursor: pointer;
  display: ${(props) => (props.isPortrait ? 'none' : 'block')};
`;

const ImageButtonSettings = styled.img`
  display: ${(props) => (props.isPortrait ? 'none' : 'block')};
  cursor: pointer;
  position: absolute;
  right: 20px;
  bottom: 50px;
`;

const MainTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #5298d8;
  padding: 5px 20px;
  width: 100%;
  text-align: start;
  overflow-y: auto;
  @media (max-width: 1280px) {
    max-height: 120px;
    font-size: 18px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
  }

  & .orange {
    color: #ef9442;
  }
`;
const MainTitle2 = styled(MainTitle)`
  font-size: 18px;
  max-height: 200px;
  font-weight: 500;
  line-height: 1.3;
  color: #021980;
  @media (max-width: 1280px) {
    font-size: 15px;
    max-height: 120px;
  }
`;
const CentreBody = styled.div`
  width: ${(props) => (props.isPortrait ? '96vw' : '72vw')};
  height: ${(props) => (props.isPortrait ? '96vw' : '35vw')};
  padding: 20px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 20px;
  border: 20px #e1efff solid;
  border-top-width: 40px;
  border-radius: 20px;
`;

const RowFlexFly = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    right: ${(props) => (props.isPortrait ? '4vw' : '14vw')};
    width: 150px;
    height: 100px;
    background: url('/images/ai_coach/icon/pagechange.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const TopClip = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: -75px;
  margin-bottom: 20px;
  width: ${(props) => (props.isPortrait ? '96vw' : '72vw')};
`;

const TopTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -35px;
  margin-bottom: 10px;
  padding: 0 20px;
  width: ${(props) => (props.isPortrait ? '96vw' : '40vw')};

  & .ant-upload span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & span {
    font-size: 20px;
    color: ${(props) => (props.color ? props.color : '#5298d8')};
    font-weight: bold;
  }
`;

const FooterStep = styled.div`
  width: ${(props) => (props.isPortrait ? '96vw' : '72vw')};
  padding: 10px 20px;
  display: flex;
  flex-direction: rows;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #fffdf8;

  & span {
    font-size: 14px;
    font-weight: bold;
    color: #6d6d6d;
    line-height: 20px;
  }

  & span.orange {
    color: #ef9442;
  }
`;

const FooterStepSmall = styled.div`
  width: ${(props) => (props.isPortrait ? '96vw' : '40vw')};
  padding: 10px 20px;
  display: flex;
  flex-direction: rows;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #fffdf8;
  min-height: 65px;

  & span {
    font-size: 14px;
    font-weight: bold;
    color: #6d6d6d;
    line-height: 20px;
  }

  & span.orange {
    color: #ef9442;
  }
`;

const HalfBody = styled.div`
  width: ${(props) => (props.isPortrait ? '100%' : '50%')};
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 85%;
  background-color: #f2f2f2;
`;
const TutorInput = styled.div`
  flex: 1;
  height: 100%;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 25px;
  color: #333232;
  flex: 1;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
  -webkit-user-select: ${(props) => !props.copyPaste && 'none'};
  -moz-user-select: ${(props) => !props.copyPaste && 'none'};
  -ms-use-select: ${(props) => !props.copyPaste && 'none'};
  user-select: ${(props) => !props.copyPaste && 'none'};
`;
const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #254b75;
  line-height: 30px;
  border: 4px #fffae6 solid;
  background-color: #fffae6;
  font-weight: 800;
  padding: 5px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media (max-width: 1280px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
const NumberBox = styled.div`
  width: 100px;
  height: 24px;
  border: 1px solid #bcbcbc;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  color: ${(props) => props.color};
  font-weight: bold;
`;
const TopicArea = styled(TutorInput)`
  color: #000000;
  font-weight: 500;
  border: 10px #fffae6 solid;
  max-height: 200px;
  padding: 20px 30px;
  min-width: 90%;
  text-align: center;
  font-size: 22px;
`;

const InputBox = styled.div`
  width: ${(props) => (props.isPortrait ? '96vw' : '40vw')};
  height: ${(props) => (props.withTitle ? (props.isPortrait ? '50vh' : '46vh') : props.isPortrait ? '96vw' : '36vw')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.theme ? props.theme : '#e1efff')};
  margin-bottom: 20px;
  border: 20px ${(props) => (props.theme ? props.theme : '#e1efff')} solid;
  border-top-width: 50px;
  border-radius: 20px;
`;

const InputArea = styled(TextArea)`
  resize: none;
  padding: 10px 25px;
  font-size: 15px;
  line-height: 25px;
  color: #333232;
  font-weight: 600;
  box-shadow: rgb(225, 239, 255) 1px 2px 5px 5px;
  border-radius: 10px;
  ::placeholder {
    color: black;
    font-size: 15px;
  }
`;
const InputArea2 = styled.div`
  resize: none;
  padding: 10px 25px;
  font-size: 15px;
  line-height: 25px;
  color: #333232;
  font-weight: 600;
  box-shadow: rgb(225, 239, 255) 1px 2px 5px 5px;
  border-radius: 10px;
  ::placeholder {
    color: black;
    font-size: 15px;
  }
`;
const LVSelect = styled(Select)`
  font-size: 14px;
  font-weight: bold;
  border: 1px #5b9ddb solid;
  color: #5b9ddb;
  padding: 0 5px;
  min-width: 170px;
  height: 34px;
  .ant-select-arrow {
    color: #5b9ddb;
  }
  .ant-select-selection-item {
    font-weight: bolder;
  }
  ::placeholder {
    color: #5b9ddb;
  }
  @media (max-width: 1720px) {
    width: 150px;
    font-size: 12px;
  }
  @media (max-width: 1280px) {
    width: 130px;
    font-size: 12px;
  }
`;
const LVOption = styled(Option)`
  font-size: 14px;
  font-weight: bold;
`;
const Button = styled.button`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: ${(props) => (props.radius ? '20px' : '10px')};
  font-size: 1.1em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: ${(props) => (props.radius ? '9px 30px' : '9px 18px')};
  border: ${(props) => (props.radius ? 'none' : '2px #dae3f3 solid')};
  @media (max-width: 1720px) {
    padding: ${(props) => (props.radius ? '9px 20px' : '8px 15px')};
    font-size: 1em;
    /* padding: 8px 15px; */
  }
  @media (max-width: 1280px) {
    padding: ${(props) => (props.radius ? '9px 17px' : '8px 15px')};
    font-size: 0.8em;
    font-weight: normal;
    padding: 8px 14px;
  }
`;

const Paginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* overflow: auto; */
  padding: 0px 20px;
  border-radius: 15px;
  max-width: 460px;
  min-width: 460px;
  /* background-color: #c7e3fd; */
`;

const PageButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.3em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: 6px 15px;
  margin: 0 5px;
  max-width: 50px;
  min-width: 50px;
  text-align: center;
`;

const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #5a5757;
  /* background: #ed7d31; */
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const LoadingScreen = styled.div`
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Dictionary = styled(Modal)`
  .ant-modal-header {
    background-color: #fffae6;
    border-radius: 20px;
  }
  .ant-modal-content {
    border: 10px solid #e1efff;
    border-radius: 20px;
    background-color: #fffae6;
  }
`;
const DictionaryInput = styled(Input)`
  resize: none;
  padding: 5px;
  width: 55%;
  font-size: 15px;
  color: #333232;
  font-weight: 500;
  border: 2px #453dd0 solid;
  /* border-radius: 10px; */
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #ffc000 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;

const BlankImageModal = styled(Modal)`
  .ant-modal-header {
    background-color: #f5faff;
    border-radius: 20px;
  }
  .ant-modal-content {
    border-radius: 20px;
    background-color: #f5faff;
  }

  .ant-modal-body {
    border-radius: 20px;
    background-color: #f5faff;
  }
`;

export default function AiEssayCorrection() {
  const { code, external_study_idx } = queryString.parse(window.location.search);
  const { isPortrait } = useMobileOrientation();
  const [step, setStep] = useState(0);
  const history = useHistory();
  const [wordLevel, setWordLevel] = useState('Grade 1');
  const [chatList, setChatList] = useState([]);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showReviseModal, setShowReviseModal] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [level, setLevel] = useState(chatList.length ? chatList[chatList.length - 1]?.data?.level : '');
  const [defaultLevel, setDefaultLevel] = useState('');
  const [copyPaste, setCopyPaste] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const [currentWord, setCurrentWord] = useState('');
  const [page, setPage] = useState(-1);
  const [writingType, setWritingType] = useState('');
  const [defaultWritingType, setDefaultWritingType] = useState('');
  const [language, setLanguage] = useState('Korean');
  const [defaultFill, setDefaultFill] = useState(BOOK_REPORT_CHECK_FILL);
  const [reviseFill, setReviseFill] = useState(BOOK_REPORT_REVISE_FILL);
  const [commentFill, setCommentFill] = useState(BOOK_REPORT_COMMENT_LIST['min']);
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [dictionaryVisible, setDictionaryVisible] = useState(false);
  const [rewriteModalVisible, setRewriteModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');
  const [rewriteModalText, setRewriteModalText] = useState('');
  const [topicData, setTopicData] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [answer, setAnswer] = useState('');
  const [showRecording, setShowRecording] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [revise, setRevise] = useState(false);
  const [editType, setEditType] = useState('check');
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [fullFeedbackDone, setFullFeedbackDone] = useState(false);
  const botChats = chatList.filter(
    (chat) =>
      (chat.user === 'bot' && chat.data.type !== 'word' && chat.data.type !== 'comment-full' && feedback) ||
      (!feedback && chat.data.type !== 'comment' && chat.user === 'bot' && chat.data.type !== 'word' && chat.data.type !== 'comment-full'),
  );
  const wordChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type === 'word');
  const botCheckChat = botChats.filter((chat) => chat.data.type === 'check');
  const botReviseChat = botChats.filter((chat) => chat.data.type === 'revise');
  const botCommentChat = botChats.filter((chat) => chat.data.type === 'comment');
  const botCommentFullChat = chatList.filter((chat) => chat.data.type === 'comment-full');

  const { data: serverStorageData } = useQuery(READ_USER_SERVER_SAVED_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      idx: `LEVELUP_BOOK_REPORT_${userInfoVar()?.idx}_${external_study_idx}`,
    },
  });

  const [loadMoreMessages, { data, loading, error }] = useLazyQuery(OPENAI_COMPLETION_QUERY, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (serverStorageData?.readUserServerSavedData && currentText.length === 0 && serverStorageData?.readUserServerSavedData?.dataObj) {
      setCurrentText(serverStorageData?.readUserServerSavedData?.dataObj?.text || '');
    }
  }, [serverStorageData, currentText]);

  const isSelfBookReport = useMemo(() => {
    let etcData = userInfoVar()?.campus?.etc
      ? JSON.parse(userInfoVar()?.campus?.etc)
      : {
          isSelfBookReport: false,
        };

    return etcData?.isSelfBookReport === false ? false : true;
  }, []);

  const isLevelupRecording = useMemo(() => {
    let etcData = userInfoVar()?.campus?.etc
      ? JSON.parse(userInfoVar()?.campus?.etc)
      : {
          isLevelupRecording: true,
        };

    return etcData?.isLevelupRecording === false ? false : true;
  }, []);

  const exportToS3 = (filename, url, cb) => {
    setLoadingGenerate(true);
    return new Promise(async (resolve, reject) => {
      try {
        const fileData = await axios.post('https://llf63tb67l3wj7nqerumdipm440stovy.lambda-url.ap-northeast-2.on.aws', {
          url: url,
          fileName: '-' + filename + '.pdf',
        });
        const fileUrl = fileData.data;
        // const fullUploader = await upload({ variables: { url: url, filename: '-' + filename + '.pdf' } });

        await cb(fileUrl);
        setLoadingGenerate(false);
      } catch (e) {
        console.log(e);
        setLoadingGenerate(false);
      }
    });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setCurrentText(value);
    setFullFeedbackDone(false);
  };

  useEffect(() => {
    if (level) {
      setDefaultFill(BOOK_REPORT_CHECK_FILL_LIST[level]);
      setReviseFill(BOOK_REPORT_REVISE_FILL_LIST[level]);
    }
  }, [level]);

  const {
    data: dataHistory,
    loading: loadingHistory,
    error: errorHistory,
  } = useQuery(LEVELUP_AI_TOPIC_USER_ASSIGNMENT_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      external_study_idx: parseInt(external_study_idx),
    },
  });
  const [levelUpUpdateAITopicUserAssignment, { loading: loadingUpdate }] = useMutation(LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });
  useEffect(() => {
    if (dataHistory?.levelUpAiTopicUserAssignmentDetails && dataHistory !== undefined) {
      if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.assigned_external_study?.external_study_data) {
        const externalStudy = dataHistory?.levelUpAiTopicUserAssignmentDetails?.assigned_external_study?.external_study_data
          ? JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.assigned_external_study?.external_study_data)
          : [];
        setDefaultLevel(externalStudy?.level ? externalStudy?.level : 'Grade 7');
        setCopyPaste(externalStudy?.copyPaste ? externalStudy?.copyPaste : false);
        setLevel(externalStudy?.level ? externalStudy?.level : 'Grade 7');
        setDefaultWritingType(externalStudy?.sentences);
        setWritingType(externalStudy?.sentences ? externalStudy?.sentences : 'sentences');
        setFeedback(externalStudy?.feedback);
        setRevise(externalStudy?.revise);
      }
      if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic?.length > 0) {
        let prmpt = [];
        if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data) {
          try {
            prmpt = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data);
            setPrompt(prmpt?.book?.topic);
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
        setTopicData(dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.topic);
      }
      const externalStudyData = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.data);
      setAnswer(externalStudyData?.answer);
      setRewriteModalText(externalStudyData?.answer);
      if (externalStudyData?.status === 2) {
        history.push('/');
      } else if (externalStudyData?.status === 1) {
        setShowRecording(true);
      }

      if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic?.length > 0) {
        let data = [];
        if (dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data !== undefined) {
          try {
            data = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data);
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
        // const data = JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.aiTopic[0]?.data);
        // if (data?.book?.level && !level) {
        //   setLevel(data.book.level);
        // }
        // if (data?.feedback && !feedback) {
        //   setFeedback(data?.feedback);
        // }
        // if (data?.revise && !revise) {
        //   setRevise(data?.revise);
        // }
      }

      let resChats = [];
      dataHistory?.levelUpAiTopicUserAssignmentDetails?.ai_request_response?.forEach((item) => {
        resChats.push({
          user: userInfoVar()?.id,
          chat_user_name: userInfoVar()?.name,
          content: item.prompt_text,
          urls: [],
          created: item.idate,
          data: item?.data ? JSON.parse(item?.data) : {},
        });
        resChats.push({
          user: 'bot',
          chat_user_name: 'AI봇',
          content: item.completion_text,
          urls: item.image_urls ? JSON.parse(item.image_urls) : [],
          created: item.idate,
          data: item?.data ? JSON.parse(item?.data) : {},
        });
      });
      let fullFeedbackCount = resChats.filter((chat) => chat.user === 'bot' && chat.data.type === 'comment').length;
      setChatList(resChats);
      setRewriteModalText(externalStudyData?.answer || answer || resChats.length ? resChats[resChats.length - 1]?.data?.currentText : '');
      setFullFeedbackDone(fullFeedbackCount > 0 ? true : false);
      setPage(resChats.filter((chat) => chat.user === 'bot' && chat.data.type !== 'word').length - 1);

      let filteredList = resChats.filter((chat) => chat.user === 'bot' && chat.data.type === 'check');
      setStep(filteredList.length > 0 ? 3 : 1);
    }
  }, [dataHistory, history]);

  useEffect(() => {
    console.log('chatList', chatList);
    if (chatList?.length) {
      setCurrentText(
        chatList[chatList.length - 1] ? chatList[chatList.length - 1]?.data?.currentText : chatList[0]?.content?.data?.currentText,
      );
      setLevel(chatList[chatList.length - 1] ? chatList[chatList.length - 1]?.data?.level : chatList[0]?.content?.data?.level);
    }
  }, [chatList]);

  useEffect(() => {
    if (writingType !== 'sentences') {
      setCommentFill(BOOK_REPORT_COMMENT_LIST[writingType === 'essay' ? 'full' : 'min']);
    }
  }, [writingType]);
  useEffect(() => {
    if (data?.getOpenAiCompletion?.text?.length > 0) {
      setChatList([
        ...chatList,
        {
          user: 'bot',
          chat_user_name: 'AI봇',
          prompt: currentText,
          content: data.getOpenAiCompletion?.text,
          urls: data.getOpenAiCompletion?.urls,
          created: data?.getOpenAiCompletion?.date,
          data: data?.getOpenAiCompletion?.chat?.data ? JSON.parse(data?.getOpenAiCompletion?.chat?.data) : null,
        },
      ]);
      setPage(botChats.length);
    }
  }, [data]);

  const onSubmit = async () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          type: 'check',
          currentText: currentText,
          level: level,
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');

    await loadMoreMessages({
      variables: {
        prompt: defaultFill?.replaceAll('((level))', level)?.replaceAll('((topic))', currentText)?.replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        external_user_study_idx: parseInt(external_study_idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'check',
          currentText: currentText,
        }),
      },
    });
    setStep(step + 1);
    setRewriteModalText(currentText);
  };

  const onRevise = async () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          type: 'revise',
          currentText: currentText,
          level: level,
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    await loadMoreMessages({
      variables: {
        prompt: reviseFill?.replaceAll('((level))', level)?.replaceAll('((topic))', currentText)?.replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        external_user_study_idx: parseInt(external_study_idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'revise',
          currentText: currentText,
        }),
      },
    });
    setShowReviseModal(true);
  };

  const onComment = async () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          type: 'comment',
          currentText: currentText,
          level: level,
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    await loadMoreMessages({
      variables: {
        prompt: commentFill?.replaceAll('((level))', level)?.replaceAll('((topic))', currentText)?.replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        external_user_study_idx: parseInt(external_study_idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'comment',
          currentText: currentText,
        }),
      },
    });
    setStep(step + 1);
    setRewriteModalText(currentText);

    setFullFeedbackDone(true);
  };

  const onWordCheck = () => {
    if (currentWord.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    if (!wordLevel) {
      message.error('레벨을 선택해주세요.');
      return;
    }

    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentWord,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: BOOK_REPORT_WORD_LIST[wordLevel]
          ?.replaceAll('((level))', wordLevel)
          ?.replaceAll('((word))', currentWord)
          ?.replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        external_user_study_idx: parseInt(external_study_idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'word',
          currentText: currentWord,
        }),
      },
    });
  };
  const handleWordClear = () => {
    setCurrentWord('');
    const tmp = chatList.filter((e) => (!e.data ? true : e.data?.type !== 'word'));
    setChatList(tmp);
  };
  //   const handleClear = ()=>{
  //     form.resetFields();
  //     console.log(form)
  //   }
  const onChangeLevel = (value) => {
    setLevel(value);
  };
  const onChangeWordLevel = (value) => {
    setWordLevel(value);
    console.log(value);
  };
  const onClear = () => {
    // setChatList([]);
    setCurrentText('');
    setPage(0);
  };
  const handleCopyPaste = (e) => {
    e.preventDefault();
    alert('복사, 붙여넣기는 허용되지 않습니다.');
  };
  const example = [
    'Word Weekly News Magazine',
    '2023-06 W3',
    'Lego goes to the moon!',
    'Write about how people can help others to save their lives.',
  ];

  const handleModalOpen = (type) => {
    setEditType(type);
    if (type === 'check') {
      setModalText(defaultFill);
    }
    if (type === 'revise') {
      setModalText(reviseFill);
    }

    if (type === 'comment') {
      setModalText(commentFill);
    }

    setModalVisible(true);
  };

  const handleModalChange = (e) => {
    setModalText(e.target.value);
  };

  const handleModalOk = () => {
    setModalVisible(false);
    if (editType === 'check') {
      setDefaultFill(modalText);
    }
    if (editType === 'revise') {
      setReviseFill(modalText);
    }
    if (editType === 'comment') {
      setCommentFill(modalText);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setModalText('');
  };

  const handleRewriteModalCancel = () => {
    setRewriteModalVisible(false);
    // setRewriteModalText('');
  };

  const handleRewriteModalOk = async () => {
    // setReviseFill(rewriteModalText);

    await levelUpUpdateAITopicUserAssignment({
      variables: {
        idx: parseInt(external_study_idx),
        answer: rewriteModalText,
      },
    });
    setRewriteModalVisible(false);
  };
  const handleDictionaryModalOk = () => {
    setDictionaryVisible(false);
    // setReviseFill(rewriteModalText);
  };

  const handleRewriteModalChange = (e) => {
    setRewriteModalText(e.target.value);
  };

  const onRewrite = () => {
    setRewriteModalVisible(true);
    if (!feedback && !fullFeedbackDone) {
      onComment();
    }
  };

  const onAnswerSubmit = async () => {
    if (rewriteModalText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    if (!feedback && !fullFeedbackDone) {
      await onComment();
    }

    Modal.confirm({
      icon: null,
      title: 'Submit',
      content: (
        <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
          Would you like to submit?
        </Title>
      ),
      onOk: () => {
        saveData({
          idx: parseInt(external_study_idx),
          answer: rewriteModalText,
          start_time: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
          end_time: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          status: isLevelupRecording ? 1 : 2,
        });
      },
      onCancel: () => {},
    });
  };

  const saveData = async (variables) => {
    await levelUpUpdateAITopicUserAssignment({
      variables,
    });

    if (!isLevelupRecording) {
      if (isSelfBookReport && !dataHistory?.assigned_idx) {
        await exportToS3(
          `-level-up-report-result-${external_study_idx}`,
          `https://culp.cloubot.com/level-up/report/generate/${external_study_idx}?pdfType=student`,
          async (fileUrl) => {
            let externalData = dataHistory?.levelUpAiTopicUserAssignmentDetails?.data
              ? JSON.parse(dataHistory?.levelUpAiTopicUserAssignmentDetails?.data)
              : {};
            await levelUpUpdateAITopicUserAssignment({
              variables: {
                idx: parseInt(external_study_idx),
                status: 2,
                rubric_data: JSON.stringify(
                  externalData?.rubric_data ? { ...JSON.parse(externalData?.rubric_data || '{}'), pdf_url: fileUrl } : { pdf_url: fileUrl },
                ),
              },
            });
          },
        );
      }
      openNotification('답변이 저장되었습니다!');
      history.goBack();
    } else {
      setAnswer(variables?.answer);
      setShowRecording(true);
    }
  };

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return (
          <ColumnFlex style={{ width: '100%', height: '100%', padding: '10px', justifyContent: 'center', alignItems: 'center' }}>
            <CentreBody isPortrait={isPortrait}>
              <TopClip isPortrait={isPortrait}>
                <img src="/images/ai_coach/icon/clip.png" style={{ width: '30px', height: '45px' }} alt="" />
                <span style={{ width: '30px' }}></span>
                <img src="/images/ai_coach/icon/clip.png" style={{ width: '30px', height: '45px' }} alt="" />
              </TopClip>
              <MainTitle>
                <span className="orange">Title: </span>
                {prompt}
              </MainTitle>
              <ColumnFlex
                style={{
                  justifyContent: 'center',
                  flex: 1,
                  alignItems: 'center',
                  width: isPortrait ? '100%' : '80%',
                  marginBottom: '10px',
                }}
              >
                <SubTitle>Topic</SubTitle>
                <TopicArea value={topicData} placeholder="Topic">
                  {topicData}
                </TopicArea>
              </ColumnFlex>
              <RowFlexFly isPortrait={isPortrait}>
                <ImageButton
                  src="/images/ai_coach/icon/start_1.png"
                  onClick={() => {
                    setStep(2);
                  }}
                />
              </RowFlexFly>
            </CentreBody>
            <FooterStep isPortrait={isPortrait}>
              <a type="primary" href={'#'} rel="noreferrer" style={{ marginRight: '10px' }}>
                <img width={80} src="/images/ai_coach/icon/guide.png" alt="guide-icon" />
              </a>
              <RowFlex
                style={{
                  justifyContent: 'space-evenly',
                  flex: 1,
                  height: '100%',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginLeft: '10px',
                }}
              >
                <span className="orange">
                  1<sup>st</sup> Draft
                </span>
                <img src="/images/ai_coach/icon/next_1.png" alt="arrow" style={{ width: '25px', padding: '5px' }} />
                <span>Check</span>
                <img src="/images/ai_coach/icon/next_1.png" alt="arrow" style={{ width: '25px', padding: '5px' }} />
                <span className="orange">Rewrite</span>
              </RowFlex>
            </FooterStep>
          </ColumnFlex>
        );
      case 2:
        return (
          <RowFlex style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <ImageButtonStep
              src="/images/ai_coach/icon/2.png"
              onClick={() => {
                setStep(1);
              }}
              isPortrait={isPortrait}
            />
            <ColumnFlex style={{ width: '100%', height: '100%', padding: '10px', justifyContent: 'center', alignItems: 'center' }}>
              <TitleBox isPortrait={isPortrait} theme="#ffffff">
                <MainTitle2>
                  <span className="orange">Topic: </span>
                  <div dangerouslySetInnerHTML={{ __html: topicData }} />
                </MainTitle2>
              </TitleBox>
              {loading ? (
                <InputBox isPortrait={isPortrait} theme="#e1efff" withTitle={true}>
                  <LoadingScreen>
                    <span style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                      It'll take 1-2 minutes <br /> Thank you
                    </span>
                    <br />
                    <Spin fontSize="24px" />
                  </LoadingScreen>
                </InputBox>
              ) : (
                <InputAreaWithOCRUpload
                  withTitle={true}
                  value={currentText}
                  onChange={handleInputChange}
                  placeholder={!level ? 'Select Your Grade' : ''}
                  disabled={!level || loading}
                  idx={external_study_idx}
                  title={
                    <span>
                      1<sup>st</sup> Draft
                    </span>
                  }
                  startTime={startTime}
                />
              )}
              <FooterStepSmall isPortrait={isPortrait}>
                <ImageButton src="/images/ai_coach/icon/search.png" onClick={() => setDictionaryVisible(true)} />
                <ImageButton src="/images/ai_coach/icon/clear.png" onClick={onClear} />

                <RowFlex style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <ImageButton
                    src="/images/ai_coach/icon/check1.png"
                    onClick={() => {
                      onSubmit(null);
                    }}
                  />
                </RowFlex>
                <RowFlex
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '#e1eef4 1px solid',
                    backgroundColor: '#e1eef4',
                    borderRadius: '2px',
                    padding: '5px 10px',
                    color: '#2b52a0',
                    margin: '0 20px',
                  }}
                >
                  <span>{DEFAULT_SENTENCES[writingType]}</span>
                </RowFlex>
              </FooterStepSmall>
            </ColumnFlex>
            <ImageButtonStep
              src={`/images/ai_coach/icon/${botCheckChat?.length ? '1' : '3'}.png`}
              isPortrait={isPortrait}
              onClick={() => {
                if (botCheckChat?.length) {
                  setStep(3);
                }
              }}
            />
          </RowFlex>
        );
      case 3:
        return (
          <RowColFlex isPortrait={isPortrait}>
            <ImageButtonStep
              src="/images/ai_coach/icon/2.png"
              isPortrait={isPortrait}
              onClick={() => {
                setStep(2);
              }}
            />
            <ColumnFlex style={{ flex: 1, padding: '10px', justifyContent: 'center', alignItems: 'flex-end' }}>
              <InputBox isPortrait={isPortrait} theme="#fffae6">
                <TopTitle color="#254b75" isPortrait={isPortrait}>
                  <span>Feedback Check</span>
                  <RowFlex style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                    <NumberBox color={'#7f7f7f'}>
                      <Timer startTime={startTime} />
                    </NumberBox>
                  </RowFlex>
                </TopTitle>
                <TutorInput
                  style={{ width: '100%', height: '100%', padding: '10px', overflowY: 'auto' }}
                  onCopy={!copyPaste && handleCopyPaste}
                  onPaste={!copyPaste && handleCopyPaste}
                  copyPaste={copyPaste}
                >
                  {botCheckChat?.length && botCheckChat[botCheckChat?.length - 1]?.content ? (
                    <MarkDownContent content={botCheckChat[botCheckChat?.length - 1]?.content} />
                  ) : (
                    ''
                  )}
                </TutorInput>
              </InputBox>
              <FooterStepSmall isPortrait={isPortrait}>
                <Title>
                  <span>
                    Step (<span className="orange">2</span>/3)
                  </span>
                </Title>
                <ImageButton src="/images/ai_coach/icon/revise.png" onClick={onRevise} />
              </FooterStepSmall>
            </ColumnFlex>
            <ColumnFlex style={{ flex: 1, padding: '10px', justifyContent: 'center', alignItems: 'flex-start' }}>
              {loading ? (
                <InputBox isPortrait={isPortrait} theme="#e1efff">
                  <LoadingScreen>
                    <span style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                      It'll take 1-2 minutes <br /> Thank you
                    </span>
                    <br />
                    <Spin fontSize="24px" />
                  </LoadingScreen>
                </InputBox>
              ) : (
                <InputAreaWithOCRUpload
                  value={rewriteModalText}
                  onChange={handleRewriteModalChange}
                  handleCopyPaste={handleCopyPaste}
                  blockCopyPaste={!copyPaste}
                  placeholder={!level ? 'Select Your Grade' : ''}
                  disabled={!level || loading}
                  idx={external_study_idx}
                  title={<span>Final Draft Rewrite</span>}
                  hideTimer={true}
                  startTime={startTime}
                />
              )}
              <FooterStepSmall isPortrait={isPortrait}>
                <ImageButton src="/images/ai_coach/icon/search.png" onClick={() => setDictionaryVisible(true)} />

                <RowFlex style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <ImageButton
                    src="/images/ai_coach/icon/submit.png"
                    onClick={() => {
                      if (loadingUpdate || !botChats?.length || !rewriteModalText.length) {
                        return;
                      }

                      onAnswerSubmit();
                    }}
                  />
                </RowFlex>
                <RowFlex
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '#e1eef4 1px solid',
                    backgroundColor: '#e1eef4',
                    borderRadius: '2px',
                    padding: '5px 10px',
                    color: '#2b52a0',
                    margin: '0 20px',
                  }}
                >
                  <span>{DEFAULT_SENTENCES[writingType]}</span>
                </RowFlex>
              </FooterStepSmall>
            </ColumnFlex>
            <ImageButtonStep src="/images/ai_coach/icon/3.png" isPortrait={isPortrait} />
          </RowColFlex>
        );

      default:
        break;
    }
  };

  const handleCloseClick = () => {
    setShowCloseModal(true);
  };

  return showRecording && answer ? (
    <Recording
      dataNew={[
        {
          page: 1,
          text: answer,
        },
      ]}
      articleData={topicData}
      idx={external_study_idx || null}
      userData={userInfoVar()}
      code={code}
      start_time={moment(startTime).format('YYYY-MM-DD HH:mm:ss')}
      end_time={moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')}
      externalUserStudyData={dataHistory?.levelUpAiTopicUserAssignmentDetails}
    />
  ) : (
    <Wrapper>
      <MainWrapper bg={step === 1 ? '/images/ai_coach/back/back1.jpg' : '/images/ai_coach/back/back2.jpg'} isPortrait={isPortrait}>
        <StageTitle>
          <div style={{ flex: 1 }}>Book Report</div>
          <CloseButton onClick={handleCloseClick}>
            <CloseOutlined />
          </CloseButton>
        </StageTitle>
        <RowFlex style={{ flex: 1, width: '100%' }}>{renderStep(step)}</RowFlex>
        <ImageButtonSettings src="/images/ai_coach/icon/setting_1.png" isPortrait={isPortrait} onClick={() => setShowSettings(true)} />
      </MainWrapper>
      <Modal title="Edit script" visible={modalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
        <TextArea value={modalText} onChange={handleModalChange} rows={6} />
      </Modal>
      <ReWriteModal
        title={
          <Title
            style={{
              backgroundColor: '#ffda00',
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
              padding: '20px',
            }}
          >
            Book Report: Final Draft
          </Title>
        }
        visible={rewriteModalVisible}
        onOk={handleRewriteModalOk}
        onCancel={handleRewriteModalCancel}
        copyPaste={copyPaste}
        width={`80%`}
        footer={null}
      >
        {/* <Title style={{ margin: '15px 0 0px 0', backgroundColor: '#ffda00' }}>Book Report: Final Draft</Title> */}
        <RowFlex style={{ height: '100%' }}>
          <HalfBody>
            <Tabs defaultActiveKey="1" onChange={(e) => setCurrentTab(e)} value={currentTab} type="card" style={{ height: '100%' }}>
              <Tabs.TabPane tab="Original" key="1" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                <TutorInput
                  onCopy={!copyPaste && handleCopyPaste}
                  onPaste={!copyPaste && handleCopyPaste}
                  value={currentText}
                  style={{ height: '50vh' }}
                >
                  {currentText}
                </TutorInput>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Check" key="2" style={{ height: '100%' }}>
                <TutorInput onCopy={!copyPaste && handleCopyPaste} onPaste={!copyPaste && handleCopyPaste} style={{ height: '50vh' }}>
                  {botCheckChat?.length && botCheckChat[botCheckChat?.length - 1]?.content
                    ? botCheckChat[botCheckChat?.length - 1]?.content.split('\n').map((line, index) => {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </TutorInput>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Comment" key="3" style={{ height: '100%' }}>
                <TutorInput onCopy={!copyPaste && handleCopyPaste} onPaste={!copyPaste && handleCopyPaste} style={{ height: '50vh' }}>
                  {botCommentChat?.length && botCommentChat[botCommentChat?.length - 1]?.content
                    ? botCommentChat[botCommentChat?.length - 1]?.content.split('\n').map((line, index) => {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </TutorInput>
              </Tabs.TabPane>
            </Tabs>
          </HalfBody>
          <HalfBody>
            <Title style={{ margin: '5px 0', textAlign: 'center' }}>Final Draft</Title>
            <InputAreaWithOCRUpload
              value={rewriteModalText}
              style={{ height: '50vh' }}
              onChange={handleRewriteModalChange}
              handleCopyPaste={handleCopyPaste}
              blockCopyPaste={!copyPaste}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <Button
                back={'#6ac5b8'}
                color={'white'}
                style={{ width: '100px', borderRadius: '20px', textAlign: 'center' }}
                onClick={handleRewriteModalOk}
              >
                Save
              </Button>
            </div>
          </HalfBody>
        </RowFlex>
      </ReWriteModal>
      <Dictionary
        title={<div>Dictionary ( Eng / Korean )</div>}
        onCancel={handleDictionaryModalOk}
        visible={dictionaryVisible}
        handleCancel={handleDictionaryModalOk}
        footer={''}
        width={'45%'}
      >
        <RowFlex style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: '20px' }}>
          {' '}
          <DictionaryInput allowClear value={currentWord} onPressEnter={onWordCheck} onChange={(e) => setCurrentWord(e.target.value)} />
          <SearchBtn
            onClick={() => {
              if (currentWord !== '') {
                onWordCheck();
              }
            }}
          >
            Search
          </SearchBtn>
          <LVSelect
            dropdownStyle={{
              fontSize: '24px',
              fontWeight: 'bold',
              alignItems: 'center',
              maxWidth: '100%',
              border: 'none',
            }}
            placeholder={` Level `}
            onChange={onChangeWordLevel}
            bordered={false}
            value={wordLevel}
          >
            {[
              { value: 'Grade 1', label: 'Basic A' },
              { value: 'Grade 4', label: 'Basic B' },
              {
                value: 'Grade 7',
                label: 'Intermediate',
              },
              {
                value: 'Grade 11',
                label: 'Advanced',
              },
              { value: 'College Level', label: 'College' },
            ].map((e) => (
              <LVOption key={e.value} value={e.value}>
                {e.label}
              </LVOption>
            ))}
          </LVSelect>
          <Tooltip title="검색 기록 삭제">
            <RedoOutlined
              onClick={handleWordClear}
              style={{ cursor: 'pointer', padding: '0 0 0 10px', color: '#4976d2', fontWeight: 'bold', fontSize: '20px' }}
            />
          </Tooltip>
        </RowFlex>
        <SearchResultBox>
          {wordChats?.length && wordChats[wordChats?.length - 1]?.content
            ? wordChats[wordChats?.length - 1]?.content.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                );
              })
            : ''}
        </SearchResultBox>
      </Dictionary>
      {/* <Dictionary2 onCancel={handleDictionaryModalOk} visible={dictionaryVisible} language={language} level={level} /> */}
      <Modal
        open={showCloseModal}
        width={700}
        onCancel={() => {
          setShowCloseModal(false);
        }}
        className="restart-task-modal"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            width: '660px',
            height: '276px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/images/popup/exit_popup.png)`,
          }}
        >
          <div style={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
            <button
              style={{
                backgroundColor: '#3DB230',
                border: 'none',
                color: '#fff',
                width: `${274 * 0.5}px`,
                height: `${113 * 0.5}px`,
                borderRadius: `${113 * 0.3}px`,
                fontSize: `${38 * 0.6}px`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                fontFamily: 'Noto Sans',
                fontWeight: '600',
                cursor: 'pointer',
              }}
              onClick={() => {
                history.push('/');
              }}
            >
              YES
            </button>
            <button
              onClick={() => {
                setShowCloseModal(false);
              }}
              style={{
                backgroundColor: '#f65656',
                border: '1px solid #ff0b0b',
                color: '#fff',
                width: `${274 * 0.5}px`,
                height: `${113 * 0.5}px`,
                borderRadius: `${113 * 0.3}px`,
                fontSize: `${38 * 0.6}px`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                fontFamily: 'Noto Sans',
                fontWeight: '600',
                cursor: 'pointer',
              }}
            >
              NO
            </button>
          </div>
        </div>
      </Modal>
      <BlankImageModal
        open={showSettings}
        footer={null}
        closeIcon={
          <img
            src="/images/ai_coach/icon/exit_4.png"
            alt="exit"
            style={{
              marginTop: '-50px',
              marginRight: '-50px',
            }}
          />
        }
        onCancel={() => setShowSettings(false)}
      >
        <ColumnFlex style={{ justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
          <Title style={{ marginBottom: '10px' }}>
            <img src="/images/ai_coach/icon/setting_2.png" alt="setting" /> Setting
          </Title>
          <RowFlex style={{ justifyContent: 'space-between', fontSize: '27px', width: '90%', marginBottom: '10px' }}>
            <Title>Language</Title>
            <LVSelect
              dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
              placeholder={` Language `}
              onChange={(e) => {
                setLanguage(e);
              }}
              value={language}
              bordered={false}
            >
              {[
                {
                  value: 'Korean',
                  label: `English & Korean`,
                },
                {
                  value: 'Taiwanese',
                  label: `English & Taiwanese`,
                },
                {
                  value: 'Japanese',
                  label: `English & Japanese`,
                },
                {
                  value: 'Spanish',
                  label: `English & Spanish`,
                },
              ].map((e) => (
                <LVOption key={e.value} value={e.value}>
                  {e.label}
                </LVOption>
              ))}
            </LVSelect>
          </RowFlex>
          <RowFlex style={{ justifyContent: 'space-between', fontSize: '27px', width: '90%', marginBottom: '10px' }}>
            <Title>Level</Title>
            <LVSelect
              dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
              placeholder={` Level `}
              disabled={defaultLevel}
              onChange={onChangeLevel}
              bordered={false}
              value={level}
            >
              {[
                { value: 'Grade 1', label: 'Basic A' },
                { value: 'Grade 4', label: 'Basic B' },
                {
                  value: 'Grade 7',
                  label: 'Intermediate',
                },
                {
                  value: 'Grade 11',
                  label: 'Advanced',
                },
                { value: 'College Level', label: 'College' },
              ].map((e) => (
                <LVOption key={e.value} value={e.value}>
                  {e.label}
                </LVOption>
              ))}
            </LVSelect>
          </RowFlex>
          <RowFlex style={{ justifyContent: 'space-between', fontSize: '27px', width: '90%', marginBottom: '10px' }}>
            <Title>Writing Type</Title>
            <LVSelect
              dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
              placeholder={` Sentences `}
              disabled={writingType}
              onChange={(e) => setWritingType(e)}
              bordered={false}
              value={writingType}
              defaultValue={`${writingType}`}
            >
              {[
                {
                  value: 'sentences',
                  label: `Sentences`,
                },
                {
                  value: '60',
                  label: `Min. 60 words`, //Minimum 60 words
                },
                {
                  value: '100',
                  label: `Min. 100 words`, //Minimum 100 words
                },
                {
                  value: '150',
                  label: `Min. 150 words`, //Minimum 150 words
                },
                {
                  value: '200',
                  label: `Min. 200 words`, //Minimum 250 words
                },
                {
                  value: 'essay',
                  label: `Full Feedback`, //Minimum 250 words
                },
              ].map((e) => (
                <LVOption key={e.value} value={e.value}>
                  {e.label}
                </LVOption>
              ))}
            </LVSelect>
          </RowFlex>
        </ColumnFlex>
      </BlankImageModal>
      <BlankImageModal
        open={showReviseModal}
        footer={null}
        centered={true}
        width={isPortrait ? '80vw' : '40vw'}
        closeIcon={
          <img
            src="/images/ai_coach/icon/exit_4.png"
            alt="exit"
            style={{
              marginTop: '-50px',
              marginRight: '-50px',
            }}
          />
        }
        onCancel={() => setShowReviseModal(false)}
      >
        <img
          src="/images/ai_coach/icon/star_top_1.png"
          alt="exit"
          style={{
            position: 'absolute',
            top: '-30px',
            left: '-60px',
            height: '50px',
            width: '50px',
          }}
        />
        <img
          src="/images/ai_coach/icon/star_top_2.png"
          alt="exit"
          style={{
            position: 'absolute',
            top: '30px',
            left: '-100px',
            height: '35px',
            width: '35px',
          }}
        />
        <img
          src="/images/ai_coach/icon/star_top_3.png"
          alt="exit"
          style={{
            position: 'absolute',
            top: '60px',
            left: '-30px',
            height: '20px',
            width: '20px',
          }}
        />
        <ColumnFlex style={{ justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
          <Title style={{ marginBottom: '10px', fontWeight: '900' }}> Revised by AI </Title>
          <TutorInput
            style={{ width: '100%', maxHeight: '60vh', padding: '10px', overflowY: 'auto', minHeight: '300px' }}
            onCopy={!copyPaste && handleCopyPaste}
            onPaste={!copyPaste && handleCopyPaste}
            copyPaste={copyPaste}
          >
            {botReviseChat?.length && botReviseChat[botReviseChat?.length - 1]?.content ? (
              <MarkDownContent content={botReviseChat[botReviseChat?.length - 1]?.content} />
            ) : (
              ''
            )}
          </TutorInput>
          <ImageButton
            src="/images/ai_coach/icon/next.png"
            style={{ marginTop: '10px' }}
            onClick={() => {
              setShowReviseModal(false);
            }}
          />
        </ColumnFlex>
        <img
          src="/images/ai_coach/icon/star_bottom_1.png"
          alt="exit"
          style={{ position: 'absolute', bottom: '20px', right: '-60px', width: '40px', height: '40px' }}
        />
        <img
          src="/images/ai_coach/icon/star_bottom_2.png"
          alt="exit"
          style={{ position: 'absolute', bottom: '-30px', right: '-30px', width: '25px', height: '25px' }}
        />
      </BlankImageModal>
    </Wrapper>
  );
}

const InputAreaWithOCRUpload = ({
  value,
  onChange,
  disabled,
  title,
  style = {},
  placeholder = '',
  handleCopyPaste,
  blockCopyPaste,
  idx,
  hideTimer,
  startTime,
  withTitle = false,
}) => {
  const [loadingOCR, setLoadingOCR] = useState(false);
  const { isPortrait } = useMobileOrientation();

  const [updateUserServerSavedData] = useMutation(UPDATE_USER_SERVER_SAVED_DATA);
  const updateServerStorage = async (prev) => {
    await updateUserServerSavedData({
      variables: {
        idx: `LEVELUP_BOOK_REPORT_${userInfoVar()?.idx}_${idx}`,
        data: { text: prev, last_updated_date: moment().format('YYYY-MM-DD HH:mm:ss') },
      },
    });
  };

  const handleOCR = async (data) => {
    setLoadingOCR(true);
    try {
      const res = await axios('https://krjupjiewlg33kkrta2cgu2zue0chzbg.lambda-url.ap-northeast-2.on.aws/', {
        method: 'POST',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-extention': data.name.split('.').pop(),
        },
      });
      console.log(res);
      if (res.data) {
        onChange({ target: { value: res.data } });
        updateServerStorage(res.data);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingOCR(false);
  };

  return (
    <>
      <InputBox isPortrait={isPortrait} theme="#e1efff" withTitle={withTitle}>
        <TopTitle isPortrait={isPortrait}>
          {title}
          <RowFlex style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            {!hideTimer && (
              <NumberBox color={'#7f7f7f'}>
                <Timer startTime={startTime} />
              </NumberBox>
            )}
            <Upload accept="image/*" action={handleOCR} showUploadList={false}>
              <ImageButton src="/images/ai_coach/icon/OCR.png" style={{ marginRight: '10px' }} />
            </Upload>
            <Upload accept="image/*" action={handleOCR} showUploadList={false} capture="environment">
              <ImageButton src="/images/ai_coach/icon/camera.png" />
            </Upload>
          </RowFlex>
        </TopTitle>
        <InputArea
          style={{ resize: 'none', flex: 1, ...style }}
          value={value}
          onChange={onChange}
          disabled={disabled || loadingOCR}
          placeholder={placeholder}
          onCopy={blockCopyPaste && handleCopyPaste}
          onPaste={blockCopyPaste && handleCopyPaste}
        />
      </InputBox>
    </>
  );
};

const Timer = ({ startTime }) => {
  const [seconds, setSeconds] = useState(moment().diff(startTime, 'seconds'));
  useEffect(() => {
    const timerId = setTimeout(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);
    return () => clearTimeout(timerId);
  }, [seconds]);
  return (
    <>
      {parseInt(seconds / 60) < 10 ? '0' + parseInt(seconds / 60) : parseInt(seconds / 60)}:
      {seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60}
    </>
  );
};

const MarkDownContent = ({ content }) => {
  let newLineMessage = content.replace(/\*\*\n(?=\w)/g, '**\n\n');
  return <ReactMarkdown remarkPlugins={[remarkGfm]} children={newLineMessage} />;
};

const SearchBtn = styled(AButton)`
  background-color: #ffc60b;
  height: 35px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;
const SearchResultBox = styled.div`
  width: 80%;
  min-height: 500px;
  background-color: #fff;
  margin: 0 auto 20px;
  border: 1px solid lightgray;
  padding: 15px;
  line-height: 2;
`;

const PageWrapper = styled(RowFlex)`
  overflow-x: auto;
  /* width: 350px; */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ffb600;
    border-radius: 3px;
  }
`;
const FinalDraftModal = styled(Modal)`
  -webkit-user-select: ${(props) => !props.copyPaste && 'none'};
  -moz-user-select: ${(props) => !props.copyPaste && 'none'};
  -ms-use-select: ${(props) => !props.copyPaste && 'none'};
  user-select: ${(props) => !props.copyPaste && 'none'};
  .ant-modal-close {
    color: #fff;
  }
`;
