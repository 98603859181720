import React from 'react';
import { Button, Modal,Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const PhonicsNoticePopupForStudents = ({visible}) => {

  return (
    <Modal
      width="40vw"
      bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      visible={visible}
      title="Phonics Monster Studying"
      // onCancel={handleClose}
      maskClosable={false}
      // okText="학습 종료"
      // cancelText="닫기"
      closable={false}
      // onOk={handleDone}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          OK
        </Button>,
      ]}
    >
      <div style={{ textAlign: 'center' }}>
        <h3>Phonics Monster 학습중</h3>
        <br/>
        <div><Spin size="large" indicator={antIcon}/></div>
        <br/>
        <div>학습을 마치고 OK 버튼을 클릭하세요.</div>
      </div>
    </Modal>
  );
};

export default PhonicsNoticePopupForStudents;
