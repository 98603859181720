import { gql } from '@apollo/client';

export const CREATE_CONSULT = gql`
  mutation createConsult(
    $campus_idx: Int!
    $manager_idx: Int!
    $phone: String!
    $student_name: String!
    $memo: String
    $ticket_code: String
    $level: String
  ) {
    createConsult(
      campus_idx: $campus_idx
      manager_idx: $manager_idx
      phone: $phone
      student_name: $student_name
      memo: $memo
      ticket_code: $ticket_code
      level: $level
    ) {
      idx
    }
  }
`;
export const REFRESH_TEST = gql`
  mutation Test($assigned_test_user_idx: [Int!]!) {
    refreshTest(assigned_test_user_idx: $assigned_test_user_idx)
  }
`;
export const MOD_CONSULT = gql`
  mutation updateConsult(
    $order_idx: Int!
    $phone: String!
    $student_name: String!
    $korean_name: String
    $reserve_date: String!
    $birth_date: String!
    $school_info: String
    $schedule_idx: Int
    $days: String
    $campus_idx: Int
  ) {
    updateConsult(
      order_idx: $order_idx
      phone: $phone
      student_name: $student_name
      korean_name: $korean_name
      reserve_date: $reserve_date
      birth_date: $birth_date
      school_info: $school_info
      schedule_idx: $schedule_idx
      days: $days
      campus_idx: $campus_idx
    ) {
      idx
    }
  }
`;

export const CREATE_CONSULT_FOR_LEVELTEST = gql`
  mutation Test(
    $campus_idx: Int!
    $phone: String!
    $student_name: String!
    $reserve_date: String!
    $ticket_code: String
    $school_grade: String
  ) {
    createConsultForLevelTest(
      campus_idx: $campus_idx
      phone: $phone
      student_name: $student_name
      reserve_date: $reserve_date
      ticket_code: $ticket_code
      school_grade: $school_grade
    ) {
      idx
    }
  }
`;

export const ASSIGN_LEVEL_TEST = gql`
  mutation assignTests(
    $title: String!
    $start_time: String!
    $limit_time: Int!
    $user_idx_list: [Int!]!
    $test_idx: Int!
    $order_leveltest_idx: Int
  ) {
    assignTests(
      title: $title
      start_time: $start_time
      limit_time: $limit_time
      user_idx_list: $user_idx_list
      test_idx: $test_idx
      class_idx: null
      order_leveltest_idx: $order_leveltest_idx
    ) {
      idx
    }
  }
`;

export const ASSIGN_LEVEL_TEST_MULTI = gql`
  mutation assignTestsMultiForPlacementTest($list: [AssignedTestsInput!]!) {
    assignTestsMultiForPlacementTest(list: $list)
  }
`;

export const ASSIGN_LEVEL_TEST_MULTI_META_EXAM = gql`
  mutation assignTestsMultiForMetaExam(
    $list: [AssignedTestsInput!]!
    $product_code: String
    $product_code_retest: String
    $product_price: Int
    $product_price_retest: Int = 0
    $product_name: String
    $retest_condition_month: Int
    $retest_condition_type: String
    $returnUrl: String
    $cancelUrl: String
    ) {
    assignTestsMultiForMetaExam(
      list: $list
      product_code:$product_code
      product_code_retest:$product_code_retest
      product_price:$product_price
      product_price_retest:$product_price_retest
      product_name:$product_name
      retest_condition_month:$retest_condition_month
      retest_condition_type:$retest_condition_type
      returnUrl:$returnUrl
      cancelUrl:$cancelUrl
    )
  }
`;

export const UPDATE_LEVEL_TEST = gql`
  mutation updateOrderLevelTest($idx: Int!, $assigned_tests_idx: Int!) {
    updateOrderLevelTest(idx: $idx, assigned_tests_idx: $assigned_tests_idx) {
      idx
    }
  }
`;

export const UPDATE_ASSIGN_LEVEL_TEST = gql`
  mutation updateAssignedTest($idx: Int!, $test_idx: Int!) {
    updateAssignedTest(idx: $idx, test_idx: $test_idx) {
      idx
    }
  }
`;

export const CREATE_CONSULT_LEVELTEST_FOR_TALKINGSAM = gql`
  mutation Test(
    $campus_idx: Int!
    $phone: String!
    $student_name: String!
    $reserve_date: String!
    $birth_date: String!
    $school_info: String!
    $ticket_code: String
    $survey1: String!
    $survey2: String!
    $survey3: String #필수가 아님..
    $survey4: String!
    $survey5: String!
    $survey6: String!
    $survey1_1_memo: String
    $survey3_1_memo: String
    $survey3_2_memo: String
    $survey3_3_memo: String
    $survey3_4_memo: String
    $survey3_5_memo: String
    $survey4_1_memo: String
    $survey5_8_memo: String
  ) {
    createConsultLevelTestForTalkingSam(
      campus_idx: $campus_idx
      phone: $phone
      student_name: $student_name
      reserve_date: $reserve_date
      birth_date: $birth_date
      school_info: $school_info
      ticket_code: $ticket_code
      survey1: $survey1
      survey2: $survey2
      survey3: $survey3
      survey4: $survey4
      survey5: $survey5
      survey6: $survey6
      survey1_1_memo: $survey1_1_memo
      survey3_1_memo: $survey3_1_memo
      survey3_2_memo: $survey3_2_memo
      survey3_3_memo: $survey3_3_memo
      survey3_4_memo: $survey3_4_memo
      survey3_5_memo: $survey3_5_memo
      survey4_1_memo: $survey4_1_memo
      survey5_8_memo: $survey5_8_memo
    )
  }
`;
export const CREATE_CONSULT_LEVELTEST_FOR_METAEXAM = gql`
  mutation Test(
    $campus_idx: Int!
    $phone: String!
    $student_name: String!
    $korean_name: String!
    $reserve_date: String!
    $birth_date: String!
    $school_info: String!
    $schedule_idx: Int
    $days: String
    $survey1: String!
    $survey2: String!
    $survey3: String #필수가 아님..
    $survey4: String
    $survey5: String!
    $survey6: String!
  ) {
    createConsultLevelTestForMetaExam(
      campus_idx: $campus_idx
      phone: $phone
      student_name: $student_name
      korean_name: $korean_name
      reserve_date: $reserve_date
      birth_date: $birth_date
      school_info: $school_info
      schedule_idx: $schedule_idx
      days: $days
      survey1: $survey1
      survey2: $survey2
      survey3: $survey3
      survey4: $survey4
      survey5: $survey5
      survey6: $survey6
    ) {
      idx
    }
  }
`;
export const CREATE_CONSULT_LEVELTEST_FOR_SMARTECLASS = gql`
  mutation createConsultLevelTestForSmartEclass(
    $phone: String!
    $student_name: String!
    $school_grade: String!
    $reserve_date: String!
    $ticket_code: String
    $english_name: String
    $student_phone: String
    $address: String
  ) {
    createConsultLevelTestForSmartEclass(
      phone: $phone
      student_name: $student_name
      school_grade: $school_grade
      reserve_date: $reserve_date
      ticket_code: $ticket_code
      english_name: $english_name
      student_phone: $student_phone
      address: $address
    ) {
      user {
        idx
      }
      order_leveltest {
        idx
        reserve_date
      }
      school_info
    }
  }
`;

export const CREATE_CONSULT_LEVELTEST_MULTI_FOR_SMARTECLASS = gql`
  mutation createConsultLevelTestMultiForSmartEclass($list: [OrderPlacementTestInput!]!, $test_date: String!) {
    createConsultLevelTestMultiForSmartEclass(list: $list, test_date: $test_date) {
      idx
      user {
        idx
      }
      order_leveltest {
        idx
        reserve_date
      }
      school_info
    }
  }
`;

export const DELETE_ORDER_FOR_PLACEMENT_TEST = gql`
  mutation deleteOrderCascade($idx_list: [Int!]!) {
    deleteOrderCascade(idx_list: $idx_list)
  }
`;

export const SEND_REPORT_MESSAGE_META = gql`
  mutation sendReportMessageMeta($inputs: [JSONObject!]!, $send_phone: String) {
    sendReportMessageMeta(inputs: $inputs, send_phone: $send_phone) {
      idx
      error_msg
    }
  }
`;
export const SEND_META_PAYMENT_LINK = gql`
  mutation sendMetaPaymentLink($phone:String!, $payment_link:String!,$send_phone:String){
    sendMetaPaymentLink(phone:$phone, payment_link:$payment_link,send_phone:$send_phone){
      idx
      msg
      error_msg
    }
  }
`;
