import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Typography, Button, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useParams, useLocation } from 'react-router-dom';
import { classStoreData } from 'src/operations/store';
import { DownloadOutlined, PrinterTwoTone } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import * as queries from 'src/operations/queries';
import { settings } from 'src/dummy/settings';
import BookrStudyDetailModal from 'src/components/Modal/BookrStudyDetailModal';
const { Option } = Select;

const HomeWorkTable = styled(CustomTable)`
  border-top: 3px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
  }
`;
const { Title, Text } = Typography;

const sortByName = (a, b) => {
  //이름순 정렬
  if (a.name === b.name) {
    return 0;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
};

const BookrResultDetail = () => {
  const { class_idx, idx } = useParams();
  const companyName = useSelector(classStoreData);
  const { state } = useLocation();
  const [isEssayVisible, setIsEssayVisible] = useState(false);
  const [currentIndx, setCurrentIndx] = useState(0);
  const [essayTitle, setEssayTitle] = useState('');
  const [studyData, setStudyData] = useState({});
  const [currentUserTitle, setCurrentUserTitle] = useState('');
  const [currentUserName, setCurrentUserName] = useState('');
  const [essayContent, setEssayContent] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  //const [recordingContent, setRecordingContent] = useState('test');
  const [recordingData, setRecordingData] = useState({});
  const [bookrVisible, setBookrVisible] = useState(false);
  const [bookrData, setBookrData] = useState({});
  const {
    data: fetchAssignedExternalStudyDetails,
    refetch: assginedRefetch,
    loading,
  } = useQuery(queries.externalStudy.SINGLE_ASSIGNED_EXTERNAL_STUDY, {
    fetchPolicy: 'no-cache',
    variables: { idx: parseInt(idx) },
  });
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function openEssayModal(essayInfo, name, nameStr) {
    setIsEssayVisible(true);
    setEssayTitle(essayInfo?.title);
    setEssayContent(essayInfo?.content);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }
  function handleOk() {
    setIsEssayVisible(false);
  }

  const assignedExternalStudyData = useMemo(() => {
    if (fetchAssignedExternalStudyDetails?.singleAssignedExternalStudy) {
      setStudyData(JSON.parse(fetchAssignedExternalStudyDetails?.singleAssignedExternalStudy?.external_study_data || '{}'));
      return fetchAssignedExternalStudyDetails?.singleAssignedExternalStudy;
    }
  }, [fetchAssignedExternalStudyDetails]);
  const className = assignedExternalStudyData?.assigned_external_study_group.class.name || '';
  function openRecordingDataModal(info, name, nameStr) {
    setIsRecordingVisible(true);
    //setRecordingContent(info?.recording_data);
    setRecordingData(info);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  const userLessons = useMemo(() => {
    if (assignedExternalStudyData) {
      const bookData = JSON.parse(assignedExternalStudyData?.external_study_data)
      let fetchData = assignedExternalStudyData.external_user_study;
      return fetchData.reduce((acc, cur, index) => {
        const { bookId, gamePlayResults, lastPageVisited, startedAt, timeSpent } = JSON.parse(cur.data) || {
          bookId: 0,
          gamePlayResults: [],
          lastPageVisited: 0,
          pagesVisited: [],
          startedAt: '',
          timeSpent: 0,
        };
        const achievement = gamePlayResults
          ? gamePlayResults.reduce(
              (acc, cur) => {
                if (cur.numberOfAttempts) {
                  acc.score += cur.numberOfAttempts;
                  acc.total += cur.numberOfElements;
                } else if (cur.numberOfCorrectAttempts) {
                  acc.score += cur.numberOfCorrectAttempts;
                  acc.total += cur.numberOfElements;
                } else if (cur.status == 1) {
                  acc.score += 1;
                  acc.total += 1;
                }
                return { ...acc };
              },
              { score: 0, total: 0 },
            )
          : {
              score: 0,
              total: 0,
            };
        const time =
          (parseInt(timeSpent / 3600) > 0 ? parseInt(timeSpent / 3600) + '시간 ' : '') +
          (parseInt((timeSpent % 3600) / 60) > 0 ? parseInt((timeSpent % 3600) / 60) + '분 ' : '') +
          (parseInt(timeSpent % 60) + '초');
        const lastStudyDate = cur.last_study_timestamp ? cur.last_study_timestamp : '';
        const obj = {
          name: `${cur.user.name}(${cur.user.id})`,
          nameStr: `${cur.user.name}`,
          No: index + 1,
          key: cur.idx,
          wpm: cur.wpm ? (
            <span style={{ color: '#289428' }}>
              <Button
                onClick={() => {
                  openRecordingDataModal(cur, cur.user.name, cur.user.id);
                }}
              >
                {cur.wpm}
              </Button>
            </span>
          ) : (
            <span style={{ color: '#ef811a' }}>{settings[companyName].notPerform}</span>
          ),
          recording: cur.recording_data ? (
            <span style={{ color: '#289428' }}>
              <Button
                onClick={() => {
                  openRecordingDataModal(cur, cur.user.name, cur.user.id);
                }}
              >
                {`${(cur.exam_correct * 100) / cur.exam_total}%`}
              </Button>
            </span>
          ) : (
            <span style={{ color: '#ef811a' }}>{settings[companyName].notPerform}</span>
          ),
          time: timeSpent > 0 ? time : '-',
          achievement: achievement.score > 0 ? `${Math.round((achievement.score * 100) / achievement.total)}%` : '0%',
          study_date: lastStudyDate ? moment(lastStudyDate).format('YYYY-MM-DD HH:mm:ss') : '-',
          image : bookData.coverShortUrls||bookData.coverUrls,
          title: bookData.title
          // study_date: lastStudyDate ? moment(lastStudyDate).format('YYYY-MM-DD HH:mm:ss') : '-',
        };

        return [...acc, obj];
      }, []);
    }
    return [];
  }, [assignedExternalStudyData, currentIndx]);
  const lessonStudyDate = useMemo(() => {
    if (assignedExternalStudyData) {
      return moment(new Date(assignedExternalStudyData?.start_date)).format('YYYY-MM-DD');
    }
  }, [assignedExternalStudyData, currentIndx]);

  // const exportToExcel = () => {
  //   ExportExcel(realColumn, userLessons);
  // };
  const openBookrDataModal = (record) => {
    setBookrData(record);
    setBookrVisible(true);
  };
  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      align: 'center',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      sorter: sortByName,
    },
    {
      key: 'study_date',
      title: 'Study Date',
      dataIndex: 'study_date',
      align: 'center',
    },
    // {
    //   key: 'quiz',
    //   title: 'Quiz',
    //   dataIndex: 'quiz',
    //   align: 'center',
    // },
   

    {
      title: 'Bookr Study',
      align: 'center',
      children: [
        {
          title: 'Study Time',
          dataIndex: 'time',
          key: 'time',
          align: 'center',
        },
        {
          title: 'Achievement',
          dataIndex: 'achievement',
          key: 'achievement',
          align: 'center',
          render: (text, record) => {
            return (
              <div
                style={{ cursor: 'pointer', color: '#289428' }}
                onClick={() => {
                  openBookrDataModal(record);
                }}
                >
                {text}{' '}
              </div>
            );
          },
        },
      ],
    },
    {
      title: 'Recording',
      align: 'center',
      children: [
        {
          key: 'Accuracy',
          title: 'Accuracy',
          dataIndex: 'recording',
          align: 'center',
        },
        {
          title: 'WPM',
          dataIndex: 'wpm',
          key: 'wpm',
          align: 'center',
        },
      ],
    },
   
  ];
  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>Bookr Homework Result ({className || ''})</HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="books-result-halfwrapper resultpages-halfwrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              <Title level={5}>
                {studyData?.title} (<Text type="success">{lessonStudyDate}</Text>)
              </Title>
            </div>

            <HomeWorkTable
              loading={loading}
              dataSource={userLessons}
              columns={columns}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ x: 530, y: 390 }}
            />
          </HalfWrapper>
        </Col>
      </Row>
      <Modal
        title={essayTitle}
        width="60%"
        visible={isEssayVisible}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button onClick={handlePrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
            <PrinterTwoTone />
          </Button>,
          <Button type="primary" key="1" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <div ref={componentRef} className="show-print" style={{ padding: 20 }}>
          <div
            className="show-print-flex"
            style={{
              background: '#ebebeb',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: '10px',
            }}
          >
            {/* <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" /> */}
            <Title level={3} style={{ padding: '0 10px' }}>
              Recording Result
            </Title>
          </div>
          <div
            className="show-print-flex"
            style={{
              background: '#ebebeb',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
            }}
          >
            <Title level={3} style={{ padding: '10px' }}>
              Student Name: {currentUserName}
            </Title>
            <Title level={3} style={{ padding: '10px', margin: 0 }}>
              Class: {className}
            </Title>
          </div>
          <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
            {`Title: ${essayTitle}`}
          </Title>
          <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: essayContent }} />
        </div>
        <div class="hide-print" dangerouslySetInnerHTML={{ __html: essayContent }} />
      </Modal>
      <RecordingDataModal
        data={{ ...recordingData, currentUserName, className }}
        isShow={isRecordingVisible}
        onPrint={handlePrint}
        componentRef={componentRef}
        onOk={handleOkForRecordingData}
      />
      <BookrStudyDetailModal
        data={bookrData}
        visible={bookrVisible}
        onCancel={() => {
          setBookrVisible(false);
        }}
      />
    </>
  );
};

export default BookrResultDetail;

const RecordingDataModal = ({ data, isShow, onPrint, componentRef, onOk }) => {
  return (
    <Modal
      title="Recording"
      width="60%"
      visible={isShow}
      onOk={onOk}
      onCancel={onOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button onClick={onPrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
          <PrinterTwoTone />
        </Button>,
        <Button type="primary" key="2" onClick={onOk}>
          확인
        </Button>,
      ]}
    >
      <div ref={componentRef} className="show-print" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '10px',
          }}
        >
          {/* <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" /> */}
          <Title level={3} style={{ padding: '0 10px' }}>
            Recording Result
          </Title>
        </div>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px',
          }}
        >
          <Title level={3} style={{ padding: '10px' }}>
            Student Name: {data?.currentUserName}
          </Title>
          <Title level={3} style={{ padding: '10px', margin: 0 }}>
            Class: {data?.className}
          </Title>
        </div>
        <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
          Recording
        </Title>
        <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
          <span>accuracy: {data?.exam_total ? Math.round((100 * parseInt(data?.exam_correct)) / data.exam_total) : 0}%</span>
          <span>wpm: {data?.wpm ? data.wpm : 0}</span>
        </div>
      </div>
      <div className="hide-print" dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
      <div className="hide-print" style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
        <span>accuracy: {data?.exam_total ? Math.round((100 * parseInt(data?.exam_correct)) / data.exam_total) : 0}%</span>
        <span>wpm: {data?.wpm ? data.wpm : 0}</span>
      </div>
    </Modal>
  );
};
