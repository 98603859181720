import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Checkbox, Input, Button, DatePicker, Space, Modal } from 'antd';
import * as _ from 'lodash';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { Link, useHistory } from 'react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { DELETE_ASSIGN_EXTERNAL_STUDY, DELETE_ASSIGN_STUDY } from 'src/operations/mutations/assignExternalStudy';
import { ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE } from 'src/operations/queries/externalStudy';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { ExportExcel } from 'src/utils/index';
import { isAfterSchool } from 'src/utils/index';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import StarWordsMenuGroup from 'src/components/common/StarWordsMenuGroup';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 800px;
  /* background-color: #ffda00; */
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 225px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  // max-height: 700px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 650px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 20px; */
`;
const ResultBtn = styled(Button)`
  // background-color: #559bdb;
  background-color: #fcbe42;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  // width: 65%;
`;

export default function StarWordsAssignedList() {
  const history = useHistory();
  const companyName = useSelector(classStoreData);
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [filteredData, setFilteredData] = useState([]);
  const [excelDownSwitch, setExcelDownSwitch] = useState({ switch: false, total: null });
  const [ch, setCh] = useState(
    filteredData
      .filter((e) => e.completion === 0)
      .map((e) => {
        let obj;
        obj = {
          no: e.no,
          value: false,
          idx: e.idx,
        };
        return obj;
      }),
  );
  const [localState, setLocalState] = UseHistoryState({
    startDate: moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    searchValue: '',
    pg: { current: 1, pageSize: 10 },
    filterType: null,
  });
  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });
  const [deleteAssignedStudy, { loading }] = useMutation(DELETE_ASSIGN_STUDY, {
    onCompleted: (res) => {
      if (!res?.deleteAssignedStudy) {
        alert('삭제 권한이 없습니다.');
        return;
      }
      reftechList();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });
  useEffect(() => {
    if (!localState.endDate) {
      setLocalState((prev) => {
        return { ...prev, endDate: moment(new Date()).format('YYYY-MM-DD') };
      });
      if (!localState.startDate) {
        setLocalState((prev) => {
          return { ...prev, startDate: moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD') };
        });
      }
    }
  }, [localState]);
  const {
    data: dataAssignedExternalStudyGroupDetail,
    loading: grouploadingdetail,
    refetch: reftechList,
  } = useQuery(ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE, {
    fetchPolicy: 'no-cache',
    variables: {
      start_date: localState.startDate,
      end_date: localState.endDate,
      campus_idx: dataUser?.getUserData?.campus_idx,
      // state: 'ing',
      take: localState.pg?.pageSize ? localState.pg?.pageSize : 10,
      page: localState.pg?.current ? localState.pg?.current - 1 : 0,
      search_text: localState.searchValue ? localState.searchValue : '',
      type: 'starwords',
      class_idx: localState.selectedClass?.idx ? localState.selectedClass?.idx : null,
    },
    skip: !dataUser?.getUserData?.campus_idx,
  });

  const [deleteAssignedExternalGrp, { loading: loadingDelete }] = useMutation(DELETE_ASSIGN_EXTERNAL_STUDY, {
    onCompleted: (res) => {
      if (!res?.deleteAssignedExternalStudy) {
        alert('삭제 권한이 없습니다.');
        return;
      }
      reftechList();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  useEffect(() => {
    const tmp = filteredData.filter((e) => e.completion === 0);
    setCh(
      tmp.map((e) => {
        let obj;
        obj = {
          no: e.no,
          value: false,
          idx: e.idx,
        };
        return obj;
      }),
    );
  }, [filteredData]);
  const sourceData = useMemo(() => {
    let tmp = [];
    if (
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate &&
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study.length > 0
    ) {
      tmp = dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study;
    }
    tmp.map(
      (e, i) => (
        (e.no =
          dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.total -
          (localState.pg?.current - 1) * localState.pg?.pageSize -
          i),
        (e.class_name = e.assigned_external_study_group.class.name),
        ((e.bookData = JSON.parse(e.external_study_data)),
        (e.completion = e.external_user_study.reduce((acc, cur) => {
          const done = cur.status === 2 ? 1 : 0;
          return acc + done;
        }, 0))),
        (e.assigned_date = e.start_date.slice(0, 10))
      ),
    );
    // if(localState.filterType){
    //   tmp = tmp.filter((e)=>e.bookData.bookreport)
    // }
    // console.log(tmp,'tmp')
    setFilteredData(tmp);
    setExcelDownSwitch((prev) => {
      return { ...prev, total: dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.total };
    });
    return tmp;
  }, [dataAssignedExternalStudyGroupDetail, localState.filterType]);
  const handleStartDateChange = (date, dateString) => {
    if (dateString) {
      setLocalState((prev) => {
        return { ...prev, startDate: dateString };
      });
    }
  };
  const handleEndDateChange = (date, dateString) => {
    if (dateString) {
      setLocalState((prev) => {
        return { ...prev, endDate: dateString };
      });
    }
  };

  // const currentPage = (i) => {
  //   return Math.floor(i / 10) === localState.pageNumber;
  // };

  const onChangeCheck = (e, idx, no) => {
    const updatedCh = [...ch];
    const indexToUpdate = updatedCh.findIndex((ele) => ele.no === parseInt(no) && ele.idx === idx);
    if (indexToUpdate !== -1) {
      updatedCh[indexToUpdate].value = e.target.checked;
      setCh(updatedCh);
    }
  };
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
      // render: (_, record, index) => {
      //   return <span>{filteredData?.length - index}</span>;
      // }
    },
    {
      title: 'A.Date',
      dataIndex: 'assigned_date',
      key: 'assigned_date',
      align: 'center',
      width: '150px',
      sorter: (a, b) => a.assigned_date.localeCompare(b.assigned_date),
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },

    {
      title: 'Class Name',
      dataIndex: 'class_name',
      key: 'class_name',
      align: 'center',
      width: 125,
      render: (text) => <span style={{ fontWeight: 'bold', color: 'blue' }}>{text}</span>,
    },
    {
      title: 'Code',
      dataIndex: 'title',
      key: 'title',
      width: '150px',
      align: 'center',
      render: (_, record) => {
        return <span>{record.bookData.code}</span>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '350px',
      align: 'center',
      render: (_, record) => {
        return <span>{record.bookData.title}</span>;
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      align: 'center',
      width: '150px',
      render: (_, record) => {
        let dueDate = record?.bookData?.dueDate || null;
        return (
          <span>
            {record.start_date
              ? dueDate
                ? moment(record.start_date).add(dueDate, 'days').format('YYYY-MM-DD')
                : moment(record.start_date).format('YYYY-MM-DD')
              : '-'}
          </span>
        );
      },
    },
    {
      title: 'Completion',
      dataIndex: 'completion',
      key: 'completion',
      align: 'center',
      width: '110px',
      render: (text, record) => {
        const total = record?.external_user_study?.length;
        return (
          // <span>1/2</span>
          <span>
            {String(text).padStart(String(total).length, '0')}/{total}
          </span>
        );
      },
    },
    {
      excelfilter: true,
      key: 'Edit',
      title: 'Action',
      dataIndex: 'edit',
      align: 'center',
      width: 120,
      // render: (_, row) => {
      render: (text, record) => {
        const className = record?.assigned_external_study_group?.class?.name;
        const title = record?.bookData?.title;
        return record?.completion > 0 ? (
          <Link
            to={{
              pathname: `/star-words/assignedlist/results/${record.idx}`,
              state: { classIdx: record?.assigned_external_study_group?.class?.idx, class_name: record.class_name },
            }}
          >
            <ResultBtn>Result</ResultBtn>
          </Link>
        ) : (
          <Link
            to={{
              pathname: `/star-words/edit/${record?.assigned_external_study_group?.class?.idx}/${record?.idx}/${record?.assigned_external_study_group?.idx}`,
              state: { className, title },
            }}
          >
            <Button>Edit</Button>
          </Link>
        );
      },
    },
    {
      excelfilter: true,
      title: () => {
        return <Checkbox checked={ch.every((e) => e.value)} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            {record?.completion == 0 ? (
              <Checkbox
                id={record.id}
                checked={ch?.length ? ch[ch?.findIndex((element) => element.no === parseInt(record.no))]?.value : ''}
                //  onChange={(e) => onChange(record?.idx,record?.no)}
                onChange={(e) => onChangeCheck(e, record.idx, record.no)}
              ></Checkbox>
            ) : (
              <></>
            )}
          </Space>
        );
      },
      width: 50,
    },
  ];
  function onCheckAllChange(e) {
    const arr2 = _.cloneDeep(ch);
    if (e.target.checked) {
      setCh(
        arr2.map((e) => {
          const obj = {
            no: e.no,
            idx: e.idx,
            value: true,
          };
          return obj;
        }),
      );
    } else {
      setCh(
        arr2.map((e) => {
          const obj = {
            no: e.no,
            idx: e.idx,
            value: false,
          };
          return obj;
        }),
      );
    }
  }
  const onSeachChange = (val) => {
    if (val.target.value) {
      setLocalState((prev) => {
        return { ...prev, searchValue: val.target.value };
      });
    } else {
      setLocalState((prev) => {
        return { ...prev, searchValue: '' };
      });
    }
  };
  const handlePageChange = (pagination) => {
    setLocalState((prev) => {
      return {
        ...prev,
        pg: pagination,
      };
    });
  };
  const handleDelete = () => {
    Modal.confirm({
      icon: null,
      //title: 'Delete',
      content: (
        <Title level={5} style={{ textAlign: 'left', lineHeight: 2 }}>
          삭제하시겠습니까?
        </Title>
      ),
      onOk: () => {
        const filteredIdxValues = ch.filter((item) => item.value === true).map((item) => item.idx);
        deleteAssignedStudy({
          variables: {
            idx: JSON.stringify(filteredIdxValues),
          },
        });
      },
      onCancel: () => {
        //do nothing
      },
    });
  };
  const handleChange = (e) => {
    setLocalState((prev) => {
      return { ...prev, selectedClass: classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0] };
    });
  };
  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <StarWordsMenuGroup currentMenu={'list'} />
        </RowFlex>
        <MainWrapper className="cust-assign-list-main">
          <Main className="cust-assign-list">
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Assigned List & Results</SubTitle>
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {localState.selectedClass?.idx >= 0 ? (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                ) : (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                )}
                &nbsp;&nbsp;&nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  value={localState.searchValue}
                />
                &nbsp;
                <DatePicker
                  allowClear={false}
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  value={moment(
                    localState.startDate !== '' ? localState.startDate : moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD'),
                  )}
                />
                ~
                <DatePicker
                  allowClear={false}
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  value={moment(localState.endDate !== '' ? localState.endDate : moment(new Date()).format('YYYY-MM-DD'))}
                />
                &nbsp;
                <Button type="danger" onClick={handleDelete}>
                  Delete
                </Button>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              className="assign-table-list"
              pagination={{
                pageSize: localState.pg?.pageSize,
                current: localState.pg?.current,
                total: dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.total || 0,
                position: ['bottomCenter'],
              }}
              loading={grouploadingdetail}
              dataSource={sourceData}
              columns={columns}
              key={(r) => r.idx}
              size="small"
              // color="#ffebc2"
              // borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
              onChange={(pagination) => {
                handlePageChange(pagination);
              }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
