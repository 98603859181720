import React, { useState, useMemo } from 'react';
// import { Switch, Route, Link } from 'react-router-dom';
// import { Col, Row, Radio, Button, Form, Input, InputNumber, Cascader, Select, Checkbox, AutoComplete, Typography, List } from 'antd';
import styled from 'styled-components';
import { GET_ORDER_ITEM_LIST_META } from 'src/operations/queries/getLevelTest';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { getMetaExamId } from 'src/utils';
import moment from 'moment';
import { GET_USER_INFO } from 'src/operations/queries/campus';
// const { Option } = Select;

const InnerWrap = styled.div`
  background-color: #fff;
  width: 1400px;
  height: 700px;
  padding: 2rem 1rem;
  margin: 1rem auto 3rem;
  border-radius: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    height: 1000px;
    width: calc(90% - 15rem);
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;

    & {
      .meta-header {
        h1.main-tit {
          font-size: 3.6rem;
        }
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 9) {
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
    margin: 0 auto;
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 10) {
    width: 75%;
    margin: 1rem auto;
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 750px;
    margin: 0 auto 0rem;

    & {
      .meta-header {
        margin: 0 auto 3rem;

        h1.main-tit {
          margin-top: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    width: 1200px;
    margin: 1rem auto 0;
    height: 600px;
  }

  @media (max-width: 1440px) and (aspect-ratio: 16/10) {
    width: 75%;
    height: 65%;
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    background-color: #fff;
    width: calc(100% - 10rem);
    height: calc(100% - 10rem);
    padding: 0rem 1rem;
    margin: 3rem auto;

    .meta-header {
      position: relative;
      text-align: center;
      width: 100%;
      margin: 0rem auto;
      display: block;
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16 / 9) {
    width: 1200px;
    max-width: 100%;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 600px;
  }

  @media (max-width: 1400px) and (aspect-ratio: 16/9) {
    height: 70%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;

    margin: 0 auto;

    & {
      h1.main-tit {
        font-size: 2rem;
      }
    }
  }

  @media (max-width: 1300px) {
  }

  @media (max-width: 1280px) {
    width: calc(75rem - 10rem);
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 1280px) and (aspect-ratio: 16 / 9) {
    margin: 0 auto;
    height: 500px;
  }

  @media (max-width: 1200px) {
    width: 900px;
    height: 500px;
    position: relative;
    margin: 0 auto;
    padding: 0 1rem;
  }

  @media (max-width: 1024px) and (aspect-ratio: 16 / 9) {
    height: auto;
    margin: 3rem auto;
    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }

      .meta-header {
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 1024px) and (aspect-ratio: 16 / 10) {
    top: 52%;
  }

  @media (max-width: 960px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 800px;
    max-height: 380px;
    position: relative;
  }

  @media screen and (max-width: 900px) {
    width: 750px;
  }

  @media screen and (max-width: 800px) {
    width: 450px;
    height: auto;
  }

  @media screen and (max-width: 800px) and (orientation: portrait) {
    width: 550px;
    height: auto;
    margin: 1rem auto 1rem;
  }

  @media screen and (max-width: 700px) and (orientation: portrait) {
    width: 550px;
    height: auto;
    margin: 2rem auto 1rem;
  }

  @media screen and (max-width: 700px) and (orientation: portrait) {
    width: 550px;
    height: auto;
    margin: 2rem auto 1rem;
  }

  @media screen and (max-width: 600px) and (orientation: portrait) {
    width: 500px;
    height: auto;
    margin: 1rem auto 1rem;
  }

  @media screen and (max-width: 425px) {
    width: 350px;
    margin: 1rem auto;
    padding-bottom: 1rem;
    & {
      h1.main-tit {
        margin: 2rem auto 0;
      }
    }
  }

  @media screen and (max-width: 375px) {
    & {
      h1.main-tit {
        font-size: 1.4rem;
        margin: 1rem auto 0;
      }
    }
  }
`;

const MetaExamType = () => {
  const history = useHistory();
  const client = useApolloClient();
  const location = useLocation();
  const [userObj, setUserObj] = useState({});
  if (!location?.state?.idx_list && localStorage.getItem('meta_idx_list')) {
    location.state = { idx_list: JSON.parse(localStorage.getItem('meta_idx_list')) };
  }
  const { loading, data } = useQuery(GET_ORDER_ITEM_LIST_META, { variables: { idx_array: location?.state?.idx_list } });

  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 ? true : false;

  const levels = useMemo(() => {
    let levels = {};
    if (data) {
      const allDone = data?.orderItemListByIdx?.filter((item) => item?.stat != 1)?.length === 0;
      let validTests = data?.orderItemListByIdx;
      if (!allDone && data?.orderItemListByIdx) {
        validTests = data?.orderItemListByIdx?.filter((item) => item?.stat != 1);
        storage.setItem('meta_idx_list', JSON.stringify(validTests?.map((item) => item?.idx)));
      }
      for (let i = 0; i < validTests?.length; i++) {
        if (validTests[i].order_leveltest) {
          for (let j = 0; j < validTests[i]?.order_leveltest?.length; j++) {
            const item = validTests[i]?.order_leveltest[j];
            let done = false;
            if (item?.assigned_tests?.assigned_test_users) {
              if (item?.assigned_tests?.assigned_test_users[0].status !== 1) {
                done = true;
              }
            }
            if (item?.assigned_tests?.tests?.test_type) {
              levels[item?.assigned_tests?.tests?.test_type] = {
                title: `${item?.assigned_tests?.tests?.title}`,
                assigned_tests_idx: item?.assigned_tests?.idx,
                done,
                batch_type: `${item?.assigned_tests?.tests?.batch_type}`,
              };
            }
            if (
              Object.keys(userObj).length === 0 &&
              item?.assigned_tests?.assigned_test_users?.length > 0 &&
              item?.assigned_tests?.assigned_test_users[0]?.user
            ) {
              const newUserObj = item?.assigned_tests?.assigned_test_users[0]?.user;
              setUserObj({
                id: getMetaExamId(newUserObj.birthday, newUserObj?.parent_phone),
                korean_name: newUserObj.english_name,
                test_date: moment(item?.assigned_tests?.start_time).format('YYYY-MM-DD'),
              });
            }
          }
        }
      }
    }
    return levels;
  }, [data]);

  if (!location?.state?.idx_list) {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/meta-login');
    return <Redirect to={`/meta-login`} />;
  }
  const type = Object.keys(levels).length == 0 ? '' : 'Type-' + levels[Object.keys(levels)[0]].batch_type;
  const isPreTest = type.startsWith('Type-PRE');

  const handleClick = (data) => {
    if (data.done) {
      return;
    }
    history.push(`/MetaExam/MetaTestStart/${data.assigned_tests_idx}`);
  };

  return (
    <>
      <div className={isWEnglish ? 'meta-con meta-full-con w-english-bg' : 'meta-con meta-full-con'}>
        <div className="meta-header type-header">
          <div>
            <h2 style={{ marginBottom: '0' }}>
              {' '}
              <span style={{ margin: '0 0.5rem' }}>{userObj?.korean_name}</span> {userObj?.id}
            </h2>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <h2> {userObj?.test_date} </h2>
          </div>
        </div>
        <InnerWrap>
          <div className="meta-header">
            <h1 className="main-tit">
              {isWEnglish ? '' : 'MetaExam'} {type}
            </h1>
          </div>
          {Object.keys(levels).length == 0 && (
            <div className="exam-type-con">
              <h2>No Test Assigned To You</h2>
            </div>
          )}
          {Object.keys(levels).length != 0 && (
            <div className="exam-type-con">
              {levels['vocabulary'] && (
                <div className="test-wrap">
                  <div onClick={() => handleClick(levels['vocabulary'])}>
                    <img src="/images/metaIcn1.png" alt="icn1" />
                    <h3>Vocabulary</h3>
                  </div>
                  {levels['vocabulary'].done && <img src="/images/check_off.png" alt="check_off" className="meta-chkImg" />}
                  {!levels['vocabulary'].done && <img src="/images/check_on.png" alt="check_on" className="meta-chkImg" />}
                </div>
              )}
              {levels['reading'] && (
                <div className="test-wrap">
                  <div onClick={() => handleClick(levels['reading'])}>
                    <img src={isPreTest ? '/images/metaIcn2_2.png' : '/images/metaIcn2.png'} alt="icn2" />
                    <h3>Reading</h3>
                  </div>
                  {levels['reading'].done && <img src="/images/check_off.png" alt="check_off" className="meta-chkImg" />}
                  {!levels['reading'].done && <img src="/images/check_on.png" alt="check_on" className="meta-chkImg" />}
                </div>
              )}
              {levels['listening'] && (
                <div className="test-wrap">
                  <div onClick={() => handleClick(levels['listening'])}>
                    <img src="/images/metaIcn3.png" alt="icn3" />
                    <h3>Listening</h3>
                  </div>
                  {levels['listening'].done && <img src="/images/check_off.png" alt="check_off" className="meta-chkImg" />}
                  {!levels['listening'].done && <img src="/images/check_on.png" alt="check_on" className="meta-chkImg" />}
                </div>
              )}
              {levels['grammar'] && (
                <div className="test-wrap">
                  <div onClick={() => handleClick(levels['grammar'])}>
                    <img src="/images/metaIcn4.png" alt="icn4" />
                    <h3>Grammar</h3>
                  </div>
                  {levels['grammar'].done && <img src="/images/check_off.png" alt="check_off" className="meta-chkImg" />}
                  {!levels['grammar'].done && <img src="/images/check_on.png" alt="check_on" className="meta-chkImg" />}
                </div>
              )}
            </div>
          )}
        </InnerWrap>
        {!isWEnglish && !isPreTest && (
          <div className="report-footer-con">
            <h5>
              Powered by {'     '}
              <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
              </span>
            </h5>
            <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default MetaExamType;
