import React, { useState, useEffect,useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { SoundFilled, MutedFilled, HomeFilled } from '@ant-design/icons';
import * as S from './style';
import styled from 'styled-components';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
const LevelList = ['1a', '1b', '2a', '2b', '3a', '3b', '4a', '4b'];
export default function PhonicsMonsterMain() {
  const history = useHistory();
  const [isMute, setIsMute] = useState(false);
  const [clickSound, setClickSound] = useState("");
  const clickedUrlRef = useRef(undefined);
  useEffect(()=>{
    const pmMute =  window.localStorage.getItem('pm_Mute')
    if(pmMute)
      {setIsMute(true)}
  },[])
  useEffect(()=>{
    if(isMute){
      window.localStorage.setItem('pm_Mute',true)
    }
    else{
      window.localStorage.removeItem('pm_Mute',true)
    }
  },[isMute])
  const handleClick = (path) => {
    console.log('clicked')
		setClickSound("https://cdn.cloubot.com/PM/audio/sound-effect/game-choose.mp3");
		clickedUrlRef.current = path;
	};
  const handleSoundEffectEnd = () => {
    setClickSound('')
	};
  useEffect(()=>{
    if(clickSound===''&&clickedUrlRef.current)
    history.push(clickedUrlRef.current);

  },[clickedUrlRef,clickSound])
  return (
    <>
    <S.fontStyle/>
    <S.Root>
      <S.RowFlex style={{ width: '85vw', justifyContent: 'right',paddingBottom:10 }}>
        <S.Button1 onClick={() => setIsMute((prev) => !prev)} style={{marginRight:10, width:50, height:50}}>
          {isMute ? (
            <MutedFilled style={{ fontSize: '1.5rem', color: '#fff' }} />
          ) : (
            <SoundFilled style={{ fontSize: '1.5rem', color: '#fff' }} />
          )}
        </S.Button1>
        <S.Button1 style={{ width:50, height:50}} onClick={()=>{history.push('/')}}><HomeFilled style={{ fontSize: '1.4rem', color: '#fff' }}/></S.Button1>
      </S.RowFlex>
      <S.Main>
        <S.BookListWrapper>
          <S.BookList>
            {LevelList.map((e) => (
              <S.BookCover
              onClick={() => {
                handleClick(`/pm/${e}`);
                }}
                >
                <Cover src={`https://cdn.cloubot.com/PM/image/cover/book_level_${e}_on.png`} />
                <LevelText>Level {e}</LevelText>
              </S.BookCover>
            ))}
          </S.BookList>
        </S.BookListWrapper>
      </S.Main>
      <SoundEffectPM url={clickSound} onEnd={handleSoundEffectEnd} />
      {!isMute && <SoundEffectPM url={'https://cdn.cloubot.com/PM/audio/sound-effect/just-joking-fun.mp3'} replayCnt={0} />}
    </S.Root>
</>
  );
}
const device = {
  smallDesktop: `(max-width: 1280px)`,
  tablet: `(max-width: 780px)`,
};
const Cover = styled.img`
  max-width: 100%;
  height: 100%;
  vertical-align: middle;
`;
const LevelText = styled.p`
  font-size: 23px;
  font-family: 'BalsamiqSans';
  /* font-weight: bold; */
  margin: 0;
  padding: 0;
  font-weight: 600;
  @media ${device.smallDesktop} {
    font-size: 18px;
  }
  @media ${device.tablet} {
    font-size: 15px;
  }
`;
