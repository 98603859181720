import React from "react";
import SoundButton from "./SoundButton";
import * as S from 'src/pages/PhonicsMonster/style';
import styled from "styled-components";

const AlphabetTopArea = ({title, soundUrl, isShortVowel}) => {
	return (
		<Wrap  className="top_area">
			<S.RibbonTitle isShortVowel={isShortVowel} className={`tit_ribbon ${isShortVowel ? "hidden" : ""}`}>
				<span style={{color:'#fff', paddingBottom:6}}>{title}</span>
			</S.RibbonTitle>
			{soundUrl && <SoundButton src={soundUrl} />}
		</Wrap>
	);
};

export default AlphabetTopArea;
const Wrap = styled(S.RowFlex)`
margin: 1.2rem;
@media (max-height: 500px) {
    margin: 0.2rem 1.2rem;
  }
`;