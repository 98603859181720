import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import _ from 'lodash';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #453ec8;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled(ColumnFlex)`
  border-radius: 0 0 13px 13px;
  /* padding: 20px 30px; */
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  min-height: 600px;
  align-items: center;
  min-width: 1000px;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
`;
const CoverWrapper = styled(ColumnFlex)`
  align-items: center;
`;
const Cover = styled.div`
  width: 200px;
  height: 263px;
  background-image: url(${(props) => props.url});
  background-size: 200px 263px;
  border-radius: 20px 0 0 20px;
`;
const InfoOutWrapper = styled(RowFlex)`
  width: 80%;
  margin: 25px 0;
  /* padding: 20px 35px; */
  height: 263px;
  border-radius: 20px;
  background-color: #ffb900;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
`;
const InfoMidWrapper = styled(ColumnFlex)`
  justify-content: space-between;
  padding: 20px 15px;
  width: 65%;
`;
const InfoRightWrapper = styled(ColumnFlex)`
  justify-content: space-between;
  padding: 20px 15px;
  width: 30%;
  height: 68%;
`;
const InfoInnerWrapper = styled(RowFlex)`
  align-items: start;
`;
const InfoTitle = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  padding: 7px;
  width: 100px;
  min-width: 100px;
`;
const InfoText = styled(RowFlex)`
  background-color: #fff1cc;
  font-size: 20px;
  color: #717171;
  /* line-height: 2.2; */
  align-items: center;
  padding: 7px;
  border: 1px solid #c4b693;
  border-radius: 5px;
  width: 100%;
  min-width: ${(props) => (props.right ? '75px' : '200px')};
  justify-content: ${(props) => props.right && 'center'};
  /* @media (max-width: 1280px) {
    font-size: 16px;
  } */
`;

const SummaryText = styled.div`
  border: 1px solid #c4b693;
  border-radius: 5px;
  background-color: #fff1cc;
  color: #717171;
  font-size: 20px;
  line-height: 1.6;
  height: 120px;
  padding: 10px;
  overflow: auto;
  /* white-space: nowrap; */
  width: 100%;
  /* @media (max-width: 1280px) {
    font-size: 16px;
  } */
`;
const LikeImage = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  min-height: 36px;
  background-image: url(${(props) => (props.toggle ? '/images/book/heart-on.png' : '/images/book/heart-off.png')});
  background-size: 25px 22px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 18px;
  background-color: #fff;
  margin: 20px 25px 20px 15px;
`;
const StageButton = styled.div`
  width: 150px;
  height: 132px;
  border-radius: 30px;
  /* border: 1px red solid; */
  cursor:pointer;
  background-image: url(${(props)=>props.bg});
  background-size:  150px 132px;
  background-repeat: no-repeat;
  @media (max-width: 1280px) {
    width: 114px;
    height: 100px;
    border-radius: 20px;
  }
`;
const StageText = styled.div`
  cursor: pointer;
  width: 130px;
  border: 3px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-size: 20px;
  font-weight: 600;
  padding:10px;
  text-align: center;
  border-radius: 25px;
  margin: 10px;
  @media (max-width: 1280px) {
    width: 95px;
    font-size: 16px;
    border-radius: 23px;
  }
`;
export default function EngstaBookStudyPage() {
  const [likeToggle, setLikeTogle] = useState(false);
  const history = useHistory();
  const book = {
    author: 'Cloubot',
    title: 'Curious Cats',
    publisher: 'ClouBot',
    url: 'https://cdn.cloubot.com/LEVELUP/covers/9781433955877.png',
    mx: 4,
    lexile: '230L',
    grl: 'H',
    classification: 'Fiction',
    page: 32,
    summary: 'Cats are curious.',
  };
  const handleLike = () => {
    if (likeToggle) {
      setLikeTogle(false);
    } else {
      setLikeTogle(true);
    }
  };
  return (
    <Wrapper>
      <MainWrapper>
        <Main>
          <Title>{book.title}</Title>
          <InfoOutWrapper>
            <CoverWrapper>
              <Cover url={`https://cdn.cloubot.com/LEVELUP/covers/9781433955877.png`} />
            </CoverWrapper>
            <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
              <InfoMidWrapper>
                <InfoInnerWrapper>
                  <InfoTitle>Author</InfoTitle>
                  <InfoText>{book?.author}</InfoText>
                </InfoInnerWrapper>
                <InfoInnerWrapper>
                  <InfoTitle>Publisher</InfoTitle>
                  <InfoText> {book?.publisher}</InfoText>
                </InfoInnerWrapper>
                <InfoInnerWrapper>
                  <InfoTitle>Summary</InfoTitle>
                  <SummaryText>{book?.summary}</SummaryText>
                </InfoInnerWrapper>
              </InfoMidWrapper>
              <InfoRightWrapper>
                <InfoInnerWrapper>
                  <InfoTitle>MX Level</InfoTitle>
                  <InfoText right> {book?.mx}</InfoText>
                </InfoInnerWrapper>
                <InfoInnerWrapper>
                  <InfoTitle>Lexile</InfoTitle>
                  <InfoText right> {book?.lexile}</InfoText>
                </InfoInnerWrapper>
                <InfoInnerWrapper>
                  <InfoTitle>GRL</InfoTitle>
                  <InfoText right> {book?.grl}</InfoText>
                </InfoInnerWrapper>
              </InfoRightWrapper>
              <LikeImage onClick={handleLike} toggle={likeToggle} />
            </RowFlex>
          </InfoOutWrapper>
          <RowFlex style={{ width: '80%', padding: ' 20px 60px 30px', justifyContent: 'space-between' }}>
            <ColumnFlex style={{alignItems:'center'}} onClick={()=>{history.push(`/engsta/voca/card`)}}>
              <StageButton bg={`/images/LevelUp/study/main/words.png`}></StageButton>
              <StageText color={'#ff005c'}>Words</StageText>
            </ColumnFlex>
            <ColumnFlex style={{alignItems:'center'}}>
            <StageButton bg={`/images/LevelUp/study/main/reading.png`}></StageButton>
              <StageText color={'#feda02'}>Reading</StageText>
            </ColumnFlex>
            <ColumnFlex style={{alignItems:'center'}}  onClick={()=>{history.push(`/engsta/voca/quiz`)}}>
            <StageButton bg={`/images/LevelUp/study/main/quiz.png`}></StageButton>
              <StageText color={'#49ada1'}>Quiz</StageText>
            </ColumnFlex>
            <ColumnFlex style={{alignItems:'center'}}>
            <StageButton bg={`/images/LevelUp/study/main/activity.png`}></StageButton>
              <StageText color={'#0ba7fe'}>Activity</StageText>
            </ColumnFlex>
            <ColumnFlex style={{alignItems:'center'}} onClick={()=>{}}>
            <StageButton bg={`/images/LevelUp/study/main/game.png`}></StageButton>
              <StageText color={'#453ec8'}>Game</StageText>
            </ColumnFlex>
          </RowFlex>
        </Main>
      </MainWrapper>
    </Wrapper>
  );
}
