import React, { useState } from 'react';
import { Row, Col, Select, Typography, Button, Input, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { ASSIGN_BOOK_TO_STUDENTS } from 'src/operations/mutations/bookCredit';
import moment from 'moment';
const { Text } = Typography;
const { Option } = Select;

const BookSelectAssignStudents = ({ item, num, loading, student, campus_idx, refetchAll }) => {
  const [book_code, setBook_code] = useState(undefined);
  const [book_days, setBookDays] = useState(0);

  const [assignBookCredit, { loadingAssign }] = useMutation(ASSIGN_BOOK_TO_STUDENTS, {
    onCompleted: ({ AssignBookToStudents: resp }) => {
      if (resp.result === 'success') {
        Modal.info({
          title: '이용권을 부여했습니다.',
          onOk: () => {
            //어디로 가나?
            setBook_code(undefined);
            setBookDays(0);
            refetchAll();
          },
        });
      } else {
        Modal.info({
          title: '오류가 발생했습니다.',
        });
      }
    },
    onError: (e) => {
      console.log(e);
      alert('오류가 발생했습니다.');
    },
  });

  const handleRegister = async () => {
    if (!book_code) {
      alert('교재를 선택하세요.');
      return false;
    }
    if (!book_days) {
      alert('갯수를 선택하세요.');
      return false;
    }

    assignBookCredit({
      variables: {
        assign_book_input: {
          campus_idx: campus_idx,
          book_code,
          user_idx: [student?.idx],
          class_idx: student?.class_student[0]?.class?.idx,
          study_type: 'exp',
          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().add(book_days, 'days').format('YYYY-MM-DD'),
          days: book_days,
        },
      },
    });
  };
  const handleInputChange = (event) => {
    const numReg = /[0-9]+/;
    if (!numReg.test(event.target.value)) {
      setBookDays('');
      return;
    }
    setBookDays(parseInt(event.target.value));
  };

  return (
    <Row gutter={[16, 16]}>
      {/* <Col span={4}>
        <Text>{item.book.code}</Text>
      </Col> */}
      <Col span={8}>
        <Select
          placeholder="교재선택"
          style={{ marginRight: '5px', width: '100%' }}
          onChange={(value) => {
            setBook_code(value);
          }}
        >
          {item.book_list &&
            item.book_list.map((book, key2) => (
              <Option key={`book-option-key${num}${key2}`} value={book.code}>
                {book.code}
              </Option>
            ))}
        </Select>
      </Col>
      <Col span={6} style={{display:'flex'}}>
      <label>Days</label>&nbsp;&nbsp;
        <Input maxLength={2} onChange={handleInputChange} placeholder="기간" value={book_days} />
      </Col>
      <Col span={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button style={{ width: '100%' }} type="primary" size="small" loading={loading || loadingAssign} onClick={handleRegister}>
          발급
        </Button>
      </Col>
    </Row>
  );
};

export default BookSelectAssignStudents;
