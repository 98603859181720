import { gql } from '@apollo/client';

export const GET_BOOK_LIST = gql`
  query bookList {
    bookList {
      code
      prefix
      level_name
      volume_name
      parent_code
    }
  }
`;

export const GET_MY_EBOOK_LIST = gql`
  query myEbookList {
    myEbookList {
      prefix
      code
      level_no
      volume_no
    }
  }
`;

export const GET_BOOK_CATEGORY_LIST = gql`
  query bookCategoryList {
    bookCategoryList {
      idx
      name
      no
    }
  }
`;

export const GET_BOOK_SERIES = gql`
  query bookSeries($search_text: String!) {
    bookSeries(search_text: $search_text) {
      code
      parent_code
      stat
      book_sub {
        idx
        title
        book_code
        category
        price
        no
        cover_path
        short_title
        number_of_levels
        number_of_volumes
        number_of_lessons
        shopping_mall_product
        credit_system
        credit_duration
        book_category {
          idx
          name
          no
        }
      }
    }
  }
`;

export const CODE_EXIST_CHECK = gql`
  query isCodeExists($code: String!) {
    isCodeExists(code: $code) {
      code
    }
  }
`;

export const GET_BOOK_LIST_BY_PARENT_CODE = gql`
  query bookListByParentCode($parent_code: String!) {
    bookListByParentCode(parent_code: $parent_code) {
      code
      parent_code
      level_no
      level_name
      volume_no
      volume_name
      evine_level
      sort_evine
      prefix
      stat
      has_leveltest
      has_textbook
      ticket_term1
      ticket_term2
      ticket_term3
      book_sub {
        idx
        title
        book_code
        category
        price
        no
        cover_path
        short_title
        introduction
        author
        publisher
        ebook_path
        cooidx
        bidx
        recommend_flag
        orderno
        pages
        lexile
        glr
        sort_lexile
        sort_glr
        genre
        easy
        level2
        additional_data
      }
    }
  }
`;
