const LearningMenuJson = [
    {
      slug: '',
      value: "Please Select",
    },
    {
      slug: 'phonics',
      value: "Phonics",
    }
];
export default LearningMenuJson;