import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { userTypVar } from 'src/apollo/cache';
import { userInfoVar } from 'src/apollo/cache'; 
import * as queries from 'src/operations/queries';
const { Option } = Select;

const TeacherSelectBox = ({ style, isAll, selectedTeacherIdx, onChange }) => {
  const { data, loading } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: { type: userTypVar().teacher },
  });
  const companyName = userInfoVar()?.company_name;
  const selectClassOptions = useMemo(() => {
    const defautlAllOption = (
      <Option key={`teacher-options-0`} value={0}>
        {/* {isAll ? `전체` : `선택`} */}
        {companyName !== "smart-eclass"?(isAll ? `강사 선택` : `선택`):(isAll ? `전체` : `선택`)}
      </Option>
    );
    if (data?.getUsers) {
      console.log('data', data.getUsers);
      return [
        defautlAllOption,
        ...data.getUsers.map((ele) => (
          <Option key={`teacher-options-${ele.idx}`} value={ele.idx}>
            {ele.name}
          </Option>
        )),
      ];
    }
    return [defautlAllOption];
  }, [isAll, data]);

  return (
    <Select style={{ ...style }} value={selectedTeacherIdx} loading={loading} onChange={onChange}>
      {selectClassOptions}
    </Select>
  );
};

export default TeacherSelectBox;

export const useTeacherSelectBox = () => {
  const [selectedTeacherIdx, setSelectedTeacherIdx] = useState(0);

  const onTeacherChange = (teacherIdx) => {
    setSelectedTeacherIdx(teacherIdx);
  };
  return { selectedTeacherIdx, onTeacherChange };
};
