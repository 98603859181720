import React, { useState, useEffect } from 'react';
// import { Row } from 'antd';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ASSIGNED_TEST_USER_SINGLE_META } from 'src/operations/queries/getTests';
import ReadingInfo from './ReadingInfo';
import ReadingTest from './ReadingTest';

const MetaTestStart = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const [test, setTest] = useState(null);
  const [showInfo, setShowInfo] = useState(true);
  const testType = test?.assigned_tests?.tests?.test_type || 'reading';
  const batchType = test?.assigned_tests?.tests?.batch_type || 'A-1';
  const totalQuestions = test?.assigned_tests?.tests?.total_questions || 0;
  const timeLimit = test?.assigned_tests.limit_time || 0;

  const { loading, error, data } = useQuery(ASSIGNED_TEST_USER_SINGLE_META, {
    variables: {
      idx: parseInt(id),
    },
  });

  const handleGoHome = () => {
    history.push('/MetaExam/MetaExamType', {
      idx_list: location.state?.idx_list,
      batch_type: data.batch_type,
    });
  };

  useEffect(() => {
    if (data) {
      setTest(data.assignedTestUserSingle);
    }

    if (data?.assignedTestUserSingle?.status === 2) {
      handleGoHome();
    }
  }, [data]);

  return showInfo ? (
    <ReadingInfo
      timeLimit={timeLimit}
      testType={testType}
      batchType={batchType}
      totalQuestions={totalQuestions}
      onClick={() => setShowInfo(false)}
    />
  ) : (
    <ReadingTest test={test} deadline={timeLimit * 60} handleGoHome={handleGoHome} />
  );
};

export default MetaTestStart;
