import React, { useMemo } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const DailyTestCenterMenuGroup = ({ currentMenu}) => {
  const history = useHistory();
  const companyName = useSelector(classStoreData);
  const isSmartEclass = useMemo(() => {
    return isAfterSchool(companyName);
  }, [companyName]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
      <MenuButton
        type={`${currentMenu === 'test-management' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/daily/test/syllabus/main')
        }}
      >
        Test Management
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'test-result' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/daily/test/syllabus/result')
        }}
      >
        Test Result
      </MenuButton>
    </div>
  );
};

export default DailyTestCenterMenuGroup;
