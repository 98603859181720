import React, {useState, useEffect} from 'react';
import Sound from "react-sound";

const SoundWords = ({words, onEnd}) => {
	// console.log(words);
	const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
	const [soundIndex, setSoundIndex] = useState(0);
	const handleFinishPlay = () => {
		setPlayStatus(Sound.status.STOPPED);
		
		if (soundIndex === words.length-1 ) {
			if (onEnd instanceof Function) {
				onEnd();
			}
		}	else {
			setSoundIndex(soundIndex+1);
			setPlayStatus(Sound.status.PLAYING);
		}
	};

	useEffect(() => {
		if (words) {
			setPlayStatus(Sound.status.PLAYING);
		}
		return () => {
			setPlayStatus(Sound.status.STOPPED);
			setSoundIndex(0);
		};
	}, [words]);

	return <Sound url={words[soundIndex] ? `https://cdn.cloubot.com/PM/audio/sounds/${words[soundIndex]}.mp3` : ''} playStatus={playStatus} onFinishedPlaying={handleFinishPlay} />;
};

export default SoundWords;