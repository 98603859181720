import React, { useState } from 'react';
import { Form, Row, Modal, Button } from 'antd';
import { useMutation } from '@apollo/client';

import CreateStudentForm from 'src/pages/Students/CreateStudentForm';
import UpdateStudentForm from 'src/pages/Students/UpdateStudentForm';
import PostcodeModal from 'src/components/Modal/PostcodeModal';

import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';
import { useModal } from 'src/utils/hooks';
import { openNotification } from 'src/components/common/Notification';
import { userTypVar, userInfoVar } from 'src/apollo/cache';

function StudentModal({ userInfo = null, popupMode, handleCancel, myInfo = false, refetch, visible }) {
  const [form] = Form.useForm();

  const initialState = {
    idx: userInfo?.idx,
    name: userInfo?.name,
    birthday: userInfo?.birthday,
    id: userInfo?.id,
    pw: null,
    phone: userInfo?.phone,
    parent_name: userInfo?.parent_name,
    parent_phone: userInfo?.parent_phone,
    english_name: userInfo?.english_name,
    email: userInfo?.email,
    school_name: userInfo?.school_name,
    school_grade: userInfo?.school_grade,
    address: userInfo?.address,
    memo: userInfo?.memo,
    type: userTypVar().student,
    lexile_level: userInfo?.lexile_level,
  };
  var company_name = userInfoVar()?.company_name;
  const [values, setValues] = useState(initialState);
  const { visible: postVisible, showModal: showPostModal, handleCancel: handlePostCancel } = useModal();

  const [mutate, { loading }] = useMutation(mutations.createUser.CREATE_USER, {
    variables: values,
    refetchQueries: [
      {
        query: queries.getUser.GET_USERS,
        variables: { type: userTypVar().student },
      },
      {
        query: queries.getUser.GET_USER,
      },
    ],
    onCompleted(data) {
      if (data) {
        if (refetch) {
          refetch();
        }
        openNotification('학생 등록 완료!');
        ActionCancel();
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];

      Modal.error({
        title: `${message}`,
      });
    },
  });

  const [update, { loading: editing }] = useMutation(mutations.updateUser.UPDATE_USER, {
    variables: { ...values, idx: userInfo?.idx },
    refetchQueries: [
      {
        query: queries.getUser.GET_USERS,
        variables: { type: userTypVar().student },
      },
      {
        query: queries.getUser.GET_USER,
      },
    ],
    onCompleted(data) {
      if (data) {
        if (refetch) {
          refetch();
        }
        openNotification('수정 완료!');
        handleCancel();
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];

      Modal.error({
        title: `${message}`,
      });
    },
  });

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setValues((prev) => {
          return {
            ...prev,
            id: formData.id,
            name: formData.name,
            email: formData.email,
            english_name: formData.english_name,
            pw: formData.pw,
            phone: formData.phone,
            parent_name: formData.parent_name,
            parent_phone: formData.parent_phone,
            school_name: formData.school_name,
            school_grade: formData.school_grade,
            address: formData.address,
            memo: formData.memo,
            birthday: formData.birthday === '' || formData.birthday === null ? null : formData.birthday,
            lexile_level: formData.lexile_level,
          };
        });

        formCallback();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  // 날짜
  function onDateChange(name, dateString) {
    setValues((prev) => ({
      ...prev,
      [name]: dateString,
    }));
  }

  function formCallback() {
    popupMode === 'create' ? mutate() : update();
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }
  const gradeDivide = ['미취학', '초등', '중등', '고등'];
  const gradeList = [
    ['5세', '6세', '7세'],
    ['초등 1학년', '초등 2학년', '초등 3학년', '초등 4학년', '초등 5학년', '초등 6학년'],
    ['중등 1학년', '중등 2학년', '중등 3학년'],
    ['고등 1학년', '고등 2학년', '고등 3학년'],
  ];
  const renderItem = (title) => ({
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
      </div>
    ),
  });
  const gradeOptions = gradeDivide.map((e, i) => {
    const tmp = {};
    tmp.label = e;
    tmp.options = gradeList[i].map((grade) => renderItem(grade));
    return tmp;
  });
  return (
    <>
      <Modal
        className={`${company_name} fullwdmodal`}
        visible={visible}
        title={myInfo ? '내 정보' : `학생 정보`}
        onCancel={handleCancel}
        width={'60%'}
        footer={[
          <Button key="submit" type="primary" loading={popupMode === 'create' ? loading : editing} onClick={handleFormSubmit}>
            {popupMode === 'create' ? '등록' : '수정'}
          </Button>,
          <Button key="back" onClick={popupMode === 'create' ? ActionCancel : handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          className="classmodal"
          form={form}
          name="control-ref"
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
        >
          {popupMode === 'create' ? (
            <CreateStudentForm
              form={form}
              formValues={values}
              onDateChange={onDateChange}
              showPostModal={showPostModal}
              setValues={setValues}
              gradeOptions={gradeOptions}
            />
          ) : (
            <UpdateStudentForm
              form={form}
              setValues={setValues}
              userInfo={userInfo}
              onDateChange={onDateChange}
              showPostModal={showPostModal}
              gradeOptions={gradeOptions}
              isTeacher={parseInt(userInfoVar()?.type)!==0}
            />
          )}
        </Form>
      </Modal>

      {postVisible && <PostcodeModal handleCancel={handlePostCancel} visible={postVisible} setValues={setValues} form={form} />}
    </>
  );
}

export default StudentModal;
