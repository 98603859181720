import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Space, Tooltip, Button, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import LevelUpResultMenu from 'src/components/common/LevelUpResultMenu';
import BackButton from 'src/components/common/BackButton';
import { ASSIGNED_EXTERNAL_STUDY_RESULT } from 'src/operations/queries/externalStudy';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const MyPageBtn = styled(Button)`
  background-color: #ed8c3d;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
`;
const PortfolioBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
`;
const BookReportBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
export default function AssignWritingResult() {
  const history = useHistory();
  const location = useLocation();
  const idxForTeacher = location.pathname.split('/')[4] ?? undefined;
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [levelReader, setLevelReader] = useState();
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [searchValue, setSeachValue] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  const [localState, setLocalState] = UseHistoryState({
    startDate: '',
    endDate: '',
    state: 'ing',
  });
  const filterResult = [];
  useEffect(() => {
    // const currentDate = moment();
    // const firstDay = currentDate.clone().startOf('month');
    // const lastDay = currentDate.clone().endOf('month');
    // // const startDateFormatted = firstDay.format('YYYY-MM-DD');
    // const endDateFormatted = lastDay.format('YYYY-MM-DD');

    const curDate = new Date();
    let year = curDate.getFullYear();
    const curmonth = (curDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 and ensure two digits
    const curday = curDate.getDate().toString().padStart(2, '0');
    let month = curDate.getMonth() + 1;
    let day = curDate.getDate();

    // month -= 3;

    // if (month < 1) {
    //   year -= 1;
    //   month += 12;
    // }

    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    setStartDate(`${year}-${(curDate.getMonth() - 2).toString().padStart(2, '0')}-${curday}`);
    setEndDate(`${year}-${curmonth}-${curday}`);
  }, []);

  const calculateTimeDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return endDate - startDate;
  };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });

  const { data, loading: grouploadingdetail } = useQuery(ASSIGNED_EXTERNAL_STUDY_RESULT, {
    fetchPolicy: 'no-cache',
    variables: {
      // start_date: localState?.startDate ? localState?.startDate : '',
      start_date: startDate,
      end_date: endDate,
      //   campus_idx: dataUser?.getUserData?.campus_idx,
      // state: 'all',
      search_text: searchValue ? searchValue : '',
      page: pg.current,
      // search_text: localState.searchClassName,
      type: 'levelup',
      user_idx: idxForTeacher ? parseInt(idxForTeacher) : parseInt(userInfoVar()?.idx),
      page_type: 'bookReport',
    },
  });
  // console.log(filterData, 'filterData');
  useEffect(() => {
    let filteredExternalStudy;
    if (data?.assignedExternalStudyResult !== undefined && data?.assignedExternalStudyResult?.assigned_external_study?.length > 0) {
      filteredExternalStudy = data?.assignedExternalStudyResult?.assigned_external_study?.map((assignedStudy) => {
        const filteredUserStudy = assignedStudy?.external_user_study?.filter((userStudy) => {
          const idx = idxForTeacher ? parseInt(idxForTeacher) : parseInt(userInfoVar()?.idx);
          return userStudy.user.idx === idx;
        });
        assignedStudy.external_user_study = filteredUserStudy;
        return assignedStudy;
      });
    }
    if (filteredExternalStudy?.length) {
      setFilterData(filteredExternalStudy);
    }
  }, [userInfoVar(), data, idxForTeacher]);
  const filterDataList = useMemo(() => {
    if (data?.assignedExternalStudyResult?.assigned_external_study?.length && filterData?.length) {
      return filterData?.map((ele, i) => {
        const bookdata = JSON?.parse(ele?.external_study_data);
        let quizStage = ele?.external_user_study[0]?.external_study_stage?.filter((item) => item.stage_no === 3) || [];
        quizStage = quizStage[0] || {};
        const data = ele?.external_user_study[0]?.data !== null ? JSON.parse(ele?.external_user_study[0]?.data) : [];
        // const data = {"start_time":{"set":"2023-10-17T11:16:06.000Z"},"end_time":{"set":"2023-10-17T11:16:27.000Z"},"status":2,"wpm":40,"recording_data":"<p><span style=\"color:brown; text-decoration:line-through;\">jgjghj</span></p>"}
        const endDate =
          data?.end_time?.set === undefined || data?.end_time?.set === '' || data?.end_time?.set === null
            ? '-'
            : moment.utc(data?.end_time?.set).format('YYYY-MM-DD HH:mm:ss');
        // const promptData = ele?.aiTpoic?.length > 0 ? ele?.aiTpoic[0]?.data !== undefined ? JSON.parse(ele?.aiTpoic[0]?.data) : [] : [];
        // const promptData = ele?.aiTpoic?.length > 0 ? ele?.aiTpoic[0]?.data !== undefined ? console.log("call") : [] : [];
        let promptData = [];
        if (ele?.aiTpoic?.length > 0 && ele?.aiTpoic[0]?.data !== undefined) {
          try {
            promptData = JSON.parse(ele.aiTpoic[0].data);
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }

        const timeDiff = calculateTimeDifference(data?.start_time?.set, data?.end_time?.set);
        let finalTime;
        if (timeDiff) {
          const totalTimeInSeconds = timeDiff / 1000;
          finalTime = formatTime(totalTimeInSeconds);
        }
        const [hours, minutes, seconds] = finalTime !== undefined ? finalTime?.split(':').map(Number) : '';
        let res = hours > 0 || minutes > 0 || seconds > 0 ? true : false;
        // const utcTimestamp = completed_date;
        // const date = new Date(utcTimestamp);
        // const localTime = date.toLocaleTimeString();
        const obj = {
          no: `${i + 1}`,
          assigned_date: moment(ele?.start_date).format('YYYY-MM-DD'),
          completed_date: endDate,
          title: bookdata?.title ? bookdata?.title : '-',
          quiz: quizStage?.exam_total ? `${Math.round((100 * quizStage?.exam_correct) / quizStage?.exam_total)}%` : '-',
          time: res ? finalTime : '-',
          wpm: data?.recording_data ? JSON.parse(data?.recording_data)?.wpm : null,
          acc: data?.recording_data ? JSON.parse(data?.recording_data)?.score : null,
          prompt: promptData?.length == 0 ? '-' : promptData?.book?.prompt,
          toefl_score: data?.rubric_data ? JSON.parse(data?.rubric_data)?.toefl : '-',
          rubric_score: data?.rubric_data ? JSON.parse(data?.rubric_data)?.score : '-',
          code: ele?.aiTpoic[0]?.level_up_code,
          external_study_idx: ele?.external_user_study[0].idx,
          data: data,
          external_study_idx: ele?.external_user_study[0].idx,
        };
        return obj;
      });
    }
  }, [data, filterData]);

  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: dateString,
        // pageNumber: 0,
      };
    });
    if (dateString) {
      setStartDate(dateString);
    } else {
      setStartDate();
    }
  };
  const handleEndDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        endDate: dateString,
        // pageNumber: 0,
      };
    });
    if (dateString) {
      setEndDate(dateString);
    } else {
      setEndDate();
    }
  };
  function openRecordingDataModal(info) {
    setIsRecordingVisible(true);
    setRecordingData(info);
  }
  useEffect(() => {
    if (classList !== undefined && dataUser !== undefined) {
      setSelectedClass(classList?.classList[0]);
    }
  }, [classList, dataUser]);

  useEffect(() => {
    if (filterResult.length > 0) {
      setLevelReader(filterResult);
    }
    // else{
    //   setLevelReader([])
    // }
  }, [filterResult]);
  useEffect(() => {
    if (searchValue) {
      const filteredArray = levelReader?.filter((item) => item.student_name.toLowerCase().includes(searchValue.toLowerCase()));
      setLevelReader(filteredArray);
    } else {
      setLevelReader(filterResult);
    }

    // console.log("filteredArray",filteredArray);
  }, [searchValue]);

  const dummy = [
    {
      no: 1,
      assigned_date: '23-06-21',
      completed_date: '23-06-21',
      title: 'The Three Bear',
      prompt: 'Write about animals',
      time: '23:10',
      toefl_score: 18,
      rubric_score: 75,
      ac_wpm: '80/130',
    },
  ];
  const filteredData1 = useMemo(() => {
    let tmp = dummy;
    if (selectedClassIdx && selectedClassIdx !== 0) {
      tmp = dummy.filter((e) => parseInt(e.classIdx) === parseInt(selectedClassIdx));
    }
    if (localState.searchStudentName) {
      tmp = tmp.filter((e) => e.student_name.includes(localState.searchStudentName));
    }
    tmp.map((e, i) => (e.no = i + 1));
    return tmp;
  }, [localState, selectedClassIdx]);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Assigned Date',
      dataIndex: 'assigned_date',
      key: 'assigned_date',
      align: 'center',
      width: '100px',
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      render: (text) => <span style={{ fontWeight: 'bold', color: 'blue' }}>{text}</span>,
    },
    {
      title: 'Prompt',
      dataIndex: 'prompt',
      key: 'prompt',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" color="#fff" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'Completed Time',
      dataIndex: 'completed_date',
      key: 'completed_date',
      align: 'center',
    },

    {
      title: 'Learning Time',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: 'Toefl Score',
      dataIndex: 'toefl_score',
      key: 'toefl_score',
      align: 'center',
    },
    {
      title: 'Rubric Score',
      dataIndex: 'rubric_score',
      key: 'rubric_score',
      align: 'center',
    },
    {
      title: 'ACC/WPM',
      dataIndex: 'wpm',
      key: 'wpm',
      align: 'center',
      render: (text, record) => {
        const recordingData = record?.data?.recording_data ? JSON.parse(record?.data?.recording_data) : null;
        if (record.status === 0 || !recordingData) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => openRecordingDataModal({ ...recordingData, accuracy: `${recordingData.score}%` })}>
                {recordingData.score}/{text}
              </Button>
            </Space>
          );
        }
      },
    },

    {
      title: 'Book Report',
      dataIndex: 'book_report',
      key: 'book_report',
      align: 'center',
      render: (text, record) => {
        return (
          <BookReportBtn
            onClick={() => {
              if (record?.data?.status === 2) {
                const rubricData = record?.data?.rubric_data ? JSON.parse(record?.data?.rubric_data) : {};
                rubricData?.pdf_url
                  ? window.open(rubricData?.pdf_url)
                  : alert('Grading has not been completed yet. Please contact your teacher.');
              } else {
                history.push(`/level-up/bookreport?code=${record?.code}&external_study_idx=${record?.external_study_idx}`);
              }
            }}
          >
            Open
          </BookReportBtn>
        );
      },
    },
  ];
  const handleChange = (e) => {
    setSelectedClass(classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0]);
  };
  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>{idxForTeacher ? <></> : <LevelUpResultMenu currentMenu={'bookreport'} />}</RowFlex>
        <MainWrapper className="book-report">
          <Main>
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Book Report Assignment by Date</SubTitle>
                {idxForTeacher ? (
                  <BackButton />
                ) : (
                  <CloseButton
                    onClick={() => {
                      history.push('/level-up');
                    }}
                  >
                    <CloseOutlined />
                  </CloseButton>
                )}
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  value={moment(startDate)}
                />
                ~
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  value={moment(endDate)}
                />
                &nbsp;
                <Icon url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              pagination={{ pageSize: 10, position: ['bottomCenter'] }}
              dataSource={filterDataList}
              // dataSource={dummy}
              // dataSource={levelReader?.length > 0 ? levelReader : []}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
      <RecordingDataModal
        data={{ ...recordingData, className: '' }}
        isShow={isRecordingVisible}
        onPrint={null}
        componentRef={null}
        onOk={() => {
          setIsRecordingVisible(false);
        }}
        hidePrint={true}
      />
    </>
  );
}
