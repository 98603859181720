import { gql } from '@apollo/client';

export const ZOOM_LIST = gql`
  query zoomScheduleList($class_idx: Int, $q: String, $from_date: String, $to_date: String, $page: Int, $take: Int) {
    zoomScheduleList(class_idx: $class_idx, q: $q, from_date: $from_date, to_date: $to_date, page:$page, take:$take) {
        total
        page
        take
        zoom_schedule {
          idx
          start_time
          end_time
          title
          zoom_account {
            zoom_host_id
            email
          }
          class {
            idx
            name
          }
          idate
        }
    }
  }
`;

export const AVIALABLE_ZOOM_ACCOUNT = gql`
  query availableZoomAccounts($start_time: String!, $end_time: String!) {
    availableZoomAccounts(start_time: $start_time, end_time: $end_time) {
        idx
        zoom_account {
          zoom_host_id
          email
          idate
        }
    }
  }
`;