import { gql } from '@apollo/client';

export const CREATE_AI_GROUP_TOPIC = gql`
  mutation createAIGroupTopic(
    $folder_type: String
    $type: String
    $data: String
    $assistants_id: String
    $assistants_data: String
    $files_list: String
    $content_idx: Int
    $vector_idx: String
  ) {
    createAIGroupTopic(
      folder_type: $folder_type
      type: $type
      data: $data
      assistants_id: $assistants_id
      assistants_data: $assistants_data
      files_list: $files_list
      content_idx: $content_idx
      vector_idx: $vector_idx
    )
  }
`;

export const CREATE_ASSISTANTS = gql`
  mutation createAssistants($files: String, $instructions: String, $name: String, $model: String, $description: String) {
    createAssistants(files: $files, instructions: $instructions, name: $name, model: $model, description: $description)
  }
`;
export const UPDATE_ASSISTANTS = gql`
  mutation updateAssistants(
    $files: String
    $instructions: String
    $description: String
    $name: String
    $model: String
    $assistant_id: String!
    $vector_id: String
    $image_file_list: String
    $audio_file_list: String
  ) {
    updateAssistants(
      files: $files
      instructions: $instructions
      description: $description
      name: $name
      model: $model
      assistant_id: $assistant_id
      vector_id: $vector_id
      image_file_list: $image_file_list
      audio_file_list: $audio_file_list
    )
  }
`;
export const UPLOAD_OPEN_AI_FILE = gql`
  mutation uploadOpenAIFile($files: [Upload!]) {
    uploadOpenAIFile(files: $files)
  }
`;

export const CREATE_THREAD = gql`
  mutation createThread($idx: Int) {
    createThread(idx: $idx)
  }
`;
export const UPDATE_THREAD = gql`
  mutation updateThread($idx: Int, $thread_idx: String!, $vector_store_idx: String!) {
    updateThread(idx: $idx, thread_idx: $thread_idx, vector_store_idx: $vector_store_idx)
  }
`;

export const UPDATE_THREAD_ID = gql`
  mutation updateUserAssignmentThread($idx: Int!, $thread_id: String) {
    updateUserAssignmentThread(idx: $idx, thread_id: $thread_id)
  }
`;

export const OPEN_AI_MESSAGE = gql`
  mutation openaiMessage($role: String, $content: String, $thread_id: String!, $metadata: JSONObject) {
    openaiMessage(role: $role, content: $content, thread_id: $thread_id, metadata: $metadata)
  }
`;
export const CREATE_RUNS = gql`
  mutation createRuns($assistant_id: String!, $thread_id: String!, $image_audio: String, $original_msg: String, $model: String) {
    createRuns(assistant_id: $assistant_id, thread_id: $thread_id, image_audio: $image_audio, original_msg: $original_msg, model: $model)
  }
`;
export const GET_STEPS = gql`
  mutation getSteps($thread_id: String!, $run_id: String!) {
    getSteps(thread_id: $thread_id, run_id: $run_id)
  }
`;
export const RETRIEVE_RUN = gql`
  mutation retrieveRun($thread_id: String!, $run_id: String!, $group_id: Int!, $topic_id: Int!) {
    retrieveRun(thread_id: $thread_id, run_id: $run_id, group_id: $group_id, topic_id: $topic_id)
  }
`;
export const SUBMIT_TOOL_OUTPUT = gql`
  mutation submitToolOutput($thread_id: String!, $run_id: String!, $tool_outputs: String!) {
    submitToolOutput(thread_id: $thread_id, run_id: $run_id, tool_outputs: $tool_outputs)
  }
`;

export const MESSAGE_LIST = gql`
  mutation messageList($thread_id: String!, $last_msg_id: String, $order: String) {
    messageList(thread_id: $thread_id, last_msg_id: $last_msg_id, order: $order)
  }
`;

export const GET_MESSAGE = gql`
  mutation getMessage($thread_id: String!, $message_id: String!) {
    getMessage(thread_id: $thread_id, message_id: $message_id)
  }
`;

export const DELETE_UPLOADED_FILE = gql`
  mutation deleteOpenAIUploadedFile($idx: Int, $file_id: String!) {
    deleteOpenAIUploadedFile(idx: $idx, file_id: $file_id)
  }
`;

export const TRANSLATE_TEXT = gql`
  mutation translateText($from_lang: String!, $to_lang: String!, $text: String!) {
    translateText(from_lang: $from_lang, to_lang: $to_lang, text: $text)
  }
`;

export const CREATE_CONTENT = gql`
  mutation createContent($name: String!) {
    createContent(name: $name) {
      idx
      content_name
    }
  }
`;

export const GET_CONTENT = gql`
  mutation getContent($idx: Int!) {
    getContent(idx: $idx)
  }
`;

export const UPDATE_CONTENT = gql`
  mutation updateContent($idx: Int!, $name: String!) {
    updateContent(idx: $idx, name: $name)
  }
`;

export const DELETE_AI_CONTENT = gql`
  mutation deleteAITopiContent($idx: Int!) {
    deleteAITopiContent(idx: $idx)
  }
`;

export const CREATE_VECTOR = gql`
  mutation createVector($uploadedFileIdx: [String!]!) {
    createVector(uploadedFileIdx: $uploadedFileIdx)
  }
`;

export const UPLOAD_FILE_IN_VECTOR = gql`
  mutation uploadFileInVectorStore($vector_store_idx: String!, $file_ids: [String!]!) {
    uploadFileInVectorStore(vector_store_idx: $vector_store_idx, file_ids: $file_ids)
  }
`;

export const GET_FILE_FROM_VECTOR = gql`
  mutation getVectorStoreFile($vector_idx: String) {
    getVectorStoreFile(vector_idx: $vector_idx)
  }
`;

export const DELETE_FILE_FROM_VECTOR = gql`
  mutation deleteFileFromVector($vector_idx: String, $file_id: String) {
    deleteFileFromVector(vector_idx: $vector_idx, file_id: $file_id)
  }
`;
