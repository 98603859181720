import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ isIgnoreAdress = false, data = null }) => {
  return (
    <footer style={{ textAlign: 'center', padding: 10, lineHeight: '1.7em', color: '#b3b3b3', fontWeight: 'bold' }}>
      <div>
        <Link
          to="/privacy"
          style={{
            color: '#b3b3b3',
            textDecoration: 'underline',
          }}
        >
          개인정보 취급방침
        </Link>
      </div>
      {!isIgnoreAdress && <address dangerouslySetInnerHTML={{ __html: data?.address }}></address>}

      <div>© {data?.companyName ? data?.companyName : 'Cloubot'}. All rights reserved.</div>
    </footer>
  );
};

export default Footer;
