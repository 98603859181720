import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Switch, Space, Input, Button, DatePicker, Tooltip, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcel } from 'src/utils/index';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { isPturn } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import { ASSIGNED_EXTERNAL_STUDY_STUDENT } from 'src/operations/queries/externalStudy';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import moment from 'moment';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
  @media (min-height: 750px) and (max-height: 1100px) {
    min-height: 740px;
  }
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const LiteracyBtn = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
`;
const PortfolioBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 auto;
  width: 70px;
`;
const LiteracyBtn2 = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 auto;
  width: 70px;
  font-weight: 600;
  font-size: 14px;
`;
const BookReportBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 auto;
  width: 70px;
  font-weight: 600;
  font-size: 14px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
export default function LevelUpReadersPage() {
  const history = useHistory();
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [levelReader, setLevelReader] = useState();
  const companyName = useSelector(classStoreData);
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  const [visible, setVisible] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [currentUserName, setCurrentUserName] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [recordingContent, setRecordingContent] = useState('');
  const [localState, setLocalState] = UseHistoryState({
    startDate: moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    state: 'ing',
    pg: { current: 1, pageSize: 10 },
    searchValue: '',
    filterType: null,
    selectedClass:-1,
    hideColumns:true,
  });
  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (!localState.endDate) {
      setLocalState((prev) => {
        return { ...prev, endDate: moment(new Date()).format('YYYY-MM-DD') };
      });
      if (!localState.startDate) {
        setLocalState((prev) => {
          return { ...prev, startDate: moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD') };
        });
      }
    }
  }, [localState]);
  const { data: dataAssignedExternalStudyStudent, loading: grouploadingdetail } = useQuery(ASSIGNED_EXTERNAL_STUDY_STUDENT, {
    fetchPolicy: 'no-cache',
    variables: {
      // start_date: localState?.startDate ? localState?.startDate : '',
      start_date: localState.startDate,
      end_date: localState.endDate,
      campus_idx: dataUser?.getUserData?.campus_idx,
      // state: 'all',
      take: localState.pg?.pageSize,
      page: localState.pg?.current ? localState.pg?.current - 1 : 0,
      search_text: localState.searchValue ? localState.searchValue : '',
      // search_text: localState.searchClassName,
      type: 'levelup',
      class_idx: localState.selectedClass?.idx ? localState.selectedClass?.idx : null,
      page_type: localState.filterType,
    },
    skip: localState.endDate === '',
  });
  const handlePageChange = (pagination) => {
    setLocalState((prev) => {
      return {
        ...prev,
        pg: pagination,
      };
    });
  };
  const handleOpenQuizPopUp = (data) => {
    setVisible(true);
    setQuizData(data);
  };
  const handleCloseQuizPopUp = () => {
    setVisible(false);
    setQuizData(null);
  };
  const handleStartDateChange = (date, dateString) => {
    if (dateString) {
      setLocalState((prev) => {
        return {
          ...prev,
          startDate: dateString,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          startDate: '',
        };
      });
    }
  };
  const handleEndDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        endDate: dateString,
      };
    });
    if (dateString) {
      setLocalState((prev) => {
        return {
          ...prev,
          endDate: dateString,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          endDate: '',
        };
      });
    }
  };
  const calculateTimeDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return endDate - startDate;
  };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  const onSeachChange = (val) => {
    if (val.target.value) {
      setLocalState((prev) => {
        return { ...prev, searchValue: val.target.value };
      });
    } else {
      setLocalState((prev) => {
        return { ...prev, searchValue: '' };
      });
    }
  };

  const filteredData = useMemo(() => {
    let tmp = [];
    if (
      dataAssignedExternalStudyStudent?.assignedExternalStudyStudent &&
      dataAssignedExternalStudyStudent?.assignedExternalStudyStudent?.external_user_study.length > 0
    ) {
      tmp = dataAssignedExternalStudyStudent?.assignedExternalStudyStudent?.external_user_study;
    } else return tmp;

    return tmp.map((e, indx) => {
      let quizStage = e?.external_study_stage?.filter((item) => item.stage_no === 3) || [];
      quizStage = quizStage[0] || {};
      const firstStudyTime = e?.external_study_stage?.reduce((acc, cur) => {
        if (acc === null) {
          return cur?.study_date;
        }
        if (moment(cur?.study_date).isBefore(moment(acc))) {
          return cur?.study_date;
        }
        return acc;
      }, null);
      const timeDiff = moment(e?.last_study_timestamp).diff(moment(firstStudyTime || e?.study_date));
      let finalTime;
      if (timeDiff) {
        const totalTimeInSeconds = timeDiff / 1000;
        finalTime = formatTime(totalTimeInSeconds);
      } else {
        finalTime = '00:00:00';
      }
      const [hours, minutes, seconds] = finalTime.split(':').map(Number);
      let res = hours > 0 || minutes > 0 || seconds > 0 ? true : false;

      const utcTimestamp = e?.last_study_timestamp;
      const date = new Date(utcTimestamp);
      const localTime = date.toLocaleTimeString();
      e.bookData = JSON.parse(e.assigned_external_study.external_study_data);
      e.bookReportData = JSON.parse(e.data);
      e.assigned_date = e.assigned_external_study.start_date.slice(0, 10);
      let activitiesStage = e?.external_study_stage?.filter((item) => [1, 2, 4, 5].includes(item.stage_no)) || [];
      let is_voca = e?.assigned_external_study?.external_study_data?JSON.parse(e?.assigned_external_study?.external_study_data).is_voca:0;
      const obj = {
        no:
          dataAssignedExternalStudyStudent?.assignedExternalStudyStudent?.total -
          (localState.pg?.current - 1) * localState.pg?.pageSize -
          indx,
        student_name: e?.user?.name,
        assigned_date: moment(e?.study_date).format('YYYY-MM-DD'),
        // last_study_date: e?.last_study_timestamp ? moment(e?.last_study_timestamp).format('YYYY-MM-DD') : '-',
        book_title: e.bookData?.title,
        spent_time: res ? finalTime : '-',
        quiz: quizStage?.exam_total ? `${quizStage?.exam_correct} / ${quizStage?.exam_total}` : '-',
        userIdx: e?.user?.idx,
        portfolio: e?.user?.external_user_study_count,
        bookreportCount: e?.user?.external_book_report_count,
        book_report: e?.bookData?.bookreport ? e.bookData.bookreport : '',
        lexile: e?.bookData?.lexileVal
          ? e?.bookData?.lexileVal < 0
            ? `BR${Math.abs(e?.bookData?.lexileVal)}L`
            : `${e?.bookData?.lexile_val}L`
          : '',
        mx_level: e?.bookData?.mx_level,
        classIdx: e.assigned_external_study.assigned_external_study_group.class.idx,
        study_date: e?.study_date,
        completed_date: e?.status===2 ? moment(e?.last_study_timestamp.slice(0, 19)).format('YYYY-MM-DD HH:mm') : '-',
        activity: activitiesStage?.length > 3 || (!is_voca&&e?.status===2) ? 'O' : '',
        class_name: e?.assigned_external_study.assigned_external_study_group.class.name,
        fluency: e?.exam_correct ? e?.exam_correct : '',
        recording_data:e?.recording_data? e?.recording_data:'',
        wpm: e?.wpm ? e?.wpm : '',
        bookreportdata: e?.bookReportData?.recording_data ? JSON.parse(e?.bookReportData?.recording_data) : null,
        quiz_data: e?.external_study_quiz_data.map((quiz, i) => {
          return {
            ...quiz,
            options: quiz.options ? JSON.parse(quiz.options) : undefined,
            is_correct: e?.external_study_stage.filter((e)=>e.stage_no===3)[0]?.external_study_stage_answer[i]?.is_correct,
            user_answer: e?.external_study_stage.filter((e)=>e.stage_no===3)[0]?.external_study_stage_answer[i]?.user_answer,
          };
        }),
        external_study_idx: e?.idx,
        code: JSON.parse(e?.assigned_external_study?.external_study_data)?.code,
      };

      return obj;
    });
  }, [dataAssignedExternalStudyStudent]);
  useEffect(() => {
    if (filteredData.length > 0) {
      const newArr = filteredData?.map((item, index) => ({
        ...item,
      }));
      setLevelReader(newArr);
    } else {
      setLevelReader([]);
    }
  }, [filteredData]);
  const handleOpenBook = (val) => {
    history.push(`/level-up/report/details-by-date/${val?.external_study_idx}`);
  };

  const openRecordingDataModal = (data, name, id) => {
    setIsRecordingVisible(true);
    setRecordingContent(data);
    setCurrentUserName(name);
    setCurrentUserId(id);
  };

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
    setRecordingContent(undefined);
    setCurrentUserName(undefined);
    setCurrentUserId(undefined);
  }

  const onChangeFilter = (checked) => {
    setLocalState((prev) => {
      return {
        ...prev,
        filterType: checked ? 'bookreport' : null,
      };
    });
  };
  const onChangeHide = (checked) => {
    setLocalState((prev) => {
      return {
        ...prev,
        hideColumns: checked ? true : false,
      };
    });
  };
  const hideoptions = localState.hideColumns
    ? [[],[]]
    : [
        [{
          title: 'Lexile',
          dataIndex: 'lexile',
          key: 'lexile',
          align: 'center',
        },
        {
          title: 'Mx level',
          dataIndex: 'mx_level',
          key: 'mx_level',
          align: 'center',
        }],[{
          title: 'Portfolio',
          dataIndex: 'portfolio',
          key: 'portfolio',
          align: 'center',
          render: (text, record) => {
            return (
              <PortfolioBtn
                onClick={() => {
                  history.push(`/level-up/portfolio/?student_idx=${record.userIdx}`);
                }}
              >
                {text}
              </PortfolioBtn>
            );
          },
        },
        {
          title: 'Literacy',
          dataIndex: 'book_report',
          key: 'book_report',
          align: 'center',
          render: (_, record) => {
            return (
              <LiteracyBtn2
                onClick={() => {
                  history.push({
                    pathname: `/level-up/report/statreportassign`,
                    state: { class_idx: record?.classIdx, user_idx: record?.userIdx },
                  });
                }}
              >
                View
              </LiteracyBtn2>
            );
          },
        },

        {
          title: 'Book Report',
          dataIndex: 'book_report',
          key: 'book_report',
          align: 'center',
          render: (text, record) => {
            if (!record?.book_report) {
              return <span>-</span>;
            }

            return <BookReportBtn onClick={() => handleOpenBook(record)}>{record?.bookreportCount}</BookReportBtn>;
          },
        },]
      ];
  const columns = isPturn(companyName)
    ? [
        {
          title: 'No.',
          dataIndex: 'no',
          key: 'no',
          width: '50px',
          align: 'center',
        },
        {
          title: 'Assigned Date',
          dataIndex: 'assigned_date',
          key: 'assigned_date',
          align: 'center',
        },
        {
          title: 'Class Name',
          dataIndex: 'class_name',
          key: 'class_name',
          align: 'center',
        },
        {
          title: 'Title',
          dataIndex: 'book_title',
          key: 'book_title',
          // width: '350px',
          align: 'center',
        },
        {
          title: 'Student',
          dataIndex: 'student_name',
          key: 'student_name',
          align: 'center',
          sorter: (a, b) => a.student_name && a.student_name.localeCompare(b.student_name),
          render: (text) => {
            return text.length > 5 ? (
              <Tooltip title={text} color="#fff">
                <span>{text.slice(0, 5) + '...'}</span>
              </Tooltip>
            ) : (
              <span>{text}</span>
            );
          },
        },

        {
          title: 'Completion Time',
          dataIndex: 'completed_date',
          key: 'completed_date',
          align: 'center',
        },
        {
          title: 'Learning Time',
          dataIndex: 'spent_time',
          key: 'spent_time',
          align: 'center',
        },
        {
          title: 'Activity',
          dataIndex: 'activity',
          key: 'activity',
          align: 'center',
          render: (text) => {
            return text ? <span style={{ color: 'green' }}>완료</span> : <span style={{ color: 'orange' }}>미완료</span>;
          },
        },
        {
          title: 'Quiz',
          dataIndex: 'quiz',
          key: 'quiz',
          align: 'center',
          width: '80px',
          render: (text, record) => {
            return text === '-' ? (
              <span>-</span>
            ) : (
              <span style={{ cursor: 'pointer' }} onClick={() => handleOpenQuizPopUp(record.quiz_data)}>
                {text}
              </span>
            );
          },
        },
        {
          title: 'Fluency',
          dataIndex: 'fluency',
          key: 'fluency',
          align: 'center',
          render: (text, record) => {
            return text === '미완료' ? (
              <span style={{ color: 'orange' }}>{text}</span>
            ) : (
              <Button onClick={() => openRecordingDataModal(record, record?.user?.name, record?.user?.id)}>{text}</Button>
            );
          },
        },
        {
          title: 'Portfolio',
          dataIndex: 'portfolio',
          key: 'portfolio',
          align: 'center',
          render: (text, record) => {
            return (
              <PortfolioBtn
                onClick={() => {
                  history.push(`/level-up/portfolio/?student_idx=${record.userIdx}`);
                }}
              >
                {text}
              </PortfolioBtn>
            );
          },
        },
        {
          title: 'Literacy',
          dataIndex: 'book_report',
          key: 'book_report',
          align: 'center',
          render: (_, record) => {
            return (
              <LiteracyBtn2
                onClick={() => {
                  history.push({
                    pathname: `/level-up/report/statreportassign`,
                    state: { class_idx: record?.classIdx, user_idx: record?.userIdx },
                  });
                }}
              >
                View
              </LiteracyBtn2>
            );
          },
        },
      ]
    : [
        {
          title: 'No.',
          dataIndex: 'no',
          key: 'no',
          width: '50px',
          align: 'center',
        },
        {
          title: 'Assigned Date',
          dataIndex: 'assigned_date',
          key: 'assigned_date',
          align: 'center',
          render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
        },
        {
          title: 'Class Name',
          dataIndex: 'class_name',
          key: 'class_name',
          align: 'center',
          render: (text) => <span style={{ fontWeight: 'bold',color:'blue' }}>{text}</span>,
        },
        {
          title: 'Title',
          dataIndex: 'book_title',
          key: 'book_title',
          // width: '350px',
          align: 'center',
          render:(text) => {
            return text.length > 10 ? (
              <Tooltip title={text} color="#fff">
                <span>{text.slice(0, 10) + '...'}</span>
              </Tooltip>
            ) : (
              <span>{text}</span>
            );
          },
        },
        ...hideoptions[0],
        {
          title: 'Student',
          dataIndex: 'student_name',
          key: 'student_name',
          align: 'center',
          sorter: (a, b) => a.student_name && a.student_name.localeCompare(b.student_name),
          render: (text) => {
            return text.length > 5 ? (
              <Tooltip title={text} color="#fff">
                <span style={{color:'blue'}}>{text.slice(0, 5) + '...'}</span>
              </Tooltip>
            ) : (
              <span style={{color:'blue'}}>{text}</span>
            );
          },
        },
        {
          title: 'Completion Time',
          dataIndex: 'completed_date',
          key: 'completed_date',
          align: 'center',
        },
        {
          title: 'Learning Time',
          dataIndex: 'spent_time',
          key: 'spent_time',
          align: 'center',
        },
        {
          title: 'Activity',
          dataIndex: 'activity',
          key: 'activity',
          align: 'center',
          render: (text) => {
            return text ? <span style={{ color: 'green' }}>완료</span> : <span style={{ color: 'orange' }}>미완료</span>;
          },
        },
        {
          title: 'Quiz',
          dataIndex: 'quiz',
          key: 'quiz',
          align: 'center',
          width: '80px',
          render: (text, record) => {
            return text === '-' ? (
              <span style={{ color: 'orange' }}>미완료</span>
            ) : (
              <span style={{ cursor: 'pointer',color: 'green' ,cursor:'pointer' }} onClick={() => handleOpenQuizPopUp(record.quiz_data)}>
                완료
              </span>
            );
          },
        },
        {
          title: 'Fluency',
          dataIndex: 'fluency',
          key: 'fluency',
          align: 'center',
          render: (text, record) => {
            const recordginData = {accuracy:text+'%',wpm:record?.wpm,recording_data:record?.recording_data}
            // console.log(record, record?.user?.name, record?.user?.id)
            return !text ? (
              <span style={{ color: 'orange' }}>미완료</span>
            ) : (
              <Button onClick={() => openRecordingDataModal({...recordginData}, record?.user?.name, record?.user?.id)}>{text}/{record?.wpm}</Button>
            );
          },
        },
        // {
        //   title: 'Book Report Fluency',
        //   dataIndex: 'bookreportdata',
        //   key: 'bookreportdata',
        //   align: 'center',
        //   render: (text, record) => {
        //     const recordginData = {wpm:record?.bookreportdata?.wpm,recording_data:record?.bookreportdata?.recording_data|| ''} ;
        //     if (!record?.book_report) {
        //       return <span>-</span>;
        //     }
        //     return text ? (
        //       <Button
        //         onClick={() =>
        //           openRecordingDataModal({ ...recordginData, accuracy: `${record?.bookreportdata?.score}%` }, record?.user?.name, record?.user?.id)
        //         }
        //       >
        //         {text.score}/{text.wpm}
        //       </Button>
        //     ) : (
        //       <span style={{ color: 'orange' }}>미완료</span>
        //     );
        //   },
        // },
        ...hideoptions[1]
      ];
      const handleChange = (e) => {
        setLocalState((prev)=>{
          return{ ...prev, selectedClass:classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0]}
        })
      };
  return (
    <>
      <Modal
        centered
        visible={visible}
        title={''}
        onCancel={() => {
          setVisible(false);
          setQuizData(null);
        }}
        footer={
          <Button key="button" type="primary" onClick={handleCloseQuizPopUp}>
            확인
          </Button>
        }
      >
        {quizData &&
          quizData.map((e) => {
            const optionNumber = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩'];
            return (
              <>
                <div style={{ fontSize: '20px' }}>
                  <span>{e.no}. </span>
                  <span dangerouslySetInnerHTML={{ __html: `<span>${e?.question}</span>` }}></span>{' '}
                </div>
                {e.image ? <img src={e.image} height={100} /> : undefined}
                <>
                  {e.options && e.options.every((option) => option?.Quiz_Image === '')
                    ? e.is_correct &&
                      (e.is_correct === '1'
                        ? e.options.map((option, i) => {
                            return (
                              <div>
                                <span style={{ fontSize: '16px', color: option.Correct === 'True' && 'blue' }}>{optionNumber[i]} </span>
                                <span style={{ color: option.Correct === 'True' && 'blue' }}>{option.Text}</span>
                              </div>
                            );
                          })
                        : e.options.map((option, i) => {
                            return (
                              <div>
                                <span
                                  style={{
                                    fontSize: '16px',
                                    color: option.Correct === 'True' ? 'blue' : option.Text === e.user_answer ? 'red' : '#000',
                                  }}
                                >
                                  {optionNumber[i]}{' '}
                                </span>
                                <span
                                  style={{ color: option.Correct === 'True' ? 'blue' : option.Text === e.user_answer ? 'red' : '#000' }}
                                >
                                  {option.Text}
                                </span>
                              </div>
                            );
                          }))
                    : e.is_correct &&
                      (e.is_correct === '1'
                        ? e.options.map((option, i) => {
                            return (
                              <>
                                <div>
                                  <span style={{ fontSize: '16px', color: option.Correct === 'True' && 'blue' }}>{optionNumber[i]} </span>
                                </div>
                                <img src={option.Quiz_Image} height={100} />
                              </>
                            );
                          })
                        : e.options.map((option, i) => {
                            return (
                              <>
                                <div>
                                  <span
                                    style={{
                                      fontSize: '16px',
                                      color: option.Correct === 'True' ? 'blue' : option.Text === e.user_answer ? 'red' : '#000',
                                    }}
                                  >
                                    {optionNumber[i]}{' '}
                                  </span>
                                </div>
                                <img src={option.Quiz_Image} height={100} />
                              </>
                            );
                          }))}
                </>
              </>
            );
          })}
      </Modal>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <LevelUpMenuGroup currentMenu={'readers'} />
        </RowFlex>
        <MainWrapper>
          <Main className="assign-result-wrap">
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Total Assigned Result</SubTitle>
                {/* <CloseButton
                  onClick={() => {
                    history.push('/level-up');
                  }}
                >
                  <CloseOutlined />
                </CloseButton> */}
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {!isPturn(companyName) && (
                  <>
                    <Space style={{ marginRight: 10 }}>
                      <Switch
                        checkedChildren="Learning Records"
                        unCheckedChildren="All Learning Activities"
                        onChange={(checked) => onChangeHide(checked)}
                        checked={localState.hideColumns}
                      />
                    </Space>
                    <Space style={{ marginRight: 10 }}>
                      <Switch
                        checkedChildren="Book Report Records"
                        unCheckedChildren="All"
                        onChange={(checked) => onChangeFilter(checked)}
                        checked={localState.filterType}
                      />
                    </Space>
                  </>
                )}
                {localState.selectedClass?.idx >= 0 ? (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                ) : (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                )}
                &nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  value={localState.searchValue}
                />
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  allowClear={false}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  value={moment(localState.startDate !== '' ? localState.startDate : new Date())}
                />
                ~
                <DatePicker
                  placeholder="Date"
                  allowClear={false}
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  value={moment(localState.endDate !== '' ? localState.endDate : new Date())}
                />
                &nbsp;
                <LiteracyBtn
                  onClick={() => {
                    history.push({ pathname: `/level-up/report/statreportassign`, state: { class_idx: selectedClassIdx } });
                  }}
                >
                  Literacy
                </LiteracyBtn>
                {/* &nbsp;
                <Icon url={imageexcel} width={'20px'} height={'20px'}></Icon> */}
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              className="cust-tble"
              pagination={{
                // showSizeChanger: false,
                pageSize: localState.pg?.pageSize,
                current: localState.pg?.current,
                total: dataAssignedExternalStudyStudent?.assignedExternalStudyStudent?.total || 0,
                position: ['bottomCenter'],
              }}
              loading={grouploadingdetail}
              // dataSource={filteredData1}
              dataSource={levelReader?.length > 0 ? levelReader : []}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
              onChange={(pagination) => {
                handlePageChange(pagination);
              }}
            />
          </Main>
        </MainWrapper>
        <RecordingDataModal
          data={{ ...recordingContent, currentUserName, currentUserId }}
          isShow={isRecordingVisible}
          onOk={handleOkForRecordingData}
          title={'Recording'}
        />
      </Wrapper>
    </>
  );
}
