import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typographym, Button } from 'antd';
import { PlayCircleFilled, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useParams, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import VocaHeader from './VocaHeader';
import VocaTypeA from './VocaTypeA';
import VocaTypeB from './VocaTypeB';
import VocaTypeC from './VocaTypeC';
import VocaDictation from './VocaDictation';
import VocaUnscramble from './VocaUnscramble';

const VocaQuizFrame = () => {
  const answer = [
    { num: 1, answer: 'floor' },
    { num: 2, answer: 'table' },
    { num: 3, answer: 'bed' },
    { num: 4, answer: 'chair' },
  ];

  return (
    <>
      <section className="vocatool-con">
        <VocaHeader />
        <VocaTypeC />
      </section>
    </>
  );
};

export default VocaQuizFrame;
