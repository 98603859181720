import React, { useState, useEffect } from 'react';
import { Col, Row, Typography, Input, Button, Modal } from 'antd';
import { EditOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import EmployeeModal from 'src/components/Modal/EmployeeModal';
import Settings from 'src/pages/Settings';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';

import { userTypVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const { Title, Text } = Typography;
const { confirm } = Modal;

const Employee = () => {
  const [visible, setVisible] = useState(false);

  const [employees, setEmployees] = useState([]);

  const [userInfo, setUserInfo] = useState(null);

  const [popupMode, setPopupMode] = useState('');

  const [filterTable, setFilterTable] = useState(null);

  const [searchValue, setSeachValue] = useState('');

  const [checkRowList, setcheckRowList] = useState([]);

  const { data, loading: getUserLoading } = useQuery(queries.getUser.GET_USERS, {
    variables: { type: userTypVar().employee },
  });

  const [deleteUser] = useMutation(mutations.deleteUser.DELETE_USER, {
    update(proxy, result) {
      const data = proxy.readQuery({
        query: queries.getUser.GET_USERS,
        variables: {
          type: userTypVar().employee,
        },
      });

      const userList = data.getUsers.reduce((acc, cur) => {
        if (checkRowList.includes(cur.idx)) {
          return acc;
        }
        acc = [...acc, cur];
        return acc;
      }, []);

      proxy.writeQuery({
        query: queries.getUser.GET_USERS,
        variables: {
          type: userTypVar().employee,
        },
        data: {
          getUsers: [...userList],
        },
      });
    },
    onCompleted(data) {
      if (data) {
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (data?.getUsers) {
      setEmployees(
        data.getUsers.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            No: index + 1,
            name: cur.name,
            id: cur.id,
            phone: cur.phone,
            email: cur.email,
            department: cur.department,
          };

          return [...acc, obj];
        }, []),
      );
    }
  }, [data]);

  const showModal = (mode = 'create') => {
    if (mode === 'create') {
      setUserInfo(null);
    }
    setPopupMode(mode);
    setVisible(true);
  };

  const handleTeacherModalCancel = () => {
    setVisible(false);
  };

  const handleSearch = (value) => {
    setSeachValue(value);
    const filterTable = employees.filter((teacher) => {
      return Object.keys(teacher).some((field) => String(teacher[field]).toLowerCase().includes(value.toLowerCase()));
    });

    setFilterTable(filterTable);
  };

  const handleEdit = (id) => {
    setUserInfo(...data.getUsers.filter((value) => value.idx === Number(id)));
    showModal('edit');
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              user_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: '팀 / 부서',
      dataIndex: 'department',
      key: 'department',
      align: 'center',
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: '수정',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <Button onClick={() => handleEdit(record.key)}>
            <EditOutlined /> Edit
          </Button>
        );
      },
    },
  ];

  return (
    <Settings className="setting-wrapper">
      <Row gutter={[16, 16]} className="setting-wrapper-main">
        <Col span={24}>
          <HalfWrapper className="setting-wrapper-halfwrap">
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            > */}
            <Row
              gutter={[8, 8]}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Col className="setting-title" span={2} xs={24} lg={2}>
                <Title level={5}>직원 List</Title>
              </Col>
              <Col className="setting-form-items">
                <Input.Search
                  className="setting-input-search"
                  placeholder="검색어를 입력해 주세요"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onSearch={handleSearch}
                  onChange={onSeachChange}
                  value={searchValue}
                />
                <CustomButton className="setting-input-reload" style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                  <RedoOutlined />
                </CustomButton>
                <CustomButton className="setting-input-btn-success" style={{ marginRight: 10 }} onClick={() => showModal('create')}>
                  <PlusOutlined style={{ color: '#52c419' }} />
                  <Text type={'success'}>등록</Text>
                </CustomButton>
                <CustomButton className="setting-input-btn-delete"
                  danger
                  style={{
                    marginRight: 10,
                  }}
                  onClick={deleteConfirm}
                >
                  삭제
                </CustomButton>
              </Col>
            </Row>
            {/* </div> */}

            <CustomTable
              className="setting-input-customtable"
              loading={getUserLoading}
              dataSource={filterTable === null ? employees : filterTable}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: 12 }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {}, // click row
                };
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <EmployeeModal userInfo={userInfo} popupMode={popupMode} handleCancel={handleTeacherModalCancel} visible={visible} />}
    </Settings>
  );
};

export default Employee;
