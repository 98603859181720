import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import * as S from 'src/pages/PhonicsMonster/style';
import { alphabetBoxTypeChecker } from '../../utils/pm/util';
const AlphabetDroppableBox = ({ letter, isDropped }) => {
  return (
    <Droppable droppableId={`upper_${letter}`} type={letter} ignoreContainerClipping={true}>
      {(provided, snapshot) => (
        <>
          <S.AlphabetBox
            row={alphabetBoxTypeChecker(letter.length > 1 ? 3 : 1)}
            gray={!isDropped}
            className={`alphabet_box ${letter.length > 1 ? 'row3' : 'row1'} ${isDropped ? 'green' : 'gray'}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
            }}
          >
            <span style={{ color: isDropped ? '#f368e0' : '#eee' }} className={`font_${isDropped ? 'pink' : 'gray'}`}>
              {letter}
            </span>
          </S.AlphabetBox>
          <div style={{ display: 'none' }}>{provided.placeholder}</div>
        </>
      )}
    </Droppable>
  );
};

export default AlphabetDroppableBox;
