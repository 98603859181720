import React, { useMemo } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { userInfoVar } from 'src/apollo/cache';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const MetaExamMenuGroup = ({ currentMenu }) => {
  const hasElib = useMemo(() => {
    //나중에 바꿔야 한다. 일단 임시로...
    if (userInfoVar()?.campus?.company?.name === 'creo') {
      return false;
    }
    return true;
  }, []);

  const isWEnglish = userInfoVar()?.campus?.company?.idx===1016 ? true : false;
  
  const history = useHistory();
  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}
    >
      <MenuButton
        type={`${currentMenu === 'metaExam' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/consultingmeta');
        }}
      >
       {isWEnglish ? '': '메타 '} 시험
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'metaSchedule' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/schedulemeta');
        }}
      >
        {isWEnglish ? '': '메타 '} 일정
      </MenuButton>
    </div>
  );
};

export default MetaExamMenuGroup;
