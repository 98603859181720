import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { isIOS } from 'react-device-detect';
import { SoundFilled } from '@ant-design/icons';
import * as queries from 'src/operations/queries';
import SoundWords from 'src/components/common/SoundWords';
import SoundEffect from 'src/components/common/SoundEffect';
import { useHistory, useParams } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CREATE_EXTERNAL_USER_STUDY } from 'src/operations/mutations/externalUserStudy';
import LevelUpFinishPopUp from 'src/components/LevelUp/FinishPopUp';
import LevelUpExitPopUp from 'src/components/LevelUp/ExitPopUp';
import { isMobile } from 'react-device-detect';
const { LEVEL_UP_STUDY_DATA } = queries.externalStudy;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #443ec8;
  color: #fff;
  /* margin-top: 20px; */
  font-size: 25px;
  box-shadow: 0 2px 1px 2px #090989;
  z-index: 10;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled(RowFlex)`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  align-items: baseline;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const XButton = styled.div`
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  /* min-height: 550px; */
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
    min-height: 600px;
  }
`;

const LeftBody = styled(ColumnFlex)`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  height: 80%;
`;
const ContentInnerBox = styled(ColumnFlex)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 5px 18px 25px;
  background-color: #fff;
  border-radius: 20px;
`;
const StageTitle = styled.div`
  color: #fff;
  padding: 5px 60px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  background-color: #49ada1;
`;
const Direction = styled.div`
  color: #000000;
  padding: 10px 50px 15px;
  /* border-radius: 5px; */
  font-size: 20px;
  font-weight: 600;
  /* background-color: #49ada1; */
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
  text-align: center;
`;
const TypeArea = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* background-color: #fff; */
  color: #453ec8;
  width: 100%;
  height: 40px;
  text-align: center;
  padding: 0 20px;
  font-size: ${(props) => (props.isLong ? '25px' : '37px')};
`;
const TryScoreText = styled(RowFlex)`
  color: #000000;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const TryNumber = styled.span`
  color: #ff005c;
`;
const ScoreNumber = styled.span`
  color: #423ec6;
`;
const EngTextBox = styled(RowFlex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 30px;
  font-weight: 600;
  color: gray;
`;
const KorTextBox = styled(RowFlex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px 0;
  font-size: 30px;
  font-weight: 600;
`;
const TypeBox = styled(RowFlex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* background-color: #dbf3fb; */
  border: 2px solid #a3d6d1;
  border-radius: 10px;
  /* padding: 10px; */
  height: 60px;
`;
const TypeInput = styled.input`
  width: 0px;
  height: 0px;
  border: none;
  outline: none;
  color: transparent;
  caret-color: transparent;
`;
const CheckButtonBox = styled(ColumnFlex)`
  /* background-color: #daedeb; */
  /* border-radius: 10px; */
  /* height: 100%; */
  width: 100%;
  justify-content: center;
  padding: 15px 0;
  align-items: end;
`;
const CheckButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #453ec8;
  color: #fff;
  border-radius: 18px;
  height: 36px;
  font-size: 25px;
  padding: 4px 20px;
  cursor: pointer;
  box-shadow: 0 2px 1px 2px rgba(9, 10, 137, 1);
  @media (max-width: 1280px) {
    font-size: 20px;
    margin-left: 12px;
  }
`;
const RightBody = styled(ColumnFlex)`
  width: 40%;
  padding: ${(props) => (props.sentence ? '0 15px' : '0 20px')};
  height: 95%;
  /* justify-content: space-between; */
`;

const WordListBox = styled(ColumnFlex)`
  background-color: #ffb900;
  border-radius: 25px;
  border: 2px #fff solid;
  height: 90%;
  color: #000000;
  font-weight: 700;
  font-size: 28px;
  align-items: center;
  padding: ${(props) => (props.sentence ? '25px 5px' : '25px')};
  justify-content: space-between;
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.05);
`;
const WordListInnerBox = styled(ColumnFlex)`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;
const WordBox = styled.div`
  border-radius: 10px;
  border: 2px #fff solid;
  background-color: ${(props) => (props.active ? '#fef189' : '#fff')};
  color: #595758;
  margin-top: 10px;
  width: 90%;
  text-align: center;
  font-size: 18px;
  line-height: 1.8;
`;
const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  /* border: 1px red solid; */
  background-image: url(${(props) => props.bg});
  background-size: 72px 50px;
  background-repeat: no-repeat;
  /* background-color: ${(props) => (props.active ? 'red' : 'orange')}; */
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  /* cursor: pointer; */
`;
const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  align-items: center;
  justify-content: center;
  border-radius: 20px 20px 0 0;
  max-width: 300px;
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const allowedKeys = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const rightSound = '/audio/LevelUp/quiz_correct_new.mp3';
const wrongSound = '/audio/LevelUp/quiz_wrong.mp3';
const typeSound = '/audio/LevelUp/type.mp3';
const useCheckScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return [width > 1600 ? 1 : width / 1600, height > 900 ? 1 : height / 900];
};
export default function LevelUpVocaType({ location }) {
  let width = useCheckScreen()[0];
  let height = useCheckScreen()[1];
  const { state } = location;
  const { idx } = useParams();
  const [currentIdx, setCurrentIdx] = useState(0);
  const [rightCount, setRightCount] = useState(0);
  const [tryCount, setTryCount] = useState(3);
  const [wordUrl, setWordUrl] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [userInput, setUserInput] = useState([]);
  const [shouldFocus, setShouldFocus] = useState(false);
  const typeAreaRef = useRef(null);
  const [sound, setSound] = useState('');
  const [showKor, setShowKor] = useState(true);
  const { data } = useQuery(LEVEL_UP_STUDY_DATA, { variables: { type: 'levelup', book_code: String(idx) }, skip: !idx });

  const [createExternalUserStudy, { loading: loadingSave }] = useMutation(CREATE_EXTERNAL_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료
      setTimeout(() => {
        setIsFinished(true);
      }, 1000);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const studyData = useMemo(() => {
    const tmp = [
      // {eng:'cute-cat pudding',audio:undefined,blind:'cute-cat pudding'.split("").map((char) => allowedKeys.includes(char) ? "●":char).join('')}
    ];
    if (data?.getExternalStudyData) {
      data?.getExternalStudyData.map((e) => {
        tmp.push({
          eng: e.eng,
          kor: e.kor,
          audio: e.eng_audio,
          blind: e.eng
            .split('')
            .map((char) => (allowedKeys.includes(char) ? '●' : char))
            .join(''),
        });
      });
    }
    return tmp;
  }, [data]);
  const [show, setShow] = useState(
    studyData[currentIdx] && studyData[currentIdx]?.eng.split('').map((char) => (allowedKeys.includes(char) ? '●' : char)),
  );
  const gotoNext = async () => {
    if (!state?.bookData) return;
    const external_user_study_input = {
      user_idx: !_.isNil(userInfoVar()?.idx) ? parseInt(userInfoVar()?.idx) : 0,
      external_study_idx: state?.bookData?.book_no,
      // assigned_idx: parseInt(assignedIdx),
      type: 'levelup',
      // lesson_code: lessonCode,
      // stage_no: parseInt(stageNumber),
      article_data: state?.bookData || null,
      exam_total: studyData?.length,
      exam_correct: parseInt(rightCount),
      stage_no: 4,
      stage_answers: JSON.stringify(studyData),
    };

    if (state?.bookData?.assignedIdx) {
      external_user_study_input['assigned_idx'] = parseInt(state?.bookData?.assignedIdx);
    }

    await createExternalUserStudy({
      variables: { external_user_study_input },
    });
  };

  const handleSoundEnd = () => {
    setWordUrl(undefined);
  };
  useEffect(() => {
    if (studyData.length > currentIdx) {
      typeAreaRef.current.focus();
    }
  }, [tryCount, currentIdx, studyData]);

  useEffect(() => {
    if (shouldFocus && typeAreaRef.current) {
      typeAreaRef.current.focus();
    }
  }, [shouldFocus]);

  // useEffect(() => {
  //   if (currentIdx) {
  //     const tmp = studyData[currentIdx]?.eng.split('').map((char) => (allowedKeys.includes(char) ? '●' : char));
  //     setShow(tmp);
  //   }
  // }, [currentIdx]);
  // useEffect(() => {}, [userInput]);

  const handleBlur = () => {
    setShouldFocus(false);
  };
  const handleOtherClick = () => {
    // setShouldFocus(true);
    if (typeAreaRef.current) {
      typeAreaRef.current.focus();
    }
  };
  const handleWrapperClick = (event) => {
    event.stopPropagation();
    setShouldFocus(true);
    if (typeAreaRef.current) {
      typeAreaRef.current.focus();
    }
  };
  useEffect(() => {
    if (currentIdx + 1 > studyData.length && studyData.length !== 0) {
      // setTimeout(() => {
      gotoNext();
      // }, 1800);
    }
    if (!isMobile || currentIdx !== 0) {
      setTimeout(() => {
        setWordUrl(studyData[currentIdx]?.audio);
      }, 1000);
    }
  }, [currentIdx, studyData, isMobile]);

  // useEffect(() => {
  //   const wordresult = getUpdatedList(studyData[currentIdx].eng);
  //   setText(wordresult);
  //   setUserInput(wordresult.split(""));
  //   setAnswer(studyData[currentIdx].eng);
  //   let ind = wordresult.split("").findIndex((ele, index) => {
  //     if (ele == "⬤") {
  //       return ele;
  //     }
  //   });
  //   if (ind >= 0) {
  //     setIndx(ind);
  //     setFirstIndx(ind);
  //   }
  // }, [currentIdx,studyData]);
  useEffect(() => {
    setShowKor(true);
    setTimeout(() => {
      setShowKor(false);
    }, 2000);
  }, [currentIdx]);
  const getUpdatedList = (word, all) => {
    let randDot = word.trim();
    randDot = randDot.replace(/[<>\[\]]*/g, '');
    const lenrandDot = Math.floor(randDot.replace(/\s*/g, '').length * 0.3);
    let last = 0;
    randDot = randDot
      .trim()
      .split('')
      .map((v) => {
        if (!v.match(/^[0-9a-zA-Z]+$/)) return v;
        if (all) return '⬤';

        return '⬤';
      })
      .join('');
    return randDot;
  };

  const handleKeyPress = (event) => {
    if (showKor) return;
    const clonedEvent = { ...event };
    let { key } = clonedEvent;
    if (key === 0 || key === 229 || key === 'Unidentified' || key === 'Process') {
      //for android chrome keycode fix
      key = typeAreaRef.current.value[typeAreaRef.current.value.length - 1];
      event.target.value = key;
    }

    let tempInput = '';

    const currentBlind = studyData[currentIdx]?.blind;
    if (String(key) === 'Enter') {
      if (userInput.join('') === studyData[currentIdx]?.eng) {
        setSound(rightSound);
        setRightCount((prev) => prev + 1);
        setCurrentIdx((prev) => prev + 1);
        setTryCount(3);
      } else {
        setSound(wrongSound);
        if (tryCount === 1) {
          setCurrentIdx((prev) => prev + 1);
          setTryCount(3);
        } else {
          setTryCount((prev) => prev - 1);
        }
      }
      typeAreaRef.current.value = '';
      setUserInput([]);
    }
    if (String(key) === 'Backspace' && userInput.length > 0) {
      setSound(typeSound);
      tempInput = JSON.parse(JSON.stringify(userInput));
      let checkTextIndex = tempInput.length - 1;

      while (currentBlind[checkTextIndex] !== '●' && checkTextIndex < currentBlind.length) {
        tempInput.pop();
        checkTextIndex = checkTextIndex - 1;
      }
      tempInput.pop();

      setUserInput(tempInput);
      typeAreaRef.current.value = '';
    }
    // key = String(key).toLowerCase();
    const re = /[\u3131-\uD79D]/giu;
    if (allowedKeys.includes(key) || re.test(key)) {
      setSound(typeSound);
      if (userInput.length < studyData[currentIdx]?.eng.length) {
        tempInput = JSON.parse(JSON.stringify(userInput));
        tempInput.push(String(key));
        let checkTextIndex = tempInput.length;
        while (currentBlind[checkTextIndex] !== '●' && checkTextIndex < currentBlind.length) {
          tempInput.push(currentBlind[checkTextIndex]);
          checkTextIndex = checkTextIndex + 1;
        }

        setUserInput(tempInput);
        if (studyData.length > currentIdx) {
          typeAreaRef.current.focus();
          typeAreaRef.current.value = '';
          typeAreaRef.current.value = key;
        }
      }
    }
  };
  const [mobileStart, setMobileStart] = useState(isMobile);
  const handleMobileStart = () => {
    setMobileStart(false);
    setWordUrl(studyData[currentIdx]?.audio);
    if (typeAreaRef.current) {
      typeAreaRef.current.focus();
    }
  };
  return (
    <>
      {visible && (
        <LevelUpExitPopUp assigned_idx={parseInt(state?.bookData?.assignedIdx)} visible={visible} setVisible={setVisible} idx={idx} />
      )}
      {isFinished ? (
        <LevelUpFinishPopUp idx={idx} assigned_idx={parseInt(state?.bookData?.assignedIdx)} />
      ) : (
        <Wrapper onClick={handleWrapperClick}>
          <MainWrapper>
            <Main>
              {mobileStart && (
                <div
                  style={{ width: '100%', height: '100%', position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.1)', zIndex: 11 }}
                  onClick={handleMobileStart}
                ></div>
              )}
              <Title>
                <span>
                  <TitleSpan color={'#453ec8'}>{state?.bookData?.title || ''}</TitleSpan>
                  {/* <TitleSpan>{state?.bookData?.title || ''}</TitleSpan> */}
                </span>
                <XButton
                  onClick={() => {
                    setVisible(true);
                  }}
                />
              </Title>
              <ColumnFlex style={{ justifyContent: 'space-between', height: '93%' }}>
                <RowFlex
                  onClick={handleOtherClick}
                  style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '15px 50px 0' }}
                >
                  <StageTitle>Typing</StageTitle>
                  <NumberText>
                    Number{' '}
                    <QuizNumber>
                      <span style={{ color: '#433fc8' }}>{studyData.length > currentIdx + 1 ? currentIdx + 1 : studyData.length}</span>/
                      {studyData.length}
                    </QuizNumber>
                  </NumberText>
                </RowFlex>
                <Direction style={{ paddingBottom: height < 0.8 && 0 }}> Look and listen. Then type.</Direction>
                <RowFlex style={{ height: '100%', padding: '0 50px' }}>
                  <LeftBody>
                    {/* <ContentBox> */}
                    {/* Look and listen. Then type. */}
                    <ContentInnerBox>
                      <RowFlex
                        onClick={handleOtherClick}
                        style={{ fontSize: '18px', width: '100%', justifyContent: 'right', lineHeight: '1.2' }}
                      >
                        <TryScoreText>
                          {' '}
                          Tries&nbsp;
                          <QuizNumber style={{ width: '60px' }}>
                            <TryNumber>{tryCount}</TryNumber>/{`3`}
                          </QuizNumber>
                        </TryScoreText>
                        &nbsp;&nbsp;
                        <TryScoreText>
                          Score&nbsp;
                          <QuizNumber style={{ width: '60px' }}>
                            <ScoreNumber>{Math.round((rightCount * 100) / studyData.length)}</ScoreNumber>
                          </QuizNumber>
                        </TryScoreText>
                      </RowFlex>
                      {studyData.length > currentIdx && (
                        <EngTextBox>{showKor || tryCount === 1 ? studyData[currentIdx].eng : '　'}</EngTextBox>
                      )}
                      {studyData.length > currentIdx && <KorTextBox>{studyData[currentIdx].kor}</KorTextBox>}
                      {studyData.length > currentIdx && (
                        <TypeBox>
                          <TypeInput
                            tabIndex={0}
                            style={{ outline: 'none' }}
                            onKeyUp={handleKeyPress}
                            onBlur={handleBlur}
                            ref={typeAreaRef}
                            autoCorrect="off"
                            autoComplete="off"
                            autoCapitalize="none"
                            spellCheck="false"
                            maxLength={studyData[currentIdx]?.eng?.length}
                          />
                          <TypeArea isLong={studyData[currentIdx]?.eng?.length > 20}>
                            {userInput.map((e) => (e === ' ' ? <span>&nbsp;</span> : <span>{e}</span>))}
                            {/* {show.join('')} */}
                            {(studyData[currentIdx]?.blind || '')
                              .slice(userInput.length)
                              .split('')
                              .map((e) => (
                                <span>{e}</span>
                              ))}
                          </TypeArea>
                        </TypeBox>
                      )}
                      <CheckButtonBox>
                        <CheckButton
                          onClick={() =>
                            handleKeyPress({
                              key: 'Enter',
                            })
                          }
                        >
                          Check
                        </CheckButton>
                      </CheckButtonBox>
                    </ContentInnerBox>
                    {/* </ContentBox> */}

                    <ImgButton
                      onClick={() => {
                        setWordUrl(studyData[currentIdx]?.audio);
                        handleOtherClick();
                      }}
                    >
                      <SoundFilled />
                    </ImgButton>
                  </LeftBody>
                  {/* <RightBody onClick={handleOtherClick}>
                    <WordListBox>
                      <div style={{ marginBottom: '15px' }}>Word List</div>
                      <WordListInnerBox>
                        {studyData.map((e, i) => {
                          return <WordBox active={currentIdx === i}>{currentIdx > i ? e.eng : e.blind}</WordBox>;
                        })}
                      </WordListInnerBox>
                    </WordListBox>
                  </RightBody> */}
                </RowFlex>
              </ColumnFlex>
            </Main>
          </MainWrapper>
          <StageButtonWrapper>
            <RowFlex style={{ justifyContent: 'space-between', width: '60%', height: '100%' }}>
              <StageButtonText active={false}>
                <StageButton bg={`/images/LevelUp/study/sub/g_unscramble.png`} isDone={true} />
                Check
              </StageButtonText>
              <StageButtonText active>
                <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} isDone={false} />
                Typing
              </StageButtonText>
            </RowFlex>
          </StageButtonWrapper>
        </Wrapper>
      )}
      <SoundWords words={wordUrl ? [wordUrl] : []} onEnd={handleSoundEnd} />
      <SoundEffect src={sound} onEnd={() => setSound(null)} replayCnt={1} />
    </>
  );
}
