import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { Typography, Select, InputNumber } from 'antd';
import { GET_ELIBRARY_RESULT_FOR_REPORTCARD } from 'src/operations/queries/reportcard';
import moment from 'moment';

const { Option } = Select;
const FinalTestTh = styled.th`
  border-left: 1px solid #c5c5c5b3;
  border-top: 1px solid #c5c5c5b3;
  font-size: 0.8em;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #9b0000;
  color: #fff;
`;
const FinalTestTd = styled.td`
  border: 1px solid #c5c5c5b3;
  text-align: center;
  padding: 5px 0;
`;
const FinalTestTdEnd = styled.td`
  border: 1px solid #c5c5c5b3;
  text-align: center;
  padding: 5px 0;
  background: #fbe5d6;
`;
const FinalTable = styled.table`
  font-size: 0.8em;
  @media print {
    font-size: 0.5em;
  }
`;

const Title = styled.title`
  margin-bottom: 0.5em;
  color: #008080;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  border-top: 3px solid #9b0000;
  display: block;
  margin-top: 15px;
`;

const TEST_TYPE_LIST = [
  {
    value: 'voca',
    label: 'Vocabulary',
  },
  {
    value: 'gram',
    label: 'Grammar',
  },
  {
    value: 'reading',
    label: 'Reading',
  },
  {
    value: 'listening',
    label: 'Listening',
  },
  {
    value: 'writing',
    label: 'Writing',
  },
];

const FinalTestInputTableLucid = ({ class_idx, user_idx, dateRange, onChangeScore, result, bookTitle, editable = false }) => {
  const [manualFinalTestScore, setManualFinalTestScore] = useState({
    voca: [0, 0],
    gram: [0, 0],
    reading: [0, 0],
    listening: [0, 0],
    writing: [0, 0],
  });
  const [performanceData, setPerformanceData] = useState({
    voca: [0, 0],
    gram: [0, 0],
    reading: [0, 0],
    listening: [0, 0],
    writing: [0, 0],
  });
  const [start_date, end_date] = useMemo(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      return [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).add(7, 'days').format('YYYY-MM-DD')];
    }
    return [undefined, undefined];
  }, [dateRange]);

  const { data, loading } = useQuery(GET_ELIBRARY_RESULT_FOR_REPORTCARD, {
    variables: { class_idx, user_idx, start_date, end_date },
    skip: !class_idx || !user_idx || !start_date || !end_date,
  });

  const dataJSON = useMemo(() => {
    if (result) {
      return JSON.parse(result);
    }
    return undefined;
  }, [result]);

  const [bookCount, score, recordingGrade, goalCount, bookCountPerWeek] = useMemo(() => {
    const elibInfo = data?.studentElibraryStudyStatis;
    if (elibInfo) {
      const { read_book_count, average_grade, recording_grade, goal_count, book_count_per_weeks } = elibInfo;
      const bookCountPerWeek = Object.keys(book_count_per_weeks).map((item, index) => {
        return {
          name: index + 1,
          books: book_count_per_weeks[item],
        };
      });

      return [read_book_count, average_grade, recording_grade, goal_count, bookCountPerWeek];
    }
    return ['--', '--', '--', '--'];
  }, [data]);

  useEffect(() => {
    onChangeScore({
      test_scores: manualFinalTestScore,
      performance_data: performanceData,
    });
  }, [manualFinalTestScore, performanceData]);

  const handleChangeFinalScore = (finalScore, type) => {
    const [, t] = manualFinalTestScore[type];
    if (isNaN(finalScore)) {
      manualFinalTestScore[type] = [0, t];
    } else {
      manualFinalTestScore[type] = [finalScore, t];
    }

    setManualFinalTestScore({ ...manualFinalTestScore });
  };
  const handleChangeFinalTotal = (total, type) => {
    const [s] = manualFinalTestScore[type];
    if (isNaN(total)) {
      manualFinalTestScore[type] = [s, 0];
    } else {
      manualFinalTestScore[type] = [s, total];
    }
    setManualFinalTestScore({ ...manualFinalTestScore });
  };

  const handleChangePerformanceScore = (finalScore, type) => {
    const [, t] = performanceData[type];
    if (isNaN(finalScore)) {
      performanceData[type] = [0, t];
    } else {
      performanceData[type] = [finalScore, t];
    }

    setPerformanceData({ ...performanceData });
  };

  const handleChangePerformanceTotal = (total, type) => {
    const [s] = performanceData[type];
    if (isNaN(total)) {
      performanceData[type] = [s, 0];
    } else {
      performanceData[type] = [s, total];
    }
    setPerformanceData({ ...performanceData });
  };

  useEffect(() => {
    if (dataJSON) {
      if (dataJSON?.test_scores) {
        setManualFinalTestScore(dataJSON.test_scores);
      }
      if (dataJSON?.performance_data) {
        setPerformanceData(dataJSON.performance_data);
      }
    }
  }, [dataJSON]);

  const renderTestRow = (type, title) => {
    const [score, total] = manualFinalTestScore[type];
    return !editable ? (
      <tr key={type}>
        <FinalTestTd>{title}</FinalTestTd>
        <FinalTestTd>{`${score}/${total}`}</FinalTestTd>
        <FinalTestTd>{total && Math.round((100 * score) / total)}</FinalTestTd>
      </tr>
    ) : (
      <tr key={type}>
        <>
          <FinalTestTd>{title}</FinalTestTd>
          <FinalTestTd>
            <InputNumber size="small" min={0} max={100} onChange={(v) => handleChangeFinalScore(v, type)} value={score} /> /&nbsp;
            <InputNumber size="small" min={0} max={100} onChange={(v) => handleChangeFinalTotal(v, type)} value={total} />
          </FinalTestTd>
          <FinalTestTd>
            <InputNumber size="small" disabled value={total && Math.round((100 * score) / total)} />
          </FinalTestTd>
        </>
      </tr>
    );
  };

  const totalScores = useMemo(() => {
    let finalScore = 0;
    let totalScore = 0;
    TEST_TYPE_LIST.forEach((type) => {
      const [score, total] = manualFinalTestScore[type.value];
      finalScore += score;
      totalScore += total;
    });
    return [finalScore, totalScore];
  }, [manualFinalTestScore, TEST_TYPE_LIST]);

  const performanceGrapgData = useMemo(() => {
    return TEST_TYPE_LIST.map((type) => {
      const [my, classAvg] = performanceData[type.value];
      return {
        name: type.label,
        my: my,
        classAvg: classAvg,
      };
    });
  }, [performanceData]);

  return (
    <>
      <Title>● E-library Record</Title>
      <div style={{ display: 'flex' }}>
        <FinalTable style={{ width: '50%', borderTop: '3px solid #c5c5c5b3' }}>
          <thead>
            <tr>
              <FinalTestTh>CONTENTS</FinalTestTh>
              <FinalTestTh></FinalTestTh>
            </tr>
          </thead>

          <tbody>
            <tr>
              <FinalTestTd>NUMBER OF E-Books(Done) </FinalTestTd>
              <FinalTestTd>{bookCount}</FinalTestTd>
            </tr>
            <tr>
              <FinalTestTd>GOAL OF THE MONTH</FinalTestTd>
              <FinalTestTd>{goalCount}</FinalTestTd>
            </tr>
            <tr>
              <FinalTestTd>ACHIEVEMENT(%)</FinalTestTd>
              <FinalTestTd>{score}</FinalTestTd>
            </tr>
            <tr>
              <FinalTestTd>RECORDING TEST SCORE</FinalTestTd>
              <FinalTestTd>{recordingGrade}</FinalTestTd>
            </tr>
          </tbody>
        </FinalTable>
        <ResponsiveContainer width="50%" height={200} style={{ width: '50%', borderTop: '3px solid #c5c5c5b3' }}>
          <LineChart height={200} data={bookCountPerWeek} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line name="Number of Books" type="monotone" dataKey="books" stroke="#8884d8" />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <Title level={4}>● Performance Graph</Title>
      <ResponsiveContainer width="100%" height={200} style={{ width: '100%', borderTop: '3px solid #c5c5c5b3' }}>
        <BarChart width={730} height={250} data={performanceGrapgData}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis />
          <Bar dataKey="my" fill="#4472C4" />
          <Bar dataKey="classAvg" fill="#ED7D31" />
        </BarChart>
      </ResponsiveContainer>
      <div style={{ width: '100%', padding: '5px' }}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <FinalTestTd></FinalTestTd>
              {TEST_TYPE_LIST.map((type) => (
                <FinalTestTd key={type.value}>{type.label}</FinalTestTd>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <FinalTestTd>학생 점수</FinalTestTd>
              {editable
                ? TEST_TYPE_LIST.map((type) => {
                    const [score] = performanceData[type.value];
                    return (
                      <FinalTestTd key={type.value}>
                        <InputNumber
                          size="small"
                          min={0}
                          max={100}
                          onChange={(v) => handleChangePerformanceScore(v, type.value)}
                          value={score}
                        />
                      </FinalTestTd>
                    );
                  })
                : TEST_TYPE_LIST.map((type) => {
                    const [score] = performanceData[type.value];
                    return <FinalTestTd key={type.value}>{score}</FinalTestTd>;
                  })}
            </tr>
            <tr>
              <FinalTestTd>전체 평균</FinalTestTd>
              {editable
                ? TEST_TYPE_LIST.map((type) => {
                    const [, classAvg] = performanceData[type.value];
                    return (
                      <FinalTestTd key={type.value}>
                        <InputNumber
                          size="small"
                          min={0}
                          max={100}
                          onChange={(v) => handleChangePerformanceTotal(v, type.value)}
                          value={classAvg}
                        />
                      </FinalTestTd>
                    );
                  })
                : TEST_TYPE_LIST.map((type) => {
                    const [, classAvg] = performanceData[type.value];
                    return <FinalTestTd key={type.value}>{classAvg}</FinalTestTd>;
                  })}
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
        <h3 style={{ borderLeft: '15px solid #4472C4', paddingLeft: '2px', marginRight: '10px' }}>학생 점수</h3>
        <h3 style={{ borderLeft: '15px solid #ED7D31', paddingLeft: '2px', marginLeft: '10px' }}>전체 평균</h3>
      </div>

      <Title level={4}>● Monthly Test</Title>
      <FinalTable style={{ width: '100%', borderTop: '3px solid #c5c5c5b3' }}>
        <thead>
          <tr>
            <FinalTestTh>Part</FinalTestTh>
            <FinalTestTh>Test Score</FinalTestTh>
            <FinalTestTh>ACHIEVEMENT(%)</FinalTestTh>
          </tr>
        </thead>
        <tbody>
          {TEST_TYPE_LIST.map((item) => renderTestRow(item.value, item.label))}
          <tr>
            <FinalTestTdEnd>TOTAL</FinalTestTdEnd>
            <FinalTestTdEnd>
              {totalScores[0]}/{totalScores[1]}
            </FinalTestTdEnd>
            <FinalTestTdEnd>{totalScores[1] && Math.round((100 * totalScores[0]) / totalScores[1])}</FinalTestTdEnd>
          </tr>
        </tbody>
      </FinalTable>
    </>
  );
};

export default FinalTestInputTableLucid;
