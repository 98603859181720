import React, { useState, useMemo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as axios from 'axios';
import { userInfoVar } from 'src/apollo/cache';
import { getCurrentToken } from 'src/utils';
import { isMobile } from 'react-device-detect';
import BookrOpenModal from 'src/components/Modal/BookrOpenModal';
import { FAVORITE_BOOK, GET_LIKE_LIST, GET_CONTINUE_LIST, GET_POPULAR_LIST } from 'src/operations/mutations/evineStudy';
import { Radio } from 'antd';
import Masonry from 'react-masonry-css';
import { useMutation } from '@apollo/client';
import jwtDecode from 'jwt-decode';

const topicList = [
  { code: 1, topic: 'Colors and shapes' },
  { code: 2, topic: 'Alphabet' },
  { code: 3, topic: 'Numbers' },
  { code: 8, topic: 'People' },
  { code: 9, topic: 'Nature' },
  { code: 10, topic: 'Place of living' },
  { code: 11, topic: 'Travelling' },
  { code: 12, topic: 'Shopping' },
  { code: 13, topic: 'Food and drinks' },
  { code: 14, topic: 'Lifestyle' },
  { code: 15, topic: 'Free time' },
  { code: 16, topic: 'Time and weather' },
  { code: 18, topic: 'Science' },
  { code: 19, topic: 'School' },
  { code: 20, topic: 'Work' },
  { code: 5866, topic: 'Personality' },
  { code: 5867, topic: 'Relationships' },
  { code: 5868, topic: 'Sport' },
  { code: 5869, topic: 'Classics' },
  { code: 5870, topic: 'Smart' },
  { code: 5871, topic: 'Animals' },
  { code: 5872, topic: 'Technology' },
  { code: 5873, topic: 'Aliens and monsters' },
  { code: 5874, topic: 'Music' },
  { code: 5875, topic: 'Vehicles' },
  { code: 5876, topic: 'Culture' },
  { code: 5877, topic: 'Cute' },
  { code: 5878, topic: 'Friends' },
  { code: 5879, topic: 'Funny' },
  { code: 5880, topic: 'Magic' },
  { code: 5948, topic: 'Body and health' },
  { code: 6025, topic: 'Everyday life' },
];
function binarySearch(arr, value) {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    let mid = Math.floor((left + right) / 2);

    if (arr[mid].code === value) {
      return mid;
    } else if (arr[mid].code < value) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return -1;
}
const useBookrBooks = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const setBookLevel = (book) => {
    const filteredCode = book.terms.filter((code) => topicList.some((obj) => obj.code === code));
    if (book.terms.includes(21)) {
      return {
        ...book,
        level: 1,
        topic: filteredCode.map((e) => topicList[binarySearch(topicList, e)].topic),
        coverOptimal: getCoverUrl(book),
      };
    } else if (book.terms.includes(22)) {
      return {
        ...book,
        level: 2,
        topic: filteredCode.map((e) => topicList[binarySearch(topicList, e)].topic),
        coverOptimal: getCoverUrl(book),
      };
    } else if (book.terms.includes(23)) {
      return {
        ...book,
        level: 3,
        topic: filteredCode.map((e) => topicList[binarySearch(topicList, e)].topic),
        coverOptimal: getCoverUrl(book),
      };
    } else if (book.terms.includes(24)) {
      return {
        ...book,
        level: 4,
        topic: filteredCode.map((e) => topicList[binarySearch(topicList, e)].topic),
        coverOptimal: getCoverUrl(book),
      };
    } else if (book.terms.includes(25)) {
      return {
        ...book,
        level: 5,
        topic: filteredCode.map((e) => topicList[binarySearch(topicList, e)].topic),
        coverOptimal: getCoverUrl(book),
      };
    } else if (book.terms.includes(26)) {
      return {
        ...book,
        level: 6,
        topic: filteredCode.map((e) => topicList[binarySearch(topicList, e)].topic),
        coverOptimal: getCoverUrl(book),
      };
    } else {
      return {
        ...book,
        level: 0,
        topic: filteredCode.map((e) => topicList[binarySearch(topicList, e)].topic),
        coverOptimal: getCoverUrl(book),
      };
    }
  };

  const fetchBooks = async () => {
    try {
      setLoading(true);
      const {
        data: {
          result: { list },
        },
      } = await axios.get('https://cdn.cloubot.com/BOOKR/books.json');
      setBooks(
        list.map((book) => {
          return setBookLevel(book);
        }),
      );
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBooks();
  }, []);

  return { books, loading, error };
};

const getCoverUrl = (book) => {
  const { coverUrls, coverTallUrls, coverShortUrls, id } = book;

  if (id % 5 === 0) {
    return coverShortUrls?.optimal || coverUrls?.optimal;
  }
  if (id % 3 === 0) {
    return coverTallUrls?.optimal || coverUrls?.optimal;
  } else {
    return coverUrls?.optimal || coverUrls?.original;
  }
};

const BookData = ({ book }) => {
  const { title, id } = book;
  const { pathname } = useLocation();
  const cover = book?.coverOptimal || book?.coverUrls?.optimal || book?.coverUrls?.original;
  const like = book?.like;
  const [bookData, setBookData] = useState({});
  const [bookrData, setBookrData] = useState(null);

  const baseApi = id ? `bookrclass://book/${id}?ssoId=cloubot` : 'bookrclass://app?ssoId=cloubot';
  const token = getCurrentToken();

  const [favBook, { loading: loadingDelete }] = useMutation(FAVORITE_BOOK, {
    onCompleted: (res) => {},
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  const handleClicked = () => {
    let pathArr = pathname.split('/');
    pathArr = pathArr.filter((item) => item !== '');
    let returnUrl = `${window.location.origin}/bookr/redirect/${userInfoVar().idx}/${id}/${0}/${pathArr.join('|')}`;
    if (window.ReactNativeWebView) {
      returnUrl = `culpmobile://oauth/bookr/redirect/${userInfoVar().idx}/${id}/${0}/${pathArr.join('|')}`;
    }
    // if (isMobile) window.location.href = `${baseApi}?token=${token}&returnUrl=${returnUrl}`;
    // else window.open(`${baseApi}?token=${token}&returnUrl=${returnUrl}`, '_blank', 'noopener,noreferrer');
    setBookrData({
      url: `${baseApi}&clientToken=${token}&returnUrl=${returnUrl}`,
    });
  };
  const fetchBook = async () => {
    try {
      const data = await axios.get(`https://cdn.cloubot.com/BOOKR/texts/${id}.json`);
      setBookData(data.data[0]);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchBook();
  }, []);

  const handleFav = (e, book) => {
    e.stopPropagation();
    favBook({ variables: { book_id: book?.id, type: 'bookr' } });
    book.like ? delete book.like : (book.like = true);
  };
  return (
    <>
      <div className="bookcover-con">
        <div className="sampleImgTest_image">
          <div id="div1" style={{ position: 'relative' }} onClick={handleClicked}>
            <img src={`${cover}`} alt="img" />
            {bookData.text && (
              <Link to={`/bookr/recording/${id}`}>
                <div className="starMark"> </div>
              </Link>
            )}
            <div id="div2" className="favMark">
              {like && (
                <img
                  className="heart-icon"
                  src="./images/pink-heart-1.png"
                  onClick={(e) => {
                    handleFav(e, book);
                  }}
                />
              )}
              {!like && (
                <img
                  className="heart-icon"
                  src="./images/black-heart-1.png"
                  onClick={(e) => {
                    handleFav(e, book);
                  }}
                />
              )}
            </div>
          </div>
          <input type="checkbox" id="check" style={{ display: 'none' }}></input>
          <button onClick={handleClicked}>{title}</button>
        </div>
      </div>

      {bookrData !== null && <BookrOpenModal visible={true} url={bookrData?.url} handleClose={() => setBookrData(null)} />}
    </>
  );
};

const LibraryContent = ({ level, sort, search, bookType, topic, setSearchOption, searchValue, searchHistory, setSearchHistory }) => {
  let { books, loading, error } = useBookrBooks();
  const [selectionStatus, setSelectionStatus] = useState([]);
  const [filterBookList, setFilterBookList] = useState();
  const [likeBookList, setLikeBookList] = useState();
  const token = localStorage.getItem('culp_token');

  const option = Array.from(new Set(books.map((e) => e.title))).sort((a, b) => (a.toUpperCase() < b.toUpperCase() ? -1 : 1));
  let searchResult = localStorage.getItem('searchData');

  useEffect(() => {
    if (searchResult == 'true') {
      setSelectionStatus({ like: false, continue: false, popular: false });
    }
  }, [searchResult]);

  useEffect(() => {
    if (searchHistory.length > 0 && searchValue.length === 0) {
      const recent = searchHistory.map((val, i) => ({
        value: val,
        label: (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{val}</span>
            <span
              key={i}
              onClick={(e) => {
                e.stopPropagation();
                const filtered = searchHistory.filter((list) => list !== val);
                localStorage.setItem('searchHistory', JSON.stringify(filtered));
                setSearchHistory(filtered);
              }}
            >
              삭제
            </span>
          </div>
        ),
      }));
      setSearchOption(recent);
    } else if (searchValue.length >= 0 && searchValue.length < 3) setSearchOption([]);
    else {
      const tmp = option
        ? option
            .filter((e) => e.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1)
            .slice(0, 10)
            .map((e) => {
              const highlighted = e.split(new RegExp(`(${searchValue})`, 'gi')).map((part, i) =>
                part.toLowerCase() === searchValue.toLowerCase() ? (
                  <span key={i} style={{ color: 'red' }}>
                    {part}
                  </span>
                ) : (
                  <span key={i}>{part}</span>
                ),
              );
              return { value: e, label: highlighted };
            })
        : [{ value: '', label: '' }];
      setSearchOption(tmp);
    }
  }, [search, searchValue, searchHistory]);

  const [likeBook, { loading: loadingDelete }] = useMutation(GET_LIKE_LIST, {
    onCompleted: (res) => {
      if (res?.getLikeBooks) {
        let booksFinal = books;
        let likeArr = [];
        const likeBook = JSON.parse(res?.getLikeBooks);
        setLikeBookList(likeBook);
        if (likeBook?.length > 0) {
          for (let i = 0; i < likeBook?.length; i++) {
            booksFinal.filter((ele) => (ele.id == likeBook[i].book_id ? likeArr.push(ele) : ''));
          }
          if (likeArr.length > 0) {
            setFilterBookList(likeArr);
            setSelectionStatus({ like: true, continue: false, popular: false });
          }
        }
      }
    },
    onError: (res) => {},
  });

  useEffect(() => {
    var userData = token ? jwtDecode(JSON.parse(token).accessToken) : '';
    likeBook({ variables: { user_idx: userData?.user_idx, type: 'bookr' } });
  }, [token]);

  useEffect(() => {
    let booksDup = books;
    if (likeBookList?.length > 0) {
      for (let i = 0; i < likeBookList?.length; i++) {
        booksDup.filter((ele) => (ele.id == likeBookList[i].book_id ? (ele.like = true) : ''));
        books = booksDup;
      }
    }
  }, [books, likeBookList]);

  const [continueBook, { loading: loadingContinue }] = useMutation(GET_CONTINUE_LIST, {
    onCompleted: (res) => {
      if (res?.getContinueBooks) {
        let booksContinue = books;
        let continueArr = [];
        const continueBook = JSON.parse(res?.getContinueBooks);
        if (continueBook?.length > 0) {
          for (let i = 0; i < continueBook?.length; i++) {
            const articleData = booksContinue.filter((ele) =>
              ele.id == JSON.parse(continueBook[i]?.article_data).id ? continueArr.push(ele) : '',
            );
          }
          if (continueArr.length > 0) {
            setFilterBookList(continueArr);
            setSelectionStatus({ like: false, continue: true, popular: false });
          }
        }
      }
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  const [popularBook, { loading: loadingPopular }] = useMutation(GET_POPULAR_LIST, {
    onCompleted: (res) => {
      if (res?.getPopularBooks) {
        let booksPopular = books;
        let popularArr = [];
        const popularBook = JSON.parse(res?.getPopularBooks);
        if (popularBook?.length > 0) {
          for (let i = 0; i < popularBook?.length; i++) {
            booksPopular.filter((ele) => (ele.id == JSON.parse(popularBook[i]?.article_data).id ? popularArr.push(ele) : ''));
          }
          if (popularArr.length > 0) {
            setFilterBookList(popularArr);
            setSelectionStatus({ like: false, continue: false, popular: true });
          }
        }
      }
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  let bookSelf = useMemo(() => {
    //divide in 10 book list  -> changed to be displayed infinity
    if (!loading) {
      let filteredBook;
      // let filteredBook = books.filter((book) => {
      if (selectionStatus?.like == true || selectionStatus?.continue == true || selectionStatus?.popular == true) {
        filteredBook = filterBookList?.filter((book) => {
          if (book.coverOptimal === undefined) return false;

          if (level === 'All') {
            return true;
          }
          return parseInt(book.level) === parseInt(level);
        });
      } else {
        filteredBook = books?.filter((book) => {
          // book.like = false
          if (book.coverOptimal === undefined) return false;

          if (level === 'All') {
            return true;
          }
          return parseInt(book.level) === parseInt(level);
        });
      }
      if (bookType === 'All') {
        filteredBook = filteredBook?.filter((book) => {
          return true;
        });
      } else {
        filteredBook = filteredBook?.filter((book) => {
          return book.type === bookType;
        });
      }
      if (topic === 'All') {
        filteredBook = filteredBook?.filter((book) => {
          return true;
        });
      } else {
        filteredBook = filteredBook?.filter((book) => {
          return book.topic.includes(topic);
        });
      }
      if (search !== '') {
        filteredBook = filteredBook?.filter((book) => {
          return book.title.toLowerCase().includes(search.toLowerCase());
        });
      }
      if (sort === 'Newest') {
        filteredBook = filteredBook?.sort((a, b) => {
          return new Date(b.publishAt) - new Date(a.publishAt);
        });
      } else if (sort === 'Oldest') {
        filteredBook = filteredBook?.sort((a, b) => {
          return new Date(a.publishAt) - new Date(b.publishAt);
        });
      } else if (sort === 'A-Z') {
        filteredBook = filteredBook?.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      } else if (sort === 'Z-A') {
        filteredBook = filteredBook?.sort((a, b) => {
          return b.title.localeCompare(a.title);
        });
      }

      return filteredBook ? Object.values(filteredBook) : [];
    }
    return [];
  }, [books, loading, level, bookType, search, sort, topic, selectionStatus]);
  if (loading) {
    return <div className="wait-comment">로딩중...</div>;
  }
  if (error) {
    return <div className="wait-comment">에러가 발생했습니다.</div>;
  }

  const breakpointColumnsObj = {
    default: 4,
    1400: 3,
    700: 2,
    500: 1,
  };
  const radioStyle = {
    display: 'block',
    height: '35px',
    lineHeight: '28px',
  };

  const handleChange = (e) => {
    if (e.target.value == 1) {
      popularBook({ variables: { user_idx: parseInt(userInfoVar().idx), type: 'bookr' } });
    }
    if (e.target.value == 2) {
      likeBook({ variables: { user_idx: parseInt(userInfoVar().idx), type: 'bookr' } });
    }
    if (e.target.value == 3) {
      continueBook({ variables: { user_idx: parseInt(userInfoVar().idx), type: 'bookr' } });
    }
  };
  return (
    <>
      <div className="main-inner-wrap library float__box">
        <div className="section__inner">
          <div className="library__content">
            <div className="library__box">
              <div className="booksRadio">
                <Radio.Group
                  className={
                    searchResult == 'true' ||
                    (selectionStatus?.like == false && selectionStatus?.continue == false && selectionStatus?.popular == false)
                      ? 'search-line'
                      : ''
                  }
                  name="bookList"
                  onChange={(e) => handleChange(e)}
                >
                  <Radio style={radioStyle} value={'1'}>
                    Popular
                  </Radio>
                  <Radio style={radioStyle} value={'2'}>
                    Like
                  </Radio>
                  <Radio style={radioStyle} value={'3'}>
                    Continue
                  </Radio>
                </Radio.Group>
              </div>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid lib-list-wrap"
                columnClassName="my-masonry-grid_column"
              >
                {bookSelf?.map((book, key) => {
                  return <BookData book={book} key={key} />;
                })}
              </Masonry>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Elibrary = ({ level, search, bookType, sort, topic, setSearchOption, searchValue, searchHistory, setSearchHistory }) => {
  return (
    <>
      <div className="library-con" style={{ margin: '5rem auto' }}>
        <LibraryContent
          level={level}
          search={search}
          bookType={bookType}
          sort={sort}
          topic={topic}
          setSearchOption={setSearchOption}
          searchValue={searchValue}
          searchHistory={searchHistory}
          setSearchHistory={setSearchHistory}
        />

        {/* <button type="button" className="swiper-button-prev" id="btn-prev"></button>
        <button type="button" className="swiper-button-next" id="btn-next"></button> */}
      </div>
    </>
  );
};

export default Elibrary;
