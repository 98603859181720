import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Typography, Select, InputNumber } from 'antd';
import { GET_TEST_AND_ELIBRARY_RESULT_FOR_REPORTCARD } from 'src/operations/queries/reportcard';
import moment from 'moment';

const { Title } = Typography;
const { Option } = Select;
const FinalTestTh = styled.th`
  border: 1px solid #a4d0e9;
  background-color: #fafafa;
`;
const FinalTestTd = styled.td`
  border: 1px solid #a4d0e9;
  text-align: center;
  padding: 5px 0;
`;

const FinalTable = styled.table`
  font-size: 0.8em;
  @media print {
    font-size: 0.5em;
  }
`;

const FinalTestInputTable = ({ class_idx, user_idx, dateRange, onChangeScore, result, bookTitle, editable = false }) => {
  const [assignedTestIdx, setAssignTestIdx] = useState(0);
  const [manualFinalTestScore, setManualFinalTestScore] = useState([0, 0]);
  const [start_date, end_date] = useMemo(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      return [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).add(7, 'days').format('YYYY-MM-DD')];
    }
    return [undefined, undefined];
  }, [dateRange]);

  const { data, loading } = useQuery(GET_TEST_AND_ELIBRARY_RESULT_FOR_REPORTCARD, {
    variables: { class_idx, user_idx, start_date, end_date },
    skip: !class_idx || !user_idx || !start_date || !end_date,
  });

  const dataJSON = useMemo(() => {
    if (result) {
      return JSON.parse(result);
    }
    return undefined;
  }, [result]);
  const finalTestOptions = useMemo(() => {
    //if (editable) {
    if (true) {
      if (data?.getTestReportList && data.getTestReportList[0]) {
        if (dataJSON?.assigned_test_idx) {
          setAssignTestIdx(Number(dataJSON?.assigned_test_idx));
        } else {
          setAssignTestIdx(data.getTestReportList[0].idx);
        }

        return data.getTestReportList.map((item) => (
          <Option value={item.idx} key={`final-test-item${item.idx}`}>
            {item.title}
          </Option>
        ));
      }
      return [<Option value={0}>{bookTitle} 수동입력</Option>];
    }
    return [];
  }, [data, bookTitle, dataJSON]);


  const [finalTestCount, finalTestScore] = useMemo(() => {
    if (data?.getTestReportList) {
      const findItem = data.getTestReportList.find((ele) => ele.idx === assignedTestIdx);
      if (findItem) {
        const totalQuestion = findItem.tests.total_questions || 0;
        const userScoreItem = findItem.assigned_test_users.find((ele) => ele.user_idx === user_idx);

        const userScore = userScoreItem ? userScoreItem.score : 0;
        const percentScore = totalQuestion > 0 ? Math.round((100 * userScore) / totalQuestion) : 0;
        return [`${userScore}/${totalQuestion}`, percentScore];
      }
    }

    return ['--', '--'];
  }, [data, assignedTestIdx, user_idx]);

  const [bookCount, score] = useMemo(() => {
    // const elibInfo = data?.studentElibraryStudyStatis;
    // if (elibInfo) {
    //   const { read_book_count, average_grade } = elibInfo;
    //   return [read_book_count, average_grade];
    // }
    return ['--', '--'];
  }, [data]);

  useEffect(() => {
    if (editable) {
      onChangeScore({
        final_test_count: finalTestCount,
        final_test_score: finalTestScore,
        elibrary_book_count: dataJSON?.elibrary_book_count || bookCount,
        elibrary_score: dataJSON?.elibrary_score || score,
        assigned_test_idx: assignedTestIdx,
      });
    }
    return () => {
      // setAssignTestIdx(0);
      // setManualFinalTestScore([0, 0]);
    };
  }, [finalTestCount, finalTestScore, bookCount, score, assignedTestIdx, dataJSON, editable]);

  useEffect(() => {
    onChangeScore({
      final_test_count: `${manualFinalTestScore[0]}/${manualFinalTestScore[1]}`,
      final_test_score: manualFinalTestScore[1] ? Math.round((100 * manualFinalTestScore[0]) / manualFinalTestScore[1]) : 0,
      elibrary_book_count: dataJSON?.elibrary_book_count || bookCount,
      elibrary_score: dataJSON?.elibrary_score || score,
      assigned_test_idx: assignedTestIdx,
    });
  }, [manualFinalTestScore, bookCount, score, assignedTestIdx, dataJSON]);

  const handleChangeFinalScore = (finalScore) => {
    const [, t] = manualFinalTestScore;
    if (isNaN(finalScore)) {
      setManualFinalTestScore([0, t]);
    } else {
      setManualFinalTestScore([finalScore, t]);
    }
  };
  const handleChangeFinalTotal = (total) => {
    const [s] = manualFinalTestScore;
    if (isNaN(total)) {
      setManualFinalTestScore([s, 0]);
    } else {
      setManualFinalTestScore([s, total]);
    }
  };

  useEffect(() => {
    //if (editable && dataJSON) {
    if (dataJSON) {
      if (dataJSON?.final_test_count && dataJSON?.final_test_count !== '--') {
        if (typeof dataJSON?.final_test_count === 'string') {
          const [c, t] = dataJSON?.final_test_count.split('/');
          const cnt = parseInt(c);
          const tot = parseInt(t);

          const testScore = [isNaN(cnt) ? 0 : cnt, isNaN(tot) ? 0 : tot];
          setManualFinalTestScore(testScore);
        }
      }
    } else {
      setManualFinalTestScore([0, 0]); //초기화
    }
  }, [dataJSON]);

  return (
    <>
      <Title level={4}>
        Final Test &nbsp; &nbsp;
        {editable && (
          <Select loading={loading} value={assignedTestIdx} style={{ minWidth: '200px' }} onChange={setAssignTestIdx}>
            {finalTestOptions}
          </Select>
        )}
      </Title>
      <FinalTable style={{ width: '100%', borderTop: '3px solid #a4d0e9' }}>
        <thead>
          <tr>
            <FinalTestTh>Final Test 맞은 개수</FinalTestTh>
            <FinalTestTh>Final Test Score</FinalTestTh>
            <FinalTestTh>전자도서관 읽은 책 권수</FinalTestTh>
            <FinalTestTh>전자도서관 Score</FinalTestTh>
          </tr>
        </thead>
        <tbody>
          {!editable ? (
            <tr>
              <FinalTestTd>{dataJSON?.assigned_test_idx ? finalTestCount : dataJSON?.final_test_count || '--'}</FinalTestTd>
              <FinalTestTd>{dataJSON?.assigned_test_idx ? finalTestScore : dataJSON?.final_test_score || '--'}</FinalTestTd>
              <FinalTestTd>{dataJSON?.elibrary_book_count || '--'}</FinalTestTd>
              <FinalTestTd>{dataJSON?.elibrary_score || '--'}</FinalTestTd>
            </tr>
          ) : (
            <tr>
              {assignedTestIdx === 0 ? (
                <>
                  <FinalTestTd>
                    <InputNumber size="small" min={0} max={100} onChange={handleChangeFinalScore} value={manualFinalTestScore[0]} /> /&nbsp;
                    <InputNumber size="small" min={0} max={100} onChange={handleChangeFinalTotal} value={manualFinalTestScore[1]} />
                  </FinalTestTd>
                  <FinalTestTd>
                    <InputNumber
                      size="small"
                      disabled
                      value={manualFinalTestScore[1] && Math.round((100 * manualFinalTestScore[0]) / manualFinalTestScore[1])}
                    />
                  </FinalTestTd>
                </>
              ) : (
                <>
                  <FinalTestTd>
                    {dataJSON?.assigned_test_idx || finalTestCount != '--' ? finalTestCount : dataJSON?.final_test_count || 0}
                  </FinalTestTd>
                  <FinalTestTd>
                    {dataJSON?.assigned_test_idx || finalTestScore != '--' ? finalTestScore : dataJSON?.final_test_score || 0}
                  </FinalTestTd>
                </>
              )}

              <FinalTestTd>{dataJSON?.elibrary_book_count ? dataJSON?.elibrary_book_count : bookCount}</FinalTestTd>
              <FinalTestTd>{dataJSON?.elibrary_score ? dataJSON?.elibrary_score : score}</FinalTestTd>
            </tr>
          )}
        </tbody>
      </FinalTable>
    </>
  );
};

export default FinalTestInputTable;
