import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Checkbox, Row, Button, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { NEWS_O_MATIC_SEARCH } from 'src/operations/queries/externalStudy';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import moment from 'moment';
import NewsOMacticMenuGroup from 'src/components/common/NewsOMacticMenuGroup';
const { Title } = Typography;
const { Search } = Input;

// const onHandleRowClick = (row, index, user) => {
// if (window.Android && row.isMeeting) {
//   if (user.type == 1) {
//     window.Android.joinZoomClass(JSON.stringify(user));
//   }else{
//     window.Android.startZoomClass(JSON.stringify(user));
//   }
// }
// };

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const ElibraryAssignBooks = (props) => {
  // const query = new URLSearchParams(props.location.search);
  // const searchPage = query.get('page');
  // const isRefresh = query.get('refresh');

  const [books, setBooks] = useState([]);
  // const [selectType, setSelectType] = useState(1);
  // const [q, setQ] = useState(null);
  const [page, setPage] = useState(1);
  // const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  // const [selectedBooks, setSelectedBooks] = useState([]);
  const [checkedList, setCheckedList] = useState(new Array(books.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      level: 1,
      text: null,
      page: 0,
    },
    'we_search',
  );

  const isAnyChecked = checkedList.filter((s) => s).length > 0;

  const { data, refetch, loading } = useQuery(NEWS_O_MATIC_SEARCH, {
    fetchPolicy: 'no-cache',
    variables: { keyword: searchValue.text, page: page > 1 ? page : null, take: 10 },
  });
  useEffect(() => {
    if (data?.searchNewsOMaticArticles && data?.searchNewsOMaticArticles?.results) {
      const newBooks = [
        ...books,
        ...data?.searchNewsOMaticArticles?.results.map((item) => ({
          ...item,
          key: item.pk,
          idx: item?.pk,
          title: item?.headline,
          issue_Date: item?.publish_date,
          type: 'newsomatic',
          type_text: 'News-O-Matic',
        })),
      ];
      setBooks(newBooks);
      setTotal(newBooks.length);
      setCheckedList(new Array(newBooks.length).fill(false));
    }
  }, [data]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    console.log('checkedList', checkedList);
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(books.length).fill(true));
    } else {
      setCheckedList([]);
    }
    console.log(checkedList);
  }
  const handleAssignData = () => {
    let data = [];
    books.forEach((item, index) => {
      checkedList.forEach((checked, index2) => {
        if (index === index2 && checked) {
          data.push(item);
        }
      });
    });
    localStorage.setItem('selectedBooks', JSON.stringify(data));
  };
  const columns = [
    {
      title: () => {
        return <Checkbox checked={checkedList.filter((s) => s).length === books.length} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (_, __, idx) => {
        return (
          <Space size="middle">
            <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
    {
      title: 'Code',
      dataIndex: 'pk',
      key: 'No',
      align: 'center',
      width: 150,
    },
    {
      title: 'Author',
      dataIndex: 'author',
      align: 'center',
      key: 'author',
      width: 100,
      render: (_, record) => {
        return <Space size="middle">{record.author?.name}</Space>;
      },
    },
    {
      title: 'Issue Date',
      dataIndex: 'publish_date',
      key: 'publish_date',
      align: 'center',
      width: 150,
      render: (_, record) => {
        return <Space size="middle">{moment(record.publish_date.split('T')[0]).format('YYYY-MM-DD')}</Space>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'headline',
      key: 'headline',
      align: 'center',
    },
  ];

  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="elibrary-main-block">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>News-O-Matic</Title>
            </Col>
          </Row>
          <Row gutter={[24, 8]}>
            <Col>
              <NewsOMacticMenuGroup currentMenu={'assign'} />
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Search
                className="headitems-search"
                placeholder="Input search text"
                //onSearch={(val) => setQ(val)}
                defaultValue={searchValue.text}
                onSearch={(val) => {
                  setBooks([]);
                  setPage(1);
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      text: val,
                    };
                  });
                }}
                allowClear
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
              />
              <Link to="/external-study/manage/action/assignExternalStudyUser" className="headitems-add">
                <Button style={{ color: '#289428', marginLeft: 5 }} onClick={handleAssignData}>
                  Assign
                </Button>
              </Link>
              <Link to="/external-study/manage/action/assign" className="headitems-add">
                <Button disabled={!isAnyChecked} style={{ color: '#289428', marginLeft: 5 }} onClick={handleAssignData}>
                  Assign Article
                </Button>
              </Link>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            dataSource={books}
            columns={columns}
            loading={loading}
            bordered
            key={books.idx}
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={false}
            size="small"
            color="#edf3fb"
            //onChange={(a) => setPage(a.current - 1)}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => onHandleRowClick(record, rowIndex),
            //   };
            // }}
            // onChange={handleTableChange}
            footer={() => (
              <Row gutter={[24, 8]}>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button
                    style={{ color: '#289428', marginLeft: 5 }}
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    Load More..
                  </Button>
                </Col>
              </Row>
            )}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(ElibraryAssignBooks);
