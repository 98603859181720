import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Space } from 'antd';
import DailyTestQuiz from "./dailyTestQuiz";
import { useParams, useHistory } from 'react-router-dom';
import { QUIZ_STUDY_DATA } from 'src/operations/queries/getEvineLesson';
import { userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import { openNotification } from '../common/Notification';



const DailyTestGroupQuiz = ({id,groupName}) => {
    const [quizStatus, setQuizStatus] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [isFinished, setIsFinished] = useState(false);
    const [grade, setGrade] = useState({ correct: 0, total: 0 });
    const [finalGrad, setFinalGrade] = useState('');
    const [userAns, setUserAns] = useState();

    const [getQuizData, { data, loading }] = useLazyQuery(QUIZ_STUDY_DATA, { fetchPolicy: 'no-cache' });
  const [updateQuizScore, { loading: loadingAdd }] = useMutation(mutations.updateTestGroup.UPDATE_QUIZ_SCORE, {
    onCompleted(data) {
      if (data?.updateQuizScore) {
        // refetch();
        // openNotification('학생 수정 완료!');
        // window.location.href = "http://localhost:3030/"
        // setTimeout(()=>{
          getQuizData({
            variables: {
              daily_test_group_assigned_idx: parseInt(id),
                // user_idx:1000007
                // user_idx:1000024
                user_idx:parseInt(userInfoVar()?.idx)
            },
          });
        // },1000)

      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const quizData = data? JSON.parse(data?.getQuizData) : [];
    const quizItem = quizData ? quizData?.data?.[currentIndex] : {};
    const total = (quizData && quizData.data?.length) || 0;
    const idx = quizData? quizData[currentIndex]?.daily_tests_idx : 0;
    const daily_test_group_idx = quizData? quizData[currentIndex]?.daily_test_group_idx : 0;
    const assigned_user_idx = quizData?.assigned_test_user_details?.idx
    const handleEachQuizFinish=(isRight, userAnswer)=>{
        if (isRight) {
            setUserAnswers((prev) => [
              ...prev,
              {
                question_no: currentIndex + 1,
                is_correct: "1",
                user_answer: userAnswer.toString(),
              },
            ]);
            setGrade({ ...grade, correct: grade.correct + 1,total:quizData?.data?.length });
          } else {
            setUserAnswers((prev) => [
              ...prev,
              {
                question_no: currentIndex + 1,
                is_correct: "0",
                user_answer: userAnswer.toString(),
              },
            ]);
            setGrade({ ...grade,total:quizData?.data?.length });
          }
          setCurrentIndex(currentIndex + 1);
          if (total > 0 && currentIndex === total - 1) {
            setFinalGrade(grade.correct * 100 / quizData?.data?.length)
            //setShowPopup(true);
            setQuizStatus(false)
            //state에 점수를 저장했기 때문에 여기에서 저장하면 마지막 값이 저장이 안 된다.
            setIsFinished(true);
            return;
          }
    }
    useEffect(()=>{
      getQuizData({
        variables: {
          daily_test_group_assigned_idx: parseInt(id),
            // user_idx:1000007
            // user_idx:1000024
            user_idx:parseInt(userInfoVar()?.idx)
        },
      });
    },[id])
    // useEffect(()=>{
    //   if(data?.getQuizData)
    //   {
    //     const res = data? JSON.parse(data?.getQuizData) : [];
    //     if(res?.data?.length){ setGrade({correct: 0,total:quizData?.data?.length }) }
    //   }
    // },[data])
    const handleQuiz=()=>{
        setQuizStatus(true)
        // getQuizData({
        //     variables: {
        //       daily_test_group_assigned_idx: parseInt(id),
        //         // user_idx:parseInt(userInfoVar()?.idx)
        //         user_idx:1000024
        //     //   study_tool_idx: filteredLessonInfo.stage[newIndx - 1].study_tool.idx,
        //     },
        //   });
    }
    
    useEffect(()=>{
      if(currentIndex === (quizData?.data?.length) && userAns?.length > 0){
        updateQuizScore({
          variables:{
              // user_idx:1000007,
              // user_idx:1000024,
              user_idx:parseInt(userInfoVar()?.idx),
              idx:assigned_user_idx,
              score :grade.correct * 100 / quizData?.data?.length,
              user_answer: JSON.stringify(userAns)
          }
      })
      }
    },[userAns])
    const handleNext=()=>{
      setQuizStatus(true)
      setCurrentIndex(0)
      const res = data? JSON.parse(data?.getQuizData) : [];
      if(res?.data?.length){ setGrade({correct: 0,total:quizData?.data?.length }) }
    }
    const handleExit = ()=>{
      window.location.href = "http://localhost:3030/"

        // updateQuizScore({
        //     variables:{
        //         user_idx:1000024,
        //         // user_idx:parseInt(userInfoVar()?.idx),
        //         idx:assigned_user_idx,
        //         score :grade.correct * 100 / quizData?.data?.length 
        //     }
        // })
    }
    return (
        <>
            {quizStatus ?
                
                    // quizData?.map((ele)=>{
                        // return(
                            quizItem?.msg ? <>{quizItem?.msg}</>:
                            <DailyTestQuiz 
                            quizItem={quizItem}
                            currentNumber={currentIndex + 1}
                            onFinish={handleEachQuizFinish}
                            total={total}
                            getUserAns={(val)=>setUserAns(val)}
                            />
                        // )
                    // })
                
                : <>
                    <div className="daily-test-wrap">
                        <div className="daily-sub-wrap">
                            <div className="header-top-wrap">
                                <div className="header-top-item">
                                 <p>{groupName}</p>
                                </div>
                                <div className="header-top-item">
                                    <p className="test-no"> Test 06</p>
                                </div>
                                <div className="header-top-item">
                                    <p className="date-info">2023-07-22</p>
                                </div>
                                <div className="header-top-item">
                                    <p className="time-info">12:30 PM</p>
                                </div>
                            </div>
                            <div className="daily-group-content">
                                {/* <h1>
                                {isFinished? <div>You are finished !</div>:<div>{groupName}</div> }
                                {isFinished? <div>You Score is</div>:<div></div>}
                                {isFinished? <div>{grade.correct * 100 / quizData?.data?.length} %</div>:<div> </div>}
                                </h1> */}
                                {/* <Space wrap>
                                {isFinished? <Button className="start-btn"  onClick={handleExit} type="primary" >Exit</Button> :<Button className="start-btn" type="primary" onClick={handleQuiz}>Start</Button>}
                                </Space> */}

                                {quizData?.data?.length && isFinished?
                                <>
                                <h1>You didn't Pass the cut_line, you can start with new test</h1>
                                <h1>Your score {grade.correct * 100 / grade.total} %</h1>
                                <Button className="start-btn"  onClick={handleNext} type="primary" >Next Quiz</Button>
                                </>:
                                <>
                                <h1>
                                {isFinished? <div>You are finished !</div>:<div>{groupName}</div> }
                                {isFinished? <div>You Score is</div>:<div></div>}
                                {isFinished? <div>{grade.correct * 100 / grade?.total} %</div>:<div> </div>}
                                </h1>
                                <Space wrap>
                                {isFinished? <Button className="start-btn"  onClick={handleExit} type="primary" >Exit</Button> :<Button className="start-btn" type="primary" onClick={handleQuiz}>Start</Button>}
                                </Space>
                                </>
                                }
                            </div>

                        </div>
                    </div>
                </>}
        </>
        )
}


export default DailyTestGroupQuiz;