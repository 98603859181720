import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Popover, Input } from 'antd';
import DeliveryCompanyListSelectBox, { useDeliveryCompany } from 'src/components/common/DeliveryCompanyListSelectBox';

const InfoTable = styled.table`
  margin-top: 0;
  margin-bottom: 15px;
`;

const InvoiceInputPopover = ({
  children,
  loadingUpdateInvoiceNumber,
  invoice_number,
  order_payment_idx,
  company_code,
  onUpdateInvoice,
}) => {
  const [visible, setVisible] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(invoice_number);
  const [selectedCode, , onCompanyChange] = useDeliveryCompany(company_code);

  useEffect(() => {
    //저장한 후에 저장된 값을 다시 세팅해줌
    onCompanyChange(company_code);
    setInvoiceNumber(invoice_number);
  }, [company_code, invoice_number]);

  useEffect(() => {
    if (!visible) {
      //팝업이 닫혔을 때 초기화 해줌.
      onCompanyChange(company_code);
      setInvoiceNumber(invoice_number);
    }
  }, [visible]);

  function handleUpdateInvoice() {
    setVisible(false);
    onUpdateInvoice(order_payment_idx, selectedCode, invoiceNumber);
  }
  return (
    <Popover
      title="송장번호입력/수정"
      placement="left"
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <InvoiceInputPopoverContent
          order_payment_idx={order_payment_idx}
          invoiceNumber={invoiceNumber}
          setInvoiceNumber={setInvoiceNumber}
          selectedCode={selectedCode}
          onCompanyChange={onCompanyChange}
          onUpdateInvoice={handleUpdateInvoice}
          loadingUpdateInvoiceNumber={loadingUpdateInvoiceNumber}
        />
      }
    >
      {children}
    </Popover>
  );
};

export default InvoiceInputPopover;

const InvoiceInputPopoverContent = ({
  invoiceNumber,
  setInvoiceNumber,
  selectedCode,
  onCompanyChange,
  onUpdateInvoice,
  loadingUpdateInvoiceNumber,
}) => {
  return (
    <>
      <InfoTable className="table-t-three">
        <thead>
          <tr>
            <th>택배사</th>
            <th>송장번호</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <DeliveryCompanyListSelectBox code={selectedCode} onChange={onCompanyChange} />
            </td>
            <td>
              <Input
                placeholder="숫자만 입력하세요."
                value={invoiceNumber}
                onChange={(e) => {
                  setInvoiceNumber(e.target.value?.replace(/[^0-9]/g, ''));
                }}
                maxLength={20}
              />
            </td>
          </tr>
        </tbody>
      </InfoTable>
      <div style={{ textAlign: 'center' }}>
        <Button type="primary" onClick={onUpdateInvoice} loading={loadingUpdateInvoiceNumber}>
          입력/수정
        </Button>
      </div>
    </>
  );
};
