import React, { useState } from 'react';
import { Layout, Input, Select, Space, Button, Option } from 'antd';
import { PlusOutlined, SoundOutlined, CloseOutlined, ArrowDownOutlined } from '@ant-design/icons';

const ModalForCmaker = ({ isOpen, setIsOpen }) => {
  function closee() {
    setIsOpen(!setIsOpen);
  }
  return (
    <>
      <div className="cmaker-modal-con">
        <span onClick={closee} style={{ marginLeft: 'auto', marginTop: '1rem' }}>
          <CloseOutlined />
        </span>
        <h1>ADD PART</h1>

        <div className="body">
          <legend>Part Name</legend>
          <Input placeholder="type the name" />

          <legend>Part DNO</legend>
          <Input placeholder="type the DNO" />

          {/* change part */}
          <legend>Part Header</legend>
          <Input placeholder="type the Header" />
          {/* change part */}
        </div>

        <div className="footer">
          <Button type="primary" value="OK">
            OK
          </Button>
          <Button value="CANCEL" onClick={closee}>
            CANCEL
          </Button>
        </div>
      </div>
    </>
  );
};

export default ModalForCmaker;
