import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

const HistoryBtn = styled(Button)`
  background: #eff5fb;
  color: #369;
  border: 1px solid #bec8cd;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #eff5fb;
    background: #369;
    transition: all 0.15s ease-in-out;
  }
`;

const SmsCnkSentMsg = ({ text }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const modalSentMsg_show = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} title="문구보기" footer={null}>
        <p>
          {text.split('\n')?.map((item) => (
            <>
              {item}
              <br />
            </>
          ))}
        </p>
      </Modal>
      <HistoryBtn onClick={modalSentMsg_show}> 문구 보기 </HistoryBtn>
    </>
  );
};

export default SmsCnkSentMsg;
