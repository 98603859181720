import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Body = styled(ColumnFlex)`
  width: 100%;
  padding: 0 30px;
  height: 100%;
  /* align-items: center; */
  /* justify-content: space-between; */
`;
const BoxTitle = styled(RowFlex)`
  font-size: 24px;
  font-weight: 600;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 25px 25px 0 0;
  color: #000000;
  text-align: center;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
  justify-content: center;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const TextDiv = styled(RowFlex)`
  padding: 0 25px;
  font-size: 22px;
  font-weight: 500;
  @media (max-width: 1280px) {
    font-size: 18px;
    padding: 0 20px;
  }
`;
const LeftBody = styled.div`
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
`;

const TextBox = styled.div`
  flex: 1;
  border: 2px solid ${(props) => props.borderColor};
  height: 70%;
  padding: 10px 20px;
  font-size: 15px;
  /* font-weight: bold; */
  color: #000000;
  background-color: white;
  border-radius: 0 0 25px 25px;
  overflow: auto;
`;

const LoadingScreen = styled.div`
  height: 100%;
  background: #ffffff88;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightBody = styled(ColumnFlex)`
  width: 100%;
  padding: 20px;
  height: 85%;
  justify-content: space-between;
`;
const NumberBox = styled(ColumnFlex)`
  width: 70px;
  height: 30px;
  border: 2px solid #b5b5b7;
  background-color: #fff;
  border-radius: 5px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 5px;
  color: ${(props) => props.color};
  font-weight: bold;
  @media (max-width: 1280px) {
    width: 65px;
    height: 25px;
    margin: 0 5px;
    font-size: 17px;
  }
`;
const Button = styled.div`
  cursor: pointer;
  border-radius: 20px;
  font-size: 1.5em;
  font-weight: bold;
  width: 130px;
  text-align: center;
  color: #fff;
  background-color: #ff005c;
  box-shadow: 0 2px 1px 2px #bf004e;
  height: 40px;
  padding: 8px 45px;
  @media (max-width: 1280px) {
    height: 32px;
    font-size: 1.2em;
    padding: 6px 25px;
  }
`;
export default function EngStaWPMResult({}) {
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <Title>
              <TitleSpan color={'#453ec8'}>BOOK Title</TitleSpan>
              <TitleSpan>Volume</TitleSpan>
              <TitleSpan>Unit</TitleSpan>
              <TitleSpan>Lesson</TitleSpan>
              <TitleSpan>Lesson Name</TitleSpan>
            </Title>
            <Body>
              <RowFlex style={{ height: '95%' }}>
                <LeftBody>
                  <RowFlex style={{ justifyContent: 'center', paddingBottom: '1px' }}>
                    <BoxTitle backgroundColor={'#49ada1'} style={{ marginTop: '6px' }}>
                      Original
                    </BoxTitle>
                  </RowFlex>
                  <TextBox borderColor={'#49ada1'}></TextBox>
                  <RowFlex style={{ justifyContent: 'center', paddingTop: '10px' }}>
                    <TextDiv>
                      Extra text is &nbsp;<span style={{ color: '#0044c5' }}> blue</span>.
                    </TextDiv>
                    <TextDiv>
                      Missed text is &nbsp;<span style={{ color: '#ff005c' }}> red</span>.
                    </TextDiv>
                  </RowFlex>
                </LeftBody>
                <ColumnFlex style={{ width: '50%',alignItems:'center' }}>
                  <RightBody>
                    <RowFlex style={{ justifyContent: 'center', paddingBottom: '1px' }}>
                      <BoxTitle backgroundColor={'#f69283'} style={{ marginTop: '6px' }}>
                        Yours
                      </BoxTitle>
                    </RowFlex>
                    <TextBox borderColor={'#f69283'}></TextBox>
                    <RowFlex style={{ justifyContent: 'center', paddingTop: '10px',width:'100%' }}>
                      <TextDiv>
                        Accuracy <NumberBox color={'#453ec8'}>{'100'}%</NumberBox>
                      </TextDiv>
                      <TextDiv>
                        WPM <NumberBox color={'#fe015c'}>{'102'}</NumberBox>
                      </TextDiv>
                    </RowFlex>
                  </RightBody>
                    <Button onClick={{}}>Back</Button>
                </ColumnFlex>
              </RowFlex>
            </Body>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
