import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Modal, Select, Spin, message, Tabs, Button as AButton, Tooltip, Form, Button, Divider, Layout, Progress } from 'antd';
import { userInfoVar } from 'src/apollo/cache';
import { useLazyQuery, useMutation } from '@apollo/client';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import { TRANSLATE_TEXT } from 'src/operations/mutations/aiGroupTopic';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { captureUserMedia } from 'src/utils';
import ReactPlayer from 'react-player';
import * as axios from 'axios';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import MessageItem from 'src/components/DashBoard/SubPage/MessageItem';
import MessageBox from 'src/components/DashBoard/SubPage/MessageBox';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import SoundEffect from 'src/components/common/SoundEffect';
const clickSound = '/audio/LevelUp/button_click.mp3';

const { TextArea } = Input;
const { Sider } = Layout;
const { Option } = Select;

const PROMPTS = [
  `I need to answer in a complete sentence.
  If I don't answer the question in complete sentence, ask me to say it in a full sentence.
  And show me how to say it in a complete sentence!
  Answers can be said many different ways, So if my answer's meaning is correct then counted as correct.
  Therefore, you don't need to say it is change them.
  Tell me if I got it right or wrong and explain why I got it wrong, and
  don't ask me the same question again and 
  If my answer is correct, tell me I got it right.
  If the answer had spelling errors, just ignore and counted as right.
  Don't ask me the same question if I get it wrong, just go to the next question.
  
  After all the questions are done, ask me to press save button to end.
  
  Here is the story that we are going to ask questions about:
  "Title: three little pig brothers
  
  Once upon a time, there were three little pig brothers: Piggy 1, Piggy 2, and Piggy 3.
  They decided to build houses to keep themselves safe from the big bad wolf.
  Piggy 1 was in a hurry and made a house of straw. It was quick but not very strong.
  Piggy 2 also wanted to finish fast, so he built his house with sticks. It was a bit stronger than straw, but still not very tough.
  Now, Piggy 3 was wise. He took his time and built a sturdy house with strong bricks. It took more effort, but he knew it would be the safest.
  One day, the big bad wolf came by. He huffed and puffed and blew down the straw house of Piggy 1! Oh no! The little pig ran to Piggy 2's house.
  The wolf huffed and puffed again, and this time, the house made of sticks also fell. Now, both Piggy 1 and Piggy 2 were scared. They all rushed to Piggy 3's brick house.
  The wolf tried with all his might to blow down the brick house, but it was too strong. The three little pigs were safe inside. The wolf couldn't get them!
  In the end, the wolf gave up and went away. The three little pigs were happy and learned an important lesson – taking time to do things right and making strong choices pays off.
  And they all lived happily ever after in their sturdy brick house. The end!"
  
  Here are the questions and only ask one at a time.
  
  1. What did Piggy 1 build his house with?
  2. What did Piggy 2 build his house with?
  3. What did Piggy 3 build his house with?
  4. Who tried to blow down the houses?
  5. Why can the big bad wolf blow down the Piggy 3’s house?
  6. What can we learn from the story?`,
];
// const TO_LESSON = `Go back to main topic of our conversation.`;
// const PAUSE = `Let's stop the conversation. I need to take a break and we will continue later.`;
// const STOP = `Stop the conversation and stop the mic and End of session`;
// const REPEAT = `Can you ask me the question that you had ask me?and only the question not the feedback.`;
const TO_LESSON = `Repeat last thing you said again.`;
const PAUSE = `Let's stop the conversation. I need to take a break and we will continue later.`;
const STOP = `Stop the conversation.`;
const REPEAT = `Can we go back to beginning of the conversation?, and ask me if I am ready to start.
If I say 'yes' then ask me the first question about our main topic.
If I say 'no' then ask me a few questions about the story one at a time that you have not ask me before.`;
const EASY = `Can you say it again in easier words for 7 years old kid who does not speak English well? And you can also elaborate what you are saying if you could.`;
const TRANSLATE = `Can you say what you just said in Korean and afterwards go back to English mode?`;
const SHORT = `Can say what you said in short, concise, easy to understand way?`;
const ASK = `Translate this into English and go back and ask the last question again.`;
const LESSON = `please go back to original topic which was asked in the beginning of the conversation.
Ask if the student has any questions about the original topic.
If answer is yes then answer the question,  if no then ask the student if you want AI to ask a question about the topic.`;
const REVIEWSAVE = `Can you summarize our conversation? and 
tell what was the main point about our conversation. give
your opinion about how well our conversation in English was.

Give me a score of 1 through 10 of our English conversation in terms of English communication skill.

Check our conversation dialogue to see if I made any grammatical errors or wrong expression in English or wrong answers; and correct it, 
give me explanation for me one by one in a format of
+ Original :  ,
- Corrections : ,
* Explanation :
---------------
At the end give me an over all comment to encourage me learning English.
After that translate in Korean in same format.`;
//const REVIEWSAVE = `Give me a summary of  our conversation`;
const Demo = () => {
  const history = useHistory();
  const [askForm] = Form.useForm();

  // const [currentMainPrompt, setCurrentMainPrompt] = useState(PROMPTS[0]);
  // const [currentEasyPrompt, setCurrentEasyPrompt] = useState(EASY);
  // const [currentTranslatePrompt, setCurrentTranslatePrompt] = useState(TRANSLATE);
  // const [currentShortPrompt, setCurrentShortPrompt] = useState(SHORT);
  // const [currentAskPrompt, setCurrentAskPrompt] = useState(ASK);
  // const [currentLessonPrompt, setCurrentLessonPrompt] = useState(LESSON);

  const [sound, setSound] = useState(clickSound);
  const [editType, setEditType] = useState('main');
  const [messagesIds, setMessagesIds] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const [activeAnswer, setActiveAnswer] = useState(false);
  const [active, setActive] = useState(false);
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const [answer, setAnswer] = useState('');
  const [micBtnClick, setMicBtnClick] = useState(0);
  const [lastBlob, setLastBlob] = useState(null);
  const recordAudio = useRef(null);
  const recorderStreams = useRef(null);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');
  const [speed, setSpeed] = useState(1.0);
  const [voice, setVoice] = useState('shimmer');
  const [soundPlay, setSoundPlay] = useState(false);
  const [micValue, setMicValue] = useState('mice_1.png');
  const [translateMic, setTranslateMic] = useState('transalte_mic.png');
  const [micBtnChange, setMicBtnChange] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [changeIcon, setChangeIcon] = useState(false);
  const [disableBtnVal, setDisableBtnVal] = useState(false);
  const [showBtn, setShowBtn] = useState({ guideBtn: false, askBtn: false, closeBtn: false });
  const [reviewSave, setReviewSave] = useState(false);
  const [playBtn, setPlayBtn] = useState('play-btn.svg');
  const [percent, setPercent] = useState(0);
  const messageBoxRef = useRef(null);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const [loadMoreMessages, { data, loading, error }] = useLazyQuery(OPENAI_COMPLETION_QUERY);
  const [translateText, { data: translateData }] = useMutation(TRANSLATE_TEXT);

  const onDataAvailableCallback = useCallback(async (blob) => {
    setCurrentRecLenght((prev) => prev + 100);
  }, []);

  const processTTS = useCallback(
    async (text) => {
      const res = await axios('https://a2vdkjqiwop2mrkfprrzmp7yqq0xdicc.lambda-url.ap-northeast-2.on.aws/', {
        method: 'POST',
        data: {
          text,
          action: 'tts',
          speed: speed,
          voice: voice,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return res.data;
    },
    [speed, voice],
  );

  const processRecordCallback = useCallback(async (blob, val) => {
    let headers;
    if (val === 'ask') {
      headers = { 'x-spoken-language': 'ko' };
    } else {
      headers = { 'Content-Type': 'audio/webm' };
    }
    try {
      if (blob !== undefined) {
        const res = await axios('https://a2vdkjqiwop2mrkfprrzmp7yqq0xdicc.lambda-url.ap-northeast-2.on.aws/', {
          method: 'POST',
          data: blob,
          headers,
        });
        if (val === 'ask') {
          askForm.setFieldValue('korean_text', res.data.text);
          askForm.submit();
        } else {
          if (val === STOP) {
            setDisableBtnVal(true);
          } else {
            setDisableBtnVal(false);
          }
          // || val === TO_LESSON || val === REPEAT
          if (
            val === PAUSE ||
            val === STOP ||
            val === TO_LESSON ||
            val === REPEAT ||
            val === EASY ||
            val === TRANSLATE ||
            val === SHORT ||
            val === ASK ||
            val === LESSON ||
            val === REVIEWSAVE ||
            val === 'ask-stop-recording'
          ) {
            // setStopMic(true)
          } else {
            setCurrentText(res.data.text);
          }
        }
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }, []);

  const startRecord = () => {
    captureUserMedia({ audio: { echoCancellation: true } }, async (stream) => {
      if (!recordAudio.current) recordAudio.current = [];
      recorderStreams.current = stream;
      recordAudio.current = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 48000,
        desiredSampRate: 16000,

        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,

        //1)
        // get intervals based blobs
        // value in milliseconds
        // as you might not want to make detect calls every seconds
        timeSlice: 100,

        // 2)
        // as soon as the stream is available
        ondataavailable: onDataAvailableCallback,
      });
      setLastBlob(null);
      recordAudio.current.startRecording();
    });
  };

  const stopRecording = (val) => {
    console.log('recordAudio->>>current', recordAudio?.current);
    if (recordAudio.current != null) {
      // message.info('Recording Stopped');
      recordAudio.current.stopRecording(async () => {
        let reader = new FileReader();

        reader.onload = async function (e) {
          processRecordCallback(recordAudio?.current?.getBlob(), val);
          setLastBlob(recordAudio.current.toURL());
          recordAudio.current.destroy();
          recordAudio.current = null;
          if (recorderStreams.current) {
            recorderStreams.current.getTracks().forEach((track) => track.stop());
            recorderStreams.current = null;
          }
        };

        reader.readAsArrayBuffer(recordAudio?.current?.getBlob());
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prevPercent) => {
        // Increase percent by 5 every second until it reaches 100
        if (prevPercent < 100) {
          return prevPercent + 5;
        } else {
          clearInterval(interval); // Stop the interval when percent reaches 100
          return prevPercent;
        }
      });
    }, 1000); // Update percent every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [percent]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTotalSeconds((prevTotalSeconds) => prevTotalSeconds + 1);
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  const handleRecording = (val) => {
    setMicValue('mice_3.png');
    handlePromptClick(val);
    setCurrentText('');
    console.log('recordAudio---->>>>', recordAudio);
    stopRecording(val);

    if (val == PAUSE || val == STOP) {
      setActive(false);
      // stopRecording(val);
      //setMicValue("mice_1.png")
      setMicBtnChange(true);
    }

    setSoundPlay(true);
    setSound(clickSound);
  };

  useEffect(() => {
    if (data?.getOpenAiCompletion?.text?.length > 0) {
      const aiResponse = data.getOpenAiCompletion?.chat?.prompt_text;
      // if (TO_LESSON == aiResponse || PAUSE == aiResponse || STOP == aiResponse || REPEAT == aiResponse
      //   || currentEasyPrompt == aiResponse || currentTranslatePrompt == aiResponse || currentShortPrompt == aiResponse || currentAskPrompt == aiResponse) {
      //   //setMicBtnChange(true)
      //   // } else {
      // }
      setChatList([
        ...chatList,
        {
          user: 'bot',
          chat_user_name: 'AI봇',
          prompt: currentText,
          content: data.getOpenAiCompletion?.text,
          urls: data.getOpenAiCompletion?.urls,
          created: data?.getOpenAiCompletion?.date,
        },
      ]);
      if (!reviewSave) {
        processTTS(data.getOpenAiCompletion?.text).then((res) => {
          setCurrentUrl(res.url);
          setMicValue('robot.png');
        });
      } else {
        setMicValue('mice_1.png');
      }
      if (percent === 100) {
        setReviewSave(false);
      }
      setMessagesIds([...messagesIds, data?.getOpenAiCompletion?.chat?.idx]);
    }
  }, [data]);

  const onSubmit = async () => {
    setChangeIcon(false);
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    setCurrentText('');

    await loadMoreMessages({
      variables: {
        prompt: currentText,
        top_p: 1,
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        messagesIds: messagesIds,
      },
    });
  };

  const handlePromptClick = async (val) => {
    // let prmpt;
    // if (val == "easy") {
    //   prmpt = EASY
    //   stopRecording(EASY);
    // } else if (val == "translate") {
    //   prmpt = TRANSLATE
    //   stopRecording(TRANSLATE);
    // } else if (val == "short") {
    //   prmpt = SHORT
    //   stopRecording(SHORT);
    // } else if (val == "ask") {
    //   prmpt = ASK
    //   stopRecording(ASK);
    // } else if (val == "lesson") {
    //   prmpt = LESSON
    //   stopRecording(LESSON);
    // } else if (val == "main") {
    //   prmpt = PROMPTS[0]
    // } else if (val == "review-save") {
    //   setActive(false)
    //   setReviewSave(true)
    //   prmpt = REVIEWSAVE
    //   stopRecording(REVIEWSAVE);
    // } else {
    //   prmpt = val
    // }
    let prmpt;
    let recording;

    switch (val) {
      case 'easy':
        prmpt = EASY;
        recording = EASY;
        break;
      case 'translate':
        prmpt = TRANSLATE;
        recording = TRANSLATE;
        break;
      case 'short':
        prmpt = SHORT;
        recording = SHORT;
        break;
      // case "ask":
      //   prmpt = ASK;
      //   recording = ASK;
      //   break;
      case 'lesson':
        prmpt = LESSON;
        recording = LESSON;
        break;
      case 'review-save':
        prmpt = REVIEWSAVE;
        recording = REVIEWSAVE;
        setActive(false);
        setReviewSave(true);
        setPercent(0);
        break;
      default:
        prmpt = val;
    }

    if (val == 'main') {
      prmpt = PROMPTS[0];
    }

    if (recording) {
      stopRecording(recording);
    }

    await loadMoreMessages({
      variables: {
        prompt: prmpt,
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        messagesIds: messagesIds,
      },
    });
  };

  const handlePlayEnd = () => {
    if (micBtnChange) {
      setMicValue('mice_1.png');
    } else {
      setMicValue('stop2x.png');
    }
    if (disableBtnVal) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    // setMicValue("stop2x.png")
    setCurrentUrl('');
    if (!micBtnChange) {
      startRecord();
    }
    // if (!stopMic) {
    //   startRecord();
    // }
    setPlayBtn('play-btn.svg');
  };

  const handleMicBtnClick = () => {
    setMicValue('mice_3.png');
    setSoundPlay(true);
    setSound(clickSound);
    setMicBtnClick(micBtnClick + 1);
    if (!active) {
      setActive(true);
      handlePromptClick('main');
      setDisableBtnVal(false);
      setMicBtnChange(false);
    } else if (recordAudio.current) {
      stopRecording();
    } else {
      startRecord();
    }
  };
  const handleClose = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      closeBtn: true,
    }));
  };

  // const handleModalOpen = (type) => {
  //   setEditType(type);
  //   if (type === 'main') {
  //     setModalText(currentMainPrompt);
  //   }

  //   if (type === 'easy') {
  //     setModalText(currentEasyPrompt);
  //   }

  //   if (type === 'translate') {
  //     setModalText(currentTranslatePrompt);
  //   }

  //   if (type === 'short') {
  //     setModalText(currentShortPrompt);
  //   }

  //   if (type === 'ask') {
  //     setModalText(currentAskPrompt);
  //   }

  //   setModalVisible(true);
  // };

  const handleModalChange = (e) => {
    setModalText(e.target.value);
  };

  // const handleModalOk = () => {
  //   setModalVisible(false);
  //   if (editType === 'main') {
  //     setCurrentMainPrompt(modalText);
  //   }

  //   if (editType === 'easy') {
  //     setCurrentEasyPrompt(modalText);
  //   }

  //   if (editType === 'translate') {
  //     setCurrentTranslatePrompt(modalText);
  //   }

  //   if (editType === 'short') {
  //     setCurrentShortPrompt(modalText);
  //   }

  //   if (editType === 'ask') {
  //     setCurrentAskPrompt(modalText);
  //   }
  // };

  const handleModalCancel = () => {
    setModalVisible(false);
    setModalText('');
  };
  const handleBtnClick = (val) => {
    setMicValue('mice_3.png');
    setCurrentText('');
    setSound(clickSound);
    setSoundPlay(true);
    handlePromptClick(val);
    setSoundPlay(true);
    if (val == 'review-save') {
      setMicBtnChange(true);
      setDisableBtnVal(true);
    } else {
      setMicBtnChange(false);
    }
  };

  const handleSoundEnd = () => {
    setSound(undefined);
    setSoundPlay(false);
  };
  const handleContentChange = (e) => {
    setCurrentText(e.currentTarget.textContent);
    setChangeIcon(true);
  };
  const handleMsgClear = () => {
    setCurrentText('');
  };
  const handleGuideBtn = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      guideBtn: true,
    }));
  };

  const handleCancel = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      askBtn: false,
    }));
    askForm.resetFields();
    setTranslateMic('transalte_mic.png');
    if (micValue == 'stop2x.png') {
      if (recordAudio.current) {
        stopRecording('ask');
      } else {
        startRecord();
      }
    }
  };
  const handleCloseCancel = () => {
    setShowBtn((prevState) => ({
      ...prevState,
      closeBtn: false,
    }));
  };

  const handleMainClose = () => {
    history.goBack();
  };
  const handleRestart = () => {
    window.location.reload();
  };
  const handleConvertText = async () => {
    setSoundPlay(true);
    setSound(clickSound);
    try {
      const res = await translateText({
        variables: {
          from_lang: 'ko',
          to_lang: 'en',
          text: askForm?.getFieldValue()?.korean_text,
        },
      });

      askForm.setFieldsValue({
        english_text: res?.data?.translateText?.dataObj?.TranslatedText,
      });
      askForm.submit();
      if (res?.data?.translateText?.dataObj?.TranslatedText) {
        processTTS(res?.data?.translateText?.dataObj?.TranslatedText).then((val) => {
          setCurrentUrl(val.url);
          setPlayBtn('pause-btn.svg');
          // setMicValue("robot.png")
        });
      }
    } catch (error) {
      console.error('Error while translating:', error);
    }
  };
  const handleAskClear = () => {
    setSoundPlay(true);
    setSound(clickSound);
    askForm.resetFields();
  };
  const handleTransalte = () => {
    setSoundPlay(true);
    setSound(clickSound);
    const newTranslateMic = translateMic === 'transalte_mic.png' ? 'stop.png' : 'transalte_mic.png';
    setTranslateMic(newTranslateMic);
    if (recordAudio.current) {
      stopRecording('ask');
    } else {
      startRecord();
    }
  };
  const handleAsk = () => {
    // stopRecording()
    setShowBtn((prevState) => ({
      ...prevState,
      askBtn: true,
    }));
    setSound(clickSound);
    setSoundPlay(true);
    if (recordAudio.current) {
      stopRecording('ask-stop-recording');
    }
    // recordAudio.current = null
  };
  const handlePlay = () => {
    setSound(clickSound);
    setSoundPlay(true);
    if (askForm?.getFieldValue()?.english_text) {
      processTTS(askForm?.getFieldValue()?.english_text).then((val) => {
        setCurrentUrl(val.url);
        setPlayBtn('pause-btn.svg');
      });
    }
  };
  return (
    <>
      <div className={displayDrawer ? 'sidebar-overlay' : ''}></div>
      <div className="add-header">
        <h2>Topic</h2>
        <div className="right-section">
          <Button type="primary">
            <img src="/images/talking-tutor/guide_o.png" alt="cguide" />
          </Button>
          <Button type="primary" onClick={handleClose}>
            <img src="/images/talking-tutor/close-icon.png" alt="close-arrow" />
          </Button>
        </div>
      </div>
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        layout="vertical"
        className="ant-form ant-form-vertical user-lession"
        //form={formControl}
      >
        <div className="middle-row">
          <div className="left-part">
            <div className="middle-input audio-input">
              <Form.Item name="title" label="Title" style={{ marginBottom: 5 }}>
                <Input name="title" />
              </Form.Item>
              <Form.Item name="topic" label="Topic" style={{ marginBottom: 5 }}>
                <TextArea name="topic" rows={2} />
              </Form.Item>
              {/* <Form.Item name="message" label="Message" onChange={(e) => { setCurrentText(e.target.value) }} style={{ marginBottom: 5 }} >                <TextArea name="message" rows={5} /> */}
              <div className="ant-col-22 msg-clear-btn" style={{ color: '#0082D8' }}>
                Clear Msg.&nbsp;&nbsp;
                <img src="/images/talking-tutor/redo.png" alt="close-arrow" onClick={handleMsgClear} style={{ cursor: 'pointer' }} />
              </div>
              <Form.Item name="message" label="Message" style={{ marginBottom: 5 }}>
                {/* <div
                                    name="message"
                                    data-manual-edit="false"
                                    className="ant-input"
                                    rows={7}
                                    contentEditable="true"
                                    onInput={handleContentChange}
                                    onBlur={handleContentChange}
                                    style={{ cursor: 'pointer', minHeight: '130px' }}
                                >
                                    {currentText}
                                </div> */}

                <div
                  name="message"
                  data-manual-edit="false"
                  className="ant-input msg-input"
                  rows={5}
                  contentEditable="true"
                  onBlur={(e) => {
                    setCurrentText(e.currentTarget.textContent);
                  }}
                  style={{ cursor: 'pointer', minHeight: '130px' }}
                >
                  {currentText}
                </div>
              </Form.Item>

              <div class="setting-btns setting-audio">
                <div className="setting-action-wrap">
                  <div class="guide-btn ant-col ant-col-4">
                    <img src="/images/talking-tutor/btn_guid-orange.png" onClick={handleGuideBtn} />
                    <p>Button Guide</p>
                  </div>
                  <div className="ant-col ant-col-18">
                    <div className="left-btn action-btn-wrap">
                      <div>
                        <img
                          src="/images/talking-tutor/y_1.png"
                          alt="y_1-icon"
                          className={!disableBtn ? 'disabled-btn' : 'enabled-btn'}
                          onClick={disableBtn ? () => handleRecording(TO_LESSON) : ''}
                        />
                        <p>Repeat</p>
                      </div>
                      <div>
                        <img
                          src="/images/talking-tutor/y_2.png"
                          alt="y_2-icon"
                          className={!disableBtn ? 'disabled-btn' : 'enabled-btn'}
                          onClick={disableBtn ? () => handleRecording(PAUSE) : ''}
                        />
                        <p>Pause</p>
                      </div>
                      <div>
                        {currentText !== '' || changeIcon ? (
                          <AButton
                            type="primary"
                            shape="circle"
                            icon={<SendOutlined />}
                            onClick={onSubmit}
                            style={{ height: 60, width: 60, transform: 'rotate(270deg)' }}
                            className="blink_me"
                          />
                        ) : (
                          <img
                            src={`/images/talking-tutor/${micValue}`}
                            className={recordAudio.current !== null && !micBtnChange && micValue == 'stop2x.png' ? 'blink_me' : ''}
                            alt="mice_1-icon"
                            // onClick={micValue == "stop2x.png" ? "" : handleMicBtnClick}
                            onClick={handleMicBtnClick}
                            style={{ cursor: 'pointer', width: '60px', height: '64px', marginTop: '-4px' }}
                          />
                        )}
                        <p style={{ marginTop: '4px' }}>Start</p>
                      </div>
                      <div>
                        <img
                          src="/images/talking-tutor/y_3.png"
                          alt="y_3-icon"
                          className={!disableBtn ? 'disabled-btn' : 'enabled-btn'}
                          onClick={disableBtn ? () => handleRecording(STOP) : ''}
                        />
                        <p>Stop</p>
                      </div>
                      <div>
                        <img
                          src="/images/talking-tutor/y_4.png"
                          alt="y_4-icon"
                          className={!disableBtn ? 'disabled-btn' : 'enabled-btn'}
                          onClick={disableBtn ? () => handleRecording(REPEAT) : ''}
                        />
                        <p>Restart</p>
                      </div>
                    </div>

                    <div className="left-btn leftt" style={{ display: 'flex' }}>
                      <img
                        src="/images/talking-tutor/b_esay.png"
                        alt="b_esay-icon"
                        className={!disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                        onClick={disableBtn ? () => handleBtnClick('easy') : ''}
                      />
                      <img
                        src="/images/talking-tutor/b_translate.png"
                        alt="translate-icon"
                        className={!disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                        onClick={disableBtn ? () => handleBtnClick('translate') : ''}
                      />

                      <img
                        src="/images/talking-tutor/b_short.png"
                        alt="b_short-icon"
                        className={!disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                        onClick={disableBtn ? () => handleBtnClick('short') : ''}
                      />
                      {/* <img src="/images/talking-tutor/b_ask.png" alt="b_ask-icon" className={!disableBtn ? "disabled-btn" : "enabled-btn hover-icon"} onClick={disableBtn ? () => handleBtnClick("ask") : ''} /> */}
                      <img
                        src="/images/talking-tutor/b_ask.png"
                        alt="b_ask-icon"
                        className={!disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                        onClick={disableBtn ? () => handleAsk() : ''}
                      />
                      <img
                        src="/images/talking-tutor/b_to lesson.png"
                        alt="b_to lesson-icon"
                        className={!disableBtn ? 'disabled-btn' : 'enabled-btn hover-icon'}
                        onClick={disableBtn ? () => handleBtnClick('lesson') : ''}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-part audio-right-part">
            <div className="dialog-wrap">
              <div className="clear-timer">
                <div className="clear-timer-box">
                  <div className="item">
                    <p>
                      Time{' '}
                      <span>{`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
                        2,
                        '0',
                      )}`}</span>
                    </p>
                  </div>
                  <div className="item">
                    <div className="clear-btn">
                      clear &nbsp;&nbsp;
                      <img
                        src="/images/talking-tutor/redo.png"
                        alt="close-arrow"
                        onClick={() => {
                          setChatList([]);
                          setMicBtnClick(0);
                          setActive(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Form.Item
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                name="dialog"
                label="Dialogue"
                style={{ marginBottom: 5 }}
              >
                {!activeAnswer ? (
                  <>
                    <MessageBox
                      id="MessageBox"
                      style={{
                        height: '45vh',
                        overflow: 'auto',
                        margin: '10px 0',
                        backdround: '#fff',
                      }}
                      className="message-box"
                      ref={messageBoxRef}
                    >
                      <InfiniteScroll
                        scrollableTarget="MessageBox"
                        style={{ display: 'flex', flexDirection: 'column' }}
                        //next={aiUserRequestResponseList}
                        inverse={false}
                        hasMore={true}
                        dataLength={chatList?.length}
                        loader={<></>}
                        endMessage={<></>}
                      >
                        {chatList?.map((thread, key) => {
                          const isMe = Number(userInfoVar()?.id) === thread.user;
                          return (
                            <React.Fragment key={`message-item-key${key}`}>
                              <DateDivider
                                isShow={
                                  key < chatList?.length &&
                                  moment(new Date(thread?.created)).format('YYYY-MM-DD') !==
                                    moment(new Date(chatList[key - 1]?.created)).format('YYYY-MM-DD')
                                }
                                date={`${moment(new Date(thread?.created)).format('YYYY-MM-DD')}`}
                              />
                              <MessageItem
                                type={'ai-talking-tutor'}
                                isMe={isMe}
                                name={thread.chat_user_name}
                                message={thread.content}
                                urls={thread.urls}
                                date={thread.created}
                                readYN={true}
                                unreadCount={0}
                              />
                            </React.Fragment>
                          );
                        })}
                        {/* {msgLoading && (
                        <p style={{ textAlign: 'center' }}>
                          <b>loading...</b>
                        </p>
                      )} */}
                      </InfiniteScroll>
                    </MessageBox>
                    {reviewSave ? (
                      <>
                        <p className="progress-text">It takes 20 seconds to the results</p>
                        <Progress
                          className="progress-bar"
                          type="line"
                          percent={percent}
                          format={() => `${Math.round(percent)}%`}
                          status="active"
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <Input.TextArea
                    style={{
                      height: '30vh',
                      overflow: 'auto',
                      border: '1px solid #00b598',
                      backdround: '#fff',
                      margin: '10px 0',
                      color: '#00b598',
                    }}
                    placeholder="답변하기"
                    name="answer"
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                )}
              </Form.Item>
              <div class="form-btn">
                <div class="right-btn">
                  <button type="submit" class="ant-btn ant-btn-default review-save-btn" onClick={() => handleBtnClick('review-save')}>
                    {' '}
                    <span>Review & Save</span>
                    <img src="/images/talking-tutor/exit_o.png" alt="exit-btn" />
                  </button>
                </div>
              </div>
            </div>
            <div className="setting-wrap">
              <div className="setting-wrap-inner">
                <img src="/images/talking-tutor/speak_1.png" alt="speak-icon" />
                <img
                  src="/images/talking-tutor/setting_b.png"
                  alt="setting-icon"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDisplayDrawer(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
      <ReactPlayer
        url={currentUrl}
        playing={currentUrl !== ''}
        width={0}
        height={0}
        loop={false}
        onEnded={handlePlayEnd}
        onError={handlePlayEnd}
      />
      {soundPlay ? <SoundEffect src={sound} onEnd={handleSoundEnd} /> : ''}
      {displayDrawer ? (
        <Sider
          trigger={null}
          className="company-sider"
          style={{
            height: '100%',
            overflowY: 'auto',
            right: 0,
            position: 'fixed',
            zIndex: '9999',
            backgroundColor: '#005d94',
            bottom: '0',
            width: 300,
            maxWidth: 300,
            minWidth: 300,
          }}
        >
          <div className="sidebar-main">
            <div className="setting-icon-wrap">
              <img src="/images/talking-tutor/setting_w.png" alt="setting" />
              <span>Setting</span>
            </div>
            <div>
              <img
                src="/images/talking-tutor/x_w.png"
                alt="setting"
                style={{ cursor: 'pointer' }}
                onClick={() => setDisplayDrawer(false)}
              />
            </div>
          </div>

          <div className="sidebar-inner">
            <div className="sidebar-inner-box">
              <div className="detail-box">
                <div>
                  <img src="/images/talking-tutor/girl.png" alt="setting" />
                </div>
                <div>
                  <h2>{userInfoVar()?.id}</h2>
                  {/* <h2>{topicData?.data?.book?.title}</h2> */}
                  <h2>Test</h2>
                  <h2>class: Test</h2>
                </div>
              </div>
            </div>
            <div className="user-guid-wrap">
              {' '}
              <img src="/images/talking-tutor/i_w.png" alt="setting" />{' '}
              <h2
                style={{ cursor: 'pointer' }}
                onClick={() => window.open('https://platform.openai.com/docs/assistants/overview', '_blank')}
              >
                User's guide
              </h2>
            </div>
            <div class="rank__wrap">
              <Form className="sidebar-form">
                <Form.Item name="Speed:" label="Speed:" style={{ marginBottom: 5 }}>
                  <Select className="select-form-control" placeholder="Select Speed" onChange={(e) => setSpeed(e)} defaultValue={speed}>
                    <Option value={0.8} className="testdata">
                      {0.8}
                    </Option>
                    <Option value={1.0}>{1.0}</Option>
                    <Option value={1.2}>{1.2}</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="Change Voice" label="Change Voice" style={{ marginBottom: 5 }}>
                  <Select className="select-form-control" placeholder="Select Speed" onChange={(e) => setVoice(e)} defaultValue={voice}>
                    <Option value="alloy" className="testdata">
                      alloy
                    </Option>
                    <Option value="echo">echo</Option>
                    <Option value="fable">fable</Option>
                    <Option value="onyx">onyx</Option>
                    <Option value="nova">nova</Option>
                    <Option value="shimmer">shimmer</Option>
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Sider>
      ) : (
        ''
      )}
      {/* <Modal title="Edit script" visible={modalVisible} onOk={handleModalOk} onCancel={handleModalCancel} width={800}>
        <TextArea value={modalText} onChange={handleModalChange} rows={10} />
      </Modal> */}
      <Modal visible={showBtn?.guideBtn} width={1100} className="guide-img-modal">
        <img
          className="modal-img"
          src="/images/talking-tutor/imgpsh_fullsize_anim.jpeg"
          onClick={() =>
            setShowBtn((prevState) => ({
              ...prevState,
              guideBtn: false,
            }))
          }
        ></img>
      </Modal>
      <Modal visible={showBtn?.askBtn} width={800} className="asked-modal" onCancel={handleCancel}>
        <div>
          <h1>Translator</h1>
        </div>
        <Form form={askForm}>
          <div className="title-wrap">
            <Form.Item name="korean_text" label="한국어(Korean)" style={{ width: '66%', marginBottom: '15px' }}>
              <Input name="korean_text" />
            </Form.Item>
            <div className="btn-wrap">
              <img
                src="/images/talking-tutor/redo.png"
                alt="close-arrow"
                onClick={handleAskClear}
                style={{ cursor: 'pointer', marginRight: '12px' }}
              />
              <img
                src={`/images/talking-tutor/${translateMic}`}
                alt="close-arrow"
                onClick={handleTransalte}
                style={{ cursor: 'pointer', marginRight: '12px', height: '38px' }}
                className={translateMic == 'stop.png' ? 'blink_me audio-record' : ''}
              />
              <button onClick={handleConvertText}>한/영</button>
            </div>
          </div>
          <div className="title-wrap">
            <Form.Item name="english_text" label="영어(English)" style={{ marginBottom: 5, width: '73%' }}>
              <Input name="english_text" />
            </Form.Item>
            {/* pause-btn.svg */}
            <img src={`/images/talking-tutor/${playBtn}`} alt="close-arrow" style={{ cursor: 'pointer' }} onClick={handlePlay} />
          </div>
        </Form>
      </Modal>

      <Modal visible={showBtn?.closeBtn} width={400} onCancel={handleCloseCancel} className="restart-task-modal">
        <h3>학습이 완료되지 않았습니다. 재접속시 기존 학습 이후부터 진행됩니다. 학습을 종료하시겠습니까?</h3>

        <div class="modal-footer">
          <Button onClick={handleMainClose} class="ant-btn ant-btn-default">
            <span>Close</span>
          </Button>
          <Button onClick={handleRestart} class="ant-btn btn-primary">
            <span>Restart</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Demo;
export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};
