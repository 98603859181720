import React, { useEffect, useState } from 'react';
import SoundButton from './SoundButton';
import PageNum from './PageNum';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
// import {useMyScript} from "./MyScriptHook";
// import {BgScripter} from "./Scripter";
import { handleAllExceptionLetters } from '../../utils/pm/util';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
//
import SwitchButtonBox from './SwitchButtonBox';
import Keyboard from './Keyboard';
//
const getWordBackgroundUrl = (sound, isEndSound) => {
  return '/images/Write_' + sound.replace('.mp3', `${isEndSound ? '_' : ''}.png`);
};

const veryGoodSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/very-good.mp3';
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
const tryAgainSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/try-again.mp3';
const MAX_WRONG_COUNT = 2;

const ImageSelectWrite = ({ steps, problem, directionEnd, setSteps, onFinish }) => {
  const stepCount = steps >= problem.length ? problem.length - 1 : steps;
  // const {myScriptEditorRef, script, onClear, error} = useMyScript();
  const [showWrite, setShowWrite] = useState(false);
  const [stepClearSound, setStepClearSound] = useState(undefined);
  const [rightWrongSound, setRightWrongSound] = useState(undefined);
  const [clickedSound, setClickedSound] = useState(false);
  const [soundFinished, setSoundFinished] = useState(true);
  const [wrongCount, setWrongCount] = useState(0);
  const [getKey, setGetKey] = useState([]);
  const location = useLocation();
  const respath = location.pathname.split('/');

  const handleImageClick = (sound) => {
    if (!soundFinished) return false;
    if (directionEnd === false && !clickedSound) return false;

    if (showWrite === true) return false;

    if (sound === problem[stepCount].rightSound) {
      setShowWrite(true);
      setRightWrongSound(veryGoodSound);
    } else {
      setRightWrongSound(tryAgainSound);
      //alert("oops");
    }
  };

  const handleStepClearSoundEnd = () => {
    setStepClearSound(undefined);
    setSteps(steps + 1);
    setShowWrite(false);
    setWrongCount(0);
  };
  const handleSoundEnd = () => {
    setClickedSound(true);
    setSoundFinished(true);
  };
  const handleSoundStart = () => {
    setSoundFinished(false);
  };
  //
  useEffect(() => {
    if (_.isNil(getKey)) {
      setGetKey([]);
    }
  }, [getKey]);
  const handleTypeEnd = () => {
    if (_.isNil(problem[stepCount].letter) || _.isNil(getKey)) {
      return;
    }
    //myscript에서 x를 제대로 인식 못해서 어쩔 수 없이 ...
    let userInpurLetter = getKey.join('');
    userInpurLetter = handleAllExceptionLetters(userInpurLetter, problem[stepCount].letter.toLowerCase());

    if (problem[stepCount].letter.toLowerCase() === userInpurLetter) {
      if (stepCount >= problem.length - 1) {
        onFinish();
        return;
      }

      setRightWrongSound(veryGoodSound);
      // setSteps(steps + 1);
      // setShowWrite(false);
      setStepClearSound('https://cdn.cloubot.com/PM/audio/sound-effect/dingdong.mp3');
    } else {
      if (wrongCount < MAX_WRONG_COUNT - 1) {
        setRightWrongSound(tryAgainSound);
        setWrongCount(wrongCount + 1);
      } else {
        setStepClearSound(woopsSound);
        if (stepCount >= problem.length - 1) {
          onFinish();
          return;
        }
      }
    }
    // onClear();
  };
  //
  return (
    <>
      <S.ColumnFlex
        style={{ flex: '1 1', alignSelf: 'stretch', height: 'calc(100% - 70px)' }}
        className={
          isMobile
            ? (respath[2] === '1a' || respath[2] === '1b') && respath[6] == '7'
              ? 'main_content  stage6_content row2 custom-mobile-stage'
              : 'main_content  stage6_content row2'
            : (respath[2] === '1a' || respath[2] === '1b') && respath[6] == '7'
            ? 'main_content  stage6_content row2 custom-stage'
            : 'main_content  stage6_content row2'
        }
      >
        <TopBox showWrite={showWrite} className="top_box">
          <PageNum
            num={stepCount + 1}
            total={problem.length}
            style={{ position: 'absolute', top: '10px', right: '10px', fontWeight: 'bold', fontSize: '1.3rem' }}
          />
          {!showWrite && (
            <SoundButton
              src={`https://cdn.cloubot.com/PM/audio/sounds/${problem[stepCount].rightSound}`}
              style={{ display: 'inline-block' }}
              onSoundEnd={handleSoundEnd}
              onStart={handleSoundStart}
            />
          )}
          <ImgUl className="image_box">
            {showWrite ? (
              problem[stepCount]?.isEndSound ? (
                <>
                  <S.Dnd4WordWrap>{problem[stepCount].question}</S.Dnd4WordWrap>
                  <S.ImgDnd4AlphabetBox style={{ color: '#2e86de' }}>
                    {getKey ? getKey : rightWrongSound === veryGoodSound ? problem[stepCount].letter : ''}
                  </S.ImgDnd4AlphabetBox>
                  <ImgLi key={`ex-image-key-answer`}>
                    <Image
                      showWrite={showWrite}
                      src={problem[stepCount].examples.filter((e) => e.isAnswer)[0].image}
                      alt=""
                      // onClick={() => {
                      // handleImageClick(v.sound);
                      // }}
                    />
                    {/* {
                      <SoundButton
                        src={`https://cdn.cloubot.com/PM/audio/sounds/${problem[stepCount].rightSound}`}
                        style={{ display: 'inline-block' }}
                        onSoundEnd={handleSoundEnd}
                        onStart={handleSoundStart}
                      />
                    } */}
                  </ImgLi>
                </>
              ) : (
                <>
                  <ImgLi key={`ex-image-key-answer`}>
                    <Image
                      showWrite={showWrite}
                      src={problem[stepCount].examples.filter((e) => e.isAnswer)[0].image}
                      alt=""
                      // onClick={() => {
                      // handleImageClick(v.sound);
                      // }}
                    />
                    {/* {
                      <SoundButton
                        src={`https://cdn.cloubot.com/PM/audio/sounds/${problem[stepCount].rightSound}`}
                        style={{ display: 'inline-block' }}
                        onSoundEnd={handleSoundEnd}
                        onStart={handleSoundStart}
                      />
                    } */}
                  </ImgLi>
                  <S.ImgDnd4AlphabetBox style={{ color: '#2e86de' }}>
                    {getKey ? getKey : rightWrongSound === veryGoodSound ? problem[stepCount].letter : ''}
                  </S.ImgDnd4AlphabetBox>
                  <S.Dnd4WordWrap>{problem[stepCount].question}</S.Dnd4WordWrap>
                </>
              )
            ) : (
              problem[stepCount].examples &&
              problem[stepCount].examples.map((v, k) => (
                <ImgLi key={`ex-image-key${k}`}>
                  <Image
                    showWrite={showWrite}
                    src={v.image}
                    alt=""
                    onClick={() => {
                      handleImageClick(v.sound);
                    }}
                  />
                  {/* {
                    <SoundButton
                      src={`https://cdn.cloubot.com/PM/audio/sounds/${v.sound}`}
                      style={{ display: 'inline-block' }}
                      onSoundEnd={handleSoundEnd}
                      onStart={handleSoundStart}
                    />
                  } */}
                </ImgLi>
              ))
            )}
          </ImgUl>
        </TopBox>
        {showWrite && (
          <S.PenKeyboardWrapMain className="bottom_box">
            <S.PenKeyboardWrap style={{ padding: '10px 0' }} className="pen_keyboard_wrap">
              <Keyboard enabledWritePreview={false} getKey={getKey} setGetKey={setGetKey} onWriteEnd={handleTypeEnd} />
            </S.PenKeyboardWrap>
          </S.PenKeyboardWrapMain>
        )}
      </S.ColumnFlex>

      {(directionEnd || clickedSound) && <SoundEffectPM url={`https://cdn.cloubot.com/PM/audio/sounds/${problem[stepCount].rightSound}`} />}
      {stepClearSound && <SoundEffectPM url={stepClearSound} onEnd={handleStepClearSoundEnd} />}
      <SoundEffectPM
        url={rightWrongSound}
        onEnd={() => {
          setRightWrongSound(undefined);
        }}
      />
    </>
  );
};

export default ImageSelectWrite;
const TopBox = styled(S.ImageSelectTopBox)`
  height: ${(props) => (!props.showWrite ? '100%' : '50%')};
  flex-direction: column;
  @media (max-height: 500px) {
    height: ${(props) => (!props.showWrite ? '100%' : '35%')};
  }
`;
const ImgUl = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex: 1 1; */
  list-style: none;
  margin: 0;
  padding: 0;
  width:100%;
`;
const ImgLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  margin: 0.4rem 0;
  margin-left: 2%;
  margin-right: 2%;
  border: 2px solid orange;
  border-radius: 5px;
  padding: 0;
  list-style: none;
  flex-direction: row;
  /* @media (max-height: 768px) {
  }
  @media (max-height: 500px) {
  } */
`;
const Image = styled.img`
  max-width: 50%;
  height: ${(props) => props.showWrite && '25vh'};
  object-fit: contain;
  @media (max-height: 768px) {
  }
  @media (max-height: 500px) {
    height: ${(props) => props.showWrite && '20vh'};
  }
`;
