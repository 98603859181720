import React, { useState } from 'react';
import { Col, Row, Radio, Button } from 'antd';
import {
  BookCard,
  BookCover,
  BookTitle,
  BOOK_COL_STYLE,
  THUMBNAIL_BASE_URL,
} from './CommonComp';

const EBOOK_BASE_URL = 'http://ebook.theclassenglish.com/SB/';
const GROUPS = ['Activity Book'];
const IMAGE_NAMES = [
  { title: 'Coco Activity', name: 'coco_activity_', ext: 'png', link_name: 'CocoActivity/vol.*_published/' },
];
const CocoActivity = () => {
  const [groupNo, setGroupNo] = useState(0);
  const handleChangeLevel = (evt) => {
    const {
      target: { value },
    } = evt;
    setGroupNo(value);
  };
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <Radio.Group
        value={groupNo}
        style={{ marginBottom: 16, marginLeft: 16 }}
        buttonStyle="solid"
        onChange={handleChangeLevel}
        size="middle"
      >
        {GROUPS.map((groupName, key) => (
          <Radio.Button key={`radio-level-key${key}`} value={key} style={{ width: 130, textAlign: 'center' }}>
            {groupName}
          </Radio.Button>
        ))}
      </Radio.Group>

      <Row gutter={[16, 16]} style={{ margin: '0 10px' }}>
        {Array(6)
          .fill(0)
          .map((_, key) => (
            <Col
              span={4}
              key={`col-${key}`}
              style={{
                ...BOOK_COL_STYLE,
                borderRight: `${key === 5 ? '1px solid #dedede' : ''}`,
              }}
            >
              <BookTitle level={5}>{`${IMAGE_NAMES[groupNo].title} ${key + 1}`}</BookTitle>
              <BookCard
                onClick={() => {
                  window.open(
                       `${EBOOK_BASE_URL}${IMAGE_NAMES[groupNo].link_name.replace('*', `${key + 1}`)}`
                      ,
                    '_blank',
                    'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes',
                  );
                }}
                cover={
                  <BookCover
                    style={{
                      backgroundImage: `url(${THUMBNAIL_BASE_URL}${IMAGE_NAMES[groupNo].name}${key + 1}.${IMAGE_NAMES[groupNo].ext})`,
                    }}
                  />
                }
              >
              </BookCard>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default CocoActivity;

