import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import AssignLesson from 'src/components/Offline/MainPage/AssignLessonPlan';
import { useHistory, Link } from 'react-router-dom';
//캠퍼스 원장용 대시보드 메인 페이지
const CampusPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  const history = useHistory();

  return (
    <>
      <Row justify="space-between" className="buttonlisting-dashboard">
        <Col span={2} className="calendar-box">
          <HeaderTitle level={4}>Assigned List</HeaderTitle>
        </Col>
        <Col span={19} className="calendar-box">
        </Col>
        <Col span={2} className="calendar-box">
          <Button
              className="btn-schedule"
              type={'primary'}
              size="large"
              style={{ background: "white", borderColor: "#B8E4F0", color : "orange", marginTop:"2%" }}
            >
              <Link to="/offline/lesson/assign">Syllabus List</Link> 
            </Button>
        </Col>
        <Col span={1} className="calendar-box">
        </Col>
      </Row>

      <Row gutter={[24, 16]}><AssignLesson /></Row>
    </>
  );
};

export default CampusPage;
