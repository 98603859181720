import React, { useEffect, useState } from "react";
import { Button, Space } from 'antd';
import SoundEffect from "../common/SoundEffect";
const MAX_TRY_COUNT = 1;

const DailyTestQuiz = ({ quizItem, onFinish, currentNumber, total,getUserAns }) => {
    const [tryCount, setTryCount] = useState(0);
    const [useSelectAnswer, setUserSelectAnswer] = useState(0);
    const [quizAudio, setQuizAudio] = useState("");
    const [sound, setSound] = useState("");
    const [questionAnswer, setQuestionAnswer] = useState([]);
    const rightSound = "/sounds/dingdong.mp3";
    const wrongSound = "/sounds/wrong-error-sound.mp3";
    const rightStyle = { backgroundColor: "#00FF00", color: "#fff", boxShadow: "none" };
    const wrongStyle = { backgroundColor: "#FF0000", boxShadow: "none" };
    useEffect(() => {
        setUserSelectAnswer(0);
        setTryCount(0);
        if (quizItem?.audio_id) setQuizAudio(quizItem?.audio_id);
    }, [quizItem]);
    useEffect(() => {
        if (tryCount >= MAX_TRY_COUNT) {
            onFinish(false, useSelectAnswer);
        }
        // eslint-disable-next-line
    }, [tryCount]);
    useEffect(()=>{
        if(total === currentNumber){
            setTimeout(()=> {
                getUserAns(questionAnswer)
            },1000)
        }
    },[questionAnswer])

    const checkAnswer = (ele,k) => {
        const userAns = {
            [`q${currentNumber}`] : k + 1 
        }
        setQuestionAnswer([...questionAnswer,userAns])
        setUserSelectAnswer(k + 1);
        if (k+1 === parseInt(quizItem?.answer)) {
            setSound(rightSound)
            setTimeout(()=>{
            onFinish(true, ele)},1000)
        } else {
            setSound(wrongSound)
            setTimeout(()=>{
                onFinish(false, ele);
            },1000)
        }
    }
    return (
        <>
            <div className="daily-test-quiz">
                <div className="daily-sub-wrap">
                    <div className="header-top-wrap">
                        <div className="header-top-item">
                            <p>Daily test sunshine 01</p>
                        </div>
                        <div className="header-top-item">
                            <p className="test-no"> Test 06</p>
                        </div>
                        <div className="header-top-item">
                            <p className="date-info">2023-07-22</p>
                        </div>
                        <div className="header-top-item">
                            <p className="time-info">12:30 PM</p>
                        </div>
                    </div>
                    {quizItem?.image_id == 'null' ?
                        <div className="daily-content">
                            <div className="daily-content-top">
                                <h1>{currentNumber} . Choose the right answer.</h1>
                                <div className="daily-num">
                                    <h3>Number</h3>
                                    <div>{currentNumber}/{total}</div>
                                </div>
                            </div>
                            <div className="specker-questionb-content">
                            <div className="detaction-block">
                                <img className="btnForDictation" src="/images/speak.png" />
                            </div>
                                <div className="specker-sub-wrap">
                                    <p>{quizItem?.question}</p>
                                </div>
                            </div>

                            {quizItem?.q_option.split("|").map((ele, k) => {
                                return (
                                    <div className="quize-option-content">
                                        <span>{k + 1}</span>
                                        <div className="quize-option-sub-wrap" 
                                                     style={
                                                        k + 1 === useSelectAnswer
                                                          ? parseInt(quizItem?.answer) === useSelectAnswer 
                                                            ? rightStyle
                                                            : wrongStyle
                                                          : useSelectAnswer > 0 && k + 1 === parseInt(quizItem?.answer)
                                                          ? rightStyle
                                                          : {}
                                                      }
                                        onClick={() => {
                                            checkAnswer(ele,k);
                                        }}>
                                            <p>{ele}</p>

                                        </div>
                                    </div>
                                )

                            })}

                        </div>
                        :
                        <>
                            <div className="img-test-quize">
                                <div className="daily-content-top">
                                    <h1>{currentNumber} . Choose the right answer.</h1>
                                    <div className="daily-num">
                                        <h3>Number</h3>
                                        <div>{currentNumber}/{total}</div>
                                    </div>
                                </div>
                                <div className="img-quize">
                                    <div className="img-wrapper">
                                        <img src={quizItem?.image_id} />
                                    </div>

                                    <div className="daily-content">
                                        <div className="specker-questionb-content">
                                            <div className="detaction-block">
                                            <img className="btnForDictation" src="/images/speak.png" />
                                            </div>
                                            <div className="specker-sub-wrap">
                                                <p>{quizItem?.question}</p>
                                            </div>
                                        </div>
                                        {quizItem?.q_option.split("|").map((ele, k) => {
                                            return (
                                                <div className="quize-option-content">
                                                    <span>{k + 1}</span>
                                                    <div className="quize-option-sub-wrap" 
                                                                              style={
                                                                                k + 1 === useSelectAnswer
                                                                                  ? parseInt(quizItem?.answer) === useSelectAnswer
                                                                                    ? rightStyle
                                                                                    : wrongStyle
                                                                                  : useSelectAnswer > 0 && k + 1 === parseInt(quizItem?.answer)
                                                                                  ? rightStyle
                                                                                  : {}
                                                                              }
                                                    onClick={() => {
                                                        checkAnswer(ele,k);
                                                    }}>
                                              <p>{ele}</p>

                                                    </div>
                                                </div>
                                            )

                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div >
            {/* <SoundEffect src={sound} onEnd={handleSoundEnd} />
      <SoundEffect src={quizAudio} onEnd={handleQuizAudioEnd} /> */}
        </>)
}


export default DailyTestQuiz;