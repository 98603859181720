import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, List, Form, Checkbox, DatePicker, Select, Input, Modal } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import moment from 'moment';
import _lo from 'lodash';
import queryString from 'query-string';

import { HeaderTitle } from 'src/components/common/Styles';
import { userInfoVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import { ASSIGN_BOOK_TO_STUDENTS } from 'src/operations/mutations/bookCredit';
import { CAMPUS_BOOK_CREDIT_INFO_LIST } from 'src/operations/queries/bookCredit';

const { Title } = Typography;
const { Option } = Select;

const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

const Assign = () => {
  const history = useHistory();
  const location = useLocation();
  const { book_code } = useParams();
  const calendarRef = useRef();
  const [startDate, setStartDate] = useState();
  const [monthChanged, setMonthChanged] = useState(0);

  const [firstForm] = Form.useForm();

  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);

  const [selectedClass, setSelectedClass] = useState(-1);
  const [selectedClassIdx, setSelectedClassIdx] = useState(0);

  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const { classIdx } = queryString.parse(window.location.search.replace('?', ''));

  const classQuery = useQuery(queries.getClass.GET_TYPE_CLASS);

  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(queries.getClass.CLASS_STUDENT_LIST);

  const [assignBookCredit, { loadingAssign }] = useMutation(ASSIGN_BOOK_TO_STUDENTS, {
    onCompleted: ({ AssignBookToStudents: resp }) => {
      if (resp.result === 'success') {
        Modal.info({
          title: '이용권을 부여했습니다.',
          onOk: () => {
            //어디로 가나?
            history.replace('/book-credit/list');
          },
        });
      } else {
        Modal.info({
          title: '오류가 발생했습니다.',
        });
      }
    },
    onError: (e) => {
      console.log(e);
      alert('오류가 발생했습니다.');
    },
  });

  const countForUse = useMemo(() => {
    if (checkedList) {
      return checkedList?.filter((item) => item?.checked)?.length || 0;
    }
    return 0;
  }, [checkedList]);

  const restBookCreditCount = useMemo(() => {
    return location?.state?.rest || 0;
  }, [location?.state]);

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }

    if (classIdx) {
      setCheckedList([]);
      setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(classIdx))[0]);
      getSelectClassStudents({
        variables: { class_idx: parseInt(classIdx) },
      });
      firstForm.setFieldsValue({
        class: parseInt(classIdx),
      });
    }
  }, [classQuery, fetchStudents, classIdx]);

  useEffect(() => {
    if (startDate) {
      let duration = location.state.duration;
      const calApi = calendarRef.current.getApi();
      const added = _lo.cloneDeep(startDate);
      calApi.select(startDate.format('YYYY-MM-DD'), added.add(duration, 'days').format('YYYY-MM-DD'));
    }
    //달이 바뀔 때마다 선텍된 것이 초기화되어서 monthChanged 추가함.
  }, [startDate, monthChanged]);

  function onChange(e, idx, user_idx) {
    checkedList[idx] = { checked: e.target.checked, user_idx };
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      if (students) {
        //setCheckedList(new Array(students.length).fill(true));
        setCheckedList(
          students.map((item) => {
            return { checked: true, user_idx: item?.user?.idx };
          }),
        );
      }
    } else {
      setCheckedList([]);
    }
  }

  function handleChange(value) {
    setSelectedClassIdx(value);
    setCheckedList([]);
    setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === value)[0]);
    getSelectClassStudents({
      variables: { class_idx: value },
    });
  }

  function handleAssignCredit() {
    if (!startDate) {
      Modal.info({ title: '날짜를 선택하세요.' });
      return;
    }
    if (countForUse === 0) {
      Modal.info({ title: '학생을 선택하세요.' });
      return;
    }
    if (restBookCreditCount - countForUse < 0) {
      Modal.info({ title: '이용권이 부족합니다.' });
      return;
    }

    let addDays = 0;
    let endDate = '';
    if (location?.state?.duration) {
      addDays = location.state.duration;
      endDate = _lo.cloneDeep(startDate).add(addDays, 'days').format('YYYY-MM-DD');
    }
    const user_idx_list = checkedList?.filter((item) => item?.checked).map((ele) => ele.user_idx);
    assignBookCredit({
      variables: {
        assign_book_input: {
          campus_idx: userInfoVar()?.campus_idx,
          book_code,
          class_idx: selectedClassIdx,
          user_idx: user_idx_list,
          study_type: 'learn',
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate,
          days: location.state.duration | 0,
        },
      },
      refetchQueries: [
        {
          query: CAMPUS_BOOK_CREDIT_INFO_LIST,
          variables: { campus_idx: userInfoVar()?.campus_idx },
        },
      ],
    });
  }

  return (
    <>
      <Col className="test-syllabus-wrapper book-credit-assign" span={24} style={{ marginTop: '10px' }}>
        <Row gutter={[16, 16]} className="book-credit-assign-col">
          <Col span={24} style={{ textAlign: 'left' }}>
            <HeaderTitle level={4}>Assign Credits(이용권 부여)</HeaderTitle>
          </Col>
        </Row>
        <TestBlock className="test-syllabus-wrapper-tb">
          <Row gutter={[16, 16]}>
            <Col className="assign-bookstore-wrapper" span={23} style={{ textAlign: 'left' }}>
              <Row gutter={[16, 16]}>
                <Col span={12} className="assign-bookstore-heading">
                  {location?.state?.book_title}
                </Col>
                <Col className="assign-bookstore-top-lbl" span={12} style={{ textAlign: 'right' }}>
                  <label>잔여 이용권 수 :</label>
                  <Input value={restBookCreditCount} style={{ width: '150px' }} readOnly />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="test-syllabus-box-listing" gutter={[24, 16]}>
            <Col className="test-syllabus-box-a" span={7}>
              <TestSectionBlock className="test-syllabus-box-a-tsb">
                <Form
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="vertical"
                  form={firstForm}
                >
                  <Title level={5}>Class List </Title>
                  <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
                    <Select style={{ width: '100%', minWidth: 100 }} placeholder="Class" onChange={handleChange} value={selectedClassIdx}>
                      {classes.map((classInfo) => (
                        <Option key={classInfo.idx} value={classInfo.idx}>
                          {classInfo.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <List
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>Name</label>
                        <div>
                          전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                        </div>
                      </div>
                    }
                    footer={<div></div>}
                    bordered
                    dataSource={students}
                    renderItem={(item, idx) => (
                      <List.Item style={{ display: 'flex' }}>
                        <span>{item.user.name}</span>{' '}
                        <Checkbox checked={checkedList[idx]?.checked} onChange={(e) => onChange(e, idx, item?.user?.idx)}></Checkbox>
                      </List.Item>
                    )}
                  />
                </Form>
              </TestSectionBlock>
            </Col>
            <Col className="test-syllabus-box-b" span={9} style={{ textAlign: 'center' }}>
              <TestSectionBlock className="test-syllabus-box-b-tsb">
                <Title level={5}>Starting Date :</Title>
                <DatePicker
                  style={{ marginBottom: 10 }}
                  onChange={setStartDate}
                  disabledDate={(currentDate) => {
                    return currentDate && currentDate < moment().startOf('day');
                  }}
                />
                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    contentHeight={250}
                    expandRows={false}
                    initialView="dayGridMonth"
                    unselectAuto={false}
                    selectable={false}
                    editable={false}
                    datesSet={() => {
                      //달이 바뀔 때마다 랜더링이 되고, 선택된 것이 리셋되기 때문에 추가함.
                      setMonthChanged(monthChanged + 1);
                    }}
                  />
                </CalendarBlock>
                <div className="increaseandescrease-input">
                  <div className="input-label">
                    <label>기간 :</label>
                  </div>
                  <div className="input-number">
                    <input style={{ border: '1px solid #ddd' }} type="number" id="number" value={location?.state?.duration | 0} readOnly />
                  </div>
                </div>
                <div className="input-detail">
                  <label>사용 이용권 :</label>
                  <input type="text" value={countForUse} readOnly />
                </div>
              </TestSectionBlock>
            </Col>

            <Col className="test-syllabus-box-c" span={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Title level={5}>Receipt</Title>
              <div className="recepits-box">
                <div className="recepits-box-mainheading">{location?.state?.book_title} :</div>
                <div className="recepits-box-subheading">
                  <div>이용권 {countForUse}개</div>
                  <div>기간 {location?.state?.duration | 0}</div>
                  <div>시작 일 : {startDate?.format('YYYY-MM-DD')}</div>
                </div>
                <div className="recipts-total">
                  <div className="recipts-total-a">--------------------------</div>
                  <div className="recipts-total-b">
                    <div className="clrone">{restBookCreditCount}</div> <div className="clrtwo">&nbsp;– {countForUse}&nbsp;</div> = 잔여
                    이용권 &nbsp; {restBookCreditCount - countForUse}개
                  </div>
                </div>
              </div>
              <div className="recepits-box-buttons">
                <Button
                  type="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  취소
                </Button>
                <Button type="primary" loading={loadingAssign} onClick={handleAssignCredit}>
                  저장
                </Button>
              </div>
            </Col>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default Assign;
