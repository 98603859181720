import React from 'react';
import { Table } from 'antd';
import SmsChkSentMsg from 'src/components/Modal/SmsChkSentMsg';
import SmsReceivedUserModal from 'src/components/Modal/SmsReceivedUserModal';

const SmsTxtHistory = ({ smsHistoryData, loading }) => {
  return (
    <>
      <Table loading={loading} dataSource={smsHistoryData} columns={smsHistoryCol} className="SmsTxtHistory_table" scroll={{ x: 240 }} />
    </>
  );
};

export default SmsTxtHistory;

const smsHistoryCol = [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
  },
  {
    title: '문자 보낸 날짜',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: '시간',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: '보낸 문자 수',
    dataIndex: 'sent_num',
    key: 'sent_num',
  },
  {
    title: '문자 유형',
    dataIndex: 'kind',
    key: 'kind',
  },
  {
    title: '보낸 문구 보기',
    dataIndex: 'show_txt',
    key: 'show_txt',
    render: (text) => <SmsChkSentMsg text={text} />,
  },
  {
    title: '받은 사람 명단',
    dataIndex: 'received',
    key: 'received',
    render: (smsList) => <SmsReceivedUserModal smsList={smsList} />,
  },
  {
    title: '받은 그룹 명',
    dataIndex: 'received_group',
    key: 'received_group',
  },
  {
    title: '성공',
    dataIndex: 'send_check',
    key: 'send_check',
  },
];
