import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Modal, Button, Space, Spin, List, Typography, Input } from 'antd';
import { GET_LESSON_INFO, STUDENT_BOOK_STUDY_LESSON_DATA } from 'src/operations/queries/lesson';

const { Title } = Typography;

const SpeakingTestResultModal = ({ user_idx, lesson_code, visible, onCancel }) => {
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [testQuestions, setTestQuestions] = useState([]);
  const { data } = useQuery(GET_LESSON_INFO, {
    skip: !lesson_code,
    variables: { lessonCode: lesson_code },
  });

  const { data: dataStudy } = useQuery(STUDENT_BOOK_STUDY_LESSON_DATA, {
    skip: !lesson_code || !visible || !user_idx,
    variables: { lesson_code: lesson_code, user_idx },
  });

  const jsonUrl = useMemo(() => {
    if (data?.bookLessonInfo?.stage) {
      const findSpeaking = data?.bookLessonInfo?.stage.find((ele) => {
        return ele.name === 'Speaking Test';
      });
      const url = findSpeaking?.study_tool?.url;
      if (url) {
        const [, code] = lesson_code.split('-');
        return `${url}${code}.json`;
      }
    }
    return undefined;
  }, [data, lesson_code]);

  const testQuestionAnswerData = useMemo(() => {
    if (dataStudy && testQuestions) {
      const findItem = dataStudy.getUserStudyLesson.find((ele) => {
        const stage_no = parseInt(ele.stage_no);
        const findItem2 = ele.book_lesson?.stage?.find((ele2) => {
          return stage_no === parseInt(ele2.no) && ele2.name === 'Speaking Test';
        });
        return findItem2 ? true : false;
      });
      if (findItem) {
        const userAnswers = findItem.user_study_answer;

        return testQuestions.map((item, key) => {
          const is_correct = userAnswers[key]?.is_correct === '1' ? true : false;

          return {
            ...item,
            is_correct,
            user_answer: userAnswers[key]?.user_answer,
          };
        });
      }
    }
    return [];
  }, [dataStudy, testQuestions]);

  useEffect(() => {
    if (jsonUrl) {
      fetch(jsonUrl, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          const questions = [];
          if (res.dialog) {
            for (const oKey in res.dialog) {
              if (/^TEST-[0-9]+/.test(oKey)) {
                if (res.dialog[oKey].questionTxt) {
                  questions.push(res.dialog[oKey].questionTxt);
                }
              }
            }
            setTestQuestions(questions);
          }

          if (questions.length === 0) {
            setIsError(true);
          } else {
            setIsError(false);
          }
          setLoading(false);
        })
        .catch((e) => {
          //error못 불러옴.
          console.log('fetch error', e);
          setIsError(true);
          setLoading(false);
        });
    } else {
      setIsError(true);
      setLoading(false);
    }
  }, [jsonUrl]);

  return (
    <Modal
      visible={visible}
      title={'Speaking Test'}
      onCancel={onCancel}
      width={'60%'}
      footer={[
        <Button key="back" onClick={onCancel}>
          닫기
        </Button>,
      ]}
    >
      {loading ? (
        <Space
          size="middle"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin size="large" />
        </Space>
      ) : isError ? (
        <div>문제를 불러오지 못했습니다.</div>
      ) : (
        testQuestions && (
          <List
            size="small"
            // header={<Title level={4}>문제</Title>}
            bordered
            dataSource={testQuestionAnswerData}
            header={
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Title level={4}>
                  Score:{' '}
                  {`${
                    testQuestionAnswerData &&
                    Math.round((100 * testQuestionAnswerData.filter((item) => item.is_correct).length) / testQuestionAnswerData.length)
                  }`}
                </Title>
              </div>
            }
            renderItem={(item, index) => (
              <List.Item>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div>
                    <span>{index + 1}. </span>
                    {item[1]}
                    <div>
                      <Typography.Text>{item?.user_answer}</Typography.Text>
                    </div>
                  </div>

                  <span style={{ display: 'flex', alignItems: 'center', fontSize: '1.7em', color: `${item.is_correct ? '#00f' : '#f00'}` }}>
                    {item.is_correct ? 'O' : 'X'}
                  </span>
                </div>
              </List.Item>
            )}
          />
        )
      )}
    </Modal>
  );
};

export default SpeakingTestResultModal;
