import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import * as S from '../style';
import { useSelector } from 'react-redux';
import { SoundFilled, MutedFilled, HomeFilled } from '@ant-design/icons';
import StageProgressBox from 'src/utils/pm/StageProgressBox';
import bookInfo from 'src/utils/pm/bookInfo';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
// import { fetchBookPerform } from 'src/utils/pm/api';
const LevelList = ['1a', '1b', '2a', '2b', '3a', '3b', '4a', '4b'];
export default function PhonicsMonsterUnitList() {
  const history = useHistory();
  const { level } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [fetchData, setFetchData] = useState(null);
  const [clickSound, setClickSound] = useState('');
  const [isMute, setIsMute] = useState(false);
  const clickedPathObjRef = useRef();
  useEffect(() => {
    _.each(Object.keys(bookInfo), (item) => {
      if (bookInfo[item].bookName === level) setFetchData(bookInfo[item].unit);
      setIsLoaded(true);
    });
  }, [fetchData]);
  useEffect(() => {
    const pmMute = window.localStorage.getItem('pm_Mute');
    if (pmMute) {
      setIsMute(true);
    }
  }, []);
  useEffect(() => {
    if (isMute) {
      window.localStorage.setItem('pm_Mute', true);
    } else {
      window.localStorage.removeItem('pm_Mute', true);
    }
  }, [isMute]);
  const table = [2, 45, 2, 45, 2, 4];
  const title = ['Unit', 'Lesson1', 'Stage', 'Lesson2', 'Stage', 'Game'];
  const handleGoStage = (e, pathObj) => {
    e.preventDefault();
    clickedPathObjRef.current = pathObj;
    setClickSound('https://cdn.cloubot.com/PM/audio/sound-effect/game-choose.mp3');
  };
  const handleSoundEffectEnd = () => {
    history.push(clickedPathObjRef.current);
  };
  return isLoaded ? (
    <>
      <S.fontStyle />
      <S.Root>
        <S.RowFlex style={{ width: '85vw', justifyContent: 'right', paddingBottom: 10 }}>
          <S.Button1 onClick={() => setIsMute((prev) => !prev)} style={{ marginRight: 10, width: 50, height: 50 }}>
            {isMute ? (
              <MutedFilled style={{ fontSize: '1.5rem', color: '#fff' }} />
            ) : (
              <SoundFilled style={{ fontSize: '1.5rem', color: '#fff' }} />
            )}
          </S.Button1>
          <S.Button1
            style={{ width: 50, height: 50 }}
            onClick={() => {
              history.push('/pm');
            }}
          >
            <HomeFilled style={{ fontSize: '1.4rem', color: '#fff' }} />
          </S.Button1>
        </S.RowFlex>
        <S.Main>
          <S.UnitWrapper>
            <S.UnitTable>
              <colgroup>
                {_.map(table, (item, idx) => (
                  <col key={`unit-table-col-${idx}`} style={{ width: idx === table.length - 1 ? '8%' : idx % 2 === 0 ? '4%' : '40%' }} />
                ))}
              </colgroup>
              <thead>
                <tr>
                  {_.map(title, (item, idx) => (
                    <S.UnitHeadTH key={`${item}${idx}`}>{item}</S.UnitHeadTH>
                  ))}
                </tr>
              </thead>
              <tbody>
                {_.map(fetchData, (item, index) => (
                  <tr key={`unit-item-${index}`} style={{ backgroundColor: index % 2 === 1 ? '#fff' : '#fcf4e3' }}>
                    <S.UnitBodyTH>{`unit ${index}`}</S.UnitBodyTH>
                    <S.UnitTD>
                      <S.UnitTitle>{item.title}</S.UnitTitle>
                      <StageProgressBox item={item} bookName={level} unit={index} data={undefined} lesson={1} />
                      {/* <StageProgressBox item={item} bookName={level} unit={index} data={performData} lesson={1} /> */}
                    </S.UnitTD>
                    <S.UnitTD>
                      <Link
                        to={{ pathname: `${level}/unit/${index}`, state: { lesson: false } }}
                        className="btn_type1"
                        onClick={(e) => handleGoStage(e, { pathname: `${level}/unit/${index}`, state: { lesson: false } })}
                      >
                        <S.Button1>
                          <img src="/images/pm/i_go.svg" alt="GO" />
                        </S.Button1>
                      </Link>
                    </S.UnitTD>
                    <S.UnitTD>
                      <S.UnitTitle>{item.title}</S.UnitTitle>
                      <StageProgressBox item={item} bookName={level} unit={index} data={undefined} lesson={2} />
                      {/* <StageProgressBox item={item} bookName={level} unit={index} data={performData} lesson={2} /> */}
                    </S.UnitTD>
                    <S.UnitTD>
                      <Link
                        to={{ pathname: `${level}/unit/${index}`, state: { lesson: true } }}
                        className="btn_type1"
                        onClick={(e) => {
                          handleGoStage(e, { pathname: `${level}/unit/${index}`, state: { lesson: true } });
                        }}
                      >
                        <S.Button1>
                          <img src="/images/pm/i_go.svg" alt="GO" />
                        </S.Button1>
                      </Link>
                    </S.UnitTD>
                    <S.UnitTD>
                      <p className="button_game" style={{ marginBottom: 0 }}>
                        {item.title.indexOf('Review') === -1 && (
                          <a
                            href={`https://epublic-phonics.cloubot.com/game/phonics_game${
                              index % 5 || 1
                            }/mobile/index.html?book=${level}&unit=${index}`}
                            target="_blank"
                            className="btn_type2"
                            rel="noopener noreferrer"
                          >
                            <S.Button2>
                              <img style={{ width: '80%' }} src="/images/pm/i_game.svg" alt="GAME!" />
                            </S.Button2>
                          </a>
                        )}
                      </p>
                    </S.UnitTD>
                  </tr>
                ))}
              </tbody>
            </S.UnitTable>
          </S.UnitWrapper>
        </S.Main>
      </S.Root>
      <SoundEffectPM url={clickSound} onEnd={handleSoundEffectEnd} />
      {!isMute && <SoundEffectPM url={'https://cdn.cloubot.com/PM/audio/sound-effect/comedy-ragtime.mp3'} replayCnt={0} />}
    </>
  ) : (
    <>loading</>
  );
}
const device = {
  smallDesktop: `(max-width: 1280px)`,
  tablet: `(max-width: 780px)`,
};
