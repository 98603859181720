import React, { useRef } from 'react';
import _ from 'lodash';
import { useFullscreenStatus } from 'src/utils/hooks';

const FullScreenButton = ({ activeElement, element }) => {
  const maximizableElement = useRef(document.documentElement);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = 'Fullscreen not supported';
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const handleClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <>
      <span onClick={handleClick}>{isFullscreen ? activeElement : element}</span>
    </>
  );
};

export default FullScreenButton;
