import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row, Typography, Button } from 'antd';
import moment from 'moment';
import CustomTable from 'src/components/common/CustomTable';
import { HalfWrapper } from 'src/components/common/Styles';
import { STUDENT_REPORTCARD_LIST } from 'src/operations/queries/reportcard';
import ReportCardViewModal from 'src/components/Modal/reportcard/ReportCardViewModal';
import StudentHomeworkMenuGroup from 'src/components/common/StudentHomeworkMenuGroup';
import { userInfoVar } from 'src/apollo/cache';
//
import { isPturn } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import ReportCardViewModalAfterSchool from 'src/components/Modal/reportcard/ReportCardViewModalAfterSchool';
const { Title } = Typography;

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
    width: '80px',
  },
  {
    title: '반명',
    dataIndex: 'class_name',
    key: 'class_name',
    align: 'center',
  },
  {
    title: '강사',
    dataIndex: 'teacher_name',
    key: 'teacher_name',
    align: 'center',
  },
  {
    title: '교재',
    dataIndex: 'book_title',
    key: 'book_title',
    align: 'center',
  },
  {
    title: '성적표 게시일',
    dataIndex: 'regdate',
    key: 'regdate',
    align: 'center',
  },
  {
    title: '성적표 보기',
    dataIndex: 'view',
    key: 'view',
    align: 'center',
    render: (_, { openReportCardView, reportCardItem }) => {
      return (
        <Button
          type="default"
          onClick={() => {
            openReportCardView(reportCardItem?.class_idx, reportCardItem?.user?.idx, reportCardItem?.user?.name, reportCardItem);
          }}
        >
          성적표 보기
        </Button>
      );
    },
  },
];

const ReportCardStudent = () => {
  const [visibleView, setVisibleView] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [studentIdx, setStudentIdx] = useState();
  const [selectedClassIdx, setSelectedClassIdx] = useState(0);
  const [reportCardItem, setReportCardItem] = useState();
  const [dateRange, setDateRange] = useState([undefined, undefined]);
  const user_idx = userInfoVar()?.idx;
  const [classBooks, setClassBooks] = useState([]);
  const [classBookCodes, setClassBookCodes] = useState([]);
  const { data, loading } = useQuery(STUDENT_REPORTCARD_LIST, { variables: { user_idx: parseInt(user_idx) }, skip: !user_idx });
  const companyName = useSelector(classStoreData);
  const openReportCardView = useCallback((class_idx, user_idx, student_name, report_card_item) => {
    setStudentIdx(user_idx);
    setStudentName(student_name);
    setReportCardItem(report_card_item);
    setSelectedClassIdx(class_idx);
    setDateRange([moment(), moment()]);
    setVisibleView(true);
    setClassBooks([{...report_card_item?.class?.book,book_code:report_card_item?.class?.book_code}])
  }, []);
  const dataReport = useMemo(() => {
    const reportList = data?.culpStudentReportCardList;
    if (reportList) {
      return reportList.map((item, key) => {
        const uniqueBooks = [...new Set(item?.class?.class_book.map((item) => item.book_code))];
        const booklist =uniqueBooks.length>0? uniqueBooks.map((code) => item?.class?.class_book.find((item) => item.book_code === code)):[{...item?.class?.book,book_code:item?.class?.book_code}]
        return {
          key: `report-list${item.idx}`,
          No: reportList.length - key,
          class_name: item?.class?.name,
          teacher_name: item?.class?.class_teacher[0]?.user?.name,
          book_title:
            uniqueBooks.length > 1
              ? `${booklist[0].book.book_sub[0].title} 외 ${booklist.length - 1}권`
              : item?.class?.book?.book_sub[0]?.title,
          regdate: item?.idate ? moment(item?.idate).format('YYYY.MM.DD') : '',
          classBooks: booklist,
          classBookCodes: uniqueBooks.length>1?Array.from({ length: uniqueBooks.length }, (_, i) => i ):[item?.class?.book_code],
          book_code:item?.class?.book_code,
          openReportCardView,
          reportCardItem: item,
        };
      });
    }
    return [];
  }, [data, openReportCardView]);
  useEffect(() => {
    if (dataReport[0]) {
      setClassBooks(dataReport[0].classBooks);
      setClassBookCodes(dataReport[0].classBookCodes);
    }
  }, [dataReport]);
  const aftercode = useMemo(() => {
    const tmp = [];
    for (let i = 0; i < classBooks.length; i++) {
      tmp.push(i);
    }
    return tmp;
  }, [classBooks]);
  return (
    <>
      <StudentHomeworkMenuGroup currentMenu="report-card" />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                Recport Card
              </Title>
            </div>

            <CustomTable
              className="classroom-table-table"
              loading={loading}
              dataSource={dataReport}
              columns={columns}
              pagination={{ pageSize: 12, position: ['bottomCenter'] }}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              color="#edf3fb"
            />
          </HalfWrapper>
        </Col>
      </Row>

      {isPturn(companyName) ? (
        <ReportCardViewModalAfterSchool
          visible={visibleView}
          onCancel={() => {
            setVisibleView(false);
            // setReportCardItem(undefined);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          reportCardItem={reportCardItem}
          classBookCodes={aftercode}
          classBooks={classBooks}
          book_codes={classBookCodes}
        />
      ) : (
        <ReportCardViewModal
          visible={visibleView}
          onCancel={() => {
            setVisibleView(false);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          reportCardItem={reportCardItem}
        />
      )}
    </>
  );
};

export default ReportCardStudent;
