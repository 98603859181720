import React, { useMemo, useState, useRef, useEffect } from "react";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as queries from 'src/operations/queries';
import { useQuery } from '@apollo/client';
import { Col, Typography, Row } from 'antd';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useHistory } from 'react-router-dom';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcelAnswerSheet } from 'src/utils/index';



const TestResultClassWise = () => {
    const { Title } = Typography;
    const printRef = useRef();
    const history = useHistory();
    const [finalfooterColumn, setFinalFooterColumn] = useState()
    const { idx, classIdx, testIdx } = useParams();
    const columns = [
        {
            title: 'No',
            dataIndex: 'No',
            key: 'No',
            width: 50,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 110,
            align: 'center',
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            align: 'center',
        },
        {
            title: 'Tries',
            dataIndex: 'tries',
            key: 'tries',
            width: 80,
            align: 'center',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            width: 120,
            align: 'center',
        },
    ];
    const { data, refetch } = useQuery(queries.getTests.DAILY_TEST_GROUP_RESULT_ClASS_WISE, {
        fetchPolicy: 'no-cache',
        variables: {
            assigned_group_idx: parseInt(idx),
            class_idx: parseInt(classIdx),
            test_idx: parseInt(testIdx)

        },
    });

    const fetchResult = useMemo(() => {
        if (data?.getTestResultClassWise) {
            const result = JSON.parse(data?.getTestResultClassWise?.data)
            const question = JSON.parse(data?.getTestResultClassWise?.testDetail);
            for (let i = 0; i < question.length; i++) {
                const columnsObj = {
                    title: `${i + 1}`,
                    dataIndex: `${i + 1}`,
                    key: `${i + 1}`,
                    width: 120,
                    align: 'center',
                    answer: question[i].answer,
                    i
                }
                columns.push(columnsObj)
            }
            return result?.reduce((acc, cur, index) => {
                const ans = JSON.parse(cur?.answer)
                const obj = {
                    idx: cur?.idx,
                    name: cur?.user?.name,
                    id: cur?.user?.id,
                    tries: 1,
                    score: cur?.score,
                    No: index + 1,
                    key: cur.idx,
                    ans: cur?.answer === null ? [] : JSON.parse(cur?.answer),
                    question: question,
                    user_idx: cur?.user_idx
                };
                return [...acc, obj];
            }, []);
        }
    }, [data])
    const handleResult = (userIdx) => {
        history.push(`/daily/test/result/${idx}/${classIdx}/${testIdx}/${userIdx}`)
    }
    function handleExportToExcel(e) {
        e.preventDefault();
        const fileName = `UserAnswer`;
        const childColumn = true;
        ExportExcelAnswerSheet(columns, fetchResult,childColumn, fileName);
    }
    return <>
        <HeaderTitle level={4}>Daily Test Class Answer Sheet</HeaderTitle>
        <Row gutter={[16, 16]} className="cust-daily-class-ans">
            <Col span={24}>
                <HalfWrapper className="assigned-syllabus-halfwrapper">
                    <div ref={printRef}>
                        <div className="cust-header-content"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Title level={4} style={{marginBottom : '0'}}>Answer Sheet</Title>
                            <a href="/" onClick={handleExportToExcel} className="excel-img"> 
                                <img src={imageexcel} alt="download" />
                            </a>
                        </div>
                        <table className="booktable-main-tbl test-result-main-tbl mytable_custome" style={{ marginBottom: 10 }}>
                            <tr>
                                {columns &&
                                    columns.map((ele, i) => {
                                        return <th key={`${i}`}>{ele.title}</th>;
                                    })}
                            </tr>
                            <tr>
                                {columns &&
                                    columns.map((ele, i) => {
                                        return <th key={`${i}`}>{ele.answer}</th>;
                                    })}
                            </tr>

                            {fetchResult &&
                                fetchResult.map((item, key) => (
                                    <tr key={`level-row-key${key}`}>
                                        <>
                                            <td>{item.No}</td>
                                            <td onClick={() => handleResult(item.user_idx)} className="test-pointer">{item.name}</td>
                                            <td>{item.id}</td>
                                            <td>{item.tries}</td>
                                            <td>{item.score === null ? "-" : item.score}</td>
                                            {item.ans.length > 0 ? item.ans.map((ele, i) => {
                                                return <td style={parseInt(item?.question[i].answer) === ele[`q${i + 1}`] ? { color: "#0f4732d9" } : { color: "#FF0000" }}>{ele[`q${i + 1}`] === null ? '-' : ele[`q${i + 1}`]}</td>
                                            }) : item.question?.map((ele) => {
                                                return <td>-</td>
                                            })}
                                        </>
                                    </tr>
                                ))}
                            <tr>
                                <td>Avg</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {columns?.map((item, index) => {
                                    if (
                                        item.title !== "No" &&
                                        item.title !== "Name" &&
                                        item.title !== "Id" &&
                                        item.title !== "Tries" &&
                                        item.title !== "Score"
                                    ) {
                                        let curLength;
                                        const total = fetchResult?.reduce((acc, current) => {
                                            curLength = fetchResult?.length
                                            let val;
                                            if (current?.ans[item.i]?.[`q${item.key}`] === undefined) {
                                                val = 0
                                            } else if (parseInt(item?.answer) === current?.ans[item.i]?.[`q${item.key}`]) {
                                                val = 1
                                            } else {
                                                val = 0
                                            }
                                            return Number(val) + acc;

                                        }, 0);
                                        return <td>{(total * 100) / curLength} %</td>
                                    }
                                })
                                }
                            </tr>
                        </table>
                    </div>
                </HalfWrapper>
            </Col>
        </Row>
    </>
}
export default TestResultClassWise;