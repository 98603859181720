import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {  AI_TOPIC_DELETE, DELETE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import TopicList from './topicList';
import CampusResults from './campusResults';
import StudentResults from './studentResults';
import AssignList from './assignList';

const AITopicList = () => {

  const { type = 'list' } = useParams();
//  const [editAITopicUserAssignment] = useMutation(EDIT_AI_TOPIC_USER_ASSIGNMENT);
  const [deleteAITopicUserAssignment] = useMutation(DELETE_AI_TOPIC_USER_ASSIGNMENT);
  const [aiTopicDelete] = useMutation(AI_TOPIC_DELETE);

  // const handleAITopicAssignEdit = async (data) => {
  //   try {
  //     await editAITopicUserAssignment({ variables: data });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleAITopicAssignGroupDelete = async (data) => {
    try {
      await deleteAITopicUserAssignment({ variables: data });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAITopicDelete = async (data) => {
    try {
      await aiTopicDelete({ variables: data });
    } catch (e) {
      console.log(e);
    }
  };

  const [user, setUser] = useState({});
  const token = getCurrentToken();
  const { data } = useQueryProxy(USER_ME, {
    variables: { token },
  });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);
  return (
    <>
      <Row gutter={[24, 16]}>
        {type === 'result' ? (
          user.type !== 0 ? (
            <CampusResults user={user} />
          ) : (
            <StudentResults user={user} />
          )
        ) : type === 'assign-list' ? (
          user.type !== 0 ? (
            <AssignList onAITopicAssignGroupDelete={handleAITopicAssignGroupDelete} user={user} />
          ) : null
        ): user.type !== 0 ? (
          <TopicList user={user} onAITopicDelete={handleAITopicDelete} />
        ) : null}
      </Row>
    </>
  );
};

export default AITopicList;
