import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  DASHBOARD_MANAGER_BOOK_LIST,
  DASHBOARD_CLASS_LESSON_LIST,
  DASHBOARD_MANAGER_TEST_LIST,
  DASHBOARD_MANAGER_ZOOM_LIST,
  DASHBOARD_MANAGER_EXTERNAL_STUDY_LIST,
  DASHBOARD_MANAGER_TEST_GROUP_LIST,
} from 'src/operations/queries/getClass';
import renderContent from 'src/components/Calendar/renderContent';
import { rootStateVar } from 'src/apollo/cache';
import { classStoreData } from 'src/operations/store';
import * as dummy from 'src/dummy';
import moment from 'moment';
import * as queries from 'src/operations/queries';

const CalendarBlock = styled.div`
  padding: 12px;
  background: #fff;
  //box-shadow: 2px 1px 5px 5px #dddddd;
  border-radius: 10px;
  min-height: calc(100vh - 18vh);
  h2.fc-toolbar-title {
    display: inline-block;
  }
  .fc-prev-button,
  .fc-next-button {
    background-color: #fff;
    color: #666;
    border: 0;
    font-weight: bold;
    font-size: 1.5em;
  }
  .fc-prev-button:hover,
  .fc-next-button:hover {
    background-color: #fff;
    color: #666;
    border: 0;
  }
`;

const CalendarArea = () => {
  console.log('TeacherCal');
  const defaultClass = useSelector(classStoreData);
  const calendarRef = useRef();
  const [events, setEvents] = useState([]);
  const [testEvents, setTestEvents] = useState([]);
  const [testGroupEvents, setTestGroupEvents] = useState([]);
  const [zoomEvents, setZoomEvents] = useState([]);
  const [OfflineEvents, setOfflineEvents] = useState([]);
  const [PhonicsEvents, setPhonicsEvents] = useState([]);
  const [bookEvents, setBookEvents] = useState([]);
  const [externalStudyEvents, setExternalStudyEvents] = useState([]);
  const [externalStudyEventsDateWise, setExternalStudyEventsDateWise] = useState([]);
  const [bookrEvents, setBookrEvents] = useState([]);
  const [aiTopics, setAiTopics] = useState([]);
  const [bookReportTopics, setBookReportTopics] = useState([]);
  const [levelupEvents, setLevelupEvents] = useState([]);
  const [aiTalkingTeacherTopics, setaiTalkingTeacherTopics] = useState([]);
  // const classColor = useMemo(
  //   () => ({
  //     ClassLesson: '#11783D',
  //     UserLesson: '#11783D',
  //     AssignedTests: '#ed5565',
  //     ZoomSchedule: '#3C79F6',
  //     OfflineLesson: '#4e9b4e',
  //     PhonicsLesson: '#ffce54',
  //     AssignedBooks: 'blue',
  //   }),
  //   [],
  // );

  const classColor = useMemo(() => (dummy.settings.settings[defaultClass] ? dummy.settings.settings[defaultClass].classColor : {}), []);
  const classType = useMemo(() => (dummy.settings.settings[defaultClass] ? dummy.settings.settings[defaultClass].classType : {}), []);
  const settings = useMemo(() => (dummy.settings.settings[defaultClass] ? dummy.settings.settings[defaultClass] : {}), []);

  const [getStudySchedule, { data }] = useLazyQuery(DASHBOARD_CLASS_LESSON_LIST, { fetchPolicy: 'no-cache' });
  const [getTestSchedule, { data: fetchTestData }] = useLazyQuery(DASHBOARD_MANAGER_TEST_LIST, { fetchPolicy: 'no-cache' });
  const [getTestGroupSchedule, { data: fetchTestGroupData }] = useLazyQuery(DASHBOARD_MANAGER_TEST_GROUP_LIST, { fetchPolicy: 'no-cache' });

  const [getZoomSchedule, { data: fetchZoomData }] = useLazyQuery(DASHBOARD_MANAGER_ZOOM_LIST, { fetchPolicy: 'no-cache' });

  const [getBookSchedule, { data: fetchBookData }] = useLazyQuery(DASHBOARD_MANAGER_BOOK_LIST, { fetchPolicy: 'no-cache' });

  const [getAiTalkingTeacherTopicList, { data: aiTalkingReportTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_TEACHER_AI_TALKING_REPORT_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  })

  /*const [getOfflineStudentSchedule, { data: fetchOfflineStudentData }] = useLazyQuery(GET_ALL_OFFLINE_SCHEDULE_STUDENT, {
    fetchPolicy: 'no-cache',
  });*/

  const [getOfflineSchedule, { data: fetchOfflineData }] = useLazyQuery(
    queries.offlinesyllabus.DASHBOARD_CLASS_OFFLINE_SYLLABUS_DATEWISE_LIST,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getPhonicsSchedule, { data: fetchPhonicsData }] = useLazyQuery(queries.phonicsUnitList.DASHBOARD_CLASS_PHONICS_DATEWISE_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getExternalStudySchedule, { data: fetchExternalStudyData }] = useLazyQuery(DASHBOARD_MANAGER_EXTERNAL_STUDY_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [getAiTopicList, { data: aiTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_TEACHER_AI_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [getBookReportTopicList, { data: bookReportTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_TEACHER_BOOK_REPORT_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (fetchBookData) {
      setBookEvents(
        fetchBookData.dashBoardBookList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_elibrary_cnt} : Bookr`,
            color: classColor['AssignedBooks'],
            type: 'AssignedBooks',
            textColor: 'AssignedBooks',
          };
        }),
      );
    }

    if (fetchExternalStudyData) {
      const newso = [];
      const bookr = [];
      const newso_assign = [];
      const levelup = []
      for (let item of fetchExternalStudyData?.dashBoardExternalStudyList) {
        if (item.type === 'newsomatic') {
          newso.push(item);
        } else if (item.type === 'bookr') {
          bookr.push(item);
        } else if (item.type === 'newsomatic-assign') {
          newso_assign.push(item);
        } else if (item.type === 'levelup') {
          levelup.push(item);
        }
      }
      const levelupAccordingToDate = levelup.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly };
        }
        return acc;
      }, {});
      setLevelupEvents(
        Object.values(levelupAccordingToDate).map((item, i) => {
          return {
            key: `LevelUp-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Level-Up`,
            color: classColor['LevelUp'],
            type: 'LevelUp',
            textColor: 'LevelUp',
          };
        }),
      );
      const newsoAccordingToDate = newso.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly };
        }
        return acc;
      }, {});
      setExternalStudyEvents(
        Object.values(newsoAccordingToDate).map((item, i) => {
          return {
            key: `external-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Newsomatic`,
            color: classColor['ExternalStudy'],
            type: 'ExternalStudy',
            textColor: 'ExternalStudy',
          };
        }),
      );
      const newsoAccordingToDate_assign = newso_assign.reduce((acc, item) => {
        const dateOnly_assign = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly_assign]) {
          acc[dateOnly_assign]['count'] += 1;
        } else {
          acc[dateOnly_assign] = { count: 1, date: dateOnly_assign };
        }
        return acc;
      }, {});
      setExternalStudyEventsDateWise(
        Object.values(newsoAccordingToDate_assign).map((item, i) => {
          return {
            key: `external-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Newsomatic`,
            color: classColor['News_O_Matic'],
            type: 'ExternalStudyForStudent',
            textColor: 'ExternalStudyForStudent',
          };
        }),
      );
      const bookrAccordingToDate = bookr.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly };
        }
        return acc;
      }, {});
      setBookrEvents(
        Object.values(bookrAccordingToDate).map((item, i) => {
          return {
            key: `bookr-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Bookr`,
            color: classColor['Bookr'],
            type: 'Bookr',
            textColor: 'Bookr',
          };
        }),
      );
    }

    if (fetchPhonicsData !== undefined && fetchPhonicsData !== null) {
      setPhonicsEvents(
        fetchPhonicsData.CamppusAssignedPhonicsDailySchedue.map((item, i) => {
          if (item.phonics_assign_date !== undefined) {
            return {
              key: i,
              date: moment(new Date(item.phonics_assign_date.split('T')[0])).format(),
              color: classColor['PhonicsLesson'],
              title: `${item?.count} : Phonics`,
              type: 'PhonicsLesson',
              textColor: 'PhonicsLesson',
            };
          }
        }),
      );
    }

    if (data) {
      setEvents(
        data.dashBoardClassLessonList.map((item, i) => {
          return {
            key: i,
            date: item.study_date,
            title: `${item?.lesson_cnt} : Homework`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    if (fetchTestData) {
      setTestEvents(
        fetchTestData.dashBoardTestList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_test_cnt} : Test`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }
    if (fetchTestGroupData) {
      setTestGroupEvents(
        fetchTestGroupData.dashBoardTestGroupList.map((item, i) => {
          console.log('item', item, classColor[`${item.__typename}`]);
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_test_cnt} : Test Group`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }
    if (fetchZoomData) {
      setZoomEvents(
        fetchZoomData.dashBoardZoomList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_zoom_cnt} : Zoom`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    // if (fetchOfflineData !== undefined) {
    //   setOfflineEvents(
    //     fetchOfflineData.OfflineAssignedDailySchedue.map((item, i) => {
    //       if (item.offline_assign_date !== undefined) {
    //         return {
    //           key: i,
    //           date: item.offline_assign_date,
    //           color: classColor['OfflineLesson'],
    //           title: `${item?.count} : Offline`,
    //           type: 'OfflineLesson',
    //           textColor: 'OfflineLesson',
    //         };
    //       }
    //     }),
    //   );
    // }
    // }, [classColor, data, fetchTestData,fetchTestGroupData,fetchZoomData, fetchOfflineData, fetchPhonicsData, fetchBookData, fetchExternalStudyData]);
  }, [classColor, data, fetchTestData, fetchTestGroupData, fetchZoomData, fetchPhonicsData, fetchBookData, fetchExternalStudyData]);

  useEffect(() => {
    if (aiTalkingReportTopicsData) {
      setaiTalkingTeacherTopics(
        aiTalkingReportTopicsData.dashBoardAiTalkingTopicList
          .filter((item) => item.ai_topic_cnt)
          .map((item, i) => {
            console.log("item", item);
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.ai_topic_cnt} : Speaking tutor`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor['AI_Talking'],
            };
          }),
      );
    }
  }, [aiTalkingReportTopicsData]);
  useEffect(() => {
    if (aiTopicsData) {
      setAiTopics(
        aiTopicsData.dashBoardAiTopicList
          .filter((item) => item.ai_topic_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.ai_topic_cnt} : AI Topic`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor['AI_Topics'],
            };
          }),
      );
    }
  }, [aiTopicsData]);

  useEffect(() => {
    if (bookReportTopicsData) {
      setBookReportTopics(
        bookReportTopicsData.dashBoardBookReportTopicList
          .filter((item) => item.ai_topic_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.ai_topic_cnt} : Book Report`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor['Book_Report'],
            };
          }),
      );
    }
  }, [bookReportTopicsData]);

  function handleCalendarClick({ startStr }) {
    rootStateVar({
      ...rootStateVar(),
      calendar: {
        ...rootStateVar().calendar,
        selectDay: startStr,
      },
    });
  }

  function handleEventClick({ event }) {
    const selectDay = moment(event.start).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }
  function handleDateClick(e) {
    const selectDay = moment(e.date).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  function handleRenderContent(event) {
    renderContent(event);
  }
  return (
    <CalendarBlock>
      <FullCalendar
        ref={calendarRef}
        //selectable={true}
        datesSet={({ start, end }) => {
          rootStateVar({
            ...rootStateVar(),
            calendar: {
              start: `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
              end: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
            },
          });
          console.log('rootStateVar', rootStateVar());
          getStudySchedule({
            variables: {
              start,
              end,
            },
          });

          getTestSchedule({
            variables: {
              start,
              end,
            },
          });
          getTestGroupSchedule({
            variables: {
              start,
              end,
            },
          });
          getZoomSchedule({
            variables: {
              start,
              end,
            },
          });

          getOfflineSchedule({
            variables: {
              start,
              end,
              input: 'offlinedates',
            },
          });

          getPhonicsSchedule({
            variables: {
              start,
              end,
              input: 'phonicsdates',
            },
          });

          getBookSchedule({
            variables: {
              start,
              end,
            },
          });

          getExternalStudySchedule({
            variables: {
              start,
              end,
            },
          });

          getAiTopicList({
            variables: {
              start,
              end,
            },
          });

          getBookReportTopicList({
            variables: {
              start: start,
              end: end,
            },
          });
          getAiTalkingTeacherTopicList({
            variables: {
              start: start,
              end: end,
            },
          });
          /*getOfflineStudentSchedule({
            variables: {
              start,
              end,
            },
          });*/
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        height={'calc(100vh - 25vh)'}
        initialView="dayGridMonth"
        events={[
          ...events,
          ...testEvents,
          ...zoomEvents,
          ...bookEvents,
          ...OfflineEvents,
          ...PhonicsEvents,
          ...externalStudyEvents,
          ...externalStudyEventsDateWise,
          ...bookrEvents,
          ...aiTopics,
          ...bookReportTopics,
          ...testGroupEvents,
          ...levelupEvents,
          ...aiTalkingTeacherTopics
        ]}
        eventContent={(eventInfo, i) => {
          return (
            <>
              <div
                style={{
                  backgroundColor: eventInfo.backgroundColor,
                  color: 'white',
                  borderRadius: 10,
                  width: 25,
                  height: 18,
                  textAlign: 'center',
                  marginRight: 5,
                }}
                className={`iconcalander ${settings.eventsIcon[eventInfo.textColor]}`}
              >
                {settings.calendarEventsIn ? classType[eventInfo.textColor] : eventInfo.event.title}
              </div>
              <div className={`iconcommontext ${settings.eventsIcon[eventInfo.textColor]}`}>
                {' '}
                {!settings.calendarEventsIn ? classType[eventInfo.textColor] : eventInfo.event.title}
              </div>
            </>
          );
        }}
        select={handleCalendarClick}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        //locale={'ko-KR'}
        longPressDelay={0}
        eventLongPressDelay={0}
        selectLongPressDelay={0}
        headerToolbar={{
          start: '',
          center: 'prev,title,next',
          end: 'todayButton',
        }}
        customButtons={{
          todayButton: {
            text: 'today',
            click: () => {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.today();
              calendarApi.select(moment(new Date()).format('yyyy-MM-DD'));
            },
          },
        }}
      />
      {defaultClass === 'lucid' ? (
        <div>
          <div className="dasboard-list">
            <span style={{ backgroundColor: '#FF0000', borderRadius: '10px' }}>H</span>
            <span style={{ backgroundColor: '#FF9900', borderRadius: '10px' }}>L</span>
            <span style={{ backgroundColor: '#000066', borderRadius: '10px' }}>T</span>
            <span style={{ backgroundColor: '#7030A0', borderRadius: '10px' }}>O</span>
            <span style={{ backgroundColor: '#6e93c1', borderRadius: '10px' }}>E</span>
          </div>
        </div>
      ) : (
        ''
      )}
    </CalendarBlock>
  );
};

export default CalendarArea;
