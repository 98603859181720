import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Input, Row, Modal } from 'antd';
import XLSX from 'xlsx';
import { useMutation } from '@apollo/client';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import ScriboSubscriptionModal from 'src/components/Modal/ScriboSubscriptionModal';
import ScriboSubscriptionEditModal from 'src/components/Modal/ScriboSubscriptionEditModal';
import ScriboSubscriptionDeleteModal from 'src/components/Modal/ScriboSubscriptionDeleteModal';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import { CREATE_SCRIBO_SUBSCRIPTION_LIST } from 'src/operations/mutations/scribo';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { openNotification } from '../../components/common/Notification';

const ScriboSubscriptionManagement = () => {
  const [fileSelected, setFileSelected] = useState(false);
  const [campusOptions, setCampusOptions] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [selectedScriboSubscription, setSelectedScriboSubscription] = useState(null);
  const { data: getAllCampusCodeData } = useQuery(queries.campus.GET_ALL_CAMPUS_CODE_LIST);
  const { loading: loadingSubscriptionList, data: scriboSubscriptionResponse } = useQuery(queries.scribo.FETCH_SCRIBO_SUBSCRIPTION_LIST);

  useEffect(() => {
    if (getAllCampusCodeData?.getAllCampusCodeList) {
      let optionsGroup = {};

      getAllCampusCodeData.getAllCampusCodeList.forEach((item) => {
        if (optionsGroup[item.company?.name]) {
          optionsGroup[item.company?.name].push({
            label: `${item.name} (${item.code ? item.code : item.idx})`,
            value: item.idx,
          });
        } else {
          optionsGroup[item.company?.name] = [
            {
              label: item.name,
              value: item.idx,
            },
          ];
        }
      });

      setCampusOptions(
        Object.keys(optionsGroup).map((item) => {
          return {
            label: item,
            options: optionsGroup[item],
          };
        }),
      );
    }
  }, [getAllCampusCodeData, setCampusOptions]);

  const scriboSubscriptionData = useMemo(() => {
    if (scriboSubscriptionResponse?.scriboSubscriptionList) {
      return scriboSubscriptionResponse?.scriboSubscriptionList.map((item, i) => {
        let status = 0;
        if (new Date(item.license_start_date) < new Date(Date.now())) {
          status = 1;
          if (new Date(new Date(item.license_start_date).getTime() + 365 * 24 * 60 * 60 * 1000) < new Date(Date.now())) {
            status = 2;
          }
        }
        return {
          no: 1 + i,
          key: item.idx,
          email: item.email,
          licenseStartDate: format(new Date(item.license_start_date), 'yy-MM-dd HH:mm'),
          campusName: item.campus?.name ? item.campus.name : '',
          assignedUserName: item.user?.name ? item.user.name : '',
          subscriptionStartDate: item.assigned_date ? format(new Date(item.assigned_date), 'yy-MM-dd HH:mm') : '',
          assignedSubscriptionDays: item.number_of_days ? item.number_of_days : '',
          status: status,
        };
      });
    }
    return [];
  }, [scriboSubscriptionResponse]);

  const [saveScriboSubscriptions, { loading: loadingSave }] = useMutation(CREATE_SCRIBO_SUBSCRIPTION_LIST, {
    refetchQueries: [
      {
        query: queries.scribo.FETCH_SCRIBO_SUBSCRIPTION_LIST,
      },
    ],
    onCompleted(data) {
      if (data) {
        openNotification('Scribo 이용이 추가되었습니다!');
        setFileInputKey((prev) => prev + 1);
        setFileSelected(false);
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];
      Modal.error({
        title: `${message}`,
      });
    },
  });

  const handleFileSelect = (data) => {
    setFileSelected(true);
    const data2 = data?.map((item, key) => {
      return {
        key: key,
        No: key + 1,
        email: String(item.email || ''),
        license_start_date: item.license_start_date || '',
        campus_idx: String(item.campus_idx || ''),
      };
    });
    setFileData(data2);
  };

  const handleSaveScriboSubscriptions = () => {
    Modal.confirm({
      title: `Scribo 이용을 저장하시겠습니까?`,
      onOk: () => {
        const scribo_subscription_list = fileData.map((item2) => {
          return {
            email: item2.email,
            license_start_date: item2.license_start_date,
            campus_idx: item2.campus_idx,
          };
        });
        saveScriboSubscriptions({ variables: { scribo_subscription_list } });
      },
    });
  };

  const handleEditClick = ({ key }) => {
    setSelectedScriboSubscription(scriboSubscriptionResponse?.scriboSubscriptionList.filter((item) => item.idx === key)[0]);
    ScriboSubscriptionEditShowModal();
  };

  const handleDeleteClick = ({ key }) => {
    setSelectedScriboSubscription(scriboSubscriptionResponse?.scriboSubscriptionList.filter((item) => item.idx === key)[0]);
    ScriboSubscriptionDeleteShowModal();
  };

  const getColumns = () => {
    return [
      {
        key: 'no',
        title: 'No',
        dataIndex: 'no',
        width: 60,
        align: 'center',
      },
      {
        key: 'email',
        title: '이메일',
        dataIndex: 'email',
        align: 'center',
        width: 170,
      },
      {
        key: 'licenseStartDate',
        title: '라이센스 시작 날짜',
        dataIndex: 'licenseStartDate',
        align: 'center',
      },
      {
        key: 'campusName',
        title: '학원',
        dataIndex: 'campusName',
        align: 'center',
      },
      {
        key: 'status',
        title: '현황',
        dataIndex: 'status',
        align: 'center',
        render: (text) => {
          return text === 2 ? 'Expired' : text === 1 ? 'Active' : 'Not Started';
        },
      },
      {
        key: 'assignedUserName',
        title: '배정된 사용자',
        dataIndex: 'assignedUserName',
        align: 'center',
      },
      {
        key: 'subscriptionStartDate',
        title: '이용 시작 날짜',
        dataIndex: 'subscriptionStartDate',
        align: 'center',
      },
      {
        key: 'assignedSubscriptionDays',
        title: '배정된 이용 기간',
        dataIndex: 'assignedSubscriptionDays',
        align: 'center',
      },
      {
        key: 'edit',
        title: '수정하기',
        dataIndex: 'edit',
        align: 'center',
        render: (_, row) => {
          return (
            <Button
              onClick={() => {
                handleEditClick(row);
              }}
            >
              수정하기
            </Button>
          );
        },
      },
      {
        key: 'delete',
        title: '삭제하기',
        dataIndex: 'delete',
        align: 'center',
        render: (_, row) => {
          return (
            <Button
              type="danger"
              onClick={() => {
                handleDeleteClick(row);
              }}
            >
              삭제하기
            </Button>
          );
        },
      },
    ];
  };

  const {
    visible: ScriboSubscriptionVisible,
    showModal: ScriboSubscriptionShowModal,
    handleCancel: handleScriboSubscriptionCancel,
  } = useModal();
  const {
    visible: ScriboSubscriptionEditVisible,
    showModal: ScriboSubscriptionEditShowModal,
    handleCancel: handleScriboSubscriptionEditCancel,
  } = useModal();
  const {
    visible: ScriboSubscriptionDeleteVisible,
    showModal: ScriboSubscriptionDeleteShowModal,
    handleCancel: handleScriboSubscriptionDeleteCancel,
  } = useModal();

  return (
    <>
      <HeaderTitle level={4}>Scribo 이용 관리</HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY: 'auto' }}>
            <Row gutter={[16, 16]} style={{ padding: 5 }}>
              <Col span={6}>
                <ExcelUpload
                  onFileSelect={handleFileSelect}
                  scriboSubscriptionList={scriboSubscriptionResponse?.scriboSubscriptionList}
                  fileInputKey={fileInputKey}
                  setFileInputKey={setFileInputKey}
                />
              </Col>
              <Col span={6}>
                <Button
                  style={{ height: '100%' }}
                  type="primary"
                  onClick={handleSaveScriboSubscriptions}
                  loading={loadingSave}
                  disabled={!fileSelected}
                >
                  저장
                </Button>
              </Col>
            </Row>
            <Button style={{ color: '#289428', float: 'right', marginRight: '15px' }} onClick={ScriboSubscriptionShowModal}>
              추가하다 +
            </Button>
            <CustomTable
              loading={loadingSubscriptionList}
              className="classroom-table-table"
              dataSource={scriboSubscriptionData}
              columns={getColumns()}
              key={(r) => r.idx}
              scroll={{ y: 'calc(100vh - 254px)' }}
              pagination={false}
              size="small"
              color="#edf3fb"
            />
          </HalfWrapper>
        </Col>
      </Row>
      {ScriboSubscriptionVisible && (
        <ScriboSubscriptionModal
          visible={ScriboSubscriptionVisible}
          handleCancel={handleScriboSubscriptionCancel}
          campusOptions={campusOptions}
        />
      )}
      {ScriboSubscriptionEditVisible && (
        <ScriboSubscriptionEditModal
          visible={ScriboSubscriptionEditVisible}
          handleCancel={handleScriboSubscriptionEditCancel}
          selectedScriboSubscription={selectedScriboSubscription}
          ScriboSubscriptionEditShowModal={ScriboSubscriptionEditShowModal}
          campusOptions={campusOptions}
        />
      )}
      {ScriboSubscriptionDeleteVisible && (
        <ScriboSubscriptionDeleteModal
          visible={ScriboSubscriptionDeleteVisible}
          handleCancel={handleScriboSubscriptionDeleteCancel}
          selectedScriboSubscription={selectedScriboSubscription}
          ScriboSubscriptionDeleteShowModal={ScriboSubscriptionDeleteShowModal}
        />
      )}
    </>
  );
};

export default ScriboSubscriptionManagement;

const ExcelUpload = ({ onFileSelect, scriboSubscriptionList, fileInputKey, setFileInputKey }) => {
  const [scriboSubscriptions, setScriboSubscriptions] = useState([]);
  useEffect(() => {
    if (scriboSubscriptions?.length > 0) {
      const scriboSubscriptionMailList = scriboSubscriptionList?.map((item2) => item2.email?.toLowerCase());
      const newScriboSubscriptions = scriboSubscriptions.filter((item) => {
        return !scriboSubscriptionMailList.includes(item.email?.toLowerCase());
      });
      if (newScriboSubscriptions.length === 0) {
        Modal.info({
          title: <>새로운 기록이 발견되지 않습니다.</>,
          onOk: () => {
            setFileInputKey((prev) => prev + 1);
            return;
          },
        });
        return;
      }
      onFileSelect(newScriboSubscriptions);
    }
    // eslint-disable-next-line
  }, [scriboSubscriptions]);

  const props = {
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: (info) => {
      const file = info.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          //https://stackoverflow.com/questions/53163552/format-date-with-sheetjs
          const ab = e.target.result;
          const wb = XLSX.read(ab, { type: 'binary', cellText: false, cellDates: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: ['number', 'email', 'license_start_date', 'campus_idx'],
            raw: false,
            dateNF: 'yyyy-mm-dd',
          });
          if (data && data instanceof Array) {
            const filtered_data = data.slice(2);
            setScriboSubscriptions(filtered_data);
          } else {
            alert('오류가 발생했습니다.');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setScriboSubscriptions([]);
      }
    },
  };
  return <Input key={`file-input-${fileInputKey}`} type="file" {...props} name="files" />;
};
