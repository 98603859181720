import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Checkbox, Button, Tooltip, Input, Modal, Select, Form, DatePicker, List, Row, Col } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
import { openNotification } from 'src/components/common/Notification';
import TalkingTutorMenuGroup from 'src/components/common/TalkingTutorMenuGroup';
import AdminTalkingTutorMenu from 'src/components/common/AdminTalkingTutorMenu';
import { CLASS_STUDENT_LIST, GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { ASSIGN_AI_TOPIC_TO_STUDENTS, DELETE_AI_GROUP } from 'src/operations/mutations/aiTopic';
import { AI_Talking_TOPICS_LIST, GET_CONTENT_LIST } from 'src/operations/queries/aiGroupTopic';
import { LoginOutlined } from '@ant-design/icons';

const { Option } = Select;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const ColumnFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const AssignModal = styled(Modal)`
  .ant-modal-header {
    background-color: #deeaf6;
    border-radius: 20px;
  }
  .ant-modal-content {
    border: 3px solid #fff2cc;
    border-radius: 20px;
    background-color: #deeaf6;
  }
  width: 860px;
  min-width: 860px;
  @media (max-width: 900px) {
    min-width: 750px;
    width: 750px;
  }
`;
const ModalLabel = styled.div`
  color: #374a5d;
  font-weight: bold;
  padding-right: 10px;
`;

const AiTalkingTutor = () => {
  //
  const history = useHistory();
  const pagePassword = 'soham';
  const [fileData, setFileData] = useState([]);
  const [books, setBooks] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [topicType, setTopicType] = useState('ai-talking-tutor');
  const [classes, setClasses] = useState([]);
  //
  const [level, setLevel] = useState(-1);
  const [searchValue, setSeachValue] = useState('');
  const [folderType, setFolderType] = useState(userInfoVar()?.type === 5 ? 'gen content' : 'publisher');
  // const [folderType, setFolderType] = useState(
  //   userInfoVar()?.type === 5 ? 'gen content' : userInfoVar()?.type === 2 ? 'campus' : userInfoVar()?.type === 4 ? 'publisher' : 'me',
  // );
  const [contentIdx, setContentIdx] = useState(null);
  const [assignBook, setAssignBook] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [showModal, setShowModal] = useState({ add: false, edit: false });
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [userPassword, setUserPassword] = useState('');
  const [editData, setEditData] = useState('');
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [ch, setCh] = useState(
    books.map((e) => {
      const obj = {
        idx: e.idx,
        value: false,
      };
      return obj;
    }),
  );
  useEffect(() => {
    if (level !== -1) {
      setPg({ current: 0, pageSize: 10 });
    }
    setPg({ ...pg, current: 0 });
  }, [folderType, topicType, level]);

  const [deleteAITopicGroup] = useMutation(DELETE_AI_GROUP);

  const { data: getContentList } = useQuery(GET_CONTENT_LIST, { variables: { type: 'ai-talking-tutor' } });

  const { data, refetch } = useQuery(AI_Talking_TOPICS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      folderType: topicType === 'levelup' ? 'NA' : folderType,
      type: topicType,
      take: pg.pageSize,
      page: pg.current,
      q: searchValue,
      // level: level !== -1 ? `"level": ${level},` : null,
      level: level !== -1 ? `${level}` : null,
      content_idx: contentIdx,
      // class_idx: 0
    },
  });
  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (showModal?.add || showModal?.edit) {
      const input = document.querySelector('.password-modal input');
      if (input) {
        input.focus();
      }
    }
  }, [showModal]);

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
  }, [classQuery?.data?.classList]);

  useEffect(() => {
    if (data?.aiTalkingGroupList) {
      // const uniqueTopicsSet = new Set();
      setBooks(
        data?.aiTalkingGroupList.aiTopicsGroup.map((item, key) => {
          const assistantData = JSON.parse(item.assistants_data || '{}');
          // let topicData = JSON.parse(item.data || '{}');
          // let topicGroupDetail = JSON.parse(item.group_detail || '{}');
          let apiVersion = assistantData?.metadata?.api_version || 'assistant-v2';
          return {
            ...item,
            item_no: key + 1,
            idx: item?.idx,
            group_idx: item?.idx,
            group_name: item?.group_name,
            group_description: assistantData?.description || '',
            lesson_count: item?.ai_topic?.length,
            level: item?.level,
            gpt_model: assistantData?.model,
            apiVersion,
            assistantData,
            total: data?.aiTalkingGroupList.total,
          };
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    if (getContentList?.getContentList?.length > 0) {
      setContentList(
        getContentList?.getContentList?.map((item, key) => {
          return {
            ...item,
            item_no: key + 1,
            idx: item?.idx,
            content_name: item?.content_name,
          };
        }),
      );
    }
  }, [getContentList]);

  useEffect(() => {
    setCh(
      books.map((e) => {
        const obj = {
          idx: e.idx,
          value: false,
        };
        return obj;
      }),
    );
  }, [books]);
  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  function onChange(e, idx) {
    ch[ch.findIndex((element) => element.idx === idx)] = { idx: idx, value: e.target.checked };
    setCh(JSON.parse(JSON.stringify(ch)));
  }
  const currentPage = (i) => {
    return Math.floor(i / pg.pageSize) === pg.current - 1;
  };
  function onCheckAllChange(e) {
    const arr2 = _.cloneDeep(ch);
    if (e.target.checked) {
      const checked = arr2.map((e) => {
        const obj = {
          idx: e.idx,
          value: true,
        };
        return obj;
      });
      setCh(checked);
    } else {
      setCh(
        arr2.map((e) => {
          const obj = {
            idx: e.idx,
            value: false,
          };
          return obj;
        }),
      );
    }
  }
  if (loading) {
    return <div className="wait-comment">로딩중...</div>;
  }
  if (error) {
    return <div className="wait-comment">에러가 발생했습니다.</div>;
  }
  const handleAITopicEdit = (data) => {
    setShowModal((prevState) => ({
      ...prevState,
      edit: true,
    }));
    setEditData(data);
    // history.push("/ai-talking-tutor/13625/135");
  };
  const handleAssign = (groupIdx) => {
    history.push('/ai-talking-tutor/topiclist/assign/' + groupIdx);
  };
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 50,
      render: (text, record, idx) => {
        return <div>{record.total - (pg.current * pg.pageSize + idx)}</div>;
      },
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      align: 'center',
      width: userInfoVar()?.type === 5 ? 300 : 200,
      sorter: (a, b) => a.group_name && a.group_name.localeCompare(b.group_name),
      render: (text) => <span style={{ color: 'blue', fontWeight: 'bold' }}>{text}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'group_description',
      key: 'group_description',
      align: 'center',
      width: userInfoVar()?.type === 5 ? 300 : 200,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" color="#fff" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'Lessons',
      dataIndex: 'lesson_count',
      key: 'lesson_count',
      align: 'center',
      width: 50,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      width: 100,
    },
    {
      title: 'Action',
      dataIndex: 'assign',
      key: 'assign',
      align: 'center',
      width: 100,
      render: (text, record) => {
        let canEditOrDelete;
        if (record?.apiVersion === 'assistant-v2' && userInfoVar()?.type == 2 && (folderType == 'campus' || folderType == 'me')) {
          canEditOrDelete = true;
        } else if (record?.apiVersion === 'assistant-v2' && userInfoVar()?.type == 5 && folderType == 'gen content') {
          canEditOrDelete = true;
        } else if (record?.apiVersion === 'assistant-v2' && userInfoVar()?.type == 1 && folderType == 'me') {
          canEditOrDelete = true;
        } else if (record?.apiVersion === 'assistant-v2' && userInfoVar()?.type == 4 && folderType == 'publisher') {
          canEditOrDelete = true;
        } else {
          canEditOrDelete = false;
        }

        const buttonStyle = canEditOrDelete ? { cursor: 'pointer' } : { opacity: '0.5', cursor: 'not-allowed' };
        return (
          <div className="table-btns">
            <>
              {userInfoVar()?.type <= 3 && (
                <Button size="small" className="ant-btn-primary" onClick={() => handleAssign(record?.group_idx)}>
                  Assign
                </Button>
              )}
              <Button style={buttonStyle} size="small" onClick={canEditOrDelete ? () => handleAITopicEdit(record) : undefined}>
                Edit
              </Button>
              <Button
                size="small"
                style={buttonStyle}
                onClick={canEditOrDelete ? () => setShowDeleteModal(record) : undefined}
                className="delet-btn"
              >
                Delete
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  const handleYes = async () => {
    if (showDeleteModal?.idx) {
      await deleteAITopicGroup({
        variables: { idx: showDeleteModal?.idx },
      }).then((result) => {
        setShowDeleteModal(null);
        if (result?.error) {
          setErrorMsg(result?.error?.deleteAITopicGroup?.message);
        } else {
          refetch();
          openNotification('귀하의 기록이 성공적으로 삭제되었습니다!');
        }
      });
    }
  };
  const handleAssignData = () => {
    let data = [];
    books.forEach((item) => {
      ch?.forEach((checked) => {
        if (item.idx === checked.idx && checked.value) {
          data.push(item);
        }
      });
    });
    if (data?.length > 0) {
      localStorage.setItem('selectedPrompts', JSON.stringify(data));

      history.push(`/ aicoach / topiclist / assign / `);
    } else {
      alert('Select the topic/prompts!');
    }
  };
  const handleClick = async () => {
    setShowModal((prevState) => ({
      ...prevState,
      add: true,
    }));
    // history.push('/ai-talking-tutor/add');
  };
  const handleSave = async () => {
    if (pagePassword == userPassword) {
      setShowError(false);
      if (showModal?.add) {
        history.push('/ai-talking-tutor/add');
      } else {
        history.push(`/ai-talking-tutor/edit/${editData?.group_idx}`);
      }
    } else {
      setShowError(true);
    }
  };
  return (
    <>
      {userInfoVar()?.type < 5 ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10, paddingBottom: 10, alignItems: 'flex-end' }}>
          {userInfoVar()?.type <= 3 ? <TalkingTutorMenuGroup currentMenu="assign" /> : <div></div>}
          <div>
            <Button style={{ marginRight: 10 }} onClick={handleClick}>
              Add Content
            </Button>
          </div>
        </div>
      ) : userInfoVar()?.type === 5 ? (
        <div className="speaking-tutor-wrap">
          <AdminTalkingTutorMenu currentMenu="group_list" />
          <div style={{ display: 'flex', alignItems: 'baseline', gap: '16px' }}>
            <Select
              style={{ width: 150, color: '#065aa9' }}
              defaultValue={contentIdx}
              placeholder="폴더 유형 선택"
              optionFilterProp="children"
              onChange={(val) => setContentIdx(val)}
            >
              {contentList?.map((ele) => {
                return (
                  <>
                    <Select.Option value={ele?.idx}>{ele?.content_name}</Select.Option>
                  </>
                );
              })}
            </Select>
            <Input.Search
              className="inputsearch-classroom"
              placeholder="prompt search"
              allowClear
              style={{ width: 250 }}
              enterButton
              onChange={onSeachChange}
              value={searchValue}
            />
            <Button style={{ marginRight: 10, marginTop: '10px' }} onClick={handleClick}>
              Add Content
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: 10,
            paddingBottom: 0,
            marginLeft: 15,
            flexWrap: 'wrap',
            gap: '8px',
          }}
        ></div>
      )}
      <div className="level-up-library-con">
        <div className="main-inner-wrap float__box">
          <div className="section__inner">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=" float__box">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>
                  AI Speaking Coach : <span style={{ color: '#00B0F0' }}>Assign</span>
                </h2>
              </div>
              {userInfoVar()?.type !== 5 ? (
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: ' wrap', gap: '8px' }}>
                  <Select style={{ width: 150, color: '#065aa9' }} value={level} onChange={(val) => setLevel(val)}>
                    {[-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((e) => (
                      <Select.Option value={e}>{e === 0 ? 'PH' : e === -1 ? 'All level' : `mx ${e}`}</Select.Option>
                    ))}
                  </Select>
                  <Select
                    style={{ width: 150, color: '#065aa9' }}
                    defaultValue={folderType}
                    placeholder="폴더 유형 선택"
                    optionFilterProp="children"
                    onChange={(val) => {
                      setFolderType(val);
                      if (val === 'gen content') {
                        setContentIdx(5); // for now
                      } else {
                        setContentIdx(null);
                      }
                    }}
                  >
                    {userInfoVar()?.type === 4 ? (
                      <>
                        <Select.Option value="gen content">Gen. Content</Select.Option>
                        <Select.Option value="publisher">Company</Select.Option>
                      </>
                    ) : userInfoVar()?.type === 2 || userInfoVar()?.type === 1 ? (
                      <>
                        <Select.Option value="gen content">Gen. Content</Select.Option>
                        <Select.Option value="publisher">Company</Select.Option>
                        <Select.Option value="campus">Campus</Select.Option>
                        <Select.Option value="me">Personal</Select.Option>
                      </>
                    ) : (
                      <></>
                    )}
                  </Select>
                  {folderType === 'gen content' ? (
                    <Select
                      style={{ width: 150, color: '#065aa9' }}
                      defaultValue={contentIdx}
                      placeholder="All contents"
                      optionFilterProp="children"
                      onChange={(val) => setContentIdx(val)}
                    >
                      {contentList?.map((ele) => {
                        return (
                          <>
                            <Select.Option value={ele?.idx}>{ele?.content_name}</Select.Option>
                          </>
                        );
                      })}
                      <Select.Option value={null}>All contents</Select.Option>
                    </Select>
                  ) : (
                    <></>
                  )}
                  <Input.Search
                    className="inputsearch-classroom"
                    placeholder="Search"
                    allowClear
                    style={{ width: 250 }}
                    enterButton
                    onChange={onSeachChange}
                    value={searchValue}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <CustomTable
              style={{ width: '100%' }}
              pagination={{
                showSizeChanger: true,
                pageSize: pg.pageSize,
                current: pg.current + 1,
                total: data?.aiTalkingGroupList?.total || 0,
                position: ['bottomCenter'],
              }}
              dataSource={books}
              columns={columns}
              loading={loading}
              bordered
              scroll={{ y: 'calc(100vh - 274px)' }}
              onChange={(pagination) => {
                setPg({
                  current: pagination.current - 1,
                  pageSize: pagination.pageSize,
                });
              }}
              size="small"
              color="#edf3fb"
            />
          </div>
        </div>
      </div>
      <AssignModal
        title={<div style={{ fontSize: '20px', color: '#23394b' }}>Writing Coach Assign Page</div>}
        visible={assignModal}
        onCancel={() => {
          setAssignModal(false);
        }}
        footer={null}
        // width={900}
      >
        <AssignReport book={assignBook} classes={classes} close={() => setAssignModal(false)} />
      </AssignModal>

      {(showModal?.add || showModal?.edit) && (
        <Modal
          className="instruction-modal password-modal"
          visible={showModal?.add || showModal?.edit}
          style={{ top: '40%', width: '600px' }}
          closable={true}
          footer={null}
          onCancel={() => (setShowModal({ add: false, edit: false }), setShowError(false))}
          title="Please Enter Password"
        >
          <Form>
            <div className="instruction-btn-wrap">
              <Form.Item name="model_prompt" style={{ marginBottom: 5, width: '100%' }}>
                <Input
                  type="password"
                  onPressEnter={handleSave}
                  onChange={(e) => setUserPassword(e.target.value)}
                  style={{ marginBottom: '10px' }}
                ></Input>
                {showError ? <span style={{ color: 'red' }}>Please Enter Valid Password</span> : <></>}
              </Form.Item>
              <div className="">
                <Button onClick={handleSave} icon={<LoginOutlined />} type="primary" />
              </div>
            </div>
          </Form>
        </Modal>
      )}
      <Modal
        visible={showDeleteModal}
        width={400}
        onCancel={() => {
          setShowDeleteModal(null);
        }}
        className="restart-task-modal"
      >
        <h3>Are you sure you want to delete this record ?</h3>

        <div className="modal-footer">
          <Button
            onClick={() => {
              setShowDeleteModal(null);
            }}
            className="ant-btn ant-btn-default"
          >
            <span>No</span>
          </Button>
          <Button onClick={handleYes} className="ant-btn btn-primary">
            <span>Yes</span>
          </Button>
        </div>
      </Modal>
      <Modal
        visible={errorMsg}
        width={400}
        onCancel={() => {
          setErrorMsg(null);
        }}
        className="restart-task-modal"
      >
        <h3>{errorMsg}</h3>

        <div className="modal-footer">
          <Button
            onClick={() => {
              setErrorMsg(null);
            }}
            className="ant-btn btn-primary"
          >
            <span>Ok</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default withRouter(AiTalkingTutor);

const AssignReport = ({ book, classes, close }) => {
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [prompt, setPrompt] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [level, setLevel] = useState('Grade 1');
  const [assignDate, setAssignDate] = useState(null);
  const [dueDate, setDueDate] = useState(6);
  const [version, setVersion] = useState('short');
  const [sentences, setSentences] = useState(60);
  const [feedback, setFeedback] = useState(false);
  const [revise, setRevise] = useState(false);
  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(CLASS_STUDENT_LIST);

  const [assignAITopicsToStudents, { loading }] = useMutation(ASSIGN_AI_TOPIC_TO_STUDENTS);

  const levelList = [
    { value: 'Grade 1', label: 'Basic A' },
    { value: 'Grade 4', label: 'Basic B' },
    {
      value: 'Grade 7',
      label: 'Intermediate',
    },
    {
      value: 'Grade 11',
      label: 'Advanced',
    },
    { value: 'College Level', label: 'College' },
  ];

  useEffect(() => {
    if (book.prompt) {
      setPrompt(book.prompt);
    }
  }, [book]);
  const handleSaveAndAssign = async () => {
    const selectedStudents = students.filter((student, idx) => checkedList[idx]);
    const studentIdxs = selectedStudents.map((student) => student.user?.idx);

    await assignAITopicsToStudents({
      variables: {
        class_idx: selectedClass.idx,
        start_time: [moment(assignDate).format('YYYY-MM-DD')],
        limit_time: 1440 * dueDate,
        user_idx_list: studentIdxs,
        type: 'book-report',
        external_data: [
          {
            idx: book.idx,
            ai_topic_idx: book.idx,
            writing_coach: true,
            dueDate: dueDate,
            version: version,
            sentences: sentences,
            feedback: feedback,
            revise: revise,
            revisedPrompt: prompt,
          },
        ],
        start_date: moment(assignDate).format('YYYY-MM-DD'),
        end_date: moment(assignDate).add(dueDate, 'days').format('YYYY-MM-DD'),
        study_time: moment(assignDate).format('HH:mm'),
        data: JSON.stringify({
          book: book,
          level: level,
          assign_date: assignDate,
          writing_coach: true,
          dueDate: dueDate,
          version: version,
          sentences: sentences,
          feedback: feedback,
          revise: revise,
          revisedPrompt: prompt,
        }),
      },
    });

    close();
  };

  useEffect(() => {
    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }
  }, [fetchStudents]);

  function handleChange(value) {
    setCheckedList([]);
    setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === value)[0]);
    getSelectClassStudents({
      variables: { class_idx: value },
    });
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(students.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  return (
    <ModalWrapper>
      <RowFlex style={{ height: '100%', justifyContent: 'space-between', alignItems: 'stretch', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flex: 1,
            paddingRight: '20px',
            // height:'100%',
            minHeight: '200px',
            width: '70%',
            borderRight: '1px solid #5a789a',
            marginRight: '20px',
          }}
        >
          <RowFlex style={{ padding: '0 0 12px', width: '100%' }}>
            <ModalLabel style={{ width: '100px', minWidth: '100px' }}>Assignment :</ModalLabel>
            <Form.Item style={{ marginBottom: 0, width: '100%' }}>
              <Input placeholder="Topic" value={book?.topic} disabled />
            </Form.Item>
          </RowFlex>
          <ColumnFlex>
            <ModalLabel style={{ paddingBottom: '9px' }}>Topic / Prompt :</ModalLabel>
            <Form.Item style={{ marginBottom: 0 }} name="prompt">
              <Input.TextArea placeholder={book?.prompt || 'Prompt'} value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            </Form.Item>
          </ColumnFlex>
          <ModalLabel style={{ color: '#2E75B6', padding: '10px 0 5px 0' }}>Student</ModalLabel>
          <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
            <RowFlex>
              <ModalLabel style={{ width: '65px' }}>Level :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={level}
                  onChange={(e) => {
                    setLevel(e);
                  }}
                  bordered={false}
                >
                  {levelList.map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Min. Words :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={sentences}
                  onChange={(e) => {
                    setSentences(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: 60, label: 'Min 60 words' },
                    { value: 100, label: 'Min 100 words' },
                    {
                      value: 150,
                      label: 'Min 150 words',
                    },
                    {
                      value: 250,
                      label: 'Min 250 words',
                    },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>
          <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
            <RowFlex>
              <ModalLabel style={{ width: '65px' }}>Report :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={version}
                  onChange={(e) => {
                    setVersion(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: 'long', label: 'Long Ver.' },
                    { value: 'short', label: 'Short Ver.' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between', padding: '12px 0 0px' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Feedback Button :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>

          <RowFlex style={{ width: '100%', justifyContent: 'end' }}>
            <RowFlex style={{ minWidth: '250px', width: '50%', justifyContent: 'space-between', padding: '12px 0 6px' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Revise Button :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={revise}
                  onChange={(e) => {
                    setRevise(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>
          <ColumnFlex>
            <ModalLabel style={{ paddingBottom: '6px 0 4px' }}>Assign Date :</ModalLabel>
            <RowFlex>
              <Form.Item style={{ marginBottom: 0, width: '50%' }}>
                <DatePicker
                  placeholder="Calendar pull down
                "
                  style={{ width: '100%' }}
                  onChange={(e) => setAssignDate(e)}
                />
              </Form.Item>
              &nbsp; &nbsp; &nbsp;
              <Form.Item style={{ marginBottom: 0, width: '50%' }}>
                <Select
                  style={{ alignItems: 'center', width: '200px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  placeholder={` Due Date`}
                  onChange={(e) => {
                    setDueDate(e);
                  }}
                  bordered={false}
                >
                  {[1, 2, 3, 4, 5, 6].map((e) => (
                    <Option key={e} value={e}>
                      {e}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </ColumnFlex>
        </div>
        <Form
          // labelCol={{
          //   span: 0,
          // }}
          // wrapperCol={{
          //   span: 24,
          // }}
          style={{ width: '30%' }}
          layout="vertical"
        >
          <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
            <Select
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              style={{ width: '100%', minWidth: 100 }}
              placeholder="Class"
              onChange={handleChange}
            >
              {classes.map((classInfo) => (
                <Option key={classInfo.idx} value={classInfo.idx}>
                  {classInfo.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <StudentList
            header={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>Name</label>
                <div>
                  Select All &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                </div>
              </div>
            }
            footer={<div></div>}
            size="small"
            bordered
            dataSource={students}
            style={{ backgroundColor: '#fff' }}
            renderItem={(item, idx) => (
              <List.Item style={{ display: 'flex' }}>
                <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
              </List.Item>
            )}
          />
        </Form>
      </RowFlex>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Button style={{ marginRight: 10 }} onClick={close}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleSaveAndAssign} loading={loading}>
          {' '}
          Assign{' '}
        </Button>
      </div>
    </ModalWrapper>
  );
};

const ExcelUpload = ({ onFileSelect, setLoading }) => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    if (topics.length > 0) {
      onFileSelect(
        topics.map((item) => {
          return { ...item };
        }),
      );
    }
    // eslint-disable-next-line
  }, [topics]);

  const props = {
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: (info) => {
      const file = info.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          //https://stackoverflow.com/questions/53163552/format-date-with-sheetjs
          const ab = e.target.result;
          const wb = XLSX.read(ab, { type: 'binary', cellText: false, cellDates: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          console.log('ws', wsname);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: ['title', 'prompt', 'level'],
            raw: false,
            dateNF: 'yyyy-mm-dd',
          });
          if (data && data instanceof Array) {
            const filtered_data = data.slice(1);
            setTopics(filtered_data);
          } else {
            alert('오류가 발생했습니다.');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setTopics([]);
      }
    },
  };
  return (
    <Input
      key={`finle - input - 0`}
      type="file"
      {...props}
      name="files"
      style={{
        width: '200px',
        margin: '0 10px',
      }}
    />
  );
};

const StudentList = styled(List)`
  border: 1px solid #5194b6;
  background-color: '#fff';
  height: 250px;
  .ant-list-header {
    border-bottom: 1px solid #5194b6;
  }
  .ant-list-items {
    overflow-y: auto;
    height: 200px;
  }
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-list-empty-text {
        .ant-empty {
          /* .ant-empty-description{ */
          display: none;
          /* } */
        }
      }
    }
  }
`;

const ModalWrapper = styled(ColumnFlex)`
  min-height: 350px;
  justify-content: space-between;
  /* width: 860px;
@media (max-width: 900px) {
  width: 750px;
} */
`;
