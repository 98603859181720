import React,{useState} from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import SoundEffect from 'src/components/common/SoundEffect';
import { Modal, Button } from 'antd';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 90vh;
  background-color: rgba(255 255 255 0.5);
  /* z-index: 999; */
`;
const PopUp = styled.div`
  width: 576px;
  height: 375px;
  background-image: url('/images/LevelUp/greatjob_levelup.png');
  background-size: 576px 375px;
  cursor: pointer;
`;
export default function LevelUpFinishPopUp({
  idx,
  url = `/level-up/study/${idx}`,
  assigned_idx = null,
  state = undefined,
  is_voca= true
}) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const handleOk = () => {
    if(is_voca){
      history.push(url, { ...state, assigned_idx: assigned_idx });}
      else{
        setVisible(true)
      }
  };
  return (
    <>
      {state ? (
        <Wrapper>
            <Link to={{ pathname: url, state: state }}>
            <PopUp />
        </Link>
          </Wrapper>
      ) : (
        <Wrapper>
          <PopUp onClick={handleOk} />
        </Wrapper>
      )}
      <>
        <SoundEffect src={'/audio/LevelUp/great.mp3'} replayCnt={1} />
        <Modal
          centered
          visible={visible}
          onOk={() => {
            history.push('/level-up/portfolio');
          }}
          onCancel={() => {
            history.push(url, { ...state, assigned_idx: assigned_idx });
          }}
          width={'400px'}
          footer={[
            <Button
              type="primary"
              onClick={() => {
                history.push('/level-up/portfolio');
              }}
            >
              Yes
            </Button>,
            <Button
              onClick={() => {
                history.push('/level-up');
              }}
            >
              No
            </Button>,
          ]}
        >
          <div>All done.</div>
          <div>Would you like to go to the portfolio page? </div>
        </Modal>
      </>
    </>
  );
}
