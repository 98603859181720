import React, { useEffect, useState } from 'react';
import { Modal, Typography, Divider, Col, Row } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
import MessageBox from 'src/components/DashBoard/SubPage/MessageBox';
import MessageItem from 'src/components/DashBoard/SubPage/MessageItem';

const { Text } = Typography;

function ViewAnswerModal({ visible, topic, userTopicId, topicAnswer, handleCancel }) {
  const [chatList, setChatList] = useState([]);

  const [aiTopicUserAssignmentDetails, { data: dataHistory, loading: loadingHistory, error: errorHistory }] =
    useLazyQuery(AI_TOPIC_USER_ASSIGNMENT_DETAILS);

  useEffect(() => {
    aiTopicUserAssignmentDetails({ variables: { idx: userTopicId } });
  }, []);

  useEffect(() => {
    if (dataHistory?.aiTopicUserAssignmentDetails?.ai_request_responses) {
      let resChats = [];
      dataHistory?.aiTopicUserAssignmentDetails?.ai_request_responses.forEach((item) => {
        resChats.push({
          user: userInfoVar()?.id,
          chat_user_name: userInfoVar()?.name,
          content: item.prompt_text,
          urls: [],
          created: item.idate,
        });
        resChats.push({
          user: 'bot',
          chat_user_name: 'AI봇',
          content: item.completion_text,
          urls: item.image_urls ? JSON.parse(item.image_urls) : [],
          created: item.idate,
        });
      });
      setChatList(resChats);
    }
  }, [dataHistory]);

  return (
    <Modal
      title="Cloubot AI"
      visible={visible}
      width="100%"
      bodyStyle={{ padding: '0 15px 15px 15px', background: '#f5f5f5' }}
      headerStyle={{ background: '#f5f5f5' }}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        <Row gutter={[16, 16]}>
          <Col className="book-header-two-table" span={12} style={{ textAlign: 'left', margin: 0 }}>
            <Text strong style={{ color: '#00b598' }}>
              주제/질문
            </Text>
            <div style={{ border: '1px solid #00b598', background: '#eafffc', padding: '5px', minHeight: '55px', marginBottom: '20px' }}>
              {topic}
            </div>
            <Text strong style={{ color: '#ff922b' }}>
              답변하기
            </Text>
            <MessageBox
              id="AnswerBox"
              style={{
                height: '40vh',
                overflow: 'auto',
                border: '1px solid #ff922b',
                margin: '2px 0 20px 0',
                backdround: '#fff',
              }}
            >
              <InfiniteScroll
                scrollableTarget="AnswerBox"
                style={{ display: 'flex', flexDirection: 'column' }}
                inverse={true}
                hasMore={false}
                dataLength={chatList}
              >
                {topicAnswer}
              </InfiniteScroll>
            </MessageBox>
          </Col>
          <Col className="book-header-two-table0icon" span={12}>
            <Text strong style={{ color: '#1890ff' }}>
              Chats
            </Text>
            <MessageBox
              id="MessageBox"
              style={{
                height: '55vh',
                overflow: 'auto',
                border: '1px solid #1890ff',
                margin: '2px 0 20px 0',
                backdround: '#fff',
              }}
            >
              <InfiniteScroll
                scrollableTarget="MessageBox"
                style={{ display: 'flex', flexDirection: 'column' }}
                inverse={true}
                hasMore={false}
                dataLength={chatList}
                endMessage={
                  loadingHistory ? (
                    <p style={{ textAlign: 'center' }}>
                      <b>Loading....</b>
                    </p>
                  ) : null
                }
              >
                {chatList?.map((thread, key) => {
                  const isMe = Number(userInfoVar()?.id) === thread.user;
                  return (
                    <React.Fragment key={`message-item-key${key}`}>
                      <DateDivider
                        isShow={
                          key < chatList.length &&
                          moment(new Date(thread?.created)).format('YYYY-MM-DD') !==
                            moment(new Date(chatList[key - 1]?.created)).format('YYYY-MM-DD')
                        }
                        date={`${moment(new Date(thread?.created)).format('YYYY-MM-DD')}`}
                      />
                      <MessageItem
                        isMe={isMe}
                        name={thread.chat_user_name}
                        message={thread.content}
                        urls={thread.urls}
                        date={thread.created}
                        readYN={true}
                        unreadCount={0}
                      />
                    </React.Fragment>
                  );
                })}
              </InfiniteScroll>
            </MessageBox>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default ViewAnswerModal;

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};
