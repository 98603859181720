import React, { useEffect, useRef, useState } from 'react';
import DefaultModal from './DefaultModal';
import { Col, Form, Input, Row, Select, Upload,Image } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { UPDATE_STUDY_QUIZ_DATA } from 'src/operations/mutations/book';
import { useMutation } from '@apollo/client';
import { openNotification } from '../common/Notification';
import { UPLOAD_FILE } from 'src/operations/mutations/uploadFileS3';


const QuizDataEditModal = ({quizData,onCancel, onQuizFinish, ...rest}) => {
    const [form] = Form.useForm();
    const [upload] = useMutation(UPLOAD_FILE);
    const [uploadfile,setUploadFile] = useState();

    const [update, { loading }] = useMutation(UPDATE_STUDY_QUIZ_DATA, {
        onCompleted(data) {
          if (data) {
            openNotification('컨텐츠 수정 완료!');
            onQuizFinish();
          }
        },
        onError(error) {
          console.log('error', error);
          alert('오류가 발생했습니다.');
        },
      });

    useEffect(() => {
        if (quizData) {
          const { answer, extra_data, no,option,option_list,pre_question,pre_question_text,question,question_audio,question_image,question_text,question_type,question_video,data_idx } = quizData;
          form.setFieldsValue({data_idx,no,question,option});
        }
      }, [quizData, form]);
    
    const handleCancel = () => {
        form.resetFields();
        onCancel();
      };
      const handleSubmit = (values) => {
        const { data_idx, no, question, option } = values;
        update({ variables: { quiz_data_input: { data_idx, no, question, option,question_image:uploadfile} } });
      };

      const handleUplaod = (data) => {
        return new Promise(async (resolve, reject) => {
          try {
            const {
              data: {
                singleUploadS3: { id, path, filename, mimetype, encoding },
              },
            } = await upload({ variables: { file: data } });
            resolve(id);
            setUploadFile(data.name)
            
          } catch (e) {
            reject(e);
          }
        });
      };

    return(
        <DefaultModal
        title="컨텐츠 변경하기"
        form={form}
        ActionCancel={handleCancel}
        popupMode={`modify`}
        width={`70`}
        loading={loading}
        {...rest}
      >
        {/* <h1>fdsfdgdfgdfg</h1> */}
        <Form form={form} name="control-ref" layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit}>
          <FormItem name="data_idx" rules={[{ required: true }]} style={{ display: 'none' }}>
            <Input type="hidden" name="data_idx" />
          </FormItem>
          <FormItem name="no" rules={[{ required: true }]} style={{ display: 'none' }}>
            <Input type="hidden" name="no" />
          </FormItem>
          <Row gutter={[16, 16]}>
              <Col span={24}>
                <FormItem name="question" label={`question`} hasFeedback rules={[{ required: true }]}>
                  <Input name="question" />
                </FormItem>
              </Col>
            <Col span={24}>
              <FormItem name="option" label={`option`} hasFeedback rules={[{ required: true }]}>
                <Input name="option" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem name="question_image" label={`question_image`} >
                <Image src={uploadfile?uploadfile:"https://cdn.cloubot.com/AE/cover/L1V1.jpg" }alt='question_image' width={'50px'} height={'50px'}></Image>
              </FormItem>
            </Col>
            <Col span={24}>
            <FormItem>
              <Upload
                accept="image/*"
                name="file"
                multiple={true}
                action={handleUplaod}
                listType="text"
                showUploadList={{ showRemoveIcon: false }}
              >
                Choose file
              </Upload>
            </FormItem>
          </Col>
          </Row>
        </Form>
      </DefaultModal>
    )
}
export default QuizDataEditModal;