import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Form } from 'antd';
import DefaultModal from './DefaultModal';
import { openNotification } from 'src/components/common/Notification';
import CreateConsultForm from 'src/pages/Consulting/CreateConsultForm';
import { MOD_CONSULT } from 'src/operations/mutations/consult';
import moment from 'moment';
import { GET_CONSULT_LIST, GET_ORDER, GET_ORDER_BY_ID } from 'src/operations/queries/consult';
import { DAY_WISE_SCHEDULE_DATA } from 'src/operations/queries/getScheduleData';

const ConsultFormModalMeta = ({ onCancel, order_idx, campus_idx, visible, searchMonth, handleStatus, testDetail, ...rest }) => {
  const [form] = Form.useForm();
  const [reservedate, setReserveDate] = useState(null);
  const [birthdate, setBirthDate] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);
  const [scheduleStatus, setScheduleStatus] = useState(false);
  const [refetchstatus, setRefecthStatus] = useState('');
  const [beginTime, setBeginTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [scheduleId, setScheduleId] = useState(null);
  const [days, setDays] = useState(null);
  const [status, setStatus] = useState('');

  const { data: dataOrderUpdate, refetch } = useQuery(GET_ORDER_BY_ID, { skip: !order_idx, variables: { idx: order_idx } });
  useEffect(() => {
    const chars = testDetail?.split(' ');
    if (chars) {
      if (chars[1]) {
        setStatus(chars[1]);
        setReserveDate(chars[0]);
      } else {
        setStatus('');
      }
    }
  }, [testDetail]);

  const [modConsult, { loading: loadingCreate }] = useMutation(MOD_CONSULT, {
    onCompleted: () => {
      openNotification('수정 완료!');
      setRefecthStatus(true);
      handleStatus(true);
      handleCancel();
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
    refetchQueries: [
      {
        query: GET_CONSULT_LIST,
        variables: { campus_idx, ym: searchMonth },
      },
    ],
  });
  // useEffect(()=>{
  //   if(dataOrderUpdate){
  //     setUserIdx(orderData?.user_data?.idx)
  //     setOrderLevelIdx(orderData.order_leveltest_data.idx)
  //   }
  // },[dataOrderUpdate])
  // useEffect(() => {
  //   if (dataOrder && visible) {
  //     form.setFieldsValue({
  //       student_name: dataOrder.orderItemByIdx.name,
  //       phone: dataOrder.orderItemByIdx.parent_phone,
  //     });
  //   }
  // }, [dataOrder, form, visible]);
  useEffect(() => {
    if (refetchstatus == true) {
      refetch();
    }
  }, [refetchstatus]);
  useEffect(() => {
    if (dataOrderUpdate?.orderItemByIdx && visible) {
      const orderData = dataOrderUpdate?.orderItemByIdx;
      let school_time = null;
      if (orderData.schedule) {
        setScheduleData({
          ...orderData.schedule,
          reserve_date: orderData.order_leveltest_data.reserve_date,
        });
        setScheduleId(orderData.schedule.idx);
        setDays(orderData.schedule?.days);
        school_time = orderData.schedule?.start_time + ' to ' + orderData.schedule?.end_time;
      }
      setScheduleStatus(orderData?.schedule_or_not);
      form.setFieldsValue({
        student_name: orderData?.name,
        phone: orderData?.parent_phone,
        korean_name: orderData?.user_data?.english_name,
        reserve_date: orderData.order_leveltest_data.reserve_date
          ? moment(orderData?.order_leveltest_data?.reserve_date, 'YYYY-MM-DD')
          : '',
        birthday: orderData.user_data.birthday ? moment(orderData?.user_data?.birthday, 'YYYY-MM-DD') : '',
        school_info: orderData?.school_info,
        school_time: !school_time ? '사용 가능한 일정 선택' : school_time,
      });
      setReserveDate(orderData?.order_leveltest_data?.reserve_date);
      setBirthDate(orderData?.user_data?.birthday);
    }
  }, [dataOrderUpdate, form, visible, refetchstatus]);

  function onDateChange(name, dateString) {
    if (name == 'reserve_date') {
      setReserveDate(dateString);
    }
    if (name == 'birthday') {
      setBirthDate(dateString);
    }
  }

  const { data: daywiseslot } = useQuery(DAY_WISE_SCHEDULE_DATA, {
    variables: {
      campus_idx: campus_idx,
      date: reservedate,
    },
    skip: !reservedate,
  });
  const getSecheduleIdx = (key) => {
    setScheduleId(daywiseslot?.getDayWiseMetaSchedule[key].idx);
    setDays(daywiseslot?.getDayWiseMetaSchedule[key].days);
  };

  const handleSubmit = useCallback(
    (values) => {
      const { phone, student_name, korean_name, reserve_date, birthday, start_time, end_time, school_info } = values;
      modConsult({
        variables: {
          order_idx,
          phone,
          student_name: student_name.trim().toLowerCase(),
          reserve_date: reservedate,
          korean_name: korean_name,
          birth_date: birthdate,
          school_info: school_info,
          schedule_idx: scheduleId,
          campus_idx: campus_idx,
          days: days,
        },
      });
    },
    [order_idx, modConsult, reservedate, beginTime, endTime, scheduleId, birthdate],
  );
  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const handleStartTimeData = (params) => {
    setBeginTime(params);
  };
  const handleEndTimeData = (params) => {
    setEndTime(params);
  };
  const scheduleDataList = useMemo(() => {
    if (daywiseslot?.getDayWiseMetaSchedule) {
      if (daywiseslot.getDayWiseMetaSchedule?.length > 0) return daywiseslot?.getDayWiseMetaSchedule;
      else if (scheduleData && moment(scheduleData.reserve_date).format('YYYY-MM-DD') == moment(reservedate).format('YYYY-MM-DD')) {
        form.setFieldsValue({
          school_time: scheduleData.start_time + ' to ' + scheduleData.end_time,
        });
        return [scheduleData];
      } else {
        form.setFieldsValue({
          school_time: null,
        });
      }
    }

    return null;
  }, [daywiseslot, scheduleData, reservedate]);

  return (
    <DefaultModal
      title="예약 정보 수정"
      form={form}
      loading={loadingCreate}
      ActionCancel={handleCancel}
      popupMode="mod"
      width={60}
      visible={visible}
      {...rest}
    >
      {/* create 가 아니라 수정임.. 헐..*/}
      <CreateConsultForm
        form={form}
        onFinish={handleSubmit}
        onDateChange={onDateChange}
        status={scheduleStatus}
        handleStartTime={handleStartTimeData}
        handleEndTime={handleEndTimeData}
        scheduleData={scheduleDataList}
        handleSchedule={getSecheduleIdx}
        testDetail={status}
        isEdit={true}
      />
    </DefaultModal>
  );
};

export default ConsultFormModalMeta;
