import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Form, Input, Button } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';
import { useForm } from 'src/utils/hooks';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const BookDeleteModal = ({ visible, handleCancel, bookInfo }) => {
  const [form] = Form.useForm();
  const { onSubmit, values } = useForm(formCallback, {
    title: bookInfo.title,
    book_code: bookInfo.book_code,
  });

  const [deleteBookSeries, { loading }] = useMutation(mutations.book.DELETE_BOOK_SERIES, {
    variables: {
      codes: [values.book_code],
    },

    update(proxy, result) {
      const data = proxy.readQuery({
        query: queries.book.GET_BOOK_SERIES,
        variables: { search_text: '' },
      });

      proxy.writeQuery({
        query: queries.book.GET_BOOK_SERIES,
        variables: { search_text: '' },
        data: {
          bookSeries: data.bookSeries.filter((bookSeries) => {
            return bookSeries.code !== result.data.deleteBookSeries[0].code;
          }),
        },
      });
    },
    onCompleted(data) {
      if (data) {
        openNotification('컨텐츠가 삭제되었습니다!');
        handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function formCallback() {
    deleteBookSeries();
  }

  return (
    <>
      <Modal
        entered
        title={`Delete Content`}
        visible={visible}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          initialValues={bookInfo}
        >
          <FormField>"{values.title}" 를 정말로 삭제하시겠습니까?</FormField>

          <FormField>
            <FormItem name="book_code">
              <Input type="hidden" name="book_code" maxLength={13} />
            </FormItem>
          </FormField>
        </Form>
      </Modal>
    </>
  );
};

BookDeleteModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default BookDeleteModal;
