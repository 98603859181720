import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_BOOKS_CODE } from 'src/operations/queries/company';
import StudyResultListContainer from 'src/components/StudyResult/StudyResultListContainer';
const LearningClassStudyResult = () => {
  const { class_idx, user_idx } = useParams();
  const location = useLocation();
  const { data: dataBook } = useQuery(GET_COMPANY_BOOKS_CODE, {
    skip: !userInfoVar()?.campus?.fc_company_idx,
    variables: {
      company_idx: userInfoVar()?.campus?.fc_company_idx,
      is_all: 1,
    },
  });
  return (
    <StudyResultListContainer
      class_idx={class_idx}
      user_idx={user_idx}
      company_book_display={dataBook?.getCompanyBookContentList}
      user_name={location?.state?.user_name}
      student_id={location?.state?.user_id}
    />
  );
};
export default LearningClassStudyResult;
