import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  Col,
  Row,
  Typography,
  Modal,
  Button,
  Upload,
  DatePicker,
  Radio,
  Checkbox,
  Form,
  Input,
  Tooltip,
  Space,
  Select,
  Dropdown,
} from 'antd';
import { PlusOutlined, RedoOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ClassRoomModal from 'src/components/Modal/ClassRoomModal';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';

import UseHistoryState from 'src/components/common/UseHistoryState';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek, getMonthDateRange } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx';

const { Title, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;
const { value } = '';
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const options = [
  { label: '진행중', value: true },
  { label: '종료', value: false },
];

function OfflineSyllabus() {
  const userType = userInfoVar()?.type;
  const [tab, setTab] = useState('main');
  const [loading, setLoading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [totalCurrentStudyingStudent, setTotalCurrentStudyingStudent] = useState(0);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const [searchValue, setSeachValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleBatch, setVisibleBatch] = useState(false);

  const [syllabusLessonObject, setsyllabusLessonObject] = useState([]);
  const [syllabusLessonObjectAdd, setsyllabusLessonObjectAdd] = useState([]);

  const [addsyllabusinput, setAddsyllabusinput] = useState([]);
  const [SyllabusLevel, setSyllabusLevel] = useState([]);

  const [SyllbausBookCode, setSyllbausBookCode] = useState('');
  const [SyllbausLevel, setSyllbausLevel] = useState('');

  const { showModal, handleCancel, popupMode } = useModal();
  const [fields, setFields] = useState([]);
  const [Lastregisterdate, setLastregisterdate] = useState('');
  const [searchBookcode, setsearchBookcode] = useState('');

  const SyllabusLevelResult = SyllabusLevel.map((level) => level);
  const [Validation, setValidation] = useState('');

  const [TextbookLevelForm, setTextbookLevelForm] = useState([]);

  const TextbookLevelFormFilter = TextbookLevelForm.map((Level) => Level);
  const [Programinputcreate, setPrograminputcreate] = useState('');
  const [Programinputlevel, setPrograminputlevel] = useState('');
  const [selectedLevel, setselectedLevel] = useState('');
  const [lessonInputNumber, setlessonInputNumber] = useState();
  const history = useHistory();

  const [InputFolder, setInputFolder] = useState('');

  const [syllabusLessonObjectInitial, setsyllabusLessonObjectInitial] = useState([{ dayslesson: null, lessontopic: null, ppturl: null }]);
  const [syllabusLessonObjectInitialAdd, setsyllabusLessonObjectInitialAdd] = useState([]);

  const [getLessonlist, { data: allLessonList }] = useLazyQuery(queries.offlinesyllabus.OFFLINE_ADD_SYLLABUS_TEXTBOOK_LESSON_LIST);
  const [getfetchtextbookbycode, { data: fetchtextbookbycode }] = useLazyQuery(
    queries.offlinesyllabus.OFFLINE_SYLLABUS_TEXTBOOK_LIST_BY_CODE,
  );

  const { data: OfflineSyllabusTextbooklist, refetch } = useQuery(queries.offlinesyllabus.OFFLINE_SYLLABUS_TEXTBOOK_LIST, {
    fetchPolicy: 'no-cache',
  });

  const { data: OfflinebookTitleWithLevel } = useQuery(queries.offlinesyllabus.OFFLINE_TEXTBOOK_TITLE_WITH_LEVEL, {
    fetchPolicy: 'no-cache',
  });

  const { data: OfflineAllLessonList } = useQuery(queries.offlinesyllabus.OFFLINE_ADD_SYLLABUS_LESSON_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [Currentprogram, setCurrentprogram] = useState('');
  const [Currentlevel, setCurrentlevel] = useState('');

  const props = {
    onChange({ file, fileList }) {
      console.log(file);
      console.log(fileList);
      /*if (file.status !== 'uploading') {
        console.log(file, fileList);
      }*/
    },
  };

  const [offlineaddlesson] = useMutation(mutations.offlinesyllabus.OFFLINE_ADD_LESSON_SYLLABUS, {
    onCompleted(data) {
      if (data) {
        setValidation('Lesson Added Successfully');
        setValidation('');
        setCurrentprogram(Programinputcreate);
        setCurrentlevel(Programinputlevel);
        let currentlesson = syllabusLessonObjectInitialAdd.map((loopitem, loopkey) => {
          let obj = {
            no: loopkey + 1,
            dayslesson: loopitem.dayslesson,
            lessontopic: loopitem.lessontopic,
            ppturl: loopitem.ppturl,
            programname: Programinputcreate,
            programlevel: Programinputlevel,
          };
          return obj;
        });
        setsyllabusLessonObject(currentlesson);
        setVisible(false);
        history.push('/offline/lesson/assign');
      }
    },
    onError(error) {
      setValidation('Please try again !!!');
      setValidation('');
      //window.location.reload();
    },
  });

  const [offlineBulklesson] = useMutation(mutations.offlinesyllabus.OFFLINE_BULK_LESSON_SYLLABUS, {
    onCompleted(data) {
      if (data) {
        setValidation('Lesson Added Successfully');
        setValidation('');
        setCurrentprogram(Programinputcreate);
        setCurrentlevel(Programinputlevel);
        let currentlesson = syllabusLessonObjectInitialAdd.map((loopitem, loopkey) => {
          let obj = {
            no: loopkey + 1,
            dayslesson: loopitem.dayslesson,
            lessontopic: loopitem.lessontopic,
            ppturl: loopitem.ppturl,
            programname: Programinputcreate,
            programlevel: Programinputlevel,
            plantopic: loopitem.plantopic,
            planday: loopitem.planday,
            planlearningobj: loopitem.planlearningobj,
            textbookcode1: loopitem.textbookcode1,
            textbookcode2: loopitem.textbookcode2,
            lessonplancontent: loopitem.lessonplancontent,
            homeworkcontent: loopitem.homeworkcontent,
          };
          return obj;
        });
        setsyllabusLessonObject(currentlesson);
        setVisibleBatch(false);
        history.push('/offline/lesson/assign');
      }
    },
    onError(error) {
      setValidation('Please try again !!!');
      setValidation('');
      //window.location.reload();
    },
  });

  useEffect(() => {
    /*if(OfflineAllLessonList?.offlineAddSyllabusList){
      let lessonlistdata = OfflineAllLessonList?.offlineAddSyllabusList.map((loopitem,loopkey) => {
                let obj = {
                  "no":loopkey+1,
                  "dayslesson":loopitem.days_lesson,
                  "lessontopic":loopitem.lesson_topic,
                  "ppturl":loopitem.ppturl,
                  "lessonplan":"",
                  "bookcode":loopitem.offline_syllabus_textbook_bookcode,
                  "booktitle":loopitem.offline_syllabus_textbook_booktitle,
                  "booklevel":loopitem.level,
                  "programname":loopitem.programname
                }
                return obj;
      })
      setsyllabusLessonObject(lessonlistdata)
      setsyllabusLessonObjectAdd(lessonlistdata)
    }*/

    if (OfflinebookTitleWithLevel?.offlineTextbookTitleWithLevel) {
      let levelformation = OfflinebookTitleWithLevel?.offlineTextbookTitleWithLevel.map((loopitem, loopkey) => {
        let obj = {
          levelname: loopitem.level == 'Phonics' ? loopitem.level : ` level - ${loopitem.level}`,
          //"levelkey" : `${loopitem.bookcode}#${loopitem.booktitle}#${loopitem.level}`
          levelkey: `${loopitem.level}`,
        };
        return obj;
      });
      setTextbookLevelForm(levelformation);
    }
  }, [OfflinebookTitleWithLevel]);

  const handlebookcodeChange = (e) => {
    setValidation('');
    if (e !== '' && e !== undefined) {
      let inputvalue = e.target.value;
      inputvalue.toUpperCase();
      setSyllbausBookCode(inputvalue);
    }
  };

  const handlebooklevelChange = (e) => {
    setPrograminputlevel(e);
    setValidation('');
  };

  useEffect(() => {
    if (Programinputcreate !== '') {
      setValidation('');
    }

    if (Programinputlevel !== '') {
      let levelnumber = Programinputlevel.split('#')[2];
      setselectedLevel(levelnumber);
    }
  }, []);

  /*const handlebooklevelHomeChange = (e) => {
    let inputsplit = e.split("#");
    let inputbookcode = inputsplit[0];
    let inputbooktitle = inputsplit[1];
    let inputbooklevel = inputsplit[2];
    let bookidwithlevelfilter = syllabusLessonObjectAdd.map((loopitem,loopkey) => {
        if(inputbookcode == loopitem.bookcode && inputbooktitle == loopitem.booktitle && inputbooklevel == loopitem.booklevel){
            let obj = {
                    "no":loopitem.no,
                    "dayslesson":loopitem.dayslesson,
                    "lessontopic":loopitem.lessontopic,
                    "ppturl":loopitem.ppturl,
                    "lessonplan":"",
                    "bookcode":loopitem.bookcode,
                    "booktitle":loopitem.booktitle,
                    "booklevel":loopitem.booklevel,
                    "programname":loopitem.programname
            }
            return obj;
        }
    }) 
    setsyllabusLessonObject(bookidwithlevelfilter.filter(item => item));
  }*/

  /*const handleSearch = (e) => {
    if(e !== ''){
      let bookidwithlevelfilter = syllabusLessonObjectAdd.map((loopitem,loopkey) => {
        if(e == loopitem.programname){
            let obj = {
                    "no":loopitem.no,
                    "dayslesson":loopitem.dayslesson,
                    "lessontopic":loopitem.lessontopic,
                    "ppturl":loopitem.ppturl,
                    "lessonplan":"",
                    "bookcode":loopitem.bookcode,
                    "booktitle":loopitem.booktitle,
                    "booklevel":loopitem.booklevel,
                    "programname":loopitem.programname
            }
            return obj;
        }
      })
      setsyllabusLessonObject(bookidwithlevelfilter.filter(item => item));
    }else{
      setsyllabusLessonObject(syllabusLessonObjectAdd);  
    }
  }*/

  /*const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };*/

  function lessonInputChange(e) {
    setValidation('');
    if (e.target.value !== '' && e.target.value !== undefined) {
      setlessonInputNumber(e.target.value);
      let loopvalue = e.target.value;
      let arrayformation = [];
      for (let i = 0; i < loopvalue; i++) {
        arrayformation.push(i);
      }
      let loopresult = [...new Set(arrayformation)];
      let lessonobjcreation = [...new Set(arrayformation)].map((loopitem, loopkey) => {
        let obj = {
          dayslesson: '',
          lessontopic: '',
          ppturl: '',
        };
        return obj;
      });
      setsyllabusLessonObjectInitial(lessonobjcreation);
      setAddsyllabusinput(loopresult);
    } else {
      setAddsyllabusinput([]);
    }
  }

  function rowcreation(e) {
    setsyllabusLessonObjectInitialAdd(syllabusLessonObjectInitial);
  }

  function addrowcreation(e) {
    let total = syllabusLessonObjectInitialAdd;
    let objform = { dayslesson: '', lessontopic: '', ppturl: '' };
    total[total.length] = objform;
    setsyllabusLessonObjectInitial(total.filter((item) => item));
    setsyllabusLessonObjectInitialAdd(total.filter((item) => item));
    setlessonInputNumber(total.filter((item) => item).length);
  }

  function inputhandleChangeBulk(i, e, staticname) {
    let totalobject = syllabusLessonObjectInitialAdd;
    if (staticname == 'dayslesson') {
      totalobject[i]['dayslesson'] = e.target.value;
      setsyllabusLessonObjectInitialAdd(totalobject);
    }
    if (staticname == 'lessontopic') {
      totalobject[i]['lessontopic'] = e.target.value;
      setsyllabusLessonObjectInitialAdd(totalobject);
    }
    if (staticname == 'ppturl') {
      totalobject[i]['ppturl'] = e.target.value;
      setsyllabusLessonObjectInitialAdd(totalobject);
    }
  }

  function inputhandleChange(i, e, staticname) {
    let totalobject = syllabusLessonObjectInitialAdd;
    if (staticname == 'dayslesson') {
      totalobject[i]['dayslesson'] = e.target.value;
      setsyllabusLessonObjectInitialAdd(totalobject);
    }
    if (staticname == 'lessontopic') {
      totalobject[i]['lessontopic'] = e.target.value;
      setsyllabusLessonObjectInitialAdd(totalobject);
    }
    if (staticname == 'ppturl') {
      totalobject[i]['ppturl'] = e.target.value;
      setsyllabusLessonObjectInitialAdd(totalobject);
    }
  }

  const handleAddSaveLesson = () => {
    setValidation('');
    if (Programinputcreate !== '' && Programinputlevel !== '') {
      offlineaddlesson({
        variables: {
          programnameold: Programinputcreate,
          programlevelold: Programinputlevel,
          programname: Programinputcreate,
          programlevel: Programinputlevel,
          lessonlist: syllabusLessonObjectInitialAdd,
          folder_type: InputFolder,
          programtype: 'create',
        },
      });
    } else {
      setValidation('Select Program name & level');
    }
  };

  const handleBulkSaveLesson = () => {
    setValidation('');
    if (Programinputcreate !== '' && Programinputlevel !== '') {
      offlineBulklesson({
        variables: {
          programnameold: Programinputcreate,
          programlevelold: Programinputlevel,
          programname: Programinputcreate,
          programlevel: Programinputlevel,
          lessonlist: syllabusLessonObjectInitialAdd,
          folder_type: InputFolder,
          programtype: 'create',
        },
      });
    } else {
      setValidation('Select Program name & level');
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      render: (text, record) => <span style={{ color: 'green' }}>{record.no}</span>,
    },
    {
      title: 'Days / Lessons',
      dataIndex: 'dayslesson',
      key: 'dayslesson',
      align: 'center',
    },
    {
      title: 'Lesson Topic',
      dataIndex: 'lessontopic',
      key: 'lessontopic',
      align: 'center',
    },
    {
      title: 'PPT (url)',
      dataIndex: 'ppturl',
      key: 'ppturl',
      align: 'center',
      render: (text, record) => <span style={{ color: '#249fe9', cursor: 'pointer' }}>{record.ppturl}</span>,
    },
    /*{
      title: 'Lesson Plan',
      key: 'Lesson_Plan',
      align: 'Lesson_Plan',
      render: (text, record) => (
        <Button style={{ color: '#249fe9', background: '#B8E4F0' }}>
          <Link
            to={{
              pathname: `/offline/lesson/create/${record.programname}/${record.programlevel}/${record.dayslesson}/${record.lessontopic}/${record.ppturl}`,
            }}
          >
            Enter
          </Link>
        </Button>
      ),
    },*/
  ];

  const ExcelUpload = ({ fileInputKey, saveData }) => {
    const props = {
      multiple: false,
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      onChange: (info) => {
        const file = info.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            /* Parse data */
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: 'array' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            //const data = XLSX.utils.sheet_to_json(ws, { header: ['dayslesson', 'lessontopic', 'ppturl', 'plantopic', 'planday', 'planlearningobj', 'textbookcode1', 'textbookcode2', 'lessonplancontent', 'homeworkcontent'] });
            const data = XLSX.utils.sheet_to_json(ws, { header: ['dayslesson', 'lessontopic', 'ppturl'] });

            if (data && data instanceof Array) {
              let formobject = data.filter((loopitem, loopkey) => loopkey !== 0);
              let currentlesson = formobject.map((loopitem, loopkey) => {
                let obj = {
                  no: loopkey + 1,
                  dayslesson: loopitem.hasOwnProperty('dayslesson') == true ? loopitem.dayslesson : '',
                  lessontopic: loopitem.hasOwnProperty('lessontopic') == true ? loopitem.lessontopic : '',
                  ppturl: loopitem.hasOwnProperty('ppturl') == true ? loopitem.ppturl : '',
                  programname: Programinputcreate,
                  programlevel: Programinputlevel,
                  //"plantopic"    : loopitem.hasOwnProperty('plantopic') == true ? loopitem.plantopic : '',
                  //"planday"      : loopitem.hasOwnProperty('planday')  == true ? loopitem.planday : '',
                  //"planlearningobj"   : loopitem.hasOwnProperty('planlearningobj') == true ? loopitem.planlearningobj : '',
                  //"textbookcode1"     : loopitem.hasOwnProperty('textbookcode1') == true ? loopitem.textbookcode1 : '',
                  //"textbookcode2"     : loopitem.hasOwnProperty('textbookcode2') == true ? loopitem.textbookcode2 : '',
                  //"lessonplancontent" : loopitem.hasOwnProperty('lessonplancontent')  == true ? loopitem.lessonplancontent : '',
                  //"homeworkcontent"   : loopitem.hasOwnProperty('homeworkcontent')  == true ? loopitem.homeworkcontent : '',
                };
                return obj;
              });
              setsyllabusLessonObjectInitialAdd(currentlesson);
              setCurrentprogram(Programinputcreate);
              setCurrentlevel(Programinputlevel);
              setsyllabusLessonObject(currentlesson);
            } else {
              alert('오류가 발생했습니다.');
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
        }
      },
    };
    return <Input size="small" key={`finle-input-${fileInputKey}`} type="file" {...props} name="files" style={{ height: 32 }} />;
  };

  const handlerowDelete = (e) => {
    let filterobj = syllabusLessonObjectInitialAdd.map((loopitem, loopkey) => {
      if (loopkey !== e) {
        return loopitem;
      }
    });
    let trimfilter = filterobj.filter((item) => item);
    setsyllabusLessonObjectInitialAdd(trimfilter);
    setlessonInputNumber(trimfilter.length);
  };

  const handleFolderChangepopup = (e) => {
    setInputFolder(e);
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY: 'auto' }}>
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Form
                form={form}
                initialValues={{
                  layout: formLayout,
                }}
              >
                <Space>
                  <span style={{ width: 200, color: '#249fe9', fontSize: '20px', fontWeight: 'bold' }}>Program Name : </span>
                  <span style={{ width: 200, fontSize: '20px', fontWeight: 'bold' }}>{Currentprogram}</span>
                  <span style={{ width: 160, color: '#249fe9', fontSize: '20px', fontWeight: 'bold' }}>Level : </span>
                  <span style={{ width: 160, fontSize: '20px', fontWeight: 'bold' }}>{Currentlevel}</span>
                  {/*<Input.Search
                        className="inputsearch-classroom"
                        placeholder="Let Go"
                        allowClear
                        style={{ marginRight: 10, background: "#B8E4F0" }}
                        enterButton
                        value={searchValue}
                        onChange={onSeachChange}
                        onSearch={handleSearch}
              />
              <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                        <RedoOutlined />
                      </CustomButton>
              <Select placeholder="level" onChange={handlebooklevelHomeChange} style={{width:"150px"}}>
                  {
                    TextbookLevelFormFilter.map((loopitem,loopkey) => <option key={loopkey} value={loopitem.levelkey} >{loopitem.levelname}</option>)
                  }
              </Select>*/}
                </Space>
              </Form>
              <div className="header-classroom-search">
                <Button
                  className="btn-schedule"
                  type={'primary'}
                  size="large"
                  style={{ margin: 5, float: 'right', background: '#4e9b4e', borderColor: 'green' }}
                ></Button>
                <Button
                  className="btn-schedule"
                  type={'primary'}
                  size="large"
                  style={{ margin: 5, float: 'right', background: '#EBEDEF', color: 'green', marginRight: '-5px' }}
                ></Button>

                <span
                  style={{
                    margin: 0,
                    border: '1px solid #1890ff',
                    background: '#f0f5f9',
                    float: 'right',
                    width: '100px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setVisible(true)}
                >
                  <center>
                    <Title className="header-classroom-title" level={5} style={{ marginTop: '10px', color: '#1890ff' }}>
                      + Create
                    </Title>
                  </center>
                </span>
                <span
                  style={{
                    margin: 0,
                    float: 'right',
                    width: '30px',
                    cursor: 'pointer',
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                </span>
                <span
                  style={{
                    margin: 0,
                    border: '1px solid #1890ff',
                    background: '#f0f5f9',
                    float: 'right',
                    width: '220px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setVisibleBatch(true)}
                >
                  <center>
                    <Title className="header-classroom-title" level={5} style={{ marginTop: '10px', color: '#1890ff' }}>
                      + Batch Syllabus Register
                    </Title>
                  </center>
                </span>

                <Modal
                  title="Add Lessons"
                  fontSize={20}
                  centered
                  visible={visible}
                  width={1100}
                  onSave={() => setVisible(false)}
                  onCancel={() => setVisible(false)}
                  footer={null}
                >
                  <Row>
                    <Col span={11}>
                      <Space>
                        <span style={{ width: 160, color: '#249fe9', fontSize: '15px', fontWeight: 'bold' }}>Program Name : </span>
                        <Input style={{ width: 250 }} value={Programinputcreate} onInput={(e) => setPrograminputcreate(e.target.value)} />
                        <Select placeholder="level" onChange={handlebooklevelChange} style={{ width: '150px' }}>
                          <Option key="0" value="Phonics">
                            Phonics
                          </Option>
                          <Option key="1" value="1">
                            level 1
                          </Option>
                          <Option key="2" value="2">
                            level 2
                          </Option>
                          <Option key="3" value="3">
                            level 3
                          </Option>
                          <Option key="4" value="4">
                            level 4
                          </Option>
                          <Option key="5" value="5">
                            level 5
                          </Option>
                          <Option key="6" value="6">
                            level 6
                          </Option>
                          <Option key="7" value="7">
                            level 7
                          </Option>
                          <Option key="8" value="8">
                            level 8
                          </Option>
                          <Option key="9" value="9">
                            level 9
                          </Option>
                          <Option key="10" value="10">
                            level 10
                          </Option>
                          <Option key="11" value="11">
                            level 11
                          </Option>
                          <Option key="12" value="12">
                            level 12
                          </Option>
                        </Select>
                        {/*<Select placeholder="level" onChange={handlebooklevelChange} style={{ width: '150px' }}>
                          {TextbookLevelFormFilter.map((loopitem, loopkey) => (
                            <option key={loopkey} value={loopitem.levelkey}>
                              {loopitem.levelname}
                            </option>
                          ))}
                        </Select>*/}
                      </Space>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={12}>
                      <Space style={{ marginTop: '-10px' }}>
                        <span
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '10px',
                            height: '32px',
                            color: '#249fe9',
                          }}
                        >
                          Folder:
                        </span>
                        <Select style={{ width: '120px' }} value={InputFolder} onChange={handleFolderChangepopup}>
                          <Select.Option value="publisher">본사 컨텐츠</Select.Option>
                          <Select.Option value="campus">학원 컨텐츠</Select.Option>
                          <Select.Option value="me">개인 컨텐츠</Select.Option>
                        </Select>
                        <Button
                          className="btn-schedule"
                          type={'primary'}
                          size="large"
                          style={{ margin: 0, float: 'right', background: '#4e9b4e', borderColor: 'green' }}
                          onClick={(e) => addrowcreation(e)}
                        >
                          Add Lesson
                        </Button>
                        <span style={{ width: '20px' }}>&nbsp;</span>
                        <Input
                          placeholder="20"
                          style={{
                            margin: 0,
                            float: 'right',
                            height: '40px',
                            width: '50px',
                            marginBottom: '20px',
                            background: 'white',
                            borderColor: 'green',
                            marginTop: '15px',
                          }}
                          value={lessonInputNumber}
                          onChange={(e) => lessonInputChange(e)}
                        ></Input>
                        <span>
                          <Title className="header-classroom-title" level={5} style={{ color: 'green' }}>
                            Lessons
                          </Title>
                        </span>
                        <Button
                          className="btn-schedule"
                          type={'primary'}
                          size="large"
                          style={{ margin: 0, float: 'right', background: '#4e9b4e', borderColor: 'green' }}
                          onClick={(e) => rowcreation(e)}
                        >
                          Create
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}></Col>
                    <Col span={12}>
                      <Title className="header-classroom-title" level={4} style={{ color: 'red' }}>
                        {Validation}
                      </Title>
                    </Col>
                    <Col span={4}></Col>
                  </Row>

                  <Row style={{ border: '3px solid #8ec1e5', padding: '10px', background: '#d0dce5', borderColor: '#d0dce5' }}>
                    <Col span={1} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        No
                      </Title>
                    </Col>
                    <Col span={1} className="calendar-box"></Col>
                    <Col span={4} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;&nbsp;Days / Lessons
                      </Title>
                    </Col>
                    <Col span={1} className="calendar-box"></Col>
                    <Col span={7} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lesson Topic
                      </Title>
                    </Col>
                    <Col span={1} className="calendar-box"></Col>
                    <Col span={6} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PPT (url)
                      </Title>
                    </Col>
                    <Col span={1} className="calendar-box"></Col>
                    <Col span={2} className="calendar-box">
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;Delete
                      </Title>
                    </Col>
                    {/* Array(Math.max(addsyllabusinput)).join().split(',') */}

                    {syllabusLessonObjectInitialAdd.map((el, i) => (
                      <React.Fragment>
                        <Col span={1} className="calendar-box">
                          {i + 1}
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <Col span={4} className="calendar-box">
                          <Input onChange={(e) => inputhandleChange(i, e, 'dayslesson')} />
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <Col span={7} className="calendar-box">
                          <Input onChange={(e) => inputhandleChange(i, e, 'lessontopic')} />
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <Col span={6} className="calendar-box">
                          <Input onChange={(e) => inputhandleChange(i, e, 'ppturl')} />
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <Col span={2} className="calendar-box">
                          <Button
                            type="link"
                            danger
                            onClick={(e) => handlerowDelete(i)}
                            style={{ margin: 5, float: 'right', borderRadius: '5px', background: 'white', borderColor: 'white' }}
                          >
                            Delete
                          </Button>
                        </Col>
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                    <Col span={18} className="calendar-box"></Col>
                    <Button
                      className="btn-schedule"
                      type={tab === 'main' && 'primary'}
                      size="large"
                      style={{ margin: 5, float: 'right', borderRadius: '5px', background: 'orange', borderColor: 'orange' }}
                      onClick={() => setVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-schedule"
                      type={tab === 'main' && 'primary'}
                      size="large"
                      style={{ margin: 5, float: 'right', borderRadius: '5px' }}
                      onClick={handleAddSaveLesson}
                    >
                      Save
                    </Button>
                  </Row>
                </Modal>

                <Modal
                  title="Add Batch Syllabus Lessons"
                  fontSize={20}
                  centered
                  visible={visibleBatch}
                  width={1000}
                  onSave={() => setVisibleBatch(false)}
                  onCancel={() => setVisibleBatch(false)}
                  footer={null}
                >
                  <Row>
                    <Col span={16}>
                      <Space>
                        <span style={{ width: 160, color: '#249fe9', fontSize: '15px', fontWeight: 'bold' }}>Program Name : </span>
                        <Input style={{ width: 250 }} value={Programinputcreate} onInput={(e) => setPrograminputcreate(e.target.value)} />
                        <Select placeholder="level" onChange={handlebooklevelChange} style={{ width: '150px' }}>
                          <Option key="0" value="Phonics">
                            Phonics
                          </Option>
                          <Option key="1" value="1">
                            level 1
                          </Option>
                          <Option key="2" value="2">
                            level 2
                          </Option>
                          <Option key="3" value="3">
                            level 3
                          </Option>
                          <Option key="4" value="4">
                            level 4
                          </Option>
                          <Option key="5" value="5">
                            level 5
                          </Option>
                          <Option key="6" value="6">
                            level 6
                          </Option>
                          <Option key="7" value="7">
                            level 7
                          </Option>
                          <Option key="8" value="8">
                            level 8
                          </Option>
                          <Option key="9" value="9">
                            level 9
                          </Option>
                          <Option key="10" value="10">
                            level 10
                          </Option>
                          <Option key="11" value="11">
                            level 11
                          </Option>
                          <Option key="12" value="12">
                            level 12
                          </Option>
                        </Select>
                        <span
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '10px',
                            height: '32px',
                            color: '#249fe9',
                          }}
                        >
                          Folder:
                        </span>
                        <Select style={{ width: '120px' }} value={InputFolder} onChange={handleFolderChangepopup}>
                          <Select.Option value="publisher">본사 컨텐츠</Select.Option>
                          <Select.Option value="campus">학원 컨텐츠</Select.Option>
                          <Select.Option value="me">개인 컨텐츠</Select.Option>
                        </Select>
                      </Space>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={7} style={{ marginTop: '8px' }}>
                      <ExcelUpload setLoading={setLoading} fileInputKey={0} />
                    </Col>
                  </Row>
                  <br />
                  <Row style={{ border: '3px solid #8ec1e5', padding: '10px', borderColor: '#d0dce5' }}>
                    <Col span={1} className="calendar-box" style={{ background: '#d0dce5' }}>
                      <Title className="header-classroom-title" level={5}>
                        No
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box" style={{ background: '#d0dce5' }}></Col>
                    <Col span={5} className="calendar-box" style={{ background: '#d0dce5' }}>
                      <Title className="header-classroom-title" level={5}>
                        Days / Lessons
                      </Title>
                    </Col>
                    <Col span={1} className="calendar-box" style={{ background: '#d0dce5' }}></Col>
                    <Col span={6} className="calendar-box" style={{ background: '#d0dce5' }}>
                      <Title className="header-classroom-title" level={5}>
                        Lesson Topic
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box" style={{ background: '#d0dce5' }}></Col>
                    <Col span={5} className="calendar-box" style={{ background: '#d0dce5' }}>
                      <Title className="header-classroom-title" level={5}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PPT (url)
                      </Title>
                    </Col>
                    <Col span={2} className="calendar-box" style={{ background: '#d0dce5' }}></Col>
                    {syllabusLessonObjectInitialAdd.map((el, i) => (
                      <React.Fragment>
                        <Col span={1} className="calendar-box">
                          {i + 1}
                        </Col>
                        <Col span={2} className="calendar-box"></Col>
                        <Col span={5} className="calendar-box">
                          <label>{el.dayslesson}</label>
                          {/*<Input value={el.dayslesson} onChange={(e) => inputhandleChangeBulk(i, e, 'dayslesson')} />*/}
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <Col span={8} className="calendar-box">
                          <label>{el.lessontopic}</label>
                          {/*<Input value={el.lessontopic} onChange={(e) => inputhandleChangeBulk(i, e, 'lessontopic')} />*/}
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <Col span={5} className="calendar-box">
                          <label>{el.ppturl}</label>
                          {/*<Input value={el.ppturl} onChange={(e) => inputhandleChangeBulk(i, e, 'ppturl')} />*/}
                        </Col>
                        <Col span={1} className="calendar-box"></Col>
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                    <Col span={18} className="calendar-box"></Col>
                    <Button
                      className="btn-schedule"
                      type={tab === 'main' && 'primary'}
                      size="large"
                      style={{ margin: 5, float: 'right', borderRadius: '5px', background: 'orange', borderColor: 'orange' }}
                      onClick={() => setVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-schedule"
                      type={tab === 'main' && 'primary'}
                      size="large"
                      style={{ margin: 5, float: 'right', borderRadius: '5px' }}
                      onClick={handleBulkSaveLesson}
                    >
                      Save
                    </Button>
                  </Row>
                </Modal>
              </div>
            </div>

            <Dropdown className="syll_btn" placement="bottomRight">
              <Button style={{ marginBottom: '10px' }}>설정1</Button>
            </Dropdown>

            <CustomTable
              className="classroom-table-table"
              dataSource={syllabusLessonObject}
              columns={columns}
              pagination={{ pageSize: 12 }}
              size="small"
              color="#edf3fb"
              style={{ float: 'center' }}
              scroll={{ y: 'calc(100vh - 254px)' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {},
                };
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
}

export default OfflineSyllabus;
