import React, { useState, useEffect } from 'react';
import { Input, Select, DatePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { phoneNumberCheck } from 'src/utils';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { settings } from 'src/dummy/settings';

const CreateTeacherForm = ({ formValues, onChange, form, onSelectChange, onDateChange }) => {
  const [phone, setPhone] = useState('');
  // const [deleted_working_days, setDeletedWorkingDays] = useState('');
  // const [working_hour, setWorkingHour] = useState('');
  // const [current_salary, setCurrentSalary] = useState('');
  // const [account_number, setAccountNumber] = useState('');
  // const [children, setChildren] = useState('');
  // const [report_income, setReportIncome] = useState('');
  const [marital_status, setMaritalStatus] = useState('');
  const company = useSelector(classStoreData);
  const { Option } = Select;

  const handleMarriedStatus = (e) => {
    setMaritalStatus(e);
  };

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }

    onChange(e);
  };

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }

    form.setFieldsValue({
      phone: phone ? phone : undefined,
      email: formValues.email ? formValues.email : undefined,
      marital_status: marital_status ? marital_status : undefined,
    });
  }, [form, formValues.email, formValues.phone, onChange, phone, onSelectChange]);

  return (
    <>
      <FormField hasFeedback={true} title="이름">
        <FormItem name="name" rules={[{ required: true, message: '이름을 입력해 주세요.' }]} hasFeedback>
          <Input name="name" onChange={onChange} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="ID">
        <FormItem name="id" rules={[{ required: true, message: '아이디를 입력해 주세요.' }]} hasFeedback>
          <Input name="id" onChange={onChange} autoComplete="new-password" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="비밀번호">
        <FormItem
          name="pw"
          rules={[
            { required: true, message: '비밀번호를 입력해 주세요.' },
            {
              pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
              validator: async (rule, value) => {
                if (value.match(rule.pattern) !== null) {
                  return Promise.reject([rule.message]);
                }
              },
              message: '한글은 입력할 수 없습니다.',
            },
          ]}
          hasFeedback
        >
          {/* <Input type="password" name="pw" onChange={onChange} autoComplete="current-password" /> */}
          <Input.Password name="pw" onChange={onChange} autoComplete="new-password" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="비밀번호 확인">
        <FormItem
          name="confirm"
          dependencies={['pw']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log('value', value);
                console.log("Value('password')", typeof getFieldValue('pw'));
                if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                  return Promise.resolve();
                } else if (value.length < 4) {
                  return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                }
                return Promise.reject('입력하신 비밀번호가 일치하지 않습니다.');
              },
            }),
          ]}
        >
          <Input.Password />
        </FormItem>
      </FormField>

      <FormField title="Email">
        <FormItem
          name="email"
          hasFeedback
          rules={[
            {
              pattern: /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
              validator: async (rule, value) => {
                if (value && !rule.pattern.test(value)) {
                  return Promise.reject([rule.message]);
                } else {
                  return '';
                }
              },
              message: '정확한 이메일 형식을 입력해 주세요.',
            },
          ]}
        >
          <Input name="email" onChange={onChange} autoComplete="email" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="전화번호">
        <FormItem name="phone" rules={[{ required: false }]} hasFeedback>
          <Input name="phone" onChange={(e) => handleChange(e)} maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="메모">
        <FormItem name="memo" rules={[{ required: false }]} hasFeedback>
          <Input.TextArea name="memo" onChange={onChange} />
        </FormItem>
      </FormField>
      {settings[company].showExtraField === 'true' ? (
        <>
          <FormField hasFeedback={false} title="주소">
            <FormItem name="address" rules={[{ required: false }]} hasFeedback>
              <Input.TextArea name="address" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="근무요일">
            <FormItem name="deleted_working_days" rules={[{ required: false }]} hasFeedback>
              <Input name="deleted_working_days" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="근무시간">
            <FormItem name="working_hour" rules={[{ required: false, message: '근무시간' }]} hasFeedback>
              <Input name="working_hour" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="현 급여">
            <FormItem name="current_salary" rules={[{ required: false, message: '현 급여' }]} hasFeedback>
              <Input name="current_salary" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="입사일">
            <FormItem name="date_of_entry" rules={[{ required: true, message: '입사일' }]} hasFeedback>
              <DatePicker name="date_of_entry" onChange={(_, d) => onDateChange('date_of_entry', d)} style={{ width: '100%' }} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="주민등록번호">
            <FormItem name="resident_reg_no" rules={[{ required: false, message: '주민등록번호' }]} hasFeedback>
              <Input name="resident_reg_no" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="계좌번호">
            <FormItem name="account_number" rules={[{ required: false, message: '계좌번호' }]} hasFeedback>
              <Input name="account_number" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="최종학력(대학명/학과명시)">
            <FormItem name="academic_background" rules={[{ required: false, message: '최종학력(대학명/학과명시)' }]} hasFeedback>
              <Input name="academic_background" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="결혼유무">
            <FormItem name="marital_status" style={{ width: '100%' }} rules={[{ required: false, message: '결혼유무' }]}>
              <Select
                mode="single"
                showArrow
                allowClear
                name="marital_status"
                size={`default`}
                placeholder="결혼유무 상태"
                style={{ width: '95%' }}
                onChange={handleMarriedStatus}
              >
                <Option key="single" value="single">
                  Single
                </Option>
                <Option key="married" value="married">
                  Married
                </Option>
              </Select>
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="자녀">
            <FormItem name="children" rules={[{ required: false, message: '자녀' }]} hasFeedback>
              <Input name="children" onChange={onChange} />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="혈액형">
            <FormItem name="blood_type" rules={[{ required: false, message: '혈액형' }]} hasFeedback>
              <Input name="blood_type" onChange={onChange} />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="소득신고방법">
            <FormItem name="report_income" rules={[{ required: false, message: '소득신고방법' }]} hasFeedback>
              <Input name="report_income" onChange={onChange} />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="특이사항">
            <FormItem name="special_category" rules={[{ required: false, message: '특이사항' }]} hasFeedback>
              <Input name="special_category" onChange={onChange} />
            </FormItem>
          </FormField>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default CreateTeacherForm;
