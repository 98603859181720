import React from 'react';
import { Link } from 'react-router-dom';
// import { Col, Row, Radio, Button, Form, Input, InputNumber, Cascader, Select, Checkbox, AutoComplete } from 'antd';
import styled from 'styled-components';
import { useQuery} from '@apollo/client';
import { GET_USER_INFO } from 'src/operations/queries/campus';

// const { Option } = Select;

const InnerWrap = styled.div`
  background-color: #fff;
  width: 80%;
  height: 75%;
  max-width: 1800px;
  min-width: 650px;
  padding: 2rem 1rem;
  margin: 4rem auto;
  border-radius: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1400px) {
    max-width: 1150px;
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 33rem);
    height: calc(100% - 30rem);
    padding: 2rem 1rem;
    margin: 6rem auto;

    & {
      .fixed_btm_btn {
        bottom: 10rem;
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 9) {
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
    margin: 0 auto;

    & {
      .process-btn-con {
        padding: 0 4rem;
        a {
          img {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 10) {
    width: calc(97rem - 3rem);
    height: calc(53rem - 3rem);
    padding: 3rem 1rem;
    margin: 5rem auto;
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    width: calc(100% - 10rem);
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 750px;
    margin: 3rem auto;
  }

  @media (max-width: 1680px) and (aspect-ratio: 16 / 10) {
    width: calc(97rem - 3rem);
    height: calc(53rem - 3rem);
    padding: 2rem 1rem;
    margin: 2rem auto;

    & {
      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 2rem;
        }
      }
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 1rem 2rem 2rem;
    max-height: 650px;
    margin: 2rem auto;
  }

  @media (max-width: 1440px) and (aspect-ratio: 16 / 10) {
    width: calc(90rem - 18rem);
    height: calc(45rem - 3rem);

    & {
      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 100%;
    max-width: 1000px;
    max-height: 500px;
    margin: 0 auto;
    padding: 0;
  }

  @media screen and (max-width: 1280px) and (aspect-ratio: 16 / 10) {
    height: calc(45rem - 3rem);
    margin: 6rem auto;
  }

  @media (max-width: 1366px) {
    width: calc(100% - 5rem);
  }

  @media (max-width: 1024px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 80%;
    max-width: 800px;
    max-height: 400px;
    margin: 0 auto;

    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
  }

  @media (max-width: 1024px) {
    width: calc(100% - 7rem);
    height: calc(100% - 15rem);
  }

  @media screen and (max-width: 950px) and (orientation: landscape) {
    height: auto;
    min-height: 450px;
    margin: 0 auto;
  }

  /* galaxy tab */
  @media only screen and (max-width: 820px) and (orientation: portrait) {
    height: calc(90vh - 5rem);
    margin: 1rem auto;
  }

  /* air */
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    // height: calc(90vh - 10rem);
    height: auto;
    max-height: 100%;
    padding: 0 1rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  }

  @media screen and (max-width: 800px) and (orientation: portrait) {
    height: calc(100vh - 9rem);
    margin: 4rem auto 0;
  }

  @media screen and (max-width: 800px) and (orientation: landscape) {
    padding: 0 1rem;
    height: auto;
    min-height: 600px;
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    padding: 0 1rem;
    margin: 2rem auto 0rem;
    // height: calc(100vh - 5rem);
    width: calc(100% - 5rem);
    min-width: auto;
    max-width: 100%;
  }
`;

const ImageTest = () => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx===1016 ? true : false;
  return (
    <>
      <div className={isWEnglish ? 'meta-con meta-full-con w-english-bg' : 'meta-con meta-full-con'}>
        <div className="meta-header test-header">
          <div className="burger-wrap">
            <img src="../images/meta_hamburger.png" alt="burger_meta" />
            <h2>Question List</h2>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <div className="time-wrap">
              <h2>Time: </h2>
              <h2 className="num">10:00</h2>
            </div>
            <div className="question-wrap">
              <h2>Question: </h2>
              <h2 className="num">10/30</h2>
            </div>
          </div>
        </div>
        <InnerWrap>
          <div className="meta-header readingTest-header">
            <h1 className="main-tit" style={{ color: '#' }}>
              Reading Passage
            </h1>
          </div>

          <div className="readingtest-con">
            <div className="readingtest-wrap passage-con passage-con-real" style={{ overflowY: 'auto' }}>
              <img src="../images/sampleImgTest.jpg" alt="sampleImgTest_image" style={{ width: '100%' }} />
            </div>
            <div className="readingtest-wrap qna-real">
              <div className="reading-q-con">
                <h1>10. Find the correct answer in the image.</h1>
              </div>
              <div className="reading-a-con">
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>a</h3>
                  </div>
                  <div className="reading-a">
                    <h3>apple</h3>
                  </div>
                </div>
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>b</h3>
                  </div>
                  <div className="reading-a">
                    <h3>peach</h3>
                  </div>
                </div>
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>c</h3>
                    <img src="../images/check.png" alt="burger_meta" className="checkedImg" />
                  </div>
                  <div className="reading-a">
                    <h3>book</h3>
                  </div>
                </div>
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>d</h3>
                  </div>
                  <div className="reading-a">
                    <h3>orange</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* bottom btn */}
          <div className="process-btn-con">
            <Link to="">
              <img src="../images/back_meta.png" alt="back_meta" />
            </Link>

            <Link to="./MetaFinished" style={{ marginLeft: 'auto' }}>
              <img src="../images/next_meta.png" alt="next_meta" />
            </Link>
          </div>
        </InnerWrap>
        {!isWEnglish && (
        <div className="report-footer-con">
          <h5>
            Powered by {'     '}
            <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
              Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
            </span>
          </h5>
          <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
        </div>
        )}
      </div>
    </>
  );
};

export default ImageTest;
