import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row, Tabs, Radio, Card, Spin } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { BOOK_LIST_BY_PREFIX, BOOK_LIST_BY_USER_IDX } from 'src/operations/queries/book';

const CONTENT_CDN_BASE_URL = 'https://culp-api.cloubot.com';

const { Meta } = Card;
const BookCard = styled(Card)`
  .ant-card-body {
    padding: 5px;
    height: 40px;
  }
`;
const BookMeta = styled(Meta)`
  .ant-card-body {
    padding: 5px;
  }

  .ant-card-meta-title {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 1px;
  }
`;

const BookLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  min-width: 1200px;
  background-color: #fff;
`;
const { TabPane } = Tabs;
const CATEGORY = { 10: 'Main book', 20: 'Workbook', 30: 'Story Book', 40: 'Music Story' };

const Evine = () => {
  const isStudent = useMemo(() => {
    if (userInfoVar()?.type === 0) {
      return true;
    }
    return false;
  }, []);

  const [levelNumber, setLevelNumber] = useState(0);
  const [tabNo, setTabNo] = useState(0);

  const { data, loading } = useQuery(isStudent ? BOOK_LIST_BY_USER_IDX : BOOK_LIST_BY_PREFIX, {
    variables: isStudent ? { user_idx: parseInt(userInfoVar().idx) } : { prefix: 'EVN' },
  });

  const bookList = useMemo(() => {
    if (data) {
      return isStudent ? data?.bookListByUserIdx : data?.bookListByPrefix;
    }
    return [];
  }, [data, isStudent]);

  const levels = useMemo(() => {
    if (bookList) {
      return bookList.reduce((acc, cur) => {
        const isExistCat = cur.book_sub.some((item) => {
          return item.book_category.no === parseInt(Object.keys(CATEGORY)[tabNo]);
        });
        if (isExistCat) {
          acc[cur.level_no] = cur.level_name;
        }
        return { ...acc };
      }, {});
    }
    return {};
  }, [bookList, tabNo]);

  useEffect(() => {
    setLevelNumber(Object.keys(levels)[0]);
  }, [levels]);

  const handleChangeLevel = (evt) => {
    const {
      target: { value },
    } = evt;
    setLevelNumber(value);
  };
  const handleTabChange = (no) => {
    setTabNo(no);
    setLevelNumber(Object.keys(levels)[0]);
  };
  const handleClickToEbook = (path) => {
    const realPath = !isStudent ? `${CONTENT_CDN_BASE_URL}${path}` : `${CONTENT_CDN_BASE_URL}${path.replace('/teacher/', '/student/')}`;
    window.open(realPath);
  };

  return (
    <>
      <HeaderTitle level={4}>EBook</HeaderTitle>
      <Spin spinning={loading}>
        <BookLayout>
          <Tabs defaultActiveKey={0} type="card" size="small" onChange={handleTabChange} style={{ width: '100%' }}>
            {Object.keys(CATEGORY).map((no, key) => (
              <TabPane tab={`${CATEGORY[no]}`} key={key}>
                <span style={{ fontSize: 'l.8em', fontWeight: 'bold' }}>&nbsp; Level </span>
                <Radio.Group value={levelNumber} style={{ marginBottom: 16 }} onChange={handleChangeLevel}>
                  {levels &&
                    Object.keys(levels).map((levelNo, key) => (
                      <Radio.Button key={`radio-level-key${key}`} value={levelNo}>
                        {levels[levelNo]}
                      </Radio.Button>
                    ))}
                </Radio.Group>
                {bookList &&
                  bookList
                    .filter((book) => {
                      const res = parseInt(book.level_no) === parseInt(levelNumber);
                      if (res) {
                        const find = book.book_sub.findIndex((bo) => {
                          return parseInt(bo.book_category.no) === parseInt(no);
                        });
                        if (find === -1) {
                          return false;
                        }
                      }
                      return res;
                    })
                    .map((item, key) => (
                      <Row key={`book-row-${key}`} gutter={[16, 16]} style={{ borderBottom: '1px solid #ccc' }}>
                        <Col
                          span={2}
                          style={{
                            backgroundColor: '#94d82d',
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{ textAlign: 'center' }}>{item.volume_name}</div>
                        </Col>
                        {item.book_sub &&
                          item.book_sub
                            .filter((book_sub_temp, index) => {
                              const find = item.book_sub.findIndex((temp) => {
                                return temp.title === book_sub_temp.title;
                              });
                              return find === index;
                            })
                            .filter((book_sub) => {
                              return parseInt(book_sub?.book_category?.no) === parseInt(no);
                            })
                            .map((item2, key2) => {
                              return (
                                <Col key={`cat-book-${key}-${key2}`} span={3}>
                                  <BookCard
                                    onClick={() => {
                                      handleClickToEbook(item2.ebook_path);
                                    }}
                                    hoverable
                                    cover={
                                      <div
                                        style={{
                                          width: '100%',
                                          minWidth: '100px',
                                          height: '200px',
                                          backgroundColor: '#fff',
                                          backgroundImage: `url(${CONTENT_CDN_BASE_URL}${item2.cover_path
                                            .replace('(', '%28')
                                            .replace(')', '%29')})`,
                                          backgroundSize: 'contain',
                                          backgroundRepeat: 'no-repeat',
                                          backgroundPosition: 'center center',
                                          border: '1px solid #eee',
                                        }}
                                      />
                                    }
                                  >
                                    <BookMeta title={item2.title} />
                                  </BookCard>
                                </Col>
                              );
                            })}
                      </Row>
                    ))}
              </TabPane>
            ))}
          </Tabs>
        </BookLayout>
      </Spin>
    </>
  );
};

export default Evine;
