import { gql } from '@apollo/client';

export const GET_ROUTE_ITEM_LIST = gql`
  query getRouteItemList {
    routeItemList {
      idx
      name
      route
      type
      pass_user_info
      is_root_only
    }
  }
`;
