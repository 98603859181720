import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Badge, DatePicker } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import * as queries from 'src/operations/queries';

const sortByDate = (isDescend) => (a, b) => {
  if (moment(a.org_date).isSame(b.org_date, 'day')) {
    return 0;
  }
  const compared = isDescend ? moment(a.org_date).isAfter(b.org_date, 'day') : moment(a.org_date).isBefore(b.org_date, 'day');
  if (compared) {
    return -1;
  }
  return 1;
};

const stageGroupColor = {
  v: '#ffce54',
  s: '#5d9cec',
  e: '#a0d468',
  reading: '#f52516',
  listening: '#f5ef0a',
  grammar: '#04a7f5',
};
let columns = [];
if (window.location.pathname == '/elibrary/manage/action/result') {
  columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '출제날짜',
      dataIndex: 'date',
      align: 'center',
      width: 100,
      sorter: sortByDate(false),
    },
    {
      key: 'Title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
    },
  ];
} else {
  columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '출제날짜',
      dataIndex: 'date',
      align: 'center',
      width: 100,
      sorter: sortByDate(false),
    },
    {
      key: 'Title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
    },
    {
      key: 'view',
      title: '확인',
      dataIndex: 'view',
      align: 'center',
      render: (_, row) => {
        const { key, className, class_idx, title } = row;
        return (
          <Link to={{ pathname: `/elibrary/learning/${class_idx}/${key}`, state: { className, title } }}>
            <Button>View</Button>
          </Link>
        );
      },
    },
    {
      key: 'Edit',
      title: '수정',
      dataIndex: 'Edit',
      align: 'left',
      render: (_, row) => {
        const { key, className, class_idx, title } = row;
        return (
          <Link to={{ pathname: `/books/manage/action/assign/`, state: { className, title } }}>
            <Button>Edit</Button>
          </Link>
        );
      },
    },
  ];
}
const ResultsPage = () => {
  const [searchName, setSearchName] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const { data: dataUser } = useQuery(GET_USER_INFO);

  // const {
  //   data: fetchassignedElibraryGroupList,
  //   refetch,
  //   loading,
  // } = useQuery(queries.elibraryBookList.ASSIGNED_ELIBRARY_GROUP_LIST, {
  //   fetchPolicy: 'no-cache',
  // });

  // const {
  //     data: onloadfetchassignedElibraryGroupList,
  //     loading: offlineLoading
  // } = useQuery(queries.elibraryBookList.ASSIGNED_ELIBRARY_GROUP_LIST, {
  //     variables: {
  //         "class_idx": 0
  //     }
  // });

  const onload_handleDateChange = (date, dateString) => {
    // if(offlineLoading !== undefined && onloadfetchassignedElibraryGroupList !== undefined){
    //   let fetchData = onloadfetchassignedElibraryGroupList.assignedElibraryGroupList;
    //   if(fetchData.length !== 0){
    //     let dateresult = []
    //     fetchData.map((fetch_data,key) => {
    //       let assigned_elibrary_loop = fetch_data.assigned_elibrary;
    //       let loop = false;
    //       assigned_elibrary_loop.map((e_loopdata) => {
    //         if((e_loopdata.start_date).split('T')[0] == dateString){
    //           loop = true;
    //         }
    //       })
    //       if(loop == true){
    //         dateresult.push(fetchData[key]);
    //       }
    //     })
    //     var onload_assignedElibraryGroupList = dateresult.reduce((acc, cur, index) => {
    //       let onload_totalCnt = cur?.assigned_elibrary?.length;
    //       let onload_completed = cur?.assigned_elibrary?.reduce((acc, cur, index) => {
    //         if (cur && moment(`${moment(cur?.start_time).format('YYYY-MM-DD')}`).isBefore(`${moment().format('YYYY-MM-DD')}`)) {
    //           acc++;
    //         }
    //         return acc;
    //       }, 0);
    //       let onload_obj = {
    //           title: `${cur?.title}`,
    //           date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
    //           start: moment(cur?.begin_date).format('YYYY-MM-DD'),
    //           end: moment(cur?.end_date).format('YYYY-MM-DD'),
    //           //testTime: cur?.test_time,
    //           className: cur?.class?.name,
    //           total: `${onload_completed} / ${onload_totalCnt}`,
    //           No: index + 1,
    //           key: cur.idx,
    //           class_idx: cur?.class?.idx,
    //       };
    //       return [...acc, onload_obj];
    //   }, []);
    //   }
    // }
  };

  if (window.location.pathname == '/elibrary/manage/action/result') {
    // if(offlineLoading !== undefined && onloadfetchassignedElibraryGroupList !== undefined){
    //   let fetchData = onloadfetchassignedElibraryGroupList.assignedElibraryGroupList;
    //   if(fetchData.length !== 0){
    //     let dateresult = []
    //     fetchData.map((fetch_data,key) => {
    //       let assigned_elibrary_loop = fetch_data.assigned_elibrary;
    //       let loop = false;
    //       assigned_elibrary_loop.map((e_loopdata) => {
    //         if((e_loopdata.start_date).split('T')[0] == new Date().toJSON().slice(0,10).replace(/-/g,'-')){
    //           loop = true;
    //         }
    //       })
    //       if(loop == true){
    //         dateresult.push(fetchData[key]);
    //       }
    //     })
    //     var onload_assignedElibraryGroupList = dateresult.reduce((acc, cur, index) => {
    //       let onload_totalCnt = cur?.assigned_elibrary?.length;
    //       let onload_completed = cur?.assigned_elibrary?.reduce((acc, cur, index) => {
    //         if (cur && moment(`${moment(cur?.start_time).format('YYYY-MM-DD')}`).isBefore(`${moment().format('YYYY-MM-DD')}`)) {
    //           acc++;
    //         }
    //         return acc;
    //       }, 0);
    //       let onload_obj = {
    //           title: `${cur?.title}`,
    //           date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
    //           start: moment(cur?.begin_date).format('YYYY-MM-DD'),
    //           end: moment(cur?.end_date).format('YYYY-MM-DD'),
    //           //testTime: cur?.test_time,
    //           className: cur?.class?.name,
    //           total: `${onload_completed} / ${onload_totalCnt}`,
    //           No: index + 1,
    //           key: cur.idx,
    //           class_idx: cur?.class?.idx,
    //       };
    //       return [...acc, onload_obj];
    //   }, []);
    //   }
    // }
  }

  const { selectedClassIdx, onClassChange } = useCampusClass();

  const assignedElibraryGroupList = useMemo(() => {
    // if (fetchassignedElibraryGroupList?.assignedElibraryGroupList) {
    //   let fetchData = null;
    //   const class_idx = parseInt(selectedClassIdx);

    //   if (class_idx > 0) {
    //     fetchData = fetchassignedElibraryGroupList?.assignedElibraryGroupList.filter((item) => item?.class.idx === class_idx);
    //   } else {
    //     fetchData = fetchassignedElibraryGroupList?.assignedElibraryGroupList;
    //   }

    //   return fetchData.reduce((acc, cur, index) => {
    //     const totalCnt = cur?.assigned_elibrary?.length;

    //     const completed = cur?.assigned_elibrary?.reduce((acc, cur, index) => {
    //       // moment('2010-10-20').isBefore('2010-10-21'); // true
    //       if (cur && moment(`${moment(cur?.start_time).format('YYYY-MM-DD')}`).isBefore(`${moment().format('YYYY-MM-DD')}`)) {
    //         acc++;
    //       }
    //       return acc;
    //     }, 0);

    //     const obj = {
    //       title: `${cur?.title}`,
    //       date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
    //       start: moment(cur?.begin_date).format('YYYY-MM-DD'),
    //       end: moment(cur?.end_date).format('YYYY-MM-DD'),
    //       //testTime: cur?.test_time,
    //       className: cur?.class?.name,
    //       total: `${completed} / ${totalCnt}`,
    //       No: index + 1,
    //       key: cur.idx,
    //       class_idx: cur?.class?.idx,
    //     };

    //     return [...acc, obj];
    //   }, []);
    // }

    return [];
  }, [selectedClassIdx]);

  const handleDateChange = (date, dateString) => {
    console.log(dateString);
    setSearchDate(dateString);
  };

  return (
    <div className="manage-action-wrapper-main">
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
          width: '100%',
        }}
      >
        <HeaderTitle level={4}>Homework Result</HeaderTitle>
        <div className="toparea-main">
          <ClassSelectBox
            onClassChange={onClassChange}
            selectedClassIdx={selectedClassIdx}
            campus_idx={dataUser?.getUserData?.campus_idx}
            style={{ width: '40%', textAlign: 'left' }}
          />
          <DatePicker
            style={{ marginLeft: '10px' }}
            onChange={(date, dateString) => {
              handleDateChange(date, dateString);
            }}
          />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="books-result-halfwrapper">
            <CustomTable
              dataSource={assignedElibraryGroupList}
              columns={columns}
              key={(r) => r.idx}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={false}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default ResultsPage;
