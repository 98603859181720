import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ASSIGN_TEST_DELETE, TEST_DELETE } from 'src/operations/mutations/createTests';

import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { MainPage, ResultsPage, StudentResults } from 'src/components/Test';

const TestDetails = () => {
  const { type = 'list' } = useParams();
  
  const [assignTestDelete] = useMutation(ASSIGN_TEST_DELETE);
  const [testDelete] = useMutation(TEST_DELETE);

  const handleTestAssignDelete = async (data) => {
    try {
      await assignTestDelete({ variables: data });
    } catch (e) {
      console.log(e);
    }
  }

  const handleTestDelete = async (data) => {
    try {
      await testDelete({ variables: data });
    } catch (e) {
      console.log(e);
    }
  }

  const [user, setUser] = useState({});
  const token = getCurrentToken();
  const { data } = useQueryProxy(USER_ME, {
    variables: { token },
  });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);
  return (
    <>
      <Row gutter={[24, 16]}>
        {type === 'result' ? (
          <ResultsPage onTestAssignDelete={handleTestAssignDelete} user={user} />
        ) : (
          user.idx ? ( user.type !== 0 ?
         <MainPage user={user} onTestDelete={handleTestDelete} /> : <StudentResults user={user} />) : null
        )}
      </Row>
    </>
  );
};

export default TestDetails;
