import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Row, Button, Select } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { ASSIGNED_TEST_USER_LIST } from 'src/operations/queries/getTests';
import { GET_TYPE_CLASS } from 'src//operations/queries/getClass';
import { useQuery, ApolloConsumer } from '@apollo/client';
import moment from 'moment';

const { Title } = Typography;

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 10px;
`;

const StudentResultsPage = (props) => {
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');

  const [classes, setClasses] = useState([]);
  const [assigned_Users, setAssignedUsers] = useState([]);
  const [class_idx, setClassIdx] = useState(null);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);

  const { data, refetch } = useQuery(ASSIGNED_TEST_USER_LIST, {
    variables: { class_idx, page, take },
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (data?.assignedTestUserList) {
      setAssignedUsers(data?.assignedTestUserList.assigned_test_users);
      setTotal(data?.assignedTestUserList.total);
    }

    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
    if (isRefresh) {
      refetch();
      props.client.clearStore();
      props.client.cache.gc();
      props.history.replace({ search: '' });
    }
  }, [data, classQuery, isRefresh, props, refetch]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: 80,
    },
    {
      title: 'Date',
      dataIndex: 'start_time',
      align: 'center',
      key: 'date',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{record.assigned_tests.start_time.substr(0, 10)}</Space>;
      },
    },
    {
      title: 'Test Number',
      dataIndex: 'number',
      key: 'number',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.assigned_tests.tests.code}</Space>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.assigned_tests.title}</Space>;
      },
    },
    {
      title: 'Completed',
      dataIndex: 'done',
      width: 100,
      key: 'total',
      align: 'center',
      render: (text, { status, assigned_test_answers, score }) => {
        return <Space size="middle">{status === 1 ? 'Not complete' : ((100 * score) / assigned_test_answers.length).toFixed()}</Space>;
      },
    },
    {
      title: 'Action',
      key: '',
      width: 100,
      render: (text, record) => {
        const startT = moment(new Date(`${record.assigned_tests.start_time.replace('Z', '')}+09:00`));
        if (moment().isAfter(startT)) {
          if (record.status === 1) {
            return (
              <Space size="middle">
                <Link to={{ pathname: `/test/new/${record.assigned_tests.idx}/take`, state: { fromList: true } }}>
                  <Button>Open</Button>
                </Link>
              </Space>
            );
          } else {
            return (
              <Space size="middle">
                <Link to={`/test/new/${record.assigned_tests.idx}/default`}>
                  <Button>Open</Button>
                </Link>
              </Space>
            );
          }
        } else {
          return <Space>Not Open Yet</Space>;
        }
      },
    },
  ];

  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]}>
            <Col className="contenttest-title-result" span={6} style={{ textAlign: 'left' }}>
              <Title level={4}>Test Results</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Select
                style={{ width: 150, color: '#065aa9', marginRight: 10 }}
                placeholder="Select a Class"
                onChange={(idx) => setClassIdx(idx)}
              >
                {classes.map((item) => (
                  <Select.Option key={item.idx} value={item.idx} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            dataSource={assigned_Users}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            scroll={{ y: 'calc(100vh - 254px)' }}
            pagination={{ pageSize: take, total }}
            size="small"
            color="#edf3fb"
            onChange={(a) => setPage(a.current - 1)}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => onHandleRowClick(record, rowIndex),
              };
            }}
          />
        </MainBlock>
      </Col>
    </>
  );
};

const WithApolloClient = (props) => <ApolloConsumer>{(client) => <StudentResultsPage client={client} {...props} />}</ApolloConsumer>;

export default withRouter(WithApolloClient);
