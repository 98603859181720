import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

const appLinks = {
  creo: {
    android: 'https://play.google.com/store/apps/details?id=com.culpmobile.creo',
    ios: 'itms://apps.apple.com/app/creo-bot/id6446823781',
  },
  lucid: {
    android: 'https://play.google.com/store/apps/details?id=com.culpmobile.lucid',
    ios: 'itms://apps.apple.com/app/lucid-english/id6446832997',
  },
  default: {
    android: 'https://play.google.com/store/apps/details?id=com.culpmobile',
    ios: 'itms://apps.apple.com/app/class-boom/id6446192346',
  },
};

function OpenAppModal({ url, handleClose, visible, waitTime = 5000 }) {
  const defaultClass = useSelector(classStoreData);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(true);
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setIsIFrameLoaded(false);
      }, 5000);

      setTimeout(() => {
        handleClose();
      }, waitTime);
    }
  }, [visible]);

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  const getApplicationLink = (type) => {
    if (defaultClass === 'creo') {
      return appLinks.creo[type];
    } else if (defaultClass === 'lucid') {
      return appLinks.lucid[type];
    } else {
      return appLinks.default[type];
    }
  };

  return (
    <>
      <Modal
        centered
        visible={visible}
        title={
          <>
            <div style={{ fontWeight: '600' }}>*앱 다운로드</div>
            <div style={{ fontWeight: '600' }}> 앱 다운로드가 필요하면, 아래 4개의 스토어에서 디바이스에 적합한 스토어를 선택하세요!</div>
          </>
        }
        onCancel={handleClose}
        width={'40%'}
        footer={null}
      >
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          {isIFrameLoaded && <Spin size="large" />}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              padding: '10px',
            }}
          >
            Click here if you are not redirected automatically
          </a>
          <p style={{ color: '#545454', fontWeight: '600' }}>※이 창은 최초 앱 다운로드시에만 필요합니다.</p>
          <p style={{ color: '#545454', fontWeight: '600' }}>앱 설치 후에는 학습창을 열기 위한 과정으로 자동으로 화면이 사라집니다.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <a
                id="download_app_android"
                href={getApplicationLink('android')}
                target="_blank"
                className="vc_single_image-wrapper   vc_box_border_grey"
                rel="noopener"
              >
                <img
                  width="100"
                  height="26"
                  src="/images/playstore.svg"
                  className="vc_single_image-img attachment-full"
                  alt=""
                  loading="lazy"
                  data-id="168"
                />
              </a>
              <h2 style={{ color: '#545454', fontWeight: '700' }}>Android</h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <a
                id="download_app_apple"
                href={getApplicationLink('ios')}
                target="_blank"
                className="vc_single_image-wrapper   vc_box_border_grey"
                rel="noopener"
              >
                <img
                  width="100"
                  height="26"
                  src="/images/appstore.svg"
                  className="vc_single_image-img attachment-full"
                  alt=""
                  loading="lazy"
                  data-id="167"
                />
              </a>
              <h2 style={{ color: '#545454', fontWeight: '700' }}>iOS</h2>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OpenAppModal;
