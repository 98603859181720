import { gql } from '@apollo/client';

export const MESSSAGE_LIST = gql`
  query chatRoomThreads($chat_room_idx: Int!, $last_idx: Int) {
    chatRoomThreads(chat_room_idx: $chat_room_idx, last_idx: $last_idx) {
      idx
      chat_room_idx
      chat_user_idx
      chat_user_name
      content
      idate
      read_yn
      read_count
      chat_room {
        chat_room_user {
          user {
            idx
          }
        }
      }
    }
  }
`;

export const GET_CHAT_ROOMS = gql`
  query chatRooms($user_idx: Int!) {
    chatRooms(user_idx: $user_idx) {
      idx
      idate
      lastest
      sender(user_idx: $user_idx)
      class_list(user_idx: $user_idx)
    }
  }
`;

export const GET_CHAT_ROOM_LIST = gql`
  query chatRoomList($user_idx: Int!) {
    chatRoomList(user_idx: $user_idx) {
      idx
      subject
      chat_room {
        idx
        subject
        lastestInfo {
          content
          idate
        }
        idate
        chat_message {
          chat_user_idx
          chat_message_read_user {
            chat_user_idx
          }
        }
      }
    }
  }
`;

export const GET_CAMPUS_STUDENT_LIST = gql`
  query getUsers($type: String!, $class_idx: Int) {
    getUsers(type: $type, class_idx: $class_idx) {
      idx
      id
      name
      class_student {
        class {
          name
        }
      }
    }
  }
`;

export const GET_STUDENT_LIST = gql`
  query classTeachers($teacher_idx: Int!) {
    classTeachers(teacher_idx: $teacher_idx) {
      class {
        name
        class_student {
          user {
            idx
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_TEACHER_LIST = gql`
  query classTeachersForStudent($student_idx: Int!) {
    classTeachersForStudent(student_idx: $student_idx) {
      class {
        name
        class_teacher {
          user {
            idx
            id
            name
          }
        }
      }
    }
  }
`;
