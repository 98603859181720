import React, { useState, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';
import { Col, Row, Typography, Select, Anchor, Button, DatePicker, Table } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
//import imagesearch from 'src/images/search.png';
import imageprinter from 'src/images/printer.png';
import imageexcel from 'src/images/xlsicon.png';
import { GET_BOOK_RETURN_LIST, GET_BOOK_RETURN_LIST_BY_DURATIOIN } from 'src/operations/queries/bookReturn';
import BookOrderMenuGroup from 'src/components/common/BookOrderMenuGroup';
import moment from 'moment';
import { MAX_SEARCH_INTERVAL_DAYS, DATE_SEARCH_OPTIONS, BOOK_RETURN_STATS } from 'src/constants/common';
import BookReturnStatusModal from 'src/components/Modal/BookReturn/BookReturnStatusModal';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { ExportExcel } from 'src/utils/index';
import { useReactToPrint } from 'react-to-print';
import { PrintDummy } from 'src/components/common/Styles';
import { isAfterSchool } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory } from 'react-router-dom';

const { RangePicker } = DatePicker;
const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const columns = [
  {
    title: '번호',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '날짜',
    dataIndex: 'idate',
    key: 'idate',
  },
  {
    title: '반품상품',
    dataIndex: 'return_title',
    key: 'return_title',
  },
  {
    title: '주문자',
    dataIndex: 'return_name',
    key: 'return_name',
  },
  {
    title: '상태',
    dataIndex: 'stat',
    key: 'stat',
    render: (stat, { onView, book_list, comment, idx }) => (
      <Button
        size="small"
        type={stat === 0 ? 'danger' : 'primary'}
        onClick={() => {
          onView(stat, book_list, comment, idx);
        }}
      >
        {BOOK_RETURN_STATS[stat]}
      </Button>
    ),
  },
];
const columnsForSmartEclass = [
  {
    title: '번호',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '날짜',
    dataIndex: 'idate',
    key: 'idate',
  },
  {
    title: '반품상품',
    dataIndex: 'return_title',
    key: 'return_title',
  },
  {
    title: '주문자',
    dataIndex: 'return_name',
    key: 'return_name',
  },
  {
    title: '수량',
    dataIndex: 'return_count',
    key: 'return_count',
  },
  {
    title: '신청학교',
    dataIndex: 'campus_name',
    key: 'campus_name',
  },
  {
    title: '소속 지사',
    dataIndex: 'branch_name',
    key: 'branch_name',
  },
  {
    title: '상태',
    dataIndex: 'stat',
    key: 'stat',
    render: (stat, { onView, book_list, comment, idx }) => (
      <Button
        size="small"
        type={stat === 0 ? 'danger' : 'primary'}
        onClick={() => {
          onView(stat, book_list, comment, idx);
        }}
      >
        {BOOK_RETURN_STATS[stat]}
      </Button>
    ),
  },
];

const { Title } = Typography;
const { Option } = Select;
const DEFAULT_DATE_RANGE = [moment().subtract(1, 'months'), moment()];

const BookReturnList = () => {
  const printRef = useRef();
  const history = useHistory();
  const companyName = useSelector(classStoreData);
  const [modalVisible, setModalVisible] = useState(false);
  const [bookList, setBookList] = useState([]);
  const [comment, setComment] = useState('');
  const [returnStatus, setReturnStatus] = useState(0);
  const [bookReturnIdx, setBookReturnIdx] = useState(0);
  const [dateSearchOption, setDateSearchOption] = useState(DATE_SEARCH_OPTIONS[0].value);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
  const { data, loading, refetch } = useQuery(dateSearchOption === 0 ? GET_BOOK_RETURN_LIST : GET_BOOK_RETURN_LIST_BY_DURATIOIN, {
    variables:
      dateSearchOption === 0 ? { ym: selectedMonth } : { start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD') },
    fetchPolicy: 'no-cache',
  });
  const dataSource = useMemo(() => {
    const list = data?.getBookReturnList || data?.getBookReturnListByDuration;
    if (list) {
      return list.map((item, key) => {
        const return_count = item?.book_return_list?.reduce((acc, cur) => {
          return acc + parseInt(cur.quantity);
        }, 0);
        return {
          key: item.idx,
          idx: item.idx,
          no: list.length - key,
          return_title: item.title,
          idate: moment(item.idate).format('YYYY-MM-DD'),
          return_name: item?.user?.name,
          return_count,
          campus_name: item?.campus?.name,
          branch_name: item?.campus?.type === '2' ? item?.campus?.name : item?.campus?.campus?.name,
          stat: Number.isNaN(parseInt(item?.stat)) ? 0 : parseInt(item?.stat),
          onView: handleViewDetail,
          comment: item?.comment,
          book_list: item?.book_return_list,
        };
      });
    }
    return [];
  }, [data]);

  function handleDisabledDate(current) {
    return current && current > moment().endOf('day');
  }
  function handleViewDetail(stat, book_list, comment, idx) {
    setModalVisible(true);
    setReturnStatus(stat);
    setBookList(book_list);
    setComment(comment);
    setBookReturnIdx(idx);
  }
  function handleRefetch() {
    refetch();
  }
  function handleExportToExcel(e) {
    e.preventDefault();
    const realColumns = isAfterSchool(companyName) ? columnsForSmartEclass : columns;
    const fileName = `반품내역`;
    ExportExcel(realColumns, dataSource, fileName);
  }
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const type = userInfoVar()?.type;
  if (isAfterSchool(companyName)) {
    if (type && ![1, 2, 3].includes(type)) {
      history.replace('/');
    }
  } else {
    if (type && type !== 2) {
      history.replace('/');
    }
  }

  return (
    <>
      <Col className="orderadmin-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <Row gutter={[24, 16]}>
          <Col className="orderadmin-wrapper-main-a" span={8} style={{ textAlign: 'left' }}>
            <BookOrderMenuGroup currentMenu="book-return-list" />
          </Col>
          <Col className="orderadmin-imagesec" span={16} style={{ textAlign: 'left' }}>
            <Anchor>
              {/* <Link>
                <img src={imagesearch} alt="search" />
              </Link> */}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                <img src={imageprinter} alt="print" />
              </a>
              <a href="/" onClick={handleExportToExcel}>
                <img src={imageexcel} alt="excel" />
              </a>
            </Anchor>
          </Col>
        </Row>
        <MainBlock className="orderadmin-wrapper-mainblock">
          <Row gutter={[24, 16]} className="orderadmin-top-head">
            <Col className="orderadmin-top-title" span={8} style={{ textAlign: 'left' }}>
              <Title level={5}>교재반품</Title>
            </Col>
            <Col className="orderadmin-top-rightform" span={16} style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}>
              <div className="purchase-select-option">
                <Select placeholder="선택" value={dateSearchOption} onChange={setDateSearchOption}>
                  {DATE_SEARCH_OPTIONS.map((item) => (
                    <Option key={`search-month-option${item.value}`} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                {dateSearchOption === 0 ? (
                  <DatePicker
                    inputReadOnly={true}
                    onChange={(m) => {
                      setSelectedMonth(m.format('YYYY-MM'));
                    }}
                    picker="month"
                    value={moment(`${selectedMonth}-01`)}
                    allowClear={false}
                  />
                ) : (
                  <RangePicker
                    value={dateRange}
                    inputReadOnly={true}
                    allowClear={false}
                    disabledDate={handleDisabledDate}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      const intervalDays = end.diff(start, 'days');
                      if (intervalDays > MAX_SEARCH_INTERVAL_DAYS) {
                        alert('검색 기간은 최대 3개월입니다.');
                        return false;
                      }
                      setDateRange(dates);
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
          <CustomTable
            className="orderadmin-table-table"
            loading={loading}
            pagination={{ pageSize: 10, position: ['bottomCenter'] }}
            dataSource={dataSource}
            columns={isAfterSchool(companyName) ? columnsForSmartEclass : columns}
            size="small"
            color="#edf3fb"
            scroll={{ y: 'calc(100vh - 254px)' }}
          />
          <PrintDummy ref={printRef}>
            <Table
              pagination={false}
              dataSource={dataSource}
              columns={isAfterSchool(companyName) ? columnsForSmartEclass : columns}
              size="small"
            />
          </PrintDummy>
        </MainBlock>
      </Col>
      <BookReturnStatusModal
        visible={modalVisible}
        idx={bookReturnIdx}
        return_stat={returnStatus}
        bookData={bookList}
        comment={comment}
        onRefetch={handleRefetch}
        onCancel={() => {
          setModalVisible(false);
          setBookList([]);
        }}
      />
    </>
  );
};

export default BookReturnList;
