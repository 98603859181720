import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, Pagination, Modal } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { HalfWrapper, MainBlock } from '../common/Styles';
import { openNotification } from 'src/components/common/Notification';
import UseHistoryState from 'src/components/common/UseHistoryState';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const SyllabusMain = () => {
  const [checkRowList, setcheckRowList] = useState([]);
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      type: '',
      text: null,
      page: 1,
    },
    'we_search',
  );

  const { data: fetchSyllabus, refetch } = useQuery(queries.getTests.TEST_GROUP_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      type: searchValue.type,
      searchText: searchValue.text,
    },
  });

  const [workBookDelete] = useMutation(mutations.createTests.WORK_BOOK_DELETE, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
  });

  const listData = useMemo(() => {
    if (fetchSyllabus) {
      return fetchSyllabus.testGroupList.reduce((acc, cur, index) => {
        const obj = {
          key: cur.idx,
          title: cur.name,
          date: moment(cur.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          lesson: cur?.tests?.length,
          No: index + 1,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchSyllabus]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: 50,
    },
    {
      title: 'Book Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => a.title && a.title.localeCompare(b.title),
    },
    {
      title: 'Uploaded Date',
      dataIndex: 'date',
      align: 'center',
      key: 'date',
      width: 120,
    },
    {
      title: 'lesson #',
      dataIndex: 'lesson',
      key: 'lesson',
      width: 90,
      align: 'center',
    },
    {
      title: 'Edit',
      key: '',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link to={`/test/syllabus/edit/${record.key}`}>
              <Button style={{ color: '#1890ff' }}>Edit</Button>
            </Link>
          </Space>
        );
      },
    },
    {
      title: 'List 보기',
      key: '',
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link to={`/test/syllabus/${record.key}/assign`}>
              <Button style={{ color: '#1890ff' }}>Assign</Button>
            </Link>
          </Space>
        );
      },
      width: 100,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };
  console.log('checkRowList', checkRowList);
  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          workBookDelete({ variables: { idxs: checkRowList } });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current,
      };
    });
  }

  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="syllabus-mailblock">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>Syllabus Main Page</Title>
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Form.Item label="Folder" className="headitems-select">
                <Select
                  style={{ width: 150, color: '#065aa9' }}
                  defaultValue={searchValue.type}
                  placeholder="Select a folder"
                  optionFilterProp="children"
                  onChange={(val) =>
                    setSearchValue((prev) => {
                      return {
                        ...prev,
                        type: val,
                      };
                    })
                  }
                >
                  <Select.Option value="">전체</Select.Option>
                  <Select.Option value="publisher">FC 본사</Select.Option>
                  <Select.Option value="campus">우리 학원 컨텐츠</Select.Option>
                  <Select.Option value="me">내 개인 컨텐츠</Select.Option>
                </Select>
              </Form.Item>
              <Search
                className="headitems-search"
                placeholder="Input search text"
                defaultValue={searchValue.text}
                onSearch={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      text: val,
                    };
                  })
                }
                allowClear
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
              />
              <Link to="/test/syllabus/add" className="headitems-add">
                <Button style={{ color: '#289428' }}>Add +</Button>
              </Link>

              <CustomButton className="testmanagmentdelete-btn" danger onClick={deleteConfirm}>
                Delete
              </CustomButton>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            dataSource={listData}
            columns={columns}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: 10, current: searchValue.page }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => onHandleRowClick(record, rowIndex),
            //   };
            // }}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default SyllabusMain;
