import React, { useState, useRef, useEffect } from 'react';
// import {useSelector} from "react-redux";
//import {Link} from "react-router-dom";
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const PageNav = (props) => {
  const { onPrev, onNext, isShowPageNav } = props;
  // const {isShowPageNav} = useSelector(state => state.commonStore, []);
  const arrowShow = isShowPageNav || 1;
  return (
    <ArrowWrap className="arrow_wrap" style={!arrowShow ? { visibility: 'hidden' } : {}}>
      <li style={{ position: 'absolute', top: '0', left: '0' }}>
        <ArrowBtn type="button" onClick={onPrev}>
          <ArrowIMG src="/images/pm/page_nav_lt.svg" alt="좌측 페이지로 가는 화살표" />
        </ArrowBtn>
      </li>
      <li style={{ position: 'absolute', top: '0', right: '0' }}>
        <ArrowBtn type="button" onClick={onNext}>
          <ArrowIMG src="/images/pm/page_nav_rt.svg" alt="우측 페이지로 가는 화살표" />
        </ArrowBtn>
      </li>
    </ArrowWrap>
  );
};

export default PageNav;

export const usePageNav = (totalSteps) => {
  const [steps, setSteps] = useState(0);
  const lastCurrectStepsRef = useRef(0);

  const onPrev = (e) => {
    e.preventDefault();
    setSteps((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const onNext = (e) => {
    e.preventDefault();

    if (lastCurrectStepsRef.current <= steps) return;
    setSteps((prev) => {
      if (prev < totalSteps - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  useEffect(() => {
    if (steps > lastCurrectStepsRef.current) {
      lastCurrectStepsRef.current = steps;
    }
  }, [steps]);
  return { steps, setSteps, onPrev, onNext };
};
const ArrowWrap = styled.ul`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: -40px;
  right: -40px;
  transform: translateY(-50%);
  list-style: none;
`;
const ArrowBtn = styled.button`
  outline: none;
  background-color: transparent;
  border: none;
  vertical-align: middle;
`;
const ArrowIMG = styled.img`
height: 32px;
`;