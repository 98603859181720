import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined, PlayCircleFilled, CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import * as axios from 'axios';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5b9bd5;
  margin-top: 10px;
  font-size: 50px;
`;
const StageTitle = styled.div`
  border-bottom: 1px solid #ed7d31;
  width: 100%;
  padding: 10px 30px;
`;
const StageTitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#0070c0')};
  font-size: 25px;
  font-weight: 600;
  padding: 0 45px;
  @media (max-width: 1280px) {
    font-size: 22px;
    padding: 40px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #7030a0;
  padding: 30px 0;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 5px 30px 15px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;

const LeftBody = styled(ColumnFlex)`
  width: 55%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  height: 95%;
`;

const TryNumber = styled.span`
  color: #ff9900;
`;
const ScoreNumber = styled.span`
  color: #4472c5;
`;
const InfoBox = styled(ColumnFlex)`
  color: #000000;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: 2px #b0c9e7 solid;
  border-radius: 10px;
  font-size: 30px;
  width: 100%;
  /* height: 55%; */
  background-color: #fbfbfb;
  /* cursor: pointer; */
`;
const SentenceNumber = styled(ColumnFlex)`
  width: 40px;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 25px;
  color: ${(props) => (props.doing ? '#4977c6' : '#fecd3a')};
  background-color: ${(props) => (props.doing ? '#dae3f3' : '#fff')};
  border: 2px solid ${(props) => (props.doing ? '#4977c6' : '#fecd3a')};
  border-radius: 20px;
  text-align: center;
  z-index: 1;
  margin-right: 15px;
  @media (max-height: 960px) {
    font-size: 20px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
`;
const LeftSetenceBox = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => (props.doing ?'#5c9bd5': '#eaaa3e')};
  background-color: ${(props) => (props.doing ? '#f4fbfe' : '#fff')};
  color: ${(props) => (props.doing ? '#dde0f3' : '#000000')};
  /* margin-top: 10px; */
  width: 85%;
  text-align: left;
  font-size: 17px;
  line-height: 1.7;
  height: 30px;
  padding-left: 20px;
`;
const DirectionText = styled.div`
font-size: 25px;
font-weight: 500;
padding: 30px 0;
`;
const QuizNumber = styled.span`
  color: #0070c0;
`;
const RightBody = styled(ColumnFlex)`
  width: 45%;
  padding: 0 15px;
  height: 95%;
`;

const WordListBox = styled(ColumnFlex)`
  background-color: #fff2cc;
  border-radius: 25px;
  border: 2px #eaaa3e solid;
  height: 90%;
  color: #2e75b6;
  font-size: 28px;
  align-items: center;
  padding: 25px 5px;
  justify-content: space-between;
`;
const RightSetenceBox = styled.div`
  border-radius: 10px;
  border: 2px #eaaa3e solid;
  background-color: ${(props) => (props.active ? '#fbe5d6' : '#fff')};
  color: ${(props) => (props.active ? '#cb0000' : '#000000')};
  margin-top: 10px;
  width: 90%;
  text-align: center;
  font-size: 17px;
  line-height: 2;
`;
const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  border-radius: 15px;
  /* border: 1px red solid; */
  background-image: url(${(props)=>props.bg});
  background-size:  72px 50px;
  background-repeat: no-repeat;
  /* background-color: ${(props) => (props.active ? 'red' : 'orange')}; */
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  cursor: pointer;
`;
export default function LevelUpSentenceRepeat({}) {
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <StageTitle>
              <StageTitleSpan>BOOK Title</StageTitleSpan>
              <StageTitleSpan>Unit</StageTitleSpan>
              <StageTitleSpan>Lesson</StageTitleSpan>
              <StageTitleSpan color={'#7030a0'}>Topic, title</StageTitleSpan>
            </StageTitle>
            <ColumnFlex style={{ justifyContent: 'space-between', height: '93%',paddingTop:'13px' }}>
              <RowFlex style={{ height: '100%' }}>
                <LeftBody>
                  <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline' }}>
                    <Title>Listen and Repeat.</Title>
                    <RowFlex style={{ fontSize: '18px', justifyContent: 'right' }}>
                      Tries:&nbsp; <TryNumber>{`2`}</TryNumber>/<ScoreNumber>{`3`}&nbsp;&nbsp;</ScoreNumber>Score:&nbsp;
                      <ScoreNumber>{`70`}</ScoreNumber>
                    </RowFlex>
                  </RowFlex>
                  <InfoBox>
                    <DirectionText>Press the play button to start.</DirectionText>
                    <RowFlex style={{ width: '100%', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                      <SentenceNumber doing>?</SentenceNumber>
                      <LeftSetenceBox doing>We sleep in a bed.</LeftSetenceBox>
                    </RowFlex>
                    <ColumnFlex style={{width: '100%', padding:'15px 0 20px'}}>
                    <RowFlex style={{ width: '100%', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                      <SentenceNumber >1</SentenceNumber>
                      <LeftSetenceBox ></LeftSetenceBox>
                    </RowFlex>
                    <RowFlex style={{ width: '100%', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                      <SentenceNumber >2</SentenceNumber>
                      <LeftSetenceBox ></LeftSetenceBox>
                    </RowFlex>
                    </ColumnFlex>
                  </InfoBox>
                  <ImgButton>
                    <PlayCircleFilled />
                  </ImgButton>
                </LeftBody>
                <RightBody>
                  <RowFlex style={{ fontSize: '18px', justifyContent: 'right', padding: '20px' }}>
                    Number&nbsp;
                    <QuizNumber>
                      {`03`}/{`10`}
                    </QuizNumber>
                  </RowFlex>
                  <WordListBox>
                    <div style={{ marginBottom: '15px' }}>Key Sentences List</div>
                    <>
                      <RightSetenceBox active={false}>I live in a big house.</RightSetenceBox>
                      <RightSetenceBox active={false}>Our furniture in our house is new.</RightSetenceBox>
                      <RightSetenceBox active={false}>Sit on the chair.</RightSetenceBox>
                      <RightSetenceBox active={false}>That is a big table.</RightSetenceBox>
                      <RightSetenceBox active={true}>We sleep in a bed.</RightSetenceBox>
                      <RightSetenceBox active={false}>Let's wait in the living room.</RightSetenceBox>
                    </>
                  </WordListBox>
                </RightBody>
              </RowFlex>
              <RowFlex style={{ justifyContent: 'space-between', fontSize: '50px', height: '50px', width: '100%', marginTop: '10px' }}>
                <CaretLeftFilled style={{ color: '#bfbfbf', cursor: 'pointer' }} />
                <RowFlex style={{ height: '50px', alignItems: 'center', fontSize: '25px', justifyContent: 'center' }}>
                <StageButton bg={`/images/LevelUp/study/sub/g_flashcard.png`} active={false} isDone={true} />
                    <ArrowRightOutlined style={{ margin: '0 10px' }} />
                    <StageButton bg={`/images/LevelUp/study/sub/g_check.png`} active={true} isDone={false} />
                    <ArrowRightOutlined style={{ margin: '0 10px' }} />
                    <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} active={false} isDone={false} />
                    <ArrowRightOutlined style={{ margin: '0 10px' }} />
                    <StageButton bg={`/images/LevelUp/study/sub/g_quiz.png`} active={false} isDone={false} />
                </RowFlex>
                <CaretRightFilled style={{ color: '#eaaa3e', cursor: 'pointer' }} />
              </RowFlex>
            </ColumnFlex>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
