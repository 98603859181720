import React, { useMemo } from 'react';
import { InputNumber } from 'antd';
import {
  getBookGroupLevelLabels,
  getBookGroupVolumeLabels,
  getStudentBookGroupDivisionLabels,
  getReserchBookGroupDivisionLabels,
} from 'src/utils';
import { Select } from 'antd';
const BookGroupOrderTable = ({ bookTitle, isReserch, books, bookCountObj, onCountChange, isUseCredit }) => {
  const groupLevelLabels = getBookGroupLevelLabels(bookTitle);
  const groupVolumeLabels = getBookGroupVolumeLabels(bookTitle);
  const bookGroupDivisionLabels = isReserch ? getReserchBookGroupDivisionLabels(bookTitle) : getStudentBookGroupDivisionLabels(bookTitle);

  const bookList = books.reduce((acc, bookLevel) => {
    let volList = [];
    for (let i = 0; i < bookLevel[1].vol_list.length; i++) {
      volList.push({
        volume_title: bookLevel[1].vol_list[i].book_sub[0].title,
        level_no: bookLevel[1].vol_list[i].level_no,
        volume_no: bookLevel[1].vol_list[i].volume_no,
        prices: bookLevel[1].price_list[i],
      });
    }
    return [...acc, ...volList];
  }, []);

  const bookGroups = groupLevelLabels.map((groupLevelLabel) => {
    const groupDivisionItems = bookGroupDivisionLabels.map((groupDivisionLabel) => {
      const groupVolumeItems = groupVolumeLabels.map((groupVolumeLabel) => {
        const groupLevelBooks = bookList.filter((s) => s.volume_title.includes(groupLevelLabel.value));
        const divisionLevelBooks = groupLevelBooks.filter((s) => {
          const volumeStartPosition = groupLevelLabel.value.length;
          const volumeEndPosition = s.volume_title.indexOf('_', volumeStartPosition);
          const groupType = volumeEndPosition > 0 ? s.volume_title.slice(1 + volumeEndPosition) : '';
          return groupDivisionLabel.value.includes(groupType);
        });
        const volumeLevelBooks = divisionLevelBooks.filter((s) => {
          const volumeStartPosition = groupLevelLabel.value.length;
          const volumeEndPosition = s.volume_title.indexOf('_', volumeStartPosition);
          const volumeIndex =
            volumeEndPosition > 0
              ? s.volume_title.substring(volumeStartPosition, volumeEndPosition)
              : s.volume_title.substring(volumeStartPosition);
          return groupVolumeLabel.value === volumeIndex;
        });
        return {
          groupVolumeLabel: groupVolumeLabel.label,
          volumeLevelBooks: volumeLevelBooks,
        };
      });
      return {
        groupDivisionLabel: groupDivisionLabel.label,
        groupVolumeItems: groupVolumeItems,
      };
    });
    return {
      groupLevelLabel: groupLevelLabel.label,
      groupDivisionItems: groupDivisionItems,
    };
  });
  //console.log(bookGroups);
  const { Option } = Select;
  const totalCurrentBookGroupCount = useMemo(() => {
    return Object.entries(bookCountObj).reduce((gacc, [, gval]) => {
      return (
        gacc +
        Object.entries(gval).reduce((acc, [, volval]) => {
          return (
            acc +
            Object.values(volval).reduce((a, c) => {
              return a + (c.volume!==undefined && !Number.isNaN(c.volume) ? c.volume : c);
            }, 0)
          );
        }, 0)
      );
    }, 0);
  }, [bookCountObj]);
  return (
    <table className="booktable-main-tbl mytable_custome" style={{ marginBottom: 10 }}>
      <thead>
        <tr>
          <th>레벨</th>
          <th>서적</th>
          {groupVolumeLabels &&
            groupVolumeLabels.map((vol) => {
              return <th key={`volumes-key${vol.value}`}>{vol.label}</th>;
            })}
          <th>총 수</th>
        </tr>
      </thead>
      <tbody>
        {bookGroups &&
          bookGroups.map((bookGroup, key) => (
            <>
              {bookGroup.groupDivisionItems.map((groupDivisionItem, key2) => (
                <tr>
                  {key2 === 0 ? <td rowSpan={key2 === 0 ? bookGroup.groupDivisionItems.length : 0}>{bookGroup.groupLevelLabel}</td> : ''}
                  <td key={`each-group-key${key}-${key2}`}>{groupDivisionItem.groupDivisionLabel}</td>
                  {groupDivisionItem.groupVolumeItems.map((groupVolumeItem, key3) => (
                    <td key={`each-input-key${key}-${key2}-${key3}`}>
                      <InputNumber
                        placeholder="수량"
                        min={0}
                        max={50}
                        maxLength={3}
                        keyboard={false}
                        onChange={(number) => {
                          onCountChange(
                            groupVolumeItem,
                            groupDivisionItem.groupDivisionLabel,
                            bookGroup,
                            number,
                            'volume',
                            isUseCredit ? groupDivisionItem.groupVolumeItems[0]?.prices[0].days : 0,
                          );
                        }}
                        onKeyPress={(e) => {
                          if (e.key >= 0 && e.key <= 9) {
                            return true;
                          } else {
                            e.preventDefault();
                            return false;
                          }
                        }}
                      />
                      {isUseCredit ? (
                        <>
                          <Select
                            onChange={(e) => onCountChange(groupVolumeItem, groupDivisionItem.groupDivisionLabel, bookGroup, e, 'days', 0)}
                            defaultValue={
                              groupDivisionItem.groupVolumeItems[0]?.prices?.length
                                ? groupDivisionItem.groupVolumeItems[0]?.prices[0].days
                                : 0
                            }
                          >
                            {groupDivisionItem.groupVolumeItems[0]?.prices?.map((price, keys) => {
                              return (
                                <>
                                  <Option key={keys} value={price.days}>
                                    {price.days}
                                  </Option>
                                </>
                              );
                            })}
                          </Select>
                          일
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                  ))}
                  <td>
                    {Object.entries(bookCountObj).reduce((acc, [level, vol]) => {
                      if (level === bookGroup.groupLevelLabel) {
                        return (
                          acc +
                          Object.values(vol).reduce((a, c) => {
                            if (c[groupDivisionItem.groupDivisionLabel]) {
                              return (
                                a +
                                (c[groupDivisionItem.groupDivisionLabel].volume!==undefined && !Number.isNaN(c[groupDivisionItem.groupDivisionLabel].volume)
                                  ? c[groupDivisionItem.groupDivisionLabel]?.volume
                                  : c[groupDivisionItem.groupDivisionLabel])
                              );
                            } else {
                              return a;
                            }
                          }, 0)
                        );
                      }
                      return acc;
                    }, 0)}
                  </td>
                </tr>
              ))}
            </>
          ))}
        <tr>
          <td>Total</td>
          <td>-</td>
          {groupVolumeLabels &&
            groupVolumeLabels.map((vol, i) => {
              let volumeTotal = [];
              Object.entries(bookCountObj).forEach(([groupLevelLabel, groupLevelItems]) => {
                Object.entries(groupLevelItems).forEach(([groupVolumeLabel, groupVolumeItems]) => {
                  Object.entries(groupVolumeItems).forEach(([groupDivisionLabel, groupDivisionItem]) => {
                    if (volumeTotal[groupVolumeLabel]) {
                      volumeTotal[groupVolumeLabel] += groupDivisionItem.volume ? groupDivisionItem.volume : 0;
                    } else {
                      volumeTotal[groupVolumeLabel] = groupDivisionItem.volume ? groupDivisionItem.volume : 0;
                    }
                  });
                });
              });
              return <td key={`volumes-key-total${vol.value}`}>{volumeTotal[vol.value]}</td>;
            })}
          <td>{totalCurrentBookGroupCount}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default BookGroupOrderTable;
