import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, DatePicker, Input, Select, TimePicker, Typography, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FormField from 'src/components/common/FormField';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { userInfoVar } from 'src/apollo/cache';
import { isAfterSchool,isPturn } from 'src/utils';

const { Option } = Select;
const { Text } = Typography;

const CreateClassForm = ({ onDateChange, onTimeChange, bookList, teacherList, yoilOptions, isAutoSchedule, form, hasLevel }) => {
  const companyName = useSelector(classStoreData);
  const [showBooktitleInput, setShowBookTitleInput] = useState(false);
  const isTeacher = useMemo(() => {
    return userInfoVar()?.type === 1 ? true : false;
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      teachers: isTeacher ? [parseInt(userInfoVar()?.idx)] : [],
    });
  }, [form, isTeacher]);

  function handleChangeBook(code) {
    setShowBookTitleInput(code === 'manual');
  }
  return (
    <>
      <FormField hasFeedback={true} title="클래스명">
        <FormItem name="name" rules={[{ required: true, message: '클래스명을 입력해 주세요.' }]} hasFeedback style={{ width: '90%' }}>
          <Input name="name" />
        </FormItem>
      </FormField>

      <FormField
        hasFeedback={hasLevel}
        title={<Tooltip title="레벨을 선택하거나 입력하세요">{`${isAfterSchool(companyName) ? '교재' : '레벨'}`}</Tooltip>}
      >
        <FormItem name="book_code" hasFeedback style={{ width: '90%' }} rules={[{ required: hasLevel, message: '교재를 선택해 주세요.' }]}>
          {/* <Tooltip title="레벨을 선택하거나 입력하세요"> */}
          <Select
            showSearch
            name="book_code"
            style={{ width: 200 }}
            placeholder={`${isAfterSchool(companyName) ? '교재 선택' : '레벨 선택'}`}
            onChange={handleChangeBook}
            filterOption={(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {bookList.map((book) => (
              <Option key={book.key} value={book.key}>
                {book.value}
              </Option>
            ))}
            <Option key="manual" value="manual">
              직접입력
            </Option>
          </Select>
          {/* </Tooltip> */}
        </FormItem>
        {showBooktitleInput && (
          <FormItem name="book_direct" style={{ width: '90%' }}>
            <Input name="book_direct" style={{ width: 200 }} placeholder="교재명을 입력하세요" maxLength={30} />
          </FormItem>
        )}
      </FormField>

      <FormField
        hasFeedback={true}
        title={<Tooltip title="선택한 교재의 수업 시작일을 설정합니다.">{`${isAfterSchool(companyName) ? '' : '학습'} 시작일`}</Tooltip>}
      >
        <FormItem
          name="begin_date"
          rules={[{ required: true, message: '학습 시작일을 선택해 주세요.' }]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <DatePicker name="begin_date" onChange={(_, d) => onDateChange('begin_date', d)} style={{ width: '100%' }} />
        </FormItem>
      </FormField>
      <FormField hasFeedback={true} title={`${isAfterSchool(companyName) ? '' : '학습'} 종료일`}>
        <FormItem
          name="end_date"
          rules={[{ required: true, message: '학습 종료일을 선택해 주세요.' }]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <DatePicker name="end_date" onChange={(_, d) => onDateChange('end_date', d)} style={{ width: '100%' }} />
        </FormItem>
      </FormField>
      {!isPturn(companyName) && (
        <>
          <FormField hasFeedback={!hasLevel ? hasLevel : isAutoSchedule} title="수업시작시간">
            <FormItem
              name="begin_time"
              rules={[{ required: !hasLevel ? hasLevel : isAutoSchedule, message: '수업시작시간을 설정해 주세요.' }]}
              hasFeedback
              style={{ width: '90%' }}
            >
              <TimePicker name="begin_time" onChange={(_, t) => onTimeChange('begin_time', t)} placeholder="00:00" format={'HH:mm'} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={!hasLevel ? hasLevel : isAutoSchedule} title="수업종료시간">
            <FormItem
              name="end_time"
              rules={[{ required: !hasLevel ? hasLevel : isAutoSchedule, message: '수업종료시간을 설정해 주세요.' }]}
              hasFeedback
              style={{ width: '90%' }}
            >
              <TimePicker name="end_time" onChange={(_, t) => onTimeChange('end_time', t)} placeholder="00:00" format={'HH:mm'} />
            </FormItem>
          </FormField>
        </>
      )}
      <FormField
        hasFeedback={!hasLevel ? hasLevel : isAutoSchedule}
        title={isAfterSchool(companyName) ? '수업 요일' : <Tooltip title="수업시간에 맞게 실라버스를 자동 설정합니다.">수업 요일</Tooltip>}
      >
        <FormItem
          name="day_of_week_view"
          rules={[{ required: !hasLevel ? hasLevel : isAutoSchedule, message: '요일을 선택해 주세요.' }]}
          style={{ width: '90%' }}
        >
          <Checkbox.Group options={yoilOptions} name="day_of_week_view" />
        </FormItem>
      </FormField>

      <FormField
        hasFeedback={!hasLevel ? hasLevel : isAutoSchedule}
        title={
          <Tooltip
            title={
              isAfterSchool(companyName)
                ? '선택한 요일에 레슨이 하나씩 자동으로 배정됩니다.(예습 및 복습 가능)'
                : '수업시간에 맞게 실라버스를 자동 설정합니다.'
            }
          >
            온라인 학습일
          </Tooltip>
        }
      >
        <FormItem
          name="day_of_week"
          rules={[{ required: !hasLevel ? hasLevel : isAutoSchedule, message: '요일을 선택해 주세요.' }]}
          style={{ width: '90%' }}
        >
          <Checkbox.Group options={yoilOptions} name="day_of_week" />
        </FormItem>
      </FormField>
      <FormField hasFeedback={true} title="강사">
        <FormItem name="teachers" style={{ width: '90%' }} rules={[{ required: true, message: '강사를 선택해 주세요.' }]}>
          {teacherList.length ? (
            <Select
              mode="multiple"
              disabled={isTeacher}
              showArrow
              allowClear
              name="teachers"
              size={`default`}
              placeholder="강사 선택"
              style={{ width: '100%' }}
            >
              {teacherList.map((teacher) => (
                <Option key={teacher.key} value={teacher.key}>
                  {`${teacher.value}(${teacher.userId})`}
                </Option>
              ))}
            </Select>
          ) : (
            <Text>강사없음</Text>
          )}
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="강의실">
        <FormItem name="room" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="room" />
        </FormItem>
      </FormField>

      {companyName !== 'creo' && (
        <>
          <FormField hasFeedback={false} title="Levelup Level">
            <FormItem name="levelup_level" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
              <Select showArrow allowClear name="levelup_level" size={`default`} placeholder="Levelup Level" style={{ width: '100%' }}>
                {['PH', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'].map((e) => (
                  <Option value={e}>{e}</Option>
                ))}
              </Select>
            </FormItem>
          </FormField>
        </>
      )}
    </>
  );
};

export default CreateClassForm;
