import React from 'react';
import { Row, Col, DatePicker, Input } from 'antd';
//import styled from 'styled-components';
import { koreanNumberFormat } from 'src/utils';
import SmsChargeModal from 'src/components/Modal/SmsChargeModal';

const SmsTxtHistoryHeader = ({ remainedPoint, totalSent, selectedYm, onChange }) => {
  return (
    <>
      <Row className="history_Wrap">
        <Col span={2} push={1}>
          <h1>보낸 문자 관리</h1>
        </Col>
        <Col span={4} push={6} className="history_Select_Wrap">
          {/* <Select defaultValue="Search" className="history_Select">
            <Option value="11">11</Option>
            <Option value="22">22</Option>
            <Option value="33">33</Option>
          </Select> */}
        </Col>
        <Col span={2} push={4} className="hisoryDate_Wrap">
          <DatePicker onChange={onChange} picker="month" value={selectedYm} inputReadOnly={true} allowClear={false} />
        </Col>
        <Col span={4} push={5}>
          총 발송 건 수 :<Input readOnly style={{ width: '20%', margin: '0 10px', textAlign: 'center' }} value={totalSent} />
        </Col>
        <Col span={4} push={4} className="remainCash_wrap">
          <span className="remainCash"> 총 잔여 금액 : </span>
          <Input style={{ textAlign: 'right' }} readOnly value={koreanNumberFormat(remainedPoint)} />원
        </Col>
        <Col span={3} push={4} className="historyBtnWrap">
          <SmsChargeModal />
        </Col>
      </Row>
    </>
  );
};

export default SmsTxtHistoryHeader;
