import React, { useEffect, useMemo, useState,useRef } from "react";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as queries from 'src/operations/queries';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Typography, Space, Row, Checkbox, Modal, Button, Input, Table } from 'antd';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useHistory } from 'react-router-dom';
import imageprinter from '../../images/printer.png';
import { useReactToPrint } from 'react-to-print';

const StudentTestResultClassWise = () => {
    const { Title } = Typography;
    const printRef = useRef();
    const history = useHistory();
    const [testId, setTestId] = useState([])
    const [maxLength, setMaxLength] = useState()

    const { idx, classIdx, testIdx, userIdx } = useParams();

    const { data: testdata } = useQuery(queries.getTests.TEST_RESULT, {
        fetchPolicy: 'no-cache',
        variables: {
            assigned_group_idx: parseInt(idx),
            class_idx: parseInt(classIdx),
            // test_idx:4
            test_idx: parseInt(testIdx),
            user_idx: parseInt(userIdx)
        },
    });
    const testResult = testdata ? JSON.parse(testdata.getTestResult) : [];

    const { data, refetch } = useQuery(queries.getTests.STUDENT_RESULT_TEST_WISE, {
        fetchPolicy: 'no-cache',
        variables: {
            assigned_group_idx: parseInt(idx),
            class_idx: parseInt(classIdx),
            // test_idx:4
            test_idx: parseInt(testIdx),
            user_idx: parseInt(userIdx)
        },
    });

    useEffect(() => {
        if (data?.getStudentResultTestWise?.length > 0) {
            setMaxLength(data?.getStudentResultTestWise?.length)
            const tempMaxLength = Math.max(...data?.getStudentResultTestWise?.map((ele) => ele?.questionAnswer?.length))
            if (data?.getStudentResultTestWise?.length < tempMaxLength) { setMaxLength(tempMaxLength) }
        }
    }, [data])

    const fetchResult = useMemo(() => {
        if (data?.getStudentResultTestWise) {
            return data?.getStudentResultTestWise?.map((ele, i) => {
                const questionAns = ele?.questionAnswer
                if (ele?.questionAnswer?.length < maxLength) {
                    const diff = maxLength - ele?.questionAnswer?.length;
                    for (let i = 0; i < diff; i++) {
                        questionAns.push({

                            "right_ans": "-",
                            "user_ans": "-"
                        })
                    }
                }
                return { ...ele, questionAnswer: questionAns }
            })

        }
    }, [maxLength, data])

    const finalTestResult = useMemo(() => {
        if (testResult) {
            if (testResult[0]?.Test?.length < maxLength) {
                const diff = maxLength - testResult[0]?.Test?.length;
                for (let i = 0; i < diff; i++) {
                    testResult[0]?.Test?.push(
                        {}
                    )
                }
            }
            return testResult;

        }
    }, [maxLength, testResult])

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
      });
    return <>
        <HeaderTitle level={4}>Daily Test Student Answer Sheet</HeaderTitle>
        <div  ref={printRef} className="student-ans-sheet">
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <HalfWrapper className="assigned-syllabus-halfwrapper" style={{padding:'12px'}}>
                    
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                        }}
                    >
                        <Title level={4}> Answer Sheet </Title>
                    </div>
                    <div className="time_retake">
                        <div className="left_content">
                            <div style={{  color: "#0067e5", fontWeight: "bold" }}>{finalTestResult[0]?.StudentName}</div>
                        </div>
                        <div className="right_content">
                           <span><label>Time: </label>{finalTestResult[0]?.TestTime}</span>
                           <span><label>Retake: </label>{finalTestResult[0]?.NoOfReTake}</span>
                            <a
                                href="/"
                                onClick={(e) => {
                                e.preventDefault();
                                handlePrint();
                                }}
                                >
                                 <div className="print_img">
                                    <img src={imageprinter} alt="print"/>
                                 </div>   
                            </a>      
                        </div>
                       
                       
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            {finalTestResult && finalTestResult.map((ele, key) => {

                                return (
                                    <>
                                        <table className="booktable-main-tbl test-result-main-tbl mytable_custome" style={{width:'177px', tableLayout:'auto'}}>
                                            <tr>
                                                <th style={{ color: "#0067e5" }}>Date</th>
                                                <td style={{ color: "#0067e5" }}>{ele?.Date?.split("T")[0]}</td>

                                            </tr>
                                            <tr>
                                                <th>Final Score</th>
                                                <td>{ele?.Score}</td>
                                            </tr>
                                            {ele?.Test.map((e) => {
                                                testId?.filter((ele) => e?.idx == ele ? '' : setTestId([...testId, e.idx]))
                                                return (
                                                    <>
                                                        <tr>
                                                            <th>{e?.test_folder_name}</th>
                                                            <td>{e?.score}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </table>
                                    </>)
                            })}
                        </div>
                        <div style={{ display: "flex", flexGrow: "1" }}>
                            {fetchResult?.map((ele) => {
                                return (
                                    <>
                                        <div style={{ display: "flex", flexGrow: "1" }}>
                                            <table className="booktable-main-tbl test-result-main-tbl mytable_custome">

                                                <tr>
                                                    <th colSpan={3} style={{ color: "#0067e5" }}>{ele.testName}</th>
                                                    <th style={{ color: "#FF0000" }}>100</th>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td>Answer</td>
                                                    <td>Student Ans</td>
                                                    <td style={{ color: "#0f4732d9" }}>O/X</td>
                                                </tr>

                                                {
                                                    ele?.questionAnswer?.map((e, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{e.right_ans}</td>
                                                                <td>{e.user_ans}</td>
                                                                <td style={parseInt(e.right_ans) === e.user_ans ? { color: "#0f4732d9" } : { color: "#FF0000" }}>{parseInt(e.right_ans) === e.user_ans ? "O" : "X"}</td>
                                                            </tr>

                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </HalfWrapper>
            </Col>
        </Row>
        </div>
    </>
}
export default StudentTestResultClassWise;