import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Col, DatePicker, Modal, Row, Typography } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { classStoreData } from 'src/operations/store';
import { openNotification } from 'src/components/common/Notification';
import ClassDetailModal from 'src/components/Modal/ClassDetailModal';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import TeacherModal from 'src/components/Modal/TeacherModal';
import BackButton from 'src/components/common/BackButton';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import RowItem from 'src/components/common/RowItem';
import { customDayOfWeek, getMonthDateRange } from 'src/utils';
import { userTypVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';

import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';
import { settings } from 'src/dummy/settings';

const { Title } = Typography;
const { confirm } = Modal;

const TearcherDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const company = useSelector(classStoreData);
  const [teacher, setTeacher] = useState(null);
  const [tearchClass, setTeachClass] = useState(null);
  const [searchTearchClass, setSearchTeachClass] = useState(null);
  const [searchTeachrValue, setSeachTeacherValue] = useState('');
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const { visible: classVisible, showModal: classShowModal, handleCancel: handleClassCancel } = useModal();
  const { data, refetch } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      type: userTypVar().teacher,
    },
  });

  const [getTearchClassList, { data: tearchClassList, loading: teacherClassLoading }] = useLazyQuery(queries.getClass.TEACHER_CLASS_LIST);

  const [getSearchTearchClassList, { data: tearchSearchClassList }] = useLazyQuery(queries.getClass.TEACHER_SEARCH_CLASS_LIST);

  const [deleteUser] = useMutation(mutations.deleteUser.DELETE_USER, {
    variables: { user_idx_list: [Number(id)] },
    onCompleted(data) {
      if (data) {
        refetch();
        history.goBack();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function showDeleteConfirm() {
    confirm({
      title: '정말 삭제하시겠습니까?',
      okText: '예',
      okType: 'primary',
      cancelText: '아니오',
      onOk() {
        console.log('OK');
        deleteUser();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const handleTearchClassList = (teacher_idx) => {
    setSeachTeacherValue('');
    setSearchTeachClass(null);
    getTearchClassList({
      variables: { teacher_idx },
    });
  };

  const handleYearMonthSelect = (date, dateString) => {
    setSeachTeacherValue(dateString);
    const monthInfo = getMonthDateRange(dateString);
    const start = monthInfo.start;
    const end = monthInfo.end;

    getSearchTearchClassList({
      variables: {
        teacher_idx: Number(id),
        start,
        end,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setTeacher(...data.getUsers.filter((value) => value.idx === Number(id)));
    }

    getTearchClassList({
      variables: { teacher_idx: Number(id) },
    });
  }, [data, id, getTearchClassList]);

  useEffect(() => {
    if (tearchClassList) {
      setTeachClass(
        tearchClassList.classTeachers.reduce((acc, cur, index) => {
          const dayOfWeek = customDayOfWeek(cur.class);

          const obj = {
            key: cur.idx,
            className: cur.class.name,
            yoil: dayOfWeek,
            classTime: cur.class.begin_time && cur.class.end_time ? `${cur.class.begin_time} ~ ${cur.class.end_time}` : '',
            startDate: cur.class.begin_date && moment(cur.class.begin_date).format('YYYY-MM-DD'),
            endDate: cur.class.end_date && moment(cur.class.end_date).format('YYYY-MM-DD'),
          };
          return [...acc, obj];
        }, []),
      );
    }
  }, [tearchClassList]);

  useEffect(() => {
    if (tearchSearchClassList) {
      setSearchTeachClass(
        tearchSearchClassList.teacherClassList.reduce((acc, cur, index) => {
          const dayOfWeek = customDayOfWeek(cur.class);

          const obj = {
            key: cur.idx,
            className: cur.class.name,
            yoil: dayOfWeek,
            classTime: `${cur.class.begin_time} ~ ${cur.class.end_time}`,
            startDate: moment(cur.class.begin_date).format('YYYY-MM-DD'),
            endDate: moment(cur.class.end_date).format('YYYY-MM-DD'),
          };
          return [...acc, obj];
        }, []),
      );
    }
  }, [tearchSearchClassList]);

  const classColumns = [
    {
      title: 'Class(반명)',
      dataIndex: 'className',
      key: 'className',
      align: 'center',
    },
    {
      title: '요일',
      dataIndex: 'yoil',
      key: 'yoil',
      align: 'center',
    },
    {
      title: '시간',
      dataIndex: 'classTime',
      key: 'classTime',
      align: 'center',
    },
    {
      title: '시작일',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
    },
    {
      title: '종료일',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
    },
    // {
    //   title: '상세보기',
    //   key: 'action',
    //   align: 'center',
    //   render: (text, record) => (
    //     <Button onClick={classShowModal}>
    //       View
    //       {/* <Link to={`/classroom/${text.key}`}>View </Link> */}
    //     </Button>
    //   ),
    // },
  ];

  return (
    <>
      <Row justify="space-around">
        <Col span={12}>
          <HeaderTitle level={4}>강사 관리</HeaderTitle>
        </Col>
        <Col span={12} style={{ paddingTop: 5, textAlign: 'right' }}>
          <BackButton />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="classroom-box-wrap-height">
        <Col span={12} className="classroom-box1">
          <HalfWrapper className="tblclass-classroom-height">
            <div style={{ background: '#ECF1F3', padding: 10 }}>
              <Row justify="space-between" align="bottom" gutter={[0, 16]}>
                <Col>
                  <Title level={5}>강사 정보</Title>
                </Col>
                <Col>
                  <Row className="halfmanagment-space">
                    <Col>
                      <CustomButton danger style={{ marginRight: 10 }} onClick={showDeleteConfirm}>
                        삭제
                      </CustomButton>
                    </Col>
                    <Col>
                      <CustomButton type="primary" ghost style={{ marginRight: 10 }} onClick={() => showModal('edit')}>
                        수정
                      </CustomButton>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="halfmanagment-space" gutter={[8, 16]}>
                <Col lg={12} xs={24}>
                  <RowItem title="이름" value={teacher?.name} />
                </Col>
                <Col lg={12} xs={24}>
                  <RowItem title="가입일" value={moment(teacher?.idate).format('YYYY-MM-DD')} />
                </Col>
              </Row>

              <Row gutter={[8, 16]} className="halfmanagment-space emailidwdth">
                <Col lg={12} xs={24}>
                  <RowItem title="ID" value={teacher?.id} />
                </Col>
                <Col lg={12} xs={24}>
                  <RowItem title="E-mail" value={teacher?.email} />
                </Col>
              </Row>

              <Row gutter={[8, 16]} className="halfmanagment-space">
                <Col lg={12} xs={24}>
                  <RowItem title="전화번호" value={teacher?.phone} />
                </Col>
                {/* <Col lg={12} xs={24}>
                  <RowItem title="수업현황" value="진행중" />
                </Col> */}
              </Row>

              <Row gutter={[8, 16]} className="halfmanagment-space">
                <Col lg={12} xs={24}>
                  <RowItem title="메모" value={teacher?.memo} />
                </Col>
                <Col lg={12} xs={24}>
                  <RowItem title="주소" value={teacher?.address} />
                </Col>
              </Row>
              {settings[company].showExtraField === 'true' ? (
                <>
                  <Row gutter={[8, 16]} className="halfmanagment-space">
                    <Col lg={12} xs={24}>
                      <RowItem title="근무요일" value={teacher?.deleted_working_days} />
                    </Col>
                    <Col lg={12} xs={24}>
                      <RowItem title="근무시간" value={teacher?.working_hour} />
                    </Col>
                  </Row>
                  <Row gutter={[8, 16]} className="halfmanagment-space">
                    <Col lg={12} xs={24}>
                      <RowItem title="현 급여" value={teacher?.current_salary} />
                    </Col>
                    <Col lg={12} xs={24}>
                      <RowItem title="입사일" value={teacher?.date_of_entry} />
                    </Col>
                  </Row>
                  <Row gutter={[8, 16]} className="halfmanagment-space">
                    <Col lg={12} xs={24}>
                      <RowItem title="주민등록번호" value={teacher?.resident_reg_no} />
                    </Col>
                    <Col lg={12} xs={24}>
                      <RowItem title="계좌번호" value={teacher?.account_number} />
                    </Col>
                  </Row>
                  <Row gutter={[8, 16]} className="halfmanagment-space">
                    <Col lg={12} xs={24}>
                      <RowItem title="최종학력(대학명/학과명시)" value={teacher?.academic_background} />
                    </Col>
                    <Col lg={12} xs={24}>
                      <RowItem title="결혼유무" value={teacher?.marital_status} />
                    </Col>
                  </Row>
                  <Row gutter={[8, 16]} className="halfmanagment-space">
                    <Col lg={12} xs={24}>
                      <RowItem title="자녀" value={teacher?.children} />
                    </Col>
                    <Col lg={12} xs={24}>
                      <RowItem title="혈액형" value={teacher?.blood_type} />
                    </Col>
                  </Row>
                  <Row gutter={[8, 16]} className="halfmanagment-space">
                    <Col lg={12} xs={24}>
                      <RowItem title="소득신고방법" value={teacher?.report_income} />
                    </Col>
                    <Col lg={12} xs={24}>
                      <RowItem title="특이사항" value={teacher?.special_category} />
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
          </HalfWrapper>
        </Col>

        <Col span={12} className="classroom-box2">
          <HalfWrapper className="tblclass-classroom-height">
            <Row gutter={[8, 8]}>
              <Col md={7} xs={24}>
                <Title level={5}>Class List</Title>
              </Col>
              <Col md={17} className="searchbox-teacherdtail" style={{ marginBottom: 10 }}>
                <Row justify="end">
                  <Col>
                    <DatePicker
                      picker="month"
                      placeholder="전체"
                      onChange={handleYearMonthSelect}
                      value={searchTeachrValue !== '' ? moment(searchTeachrValue) : ''}
                    />
                    <CustomButton style={{ marginLeft: 10 }} onClick={() => handleTearchClassList(teacher.idx)}>
                      초기화
                    </CustomButton>
                  </Col>
                </Row>
              </Col>
            </Row>

            <CustomTable
              className="classroom-table-table"
              loading={teacherClassLoading}
              dataSource={searchTearchClass === null ? tearchClass : searchTearchClass}
              columns={classColumns}
              pagination={{ pageSize: 5 }}
              size="small"
              color="#edf3fb"
              locale="nono"
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <TeacherModal userInfo={teacher} popupMode={popupMode} handleCancel={handleCancel} visible={visible} refetch={refetch} />}

      {classVisible && <ClassDetailModal userInfo={teacher} handleCancel={handleClassCancel} visible={classVisible} />}
    </>
  );
};

export default TearcherDetail;
