import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form } from 'antd';
import DefaultModal from './DefaultModal';
import { Modal } from 'antd';
import CreatePlacementTestForm from 'src/pages/Consulting/CreatePlacementTestForm';
import UpdateMetaScheduleForm from 'src/pages/Schedule/UpdateMetaScheduleForm';
import { UPDATE_META_SCHEDULE } from 'src/operations/mutations/schedule';

const UpdateScheduleModal = ({ userInfo, schedule, visible, onCancel, refetch, campus_idx, ym, ...rest }) => {
  // console.log('userInfo', userInfo);
  const [form] = Form.useForm();
  const [closeForm, setCloseForm] = useState(false);
  const initialState = {
    days: userInfo?.days,
    no_of_system: userInfo?.no_of_system,
    start_time: userInfo?.start_time,
    end_time: userInfo?.end_time,
  };
  const [values, setValues] = useState(initialState);
  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
    setCloseForm(true);
  }, [form, onCancel]);

  useEffect(() => {
    if (visible) {
      setCloseForm(false);
    }
  }, [visible]);

  const [updateMetaSchedule, { loading }] = useMutation(UPDATE_META_SCHEDULE, {
    onCompleted(data) {
      if (data) {
        handleCancel();
        refetch();
        Modal.success({
          title: 'Schedule successfully',
        });
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];
      Modal.error({
        title: `${message}`,
      });
    },
  });

  // console.log('form===', form);
  return (
    <DefaultModal
      title="등록"
      form={form}
      ActionCancel={handleCancel}
      popupMode="create"
      loading={loading}
      style={{ width: '80%' }}
      visible={visible}
      {...rest}
      className="schedule-popup"
    >
      {/* <UpdateMetaScheduleForm  scheduleData={userInfo} form={form} onCreate={createMetaSchedule} closeForm={closeForm}/> */}
      <UpdateMetaScheduleForm setValues={setValues} scheduleData={userInfo} form={form} onCreate={updateMetaSchedule} />
    </DefaultModal>
  );
};

export default UpdateScheduleModal;
