import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button, Typography, Switch } from 'antd';
import { useMutation, useLazyQuery } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from '../common/Notification';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { CaretRightOutlined } from '@ant-design/icons';

const { Title } = Typography;

function CampusMenuSettingModal({ visible, handleCancel, company_idx, campus_idx, campus_name }) {
  const userTypeOptions = [
    { value: '0', label: '학생' },
    { value: '1', label: '강사' },
    { value: '2', label: '원장' },
    { value: '3', label: '지사장' },
    { value: '4', label: '본사' },
    { value: '6', label: '학부모' },
  ];

  const [selectedUserType, setSelecteUserType] = useState();
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const [level1DataSource, setLevel1DataSource] = useState([]);
  const [level2DataSource, setLevel2DataSource] = useState([]);
  const [level3DataSource, setLevel3DataSource] = useState([]);
  const [level1RowSelect, setLevel1RowSelect] = useState();
  const [level2RowSelect, setLevel2RowSelect] = useState();
  const [level1SelectedItem, setLevel1SelectedItem] = useState();
  const [level2SelectedItem, setLevel2SelectedItem] = useState();
  const [selectedCampusMenuItemSettings, setSelectedCampusMenuItemSettings] = useState();
  const [updateCampusMenuItems, setUpdateCampusMenuItems] = useState();

  const [fetchMenuList, { data: companyUserMenuListData, loading: companyUserMenuListLoading }] = useLazyQuery(
    queries.company.GET_COMPANY_USER_MENU_LIST,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [fetchCampusMenuItemSettingList, { data: campusMenuItemSettingListData, loading: campusMenuItemSettingListLoading }] = useLazyQuery(
    queries.campus.GET_CAMPUS_USER_MENU_ITEM_SETTING_LIST,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const onUserTypeChange = (e) => {
    setSelecteUserType(e.target.value);
    setLevel1RowSelect();
    setLevel1SelectedItem();
    setLevel2RowSelect();
    setLevel2SelectedItem();
  };

  useEffect(() => {
    if (!selectedUserType) return;

    if (campus_idx) {
      fetchCampusMenuItemSettingList({
        variables: {
          campus_idx: campus_idx,
        },
      });
    }

    fetchMenuList({
      variables: {
        company_idx: company_idx,
        user_type: selectedUserType,
      },
    });
  }, [fetchMenuList, fetchCampusMenuItemSettingList, company_idx, campus_idx, selectedUserType]);

  useEffect(() => {
    const menuSettingMap = new Map();
    campusMenuItemSettingListData?.campusUserMenuItemSettingList?.forEach((element) => {
      menuSettingMap.set(element.company_menu_item_id, element);
    });
    setSelectedCampusMenuItemSettings(menuSettingMap);

    setSelectedMenuItems(
      companyUserMenuListData?.companyUserMenuList[0]?.company_user_menu_item?.map((item) => {
        const menuItemSetting = menuSettingMap.get(item.idx);
        let obj = { ...item };
        const key = `checked${item.idx}`;
        if (menuItemSetting) {
          obj = { ...obj, is_hidden: menuItemSetting.is_hidden };
        }
        obj[key] = obj.is_hidden;
        return obj;
      }),
    );
  }, [companyUserMenuListData, campusMenuItemSettingListData]);

  useEffect(() => {
    if (selectedUserType) {
      setLevel1DataSource(selectedMenuItems?.filter((item) => item.parent_id === null).sort((a, b) => a.index - b.index));
    } else {
      setLevel1DataSource([]);
    }
    if (level1SelectedItem) {
      setLevel2DataSource(selectedMenuItems?.filter((item) => item.parent_id === level1SelectedItem).sort((a, b) => a.index - b.index));
    } else {
      setLevel2DataSource([]);
    }
    if (level2SelectedItem) {
      setLevel3DataSource(selectedMenuItems?.filter((item) => item.parent_id === level2SelectedItem).sort((a, b) => a.index - b.index));
    } else {
      setLevel3DataSource([]);
    }
  }, [selectedUserType, selectedMenuItems, level1SelectedItem, level2SelectedItem, selectedCampusMenuItemSettings]);

  const handleL1TableRowClick = (selected) => {
    setLevel1RowSelect(selected.index);
    setLevel1SelectedItem(selected.idx);
    setLevel2DataSource(selectedMenuItems.filter((item) => item.parent_id === selected.idx).sort((a, b) => a.index - b.index));
    setLevel2RowSelect();
    setLevel2SelectedItem();
  };

  const handleL2TableRowClick = (selected) => {
    setLevel2RowSelect(selected.index);
    setLevel2SelectedItem(selected.idx);
    setLevel3DataSource(selectedMenuItems.filter((item) => item.parent_id === selected.idx).sort((a, b) => a.index - b.index));
  };

  const handleIsHiddenChange = (e, record) => {
    const menuSettingData = {
      campus_idx: campus_idx,
      company_menu_item_id: record.idx,
      is_hidden: e,
    };

    setUpdateCampusMenuItems((prevState) => {
      return new Map(prevState).set(record.idx, menuSettingData);
    });

    setSelectedCampusMenuItemSettings((prevState) => {
      return new Map(prevState).set(record.idx, menuSettingData);
    });
  };

  const handleSave = () => {
    updateCampusUserMenuItemSettingList({
      variables: {
        campus_user_menu_item_settings: Array.from(updateCampusMenuItems.values()),
      },
    });
  };

  const [updateCampusUserMenuItemSettingList, { updateCampusUserMenuItemSettingListLoading }] = useMutation(
    mutations.campus.UPDATE_CAMPUS_USER_MENU_ITEM_SETTING_LIST,
    {
      refetchQueries: [
        {
          query: queries.company.GET_COMPANY_USER_MENU_LIST,
          variables: {
            company_idx: company_idx,
            user_type: selectedUserType,
          },
        },
        {
          query: queries.campus.GET_CAMPUS_USER_MENU_ITEM_SETTING_LIST,
          variables: {
            campus_idx: campus_idx,
          },
        },
      ],

      onCompleted(data) {
        if (data) {
          openNotification('메뉴 항목이 업데이트되었습니다!');
          //handleCancel();
        }
      },
      onError(error) {
        console.log('error', error);
      },
    },
  );

  const getColumnsL1 = () => {
    return [
      {
        title: '항목명',
        dataIndex: 'item_name',
        key: 'item_name',
        align: 'center',
      },
      {
        title: 'Hide',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (text, record) => {
          const key = `checked${record.idx}`;
          return (
            <>
              <span>
                <Switch
                  // disabled={record.is_hidden}
                  name={key}
                  defaultChecked={record[key]}
                  onChange={(e) => handleIsHiddenChange(e, record)}
                />
              </span>
            </>
          );
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              {record ? (
                <>
                  <span>
                    <Button
                      onClick={(e) => {
                        handleL1TableRowClick(record);
                      }}
                      size="small"
                      icon={<CaretRightOutlined />}
                    />
                  </span>
                </>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];
  };

  const getColumnsL2 = () => {
    return [
      {
        title: '항목명',
        dataIndex: 'item_name',
        key: 'item_name',
        align: 'center',
      },
      {
        title: 'Hide',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (text, record) => {
          const key = `checked${record.idx}`;
          return (
            <>
              <span>
                <Switch
                  disabled={record.is_hidden}
                  name={key}
                  defaultChecked={record[key]}
                  onChange={(e) => handleIsHiddenChange(e, record)}
                />
              </span>
            </>
          );
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (text, record) => {
          return (
            <>
              {record ? (
                <>
                  <span>
                    <Button
                      onClick={(e) => {
                        handleL2TableRowClick(record);
                      }}
                      size="small"
                      icon={<CaretRightOutlined />}
                    />
                  </span>
                </>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];
  };

  const getColumnsL3 = () => {
    return [
      {
        title: '항목명',
        dataIndex: 'item_name',
        key: 'item_name',
        align: 'center',
      },
      {
        title: 'Hide',
        dataIndex: '',
        key: '',
        align: 'center',
        render: (text, record) => {
          const key = `checked${record.idx}`;
          return (
            <>
              <span>
                <Switch
                  disabled={record.is_hidden}
                  name={key}
                  defaultChecked={record[key]}
                  onChange={(e) => handleIsHiddenChange(e, record)}
                />
              </span>
            </>
          );
        },
      },
    ];
  };

  return (
    <>
      <Modal
        entered
        title={`${campus_name} 메뉴 설정 관리`}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleSave}
        okText="확인"
        cancelText="취소"
        width={'100%'}
        loading={updateCampusUserMenuItemSettingListLoading}
      >
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <select name="userType" style={{ width: '100%', marginBottom: '10px' }} className="ant-input" onChange={onUserTypeChange}>
              <option key={''} value={''}>
                {'사용자 유형 설정'}
              </option>
              {userTypeOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>레벨1</Title>
              </Col>
            </Row>
            <CustomTable
              dataSource={level1DataSource}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={companyUserMenuListLoading || campusMenuItemSettingListLoading}
              columns={getColumnsL1()}
              rowKey="index"
              rowClassName={(record, index) => (record.index === level1RowSelect ? 'clickRowStyle' : '')}
            />
          </Col>
          <Col span={8}>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>레벨2</Title>
              </Col>
            </Row>
            <CustomTable
              dataSource={level2DataSource}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={companyUserMenuListLoading || campusMenuItemSettingListLoading}
              columns={getColumnsL2()}
              rowKey="index"
              rowClassName={(record, index) => (record.index === level2RowSelect ? 'clickRowStyle' : '')}
            />
          </Col>
          <Col span={8}>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>레벨3</Title>
              </Col>
            </Row>
            <CustomTable
              dataSource={level3DataSource}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={companyUserMenuListLoading || campusMenuItemSettingListLoading}
              columns={getColumnsL3()}
              rowKey="index"
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

CampusMenuSettingModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  myBookSeries: PropTypes.array,
  campusInfo: PropTypes.objectOf.isRequired,
};

export default CampusMenuSettingModal;
