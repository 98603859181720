import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, List, Form, Checkbox, DatePicker, Select, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { EditOutlined } from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import moment from 'moment';
import queryString from 'query-string';

import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';
import FormField from 'src/components/common/FormField';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { customDayOfWeek } from 'src/utils';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

/*let assignedStartDate = "2021-12-01";
let assignedendDate   = "2021-12-31";*/
let assignedStartDate = null;
let assignedendDate = null;
// let assigneduserList = [];
let assignedClasslist = [];
let assignedBookList = [];
let onRangeChangePageload = false;
const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];

const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

const Assign = () => {
  const history = useHistory();
  const { id, class_idx, title, key } = useParams();
  const [workBooks, setWorkBooks] = useState([]);
  const [formControl] = Form.useForm();
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [threeForm] = Form.useForm();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [selectYoil, setSelectYoil] = useState([]);
  const [selectStudents, setSelectStudents] = useState([]);
  const [testTime, setTestTime] = useState('00:00');
  const [events, setEvents] = useState([]);
  const [assigneduserList, setAssigneduserList] = useState([]);
  const [listData, setListData] = useState([]);
  const [rangeDate, setRangeDate] = useState({ start: null, end: null });
  const [checkedListAll, setcheckedListAll] = useState(false);
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const { classIdx } = queryString.parse(window.location.search.replace('?', ''));
  const [page, setPage] = useState(1);
  const [classValueSelected, setClassValueSelected] = useState(parseInt(class_idx));
  const [classNameSelected, setClassNameSelected] = useState('Class');
  const { data: fetchAssignedPhonicsBookList, loading: loadingbooklist } = useQuery(queries.phonicsUnitList.FETCH_ASSIGNED_PHONICS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      assigned_phonics_group_idx: parseInt(key),
    },
  });

  const [onLoadLogicExecuted, setOnLoadLogicExecuted] = useState(false);
  const classQuery = useQuery(queries.getClass.GET_TYPE_CLASS, {
    variables: {
      state: 'ing',
    },
  });

  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(queries.getClass.CLASS_STUDENT_LIST);

  const [assignElibraryToStudents] = useMutation(mutations.phonicsassignElibrary.PHONICS_ASSIGN_ELIBRARY_TO_STUDENTS, {
    onCompleted: () => {
      openNotification('등록 완료!');
      history.goBack();
    },
  });
  // assigneduserList  = [];
  //assignedClasslist = [];
  assignedBookList = [];
  // findstudentresult = [];

  useEffect(() => {
    history.listen((location) => {
      window.location.reload(true);
    });
  }, [history]);

  useEffect(() => {
    if (classes.length > 0) {
      /*setClassValueSelected(assignedClasslist[0]);
      setClassNameSelected(assignedClasslist[1]);
      handleChange(assignedClasslist[0]);
      if (rangeDate?.start == null && rangeDate?.end == null) {
        window.location.reload(false);
      }*/
      //selectYoil.length == 0 && onRangeChange([], [rangeDate?.start, rangeDate?.end]);
      //onRangeChange("dates",[rangeDate?.start,rangeDate?.end])
    }
  }, [fetchAssignedPhonicsBookList?.AssignedPhonicsFetch, classes]);

  useEffect(() => {
    if (fetchAssignedPhonicsBookList?.AssignedPhonicsList) {
      let testtimeinput1 = '';
      let testtimeinput2 = '';
      const classData = classes.find((item) => item.idx === parseInt(class_idx));

      assignedBookList = fetchAssignedPhonicsBookList?.AssignedPhonicsList.map((assigndata, key) => ({
        No: key + 1,
        code: assigndata.book_idx,
        level: assigndata.level,
        no_of_students_complted: 0,
        stage: 0,
        stage_title: assigndata?.title,
        total_students: 0,
        unit: assigndata.unit,
        unit_title: assigndata?.title,
        unit_assign_date: assigndata.start_date,
        user: assigndata.user,
      }));

      assignedStartDate = assignedBookList[0].unit_assign_date.split('T')[0];
      assignedendDate = assignedBookList[assignedBookList.length - 1].unit_assign_date?.split('T')[0] || '';
      const assigneduserListTemp = assignedBookList[0].user.map((item) => item);

      assignedClasslist.push(classData ? classData.idx : parseInt(class_idx), classData ? classData.name : 'Class');
      let selectedBooks = Object.values(assignedBookList.reduce((acc, cur) => Object.assign(acc, { [cur.code]: cur }), {}));

      setListData(selectedBooks);
      setWorkBooks(selectedBooks);
      let selectYoil = selectedBooks.map((loopdata) => loopdata.unit_assign_date?.split('T')[0] || '');
      setSelectYoil(selectYoil);
      onCheckCalendareList(selectYoil);
      setRangeDate({
        start: assignedStartDate,
        end: assignedendDate,
      });
      const findstudentresult = [];
      if (students !== undefined) {
        students.map((findstudent, key) => {
          findstudentresult.push([...new Set(assigneduserListTemp)].includes(findstudent.user.idx));
        });
        console.log(assigneduserListTemp);
        setAssigneduserList([...new Set(assigneduserListTemp)]);
      }
      setTestTime(moment(assignedStartDate).format('HH:mm'));
      setClassValueSelected(assignedClasslist[0]);
      setClassNameSelected(assignedClasslist[1]);
      handleChange(assignedClasslist[0]);
      setCheckedList(findstudentresult);
      getSelectClassStudents({
        variables: { class_idx: parseInt(class_idx) },
      });
    }
  }, [fetchAssignedPhonicsBookList?.AssignedPhonicsList, classes, students]);

  // useEffect(() => {
  //   if (students !== undefined && students.length > 0) {
  //     let checkedStudentList = [];
  //     let selectedStudentsArray = [];
  //     students.map((findstudent, key) => {
  //       checkedStudentList.push(assigneduserList.includes(findstudent.user.idx.toString()));
  //       if (assigneduserList.includes(findstudent.user.idx.toString())) {
  //         selectedStudentsArray.push(findstudent.user);
  //       }
  //     });
  //     setCheckedList(checkedStudentList);
  //     setSelectStudents(selectedStudentsArray);
  //     //onRangeChange("dates",[assignedStartDate,assignedendDate])
  //   }
  // }, [students]);

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }

    /*if (classIdx) {
      setCheckedList([]);
      const selectedClassitem = classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(class_idx))[0];
      setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(class_idx))[0]);
      getSelectClassStudents({
        variables: { class_idx: parseInt(class_idx) },
      });
      firstForm.setFieldsValue({
        class: parseInt(class_idx),
      });
    }*/
  }, [classQuery, fetchStudents, classIdx]);

  /*useEffect(() => {
    let selectedBooks = JSON.parse(localStorage.getItem('selectedBooks'));
    setListData(selectedBooks);
    setWorkBooks(selectedBooks);
  }, []);*/

  function handleDateSelect(selectInfo) {
    formControl.setFieldsValue({
      date: moment(selectInfo.dateStr),
    });
  }

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
    onCheckStudents();
  }

  function onCheckAllChange(e) {
    if (checkedList.length > 0) {
      let tempcheck = checkedList;
      tempcheck.map((loopdata, key) => {
        checkedList[key] = false;
      });
    }
    setCheckedList(new Array(checkedList.length).fill(false));
    if (e.target.checked) {
      setcheckedListAll(true);
      setCheckedList([]);
      onCheckStudents();
      setCheckedList(new Array(students.length).fill(true));
    } else {
      setcheckedListAll(false);
      setCheckedList([]);
      onCheckStudents();
    }
  }

  function onCheckCalendareList(selectYoil) {
    setEvents(
      selectYoil.map((item, i) => {
        return {
          date: item,
          color: '#ffbf32',
          //color: '#378006',
          title: `${i + 1}회차`,
          key: i,
        };
      }),
    );
  }

  function onCheckGenerateList(yoil) {
    const checkStudents = onCheckStudents();
    let workbookdate = [];
    const newListData = workBooks?.map((item, index) => {
      let loopobject = {
        No: index + 1,
        code: item.code,
        level: item.level,
        no_of_students_complted: 0,
        stage: 0,
        stage_title: item.stage_title,
        total_students: 0,
        unit: item.unit,
        unit_assign_date: yoil[index] + 'T00:00:00+00:00',
        unit_title: item.unit_title,
      };
      workbookdate.push(`${moment(`${yoil[index]}`).format('YYYY-MM-DD')}`);
      return loopobject;
    });
    setRangeDate({
      start: assignedStartDate,
      end: assignedendDate,
    });
    setSelectYoil(workbookdate);
    setWorkBooks(newListData);
    //setListData(newListData);
    setListData(newListData);
    checkStudents.length && setListData(newListData);
  }

  function handleChange(value) {
    if (checkedList.length > 0) {
      let tempcheck = checkedList;
      tempcheck.map((loopdata, key) => {
        checkedList[key] = false;
      });
    }
    setCheckedList(new Array(checkedList.length).fill(false));
    let tempchecklist = onCheckStudents();
    if (tempchecklist.length > 0) {
      setCheckedList([]);
      setcheckedListAll(false);
      onCheckStudents();
    } else {
      setCheckedList([]);
      setCheckedList([]);
      setcheckedListAll(false);
      onCheckStudents();
    }
    if (onRangeChangePageload == false) {
      if (fetchStudents?.classStudentList && classQuery?.data?.classList) {
        setClasses(classQuery?.data?.classList);
        setStudents(fetchStudents?.classStudentList);
      }
      setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === value)[0]);
      getSelectClassStudents({
        variables: { class_idx: parseInt(class_idx) },
      });
    } else {
      setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === parseInt(class_idx))[0]);
      getSelectClassStudents({
        variables: { class_idx: parseInt(class_idx) },
      });
    }
    //setCheckedList([]);
  }

  const onCheckStudents = (data) => {
    const astAdd = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    setSelectStudents(students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user));
    return students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user);
  };

  function onRangeChange(dates, dateStrings) {
    assignedStartDate = dateStrings[0];
    assignedendDate = dateStrings[1];
    if (selectedClass) {
      let dayOfWeek = '';
      if (selectedClass.day_of_week) {
        dayOfWeek = customDayOfWeek(selectedClass);
      }
      if (secondForm.getFieldValue('day_of_week')) {
        dayOfWeek = secondForm.getFieldValue('day_of_week').join(', ');
      } else if (!dayOfWeek) {
        dayOfWeek = '일, 월, 화, 수, 목, 금, 토';
      }
      const selectYoil = getDateRangeData(dateStrings[0], dateStrings[1], dayOfWeek);
      setRangeDate({
        start: dateStrings[0],
        end: dateStrings[1],
      });
      setSelectYoil(selectYoil);
      onCheckCalendareList(selectYoil);
      onCheckGenerateList(selectYoil);
    }
  }

  function onTimeChange(time, TimeStrings) {
    setTestTime(TimeStrings);
  }

  function getDateRangeData(param1, param2, dayOfWeek) {
    //param1은 시작일, param2는 종료일이다.
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const res_day = [];
    const ss_day = new Date(param1);
    const ee_day = new Date(param2);
    let count = 0;
    while (ss_day.getTime() <= ee_day.getTime()) {
      let _mon_ = ss_day.getMonth() + 1;
      _mon_ = _mon_ < 10 ? '0' + _mon_ : _mon_;

      let _day_ = ss_day.getDate();
      _day_ = _day_ < 10 ? '0' + _day_ : _day_;

      let yoil = ss_day.getDay();

      if (dayOfWeek.includes(week[yoil]) && workBooks[count]) {
        res_day.push(ss_day.getFullYear() + '-' + _mon_ + '-' + _day_);
        count++;
      }

      ss_day.setDate(ss_day.getDate() + 1);
    }
    return res_day;
  }

  const handleFormSubmit = () => {
    if (selectYoil.length < workBooks.length) {
      var answer = window.confirm(`Do you want to go with ${assignedBookList.length} books ?`);
      if (answer) {
        saveAssignedBooks();
      }
    } else {
      saveAssignedBooks();
    }
  };
  const saveAssignedBooks = () => {
    let studentlength = selectStudents.map((students) => students.idx);
    let selectedstudentlist = [];
    if (studentlength.length == 0) {
      let currentselectionstudent = onCheckStudents();
      selectedstudentlist = currentselectionstudent.map((stddata) => stddata.idx);
    } else {
      selectedstudentlist = selectStudents.map((students) => students.idx);
    }
    if (selectedstudentlist.length > 0) {
      let selectedWorkBooks = Array.from(listData);
      formControl
        .validateFields()
        .then(async (formData) => {
          await assignElibraryToStudents({
            variables: {
              class_idx: selectedClass.idx,
              start_time: selectYoil, // 클래스별 설정된 요일 별 날자 체크(요일 없을시 하루간격)
              limit_time: 1440,
              user_idx_list: selectedstudentlist,
              books_list: selectedWorkBooks.splice(0, selectYoil.length).map((book) => book.code),
              start_date: rangeDate?.start, // 달력 시작일
              end_date: rangeDate?.end, // 달력 마지막일
              elibrary_time: testTime,
              rowidx: parseInt(key),
            },
          });
          firstForm.resetFields();
          formControl.resetFields();
          secondForm.resetFields();
          threeForm.resetFields();
          setClasses([]);
          setStudents([]);
          setSelectedClass(-1);
          setSelectYoil([]);
          setSelectStudents([]);
          setTestTime([]);
          setEvents([]);
          setListData([]);
          setRangeDate({ start: null, end: null });
          // history.push('/elibrary/books');
        })
        .catch((errorInfo) => {
          console.error('errorInfo', errorInfo);
        });
    } else {
      alert('kindly Select Student list');
    }
  };

  function showUpdateConfirm(changeInfo) {
    handleEventChange(changeInfo);
  }

  function handleEventChange(changeInfo, type = '') {
    const {
      start,
      extendedProps: { key },
    } = changeInfo.event.toPlainObject();

    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item.date = start;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item = start;
        }
        return item;
      });
    });

    // updateClassLession({
    //   variables: {
    //     class_idx,
    //     class_lesson_idx: classLessonIdx,
    //     study_date: start,
    //     type,
    //   },
    // });
  }

  function handleDateChange(date, changeDate, index) {
    const name = `testDate-${index}`;
    formControl.setFieldsValue({
      [name]: changeDate && moment(changeDate, 'YYYY-MM-DD'),
    });

    const searchIndex = index - 1;
    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item.date = changeDate;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item = changeDate;
        }
        return item;
      });
    });

    let listdata_backup = workBooks;
    listdata_backup.map((item, index) => {
      console.log(index + ' === ' + searchIndex);
      if (index === searchIndex) {
        listdata_backup[index].unit_assign_date = changeDate;
      }
    });
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: 50,
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Book',
      dataIndex: 'unit_title',
      key: 'lesson',
      align: 'center',
    },
    {
      title: '날짜',
      dataIndex: 'unit_assign_date',
      align: 'center',
      key: 'unit_assign_date',
      width: 200,
      render: (text, record) => {
        const names = `testDate-${record.code}`;
        formControl.setFieldsValue({
          [names]: text && moment(text, 'YYYY-MM-DD'),
        });
        {
          /*return text.split('T')[0];*/
        }
        return (
          <>
            <DatePicker
              name={`testDate-${record.code}`}
              value={text && moment(text, 'YYYY-MM-DD')}
              // onChange={handleDateChange}
              onChange={(date, dateString) => {
                handleDateChange(date, dateString, record.No);
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <Col className="test-syllabus-wrapper" span={24} style={{ marginTop: '10px' }}>
        <TestBlock>
          <Row style={{ padding: 10 }}>
            <Title level={5}>
              <span style={{ color: '#289428' }}>Auto Assign</span>
            </Title>
          </Row>
          <Row className="test-syllabus-box-listing" gutter={[24, 16]}>
            <Col className="test-syllabus-box-a" span={5} style={{ paddingLeft: 20 }}>
              <TestSectionBlock className="test-syllabus-box-a-testsectionblock">
                <Title level={5}>&nbsp;</Title>
                <Form
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="vertical"
                  form={firstForm}
                >
                  <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
                    <Select
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '100%', minWidth: 100 }}
                      placeholder={classNameSelected}
                      onChange={handleChange}
                    >
                      {classes.map((classInfo) => (
                        <Option selected={true} key={classInfo.idx} value={classInfo.idx}>
                          {classInfo.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <List
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>Name</label>
                        <div>
                          전체 선택 &nbsp; <Checkbox checked={checkedListAll} onChange={onCheckAllChange}></Checkbox>
                        </div>
                      </div>
                    }
                    footer={<div></div>}
                    bordered
                    dataSource={students}
                    renderItem={(item, idx) => (
                      <List.Item style={{ display: 'flex' }}>
                        <span>{item.user.name}</span>{' '}
                        <Checkbox key={idx} checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
                      </List.Item>
                    )}
                  />
                </Form>
              </TestSectionBlock>
            </Col>
            <Col className="test-syllabus-box-b" span={10} style={{ textAlign: 'center', paddingRight: 20 }}>
              <TestSectionBlock>
                <Form form={secondForm}>
                  <Title level={5}>Test Date / Time</Title>
                  <FormItem name="day_of_week">
                    <Checkbox.Group options={yoilOptions} name="day_of_week" />
                  </FormItem>
                  <RangePicker
                    style={{ marginBottom: 10 }}
                    onChange={onRangeChange}
                    name="rangeDate"
                    value={[moment(rangeDate.start), moment(rangeDate.end)]}
                  />
                </Form>
                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    contentHeight={250}
                    expandRows={false}
                    initialView="dayGridMonth"
                    selectable={true}
                    events={events}
                    editable={true}
                    eventContent={(eventInfo, index) => {
                      return (
                        <>
                          <div
                            style={{
                              backgroundColor: eventInfo.backgroundColor,
                              color: 'white',
                              borderRadius: 10,
                              width: 20,
                              textAlign: 'center',
                              marginRight: 5,
                            }}
                          >
                            {eventInfo.event.title}
                          </div>
                          {/* <div>{classType[eventInfo.textColor]}</div> */}
                        </>
                      );
                    }}
                    eventChange={showUpdateConfirm}
                  />
                </CalendarBlock>

                <Form
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  layout="horizontal"
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                  form={threeForm}
                >
                  <Form.Item label="Time">
                    <DatePicker
                      picker="time"
                      size="large"
                      format="HH:mm"
                      inputReadOnly={true}
                      showTime={{ value: moment(`${testTime}`, 'HH:mm') }}
                      onChange={onTimeChange}
                    />
                  </Form.Item>

                  {/* <Form.Item>
                    <Button type="primary" onClick={() => onCheckGenerateList(selectYoil)}>
                      Done
                    </Button>
                  </Form.Item> */}
                </Form>
              </TestSectionBlock>
            </Col>

            <Col className="test-syllabus-box-c" span={9} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Title level={5}>Confirmation</Title>
              <Form form={formControl} component={false}>
                <CustomTable
                  dataSource={listData}
                  columns={columns}
                  bordered
                  scroll={{ y: 'calc(100vh - 244px)' }}
                  pagination={{ pageSize: 8 }}
                  size="small"
                  color="#edf3fb"
                />
                <div>
                  <Button
                    type="secondary"
                    shape="round"
                    size="large"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                  <Button type="primary" shape="round" size="large" htmlType="submit" onClick={handleFormSubmit}>
                    Save
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default Assign;
