import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { ImagePopup } from './PopUp';
import AlphabetTopArea from './AlphabetTopArea';
import { DragDropContext } from 'react-beautiful-dnd';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import SoundButton from './SoundButton';
import AlphabetDroppableBox from './AlphabetDroppableBox';
import AlphabetDraggableBox from './AlphabetDraggableBox';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';

const AlphabetDnD9 = ({ customData, onEnd, isEnd }) => {
  const { problem, word } = customData;
  const [dropSound, setDropSound] = useState(undefined);
  const [dropped, setDropped] = useState(Array(problem).fill(false));

  const [droppedIdx, setDroppedIdx] = useState(-1);

  const [underAlphabet, setUnderAlphabet] = useState(problem);
  const handleDragEnd = useCallback(
    (result) => {
      const { destination } = result;
      if (_.isNil(destination) || destination.droppableId.startsWith('under_')) {
        setDropSound('wrong_sound1');
        return;
      }
      setDropSound('mouse-click');

      const droppedWord = destination.droppableId.split('_')[1];

      const copy2 = _.cloneDeep(underAlphabet);
      console.log('droppedWord', droppedWord);
      copy2.splice(copy2.indexOf(droppedWord), 1);
      console.log('copy2', copy2);
      setUnderAlphabet(copy2);

      const copy = _.cloneDeep(dropped);
      const copyLetters = _.cloneDeep(problem);
      copy[copyLetters.indexOf(droppedWord)] = true;
      console.log('copy', copy);
      setDropped(copy);
      setDroppedIdx(copyLetters.indexOf(droppedWord));
    },
    [dropped, underAlphabet, problem],
  );

  const handleDropSoundEnd = () => {
    setDropSound(undefined);
  };

  useEffect(() => {
    if (droppedIdx !== -1) {
      onEnd(problem);
    }
  }, [droppedIdx]);

  useEffect(() => {
    console.log('여기', problem);
    setUnderAlphabet(problem);
    setDroppedIdx(-1);
    if (problem && problem.length > 0) {
      setTimeout(() => {
        setDropped(Array(problem.length).fill(false));
      }, 500);
    }
  }, [problem]);
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <S.RowFlex style={{ minWidth: '95px' }}>
            <S.AlphabetBottomWrapUl
              borderTop={true}
              className="alphabet_wrap gray_bg_border_top"
              style={{
                backgroundColor: 'unset',
                border: 'none',
              }}
            >
              {underAlphabet &&
                underAlphabet.length > 0 &&
                underAlphabet.map((item, key) => (
                  <AlphabetDraggableBox isDropped={dropped[key]} letter={item} idx={key} key={`draggable-letter-key-${key}`} />
                ))}
            </S.AlphabetBottomWrapUl>
          </S.RowFlex>
          <img
            style={{ height: '40px', marginBottom: '1vh' }}
            src="/images/pm/i_arrow_right.svg"
            alt="위로 향하는 화살표"
            className="top_arrow"
          />
          <S.RowFlex style={{ alignItems: 'center', minWidth: '95px', justifyContent: 'center' }}>
            {isEnd?<>
                <S.ImgDndAlphabetP className="font_blue">{word && word.slice(0,(word.length - 1))}</S.ImgDndAlphabetP>
              <AlphabetWrap className="alphabet_wrap">
                {problem &&
                  problem.map((item, key) => (
                    <li key={`droppable-letters-key-${key}`}>
                      <AlphabetDroppableBox letter={item} isDropped={dropped && dropped[key]} />
                    </li>
                  ))}
              </AlphabetWrap>
            </>:<>
              <AlphabetWrap className="alphabet_wrap">
                {problem &&
                  problem.map((item, key) => (
                    <li key={`droppable-letters-key-${key}`}>
                      <AlphabetDroppableBox letter={item} isDropped={dropped && dropped[key]} />
                    </li>
                  ))}
              </AlphabetWrap>
              <S.ImgDndAlphabetP className="font_blue">{word && word.slice(-(word.length - 1))}</S.ImgDndAlphabetP>
            </>}
          </S.RowFlex>
        </div>
      </DragDropContext>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
    </>
  );
};
export default AlphabetDnD9;

const AlphabetWrap = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  flex: 1 1;
  padding: 10px;
  margin: 0;
`;
