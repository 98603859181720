import React from 'react';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { Col, Row, Typography, Input, Button, Modal, Select, Tag, Space  } from 'antd';
import { HeaderTitle, HalfWrapper, ListTitleWrapper } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height:calc(100vh - 84px);
`;

const dataSource = [
  {
    key: '1',
    columnnumber : '',
    columnone : '',
    columntwo : '',
    columnthree : '',
    columnfour : '',
    columnfive : '',
    columnsix : '',
    columnseven : '',
    columneight : '',
  },
  {
    key: '1',
    columnnumber : '',
    columnone : '',
    columntwo : '',
    columnthree : '',
    columnfour : '',
    columnfive : '',
    columnsix : '',
    columnseven : '',
    columneight : '',
  },
  {
    key: '1',
    columnnumber : '',
    columnone : '',
    columntwo : '',
    columnthree : '',
    columnfour : '',
    columnfive : '',
    columnsix : '',
    columnseven : '',
    columneight : '',
  },
  {
    key: '1',
    columnnumber : '',
    columnone : '',
    columntwo : '',
    columnthree : '',
    columnfour : '',
    columnfive : '',
    columnsix : '',
    columnseven : '',
    columneight : '',
  },
  
];

const columns = [
  {
    title: ' ',
    dataIndex: 'columnnumber',
    key: 'columnnumber',
  },
  {
    title: ' ',
    dataIndex: 'columnone',
    key: 'columnone',
  },
  {
    title: ' ',
    dataIndex: 'columntwo',
    key: 'columntwo',
  },
  {
    title: ' ',
    dataIndex: 'columnthree',
    key: 'columnthree',
  },
  {
    title: ' ',
    dataIndex: 'columnfour',
    key: 'columnfour',
  },
  {
    title: ' ',
    dataIndex: 'columnfive',
    key: 'columnfive',
  },
  {
    title: ' ',
    dataIndex: 'columnsix',
    key: 'columnsix',
  },
  {
    title: ' ',
    dataIndex: 'columnseven',
    key: 'columnseven',
  },
  {
    title: ' ',
    dataIndex: 'columneight',
    key: 'columneight',
  },
  {
    title: ' ',
    dataIndex: 'columnnine',
    key: 'columnnine',
  },
];

const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const Admin = () => {
  const history = useHistory();
  const type = userInfoVar()?.type;
  if (type && type !== 4) {
    history.replace('/');
  }
  return (
    <>
    <Col className="settingpayadmin-wrapper-main" span={24} style={{textAlign: "right", margin:0}}>
    <HeaderTitle level={4}>Settings ( 구매 사이트 설정 )</HeaderTitle>
      <MainBlock className="settingpayadmin-wrapper-mainblock">
        <div className="card-container">
    <Tabs type="card">
      <TabPane tab="컨텐츠 정보" key="1">
        <Row  gutter={[24, 16]} className="settingpayadmin-top-head">
          <Col className="settingpayadmin-top-title" span={13} style={{textAlign: "left"}}>
            <Title level={5}>컨텐츠 관리</Title>
          </Col>
        </Row>
        <CustomTable
          className="settingpayadmin-table-table"
          pagination={{ pageSize: 12 }}
          dataSource={dataSource}
          columns={columns}
          size="small"
          color="#edf3fb"
          scroll={{ y: 'calc(100vh - 254px)' }}
        />
      </TabPane>
      <TabPane tab="결제정보" key="2">
        <Row  gutter={[24, 16]} className="settingpayadmin-top-head">
          <Col className="settingpayadmin-top-title" span={13} style={{textAlign: "left"}}>
            <Title level={5}>컨텐츠 관리</Title>
          </Col>
        </Row>
        <CustomTable
          className="settingpayadmin-table-table"
          pagination={{ pageSize: 12 }}
          dataSource={dataSource}
          columns={columns}
          size="small"
          color="#edf3fb"
          scroll={{ y: 'calc(100vh - 254px)' }}
          />
      </TabPane>
      <TabPane tab="배송 정보" key="3">
        <Row  gutter={[24, 16]} className="settingpayadmin-top-head">
          <Col className="settingpayadmin-top-title" span={13} style={{textAlign: "left"}}>
            <Title level={5}>컨텐츠 관리</Title>
          </Col>
        </Row>
        <CustomTable
        className="settingpayadmin-table-table"
        pagination={{ pageSize: 12 }}
        dataSource={dataSource}
        columns={columns}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
        />
      </TabPane>
    </Tabs>
  </div>
      </MainBlock>
    </Col>
    
    </>
  );
};

export default Admin;
