import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Calendar, Radio, Select, Input, Button, Modal } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
//import styled from 'styled-components';

import SmsModal from 'src/components/Modal/SmsModal';
import SmsSendGroup from './SmsSendGroup';
import SmsSendBoard from './SmsSendBoard';
import ReserveTxt from './ReserveTxt';
import settingIcn from 'src/images/sms-icn-setting-blue.png';
import downIcn from 'src/images/sms-icn-down-blue.png';
import undoIcn from 'src/images/sms-icn-undo-blue.png';
import smsIcn from 'src/images/smsIcon2.png';
import katalkIcn from 'src/images/katalk-logo.png';
import { getByteLength } from 'src/utils/index';
import { MAX_SMS_BYTES } from 'src/constants/common';
//import msgIcn from 'src/images/msg-logo.png';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { SAVE_SMS_TEXT, SEND_SMS_TEXT_MESSAGE } from 'src/operations/mutations/message';
import {
  MY_SAVED_SMS_TEXT_LIST,
  CALLING_NUMBER_LIST,
  MY_SENT_SMS_GROUP_HISTORY_LIST,
  CAMPUS_REMAINED_SMS_POINT,
  COMPANY_SMS_TEMPLATE_LIST,
} from 'src/operations/queries/message';
import { MSG_TYPE_SMS, MSG_TYPE_LMS, MSG_TYPE_TALK, MSG_FEE_LIST } from 'src/constants/common';
import { getHumanReadablePhoneNumber } from 'src/utils/index';
import { useCampusClass } from 'src/components/common/ClassSelectBox';
import { userInfoVar } from 'src/apollo/cache';
import { isAfterSchool } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import moment from 'moment';



const MESSAGE_SMS = 1;
const MESSAGE_TALK = 2;
const MESSAGE_PUSH = 3;

const SmsTabSent = ({ smsAccountInfo, smsRemainedPoint, refetchPoint }) => {
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const [smsMsgType, setSmsMsgType] = useState(MESSAGE_SMS);
  const [inputMessage, setInputMessage] = useState('');
  const [selectedSender, setSelectedSender] = useState('');
  const [checkRowList, setcheckRowList] = useState([]); //받는 사람 테이블 체크박스 control용으로 사용
  const [destInfoList, setDestInfoList] = useState([]); //sms 보낼때 사용, 반선택시
  const [destInfoListObj, setDestInfoListObj] = useState({}); //sms 보낼때 사용, 직접 입력시
  const [yellowId, setYellowId] = useState('');
  const [templatecode, setTemplatecode] = useState('');
  // const [modalVisible,setModalVisible] = useState(false);
  const companyName = useSelector(classStoreData);

  const [isDirectInput, setIsDirectInput] = useState(false);

  function isOverRemainedPoint(msg_type, smsRemainedPoint, msg_count) {
    let boolean = MSG_FEE_LIST[msg_type] * msg_count > smsRemainedPoint
    console.log(MSG_FEE_LIST, smsRemainedPoint, msg_count);
    if(isAfterSchool(companyName)&&boolean){
      if(smsRemainedPoint-(MSG_FEE_LIST[msg_type] * msg_count)<=-20000){
        alert('1899-0597 (내선 2번) 위 전화번호로 문의 바랍니다.')
        return true;
      }
      boolean = window.confirm("유료 서비스입니다. 전송하시겠습니까?")?false:true;
    }
    return boolean;
  }


  const receiversRowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      console.log('selectedRows', selectedRows);
      setDestInfoList(selectedRows.map((data) => ({ user_idx: data.user_idx, phone: data.phoneNum })));
      setcheckRowList(selectedRows.map((data) => data.key));
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.phoneNum ? false : true,
        key: record.key,
      };
    },
  };

  const isMMS = useMemo(() => {
    if (getByteLength(inputMessage) > MAX_SMS_BYTES) {
      return true;
    }
    return false;
  }, [inputMessage]);
  const [pid, ppwd] = useMemo(() => {
    if (smsAccountInfo) {
      const [first, second] = smsAccountInfo.split('&');
      if (first && second) {
        const [, id] = first.split('=');
        const [, pw] = second.split('=');
        if (id && pw) {
          return [id, pw];
        }
      }
    }
    return [null, null];
  }, [smsAccountInfo]);

  const { data: dataSavedTxt } = useQuery(MY_SAVED_SMS_TEXT_LIST);
  const { data: dataTemplateList } = useQuery(COMPANY_SMS_TEMPLATE_LIST, {
    variables: { company_idx: userInfoVar()?.campus?.company?.idx },
    skip: !userInfoVar()?.campus?.company?.idx,
  });

  const dataTalkTxt = useMemo(() => {
    const list = dataTemplateList?.companyTalkTemplateList;
    if (list) {
      const campus_name = userInfoVar()?.campus?.name;
      return list.map((item) => {
        return {
          txt: item?.message_template?.replace('#{학교명}', campus_name.replace(/초$|초등학교$|초교$/gi, '')) || '',
          code: item?.template_code,
        };
      });
    }
    return [];
  }, [dataTemplateList]);

  useEffect(() => {
    if (dataTemplateList?.companyTalkTemplateList) {
      setYellowId(dataTemplateList?.companyTalkTemplateList[0]?.yellow_key);
    }
  }, [dataTemplateList]);
  const [fetchCallingNumbers, { data: dataPhoneNumber, loading: loadingNumbers }] = useLazyQuery(CALLING_NUMBER_LIST, {
    fetchPolicy: 'no-cache',
  });
  //
  const [sendMessage, { loading: loadingSend }] = useMutation(SEND_SMS_TEXT_MESSAGE, {
    onCompleted: (res) => {
      console.log(res);
      alert('발송했습니다.');
      setDestInfoList([]);
      setcheckRowList([]);
      refetchPoint();
    },
    onError: (e) => {
      console.log(e);
      alert(e.message);
    },
    refetchQueries: [
      {
        query: CAMPUS_REMAINED_SMS_POINT,
        variables: { campus_idx: parseInt(userInfoVar()?.campus_idx) },
      },
      {
        query: MY_SENT_SMS_GROUP_HISTORY_LIST,
        variables: { ym: moment().format('YYYY-MM') },
      },
    ],
  });

  const [saveText, { loadingSave }] = useMutation(SAVE_SMS_TEXT, {
    onCompleted: ({ SaveSmsText }) => {
      const { success, message } = SaveSmsText;
      if (success) {
        alert('저장했습니다.');
      } else {
        alert(message);
      }
    },
    onError: (e) => {
      console.log(e);
      alert('오류가 발생했습니다.');
    },
    refetchQueries: [
      {
        query: MY_SAVED_SMS_TEXT_LIST,
      },
    ],
  });

  const senderOptions = useMemo(() => {
    const list = dataPhoneNumber?.callingNumberList;
    if (list) {
      return list.map((item) => ({ label: getHumanReadablePhoneNumber(item.phone), value: item.phone }));
    }

    return [{ label: '발신번호등록하세요', value: '' }];
  }, [dataPhoneNumber]);

  useEffect(() => {
    if (senderOptions[0]) {
      setSelectedSender(senderOptions[0]?.value || '');
    }
  }, [senderOptions]);

  useEffect(() => {
    if (pid) {
      fetchCallingNumbers({ variables: { pid } });
    }
  }, [pid]);

  useEffect(() => {
    if (!isDirectInput) {
      setDestInfoListObj({});
    }
  }, [isDirectInput]);

  const handleFetchCallingNumber = useCallback(() => {
    if (pid) {
      fetchCallingNumbers({ variables: { pid } });
    }
  }, [pid]);

  const handleChangeMessageType = (e) => {
    // if (MESSAGE_TALK === e.target.value) {
    //   alert('준비 중입니다.');
    //   return false;
    // }
    setInputMessage('');
    setSmsMsgType(e.target.value);
  };

  function handleSenderChange(value) {
    console.log(`selected ${value}`);
    setSelectedSender(value);
  }
  function handleChangeTextIput(e) {
    setInputMessage(e.target.value);
  }
  function handleSend(isNow, reserveDate) {
    if (!selectedSender) {
      alert('발신번호를 선택하세요.');
      return;
    }

    if (!inputMessage) {
      alert('메시지를 입력하세요.');
      return;
    }

    let receiverInfoList = [];
    if (isDirectInput) {
      receiverInfoList = Object.keys(destInfoListObj)
        .map((item) => {
          return {
            phone: destInfoListObj[item],
          };
        })
        .filter((item2) => item2.phone);
    } else {
      receiverInfoList = [...destInfoList];
    }

    if (receiverInfoList.length === 0) {
      alert(isDirectInput ? '받는 사람을 입력하세요.' : '받는 사람을 선택하세요.');
      return;
    }

    let msg_type = MSG_TYPE_SMS;
    if (smsMsgType === MESSAGE_TALK) {
      msg_type = MSG_TYPE_TALK;
    } else if (smsMsgType === MESSAGE_SMS && isMMS) {
      msg_type = MSG_TYPE_LMS;
    }

    if (isOverRemainedPoint(msg_type, smsRemainedPoint, receiverInfoList.filter((item2) => item2.phone).length)) {
      alert('충전 포인트가 모자랍니다. 충전 후 사용하세요.');
      return;
    }
    console.log(receiverInfoList);
    console.log('selectedClassIdx', selectedClassIdx);

    const message_input = {
      msg_type,
      dest_info_list: receiverInfoList
        .filter((item2) => item2.phone)
        .map((item) => ({ ...item, phone: item.phone?.replace(/[^0-9]+/gi, '') })),
      sendphone: selectedSender.replace(/[^0-9]+/gi, ''),
      class_idx: selectedClassIdx,
      pid,
      ppwd,
      message: inputMessage,
      reserved_datetime: isNow ? moment().add(2, 'seconds').format('YYYYMMDDHHmmss') : reserveDate,

      yellow_id: yellowId,
      templatecode: templatecode,
    };

    sendMessage({ variables: { message_input } });
  }
  function handleMessageClick(msg, code) {
    setInputMessage(msg || '');
    setTemplatecode(code || '');
  }
  function handleSaveText() {
    if (!inputMessage) {
      alert('메시지를 입력하세요.');
      return;
    }
    if (loadingSave) {
      return;
    }
    saveText({ variables: { text: inputMessage } });
  }

  function handlePhoneNumberChange(idx, number) {
    const copy = { ...destInfoListObj };
    copy[idx] = number;
    setDestInfoListObj({ ...copy });
  }

  return (
    <>
      <Row className="row-container">
        <Col span={7} className="phone_con">
          <div className="phoneBlkBg">
            <div className="phoneWhiteBg">
              <Row style={{ padding: '15px 0 0 0' }}>
                <Col span={8} push={1}>
                  <h1 style={{ textAlign: 'center', marginTop: '10px' }}>Messages</h1>
                </Col>
                <Col span={12} push={5}>
                  <Select
                    loading={loadingNumbers}
                    defaultValue={''}
                    value={selectedSender}
                    className="senderBox"
                    onChange={handleSenderChange}
                    options={senderOptions}
                  />
                  <Button type="primary" shape="circle" icon={<RedoOutlined />} size="small" onClick={handleFetchCallingNumber} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Input.TextArea
                    readOnly={smsMsgType === MESSAGE_TALK}
                    //readOnly={true}
                    className="smsTxtArea"
                    style={isMMS ? { color: 'red' } : {}}
                    value={inputMessage}
                    onChange={handleChangeTextIput}
                    onFocus={() => {
                      if (smsMsgType === MESSAGE_TALK && !inputMessage) {
                        //alert('알림톡은 직접 입력이 불가능합니다. 메시지를 불러오세요.');
                        //console.log('talk');
                        Modal.info({ title: '알림톡 메시지를 불러오세요.' });
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ padding: '20px 0' }} className="sendMsgBtnWrap">
                <Col span={8} push={1}>
                  <SmsModal
                    onMessageClick={handleMessageClick}
                    data={smsMsgType === MESSAGE_TALK ? dataTalkTxt : dataSavedTxt?.mySavedSmsTextList}
                    isTalk={smsMsgType === MESSAGE_TALK}
                  />
                </Col>
                <Col span={2} push={1}>
                  <img src={settingIcn} className="smsIcn" alt="setting-icon" />
                </Col>
                <Col span={2} push={1}>
                  <img src={downIcn} style={{ cursor: 'pointer' }} onClick={handleSaveText} className="smsIcn" alt="down-icon" />
                </Col>
                <Col span={2} push={1}>
                  <img
                    src={undoIcn}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setInputMessage('');
                    }}
                    className="smsIcn"
                    alt="undo-icon"
                  />
                </Col>
                <Col span={7} push={2}>
                  <span
                    style={{
                      width: '40px',
                      display: 'block',
                      textAlign: 'center',
                      background: '#fff',
                      color: '#333',
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                      padding: '5px 10px',
                    }}
                  >
                    {getByteLength(inputMessage)}
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col span={7} className="toSendGrp_con" style={{ zIndex: 2 }}>
          <Row>
            <Col
              className="toSendGrp_tit"
              span={24}
              style={{ textAlign: 'center', position: 'relative', padding: '10px', background: '#1890ff', color: '#fff', fontSize: '1rem' }}
            >
              <span>받는 사람 번호</span>
            </Col>
          </Row>
          <SmsSendGroup
            selectedClassIdx={selectedClassIdx}
            onClassChange={onClassChange}
            rowSelection={receiversRowSelection}
            onPhoneNumberChange={handlePhoneNumberChange}
            isDirectInput={isDirectInput}
            setIsDirectInput={setIsDirectInput}
            destInfoListObj={destInfoListObj}
            setTemplatecode={setTemplatecode}
            setInputMessage={setInputMessage}
            isTalk={smsMsgType === MESSAGE_TALK}
            dataTalkTxt={dataTalkTxt}
          />
        </Col>

        <Col span={10} push={1} className="time_con">
          <SmsSendBoard smsRemainedPoint={smsRemainedPoint} />
          <Row className="dateCon" style={{ paddingBottom: '10px' }}>
            <Col span={12} className="DateTime_Wrap">
              <h3 style={{ marginTop: '5px', textAlign: 'center' }}>Date</h3>
              <Calendar fullscreen={false} />

              <Row>
                <Col className="SmsRadioBtn_Wrap">
                  <Radio.Group
                    style={{ display: 'inline-flex', background: '#eee', borderRadius: '5px', paddingBottom: '12px' }}
                    onChange={handleChangeMessageType}
                    value={smsMsgType}
                  >
                    <Radio value={MESSAGE_SMS}>
                      <img src={smsIcn} style={{ width: '100%', display: 'block' }} alt="sms-icon" />
                    </Radio>

                    <Radio value={MESSAGE_TALK}>
                      <img src={katalkIcn} style={{ width: '97%', display: 'block' }} alt="kakaotalk-icon" />{' '}
                    </Radio>

                    {/* <Radio value={MESSAGE_PUSH} disabled={true}>
                      <img src={'../../images/notify.png'} style={{ width: '100%', display: 'block' }} alt="message-icon" />{' '}
                    </Radio> */}
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
            <Col className="reserveWrap" span={12} style={{ borderLeft: '1px dashed #d2d6d8', padding: '10px 20px', background: '#fff' }}>
              <div className="reserve-con" style={{ fontWeight: '600', marginTop: '7px' }}>
                <h3 style={{ fontWeight: '600', marginBottom: '20px', textAlign: 'center' }}>예약 문자</h3>
                <ReserveTxt onSend={handleSend} loadingSend={loadingSend} sendingCount={checkRowList.length} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SmsTabSent;
