import React, { useState } from 'react';
// import SwitchArea from './SwitchArea';
// import PopUp from './PopUp';
import Keyboard from './Keyboard';
import ImageWordBox from './ImageWordBox';
import PageNum from './PageNum';
import { useLocation } from 'react-router-dom';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';

const ImageWritePattern = ({ userInputWord, hint, steps, setSteps, problem, onFinish, onWriteEnd }) => {
  const [getKey, setGetKey] = useState('');
  const location = useLocation();
  const res = location.pathname.split('/');
  console.log(getKey,'getKey')
  return (
    <>
      <Wrap
        className={
          res[2] === '3a' && res[6] == '6' ? 'main_content  stage6_content  row2 look-n-write-popup' : 'main_content  stage6_content  row2'
        }
      >
        <PageNum num={steps + 1} total={problem && problem.length} />

        <S.ImageSelectTopBox className="top_box" style={{height:'45%'}}>
          <ImageWordBox getKey={getKey} userInputWord={userInputWord} problem={problem} onFinish={onFinish} steps={steps} setSteps={setSteps} hint={hint} />
        </S.ImageSelectTopBox>
        <S.PenKeyboardWrapMain className="bottom_box" style={{height:'50%'}}>
          <S.PenKeyboardWrap style={{ padding: '10px 0'}} className="pen_keyboard_wrap">
            <Keyboard getKey={getKey} setGetKey={setGetKey} enabledWritePreview={false} imageWordWrite={true} steps={steps} onWriteEnd={onWriteEnd} />
          </S.PenKeyboardWrap>
        </S.PenKeyboardWrapMain>
      </Wrap>
      {/* {isLoading && <PopUp popUpType="loading" />} */}
    </>
  );
};

export default ImageWritePattern;
const Wrap = styled(S.ColumnFlex)`
  flex: 1 1;
  align-self: stretch;
  height: calc(100% - 70px);
  @media (max-height: 720px) {
    height: calc(100% - 60px);
  }
  @media (max-height: 500px) {
    height: calc(100% - 40px);
  }
`;
