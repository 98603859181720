import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const Field = styled(Col)`
  padding-bottom: 15px;
`;

function FcDetail({ fcInfo }) {
  return (
    <>
      <Row style={{ padding: '20px 30px', background: '#F5F5F5' }}>
        <Field span={24}>
          <Row>
            <Col span={5}>
              <Text strong>업체명 :</Text>
            </Col>
            <Col>{fcInfo?.name}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={5}>
              <Text strong>연락처 :</Text>
            </Col>
            <Col>{fcInfo?.phone}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={5}>
              <Text strong>주소 :</Text>
            </Col>
            <Col>{fcInfo?.address}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={5}>
              <Text strong>FAX :</Text>
            </Col>
            <Col>{fcInfo?.fax}</Col>
          </Row>
        </Field>
      </Row>
    </>
  );
}

FcDetail.prototype = {
  fcInfo: PropTypes.objectOf.isRequired,
};

export default FcDetail;
