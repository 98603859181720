import { gql } from '@apollo/client';

export const GET_BOOK_RETURN_LIST = gql`
  query getBookReturnList($ym: String!) {
    getBookReturnList(ym: $ym) {
      idx
      title
      idate
      stat
      comment
      user {
        name
      }
      campus {
        name
        type
        campus {
          name
        }
      }
      book_return_list {
        idx
        product_code
        product_name
        price
        quantity
      }
    }
  }
`;

export const GET_BOOK_RETURN_LIST_BY_DURATIOIN = gql`
  query getBookReturnListByDuration($start: String!, $end: String!) {
    getBookReturnListByDuration(start: $start, end: $end) {
      idx
      title
      idate
      stat
      comment
      user {
        name
      }
      campus {
        name
        type
        campus {
          name
        }
      }
      book_return_list {
        idx
        product_code
        product_name
        price
        quantity
      }
    }
  }
`;

export const GET_ADMIN_BOOK_RETURN_LIST = gql`
  query getAdminBookReturnList($ym: String!, $company_idx: Int!) {
    getAdminBookReturnList(ym: $ym, company_idx: $company_idx) {
      idx
      title
      idate
      stat
      comment
      user {
        name
        email
        phone
      }
      campus {
        name
        type
        campus {
          name
        }
      }
      book_return_list {
        idx
        product_code
        product_name
        price
        quantity
        idate
      }
    }
  }
`;

export const GET_ADMIN_BOOK_RETURN_LIST_BY_DURATIOIN = gql`
  query getAdminBookReturnListByDuration($start: String!, $end: String!, $company_idx: Int!) {
    getAdminBookReturnListByDuration(start: $start, end: $end, company_idx: $company_idx) {
      idx
      title
      idate
      stat
      comment
      user {
        name
        email
        phone
      }
      campus {
        name
        type
        campus {
          name
        }
      }
      book_return_list {
        idx
        product_code
        product_name
        price
        quantity
        idate
      }
    }
  }
`;
