import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const AITopicMenuGroup = ({ currentMenu}) => {
  const history = useHistory();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
      <MenuButton
        type={`${currentMenu === 'ai-topic-management' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/ai-topic/list');
        }}
      >
        AI Topic 관리
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'ai-topic-assign-list' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/ai-topic/assign-list');
        }}
      >
        AI Topic Assign List
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'ai-topic-result' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/ai-topic/result');
        }}
      >
        AI Topic 배정 결과
      </MenuButton>
    </div>
  );
};

export default AITopicMenuGroup;
