import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { alphabetBoxTypeChecker } from '../../utils/pm/util';
const AlphabetDraggableBox = ({ letter, idx,isDropped=false }) => {
  return (
    <Droppable droppableId={`under_${letter}`} type={letter} isDropDisabled={true}>
      {(providedDrop) => (
        <li ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
          <Draggable draggableId={`underDrag${letter}`} index={idx} type={letter}>
            {(provided) => (
              <S.AlphabetBox
                row={alphabetBoxTypeChecker(letter.length > 1 ? 3 : 1)}
                className={`alphabet_box ${letter.length > 1 ? 'row3' : 'row1'} green`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                hide={isDropped}
              >
                <span style={{ color: '#f368e0' }} className="font_pink">
                  {letter}
                </span>
              </S.AlphabetBox>
            )}
          </Draggable>
          <ul style={{ display: 'none' }}>{providedDrop.placeholder}</ul>
        </li>
      )}
    </Droppable>
  );
};

export default AlphabetDraggableBox;
