import React, { useEffect, useState } from 'react';
import { Modal, Spin, Image } from 'antd';
import ReactPlayer from 'react-player';
import { CaretRightOutlined, PauseCircleOutlined } from '@ant-design/icons';

function MediaPlayer({ item, isOpen, dontHandleClick, handleCloseClick, Icon, style }) {
  const [showModal, setShowModal] = useState(false);
  const [paused, setPaused] = useState(false);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (dontHandleClick) {
      setShowModal(isOpen);
    }
  }, [isOpen]);

  const handleClick = () => {
    if (!dontHandleClick) {
      setShowModal(true);
    }
  };

  const handleModelClick = () => {
    setPaused(true);
    setShowModal(false);

    if (handleCloseClick) {
      handleCloseClick();
    }
  };

  return (
    <>
      <Modal
        title={item ? item.title : ''}
        visible={showModal}
        width="80%"
        bodyStyle={{ height: 'calc(100vh - 120px)' }}
        style={{ top: 20 }}
        closable={true}
        footer={null}
        onCancel={handleModelClick}
      >
        {item ? (
          <ReactPlayer
            url={item.path}
            width="100%"
            height="100%"
            style={{ top: 20 }}
            config={{
              file: {
                forceVideo: true,
                attributes: { controls: true, poster: '/images/boom.jpg' },
              },
            }}
          />
        ) : null}
      </Modal>

      {style.flatAudio ? (
        <>
          {playing ? (
            <PauseCircleOutlined onClick={() => setPlaying(false)} style={{ ...style, fontSize: '40px', width: '100%' }} />
          ) : (
            <CaretRightOutlined onClick={() => setPlaying(true)} style={{ ...style, fontSize: '40px', width: '100%' }} />
          )}
          <ReactPlayer
            url={item.path}
            playing={playing}
            width="100%"
            height="0px"
            loop={false}
            config={{
              controls: true,
            }}
          />
        </>
      ) : (
        <Icon onClick={handleClick} style={style} />
      )}
    </>
  );
}

export default MediaPlayer;
