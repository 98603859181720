import React, { useMemo, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import * as queries from 'src/operations/queries';
import { format } from 'date-fns';
import { useModal } from 'src/utils/hooks';
import ScriboSubscriptionAssignModal from 'src/components/Modal/ScriboSubscriptionAssignModal';

const ScriboSubscriptionList = () => {
  const [selectedScriboSubscription, setSelectedScriboSubscription] = useState(null);
  const { data: dataUser } = useQuery(GET_USER_INFO);

  const [fetchScriboSubscriptionList, { loading, data: scriboSubscriptionResponse }] = useLazyQuery(
    queries.scribo.FETCH_SCRIBO_SUBSCRIPTION_LIST_FOR_CAMPUS,
  );

  const [fetchcampusStudents,{ data: campusStudents }] = useLazyQuery(queries.getUser.GET_CAMPUS_STUDENT_USERS,);

  useMemo(() => {
    if (dataUser?.getUserData?.campus_idx) {
      fetchScriboSubscriptionList({
        variables: {
          campus_idx: dataUser.getUserData.campus_idx,
        },
      });
      
      fetchcampusStudents({ variables: { campus_idx: dataUser.getUserData.campus_idx,}, });
      
    }
  }, [dataUser, fetchScriboSubscriptionList, fetchcampusStudents]);

  const scriboSubscriptionData = useMemo(() => {
    if (scriboSubscriptionResponse?.scriboSubscriptionList) {
      return scriboSubscriptionResponse?.scriboSubscriptionList.map((item, i) => {
        let status = 0;
        if(new Date(item.license_start_date) < new Date(Date.now())){
          status = 1;
          if(new Date(new Date(item.license_start_date).getTime() + 365 * 24*60*60*1000) < new Date(Date.now())){
            status = 2;
          }
        } 
        return {
          no: 1 + i,
          key: item.idx,
          email: item.email,
          licenseStartDate: format(new Date(item.license_start_date), 'yy-MM-dd HH:mm'),
          campusName: item.campus?.name ? item.campus.name : '',
          assignedUserName: item.user?.name? item.user.name : '',
          subscriptionStartDate: item.assigned_date ? format(new Date(item.assigned_date), 'yy-MM-dd HH:mm') : '',
          assignedSubscriptionDays: item.number_of_days ? item.number_of_days : '',
          status: status,
        };
      });
    }
    return [];
  }, [scriboSubscriptionResponse]);

  const handleEditClick = ({ key }) => {
    setSelectedScriboSubscription(scriboSubscriptionResponse?.scriboSubscriptionList.filter((item) => item.idx === key)[0]);
    ScriboSubscriptionAssignShowModal();
  };

  const getColumns = () => {
    return [
      {
        key: 'no',
        title: 'No',
        dataIndex: 'no',
        width: 60,
        align: 'center',
      },
      {
        key: 'email',
        title: '이메일',
        dataIndex: 'email',
        align: 'center',
        width: 170,
      },
      {
        key: 'licenseStartDate',
        title: '라이센스 시작 날짜',
        dataIndex: 'licenseStartDate',
        align: 'center',
      },
      {
        key: 'campusName',
        title: '학원',
        dataIndex: 'campusName',
        align: 'center',
      },
      {
        key: 'status',
        title: '현황',
        dataIndex: 'status',
        align: 'center',
        render: (text) => {
          return text===2 ? 'Expired' : text===1 ? 'Active' : 'Not Started';
        },
      },
      {
        key: 'assignedUserName',
        title: '배정된 사용자',
        dataIndex: 'assignedUserName',
        align: 'center',
      },
      {
        key: 'subscriptionStartDate',
        title: '이용 시작 날짜',
        dataIndex: 'subscriptionStartDate',
        align: 'center',
      },
      {
        key: 'assignedSubscriptionDays',
        title: '배정된 이용 기간',
        dataIndex: 'assignedSubscriptionDays',
        align: 'center',
      },
      {
        key: 'edit',
        title: '배정 수정하기',
        dataIndex: 'edit',
        align: 'center',
        render: (_, row) => {
          return (
            <Button
              onClick={() => {
                handleEditClick(row);
              }}
            >{row.assignedUserName ? '수정하기 및 삭제하기':'배정하기'}
            </Button>
          );
        },
      },
    ];
  };

  const {
    visible: ScriboSubscriptionAssignVisible,
    showModal: ScriboSubscriptionAssignShowModal,
    handleCancel: handleScriboSubscriptionAssignCancel,
  } = useModal();

  return (
    <>
      <div className="manage-action-wrapper-main">
        <div
          className="action-wrapper-main-inner"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '7vh',
            width: '100%',
          }}
        >
          <HeaderTitle level={4}>Scribo Subscription List</HeaderTitle>
        </div>
        <Row>
          <Col>
            <HalfWrapper className="books-result-halfwrapper">
              <CustomTable
                style={{ width: '100%' }}
                dataSource={scriboSubscriptionData}
                columns={getColumns()}
                key={(r) => r.idx}
                pagination={false}
                size="small"
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
                loading={loading}
              />
            </HalfWrapper>
          </Col>
        </Row>
      </div>
      {ScriboSubscriptionAssignVisible && (
        <ScriboSubscriptionAssignModal
          visible={ScriboSubscriptionAssignVisible}
          handleCancel={handleScriboSubscriptionAssignCancel}
          selectedScriboSubscription={selectedScriboSubscription}
          campusId={dataUser?.getUserData?.campus_idx}
          campusStudents={campusStudents?.getCampusStudentUsers}
          ScriboSubscriptionAssignShowModal={ScriboSubscriptionAssignShowModal}
        />
      )}
    </>
  );
};

export default ScriboSubscriptionList;
