import React from 'react';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isPturn } from 'src/utils';
import StudentsBatchForSmartEclass from './StudentsBatchForSmartEclass';
import StudentsBatchCommon from './StudentsBatchCommon';

const StudentsBatch = () => {
  const companyName = useSelector(classStoreData);
  return isPturn(companyName) ? <StudentsBatchForSmartEclass /> : <StudentsBatchCommon />;
};

export default StudentsBatch;
