import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { CloseCircleFilled } from '@ant-design/icons';

const VocaHeader = () => {
  return (
    <>
      <header>
        <div>
          <h3>Let's Go 1-1</h3>
        </div>
        <div className="lesson-tit ">
          <h3>Unit 01</h3>
        </div>
        <div className="lesson-tit ">
          <h3>Lesson 02</h3>
        </div>
        <div className="tit">
          <h3>Reading : My Home</h3>
        </div>

        <div className="user-info-con">
          <h3>Id: apple1004</h3>
          <div className="close">
            <CloseCircleFilled />
          </div>
        </div>
      </header>
    </>
  );
};

export default VocaHeader;
