import React, { useState, useEffect, useRef } from 'react';
import Sound from 'react-sound';
import _ from 'lodash';

const wrapperStyle = {
  backgroundColor: '#555',
  position: 'absolute',
  bottom: '150px',
  width: '310px',
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderRadius: '5px',
  marginLeft: '-155px',
  display: 'flex',
  left: '50%',
  right: '0',
  alignItems: 'center',
  justifyContent: 'center',
};

const StorySoundButton = ({ src, setenceIdx, setSetenceIdx }) => {
  const getSoundUrls = (src) => {
    if (src instanceof Array) {
      return src.map((item) => `https://cdn.cloubot.com/PM/audio/sounds/reading/${item}`);
    }
    return [`https://cdn.cloubot.com/PM/audio/sounds/${src}.mp3`];
  };
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  // const [soundIndex, setSoundIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [playPosition, setPlayPosition] = useState(0);

  const progressRef = useRef();

  const urls = getSoundUrls(src);
  const url = urls[setenceIdx];

  const handleFinishPlay = () => {
    setProgress(0);
    setPlayStatus(Sound.status.STOPPED);
    if (urls.length > 1 && setenceIdx < urls.length - 1) {
      setTimeout(() => {
        setSetenceIdx(setenceIdx + 1);
        setPlayStatus(Sound.status.PLAYING);
      }, 2500);
    }

    if (urls.length > 1 && setenceIdx === urls.length - 1) {
      setSetenceIdx(0);
    }
  };
  const handleClick = () => {
    if (url) {
      if (playStatus !== 'PLAYING') setPlayStatus(Sound.status.PLAYING);
      else setPlayStatus(Sound.status.PAUSE);
    }
  };
  const handlePlaying = (e) => {
    if (!_.isNil(playPosition)) setPlayPosition(null);
    if (totalDuration === 0) setTotalDuration(e.duration / 1000);
    setProgress((e.position / e.duration) * 100);
  };
  const handleProgressClick = (e) => {
    const clickedPosition = e.clientX - progressRef.current.getBoundingClientRect().x;
    const linear = progressRef.current.getBoundingClientRect().width / Math.floor(totalDuration);
    setPlayPosition((clickedPosition / linear) * 1000);
    // setProgress((clickedPosition / linear) * 1000);
  };

  useEffect(() => {
    return () => {
      setSetenceIdx(0);
    };
  }, []);

  return (
    <div className="play_box">
      <button type="button" onClick={handleClick}>
        <img src={`/images/pm/${playStatus === 'PLAYING' ? 'i_stop' : 'i_play'}.svg`} alt="재생 버튼" />
      </button>
      <div className="progress_list" ref={progressRef} onClick={handleProgressClick}>
        <div className="progress-light" style={{ width: `${progress}%` }}></div>
      </div>
      <Sound
        url={url}
        playStatus={playStatus}
        onFinishedPlaying={handleFinishPlay}
        onPlaying={handlePlaying}
        playFromPosition={playPosition}
      />
    </div>
  );
};

export default StorySoundButton;
