import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Row, Typography,Radio, Modal } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined, RedoOutlined, DownloadOutlined } from '@ant-design/icons';
import XLSX from 'xlsx';
import moment from 'moment';

import StudentModal from 'src/components/Modal/StudentModal';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { userTypVar } from 'src/apollo/cache';
import { useLoginTo, useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import ClassAndMemberMenuGroup from 'src/components/common/ClassAndMemberMenuGroup';
import { userInfoVar } from 'src/apollo/cache';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';

const { Title, Text } = Typography;
const { confirm } = Modal;
const stateOptions = [
  { label: '재원', value: '1' },
  { label: '휴원', value: '3' },
];
const getRecentClassInfo = (classStudents) => {
  if (classStudents && classStudents instanceof Array) {
    const sortedClassStudents = classStudents.sort((a, b) => {
      if (moment(a.class.idate).isBefore(b.class.idate)) {
        return 1;
      } else {
        return -1;
      }
    });
    // console.log(sortedClassStudents[0]?.class);
    return sortedClassStudents[0]?.class;
  }
  return null;
};

function StudentsForSmartEclass() {
  const loginTo = useLoginTo(false);

  const [students, setStudents] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const [searchValue, setSeachValue] = useState('');
  const [checkRowList, setcheckRowList] = useState([]);
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const [localState, setLocalState] = UseHistoryState({
    userStat: '1',
    currentPage: '1',
  });
  const handlePageChange = (pageNumber) => {
    setLocalState((prev) => ({
      ...prev, currentPage: pageNumber
    }))
  };
  const pageSize = 12
  const {
    data,
    loading: getStudentsLoading,
    refetch,
  } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: { type: userTypVar().student, stat: localState.userStat },
  });

  const [deleteUser] = useMutation(mutations.deleteUser.DELETE_USER, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (data?.getUsers) {
      setStudents(
        data.getUsers
          .reduce((acc, cur, index) => {
            const classInfo = getRecentClassInfo(cur?.class_student);
            const class_name = classInfo?.name;
            const class_book = classInfo?.book?.book_sub[0]?.title || classInfo?.book_code;
            const class_idx = classInfo?.idx;

            const obj = {
              key: cur.idx,
              No: index + 1,
              name: cur.name,
              id: cur.id,
              phone: cur.phone,
              parent_phone: cur.parent_phone,
              email: cur.email,
              class_name,
              class_book,
              class_idx,
              loginTo,
            };

            return [...acc, obj];
          }, [])
          .filter((item) => {
            if (!selectedClassIdx) {
              return true;
            }

            return selectedClassIdx === item.class_idx;
          }),
      );
      //dependency에 loginTo 넣으면 안 됨.
    }
  }, [data, selectedClassIdx]);

  const handleSearch = (value) => {
    setSeachValue(value);
    const filterTable = students.filter((student) => {
      return Object.keys(student).some((field) => {
        console.log('flter-->', student[field]);
        return String(student[field]).toLowerCase().includes(value.toLowerCase());
      });
    });

    setFilterTable(filterTable);
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '학생과 관련된 데이터가 전부 삭제됩니다. 삭제된 데이터는 복구가 어렵습니다. 정말 삭제하시겠습니까?(임시 비활성화시 휴원기능을 이용하시기 바랍니다.)',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              user_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  const HandleExcelDownload = () => {
    if (!students || students.length === 0) {
      Modal.warning({
        title: '다운로드할 학생이 없습니다.',
        okText: '확인',
      });
      return;
    }
    /* original data */
    const filename = 'students.xlsx';
    const sheetName = 'students';

    const data = [];
    data.push(['No', '학생이름', 'ID', '반', '교재', '학생연락처', 'Email']);
    students.forEach((ele) => {
      data.push([ele.No, ele.name, ele.id, ele.class_name, ele.class_book, ele.phone, ele.email]);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);

    /* add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* write workbook */
    XLSX.writeFile(wb, filename);
  };

  return (
    <>
      <ClassAndMemberMenuGroup currentMenu="student" />
      <HeaderTitle level={4}>학생 관리</HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom-teacher"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                학생 List
                <span style={{ paddingLeft: '5px' }}>
                  <Button type="primary" shape="rect" icon={<DownloadOutlined />} size="default" onClick={HandleExcelDownload}></Button>
                </span>
              </Title>
              <div className="header-classroom-search">
              <span style={{ paddingRight: 10 }}>
                  <Radio.Group
                    options={stateOptions}
                    optionType="button"
                    value={localState.userStat}
                    buttonStyle="solid"
                    onChange={(e) => {
                      //setUserStat(e.target.value);
                      setLocalState((prev) => ({
                        ...prev,
                        userStat: e.target.value,
                      }));
                    }}
                  />
                </span>
                <ClassSelectBox
                  firstRowDisplayValue="선택"
                  onClassChange={onClassChange}
                  selectedClassIdx={selectedClassIdx}
                  campus_idx={userInfoVar()?.campus_idx}
                  style={{ textAlign: 'left', width: '120px' }}
                />
                <Input.Search
                  className="inputsearch-classroom"
                  placeholder="검색어를 입력해 주세요"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onSearch={handleSearch}
                  onChange={onSeachChange}
                  value={searchValue}
                />
                <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                  <RedoOutlined />
                </CustomButton>

                <CustomButton style={{ marginRight: 10 }} onClick={() => showModal('create')}>
                  <PlusOutlined style={{ color: '#52c419' }} />
                  <Text type={'success'}>등록</Text>
                </CustomButton>
                <CustomButton
                  danger
                  style={{
                    marginRight: 10,
                  }}
                  onClick={deleteConfirm}
                >
                  삭제
                </CustomButton>
              </div>
            </div>

            <CustomTable
              className="classroom-table-table"
              loading={getStudentsLoading}
              dataSource={filterTable === null ? students : filterTable}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: pageSize, current: localState.currentPage, onChange: handlePageChange }}
              size="small"
              color="#edf3fb"
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => {}, // click row
              //   };
              // }}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <StudentModal popupMode={popupMode} handleCancel={handleCancel} visible={visible} refetch={refetch} />}
    </>
  );
}

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
  },
  {
    title: '학생 이름',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text, record) => {
      return <Link to={`/students/${record.key}`}>{text} </Link>;
    },
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    sorter: (a, b) => a.id.localeCompare(b.id),
    render: (text, record) => {
      return <Link to={`/students/${record.key}`}>{text} </Link>;
    },
  },
  {
    title: '반',
    dataIndex: 'class_name',
    key: 'class_name',
    align: 'center',
  },
  {
    title: '교재',
    dataIndex: 'class_book',
    key: 'class_book',
    align: 'center',
  },
  // {
  //   title: '학생 연락처',
  //   dataIndex: 'phone',
  //   key: 'phone',
  //   align: 'center',
  // },
  {
    title: '학부모 연락처',
    dataIndex: 'parent_phone',
    key: 'parent_phone',
    align: 'center',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'center',
  },
  {
    title: '상세보기',
    key: 'action',
    align: 'center',
    width: '100px',
    render: (text, record) => (
      <Button>
        <Link to={`/students/${text.key}`}>View </Link>
      </Button>
    ),
  },
  {
    title: '로그인',
    dataIndex: 'login',
    key: 'login',
    align: 'center',
    width: '100px',
    render: (_, record) => {
      return (
        <CustomButton
          type="primary"
          size="small"
          onClick={() => {
            //loginTo(record.id, record.client, record.loginUser);
            record.loginTo(record.id);
          }}
        >
          로그인
        </CustomButton>
      );
    },
  },
];

export default StudentsForSmartEclass;
