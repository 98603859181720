import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { GET_SCRIBO_TOKEN } from 'src/operations/queries/getUser';

const TodoCategory = styled.div`
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  padding: 0;
`;

//현재 진행 중인 반의 책을 보여주는 걸로 하자 일단은..
const ScriboButton = (props) => {
  const [LoadToken, { data:scriboData, error:scriboError }] = useLazyQuery(GET_SCRIBO_TOKEN);
  const form = useRef();
  const token = useMemo(() => {
    return window.localStorage.getItem('culp_token');
  }, []);

  const getType = (user_type) => {
    switch (user_type) {
      case '0':
        return 0;
      case '1':
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    if (scriboError) {
      alert(scriboError.message);
    } else if (scriboData?.getScriboToken) {
      form.current.submit();
    }
  }, [scriboData, scriboError]);

  return (
    <>
      <form ref={form} action="https://www.literatu.com/sso/cloubot/launch" method="get" target="_blank">
        <input name="signature" type="hidden" value={scriboData?.getScriboToken?.signature} />
        <input name="token" type="hidden" value={token} />
        <input name="user_email" type="hidden" value={scriboData?.getScriboToken?.user_email} />
        <input name="user_id" type="hidden" value={scriboData?.getScriboToken?.user_id} />
        <input name="user_name" type="hidden" value={scriboData?.getScriboToken?.user_name} />
        <input name="user_phone" type="hidden" value={scriboData?.getScriboToken?.user_phone} />
        <input name="user_type" type="hidden" value={getType(scriboData?.getScriboToken?.user_type)} />
        <input name="level" type="hidden" value={parseInt(scriboData?.getScriboToken?.level)} />
        <input name="classes" type="hidden" value={scriboData?.getScriboToken?.classes ? JSON.stringify(scriboData?.getScriboToken?.classes) : null} />
        <input name="campus_id" type="hidden" value={scriboData?.getScriboToken?.campus_id || null} />
        <input name="campus_name" type="hidden" value={scriboData?.getScriboToken?.campus_name || null} />
      </form>
      {props?.type === 'link' ? (
        <Link
          to="#"
          onClick={LoadToken}
        >
          Scribo
        </Link>
      ) : (
        <TodoCategory style={{ backgroundColor: props?.backgroundColor }} onClick={LoadToken}>
          {props?.text || 'Scribo'}
        </TodoCategory>
      )}
    </>
  );
};

export default ScriboButton;
