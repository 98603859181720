import React, { useEffect, useMemo, useState, useRef } from 'react';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Col, Row, Typography, Input, Button, Select, Form, Modal } from 'antd';
import { ShoppingCartOutlined, LeftOutlined } from '@ant-design/icons';

import { HeaderTitle } from 'src/components/common/Styles';
//import titleimagetwo from '../../../src/images/reload.png';
//import zoomicon from '../../../src/images/zoom-table.png';
//import topiconone from 'src/images/xlsicon.png';
//import topicontwo from 'src/images/printer.png';
import { koreanNumberFormat } from 'src/utils/index';
import { PLACE_ORDER } from 'src/operations/queries/pay';
import { GET_USER } from 'src/operations/queries/getUser';
import PostcodeModal from 'src/components/Modal/PostcodeModal';
import { useModal } from 'src/utils/hooks';
import { v4 as uuidv4 } from 'uuid';
import { getHumanReadablePhoneNumber } from 'src/utils';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { bookCartVar } from 'src/apollo/cart';
import { isAfterSchool, isLucid } from 'src/utils';
import moment from 'moment';
import { CHECK_ADDRESS_PTURN_FOR_ORDER } from 'src/operations/queries/pay';

//const { Link } = Anchor;
const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const { Option } = Select;
const { Title } = Typography;

const BookOrderPay = () => {
  const companyName = useSelector(classStoreData);
  const DELIVERY_PRICE = companyName === 'englishtap' ? 2750 : 2600;
  const ediDate = moment(Date.now()).format('yyyyMMDDhhmmss');
  const {
    data: addressData,
    loading: addressLoading,
    refetch: addressRefetch,
  } = useQuery(CHECK_ADDRESS_PTURN_FOR_ORDER, {
    variables: { start: moment().subtract(3, 'months').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
    fetchPolicy: 'no-cache',
  });
  const lastAddress = useMemo(() => {
    if (addressData && addressData?.MyOrderListByDuration[0]?.order_payment?.length > 0) {
      const tmp = addressData.MyOrderListByDuration[0].order_payment[0];
      return {
        postcode: tmp.buyer_postcode,
        address: tmp.buyer_addr.slice(0, tmp.buyer_addr.indexOf(')') + 1),
        address2: tmp.buyer_addr.slice(tmp.buyer_addr.indexOf(')') + 1),
      };
    }
    return { postcode: '', address: '', address2: '' };
  }, [addressData]);

  const [orderIdx, setOrderIdx] = useState(0);
  const orderIdxRef = useRef();
  const history = useHistory();

  const { data: userInfo } = useQuery(GET_USER);
  const { visible: postVisible, showModal: showPostModal, handleCancel: handlePostCancel } = useModal();
  const [form] = Form.useForm();

  const [placeOrder, { data: dataPlaceOrder, loading }] = useLazyQuery(PLACE_ORDER);
  const bookData = useMemo(() => {
    const bookCartData = bookCartVar();
    const bookItems = [];
    if (isAfterSchool(companyName)) {
      bookCartData.forEach((bookCartItem) => {
        if (bookCartItem.studentBookCount > 0) {
          bookItems.push({
            book_title: `${bookCartItem.book_title} 학생용`,
            book_code: bookCartItem.book_code,
            count: bookCartItem.studentBookCount,
            unit_price: bookCartItem.unit_price,
            price: bookCartItem.price,
            days: bookCartItem.days,
            type: bookCartItem.type,
            shoppingMallProduct: bookCartItem.shoppingMallProduct,
          });
        }
        if (bookCartItem.teacherBookCount > 0) {
          bookItems.push({
            book_title: `${bookCartItem.book_title} 교사용`,
            book_code: bookCartItem.book_code,
            count: bookCartItem.teacherBookCount,
            unit_price: bookCartItem.unit_price,
            price: bookCartItem.price,
            days: bookCartItem.days,
            type: bookCartItem.type,
            shoppingMallProduct: bookCartItem.shoppingMallProduct,
          });
        }
      });
      return bookItems;
    } else {
      return bookCartData;
    }
  }, []);
  const [payMethod, setPayMethod] = useState();
  const [orderUniqueId, setOrderUniqueId] = useState(`oid-${uuidv4()}`);
  const PAY_METHODS = useMemo(() => {
    if (isAfterSchool(companyName) || isLucid(companyName)) {
      if (bookData.some((x) => x.type === 'package')) {
        setPayMethod('creditcard');
        return [{ name: '신용카드', value: 'creditcard' }];
      } else {
        setPayMethod('manual');
        return [{ name: '후불정산', value: 'manual' }];
      }
    } else if (companyName === 'englishtap') {
      setPayMethod('manual');
      return [
        { name: '계좌이체', value: 'manual' },
        { name: '신용카드', value: 'creditcard' },
      ];
    } else if (bookData.some((x) => x.shoppingMallProduct === true)) {
      setPayMethod('creditcard');
      return [{ name: '신용카드', value: 'creditcard' }];
    } else {
      setPayMethod('creditcard');
      return [
        { name: '신용카드', value: 'creditcard' },
        //{ name: '직접결제', value: 'manual' },//일단 빼자..
      ];
    }
  }, [companyName, bookData]);

  const productName = useMemo(() => {
    if (bookData) {
      let name = bookData[0].book_title;
      const itemCount = bookData.length - 1;
      if (itemCount > 0) {
        name = `${name} 외 ${itemCount}건`;
      }
      return name;
    }
    return '';
  }, [bookData]);

  const totalPrice = useMemo(() => {
    if (bookData) {
      const price = bookData.reduce((a, c) => a + c.price, 0);
      return price;
    }
  }, [bookData]);
  const engTapDiscount = useMemo(() => {
    if (companyName === 'englishtap') {
      const counts = { ET: 0, LTR: 0 };
      bookData.forEach((e) => {
        const code = e.book_code.split('-')[0];
        counts[code] += e.count;
      });
      const sets = Math.min(counts.ET, counts.LTR);
      return sets * 2000;
    } else return 0;
  }, [bookData, companyName]);
  const finalPrice = useMemo(() => {
    if (bookData.every((x) => x.shoppingMallProduct === true)) {
      return totalPrice - engTapDiscount;
    }
    return companyName === 'englishtap' && totalPrice >= 100000
      ? totalPrice - engTapDiscount
      : totalPrice - engTapDiscount + DELIVERY_PRICE;
  }, [totalPrice, bookData, engTapDiscount]);

  const initialState = useMemo(() => {
    return {
      item_list: bookData?.map((item) => {
        return {
          code: item?.book_code,
          name: item?.book_title,
          count: item?.count ? Number(item?.count) : 0,
          price: item?.price,
          days: item?.days,
        };
      }),
      address: userInfo?.getUserData?.campus?.address,
      address2: userInfo?.getUserData?.campus?.address2,
      name: userInfo?.getUserData?.name,
      phone: userInfo?.getUserData?.phone,
      post_code: userInfo?.getUserData?.campus?.postcode,
      comment: '',
      delivery_price: bookData.every((x) => x.shoppingMallProduct === true) ? 0 : DELIVERY_PRICE,
      ediDate: ediDate,
      amount: finalPrice,
      product_name: productName,
    };
  }, [bookData, finalPrice, userInfo, lastAddress]);

  const { address, address2, post_code, ...rest } = initialState;
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    setValues({ address: lastAddress.address, address2: lastAddress.address2, post_code: lastAddress.postcode, ...rest });
  }, [addressLoading]);
  // setValues({address:lastAddress.address,address2:lastAddress.address2,post_code:lastAddress.postcode,...rest})
  const handlePay = () => {
    const buyer_info_array = [];

    if (!values?.name) {
      Modal.info({ title: '수취인을 입력하세요.' });
      return;
    }
    buyer_info_array.push(values.name);

    if (!values?.phone) {
      Modal.info({ title: '휴대전화번호를 입력하세요.' });
      return;
    }
    buyer_info_array.push(values.phone);
    // if (!values?.post_code) {
    //   Modal.info({ title: '주소를 입력하세요.' });
    //   return;
    // }

    buyer_info_array.push(values.post_code);

    if (!values?.address2) {
      Modal.info({ title: '상세주소를 입력하세요.' });
      return;
    }

    buyer_info_array.push(values.address + `${values?.address2 ? ' ' + values?.address2 : ''}`);

    buyer_info_array.push(values?.comment);

    const item_list = initialState?.item_list;

    //이름|전화번호|우편번호|주소|배송시요청사항
    const buyer_info = buyer_info_array.join('|');

    // if (payMethod === 'manual') {
    //   alert('후불정산... 준비중입니다.');
    //   return;
    // }

    //후불정산도 같은 API로 처리한다. 결과값을 보고 다르게 처리함.

    placeOrder({
      variables: {
        pgcode: payMethod,
        order_idx: orderIdx,
        order_no: orderUniqueId,
        user_id: userInfoVar()?.idx?.toString(),
        product_name: productName,
        amount: finalPrice,
        delivery_price:
          bookData.every((x) => x.shoppingMallProduct === true) || (companyName === 'englishtap' && totalPrice >= 100000)
            ? 0
            : DELIVERY_PRICE,
        item_list,
        buyer_info,
        extra_data: JSON.stringify({
          ...values,
          returnUrl: window.location.origin + '/book/order-complete',
          cancelUrl: window.location.origin + '/book/order-cancelled',
        }),
      },
    });
  };

  useEffect(() => {
    const payResp = dataPlaceOrder?.placeOrder;
    if (payResp) {
      if (payResp.success) {
        orderIdxRef.current = payResp.info?.order_idx;
        if (payMethod === 'creditcard') {
          window.open(
            `/i-frame/${btoa(
              process.env.REACT_APP_BACKEND_HOST +
                `/${companyName === 'englishtap' ? 'inicis' : 'nicepay'}/payment/` +
                +payResp.info?.order_idx,
            )}`,
            '_self',
          );
        } else {
          //후불정산인 경우임. 팝업 안 띄우고 바로 이동함.
          history.replace('/book/order-complete');
        }
      }
    }
  }, [dataPlaceOrder]);

  function handleInputChange(targetName, val) {
    const copy = { ...values };
    copy[targetName] = val;
    setValues(copy);
  }

  const type = userInfoVar()?.type;
  if (isAfterSchool(companyName)) {
    if (type && ![1, 2, 3].includes(type)) {
      history.replace('/');
    }
  } else {
    if (type && type !== 2) {
      history.replace('/');
    }
  }

  return (
    <>
      <Col className="bookstore-book-order-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <Row gutter={[16, 16]}>
          <Col className="bookstore-book-order-wrapper-main-a" span={12} style={{ textAlign: 'left' }}>
            <HeaderTitle level={4}>Book Store(컨텐츠 구매)</HeaderTitle>
          </Col>
          <Col className="topheader-image" span={12} style={{ textAlign: 'right' }}>
            {/* <Anchor>
              <Link>
                <img src={topiconone} alt="" />
              </Link>
              <Link>
                <img src={topicontwo} alt="" />
              </Link>
            </Anchor> */}
          </Col>
        </Row>
        <MainBlock className="bookstore-book-order-mainblock">
          <Row gutter={[16, 16]}>
            <Col className="bookstore-sec-one" span={24} style={{ textAlign: 'left' }}>
              <Row gutter={[16, 16]} className="bookstore-header-top">
                <Col className="bookstore-heading" span={12} style={{ textAlign: 'left', margin: 0 }}>
                  <Title className="mainheader-left" level={5}>
                    <div>주문/결제</div>
                  </Title>
                </Col>
                <Col className="select-right" span={12} style={{ textAlign: 'right', margin: 0 }}></Col>
              </Row>
              <div className="data-list-table-dv">
                <Row className="data-list-table" gutter={[16, 16]}>
                  <Col span={24} className="tablebookstore-1s">
                    <table className="table-t-one-a">
                      <thead>
                        <tr>
                          <th style={{ width: '100px' }}>No.</th>
                          <th>주문내역</th>
                          <th>권수</th>
                          {!isAfterSchool(companyName) && !isLucid(companyName) && <th>합계</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {bookData &&
                          bookData.map((item, key) => (
                            <tr key={`book-list${key}`}>
                              <td style={{ textAlign: 'center' }}>{key + 1}</td>
                              <td style={{ textAlign: 'center' }}>{item?.book_title}</td>
                              <td style={{ textAlign: 'center' }}>{item?.count}</td>
                              {!isAfterSchool(companyName) && !isLucid(companyName) && (
                                <td style={{ textAlign: 'center' }}>{item?.price && koreanNumberFormat(item?.price)} 원</td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {!isAfterSchool(companyName) && !isLucid(companyName) && (
                      <table className="table-t-one-b">
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'center' }}>총 주문금액</th>
                            <th style={{ textAlign: 'center' }}>{companyName === 'englishtap' ? 'Set 할인' : '반품 CR'}</th>
                            <th style={{ textAlign: 'center' }}>배송료</th>
                            <th style={{ textAlign: 'center' }}>최종 결제금액</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="table-t-one-icon-a">{koreanNumberFormat(totalPrice)}원</td>
                            <td className="table-t-one-icon-b">
                              {koreanNumberFormat(companyName === 'englishtap' ? engTapDiscount : '0')}원
                            </td>
                            <td className="table-t-one-icon-c">
                              {bookData.every((x) => x.shoppingMallProduct === true) ||
                              (companyName === 'englishtap' && totalPrice >= 100000)
                                ? 0
                                : koreanNumberFormat(DELIVERY_PRICE)}
                              원
                            </td>
                            <td className="table-t-one-icon-d">{koreanNumberFormat(finalPrice)}원</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </Col>
                  <Col span={24} className="tablebookstore-2s">
                    <div className="heading-top-bbokorder">주문자정보</div>
                    <table className="table-t-two">
                      <tr>
                        <th style={{ width: '200px' }}>주문자</th>
                        <td>{userInfo?.getUserData?.name}</td>
                        <th style={{ width: '200px' }}>휴대전화</th>
                        <td>{getHumanReadablePhoneNumber(userInfo?.getUserData?.phone)}</td>
                      </tr>
                    </table>
                  </Col>
                  <Col span={24} className="tablebookstore-3s">
                    <div className="heading-top-bbokorder">배송 정보</div>
                    <table className="table-t-three">
                      <tr>
                        <th style={{ width: '200px' }}>수취인</th>
                        <td>
                          <Input
                            placeholder=""
                            maxLength={10}
                            onChange={(e) => {
                              handleInputChange('name', e.target.value);
                            }}
                            value={values?.name}
                          />
                        </td>
                        <th style={{ width: '200px' }}>휴대전화</th>
                        <td>
                          <Input
                            placeholder=""
                            maxLength={13}
                            onChange={(e) => {
                              handleInputChange('phone', e.target.value);
                            }}
                            value={values?.phone}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: '100px' }}>배송지</th>
                        <td colSpan="3" style={{ padding: '0px' }}>
                          <table>
                            <tr>
                              <td colspan="2">
                                <div className="bbokorder-input">
                                  <Input placeholder="" disabled value={values?.post_code} />
                                  <Button type="default" size="small" onClick={showPostModal}>
                                    우편번호
                                  </Button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="inputname-full">
                                <Input placeholder="" readOnly value={values?.address} />
                              </td>
                              <td className="inputname-full">
                                <Input
                                  placeholder="상세주소입력"
                                  maxLength={30}
                                  onChange={(e) => {
                                    handleInputChange('address2', e.target.value);
                                  }}
                                  value={values?.address2}
                                />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: '100px' }}>배송시 요청사항</th>
                        <td colSpan="3">
                          <Input
                            placeholder="100자 이내로 적어주세요"
                            maxLength={100}
                            onChange={(e) => {
                              handleInputChange('comment', e.target.value);
                            }}
                            value={values?.comment}
                          />
                        </td>
                      </tr>
                    </table>
                  </Col>
                  <Col span={24} className="tablebookstore-4s">
                    <div className="heading-top-bbokorder">결제방법</div>
                    <table className="table-t-four">
                      <tr>
                        <th style={{ width: '200px' }}>결제방법</th>
                        <td>
                          <Select name="PayMethod" defaultValue={payMethod} onChange={setPayMethod}>
                            {PAY_METHODS.map(({ name, value }) => (
                              <Option key={`pay-method-${value}`} value={value}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        {companyName === 'englishtap' && payMethod === 'manual' && (
                          <>
                            <th style={{ width: '200px' }}>입금계좌</th>
                            <td>(주)상일이엔피 우리은행 1005-002-984867</td>
                          </>
                        )}
                      </tr>
                    </table>
                    <div style={{ marginTop: 10, textAlign: 'right' }}>
                      <Button
                        icon={<LeftOutlined />}
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        뒤로가기
                      </Button>{' '}
                      <Button type="primary" icon={<ShoppingCartOutlined />} loading={loading} onClick={handlePay}>
                        {isAfterSchool(companyName) || isLucid(companyName) ? '주문하기' : '결제하기'}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </MainBlock>
      </Col>
      {postVisible && <PostcodeModal handleCancel={handlePostCancel} visible={postVisible} setValues={setValues} form={form} />}
    </>
  );
};

export default BookOrderPay;
