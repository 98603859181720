import React, { useState } from 'react';
import { Col, Row, Form,Radio, Select } from 'antd';
import styled from 'styled-components';

import Tickets from 'src/pages/Tickets';
import CustomButton from 'src/components/common/CustomButton';
import { HeaderTitle } from 'src/components/common/Styles';

const { Option } = Select;

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

function TicketForm() {
  const [optionValue, setOptionValue] = useState('');
  const [option2Value, setOption2Value] = useState('');
  const options = [
    { label: '체험학습', value: 'experience' },
    { label: '학습', value: 'learn' },
  ];

  const options2 = [
    { label: '1개', value: '1' },
    { label: '10개', value: '10' },
    { label: '50개', value: '50' },
    { label: '100개', value: '100' },
  ];

  function handleChange(e) {
    console.log('optionValue checked', e.target.value);
    setOptionValue(e.target.value);
  }

  function handle2Change(e) {
    console.log('option2Value checked', e.target.value);
    setOption2Value(e.target.value);
  }

  const selectOptions = [];
  const optionName = '교재';
  for (let i = 1; i < 36; i++) {
    selectOptions.push(<Option key={i.toString(36) + i}>{optionName + i}</Option>);
  }

  function handleChangeSelectOptions(value) {
    console.log(`Selected: ${value}`);
  }

  return (
    <Tickets>
      <Row>
        <Col span={24}>
          <MyInfoWrapper>
            <HeaderTitle level={5} style={{ paddingBottom: 10 }}>
              이용권 등록
            </HeaderTitle>
            <Form layout="horizontal">
              <Row gutter={[0, 32]}>
                <Col span={4}>
                  <label>이용권 종류</label>
                </Col>
                <Col span={20}>
                  <Radio.Group options={options} onChange={handleChange} value={optionValue} optionType="button" />
                </Col>

                <Col span={4}>
                  <label>교재</label>
                </Col>
                <Col span={20}>
                  <Select size={`default`} placeholder="선택해주세요" onChange={handleChangeSelectOptions} style={{ width: '50%' }}>
                    {selectOptions}
                  </Select>
                </Col>

                <Col span={4}>
                  <label>목록</label>
                </Col>
                <Col span={20}>
                  <Select size={`default`} placeholder="선택해주세요" onChange={handleChangeSelectOptions} style={{ width: '50%' }}>
                    {selectOptions}
                  </Select>
                </Col>

                <Col span={4}>
                  <label>발급 개수</label>
                </Col>
                <Col span={20}>
                  <Radio.Group options={options2} onChange={handle2Change} value={option2Value} optionType="button" />
                </Col>
              </Row>
              <hr />
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Form.Item>
                  <CustomButton
                    style={{
                      marginRight: 10,
                      background: '#C4C4C4',
                      color: '#555555',
                    }}
                    htmlType="submit"
                  >
                    생성하기
                  </CustomButton>
                </Form.Item>
              </div>
            </Form>
          </MyInfoWrapper>
        </Col>
      </Row>
    </Tickets>
  );
}

export default TicketForm;
