import React from 'react';

import DefaultLayout from 'src/layouts/DefaultLayout';
import DashBoard from 'src/pages/DashBoard';
import Campuses from 'src/pages/Campuses';
import BranchAdmin from 'src/pages/Order/BranchAdmin';
import BookOrder from 'src/pages/BookStore/BookOrder';
import BookOrderList from 'src/pages/BookStore/BookOrderList';
import BookOrderPay from 'src/pages/BookStore/BookOrderPay';
import BookOrderPayComplete from 'src/pages/BookStore/BookOrderPayComplete';
import BookReturn from 'src/pages/BookStore/BookReturn';
import BookReturnList from 'src/pages/BookStore/BookReturnList';
import BookReturnRequest from 'src/pages/BookStore/BookReturnRequest';
import OpenAiUsage from 'src/pages/OpenAiUsage';
import Contents from 'src/pages/Contents';
import SMSBranchStatic from 'src/pages/SMSBranchstatic';
const BranchManager = () => {
  return (
    <>
      <DefaultLayout exact path="/dashboard" component={DashBoard} />
      <DefaultLayout exact path="/campus" component={Campuses} />
      <DefaultLayout exact path="/smsstatic" component={SMSBranchStatic} />
      <DefaultLayout exact path="/order/branch-admin" component={BranchAdmin} />
      <DefaultLayout exact path="/book/order" component={BookOrder} />
      <DefaultLayout exact path="/book/order/list" component={BookOrderList} />
      <DefaultLayout exact path="/book/order-pay" component={BookOrderPay} />
      <DefaultLayout exact path="/book/order-complete" component={BookOrderPayComplete} />
      <DefaultLayout exact path="/book/return" component={BookReturn} />
      <DefaultLayout exact path="/book/return-request" component={BookReturnRequest} />
      <DefaultLayout exact path="/book/return/list" component={BookReturnList} />
      <DefaultLayout exact path="/openAiUsage" component={OpenAiUsage} />
      <DefaultLayout exact path="/contents" component={Contents} />

      <DefaultLayout exact path="/" component={DashBoard} />
    </>
  );
};

export default BranchManager;
