import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Typography, DatePicker, Button, Modal } from 'antd';
import { DownloadOutlined, PrinterTwoTone } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import CustomTable from 'src/components/common/CustomTable';
import { CLASS_STUDENT_LIST_FOR_REPORT_CARD } from 'src/operations/queries/getClass';
import SearchClassComp, { useSearchClass, useSeachClassDate, useSearchClassBooks } from 'src/components/ReportCard/SearchClassComp';
import ReportCardWriteModal from 'src/components/Modal/reportcard/ReportCardWriteModal';
import ReportCardWriteModalLucid from 'src/components/Modal/reportcard/ReportCardWriteModalLucid';
import ReportCardWriteModalAfterschool from 'src/components/Modal/reportcard/ReportCardWriteModalAfterschool';
import ReportCardViewModal from 'src/components/Modal/reportcard/ReportCardViewModal';
import ReportCardViewModalLucid from 'src/components/Modal/reportcard/ReportCardViewModalLucid';
import ReportCardViewModalAfterSchool from 'src/components/Modal/reportcard/ReportCardViewModalAfterSchool';
import ReportCardMultiViewModal from 'src/components/Modal/reportcard/ReportCardMultiViewModal';
import ReportCardMultiViewModalAfterSchool from 'src/components/Modal/reportcard/ReportCardMultiViewModalAfterSchool';
import moment from 'moment';
import { isPturn } from 'src/utils/index';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const getSelectedReportCards = (checked_list, data) => {
  if (checked_list && data) {
    return data.filter((item) => checked_list.includes(item.key) && item.report_card_item);
  }
  return [];
};

const ReportCardAdminListTab = () => {
  const companyName = useSelector(classStoreData);
  const [checkRowList, setcheckRowList] = useState([]);
  const [dateRange, setDateRange] = useState();

  const [visibleWrite, setVisibleWrite] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [visibleMultiView, setVisibleMultiView] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [studentIdx, setStudentIdx] = useState();
  const [reportCardItem, setReportCardItem] = useState();
  const [reportList, setReportList] = useState([]);
  const { selectedClassIdx, onChange: onClassChange } = useSearchClass();
  const { dates, onChange: onClassDateChange } = useSeachClassDate();
  const { classBooks, classBookCodes, onChange: onClassBooksChange } = useSearchClassBooks();

  const [getSelectClassStudents, { data: dataStudents, loading, refetch: refetchReport }] = useLazyQuery(
    CLASS_STUDENT_LIST_FOR_REPORT_CARD,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record?.report_card_item ? false : true,
        key: record.key,
      };
    },
  };

  const openReportCardEditer = useCallback(
    (user_idx, student_name, report_card_item) => {
      setStudentIdx(user_idx);
      setStudentName(student_name);
      setReportCardItem(report_card_item);
      if (!selectedClassIdx) {
        alert('반을 선택하세요.');
        return;
      }

      if (!dateRange) {
        alert('수업기간을 지정하세요.');
        return;
      }
      setVisibleWrite(true);
    },
    [selectedClassIdx, dateRange],
  );
  const openReportCardView = useCallback(
    (user_idx, student_name, report_card_item) => {
      setStudentIdx(user_idx);
      setStudentName(student_name);
      setReportCardItem(report_card_item);
      if (!selectedClassIdx) {
        alert('반을 선택하세요.');
        return;
      }

      if (!dateRange) {
        alert('수업기간을 지정하세요.');
        return;
      }
      setVisibleView(true);
    },
    [selectedClassIdx, dateRange],
  );

  useEffect(() => {
    getSelectClassStudents({ variables: { class_idx: selectedClassIdx, hasReportCard: true } });

    //체크박스 초기화 해야 함.
    setcheckRowList([]);
  }, [selectedClassIdx]);

  const dataSource = useMemo(() => {
    const students = dataStudents?.classStudentList;
    const reports = dataStudents?.culpReportCardList;

    if (students) {
      return students.map((item, key) => {
        let report_card_item = null;
        if (reports) {
          report_card_item = reports.find((ele) => ele.user_idx === item?.user?.idx);
        }
        return {
          No: key + 1,
          key: `student-row${key}`,
          student_name: item?.user?.name,
          user_idx: item?.user?.idx,
          report_card_item,
          openReportCardEditer,
          openReportCardView,
        };
      });
    }
    return [];
  }, [dataStudents, openReportCardEditer, openReportCardView]);

  useEffect(() => {
    if (dates && dates.length > 0) {
      setDateRange([moment(dates[0]), moment(dates[1])]);
    }
  }, [dates]);

  const handleMultiDownload = useCallback(() => {
    if (checkRowList.length !== 0) {
      const existReports = getSelectedReportCards(checkRowList, dataSource);
      if (existReports.length > 0) {
        setReportList(existReports.map((item) => ({ ...item.report_card_item, user_name: item.student_name })));
        setVisibleMultiView(true);
        setIsDownload(true);
        return;
      }
    }
    Modal.info({ title: `다운로드할 레포트를 선택하세요.` });
  }, [dataSource, checkRowList]);

  const handleDownload = (record) => {
    // console.log([`student-row${no}`], dataSource)
    // if (checkRowList.length !== 0) {
      const existReports = getSelectedReportCards([`student-row${parseInt(record.No) - 1}`], dataSource);
      if (existReports.length > 0) {
        setReportCardItem(record.report_card_item);
        setStudentIdx(record.user_idx);
        setStudentName(record.student_name);
      setReportList(existReports.map((item) => ({ ...item.report_card_item, user_name: item.student_name })));
      setVisibleMultiView(true);
      setIsDownload(true);
      return;
    }
    // }
    // Modal.info({ title: `다운로드할 레포트를 선택하세요.` });
  };

  const handleMultiPrint = useCallback(() => {
    if (checkRowList.length !== 0) {
      const existReports = getSelectedReportCards(checkRowList, dataSource);
      if (existReports.length > 0) {
        setReportList(existReports.map((item) => ({ ...item.report_card_item, user_name: item.student_name })));
        setVisibleMultiView(true);
        setIsPrint(true);
        return;
      }
    }
    Modal.info({ title: `프린트할 레포트를 선택하세요.` });
  }, [dataSource, checkRowList]);
  const aftercode = useMemo(() => {
    const tmp = [];
    for (let i = 0; i < classBooks.length; i++) {
      tmp.push(i);
    }
    return tmp;
  }, [classBooks]);
  // console.log(dataSource,'dataSource')
  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'student_name',
      title: '학생명',
      dataIndex: 'student_name',
      align: 'center',
    },
    {
      key: 'write',
      title: 'Report작성',
      dataIndex: 'write',
      align: 'center',
      render: (_, { openReportCardEditer, user_idx, student_name, report_card_item }) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              openReportCardEditer(user_idx, student_name, report_card_item);
            }}
          >
            성적표 작성하기
          </Button>
        );
      },
    },
    {
      key: 'view',
      title: 'Report보기',
      dataIndex: 'view',
      align: 'center',
      render: (_, { report_card_item, openReportCardView, user_idx, student_name }) => {
        if (!report_card_item) {
          return undefined;
        }
        return (
          <Button
            type="default"
            onClick={() => {
              openReportCardView(user_idx, student_name, report_card_item);
            }}
          >
            성적표 보기
          </Button>
        );
      },
    },
    {
      key: 'view',
      title: 'Download',
      dataIndex: 'view',
      align: 'center',
      render: (_, record) => {
        // console.log(record)
        const { report_card_item, openReportCardView, user_idx, student_name } = record;
        if (!report_card_item) {
          return undefined;
        }
        return (
          <Button
            icon={<DownloadOutlined />}
            type="default"
            onClick={() => {
              handleDownload(record);
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={9}>
          <Title level={5}>검색하기</Title>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ padding: '7px 0' }}>
        {['lucid'].includes(companyName) || isPturn(companyName) ? (
          <SearchClassComp
            onSearchClassChange={onClassChange}
            onSearchClassDateChange={onClassDateChange}
            hasBookDropBox={true}
            onSearchClassBookChange={onClassBooksChange}
            isAfterSchool={isPturn(companyName)}
          />
        ) : (
          <SearchClassComp onSearchClassChange={onClassChange} onSearchClassDateChange={onClassDateChange} />
        )}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <RangePicker value={dateRange} disabled style={{ marginBottom: 7 }} />
            <div>
              {/* <Button icon={<DownloadOutlined />} onClick={handleMultiDownload}>
                Download
              </Button>
              &nbsp; */}
              <Button icon={<PrinterTwoTone />} onClick={handleMultiPrint}>
                Print
              </Button>
            </div>
          </div>
          <CustomTable
            dataSource={dataSource}
            columns={columns}
            size="small"
            color="#edf3fb"
            //scroll={{ y: 'calc(100vh - 254px)' }}
            loading={loading}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        </Col>
      </Row>
      {companyName === 'lucid' ? (
        <ReportCardWriteModalLucid
          visible={visibleWrite}
          onCancel={() => {
            setVisibleWrite(false);
            setReportCardItem(undefined);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          refetchReport={refetchReport}
          reportCardItem={reportCardItem}
          classBookCodes={classBookCodes}
          classBooks={classBooks}
        />
      ) : isPturn(companyName) ? (
        <ReportCardWriteModalAfterschool
          visible={visibleWrite}
          onCancel={() => {
            setVisibleWrite(false);
            setReportCardItem(undefined);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          refetchReport={refetchReport}
          reportCardItem={reportCardItem}
          classBookCodes={aftercode}
          classBooks={classBooks}
        />
      ) : (
        <ReportCardWriteModal
          visible={visibleWrite}
          onCancel={() => {
            setVisibleWrite(false);
            setReportCardItem(undefined);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          refetchReport={refetchReport}
          reportCardItem={reportCardItem}
          classBookCodes={classBookCodes}
          classBooks={classBooks}
        />
      )}

      {companyName === 'lucid' ? (
        <ReportCardViewModalLucid
          visible={visibleView}
          onCancel={() => {
            setVisibleView(false);
            setReportCardItem(undefined);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          reportCardItem={reportCardItem}
        />
      ) : isPturn(companyName) ? (
        <ReportCardViewModalAfterSchool
          visible={visibleView}
          onCancel={() => {
            setVisibleView(false);
            setReportCardItem(undefined);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          reportCardItem={reportCardItem}
          classBookCodes={aftercode}
          classBooks={classBooks}
        />
      ) : (
        <ReportCardViewModal
          visible={visibleView}
          onCancel={() => {
            setVisibleView(false);
            setReportCardItem(undefined);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          reportCardItem={reportCardItem}
        />
      )}

      {isPturn(companyName) ? (
        <ReportCardMultiViewModalAfterSchool
          visible={visibleMultiView}
          onCancel={() => {
            setVisibleMultiView(false);
            setIsDownload(false);
            setIsPrint(false);
          }}
          user_idx={studentIdx}
          class_idx={selectedClassIdx}
          dateRange={dateRange}
          reportList={reportList}
          isDownload={isDownload}
          isPrint={isPrint}
          classBookCodes={aftercode}
          classBooks={classBooks}
          reportCardItem={reportCardItem}
        />
      ) : (
        <ReportCardMultiViewModal
          visible={visibleMultiView}
          onCancel={() => {
            setVisibleMultiView(false);
            setIsDownload(false);
            setIsPrint(false);
          }}
          class_idx={selectedClassIdx}
          dateRange={dateRange}
          reportList={reportList}
          isDownload={isDownload}
          isPrint={isPrint}
        />
      )}
    </>
  );
};

export default ReportCardAdminListTab;
