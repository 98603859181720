import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Col, Row, Typography, Space, DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import ClassSelectBox from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import { HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import * as queries from 'src/operations/queries';
import TalkingTutorMenuGroup from 'src/components/common/TalkingTutorMenuGroup';
import UseHistoryState from 'src/components/common/UseHistoryState';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const { Title } = Typography;
const ExternalStudyAssignResultTable = styled(CustomTable)`
  colgroup {
    display: table-column-group !important;
  }
`;

const sortByDate = (isDescend) => (a, b) => {
  if (moment(a.start).isSame(b.start, 'day')) {
    return 0;
  }
  const compared = isDescend ? moment(a.start).isAfter(b.start, 'day') : moment(a.start).isBefore(b.start, 'day');
  if (compared) {
    return -1;
  }
  return 1;
};

let columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  // {
  //   key: 'date',
  //   title: 'Created Date',
  //   dataIndex: 'date',
  //   align: 'center',
  //   width: 170,
  //   sorter: (a, b) => a.date && a.date.localeCompare(b.date),
  //   render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
  // },
  {
    key: 'start',
    title: 'Assigned Date',
    dataIndex: 'start',
    align: 'center',
    width: 170,
    sorter: sortByDate(false),
    render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
  },
  {
    key: 'className',
    title: 'Class',
    dataIndex: 'className',
    align: 'center',
    render: (text) => <span style={{ color: 'blue', fontWeight: 'bold' }}>{text}</span>,
  },
  {
    key: 'title',
    title: 'Topic',
    dataIndex: 'title',
    align: 'center',
  },
  // {
  //   key: 'prompt',
  //   title: 'Prompt',
  //   dataIndex: 'prompt',
  //   align: 'center',

  //   render: (text) => (
  //     <div
  //       className="content-wrapper"
  //     >
  //       {text}
  //     </div >
  //   ),
  // },
  {
    key: 'end',
    title: 'Due Date',
    dataIndex: 'end',
    align: 'center',
  },
  {
    key: 'complete',
    title: 'Complete',
    dataIndex: 'complete',
    align: 'center',
  },
  {
    key: 'no',
    title: 'Result',
    dataIndex: 'no',
    align: 'center',
    render: (_, record) => {
      const { idx } = record;
      return (
        <Link to={`/ai-talking-tutor/report/details-by-date/${idx}`}>
          <Button type="primary">Result</Button>
        </Link>
      );
    },
  },
];

const AssignTalkingTutorList = ({ user }) => {
  const [classes, setClasses] = useState([]);
  const { data: dataUser } = useQuery(GET_USER_INFO);
  // const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [localState, setLocalState] = UseHistoryState({
    pg: { current: 0, pageSize: 10 },
    selectedClassIdx: 0,
    searchClassIdx: 0,
    start_date: dayjs().subtract(3, 'M').format('YYYY-MM-DD'),
    end_date: dayjs().format('YYYY-MM-DD'),
  });
  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });
  dayjs.extend(customParseFormat);
  dayjs.extend(advancedFormat);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  const {
    data: fetchAITopicDateList,
    refetch,
    loading,
  } = useQuery(queries.aiTopic.AI_TOPIC_DATE_ASSIGNMENT_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      class_idx: localState.selectedClassIdx,
      type: 'ai-talking-tutor',
      page: localState.pg?.current,
      start_date: localState.start_date ? localState.start_date : undefined,
      end_date: localState.end_date ? localState.end_date : undefined,
    },
  });

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
  }, [classQuery]);
  const handleClass = (classIdx) => {
    setLocalState((prev) => {
      return {
        ...prev,
        selectedClassIdx: classIdx,
        searchClassIdx: classIdx,
      };
    });
  };
  useEffect(() => {
    const total = Math.ceil(fetchAITopicDateList?.aiTopicDateAssignmentList?.total / 10);
    if (localState?.pg.current + 1 > total) {
      setLocalState((prev) => {
        return {
          ...prev,
          pg: { current: 0, pageSize: 10 },
        };
      });
    }
  }, [fetchAITopicDateList]);
  const aiTopicGroupList = useMemo(() => {
    if (fetchAITopicDateList?.aiTopicDateAssignmentList) {
      return fetchAITopicDateList?.aiTopicDateAssignmentList?.ai_topic_date_assignments.reduce((acc, cur, index) => {
        const totalCnt = cur?.ai_topic_user_assignments?.length;

        let data = JSON.parse(cur?.data || '{}');
        let topicData = JSON.parse(cur?.ai_topic?.data || '{}');
        const completed = cur?.ai_topic_user_assignments?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && cur?.status > 0) {
            acc++;
          }
          return acc;
        }, 0);
        let prmpt = topicData?.book?.prompt?.question ?? topicData?.book?.prompt?.chat ?? topicData?.book?.prompt?.debate;

        const obj = {
          data,
          title: topicData?.book?.title || topicData?.book?.topic,
          prompt: prmpt,
          date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          start: moment(cur?.start_date).format('YYYY-MM-DD'),
          end: moment(cur?.start_date)
            .add(cur?.limit_time / 1440, 'days')
            .format('YYYY-MM-DD'),
          //testTime: cur?.test_time,
          className: cur?.ai_topic_group?.class?.name,
          complete: `${completed} / ${totalCnt}`,
          No: index + 1,
          key: cur.idx,
          class_idx: cur?.ai_topic_group?.class?.idx,
          done_count: completed,
          type: cur?.type,
          idx: cur?.idx,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchAITopicDateList, classes, localState?.selectedClassIdx]);
  const onChangeStartDate = (a, date) => {
    let clearCheck = a === null;
    if (clearCheck) {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: undefined,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: dayjs(date).format('YYYY-MM-DD'),
        };
      });
    }
  };
  const onChangeEndDate = (a, date) => {
    let clearCheck = a === null;
    if (clearCheck) {
      setLocalState((prev) => {
        return {
          ...prev,
          end_date: undefined,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          end_date: dayjs(date).format('YYYY-MM-DD'),
        };
      });
    }
  };
  return (
    <>
      <TalkingTutorMenuGroup currentMenu="date-list" />
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>
                AI Speaking Coach : <span style={{ color: '#00B0F0' }}>Assigned List & Results</span>
              </Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
              <DatePicker onChange={onChangeStartDate} value={dayjs(localState.start_date)} allowClear={false}/>
              ~
              <DatePicker onChange={onChangeEndDate} value={dayjs(localState.end_date)}  allowClear={false}/>
              &nbsp;
              <div className="toparea-main" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', gap: '10px' }}>
                <h1 style={{ color: '#1890ff' }}>Class : </h1>
                <Space>
                  <ClassSelectBox
                    onClassChange={handleClass}
                    selectedClassIdx={localState?.selectedClassIdx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px' }}
                  />
                </Space>
              </div>
              {/* </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <HalfWrapper className="books-result-halfwrapper">
                <ExternalStudyAssignResultTable
                  style={{ width: '98%' }}
                  dataSource={aiTopicGroupList}
                  columns={columns}
                  key={(r) => r.idx}
                  size="small"
                  color="#edf3fb"
                  scroll={{ y: 'calc(100vh - 254px)' }}
                  loading={loading}
                  pagination={{
                    showSizeChanger: false,
                    pageSize: 10,
                    current: localState.pg?.current + 1,
                    total: fetchAITopicDateList?.aiTopicDateAssignmentList?.total || 0,
                    position: ['bottomCenter'],
                  }}
                  onChange={(pagination) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        pg: { current: pagination.current - 1, pageSize: pagination.pageSize },
                      };
                    });
                  }}
                />
              </HalfWrapper>
            </Col>
          </Row>
          {/* </div> */}
        </MainBlock>
      </Col>
    </>
  );
};

export default AssignTalkingTutorList;
