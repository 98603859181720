import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${(props) => props.bg && props.bg};
  border: 1px solid #fff;
`;
const list = [
    {
        title: 'My Page',
        currentMenu: 'mypage',
        url: '/level-up',
    },
    { title: 'Assigned Result', currentMenu: 'assignresult', url: '/level-up/results/assignresult' },
    // {
    //   title: 'Assigned Result111',
    //   currentMenu: 'result',
    //   url: '/level-up/assignedresult',
    // },
    {
        title: 'Book Report Result',
        currentMenu: 'bookreport',
        url: '/level-up/results/bookreport',
    }
];
const LevelUpResultMenu = ({ currentMenu }) => {
    const history = useHistory();
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
            {list.map((e) => {
                return (
                    <>
                        <MenuButton
                            key={e.currentMenu}
                            bg={currentMenu === e.currentMenu && '#ed8c3d'}
                            onClick={() => {
                                e.url && history.push(e.url);
                            }}
                        >
                            {e.title}
                        </MenuButton>
                        &nbsp;
                    </>
                );
            })}
        </div>
    );
};

export default LevelUpResultMenu;
