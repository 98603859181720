import React from 'react';
import CustomTable from 'src/components/common/CustomTable';

const SmartEclassOrderListTableAll = ({
  loading,
  dataSource,
  checkRowList,
  setcheckRowList,
  pageSize = 12,
  total,
  page,
  columns,
  isRowSelection = true,
  onPageChange,
}) => {
  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };
  console.log('dataSource', dataSource);
  return (
    <>
      <CustomTable
        loading={loading}
        className="orderadmin-table-table"
        pagination={{ pageSize, total, current: page, position: ['bottomCenter'], onChange: onPageChange, showSizeChanger: false }}
        dataSource={dataSource}
        columns={columns}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
        rowSelection={isRowSelection ? rowSelection : false}
      />
    </>
  );
};

export default SmartEclassOrderListTableAll;
