import React from 'react';
import { Col, Row } from 'antd';

import MyPage from 'src/pages/MyPage';
import { HalfWrapper } from 'src/components/common/Styles';

function Counseling() {
  return (
    <MyPage>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <HalfWrapper>리스트</HalfWrapper>
        </Col>
        <Col span={12}>
          <HalfWrapper>상세</HalfWrapper>
        </Col>
      </Row>
    </MyPage>
  );
}

export default Counseling;
