import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Switch, Space, Input, Button, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { EXT_READER_LIST } from 'src/operations/queries/externalStudy';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import { ExportExcel } from 'src/utils/index';
import StarWordsMenuGroup from 'src/components/common/StarWordsMenuGroup';
import moment from 'moment';
import Select from 'rc-select';
const Wrapper = styled.div`
  width: 100%;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }

  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const LiteracyBtn = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
`;
const PortfolioBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  padding: 0 auto;
  width: 70px;
  font-weight: 600;
  font-size: 14px;
`;
const LiteracyBtn2 = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 auto;
  width: 70px;
  font-weight: 600;
  font-size: 14px;
`;
const BookReportBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 auto;
  width: 70px;
  font-weight: 600;
  font-size: 14px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
const ThumbNail = styled(Image)`
  height: 90%;
  margin: 10px;
  border: 1px solid #968489;
  max-width: 180px;
`;
const { Option } = Select;
export default function StarwordsIndividualResult() {
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  // const { student_idx, ym } = queryString.parse(window.location.search);
  const history = useHistory();
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);

  const [searchValue, setSearchValue] = useState('');
  const [selectedClass, setSelectedClass] = useState(-1);

  // const [pg, setPg] = useState({ current: 1, pageSize: 10 });
  const [hideColumns, setHideColumns] = useState(true);

  const companyName = useSelector(classStoreData);
  const [localState, setLocalState] = UseHistoryState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    state: 'ing',
    selectedClass: -1,
    pg: { current: 1, pageSize: 10 },
  });
  const filterResult = [];
  const resultList = [];
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month1 = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two-digit month
  // return `${year}-${month1}`;

  const user_idx = userInfoVar()?.idx || null;

  const { data, loading } = useQuery(EXT_READER_LIST, {
    variables: {
      // user_idx: student_idx ? parseInt(student_idx) : parseInt(user_idx),
      // user_idx:1000004,
      // ym: month.format('YYYY-MM'),
      start_date: localState.startDate,
      end_date: localState.endDate,
      ym: `${year}-${month1}`,
      type: 'starwords',
      status: 0,
      class_idx: localState.selectedClass?.idx ? localState.selectedClass?.idx : null,
    },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });

  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: data?.getUserData?.campus_idx,
    fetchPolicy: 'no-cache',
  });
  // useEffect(() => {
  //   if (classList?.classList?.length) {
  //     setSelectedClass(classList?.classList[0]);
  //   }
  // }, [classList]);
  const studyData = useMemo(() => {
    if (data?.getExtReaderList) {
      return data?.getExtReaderList
        .sort((a, b) => {
          if (moment(a.study_date).isSameOrAfter(b.study_date)) {
            return -1;
          }
          return 1;
        })
        .map((item, key) => {
          const {
            study_date,
            wpm,
            external_study_idx,
            last_study_timestamp,
            assigned_idx,
            idate,
            total_read_time,
            book_report,
            portfoilio,
            student_name,
            student_id,
            user_idx,
            attendance,
            class_name,
            class_idx,
          } = item;

          return {
            key: `starwords-list-${key}`,
            class_name,
            class_idx,
            wpm,
            idx: item?.idx,
            assigned_idx,
            // exam_total,
            // exam_correct,
            book_id: external_study_idx,
            last_study_timestamp: last_study_timestamp ? `${last_study_timestamp.slice(0, 10)} ${last_study_timestamp.slice(11, 19)}` : '-',
            selfStudy: assigned_idx === null,
            study_date,
            idate,
            total_read_time,
            book_report,
            portfoilio,
            student_name,
            user_idx,
            attendance,
            student_id,
          };
        });
    }
    return [];
  }, [data]);
  const books = useMemo(() => {
    if (studyData.length > 0) {
      return searchValue !== '' && searchValue !== undefined
        ? studyData?.filter((item) => item?.student_name?.toLowerCase().includes(searchValue?.toLowerCase()))
        : studyData;
    }
    return [];
  }, [studyData, searchValue]);
  useEffect(() => {
    studyData?.map((ele) => {
      if (resultList?.length == 0) {
        resultList.push(ele);
      }
      // else {
      //   if(ele?.user?.idx === )
      // }
    });
  }, []);

  const filterDataList = useMemo(() => {
    return books?.map((ele, i) => {
      const obj = {
        no: books.length - i,
        time: ele?.time === '-' || !ele?.time ? moment(ele?.last_study_timestamp).format('HH:mm:ss') : ele?.time,
        student: ele?.student_name,
        class_name: ele?.class_name,
        class_idx: ele?.class_idx,
        // study_date:moment(ele?.last_study_timestamp).format('YYYY-MM-DD'),
        last_cmp_date:
          ele?.last_study_timestamp !== '-'
            ? `${moment(ele?.last_study_timestamp).format('YYYY-MM-DD')} ${
                ele?.time === '-' || !ele?.time ? moment(ele?.last_study_timestamp).format('HH:mm:ss') : ele?.time
              }`
            : '-',
        portfolio: ele?.portfoilio,
        total_time_read: ele?.total_read_time,
        user_idx: ele?.user_idx,
        attendance: ele?.attendance,
        student_id: ele?.student_id,
      };
      return obj;
    });
  }, [books]);
  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: moment(dateString).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(dateString).endOf('month').format('YYYY-MM-DD'),
        // pageNumber: 0,
      };
    });
    // if (dateString) {
    //   setStartDate(dateString);
    // } else {
    //   setStartDate();
    // }
  };
  const handleEndDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        endDate: dateString,
        // pageNumber: 0,
      };
    });
    // if (dateString) {
    //   setEndDate(dateString);
    // } else {
    //   setEndDate();
    // }
  };
  const onSeachChange = (val) => {
    if (val.target.value) {
      setSearchValue(val.target.value);
    } else {
      setSearchValue('');
    }
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      align: 'center',
      render: (text) => {
        return <span style={{ color: 'blue' }}>{text}</span>;
      },
    },

    {
      title: 'Class Name',
      dataIndex: 'class_name',
      key: 'class_name',
      align: 'center',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      // sorter: (a, b) => a.last_study_date.localeCompare(b.last_study_date),
    },
    {
      title: 'Attendance',
      dataIndex: 'attendance',
      key: 'attendance',
      align: 'center',
    },
    {
      title: 'Total Time Read',
      dataIndex: 'total_time_read',
      key: 'total_time_read',
      align: 'center',
      // sorter: (a, b) => a.last_study_date.localeCompare(b.last_study_date),
    },
    {
      title: 'portfolio',
      dataIndex: 'portfolio',
      key: 'portfolio',
      align: 'center',
      sorter: (a, b) => a.portfolio - b.portfolio,
      render: (text, record) => {
        return (
          <PortfolioBtn
            onClick={() => {
              history.push(
                `/star-words/portfolio/?student_idx=${record.user_idx}&name=${record.student}&studentid=${
                  record.student_id
                }&month=${localState.endDate.slice(0, 7)}`,
              );
            }}
          >
            {text}
          </PortfolioBtn>
        );
      },
    },
    {
      title: 'Last Study Date',
      dataIndex: 'last_cmp_date',
      key: 'last_cmp_date',
      align: 'center',
    },
  ];

  const handleChange = (e) => {
    setLocalState((prev) => {
      return { ...prev, selectedClass: classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0] };
    });
  };
  function handleExportToExcel() {
    const fileName = `Individual Results`;
    const columnsForExcel = columns.filter((e) => e.title !== 'Literacy');
    ExportExcel(columnsForExcel, filterDataList, fileName);
  }
  return (
    <>
      <Wrapper>
      <RowFlex style={{ justifyContent: 'center' }}>
          <StarWordsMenuGroup currentMenu={'extreaders'} />
        </RowFlex>
        <MainWrapper>
          <Main className="cust-extra-reader">
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Individual Results</SubTitle>
                {/* <CloseButton
                  onClick={() => {
                    history.push('/level-up');
                  }}
                >
                  <CloseOutlined />
                </CloseButton> */}
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {companyName === 'gnb' && (
                  <Space style={{ marginRight: 10 }}>
                    <Switch
                      checkedChildren="Hide"
                      unCheckedChildren="Show"
                      onChange={(checked) => setHideColumns(checked)}
                      checked={hideColumns}
                    />
                  </Space>
                )}
                {localState.selectedClass?.idx >= 0 ? (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                ) : (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                )}
                &nbsp; &nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search Student"
                  allowClear
                  style={{ width: 250 }}
                  onChange={onSeachChange}
                  enterButton
                  // onChange={onSeachChange}
                  // value={searchValue}
                />
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  picker="month"
                  value={moment(localState.startDate)}
                  allowClear={false}
                  // defaultValue={[moment(startDate), moment(endDate)]}
                  // defaultValue={startDate}
                />
                {/* ~
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  value={moment(localState.endDate)}
                /> */}
                &nbsp;
                <Icon onClick={handleExportToExcel} url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              // pagination={{ pageSize: 10, position: ['bottomCenter'] }}
              loading={loading}
              dataSource={filterDataList}
              // dataSource={dummy}
              // dataSource={filteredData}
              // dataSource={levelReader?.length > 0 ? levelReader : []}
              pagination={{ ...localState.pg, showSizeChanger: true, position: ['bottomCenter'] }}
              onChange={(pagination) => {
                setLocalState((prev) => {
                  return { ...prev, pg: pagination };
                });
              }}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
