import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Form, Input, Button, Checkbox, Switch } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';
import { useForm } from 'src/utils/hooks';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const MenuItemEditModal = ({ visible, handleCancel, menuItem, fcInfo, selectedUserType }) => {
  const [form] = Form.useForm();
  const [showIcon, setShowIcon] = useState(menuItem.show_icon);
  const [isHidden, setIsHidden] = useState(menuItem.is_hidden);

  const { onChange, onSubmit, values } = useForm(formCallback, {
    item_name: menuItem.item_name,
    show_icon: menuItem.show_icon,
    icon_url: menuItem.icon_url,
    is_hidden: menuItem.is_hidden,
  });

  const [updateCompanyUserMenuItem, { loading }] = useMutation(mutations.company.UPDATE_COMPANY_USER_MENU_ITEM, {
    refetchQueries: [
      {
        query: queries.company.GET_COMPANY_USER_MENU_LIST,
        variables: {
          company_idx: fcInfo.idx,
          user_type: selectedUserType,
        },
      },
    ],

    onCompleted(data) {
      if (data) {
        openNotification('메뉴 항목이 업데이트되었습니다!');
        handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleShowIconChange = (e) => {
    setShowIcon(e.target.checked);
  };

  const handleIsHiddenChange = (e) => {
    setIsHidden(e);
  };

  function formCallback() {
    updateCompanyUserMenuItem({
      variables: {
        menu_item_info: {
          ...values,
          show_icon: showIcon,
          is_hidden: isHidden,
          idx: menuItem.idx,
        },
      },
    });
  }

  return (
    <>
      <Modal
        entered
        title={`메뉴 항목 수정`}
        visible={visible}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          initialValues={{
            ...menuItem,
          }}
        >
          <FormField hasFeedback={true} title="경로 항목">
            <FormItem>
              <Input placeholder={`${menuItem.route_item.name} (${menuItem.route_item.route})`} disabled />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="항목명">
            <FormItem name="item_name" rules={[{ required: true, message: '항목 이름을 입력하세요.' }]} hasFeedback>
              <Input name="item_name" onChange={onChange} maxLength={100} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="아이콘 표시">
            <FormItem name="show_icon" hasFeedback>
              <Checkbox name="show_icon" onChange={handleShowIconChange} checked={showIcon} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="아이콘 URL">
            <FormItem
              name="icon_url"
              rules={[
                { required: false },
                {
                  type: 'url',
                  message: '유효한 URL을 입력하세요',
                },
              ]}
              hasFeedback
            >
              <Input name="icon_url" onChange={onChange} maxLength={500} disabled={!showIcon} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="숨김">
            <FormItem name="is_hidden" valuePropName="checked" rules={[{ required: false }]} hasFeedback>
              <Switch name="is_hidden" onChange={handleIsHiddenChange} />
            </FormItem>
          </FormField>
        </Form>
      </Modal>
    </>
  );
};

MenuItemEditModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default MenuItemEditModal;
