import { gql } from '@apollo/client';

export const CREATE_AI_TOPIC = gql`
  mutation createAITopic($topic: String!, $folder_type: String, $class_idx: Int, $type: String, $data: String, $level_up_code: String) {
    createAITopic(
      topic: $topic
      folder_type: $folder_type
      class_idx: $class_idx
      type: $type
      data: $data
      level_up_code: $level_up_code
    ) {
      idx
    }
  }
`;

export const EDIT_AI_TOPIC = gql`
  mutation updateAITopic($idx: Int!, $topic: String!, $folder_type: String, $class_idx: Int, $data: String, $level_up_code: String) {
    updateAITopic(idx: $idx, topic: $topic, folder_type: $folder_type, class_idx: $class_idx, data: $data, level_up_code: $level_up_code) {
      idx
    }
  }
`;

export const AI_TOPIC_DELETE = gql`
  mutation deleteAITopic($idxs: [Int!]!) {
    deleteAITopic(idxs: $idxs)
  }
`;

export const ASSIGN_AI_TOPIC_TO_STUDENTS = gql`
  mutation assignAITopicToStudents(
    $class_idx: Int
    $start_time: [String!]!
    $limit_time: Int!
    $user_idx_list: [Int!]!
    $external_data: [JSONObject!]!
    $start_date: String!
    $end_date: String!
    $study_time: String!
    $rowidx: Int
    $type: String
    $data: String
    $title: String
  ) {
    assignAITopicToStudents(
      class_idx: $class_idx
      start_time: $start_time
      limit_time: $limit_time
      user_idx_list: $user_idx_list
      external_data: $external_data
      start_date: $start_date
      end_date: $end_date
      study_time: $study_time
      rowidx: $rowidx
      type: $type
      data: $data
      title: $title
    )
  }
`;

export const UPDATE_AI_TOPIC_USER_ASSIGNMENT = gql`
  mutation updateAITopicUserAssignment(
    $idx: Int!
    $start_time: String
    $end_time: String
    $answer: String
    $status: Int
    $recording_data: String
    $wpm: Int
    $score: Float
    $rubric_data: String
    $last_message:String
  ) {
    updateAITopicUserAssignment(
      idx: $idx
      start_time: $start_time
      end_time: $end_time
      answer: $answer
      status: $status
      recording_data: $recording_data
      wpm: $wpm
      score: $score
      rubric_data: $rubric_data
      last_message:$last_message
    ) {
      idx
      status
      answer
    }
  }
`;
export const UPDATE_SINGLE_AI_TOPIC_USER_ASSIGNMENT = gql`
  mutation updateSingleAITopicToStudents(
    $idx: Int!
    $start_date: String!
    $user_idx_list: [Int!]!
  ) {
    updateSingleAITopicToStudents(
      idx: $idx
      start_date: $start_date
      user_idx_list:$user_idx_list
    ) 
  }
`;

export const LEVELUP_UPDATE_AI_TOPIC_USER_ASSIGNMENT = gql`
  mutation levelUpUpdateAITopicUserAssignment(
    $idx: Int!
    $start_time: String
    $end_time: String
    $answer: String
    $status: Int
    $recording_data: String
    $wpm: Int
    $score: Float
    $rubric_data: String
  ) {
    levelUpUpdateAITopicUserAssignment(
      idx: $idx
      start_time: $start_time
      end_time: $end_time
      answer: $answer
      status: $status
      recording_data: $recording_data
      wpm: $wpm
      score: $score
      rubric_data: $rubric_data
    )
  }
`;
export const DELETE_AI_TOPIC_ASSIGNMENT_GROUP = gql`
  mutation deleteAITopicAssignmentGroup($idx: Int!) {
    deleteAITopicAssignmentGroup(idx: $idx)
  }
`;

export const DELETE_AI_TOPIC_USER_ASSIGNMENT = gql`
  mutation deleteAITopicUserAssignment($idxs: [Int!]!) {
    deleteAITopicUserAssignment(idxs: $idxs)
  }
`;

export const DELETE_AI_GROUP = gql`
  mutation deleteAITopicGroup($idx: Int!) {
    deleteAITopicGroup(idx: $idx)
  }
`;

export const SAVE_AI_TOPIC_ANSWERS = gql`
  mutation saveAITopicAnswers(
    $answers: [JSONObject!]!
    $answer_retakes: [JSONObject!]
    $start_time: String!
    $end_time: String!
    $score: Float!
    $assigned_test_user_idx: Int!
    $form_id: String
    $test_type: String
    $user_idx: Int
    $batch_type: String
  ) {
    saveAITopicAnswers(
      answers: $answers
      answer_retakes: $answer_retakes
      start_time: $start_time
      end_time: $end_time
      score: $score
      assigned_test_user_idx: $assigned_test_user_idx
      form_id: $form_id
      test_type: $test_type
      user_idx: $user_idx
      batch_type: $batch_type
    ) {
      idx
    }
  }
`;

export const UPDATE_AI_REQUEST_RESPONSE = gql`
  mutation updateAiRequestResponse($idx: Int!, $completion_text: String!, $data: String) {
    updateAiRequestResponse(idx: $idx, completion_text: $completion_text, data: $data)
  }
`;
