import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CrossWordsImageBox from '../CrossWordsImageBox';
import CrossWordsSpellingBox from '../CrossWordsSpellingBox';
import PageNum from '../PageNum';
import PopUp from '../PopUp';
import _ from 'lodash';
import { useStudyCreate } from '../../../utils/pm/api';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
//const MAX_SELECT_COUNT = 5;
const rightSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/dingdong.mp3';
const CrossWordsStage = ({ unitInfo, stages, bookInfo, steps, setSteps }) => {
  const {
    customData: { problem, imageBaseUrl },
  } = stages;

  const [showPopUp, setShowPopUp] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [crossWords, setCrossWords] = useState([]);
  const [activeArr, setActiveArr] = useState([]);
  const [answerWordArr, setAnswerWordArr] = useState([]);
  const [dingdongSound, setDingdongSound] = useState(undefined);
  const history = useHistory();
  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  const correctWords = useMemo(() => {
    const stepIndex = steps < problem.length ? steps : problem.length - 1;
    return problem[stepIndex].words.map((item) => item.name);
  }, [problem, steps]);
  const correctWordsXY = useMemo(() => {
    return steps < problem.length ? problem[steps].words.map((item) => item.xy) : [];
  }, [problem, steps]);

  const MAX_SELECT_COUNT = useMemo(() => {
    const stepIndex = steps < problem.length ? steps : problem.length - 1;
    return problem[stepIndex].words.reduce((acc, cur) => {
      return Math.max(acc, cur.name.length);
    }, 0);
  }, [problem, steps]);

  const checkAnswer = () => {
    let ret = undefined;
    correctWordsXY.forEach((item) => {
      const findWord = item.every((el) => {
        for (let i = 0; i < activeArr.length; i++) {
          if (JSON.stringify(el) === JSON.stringify(activeArr[i])) {
            return true;
          }
        }
        return false;
      });
      if (findWord) {
        setDingdongSound(rightSound);
        ret = item;
        return;
      }
    });
    return ret;
  };
  const handleDingdongSoundEnd = () => {
    setDingdongSound(undefined);
  };

  const isActive = (x, y) => {
    const find = _.findIndex(activeArr, (el) => {
      return JSON.stringify(el) === JSON.stringify([x, y]);
    });
    return find !== -1;
  };

  const handleFinishStep = () => {
    //다음 문제로 넘어가거나 스테이지 종료
    setSteps((prev) => prev + 1);
  };

  const handleCheck = (x, y, active) => {
    if (active) {
      setActiveArr([...activeArr, [x, y]]);
    } else {
      const copy = _.cloneDeep(activeArr);
      const find = _.findIndex(copy, (el) => {
        return JSON.stringify(el) === JSON.stringify([x, y]);
      });
      copy.splice(find, 1);
      setActiveArr(copy);
    }
  };

  const handleGoodJobSoundEnd = () => {
    console.log('handleGoodJobSoundEnd');
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };

  useEffect(() => {
    const check = checkAnswer();
    if (!_.isNil(check)) {
      const copyCrossWords = _.cloneDeep(crossWords);
      check.forEach((item) => {
        //x, y가 반대임.. 음..
        copyCrossWords[item[1]][item[0]].isAnswer = true;
      });
      const answerWord = check.reduce((acc, cur) => {
        return acc + copyCrossWords[cur[1]][cur[0]].alphabet;
      }, '');
      setAnswerWordArr([...answerWordArr, answerWord]);
      setCrossWords(copyCrossWords);
      setActiveArr([]);
    }

    if (activeArr.length === MAX_SELECT_COUNT) {
      setActiveArr([]);
    }
    // eslint-disable-next-line
  }, [activeArr]);

  useEffect(() => {
    if (steps > problem.length - 1) {
      //스테이지 완료
      setPopUpType('correct');
      setShowPopUp(true);
      return;
    }
    const coords = problem[steps].coords;
    setCrossWords(
      coords.map((item) => {
        return item.map((item2) => ({ alphabet: item2, isAnswer: false }));
      }),
    );
  }, [problem, steps]);

  return (
    <>
      <PageNum
        num={steps < problem.length ? steps + 1 : problem.length}
        total={problem.length}
        style={{ top: 20, position: 'absolute', left: '50%', fontSize: '1.3rem', fontWeight: 'bold' }}
      />
      <S.BorderColumnBox style={{ padding: '1.2rem', height: 'calc(100% - 70px)' }} id="lv3_review4_content" className="main_content">
        <ImgWordWrap className="img_wrod_wrap">
          <CrossWordsImageBox imageBaseUrl={imageBaseUrl} words={correctWords} answerWordArr={answerWordArr} onFinish={handleFinishStep} />
          <WordBox className="word_box">
            {crossWords &&
              crossWords.map((arr, key) => (
                <>
                  {arr.map((item, key2) => (
                    <CrossWordsSpellingBox
                      key={`spelling-box-${key2}-${key}`}
                      item={item}
                      x={key2}
                      y={key}
                      onClicked={handleCheck}
                      clickedLength={activeArr.length}
                      active={isActive(key2, key)}
                      maxClickLength={MAX_SELECT_COUNT}
                    />
                  ))}
                </>
              ))}
          </WordBox>
        </ImgWordWrap>
      </S.BorderColumnBox>
      {showPopUp ? <PopUp popUpType={popUpType} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      <SoundEffectPM url={!_.isNil(dingdongSound) ? dingdongSound : undefined} onEnd={handleDingdongSoundEnd} />
    </>
  );
};

export default CrossWordsStage;
const ImgWordWrap = styled(S.RowFlex)`
  justify-content: space-between;
  flex: 1 1;
  margin-bottom: 1.6rem;
`;

const WordBox = styled.ul`
  width: 47%;
  display: grid;
  grid-template: repeat(8, 1fr) / repeat(7, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #e6e6e6;
`;
