import React from 'react';
import { Space, Spin } from 'antd';

const LoadingLayer = ({ style = {} }) => {
  return (
    <Space
      size="middle"
      style={{
        ...style,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Spin size="large" />
    </Space>
  );
};

export default LoadingLayer;
