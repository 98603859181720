import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import SmsTxtHistory from './SmsTxtHistory';
import SmsTxtHistoryHeader from './SmsTxtHistoryHeader';
import { MY_SENT_SMS_GROUP_HISTORY_LIST } from 'src/operations/queries/message';
import { MSG_TYPE_TEXT_LIST } from 'src/constants/common';

const SmsTxtHistoryContainer = ({ smsRemainedPoint }) => {
  const [selectedYm, setSelectedYm] = useState(moment());
  const { data, loading, refetch } = useQuery(MY_SENT_SMS_GROUP_HISTORY_LIST, {
    variables: { ym: selectedYm.format('YYYY-MM') },
    fetchPolicy: 'no-cache',
  });
  const smsHistoryData = useMemo(() => {
    const list = data?.mySentSmsGroupHistoryList;
    if (list) {
      return list.map((item, key) => {
        const date = moment(item.idate).format('YYYY-MM-DD');
        const time = moment(item.idate).format('a hh:mm');

        const total = item?.sms_log?.length || 0;
        const success = item?.sms_log?.filter((item) => item?.error_msg === '')?.length || 0;
        console.log('item?.sms_log', item?.sms_log);
        return {
          key: `sent-list-${item.idx}`,
          no: list.length - key,
          date,
          time,
          sent_num: total,
          kind: MSG_TYPE_TEXT_LIST[item?.msg_type] || '',
          show_txt: item?.sms_log[0]?.msg || '',
          received: item?.sms_log,
          received_group: item?.class?.name || '',
          send_check: `${success}/${total}`,
        };
      });
    }
    return [];
  }, [data]);
  const totalSent = useMemo(() => {
    if (smsHistoryData) {
      return smsHistoryData.reduce((acc, cur) => {
        return acc + Number(cur.sent_num);
      }, 0);
    }
    return 0;
  }, [smsHistoryData]);
  useEffect(() => {
    refetch();
  }, [smsRemainedPoint]);
  function onChange(date) {
    setSelectedYm(date);
  }
  return (
    <>
      <SmsTxtHistoryHeader selectedYm={selectedYm} remainedPoint={smsRemainedPoint} totalSent={totalSent} onChange={onChange} />
      <SmsTxtHistory smsHistoryData={smsHistoryData} loading={loading} />
    </>
  );
};

export default SmsTxtHistoryContainer;
