import React, { useMemo } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { userInfoVar } from 'src/apollo/cache';
import { isPturn } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const HomeworkResultMenuGroup = ({ currentMenu }) => {
  const companyName = useSelector(classStoreData);
  const hasElib = useMemo(() => {
    //나중에 바꿔야 한다. 일단 임시로...
    if (userInfoVar()?.campus?.company?.name === 'creo') {
      return false;
    }
    return true;
  }, []);

  const history = useHistory();
  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}
    >
      <MenuButton
        type={`${currentMenu === 'date' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learning');
        }}
      >
        날짜별 숙제 관리
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'class' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learning-class');
        }}
      >
        반별 숙제 관리
      </MenuButton>
      {/* ebook 교재를 사용하는지 체크 */}
      {/* {hasElib && !isPturn(companyName)&& (
        <>
          &nbsp;
          <MenuButton
            type={`${currentMenu === 'elibrary' ? 'primary' : 'default'} `}
            onClick={() => {
              history.push('/learning-elibrary');
            }}
          >
            전자도서관 학습관리
          </MenuButton>
        </>
      )} */}
    </div>
  );
};

export default HomeworkResultMenuGroup;
