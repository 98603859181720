import React, { useState, useEffect } from 'react';
import { Col, Typography, Checkbox, Select, Row, List, Pagination, Input } from 'antd';
// import { StarOutlined, VideoCameraOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import VimeoVideo from 'src/components/common/VimeoVideo';
import moment from 'moment';
import { VIDEOS_LIST } from 'src/operations/queries/getVideos';
import { useQuery } from '@apollo/client';

const { Title } = Typography;
const { Search } = Input;

const MainBlock = styled.div`
  background: #fff;
  padding: 10px;
  margin-top: 10px;
  max-height: calc(100vh - 70px);
  border-radius: 10px;
`;

const ListBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 260px);
  overflow-y: scroll;
`;

const ShadowBlock = styled.div`
  /* box-shadow: rgb(221, 221, 221) 2px 1px 5px 5px; */
`;

const PageBlock = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
`;

const HeadBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  background: #edf3fbc4;
`;

const ListPage = (props) => {
  const [otherList, setOtherList] = useState([]);
  const [checkedList, setCheckedList] = useState(new Array(otherList ? otherList.length : 0).fill(false));
  const [selectAll, setSelectAll] = useState(false);

  const list2 = useQuery(VIDEOS_LIST, { variables: { is_mine: false, is_public: false, type: 2 } });

  useEffect(() => {
    if (list2?.data?.videosList) {
      setOtherList(list2?.data?.videosList);
      setCheckedList(new Array(list2?.data?.videosList.length).fill(false));
    }
  }, [list2]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(otherList.length).fill(true));
      setSelectAll(true);
    } else {
      setSelectAll(false);
      setCheckedList(new Array(otherList.length).fill(false));
    }
  }

  const ListHeader = ({ text, isShowAll }) => (
    <HeadBlock>
      <Title level={5}>{text}</Title>
      <div style={{ paddingRight: '20px' }}>
        Select All &nbsp;<Checkbox checked={selectAll} onChange={onCheckAllChange}></Checkbox>
      </div>
    </HeadBlock>
  );

  return (
    <>
      <Col className="zoomvideo-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="zoomvideo-mailblock">
          <Row gutter={[24, 8]} className="zoomvideo-topheader">
            <Col className="zoomvideo-topheader-title" span={10} xs={6} md={10} style={{ textAlign: 'left' }}>
              <Title level={4}>Zoom Video</Title>
            </Col>
            <Col
              className="zoomvideo-topheader-selectfield"
              span={14}
              xs={18}
              md={14}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Select value={props.selectedItem.idx} style={{ width: 200, marginRight: 20 }} onChange={props.onSelectedValueChange}>
                {props.optionsList.map((item) => (
                  <Select.Option key={item.idx} value={item.idx}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <Search placeholder="Input search text" style={{ width: 250, paddingTop: 0, paddingBottom: 0, height: 32 }} />
            </Col>
          </Row>
          <Row className="zoomvideo-listing" gutter={[24, 8]}>
            <Col className="zoomvideo-listing-col" span={24} style={{ textAlign: 'left' }}>
              <ShadowBlock className="zoomvideo-shadwblock">
                <ListHeader className="zoomvideo-listheader" text={props.selectedItem.name} isShowAll={false} />
                <ListBlock className="zoomvideo-listing-listblock">
                  <List
                    className="zoomvideo-listing-list"
                    pagination={false}
                    itemLayout="vertical"
                    size="large"
                    dataSource={otherList ? JSON.parse(JSON.stringify(otherList)).reverse() : []}
                    renderItem={(item, i) => (
                      <List.Item
                        key={item.title}
                        style={{ padding: 10 }}
                        extra={
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Checkbox style={{ marginRight: 10 }} checked={checkedList[i]} onChange={(e) => onChange(e, i)}></Checkbox>
                          </div>
                        }
                      >
                        <ListItemMeta item={item} />
                      </List.Item>
                    )}
                  />
                </ListBlock>
                {/* <PageBlock style={{ justifyContent: 'center' }}>
                  <Pagination size="small" total={50} />
                </PageBlock> */}
              </ShadowBlock>
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default ListPage;

const ListItemMeta = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <List.Item.Meta
      style={{ marginBottom: 5 }}
      avatar={
        <>
          <span style={{ marginRight: 10 }}>{item.idx}</span>
          <VimeoVideo item={item} width={120} open={isOpen} handleCloseClick={() => setIsOpen(false)} style={{ cursor: 'pointer' }} />
        </>
      }
      title={
        <>
          <a href={item.href} onClick={() => setIsOpen(!isOpen)}>{`${item.title} at ${moment(item.idate).format('YYYY-MM-DD HH:mm')}`}</a>
        </>
      }
      description={
        <>
          <div>{item.description}</div>
          <div>#key #words</div>
        </>
      }
    />
  );
};
