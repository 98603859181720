import React, { useState } from 'react';
import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { Typography } from 'antd';
import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import AssignOfflineEdit from 'src/components/Offline/MainPage/AssignOfflineEdit';
/*import SubPage from 'src/components/Offline/SubPage';*/
import { useHistory } from 'react-router-dom';
import CustomButton from 'src/components/common/CustomButton';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
//캠퍼스 원장용 대시보드 메인 페이지
const { Title, Text } = Typography;
const CampusPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  const history = useHistory();

  return (
    <>
      <Row justify="space-between" className="buttonlisting-dashboard">
        <HeaderTitle level={4}>Assign Edit Lesson Plan(Syllabus)</HeaderTitle>
      </Row>

      <Row gutter={[24, 16]}><AssignOfflineEdit /></Row>
    </>
  );
};

export default CampusPage;
