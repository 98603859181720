import React, { useState, useMemo, useEffect } from 'react';
// import { Switch, Route, Link } from 'react-router-dom';
import { Button, Spin } from 'antd';
import styled from 'styled-components';
import { GET_ORDER_ITEM_LIST_META_REPORT } from 'src/operations/queries/getLevelTest';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { getMetaExamId, getLixileScoreObject, getCefr } from 'src/utils';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import axios from 'axios';

const InnerWrap = styled.div`
  background-color: #fff;
  width: calc(95rem - 15rem);
  height: calc(50rem - 3rem);
  padding: 2rem 1rem;
  margin: 0rem auto;
  border-radius: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  & {
    .meta-header .typeLogo {
      width: 50%;
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100vw - 25rem);
    height: calc(100vh - 30rem);
    padding: 2rem 1rem;
    margin: 0rem auto;

    & {
      h1.main-tit {
        font-size: 5rem;
      }

      .fixed_btm_btn {
        bottom: 0;
        width: 400px;
        padding: 4rem 1rem;
        border-radius: 20rem;
      }

      .meta-con {
        .btn {
          a {
            position: relative;
            top: -2rem;
          }
        }
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
    & {
      h1.main-tit {
        font-size: 5rem;
        margin-top: -2rem;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16/10) {
    width: 75%;
    height: 70%;
  }

  @media screen and (max-width: 1400px) {
    width: calc(75rem - 15rem);
    height: calc(40rem - 3rem);
  }

  @media (max-width: 1280px) {
    width: calc(75rem - 10rem);
    height: calc(45rem - 10rem);
  }

  @media screen and (max-width: 1100px) {
    width: calc(55rem - 10rem);
    height: 80%;

    & {
      .finished-con.type-reading-con {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: calc(40rem - 10rem);
    & {
      .btn.fixed_btm_btn {
        bottom: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(45rem - 10rem);
    height: calc(35rem - 10rem);
  }

  @media screen and (max-width: 580px) {
    width: calc(35rem - 10rem);
    height: calc(40rem - 10rem);
  }

  @media screen and (max-width: 425px) and (orientation: portrait) {
    width: calc(30rem - 10rem);
    height: calc(35rem - 10rem);
  }

  /* 16/10 */
  @media screen and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
  }

  @media screen and (max-width: 1368px) {
    & {
      .fixed_btm_btn {
        bottom: 0;
      }
    }
  }

  @media screen and (max-width: 950px) and (orientation: landscape) {
    & {
      .meta-header {
        &.onlyTxt-header {
          h1 {
            &.main-tit {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }

  /********* 이하 비정상 **********/

  @media (max-width: 1200px) and (max-height: 800px) {
    margin: 0 auto;
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    .meta-header.onlyTxt-header {
      top: 3rem;
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 1200px;
    max-height: 650px;
    margin: 0 auto;

    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: -4rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .finished-con {
        margin-top: 0rem;
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 4rem;
      }
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16/9) {
    & {
      .inner-header {
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 10rem;
      }
    }
  }

  @media (min-width: 1440px) and (aspect-ratio: 16 / 10) {
    & {
      .fixed_btm_btn {
        bottom: 2rem;
      }
    }
  }

  @media (max-width: 1280px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    margin: 0 auto;

    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: -3rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 4rem;
      }
    }
  }

  @media (max-width: 1024px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    margin: 0 auto;

    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 1rem;
        padding: 0;
      }
    }
  }

  @media (max-width: 960px) and (aspect-ratio: 16/9) {
    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: 1rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .finished-con {
        margin-top: 1rem;
      }

      .onlyTxt-header {
        h1.main-tit {
          margin-top: 1rem;
        }
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 0.5rem;
      }
    }
  }
`;

const MetaFinished = () => {
  const [resultData, setResultData] = useState(null);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const order_id = params.get('id') ? Number(params.get('id')) : null;
  const history = useHistory();
  const client = useApolloClient();
  const location = useLocation();
  const [userObj, setUserObj] = useState({});
  const [lexileScore, setlexileScore] = useState(0);
  const [batchType, setBatchType] = useState('');
  const [lastDate, setlastDate] = useState('');
  const [testIdxList, settestIdxList] = useState([]);
  const [levels, setLevels] = useState({});
  const [reportData, setReportData] = useState({});
  const [allDone, setAllDone] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  const isPreTest = useMemo(() => {
    if (batchType.startsWith('PRE')) {
      return true;
    }
    return false;
  }, [batchType]);

  if (!location?.state?.idx_list && localStorage.getItem('meta_idx_list')) {
    location.state = { idx_list: JSON.parse(localStorage.getItem('meta_idx_list')) };
  }
  if (order_id) {
    location.state = { idx_list: [order_id] };
  }
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 ? true : false;
  const { loading, data } = useQuery(GET_ORDER_ITEM_LIST_META_REPORT, { variables: { idx_array: location?.state?.idx_list } });

  const alertUser = (event) => {
    event.preventDefault();
    event.returnValue = 'Data will be lost if you leave the page, are you sure?';
  };

  useEffect(() => {
    if (loadingReport) {
      window.addEventListener('beforeunload', alertUser);
    } else {
      window.removeEventListener('beforeunload', alertUser);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [loadingReport]);

  useEffect(() => {
    let levels = {};
    if (data) {
      for (let i = 0; i < data?.orderItemListByIdx?.length; i++) {
        if (data?.orderItemListByIdx[i].order_leveltest) {
          for (let j = 0; j < data?.orderItemListByIdx[i]?.order_leveltest?.length; j++) {
            const item = data?.orderItemListByIdx[i]?.order_leveltest[j];
            let done = false;
            if (item?.assigned_tests?.assigned_test_users) {
              if (item?.assigned_tests?.assigned_test_users[0].status !== 1) {
                done = true;
              }
            }
            if (
              Object.keys(userObj).length === 0 &&
              item?.assigned_tests?.assigned_test_users?.length > 0 &&
              item?.assigned_tests?.assigned_test_users[0]?.user
            ) {
              const newUserObj = item?.assigned_tests?.assigned_test_users[0]?.user;
              setUserObj({
                id: getMetaExamId(newUserObj.birthday, newUserObj?.parent_phone),
                korean_name: newUserObj.english_name,
                name: newUserObj.name,
                grade: item?.order?.school_info,
                user_idx: newUserObj.idx,
              });
            }
            if (item?.assigned_tests?.tests?.test_type) {
              levels[item?.assigned_tests?.tests?.test_type] = {
                title: `${item?.assigned_tests?.tests?.title}`,
                assigned_tests_idx: item?.assigned_tests?.idx,
                done,
                batch_type: `${item?.assigned_tests?.tests?.batch_type}`,
                score: item?.assigned_tests?.assigned_test_users[0].score,
                total: `${item?.assigned_tests?.tests?.test_answers?.length}`,
                assigned_test_user_idx: item?.assigned_tests?.assigned_test_users[0].idx,
              };

              if (item?.assigned_tests?.tests?.test_type == 'reading' && item?.assigned_tests?.assigned_test_users[0].extra_data) {
                let ability = JSON.parse(item?.assigned_tests?.assigned_test_users[0].extra_data)?.ability;
                setBatchType(item?.assigned_tests?.tests?.batch_type);
                setlexileScore(Math.round(ability / 5) * 5);
              }
              if (!testIdxList.includes(item?.assigned_tests?.tests?.idx)) {
                settestIdxList((prevState) => [...prevState, item?.assigned_tests?.tests?.idx]);
              }
            }
            if (
              item?.assigned_tests?.assigned_test_users[0]?.end_time &&
              lastDate < item?.assigned_tests?.assigned_test_users[0]?.end_time
            ) {
              setlastDate(item?.assigned_tests?.assigned_test_users[0]?.end_time);
            }
          }
        }
      }
    }
    setLevels(levels);
  }, [data]);

  const lexileScoreObject = useMemo(() => {
    if (batchType !== '') {
      return getLixileScoreObject(lexileScore, batchType);
    }
    return null;
  }, [batchType, lexileScore]);

  useEffect(() => {
    if (batchType !== '') {
      const cefr = getCefr(lexileScore);
      let dev = 0;
      let mx = Number(lexileScoreObject?.mx);
      if (levels && Object.keys(levels).length != 0) {
        dev += Number(levels['vocabulary']?.score) - Number(lexileScoreObject?.mean_values?.vocabulary);
        dev += Number(levels['listening']?.score) - Number(lexileScoreObject?.mean_values?.listening);
        if (levels['grammar']) dev += Number(levels['grammar']?.score) - Number(lexileScoreObject?.mean_values?.grammar);
      }
      let mxDev = dev;

      if (isPreTest) {
        if (dev <= -5 && mx > 0) {
          mx--;
          mxDev = mxDev + 5;
        }
        if (dev >= 5) {
          mx++;
          mxDev = mxDev - 5;

          if (mx < 5 && dev >= 10) {
            mx++;
            mxDev = mxDev - 5;
          }
        }
      } else {
        if (dev <= -6) {
          mx--;
          mxDev = mxDev + 6;
        }
        if (dev >= 6) {
          mx++;
          mxDev = mxDev - 6;

          // if (mx < 6 && dev >= 12) {
          //   mx++;
          //   mxDev = mxDev - 6;
          // }
        }
      }
      setResultData({
        cefr: cefr.cefr,
        agl:
          dev >= 6
            ? isNaN(lexileScoreObject?.agl)
              ? lexileScoreObject?.agl
              : parseFloat(lexileScoreObject?.agl) + 0.5
            : lexileScoreObject?.agl,
        grl: lexileScoreObject?.grl,
        dev: mxDev,
        mx,
      });
    }
  }, [lexileScoreObject, batchType, levels]);

  const getReport = async (order_id) => {
    setLoadingReport(true);
    const url = process.env.REACT_APP_BACKEND_HOST + '/meta/exam/generate-report';
    const auth = JSON.parse(storage.getItem('culp_token'));
    const headers = {
      'Content-Type': 'application/json',
      Authorization: auth.accessToken,
    };
    const reportData = await axios.post(
      url,
      {
        data: {
          order_id: order_id,
          mx_level: resultData?.mx.toString(),
          cefr: resultData?.cefr.toString(),
          agl: resultData?.agl.toString(),
          grl: resultData?.grl.toString(),
          dev: resultData?.dev.toString(),
        },
      },
      { headers: headers },
    );
    console.log(reportData?.data);
    setReportData(reportData);
    setLoadingReport(false);
  };

  useEffect(() => {
    if (data?.orderItemListByIdx[0]?.order_leveltest[0]?.order?.idx && resultData) {
      getReport(data?.orderItemListByIdx[0]?.order_leveltest[0].order.idx);
    }
  }, [data, resultData]);

  useEffect(() => {
    if (
      reportData?.data?.success &&
      Object.keys(levels).length != 0 &&
      levels['vocabulary'].done &&
      levels['reading'].done &&
      levels['listening'].done &&
      levels['grammar']?.done
    ) {
      setAllDone(true);
    }
  }, [levels, reportData]);

  if (!location?.state?.idx_list) {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/MetaExam/MetaLogin');
    return <Redirect to={`/MetaExam/MetaLogin`} />;
  }

  if (!location?.state?.idx_list) {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/meta-login');
    return <Redirect to={`/meta-login`} />;
  }
  const typeDisp = Object.keys(levels).length == 0 ? '' : 'Type-' + levels[Object.keys(levels)[0]].batch_type;

  return (
    <>
      <div className={isWEnglish ? 'meta-con meta-full-con w-english-bg' : 'meta-con meta-full-con'}>
        <InnerWrap>
          <div className="meta-header onlyTxt-header">
            <h1 className="main-tit" style={{ color: '#777' }}>
              {/*<img src="../../images/metalogin_logo.png" alt="metaexam-logo" className="typeLogo" />*/} {isWEnglish ? '' : 'MetaExam'}{' '}
              {typeDisp}
            </h1>
          </div>

          <div className="type-reading-con">
            <h1 style={{ color: '#1561a0' }}>Completed</h1>
            <h3>You have done a great job !</h3>
            {!allDone ? (
              <h3>You can start the next test by clicking next button.</h3>
            ) : (
              <h3>
                You have finished all the tests. <br /> Now you can close the window
              </h3>
            )}
          </div>
          <Button className="btn  fixed_btm_btn">
            {/* {allDone && <Link to="./MetaReport">Completed</Link>} */}
            {!allDone && !loadingReport && (
              <a
                onClick={() => {
                  window.location = '/MetaExam/MetaExamType';
                }}
              >
                Completed
              </a>
            )}
            {loadingReport && <Spin size="large" />}
          </Button>
        </InnerWrap>
        {!isWEnglish && (
          <div className="report-footer-con">
            <h5>
              Powered by {'     '}
              <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
              </span>
            </h5>
            <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default MetaFinished;
