import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const BookOrderAdminMenuGroup = ({ currentMenu }) => {
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 10, paddingBottom: 10 }}>
      <MenuButton
        type={`${currentMenu === 'order-admin' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/order/admin');
        }}
      >
        주문관리
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'return-admin' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/book-return/admin');
        }}
      >
        반품관리
      </MenuButton>
    </div>
  );
};

export default BookOrderAdminMenuGroup;
