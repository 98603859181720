import { gql } from '@apollo/client';

//실제로 삭제하는 게 아님
export const DELETE_ORDERS = gql`
  mutation updateOrderDeleteDate($idx_list: [Int!]!) {
    updateOrderDeleteDate(idx_list: $idx_list)
  }
`;

export const UPDATE_DELIVERY_STATUS = gql`
  mutation updateDeliveryStatus($order_payment_idx: Int!, $status: String!) {
    updateDeliveryStatus(order_payment_idx: $order_payment_idx, status: $status) {
      success
      message
    }
  }
`;

export const UPDATE_INVOICE_NUMBER = gql`
  mutation updateInvoiceNumber($order_payment_idx: Int!, $company_code: String!, $invoice_number: String!) {
    updateInvoiceNumber(order_payment_idx: $order_payment_idx, company_code: $company_code, invoice_number: $invoice_number) {
      success
      message
    }
  }
`;
