import React, { useState, useMemo } from 'react';
import { Col, Row, Typography, Space, DatePicker, Button } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from '@apollo/client';
import { HalfWrapper } from 'src/components/common/Styles';
import StudentHomeworkMenuGroup from 'src/components/common/StudentHomeworkMenuGroup';
import { ElibraryTable } from 'src/components/common/Styles';
import { EXTERNAL_USER_STUDY_HISTORY } from 'src/operations/queries/externalStudy';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import { userInfoVar } from 'src/apollo/cache';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title } = Typography;
const getStageGrade = (stageNo, stages) => {
  const found = stages.find((item) => item.stage_no === stageNo);
  if (found) {
    return found?.grade || '완료';
  }
  return null;
};

const ExternalStudyHistory = () => {
  const { student_idx, ym } = queryString.parse(window.location.search);
  const [month, setMonth] = useState(ym ? moment(ym) : moment());
  const history = useHistory();
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const user_idx = userInfoVar()?.idx;
  const { data, loading } = useQuery(EXTERNAL_USER_STUDY_HISTORY, {
    variables: { user_idx: student_idx ? parseInt(student_idx) : parseInt(user_idx), ym: month.format('YYYY-MM'), type: 'newsomatic' },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });
  const studyData = useMemo(() => {
    if (data?.getExternalStuiesList) {
      return data?.getExternalStuiesList
        .sort((a, b) => {
          if (moment(a.study_date).isSameOrAfter(b.study_date)) {
            return -1;
          }
          return 1;
        })
        .map((item, key) => {
          const recording_data = item.recording_data;
          const { study_date, article_data, exam_total, exam_correct, wpm, data, last_study_timestamp } = item;
          const dataparse = JSON.parse(data);
          const score = dataparse?.score_percent ?? null;
          const { lexile, issue_date, title, level, image } = JSON.parse(
            article_data || {
              lexile: '',
              issue_date: '',
              title: '',
              level: '',
              image: '',
            },
          );
          const date = moment(study_date).format('M월D일');
          return {
            key: `external-study-list-${key}`,
            date,
            lexile,
            issue_date,
            title,
            level,
            image,
            accuracy: recording_data ? `${Math.round((100 * exam_correct) / exam_total)}%` : '',
            wpm,
            recording_data,
            exam_total,
            exam_correct,
            score,
            quiz_score: score===null ?  '-':score + '/100' ,
            last_study_timestamp: last_study_timestamp ? `${last_study_timestamp.slice(0, 10)} ${last_study_timestamp.slice(11, 19)}` : '-',
          };
        });
    }
    return [];
  }, [data]);
  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    //setRecordingContent(info?.recording_data);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }
  const columns = [
    {
      title: 'Assigned date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '10%',
    },
    {
      title: 'Article Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'Lexile',
      dataIndex: 'lexile',
      key: 'lexile',
      align: 'center',
    },
    {
      title: 'Study Date',
      dataIndex: 'last_study_timestamp',
      key: 'last_study_timestamp',
      align: 'center',
    },
    {
      title: 'Quiz',
      dataIndex: 'quiz_score',
      key: 'quiz_score',
      align: 'center',
    },
    {
      title: 'Accuracy',
      dataIndex: 'accuracy',
      key: 'accuracy',
      align: 'center',
      render: (text, record) => {
        return text ? (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const userName = userInfoVar()?.name;
              openRecordingDataModal(record, userName);
            }}
          >
            <span style={{ color: '#289428' }}>{text}</span>
          </span>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: 'WPM',
      dataIndex: 'wpm',
      key: 'wpm',
      align: 'center',
      render: (text, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const userName = userInfoVar()?.name;
              openRecordingDataModal(record, userName);
            }}
          >
            <span style={{ color: '#289428' }}>{text}</span>
          </span>
        );
      },
    },
  ];

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  return (
    <>
      {!student_idx && <StudentHomeworkMenuGroup currentMenu="externalstudy" />}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                News-o-matic Learning result
              </Title>
              <Space direction="horizontal">
                <DatePicker
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={(date) => {
                    setMonth(date);
                  }}
                  picker="month"
                  value={month}
                />
                <Button onClick={() => history.goBack()}>
                  <ArrowLeftOutlined /> Back
                </Button>
              </Space>
            </div>

            <ElibraryTable
              loading={false}
              dataSource={studyData}
              columns={columns}
              pagination={{
                pageSize: 10,
                position: ['bottomCenter'],
              }}
              size="small"
              color="#edf3fb"
            />
          </HalfWrapper>
        </Col>
      </Row>
      <RecordingDataModal
        data={{ ...recordingData, currentUserName, className: '' }}
        isShow={isRecordingVisible}
        onPrint={null}
        componentRef={null}
        onOk={handleOkForRecordingData}
        hidePrint={true}
      />
    </>
  );
};

export default ExternalStudyHistory;
