import React from 'react';
import { Col, Row, Typography } from 'antd';
import { HalfWrapper } from 'src/components/common/Styles';
import { useHistory } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';

const { Title } = Typography;

//방과후에서 학생이 자신이 등록한 수업에 대한 전자책 보는 페이지
const MyBooks = () => {
  const history = useHistory();
  const companyName = useSelector(classStoreData);

  const type = userInfoVar()?.type;
  if (type !== 0 || !isAfterSchool(companyName)) {
    history.replace('/');
    return;
  }
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <HalfWrapper className="tblclass-list-height">
          <div
            className="header-classroom"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <Title className="header-classroom-title" level={5}>
              My e-book list
            </Title>
          </div>
        </HalfWrapper>
      </Col>
    </Row>
  );
};

export default MyBooks;
