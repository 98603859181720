import React from 'react';
import { Col, Row } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';
import { BookCard, BookCover, BookMeta, BookTitle, BOOK_COL_STYLE, THUMBNAIL_BASE_URL } from './CommonComp';

const LEVELS = [1, 2, 3, 4];
const VOLUMES = [1, 2, 3];
const URL = [["obsq",	"nmpq",	"zbqf"],	["dzmn",	"eyaq",	"csgi"],["zoru",	"oezf",	"foej"],	["jtsu",	"mbhi",	"scky"],]

const GrammarTap = () => {
  const { levelNo, onChangeLevelNo } = useLevelTab();
  return (
    <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }}>
      <LevelTab
        levelNo={levelNo}
        levels={LEVELS}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />
      {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv) => (
        <Row key={`row-${lv}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
          {VOLUMES.map((vol) => (
            <Col span={4} key={`col-${lv}-${vol}`} style={{ ...BOOK_COL_STYLE, borderRight: `${vol === 3 ? '1px solid #dedede' : ''}` }}>
              <BookTitle level={5}>{`Grammar Tap ${lv}-${vol}`}</BookTitle>
              <BookCard
                onClick={() => {
                  window.open(
                    `https://online.flipbuilder.com/sangbong91/${URL[lv-1][vol-1]}`,
                    '_blank',
                    'width=1400, height=900',
                  );
                }}
                cover={
                  <BookCover
                    style={{
                      backgroundImage: `url(${THUMBNAIL_BASE_URL}GT-${lv}-${vol}.jpg)`,
                    }}
                  />
                }
              >
                <BookMeta />
              </BookCard>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default GrammarTap;
