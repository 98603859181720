import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
const bgStyle = { backgroundColor: '#868e96' };

const KeyboardEachButton = ({ item, onKeyPress, imageWordWrite, isCaps }) => {
  const keyPressed = useKeyPress(item);
  useEffect(() => {
    if (keyPressed) {
      onKeyPress(item);
    }
    // eslint-disable-next-line
  }, [keyPressed]);
  return (
    <KeyboardButton imageWordWrite={imageWordWrite} style={keyPressed ? bgStyle : {}} onClick={() => onKeyPress(item)}>
      {isCaps && (
        <span style={{ textTransform: 'uppercase' }} className="upper">
          {item}
        </span>
      )}
      {!isCaps && (
        <span className="lower" style={{ position: 'relative' }}>
          {item}
        </span>
      )}
    </KeyboardButton>
  );
};
export default KeyboardEachButton;

export const BackspaceButton = ({ onKeyPress }) => {
  const keyPressed = useKeyPress('back');

  useEffect(() => {
    if (keyPressed) {
      onKeyPress();
    }
    // eslint-disable-next-line
  }, [keyPressed]);
  return (
    <KeyboardButton className="backspace" style={keyPressed ? bgStyle : {}} onClick={onKeyPress}>
      <img style={{ maxWidth: '60%' }} src="/images/pm/keyboard_backspace.svg" alt="지우기" />
    </KeyboardButton>
  );
};

export const EnterButton = ({ onKeyPress }) => {
  const keyPressed = useKeyPress('enter');
  useEffect(() => {
    if (keyPressed) {
      onKeyPress();
    }
    // eslint-disable-next-line
  }, [keyPressed]);
  return (
    <KeyboardButton className="enter" style={keyPressed ? bgStyle : {}} onClick={onKeyPress}>
      <img style={{ maxWidth: '40%' }} src="/images/pm/keyboard_enter.svg" alt="엔터" />
    </KeyboardButton>
  );
};

export const ShiftButton = ({ onKeyPress, isCaps }) => {
  const keyPressed = useKeyPress('shift');
  useEffect(() => {
    if (keyPressed) {
      onKeyPress();

      // console.log("shift");
    }
    // eslint-disable-next-line
  }, [keyPressed]);
  return (
    <KeyboardButton
      className="shift"
      style={{
        backgroundColor: isCaps ? '#868e96' : '#feca57',
      }}
      onClick={onKeyPress}
    >
      <img style={{ maxWidth: '50%' }} src="/images/pm/keyboard_shift.svg" alt="shift" />
    </KeyboardButton>
  );
};

export const SpaceButton = ({ onKeyPress }) => {
  const keyPressed = useKeyPress('space');
  useEffect(() => {
    if (keyPressed) {
      onKeyPress();
    }
    // eslint-disable-next-line
  }, [keyPressed]);
  return (
    <KeyboardButton style={keyPressed ? bgStyle : {}} className="space" onClick={onKeyPress}>
      <img style={{ maxWidth: '80%' }} src="/images/pm/keyboard_space.svg" alt="space" />
    </KeyboardButton>
  );
};

// reference(라고 쓰고, copy라고 읽는다.): https://usehooks.com/useKeyPress/
// Hook
function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler({ key, keyCode }) {
    if (key === targetKey) {
      setKeyPressed(true);
    } else if (targetKey === 'back') {
      if (keyCode === 8) {
        setKeyPressed(true);
      }
    } else if (targetKey === 'enter') {
      if (keyCode === 13) {
        setKeyPressed(true);
      }
    } else if (targetKey === 'space') {
      if (keyCode === 32) {
        setKeyPressed(true);
      }
    } else if (targetKey === 'shift' || targetKey === 'caps') {
      if (keyCode === 16) {
        setKeyPressed(true);
      }
    }
    //backspace, enter예외처리
  }

  // If released key is our target key then set to false
  const upHandler = ({ key, keyCode }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    } else if (targetKey === 'back') {
      if (keyCode === 8) {
        setKeyPressed(false);
      }
    } else if (targetKey === 'enter') {
      if (keyCode === 13) {
        setKeyPressed(false);
      }
    } else if (targetKey === 'space') {
      if (keyCode === 32) {
        setKeyPressed(false);
      }
    } else if (targetKey === 'shift' || targetKey === 'caps') {
      if (keyCode === 16) {
        setKeyPressed(false);
      }
    }
    //backspace, enter예외처리
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}
const KeyboardButton = styled.li`
  display: flex;
  align-items: center;
  height: 4vw;
  width: 4vw;
  border-radius: 50%;
  border: 2px solid #e7e7e7;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 10px;
  cursor: pointer;
  box-shadow: 0 7px 0 #7d84ca;
  color: #212121;
  background-color: #fff;
  font-weight: 900;
  font-size: 2.5em;

  &.space,
  &.enter {
    width: 8vw;
    border-radius: 20px;
    background-color: #8ed1f1;
    padding: 10px;
  }

  &.shift,
  &.backspace {
    background-color: #feca57;
  }

  @media (orientation: portrait) {
    font-size: 1.5em;
    height: 2vh;
    width: 2vh;
  }
  @media (max-height: 760px) {
    font-size: 2.2em;
    height: 3.5vw;
    width: 3.5vw;
  }
  @media (max-height: 500px) {
    font-size: 2em;
    height: 3vw;
    width: 3vw;
  }
`;
