import React, { useState, useMemo, useEffect } from 'react';
import { Col, Row, Select, Modal, Button, Input,Form } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import CreateMetaScheduleModel from 'src/components/Modal/CreateMetaScheduleModel';
import UpdateMetaScheduleModel from 'src/components/Modal/UpdateMetaScheduleModel';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { userInfoVar } from 'src/apollo/cache';
import { getYearMonthArray } from 'src/utils';
import { SCHEDULE_DATA } from 'src/operations/queries/getScheduleData';
import { DELETE_META_SCHEDULE } from 'src/operations/mutations/schedule';
import { SCHEDULE_DATA_ID_WISE } from 'src/operations/mutations/schedule';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';
import MetaExamMenuGroup from 'src/components/common/MetaExamMenuGroup';
import { UPDATE_ORDER_META_SCHEDULE } from 'src/operations/mutations/schedule';

const { Option } = Select;
const { confirm } = Modal;
const { Search } = Input;

const months = getYearMonthArray('2021-01');
const pageSize = 12;

const Schedule = () => {
  const [form] = Form.useForm();
  const [searchMonth, setSearchMonth] = useState(months[0]); //시험날짜 기준으로 필터링함
  const [visibleRegisterForm, setVisibleRegisterForm] = useState(false);
  const [visibleUpdateRegisterForm, setVisibleUpdateRegisterForm] = useState(false);
  const [displayUpdatePopup, setDisplayUpdatePopup] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState('On');
  // const [isChecked, setIsChecked] = useState(false);
  const [scheduleData, setScheduleData] = useState();
  const [isChecked, setIsChecked] = useState(userInfoVar()?.campus?.meta_schedule || 0);
  const [checkRowList, setcheckRowList] = useState([]);
  // const [form] = Form.useForm();
// 
  const initialState = {
    no_of_system: scheduleData?.no_of_system,
    end_time: scheduleData?.end_time,
    start_time: scheduleData?.start_time,
  };
  const [values, setValues] = useState(initialState);
  
  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };
  // const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = userInfoVar()?.campus?.company?.idx===1016 ? true : false;
  const { data: dataSchedule, loading, refetch } = useQuery(SCHEDULE_DATA, { variables: { campus_idx: userInfoVar()?.campus_idx } });
  //const { data: dataSchedule, loading, refetch } = useQuery(SCHEDULE_DATA, { variables: { campus_idx: dataUser?.getUserData?.campus_idx } });
  // const { data: scheduleDetail, refetch: refetch1 } = useQuery(SCHEDULE_DATA_ID_WISE, { variables: { schedule_idx: scheduleIdx } });
  const [scheduleDetail] = useMutation(SCHEDULE_DATA_ID_WISE, {
    onCompleted(data) {
      if (data) {
        setScheduleData(data.getScheduleDataIdWise[0]);
        refetch();
      }else{
        alert('오류가 발생했습니다.');
      }
    }
    });
  // const { data: dataSchedule, loading, refetch } = useQuery(SCHEDULE_DATA, { variables: { campus_idx: userInfoVar()?.campus_idx } });

  const [updateOrderMetaSchedule] = useMutation(UPDATE_ORDER_META_SCHEDULE, {
    onCompleted(data) {
      if (data) {
        refetch();
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];
    },
  });
  const [deleteMetaSchedule, { loading: loading1 }] = useMutation(DELETE_META_SCHEDULE, {
    onCompleted(data) {
      if (data) {
        // handleCancel();
        refetch();
        Modal.success({
          title: "Delete Schedule successfully",
        });
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];
      Modal.error({
        title: `${message}`,
      });
    },
    refetchQueries: [
      {
        query: SCHEDULE_DATA,
        //variables: dataUser?.getUserData?.campus_idx,
        variables: dataSchedule?.getUserData?.campus_idx,
      },
    ],
  });

  const metaSChedule = useMemo(() => {
    if (dataSchedule) {
      return dataSchedule.getMetaSchedule
        .map((item, key) => {
          return {
            key: `consult-list-${item.idx}`,
            no: key + 1,
            days: item?.days,
            start_time: item?.start_time,
            end_time: item?.end_time,
            no_of_system: item?.no_of_system,
            idx: item?.idx
          };
        })
        .sort((a, b) => {
          if (a.test_result === b.test_result) {
            return 0;
          }
          return a.test_result > b.test_result ? 1 : -1;
        });
    }
    return [];
  }, [dataSchedule]);
  const handleDeleteSchedule = (idx) => {

    confirm({
      title: '정말 삭제하시겠습니까?',
      okText: '예',
      okType: 'primary',
      cancelText: '아니오',
      onOk() {
        deleteMetaSchedule({
          variables: {
            // schedule_idx: parseInt(idx),
            schedule_idx: parseInt(idx)
          },
        });
      },
      onCancel() { },
    });
  }
  const handleEditSchedule = (idx) => {
    // return
    setDisplayUpdatePopup(true)
    // setVisibleUpdateRegisterForm(true);
    setVisibleRegisterForm(true)
    console.log("idx",idx)
    scheduleDetail({
      variables: {
        schedule_idx: parseInt(idx),
      },
    });
  }
  const columns = [
    {
      title: 'No',
      align: 'center',
      dataIndex: 'no',
      width: 40,
    },
    {
      title: 'Days',
      align: 'center',
      dataIndex: 'days',
      width: 40,
    },
    {
      title: 'Start Time',
      align: 'center',
      dataIndex: 'start_time',
      width: 40,
    },
    {
      title: 'End Time',
      align: 'center',
      dataIndex: 'end_time',
      width: 40,
    },
    {
      title: 'No of System',
      align: 'center',
      dataIndex: 'no_of_system',
      width: 40,
    },
    {
      title: '동작',
      align: 'center',
      dataIndex: 'idx',
      key: 'idx',
      render: (text, record) => {
        const { idx } = record;
        console.log("record", record)
        return (
          <>
            <CustomButton
              onClick={() => {
                handleEditSchedule(idx);
              }}
              size="small"
              icon={<EditOutlined />}
            />
            <CustomButton
              onClick={() => {
                handleDeleteSchedule(idx);
              }}
              size="small"
              icon={<DeleteOutlined />}
            />
          </>
        );
      },
    },
  ]
  const toggleChecked = () => {
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };
  useMemo(() => {
    updateOrderMetaSchedule({
      variables: {
        campus_idx: userInfoVar()?.campus_idx,
        meta_schedule: isChecked ? 1 : 0,
      },
    });
  }, [isChecked]);

  return (
    <>
    <MetaExamMenuGroup currentMenu="metaSchedule" />
      <HeaderTitle level={4}>{isWEnglish ? '': 'Meta '} Schedule</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="placement-test-halfwrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 10,
              }}
            >
              <div className="switch">
                <span>
                  <input type="checkbox" id="toggleInput" defaultChecked={userInfoVar()?.meta_schedule} checked={isChecked} />
                  <button className="slider" type="button" onClick={() => toggleChecked()}></button>
                </span>
              </div>
              <CustomButton
                style={{ marginLeft: 10 }}
                onClick={() => {
                  setVisibleRegisterForm(true);
                }}
              >
                등록/Schedule
              </CustomButton>
            </div>
            <div className="cust-schedule-table">
              <CustomTable
                dataSource={metaSChedule}
                columns={columns}
                pagination={{ pageSize, position: ['bottomCenter'] }}
                size="small"
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
              />
            </div>
          </HalfWrapper>
        </Col>
      </Row>
      {displayUpdatePopup ?
       <UpdateMetaScheduleModel
       visible={visibleRegisterForm}
       ym={searchMonth}
       schedule={isChecked}
       onCancel={() => {
         setVisibleRegisterForm(false);
       }}
       refetch={refetch}
       userInfo={scheduleData?scheduleData:""}
     />
    :  
    <CreateMetaScheduleModel
    visible={visibleRegisterForm}
    ym={searchMonth}
    schedule={isChecked}
    onCancel={() => {
      setVisibleRegisterForm(false);
    }}
    refetch={refetch}
  />
  }
    </>
  );
};

export default Schedule;
