import React, { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Typography, Space, Row, Modal, Button, Input, Table } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';
import { userInfoVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { jsPDF } from "jspdf";

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const DailyTestGroupResult = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [testData, setTestData] = useState({});
    const [testDetailList, setTestDetailList] = useState();
    const { selectedClassIdx, onClassChange, searchClassIdx } = useCampusClass({
        // key: '전체',
        key: 'Class',
        title: 'we_assigned_search',
    });
    const [checkRowList, setcheckRowList] = useState([]);
    
    const { data: fetchGroupResultList, refetch } = useQuery(queries.getTests.DAILY_TEST_GROUP_PARENT_RESULT_LIST, {
        fetchPolicy: 'no-cache',
        variables: {
            type: '',
            searchText: '',
            class_idx: selectedClassIdx,
            campus_idx:userInfoVar()?.campus_idx
        },
    });
    const [getTestDetailsList] = useMutation(mutations.createTests.TEST_DETAIL_LIST, {
        onCompleted(data) {
            setTestDetailList(data?.getTestDetailsList)
        },
    });
     
     const [assignedTestDelete] = useMutation(mutations.createTests.ASSIGNED_TEST_GROUP_DELETE, {
        onCompleted(data) {
            if (data) {
                refetch();
                openNotification('삭제 완료!');
            }
        },
    });

    const handleModel = (param) => {
        setTestData(param)
        setModalVisible(true)
        const idx = param?.paper_test.map((ele)=> ele.idx)
        if(idx?.length > 0){
            getTestDetailsList({ variables: { idxs: idx } });
        }
    }

    const dailyTestGroupResultList = useMemo(() => {
        if(fetchGroupResultList?.dailyTestGroupResultParentList) {
            return fetchGroupResultList?.dailyTestGroupResultParentList?.reduce((acc, cur, index) => {
                console.log("cur",cur);
                const obj = {
                    start_date:`${moment(cur?.begin_date).format('YYYY-MM-DD')}`,
                    end_date:`${moment(cur?.end_date).format('YYYY-MM-DD')}`,
                    total_group:cur?.count,
                    No: index + 1,
                    key: cur.idx,
                    idx:cur.idx,
                };

                return [...acc, obj];
            }, []);
        }
    }, [fetchGroupResultList])
    const columns = [
        {
            title: 'No',
            dataIndex: 'No',
            key: 'No',
            width: 50,
        },
        {
            title: 'Starting Date',
            dataIndex: 'start_date',
            key: 'start_date',
            width: 120,
            align: 'center',
        },
        {
            title: 'Ending Date',
            dataIndex: 'end_date',
            key: 'end_date',
            width: 120,
            align: 'center',
        },
        {
            title: 'Total Assigned Group',
            dataIndex: 'total_group',
            key: 'total_group',
            width: 120,
            align: 'center',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            width: 120,
            align: 'center',
            render: (text, record) => {
                return (
                    <Button>
                    <Link to={`/daily/test/result/${record?.idx}`}>View</Link>
                  </Button>
                );
            },
        },

    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setcheckRowList(selectedRows.map((data) => data.idx));
        },
    };

    const deleteConfirm = () => {
        console.log("checkRowList",checkRowList);
        if (checkRowList.length) {
            confirm({
                title: '정말 삭제하시겠습니까?',
                okText: '예',
                okType: 'primary',
                cancelText: '아니오',
                onOk() {
                    assignedTestDelete({ variables: { idxs: checkRowList } });
                },
                onCancel() { },
            });
        } else {
            infoConfirm();
        }
    };

    function infoConfirm() {
        Modal.warning({
            title: '삭제할 항목을 선택해 주세요.',
            okText: '확인',
        });
    }
    const generateData = ()=>{
        var result = [];
        testDetailList?.map((ele,indx)=>{
                result.push({
                    No: (indx + 1).toString(),
                    Question: ele?.question,
                    Answer:ele?.answer,
                    TestName:ele?.test_name
                })
        })
        return result;
      };

    const createHeaders = (keys) => {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
          result.push({
                    id: keys[i],
                    name: keys[i],
                    prompt: keys[i],
                    width: 65,
                    align: "center",
                    padding: 0
          });
        }
        return result;
      }
    const handleDownload = async (file) => {
        try {
              const headers = createHeaders([
                "No",
                "Question",
                "Answer",
                "TestName",
              ]);
        
              const res = generateData();
              const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
              doc.table(1, 1, res, headers, { autoSize: true });
              doc.save("Answer.pdf")

            for (const fileUrl of file.paper_test) {
                const response = await fetch(fileUrl.pdf_id);
                const blob = await response.blob();

                const tempLink = document.createElement('a');
                tempLink.href = URL.createObjectURL(blob);
                tempLink.download = fileUrl.test_folder_name;

                tempLink.click();
                URL.revokeObjectURL(tempLink.href);
            }
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    }
    return (
        <>
            <HeaderTitle level={4}>Daily Test Result Page</HeaderTitle>
            <Row gutter={[16, 16]} className='cust-assigned-list'>
                <Col span={24}>
                    <HalfWrapper className="assigned-syllabus-halfwrapper">
                        <div className='cust-header-content'
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Title level={4} style={{marginBottom : '0'}}>Assigned List</Title>
                            <div className="assigned-syllabus-top-fields" style={{ display: 'inline-block', width: '50%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
                                <Search
                                    className="headitems-search"
                                    placeholder="Input search text"
                                    // defaultValue={searchValue.text}
                                    // onSearch={(val) =>
                                    //     setSearchValue((prev) => {
                                    //         return {
                                    //             ...prev,
                                    //             text: val,
                                    //         };
                                    //     })
                                    // }
                                    allowClear
                                    style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
                                />
                                <CustomButton danger onClick={deleteConfirm}>
                                    삭제
                                </CustomButton>
                                
                            </div>
                        </div>
                        <CustomTable
                            bordered
                            dataSource={dailyTestGroupResultList}
                            columns={columns}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            scroll={{ y: 'calc(100vh - 254px)' }}
                            pagination={{ pageSize: 10 }}
                            size="small"
                            color="#edf3fb"
                        />
                    </HalfWrapper>
                </Col>
                {modalVisible ?
                    <Modal
                        title="Paper Test Download"
                        centered
                        visible={modalVisible}
                        onCancel={() => { setModalVisible(false) }}
                        footer={[
                        ]}
                        className='paperTestPopup'
                    >
                        <table >
                            <tr>
                                <th colspan="6" ><span style={{ color: "#da6425" }}>{testData?.test_series_title}</span>
                                </th>
                            </tr>
                            <tr>
                                <td>Answer</td>
                                {testData?.paper_test?.map((ele) => {
                                    return <>
                                        <td>{ele?.test_folder_name}</td>
                                    </>
                                })}
                            </tr>
                            <tr>
                                <td colspan="6">
                                    <button className="download-pdf-btn" onClick={() => handleDownload(testData)} >Click to Download</button>
                                </td>
                            </tr>
                        </table>
                    </Modal> : ""}
            </Row>
        </>
    );
};

export default DailyTestGroupResult;
