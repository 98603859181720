import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Input } from 'antd';
import styled from 'styled-components';
import Settings from 'src/pages/Settings';
import { useQuery, useMutation } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { getCurrentToken } from 'src/utils';
import { useQueryProxy } from 'src/operations/proxy/user';
import { USER_ME } from 'src/operations/queries/getUser';
import { GET_BOOK_SERIES } from 'src/operations/queries/getBook';

const MyInfoWrapper = styled.div`
  padding: 24px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;
function BookInfo() {
  const [form] = Form.useForm();
  const [books, setBooks] = useState([]);
  const [bookdata, setBookData] = useState(false);
  const token = getCurrentToken();
  const { data: dataUser } = useQueryProxy(USER_ME, { variables: { token } });

  const { data: allBookSeries } = useQuery(GET_BOOK_SERIES, {
    variables: { search_text: '' },
  });

  const { data: fetchBooks, refetch } = useQuery(queries.company.GET_COMPANY_BOOK_DISPLAY_LIST, {
    skip: !dataUser,
    variables: {
      company_idx: dataUser && dataUser?.me?.campus?.fc_company_idx,
    },
  });

  useEffect(() => {
    refetch();
  }, [bookdata]);

  const [updateGoodsPrice] = useMutation(mutations.company.UPDATE_COMPANY_BOOK_DISPLAY_PRICE, {
    onCompleted(data) {
      if (data) {
        openNotification('수정 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const [deleteBook] = useMutation(mutations.company.DELETE_BOOK_INFO, {
    onCompleted(data) {
      if (data) {
        setBookData((prevVal) => !prevVal);
        openNotification('수정 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    const allBookSeriesMap = new Map();
    allBookSeries?.bookSeries?.forEach((element) => {
      allBookSeriesMap.set(element.code, element);
    });

    const bookSeriesFilterd = fetchBooks?.getCompanyBookDisplayList.filter((item) => {
      if (item.book.parent_code == null) {
        const bookSeriesOne = allBookSeriesMap.get(item.book.code);
        if (bookSeriesOne?.book_sub[0].shopping_mall_product === false) {
          return true;
        }
      }
      return false;
    });
    const bookSeries = bookSeriesFilterd?.reduce((acc, cur, index) => {
      const obj = {
        key: cur.idx,
        No: index + 1,
        code: cur.book.code,
        book_title: cur.book?.book_sub[0]?.title ? `${cur.book?.book_sub[0]?.title}(${cur.book.code})` : cur.book.code,
        levelName: cur.book.level_name,
        volumeName: cur.book.volume_name,
        bookList: cur.book_list,
        price: cur.price,
        prices: cur.prices,
        is_bundle: cur.is_bundle,
        is_use_credit: cur.is_use_credit,
      };

      return cur.book.parent_code ? acc : [...acc, obj];
    }, []);

    setBooks(
      bookSeries?.reduce((acc, cur) => {
        let children;

        if (cur.bookList.length) {
          children = {
            children: cur.bookList.reduce((insideAcc, insideCur) => {
              const selectData = fetchBooks?.getCompanyBookDisplayList.filter((item) => {
                return insideCur.code === item.book.code;
              })[0];

              if (selectData) {
                const insideObj = {
                  key: selectData.idx,
                  volume: insideCur.code,
                  price: selectData.price ? selectData.price : 0,
                  prices: selectData.prices,
                  parent: selectData.book.parent_code,
                  is_bundle: selectData.is_bundle,
                  is_use_credit: selectData.is_use_credit,
                };

                insideAcc = [...insideAcc, insideObj];
              }

              return insideAcc;
            }, []),
          };

          if (!children?.children?.length) delete children.children;
        }
        const obj = {
          key: cur.key,
          volume: cur.code,
          price: cur.price ? cur.price : 0,
          prices: cur.prices,
          parent: null,
          is_bundle: cur.is_bundle,
          is_use_credit: cur.is_use_credit,
          ...children,
        };

        return [...acc, obj];
      }, []),
    );
  }, [fetchBooks]);

  function onChange(record) {
    const price = parseInt(record.value);
    const selectRecordKey = Number(record.key);
    setBooks((prev) => {
      return prev.map((val) => {
        if (val.key === selectRecordKey) {
          if ((val.prices && val.prices.length) || val.price!==undefined) {
            if (record.index === -1) {
              return { ...val, ...{ price: price } };
            } else {
              let prices = val.prices.map((item, itemIndex) => {
                if (record.type === 'price') {
                  return itemIndex === record.index ? { ...item, ...{ price: price } } : item;
                } else if (record.type === 'days') {
                  return itemIndex === record.index ? { ...item, ...{ days: price } } : item;
                } else {
                  return item;
                }
              });
              return { ...val, ...{ prices: prices } };
            }
          } else {
            return val;
          }
        } else if (val.key !== selectRecordKey && val.hasOwnProperty('children')) {
          return {
            ...val,
            children: val.children.map((child) => {
              if (child.key === selectRecordKey) {
                if ((child.prices && child.prices.length) || child.price!==undefined) {
                  if (record.index === -1) {
                    return { ...child, ...{ price: price } };
                  } else {
                    let prices = child.prices.map((item, itemIndex) => {
                      if (record.type === 'price') {
                        return itemIndex === record.index ? { ...item, ...{ price: price } } : item;
                      } else if (record.type === 'days') {
                        return itemIndex === record.index ? { ...item, ...{ days: price } } : item;
                      } else {
                        return item;
                      }
                    });
                    return { ...child, ...{ prices: prices } };
                  }
                } else {
                  return child;
                }
              } else {
                return child;
              }
            }),
          };
        } else {
          return val;
        }
      });
    });
  }

  function handleFormSubmit() {
    updateGoodsPrice({
      variables: {
        fc_company_idx: dataUser?.me?.campus?.fc_company_idx,
        goods: books,
      },
      refetchQueries: [
        {
          query: queries.company.GET_COMPANY_BOOK_DISPLAY_LIST,
          variables: { company_idx: dataUser?.me?.campus?.fc_company_idx },
        },
      ],
    });
  }

  const columns = [
    {
      title: '교재명',
      dataIndex: 'volume',
      key: 'volume',
      align: 'center',
    },
    {
      title: '가격',
      dataIndex: 'prices',
      key: 'prices',

      render: (text, record) => {
          return record.is_use_credit ? (
            <>
              {record.prices.map((items, index) => {
                return (
                  <>
                    <label>일</label>
                    <Input
                      type="number"
                      defaultValue={items.days}
                      onChange={(e) => {
                        onChange({
                          value: e.target.value,
                          key: items.book_idx,
                          index: index,
                          type: 'days',
                        });
                      }}
                      style={{ width: '30%' }}
                      // addonAfter="원"
                    />
                    <label>가격</label>
                    <Input
                      type="number"
                      defaultValue={items.price}
                      onChange={(e) => {
                        onChange({
                          value: e.target.value,
                          key: items.book_idx,
                          index: index,
                          type: 'price',
                        });
                      }}
                      style={{ width: '30%' }}
                      addonAfter="원"
                    />
                    {record.prices.length - 1 > 0 && (
                      <>
                        <Button className="" onClick={() => removeItem(record, items, index, items?.children ? true : false)}>
                          -
                        </Button>
                      </>
                    )}
                    {record.prices.length - 1 === index && (
                      <>
                        <Button className="" onClick={() => addPrice(record)}>
                          +
                        </Button>
                      </>
                    )}
                    <br />
                  </>
                );
              })}
            </>
          ) : (
            <>
              <label>가격</label>
              <Input
                defaultValue={record.price}
                type="number"
                onChange={(e) => {
                  onChange({
                    value: e.target.value,
                    key: record.key,
                    index: -1,
                  });
                }}
                style={{ width: '30%' }}
                addonAfter="원"
              />
            </>
          );
      },
    },
  ];
  function addPrice(val) {
    let obj = [
      {
        book_idx: val.key,
        days: 0,
        idx: null,
        price: 0,
      },
    ];
    if (val.parent) {
      let index = books.findIndex((ele) => ele.volume === val.parent);
      let booksCopy = [...books];
      let childIndex = booksCopy[index].children.findIndex((childItem) => childItem.key === val.key);
      booksCopy[index].children[childIndex].prices = [...booksCopy[index].children[childIndex].prices, ...obj];
      setBooks(booksCopy);
    } else {
      let index = books.findIndex((ele) => ele.key === val.key);
      if (index >= 0) {
        let nw = [...books];

        nw[index].prices = [...nw[index].prices, ...obj];

        setBooks(nw);
      }
    }
  }
  function removeItem(record, val, index1, isChild) {
    if (!val.idx) {
      if (record.parent) {
        let index = books.findIndex((ele) => ele.volume === record.parent);
        let booksCopy = JSON.parse(JSON.stringify(books));
        let childIndex = booksCopy[index].children.findIndex((childItem) => childItem.key === record.key);
        booksCopy[index].children[childIndex].prices.splice(index1, 1);
        setBooks(booksCopy);
      } else {
        let index = books.findIndex((ele) => ele.key === record.key);
        if (index >= 0) {
          let newbook = JSON.parse(JSON.stringify(books));
          newbook[index].prices.splice(index1, 1);
          setBooks(newbook);
        }
      }
    } else {
      deleteBook({
        variables: {
          idx: val?.idx,
        },
      });
    }
  }
  return (
    <Settings className="setting-main-wrapper">
      <MyInfoWrapper className="setting-main-maininfo">
        <Form
          className="setting-main-frm"
          form={form}
          name="control-ref"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          onFinish={handleFormSubmit}
        >
          <Row gutter={[16, 16]} className="price-management-tbl">
            <Col span={24}>
              <CustomTable
                columns={columns}
                dataSource={books}
                color="#edf3fb"
                textAlign="left"
                pagination={false}
                scroll={{ y: 'calc(100vh - 290px)' }}
                bordered
              />
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <Button key="button" type="primary" onClick={handleFormSubmit}>
                  수정
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </MyInfoWrapper>
    </Settings>
  );
}

export default BookInfo;
