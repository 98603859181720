import React from 'react';
import moment from 'moment';

const TimeBlock = ({ date, readYN, unreadCount }) => {
  return (
    <p
      className='msg_date'
      style={{
        color: '#777777',
        opacity: '0.7',
        position: 'relative',
      }}
    >
      {/* {readYN && readYN === 'N' && <span style={{ position: 'absolute', top: '-20px', left: '0' }}>1</span>} */}
      {unreadCount && <span style={{ position: 'absolute', top: '-20px', left: '0' }}>{unreadCount}</span>}
      {moment(new Date(date)).format('A hh:mm').replace('AM', '오전').replace('PM', '오후')}
    </p>
  );
};

export default TimeBlock;
