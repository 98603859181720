import React, { useEffect } from 'react';
import _ from 'lodash';
import { isVowel } from '../../utils/pm/util';

const MagicWrite = ({ problem, steps, isAfter = false, onFinish }) => {
  const stepIndex = steps < problem.length ? steps : problem.length - 1;
  const short = problem[stepIndex]['short'];
  const long = problem[stepIndex]['long'];

  useEffect(() => {
    if (steps >= problem.length) {
      onFinish();
    }
    // eslint-disable-next-line
  }, [problem, steps]);

  return (
    <>
      <div className="hrbox_content">
        <div className="after active">
          <img src={`https://cdn.cloubot.com/PM/image/level3/image_${problem[stepIndex]['short']}.png`} alt={problem[stepIndex]['short']} />
          <p className="word">
            {_.map(short, (item) => (
              <span className={isVowel(item) ? 'text_pink' : 'text_blue'}>{item}</span>
            ))}
          </p>
        </div>
      </div>
      <img src="/images/pm/i_arrow_right.svg" alt="화살표" />
      <div className="hrbox_content">
        <div className={`${isAfter ? 'after' : 'before'} active`}>
          <img
            src={
              isAfter
                ? `https://cdn.cloubot.com/PM/image/level3/image_${problem[stepIndex]['long']}.png`
                : 'https://cdn.cloubot.com/PM/image/default_monster_gray.png'
            }
            alt={isAfter ? 'before' : problem[stepIndex]['long']}
          />
          {isAfter ? (
            <p className="word">
              {_.map(long, (item) => (
                <span className={isVowel(item) ? 'text_pink' : 'text_blue'}>{item}</span>
              ))}
            </p>
          ) : undefined}
        </div>
      </div>
    </>
  );
};

export default MagicWrite;
