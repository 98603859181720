import React from 'react';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isPturn } from 'src/utils';
import AdminCommon from './AdminCommon';
import AdminAfterSchool from './AdminAfterSchool';

//본사 주문 관리 페이지
const Admin = () => {
  const companyName = useSelector(classStoreData);

  return isPturn(companyName) ? <AdminAfterSchool /> : <AdminCommon />;
};

export default Admin;
