import React from 'react';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
const BookrGoRecordingModal = ({ visible, onCancel, data }) => {
  const history = useHistory();
  const goRecord = () => {
    history.push({
      pathname: `/bookr/recording/${data?.book_id}`,
      state: { articleData: data?.articleData, assigned_idx: data?.assigned_idx, idx: data?.idx },
    });
  };
  data?.hasData && visible && setTimeout(goRecord, 1500);
  return (
    <Modal
      title="Go Recording Page"
      visible={visible}
      onCancel={onCancel}
      footer={
        <Button type="primary" key="2" onClick={onCancel}>
          확인
        </Button>
      }
    >
      {data?.hasData ? <>loading...</> : <>Recording Contents를 준비중입니다.</>}
    </Modal>
  );
};
export default BookrGoRecordingModal;
