import React, { useState, useMemo } from 'react';
import { Button, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import OfflineEditSyllabus from 'src/components/Offline/MainPage/OfflineEditSyllabus';
import OfflineEditSyllabusFC from 'src/components/Offline/MainPage/OfflineEditSyllabusFC';
/*import SubPage from 'src/components/Offline/SubPage';*/
import { useHistory, Link } from 'react-router-dom';
import { getCurrentToken } from 'src/utils';
import { userVar } from 'src/apollo/cache';
import { useQueryProxy } from 'src/operations/proxy/user';
import * as queries from 'src/operations/queries';
//캠퍼스 원장용 대시보드 메인 페이지
const CampusPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  const history = useHistory();

  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  const userType = useMemo(() => userVar()[data?.me?.type] || userVar()[0], [data]);

  if (userType == 'fc') {
    return (
      <>
        <Row justify="space-between" className="buttonlisting-dashboard" style={{ margin: 0 }}>
          <Col span={4}>
            <HeaderTitle level={4}>Edit Syllabus</HeaderTitle>
          </Col>
          <Col span={18} style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
            <Button type={'primary'} size="large" style={{ background: 'white', color: '#1890ff' }}>
              <Link style={{ color: '#1890ff' }} to={`/offline/lesson/assign`}>
                Syllabus Main
              </Link>
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 16]}>
          <OfflineEditSyllabusFC />
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row justify="space-between" className="buttonlisting-dashboard" style={{ margin: 0 }}>
          <Col span={4}>
            <HeaderTitle level={4}>Edit Syllabus</HeaderTitle>
          </Col>
          <Col span={18} style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
            <Button type={'primary'} size="large" style={{ background: 'white', color: '#1890ff' }}>
              <Link style={{ color: '#1890ff' }} to={`/offline/lesson/assign`}>
                Syllabus Main
              </Link>
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 16]}>
          <OfflineEditSyllabus />
        </Row>
      </>
    );
  }
};

export default CampusPage;
