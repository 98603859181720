import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Row, Button, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_USER_ASSIGNMENT_LIST } from 'src/operations/queries/aiTopic';
import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import AITopicMenuGroup from 'src/components/common/AITopicMenuGroup';
import ViewAnswerModal from '../../components/Modal/ViewAnswerModal';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const CampusResults = (props) => {
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');

  const [classes, setClasses] = useState([]);
  const [ai_topic_user_assignments, setAssignedAITopics] = useState([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [userTopicId, setUserTopicId] = useState();
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      class_idx: 0,
      page: 0,
      class_type: 0, //class_type - 0: 진행, 1: 종료
    },
    'ai_topic_result',
  );

  const { data, loading, refetch } = useQuery(AI_TOPIC_USER_ASSIGNMENT_LIST, {
    // variables: { class_idx, page, take },
    variables: { class_idx: searchValue.class_idx, class_type: searchValue.class_type, type: 'ai-topic' },
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (data?.aiTopicUserAssignmentList) {
      setAssignedAITopics(
        data?.aiTopicUserAssignmentList.ai_topic_user_assignments.map((item, index) => {
          return { ...item, no: index + 1 };
        }),
      );
      setTotal(data?.aiTopicUserAssignmentList.total);
    }

    if (isRefresh) {
      refetch();
      props.history.replace({ search: '' });
    }
  }, [data, isRefresh]);

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
  }, [classQuery]);

  const handleAITopicAssignDelete = (item) => {
    const idxs = [item.idx];
    props.onAITopicAssignDelete({ idxs });
    setTimeout(() => {
      refetch();
      classQuery.refetch();
    }, 1000);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    {
      title: '주제',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic.topic}</Space>;
      },
    },
    {
      title: '사용자',
      dataIndex: 'user',
      key: 'user',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.user.name}</Space>;
      },
    },
    {
      title: '날짜',
      dataIndex: 'ai_topic_date_assignment',
      align: 'center',
      key: 'date',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic_date_assignment.start_date.substr(0, 10)}</Space>;
      },
    },
    {
      title: 'Class',
      dataIndex: 'class',
      key: 'class',
      width: 300,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.class.name}</Space>;
      },
    },
    {
      title: '상태',
      dataIndex: 'status',
      width: 100,
      key: 'status',
      align: 'center',
      render: (text, { status }) => {
        return <Space size="middle">{status === 0 ? '시작하지 않음' : status === 1 ? '시작함' : status === 2 ? '답변함' : '적합함'}</Space>;
      },
    },
    {
      title: '동작',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record.status === 0) {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  handleAITopicAssignDelete(record);
                }}
                danger
              >
                삭제
              </Button>
            </Space>
          );
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setUserTopicId(record.idx);
                  setTopicAnswer(record.answer);
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
  ];

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  return (
    <>
      <AITopicMenuGroup currentMenu="ai-topic-result" />
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>AI Topic 배정 결과</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Select
                style={{ width: 150, color: '#065aa9', marginRight: 10 }}
                placeholder="Select a Class"
                //onChange={(idx) => setClassIdx(idx)}
                //defaultValue={searchValue.class_idx}
                value={searchValue.class_idx}
                onChange={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      class_idx: parseInt(val),
                    };
                  })
                }
              >
                <Select.Option value={0}>전체</Select.Option>
                {classes.map((item) => (
                  <Select.Option key={item.idx} value={item.idx} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={ai_topic_user_assignments}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>
        {viewAnswerModal && (
          <ViewAnswerModal
            visible={viewAnswerModal}
            topic={topic}
            userTopicId={userTopicId}
            topicAnswer={topicAnswer}
            handleCancel={() => {
              setViewAnswerModal(false);
            }}
          />
        )}
      </Col>
    </>
  );
};

export default withRouter(CampusResults);
