import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Typography, Row, Space, Button } from 'antd';
import { Link } from 'react-router-dom';
import CustomTable from 'src/components/common/CustomTable';
import { MainBlock } from '../common/Styles';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import BackButton from '../common/BackButton';

const { Title } = Typography;

const onHandleRowClick = (row, index, user) => {
  if (window.Android && row.isMeeting) {
    if (user.type === 1) {
      window.Android.joinZoomClass(JSON.stringify(user));
    } else {
      window.Android.startZoomClass(JSON.stringify(user));
    }
  }
};

const DailySyllabusEdit = () => {
  const { idx } = useParams();
  const [test, setTest] = useState()

  const { data: fetchTestList, refetch } = useQuery(queries.getTests.GROUP_WISE_TEST_LIST, {
    fetchPolicy: 'no-cache',
    variables: { idx: parseInt(idx) },
  });

  const { data } = useQuery(queries.getTests.DAILY_TEST_GROUP_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      groupIdx: parseInt(idx)
    },
  });
  const [deleteDailyTest] = useMutation(mutations.createTests.DAILY_TEST_DELETE, {
    onCompleted(data) {
      if (data) {
        refetch();
        // openNotification('삭제 완료!');
      }
    },
  });

  const testsData = useMemo(() => {
    if (fetchTestList) {
      return fetchTestList.groupWiseTestList?.reduce((acc, cur, index) => {
        const obj = {
          excelFile: `${cur.test_name.split(".")[0]}.xlsx`,
          pdfFile: `${cur.test_name}`,
          testName: cur.test_folder_name,
          No: index + 1,
          key: index + 1,
          testIdx: cur?.idx,
          pdf_id: cur?.pdf_id,
          excel_id: cur?.excel_id
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchTestList]);

  useEffect(() => {
    if (testsData?.length > 0) {
      const test = testsData[testsData.length - 1]?.testName.split("-")
      const testNo = parseInt(test[1]) + 1
      setTest(`${test[0] + "-" + testNo}`)
    } else {
      setTest(`Test-1`)
    }
  }, [testsData])


  const handleTestDelete = (testId) => {
    console.log("testId", testId);
    deleteDailyTest({
      variables: {
        groupIdx: parseInt(idx),
        testIdx: testId
      }
    })
  }
  const handleDownload = async (fileUrl, fileName) => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const tempLink = document.createElement('a');
    tempLink.href = URL.createObjectURL(blob);
    // tempLink.download = fileUrl.test_folder_name;
    tempLink.download = fileName


    tempLink.click();
    URL.revokeObjectURL(tempLink.href);
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      width: 30,
      className: 'pad-4',
      align: 'center',
    },
    {
      title: 'Test Name',
      dataIndex: 'testName',
      key: 'code',
      width: 170,
      align: 'center',
    },
    {
      title: 'Excel File',
      dataIndex: 'excelFile',
      key: 'excelFile',
      ellipsis: true,
      className: 'pad-4',
      align: 'center',
      render: (text, record) => {
        return (
          <a onClick={() => handleDownload(record?.excel_id, record?.excelFile)}>{record?.excelFile}</a>
        );
      },
    },
    {
      title: 'Pdf File',
      dataIndex: 'pdfFile',
      key: 'pdfFile',
      ellipsis: true,
      className: 'pad-4',
      align: 'center',
      render: (text, record) => {
        console.log("record", record?.pdfFile);
        return (
          <a onClick={() => handleDownload(record?.pdf_id, record?.pdfFile)}>{record?.pdfFile}</a>
        );
      },
    },
    {
      title: 'Delete Test',
      dataIndex: 'testIdx',
      key: 'testIdx',
      ellipsis: true,
      className: 'pad-4',
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button onClick={() => handleTestDelete(record?.testIdx)} style={{ color: '#1890ff' }}>Delete</Button>
          </Space>
        );
      },
    },
    {
      title: 'Edit Test',
      dataIndex: 'testIdx',
      key: 'testIdx',
      ellipsis: true,
      className: 'pad-4',
      align: 'center',
      render: (text, record) => {
        console.log("record===", record);
        return (
          <Space size="middle">
            <Link to={`/daily/test/editTest/${idx}/${data?.dailyTestGroupDetail?.code}/${record.testIdx}`}>
              <Button style={{ color: '#1890ff' }}>Edit</Button>
            </Link>
          </Space>
        );
      },
    }
  ];

  return (
    <>
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItem: 'center',
          paddingTop: 15,
          paddingBottom: 10,
        }}
      >
        <BackButton />
      </Col>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="syllabus-mailblock">
          <Link to={`/daily/test/uploadtest/${idx}/${data?.dailyTestGroupDetail?.code}`} className="headitems-add">
            <Button style={{ color: '#289428', marginRight: "5px" }}>Assign</Button>
          </Link>

          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>Test List</Title>
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
            </Col>
          </Row>
          <CustomTable
            className="syllabustbl"
            dataSource={testsData}
            columns={columns}
            size="small"
            scroll={{ y: 'calc(100vh - 254px)' }}
            bordered
            pagination={{ pageSize: 10 }}
            color="#edf3fb"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => onHandleRowClick(record, rowIndex),
              };
            }}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default DailySyllabusEdit;
