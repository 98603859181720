import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row, Typography, Tag, Select } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import CustomTable from 'src/components/common/CustomTable';
import { CAMPUS_BOOK_CREDIT_INFO_LIST } from 'src/operations/queries/bookCredit';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
// import { isAfterSchool } from 'src/utils/index';
// import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';

const { Title } = Typography;

const BookCreditManage = () => {
  const [selectedTitle, setSelectedTitle] = useState('');
  const history = useHistory();
  const { data, loading } = useQuery(CAMPUS_BOOK_CREDIT_INFO_LIST, {
    variables: { campus_idx: userInfoVar()?.campus_idx },
    skip: !userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });
//  const companyName = useSelector(classStoreData);
  const [dataSource, titleOptions] = useMemo(() => {
    let list = data?.getCampusBookCreditInfoList;
    // if (isAfterSchool(companyName)) {
    //   list = list?.filter((item) => {
    //     return item.book.book_sub[0]?.book_category?.name === 'package';
    //   });
    // }
    let options = [{ label: '전체', value: '' }];
    let source = [];
    if (list) {
      source = list
        .map((item, key) => {
          return {
            key: `book-credit-${item.idx}`,
            no: key + 1,
            book_code: item.book_code,
            book_title: item?.book?.book_sub[0]?.title || item.book_code,
            total: item?.credit_count,
            used: item?.used_count,
            rest: item?.credit_count - item?.used_count,
            duration: item?.days===0 ? '' : item?.days,
            // duration: '30일', //임시로 한 거임. FC에서 책별로 지정, campus 마다 다를 수 있다. (campus_book_display의 credit_duration을 이용하자..)
            latest_date: item?.latest_date,
            latest_book_credit_count: item?.latest_book_credit_count,
          };
        })
        .filter((item2) => {
          if (selectedTitle) {
            return item2.book_title === selectedTitle;
          }
          return true;
        });

      const titles = list.reduce((acc, cur) => {
        const book_title = cur?.book?.book_sub[0]?.title || cur.book_code;
        if (acc.includes(book_title)) {
          return [...acc];
        }
        return [...acc, book_title];
      }, []);
      if (titles.length > 0) {
        options = [
          ...options,
          ...titles.sort().map((item) => {
            return { label: item, value: item };
          }),
        ];
      }
    }
    return [source, options];
  }, [data, selectedTitle]);

  const [totalRestCount] = useMemo(() => {
    let total = 0;
    if (dataSource) {
      total = dataSource.reduce((acc, cur) => {
        return acc + cur.rest;
      }, 0);
    }
    return [total];
  }, [dataSource]);

  return (
    <>
      <Row gutter={[24, 16]} className="bookcredit-top-head">
        <Col className="bookcredit-top-title" span={3} style={{ textAlign: 'left' }}>
          <Title level={5}>이용권 관리</Title>
        </Col>
        <Col className="bookcredit-top-rightform" span={21} style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}>
          <label>보유 이용권 :</label>
          <span
            style={{
              border: '2px solid #dfdfdf',
              fontSize: '14px',
              padding: '3px 15px',
              display: 'inline-block',
              marginRight: 10,
              textAlign: 'center',
              borderRadius: 7,
            }}
          >
            {totalRestCount}
          </span>
          <label>상품검색 :</label>
          <Select
            options={titleOptions}
            value={selectedTitle}
            onChange={setSelectedTitle}
            showSearch={true}
            style={{ minWidth: '100px', width: '20%' }}
          />
          &nbsp;
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/book/order');
            }}
          >
            Bookstore
          </button>
        </Col>
      </Row>
      <CustomTable
        className="bookcredit-table-table"
        pagination={{ pageSize: 12 }}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
      />
    </>
  );
};

export default BookCreditManage;

const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    width: '50px',
  },
  // {
  //   title: '기간 ',
  //   dataIndex: 'duration',
  //   key: 'duration',
  //   render: (tags) => (
  //     <>
  //       <Switch checkedChildren="ON" unCheckedChildren="OFF" defaultChecked />
  //     </>
  //   ),
  // },
  {
    title: '최근 구매 날짜',
    dataIndex: 'latest_date',
    key: 'latest_date',
    render: (date) => moment(date).format('YYYY-MM-DD'),
  },
  {
    title: ' 상품 명',
    dataIndex: 'book_title',
    key: 'book_title',
  },
  // {
  //   title: '누적 이용권 수',
  //   dataIndex: 'total',
  //   key: 'total',
  //   className: 'book-clm-ls-four',
  // },
  {
    title: '구매 이용권',
    dataIndex: 'latest_book_credit_count',
    key: 'latest_book_credit_count',
    className: 'book-clm-ls-three',
  },
  {
    title: ' 잔여 이용권',
    dataIndex: 'rest',
    key: 'rest',
    className: 'book-clm-ls-two',
  },
  {
    title: '기간 ',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: '이용권 부여 ',
    dataIndex: 'assign',
    key: 'assign',
    className: 'taglistcolumn',
    render: (_, { book_code, book_title, rest, duration }) => (
      <Link to={{ pathname: `/book-credit/assign/${book_code}`, state: { book_title, rest, duration } }}>
        <Tag color="blue">부여</Tag>
      </Link>
    ),
  },
];
