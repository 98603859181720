import React, { useState, useEffect } from 'react';
import PageNum from './PageNum';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const ImageSentectBox = ({ problem, steps, onCorrect, onWrong }) => {
  const [active, setActive] = useState(false);
  const handleClick = (isAnswer) => {
    if (isAnswer) {
      setActive(true);
      if (onCorrect instanceof Function) {
        onCorrect(undefined);
      }
    } else {
      onWrong();
    }
  };
  useEffect(() => {
    setActive(false);
  }, [steps]);
  return (
    <>
      <S.BorderColumnBox
        style={{ padding: '1.2rem', alignSelf: 'stretch', flex: '1 1', position: 'relative', height: 'calc(100% - 70px)' }}
        id="lv3_review6_content"
        className="main_content"
      >
        <PageWrap>
          <PageNum num={steps + 1} total={problem.length} style={{ fontWeight: 'bold' }} />
        </PageWrap>
        <TitleTxt className="ex_txt">{problem[steps].qSentence}</TitleTxt>
        <ImageSelectUl className="img_box">
          {problem[steps] &&
            problem[steps].exImage &&
            problem[steps].exImage.map((item, key) => (
              <ImageSelectLi key={`image-list-key${key}`}>
                <ImageSelectBtn
                  type="button"
                  active={active && item.isAnswer}
                  className={active && item.isAnswer ? 'active' : ''}
                  onClick={() => {
                    handleClick(item.isAnswer);
                  }}
                >
                  <img style={{ height: '32vh' }} src={item.image} alt="보기 이미지" />
                </ImageSelectBtn>
              </ImageSelectLi>
            ))}
        </ImageSelectUl>
      </S.BorderColumnBox>
    </>
  );
};

export default ImageSentectBox;

const TitleTxt = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  margin: 0 0 1.2rem;
  padding: 0;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  @media (max-height: 500px) {
	  font-size: 25px;
	  margin: 0 0 0.6rem;
  }
`;
const PageWrap = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  font-size: 1.3rem;
  @media (max-height: 500px) {
    top: 10px;
    right: 10px;
	left: unset;
	font-size: 1.1rem;
  }
`;
const ImageSelectUl = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.4rem;
  flex: 3 1;
  margin-bottom: 1.6rem;
  list-style: none;
  padding:0;
`;
const ImageSelectLi = styled.li`
  overflow: hidden;
  border-radius: 1.2rem;
`;
const ImageSelectBtn = styled.button`
  width: 100%;
  padding: 1.2rem 0;
  border-radius: 1.2rem;
  border: 3px solid ${(props) => (props.active ? '#feca57' : '#e5e5e5')};
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
`;
