import React from 'react';
import { Modal, Button, Table } from 'antd';
import { useMemo } from 'react';
const StageDateModal = ({ visible, stageDate, onCancel }) => {
  const data = useMemo(() => {
    if (stageDate) {
      return stageDate;
    } else return [];
  }, [stageDate]);
  const columns = [
    {
      key: 'stage_no',
      title: 'Stage',
      dataIndex: 'stage_no',
      width: 60,
      align: 'center',
    },
    { key: 'date', title: '학습기록', dataIndex: 'date', align: 'center' },
  ];
  return (
    <>
      <Modal
        visible={visible}
        title={'Stage Study Log'}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            닫기
          </Button>,
        ]}
      >
        <Table dataSource={data} columns={columns} pagination={false} size="small" color="#edf3fb" />
      </Modal>
    </>
  );
};

export default StageDateModal;
