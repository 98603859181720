import React, { useState, useEffect, useRef } from 'react';
import { Input, Modal, Select, Spin, message, Tabs, Button as AButton, Tooltip } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import styled from 'styled-components';
import { openNotification } from 'src/components/common/Notification';
import moment from 'moment';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, RedoOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Timer from 'src/components/LevelUp/Timer';
import {
  BOOK_REPORT_DEFAULT_FILL,
  BOOK_REPORT_REVISE_FILL,
  BOOK_REPORT_DEFAULT_FILL_LIST,
  BOOK_REPORT_REVISE_FILL_LIST,
  BOOK_REPORT_COMMENT_LIST,
  BOOK_REPORT_WORD_LIST,
} from 'src/constants/common';
const { TextArea } = Input;
const { Option } = Select;
const Wrapper = styled.div`
  width: 100%;

  height: 100%;
  background-color: #453dd0;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Header = styled(RowFlex)`
  justify-content: start;
  align-items: center;
  height: 75px;
  padding: 16px 65px 16px 45px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;
const StageTitle = styled.div`
  /* border-bottom: 1px solid #4472c4; */
  width: 100%;
  color: #453dd0;
  font-size: 32px;
  font-weight: bold;
  padding: 15px 30px;
  margin-bottom: 15px;
  background-color: #f3db4c;
  @media (max-width: 1280px) {
    font-size: 25px;
  }
`;
const StageTitleSpan = styled.span`
  color: '#00b0f0';
  font-size: 25px;
  padding-left: 50px;
  @media (max-width: 1280px) {
    font-size: 20px;
    padding-left: 40px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #2f5597;
  padding: 5px 10px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 0 45px 15px 45px;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 0 0px 25px;
  background-color: #ffffff;
  max-width: 1650px;
  min-width: 1350px;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 900px;
  }
`;

const HalfBody = styled.div`
  width: 50%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 85%;
`;
const TutorInput = styled.div`
  flex: 1;
  border: 2px #00b0f0 solid;
  height: 100%;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 25px;
  font-weight: bold;
  color: #797979;
  flex: 1;
  background-color: white;
  border-radius: 10px;
  overflow: auto;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #404040;
  line-height: 30px;
  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 24px;
  }
`;
const NumberBox = styled.div`
  width: 100px;
  height: 30px;
  border: 1px solid #dae3f3;
  border-radius: 5px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  color: ${(props) => props.color};
  font-weight: bold;
`;
const TopicArea = styled(TutorInput)`
  color: #000000;
  font-weight: 500;
  border: 2px #0070c0 solid;
  max-height: 70px;
`;
const InputArea = styled(TextArea)`
  resize: none;
  padding: 20px;
  font-size: 15px;
  line-height: 25px;
  color: #333232;
  font-weight: 600;
  border: 2px #ffc000 solid;
  border-radius: 10px;
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #ffc000 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;
const InputArea2 = styled(TextArea)`
  resize: none;
  padding: 20px;
  font-size: 15px;
  color: #333232;
  font-weight: 600;
  border: 2px #00b0f0 solid;
  border-radius: 10px;
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #00b0f0 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;
const LVSelect = styled(Select)`
  font-size: 14px;
  font-weight: bold;
  border: 1px #5b9ddb solid;
  color: #5b9ddb;
  padding: 0 5px;
  width: 170px;
  height: 34px;
  .ant-select-arrow {
    color: #5b9ddb;
  }
  .ant-select-selection-item {
    font-weight: bolder;
  }
  ::placeholder {
    color: #5b9ddb;
  }
  @media (max-width: 1720px) {
    width: 150px;
    font-size: 12px;
  }
  @media (max-width: 1280px) {
    width: 130px;
    font-size: 12px;
  }
`;
const LVOption = styled(Option)`
  font-size: 14px;
  font-weight: bold;
`;
const Button = styled.div`
  cursor: pointer;
  border-radius: ${(props) => (props.radius ? '20px' : '10px')};
  font-size: 1.1em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: ${(props) => (props.radius ? '9px 30px' : '9px 18px')};
  border: ${(props) => (props.radius ? 'none' : '2px #dae3f3 solid')};
  @media (max-width: 1720px) {
    padding: ${(props) => (props.radius ? '9px 20px' : '8px 15px')};
    font-size: 1em;
    /* padding: 8px 15px; */
  }
  @media (max-width: 1280px) {
    padding: ${(props) => (props.radius ? '9px 17px' : '8px 15px')};
    font-size: 0.8em;
    font-weight: normal;
    padding: 8px 14px;
  }
`;

const Paginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* overflow: auto; */
  padding: 0px 20px;
  border-radius: 15px;
  max-width: 460px;
  min-width: 460px;
  /* background-color: #c7e3fd; */
`;

const PageButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.3em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  padding: 6px 15px;
  margin: 0 5px;
  max-width: 50px;
  min-width: 50px;
  text-align: center;
`;

const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #5a5757;
  /* background: #ed7d31; */
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const LoadingScreen = styled.div`
  height: 100%;
  background: #ffffff88;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Dictionary = styled(Modal)`
  .ant-modal-header {
    background-color: #f0effb;
    border-radius: 20px;
  }
  .ant-modal-content {
    border: 10px solid #453dd0;
    border-radius: 20px;
    background-color: #f0effb;
  }
`;
const DictionaryInput = styled(Input)`
  resize: none;
  padding: 5px;
  width: 55%;
  font-size: 15px;
  color: #333232;
  font-weight: 500;
  border: 2px #453dd0 solid;
  /* border-radius: 10px; */
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #ffc000 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;

export default function AiEssayCorrection({
  defautTopic,
  defaultLevel,
  defaultChatList,
  defaultFeedback,
  defaultRevise,
  idx,
  onShowRecording,
}) {
  const [level, setLevel] = useState(defaultLevel || null);
  const [wordLevel, setWordLevel] = useState('Grade 1');
  const [defaultFill, setDefaultFill] = useState(BOOK_REPORT_DEFAULT_FILL);
  const [reviseFill, setReviseFill] = useState(BOOK_REPORT_REVISE_FILL);
  const [commentFill, setCommentFill] = useState(BOOK_REPORT_COMMENT_LIST['min']);
  const [chatList, setChatList] = useState(defaultChatList.length ? defaultChatList : []);
  const [currentText, setCurrentText] = useState('');
  const [currentWord, setCurrentWord] = useState('');
  const [page, setPage] = useState(
    defaultChatList.length
      ? defaultChatList.filter((chat) => chat.user === 'bot' && chat.data.type !== 'word' && chat.data.type !== 'comment-full').length - 1
      : 0,
  );
  const [writingType, setWritingType] = useState('sentences');
  const [language, setLanguage] = useState('Korean');
  const [startTime, setStartTime] = useState(new Date());
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [dictionaryVisible, setDictionaryVisible] = useState(false);
  const [rewriteModalVisible, setRewriteModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');
  const [rewriteModalText, setRewriteModalText] = useState('');
  const [topic, setTopic] = useState(defautTopic || '');
  const [editType, setEditType] = useState('check');
  const botChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type !== 'word' && chat.data.type !== 'comment-full');
  const wordChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type === 'word');
  const botCheckChat = botChats.filter((chat) => chat.data.type === 'check');
  const botReviseChat = botChats.filter((chat) => chat.data.type === 'revise');
  const botCommentChat = botChats.filter((chat) => chat.data.type === 'comment');
  const history = useHistory();

  useEffect(() => {
    if (level) {
      if (writingType === 'sentences') {
        setDefaultFill(BOOK_REPORT_DEFAULT_FILL);
      } else {
        setDefaultFill(BOOK_REPORT_DEFAULT_FILL_LIST[level]);
      }
      setReviseFill(BOOK_REPORT_REVISE_FILL_LIST[level]);
    }
  }, [level, writingType]);

  useEffect(() => {
    if (writingType !== 'sentences') {
      setCommentFill(BOOK_REPORT_COMMENT_LIST[writingType === 'essay' ? 'full' : 'min']);
    }
  }, [writingType]);

  const [loadMoreMessages, { data, loading, error }] = useLazyQuery(OPENAI_COMPLETION_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (updatedData?.updateAITopicUserAssignment?.status) {
      if (updatedData?.updateAITopicUserAssignment?.status === 2) {
        openNotification('답변이 저장되었습니다!');
      } else if (updatedData?.updateAITopicUserAssignment?.status === 1) {
        onShowRecording(updatedData?.updateAITopicUserAssignment?.answer);
      }
    }
  }, [updatedData]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setCurrentText(value);
  };

  useEffect(() => {
    if (data?.getOpenAiCompletion?.text?.length > 0) {
      let curData = {
        user: 'bot',
        chat_user_name: 'AI봇',
        prompt: currentText,
        content: data.getOpenAiCompletion?.text,
        urls: data.getOpenAiCompletion?.urls,
        created: data?.getOpenAiCompletion?.date,
        data: data?.getOpenAiCompletion?.chat?.data ? JSON.parse(data?.getOpenAiCompletion?.chat?.data) : null,
      };
      setChatList([...chatList, curData]);

      if (curData.data.type !== 'word' && curData.data.type !== 'comment-full') {
        setPage(botChats.length);
      }
    }
  }, [data]);

  const handleCloseClick = () => {
    Modal.confirm({
      icon: null,
      title: 'Exit',
      content: (
        <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
          학습이 완료되지 않았습니다. 재접속시 기존 학습 이후부터 진행됩니다. 학습을 종료하시겠습니까?
        </Title>
      ),
      onOk: () => history.push('/'),
    });
  };

  const onSubmit = () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          level: level,
          topic: topic,
          type: 'check',
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');

    loadMoreMessages({
      variables: {
        prompt: defaultFill.replaceAll('((level))', level).replaceAll('((topic))', currentText).replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: parseInt(idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          topic: topic,
          type: 'check',
          currentText: currentText,
        }),
      },
    });
  };

  const onAnswerSubmit = () => {
    if (rewriteModalText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    Modal.confirm({
      icon: null,
      title: 'Submit',
      content: (
        <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
          Would you like to submit?
        </Title>
      ),
      onOk: () => {
        updateAITopicUserAssignment({
          variables: {
            idx: parseInt(idx),
            answer: rewriteModalText,
            start_time: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
            end_time: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
            status: 1,
          },
        });
      },
      onCancel: () => {},
    });
  };

  const onRevise = () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
        data: {
          level: level,
          topic: topic,
          type: 'revise',
        },
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: reviseFill.replaceAll('((level))', level).replaceAll('((topic))', currentText).replaceAll('((language))', language),
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: parseInt(idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          topic: topic,
          type: 'revise',
          currentText: currentText,
        }),
      },
    });
  };

  const onComment = async () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    await loadMoreMessages({
      variables: {
        prompt: commentFill.replaceAll('((level))', level).replaceAll('((topic))', currentText).replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: parseInt(idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'comment',
          currentText: currentText,
        }),
      },
    });

    await onCommentFull();
  };

  const onCommentFull = async () => {
    if (currentText.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    await loadMoreMessages({
      variables: {
        prompt: BOOK_REPORT_COMMENT_LIST['full']
          .replaceAll('((level))', level)
          .replaceAll('((topic))', currentText)
          .replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: parseInt(idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'comment-full',
          currentText: currentText,
        }),
      },
    });
  };

  const onWordCheck = () => {
    if (currentWord.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    if (!wordLevel) {
      message.error('레벨을 선택해주세요.');
      return;
    }

    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentWord,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: BOOK_REPORT_WORD_LIST[wordLevel]
          .replaceAll('((level))', wordLevel)
          .replaceAll('((word))', currentWord)
          .replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: parseInt(idx),
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: level,
          type: 'word',
          currentText: currentWord,
        }),
      },
    });
  };
  const handleWordClear = () => {
    setCurrentWord('');
    const tmp = chatList.filter((e) => (!e.data ? true : e.data?.type !== 'word'));
    setChatList(tmp);
  };
  //   const handleClear = ()=>{
  //     form.resetFields();
  //     console.log(form)
  //   }
  const onChangeLevel = (value) => {
    setLevel(value);
    console.log(value);
  };

  const onChangeWordLevel = (value) => {
    setWordLevel(value);
    console.log(value);
  };

  const onClear = () => {
    // setChatList([]);
    setCurrentText('');
    setPage(0);
  };

  const handleModalOpen = (type) => {
    return false;
    setEditType(type);
    if (type === 'check') {
      setModalText(defaultFill);
    }
    if (type === 'revise') {
      setModalText(reviseFill);
    }

    if (type === 'comment') {
      setModalText(commentFill);
    }

    setModalVisible(true);
  };

  const handleModalChange = (e) => {
    setModalText(e.target.value);
  };

  const handleModalOk = () => {
    setModalVisible(false);
    if (editType === 'check') {
      setDefaultFill(modalText);
    }
    if (editType === 'revise') {
      setReviseFill(modalText);
    }
    if (editType === 'comment') {
      setCommentFill(modalText);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setModalText('');
  };

  const handleRewriteModalCancel = () => {
    setRewriteModalVisible(false);
    setRewriteModalText('');
  };

  const handleRewriteModalOk = () => {
    setRewriteModalVisible(false);

    if (!defaultFeedback) {
      onCommentFull();
    }

    // setReviseFill(rewriteModalText);
  };
  const handleDictionaryModalOk = () => {
    setDictionaryVisible(false);
    // setReviseFill(rewriteModalText);
  };

  const handleRewriteModalChange = (e) => {
    setRewriteModalText(e.target.value);
  };

  const onRewrite = () => {
    setRewriteModalVisible(true);
    // setRewriteModalText('');
  };

  const pageWrapperRef = useRef(null);

  useEffect(() => {
    //50.47
    if (pageWrapperRef.current) {
      // const pageWidth = pageWrapperRef.current.offsetWidth;
      // const halfScreenWidth = pageWidth / 2;
      // const scrollPosition = page * pageWidth/50.47;
      if (page < 3) {
        pageWrapperRef.current.scrollLeft = 0;
      } else if (page > botChats.length - 4) {
        pageWrapperRef.current.scrollLeft = pageWrapperRef.current.scrollWidth;
      } else {
        // 페이지를 화면 가운데에 위치하도록 조정
        pageWrapperRef.current.scrollLeft = 60 * (page - 2);
      }
    }
  }, [page]);

  return (
    <Wrapper>
      <Header>
        <div
          onClick={() => {
            history.push('/level-up');
          }}
          style={{ cursor: 'pointer' }}
        >
          {' '}
          <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} />
        </div>
        <HeadBox background="transparent" color={'#5a5757'} style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px' }}>
          ID: {userInfoVar()?.id}
          <CloseButton
            onClick={() => {
              history.push('/');
            }}
          >
            <CloseOutlined />
          </CloseButton>
        </HeadBox>
      </Header>
      <MainWrapper>
        <Main>
          <StageTitle>
            BOOK REPORT :{' '}
            <StageTitleSpan>
              <span style={{ color: '#002060' }}>
                Automatic<span onClick={() => handleModalOpen('check')}> Proofreading</span>, Editing,{' '}
                <span onClick={() => handleModalOpen('revise')}>Rewriting</span>, and{' '}
                <span onClick={() => handleModalOpen('comment')}>Explanation </span>
              </span>
            </StageTitleSpan>
          </StageTitle>
          <RowFlex style={{ height: '100%', padding: '0 20px' }}>
            <HalfBody>
              <Title style={{ margin: '5px 0 5px 0' }}>Essay Topic / Prompt</Title>
              <TopicArea>{topic}</TopicArea>

              <RowFlex style={{ justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px', alignItems: 'center' }}>
                <Title style={{ color: '#ff9900' }}>Editor</Title>
                <RowFlex style={{ padding: '5px 0' }}>
                  <RowFlex>
                    <SubTitle>Word Count : </SubTitle>
                    <NumberBox color={'#0070c0'}>{currentText.split(' ').filter((word) => word !== '').length}</NumberBox>
                  </RowFlex>
                  <RowFlex>
                    <SubTitle>Time : </SubTitle>
                    <NumberBox color={'#ff0000'}>
                      <Timer />
                    </NumberBox>
                  </RowFlex>
                </RowFlex>
              </RowFlex>
              <div style={{ flex: 1, resize: 'none', padding: '0' }}>
                {loading ? (
                  <LoadingScreen>
                    <Spin fontSize="24px" />
                  </LoadingScreen>
                ) : (
                  <InputArea
                    style={{ resize: 'none', height: '100%' }}
                    value={currentText}
                    onChange={handleInputChange}
                    disabled={!level || loading}
                    placeholder={!level ? 'Select Your Grade' : ''}
                  />
                )}
              </div>

              <RowFlex style={{ justifyContent: 'space-between', marginTop: '12px', alignItems: 'center', padding: '10px 10px 0' }}>
                <Button back={'#ff7e1d'} color={'white'} onClick={onClear} radius={true}>
                  CLEAR
                </Button>

                <LVSelect
                  dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                  placeholder={` Level `}
                  onChange={onChangeLevel}
                  bordered={false}
                  value={level}
                >
                  {[
                    { value: 'Grade 1', label: 'Basic A' },
                    { value: 'Grade 4', label: 'Basic B' },
                    {
                      value: 'Grade 7',
                      label: 'Intermediate',
                    },
                    {
                      value: 'Grade 11',
                      label: 'Advanced',
                    },
                    { value: 'College Level', label: 'College' },
                  ].map((e) => (
                    <LVOption key={e.value} value={e.value}>
                      {e.label}
                    </LVOption>
                  ))}
                </LVSelect>
                <LVSelect
                  disabled={!defaultFeedback}
                  dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                  placeholder={` Sentences `}
                  onChange={(e) => setWritingType(e)}
                  bordered={false}
                  value={writingType}
                >
                  {[
                    {
                      value: 'sentences',
                      label: `Sentences`,
                    },
                    {
                      value: '60',
                      label: `Min. 60W`, //Minimum 60 words
                    },
                    {
                      value: '100',
                      label: `Min. 100W`, //Minimum 100 words
                    },
                    {
                      value: '150',
                      label: `Min. 150W`, //Minimum 150 words
                    },
                    {
                      value: '200',
                      label: `Min. 200W`, //Minimum 250 words
                    },
                    {
                      value: 'essay',
                      label: `Full Feedback`, //Minimum 250 words
                    },
                  ].map((e) => (
                    <LVOption key={e.value} value={e.value}>
                      {e.label}
                    </LVOption>
                  ))}
                </LVSelect>
                <Button back={'#00abff'} color={'white'} onClick={() => setDictionaryVisible(true)} radius={true}>
                  &nbsp;&nbsp;DIC&nbsp;&nbsp;
                </Button>
              </RowFlex>
              <RowFlex style={{ justifyContent: 'space-between', alignItems: 'center', padding: '15px 10px 0' }}>
                <Button disabled={loading} onClick={onSubmit} back={'#64a6f7'} color={'white'} radius={false}>
                  1. CHECK
                </Button>
                <Button
                  back={writingType === 'sentences' ? 'gray' : '#64a6f7'}
                  color={'white'}
                  radius={false}
                  onClick={() => {
                    if (writingType !== 'sentences') {
                      onComment();
                    }
                  }}
                >
                  2. FEEDBACK
                </Button>
                <Button disabled={defaultRevise} back={'#64a6f7'} color={'white'} onClick={onRevise} radius={false}>
                  3. REVISE
                </Button>
                <Button
                  back={!botChats?.length ? '#5085C6' : '#64a6f7'}
                  color={'white'}
                  radius={false}
                  onClick={() => {
                    if (loading || loadingUpdate || !botChats?.length) {
                      return;
                    }

                    onRewrite();
                  }}
                >
                  4. REWRITE
                </Button>
                <Button
                  back={'#ffb600'}
                  color={'white'}
                  radius={false}
                  loading={loadingUpdate}
                  onClick={() => {
                    if (loading || loadingUpdate || !botChats?.length) {
                      return;
                    }

                    onAnswerSubmit();
                  }}
                >
                  5. UPLOAD
                </Button>
              </RowFlex>
            </HalfBody>
            <HalfBody>
              <RowFlex style={{ justifyContent: 'space-between', paddingBottom: '5px' }}>
                <Title style={{ marginTop: '6px' }}>AI Writing Tutor</Title>
                <RowFlex style={{ justifyContent: 'right', fontSize: '27px' }}>
                  <LVSelect
                    dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                    placeholder={` Language `}
                    onChange={(e) => {
                      setLanguage(e);
                    }}
                    value={language}
                    bordered={false}
                  >
                    {[
                      {
                        value: 'Korean',
                        label: `Korean`,
                      },
                      {
                        value: 'Taiwanese',
                        label: `Taiwanese`,
                      },
                      {
                        value: 'Japanese',
                        label: `Japanese`,
                      },
                      {
                        value: 'Spanish',
                        label: `Spanish`,
                      },
                    ].map((e) => (
                      <LVOption key={e.value} value={e.value}>
                        {e.label}
                      </LVOption>
                    ))}
                  </LVSelect>
                  <RedoOutlined style={{ padding: '0 10px', color: '#4976d2', fontWeight: 'bold' }} />
                </RowFlex>
              </RowFlex>
              <TutorInput>
                {botChats?.length && botChats[page]?.content
                  ? botChats[page]?.content.split('\n').map((line, index) => {
                      return (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      );
                    })
                  : ''}
              </TutorInput>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Paginate>
                  <PageButton
                    color={'#ffffff'}
                    back={'#ed7d31'}
                    onClick={() => {
                      if (page > 0) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    <ArrowLeftOutlined />
                  </PageButton>
                  <PageWrapper ref={pageWrapperRef}>
                    {botChats?.length
                      ? botChats.map((chat, index) => {
                          return (
                            <PageButton
                              key={index}
                              color={page === index ? '#ffffff' : '#000000'}
                              back={page === index ? '#ed7d31' : '#ffffff'}
                              onClick={() => setPage(index)}
                            >
                              {index + 1}
                            </PageButton>
                          );
                        })
                      : ''}
                  </PageWrapper>
                  <PageButton
                    color={'#ffffff'}
                    back={'#ed7d31'}
                    onClick={() => {
                      if (page < botChats.length - 1) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    <ArrowRightOutlined />
                  </PageButton>
                </Paginate>
              </div>
            </HalfBody>
          </RowFlex>
        </Main>
      </MainWrapper>
      <Modal title="Edit script" visible={modalVisible} onOk={handleModalOk} onCancel={handleModalCancel}>
        <TextArea value={modalText} onChange={handleModalChange} rows={6} />
      </Modal>
      <Modal
        title=""
        visible={rewriteModalVisible}
        onOk={handleRewriteModalOk}
        onCancel={handleRewriteModalCancel}
        width={`80%`}
        footer={null}
        bodyStyle={{
          backgroundColor: '#453dd0',
        }}
      >
        <Title style={{ margin: '15px 0 0px 0', backgroundColor: '#ffda00' }}>Book Report: Final Draft</Title>
        <RowFlex
          style={{ height: '100%', background: '#fff', padding: '10px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
        >
          <HalfBody>
            <Tabs defaultActiveKey="1" onChange={(e) => setCurrentTab(e)} value={currentTab} type="card" style={{ height: '100%' }}>
              <Tabs.TabPane tab="Original" key="1" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                <TutorInput value={currentText} style={{ height: '50vh' }}>
                  {currentText}
                </TutorInput>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Check" key="2" style={{ height: '100%' }}>
                <TutorInput style={{ height: '50vh' }}>
                  {botCheckChat?.length && botCheckChat[botCheckChat?.length - 1]?.content
                    ? botCheckChat[botCheckChat?.length - 1]?.content.split('\n').map((line, index) => {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </TutorInput>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Comment" key="3" style={{ height: '100%' }}>
                <TutorInput style={{ height: '50vh' }}>
                  {botCommentChat?.length && botCommentChat[botCommentChat?.length - 1]?.content
                    ? botCommentChat[botCommentChat?.length - 1]?.content.split('\n').map((line, index) => {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    : ''}
                </TutorInput>
              </Tabs.TabPane>
            </Tabs>
          </HalfBody>
          <HalfBody>
            <Title style={{ margin: '5px 0 10px 0', textAlign: 'center' }}>Final Draft</Title>
            <InputArea value={rewriteModalText} onChange={handleRewriteModalChange} style={{ height: '50vh' }} />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <Button
                back={'#6ac5b8'}
                color={'white'}
                style={{ width: '100px', borderRadius: '20px', textAlign: 'center' }}
                onClick={handleRewriteModalOk}
              >
                Save
              </Button>
            </div>
          </HalfBody>
        </RowFlex>
      </Modal>
      <Dictionary
        title={<div>Dictionary ( Eng / Korean )</div>}
        onCancel={handleDictionaryModalOk}
        visible={dictionaryVisible}
        handleCancel={handleDictionaryModalOk}
        footer={''}
        width={'45%'}
      >
        <RowFlex style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: '20px' }}>
          {' '}
          <DictionaryInput allowClear value={currentWord} onPressEnter={onWordCheck} onChange={(e) => setCurrentWord(e.target.value)} />
          <SearchBtn
            onClick={() => {
              if (currentWord !== '') {
                onWordCheck();
              }
            }}
          >
            Search
          </SearchBtn>
          <LVSelect
            dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
            placeholder={` Level `}
            onChange={onChangeWordLevel}
            bordered={false}
            value={wordLevel}
          >
            {[
              { value: 'Grade 1', label: 'Basic A' },
              { value: 'Grade 4', label: 'Basic B' },
              {
                value: 'Grade 7',
                label: 'Intermediate',
              },
              {
                value: 'Grade 11',
                label: 'Advanced',
              },
              { value: 'College Level', label: 'College' },
            ].map((e) => (
              <LVOption key={e.value} value={e.value}>
                {e.label}
              </LVOption>
            ))}
          </LVSelect>
          <Tooltip title="검색 기록 삭제">
            <RedoOutlined
              onClick={handleWordClear}
              style={{ cursor: 'pointer', padding: '0 0 0 10px', color: '#4976d2', fontWeight: 'bold', fontSize: '20px' }}
            />
          </Tooltip>
        </RowFlex>
        <SearchResultBox>
          {wordChats?.length && wordChats[wordChats?.length - 1]?.content
            ? wordChats[wordChats?.length - 1]?.content.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                );
              })
            : ''}
        </SearchResultBox>
      </Dictionary>
    </Wrapper>
  );
}
const SearchBtn = styled(AButton)`
  background-color: #ffc60b;
  height: 35px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;
const SearchResultBox = styled.div`
  width: 80%;
  min-height: 500px;
  background-color: #fff;
  margin: 0 auto 20px;
  border: 1px solid lightgray;
  padding: 15px;
  line-height: 2;
`;

const PageWrapper = styled(RowFlex)`
  overflow-x: auto;
  /* width: 350px; */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ffb600;
    border-radius: 3px;
  }
`;
