import React, {  useState, useEffect } from 'react';
import styled from 'styled-components';
import {  useQuery } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import {  Tooltip } from 'antd';
import useSound from 'use-sound';
import clipGuide from 'src/sounds/meta/3.mp3';
import { GET_USER_INFO } from 'src/operations/queries/campus';

const InnerWrap = styled.div`
  background-color: #fff;
  width: 80%;
  height: 75%;
  max-width: 1800px;
  min-width: 650px;
  padding: 2rem 1rem;
  margin: 10rem auto;
  border-radius: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1400px) {
    max-width: 1150px;
    margin: 5rem auto;
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 33rem);
    height: calc(100% - 30rem);
    max-width: 100%;
    padding: 2rem 1rem;
    margin: 10rem auto;
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 9) {
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
    margin: 0 auto;

    & {
      .process-btn-con {
        padding: 0 4rem;
        a {
          img {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 10) {
    width: calc(97rem - 3rem);
    height: calc(53rem - 3rem);
    padding: 3rem 1rem;
    margin: 10rem auto;
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    width: calc(100% - 10rem);
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 750px;
    margin: 3rem auto;
  }

  @media (max-width: 1680px) and (aspect-ratio: 16 / 10) {
    width: calc(97rem - 3rem);
    height: calc(53rem - 3rem);
    padding: 2rem 1rem;
    margin: 6rem auto;

    & {
      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 2rem;
        }
      }
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 1rem 2rem 2rem;
    max-height: 650px;
    margin: 5rem auto;
  }

  @media (max-width: 1440px) and (aspect-ratio: 16 / 10) {
    width: calc(90rem - 18rem);
    height: calc(45rem - 3rem);

    & {
      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 100%;
    max-width: 1000px;
    max-height: 500px;
    margin: 0 auto;
    padding: 0;
  }

  @media screen and (max-width: 1280px) and (aspect-ratio: 16 / 10) {
    height: calc(45rem - 3rem);
    margin: 6rem auto;
  }

  @media (max-width: 1366px) {
    width: calc(100% - 5rem);
    margin: 5rem auto;
  }

  @media (max-width: 1024px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 80%;
    max-width: 800px;
    max-height: 400px;
    margin: 0 auto;

    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }
    }
  }

  @media (max-width: 1024px) {
    width: calc(100% - 7rem);
    //  height: calc(100% - 15rem);
  }

  @media screen and (max-width: 1024px) {
    margin: 5rem auto;
  }

  /* galaxy tab */
  @media only screen and (max-width: 820px) and (orientation: portrait) {
    height: calc(90vh - 5rem);
    margin: 10rem auto;
  }

  @media screen and (min-width: 1024px) and (min-height: 1366px) and (orientation: portrait) {
  }

  /* air */
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    // height: calc(90vh - 10rem);
    height: auto;
    max-height: 100%;
    padding: 1rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  }

  @media screen and (max-width: 800px) and (orientation: portrait) {
    height: auto;
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    padding: 0 1rem;
    margin: 5rem auto;
    // height: calc(100vh - 5rem);
    width: calc(100% - 5rem);
    min-width: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 768px) and (orientation: landscape) {
    max-height: 100%;
  }

  @media screen and (max-width: 425px) and (orientation: portrait) {
    margin: 8rem auto;
  }
`;

// const burgerData = ['1', '2', '3', '4', '5'];
const passageText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">1</span>There is a scroll if the passage is long.
  </span>
);
const nextText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">3</span>Click to go to the next question
  </span>
);
const anywhereText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">2</span>Click anywhere in this box
  </span>
);
// const timeText = <span>It is the countdown clock.</span>;
// const questionText = <span>Question number / Total Items</span>;
const ReadingTestSample = () => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 ? true : false;
  const [isMarked, setIsMarked] = useState(false);
  const history = useHistory();

  const [playStart, { stop: stopPlay }] = useSound(clipGuide, { volume: 0.5 });

  useEffect(() => {
    if (playStart) {
      setTimeout(() => {
        playStart();
      }, 1000);
    }
  }, [playStart]);

  const stopSoundAndLeave = () => {
    if (stopPlay) {
      stopPlay();
    }
    history.push('/MetaExam/ListeningTestSample');
  };
  return (
    <>
      <div className={isWEnglish ? 'meta-con guide-con w-english-bg' : 'meta-con guide-con'}>
        <div className="meta-header test-header">
          <div className="burger-wrap">
            <img src="../images/meta_hamburger.png" alt="burger_meta" />
            <h2 style={{ marginBottom: '0' }}>Question List</h2>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <div className="time-wrap">
              <h2>Time: </h2>
              <h2 className="num">10:00</h2>
            </div>

            <div className="question-wrap">
              <h2>Question: </h2>
              <h2 className="num">10/30</h2>
            </div>
          </div>
        </div>
        <InnerWrap>
          <div className="meta-header readingTest-header">
            <h1 className="main-tit" style={{ color: '#' }}>
              Reading
            </h1>
          </div>

          <div className="readingtest-con">
            <Tooltip
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultVisible="true"
              visible="true"
              trigger="focus"
              placement="top"
              title={passageText}
            >
              <div className="readingtest-wrap passage-con" style={{ overflowY: 'scroll' }}>
                <p>
                  Caleb sat on the grass between the fence and the blackberry bushes, trying to read for a class assignment. But the warm
                  sun and gentle breeze made him sleepy. He closed his eyes and listened tothe bees near the white blackberry flowers
                  buzzing like tiny motors. He opened his eyes and watched the clouds slowly travel across the sky. He heard the screen door
                  open suddenly with a loud squeak. His mother was looking for him. “Have you finished your assignment yet?” she asked.
                  Caleb sighed quietly and picked up his bookagain. Caleb sat on the grass between the fence and the blackberry bushes,
                  trying to read for a class assignment. But the warm sun and gentle breeze made him sleepy. He closed his eyes and listened
                  tothe bees near the white blackberry flowers buzzing like tiny motors. He opened his eyes and watched the clouds slowly
                  travel across the sky. He heard the screen door open suddenly with a loud squeak. His mother was looking for him. “Have
                  you finished your assignment yet?” she asked. Caleb sighed quietly and picked up his bookagain. Caleb sat on the grass
                  between the fence and the blackberry bushes, trying to read for a class assignment. But the warm sun and gentle breeze
                  made him sleepy. He closed his eyes and listened tothe bees near the white blackberry flowers buzzing like tiny motors. He
                  opened his eyes and watched the clouds slowly travel across the sky. He heard the screen door open suddenly with a loud
                  squeak. His mother was looking for him. “Have you finished your assignment yet?” she asked. Caleb sighed quietly and
                  picked up his bookagain.
                </p>
              </div>
            </Tooltip>

            <div className="readingtest-wrap">
              <div className="reading-q-con">
                <h1>10. Caleb was ________.</h1>
              </div>
              <div className="reading-a-con">
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>a</h3>
                  </div>
                  <div className="reading-a">
                    <h3>Ignored</h3>
                  </div>
                </div>
                <Tooltip
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultVisible="true"
                  visible="true"
                  trigger="focus"
                  placement="right"
                  title={anywhereText}
                >
                  <div style={{ border: '1px dashed orange', padding: '0.1rem' }} onClick={() => setIsMarked(true)}>
                    <div className="reading-a-wrap">
                      <div className="reading-a-num">
                        <h3>b</h3>
                        {isMarked && <img src="../images/check.png" alt="burger_meta" className="checkedImg" />}
                      </div>
                      <div className="reading-a" style={{ width: '100%', display: 'flex' }}>
                        <h3>distracted</h3>
                        {/*<div className="arrow-wrap">
                        <p className="guide-arrow">
                          <span class="arrow-body">2. Click anywhere in this box</span>
                        </p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>c</h3>
                  </div>
                  <div className="reading-a">
                    <h3>Protected</h3>
                  </div>
                </div>
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>d</h3>
                  </div>
                  <div className="reading-a">
                    <h3>Confused</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* bottom btn */}

          <div className="process-btn-con">
            <Link to="/MetaExam/InstructionalGuideMeta">
              <img src="/images/back_meta.png" alt="back_meta" />
            </Link>
            {/*
            <div className="arrow-wrap">
              <p className="guide-arrow">
                <span class="arrow-body">3. Click to go to the next question</span>
              </p>
            </div> */}
            <Tooltip
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultVisible="true"
              visible="true"
              trigger="focus"
              placement="left"
              title={nextText}
            >
              <a href="#" onClick={stopSoundAndLeave} style={{ marginLeft: 'auto' }}>
                <img src="/images/next_meta.png" alt="next_meta" />
              </a>
            </Tooltip>
          </div>
        </InnerWrap>
      </div>
    </>
  );
};

export default ReadingTestSample;
