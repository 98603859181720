import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Select, Row, Button,Form } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { ZOOM_LIST } from 'src/operations/queries/getZoom';
import { GET_TYPE_CLASS } from 'src//operations/queries/getClass';
import { useQuery } from "@apollo/client"
import moment from 'moment';

const { Title } = Typography;

const columns = [
  {
    title: 'No',
    dataIndex: 'idx',
    key: 'No',
    width:50,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: "center",
    width:120,
    render: (text, record) => {
      return (
        <Space size="middle">
          {moment(record.start_time).format("YYYY-DD-MM")}
        </Space>
      )
    }
  },
  {
    title: 'Time',
    key: 'time',
    render: (text, record) => {
      return (
        <Space size="middle">
          {`${moment(record.start_time).format("HH-mm")} - ${moment(record.end_time).format("HH-mm")}`}
        </Space>
      )
    },
    width:120,
  },
  {
    title: 'Class',
    key: 'class',
    render: (text, record) => {
      return (
        <Space size="middle">
          {record.class ? record.class.name : ''}
        </Space>
      )
    },
    width:100,
    align: "center"
  },
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
    align: "center"
  },
  {
    title: 'Zoom Room',
    key: 'room',
    render: (text, record) => {
      return (
        <Space size="middle">
          {record.zoom_account.email}
        </Space>
      )
    },
    width:200,
    align: "center"
  },
  {
    title: 'Ongoing',
    key: 'action',
    render: (text, record) => {
      let start = moment(record.start_time);
      let end = moment(record.end_time);
      let now = moment();
      if (now.isBetween(start, end)) {
        return (
          <Button size="small"  type="primary">Enter</Button>
        )
      }else{
        return (
          <Button disabled size="small"  style={{color:"#1890ff"}}>Enter</Button>
        )
      }
    },
    width:100,
    align: "center"
  }
];

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
}

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height:calc(100vh - 84px);
`;

const MainPage = (props) => {
  const [classes, setClasses] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [class_idx, setClassIdx] = useState(null);
  const [q, setQ] = useState(null);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);

  console.log(props.selectedItem.idx);

  const { data } = useQuery(ZOOM_LIST, {
    variables: { class_idx, q, page, take },
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: { },
  });

  useEffect(() => {
    if(data?.zoomScheduleList) {
      setSchedules(data?.zoomScheduleList.zoom_schedule)
      setTotal(data?.zoomScheduleList.total)
    }

    if(classQuery?.data?.classList) {
      setClasses([{idx:null, name:"select class"}, ...classQuery?.data?.classList])
    }
  }, [data, classQuery]);

  return (
    <>
      <Col className="zoom-wrapper-main" span={24} style={{textAlign: "right", margin:0}}>
        <MainBlock className="zoom-wrapper-mainblock">

          <Row  gutter={[24, 16]} className="zoom-top-head">
            <Col className="zoom-top-title" span={13} style={{textAlign: "left"}}>
              <Title level={4}>Zoom Class Management</Title>
            </Col>
            <Col className="zoom-top-rightform" span={11} style={{textAlign: "left", display:"flex", justifyContent:"flex-end"}}>

              <Form.Item label="Class" className="zoom-top-select">
                <Select
                  style={{ width: 150, color:"#065aa9" }}
                  value={class_idx}
                  placeholder="Select a Class"
                  optionFilterProp="children"
                  onChange={(idx) => setClassIdx(idx)}
                >
                  {classes.map((item) => <Select.Option key={item.idx} value={item.idx}>{item.name}</Select.Option>)}
                </Select>
              </Form.Item>
              <Link className="zoom-top-addlink" to="/zoom/manage/add"><Button style={{color:"#289428"}}>Add +</Button></Link>
            </Col>
          </Row>
          <CustomTable
            className="zoomtablelist"
            dataSource={schedules}
            columns={columns}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: take, total }}
            size="small"
            color="#edf3fb"
            onChange={(a) => setPage(a.current - 1)}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => onHandleRowClick(record, rowIndex)
              };
            }}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default MainPage;
