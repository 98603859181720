import React, { useState, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useMutation, useQuery, gql } from '@apollo/client';
import TableTransfer from 'src/components/common/TableTransfer';
//import CustomTable from 'src/components/common/CustomTable';
import { CREATE_CHAT_ROOM } from 'src/operations/mutations/messenger';
import { GET_TEACHER_LIST } from 'src/operations/queries/messenger';
import { userInfoVar } from 'src/apollo/cache';

const TeacherListModal = ({ visible, onCancel, onChatStart, student_idx }) => {
  const [selectKeys, setSelectKeys] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [direction, setDirection] = useState([]);
  const [createRoom, { loading: loadingCreate }] = useMutation(CREATE_CHAT_ROOM, {
    onCompleted(data) {
      if (data) {
        console.log('CREATE_CHAT_ROOM onCompleted', data);
        onChatStart(data);
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });
  // const handleStart = (user_idx) => {
  //   const chat_user_idx_list = [parseInt(user_idx), parseInt(student_idx)];
  //   createRoom({ variables: { chat_user_idx_list } });
  // };
  const columns = [
    {
      title: '반이름',
      align: 'center',
      dataIndex: 'className',
      key: 'className',
    },
    {
      title: '이름',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '선생님ID',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
    },
  ];

  const { data } = useQuery(GET_TEACHER_LIST, {
    variables: { student_idx: parseInt(student_idx) },
  });

  const teachers = useMemo(() => {
    const list = [];
    if (data?.classTeachersForStudent) {
      for (let i = 0; i < data.classTeachersForStudent.length; i++) {
        const className = data?.classTeachersForStudent[i]?.class?.name;
        const users = data.classTeachersForStudent[i].class.class_teacher.map((item, index) => {
          console.log('item', item);
          return {
            ...item.user,
            className,
            key: item.user.idx,
          };
        });
        list.push(...users);
      }
    }

    return list;
  }, [data]);

  const onChange = (targetKeys, direction) => {
    setTargetKeys(targetKeys);
    setSelectKeys(targetKeys);
    setDirection(direction);
  };
  const handleStartChat = () => {
    if (!direction || !selectKeys.length) {
      Modal.warning({
        title: '선생님을 선택해 주세요.',
        okText: '확인',
      });
      return;
    }
    const user_idx = userInfoVar()?.idx;

    const chat_user_idx_list = [...targetKeys, parseInt(user_idx)];
    createRoom({ variables: { chat_user_idx_list } });
  };

  return (
    <Modal
      visible={visible}
      title={`선생님목록`}
      onCancel={onCancel}
      width={`50%`}
      footer={[
        <Button key="start" type="primary" onClick={handleStartChat}>
          시작
        </Button>,
        <Button key="back" onClick={onCancel}>
          닫기
        </Button>,
      ]}
    >
      {/* <CustomTable dataSource={teachers} columns={columns} pagination={{ pageSize: 5 }} size="small" color="#edf3fb" /> */}
      <TableTransfer
        //loading={getUserLoading}
        dataSource={teachers}
        titles={['', '']}
        targetKeys={targetKeys}
        //showSearch={true}
        onChange={onChange}
        filterOption={(inputValue, item) => item.userid.indexOf(inputValue) !== -1 || item.name.indexOf(inputValue) !== -1}
        leftColumns={columns}
        rightColumns={columns}
        locale={{ itemUnit: '명', itemsUnit: '명' }}
      />
    </Modal>
  );
};

export default TeacherListModal;
