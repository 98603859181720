import React, { useMemo, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Table, Typography, Col } from 'antd';
import { useSelector } from 'react-redux';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend } from 'recharts';
import { classStoreData } from 'src/operations/store';
import { USER_CLASS_LESSON_LIST, BOOK_STAGE_LIST_BY_BOOK_CODES, ALL_USER_CLASS_LESSION_WITH_BOOK_LIST } from 'src/operations/queries/study';

const isCountGrade = (stage_abbr) => {
  if (['w1', 'w2'].includes(stage_abbr)) {
    return false;
  }
  return true;
};

const StudyTable = styled(Table)`
  border-top: 3px solid #c5c5c5b3;
  border-right: 1px solid #c5c5c5b3;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #c5c5c5b3;
    border-top: 1px solid #c5c5c5b3;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #9b0000;
    color: #fff;
  }

  tr[class*='ant-table-row'] td[rowspan*='3']:first-child {
    background: #fbe5d6;
  }

  tr[class*='ant-table-row']:last-child td {
    background: #fbe5d6;
  }

  tbody td {
    border-left: 1px solid #c5c5c5b3;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media print {
    max-width: 700px;
    thead[class*='ant-table-thead'] th {
      padding: 3px;
      font-size: 0.5em;
    }
    tbody td {
      padding: 3px;
      font-size: 0.5em;
    }
  }
`;

const Title = styled.title`
  margin-bottom: 0.5em;
  color: #008080;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  border-top: 3px solid #9b0000;
  display: block;
  margin-top: 15px;
`;

const getPercentGrade = (stageGroupCode, stages, userStudyList) => {
  if (!userStudyList || userStudyList.length === 0) {
    return -1;
  }
  const findItem = stages.find((stage) => stage.group_code === stageGroupCode);
  if (!findItem) {
    return -1; //여기로 들어오면 안 되는 거임
  }
  const findItem2 = userStudyList.find((studyItem) => studyItem.stage_no === findItem.no);
  if (!findItem2) {
    return -1;
  }
  const { exam_total, exam_correct } = findItem2;
  if (!exam_total) {
    return 100;
  }

  const percentGrade = Math.round((100 * parseInt(exam_correct)) / parseInt(exam_total));
  return percentGrade;
};

const StudyCompleteTableLucid = ({ bookCode, class_idx, user_idx, book_codes = [], classBooks = [], study_range = [] }) => {
  const companyName = useSelector(classStoreData);
  const levels = useRef(new Set());

  const { data: dataStages } = useQuery(BOOK_STAGE_LIST_BY_BOOK_CODES, {
    skip: !book_codes.length,
    variables: { book_codes },
  });

  const { data: dataLessons } = useQuery(USER_CLASS_LESSON_LIST, {
    skip: !class_idx || !user_idx,
    variables: { class_idx: parseInt(class_idx), user_idx: parseInt(user_idx), book_codes, study_range },
    fetchPolicy: 'no-cache',
  });

  const { data: dataAllUsers, loading } = useQuery(ALL_USER_CLASS_LESSION_WITH_BOOK_LIST, {
    variables: { class_idx, book_codes, study_range },
    skip: !class_idx,
  });
  const columns = [
    {
      key: 'level',
      title: 'LEVEL',
      dataIndex: 'level',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index % 3 === 0) {
          obj.props.rowSpan = 3;
        }
        // These two are merged into above cell
        if (index % 3 === 1) {
          obj.props.rowSpan = 0;
        }
        if (index % 3 === 2) {
          obj.props.rowSpan = 0;
        }
        return obj;
      },
    },
    {
      key: 'subject',
      title: 'SUBJECT',
      dataIndex: 'subject',
      align: 'center',
    },
    {
      key: 'score',
      title: 'SCORE',
      dataIndex: 'score',
      align: 'center',
    },
    {
      key: 'achievement',
      title: 'ACHIEVEMENT',
      dataIndex: 'achievement',
      align: 'center',
      render: (value) => {
        return value + '%';
      },
    },
  ];

  const dataStudyComplete = useMemo(() => {
    if (dataLessons && dataStages) {
      const gradeData = dataLessons.getUserClassLessonList.map((item) => {
        const baseData = {};
        const lessonBookCode = item.book_lesson?.book?.code;
        let stageList = dataStages.bookStageListByBookCodes.filter((stage) => stage.book_code === lessonBookCode);

        for (const stageItem of stageList) {
          const groupCodes = stageItem.book_stage.map((stage) => stage.group_code);

          if (groupCodes instanceof Array && groupCodes.includes(item.book_lesson.stage_group_code)) {
            const grade = getPercentGrade(
              item.book_lesson.stage_group_code,
              stageItem.book_stage,
              item.user_study.filter((study) => study.user_lesson_idx),
            );
            baseData[stageItem.name_abbr] = grade;
          }
        }
        let doneStages = Object.values(baseData).filter((value) => value != -1);
        return {
          ...baseData,
          lesson_code: item.book_lesson.code,
          avg: Math.round((100 * parseInt(doneStages.reduce((a, b) => a + (b || 0), 0))) / parseInt(Object.keys(baseData).length * 100)),
          completion: Math.round((100 * parseInt(doneStages.length)) / parseInt(Object.keys(baseData).length)),
        };
      });
      return gradeData;
    }
    return [];
  }, [dataLessons, dataStages]);

  const dataStudyBooks = useMemo(() => {
    if (dataStudyComplete && classBooks) {
      let tmp = [];
      classBooks.forEach((classBook) => {
        const { book_code, book_sub } = classBook;
        let subject = book_sub && (book_sub[0]?.sub_title || book_sub[0]?.title);
        let level = subject.replace('_R', '').replace('_L&S', '').replace('_W&G', '');
        const lessons = dataStudyComplete.filter((item) => item.lesson_code.startsWith(book_code));
        const score = lessons.length ? lessons.reduce((a, b) => a + b.avg, 0) / lessons.length : 0;
        const achievement = lessons.length ? lessons.reduce((a, b) => a + b.completion, 0) / lessons.length : 0;

        tmp.push({
          level,
          subject,
          score: Math.round(score),
          achievement: Math.round(achievement),
        });
      });

      tmp.push({
        level: '',
        subject: 'Total Average',
        score: tmp.length ? Math.round(tmp.reduce((a, b) => a + b.score, 0) / tmp.length) : 0,
        achievement: tmp.length ? Math.round(tmp.reduce((a, b) => a + b.achievement, 0) / tmp.length) : 0,
      });

      return tmp;
    }

    return [];
  }, [dataStudyComplete, classBooks]);

  const classGrade = useMemo(() => {
    const list = dataAllUsers?.getAllUserClassLessonList;
    if (list && dataStages?.bookStageListByBookCodes) {
      const gradeData = list.map((item) => {
        const baseData = {};
        const lessonBookCode = item.book_lesson?.book?.code;
        let stageList = dataStages.bookStageListByBookCodes.filter((stage) => stage.book_code === lessonBookCode);

        for (const stageItem of stageList) {
          const groupCodes = stageItem.book_stage.map((stage) => stage.group_code);

          if (groupCodes instanceof Array && groupCodes.includes(item.book_lesson.stage_group_code)) {
            const grade = getPercentGrade(
              item.book_lesson.stage_group_code,
              stageItem.book_stage,
              item.user_study.filter((study) => study.user_lesson_idx),
            );
            baseData[stageItem.name_abbr] = grade;
          }
        }
        let doneStages = Object.values(baseData).filter((value) => value != -1);
        return {
          ...baseData,
          lesson_code: item.book_lesson.code,
          avg: Math.round((100 * parseInt(doneStages.reduce((a, b) => a + (b || 0), 0))) / parseInt(Object.keys(baseData).length * 100)),
          completion: Math.round((100 * parseInt(doneStages.length)) / parseInt(Object.keys(baseData).length)),
        };
      });
      return gradeData;
    }
    return [];
  }, [dataAllUsers, dataStages]);

  const dataClassStudyBooks = useMemo(() => {
    if (classGrade && classBooks) {
      let tmp = [];

      classBooks.forEach((classBook) => {
        const { book_code, book_sub } = classBook;
        let subject = book_sub && (book_sub[0]?.sub_title || book_sub[0]?.title);
        let level = subject.replace('_R', '').replace('_L&S', '').replace('_W&G', '');
        const lessons = classGrade.filter((item) => item.lesson_code.startsWith(book_code));
        const score = lessons.length ? lessons.reduce((a, b) => a + b.avg || 0, 0) / lessons.length : 0;
        const achievement = lessons.length ? lessons.reduce((a, b) => a + b.completion, 0) / lessons.length : 0;

        tmp.push({
          level,
          subject,
          score: Math.round(score),
          achievement: Math.round(achievement),
        });
      });

      tmp.push({
        level: '',
        subject: 'Total Average',
        score: tmp.length ? Math.round(tmp.reduce((a, b) => a + b.score, 0) / tmp.length) : 0,
        achievement: tmp.length ? Math.round(tmp.reduce((a, b) => a + b.achievement, 0) / tmp.length) : 0,
      });

      return tmp;
    }

    return [];
  }, [classGrade, classBooks]);

  const radarData = useMemo(() => {
    if (dataStudyBooks && dataClassStudyBooks) {
      return dataStudyBooks.map((item, key) => {
        const classData = dataClassStudyBooks[key];
        return { subject: item.subject, A: item.score, B: classData.score, fullMark: 100 };
      });
    }
    return [];
  }, [dataStudyBooks, dataClassStudyBooks]);

  const customTick = ({ payload, x, y, textAnchor, stroke, radius }) => {
    let { index, coordinate } = payload;
    const myData = dataStudyBooks[index];
    const classData = dataClassStudyBooks[index];
    const newY = coordinate < -230 ? y - 30 : y;
    const newX = coordinate > -45 ? x + 10 : x;
    const newXLine = coordinate > -45 ? newX : newX - 20;
    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <text
          radius={radius}
          stroke={stroke}
          x={newX}
          y={newY}
          className="recharts-text recharts-polar-angle-axis-tick-value"
          text-anchor={textAnchor}
        >
          <tspan x={newX} dy="0em">
            {payload.value}
          </tspan>
          <tspan x={newX} dy="1.2em">
            {myData.score}
          </tspan>
          <tspan x={newX} dy="1.2em">
            {classData.score}
          </tspan>
        </text>
        <path
          d={`M${newXLine},${newY + 10}L${newXLine - 10},${newY + 10}`}
          className="recharts-polar-angle-axis-tick-line"
          x={newX}
          y={newY}
          fill="none"
          stroke="#F1A78A"
          stroke-width="2"
        />
        <path
          d={`M${newXLine},${newY + 30}L${newXLine - 10},${newY + 30}`}
          className="recharts-polar-angle-axis-tick-line"
          x={newX}
          y={newY}
          fill="none"
          stroke="#D26E2A"
          stroke-width="2"
        />
      </g>
    );
  };
  //스마트이클래스는 성취도 그래프를 보여준다.(점수 기준임)
  return (
    <>
      <Col span={24}>
        <Title>● Online : Academic Performance</Title>
        <StudyTable columns={columns} dataSource={dataStudyBooks} pagination={false} />
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <RadarChart cx={250} cy={200} outerRadius={100} width={500} height={400} data={radarData} startAngle={0} endAngle={-360}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" tick={customTick} />
          <PolarRadiusAxis angle={0} domain={[0, 100]} />
          <Radar name="Personal Score" dataKey="A" stroke="#F1A78A" fill="transparent" fillOpacity={0.6} />
          <Radar name="Class Score" dataKey="B" stroke="#D26E2A" fill="transparent" fillOpacity={0.6} />
          <Legend />
        </RadarChart>
      </Col>
    </>
  );
};

export default StudyCompleteTableLucid;
