import React, { useState, useEffect, useRef, useCallback } from 'react';
import Iframe from '@trendmicro/react-iframe';
import { Stage, Layer, Line, Text } from 'react-konva';
import { SketchPicker } from 'react-color';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as tus from 'tus-js-client';
import { Col, Typography, Space, Checkbox, Select, Row, Button, message, Pagination, Input, Upload, Progress } from 'antd';
import {
  StarOutlined,
  VideoCameraOutlined,
  DeleteOutlined,
  UploadOutlined,
  EditOutlined,
  CloseOutlined,
  ClearOutlined,
  FormOutlined,
  HighlightOutlined,
  FontColorsOutlined,
  UndoOutlined,
  CameraOutlined,
  StopOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { useQueryProxy } from 'src/operations/proxy/user';
import { CREATE_VIDEOS } from 'src/operations/mutations/createFiles';
import { getCurrentToken } from 'src/utils';
import { USER_ME, GET_STUDENT_USER, GET_TEACHER_USER } from 'src/operations/queries/getUser';
import RecordRTC, { StereoAudioRecorder, MediaStreamRecorder, invokeSaveAsDialog } from 'recordrtc';
import FileManagerModal from 'src/components/Modal/FileManagerModal';
import VimeoVideo from 'src/components/common/VimeoVideo';

import moment from 'moment';
import { captureDisplayMedia, captureUserMedia } from 'src/utils';
import { useHistory } from 'react-router-dom';

const { Title } = Typography;
const { Search } = Input;

const MainBlock = styled.div`
  background: #fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
`;

const ListBlock = styled.div`
  padding: 10px;
  background: #fff;
  overflow-y: scroll;
`;

const ShadowBlock = styled.div`
  box-shadow: rgb(221, 221, 221) 2px 1px 5px 5px;
  border-radius: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

const PageBlock = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
`;

const HeadBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  background: #edf3fbc4;
`;

const MainPage = (props) => {
  const token = getCurrentToken();
  const history = useHistory();
  const [checkedList, setCheckedList] = useState(new Array(props.myList ? props.myList.length : 0).fill(false));
  const [selectAll, setSelectAll] = useState(false);
  const [activeSession, setActiveSession] = useState(false);
  const [isPenActive, setIsPenActive] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoUploading, setVideoUploding] = useState(0);
  const [tool, setTool] = React.useState('pen');
  const [penColor, setPenColor] = React.useState('#df4b26');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [userData, setUserData] = useState(null);
  const [classList, setClassList] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ idx: 0 });
  const [isPublic, setIsPublic] = useState(false);
  const [classIdx, setClassIdx] = useState(null);
  const [q, setQ] = useState('');
  const [currentUrl, setCurrentUrl] = useState(window.location.origin + '/');
  const [lines, setLines] = React.useState([]);
  const isDrawing = React.useRef(false);
  const currentBlob = React.useRef(null);

  const recorderScreenStreams = useRef(null);
  const recorderCameraStreams = useRef(null);
  const recorderAudioStreams = useRef(null);
  const recordScreen = useRef(null);
  const videoPreviewRef = useRef(null);

  if (props.onChangeTitle) {
    props.onChangeTitle('V-Tube');
  }

  const [createVideos] = useMutation(CREATE_VIDEOS, {
    onCompleted(data) {
      message.success(`New Video has been added`);
    },
  });

  const onDataAvailableCallback = useCallback(
    (blob) => {
      console.log('blob', blob);
      videoPreviewRef.current.src = videoPreviewRef.current.srcObject = null;
      videoPreviewRef.current.src = URL.createObjectURL(blob);
    },
    [recordScreen],
  );
  const { data } = useQueryProxy(USER_ME, { variables: { token } });

  // React.useEffect(() => {
  //   if (videoPreviewRef.current && recorderScreenStreams.current) {
  //     videoPreviewRef.current.srcObject = recorderScreenStreams.current;
  //   }
  // }, [recorderScreenStreams.current]);

  const onHandleRecordingClick = (data = '') => {
    if (window.Android) {
      window.Android.startRecordingActivity(data);
    } else {
      console.log('not android');
      if (!recordScreen.current) {
        captureDisplayMedia(
          {
            video: true,
            audio: { echoCancellation: true },
          },
          async (screen) => {
            console.log('screen', screen);
            recorderScreenStreams.current = screen;
            console.log(screen.getTracks());

            captureUserMedia(
              {
                video: {
                  facingMode: 'user',
                },
              },
              async (camera) => {
                console.log('camera', camera);
                videoPreviewRef.current.srcObject = camera;
                recorderCameraStreams.current = camera;

                // camera.width = 320;
                // camera.height = 240;
                // camera.top = screen.height - camera.height;
                // camera.left = screen.width - camera.width;

                captureUserMedia(
                  {
                    audio: { echoCancellation: true },
                  },
                  async (audio) => {
                    console.log('audio', audio);
                    recorderAudioStreams.current = audio;
                    // console.log(audio.getTracks());
                    // screen.addTrack(audio.getTracks()[0]);
                    // console.log(screen.getTracks());

                    screen.width = window.screen.width;
                    screen.height = window.screen.height;
                    screen.fullcanvas = true;
                    recordScreen.current = RecordRTC([screen, audio], {
                      type: 'video',
                      mimeType: 'video/webm',
                      sampleRate: 48000,
                      desiredSampRate: 16000,
                      numberOfAudioChannels: 1,
                    });
                    setActiveSession(true);
                    recordScreen.current.startRecording();
                  },
                );
              },
            );
          },
        );
      } else {
        stopRecording();
      }
    }
  };

  const handleUploadVideos = async () => {
    if (!currentBlob.current) return false;
    let file = new File([currentBlob.current], `${videoTitle}.mp4`, { type: 'video/mp4' });
    return new Promise(async (resolve, reject) => {
      setVideoUploding(1);
      try {
        let vimeoData = { upload: { approach: 'tus', size: file.size }, privacy: { embed: 'public' }, name: file.name };

        const rawResponse = await fetch('https://api.vimeo.com/me/videos', {
          method: 'POST',
          headers: {
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
            'Content-Type': 'application/json',
            Authorization: 'bearer 5ab37f96092e3c815e8b4d58d711c71f',
          },
          body: JSON.stringify(vimeoData),
        });

        const tusUploadIntent = await rawResponse.json();
        var upload = new tus.Upload(file, {
          uploadUrl: tusUploadIntent?.upload.upload_link,
          headers: {
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
            Authorization: 'bearer 5ab37f96092e3c815e8b4d58d711c71f',
          },
          onError: function (error) {
            console.log('Failed because: ' + error);
            setVideoUploding(0);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setVideoUploding(percentage);
          },
          onSuccess: function () {
            console.log('Download %s from %s', upload.file.name, upload.url);
            setVideoUploding(0);
          },
        });

        // Start the upload by default
        upload.start();
        createVideos({
          variables: {
            title: videoTitle,
            video_id: tusUploadIntent.uri,
            data: JSON.stringify(tusUploadIntent),
            is_shared: false,
            class_idx: classIdx,
            is_public: isPublic,
            type: 1,
          },
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  let getUserType = GET_STUDENT_USER;

  if (data?.me && data?.me?.type !== '0' && data?.me?.type !== 0) {
    getUserType = GET_TEACHER_USER;
  }

  useQuery(getUserType, {
    variables: {},
    onCompleted: (data) => {
      if (data?.getUserData) {
        let tempData = data?.getUserData;
        if (tempData.class_teacher) {
          tempData = Object.assign({}, tempData, { classes: tempData.class_teacher.map((i) => i.class) });
        } else {
          tempData = Object.assign({}, tempData, { classes: tempData.class_student.map((i) => i.class) });
        }
        setUserData(tempData);
      }
    },
  });

  useEffect(() => {
    if (selectedItem) {
      switch (selectedItem.idx) {
        case 'general':
          setClassIdx(null);
          setIsPublic(true);
          break;
        case 'private':
          setClassIdx(null);
          setIsPublic(false);
          break;
        default:
          setIsPublic(false);
          if (selectedItem.idx) setClassIdx(parseInt(selectedItem.idx.substring(4)));
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (userData) {
      let tempList = [{ idx: `general`, name: 'General' }];
      tempList = tempList.concat(userData.classes.map((item) => ({ idx: `cal-${item.idx}`, name: item.name })));
      tempList = tempList.concat(classList.map((item) => ({ idx: `cal-${item.idx}`, name: item.name })));
      tempList.push({ idx: `private`, name: 'Private' });
      setOptionsList(tempList);
      setSelectedItem({ idx: `private`, name: 'Private' });
    }
  }, [userData, classList, setOptionsList, setSelectedItem]);

  useEffect(() => {
    if (!activeSession && recorderScreenStreams.current) {
      recorderScreenStreams.current.getTracks().forEach((track) => track.stop());
      if (recorderScreenStreams.screen && recorderScreenStreams.screen.getVideoTracks) {
        recorderScreenStreams.screen.getVideoTracks().forEach((track) => track.stop());
        recorderScreenStreams.screen.stop();
        recorderScreenStreams.screen = null;
      }
    }

    if (!activeSession && recorderCameraStreams.current) {
      recorderCameraStreams.current.getTracks().forEach((track) => track.stop());
      recorderCameraStreams.current = null;
    }

    if (!activeSession && recorderAudioStreams.current) {
      recorderAudioStreams.current.getTracks().forEach((track) => track.stop());
      recorderAudioStreams.current = null;
    }
  }, [recorderScreenStreams.current, recorderCameraStreams.current, recorderAudioStreams.current, activeSession]);

  const stopRecording = () => {
    if (recordScreen.current) {
      // recordScreen.current.screen.stop();
      recordScreen.current.stopRecording(() => {
        videoPreviewRef.current.src = videoPreviewRef.current.srcObject = null;
        videoPreviewRef.current.src = recordScreen.current.toURL();
        // invokeSaveAsDialog(recordScreen.current.getBlob(), 'video.webm');
        currentBlob.current = recordScreen.current.getBlob();
        if (recordScreen.current) {
          recordScreen.current.destroy();
          recordScreen.current = null;
          setActiveSession(false);
        }
      });
    }
  };

  useEffect(() => {
    if (videoFile && videoFile.length) {
      console.log(videoFile);
      props.createVideo({
        variables: {
          title: videoFile[0]?.videos.title,
          video_id: videoFile[0]?.videos.video_id,
          data: videoFile[0]?.videos.data,
          is_shared: false,
          class_idx: props.classIdx,
          is_public: props.isPublic,
          type: 1,
        },
      });
    }
  }, [videoFile]);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y], color: penColor }]);
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(props.myList.length).fill(true));
      setSelectAll(true);
    } else {
      setSelectAll(false);
      setCheckedList(new Array(props.myList.length).fill(false));
    }
  }

  const ListHeader = ({ text, isShowAll }) => (
    <HeadBlock>
      <Title level={5}>{text}</Title>
      {isShowAll ? (
        <Checkbox checked={selectAll} onChange={onCheckAllChange}>
          Select All
        </Checkbox>
      ) : null}
    </HeadBlock>
  );
  const handleSelectedValueChange = (e, v) => {
    setSelectedItem({ idx: e, name: v.children });
  };

  const handleChangeContent = (e) => {
    setCurrentUrl((prev) => {
      switch (e) {
        case 0:
          return window.location.origin + '/folder';
        case 1:
          return 'http://demo-ebook.cloubot.com/ebookSneakers.php';
        case 2:
          return 'https://epublic-phonics.cloubot.com/';
        case 3: {
          return `https://culp-elibrary-lms.cloubot.com/elibrary/bookList?id=${data?.me?.id}&name=${data?.me?.name}&token=${token}`;
        }
        case 4:
          return 'https://www.getepic.com/sign-in/parent';
        default:
          return window.location.origin + '/';
      }
    });
  };

  return (
    <>
      <Col span={24} style={{ textAlign: 'right', margin: 0 }}>
        <Row gutter={[24, 8]} style={{ margin: 0 }}>
          <Col
            span={5}
            style={{
              borderRadius: '10px',
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              paddingTop: '20px',
              paddingBottom: '20px',
              background: '#f3eeee',
              marginTop: '10px',
            }}
            className="vtube-mainblock-sec-a"
          >
            <Row gutter={[24, 8]} style={{ marginTop: '10px', marginBottom: '200px' }}>
              <Col className="vtube-title-sec" span={10} xs={6} md={10} style={{ textAlign: 'left' }}>
                <Title level={5}>User</Title>
              </Col>
              <Col
                className="fieldsection-vtube"
                span={14}
                xs={18}
                md={14}
                style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
              >
                {data?.me?.name}({data?.me?.id})
              </Col>
              <Col className="vtube-title-sec" span={10} xs={6} md={10} style={{ textAlign: 'left' }}>
                <Title level={5}>Title</Title>
              </Col>
              <Col
                className="fieldsection-vtube"
                span={14}
                xs={18}
                md={14}
                style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Input value={videoTitle} onChange={(e) => setVideoTitle(e.target.value)} />
              </Col>
              {/* <Col className="vtube-title-sec" span={10} xs={6} md={10} style={{ textAlign: 'left' }}>
                <Title level={5}>Subject</Title>
              </Col>
              <Col
                className="fieldsection-vtube"
                span={14}
                xs={18}
                md={14}
                style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Input />
              </Col>
              <Col className="vtube-title-sec" span={10} xs={6} md={10} style={{ textAlign: 'left' }}>
                <Title level={5}>Meta</Title>
              </Col>
              <Col
                className="fieldsection-vtube"
                span={14}
                xs={18}
                md={14}
                style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Input.TextArea> </Input.TextArea>
              </Col> */}
              <Col className="vtube-title-sec" span={10} xs={6} md={10} style={{ textAlign: 'left' }}>
                <Title level={5}>Folder</Title>
              </Col>
              <Col
                className="fieldsection-vtube"
                span={14}
                xs={18}
                md={14}
                style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
              >
                <Select value={selectedItem.idx} style={{ width: '100%' }} onChange={handleSelectedValueChange}>
                  {optionsList.map((item) => (
                    <Select.Option key={item.idx} value={item.idx}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[24, 8]} style={{ padding: '10px 0' }}>
              <Col span={24}>
                <video ref={videoPreviewRef} controls autoPlay playsInline width={'100%'} />
              </Col>
              <Col span={24}>
                <PageBlock>
                  <Button
                    onClick={() => onHandleRecordingClick(JSON.stringify({ options: optionsList }))}
                    type="primary"
                    size="large"
                    style={{ background: 'red' }}
                    shape="circle"
                    icon={<StopOutlined />}
                  />
                  <Button
                    type="primary"
                    size="large"
                    className={`vtube-btn-upload ${activeSession ? 'blink_me' : ''}`}
                    style={{ padding: '0px' }}
                    onClick={() => onHandleRecordingClick(JSON.stringify({ options: optionsList }))}
                    shape="circle"
                  >
                    <img src="/images/stop-icon.png" style={{ height: '100%' }} />
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    style={{ background: 'lightseagreen' }}
                    onClick={handleUploadVideos}
                    shape="circle"
                    icon={<UploadOutlined />}
                  />
                </PageBlock>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                  <Button
                    type="primary"
                    size="large"
                    style={{ border: '2px solid lightseagreen', background: 'white', color: 'black', borderRadius: '10px' }}
                    onClick={() => {
                      history.push('/recording');
                    }}
                  >
                    Close
                  </Button>
                </div>
              </Col>
              {videoUploading ? <Progress percent={videoUploading} /> : null}
            </Row>
            <Row gutter={[24, 8]}>
              <Col span={24}>
                <Select defaultValue={0} style={{ width: '100%', marginBottom: '20px' }} onChange={handleChangeContent}>
                  <Select.Option key={0} value={0}>
                    Culp
                  </Select.Option>
                  <Select.Option key={1} value={1}>
                    Sneakers
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    Phonics
                  </Select.Option>
                  <Select.Option key={3} value={3}>
                    Elibrary
                  </Select.Option>
                  <Select.Option key={4} value={4}>
                    Readers
                  </Select.Option>
                </Select>
              </Col>
            </Row>
          </Col>

          <Col span={19} style={{ textAlign: 'left', marginTop: '10px' }} className="vtube-mainblock-sec-a">
            <Iframe
              src={currentUrl}
              style={{ height: 'calc(100vh - 80px)', maxWidth: '100%', overflow: 'scroll' }}
              sandbox={{
                allowTopNavigation: true,
                allowScripts: true,
                allowDownloads: true,
                allowSameOrigin: true,
                allowModals: true,
                allowPopups: true,
                allowForms: true,
              }}
            />
          </Col>
        </Row>
        {isPenActive ? (
          <>
            <Stage
              width={window.innerWidth}
              height={window.innerHeight}
              onMouseDown={handleMouseDown}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
              style={{ position: 'fixed', top: 0, left: 0, zIndex: 9000 }}
            >
              <Layer>
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke={line.color}
                    strokeWidth={5}
                    tension={0.5}
                    lineCap="round"
                    globalCompositeOperation={line.tool === 'eraser' ? 'destination-out' : 'source-over'}
                  />
                ))}
              </Layer>
            </Stage>
          </>
        ) : null}

        <div style={{ position: 'absolute', bottom: 10, right: 10, zIndex: 10000 }}>
          {isPenActive ? (
            <>
              {showColorPicker ? <SketchPicker color={penColor} onChangeComplete={(color) => setPenColor(color.hex)} /> : null}
              <Button
                type="primary"
                size="large"
                style={{ background: penColor, marginLeft: 10 }}
                shape="circle"
                onClick={() => setShowColorPicker(!showColorPicker)}
                icon={<FontColorsOutlined />}
              />
              <Button
                type="primary"
                size="large"
                style={{ background: '#838383', marginLeft: 10 }}
                shape="circle"
                onClick={() => setLines(lines.slice(0, -1))}
                icon={<UndoOutlined />}
              />
              <Button
                type="primary"
                size="large"
                style={{ background: '#e26688', marginLeft: 10 }}
                shape="circle"
                onClick={() => setLines([])}
                icon={<ClearOutlined />}
              />
              <Button
                type="primary"
                size="large"
                style={{ background: '#66cbe2', marginLeft: 10 }}
                shape="circle"
                onClick={() => setTool(tool === 'eraser' ? 'pen' : 'eraser')}
                icon={tool === 'eraser' ? <EditOutlined /> : <HighlightOutlined />}
              />
            </>
          ) : null}
          <Button
            type="primary"
            size="large"
            style={{ background: '#6666e2', marginLeft: 10 }}
            shape="circle"
            onClick={() => setIsPenActive(!isPenActive)}
            icon={isPenActive ? <CloseOutlined /> : <FormOutlined />}
          />
        </div>
      </Col>
    </>
  );
};

export default MainPage;
