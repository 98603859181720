import React from 'react';
import { Table } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { PrintDummy } from 'src/components/common/Styles';

const SmartEclassOrderListTable = ({ loading, dataSource, checkRowList, setcheckRowList, prinfRef, columns, isRowSelection = true }) => {
  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };
  return (
    <>
      <CustomTable
        loading={loading}
        className="orderadmin-table-table"
        pagination={{ pageSize: 12, position: ['bottomCenter'], showSizeChanger: false }}
        dataSource={dataSource}
        columns={columns}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
        rowSelection={isRowSelection ? rowSelection : false}
      />
      <PrintDummy ref={prinfRef}>
        <Table pagination={false} dataSource={dataSource} columns={columns} size="small" />
      </PrintDummy>
    </>
  );
};

export default SmartEclassOrderListTable;
