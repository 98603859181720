import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Carousels from 'src/components/LevelUp/Carousel';
import { PoweroffOutlined } from '@ant-design/icons';
import FullScreenButton from 'src/components/common/FullScreenButton';
import { Select, Input, Spin, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import { getLixileScoreObject } from 'src/utils/index';
import LeftMenu from 'src/components/LevelUp/LeftMenu';
import { EXTERNAL_STUDY_BOOK } from 'src/operations/queries/externalStudy';
import { useQuery } from '@apollo/client';
import { GET_STUDENT_USER, GET_TEACHER_USER } from 'src/operations/queries/getUser';
import queryString from 'query-string';
import _ from 'lodash';
const { Text } = Typography;
const {  CarouselItem } = Carousels;
const { Option } = Select;
const { Search } = Input;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffa700;
`;
const Header = styled(RowFlex)`
  justify-content: start;
  align-items: center;
  height: 75px;
  padding: 16px 65px 16px 45px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #fff;
  /* background: #ed7d31; */
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
const MainWrapper = styled(RowFlex)`
  height: calc(100vh - 175px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1200px;
  /* max-height: 680px; */
  margin: 0 auto;
  background-color: #ffda00;
  padding: 24px 20px;
  @media (max-width: 1280px) {
    width: 1000px;
    min-width: 1000px;
    min-height: 620px;
  }
`;
const RightBox = styled(ColumnFlex)`
  padding: 0 15px;
  align-items: center;
  width: 100%;
  @media (max-width: 1280px) {
    padding-left: 5px;
  }
`;
const BookWrapper = styled(ColumnFlex)`
  width: 100%;
  height: calc(100vh - 275px);
  overflow-y: scroll;
  padding: 0 30px;
  ::-webkit-scrollbar {
    width: 7px;
    height: 8px;
    background-color: #fff;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ed8c3d;
    border-radius: 4px;
  }
  @media (max-width: 1280px) {
    padding: 0 19px;
    min-height: 500px;
  }
`;
const SearchBox = styled(Search)`
  width: 300px;
  padding: 10px;
`;
const BottomShadow = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  box-shadow: 3px 8px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
`;

const ImgButton = styled.img`
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export default function LevelUpSearch() {
  const { keyword } = queryString.parse(window.location.search);
  const history = useHistory();
  const [level, setLevel] = useState(null);
  const [keyWord, setKeyWord] = useState('');
  const [filteredbook, setFilteredbook] = useState([]);
  const [error, setError] = useState(null);
  const leveldata = useLocation()?.state?.level;
  const {type,company_name} = userInfoVar();
  let getUserType = GET_STUDENT_USER;
  const [userData, setUserData] = useState(null);
  if (userInfoVar() && userInfoVar()?.type !== '0' && userInfoVar()?.type !== 0) {
    getUserType = GET_TEACHER_USER;
  }

  useQuery(getUserType, {
    variables: {},
    onCompleted: (data) => {
      if (data?.getUserData) {
        let tempData = data?.getUserData;
        if (tempData.class_teacher) {
          tempData = Object.assign({}, tempData, { classes: tempData.class_teacher.map((i) => i.class) });
        } else {
          tempData = Object.assign({}, tempData, { classes: tempData.class_student.map((i) => i.class) });
        }
        setUserData(tempData);
      }
    },
  });
  const levelForHS = useMemo(() => {
    let lv = null;
    if (userData) {
      if (parseInt(type) === 0 && company_name === 'educloud') {
        lv = userData.class_student[0]?.class?.levelup_level
      }
      setLevel(lv)
    }
    return lv;
  }, [userData]);
  const handleInputChange = (e) => {
    setKeyWord(e.target.value);
  };
  useEffect(() => {
    if (leveldata) {
      setLevel(leveldata);
    }
  }, [leveldata]);
  const setMxLevel = (lexile) => {
    const mx = getLixileScoreObject(lexile, 'A-1');
    return mx;
  };
  const setLexileValue = (lexile) => {
    if (lexile.includes('BR')) {
      return 0;
    } else if (lexile.includes('NP')) {
      return 1000;
    } else {
      return parseInt(lexile);
    }
  };

  const {
    data: userBook,
    loading,
    refetch,
  } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      type: 'levelup',
      q: keyWord,
      level: level !==null ? `${level < 1 ? 'PH' : level}` : null,
    },
    skip: keyWord?.length < 2,
    fetchPolicy: 'no-cache',
  });
  // console.log(level,'level')
  useEffect(() => {
    try {
      let list = userBook?.getExternalUserBook?.map((e, i) => {
        e.no = i + 1;
        e.lexileVal = e.lexile_val ? e.lexile_val : setLexileValue(e.lexile);
        e.mxObj = setMxLevel(e.lexileVal);
        e.level = e.mxObj?.mx;
        return e;
      });
      setFilteredbook(list);
    } catch (e) {
      setError(e);
    }
  }, [userBook]);
  useEffect(() => {
    if (keyword && keyword.length > 1) {
      setKeyWord(keyword);
    }
  }, [keyword]);

  const chunkData = useMemo(() => {
    if (filteredbook) {
      let sortedList = _.sortBy(filteredbook, ['lexile_val', 'title']);
      const tmp = [];
      for (let i = 0; i < sortedList.length; i += 5) {
        const chunk = sortedList.slice(i, i + 5);
        tmp.push(chunk);
      }
      return tmp;
    } else {
      return [];
    }
  }, [filteredbook]);

  const handleSearch = (e) => {
    if (e === '' || e.length === 0) {
      setKeyWord('');
    } else if (e.length === 1) {
      alert('Please write a search term of at least 2 characters.');
    } else {
      setKeyWord(e);
    }
  };
  return (
    <>
      {' '}
      <Wrapper>
        <Header>
          <div
            onClick={() => {
              history.push('/level-up');
            }}
            style={{ cursor: 'pointer' }}
          >
            {' '}
            <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} alt="level up logo" />
          </div>
          <HeadBox background="transparent" color={'#fff'} style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px' }}>
            <FullScreenButton
              element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
              activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
            />
            <span style={{ color: '#999', marginLeft: 5, fontSize: '15px' }}>
              <span className="username">
                {userData?.name.length > 5 ? `${userData?.name.slice(0, 5)}...` : userData?.name}{' '}
                <Text strong>({userData?.id.length > 9 ? `${userData?.id.slice(0, 9)}...` : userData?.id})</Text>
              </span>
            </span>
            <CloseButton
              onClick={() => {
                history.push('/level-up');
              }}
            >
              <PoweroffOutlined />
            </CloseButton>
          </HeadBox>
        </Header>
        <MainWrapper>
          <LeftMenu level={level} setLevel={setLevel} isSearch={true} myLevel={parseInt(type)!==0?-2:levelForHS} isStudent={parseInt(type)===0}/>
          <RightBox>
            <SearchBox
              placeholder={'Search the Book'}
              allowClear
              enterButton
              value={keyWord}
              //   onChange={onChangeKeyword}
              onChange={handleInputChange}
              onSearch={handleSearch}
            />
            <BookWrapper>
              {loading ? (
                <Spin style={{ margin: '10px' }} size="large" />
              ) : (
                filteredbook&& filteredbook.length>0?
                chunkData.map((chunk, idx) => (
                  <ColumnFlex key={idx}>
                    <RowFlex style={{ alignItems: 'end', justifyContent: 'flex-start', padding: '0 20px' }}>
                      {chunk.map((e, i) => (
                        <CarouselItem key={i} data={e} />
                      ))}
                    </RowFlex>
                    <BottomShadow />
                  </ColumnFlex>
                )):<RowFlex style={{paddingTop:'40px', width:'100%', alignItems:'center',justifyContent:'center',fontSize:'25px'}}>No Results</RowFlex>
              )}
            </BookWrapper>
          </RightBox>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
