import React from "react";

const PageNum = ({num, total, style={ position: 'absolute', top: '10px', right: '10px',fontWeight:'bold',fontSize:'1.3rem' }}) => {
	return (
		// <div className="page_num" style={style}>
		// 	<span>{num}</span> / <span>{total}</span>
		// </div>

		<p className="page_num" style={style}>
			<span>{Math.min(num, total)}</span>/<span>{total}</span>
		</p>
	);
};

export default PageNum;
