import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { getCurrentToken } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import StarWordsOpenModal from 'src/components/Modal/StarWordsOpenModal';

const TodoCategory = styled.div`
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  padding: 0;
`;

//현재 진행 중인 반의 책을 보여주는 걸로 하자 일단은..
const StarWordsButton = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const baseApi = 'starwords://login';
  const token = getCurrentToken();

  const linkUrl = useMemo(() => {
    return `${baseApi}?id=${userInfoVar()?.idx}&name=${userInfoVar()?.name}&token=${token}`;
  }, [token]);
  const handleClicked = (e) => {
    e.preventDefault();
    setUrl(linkUrl);
    setIsModalOpen(true);
    // window.location.href = linkUrl;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {props?.type === 'link' ? (
        <a href="#" onClick={handleClicked} style={{ color: 'white' }} target="_blank" rel="noreferrer">
          {props?.text || 'Star Words'}
        </a>
      ) : (
        <TodoCategory style={{ backgroundColor: props?.backgroundColor }} onClick={handleClicked}>
          {props?.text || 'Star Words'}
        </TodoCategory>
      )}
      {isModalOpen && <StarWordsOpenModal url={url} visible={isModalOpen} handleClose={handleCloseModal} />}
    </>
  );
};

export default StarWordsButton;
