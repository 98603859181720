import { gql } from '@apollo/client';

export const CREATE_CHAT_ROOM = gql`
  mutation createChatRoom($chat_user_idx_list: [Int!]!) {
    createChatRoom(chat_user_idx_list: $chat_user_idx_list) {
      idx
      subject
      lastest
    }
  }
`;

export const MESSAGE_ADD = gql`
  mutation createChatMessage(
    $chat_user_idx: Int!
    $chat_room_idx: Int!
    $message: String!
  ) {
    createChatMessage(
      chat_user_idx: $chat_user_idx
      chat_room_idx: $chat_room_idx
      message: $message
    ) {
      idx
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation updateMessageReadYN($chat_room_idx: Int!, $chat_user_idx: Int!) {
    updateMessageReadYN(
      chat_room_idx: $chat_room_idx
      chat_user_idx: $chat_user_idx
    )
  }
`;
