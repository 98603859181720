import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Modal, Form, Input, Button } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';
import { phoneNumberCheck } from 'src/utils';
import { useForm } from 'src/utils/hooks';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const FcModal = ({ visible, handleCancel, popupMode }) => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState('');

  const { onChange, onSubmit, values } = useForm(formCallback, {
    name: '',
    phone: '',
    address: '',
    fax: '',
    userId: '',
    userPassword: '',
  });

  const [isUserId, { data }] = useLazyQuery(queries.getUser.USER_EXIST_CHECK);

  const [createCompanyFc, { loading }] = useMutation(mutations.company.CREATE_COMPANY_FC, {
    variables: {
      fc_company_info: values,
    },

    update(proxy, result) {
      const data = proxy.readQuery({
        query: queries.company.GET_COMPANY_LIST,
      });

      proxy.writeQuery({
        query: queries.company.GET_COMPANY_LIST,
        data: {
          companyList: [...data.companyList, result.data.createCompanyFc],
        },
      });
    },
    onCompleted(data) {
      if (data) {
        openNotification('등록 완료!');
        handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }

    onChange(e);
  };

  function formCallback() {
    if (popupMode === 'create') {
      createCompanyFc();
    } else {
    }
  }

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }

    form.setFieldsValue({
      phone: phone ? phone : undefined,
    });
  }, [form, onChange, phone]);

  return (
    <>
      <Modal
        entered
        title={`FC 관리`}
        visible={visible}
        onCancel={handleCancel}
        width={'70%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
        >
          <FormField hasFeedback={true} title="업체명">
            <FormItem name="name" rules={[{ required: true, message: '업체명을 입력해 주세요.' }]} hasFeedback>
              <Input name="name" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="연락처">
            <FormItem name="phone" rules={[{ required: false }]} hasFeedback>
              <Input name="phone" onChange={(e) => handleChange(e)} maxLength={13} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="아이디">
            <FormItem
              name="userId"
              rules={[
                { required: true, message: '아이디를 입력하세요.' },
                {
                  validator: async (rule, value) => {
                    isUserId({
                      variables: {
                        userId: value,
                      },
                    });

                    if (data?.isUserId.idx) {
                      return Promise.reject([rule.message]);
                    }
                  },
                  message: '이미 존재하는 ID 입니다.',
                },
              ]}
              hasFeedback
            >
              <Input name="userId" onChange={onChange} maxLength={13} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="비밀번호">
            <FormItem
              name="userPassword"
              rules={[
                { required: true, message: '비밀번호를 입력하세요.' },
                {
                  pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
                  validator: async (rule, value) => {
                    if (value.match(rule.pattern) !== null) {
                      return Promise.reject([rule.message]);
                    }
                  },
                  message: '한글은 입력할 수 없습니다.',
                },
              ]}
              hasFeedback
            >
              <Input.Password name="userPassword" onChange={onChange} maxLength={20} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="주소">
            <FormItem name="address" rules={[{ required: false }]} hasFeedback>
              <Input name="address" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="Fax">
            <FormItem name="fax" rules={[{ required: false }]} hasFeedback>
              <Input name="fax" onChange={onChange} />
            </FormItem>
          </FormField>
        </Form>
      </Modal>
    </>
  );
};

FcModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default FcModal;
