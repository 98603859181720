import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Badge,Radio } from 'antd';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_CLASSS_LESSONS_FOR_HOMEWORK } from 'src/operations/queries/getClass';
import { Link } from 'react-router-dom';
import { userInfoVar } from 'src/apollo/cache';
import UseHistoryState from 'src/components/common/UseHistoryState';
import HomeworkResultMenuGroup from 'src/components/common/HomeworkResultMenuGroup';
import { settings } from 'src/dummy/settings.js';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

const stageGroupColor = {
  v: '#ffce54',
  s: '#5d9cec',
  e: '#a0d468',
  reading: '#f52516',
  listening: '#f5ef0a',
  grammar: '#04a7f5',
};



const PER_PAGE = 10;

const LearningSmartEclass = () => {
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const companyName = useSelector(classStoreData);

  const [searchValue, setSearchValue] = UseHistoryState(
    {
      class_idx: 0,
      search_text: '',
      pageNumber: 0,
    },
    'hw_search',
  );

  const campus_idx = userInfoVar()?.campus_idx;
  const { data: dataClassLessons, loading } = useQuery(GET_CLASSS_LESSONS_FOR_HOMEWORK, {
    variables: {
      skip: !campus_idx,
      campus_idx: campus_idx,
      page: searchValue.pageNumber,
      class_idx: searchValue.class_idx,
      search_text: searchValue.search_text,
      state: searchValue.state,
    },
  });

  const classLessonsReal = useMemo(() => {
    if (dataClassLessons) {
      return dataClassLessons.classLessonsForHomworkResult?.class_lessons.map((item, key) => {
        const studentTotal = item?.class?.class_student?.length;

        const complete = item?.class?.user_lesson
          ?.filter((item2) => item2?.lesson_code === item.lesson_code)
          .filter((item3) => item3.stat === '1').length;

        return {
          No: PER_PAGE * searchValue.pageNumber + key + 1,
          key: `class-lesson-row${item.idx}-${item.lesson_code}`,
          class: item?.class?.name,
          lessonCode: item.lesson_code,
          classIdx: item.class.idx,
          title: `${item?.book_lesson?.title || item.lesson_code}`,
          date: moment(item.study_date).format('YY/MM/DD'),
          org_date: item.study_date,
          complete: `${complete}/${studentTotal}`,
          stage_group_code: item?.book_lesson?.stage_group_code?.split('')[0],
          stage_group_code_full: item?.book_lesson?.stage_group_code,
          companyName,
        };
      });
    }
    return [];
  }, [dataClassLessons, searchValue, companyName]);

  useEffect(() => {
    onClassChange(searchValue.class_idx);
    // eslint-disable-next-line
  }, [searchValue.class_idx]);

  const handleClassChange = (idx) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        class_idx: idx,
        pageNumber: 0,
      };
    });
  };

  const handleNameSearch = (sv) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        search_text: sv,
        pageNumber: 0,
      };
    });
  };

  const handleTableChange = (pagination) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        pageNumber: pagination.current - 1,
      };
    });
  };
  const handleStateChange = (e) => {
    setSearchValue((prev) => {
      return {
        ...prev,
        state: e.target.value,
        pageNumber: 0,
      };
    });
  };
  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '숙제일',
      dataIndex: 'date',
      align: 'date',
    },
    {
      key: 'class',
      title: 'Class',
      dataIndex: 'class',
      align: 'center',
    },
    {
      key: 'book',
      title: '교재',
      dataIndex: 'book',
      align: 'center',
      render: (_, record) => {
        //원래는 DB에서 가져와야 하는데, 로딩이 너무 오래걸리는 것 같아서 여기서 계산함.. 쩝
        const level = Number(record.lessonCode.slice(3, 7).slice(1, 2));
        const volume = Number(record.lessonCode.slice(3, 7).slice(3, 4));
        const book_title = settings[companyName].bookTitle(companyName, record.lessonCode);
  
        const prefix = level > 0 ? book_title : volume > 6 ? 'Super Coco' : 'Coco Phonics';
        const level_volume =
          level > 0 ? record.lessonCode.slice(3, 7).replace('L', '').replace('V', '-') : volume > 6 ? `1-${volume - 6}` : `1-${volume}`;
  
        return `${prefix} ${level_volume}`;
      },
    },
    {
      key: 'title',
      title: 'Lesson',
      dataIndex: 'title',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Badge
              className="site-badge-count-109"
              count={
                !settings[companyName].isTalkingTap(companyName, record.lessonCode)
                  ? record?.stage_group_code?.toUpperCase()
                  : record.lessonCode.slice(-1) === '1'
                  ? 'L'
                  : 'S'
              }
              style={{
                backgroundColor: settings[companyName].isTalkingTap(companyName, record.lessonCode)?'#1890ff':stageGroupColor[record?.stage_group_code_full] ? stageGroupColor[record?.stage_group_code_full] : '#ff0000',
              }}
            />
            <span>&nbsp;{record.lessonCode.slice(-4).replace('U', 'Unit').replace('D', ' Lesson')}</span>
          </>
        );
      },
    },
  
    {
      key: 'complete',
      title: '완료/총학생',
      dataIndex: 'complete',
      align: 'center',
    },
    {
      key: 'view',
      title: '확인',
      dataIndex: 'view',
      align: 'center',
      render: (_, { class: className, classIdx, lessonCode, title: lessonTitle }) => {
        return (
          <Link to={{ pathname: `/learning/${classIdx}/${lessonCode}`, state: { className, lessonTitle } }}>
            <Button>View</Button>
          </Link>
        );
      },
    },
  ];
  return (
    <>
      <HomeworkResultMenuGroup currentMenu="date" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>
          Homework Result<span style={{ paddingLeft: 10, fontSize: '0.7em' }}>* 숙제할 날짜를 기준으로 학습 결과 확인해 주세요.</span>
        </HeaderTitle>
        <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
        <Radio.Group value={searchValue.state} onChange={handleStateChange}>
            <Radio.Button value={undefined}>전체</Radio.Button>
            <Radio.Button value="ing">진행중</Radio.Button>
            <Radio.Button value="end">종료</Radio.Button>
          </Radio.Group>
          {/* <ClassSelectBox
            onClassChange={handleClassChange}
            selectedClassIdx={selectedClassIdx}
            campus_idx={campus_idx}
            style={{ width: '30%', textAlign: 'left' }}
          /> */}
          &nbsp;
          <Input.Search
            placeholder="반명 검색"
            type="text"
            style={{ width: '30%' }}
            defaultValue={searchValue.search_text}
            onSearch={handleNameSearch}
          />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              {/* <Title level={5}>학생 List</Title> */}
              {/* <Button style={{ color: '#289428' }}>+ 숙제출제일정</Button> */}
            </div>

            <CustomTable
              dataSource={classLessonsReal}
              columns={columns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                current: searchValue.pageNumber + 1,
                total: dataClassLessons?.classLessonsForHomworkResult?.total || 0,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default LearningSmartEclass;
