import React, { useState, useEffect } from 'react';
import { PlayCircleFilled } from '@ant-design/icons';
// import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
// import ReactPlayer from 'react-player';
// import { SAVE_TEST_ANSWERS } from 'src/operations/mutations/createTests';
import { useHistory, Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import useSound from 'use-sound';
import clipGuide from 'src/sounds/meta/4.mp3';
import clipPlay from 'src/sounds/meta/4.1.mp3';
import { GET_USER_INFO } from 'src/operations/queries/campus';

const InnerWrap = styled.div`
  background-color: #fff;
  width: calc(95% - 23rem);
  height: calc(100vh - 17rem);
  padding: 2rem 1rem;
  margin: 0rem auto;
  border-radius: 0px;
  min-width: 750px;
  max-width: 1200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  left: 50%;
  top: 54%;
  transform: translate(-50%, -50%);

  & {
    .process-btn-con {
      a.ant-tooltip-open {
        img {
          border: 2px dashed orange;
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        width: 100%;
        .reading-q-con {
        }
      }
    }
  }

  @media screen and (max-width: 2560px) {
    margin: 0 auto;
    height: 70%;
    & {
      .readingtest-con {
        .ant-tooltip {
          .ant-tooltip-content {
            .ant-tooltip-arrow {
            }
            .ant-tooltip-inner {
              span {
                font-size: 1.2rem;
                font-weight: 600;
              }

              .guideNum {
                font-size: 1.5rem;
              }
            }
          }
        }
      }

      .process-btn-con {
        .ant-tooltip {
          .ant-tooltip-inner {
            span {
              font-size: 1.2rem;
              font-weight: 600;

              .guideNum {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 2560px) {
    margin: 0 auto;
    height: 70%;
  }

  @media screen and (min-width: 1200px) {
    margin: 0 auto;
    height: 70%;
    & {
      .meta-header {
        &.readingTest-header {
          margin: 1rem auto 0;
          h1.main-tit {
          }
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    margin: 0 auto;
    height: 65%;
  }

  @media (device-width: 2560px) and (max-width: 2560px) and (aspect-ratio: 16/10) {
    & {
      .readingtest-con {
        .ant-tooltip {
          .ant-tooltip-content {
            .ant-tooltip-arrow {
              left: 108%;
              transform: translateX(-50%) rotate(0deg) scale(5);
              top: 3rem;
            }
            .ant-tooltip-inner {
              span {
                font-size: 1.6rem;
              }
            }
          }
        }
      }

      .process-btn-con {
        .ant-tooltip {
          .ant-tooltip-content {
            .ant-tooltip-arrow {
              left: 92%;
              transform: translateX(-50%) rotate(45deg) scale(2.5);
              top: 2.8rem;
              background-color: #ffb055;
            }
            .ant-tooltip-inner {
              span {
                font-size: 1.6rem;
              }
            }
          }
        }
      }

      .clickAnywhereSpan {
        font-size: 1.5rem;
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 33rem);
    height: calc(100% - 30rem);
    padding: 2rem 1rem;
    margin: 6rem auto;

    & {
      .fixed_btm_btn {
        bottom: 10rem;
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
    margin: 0 auto;

    & {
      .process-btn-con {
        padding: 0 4rem;
        a {
          img {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16/10) {
    width: calc(97rem - 3rem);
    height: calc(53rem - 3rem);
    padding: 3rem 1rem;
    margin: 5rem auto;
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    width: calc(100% - 10rem);
    height: calc(100% - 8rem);
  }

  @media (max-width: 1920px) and (aspect-ratio: 16/9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 750px;
    margin: 3rem auto;
  }

  @media (max-width: 1680px) and (aspect-ratio: 16/10) {
    width: calc(97rem - 3rem);
    height: calc(53rem - 3rem);
    padding: 2rem 1rem;
    margin: 2rem auto;

    & {
      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 2rem;
        }
      }
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16/9) {
    max-width: 1500px;
    height: 100%;
    padding: 1rem 2rem 2rem;
    max-height: 650px;
    margin: 2rem auto;
  }

  @media (max-width: 1440px) and (aspect-ratio: 16/10) {
    width: calc(90rem - 18rem);
    height: calc(45rem - 3rem);

    & {
      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16/9) {
    height: 70%;
    width: 100%;
    max-width: 1000px;
    max-height: 500px;
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    padding: 0;
  }

  @media (max-width: 1280px) and (aspect-ratio: 16/9) {
    top: 47%;
  }

  @media (max-width: 1366px) {
    width: calc(70rem - 3rem);
    height: calc(37rem - 3rem);
    padding: 2rem 1rem;
    margin: 2rem auto;
  }

  @media (max-width: 1024px) {
    height: 70%;
    width: 90%;
  }

  @media (max-width: 1024px) and (aspect-ratio: 16/9) {
    height: 70%;
    width: 80%;
    max-width: 800px;
    max-height: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }
    }

    @media (max-width: 1024px) {
      width: calc(100% - 7rem);
      height: calc(100% - 13rem);
    }

    @media (max-width: 960px) and (aspect-ratio: 16/9) {
      height: 80%;
      width: 100%;
      max-width: 800px;
      max-height: 380px;
      position: absolute;
      left: 50%;
      top: 53%;
    }
  }
`;

// const burgerData = ['1', '2', '3', '4', '5'];
const playBtnText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">1</span>Click here to play the audio to start. You can listen to the aduio for each question up to two times.
  </span>
);
const anywhereText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">2</span>Click anywhere in this box
  </span>
);
// const questionText = <span>Question number / Total Items</span>;
const nextText = (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <span className="guideNum">3</span>Click to go to the next question
  </span>
);

const ListeningTestSample = () => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 ? true : false;
  const [isMarked, setIsMarked] = useState(false);
  const history = useHistory();

  const [playStart, { stop: stopPlay }] = useSound(clipGuide, { volume: 0.5 });
  const [play] = useSound(clipPlay);

  useEffect(() => {
    if (playStart) {
      setTimeout(() => {
        playStart();
      }, 1000);
    }
  }, [playStart]);

  const stopSoundAndLeave = () => {
    if (stopPlay) {
      stopPlay();
    }
    history.push('/MetaExam/InstructionalGuideMeta/complete');
  };
  return (
    <>
      <div className={isWEnglish ? 'meta-con guide-con w-english-bg' : 'meta-con guide-con'}>
        <div className="meta-header test-header">
          <div className="burger-wrap">
            <img src="../images/meta_hamburger.png" alt="burger_meta" />
            <h2 style={{ marginBottom: '0' }}>Question List</h2>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <div className="time-wrap">
              <h2>Time: </h2>
              <h2 className="num">10:00</h2>
            </div>

            <div className="question-wrap">
              <h2>Question: </h2>
              <h2 className="num">10/30</h2>
            </div>
          </div>
        </div>
        <InnerWrap>
          <div className="meta-header readingTest-header">
            <h1 className="main-tit" style={{ color: '#' }}>
              Listening
            </h1>
          </div>

          <div className="readingtest-con">
            <div className="readingtest-wrap">
              <div className="reading-q-con" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                <Tooltip
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultVisible="true"
                  visible="true"
                  overlayStyle={{
                    minWidth: '400px',
                  }}
                  trigger="focus"
                  placement="top"
                  title={playBtnText}
                >
                  <PlayCircleFilled onClick={() => play()} style={{ fontSize: '40px', marginRight: '20px' }} />{' '}
                </Tooltip>
                <h1>5. There are _________ apples.</h1>
                {/* <div className="playBtnArrow arrow-wrap">
                  <p className="guide-arrow">
                    <span class="arrow-body">1. Click here to play the audio to start</span>
                  </p>
                </div> */}
              </div>
              <div className="reading-a-con">
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>a</h3>
                  </div>
                  <div className="reading-a">
                    <h3>one</h3>
                  </div>
                </div>
                <div>
                  <div className="reading-a-wrap">
                    <div className="reading-a-num">
                      <h3>b</h3>
                    </div>
                    <div className="reading-a">
                      <h3>two</h3>
                    </div>
                  </div>
                </div>
                <Tooltip
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultVisible="true"
                  visible="true"
                  trigger="focus"
                  placement="top"
                  title={anywhereText}
                >
                  <div style={{ border: '1px dashed orange', padding: '0rem' }} onClick={() => setIsMarked(true)}>
                    <div className="reading-a-wrap">
                      <div className="reading-a-num">
                        <h3>c</h3>
                        {isMarked && <img src="../images/check.png" alt="burger_meta" className="checkedImg" />}
                      </div>
                      <div className="reading-a" style={{ width: '100%', display: 'flex' }}>
                        <h3>three</h3>
                        {/* <div className="listeningAnswerArrow arrow-wrap">
                        <p className="guide-arrow">
                          <span class="arrow-body">2. Click anywhere in this box</span>
                        </p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <div className="reading-a-wrap">
                  <div className="reading-a-num">
                    <h3>d</h3>
                  </div>
                  <div className="reading-a">
                    <h3>four</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* bottom btn */}

          <div className="process-btn-con">
            <Link to="/MetaExam/InstructionalGuideMeta">
              <img src="/images/back_meta.png" alt="back_meta" />
            </Link>
            {/*
            <div className=" arrow-wrap">
              <p className="guide-arrow">
                <span class="arrow-body">3. Click to go to the next question</span>
              </p>
            </div> */}
            <Tooltip
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultVisible="true"
              visible="true"
              trigger="focus"
              placement="bottom"
              title={nextText}
            >
              <a href="#" onClick={stopSoundAndLeave} style={{ marginLeft: 'auto' }}>
                <img src="/images/next_meta.png" alt="next_meta" />
              </a>
            </Tooltip>
          </div>
        </InnerWrap>
      </div>
    </>
  );
};

export default ListeningTestSample;
