import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { Modal, Form, Input, Button, Checkbox, Radio, Row, Col } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';
import { useForm } from 'src/utils/hooks';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const BookModal = ({ visible, handleCancel, popupMode }) => {
  const [form] = Form.useForm();
  const [packageSelected, setPackageSelected] = useState(false);
  const [creditSystem, setCreditSystem] = useState(false);
  const [bookCategoryOptions, setBookCategoryOptions] = useState([]);
  const { data } = useQuery(queries.book.GET_BOOK_CATEGORY_LIST, { variables: {} });

  useEffect(() => {
    setBookCategoryOptions(
      data?.bookCategoryList.map((bookCategory) => {
        return {
          label: bookCategory.name,
          value: bookCategory.idx,
        };
      }),
    );
  }, [data]);

  const { onChange, onSubmit, values } = useForm(formCallback, {
    title: '',
    code: '',
    category: '',
    price: '',
    number_of_levels: '',
    number_of_volumes: '',
    number_of_lessons: '',
    shopping_mall_product: false,
    cover_path: '',
    credit_system: false,
    credit_duration: '',
    short_title: '',
    stat: 1,
  });

  const [isCodeExists] = useLazyQuery(queries.book.CODE_EXIST_CHECK);
  const [createBookSeries, { loading }] = useMutation(mutations.book.CREATE_BOOK_SERIES, {
    variables: {
      ...values,
      category: parseInt(values.category),
      price: parseInt(values.price),
      number_of_levels: parseInt(values.number_of_levels),
      number_of_volumes: parseInt(values.number_of_volumes),
      number_of_lessons: parseInt(values.number_of_lessons),
      credit_duration: parseInt(values.credit_duration),
      stat: parseInt(values.stat),
    },

    update(proxy, result) {
      const data = proxy.readQuery({
        query: queries.book.GET_BOOK_SERIES,
        variables: { search_text: '' },
      });

      proxy.writeQuery({
        query: queries.book.GET_BOOK_SERIES,
        variables: { search_text: '' },
        data: {
          bookSeries: [...data.bookSeries, result.data.createBookSeries],
        },
      });
    },
    onCompleted(data) {
      if (data) {
        openNotification('콘텐츠가 추가되었습니다!');
        handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleCreditSystemChange = (e) => {
    if (e.target.checked) {
      setCreditSystem(e.target.checked);
    }

    onChange(e);
  };

  const onCategoryChange = (e) => {
    if (e.target.value == 6) {
      setPackageSelected(true);
    } else {
      setPackageSelected(false);
    }

    onChange(e);
  };

  function formCallback() {
    if (popupMode === 'create') {
      createBookSeries();
    } else {
      console.log('not create');
    }
  }

  return (
    <>
      <Modal
        entered
        title={`컨텐츠 제작`}
        visible={visible}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          initialValues={{
            title: '',
            code: '',
            category: '',
            price: '',
            number_of_levels: '',
            number_of_volumes: '',
            number_of_lessons: '',
            shopping_mall_product: false,
            cover_path: '',
            credit_system: false,
            credit_duration: '',
            short_title: '',
            stat: 1,
          }}
        >
          <FormField hasFeedback={true} title="제목">
            <FormItem name="title" rules={[{ required: true, message: '제목을 입력하세요.' }]} hasFeedback>
              <Input name="title" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="코드">
            <FormItem
              name="code"
              rules={[
                { required: true, message: '코드를 입력하세요.' },
                {
                  validator: async (rule, value) => {
                    const codeExistsRes = await isCodeExists({
                      variables: {
                        code: value,
                      },
                    });
                    if (codeExistsRes.data?.isCodeExists.code !== 'false') {
                      return Promise.reject([rule.message]);
                    }
                  },
                  message: '해당 코드는 이미 존재합니다.',
                },
              ]}
              hasFeedback
            >
              <Input name="code" onChange={onChange} maxLength={13} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="카테고리">
            <FormItem name="category" rules={[{ required: true, message: '카테고리를 선택하세요.' }]} hasFeedback>
              <select name="category" style={{ width: '100%' }} className="ant-input" onChange={onCategoryChange}>
                <option key={''} value={''}>
                  {'카테고리 선택'}
                </option>
                {bookCategoryOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="가격">
            <FormItem name="price" rules={[{ required: true, message: '가격을 입력하세요.' }]} hasFeedback>
              <Input name="price" onChange={onChange} maxLength={13} />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="레벨 개수">
            <FormItem name="number_of_levels" rules={[{ required: false }]} hasFeedback>
              <Input name="number_of_levels" onChange={onChange} />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="볼륨 개수">
            <FormItem name="number_of_volumes" rules={[{ required: false }]} hasFeedback>
              <Input name="number_of_volumes" onChange={onChange} />
            </FormItem>
          </FormField>
          <FormField hasFeedback={false} title="레슨 개수">
            <FormItem name="number_of_lessons" rules={[{ required: false }]} hasFeedback>
              <Input name="number_of_lessons" onChange={onChange} />
            </FormItem>
          </FormField>
          {packageSelected && (
            <FormField hasFeedback={false} title="쇼핑몰 상품">
              <FormItem name="shopping_mall_product" hasFeedback>
                <Checkbox name="shopping_mall_product" onChange={onChange} />
              </FormItem>
            </FormField>
          )}
          <FormField hasFeedback={false} title="커버 이미지 URL">
            <FormItem
              name="cover_path"
              rules={[
                { required: false },
                {
                  type: 'url',
                  message: '유효한 URL을 입력하세요',
                },
              ]}
              hasFeedback
            >
              <Input name="cover_path" onChange={onChange} maxLength={500} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="크레딧 시스템">
            <FormItem name="credit_system" hasFeedback>
              <Checkbox name="credit_system" onChange={(e) => handleCreditSystemChange(e)} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="크레딧 기간">
            <FormItem name="credit_duration" rules={[{ required: false }]} hasFeedback>
              <Input name="credit_duration" disabled={!creditSystem} onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="짧은 제목">
            <FormItem name="short_title" rules={[{ required: false }]} hasFeedback>
              <Input name="short_title" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="상태">
            <FormItem name="stat" rules={[{ required: false }]} hasFeedback>
              <Radio.Group name="stat" onChange={onChange}>
                <Row>
                  <Col span={15} align="left">
                    <Radio value={0}>판매 불가능</Radio>
                  </Col>
                  <Col span={15} align="left">
                    <Radio value={1}>판매 가능</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </FormItem>
          </FormField>
        </Form>
      </Modal>
    </>
  );
};

BookModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default BookModal;
