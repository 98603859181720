import { gql } from '@apollo/client';

export const CREATE_FOLDERS = gql`
  mutation createFolders(
    $name: String!
    $folder_idx: Int
    $is_public: Boolean!
  ) {
    createFolders(
      name: $name
      folder_idx: $folder_idx
      is_public: $is_public
    ) {
      idx
      name
      is_public
      is_default
      folders {
        idx
        name
        idate
        user {
          idx
          name
        }
      }
      user {
        idx
        name
      }
      idate
    }
  }
`;
