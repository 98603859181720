import React from 'react';
import { Col, Row } from 'antd';
import LevelTab, { useLevelTab } from './LevelTab';

import { BookCard, BookCover, BookMeta, BookTitle, THUMBNAIL_BASE_URL_ESL_RAINBOW, ESL_PPT_CDN_BASE_URL } from './CommonComp';

const GROUPS = ['Volume1', 'Volume2', 'Volume3', 'Volume4'];
const LEVELS = [9, 10,11];
const VOLUMES = [1, 2, 3, 4, 5, 6, 7, 8];
const SECTIONS = [1, 2];

const ESLCrossover = () => {
  const { levelNo, onChangeLevelNo } = useLevelTab(0);

  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <LevelTab
        levelNo={levelNo}
        levels={LEVELS}
        onChage={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />

      {[9, 10, 11]
        .filter((level) => (levelNo === 0 ? true : level === levelNo))
        .map((lv) => (
          <>
            {[1, 2].map((volume) => (
              <Row
                key={`row-${lv}-${volume}`}
                gutter={[16, 16]}
                style={{
                  margin: '0 10px',
                }}
              >
                {SECTIONS.map((vol) => (
                  <Col
                    span={6}
                    key={`col-${lv}-${vol}`}
                    style={{
                      display: 'flex',
                      border: '1px solid #eee',
                    }}
                    className="CAWrap crossover"
                  >
                    <div className="CreoBook-con">
                      <div className="Creo-titWrap" style={{ backgroundColor: `${volume % 2 === 0 && 'lightblue'}` }}>
                        <BookTitle
                          level={2}
                          style={{
                            backgroundColor: `${volume % 2 === 0 && 'lightblue'}`,
                            display: `${vol === 1 ? ' block' : ' none'}`,
                          }}
                          className="CreoBookTitle"
                        >
                          Level{lv}
                          <br />
                          Vol.{volume}
                        </BookTitle>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          textAlign: 'center',
                        }}
                        className={`bookCardCon ${lv < 4 && vol === 1 ? 'ddd' : ''}`}
                      >
                        <h2
                          className="SectionTit"
                          style={lv === 4 ? { margin: '0.5rem auto', display: 'flex' } : {}}
                        >{`Section ${vol}`}</h2>
                        <div className="bookCardWrap">
                          <BookCard
                            cover={
                              <BookCover
                                style={{
                                  backgroundImage: `url(${THUMBNAIL_BASE_URL_ESL_RAINBOW}L${lv}V${volume}.png)`,
                                }}
                              />
                            }
                          >
                            <BookMeta />
                          </BookCard>
                          <UnitGroup level={lv} volume={volume} section={vol} />
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </>
        ))}
      {[12]
        .filter((level) => (levelNo === 0 ? true : level === levelNo))
        .map((lv) => (
          <>
            {VOLUMES.map((volume) => (
              <Row
                key={`row-${lv}-${volume}`}
                gutter={[16, 16]}
                style={{
                  margin: '0 10px',
                }}
              >
                {SECTIONS.map((vol) => (
                  <Col
                    span={6}
                    key={`col-${lv}-${vol}`}
                    style={{
                      display: 'flex',
                      border: '1px solid #eee',
                    }}
                    className="CAWrap crossover"
                  >
                    <div className="CreoBook-con">
                      <div className="Creo-titWrap" style={{ backgroundColor: `${volume % 2 === 0 && 'lightblue'}` }}>
                        <BookTitle
                          level={2}
                          style={{
                            backgroundColor: `${volume % 2 === 0 && 'lightblue'}`,
                            display: `${vol === 1 ? ' block' : ' none'}`,
                          }}
                          className="CreoBookTitle"
                        >
                          {`Level ${lv}-${volume}`}
                        </BookTitle>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          textAlign: 'center',
                        }}
                        className={`bookCardCon ${lv < 4 && vol === 1 ? 'ddd' : ''}`}
                      >
                        <h2
                          className="SectionTit"
                          style={lv === 4 ? { margin: '0.5rem auto', display: 'flex' } : {}}
                        >{`Section ${vol}`}</h2>
                        <div className="bookCardWrap">
                          <BookCard
                            cover={
                              <BookCover
                                style={{
                                  backgroundImage: `url(${THUMBNAIL_BASE_URL_ESL_RAINBOW}L${lv}V1.jpg)`,
                                }}
                              />
                            }
                          >
                            <BookMeta />
                          </BookCard>
                          <UnitGroup level={lv} volume={volume} section={vol} />
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </>
        ))}
    </div>
  );
};

export default ESLCrossover;

const UnitGroup = ({ level, volume, section }) => {
  return (
    <BookCard className="unit-con">
      {Array(5)
        .fill(0)
        .map((_, key) => {
          const unitNo = (section - 1) * 5 + key + 1;
          return (
            <div
              key={`lesson-list${key}`}
              className="unit-wrap"
              onClick={(e) => {
                e.stopPropagation();
                window.open(`${ESL_PPT_CDN_BASE_URL}CREO_CROSS_OVER_${level}-${volume}_U${unitNo}/index.html`, '_blank');
              }}
              data-url={`${ESL_PPT_CDN_BASE_URL}CREO_CROSS_OVER_${level}-${volume}_U${unitNo}/index.html`}
            >
              <h2>UNIT {unitNo}</h2>
            </div>
          );
        })}
    </BookCard>
  );
};
