import React from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_TEST, ASSIGN_TEST, ASSIGN_TEST_EDIT, EDIT_TEST } from 'src/operations/mutations/createTests';
import { withRouter } from 'react-router';
import { Assign, ResultsPage } from 'src/components/Phonics/Elibrary/Elibraryedit/';

const Manage = (props) => {
  const { type = 'add', id = undefined } = useParams();

  const [createTest, { loading }] = useMutation(CREATE_TEST);
  const [editTest, { loading4 }] = useMutation(EDIT_TEST);
  const [assignTest, { loading2 }] = useMutation(ASSIGN_TEST);
  const [assignTestEdit, { loading3 }] = useMutation(ASSIGN_TEST_EDIT);

  const handleGoBack = () => {
    props.history.goBack();
  };

  const handleTestCreate = async (data) => {
    try {
      await createTest({ variables: data });
      props.history.push('/elibrary/manage/list?refresh=true');
    } catch (e) {
      console.log(e);
    }
  };

  const handleTestAssign = async (data) => {
    try {
      await assignTest({ variables: data });

      //props.history.push('/test/manage/list');
      props.history.goBack();
    } catch (e) {
      console.log(e);
    }
  };

  const handleTestAssignEdit = async (data) => {
    try {
      await assignTestEdit({ variables: data });
      props.history.push('/test/manage/result?refresh=true');
    } catch (e) {
      console.log(e);
    }
  };

  const getSwitchableRoute = (val, id) => {
    switch (val) {
      case 'assign':
        return <Assign id={parseInt(1)} onClickSave={handleTestAssign} />;
      case 'result':
        return <ResultsPage />;
      default:
        return <Assign onClickSave={handleTestCreate} onClickGoback={handleGoBack} />;
    }
  };

  return (
    <>
      <Row gutter={[24, 16]}>{getSwitchableRoute(type, id)}</Row>
    </>
  );
};

export default withRouter(Manage);
