import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { Col } from 'antd';
import { userInfoVar } from 'src/apollo/cache';
import { HeaderTitle } from 'src/components/common/Styles';
import BookCreditManage from './tabs/BookCreditManage';
import BookCreditAssignedList from './tabs/BookCreditAssignedList';

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;
const { TabPane } = Tabs;

const List = () => {
  const history = useHistory();

  const type = userInfoVar()?.type;
  if (type && type !== 2) {
    history.replace('/');
  }
  return (
    <>
      <Col className="bookcredit-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <HeaderTitle level={4}>Purchase Credits(이용권 관리)</HeaderTitle>
        <MainBlock className="bookcredit-wrapper-mainblock">
          <div className="card-container">
            <Tabs type="card">
              <TabPane tab="구매내역" key="1">
                <BookCreditManage />
              </TabPane>
              <TabPane tab="부여 내역" key="2">
                <BookCreditAssignedList />
              </TabPane>
            </Tabs>
          </div>
        </MainBlock>
      </Col>
    </>
  );
};

export default List;
