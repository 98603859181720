import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Modal, Input, Button, Typography, Radio } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { CAMPUS_SEARCH_LIST } from 'src/operations/queries/campus';

const columns = [
  {
    title: '캠퍼스명',
    dataIndex: 'campus_name',
    key: 'campus_name',
    align: 'center',
    width: '25%',
  },
  {
    title: '주소',
    dataIndex: 'address',
    key: 'address',
    align: 'center',
    width: '30%',
  },
  {
    title: '전화번호',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
    width: '25%',
  },
  {
    title: '선택',
    dataIndex: 'choose',
    key: 'choose',
    align: 'center',
    width: '20%',
    render: (_, { onChoose, campus_idx, campus_name }) => {
      return (
        <Button
          type="primary"
          onClick={() => {
            onChoose({ campus_idx, campus_name });
          }}
        >
          선택
        </Button>
      );
    },
  },
];

const SearchCampusModal = ({ visible, onCancel, onChoose, company = 'cleveredu' }) => {
  const [searchType, setSearchType] = useState('name');
  const [searchText, setSearchText] = useState('');

  const [search, { data, loading }] = useLazyQuery(CAMPUS_SEARCH_LIST);
  const handleSearch = () => {
    if (searchText.trim() === '') {
      alert('검색어를 입력하세요.');
      return false;
    }

    const variables = { company_name: company, search_type: searchType, search_text: searchText.trim() };
    search({ variables, fetchPolicy: 'no-cache' });
  };

  const searchedData = useMemo(() => {
    if (data?.campusSearchList?.length) {
      return data.campusSearchList.map((item, key) => ({
        key: `campus-list${key}`,
        campus_name: item.name,
        address: `${item?.address} ${item?.address2}`,
        phone: `${item?.phone}`,
        campus_idx: item?.idx,
        onChoose,
      }));
    }
    return [];
  }, [data, onChoose]);

  return (
    <Modal
      centered={true}
      title={`캠퍼스 검색하기`}
      visible={visible}
      onCancel={() => {
        setSearchType('name');
        setSearchText('');
        const variables = { company_name: '', search_type: '', search_text: '' };
        search({ variables, fetchPolicy: 'no-cache' });
        onCancel();
      }}
      width={'90%'}
      footer={false}
    >
      <Typography.Text>방문을 희망하시는 캠퍼스의 이름/지역명으로 검색이 가능합니다.</Typography.Text>

      <Radio.Group
        name="search_type"
        value={searchType}
        onChange={(e) => {
          const {
            target: { value },
          } = e;
          setSearchType(value);
        }}
      >
        <Radio value={'name'}>캠퍼스 이름 검색</Radio>
        <Radio value={'address'}>캠퍼스 주소 검색</Radio>
      </Radio.Group>
      <Input.Search
        placeholder="input search text"
        value={searchText}
        loading={loading}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        enterButton="검색"
        size="large"
        onSearch={handleSearch}
      />
      <CustomTable dataSource={searchedData} columns={columns} pagination={{ pageSize: 4 }} size="small" color="#edf3fb" scroll={{ y: 'calc(100vh - 254px)' }} />
    </Modal>
  );
};

export default SearchCampusModal;
