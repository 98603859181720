import React from 'react';
import { Table } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { PrintDummy } from 'src/components/common/Styles';

const OrderListTable = ({ loading, dataSource, prinfRef, columns }) => {
  return (
    <>
      <CustomTable
        loading={loading}
        className="orderadmin-table-table"
        pagination={{ pageSize: 12, position: ['bottomCenter'] }}
        dataSource={dataSource}
        columns={columns}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
      />
      <PrintDummy ref={prinfRef}>
        <Table pagination={false} dataSource={dataSource} columns={columns} size="small" />
      </PrintDummy>
    </>
  );
};

export default OrderListTable;
