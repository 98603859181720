import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, Space } from 'antd';
import { withRouter } from 'react-router-dom';
import { MenuOutlined, LeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ASSIGNED_TEST_USERS_SINGLE_FOR_MANGER } from 'src//operations/queries/getTests';
import { useQuery } from '@apollo/client';
import * as diff_match_patch from 'diff-match-patch';

import CustomTable from 'src/components/common/CustomTable';
import * as dummy from 'src/dummy';
import { Link } from 'react-router-dom';
import File from 'src/components/common/File';
import moment from 'moment';
const dmp = new diff_match_patch();

const { Title } = Typography;

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

// function averageTimes(times,unit) {
//     if (!times) {
//         return false;
//     }
//     else {
//         var totalMilliseconds = 0, hours, minutes, seconds, milliseconds, parts;
//         for (var i = 0, len = times.length; i < len; i++) {
//             parts = times[i].split(':');
//             hours = parseInt(parts[0], 10) * 3600000;
//             minutes = parseInt(parts[1], 10) * 60000;
//             seconds = parseInt(parts[2].split('.')[0], 10) * 1000;
//             milliseconds = parseInt(parts[2].split('.')[1], 10);
//             totalMilliseconds += (hours + minutes + seconds + milliseconds);
//         }
//         if (!unit || unit.toLowerCase() == 'ms'){
//             return totalMilliseconds/times.length;
//         }
//         else if (unit.toLowerCase() == 's') {
//             return (totalMilliseconds/1000)/times.length;
//         }
//
//     }
// }

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  min-height: calc(100vh - 15vh);
`;

const StudentResultManager = (props) => {
  const [testUser, setTestUser] = useState({ assigned_test_answers: [], assigned_tests: { tests: { test_answers: [] } } });
  const [testUserAnswer, setTestUserAnswer] = useState([]);
  const [activeExtra, setActiveExtra] = useState(false);

  const { data } = useQuery(ASSIGNED_TEST_USERS_SINGLE_FOR_MANGER, {
    variables: { idx: parseInt(props.id) },
  });

  useEffect(() => {
    if (data?.assignedTestUsersSingleForManager) {
      setTestUser(data?.assignedTestUsersSingleForManager);

      setTestUserAnswer(
        data?.assignedTestUsersSingleForManager?.assigned_test_answers?.reduce((acc, cur, index) => {
          const answer = cur.answer && cur.answer !== 'undefined' && cur.answer !== 'null' ? cur.answer : '';
          const obj = {
            key: index,
            code: cur.code,
            answer: answer,
            is_correct: cur.is_correct,
          };
          return [...acc, obj];
        }, []),
      );
    }
  }, [data]);

  const columns = [
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>No</Space>;
      },
      dataIndex: 'code',
      key: 'code',
      width: 90,
    },
    // {
    //   title: () => {
    //     return (
    //       <Space style={{color:"#065aa9"}}>
    //         Question
    //       </Space>
    //     )
    //   },
    //   dataIndex: 'name',
    //   key: 'name',
    //   width: 150,
    //   render: (text, record, i) => {
    //     const answer = testUser["assigned_tests"]["tests"]["test_answers"].filter(f => f.code === record.code);
    //     return <Space size="middle">
    //       {answer[]}
    //     </Space>
    //   }
    // },
    {
      title: () => {
        return (
          <Space style={{ color: '#065aa9', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
            Correct Answer
          </Space>
        );
      },
      dataIndex: 'code',
      key: 'correct_answer',
      render: (text) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === text)[0];
        return (
          <Space size="middle" style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
            {answer?.['answer']}
          </Space>
        );
      },
    },
    {
      title: () => {
        return <Space style={{ color: '#065aa9', display: 'flex', justifyContent: 'center' }}>User Answer</Space>;
      },
      dataIndex: 'answer',
      key: 'user_answer',
      render: (text, record, i) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === record.code)[0];
        if (answer?.['option_count']) {
          return <Space style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>{text}</Space>;
        } else {
          dmp.Diff_Timeout = 5;
          dmp.Diff_EditCost = 5;
          const d = dmp.diff_main(answer?.['answer'] || '', text);
          dmp.diff_cleanupSemantic(d);
          let htmltext = [];
          d.forEach((element, i) => {
            if (element[0] === 0) {
              const filteredArrayl = element[1].split(' ').filter((value) => answer?.['answer'].split(' ').includes(value));
              htmltext.push(`<span style="color:black;">${element[1]}</span>`);
            } else if (element[0] === -1) {
              htmltext.push(`<span style="color:brown;">${element[1]}</span>`);
            } else if (element[0] === 1) {
              htmltext.push(`<span style="color:darkblue;">${element[1] && element[1] !== 'undefined' ? element[1] : ''}</span>`);
            }
          });

          return (
            <Space size="middle" style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div dangerouslySetInnerHTML={{ __html: `${htmltext.join('')}` }} />
            </Space>
          );
        }
      },
    },
    {
      title: () => {
        return (
          <div style={{ color: '#065aa9', display: 'flex', justifyContent: 'center' }}>
            <div>O/X</div>
          </div>
        );
      },
      dataIndex: 'is_correct',
      key: 'is_correct',
      width: 70,
      render: (text) => {
        if (text) {
          return (
            <Space
              align="center"
              style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', color: '#2ea906' }}
            >
              O
            </Space>
          );
        } else {
          return (
            <Space
              align="center"
              style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', color: '#a90606' }}
            >
              X
            </Space>
          );
        }
      },
    },
  ];

  const extraColumns = [
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>PDF</Space>;
      },
      dataIndex: 'code',
      key: 'pdf',
      width: 90,
      render: (text, record, i) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === text)[0];
        return answer?.['pdf'] ? (
          <File
            file={answer['pdf']}
            thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
            textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
          />
        ) : null;
      },
    },
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>Audio</Space>;
      },
      dataIndex: 'code',
      key: 'audio',
      width: 90,
      render: (text, record, i) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers'].filter((f) => f.code === text)[0];
        return answer?.['audio'] ? (
          <File
            file={answer['audio']}
            thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
            textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
          />
        ) : null;
      },
    },
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>Video</Space>;
      },
      dataIndex: 'code',
      key: 'video',
      width: 60,
      render: (text, record, i) => {
        const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === text)[0];
        return answer?.['video'] ? (
          <File
            file={answer['video']}
            thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
            textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
          />
        ) : null;
      },
    },
  ];

  const buttonColumn = [
    {
      title: <Button type="primary" icon={<MenuOutlined />} onClick={() => setActiveExtra(!activeExtra)} />,
      key: 'action',
      width: 70,
      render: (text, record) => {
        return <Space size="middle" key={record.id}></Space>;
      },
    },
  ];
  const finalExtraColumn = activeExtra ? [...extraColumns, ...buttonColumn] : [...buttonColumn];

  const assignedRetakes = testUser?.['assigned_test_answer_retakes'] || [];
  const maxRetakesTaken = assignedRetakes.reduce((a, b) => (a.retries > b.retries ? a : b), {});
  const retakeColumns = maxRetakesTaken?.retries
    ? new Array(maxRetakesTaken?.retries).fill(0).reduce((a, b, i) => {
        return [
          ...a,
          {
            title: () => {
              return (
                <Space style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', color: '#065aa9' }}>
                  Retake Answer {i + 1}
                </Space>
              );
            },
            dataIndex: `retake_answer_${i}`,
            key: `retake_answer_${i}`,
            render: (text, record) => {
              let findAnswer = assignedRetakes.filter((f) => f.code === record.code && f.retries === i + 1);
              if (!findAnswer.length) {
                return '';
              } else {
                text = findAnswer.length ? findAnswer[0]['answer'] : '';

                const answer = testUser?.['assigned_tests']?.['tests']?.['test_answers']?.filter((f) => f.code === record.code)[0];
                if (answer?.['option_count']) {
                  return (
                    <Space style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                      {answer?.['answer']}
                    </Space>
                  );
                } else {
                  dmp.Diff_Timeout = 5;
                  dmp.Diff_EditCost = 5;
                  const d = dmp.diff_main(answer?.['answer'] || '', text);
                  dmp.diff_cleanupSemantic(d);
                  let htmltext = [];
                  d.forEach((element, i) => {
                    if (element[0] === 0) {
                      const filteredArrayl = element[1].split(' ').filter((value) => answer?.['answer'].split(' ').includes(value));
                      htmltext.push(`<span style="color:black;">${element[1]}</span>`);
                    } else if (element[0] === -1) {
                      htmltext.push(`<span style="color:brown;">${element[1]}</span>`);
                    } else if (element[0] === 1) {
                      htmltext.push(`<span style="color:darkblue;">${element[1] && element[1] !== 'undefined' ? element[1] : ''}</span>`);
                    }
                  });

                  return (
                    <Space size="middle" style={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <div dangerouslySetInnerHTML={{ __html: `${htmltext.join('')}` }} />
                    </Space>
                  );
                }
              }
            },
          },
          {
            title: () => {
              return (
                <div style={{ color: '#065aa9', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div>Retake O/X {i + 1} </div>
                </div>
              );
            },
            dataIndex: `retake_is_correct_${i}`,
            key: `retake_is_correct_${i}`,
            width: 100,
            render: (text, record) => {
              let findAnswer = assignedRetakes.filter((f) => f.code === record.code && f.retries === i + 1);
              if (!findAnswer.length) {
                return '';
              }
              if (findAnswer[0].is_correct) {
                return (
                  <Space
                    align="center"
                    style={{ color: '#2ea906', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}
                  >
                    O
                  </Space>
                );
              } else {
                return (
                  <Space
                    align="center"
                    style={{ color: '#a90606', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}
                  >
                    X
                  </Space>
                );
              }
            },
          },
        ];
      }, [])
    : [];

  const finalColumns = assignedRetakes.length ? [...columns, ...retakeColumns, ...finalExtraColumn] : [...columns, ...finalExtraColumn];

  return (
    <>
      <Col span={24}>
        <MainBlock>
          <Row gutter={[24, 16]}>
            <Col span={16} style={{ padding: 0 }}>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col span={14} style={{ padding: 0, paddingLeft: 10 }}>
                  <Title level={5} ecliple={true}>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Test Title :</span> {testUser.assigned_tests.title}{' '}
                  </Title>
                </Col>
                <Col span={10} style={{ padding: 2 }}>
                  <Title level={5}>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>날짜 :</span>{' '}
                    {moment(testUser.start_time).format('YYYY.MM.DD, HH:mm')}
                  </Title>
                </Col>
              </Row>
              <Row style={{ margin: 0, marginTop: 15 }}>
                <Title level={5}>
                  <span style={{ color: '#065aa9', marginLeft: 10 }}>Test :</span> {testUser.assigned_tests.tests.title}{' '}
                </Title>
              </Row>
            </Col>
            <Col span={8} style={{ padding: 2 }}>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col offset={2} span={10}>
                  <Title level={5}>
                    <span style={{ color: '#ff7a1b', marginLeft: 10 }}>
                      Time - {moment.utc(moment(testUser.end_time).diff(moment(testUser.start_time))).format('HH:mm:ss')}
                    </span>{' '}
                  </Title>
                </Col>
                <Col offset={2} span={10}>
                  <Title level={5}>
                    <span style={{ color: '#de3333', marginLeft: 10 }}>Score : {testUser.score}</span>{' '}
                  </Title>
                </Col>
              </Row>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col span={10} offset={2}>
                  <Link to={`/test/new/${testUser['assigned_tests']['tests']['idx']}/preview`}>
                    <Button style={{ color: '#065aa9' }}>시험지 보기</Button>
                  </Link>
                </Col>
                <Col span={8} offset={4} style={{ padding: 2 }}>
                  <Button shape="round" size="default" icon={<LeftOutlined />} onClick={() => props.history.goBack()}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <CustomTable
            dataSource={testUserAnswer}
            columns={finalColumns}
            size="small"
            bordered
            color="#edf3fb"
            pagination={{ hideOnSinglePage: true }}
            scroll={{ y: 'calc(100vh - 254px)' }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => onHandleRowClick(record, rowIndex),
              };
            }}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(StudentResultManager);
