import React, { useState, useEffect, useMemo } from 'react';
import { Col, Typography, Row, Button, Form, Input, Select, Tooltip } from 'antd';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { AI_TOPICS_SINGLE } from 'src/operations/queries/aiTopic';
import { ApolloConsumer } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import AITopicMenuGroup from 'src/components/common/AITopicMenuGroup';
import { useMutation } from '@apollo/client';
import { CREATE_AI_TOPIC, EDIT_AI_TOPIC } from 'src/operations/mutations/aiTopic';
import TextArea from 'antd/lib/input/TextArea';

const { Title } = Typography;

const AITopicBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const AITopicSectionBlock = styled.div`
  background: #fff;
  overflow: auto;
  overflow-x: hidden;
`;

const Add = ({ onClickSave, client, onClickGoback, id, isEdit, popupMode }) => {
  const [aiTopic, setAITopic] = useState({});
  const [values, setValues] = useState({});
  const [activeSearch, setActiveSearch] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    topic: null,
    folder_type: userInfoVar()?.type === 4 ? 'publisher' : userInfoVar()?.type === 2 ? 'campus' : 'me' ,
  });

  const [getAITopicData, { data }] = useLazyQuery(AI_TOPICS_SINGLE);
  const [createAITopic] = useMutation(CREATE_AI_TOPIC);
  const [editAITopic] = useMutation(EDIT_AI_TOPIC);

  useEffect(() => {
    if (id) {
      getAITopicData({
        variables: {
          idx: id,
        },
      });
    }

    if (data?.aiTopicsSingle) {
      setAITopic(data?.aiTopicsSingle);

      setInitialFormValues({
        topic: data?.aiTopicsSingle?.topic,
        folder_type: data?.aiTopicsSingle?.company_idx ? "publisher" : data?.aiTopicsSingle?.campus_idx ? "campus" : "me",
      });
    }
  }, [id, data, getAITopicData]);

  const [mainform] = Form.useForm();

  useEffect(() => {
    mainform.setFieldsValue(initialFormValues);
  }, [mainform, initialFormValues]);

  const onFinish = (data) => {
    client.clearStore();
    client.cache.gc(); 
    if(isEdit===true){
      handleAITopicEdit({ ...data, ...values});
    }else{
    handleAITopicCreate({ ...data, ...values});
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleAITopicCreate = async (data) => {
    try {
      await createAITopic({ variables: data });
      window.location = '/ai-topic/list?refresh=true';
    } catch (e) {
      console.log(e);
    }
  };

  const handleAITopicEdit = async (data) => {
    try {
      await editAITopic({ variables: { ...data, idx: parseInt(id) } });
      window.location = '/ai-topic/list?refresh=true';
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <AITopicMenuGroup currentMenu="ai-topic-management" />
      <Col className="addnew-ai-topicmanagment" span={24}>
        <AITopicBlock className="addnew-ai-topicmanagment-ai-topicblock spacetop-ai-topicmanagment">
          <Row gutter={[24, 16]}>
            <Col span={10} className="aiTopicmanagment-sectiona">
              <AITopicSectionBlock>
                {!popupMode && (
                  <Title level={5}>
                    { isEdit ? 'AI Topic 수정하기' : 'AI Topic 추가하기' }
                  </Title>
                )}

                <Form
                  form={mainform}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  layout="horizontal"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={initialFormValues}
                  style={{
                    minHeight: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}
                >

                  <Form.Item
                    label={'주제'}
                    name="topic"
                    required={true}
                    style={{ marginBottom: 5 }}
                  >
                    <TextArea required={true} name="topic" />
                  </Form.Item>
                  <Form.Item
                    name="folder_type"
                    label={'폴더 유형'}
                    style={{ marginBottom: 5 }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: 150, color: '#065aa9' }}
                      placeholder="폴더 유형 선택"
                      optionFilterProp="children"
                    >{console.log(userInfoVar()?.type)}
                      {userInfoVar()?.type === 4 && <Select.Option value="publisher">본사 컨텐츠</Select.Option>}
                      {userInfoVar()?.type === 2 && <Select.Option value="campus">학원 컨텐츠</Select.Option>}
                      {userInfoVar()?.type !== 4 && <Select.Option value="me">개인 컨텐츠</Select.Option>}
                    </Select>
                  </Form.Item>

                  <div className="btnfordesktoponly-add">
                    <Col
                      className="wrapcaccelsave-btn"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 20,
                      }}
                    >
                      <Button type="outline" danger shape="round" onClick={onClickGoback} size="large">
                        Cancel
                      </Button>
                        <Button type="primary" onClick={onFinish} htmlType="submit" shape="round" size="large">
                        저장하기
                        </Button>
                    </Col>
                  </div>
                </Form>
              </AITopicSectionBlock>
            </Col>
            <div className="btnformobileonly-add">
              <Col
                className="wrapcaccelsave-btn"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <Button type="outline" danger shape="round" onClick={onClickGoback} size="large">
                  Cancel
                </Button>
                <Button onClick={() => mainform.submit()} type="primary" htmlType="submit" shape="round" size="large">
                  Save
                </Button>
              </Col>
            </div>
          </Row>
        </AITopicBlock>
      </Col>
    </>
  );
};

const WithApolloClient = (props) => <ApolloConsumer>{(client) => <Add client={client} {...props} />}</ApolloConsumer>;

export default WithApolloClient;
