import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { DELIVERY_STATUS_STATUS_COLOR_LIST } from 'src/constants/common';

const StatusButton = ({ delivery_status, children, ...rest }) => {
  return (
    <AdminDeliveryStatusButtonWrapper delivery_status={delivery_status}>
      <Button size="small" type="primary" {...rest}>
        {children}
      </Button>
    </AdminDeliveryStatusButtonWrapper>
  );
};
export default StatusButton;

const AdminDeliveryStatusButtonWrapper = styled.div`
  .ant-btn-primary {
    background-color: ${(props) =>
      DELIVERY_STATUS_STATUS_COLOR_LIST[props?.delivery_status] ? DELIVERY_STATUS_STATUS_COLOR_LIST[props?.delivery_status] : '#a61e4d'};
    border-color: ${(props) =>
      DELIVERY_STATUS_STATUS_COLOR_LIST[props?.delivery_status] ? DELIVERY_STATUS_STATUS_COLOR_LIST[props?.delivery_status] : '#a61e4d'};
    width: 80px;
  }
`;
