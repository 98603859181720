import React, { useEffect, useMemo, useState } from 'react';
import { InputNumber } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import * as mutations from 'src/operations/mutations';
import { openNotification } from 'src/components/common/Notification';
import fileDownload from 'js-file-download';
import TestResultClassWise from './testResultClassWise';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Upload, Modal } from 'antd';

const TestResultTable = ({ columns, dataSource, scoreEdit }) => {
  const history = useHistory();
  const { idx, classIdx } = useParams();
  const [testDetail, setTestDetail] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [testIdx, setTestIdx] = useState();
  const [showTestResult, setShowTestResult] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [testTitle, setTestTitle] = useState();
  const [initialFormValues, setInitialFormValues] = useState({
    files: [],
    test_idx: '',
  });
  const test = [];
  const [form] = Form.useForm();
  const [updateTestScore] = useMutation(mutations.updateTests.UPDATE_TEST_SCORE, {
    onCompleted: () => {
      openNotification('수정 완료!');
    },
  });
  const [updateTestScoreByExcel] = useMutation(mutations.updateTests.UPDATE_TEST_SCORE_BY_EXCEL, {
    variables: { ...initialFormValues },
    onCompleted: () => {
      openNotification('수정 완료!');
      setTimeout(() => {
        setModalVisible(false);
      }, 1000);
      setInitialFormValues({
        files: [],
        test_idx: '',
      });
    },
  });

  const props = {
    multiple: true,
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      setInitialFormValues((prev) => {
        const index = prev.files.indexOf(file);
        const newFileList = prev.files.slice();
        newFileList.splice(index, 1);

        return {
          files: newFileList,
          test_idx: testIdx,
        };
      });
    },
    beforeUpload: (file) => {
      setFileList((prev) => [...prev, file]);
      setInitialFormValues((prev) => {
        return {
          files: [...prev.files, file],
          test_idx: testIdx,
        };
      });
      return false;
    },
    fileList,
  };

  const handleChange = (val, key, i) => {
    const tempTestDetail = [...testDetail];
    tempTestDetail[key].test[i].score = val;
    setTestDetail(tempTestDetail);
  };
  useEffect(() => {
    setTestDetail(dataSource);
  }, [dataSource]);

  const saveData = () => {
    updateTestScore({
      variables: {
        testDetail: JSON.stringify(testDetail),
      },
    });
  };
  const handleClick = (val, title) => {
    setModalVisible(true);
    setTestIdx(val);
    setTestTitle(title);
    // setShowTestResult(true)
  };

  useMemo(() => {
    if (fileList.length > 0 && testIdx) {
      updateTestScoreByExcel();
      // updateTestScoreByExcel({
      //     variables: {
      //         file: fileList[0],
      //         test_idx :testIdx
      //     }
      // })
    }
  }, [fileList, testIdx]);

  const handleDownload = () => {
    (async () => {
      const blob = await fetch('https://cdn.cloubot.com/common/daily-test-answer-upload-sample.xlsx').then((r) => r.blob());
      fileDownload(blob, 'sample.xlsx');
    })();
  };
  return (
    <>
      {showTestResult ? (
        history.push(`/daily/test/result/${idx}/${classIdx}/${testIdx}`)
      ) : (
        <>
          <table
            id="assignTable"
            className="booktable-main-tbl test-result-main-tbl mytable_custome assign-table"
            style={{ marginBottom: 10 }}
          >
            <tr>
              {columns &&
                columns.map((ele, i) => {
                  return (
                    <th key={`${i}`} className= { ele?.dataIndex === 'Test' ? "test-pointer" : ''} onClick={() => (ele?.dataIndex === 'Test' ? handleClick(ele.key, ele.title) : '')}>
                      {ele.title}
                    </th>
                  );
                })}
            </tr>
            {testDetail &&
              testDetail.map((item, key) => (
                <tr key={`level-row-key${key}`}>
                  <>
                    <td>{item.No}</td>
                    <td>{item.name}</td>
                    <td>{item.id}</td>
                    <td>{item.comp}</td>
                    <td>{item.avg_tries}</td>
                    {columns?.map((e, indx) => {
                      return (
                        <>
                          {e.dataIndex === 'Test'
                            ? item?.test?.map((ele, i) => {
                                if (e.key === ele.daily_test_idx) {
                                  return scoreEdit ? (
                                    <td>
                                      <InputNumber onChange={(e) => handleChange(e, key, i)} value={ele?.score} />{' '}
                                    </td>
                                  ) : (
                                    <td>{ele?.score === null ? 0 : ele.score}</td>
                                  );
                                }
                              })
                            : ''}
                        </>
                      );
                    })}
                  </>
                </tr>
              ))}
            <tr>
              <td>Avg</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {columns?.map((item) => {
                if (
                  item.title !== 'No' &&
                  item.title !== 'Name' &&
                  item.title !== 'Id' &&
                  item.title !== 'Comp' &&
                  item.title !== 'Avg. Tries'
                ) {
                  let curLength;
                  const total = testDetail?.reduce((acc, current) => {
                    curLength = current.test?.length;
                    curLength = testDetail?.length;
                    return Number(current.test[item.i]?.score) + acc;
                  }, 0);
                  return <td>{total / curLength}</td>;
                }
              })}
            </tr>
          </table>
          <div className="table-btn-wrapper">
            {scoreEdit ? (
              <>
                <Button className="btn-auto-download" onClick={handleDownload}>
                  Auto Correction Excel Template Download
                </Button>
                <Button className="btn-save" onClick={saveData}>
                  Save
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>

          {modalVisible ? (
            <Modal
              title={testTitle}
              centered
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
              }}
              footer={[]}
              className="paperTestPopup cust-assigned-list-popup"
            >

              <div className='assign-upload-btn-grp'>
              <Upload {...props} name="files" className='upload-btn' accept=".xlsx">
                <Button icon={<UploadOutlined />}>Click to uplodad</Button>
              </Upload>
              <Button key="back" onClick={() => (setShowTestResult(true), setModalVisible(false))} className='view-btn'>
                View the Test Result
              </Button>
              </div>
            </Modal>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default TestResultTable;
