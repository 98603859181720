import React from 'react';
import { userInfoVar } from 'src/apollo/cache';
import ReportCardAdmin from './ReportCardAdmin';
import ReportCardStudent from './ReportCardStudent';

const ReportCard = () => {
  const type = userInfoVar()?.type;
  return type > 0 ? <ReportCardAdmin /> : <ReportCardStudent />;
};

export default ReportCard;
