import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typographym, Button } from 'antd';
import { PlayCircleFilled, SoundFilled, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';

import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import VocaHeader from './VocaHeader';

const VocaUnscramble = () => {
  const wordbox = [
    'I live in a big house.',
    'I live in a big house.',
    'I live in a big house.',
    'I live in a big house.',
    'I live in a big house.',
    'I live in a big house.',
    'I live in a big house.',
    'I live in a big house.',
    'I live in a big house.',
    'I live in a big house.',
  ];

  const repeatNum = [1, 2];

  return (
    <>
      <div className="unscramble-con">
        <div className="question-con">
          <div className="inner top">
            <div className="progress-bar">
              <div className="bar"></div>
              <div className="num">
                <h3>
                  <span>05</span>/<span>10</span>
                </h3>
              </div>
            </div>
          </div>

          <div className="inner bottom">
            <h1>글씨를 보고 맞는 위치에 드래그해서 넣어보세요.</h1>

            <h1 className="tit-kr">나는 고양이 한 마리가 있어요.</h1>
            <div className="answer-sheet-box">
              <div className="top">
                <div className="inner">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div className="bottom">
                <div>
                  <h3>I</h3>
                </div>
                <div>
                  <h3>a cat</h3>
                </div>
                <div>
                  <h3>have</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VocaUnscramble;
