import React from 'react';
import _ from 'lodash';
import reactStringReplace from 'react-string-replace';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const SentenceImageSelectBox = ({ stepProblem, onChangeWord, selectedWord }) => {
  const handleImageSelect = (word) => {
    onChangeWord(word);
  };
  if (_.isNil(stepProblem)) return false;
  return (
    // <div className="word_wrap">
    // 	<p>
    // 		{reactStringReplace(stepProblem.sentenceFormat, "##word##", match => {
    // 			if (match) {
    // 				return (
    // 					<span className="word_img_box">
    // 						{stepProblem.words &&
    // 							stepProblem.words.map((item, key) => (
    // 								<ImageBox
    // 									key={`sentece-image-${key}`}
    // 									imageUrl={`${stepProblem.imageBaseUrl}image_${item}.png`}
    // 									item={item}
    // 									onImageSelect={handleImageSelect}
    // 									active={selectedWord === item}
    // 								/>
    // 							))}
    // 					</span>
    // 				);
    // 			}
    // 		})}
    // 	</p>
    // </div>

    <WordWrap className="word_wrap">
      {reactStringReplace(stepProblem.sentenceFormat, '##word##', (match) => {
        if (match) {
          return (
            <>
              {stepProblem.words &&
                stepProblem.words.map((item, key) => (
                  <ImageBox
                    key={`sentece-image-${key}`}
                    imageUrl={`${stepProblem.imageBaseUrl}image_${item}.png`}
                    item={item}
                    onImageSelect={handleImageSelect}
                    active={selectedWord === item}
                  />
                ))}
            </>
          );
        }
      })}
    </WordWrap>
  );
};

export default SentenceImageSelectBox;

const ImageBox = ({ imageUrl, item, active, onImageSelect }) => {
  return (
    // <img
    // 	className={active ? "active" : ""}
    // 	src={imageUrl}
    // 	alt={item}
    // 	onClick={() => {
    // 		onImageSelect(item);
    // 	}}
    // />
    <ul style={{ display: 'flex', margin: '0 8px', listStyle: 'none', padding: 0 }} className="img_box">
      <li style={{ overflow: 'hidden', margin: '0.4rem', borderRadius: '1.6rem' }}>
        <WordBtn
          type="button"
          className={active ? 'active' : ''}
          active={active}
          onClick={() => {
            onImageSelect(item);
          }}
        >
          <Image  src={imageUrl} alt={item} />
        </WordBtn>
      </li>
    </ul>
  );
};

const WordWrap = styled(S.RowFlex)`
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 32px;
`;
const WordBtn = styled.button`
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 1.6rem;
  border-radius: 1.6rem;
  border: 0.3rem solid ${props=>props.active?'#feca57':'#e5e5e5'} ;
`;
const Image = styled.img`
height: 16vh;
@media (max-height: 720px){
height: 12vh;
    }
`;