import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typography } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import VocaHeader from './VocaHeader';
import VocaFlashcard from './VocaFlashcard';
import VocaCheck from './VocaCheck';
import VocaFlashcardTyping from './VocaFlashCardTyping';
import VocaQuiz from './VocaQuiz';
import VocaKS from './VocaKS';
import VocaUnscramble from './VocaUnscramble';
import VocaDictation from './VocaDictation';
import VocaTypeA from './VocaTypeA';
const VocaTool = () => {
  return (
    <>
      <section className="vocatool-con">
        <VocaHeader />
        <div className="vocatool-body">
          <div className="innner">
            <VocaFlashcard />
          </div>
          <div className="progress-con">
            <div className="inner">
              <div className="pentagon first  active">
                <h3>FI</h3>
              </div>
            </div>

            <div className="inner">
              <div className="pentagon ">
                <h3>Ch</h3>
              </div>
            </div>

            <div className="inner">
              <div className="pentagon">
                <h3>Qz</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VocaTool;
