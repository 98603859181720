import React, { useState, useEffect } from 'react';
import { Typography, Button, Image } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
const { Title } = Typography;
const MenuWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
  margin-left: 15px;
`;
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;
const RecommandBody = styled.div`
  border-radius: 10px;
  background: white;
  width: 100%;
  min-height: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const RecommandTitle = styled(Title)`
  padding: 20px 0 30px;
`;
const RecommandBox = styled.div`
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;
const Recommand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
`;
export default function FAQ() {
  const [page, setPage] = useState(0);
  // let title = page === 0 ? '학습 전 체크사항' : page === 1 ? '자주 묻는 질문' : '자주 묻는 질문 - Phonics';
  const deviceFAQ = [
    {
      q: 'AI학습/Fluency 학습이 제대로 진행되지 않아요. (마이크 이용하는 학습이 잘 안되는 경우)',
      a: '▶크롬 브라우저 사용시\n 설정 -> 개인정보 및 보안 -> 사이트설정 -> 마이크 허용\n※주소창에서 확인 방법 : 주소창의 자물쇠를 클릭 -> 마이크가 활성화(파란색) 되었는지 확인합니다. 비활성화(흑백)되어 있으면 활성화하세요.\n▶엣지 브라우저 사용시\n설정 -> 쿠키 및 사이트 권한 -> 마이크 허용\n마이크 연결에 이상이 없는 경우 캐시 삭제 후 다시 진행해 보세요. 캐시 삭제 방법은 아래와 같습니다.\n▶크롬 브라우저 사용시\n설정 -> 개인정보 및 보안 -> 인터넷 사용 기록 삭제(탭 열리면 기간을 전체 기간으로 설정한 후 전체 항목을 선택한 다음 삭제)',
    },

    {
      q: '스테이지 풍선이 열리지 않거나 학습 중에 끊김 현상이 있어요.',
      a: 'Creo-bot 숙제(학습창)는 네트워크 환경이 원활해야 정상 접속 및 사용이 가능합니다. 그 외, 기기 사양, 브라우저 등 여러 가지 요인이 있을 수 있습니다. 기기에서 많은 앱을 동시에 실행하고 있거나, 인터넷 앱 내에서 많은 탭이 동시 실행되고 있다면, 기기의 작동이 느려질 수 있습니다. 이 경우, 동시 실행중인 다른 앱/탭을 닫은 후 CREO-BOT을 재실행 해보시길 바랍니다. 또한, 모바일(태블릿 등)의 경우, 와이파이의 영향이 크기 때문에 공유기와 가까운 곳에서 다시 학습할 수 있도록 합니다.',
    },

    {
      q: '갑자기 화면이 흰 색으로 보여요.',
      a: '이는 학습기기 혹은 브라우저의 일시적인 문제일 수 있습니다. 새로 고침(F5) 하거나, 창(또는 앱)을 껐다가 켜서 다시 실행해주시길 바랍니다.',
    },

    {
      q: 'Small Talk를 하는데 이미지가 안 보이고 흰 화면만 나와요.',
      a: '두가지 경우일 수 있습니다: ①일시적인 문제, ②데이터 오류 이 경우 새로고침(F5) 혹은 창(또는 앱)을 껐다 켠 후 재실행 해보도록 하시고 그래도 오류가 지속된다면 문의 전화바랍니다.',
    },

    {
      q: 'Role Play에서 말을 했는데 영어가 안 보이고 한글이 보여요.',
      a: "브라우저에서 자동 번역 기능이 켜져 있는지 확인해 주세요. 특히 엣지 브라우저에서 간혹 발생합니다. 주소창 우측에 있는 번역 아이콘을 클릭하여 '항상 페이지를 번역합니다'에 체크가 되어있다면, 체크를 풀어주세요.",
    },

    {
      q: 'AI학습 하는데, 레슨과 관련 없는 다른 문장이 나와요. (small talk/key sentences-listen/pattern drills/role-play)',
      a: '일시적인 네트워크 혹은 브라우저의 오류로 발생할 수 있습니다. 이 때는 새로 고침(F5)하거나, 인터넷창(혹은 인터넷 앱)을 껐다가 다시 켠 후 재시도 해보세요. 혹은 캐시를 삭제한 후 다시 실행해 보도록 안내해 주세요.',
    },

    {
      q: 'AI학습 중, 계속 처음으로 돌아가요. (small talk/key sentences-listen/pattern drills/role-play)',
      a: '두가지 경우가 있습니다: ①AI버튼 기능 미숙지, ②학습기기 및 학습 환경으로 인한 일시적 오류 우선, AI버튼의 기능을 숙지했는지 확인바랍니다. AI 버튼은, 경우에 따라 처음부터 다시 시작합니다. 두번째로, 학습기기 및 학습 환경을 확인합니다. 기기에서 많은 앱을 동시에 실행하고 있거나, 인터넷 앱 내에서 많은 탭이 동시 실행되고 있다면, 기기의 작동이 느려질 수 있습니다. 이 경우, 동시 실행중인 다른 앱/탭을 닫은 후 CREO-BOT을 실행할 수 있도록 합니다. 또한, 모바일(태블릿 등)의 경우, 와이파이의 영향이 크기 때문에 공유기와 가까운 곳에서 다시 학습할 수 있도록 합니다.',
    },

    {
      q: 'AI 인식이 안 돼요. (small talk/key sentences-listen/pattern drills/role-play)',
      a: 'PC의 경우, 마이크 연결 관련하여 체크를 해주세요. 모바일(태블릿)의 경우, 앱 권한 설정 > 마이크가 활성화 되었는지 확인해 주세요. 와이파이가 잘 되도록, 최대한 공유기와 가까운 곳에서 진행하도록 안내해 주세요.',
    },
  ];
  const studyFAQ = [
    {
      q: 'Fluency 학습에서 MY WPM에 이상한 표시(NaN 혹은 infinity)가 떠요.',
      a: 'Fluency 학습 시, 녹음 버튼을 한 번 누르면 녹음 시작, 다시 한 번 누르면 녹음 종료가 됩니다. 너무 빨리 두 번을 연속 눌렀을 경우 총 녹음 시간이 0초가 되는데, 이 때 My WPM에 다음과 같은 표시가 뜨게 됩니다. 이는 제대로 녹음이 되지 않았다는 의미입니다. 이 경우, 녹음 버튼을 천천히 눌러서 진행해주시길 바랍니다.',
    },
    {
      q: 'Fluency(녹음) 숙제를 완료하고 종료했는데 미완료라고 나와요. (풍선색이 바뀌지 않아요)',
      a: '녹음을 했다면, MY WPM 과 Accuracy에 수치(결과)가 남아있을 것입니다. 학습 시 녹음을 했음에도 완료로 되지 않은 이유는, 전송버튼을 클릭하지 않고 x버튼을 눌러 창을 닫았기 때문입니다. 이 경우 전송 버튼을 클릭해야만 학습 완료가 됩니다. 만약, 녹음기록이 없다면 녹음을 진행하지 않은 것입니다. 이 때는, 마이크 연결 여부를 체크해 주세요. 그리고, 녹음을 완벽히 진행 후, 전송 버튼을 클릭하여 종료할 수 있도록 해주시길 바랍니다.',
    },
    {
      q: 'AI 문장 인식이 잘 안 돼요 (잘려서 인식되거나, 전체 이상하게 인식)',
      a: '너무 빨리 답변하거나, 너무 빨리 버튼을 눌러 답변을 종료하는 경우 AI인식에 문제가 있을 수 있습니다. 학습 시 중앙 버튼이 마이크 모양으로 변하고, 1초 뒤 답변하세요. 답변 후에 시간이 남았다면, 1초 기다린 후 버튼을 눌러 답변을 종료하시면 원활한 학습이 가능합니다.',
    },

    {
      q: 'AI학습 중, 뒤로가기 버튼을 눌렀는데, AI가 마지막 멘트를 하며 학습이 종료돼요.',
      a: '버튼을 너무 빨리 눌렀기 때문에 생기는 오류 입니다. 보통 버튼을 클릭하거나 답변을 하면 AI가 반응을 하는데, AI가 반응하기 전에 버튼을 여러 번 클릭하게 되면, AI가 오류를 일으킬 수 있습니다. 버튼을 누른 후 AI가 반응을 할 때까지 기다리고, AI가 반응하면 그 때 다시 버튼을 클릭해주시길 바랍니다.',
    },

    {
      q: "학습을 모두 완료했고, 모든 풍선 색깔도 회색으로 변한 것을 확인했으나, LMS(대시보드)에서는 '미완료'로 표시돼요",
      a: "학습기기 및 환경에 따라, 학습완료로 곧바로 바뀌지 않는 경우가 있습니다. 이 경우, 학습기기에 따라 아래와 같이 진행해 주십시오.\n[PC] 새로고침(F5) 혹은 로그아웃 후 재로그인한 후 확인\n[모바일(태블릿)] 실행 중인 앱을 모두 닫았다가 다시 켠 후 확인\nLMS에 '미완료'로 표시가 되어 있고, 학습창에서 풍선이 회색이 아닌 곳이 있다면, 해당 스테이지의 학습을 완료하지 않은 것입니다. 이 경우 해당 스테이지를 다시 완료해주시길 바랍니다.",
    },

    {
      q: "학생이 모든 학습을 완료했는데, '미완료'로 모두 표시돼요.",
      a: '해당 오류가 발생했을 시에는 아래의 정보가 필요합니다. 기본 정보(계정/캠퍼스/학습일/사용기기/레슨명 등) + 학습을 진행한 정확한 시간 해당 정보를 알려주시면 학습 기록을 조회하여 수정하도록 하겠습니다. 하지만 서버에 해당 정보에 대한 기록이 남아있지 않다면, 추가 확인이 어렵고, 학습 여부를 확인할 수가 없습니다. 이 경우 해당 학생의 기록을 모두 완료 처리해드리겠습니다.',
    },
  ];
  const phonicsFAQ = [
    {
      q: '스테이지 내 활동 중, 다음 step으로 넘어가지 않아요',
      a: '현재 step에서 전체 완료를 하지 않았기 때문에, 다음 step으로 넘어가지 않습니다. 반드시 전체 완료를 하시고 다음 스테이지를 진행해 주시길 바랍니다.',
    },

    {
      q: '쓰기(writing) 부분이 저절로 넘어가거나, 타이핑 방식으로 자동 변경돼요.',
      a: '두 가지 경우가 있습니다.\n①학습 화면에 너무 오래 머물렀을 경우\n②동시 접속자가 많은 경우\n필기(writing)를 진행하는 학습 화면에 너무 오래 머무를 경우, 필기가 정상적으로 인식되지 않을 수 있습니다.\n또한, 동시 접속자가 많은 경우에도 발생할 수 있습니다.\n이 경우, 필기 인식 문제를 방지하기 위하여 자동으로 필기부분이 넘어가거나, 타이핑 방식으로 전환이 될 수 있습니다. 위 사항들은 오류가 아니니 학습을 계속 진행해주셔도 좋습니다. 하지만 학습 화면에서 너무 오래 머무르지 않도록 부탁드리겠습니다.',
    },

    {
      q: 'Phonics Monster 학습 시 딕테이션 시간이 부족합니다.',
      a: 'Phonics Monster는 딕테이션 파트에 시간 제한이 없습니다. 녹음 버튼을 누르고 따라 읽은 뒤 정지 버튼을 누르면 녹음이 완료됩니다.',
    },
  ];
  return (
    <div style={{ height: '100%', paddingTop: 10 }}>
      <MenuWrap>
        <MenuButton
          type={`${page === 0 ? 'primary' : 'default'} `}
          onClick={() => {
            setPage(0);
          }}
        >
          학습 전 체크사항
        </MenuButton>
        &nbsp;
        <MenuButton
          type={`${page === 1 ? 'primary' : 'default'} `}
          onClick={() => {
            setPage(1);
          }}
        >
          FAQ - 기기문제
        </MenuButton>
        &nbsp;
        <MenuButton
          type={`${page === 2 ? 'primary' : 'default'} `}
          onClick={() => {
            setPage(2);
          }}
        >
          FAQ - 학습오류
        </MenuButton>
        &nbsp;
        <MenuButton
          type={`${page === 3 ? 'primary' : 'default'} `}
          onClick={() => {
            setPage(3);
          }}
        >
          FAQ - Phonics
        </MenuButton>
      </MenuWrap>

      {page ? (
        <Accordion data={page === 1 ? deviceFAQ : page === 2 ? studyFAQ : phonicsFAQ} />
      ) : (
        <RecommandBody>
          {/* <RecommandTitle> */}
          <RecommandTitle level={2}>학습 전 최적의 학습 조건을 확인하여 진행해 주시길 바랍니다.</RecommandTitle>
          {/* </RecommandTitle> */}
          <RecommandBox>
            <Recommand>
              <Image preview={false} src={'/images/faq/os.png'} style={{ maxWidth: '250px' }} />
              <RecommandTitle level={4}>OS : Windows10</RecommandTitle>
            </Recommand>
            <Recommand>
              <Image preview={false} src={'/images/faq/browser.png'} style={{ maxWidth: '200px' }} />
              <RecommandTitle level={4}>Browser : Chrome</RecommandTitle>
            </Recommand>
            <Recommand>
              <Image preview={false} src={'/images/faq/sound.png'} style={{ maxWidth: '250px' }} />
              <RecommandTitle level={4}>Sound Check</RecommandTitle>
            </Recommand>
            <Recommand>
              <Image preview={false} src={'/images/faq/mic.png'} style={{ maxWidth: '50px' }} />
              <RecommandTitle level={4}>Mike Check</RecommandTitle>
            </Recommand>
          </RecommandBox>
        </RecommandBody>
      )}
    </div>
  );
}
const AccordionWrapper = styled.div`
  border-radius: 10px;
  margin: 0 auto;
  width: 70%;
  min-width: 800px;
  max-width: 1300px;
  `;
const AccordionItem = styled.div`
  margin-bottom: 10px;
`;

const AccordionTitle = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background-color: #e0e0e0;
  }

  ${({ active }) =>
    active &&
    `
    border-radius: 10px 10px 0 0;
    background-color: #ccc;
  `}
`;

const AccordionContent = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  line-height: 160%;
`;
const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  useEffect(() => {
    setActiveIndex(null);
  }, [data]);
  return (
    <AccordionWrapper>
      {data.map((item, index) => (
        <AccordionItem key={index + 1}>
          <AccordionTitle active={activeIndex === index} onClick={() => handleClick(index)}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>{index + 1}.</strong> {item.q}
              </div>
              <DownCircleOutlined rotate={activeIndex === index && 180} />
            </div>
          </AccordionTitle>
          {activeIndex === index && (
            <AccordionContent>
              {item.a.split('\n').map((e, i) => (
                <div key={i}>{e}</div>
              ))}
            </AccordionContent>
          )}
        </AccordionItem>
      ))}
    </AccordionWrapper>
  );
};
