import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Space, DatePicker, Select, Input, Switch } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_META_STATICS_COMPANY_CAMPUS } from 'src/operations/queries/company';
import { META_COMPANY_STATICS_DETAILS } from 'src/operations/queries/consult';
import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcelUserSheet } from 'src/utils/index';
import UseHistoryState from 'src/components/common/UseHistoryState';
const MetaExamStaticsReadCloubot = () => {
  const [searchText, setSearchText] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [companyIdx, setCompanyIdx] = useState(-1);
  const [filterType, setFilterType] = useState('reserve_date');
  const [localState, setLocalState] = UseHistoryState({
    companyIdx: -1,
    filterType: 'reserve_date',
    selectedDate: new Date().toISOString().split('T')[0],
  });
  const { data: dataCompany, refetch } = useQuery(GET_META_STATICS_COMPANY_CAMPUS, {
    skip: !localState.companyIdx || localState.companyIdx === -1,
    variables: {
      company_idx: localState.companyIdx,
      selected_date: localState.selectedDate ? localState.selectedDate : '',
    },
    fetchPolicy: 'no-cache',
  });
  const { data: monthlyData, refetch: refetchMonthly } = useQuery(META_COMPANY_STATICS_DETAILS, {
    skip: !localState.companyIdx || localState.companyIdx === -1 || !localState.selectedDate,
    variables: {
      company_idx: localState.companyIdx,
      gettype: 'month',
      fetchtype: localState.selectedDate ? localState.selectedDate.slice(0, -3) : '',
      filter_type: localState.filterType,
    },
  });
  const StaticsRes = dataCompany ? JSON.parse(dataCompany?.getCampusMetaStatics) : '';
  // useEffect(() => {
  //   let d = new Date().toISOString();
  //   setLocalState((prev)=>{
  //     return {...prev,selectedDate: new Date().toISOString().split('T')[0]}
  //   });
  // }, []);

  useEffect(() => {
    refetch();
  }, [localState.selectedDate, localState.companyIdx]);

  const handleDateChange = (date, string) => {
    setLocalState((prev) => {
      return { ...prev, selectedDate: string };
    });
  };

  const dataSource = useMemo(() => {
    if (StaticsRes) {
      const sum = StaticsRes.reduce(
        (acc, cur) => {
          return {
            day: acc.day + cur.day,
            month: acc.month + cur.month,
            quaterYear: acc.quaterYear + cur.quaterYear,
            year: acc.year + cur.year,
            total: acc.total + cur.total,
          };
        },
        { day: 0, month: 0, quaterYear: 0, year: 0, total: 0 },
      );
      sum.campus_name = '합계';
      sum.idx = '';
      return [sum, ...StaticsRes];
    }
    return StaticsRes;
  }, [StaticsRes]);
  const handleSearch = (e) => {
    if (searchText.trim() === '') {
      setFilteredList([]);
      alert('검색어를 입력하세요.');
      return false;
    }
    const tmp = dataSource.filter((e) => e.campus_name.includes(searchText));
    setFilteredList(tmp);
    // setSearchText(e.target.value)
  };
  useEffect(() => {
    if (searchText.length === 0) {
      setFilteredList([]);
    }
  }, [searchText]);
  const MetaStaticsTable = styled(CustomTable)`
    border-top: 3px solid #a4d0e9;
    thead[class*='ant-table-thead'] th {
      border-left: 1px solid #a4d0e9;
      border-top: 1px solid #a4d0e9;
    }
  `;
  const getColumnHeading = () => {
    let lessonHeading = [];
    lessonHeading.push({
      title: 'No',
      dataIndex: 'idx',
      align: 'center',
      width: 40,
      render: (text, record) => {
        return <Space size="middle">{record.idx + 1}</Space>;
      },
    });
    lessonHeading.push({
      title: 'Academy',
      dataIndex: 'campus_name',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{record.campus_name}</Space>;
      },
    });
    lessonHeading.push({
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{record.total}</Space>;
      },
    });
    lessonHeading.push({
      title: 'Year',
      dataIndex: 'year',
      align: 'center',
      width: 100,
      render: (text, record, key) => {
        const yeardate = localState.selectedDate.split('-');

        if (!record?.campus_idx) {
          return (
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <div style={{ textAlign: 'left' }}>{record.year}</div>
            </Space>
          );
        }
        return (
          <>
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <Link
                to={{
                  pathname: `/meta-statics/details/year/${record?.campus_idx}/${yeardate[0]}`,
                  state: { campus_name: record?.campus_name, yeardate: yeardate[0], isCreo: localState.companyIdx === 1010 },
                }}
              >
                <div style={{ textAlign: 'left' }}>{record.year}</div>
              </Link>
            </Space>
          </>
        );
      },
    });
    lessonHeading.push({
      title: 'Quarter Year',
      dataIndex: 'quaterYear',
      align: 'center',
      width: 100,
      render: (text, record, key) => {
        if (!record?.campus_idx) {
          return (
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <div style={{ textAlign: 'left' }}>{record.quaterYear}</div>
            </Space>
          );
        }
        return (
          <>
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <Link
                to={{
                  pathname: `/meta-statics/details/quateryear/${record?.campus_idx}/${localState.selectedDate}`,
                  state: { campus_name: record?.campus_name, yeardate: localState.selectedDate, isCreo: localState.companyIdx === 1010 },
                }}
              >
                <div style={{ textAlign: 'left' }}>{record.quaterYear}</div>
              </Link>
            </Space>
          </>
        );
      },
    });
    lessonHeading.push({
      title: 'Month',
      dataIndex: 'month',
      align: 'center',
      width: 100,
      render: (text, record, key) => {
        const monthdate = localState.selectedDate.split('-');
        if (!record?.campus_idx) {
          return (
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <div style={{ textAlign: 'left' }}>{record.month}</div>
            </Space>
          );
        }
        return (
          <>
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <Link
                to={{
                  pathname: `/meta-statics/details/month/${record?.campus_idx}/${monthdate[0] + '-' + monthdate[1]}`,
                  state: {
                    campus_name: record?.campus_name,
                    yeardate: monthdate[0] + '-' + monthdate[1],
                    isCreo: localState.companyIdx === 1010,
                  },
                }}
              >
                <div style={{ textAlign: 'left' }}>{record.month}</div>
              </Link>
            </Space>
          </>
        );
      },
    });
    lessonHeading.push({
      title: 'Day',
      dataIndex: 'day',
      align: 'center',
      width: 100,
      render: (text, record, key) => {
        if (!record?.campus_idx) {
          return (
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <div style={{ textAlign: 'left' }}>{record.day}</div>
            </Space>
          );
        }
        return (
          <>
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <Link
                to={{
                  pathname: `/meta-statics/details/day/${record?.campus_idx}/${localState.selectedDate}`,
                  state: { campus_name: record?.campus_name, yeardate: localState.selectedDate, isCreo: localState.companyIdx === 1010 },
                }}
              >
                <div style={{ textAlign: 'left' }}>{record.day}</div>
              </Link>
            </Space>
          </>
        );
      },
    });
    return lessonHeading;
  };
  const MetaStaticsChild = getColumnHeading();

  const columns = [
    {
      title: `Users`,
      children: MetaStaticsChild,
    },
  ];
  const downloadData = useMemo(() => {
    if (monthlyData) {
      return monthlyData?.getMetaCompanyStaticsDetails.map((item, key) => {
        const extraData = item?.test_result?.extra_data ? JSON.parse(item?.test_result?.extra_data) : [];
        const type = item?.order_leveltest[0]?.assigned_tests?.tests?.batch_type;
        return {
          campus: item?.campus?.name,
          no: key + 1,
          english_name: item?.name,
          name: item?.user.english_name,
          id: `${moment(item?.user.birthday.split('T')[0]).format('YYMMDD')}-${item?.parent_phone.slice(3)}`,
          stat: item?.stat,
          school_info: item?.school_info,
          type: type,
          mx_level: extraData?.mx || '',
        };
      });
    }
  }, [monthlyData]);
  function handleExportToExcel(e) {
    e.preventDefault();
    if (monthlyData) {
      const fileName = `${localState.selectedDate.slice(0, -3)}Meta Exam statics`;
      const tmpData = downloadData?.filter((e) => e.stat === '1');
      const columnsForExcel = [
        {
          title: 'No',
          align: 'center',
          dataIndex: 'no',
          width: 40,
        },
        {
          title: 'Campus',
          align: 'center',
          dataIndex: 'campus',
        },
        {
          title: 'ID',
          align: 'center',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '이름',
          align: 'center',
          dataIndex: 'name',
          key: 'phone',
        },
        {
          title: '영문이름',
          align: 'center',
          dataIndex: 'english_name',
          key: 'phone',
        },
        {
          title: '학년',
          align: 'center',
          dataIndex: 'school_info',
          key: 'phone',
        },
        {
          title: 'Type',
          align: 'center',
          dataIndex: 'type',
          key: 'phone',
        },
        {
          title: 'mx Level',
          key: 'phone',
          align: 'center',
          dataIndex: 'mx_level',
          width: 100,
        },
      ];
      ExportExcelUserSheet(columnsForExcel, tmpData, fileName);
    } else {
      alert('select company and date');
      return;
    }
  }

  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>MetaExam Statics</HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="learning-halfwrapper learning-hs">
            <div
              className="learning-halfwrapper-top-header"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              <Select
                defaultValue={localState.companyIdx}
                style={{ width: 120 }}
                onChange={(value) => {
                  setLocalState((prev) => {
                    return { ...prev, companyIdx: value };
                  });
                  // setCompanyIdx(value);
                }}
                options={[
                  { label: 'Select', value: -1 },
                  { label: 'Creo', value: 1010 },
                  { label: '@W English', value: 1016 },
                  { label: 'semtlenuri', value: 1007 },
                  { label: 'stsemtle', value: 1021 },
                  { label: 'stalpha', value: 1022 },
                  { label: 'sntadmin', value: 1026 },
                  { label: 'classboom', value: 1020 },
                  { label: 'academy', value: 1029 },
                ]}
              ></Select>

              <div style={{ display: 'flex' }}>
                <Space style={{ marginRight: 10 }}>
                  <a href="/" onClick={handleExportToExcel} className="excel-img">
                    <img style={{ height: '20px', width: '20px' }} src={imageexcel} alt="download" />
                  </a>
                </Space>
                <Space style={{ marginRight: 10, width: 70 }}>
                  <Switch
                    checkedChildren="응시일"
                    unCheckedChildren="배치일"
                    onChange={(checked) =>
                      setLocalState((prev) => {
                        return { ...prev, filterType: checked ? 'completed_date' : 'reserve_date' };
                      })
                    }
                    defaultChecked={localState.filterType === 'completed_date'}
                  />
                </Space>
                <Space style={{ marginRight: 10, width: 240 }}>
                  <Input.Search
                    placeholder="input search text"
                    value={searchText}
                    // loading={loading}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    enterButton="검색"
                    // size="large"
                    onSearch={handleSearch}
                    allowClear
                  />
                </Space>
                <DatePicker
                  placeholder="월별 검색"
                  value={localState.selectedDate && moment(localState.selectedDate, 'YYYY-MM-DD')}
                  defaultValue={localState.selectedDate && moment(localState.selectedDate, 'YYYY-MM-DD')}
                  onChange={(date, dateString) => {
                    handleDateChange(date, dateString);
                  }}
                />
              </div>
            </div>
            <MetaStaticsTable
              dataSource={filteredList.length > 0 ? filteredList : dataSource}
              columns={columns}
              pagination={true}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </HalfWrapper>
        </Col>
      </Row>{' '}
    </>
  );
};
export default MetaExamStaticsReadCloubot;
