import React from 'react';
import { Form, Row } from 'antd';

import DefaultModal from './DefaultModal';
import CreatePopupForm from 'src/pages/PopupPage/CreatePopupForm';
import { useForm } from 'src/utils/hooks';

function PopupModal({ userInfo, popupMode, handleCancel, ...rest }) {
  const [form] = Form.useForm();

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  return (
    <>
      <DefaultModal
        title="팝업"
        form={form}
        ActionCancel={ActionCancel}
        popupMode={popupMode}
        {...rest}
      >
        <Form
          form={form}
          name="control-ref"
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
        >
          <Row gutter={[16, 16]}>
            <CreatePopupForm />
          </Row>
        </Form>
      </DefaultModal>
    </>
  );
}

export default PopupModal;
