import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Typography, Button } from 'antd';

import CustomTable from 'src/components/common/CustomTable';
import SearchClassGroup, { useSearchStudent, useSearchClass } from 'src/components/ReportCard/SearchClassGroup';
import { useSearchClassBooks } from 'src/components/ReportCard/SearchClassComp';
import { REPORTCARD_LIST_FOR_STUDENT } from 'src/operations/queries/reportcard';
import ReportCardViewModal from 'src/components/Modal/reportcard/ReportCardViewModal';
import ReportCardViewModalAfterSchool from 'src/components/Modal/reportcard/ReportCardViewModalAfterSchool';
//
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isPturn } from 'src/utils/index';
const { Title } = Typography;
const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  {
    key: 'class_name',
    title: '반명',
    dataIndex: 'class_name',
    align: 'center',
  },
  {
    key: 'book_title',
    title: '교재',
    dataIndex: 'book_title',
    align: 'center',
  },
  {
    key: 'regdata',
    title: '성적표 게시일',
    dataIndex: 'regdata',
    align: 'center',
  },
  {
    key: 'view',
    title: '성적표보기',
    dataIndex: 'view',
    align: 'center',
    render: (_, { openReportCardView, reportCardItem }) => {
      return (
        <Button
          type="default"
          onClick={() => {
            openReportCardView(reportCardItem?.class_idx, reportCardItem?.user?.idx, reportCardItem?.user?.name, reportCardItem);
          }}
        >
          성적표 보기
        </Button>
      );
    },
  },
];

const ReportCardListView = () => {
  const [visibleView, setVisibleView] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [studentIdx, setStudentIdx] = useState();
  const [reportCardItem, setReportCardItem] = useState();
  const [selectedClassIdx, setSelectedClassIdx] = useState(0);
  const { selectedUserIdx, onChange: onChangeStudent } = useSearchStudent();
  const { selectedClassValue, onChange: onChangeClass } = useSearchClass();
  const [dateRange, setDateRange] = useState([undefined, undefined]);

  //
  const companyName = useSelector(classStoreData);
  const [classBooks, setClassBooks] = useState([]);
  const [classBookCodes, setClassBookCodes] = useState([]);
  const [getReportList, { data, loading }] = useLazyQuery(REPORTCARD_LIST_FOR_STUDENT, {
    fetchPolicy: 'no-cache',
  });
  //

  const openReportCardView = useCallback((class_idx, user_idx, student_name, report_card_item) => {
    setStudentIdx(user_idx);
    setStudentName(student_name);
    setReportCardItem(report_card_item);
    setSelectedClassIdx(class_idx);
    setDateRange([moment(), moment()]);
    setVisibleView(true);
  }, []);

  useEffect(() => {
    //성적표목록을 불러오자.
    console.log('--->', selectedUserIdx, selectedClassValue);
    if (selectedUserIdx && selectedClassValue) {
      const classList = JSON.parse(selectedClassValue);
      if (classList instanceof Array && classList.length > 0) {
        //가져온다.
        getReportList({ variables: { class_idx: classList, user_idx: selectedUserIdx } });
      }
    }
  }, [selectedUserIdx, selectedClassValue]);
  const dataReport = useMemo(() => {
    const reportList = data?.culpReportCardListForStudent;
    if (reportList) {
      return reportList.map((item, key) => {
        const origin_book = {book:{book_sub:item?.class?.book?.book_sub}, book_code:item?.class?.book_code};
        const books = [origin_book,...item?.class?.class_book]; 
        const uniqueBooks = [...new Set(books.map((item) => item.book_code))].length>0?[...new Set(books.map((item) => item.book_code))]:[];
        const booklist = uniqueBooks.map((code) => books.find((item) => item.book_code === code));
        return {
          key: `report-list${item.idx}`,
          No: key + 1,
          class_name: item?.class?.name,
          book_title: booklist.length > 1 ? `${booklist[0].book.book_sub[0].title} 외 ${booklist.length - 1}권` : item?.class?.book?.book_sub[0]?.title,
          // book_title: item?.class?.book?.book_sub[0]?.title,
          regdata: item?.idate ? moment(item?.idate).format('YYYY.MM.DD') : '',
          classBooks: booklist,
          classBookCodes: Array.from({ length: uniqueBooks.length }, (_, i) => i).legnth>0?Array.from({ length: uniqueBooks.length }, (_, i) => i):[...new Set(item?.class?.book?.book_sub?.map((item) => item.title))],
          openReportCardView,
          reportCardItem: item,
        };
      });
    }
    return [];
  }, [data, openReportCardView]);
  useEffect(() => {
    if (dataReport[0]) {
      setClassBooks(dataReport[0].classBooks);
      setClassBookCodes(dataReport[0].classBooks&&dataReport[0].classBooks.map((_,i)=>i));
    }
  }, [dataReport]);
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={9}>
          <Title level={5}>검색하기</Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ padding: '7px 0' }}>
        {/* {isPturn(companyName) ? (
          <SearchClassGroup onSearchStudentChange={onChangeStudent} hasStudentDropbox={true} onSearchClassChange={onChangeClass} />
        ) : ( */}
          <SearchClassGroup onSearchStudentChange={onChangeStudent} hasStudentDropbox={true} onSearchClassChange={onChangeClass} />
        {/* )} */}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <CustomTable
            dataSource={dataReport}
            columns={columns}
            size="small"
            color="#edf3fb"
            //scroll={{ y: 'calc(100vh - 254px)' }}
            loading={loading}
            pagination={false}
          />
        </Col>
      </Row>
      {isPturn(companyName) ? (
        <ReportCardViewModalAfterSchool
          visible={visibleView}
          onCancel={() => {
            setVisibleView(false);
            // setReportCardItem(undefined);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          reportCardItem={reportCardItem}
          classBookCodes={classBookCodes}
          classBooks={classBooks}
        />
      ) : (
        <ReportCardViewModal
          visible={visibleView}
          onCancel={() => {
            setVisibleView(false);
          }}
          user_name={studentName}
          class_idx={selectedClassIdx}
          user_idx={studentIdx}
          dateRange={dateRange}
          reportCardItem={reportCardItem}
        />
      )}
    </>
  );
};

export default ReportCardListView;
