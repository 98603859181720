import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import WritePopup from '../WritePopup';
import PopUp from '../PopUp';
import PageNum from '../PageNum';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useStudyCreate } from '../../../utils/pm/api';
// import {useMyScript} from "./common/MyScriptHook";
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { getTextInPink, alphabetBoxTypeChecker } from '../../../utils/pm/util';
import styled from 'styled-components';
import * as S from 'src/pages/PhonicsMonster/style';
import { userInfoVar } from 'src/apollo/cache';
//const veryGoodSound = "/sound-effect/very-good.mp3";
const woopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
let bookNameUnitStage = undefined;
let bookNameUnitStageAdd = undefined;
let bookName4Unit5Stage7Add = undefined;
const re = /(\w*)##(\w+)##(\w*)/;

const PhonemeSelectAndWriteStage = ({ unitInfo, bookInfo, stages, steps, setSteps }) => {
  const { problem } = stages;
  const [isCorrect, setIsCorrect] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  // to avoid stuck while clicking on wrong answer after clicking right answer
  const [endSound, setEndSound] = useState(true);
  const [showWritePopUp, setShowWritePopUp] = useState(false);
  const [soundCorrectWrong, setSoundCorrectWrong] = useState(undefined);

  // const {myScriptEditorRef, script, onClear, error} = useMyScript();
  const { level, unit, stage } = useParams();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  if (level == '4b' && unit == 10 && stage == 7) {
    bookNameUnitStage = true;
  }

  if (level == '4b' && unit == 6 && stage == 7) {
    bookNameUnitStageAdd = true;
  }

  if (level == '4a' && unit == 5 && stage == 7) {
    bookName4Unit5Stage7Add = true;
    bookNameUnitStage = true;
  }

  if (level == '4b' && unit == 8 && stage == 7) {
    bookNameUnitStageAdd = true;
  }

  if (level == '4a' && unit == 4 && stage == 7) {
    bookNameUnitStageAdd = true;
  }

  if (level == '4a' && unit == 3 && stage == 7) {
    bookNameUnitStageAdd = true;
  }

  if (level == '4a' && unit == 3 && stage == 9) {
    bookNameUnitStageAdd = true;
  }

  if (level == '4a' && unit == 2 && stage == 7) {
    bookNameUnitStageAdd = true;
  }

  if (level == '4a' && unit == 1 && stage == 7) {
    bookNameUnitStageAdd = true;
  }
  if (level == '4b' && unit == 7 && stage == 7) {
    bookNameUnitStageAdd = true;
  }
  if (level == '4b' && unit == 9 && stage == 7) {
    bookNameUnitStageAdd = true;
  }

  const history = useHistory();

  const handlePopupClose = () => {
    // onClear();
    setIsCorrect(false);
    setShowWritePopUp(false);
  };

  const handleWordClick = (word) => {
    if (word === (_.isNil(problem[steps].answer) ? problem[steps].word.replace(re, '$2') : problem[steps].answer)) {
      setIsCorrect(true);
      //setSoundCorrectWrong(veryGoodSound);
      setSoundCorrectWrong(`https://cdn.cloubot.com/PM/audio/sounds/${problem[steps].word.replace(/#/g, '')}.mp3`);
      setEndSound(false);
    } else if (endSound) {
      setSoundCorrectWrong(woopsSound);
    }
  };
  const handleSoundCorrectWrongEnd = () => {
    if (soundCorrectWrong !== woopsSound) {
      if (steps <= problem.length - 1) {
        //setSteps(steps + 1);
        setShowWritePopUp(true);
        setIsCorrect(false);
        setEndSound(true);
      }
    }
    setSoundCorrectWrong(undefined);
  };

  const handleCorrectWriteEnd = () => {
    // onClear();
    setShowWritePopUp(false);
    if (!_.isNil(problem[steps + 1])) {
      setSteps((prev) => prev + 1);
    } else {
      setShowPopUp(true);
    }
  };

  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };

  return (
    <>
      <S.RowFlex style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }} className="main_content  stage4_content col2">
        <PageNum
          num={steps + 1}
          total={problem && problem.length}
          style={{top: "20px",position:'absolute',right:'1.8rem',fontSize:'1.3rem',fontWeight:'bold' }}
        />
        <LeftBox className="left_box">
          <div className="img_box" style={{ position: 'relative' }}>
            <img style={{ height: '44vh' }} src={problem[steps].image} alt="" />
            <div style={{ paddingTop: '10px', textAlign: 'center' }} className="sticker_box_wrap">
              <StickerBox className="sticker_box">
                {problem[steps].word.replace(re, '$1') && (
                  <span style={{ padding: '0 12px', background: '#d8d8d8' }} className="fill">
                    {problem[steps].word.replace(re, '$1')}
                  </span>
                )}
                {problem[steps].word.replace(re, '$2') && (
                  <span
                    style={{ padding: '0 10px', color: isCorrect ? '#f368e0' : 'transparent' }}
                    className={`blank ${!isCorrect ? 'hidden' : ''}`}
                  >
                    {problem[steps].word.replace(re, '$2')}
                  </span>
                )}
                {problem[steps].word.replace(re, '$3') && (
                  <span style={{ padding: '0 12px', background: '#d8d8d8' }} className="fill">
                    {problem[steps].word.replace(re, '$3')}
                  </span>
                )}
              </StickerBox>
            </div>
          </div>
        </LeftBox>
        <RightBox className="right_box">
          <S.AlphabetWrap className="alphabet_wrap" style={{ alignContent: 'center' }}>
            {problem[steps] &&
              problem[steps].exPhonemes &&
              problem[steps].exPhonemes.map((item, key) => (
                <li
                  key={`word-list-key${key}`}
                  onClick={() => {
                    handleWordClick(item);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <AlphabetBox
                    row={alphabetBoxTypeChecker(3)}
                    blue={
                      isCorrect && item === (_.isNil(problem[steps].answer) ? problem[steps].word.replace(re, '$2') : problem[steps].answer)
                    }
                    className={`alphabet_box click row3 ${
                      isCorrect && item === (_.isNil(problem[steps].answer) ? problem[steps].word.replace(re, '$2') : problem[steps].answer)
                        ? 'blue'
                        : 'green'
                    } ${item.indexOf('-') !== -1 ? 'width-auto' : ''}`}
                    style={{width:item.indexOf('-') !== -1&&'auto'}}
                  >
                    {bookNameUnitStage ? (
                      <React.Fragment>
                        <span
                          data={item}
                          style={{ color: item.split('-')[0] == 'short' || 'long' ? '#2e86de' : '#f368e0' }}
                          className={`font_${item.split('-')[0] == 'short' || 'long' ? 'blue' : 'pink'}`}
                        >
                          {item.split('-')[0]}
                        </span>
                        <span
                          data={item}
                          style={{ color: item.split('-')[1] == 'short' || 'long' ? '#f368e0' : '#2e86de' }}
                          className={`font_${item.split('-')[1] == 'short' || 'long' ? 'pink' : 'blue'}`}
                        >
                          &nbsp;{item.split('-')[1]}
                        </span>
                      </React.Fragment>
                    ) : bookNameUnitStageAdd ? (
                      <span data={item} style={{ color: '#f368e0' }} className="font_pink">
                        {item.replace('-', ' ')}
                      </span>
                    ) : (
                      <span data={item} style={{ color: '#2e86de' }} className="font_blue">
                        {item.replace('-', ' ')}
                      </span>
                    )}
                  </AlphabetBox>
                </li>
              ))}
          </S.AlphabetWrap>
        </RightBox>
      </S.RowFlex>
      {showWritePopUp && (
        <WritePopup
          word={problem[steps].word.replace(/#/g, '')}
          userScript={{}}
          showWritePopUp={showWritePopUp}
          editorRef={{}}
          onCorrectEnd={handleCorrectWriteEnd}
          onClear={{}}
          onClose={handlePopupClose}
          textInPink={getTextInPink(problem[steps].word.replace(/#/g, ''), level, unit, stage)}
          error={{}}
        />
      )}
      {<SoundEffectPM url={soundCorrectWrong} onEnd={handleSoundCorrectWrongEnd} />}
      {showPopUp ? <PopUp popUpType={'correct'} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
    </>
  );
};

export default PhonemeSelectAndWriteStage;
const LeftBox = styled(S.ImgDndBox)`
  align-items: center;
  position: relative;
`;
const RightBox = styled(LeftBox)`
  margin-left: 24px;
`;
const StickerBox = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  font-weight: 800;
  font-size: 38px;
  border: 4px solid #aaa;
  border-radius: 12px;
  overflow: hidden;
`;
const AlphabetBox = styled(S.AlphabetBox)`
  border-radius: 0;
  margin: 8px;
  border: 6px solid ${(props) => (props.blue ? '#48dbfb' : '#00d2d3')};
  box-shadow: 0 6px 0 ${(props) => (props.blue ? '#0abde3' : '#00a3a4')};
`;
