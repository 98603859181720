import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Table, Typography, Col } from 'antd';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { USER_CLASS_LESSON_LIST, BOOK_STAGE_LIST_BY_BOOK_CODE, ALL_USER_CLASS_LESSION_LIST } from 'src/operations/queries/study';
import { getStageTableHeader } from 'src/utils/bookStage';
import AchievementGraph from './AchievementGraph';
import { isAfterSchool, parseBookCode } from 'src/utils';
import { settings } from 'src/dummy/settings.js';
const isCountGrade = (stage_abbr) => {
  if (['w1', 'w2'].includes(stage_abbr)) {
    return false;
  }
  return true;
};

const { Title } = Typography;
const StudyTable = styled(Table)`
  border-top: 3px solid #a4d0e9;
  border-right: 1px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  tbody td {
    border-left: 1px solid #a4d0e9;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media print {
    max-width: 700px;
    thead[class*='ant-table-thead'] th {
      padding: 3px;
      font-size: 0.5em;
    }
    tbody td {
      padding: 3px;
      font-size: 0.5em;
    }
  }
`;

const getPercentGrade = (stageGroupCode, stages, userStudyList) => {
  if (!userStudyList || userStudyList.length === 0) {
    return '미수행';
  }
  const findItem = stages.find((stage) => stage.group_code === stageGroupCode);
  if (!findItem) {
    return '오류1'; //여기로 들어오면 안 되는 거임
  }
  const findItem2 = userStudyList.find((studyItem) => studyItem.stage_no === findItem.no);
  if (!findItem2) {
    return '미수행';
  }
  const { exam_total, exam_correct } = findItem2;
  if (!exam_total) {
    return '완료';
  }

  const percentGrade = Math.round((100 * parseInt(exam_correct)) / parseInt(exam_total));
  return percentGrade;
};

const StudyCompleteTable = ({ bookCode, class_idx, user_idx, isPhonics }) => {
  const companyName = useSelector(classStoreData);
  const [completePercent, setCompletePercent] = useState(0);
  const [average, setAverage] = useState(0);

  const isSmarteclass = useMemo(() => {
    return isAfterSchool(companyName);
  }, [companyName]);

  const { data: dataStages } = useQuery(BOOK_STAGE_LIST_BY_BOOK_CODE, {
    skip: !bookCode,
    variables: { book_code: bookCode },
  });

  const { data: dataLessons } = useQuery(USER_CLASS_LESSON_LIST, {
    skip: !class_idx || !user_idx,
    variables: { class_idx: parseInt(class_idx), user_idx: parseInt(user_idx) },
    fetchPolicy: 'no-cache',
  });
  const dataStudyComplete = useMemo(() => {
    if (dataLessons && dataStages) {
      const gradeData = dataLessons.getUserClassLessonList.map((item) => {
        const baseData = {};

        for (const stageItem of dataStages.bookStageListByBookCode) {
          const groupCodes = stageItem.book_stage.map((stage) => stage.group_code);

          if (groupCodes instanceof Array && groupCodes.includes(item.book_lesson.stage_group_code)) {
            const grade = getPercentGrade(
              item.book_lesson.stage_group_code,
              stageItem.book_stage,
              item.user_study.filter((study) => study.user_lesson_idx),
            );
            baseData[stageItem.name_abbr] = grade;
          } else {
            baseData[stageItem.name_abbr] = '--';
          }
        }
        return baseData;
      });

      const gradeObject = { no: '점수' };
      const completeObject = { no: '수행율' };

      const totalStudy = gradeData.reduce((acc, cur) => {
        const keys = Object.keys(cur);
        for (const ele of keys) {
          if (cur[ele] !== '--') {
            acc[ele] = acc[ele] ? acc[ele] + 1 : 1;
          }
        }
        return { ...acc };
      }, {});
      const totalComplete = gradeData.reduce((acc, cur) => {
        const keys = Object.keys(cur);
        for (const ele of keys) {
          if (cur[ele] !== '--' && cur[ele] !== '미수행') {
            acc[ele] = acc[ele] ? acc[ele] + 1 : 1;
          }
        }
        return { ...acc };
      }, {});

      const totalGrade = gradeData.reduce((acc, cur) => {
        const keys = Object.keys(cur);
        for (const ele of keys) {
          if (cur[ele] !== '--' && cur[ele] !== '미수행' && !isNaN(cur[ele])) {
            acc[ele] = acc[ele] ? acc[ele] + cur[ele] : cur[ele];
          }
        }
        return { ...acc };
      }, {});

      let sum = 0,
        sumGrade = 0;
      for (const [key, value] of Object.entries(totalStudy)) {
        completeObject[key] = value > 0 && totalComplete[key] ? Math.round((100 * totalComplete[key]) / value) + '%' : '0%';
        gradeObject[key] =
          value > 0 && !isNaN(totalGrade[key]) ? Math.round(totalGrade[key] / value) : completeObject[key] === 100 ? '-' : '-'; //'완료' : '미완료';
        sum += value > 0 && totalComplete[key] ? Math.round((100 * totalComplete[key]) / value) : 0;
        sumGrade += value > 0 && !isNaN(totalGrade[key]) ? Math.round(totalGrade[key] / value) : 0;
      }
      setCompletePercent(Math.round(sum / Object.keys(totalStudy).length));
      setAverage(Object.keys(totalGrade).length > 0 ? Math.round(sumGrade / Object.keys(totalGrade).length) : 0); //점수가 존재하는 것만 카운트한다.

      return [gradeObject, completeObject];
    }

    return [{ no: '점수' }, { no: '수행율' }];
  }, [dataLessons, dataStages]);
  const [realColums, columnsForGraph] = useMemo(() => {
    const columns = [
      {
        key: 'no',
        title: '',
        dataIndex: 'no',
        align: 'center',
        width: '100px',
      },
    ];

    if (dataStages) {
      const { level } = parseBookCode(bookCode);
      const added = getStageTableHeader(companyName, dataStages.bookStageListByBookCode, level);
      console.log('added', added);
      return [[...columns, ...added], dataStages.bookStageListByBookCode.map((ele) => ele.book_stage[0].name_abbr)];
    }
    return [[...columns], []];
  }, [dataStages, companyName, bookCode]);

  const { data: dataAllUsers, loading } = useQuery(ALL_USER_CLASS_LESSION_LIST, {
    variables: { class_idx },
    skip: !class_idx || !isSmarteclass,
  });
// console.log(dataAllUsers?.getAllUserClassLessonList[0],'dau1')
  const classGrade = useMemo(() => {
    const list = dataAllUsers?.getAllUserClassLessonList;
    const stageList = dataStages?.bookStageListByBookCode;
    if (list && stageList) {
      const reduced = list.reduce((acc, cur) => {
        if (!acc[cur.user_idx]) {
          acc[cur.user_idx] = [];
        }
        acc[cur.user_idx].push(cur);
        return { ...acc };
      }, {});
      const gradeData = Object.values(reduced).map((lessons) => {
        const eachGrades = lessons.map((item) => {
          const baseData = {};

          for (const stageItem of stageList) {
            const groupCodes = stageItem.book_stage.map((stage) => stage.group_code);

            if (groupCodes instanceof Array && groupCodes.includes(item.book_lesson.stage_group_code)) {
              const grade = getPercentGrade(
                item.book_lesson.stage_group_code,
                stageItem.book_stage,
                item.user_study.filter((study) => study.user_lesson_idx),
              );
              baseData[stageItem.name_abbr] = grade;
            } else {
              baseData[stageItem.name_abbr] = '--';
            }
          }
          return baseData;
        });

        const totalStudy = eachGrades.reduce((acc, cur) => {
          const keys = Object.keys(cur);
          for (const ele of keys) {
            if (cur[ele] !== '--') {
              acc[ele] = acc[ele] ? acc[ele] + 1 : 1;
            }
          }
          return { ...acc };
        }, {});
        const totalGrade = eachGrades.reduce((acc, cur) => {
          const keys = Object.keys(cur);
          for (const ele of keys) {
            if (cur[ele] !== '--' && cur[ele] !== '미수행' && !isNaN(cur[ele])) {
              acc[ele] = acc[ele] ? acc[ele] + cur[ele] : cur[ele];
            }
          }
          return { ...acc };
        }, {});
        const gradeObject = {};
        for (const [key, value] of Object.entries(totalStudy)) {
          //점수가 있는 건지 없는 건지 확인할 방법이 없네요.
          gradeObject[key] =
            value > 0 && isCountGrade(key) ? (!isNaN(totalGrade[key]) ? Math.round(totalGrade[key] / value) : 0) : '점수없음';
        }
        //console.log('gradeObject', gradeObject);
        return gradeObject;
      });

      const resMap = new Map();
      const res = gradeData.reduce((acc, cur) => {
        for (const [key, value] of Object.entries(cur)) {
          if (!isNaN(value)) {
            if (acc.has(key)) {
              acc.set(key, acc.get(key) + value);
            } else {
              acc.set(key, value);
            }
          }
        }
        return acc;
      }, resMap);

      return Array.from(res)
        .map(([key, val]) => [key, Math.round(val / gradeData.length)])
        .reduce((acc, cur) => {
          acc[cur[0]] = cur[1];
          return { ...acc };
        }, {});
    }
    return [];
  }, [dataAllUsers, dataStages]);
  //스마트이클래스는 성취도 그래프를 보여준다.(점수 기준임)
  return (
    <>
      <Col span={24}>
        <Title level={4}>온라인 학습 성취도</Title>
        <StudyTable columns={realColums} dataSource={dataStudyComplete} pagination={false} />
        <div style={{ textAlign: 'center', paddingTop: 15, fontSize: '0.7em' }}>
          <label style={{ backgroundColor: '#339af0', color: '#fff', padding: 4, borderRadius: 7 }}>나의 학습 수행률</label>
          <span style={{ color: '#339af0', fontWeight: 'bold', paddingLeft: 5 }}>{completePercent}%</span>
          <span className="span" style={{ padding: '0 10px' }}></span>
          <label style={{ backgroundColor: '#339af0', color: '#fff', padding: 4, borderRadius: 7 }}>나의 평균점수</label>{' '}
          <span style={{ color: '#339af0', fontWeight: 'bold', paddingLeft: 5 }}>{average} 점</span>
        </div>
      </Col>
      {isSmarteclass && (
        <AchievementGraph
          cols={columnsForGraph}
          myGradeData={dataStudyComplete[0]}
          classGradeData={classGrade}
          myAverage={average}
          class_idx={class_idx}
          isPhonics={isPhonics}
        />
      )}
    </>
  );
};

export default StudyCompleteTable;
