import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Modal, Typography, message } from 'antd';
import * as diff_match_patch from 'diff-match-patch';
import * as axios from 'axios';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { CREATE_SIGNED_S3_UPLOAD } from 'src/operations/mutations/uploadFileS3';
import { userInfoVar } from 'src/apollo/cache';
import { captureUserMedia } from 'src/utils';
import SoundEffectPM, { SoundEffectProgress } from 'src/components/pm/SoundEffectPM';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import Sound from 'react-sound';
import _ from 'lodash';

const { Title } = Typography;

let MainContent = styled.div`
  min-width: 60%;
  li,
  ol,
  ul {
    list-style: none;
  }
  & .wave {
    font-size: 10px;
    font-family: 'BalsamiqSans';
    user-select: none;
    margin: 0;
    padding: 0;
    flex: 1 1;
    height: 1.4rem;
    margin-bottom: 1.2rem;
    border-radius: 0.5rem;
    background-color: #ff9f43;
    @media (max-height: 500px) {
      display: none;
    }
  }

  & .recording_btn_box {
    font-size: 10px;
    font-family: 'BalsamiqSans';
    user-select: none;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  & .recording_btn_box button {
    cursor: pointer;
    user-select: none;
  }

  & button {
    user-select: none;
    list-style: none;
    padding: 0;
    font-family: 'Alata', 'NanumSquareRound', sans-serif;
    color: #333;
    vertical-align: middle;
    outline: none;
    background-color: #c8d6e5;
    border-radius: 100%;
    margin: 0 10px;
    box-shadow: 0 0.4rem 0 0 #8395a7;
    width: 48px;
    height: 48px;
    border: 0.2rem solid #fff;
    @media (max-height: 500px) {
      width: 30px;
      height: 30px;
      margin: 0 5px;
      box-shadow: 0 0.2rem 0 0 #8395a7;
      border: 0.1rem solid #fff;
    }
    img {
      width: 24px;
      height: 24px;
      @media (max-height: 500px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  & .btn_red {
    background-color: #ff7474;
    box-shadow: 0 0.4rem 0 rgba(213, 55, 55, 0.8);
  }

  & .btn_gray {
    background-color: #c8d6e5;
    box-shadow: 0 0.4rem 0 0 #8395a7;
  }
`;
const dmp = new diff_match_patch();

const RecordButtonBox = ({ bookInfo, onRecordComplete, sentences }) => {
  const recordAudio = useRef(null);
  const recorderStreams = useRef(null);
  const audioData = useRef();
  const currentSttRequest = useRef(null);
  const currentSttText = useRef(null);
  const [showLoading, setShowLoading] = useState(false);
  const [lastBlob, setLastBlob] = useState(null);
  const [playing, setPlaying] = useState(Sound.status.STOPPED);
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [currentProgressSlider, setCurrentProgressSlider] = useState(0);
  const [currentDoneValue, setCurrentDoneValue] = useState(null);
  const [processCallbackLoading, setProcessCallbackLoading] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [clickSound, setClickSound] = useState(undefined);

  const [uploadFileS3] = useMutation(CREATE_SIGNED_S3_UPLOAD);

  const processRecordCallback = useCallback(
    async (blob, textA, currentRecLength, s3Url) => {
      let fd = new FormData();
      let text = textA.map((p) => p);
      text.push('[unk]');
      fd.append('audio', blob);
      fd.append('sample_rate', 16000);
      currentSttRequest.current = false;

      let sanitizePhraseList = text.map((e) =>
        e
          .replaceAll('\n', ' ')
          .replace(/[.,?!’“”#]/gi, '')
          .toLowerCase(),
      );

      fd.append('phrase_list', JSON.stringify(sanitizePhraseList));

      setProcessCallbackLoading(true);

      const res = await axios('https://kaldi-stt-api.cloubot.com/process', {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${new Date().getTime()}` },
        method: 'POST',
        data: fd,
      });
      setProcessCallbackLoading(false);

      let prev = currentSttText.current;

      let phList = sanitizePhraseList.reduce((p, n) => p.concat(n.split(' ')), []).filter((p) => p != '');
      let resData = res.data;
      let dataSplit = resData.text.split(' ');
      resData.text = resData.text.replace('[unk]', '');

      // const filteredArray = phList.filter(value => dataSplit.includes(value));
      dmp.Diff_Timeout = 5;
      dmp.Diff_EditCost = 5;
      resData['accuracy'] = 0;
      resData['duration'] = currentRecLength;
      resData['wpm'] = (dataSplit.length / (currentRecLength / 1000)) * 60;
      const d = dmp.diff_main(
        textA
          .map((e) =>
            e
              .replace(/[.,?!’“”#]/gi, '')
              .replace(',', ' ')
              .toLowerCase(),
          )
          .reduce((p, n) => p + n, ''),
        resData.text,
      );
      dmp.diff_cleanupSemantic(d);
      let htmltext = [];
      d.forEach((element, i) => {
        if (element[0] === 0) {
          const filteredArrayl = element[1].split(' ').filter((value) => phList.includes(value));
          resData['accuracy'] += filteredArrayl.length;
          htmltext.push(`<span style="color:black;">${element[1]}</span>`);
        } else if (element[0] === -1) {
          const filteredArrayl = element[1].split(' ').filter((value) => phList.includes(value));
          resData['accuracy'] = resData['accuracy'] ? resData['accuracy'] - filteredArrayl.length / 6 : 0;
          htmltext.push(`<span style="color:brown; text-decoration:line-through;">${element[1]}</span>`);
        } else if (element[0] === 1) {
          htmltext.push(`<span style="color:darkblue;">${element[1]}</span>`);
        }
      });
      let tempAccuracy = resData['accuracy'] / (phList.length / 100);
      tempAccuracy = tempAccuracy > 100 ? 100 : tempAccuracy;
      resData['accuracy'] = tempAccuracy > 0 ? Math.floor(tempAccuracy) : 0;
      resData.text =
        htmltext.join('') + '<sup><a style="margin-left:5px;" href="' + s3Url + '" target="_blank">' + '녹음 재생' + 1 + '</a></sup>';
      prev = resData;

      currentSttRequest.current = true;
      setCurrentDoneValue(resData);
      setShowLoading(false);

      currentSttText.current = prev;
    },
    [currentSttRequest],
  );

  const stopRecording = () => {
    setClickSound('mouse-click');
    let page = 0;
    if (recordAudio.current != null && recordAudio.current) {
      // message.info('Recording Stopped');
      recordAudio.current.stopRecording(async () => {
        //
        let reader = new FileReader();

        reader.onload = async function (e) {
          let dataUrl = reader.result;
          let res = await uploadFileS3({
            variables: {
              name: `PHONICSMONSTER-RECORDING-${userInfoVar()?.idx}-${bookInfo?.id}-${page + 1}.wav`,
              contentType: 'audio/wav',
              replace: true,
            },
          });

          await fetch(res.data.createSignedS3Upload, {
            method: 'PUT',
            body: dataUrl,
            headers: {
              'Content-Type': 'audio/wav',
              'x-amz-acl': 'public-read',
            },
          });

          const s3Url = res.data.createSignedS3Upload.split('?')[0];
          setIsRecording(false);
          processRecordCallback(recordAudio.current.getBlob(), sentences, currentRecLength, s3Url);
          setLastBlob(recordAudio.current.toURL());
          recordAudio.current.destroy();
          recordAudio.current = null;
          if (recorderStreams.current) {
            recorderStreams.current.getTracks().forEach((track) => track.stop());
            recorderStreams.current = null;
          }
        };

        reader.readAsArrayBuffer(recordAudio.current.getBlob());
        //
      });
    }
  };

  const handleUploadClick = () => {
    if (currentSttRequest.current) {
      setClickSound('mouse-click');
      if (!currentSttText.current) {
        Modal.destroyAll();
        Modal.error({
          icon: null,
          title: 'Not Complete',
          content: (
            <Title level={4}>
              {`  학습이 완료되지 않았습니다.`}
              <br />
              {`  녹음을 계속하시겠습니까?`}
            </Title>
          ),
        });
      } else {
        Modal.destroyAll();
        Modal.info({
          icon: null,
          title: 'Done',
          content: <Title level={4}>{`  당신의 점수는 ${currentSttText.current.accuracy} 점 입니다. 학습을 종료합니다.`}</Title>,
          onOk: () => {
            onRecordComplete(
              {
                exam_total: 100,
                exam_correct: currentSttText.current.accuracy,
                recording_data: currentSttText.current.text,
                wpm: parseInt(currentSttText.current.wpm),
              },
              true,
            );
            setProcessCallbackLoading([]);
            currentSttText.current = null;
          },
        });
      }
    }
  };

  const sleep = (m) => new Promise((r) => setTimeout(r, m));

  const onDataAvailableCallback = useCallback(async (blob) => {
    console.log('s');
    setCurrentRecLenght((prev) => prev + 100);
  }, []);

  const startRecord = () => {
    setClickSound('mouse-click');
    if (isRecording) return;
    setCurrentRecLenght(0);
    captureUserMedia({ audio: { echoCancellation: true } }, async (stream) => {
      if (!recordAudio.current) recordAudio.current = [];
      recorderStreams.current = stream;
      recordAudio.current = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 48000,
        desiredSampRate: 16000,

        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,

        //1)
        // get intervals based blobs
        // value in milliseconds
        // as you might not want to make detect calls every seconds
        timeSlice: 100,

        // 2)
        // as soon as the stream is available
        ondataavailable: onDataAvailableCallback,
      });
      setLastBlob(null);
      recordAudio.current.startRecording();

      await sleep(500);
      setIsRecording(true);
      message.info('Recording Started');
    });
  };

  const handlePlay = () => {
    if (!lastBlob) return;
    // setClickSound('mouse-click');
    setPlaying(Sound.status.PLAYING);
    setCurrentProgressSlider(currentProgressSlider < 5 ? 5 : currentProgressSlider);
  };

  const handleStop = () => {
    setClickSound('mouse-click');
    setPlaying(Sound.status.PAUSED);
  };

  return (
    <MainContent>
      <div className="wave">
        <SoundEffectProgress
          url={lastBlob}
          playStatus={playing}
          onEnd={() => setPlaying(Sound.status.STOPPED)}
          onPlaying={(progress) => {
            setCurrentProgressSlider((progress.position / progress.duration) * 100);
          }}
        />
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${currentProgressSlider}%`, height: '1.4rem', backgroundColor: '#ff7474', borderRadius: '0.5rem' }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <ul className="recording_btn_box">
        <RecButton isRecording={isRecording} onRecored={startRecord} onStop={stopRecording} />
        <li>
          <button
            type="button"
            className={lastBlob ? 'btn_red' : 'btn_gray'}
            onClick={playing === Sound.status.PLAYING ? handleStop : handlePlay}
          >
            <img src={`/images/pm/i_${playing === Sound.status.PLAYING ? 'stop' : 'play'}.svg`} alt="재생 버튼" />
          </button>
        </li>
        <li>
          <button type="button" className={currentDoneValue ? 'btn_red' : 'btn_gray'} onClick={handleUploadClick}>
            <img src="/images/pm/i_upload.svg" alt="업로드 버튼" />
          </button>
        </li>
      </ul>
      <SoundEffectPM
        url={!_.isNil(clickSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${clickSound}.mp3` : undefined}
        onEnd={() => setClickSound(undefined)}
      />
    </MainContent>
  );
};

export default RecordButtonBox;

const RecButton = ({ isRecording, onRecored, onStop }) => {
  const [onoff, setOnoff] = useState(false);
  useEffect(() => {
    if (isRecording) {
      setTimeout(() => {
        setOnoff(!onoff);
      }, 500);
    }
  }, [isRecording, onoff]);

  return (
    <li>
      <button type="button" className={`btn_gray ${isRecording ? 'blink_me' : ''}`} onClick={isRecording ? onStop : onRecored}>
        <img src={`/images/pm/i_record${isRecording ? '_stop' : ''}.svg`} alt="녹음 버튼" />
      </button>
    </li>
  );
};
