import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Space, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_NEW_O_MATICS_STATICS_COMPANY_CAMPUS } from 'src/operations/queries/company';
import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

const NewsOMaticStatics = () => {
  const [selectedDate, setSelectedDate] = useState();
  // const { Title, Text } = Typography;
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: dataCompany, refetch } = useQuery(GET_NEW_O_MATICS_STATICS_COMPANY_CAMPUS, {
    skip: !dataUser,
    variables: {
      company_idx: dataUser?.getUserData.campus.fc_company_idx,
      selected_date: selectedDate ? selectedDate : '',
    },
  });

  const StaticsRes = dataCompany ? JSON.parse(dataCompany?.getCampusNewOMaticsStatics) : '';

  useEffect(() => {
    let d = new Date().toISOString();
    setSelectedDate(d.split('T')[0]);
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedDate]);

  const handleDateChange = (date, string) => {
    setSelectedDate(string);
  };

  const dataSource = useMemo(() => {
    if (StaticsRes) {
      const sum = StaticsRes.reduce(
        (acc, cur) => {
          return {
            day: acc.day + cur.day,
            month: acc.month + cur.month,
            quaterYear: acc.quaterYear + cur.quaterYear,
            year: acc.year + cur.year,
            total: acc.total + cur.total,
          };
        },
        { day: 0, month: 0, quaterYear: 0, year: 0, total: 0 },
      );
      sum.campus_name = '합계';
      sum.idx = '';
      return [sum, ...StaticsRes];
    }
    return StaticsRes;
  }, [StaticsRes]);
  console.log(dataSource, 'dataSource');

  const NewsOMaticStaticsTable = styled(CustomTable)`
    border-top: 3px solid #a4d0e9;
    thead[class*='ant-table-thead'] th {
      border-left: 1px solid #a4d0e9;
      border-top: 1px solid #a4d0e9;
    }
  `;
  const getColumnHeading = () => {
    let lessonHeading = [];
    lessonHeading.push({
      title: 'No',
      dataIndex: 'idx',
      align: 'center',
      width: 40,
      render: (text, record) => {
        return <Space size="middle">{record.idx + 1}</Space>;
      },
    });
    lessonHeading.push({
      title: 'Academy',
      dataIndex: 'campus_name',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{record.campus_name}</Space>;
      },
    });
    lessonHeading.push({
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{record.total}</Space>;
      },
    });
    lessonHeading.push({
      title: 'Year',
      dataIndex: 'year',
      align: 'center',
      width: 100,
      render: (text, record, key) => {
        const yeardate = selectedDate.split('-');

        if (!record?.campus_idx) {
          return (
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <div style={{ textAlign: 'left' }}>{record.year}</div>
            </Space>
          );
        }

        return (
          <>
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <Link to={`/news-o-matic-statics/details/year/${record?.campus_idx}/${yeardate[0]}`}>
                <div style={{ textAlign: 'left' }}>{record.year}</div>
              </Link>
            </Space>
          </>
        );
      },
    });
    lessonHeading.push({
      title: 'Quarter Year',
      dataIndex: 'quaterYear',
      align: 'center',
      width: 100,
      render: (text, record, key) => {
        if (!record?.campus_idx) {
          return (
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <div style={{ textAlign: 'left' }}>{record.quaterYear}</div>
            </Space>
          );
        }
        return (
          <>
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <Link to={`/news-o-matic-statics/details/quateryear/${record?.campus_idx}/${selectedDate}`}>
                <div style={{ textAlign: 'left' }}>{record.quaterYear}</div>
              </Link>
            </Space>
          </>
        );
      },
    });
    lessonHeading.push({
      title: 'Month',
      dataIndex: 'month',
      align: 'center',
      width: 100,
      render: (text, record, key) => {
        const monthdate = selectedDate.split('-');

        if (!record?.campus_idx) {
          return (
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <div style={{ textAlign: 'left' }}>{record.month}</div>
            </Space>
          );
        }
        return (
          <>
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <Link to={`/news-o-matic-statics/details/month/${record?.campus_idx}/${monthdate[0] + '-' + monthdate[1]}`}>
                <div style={{ textAlign: 'left' }}>{record.month}</div>
              </Link>
            </Space>
          </>
        );
      },
    });
    lessonHeading.push({
      title: 'Day',
      dataIndex: 'day',
      align: 'center',
      width: 100,
      render: (text, record, key) => {
        if (!record?.campus_idx) {
          return (
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <div style={{ textAlign: 'left' }}>{record.day}</div>
            </Space>
          );
        }
        return (
          <>
            <Space style={key === 0 && { fontWeight: 'bolder' }} size="middle">
              <Link to={`/news-o-matic-statics/details/day/${record?.campus_idx}/${selectedDate}`}>
                <div style={{ textAlign: 'left' }}>{record.day}</div>
              </Link>
            </Space>
          </>
        );
      },
    });
    return lessonHeading;
  };
  const MetaStaticsChild = getColumnHeading();

  const columns = [
    {
      title: `Users`,
      children: MetaStaticsChild,
    },
  ];

  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>News-O-Matics Statics</HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="learning-halfwrapper learning-hs">
            <div
              className="learning-halfwrapper-top-header"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              <DatePicker
                placeholder="월별 검색"
                value={selectedDate && moment(selectedDate, 'YYYY-MM-DD')}
                defaultValue={selectedDate && moment(selectedDate, 'YYYY-MM-DD')}
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString);
                }}
              />
            </div>
            <NewsOMaticStaticsTable
              dataSource={dataSource}
              columns={columns}
              pagination={true}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </HalfWrapper>
        </Col>
      </Row>{' '}
    </>
  );
};
export default NewsOMaticStatics;
