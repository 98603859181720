import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row, Typography} from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_NEW_O_MATICS_STATICS_DETAIL } from 'src/operations/queries/company';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const NewsOMaticStaticsDetail = () => {
  const [selectedDate, setSelectedDate] = useState()
  const { Title } = Typography;
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { pathname } = useLocation();
  let pathArr = pathname.split('/');

  const { data: dataList,refetch } = useQuery(GET_NEW_O_MATICS_STATICS_DETAIL, {
    skip: !dataUser,
    variables: {
      campus_idx: parseInt(pathArr[4]), 
      selected_date: pathArr[5] ,
       gettype:pathArr[3], 
       type: 'newsomatic'
    },
  });

  const studyData = useMemo(() => {
    if (dataList?.getCampusNewOMaticsStaticsDetail) {
      return dataList?.getCampusNewOMaticsStaticsDetail
        .map((item, key) => {
          const recording_data = item.recording_data;
          const { study_date, article_data, exam_total, exam_correct, wpm, data, last_study_timestamp } = item;
          const dataparse = JSON.parse(data);
          const score = dataparse?.score_percent || '';
          const { lexile, issue_date, title, level, image } = JSON.parse(
            article_data || {
              lexile: '',
              issue_date: '',
              title: '',
              level: '',
              image: '',
            },
          );
          const date = moment(study_date).format('M월D일');
          return {
            key: `${key + 1}`,
            date,
            lexile,
            issue_date,
            title,
            level,
            image,
            accuracy: recording_data ? `${Math.round((100 * exam_correct) / exam_total)}%` : '',
            wpm,
            recording_data,
            exam_total,
            exam_correct,
            quiz_score: score ? score + '/100' : '-',
            last_study_timestamp: last_study_timestamp ? `${last_study_timestamp.slice(0, 10)} ${last_study_timestamp.slice(11, 19)}` : '-',
          };
        });
    }
    return [];
  }, [dataList]);

   useEffect(() => {
    let d = new Date().toISOString()
    setSelectedDate(d.split("T")[0])
    }, [])

   useEffect(() => {
    refetch()
   }, [selectedDate])


  const NewsOMaticStaticsDetailTable = styled(CustomTable)`
    border-top: 3px solid #a4d0e9;
    thead[class*='ant-table-thead'] th {
      border-left: 1px solid #a4d0e9;
      border-top: 1px solid #a4d0e9;
    }
  `;

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: '10%',
    },
    {
      title: 'Article Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'Lexile',
      dataIndex: 'lexile',
      key: 'lexile',
      align: 'center',
    },
    {
      title: 'Study Date',
      dataIndex: 'last_study_timestamp',
      key: 'last_study_timestamp',
      align: 'center',
    },
    {
      title: 'Quiz',
      dataIndex: 'quiz_score',
      key: 'quiz_score',
      align: 'center',
    },
    {
      title: 'Accuracy',
      dataIndex: 'accuracy',
      key: 'accuracy',
      align: 'center',
      render: (text, record) => {
        return text ? (
          <span
            style={{ cursor: 'pointer' }}
          >
            <span style={{ color: '#289428' }}>{text}</span>
          </span>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: 'WPM',
      dataIndex: 'wpm',
      key: 'wpm',
      align: 'center',
      render: (text, record) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
          >
            <span style={{ color: '#289428' }}>{text}</span>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>MetaExam Statics Details</HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="learning-halfwrapper learning-hs">
            <div
              className="learning-halfwrapper-top-header"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              <Title level={5} style={{ width: '100%', textAlign: 'left' }}>
              </Title>
            </div>
            <NewsOMaticStaticsDetailTable
            dataSource={studyData}
              columns={columns}
              pagination={true}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
            />
          </HalfWrapper>
        </Col>
      </Row>{' '}
    </>
  );
};
export default NewsOMaticStaticsDetail;