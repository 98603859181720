import React from 'react';
import { Modal } from 'antd';
import ClassDetail from 'src/pages/ClassRoom/ClassDetail';
import ClassDetailForAfterSchool from 'src/pages/ClassRoom/ClassDetail/ClassDetailForAfterSchool.js';
import { userInfoVar } from 'src/apollo/cache';
import { isAfterSchool } from 'src/utils/index';

function ClassDetailModal({ userInfo, handleCancel, visible }) {
  const company_name = userInfoVar()?.company_name;
  return (
    <>
      <Modal centered visible={visible} title={`반`} onCancel={handleCancel} width={'80%'}>
        {isAfterSchool(company_name)?<ClassDetailForAfterSchool view_id={userInfo.idx}/>:<ClassDetail view_id={userInfo.idx} />}
      </Modal>
    </>
  );
}

export default ClassDetailModal;
