import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Typography, Select, Modal, Table, InputNumber } from 'antd';
import { GET_TEST_AND_ELIBRARY_RESULT_FOR_REPORTCARD } from 'src/operations/queries/reportcard';
import moment from 'moment';
import { EXTERNAL_USER_STUDY_HISTORY } from 'src/operations/queries/externalStudy';
const { Title } = Typography;
const { Option } = Select;
const FinalTestTh = styled.th`
  border: 1px solid #a4d0e9;
  background-color: #fafafa;
`;
const FinalTestTd = styled.td`
  border: 1px solid #a4d0e9;
  text-align: center;
  padding: 5px 0;
`;

const FinalTable = styled.table`
  font-size: 0.8em;
  @media print {
    font-size: 0.5em;
  }
`;
const StudyTable = styled(Table)`
  width: 90%;
  border-top: 3px solid #a4d0e9;
  border-right: 1px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background: #9b0000; */
    /* color: #fff; */
  }

  /* tr[class*='ant-table-row'] td[rowspan*='3']:first-child {
    background: #fbe5d6;
  }

  tr[class*='ant-table-row']:last-child td {
    background: #fbe5d6;
  } */

  tbody td {
    /* border-left: 1px solid #c5c5c5b3;
     */
    border-left: 1px solid #a4d0e9;
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media print {
    max-width: 700px;
    thead[class*='ant-table-thead'] th {
      padding: 3px;
      font-size: 0.5em;
    }
    tbody td {
      padding: 3px;
      font-size: 0.5em;
    }
  }
`;

const FinalTestInputTableAfterSchool = ({
  classBooks,
  class_idx,
  user_idx,
  dateRange,
  onChangeScore,
  result,
  setLoaded=()=>{},
  editable = false,
}) => {
  const [assignedTestIdx, setAssignTestIdx] = useState(0);
  const [manualFinalTestScore, setManualFinalTestScore] = useState([0, 0]);
  const [start_date, end_date] = useMemo(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      return [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')];
    }
    return [undefined, undefined];
  }, [dateRange]);

  // const [start_date, end_date] = useMemo(() => {
  //   if (dateRange && dateRange[0] && dateRange[1]) {
  //     return [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).add(7, 'days').format('YYYY-MM-DD')];
  //   }
  //   return [undefined, undefined];
  // }, [dateRange]);
  const { data, loading } = useQuery(GET_TEST_AND_ELIBRARY_RESULT_FOR_REPORTCARD, {
    variables: { class_idx, user_idx, start_date, end_date },
    skip: !class_idx || !user_idx || !start_date || !end_date||!editable,
  });
  const { data: bookData, loading: levelupLoading } = useQuery(EXTERNAL_USER_STUDY_HISTORY, {
    variables: { user_idx: parseInt(user_idx), start_date, end_date, type: 'levelup' },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });
  useEffect(()=>{
    if(!loading&&!levelupLoading){
      setLoaded(true);
    }
  },[loading,levelupLoading])
  const studyData = useMemo(() => {
    if (bookData?.getExternalStuiesList) {
      return bookData?.getExternalStuiesList
        .sort((a, b) => {
          if (moment(a.study_date).isSameOrAfter(b.study_date)) {
            return -1;
          }
          return 1;
        })
        .map((item, key) => {
          const recording_data = item.recording_data;
          const { exam_total, exam_correct, wpm, data } = item;
          // const dataparse = JSON.parse(data);
          // const score = dataparse?.score_percent || '';
          const { gamePlayResults } = JSON.parse(data) || {
            gamePlayResults: [],
          };
          const achievement = gamePlayResults
            ? gamePlayResults.reduce(
                (acc, cur) => {
                  if (cur.numberOfAttempts) {
                    acc.score += cur.numberOfAttempts;
                    acc.total += cur.numberOfElements;
                  } else if (cur.numberOfCorrectAttempts) {
                    acc.score += cur.numberOfCorrectAttempts;
                    acc.total += cur.numberOfElements;
                  } else if (cur.status == 1) {
                    acc.score += 1;
                    acc.total += 1;
                  }
                  return { ...acc };
                },
                { score: 0, total: 0 },
              )
            : {
                score: 0,
                total: 0,
              };
          return {
            key: `bookr-list-${key}`,
            accuracy: recording_data ? Math.round((100 * exam_correct) / exam_total) : null,
            wpm,
            // recording_data,
            // exam_total,
            // exam_correct,
            achievement: achievement.score > 0 ? Math.round((achievement.score * 100) / achievement.total) : 0,
          };
        });
    }
    return [];
  }, [bookData]);
  const [bookCount, bookAchievement, bookAccuracy, bookWPM] = useMemo(() => {
    if (studyData) {
      const tmp = studyData.reduce(
        (acc, cur) => {
          acc.achievement += cur.achievement;
          if (cur.accuracy !== null) {
            acc.count++;
            acc.accuracy += cur.accuracy;
            acc.wpm += cur.wpm;
          }

          return { ...acc };
        },
        { achievement: 0, accuracy: 0, wpm: 0, count: 0 },
      );

      return [
        studyData.length,
        studyData.length === 0 ? '--' : Math.floor(tmp.achievement / studyData.length),
        tmp.count === 0 ? '--' : Math.floor(tmp.accuracy / tmp.count),
        tmp.count === 0 ? '--' : Math.floor(tmp.wpm / tmp.count),
      ];
    }
    return ['--', '--', '--', '--'];
  }, [studyData]);
  const booklist = useMemo(() => {
    if (classBooks) {
      return classBooks.map((e) => e.book_sub[0].title);
    }
  }, [classBooks]);
  const dataJSON = useMemo(() => {
    if (result) {
      const tmpjson = JSON.parse(result);
      if (!editable) {
        return tmpjson;
      } else {
        const { finalData, ...rest } = tmpjson;
        const manualdata = finalData.filter((e) => booklist.includes(e.title));
        return { finalData: [...manualdata], ...rest };
      }
    }
    return undefined;
  }, [result]);
  const columns = [
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      key: 'correct',
      title: '맞은 개수',
      dataIndex: 'correct',
      align: 'center',
    },
    {
      key: 'score',
      title: 'Score',
      dataIndex: 'score',
      align: 'center',
    },
  ];
  // const finalTestOptions = useMemo(() => {
  //   if (true) {
  //     if (data?.getTestReportList && data.getTestReportList[0]) {
  //       if (dataJSON?.assigned_test_idx) {
  //         setAssignTestIdx(Number(dataJSON?.assigned_test_idx));
  //       } else {
  //         setAssignTestIdx(data.getTestReportList[0].idx);
  //       }

  //       return data.getTestReportList.map((item) => (
  //         <Option value={item.idx} key={`final-test-item${item.idx}`}>
  //           {item.title}
  //         </Option>
  //       ));
  //     }
  //     return [<Option value={0}>{bookTitle} 수동입력</Option>];
  //   }
  //   return [];
  // }, [data, bookTitle, dataJSON]);
  // const [finalTestCount, finalTestScore] = useMemo(() => {
  //   if (data?.getTestReportList) {
  //     const findItem = data.getTestReportList.find((ele) => ele.idx === assignedTestIdx);
  //     if (findItem) {
  //       const totalQuestion = findItem.tests.total_questions || 0;
  //       const userScoreItem = findItem.assigned_test_users.find((ele) => ele.user_idx === user_idx);

  //       const userScore = userScoreItem ? userScoreItem.score : 0;
  //       const percentScore = totalQuestion > 0 ? Math.round((100 * userScore) / totalQuestion) : 0;
  //       return [`${userScore}/${totalQuestion}`, percentScore];
  //     }
  //   }

  //   return ['--', '--'];
  // }, [data, assignedTestIdx, user_idx]);
  // const [bookCount, score] = useMemo(() => {
  //   const elibInfo = data?.studentElibraryStudyStatis;
  //   if (elibInfo) {
  //     const { read_book_count, average_grade } = elibInfo;
  //     return [read_book_count, average_grade];
  //   }
  //   return ['--', '--'];
  // }, [data]);
  const [testTitle, setTestTitle] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const [right, setRight] = useState(0);
  const [total, setTotal] = useState(0);
  const [finalData, setFinalData] = useState([]);
  useEffect(() => {
    if (testTitle) {
      setTotal(0);
      setRight(0);
      setVisible(true);
    }
  }, [testTitle]);
  const testData = useMemo(() => {
    const tmp = [];
    if (data?.getTestReportList) {
      data.getTestReportList.map((e) => {
        const userScore = e.assigned_test_users.filter((users) => parseInt(users.user_idx) === parseInt(user_idx))[0].score
        tmp.push({
          title: e.title,
          score: userScore
             ? Math.round((userScore * 100) / e.tests.total_questions)
             : '-'
            ,
          correct:userScore
            ? `${userScore}/${
                e.tests.total_questions
              }`
            : `-/${e.tests.total_questions}`,
        });
      });
    }
    if (dataJSON && dataJSON.finalData) {
      setFinalData([...tmp, ...dataJSON.finalData]);
    } else {
      setFinalData(tmp);
    }
    return tmp;
  }, [data, user_idx, dataJSON]);
  const scoreSubmit = () => {
    const tmp = [...finalData];
    // const before = tmp.filter((e)=>e.title===testTitle)
    const rest = tmp.filter((e) => e.title !== testTitle);
    if (testData && total > 0) {
      setFinalData([...rest, { title: testTitle, score: Math.round((right * 100) / total), correct: `${right}/${total}` }]);
    }
  };
  useEffect(() => {
    if (editable) {
      onChangeScore({
        finalData: finalData,
        bookCount: dataJSON?.bookCount || bookCount,
        bookAchievement: dataJSON?.bookAchievement || bookAchievement,
        bookAccuracy: dataJSON?.bookAccuracy || bookAccuracy,
        bookWPM: dataJSON?.bookWPM || bookWPM,
        // elibrary_score: dataJSON?.elibrary_score || score,
        assigned_test_idx: assignedTestIdx,
      });
    }
  }, [finalData, bookCount, bookAchievement, bookAccuracy, bookWPM, assignedTestIdx, dataJSON, editable]);
  // useEffect(() => {
  //   if (editable) {
  //     onChangeScore({
  //       final_test_count: finalTestCount,
  //       final_test_score: finalTestScore,
  //       elibrary_book_count: dataJSON?.elibrary_book_count || bookCount,
  //       elibrary_score: dataJSON?.elibrary_score || score,
  //       assigned_test_idx: assignedTestIdx,
  //     });
  //   }
  //   return () => {};
  // }, [finalTestCount, finalTestScore, bookCount, score, assignedTestIdx, dataJSON, editable]);

  // useEffect(() => {
  //   onChangeScore({
  //     final_test_count: `${manualFinalTestScore[0]}/${manualFinalTestScore[1]}`,
  //     final_test_score: manualFinalTestScore[1] ? Math.round((100 * manualFinalTestScore[0]) / manualFinalTestScore[1]) : 0,
  //     elibrary_book_count: dataJSON?.elibrary_book_count || bookCount,
  //     elibrary_score: dataJSON?.elibrary_score || score,
  //     assigned_test_idx: assignedTestIdx,
  //   });
  // }, [manualFinalTestScore, bookCount, score, assignedTestIdx, dataJSON]);

  // const handleChangeFinalScore = (finalScore) => {
  //   const [, t] = manualFinalTestScore;
  //   if (isNaN(finalScore)) {
  //     setManualFinalTestScore([0, t]);
  //   } else {
  //     setManualFinalTestScore([finalScore, t]);
  //   }
  // };
  // const handleChangeFinalTotal = (total) => {
  //   const [s] = manualFinalTestScore;
  //   if (isNaN(total)) {
  //     setManualFinalTestScore([s, 0]);
  //   } else {
  //     setManualFinalTestScore([s, total]);
  //   }
  // };

  // useEffect(() => {
  //   if (dataJSON) {
  //     if (dataJSON?.final_test_count && dataJSON?.final_test_count !== '--') {
  //       if (typeof dataJSON?.final_test_count === 'string') {
  //         const [c, t] = dataJSON?.final_test_count.split('/');
  //         const cnt = parseInt(c);
  //         const tot = parseInt(t);

  //         const testScore = [isNaN(cnt) ? 0 : cnt, isNaN(tot) ? 0 : tot];
  //         setManualFinalTestScore(testScore);
  //       }
  //     }
  //   } else {
  //     setManualFinalTestScore([0, 0]);
  //   }
  // }, [dataJSON]);

  return (
    <>
      <div style={{ display: 'flex', width: '100%', padding: '0 4%' }}>
        <div style={{ width: '50%' }}>
          <Title level={4}>
            Final Test &nbsp; &nbsp;
            {editable && (
              <Select
                loading={loading}
                value={testTitle ? testTitle : '수동입력 교재선택'}
                style={{ minWidth: '200px' }}
                onChange={setTestTitle}
              >
                {booklist.map((e) => (
                  <Option value={e}>{e}</Option>
                ))}
              </Select>
            )}
          </Title>
          <StudyTable columns={columns} dataSource={finalData} pagination={false} />
        </div>
        <div style={{ width: '50%' }}>
          <Title level={4}>
            전자도서관 성취도 &nbsp; &nbsp;
            {/* {editable && (
          <Select loading={loading} value={assignedTestIdx} style={{ minWidth: '200px' }} onChange={setAssignTestIdx}>
            {finalTestOptions}
          </Select>
        )} */}
          </Title>
          <FinalTable style={{ width: '90%', borderTop: '3px solid #a4d0e9' }}>
            <thead>
              <tr>
                {/* <FinalTestTh>맞은 개수</FinalTestTh>
            <FinalTestTh>Score</FinalTestTh> */}
                <FinalTestTh>읽은 책 권수</FinalTestTh>
                {/* <FinalTestTh>성취도</FinalTestTh> */}
                <FinalTestTh>말하기 정확도</FinalTestTh>
                <FinalTestTh>말하기 속도</FinalTestTh>
              </tr>
            </thead>
            <tbody>
              {/* {!editable ? ( */}
              <tr>
                {/* <FinalTestTd>{dataJSON?.assigned_test_idx ? finalTestCount : dataJSON?.final_test_count || '--'}</FinalTestTd>
              <FinalTestTd>{dataJSON?.assigned_test_idx ? finalTestScore : dataJSON?.final_test_score || '--'}</FinalTestTd> */}
                {/* <FinalTestTd>{dataJSON?.elibrary_book_count ||bookCount|| '--'}</FinalTestTd>
              <FinalTestTd>{dataJSON?.elibrary_score ||score|| '--'}</FinalTestTd> */}
              </tr>
              {/* ) : (
            <tr>
            {assignedTestIdx === 0 ? (
              <>
              <FinalTestTd>
              <InputNumber size="small" min={0} max={100} onChange={handleChangeFinalScore} value={manualFinalTestScore[0]} /> /&nbsp;
                    <InputNumber size="small" min={0} max={100} onChange={handleChangeFinalTotal} value={manualFinalTestScore[1]} />
                    </FinalTestTd>
                    <FinalTestTd>
                    <InputNumber
                    size="small"
                    disabled
                    value={manualFinalTestScore[1] && Math.round((100 * manualFinalTestScore[0]) / manualFinalTestScore[1])}
                    />
                    </FinalTestTd>
                    </>
                    ) : (
                      <>
                      <FinalTestTd>
                      {dataJSON?.assigned_test_idx || finalTestCount != '--' ? finalTestCount : dataJSON?.final_test_count || 0}
                      </FinalTestTd>
                      <FinalTestTd>
                      {dataJSON?.assigned_test_idx || finalTestScore != '--' ? finalTestScore : dataJSON?.final_test_score || 0}
                      </FinalTestTd>
                      </>
                    )} */}

              <tr>
                <FinalTestTd>{bookCount}</FinalTestTd>
                {/* <FinalTestTd>{bookAchievement}</FinalTestTd> */}
                <FinalTestTd>{bookAccuracy}</FinalTestTd>
                <FinalTestTd>{bookWPM}</FinalTestTd>
                {/* <FinalTestTd>{dataJSON?.elibrary_book_count || bookCount}</FinalTestTd>
              <FinalTestTd>{dataJSON?.elibrary_score || score}</FinalTestTd> */}
              </tr>
              {/* )} */}
            </tbody>
          </FinalTable>
        </div>
      </div>
      <Modal
        title={`${testTitle} 성적 수동입력`}
        centered
        visible={visible}
        width={500}
        onOk={() => {
          if (total >= right) {
            scoreSubmit();
            setTestTitle(undefined);
            setVisible(false);
          } else {
            Modal.info({
              title: '입력 값을 확인하세요.',
            });
          }
        }}
        onCancel={() => {
          setTestTitle(undefined);
          setVisible(false);
          setRight(0);
          setTotal(0);
        }}
      >
        <div style={{ display: 'flex' }}>
          <div>
            <div>맞은 개수</div>
            <InputNumber min={0} keyboard={false} onChange={setRight} value={right} />
          </div>
          <div>
            <div>총 개수</div>
            <InputNumber min={0} keyboard={false} onChange={setTotal} value={total} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FinalTestInputTableAfterSchool;
