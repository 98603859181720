import React, { useState, useCallback, useEffect } from 'react';
import AlphabetTopArea from './AlphabetTopArea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import SoundButton from './SoundButton';
import { ImagePopup } from './PopUp';
import { alphabetBoxTypeChecker } from '../../utils/pm/util';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const AlphabetDnD6 = ({ customData, steps, setSteps }) => {
  const { problem } = customData;

  const [isDropEnds, setIsDropEnds] = useState(Array(2).fill(false));
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);

  const handleDragFinish = (idx) => {
    const copy = _.cloneDeep(isDropEnds);
    copy[idx] = true;
    setIsDropEnds(copy);
  };

  const handleNext = () => {
    if (wordIndex < problem[steps].words.length - 1) {
      setWordIndex(wordIndex + 1);
    } else {
      setSteps(steps + 1);
      setIsDropEnds([false, false]);
      setIsShowPopup(false);
      setWordIndex(0);
    }
  };

  useEffect(() => {
    console.log('isDropEnds', isDropEnds);
    if (isDropEnds.every((v) => v === true)) {
      //setSteps(steps + 1);
      //setIsDropEnds([false, false]);
      setIsShowPopup(true);
    }
  }, [isDropEnds, steps, setSteps]);
  return (
    <>
      <S.RowFlex style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }} className="main_content  stage2_content  col2">
        <S.ImageWordSelectWriteBox>
          <AlphabetTopArea title={`Hard ${problem[steps] && problem[steps].letter.toUpperCase()}`} />
          <AlphabetGroupDnDContextArea
            prefix="hard"
            letter={problem[steps] && problem[steps].letter}
            letterGroup={problem[steps] && problem[steps].hard}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : '/sounds/'}
            onDragFinish={() => {
              handleDragFinish(0);
            }}
          />
        </S.ImageWordSelectWriteBox>
        <S.ImageWordSelectWriteBox style={{ marginLeft: '24px' }} className="right_box">
          <AlphabetTopArea title={`Soft ${problem[steps] && problem[steps].letter.toUpperCase()}`} />
          <AlphabetGroupDnDContextArea
            prefix="soft"
            letter={problem[steps] && problem[steps].letter}
            letterGroup={problem[steps] && problem[steps].soft}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : '/sounds/'}
            onDragFinish={() => {
              handleDragFinish(1);
            }}
          />
        </S.ImageWordSelectWriteBox>
      </S.RowFlex>
      {isShowPopup && (
        <ImagePopup
          discImage={problem[steps].words[wordIndex].discImage}
          wordImage={problem[steps].words[wordIndex].wordImage}
          word={problem[steps].words[wordIndex].word}
          number={wordIndex + 1}
          total={problem[steps].words.length}
          sound={problem[steps].words[wordIndex].sound}
          onNext={handleNext}
        />
      )}
    </>
  );
};

export default AlphabetDnD6;

const AlphabetGroupDnDContextArea = ({ prefix, letter, letterGroup, soundBaseUrl, onDragFinish }) => {
  const [dropSound, setDropSound] = useState(undefined);
  const [eachSound, setEachSound] = useState(undefined);
  const [dropped, setDropped] = useState(false);

  const handleDragEnd = useCallback((result) => {
    const { destination } = result;

    if (_.isNil(destination)) {
      setDropSound('wrong_sound1');
      return;
    }
    setDropSound('mouse-click');
    setDropped(true);
  }, []);

  const handleDropSoundEnd = () => {
    setDropSound(undefined);
  };

  const handleEachSoundEnd = () => {
    console.log('handleEachSoundEnd');
    setEachSound(undefined);
    if (dropped) {
      onDragFinish();
    }
  };

  useEffect(() => {
    if (dropped) {
      setEachSound(`${soundBaseUrl}${prefix}_${letter}.mp3`);
    }
  }, [dropped]);

  useEffect(() => {
    setDropped(false);
  }, [letter]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <S.AlphabetWrap className="alphabet_wrap">
          <li>
            <S.SoundWrap className="button_box">
              <SoundButton src={`${soundBaseUrl}${prefix}_${letter}.mp3`} />
            </S.SoundWrap>
            <AlphabetGroupDroppableBox prefix={prefix} letter={letter} isDropped={dropped} />
          </li>
        </S.AlphabetWrap>
        <Arrow
          // style={{ height: '3rem', marginBottom: '1vh' }}
          src="/images/pm/i_arrow_top.svg"
          alt="위로 향하는 화살표"
          className="top_arrow"
        />
        <S.AlphabetBottomWrapUl className="alphabet_wrap gray_bg_border_top">
          <AlphabetGroupDraggableBox prefix={prefix} letter={letter} letterGroup={letterGroup} idx={0} />
        </S.AlphabetBottomWrapUl>
      </DragDropContext>
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      {eachSound && <SoundEffectPM url={eachSound} onEnd={handleEachSoundEnd} />}
    </>
  );
};

const AlphabetGroupDroppableBox = ({ prefix, letter, isDropped }) => {
  return (
    <Droppable droppableId={`upper_${letter}`} type={`${prefix}_${letter}`}>
      {(provided, snapshot) => (
        <>
          <S.AlphabetBox
            className={`alphabet_box rows ${isDropped ? 'green' : 'gray'}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined,
              alignItems: 'center',
            }}
            row={alphabetBoxTypeChecker(9)}
            gray={!isDropped}
          >
            <span style={{ color: isDropped ? '#f368e0' : '#eee' }} className={`font_${isDropped ? 'pink' : 'gray'}`}>
              {letter && `${prefix} ${letter.toUpperCase()}`}
            </span>
          </S.AlphabetBox>
          <div style={{ display: 'none' }}>{provided.placeholder}</div>
        </>
      )}
    </Droppable>
  );
};

const AlphabetGroupDraggableBox = ({ prefix, letter, letterGroup, idx }) => {
  return (
    <Droppable droppableId={`under_${prefix}_${letter}`} type={`${prefix}_${letter}`} isDropDisabled={true}>
      {(providedDrop) => (
        <li ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
          <Draggable draggableId={`underDrag_${prefix}_${letter}`} index={idx} type={`${prefix}_${letter}`}>
            {(provided) => (
              <S.AlphabetBox
                style={{
                  alignItems: 'center',
                }}
                row={alphabetBoxTypeChecker(9)}
                className={`alphabet_box rows green`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <S.RowFlex style={{ alignItems: 'center', height: '100%' }} className="inner_wrap">
                  <Large className="large">
                    <span style={{ color: '#ee5353' }} className="font_red">
                      {letter}
                    </span>
                  </Large>
                  <Plus>+</Plus>
                  <Small className="small">
                    <span style={{ color: '#2e86de' }} className="font_blue">
                      {letterGroup &&
                        letterGroup.map((item, key) => (
                          <span key={`letter-group-key${key}`}>
                            {item}
                            <br />
                          </span>
                        ))}
                    </span>
                  </Small>
                </S.RowFlex>
              </S.AlphabetBox>
            )}
          </Draggable>
          <ul style={{ display: 'none' }}>{providedDrop.placeholder}</ul>
        </li>
      )}
    </Droppable>
  );
};
const Arrow = styled.img`
  height: 3rem;
  margin-bottom: 1vh;
  @media (max-height: 500px) {
    height: 1.7rem;
    margin: 0.5vh;
  }
`;
const Large = styled.p`
  margin: 0;
  padding: 3px 6px;
  text-align: center;
  font-size: 50px;
  line-height: 40px;
  @media (max-height: 500px) {
    font-size: 35px;
    line-height: 35px;

  }
`;
const Plus = styled.p`
  margin: 0;
  padding: 3px 6px;
  text-align: center;
  font-size: 38px;
  line-height: 40px;
  @media (max-height: 500px) {
    font-size: 28px;
    line-height: 30px;
    
  }
`;
const Small = styled.p`
  margin: 0;
  padding: 3px 6px;
  text-align: center;
  font-size: 25px;
  line-height: 28px;
  @media (max-height: 500px) {
    font-size: 20px;
    line-height: 22px;
    
  }
`;
// const DndLi = styled.li`
// margin: 0;
// padding: 0;
// `;
