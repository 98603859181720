import { gql } from '@apollo/client';

export const CREATE_BOOK_RETURN = gql`
  mutation createBookReturn($book_return_input: BookReturnInput!) {
    createBookReturn(book_return_input: $book_return_input) {
      success
      message
    }
  }
`;

export const DELETE_BOOK_RETURN = gql`
  mutation deleteBookReturn($idx: Int!) {
    deleteBookReturn(idx: $idx) {
      success
      message
    }
  }
`;

export const UPDATE_BOOK_RETURN_STATUS = gql`
  mutation updateBookReturnStatus($idx: Int!, $stat: String!) {
    updateBookReturnStatus(idx: $idx, stat: $stat) {
      success
      message
    }
  }
`;
