import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { Input, Form, DatePicker, Typography, Checkbox, Radio, Select, TimePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import FormField from 'src/components/common/FormField';
// import { Col, Row, Select, Modal, Button, Input } from 'antd';
import moment from 'moment';
import { SurveyWrapper } from 'src/popups/LevelTestAppForm/LevelTestStyledComp';
import { userInfoVar } from 'src/apollo/cache';
import Schema from 'async-validator';
import CheckboxDropdownComponent, { createStyles } from 'react-checkbox-dropdown';

const { Option } = Select;
const options = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Satuarday', 'Sunday'].map((item) => ({
  value: item,
  label: item,
}));

const CreateMetaScheduleForm = ({ form, onCreate, closeForm, scheduledata }) => {
  const [beginTime, setBeginTime] = useState();
  const [endTime, setEndTime] = useState();
  const [days, setDays] = useState();
  const [days1, setDays1] = useState([]);
  const [sysNo, setSysNo] = useState();

  const [beginTimeError, setBeginTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [daysError, setDaysError] = useState(false);
  const [daysError1, setDaysError1] = useState(false);
  const [sysNoError, setSysNoError] = useState(false);

  const [campus_id, setCampusId] = useState();
  const { campus_idx } = userInfoVar();

  useEffect(() => {
    setCampusId(campus_idx);
  }, [campus_idx]);

  function onTimeChange(name, timeString) {
    console.log('name', name);
    if (name == 'start_time') {
      setBeginTime(timeString);
    }
    if (name == 'end_time') {
      setEndTime(timeString);
    }
  }
  useEffect(() => {
    if (closeForm == true) {
      setBeginTimeError(false);
      setEndTimeError(false);
      setDaysError(false);
      setSysNoError(false);
      setDaysError1(false);
    }
  }, [closeForm]);

  const handleSubmit = (values) => {
    const variables = {};
    if (beginTime == ' ' || beginTime == undefined) {
      setBeginTimeError(true);
    }
    if (endTime == ' ' || endTime == undefined) {
      setEndTimeError(true);
    }
    // if (days == " " || days == undefined) {
    // setDaysError(true)
    // }
    if (days1.length == 0 || days1.length == undefined) {
      setDaysError1(true);
    }
    if (sysNo == ' ' || sysNo == undefined) {
      setSysNoError(true);
    }
    // const res = days1;
    // res.map((e, k) => {
    //   console.log("element", e, k)
    //   if (e.value == "Mon") {
    //     days1[k].value = "Monday"
    //     days1[k].label = "Monday"
    //   }
    //   if (e.value == "Tue") {
    //     days1[k].value = "Tuesday"
    //     days1[k].label = "Tuesday"
    //   }
    //   if (e.value == "Wed") {
    //     days1[k].value = "Wednesday"
    //     days1[k].label = "Wednesday"
    //   }
    //   if (e.value == "Thu") {
    //     days1[k].value = "Thursday"
    //     days1[k].label = "Thursday"
    //   }
    //   if (e.value == "Fri") {
    //     days1[k].value = "Friday"
    //     days1[k].label = "Friday"
    //   }
    //   if (e.value == "Sat") {
    //     days1[k].value = "Saturday"
    //     days1[k].label = "Saturday"
    //   }
    // })

    if (beginTime && endTime && sysNo) {
      variables['start_time'] = beginTime;
      variables['end_time'] = endTime;
      variables['days'] = JSON.stringify(days1);
      variables['no_of_system'] = sysNo;
      variables['campus_idx'] = campus_id;
      //variables['meta_schedule'] = JSON.stringify(scheduledata)
      onCreate({ variables });
      return false;
    }
  };
  useEffect(() => {
    Schema.warning = function () {};
  }, []);
  useMemo(() => {
    if (days1.length > 0) {
      setDaysError1(false);
    }
  }, [days1]);

  useEffect(() => {
    const { campus_idx } = userInfoVar();
    form.setFieldsValue({ campus_idx });
  }, [form]);

  return (
    <>
      <Form name="control-ref" layout="inline" form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
        <Form.Item
          style={{ display: 'none' }}
          name="campus_idx"
          rules={[
            {
              required: true,
              message: '학원을 선택해 주세요.',
            },
          ]}
        >
          <Input type="hidden" name="campus_idx" />
        </Form.Item>
        <div className={days1.length == 0 ? 'unfill-day day-dropdown' : 'fill-day day-dropdown'}>
          <FormField hasFeedback={true} title="요일 선택">
            <Form.Item>
              <CheckboxDropdownComponent
                displayText="요일 선택"
                options={options}
                onChange={(option) => {
                  if (!days1.includes(option)) {
                    const newValue = [...days1, option];
                    setDays1(newValue);
                  }
                }}
                onDeselectOption={(option) => {
                  const filteredOptions = days1.filter((item) => item.value !== option.value);
                  setDays1(filteredOptions);
                }}
                value={days1}
                displayTags
                isStrict={false}
              />
              {daysError1 ? <label style={{ color: 'red' }}>요일을 선택하세요</label> : ''}
            </Form.Item>
          </FormField>
        </div>
        <FormField hasFeedback={true} title="시스템 수">
          <Form.Item
            name="no_of_system"
            className="system-no"
            rules={[
              {
                //required: true,
                //whitespace: true,
                //message: '시스템 수 ',
              },
            ]}
          >
            <Input
              placeholder="시스템 수"
              size="large"
              name="student_name2"
              onChange={(e) => (setSysNo(e.target.value), setSysNoError(false))}
            ></Input>
            {sysNoError ? <label style={{ color: 'red' }}>시스템 번호를 입력하십시오</label> : ''}
          </Form.Item>
        </FormField>

        <FormField hasFeedback={true} title="시작 시간">
          <FormItem name="start_time" hasFeedback style={{ width: '100%' }}>
            <TimePicker
              name="start_time"
              onChange={(_, t) => (onTimeChange('start_time', t), setBeginTimeError(false))}
              placeholder="00:00"
              format={'HH:mm'}
            />
          </FormItem>
          {beginTimeError ? <label style={{ color: 'red' }}>시작 시간을 선택하세요</label> : ''}
        </FormField>

        <FormField hasFeedback={true} title="종료 시간">
          <FormItem name="end_time" hasFeedback style={{ width: '100%' }}>
            <TimePicker
              name="end_time"
              onChange={(_, t) => (onTimeChange('end_time', t), setEndTimeError(false))}
              placeholder="00:00"
              format={'HH:mm'}
            />
          </FormItem>
          {endTimeError ? <label style={{ color: 'red' }}>종료 시간을 선택하세요</label> : ''}
        </FormField>
      </Form>
      <div className="ant-modal-footer cust-footer">
        <Button key="submit" type="primary" onClick={() => handleSubmit()}>
          확인
        </Button>
      </div>
    </>
  );
};

export default CreateMetaScheduleForm;
