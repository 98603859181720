import { gql } from '@apollo/client';

export const CREATE_ZOOM_SCHEDULE = gql`
  mutation createZoomSchedule(
    $title: String!
    $zoom_host_id: String!
    $start_time: String!
    $end_time: String!
    $class_idx: Int
    $zoom_user_idx_list: [Int!]!
  ) {
    createZoomSchedule(
        zoom_host_id: $zoom_host_id
        title: $title
        start_time: $start_time
        end_time: $end_time
        class_idx: $class_idx
        zoom_user_idx_list: $zoom_user_idx_list
    ) {
      idx
    }
  }
`;