import React, { useState } from 'react';
import _ from 'lodash';
import { ImagePopup } from './PopUp';
import AlphabetTopArea from './AlphabetTopArea';
import AlphabetDnDContextArea from './AlphabetDnDContextArea';
import * as S from 'src/pages/PhonicsMonster/style';
const AlphabetDnD5 = ({ customData, steps, setSteps }) => {
  const { problem } = customData;
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const handleDragFinish = () => {
    //setSteps(steps + 1);
    setIsShowPopup(true);
  };
  const handleNext = () => {
    if (wordIndex < problem[steps].words.length - 1) {
      setWordIndex(wordIndex + 1);
    } else {
      setSteps(steps + 1);
      setIsShowPopup(false);
      setWordIndex(0);
    }
  };
//   console.log(problem[steps].words[wordIndex].sound,problem[steps])
  return (
    <>
      <S.RowFlex
        style={{
          alignSelf: 'stretch',
          flex: '1 1',
		  height:'calc(100% - 70px)'
        }}
        className="main_content  stage2_content  col2"
      >
        <S.BorderColumnBox
          style={{
            flex: '1 1',
            position: 'relative',
          }}
          className=""
        >
          <AlphabetTopArea title="Double Letters" />
          <AlphabetDnDContextArea
            letters={problem[steps].double}
            soundBaseUrl={!_.isNil(customData.soundBaseUrl) ? customData.soundBaseUrl : '/sounds/'}
            onDragFinish={handleDragFinish}
          />
        </S.BorderColumnBox>
      </S.RowFlex>
      {isShowPopup && (
        <ImagePopup
          discImage={problem[steps].discImage}
          wordImage={problem[steps].words[wordIndex].wordImage}
          word={problem[steps].words[wordIndex].word}
          number={wordIndex + 1}
          total={problem[steps].words.length}
          sound={problem[steps].words[wordIndex].sound}
          onNext={handleNext}
        />
      )}
    </>
  );
};

export default AlphabetDnD5;
