import { gql } from '@apollo/client';
export const PHONICS_ASSIGN_ELIBRARY_TO_STUDENTS = gql`
  mutation PhonicsAssignedElibrary(
    $class_idx: Int
    $limit_time: Int!
    $start_time: [String!]!
    $start_date: String!
    $end_date: String!
    $books_list: [String!]!
    $user_idx_list: [Int!]!
    $elibrary_time: String!
    $rowidx: Int
  ) {
    PhonicsAssignedElibrary(
      class_idx: $class_idx
      limit_time: $limit_time
      start_time: $start_time
      books_list: $books_list
      user_idx_list: $user_idx_list
      start_date: $start_date
      end_date: $end_date
      elibrary_time: $elibrary_time
      rowidx: $rowidx
    )
  }
`;

//foreign key에 delete action을 cascade로 해 놓았기 때문에 관련된 데이터는 모두 삭제가 된다.
export const PHONICS_DELETE_ASSIGN_ELIBRARY = gql`
  mutation DeletePhonicsAssigned($idx: Int!) {
    DeletePhonicsAssigned(idx: $idx)
  }
`;
export const PHONICS_DELETE_ASSIGN_ELIBRARY_BULK = gql`
  mutation DeletePhonicsAssigned($idx_list: [Int!]!) {
    DeletePhonicsAssigned(idx_list: $idx_list)
  }
`;
