import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { Col, Row, Typography } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';

import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { DivBlock } from 'src/components/common/Styles';
import StudentListModal from 'src/components/Modal/message/StudentListModal';
import TeacherListModal from 'src/components/Modal/message/TeacherListModal';
import MessageList from './MessageList';
import MessageForm from './MessageForm';
import MessageBox from './MessageBox';

import { GET_CHAT_ROOM_LIST } from 'src/operations/queries/messenger';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import { PlusOutlined } from '@ant-design/icons';
//import StudentSelectBox from 'src/components/common/StudentSelectBox';

const MESSAGE_PAGE_SIZE = 5;
const { Text } = Typography;

const SubPageBlock = styled.div`

`;

function SubPage() {
  const [chatRoomIdx, setChatRoomIdx] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([0]);
  const [visible, setVisible] = useState(false);
  const [startChatRoomObj, setStartChatRoomObj] = useState(null);
  const [pageCurrent, setPageCurrent] = useState(1);
  const textAreaEl = useRef(null);

  const token = getCurrentToken();

  const { data: userData } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });

  const { data, error, refetch } = useQuery(GET_CHAT_ROOM_LIST, {
    fetchPolicy: 'no-cache',
    variables: { user_idx: parseInt(userInfoVar()?.idx) },
  });

  const messageData = useMemo(() => {
    if (data?.chatRoomList) {
      return data.chatRoomList
        .map((item, key) => {
          const lastest = item?.chat_room?.lastestInfo;
          let unReadCount = 0;
          if (item?.chat_room?.chat_message) {
            const countList = item?.chat_room?.chat_message.filter((msg) => {
              const me_idx = parseInt(userInfoVar().idx);
              console.log('msg', msg.chat_message_read_user);
              if (msg.chat_user_idx !== me_idx) {
                if (msg.chat_message_read_user.length > 0) {
                  if (msg.chat_message_read_user.map((user) => user.chat_user_idx).indexOf(me_idx) === -1) {
                    return true;
                  }
                  return false;
                }
                return true;
              }
              return false;
            });
            unReadCount = countList.length;
          }
          return {
            idx: item?.chat_room?.idx,
            key: `chat-list-${key + 1}`,
            title: item?.subject || item.chat_room.subject,
            isNew: false,
            unReadCount,
            content: lastest?.content || '',
            date: moment(lastest?.idate || item.idate).format('YYYY-MM-DD'),
            lastestDate: lastest?.idate || item.idate,
          };
        })
        .sort((a, b) => {
          const isSame = moment(a).isSame(b);
          if (isSame) {
            return 0;
          }
          return moment(a).isAfter(b) ? 1 : -1;
        });
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (messageData && messageData.length > 0) {
      if (startChatRoomObj) {
        const findIdx = messageData.findIndex((ele) => ele.idx === startChatRoomObj.idx);
        selectRow(messageData[findIdx]);
        //pagination도 바뀌어야 한다.
        const pageNumber = Math.floor(findIdx / MESSAGE_PAGE_SIZE) + 1;
        setPageCurrent(pageNumber);
      } else {
        selectRow(messageData[0]);
      }
    }
  }, [messageData, startChatRoomObj]);

  const handleChatStart = (roomData) => {
    setStartChatRoomObj(roomData?.createChatRoom);
    setVisible(false);
    refetch({ variables: { user_idx: parseInt(userInfoVar()?.idx) } });

    textAreaEl.current.focus();
  };

  const selectRow = (record) => {
    if (record) {
      setChatRoomIdx(record.idx);
      setSelectedRowKeys([record.key]);

      textAreaEl.current && textAreaEl.current.focus();
    }
  };

  if (error) {
    console.log(`chat room data fetch error: `, error);
  }

  return (
    <>
      <Col className="subboxone-dashboard" span={12}>
        <SubPageBlock className="subboxone-dashboard-height">
          <Row gutter={[0, 8]}>
            <Col className="subboxone-dashboard-btn" span={24} >
              <CustomButton className="subboxone-dashboard-cbtn" onClick={() => setVisible(true)}>
                <PlusOutlined />
                <Text type={'success'}>대화방추가</Text>
              </CustomButton>
            </Col>
          </Row>
          <CustomTable
            rowSelection={{
              //type: 'checkbox',
              hideSelectAll: true,
              selectedRowKeys,
              type: 'radio',
              onChange: (_, rows) => {
                selectRow(rows[0]);
              },
            }}
            onRow={(record) => ({
              onClick: () => {
                selectRow(record);
              },
            })}
            onChange={(pagination, _a, _b, { currentDataSource }) => {
              const { current, pageSize } = pagination;
              const first = pageSize * (current - 1);
              const firstRow = currentDataSource[first];
              selectRow(firstRow);
              setPageCurrent(current);
            }}
            columns={columns}
            dataSource={messageData}
            pagination={{
              current: pageCurrent,
              pageSize: MESSAGE_PAGE_SIZE,
              position: ['bottomCenter'],
            }}
            size="small"
            color="#edf3fb"
            scroll={{ y: 'calc(100vh - 254px)' }}
          />
        </SubPageBlock>
      </Col>

      <Col span={12} className="subboxtwo-dashboard-chat">
        {chatRoomIdx > 0 ? (
          <SubPageBlock className="subboxtwo-dashboard-chat-height">
            <MessageBox id="MessageBox">
              <MessageList userData={userData} chat_room_idx={chatRoomIdx} />
            </MessageBox>
            <MessageForm userData={userData} chat_room_idx={chatRoomIdx} textAreaEl={textAreaEl} refetch={refetch} />
          </SubPageBlock>
        ) : (
          <SubPageBlock className="subboxtwo-dashboard-chat-height">
            <Text>대화방을 추가하세요.</Text>
          </SubPageBlock>
        )}
      </Col>

      {userData?.me?.type === 0 ? (
        <TeacherListModal
          student_idx={userData?.me?.idx}
          onCancel={() => {
            setVisible(false);
          }}
          onChatStart={handleChatStart}
          visible={visible}
        />
      ) : (
        <StudentListModal
          teacher_idx={userData?.me?.idx}
          onCancel={() => {
            setVisible(false);
          }}
          onChatStart={handleChatStart}
          visible={visible}
        />
      )}
    </>
  );
}

const columns = [
  {
    title: '대화방',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    width: 70,
  },
  {
    title: '내용',
    dataIndex: 'content',
    key: 'content',
    align: 'center',
    width: 180,
    render: (text, { unReadCount }) => (
      <>
        {text}
        {unReadCount > 0 && (
          <span
            style={{
              display: 'inline-block',
              marginLeft: '2px',
              padding: '2px 6px',
              borderRadius: '50%',
              background: '#f76707',
              color: '#fff',
              fontWeight: '600',
              fontSize: '9px',
            }}
          >
            {unReadCount}
          </span>
        )}
      </>
    ),
  },
  {
    title: '날짜',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: 130,
  },
];

export default SubPage;
