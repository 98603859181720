import React from 'react';
import { Form, Row, Modal, Button } from 'antd';

import CreateStudentForm from 'src/pages/Students/CreateStudentForm';
import UpdateStudentForm from 'src/pages/Students/UpdateStudentForm';

import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

import { StudentResult } from 'src/components/Test';

function AssignedSyllabusStudentModal({ userTestInfo, visible, handleCancel, popupMode }) {
  const [form] = Form.useForm();

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  return (
    <>
      <Modal
        className="fullwdmodal"
        visible={visible}
        title="시험 결과"
        onCancel={handleCancel}
        width={'80%'}
        style={{ top: 10 }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <StudentResult id={userTestInfo.assignedTests} user_idx={userTestInfo.user_idx} />
      </Modal>
    </>
  );
}

export default AssignedSyllabusStudentModal;
