import React from 'react';
import { Button, Modal, Typography } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { getHumanReadablePhoneNumber } from 'src/utils';

const { Title } = Typography;
const InfoTitle = styled(Title)`
  margin-bottom: 1px !important;
`;
const InfoTable = styled.table`
  margin-top: 0;
  margin-bottom: 15px;
`;

const DeliveryInfoModal = ({ visible, info, onCancel }) => {
  console.log('DeliveryInfoModal', info);
  return (
    <Modal
      visible={visible}
      title={`${info?.campus_name} 배송정보`}
      onCancel={onCancel}
      width={'60%'}
      footer={
        <Button key="back" onClick={() => onCancel()}>
          확인
        </Button>
      }
    >
      <div style={{ marginBottom: 10 }}>{moment(info?.idate).format('YYYY-MM-DD')}</div>
      <InfoTitle level={4}>배송정보</InfoTitle>
      <InfoTable className="table-t-three">
        <tbody>
          <tr>
            <th style={{ width: '200px' }}>수취인</th>
            <td>{info?.order_payment?.buyer_name}</td>
            <th style={{ width: '200px' }}>휴대전화</th>
            <td>{getHumanReadablePhoneNumber(info?.order_payment?.buyer_tel)}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>배송지</th>
            <td colSpan="3">{info?.order_payment?.buyer_addr}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>배송시 요청사항</th>
            <td colSpan="3">{info?.order_payment?.delivery_comment}</td>
          </tr>
        </tbody>
      </InfoTable>
      <InfoTitle level={4}>결제방법</InfoTitle>
      <InfoTable className="table-t-three">
        <tbody>
          <tr>
            <th style={{ width: '100px' }}>결제방법</th>
            <td colSpan="3">{info?.order_payment?.pay_method?.includes('card') ? '신용카드' : '직접결제'}</td>
          </tr>
        </tbody>
      </InfoTable>
    </Modal>
  );
};

export default DeliveryInfoModal;
