import React from 'react';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import StudentsCommon from './StudentsCommon';
import StudentsForSmartEclass from './StudentsForSmartEclass';
import { isPturn } from 'src/utils';

function Students() {
  const companyName = useSelector(classStoreData);
  return isPturn(companyName) ? <StudentsForSmartEclass /> : <StudentsCommon />;
}

export default Students;
