import React, { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';

function BookrOpenModal({ url, handleClose, visible }) {
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(true);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setIsIFrameLoaded(false);
      }, 5000);

      setTimeout(() => {
        handleClose();
      }, 20000);
    }
  }, [visible]);

  useEffect(() => {
    if (url) {
      window.location.href = 'bookrclass://app';
    }
  }, [url]);

  return (
    <>
      <Modal
        centered
        visible={visible}
        title={
          <>
            {/* <div style={{fontWeight: 'bold'}}>Bookr(전자도서관) 서버 점검 안내</div> */}
            <div style={{ fontWeight: '600' }}>*앱 다운로드</div>
            <div style={{ fontWeight: '600' }}> 앱 다운로드가 필요하면, 아래 4개의 스토어에서 디바이스에 적합한 스토어를 선택하세요!</div>
          </>
        }
        onCancel={handleClose}
        // width={'60%'}
        width={'40%'}
        footer={null}
      >
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          {/* <img src='/images/popup/popup_230414_bookr.png'/> */}
          {isIFrameLoaded && <Spin size="large" />}
          <p style={{ color: '#545454', fontWeight: '600' }}>※이 창은 최초 앱 다운로드시에만 필요합니다.</p>
          <p style={{ color: '#545454', fontWeight: '600' }}>앱 설치 후에는 학습창을 열기 위한 과정으로 자동으로 화면이 사라집니다.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <a
                href="https://apps.microsoft.com/store/detail/bookr-class/9MSQQZFSQSHP"
                target="_blank"
                className="vc_single_image-wrapper   vc_box_border_grey"
                rel="noopener"
              >
                <img
                  width="100"
                  height="26"
                  src="https://bookrclass.com/wp-content/uploads/2021/06/get-it-from-Microsoft.svg"
                  className="vc_single_image-img attachment-full"
                  alt=""
                  decoding="async"
                  loading="lazy"
                  data-id="2305"
                />
              </a>
              <h2 style={{ color: '#545454', fontWeight: '700' }}>Windows</h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <a
                id="download_app_mac"
                href="https://apps.apple.com/us/app/bookr-class-learn-english/id1478717573"
                target="_blank"
                className="vc_single_image-wrapper   vc_box_border_grey"
                rel="noopener"
              >
                <img
                  width="100"
                  height="26"
                  src="https://bookrclass.com/wp-content/uploads/2021/06/mac-app-store.svg"
                  className="vc_single_image-img attachment-full"
                  alt=""
                  loading="lazy"
                  data-id="2304"
                />
              </a>
              <h2 style={{ color: '#545454', fontWeight: '700' }}>Mac</h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <a
                id="download_app_android"
                href="https://play.google.com/store/apps/details?id=com.bookrclass.edu&amp;hl=en&amp;gl=US"
                target="_blank"
                className="vc_single_image-wrapper   vc_box_border_grey"
                rel="noopener"
              >
                <img
                  width="100"
                  height="26"
                  src="https://bookrclass.com/wp-content/uploads/2021/05/playstore.svg"
                  className="vc_single_image-img attachment-full"
                  alt=""
                  loading="lazy"
                  data-id="168"
                />
              </a>
              <h2 style={{ color: '#545454', fontWeight: '700' }}>Android</h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <a
                id="download_app_apple"
                href="https://apps.apple.com/hu/app/bookr-class/id1478717573"
                target="_blank"
                className="vc_single_image-wrapper   vc_box_border_grey"
                rel="noopener"
              >
                <img
                  width="100"
                  height="26"
                  src="https://bookrclass.com/wp-content/uploads/2021/05/appstore.svg"
                  className="vc_single_image-img attachment-full"
                  alt=""
                  loading="lazy"
                  data-id="167"
                />
              </a>
              <h2 style={{ color: '#545454', fontWeight: '700' }}>iOS</h2>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default BookrOpenModal;
