import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Col, Select } from 'antd';
import ClassGroupSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox/ClassGroupSelectBox';
import TeacherSelectBox, { useTeacherSelectBox } from 'src/components/common/TeacherSelectBox';
import { userInfoVar } from 'src/apollo/cache';
import { MULTI_CLASSS_STUEDNT_LIST } from 'src/operations/queries/getClass';

const { Option } = Select;

const SearchClassGroup = ({ onSearchClassChange = undefined, onSearchStudentChange = undefined, hasStudentDropbox = false }) => {
  const campus_idx = userInfoVar()?.campus_idx;
  const user_type = userInfoVar()?.type;

  const { selectedTeacherIdx, onTeacherChange } = useTeacherSelectBox();
  const [studntUserIdx, setStudentUserIdx] = useState(0);
  const { selectedClassValue, onClassChange } = useCampusClass();

  const [getSelectClassStudents, { data, loading }] = useLazyQuery(MULTI_CLASSS_STUEDNT_LIST, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (selectedClassValue) {
      getSelectClassStudents({ variables: { class_idx: JSON.parse(selectedClassValue) } });
    }
  }, [selectedClassValue]);

  const dataStudents = useMemo(() => {
    const dataClassStudent = data?.multiClassStudentList;
    if (dataClassStudent) {
      return dataClassStudent.reduce((acc, cur) => {
        const find = acc.find((ele) => ele.user.idx === cur.user.idx);
        if (!find) {
          return [...acc, { user: cur.user }];
        }
        return [...acc];
      }, []);
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (onSearchClassChange instanceof Function) {
      onSearchClassChange(selectedClassValue);
    }
  }, [selectedClassValue, onSearchClassChange]);

  useEffect(() => {
    if (onSearchStudentChange instanceof Function && hasStudentDropbox) {
      onSearchStudentChange(studntUserIdx);
    }
  }, [studntUserIdx, hasStudentDropbox, onSearchStudentChange]);

  useEffect(() => {
    setStudentUserIdx(0);
  }, [selectedTeacherIdx, selectedClassValue]);

  return (
    <>
      {parseInt(user_type) === 2 && (
        <Col span={3}>
          <TeacherSelectBox
            isAll={true}
            selectedTeacherIdx={selectedTeacherIdx}
            size={`default`}
            style={{ width: '100%' }}
            onChange={onTeacherChange}
          ></TeacherSelectBox>
        </Col>
      )}

      <Col span={6}>
        <ClassGroupSelectBox
          campus_idx={campus_idx}
          class_teacher_idx={selectedTeacherIdx}
          style={{ width: '100%' }}
          classValue={selectedClassValue}
          onClassChange={onClassChange}
        />
      </Col>
      {hasStudentDropbox && (
        <Col span={3}>
          <Select size={`default`} style={{ width: '100%' }} loading={loading} value={studntUserIdx} onChange={setStudentUserIdx}>
            <Option key={`class-student-option${'000'}`} value={0}>
              선택
            </Option>
            {dataStudents &&
              dataStudents.map(({ user: { idx, name } }, key) => (
                <Option key={`class-student-option${key}`} value={idx}>
                  {name}
                </Option>
              ))}
          </Select>
        </Col>
      )}
    </>
  );
};

export default SearchClassGroup;

const defaultClassVaule = JSON.stringify([]);

export const useSearchClass = () => {
  const [selectedClassValue, setSelectedClassValue] = useState(defaultClassVaule);
  const onChange = (val) => {
    setSelectedClassValue(val);
  };
  return { selectedClassValue, onChange };
};

export const useSearchStudent = () => {
  const [selectedUserIdx, setSelectedUserIdx] = useState(0);
  const onChange = (user_idx) => {
    setSelectedUserIdx(user_idx);
  };
  return { selectedUserIdx, onChange };
};
