import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typographym, Button } from 'antd';
import { PlayCircleFilled, SoundFilled, ExclamationCircleFilled } from '@ant-design/icons';

import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import VocaHeader from './VocaHeader';

const VocaFlashcardTyping = () => {
  const wordbox = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <>
      <div className="flashcard-voca-con typing">
        <div className="question-con">
          <div className="num">
            <h3>
              <span>05</span>/<span>10</span>
            </h3>
          </div>
          <div className="inner top">
            <div className="type-wrap">
              <h1> Listen and Type.</h1>
              <div className="type-inner">
                <div className="top">
                  <div className="answer-wrap">
                    <div className="inner">
                      <p>●●●●●●</p>
                    </div>
                  </div>
                  <Button className="chk-btn">Check</Button>
                </div>
                <div className="btm">
                  <div className="result-wrap">
                    <div>
                      {' '}
                      <h3>Tries:</h3>
                      <div className="box">
                        <h3>3</h3>
                      </div>
                    </div>
                    <div>
                      <h5>Max Tries: 3</h5>
                    </div>
                  </div>
                  <div className="result-wrap">
                    <div>
                      {' '}
                      <h3>Score:</h3>
                      <div className="box">
                        <h3>3</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inner bottom">
            <div>
              <SoundFilled className="speaker-btn" />
            </div>
            <div>
              <PlayCircleFilled className="prev-btn" />{' '}
            </div>
            <div>
              <PlayCircleFilled className="play-btn" />{' '}
            </div>
            <div>
              <PlayCircleFilled />{' '}
            </div>
            <div>
              <ExclamationCircleFilled />
            </div>
          </div>
        </div>
        <div className="wordlist-con">
          <h2>Word List</h2>
          <div className="inner">
            <div className="word-box correct">
              <h3>answer</h3>
            </div>
            {wordbox.map((words) => {
              return (
                <>
                  <div className="word-box">
                    <h3>{words}</h3>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default VocaFlashcardTyping;
