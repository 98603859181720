import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import star from 'src/images/star.png';
const StatusCheckTh = styled.th`
  border: 1px solid #a4d0e9;
  background-color: #fafafa;
  font-size: 0.8em;
  @media print {
    font-size: 0.5em;
  }
`;
const StatusCheckTd = styled.td`
  border: 1px solid #a4d0e9;
  text-align: center;
  padding: 5px 0;
  font-size: 0.8em;
  @media print {
    font-size: 0.5em;
  }
`;

const StatusCheckFirstTd = styled.td`
  border: 1px solid #c5c5c5b3;
  text-align: center;
  padding: 5px 0;
  font-size: 0.8em;
  background: #fbe5d6;
  @media print {
    font-size: 0.5em;
  }
`;

const Title = styled.title`
  margin-bottom: 0.5em;
  color: #008080;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  border-top: 3px solid #9b0000;
  display: block;
  margin-top: 15px;
`;

const STATUS_GRADE_LIST = ['Excellent', 'Very Good', 'Average', 'Developing', 'Needs Improvement'];
const STATUS_TYPE_LIST = [
  ['attendance', 'Attendance(출결사항)'],
  ['homework', 'Homework(과제수행)	'],
  ['attitude', 'Attitude(학습태도)'],
  ['participation', 'Participation(수업태도)'],
];

const StatusCheckTable = ({ onChangeStatus, data, editable = false, basicInfo }) => {
  const [initialValues, setInitialValues] = useState({});
  // const isSameStatus = useCallback(
  //   (status_type, grade) => {
  //     if (data?.study_status_result) {
  //       const dataJSON = JSON.parse(data?.study_status_result);
  //       console.log('dataJSON', dataJSON, status_type, grade);
  //       if (parseInt(dataJSON[status_type]) === grade) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [data],
  // );
  // const isSameStatus = (status_type, grade) => {
  //   if (data?.study_status_result) {
  //     const dataJSON = JSON.parse(data?.study_status_result);
  //     if (parseInt(dataJSON[status_type]) === grade) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };
  const isChecked = (status_type, grade) => {
    if (initialValues[status_type] === grade) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (data) {
      if (data?.study_status_result) {
        const dataJSON = JSON.parse(data.study_status_result);
        onChangeStatus({ ...dataJSON });
        setInitialValues({ ...dataJSON });
      }
    }
  }, [data]);

  const handleChange = (e, status_type) => {
    const { value } = e.target;
    onChangeStatus({ ...initialValues, [status_type]: value });
    setInitialValues({ ...initialValues, [status_type]: value });
  };
  const starArr = (num) => {
    const arr = new Array(num).fill(1);
    return (
      <div>
        {arr.map(function () {
          return <img src={star} style={{ width: '30px', height: '30px' }} />;
        })}
      </div>
    );
  };
  return (
    <>
      <table style={{ width: '100%', borderTop: '3px solid #a4d0e9' }}>
        <thead>
          <tr>
            {STATUS_TYPE_LIST.map((e) => (
              <StatusCheckTh> {e[1]}</StatusCheckTh>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <StatusCheckFirstTd>{basicInfo?.teacher_name}</StatusCheckFirstTd> */}
            {STATUS_TYPE_LIST.map((statusType, index) => (
              <StatusCheckTd key={index}>
                {editable ? (
                  <div style={{ padding: '10px 0' }}>
                    <select value={initialValues[statusType[0]] || ''} onChange={(e) => handleChange(e, statusType[0])}>
                      <option value={''} disabled>
                        Select
                      </option>
                      {STATUS_GRADE_LIST.map((grade) => (
                        <option key={grade} value={grade} selected={isChecked(statusType[0], grade)}>
                          {grade}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <span key={index}>{initialValues[statusType[0]]}</span>
                )}
              </StatusCheckTd>
            ))}
          </tr>
          {Object.keys(initialValues).length > 0 && (
            <tr>
              {STATUS_TYPE_LIST.map((statusType, index) => (
                <StatusCheckTd key={index}>
                  { (
                    <div>
                      {STATUS_GRADE_LIST.indexOf(initialValues[statusType[0]]) > -1 &&
                        starArr(5 - STATUS_GRADE_LIST.indexOf(initialValues[statusType[0]]))}
                    </div>
                  )}
                </StatusCheckTd>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default StatusCheckTable;
