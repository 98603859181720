import { gql } from '@apollo/client';
export const ASSIGN_EXTERNAL_STUDY_TO_STUDENTS = gql`
  mutation assignedExternalStudy(
    $class_idx: Int
    $study_type: String!
    $limit_time: Int!
    $start_time: [String!]!
    $start_date: String!
    $end_date: String!
    $external_data: [JSON!]!
    $user_idx_list: [Int!]!
    $study_time: String!
    $rowidx: Int
    $dateList: String
    $title: String
  ) {
    assignedExternalStudy(
      class_idx: $class_idx
      study_type: $study_type
      limit_time: $limit_time
      start_time: $start_time
      start_date: $start_date
      user_idx_list: $user_idx_list
      external_data: $external_data
      end_date: $end_date
      study_time: $study_time
      rowidx: $rowidx
      dateList: $dateList
      title: $title
    )
  }
`;

//foreign key에 delete action을 cascade로 해 놓았기 때문에 관련된 데이터는 모두 삭제가 된다.
export const DELETE_ASSIGN_EXTERNAL_STUDY = gql`
  mutation deleteAssignedExternalStudy($idx: Int!) {
    deleteAssignedExternalStudy(idx: $idx)
  }
`;

export const DELETE_CONTENTS = gql`
  mutation deleteContents($content_idx_list: [String!]!) {
    deleteContents(content_idx_list: $content_idx_list)
  }
`;

export const DELETE_ASSIGN_STUDY = gql`
  mutation deleteAssignedStudy($idx: [String!]!) {
    deleteAssignedStudy(idx: $idx)
  }
`;
export const EDIT_EXTERNAL_STUDY = gql`
  mutation editExternalStudyList(
    $class_idx: Int
    $study_type: String!
    $limit_time: Int!
    $start_time: [String!]!
    $start_date: String!
    $end_date: String!
    $external_data: [JSON!]!
    $user_idx_list: [Int!]!
    $study_time: String!
    $rowidx: Int
    $dateList: String
    $assigned_external_study_idx: Int!
  ) {
    editExternalStudyList(
      class_idx: $class_idx
      study_type: $study_type
      limit_time: $limit_time
      start_time: $start_time
      start_date: $start_date
      user_idx_list: $user_idx_list
      external_data: $external_data
      end_date: $end_date
      study_time: $study_time
      rowidx: $rowidx
      dateList: $dateList
      assigned_external_study_idx: $assigned_external_study_idx
    )
  }
`;
