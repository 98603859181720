import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Radio, Input, Button, DatePicker, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery, useMutation } from '@apollo/client';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import { DELETE_ASSIGN_EXTERNAL_STUDY } from 'src/operations/mutations/assignExternalStudy';
import { getClass, externalStudy } from 'src/operations/queries';
// import * as queries from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
export default function GroupList() {
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  const { ASSIGNED_EXTERNAL_STUDY_GROUP_LIST } = externalStudy;
  const [localState, setLocalState] = UseHistoryState({
    startDate: '',
    endDate: '',
    state: 'ing',
    selectedClass: -1,
    pg: { current: 0, pageSize: 10 },
  });
  const filterResult = [];

  const {
    data: fetchassignedExternalStudyGroupList,
    refetch,
    loading,
  } = useQuery(ASSIGNED_EXTERNAL_STUDY_GROUP_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      class_idx: localState.selectedClass?.idx ? localState.selectedClass?.idx : null,
      //   class_idx:null;
      type: 'levelup',
    },
  });
  const [deleteAssignedElib, { loading: loadingDelete }] = useMutation(DELETE_ASSIGN_EXTERNAL_STUDY, {
    onCompleted: (res) => {
      if (!res?.deleteAssignedExternalStudy) {
        alert('삭제 권한이 없습니다.');
        return;
      }
      refetch();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });
  const sortByDate = (isDescend) => (a, b) => {
    if (moment(a.org_date).isSame(b.org_date, 'day')) {
      return 0;
    }
    const compared = isDescend ? moment(a.org_date).isAfter(b.org_date, 'day') : moment(a.org_date).isBefore(b.org_date, 'day');
    if (compared) {
      return -1;
    }
    return 1;
  };
  function handleDeleteAssignedElib(assigned_external_study_group_idx, isExistStudyResult) {
    Modal.confirm({
      icon: null,
      //title: 'Delete',
      content: (
        <Title level={5} style={{ textAlign: 'left', lineHeight: 2 }}>
          {isExistStudyResult ? (
            <>
              이미 학습한 학생이 있습니다. <br /> 삭제하면 학생이 학습한 내용도 함께 삭제됩니다. <br />
              그래도 삭제하시겠습니까?
            </>
          ) : (
            <>삭제하시겠습니까?</>
          )}
        </Title>
      ),
      onOk: () => {
        // alert(assigned_external_study_group_idx)
        deleteAssignedElib({ variables: { idx: assigned_external_study_group_idx } });
      },
      onCancel: () => {
        //do nothing
      },
    });
  }
  const assignedExternalStudyGroupList = useMemo(() => {
    if (fetchassignedExternalStudyGroupList?.assignedExternalStudyGroupList) {
      let fetchData = null;
      const class_idx = parseInt(selectedClassIdx);

      if (class_idx > 0) {
        fetchData = fetchassignedExternalStudyGroupList?.assignedExternalStudyGroupList.filter((item) => item?.class.idx === class_idx);
      } else {
        fetchData = fetchassignedExternalStudyGroupList?.assignedExternalStudyGroupList;
      }

      return fetchData.reduce((acc, cur, index) => {
        const totalCnt = cur?.assigned_external_study?.length;

        const completed = cur?.assigned_external_study?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && moment(`${moment(cur?.start_time).format('YYYY-MM-DD')}`).isBefore(`${moment().format('YYYY-MM-DD')}`)) {
            acc++;
          }
          return acc;
        }, 0);

        const done_count = cur?.assigned_external_study?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && cur.user_study && cur.user_study.length > 0) {
            acc++;
          }
          return acc;
        }, 0);

        const obj = {
          title: `${cur?.title}`,
          date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          start: moment(cur?.start_date).format('YYYY-MM-DD'),
          end: moment(cur?.end_date).format('YYYY-MM-DD'),
          //testTime: cur?.test_time,
          className: cur?.class?.name,
          total: `${completed} / ${totalCnt}`,
          No: index + 1,
          key: cur.idx,
          class_idx: cur?.class?.idx,
          done_count: done_count,
          type: cur?.type,
          onDeleteAssignedElib: handleDeleteAssignedElib,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchassignedExternalStudyGroupList?.assignedExternalStudyGroupList, selectedClassIdx]);

  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: 'Created',
      dataIndex: 'date',
      align: 'center',
      width: 100,
      sorter: sortByDate(false),
      render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
    },
    {
      key: 'start',
      title: 'Start Date',
      dataIndex: 'start',
      align: 'center',
      width: 100,
      sorter: sortByDate(false),
    },
    {
      key: 'end',
      title: 'End Date',
      dataIndex: 'end',
      align: 'center',
      width: 100,
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
      render: (text) => {
        return <span style={{ color: 'blue', fontWeight: 'bold' }}>{text}</span>;
      },
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      key: 'Edit',
      title: 'Edit',
      dataIndex: 'Edit',
      align: 'center',
      render: (text, record) => {
        let title = record?.title;
        return (
          <Link
            to={{
              pathname: `/level-up/books/manage/action/edit/assign/${record?.class_idx}/${record?.title}/${record?.key}`,
              state: { className: record?.className, title },
            }}
          >
            <Button>Edit</Button>
          </Link>
        );
      },
    },
    {
      key: 'delete',
      title: 'Delete',
      dataIndex: 'delete',
      align: 'center',
      render: (_, row) => {
        const { key, done_count, onDeleteAssignedElib } = row;
        return (
          <Button
          style={{ color: '#FFCD9A', borderColor: '#FFCD9A' }}
            onClick={() => {
              onDeleteAssignedElib(key, done_count > 0);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];
  const handleChange = (e) => {
    setLocalState((prev) => {
      return { ...prev, selectedClass: classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0] };
    });
  };
  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <LevelUpMenuGroup currentMenu={'grouplist'} />
        </RowFlex>
        <MainWrapper>
          <Main>
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Assigned GroupList</SubTitle>
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {localState.selectedClass?.idx >= 0 ? (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                ) : (
                  <ClassSelectBox
                    onClassChange={onClassChange}
                    // selectedClassIdx={selectedClassIdx}
                    onChange={handleChange}
                    selectedClassIdx={localState.selectedClass?.idx}
                    campus_idx={dataUser?.getUserData?.campus_idx}
                    style={{ width: '200px', textAlign: 'left' }}
                    firstRowDisplayValue={'All Classes'}
                  />
                )}
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              pagination={{ pageSize: 10, position: ['bottomCenter'] }}
              // dataSource={filteredData1}
              dataSource={assignedExternalStudyGroupList}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
