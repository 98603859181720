import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const Field = styled(Col)`
  padding-bottom: 15px;
`;

function BookDetail({ bookInfo }) {
  return (
    <>
      <Row style={{ padding: '20px 30px', background: '#F5F5F5' }}>
        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>제목 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].title}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>짧은 제목 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].short_title}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>코드 :</Text>
            </Col>
            <Col>{bookInfo?.code}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>표지 이미지 :</Text>
            </Col>
            <Col>
              <img
                style={{ maxHeight: '150px' }}
                src={bookInfo?.book_sub[0]?.cover_path ? bookInfo?.book_sub[0].cover_path : `/images/menu-icon/content.png`}
                alt={bookInfo?.book_sub[0].title}
              />
            </Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>카테고리 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].book_category.name}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>가격 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].price}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>레벨 개수 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].number_of_levels}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>볼륨 개수 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].number_of_volumes}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>레슨 개수 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].number_of_lessons}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>쇼핑몰 상품 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].shoppig_mall_product ? '예' : '아니요'}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>크레딧 시스템 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].credit_system ? '예' : '아니요'}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>크레딧 기간:</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].credit_duration ? bookInfo?.book_sub[0].credit_duration : '해당 없음'}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>상태 :</Text>
            </Col>
            <Col>{bookInfo?.stat === '0' ? '판매 불가능' : '판매 가능'}</Col>
          </Row>
        </Field>
      </Row>
    </>
  );
}

BookDetail.prototype = {
  bookInfo: PropTypes.objectOf.isRequired,
};

export default BookDetail;
