import React, { useEffect, useMemo } from 'react';
import { Col, Row, Button } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { bookCartVar } from 'src/apollo/cart';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool, isLucid } from 'src/utils';

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const BookOrderPayComplete = () => {
  const companyName = useSelector(classStoreData);
  const isSmartEclass = useMemo(() => {
    return isAfterSchool(companyName) || isLucid(companyName);
  }, [companyName]);
  const history = useHistory();
  useEffect(() => {
    bookCartVar([]); //결제완료 후 장바구니 초기화
  }, []);
  return (
    <>
      <Col className="bookstore-book-order-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <Row gutter={[16, 16]}>
          <Col className="bookstore-book-order-wrapper-main-a" span={12} style={{ textAlign: 'left' }}>
            <HeaderTitle level={4}>Payment Status</HeaderTitle>
          </Col>
          <Col className="topheader-image" span={12} style={{ textAlign: 'right' }}></Col>
        </Row>
        <MainBlock className="bookstore-book-order-mainblock">
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <p>{isSmartEclass ? '주문이 신청되었습니다.' : '결제가 완료되었습니다.'}</p>
            <p>
              {/* <Button
                type="primary"
                onClick={() => {
                  history.replace('/book/order/list');
                }}
              >
                완료
              </Button> */}
            </p>
          </div>
        </MainBlock>
      </Col>
    </>
  );
};

export default BookOrderPayComplete;
