import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Form, Input, Button } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';
import { useForm } from 'src/utils/hooks';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const BookSettingsEditModal = ({ visible, handleCancel, popupMode, bookInfo, setSelectBook }) => {
  const [form] = Form.useForm();
  const { onChange, onSubmit, values } = useForm(formCallback, {
    ...bookInfo,
    ...bookInfo.book_sub[0],
  });

  const [updateBookSettings, { loading }] = useMutation(mutations.book.UPDATE_BOOK_SETTINGS, {
    variables: {
      ...values,
      code: bookInfo.code,
      price: parseInt(values.price),
      sort_evine: parseInt(values.sort_evine),
      pages: parseInt(values.pages),
      sort_lexile: parseInt(values.sort_lexile),
      sort_glr: parseInt(values.sort_glr),
      cooidx: parseInt(values.cooidx),
      bidx: parseInt(values.bidx),
      recommend_flag: parseInt(values.recommend_flag),
      orderno: parseInt(values.orderno),
      easy: parseInt(values.easy),
      level2: parseInt(values.level2),
    },

    update(proxy, result) {
      const data = proxy.readQuery({
        query: queries.book.GET_BOOK_LIST_BY_PARENT_CODE,
        variables: {
          parent_code: bookInfo.parent_code,
        },
      });

      proxy.writeQuery({
        query: queries.book.GET_BOOK_LIST_BY_PARENT_CODE,
        variables: {
          parent_code: bookInfo.parent_code,
        },
        data: {
          bookListByParentCode: data.bookListByParentCode.map((item) => {
            if (item.code === bookInfo.code) {
              return result.data.updateBookSettings;
            } else {
              return item;
            }
          }),
        },
      });
    },
    onCompleted(data) {
      if (data) {
        console.log(data);
        openNotification('교재 설정이 업데이트 되었습니다!');
        setSelectBook({
          ...data.updateBookSettings,
        });
        handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function formCallback() {
    updateBookSettings({
      variables: {
        ...values,
        code: bookInfo.code,
        price: parseInt(values.price),
        sort_evine: parseInt(values.sort_evine),
        pages: parseInt(values.pages),
        sort_lexile: parseInt(values.sort_lexile),
        sort_glr: parseInt(values.sort_glr),
        cooidx: parseInt(values.cooidx),
        bidx: parseInt(values.bidx),
        recommend_flag: parseInt(values.recommend_flag),
        orderno: parseInt(values.orderno),
        easy: parseInt(values.easy),
        level2: parseInt(values.level2),
      },
    });
  }

  return (
    <>
      <Modal
        entered
        title={`${bookInfo.book_sub[0].title} 의 볼륨 및 레벨 설정 수정`}
        visible={visible}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          initialValues={{ ...bookInfo, ...bookInfo.book_sub[0] }}
        >
          <FormField hasFeedback={true} title="제목">
            <FormItem name="title" rules={[{ required: true, message: '제목을 입력하세요.' }]} hasFeedback>
              <Input name="title" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={true} title="가격">
            <FormItem name="price" rules={[{ required: true, message: '가격을 입력하세요.' }]} hasFeedback>
              <Input name="price" onChange={onChange} maxLength={13} />
            </FormItem>
          </FormField>

          <FormField title="짧은 제목">
            <FormItem name="short_title" rules={[{ required: false }]} hasFeedback>
              <Input name="short_title" onChange={onChange} maxLength={13} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="커버 이미지 경로">
            <FormItem name="cover_path" rules={[{ required: false }]} hasFeedback>
              <Input name="cover_path" onChange={onChange} maxLength={500} />
            </FormItem>
          </FormField>

          <FormField title="레벨명">
            <FormItem name="level_name" rules={[{ required: false }]} hasFeedback>
              <Input name="level_name" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField title="볼륨명">
            <FormItem name="volume_name" rules={[{ required: false }]} hasFeedback>
              <Input name="volume_name" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField title="Evine 레벨">
            <FormItem name="evine_level" rules={[{ required: false }]} hasFeedback>
              <Input name="evine_level" onChange={onChange} maxLength={13} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="Evine 분류하기">
            <FormItem name="sort_evine" rules={[{ required: false }]} hasFeedback>
              <Input name="sort_evine" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="소개">
            <FormItem name="introduction" hasFeedback>
              <Input name="introduction" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="저자">
            <FormItem name="author" rules={[{ required: false }]} hasFeedback>
              <Input name="author" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="출판사">
            <FormItem name="publisher" hasFeedback>
              <Input name="publisher" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="장르">
            <FormItem name="genre" rules={[{ required: false }]} hasFeedback>
              <Input name="genre" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="페이지">
            <FormItem name="pages" hasFeedback>
              <Input name="pages" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="이북 경로">
            <FormItem name="ebook_path" rules={[{ required: false }]} hasFeedback>
              <Input name="ebook_path" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="렉사일">
            <FormItem name="lexile" rules={[{ required: false }]} hasFeedback>
              <Input name="lexile" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="렉사일 분류하기">
            <FormItem name="sort_lexile" hasFeedback>
              <Input name="sort_lexile" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="GLR">
            <FormItem name="glr" rules={[{ required: false }]} hasFeedback>
              <Input name="glr" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="GLR 분류하기">
            <FormItem name="sort_glr" hasFeedback>
              <Input name="sort_glr" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="출판사 코드">
            <FormItem name="cooidx" hasFeedback>
              <Input name="cooidx" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="책번호">
            <FormItem name="bidx" rules={[{ required: false }]} hasFeedback>
              <Input name="bidx" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="플래그 추천">
            <FormItem name="recommend_flag" rules={[{ required: false }]} hasFeedback>
              <Input name="recommend_flag" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="순서 정렬">
            <FormItem name="orderno" hasFeedback>
              <Input name="orderno" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="쉬움">
            <FormItem name="easy" rules={[{ required: false }]} hasFeedback>
              <Input name="easy" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="레벨 2">
            <FormItem name="level2" hasFeedback>
              <Input name="level2" onChange={onChange} />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="추가 데이터">
            <FormItem name="additional_data" rules={[{ required: false }]} hasFeedback>
              <Input name="additional_data" onChange={onChange} />
            </FormItem>
          </FormField>
        </Form>
      </Modal>
    </>
  );
};

BookSettingsEditModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default BookSettingsEditModal;
