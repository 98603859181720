import { Button, Divider } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { Input, Typography } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import MessageItem from 'src/components/DashBoard/SubPage/MessageItem';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userInfoVar } from 'src/apollo/cache';
import MessageBox from 'src/components/DashBoard/SubPage/MessageBox';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import { AI_TOPIC_USER_ASSIGNMENT_DETAILS, AI_USER_REQUEST_RESPONSE_LIST } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import CustomButton from '../common/CustomButton';
import { openNotification } from '../common/Notification';

const { Text } = Typography;

const PROMPTS = [
  `I am a first grader kid who does not speak English well.
  So talk to me with easy words and do not use long sentences.
  I am going to give you a story. You probably know the story.
  It is called "The Three Little Pigs".
  You can ask me about five questions about the story and ask me one at a time.
  I could ask you some questions, and you would answer in easy language.
  Here is the story"
  
  Once upon a time, there were three little pig brothers: Piggy 1, Piggy 2, and Piggy 3. They decided to build houses to keep themselves safe from the big bad wolf.
  Piggy 1 was in a hurry and made a house of straw. It was quick but not very strong. Piggy 2 also wanted to finish fast, so he built his house with sticks. It was a bit stronger than straw, but still not very tough.
  Now, Piggy 3 was wise. He took his time and built a sturdy house with strong bricks. It took more effort, but he knew it would be the safest.
  One day, the big bad wolf came by. He huffed and puffed and blew down the straw house of Piggy 1! Oh no! The little pig ran to Piggy 2's house.
  The wolf huffed and puffed again, and this time, the house made of sticks also fell. Now, both Piggy 1 and Piggy 2 were scared. They all rushed to Piggy 3's brick house.
  The wolf tried with all his might to blow down the brick house, but it was too strong. The three little pigs were safe inside. The wolf couldn't get them!
  In the end, the wolf gave up and went away. The three little pigs were happy and learned an important lesson – taking time to do things right and making strong choices pays off.
  And they all lived happily ever after in their sturdy brick house. The end! "
  
  You start by asking me a question about the story.`,
];

//<Divider plain>여기까지 확인했습니다.</Divider>

function ChatWindow({ topic, userTopicId, userTopicStatus, handleCancel }) {
  const [userTopicAssignmetStatus, setUserTopicAssignmetStatus] = useState(userTopicStatus);
  const [activeAnswer, setActiveAnswer] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const [answer, setAnswer] = useState('');
  const [chatList, setChatList] = useState([]);
  const [messagesIds, setMessagesIds] = useState([]);
  const [promptActive, setPromptActive] = useState(false);
  const listInnerRef = useRef();
  const [loadMoreMessages, { data, loading, error }] = useLazyQuery(OPENAI_COMPLETION_QUERY);

  const [aiTopicUserAssignmentDetails, { data: dataHistory, loading: loadingHistory, error: errorHistory }] =
    useLazyQuery(AI_TOPIC_USER_ASSIGNMENT_DETAILS);

  const [aiUserRequestResponseList, { data: dataRRHistory, loading: loadingRRHistory, error: errorRRHistory }] =
    useLazyQuery(AI_USER_REQUEST_RESPONSE_LIST);

  const [updateAITopicUserAssignment, { data: updatedData }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (!userTopicStatus || userTopicStatus === 0) {
      setChatList([]);
    }
  }, [userTopicStatus]);

  useEffect(() => {
    if (userTopicId && userTopicAssignmetStatus > 0) {
      aiTopicUserAssignmentDetails({ variables: { idx: userTopicId } });
    } else {
      // aiUserRequestResponseList();
    }
  }, [userTopicId]);

  useEffect(() => {
    if (dataHistory?.aiTopicUserAssignmentDetails?.ai_request_responses) {
      let resChats = [];
      dataHistory?.aiTopicUserAssignmentDetails?.ai_request_responses.forEach((item) => {
        resChats.push({
          user: userInfoVar()?.id,
          chat_user_name: userInfoVar()?.name,
          content: item.prompt_text,
          urls: [],
          created: item.idate,
        });
        resChats.push({
          user: 'bot',
          chat_user_name: 'AI봇',
          content: item.completion_text,
          urls: item.image_urls ? JSON.parse(item.image_urls) : [],
          created: item.idate,
        });
      });
      setChatList(resChats);
    }
  }, [dataHistory]);

  useEffect(() => {
    if (dataRRHistory?.aiUserRequestResponseList) {
      let resChats = [];
      dataRRHistory?.aiUserRequestResponseList.forEach((item) => {
        resChats.push({
          user: userInfoVar()?.id,
          chat_user_name: userInfoVar()?.name,
          content: item.prompt_text,
          urls: [],
          created: item.idate,
        });
        resChats.push({
          user: 'bot',
          chat_user_name: 'AI봇',
          content: item.completion_text,
          urls: item.image_urls ? JSON.parse(item.image_urls) : [],
          created: item.idate,
        });
      });
      setChatList([...resChats, ...chatList]);
    }
  }, [dataRRHistory]);

  useEffect(() => {
    if (data?.getOpenAiCompletion?.text?.length > 0) {
      setChatList([
        ...chatList,
        {
          user: 'bot',
          chat_user_name: 'AI봇',
          prompt: currentText,
          content: data.getOpenAiCompletion?.text,
          urls: data.getOpenAiCompletion?.urls,
          created: data?.getOpenAiCompletion?.date,
        },
      ]);
      if (userTopicAssignmetStatus === 0) {
        updateAITopicUserAssignment({
          variables: {
            idx: userTopicId,
            start_time: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
            status: 1,
          },
        });
      }

      if (promptActive) {
        setMessagesIds([...messagesIds, data?.getOpenAiCompletion?.chat?.idx]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (updatedData?.updateAITopicUserAssignment?.status) {
      setUserTopicAssignmetStatus(updatedData?.updateAITopicUserAssignment?.status);
      if (updatedData?.updateAITopicUserAssignment?.status === 2) {
        openNotification('답변이 저장되었습니다!');
        handleCancel();
      }
    }
  }, [updatedData]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  const onSubmit = () => {
    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentText,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    setCurrentText('');

    loadMoreMessages({
      variables: {
        topic: topic,
        prompt: currentText,
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: userTopicId,
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        messagesIds: messagesIds,
      },
    });
  };

  const onAnswerSubmit = () => {
    updateAITopicUserAssignment({
      variables: {
        idx: userTopicId,
        answer: answer,
        end_time: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
        status: 2,
      },
    });
  };

  const handlePromptClick = (number) => {
    let currentPrompt = PROMPTS[number];

    loadMoreMessages({
      variables: {
        topic: topic,
        prompt: currentPrompt,
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        userTopicId: userTopicId,
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
      },
    });

    setPromptActive(true);
  };

  return (
    <div>
      <div style={{ border: '1px solid #00b598', background: '#eafffc', padding: '5px', minHeight: '50px' }}>
        <Text strong style={{ color: '#00b598' }}>
          주제/질문: {'  '}
        </Text>
        {topic ? (
          <>
            <br></br>
            {topic}
          </>
        ) : (
          <Button type="primary" onClick={() => handlePromptClick(0)}>
            Prompt 1
          </Button>
        )}
      </div>
      {!activeAnswer ? (
        <MessageBox
          id="MessageBox"
          style={{
            height: '40vh',
            overflow: 'auto',
            border: '1px solid #1890ff',
            margin: '10px 0',
            backdround: '#fff',
          }}
        >
          <InfiniteScroll
            scrollableTarget="MessageBox"
            style={{ display: 'flex', flexDirection: 'column' }}
            next={aiUserRequestResponseList}
            inverse={false}
            hasMore={true}
            dataLength={chatList.length}
            loader={
              loadingRRHistory ? (
                <p style={{ textAlign: 'center' }}>
                  <b>Loading...</b>
                </p>
              ) : null
            }
            endMessage={
              loading ? (
                <p style={{ textAlign: 'center' }}>
                  <b>생각중...</b>
                </p>
              ) : null
            }
          >
            {chatList?.map((thread, key) => {
              const isMe = Number(userInfoVar()?.id) === thread.user;
              return (
                <React.Fragment key={`message-item-key${key}`}>
                  <DateDivider
                    isShow={
                      key < chatList.length &&
                      moment(new Date(thread?.created)).format('YYYY-MM-DD') !==
                        moment(new Date(chatList[key - 1]?.created)).format('YYYY-MM-DD')
                    }
                    date={`${moment(new Date(thread?.created)).format('YYYY-MM-DD')}`}
                  />
                  <MessageItem
                    isMe={isMe}
                    name={thread.chat_user_name}
                    message={thread.content}
                    urls={thread.urls}
                    date={thread.created}
                    readYN={true}
                    unreadCount={0}
                  />
                </React.Fragment>
              );
            })}
            {loading && (
              <p style={{ textAlign: 'center' }}>
                <b>loading...</b>
              </p>
            )}
          </InfiniteScroll>
        </MessageBox>
      ) : (
        <Input.TextArea
          style={{ height: '30vh', overflow: 'auto', border: '1px solid #00b598', backdround: '#fff', margin: '10px 0', color: '#00b598' }}
          placeholder="답변하기"
          name="answer"
          onChange={(e) => setAnswer(e.target.value)}
        />
      )}

      {!activeAnswer ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', alignItems: 'center' }}>
          <Input.TextArea
            key={`message-box-key`}
            placeholder="내용을 입력하세요"
            name="message"
            style={{ height: '80px', resize: 'none' }}
            value={currentText}
            onChange={(e) => setCurrentText(e.target.value)}
          />
          <div style={{ display: 'flex', width: '200px', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
              <CustomButton type="primary" style={{ margin: '0 5px' }} onClick={onSubmit}>
                보내기
              </CustomButton>
              <CustomButton style={{ margin: '0 5px' }} onClick={() => setChatList([])}>
                지우기
              </CustomButton>
            </div>
            {topic && (
              <CustomButton
                style={{ backgroundColor: '#00b598', borderColor: '#00b598', color: '#fff', margin: '5px' }}
                onClick={() => setActiveAnswer(true)}
              >
                답변하기
              </CustomButton>
            )}
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <CustomButton type="primary" style={{ margin: '0 5px' }} onClick={() => onAnswerSubmit()}>
            저장하기
          </CustomButton>
          <CustomButton style={{ margin: '0 5px' }} onClick={() => setActiveAnswer(false)}>
            돌아가기
          </CustomButton>
        </div>
      )}
    </div>
  );
}

export default ChatWindow;

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};
