import { gql } from '@apollo/client';

export const META_SCHEDULE = gql`
  mutation ($campus_idx: Int!, $no_of_system: String!, $start_time: String!, $end_time: String!, $days: String!) {
    metaSchedule(campus_idx: $campus_idx, no_of_system: $no_of_system, start_time: $start_time, end_time: $end_time, days: $days) {
      campus_idx
      no_of_system
      start_time
      end_time
      days
      exists_day
    }
  }
`;
export const META_EXAM_SCHEDULE_SLOT = gql`
  mutation scheduleMetaExam($idx: Int!,$schedule_idx: Int!, $date: String!) {
    scheduleMetaExam(idx: $idx, schedule_idx: $schedule_idx, date: $date)
  }
`;

export const DELETE_META_SCHEDULE = gql`
  mutation deletemetaSchedule($schedule_idx: Int!) {
    deletemetaSchedule(schedule_idx: $schedule_idx)
  }
`;

export const SCHEDULE_DATA_ID_WISE = gql`
  mutation (
    $schedule_idx: Int!
  ) {
    getScheduleDataIdWise(
      schedule_idx: $schedule_idx
    ){
      idx
      campus_idx
      days
      start_time
      end_time
      no_of_system
      idate
    }
  }
`;

export const UPDATE_META_SCHEDULE = gql`
  mutation (
    $idx: Int!
    $campus_idx: Int!
    $no_of_system: Int!
    $start_time: String!
    $end_time: String!
    $days : String!
  ) {
    updateMetaSchedule(
      idx:$idx
      campus_idx: $campus_idx
      no_of_system: $no_of_system
      start_time: $start_time
      end_time: $end_time
      days: $days
    ){
        idx
        campus_idx
        no_of_system
        start_time
        end_time
        days
    }
  }
`;

export const UPDATE_ORDER_META_SCHEDULE = gql`
  mutation ($campus_idx: Int!, $meta_schedule: Int!) {
    updateOrderMetaSchedule(campus_idx: $campus_idx, meta_schedule: $meta_schedule)
  }
`;
