import React, { useState } from 'react';
import { Col, Row, Radio, Button } from 'antd';
import {
  BookCard,
  BookCover,
  BookMeta,
  BookTitle,
  BOOK_COL_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  THUMBNAIL_BASE_URL,
} from './CommonComp';

const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;
const EBOOK_BASE_URL = 'http://ebook.theclassenglish.com/SB/';
const EBOOK_BASE_URL2 = 'https://online.flipbuilder.com/sangbong91/';
const GROUPS = ['SB', 'WB', 'Readers'];
// const READERS_URL = ['bect', 'xgkw', 'hryo', 'uamg', 'cveu', 'eykw'];
// const SB_URL = ['hxrm', 'ykbu', 'qjze', 'wcni', 'xtvg', 'wsyd'];
// const WB_URL = ['imnr', 'buef', 'pcor', 'udpc', 'hwen', 'wrmo'];
const READERS_LIST = [
  ['hxrm', 'ykbu', 'qjze', 'wcni', 'xtvg', 'wsyd'],
  ['imnr', 'buef', 'pcor', 'udpc', 'hwen', 'wrmo'],
  ['bect', 'xgkw', 'hryo', 'uamg', 'cveu', 'eykw'],
];
// const READERS_URL_SUPER = ['zwbu', 'lyib', 'khrb'];
// const SB_URL_SUPER = ['svtc', 'nqzg', 'okse'];
// const WB_URL_SUPER = ['odzm', 'ncvg', 'igrz'];
const SUPER_LIST = [
  ['svtc', 'nqzg', 'okse'],
  ['odzm', 'ncvg', 'igrz'],
  ['zwbu', 'lyib', 'khrb'],
];
const IMAGE_NAMES = [
  { title: 'Coco Phonics', name: 'coco_phonics_', ext: 'jpg', link_name: 'CocoPhonics/CPH_*_TEACHER/' },
  { title: 'Coco Phonics', name: 'coco_phonics_wb_', ext: 'jpg', link_name: 'CocoPhonics/CPHWB_*_TEACHER/' },
  { title: 'Coco Readers', name: 'coco_readers_', ext: 'jpg', link_name: 'CocoPhonicsReaders/CoCo_Phonics_Vol_*_new_readers/' },
  // { title: 'Coco Activity', name: 'coco_activity_', ext: 'png', link_name: 'CocoActivity/vol.*_published/' },
];
const SUPER_IMAGE_NAMES = [
  { title: 'Super Coco Phonics', name: 'super_coco_phonics_cover_', ext: 'jpg', link_name: 'SuperCocoPhonics/SCPH_*_Teachers/' },
  { title: 'Super Coco Phonics', name: 'super_coco_phonics_cover_wb_', ext: 'jpg', link_name: 'SuperCocoPhonics/SCPHWB_*_Teachers/' },
  {
    title: 'Super Coco Readers',
    name: 'super_coco_readers_',
    ext: 'jpg',
    link_name: 'CocoPhonicsReaders/Super_CoCo_Phonics_Vol_*_new_readers/',
  },
];
const CocoPhoincs = ({checkForEduco=false}) => {
  const [groupNo, setGroupNo] = useState(0);
  const handleChangeLevel = (evt) => {
    const {
      target: { value },
    } = evt;
    setGroupNo(value);
  };
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <Radio.Group
        value={groupNo}
        style={{ marginBottom: 16, marginLeft: 16 }}
        buttonStyle="solid"
        onChange={handleChangeLevel}
        size="middle"
      >
        {GROUPS.map((groupName, key) => (
          <Radio.Button key={`radio-level-key${key}`} value={key} style={{ width: 130, textAlign: 'center' }}>
            {groupName}
          </Radio.Button>
        ))}
      </Radio.Group>

      <Row gutter={[16, 16]} style={{ margin: '0 10px' }}>
        {Array(6)
          .fill(0)
          .map((_, key) => (
            <Col
              span={4}
              key={`col-${key}`}
              style={{
                ...BOOK_COL_STYLE,
                borderRight: `${key === 5 ? '1px solid #dedede' : ''}`,
              }}
            >
              <BookTitle level={5}>{`${IMAGE_NAMES[groupNo].title} ${key + 1}`}</BookTitle>
              <BookCard
                onClick={() => {
                  window.open(
                    groupNo === 3
                      ? `${EBOOK_BASE_URL}${IMAGE_NAMES[groupNo].link_name.replace('*', `${key + 1}`)}`
                      : EBOOK_BASE_URL2 + READERS_LIST[groupNo][key],
                    '_blank',
                    'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes',
                  );
                }}
                cover={
                  <BookCover
                    style={{
                      backgroundImage: `url(${THUMBNAIL_BASE_URL}${IMAGE_NAMES[groupNo].name}${key + 1}.${IMAGE_NAMES[groupNo].ext})`,
                    }}
                  />
                }
              >
                <BookMeta description={groupNo === 0 &&!checkForEduco&& <EbookLinkGroup volume={key + 1} />} />
              </BookCard>
            </Col>
          ))}
      </Row>
      {groupNo !== 3 && (
        <Row gutter={[16, 16]} style={{ margin: '0 10px' }}>
          {Array(3)
            .fill(0)
            .map((_, key) => (
              <Col span={4} key={`col-${key}`} style={BOOK_COL_STYLE}>
                <BookTitle level={5}>{`${SUPER_IMAGE_NAMES[groupNo].title} ${key + 1}`}</BookTitle>
                <BookCard
                  onClick={() => {
                    window.open(
                      groupNo === 3
                        ? `${EBOOK_BASE_URL}${SUPER_IMAGE_NAMES[groupNo].link_name.replace('*', `${key + 1}`)}`
                        : EBOOK_BASE_URL2 + SUPER_LIST[groupNo][key],
                      '_blank',
                      'height=' + window.screen.height + ',width=' + window.screen.width + 'fullscreen=yes',
                    );
                  }}
                  style={{ marginLeft: 5 }}
                  cover={
                    <BookCover
                      style={{
                        backgroundImage: `url(${THUMBNAIL_BASE_URL}${SUPER_IMAGE_NAMES[groupNo].name}${key + 1}.${
                          SUPER_IMAGE_NAMES[groupNo].ext
                        })`,
                      }}
                    />
                  }
                >
                  <BookMeta description={groupNo === 0 &&!checkForEduco&& <EbookLinkGroup volume={key + 1 + 6} />} />
                </BookCard>
              </Col>
            ))}
          {/* dummy : 선 때문에.. */}
          <Col
            span={4}
            style={{
              ...BOOK_COL_STYLE,
              borderLeft: '1px solid #dedede',
              borderBottom: '0px solid #fff',
              borderTop: '0px solid #fff',
            }}
          ></Col>
        </Row>
      )}
    </div>
  );
};

export default CocoPhoincs;

const EbookLinkGroup = ({ volume }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, paddingBottom: 5 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          const super_coco = volume > 6 ? 'Super_' : '';
          const real_volume = volume > 6 ? volume - 6 : volume;
          window.open(`${SMART_ECLASS_CDN_BASE_URL}common/coco-phonics/${super_coco}CoCo_Phonics${real_volume}_teacher_PPT.zip`);
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.4em', lineHeight: '1' }}
      >
        PPT <br />
        Download
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          const culp_token = window.localStorage.getItem('culp_token');
          //window.open(`https://admin.kidstapenglish.com/TG/Phonics/PH_${volume}/`, '_blank', 'width=1400, height=900');
          window.open(`${STUDY_TOOL_HOST}/SM-L0V${volume}U1D1?token=${culp_token}`, '_blank');
        }}
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, fontSize: '0.6em' }}
      >
        E-learning
      </Button>
    </div>
  );
};
