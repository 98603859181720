import React from 'react';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import FormItem from 'antd/lib/form/FormItem';

import Settings from 'src/pages/Settings';
import { useForm } from 'src/utils/hooks';
import CustomButton from 'src/components/common/CustomButton';
import PasswordModal from 'src/components/Modal/PasswordModal';
import { userTypVar, userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';

const MyInfoWrapper = styled.div`
  padding: 24px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

function Account({ userInfo }) {
  const [form] = Form.useForm();
  const { visible, showModal, handleCancel } = useModal();
  console.log('userInfo', userInfoVar().campus_idx);
  const initialState = {
    name: userInfo?.name,
    birthday: '',
    id: userInfo?.id,
    pw: null,
    phone: userInfo?.phone,
    parent_phone: userInfo?.email,
    campus_idx: userInfoVar().campus_idx,
    type: userTypVar().student,
  };

  const { onChange, onSubmit, values } = useForm(formCallback, initialState);

  function formCallback() {
    //popupMode === 'create' ? mutate() : update();
  }

  function onDateChange(name, dateString) {
    // setValues((prev) => ({
    //   ...prev,
    //   [name]: dateString,
    // }));
  }

  return (
    <Settings>
      <MyInfoWrapper>
        <Form form={form} name="control-ref" onFinish={onSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <FormItem name="name" rules={[{ required: true, message: '학생이름을 입력해 주세요.' }]} label={`학생 이름`} hasFeedback>
                <Input name="name" onChange={onChange} />
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem name="birthday" rules={[{ required: true }]} label={`생년월일`} hasFeedback>
                <DatePicker name="birthday" onChange={(_, d) => onDateChange('birthday', d)} placeholder="생년월일" />
              </FormItem>
            </Col>

            {/* <Col span={12}>
              <Row>
                <Col span={4}>
                  <label>학생 ID</label>
                </Col>
                <Col span={20}>Goodzzong</Col>
              </Row>
            </Col> */}

            <Col span={12}>
              <FormItem name="id" rules={[{ required: true }]} label={`학생 ID`} hasFeedback>
                <Input name="id" />
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem name="pw" label={`Password`} hasFeedback>
                <CustomButton style={{ width: 150 }} onClick={showModal}>
                  변경하기
                </CustomButton>
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem name="phone" rules={[{ required: true }]} label={`학생 연락처`} hasFeedback>
                <Input name="phone" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="parent_phone" rules={[{ required: true }]} label={`부모님 전화`} hasFeedback>
                <Input name="parent_phone" />
              </FormItem>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 30,
                }}
              >
                <CustomButton size="large" style={{ width: 200 }} onClick={() => form.submit()}>
                  등록
                </CustomButton>
              </div>
            </Col>
          </Row>
        </Form>

        <PasswordModal visible={visible} handleCancel={handleCancel} />
      </MyInfoWrapper>
    </Settings>
  );
}

export default Account;
