import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation sendMessage($message_input: SendMessageInput!) {
    sendMessage(message_input: $message_input) {
      success
      message
    }
  }
`;

export const SEND_SMS_TEXT_MESSAGE = gql`
  mutation sendSmsTextMessage($message_input: SendSmsTextMessageInput!) {
    sendSmsTextMessage(message_input: $message_input) {
      success
    }
  }
`;

export const SAVE_SMS_TEXT = gql`
  mutation SaveSmsText($text: String!) {
    SaveSmsText(text: $text) {
      success
      message
    }
  }
`;

export const CREATE_SMS_ACCOUNT = gql`
  mutation createSmsAccount {
    createSmsAccount {
      success
      message
    }
  }
`;
