import React, { useState } from 'react';
import { Col, Row, Radio, Button } from 'antd';
import { useVolumeTab } from './VolumeTab';
import { BookCard, BookCover, BookTitle, BOOK_COL_STYLE, THUMBNAIL_BASE_URL_ESL_RAINBOW, ESL_PPT_CDN_BASE_URL } from './CommonComp';

const LEVELS = [1, 2, 3, 4, 5, 6, 7, 8];
const LEVELS_NAME = ['Starter', 'RED', 'ORANGE', 'YELLOW', 'GREEN', 'BLUE', 'NAVY', 'PURPLE'];
const VOLUMES = [1, 2];
const UNITS = [
  [1, 2, 3, 4],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [1, 2, 3, 4, 5, 6, 7, 8],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
];

const LESSONS = [4, 4, 3, 3, 3, 3, 3, 3];

const VolumeTab = ({ onChange, levelNo, levels, levelNames, Volume = 'Volume' }) => {
  return (
    <Radio.Group
      value={levelNo}
      style={{ marginBottom: 16, marginLeft: 16, display: 'block' }}
      buttonStyle="solid"
      onChange={onChange}
      size="middle"
    >
      <Radio.Button value={0} style={{ width: 75, textAlign: 'center' }}>
        전체
      </Radio.Button>
      {levels.map((lv, key) => (
        <Radio.Button key={`radio-level-key${key}`} value={lv} style={{ width: 100, textAlign: 'center' }}>
          {levelNames[key]}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

const CreoTown = () => {
  // const [groupNo, setGroupNo] = useState(0);
  const { levelNo, onChangeLevelNo } = useVolumeTab();

  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <VolumeTab
        levelNo={levelNo}
        levels={LEVELS}
        levelNames={LEVELS_NAME}
        Volume="Level"
        onChange={(evt) => {
          const {
            target: { value },
          } = evt;
          onChangeLevelNo(value);
        }}
      />

      {LEVELS.filter((level) => (levelNo === 0 ? true : level === levelNo)).map((lv) =>
        VOLUMES.map((volume) => (
          <Row key={`row-${lv}-${volume}`} gutter={[16, 16]} style={{ margin: '0 10px' }}>
            {UNITS[lv - 1].map((unit, i) => (
              <Col
                span={6}
                key={`col-${lv}-${volume}-${unit}`}
                style={{
                  ...BOOK_COL_STYLE,
                  borderRight: `${unit === 6 ? '1px solid #dedede' : ''}`,
                }}
                className="CAWrap creotown-ebook"
              >
                <div className="CreoBook-con">
                  {i < 1 && (
                    <div
                      className="Creo-AW-CT-titWrap"
                      style={{
                        backgroundColor: `${volume % 2 === 0 && 'lightblue'}`,
                        display: `${lv < 9 ? ' block' : ' none'}`,
                      }}
                    >
                      <BookTitle
                        level={2}
                        style={{
                          backgroundColor: `${volume % 2 === 0 && 'lightblue'}`,
                          display: `${lv > 0 ? ' block' : ' none'}`,
                        }}
                        className="CreoBookTitle"
                      >
                        {LEVELS_NAME[lv - 1]}
                        <br />
                        Vol.{volume}
                      </BookTitle>
                    </div>
                  )}

                  <div style={{ width: '100%', display: 'flex', textAlign: 'center' }} className="bookCardCon">
                    <h2 className="SectionTit">{`UNIT ${unit}`}</h2>
                    <div className="bookCardWrap">
                      <BookCard
                        onClick={() => {}}
                        cover={
                          <BookCover
                            style={{
                              backgroundImage: `url(${THUMBNAIL_BASE_URL_ESL_RAINBOW}L${lv}V${volume}.png)`,
                              border: '0px solid #fff',
                            }}
                          />
                        }
                      />

                      <LessonPPTLinkGroup level={lv} volume={volume} unit={unit} />
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )),
      )}
    </div>
  );
};

export default CreoTown;

const LessonPPTLinkGroup = ({ level, volume, unit }) => {
  return (
    <BookCard className="unit-con">
      {Array(LESSONS[level - 1])
        .fill(0)
        .map((_, key) => {
          const lesson = key + 1;
          return (
            <div key={`lesson-key-${key}`} className="unit-wrap">
              <h2
                onClick={() => {
                  //alert(`level${level} volume${volume} unit${unit}`);
                  window.open(`${ESL_PPT_CDN_BASE_URL}ESL_${level}-${volume}_U${unit}D${lesson}/index.html`, '_blank');
                }}
              >
                Lesson {lesson}
              </h2>
            </div>
          );
        })}
    </BookCard>
  );
};
