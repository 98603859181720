import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const StatusCheckTh = styled.th`
  border-left: 1px solid #c5c5c5b3;
  border-top: 1px solid #c5c5c5b3;
  font-size: 0.8em;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #9b0000;
  color: #fff;
  @media print {
    font-size: 0.5em;
  }
`;
const StatusCheckTd = styled.td`
  border: 1px solid #c5c5c5b3;
  text-align: center;
  padding: 5px 0;
  font-size: 0.8em;
  @media print {
    font-size: 0.5em;
  }
`;

const StatusCheckFirstTd = styled.td`
  border: 1px solid #c5c5c5b3;
  text-align: center;
  padding: 5px 0;
  font-size: 0.8em;
  background: #fbe5d6;
  @media print {
    font-size: 0.5em;
  }
`;

const Title = styled.title`
  margin-bottom: 0.5em;
  color: #008080;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  border-top: 3px solid #9b0000;
  display: block;
  margin-top: 15px;
`;

const STATUS_GRADE_LIST = ['Excellent', 'Very Good', 'Average', 'Developing', 'Needs Improvement'];
const STATUS_TYPE_LIST = [
  ['class_perfomance', 'Class Performance'],
  ['attendance', 'Attendance'],
  ['presentation', 'Presentation'],
];

const StatusCheckTable = ({ onChangeStatus, data, editable = false, basicInfo }) => {
  const [initialValues, setInitialValues] = useState({});
  // const isSameStatus = useCallback(
  //   (status_type, grade) => {
  //     if (data?.study_status_result) {
  //       const dataJSON = JSON.parse(data?.study_status_result);
  //       console.log('dataJSON', dataJSON, status_type, grade);
  //       if (parseInt(dataJSON[status_type]) === grade) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [data],
  // );
  const isSameStatus = (status_type, grade) => {
    if (data?.study_status_result) {
      const dataJSON = JSON.parse(data?.study_status_result);
      if (parseInt(dataJSON[status_type]) === grade) {
        return true;
      }
    }
    return false;
  };
  const isChecked = (status_type, grade) => {
    if (initialValues[status_type] === grade) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (data) {
      if (data?.study_status_result) {
        const dataJSON = JSON.parse(data.study_status_result);
        onChangeStatus({ ...dataJSON });
        setInitialValues({ ...dataJSON });
      }
    }
  }, [data]);

  const handleChange = (e, status_type) => {
    const { value } = e.target;
    onChangeStatus({ ...initialValues, [status_type]: value });
    setInitialValues({ ...initialValues, [status_type]: value });
  };
  return (
    <>
      <Title level={4}>● Class Performance</Title>

      <table style={{ width: '100%', borderTop: '3px solid #c5c5c5b3' }}>
        <thead>
          <tr>
            <StatusCheckTh>TEACHER</StatusCheckTh>
            <StatusCheckTh>{STATUS_TYPE_LIST[0][1]}</StatusCheckTh>
            <StatusCheckTh>{STATUS_TYPE_LIST[1][1]}</StatusCheckTh>
            <StatusCheckTh>{STATUS_TYPE_LIST[2][1]}</StatusCheckTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <StatusCheckFirstTd>{basicInfo?.teacher_name}</StatusCheckFirstTd>
            {STATUS_TYPE_LIST.map((statusType, index) => (
              <StatusCheckTd key={index}>
                {editable ? (
                  <select value={initialValues[statusType[0]] || ''} onChange={(e) => handleChange(e, statusType[0])}>
                    <option value={''}>Select</option>
                    {STATUS_GRADE_LIST.map((grade) => (
                      <option key={grade} value={grade} selected={isChecked(statusType[0], grade)}>
                        {grade}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span>{initialValues[statusType[0]]}</span>
                )}
              </StatusCheckTd>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default StatusCheckTable;
