import { gql } from '@apollo/client';

export const GET_BOOK_CREDIT_LIST = gql`
  query getBookCreditList($campus_idx: Int!) {
    getBookCreditList(campus_idx: $campus_idx) {
      book_code
      credit_type
      cnt
      days
    }
  }
`;

export const GET_CAMPUS_BOOK_CREDIT_LIST = gql`
  query getCampusBookCreditList($campus_idx: Int!, $code: String!) {
    getCampusBookCreditList(campus_idx: $campus_idx, code: $code) {
      book_code
      cnt
    }
  }
`;

export const GET_CAMPUS_BOOK_CREDIT_COUNT = gql`
  query getCampusBookCreditCount($campus_idx: Int!, $book_code: String!) {
    getCampusBookCreditCount(campus_idx: $campus_idx, book_code: $book_code)
  }
`;

export const GET_BOOK_COMPANY_CREDIT_LIST = gql`
  query getCompanyBookCreditByMonth($company_idx: Int!, $month: String!) {
    getCompanyBookCreditByMonth(company_idx: $company_idx, month: $month) {
      idx
      cnt
      campus {
        idx
        name
      }
      prev_cnt(month: $month)
      manual_cnt(month: $month)
    }
  }
`;

export const GET_CAMPUS_BOOK_CREDIT_DETAIL_LIST = gql`
  query getCampusBookCreditDetailList($campus_idx: Int!, $month: String!) {
    getCampusBookCreditDetailList(campus_idx: $campus_idx, month: $month) {
      book {
        code
      }
      day
      credit_type
      cnt
    }
  }
`;

export const GET_CAMPUS_STUDENT_ASSIGNED_TICKET_LIST = gql`
  query getCampusStudentAssignedTicketList($campus_idx: Int!, $q: String) {
    getCampusStudentAssignedTicketList(campus_idx: $campus_idx, q: $q) {
      idx
      book_code
      user {
        name
        id
        class_student {
          class_idx
        }
      }
      book_credit {
        idx
        credit_type
        study_type
        issue_datetime
        idate
      }
      book_start_datetime
      book_end_datetime
      lesson_complete
    }
  }
`;

export const GET_CAMPUS_CREDIT_USED_STATUS_LIST = gql`
  query getCampusBookCreditUsedStatusList($campus_idx: Int!) {
    getCampusBookCreditUsedStatusList(campus_idx: $campus_idx) {
      book_code
      total
      unused
    }
  }
`;

export const CAMPUS_BOOK_CREDIT_INFO_LIST = gql`
  query getCampusBookCreditInfoList($campus_idx: Int!) {
    getCampusBookCreditInfoList(campus_idx: $campus_idx) {
      idx
      book_code
      credit_count
      used_count
      latest_date
      latest_book_credit_count
      days
      book {
        book_sub {
          title
          book_category {
            name
          }
        }
      }
    }
  }
`;

export const CAMPUS_STUDENT_BOOK_CREDIT_ASSIGNED_LIST_GROUP_BY_CLASS = gql`
  query getCampusStudentBookCreditAssignedListGroupByClass($campus_idx: Int!, $ym: String!) {
    getCampusStudentBookCreditAssignedListGroupByClass(campus_idx: $campus_idx, ym: $ym) {
      idx
      class_idx
      regdate
      credit_count
      book_code
      book_start_datetime
      book_end_datetime
      start_datetime
      end_datetime
      class_name
      days
      book {
        book_sub {
          title
          book_category {
            name
          }
        }
      }
      class {
        name
      }
    }
  }
`;
export const ENGLISHTAP_BOOK_CREDIT_CHECK = gql`
  query hasValidRootBookCredit($user_idx: Int!, $company_idx: Int!, $root_book_code: String!) {
    hasValidRootBookCredit(user_idx: $user_idx, company_idx: $company_idx, root_book_code: $root_book_code)
  }
`;
