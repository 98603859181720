import React, { useState, useEffect} from 'react';
const Timer = () => {
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
      const timerId = setTimeout(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }, [seconds]);
    return (
      <>
        {parseInt(seconds / 60) < 10 ? '0' + parseInt(seconds / 60) : parseInt(seconds / 60)}:
        {seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60}
      </>
    );
  };

  export default Timer;