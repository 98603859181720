import React, { useState, useEffect, useRef } from 'react';
import { PlayCircleFilled, PauseCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import ReactPlayer from 'react-player';
import { SAVE_TEST_ANSWERS } from 'src/operations/mutations/createTests';
import { List } from 'antd';
import { useFullscreenStatus } from 'src/utils/hooks';
import { GET_USER_INFO } from 'src/operations/queries/campus';

const InnerWrap = styled.div`
  background-color: #fff;
  width: 80%;
  height: 75%;
  max-width: 1800px;
  min-width: 650px;
  padding: 2rem 1rem;
  margin: 4rem auto;
  border-radius: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1400px) {
    max-width: 1150px;
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 33rem);
    height: calc(100% - 30rem);
    max-width: 100%;
    padding: 2rem 1rem;
    margin: 6rem auto;
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 9) {
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
    margin: 0 auto;

    & {
      .process-btn-con {
        padding: 0 4rem;
        a {
          img {
            transform: scale(1.5);
          }
        }
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 10) {
    width: calc(97rem - 3rem);
    height: calc(53rem - 3rem);
    padding: 3rem 1rem;
    margin: 5rem auto;
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    width: calc(100% - 10rem);
  }

  @media (max-width: 1920px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 2rem 2rem;
    max-height: 750px;
    margin: 3rem auto;
  }

  @media (max-width: 1680px) and (aspect-ratio: 16 / 10) {
    width: calc(97rem - 3rem);
    height: calc(53rem - 3rem);
    padding: 2rem 1rem;
    margin: 2rem auto;

    & {
      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 2rem;
        }
      }
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16 / 9) {
    max-width: 1500px;
    height: 100%;
    padding: 1rem 2rem 2rem;
    max-height: 650px;
    margin: 2rem auto;
  }

  @media (max-width: 1440px) and (aspect-ratio: 16 / 10) {
    width: calc(90rem - 18rem);
    height: calc(45rem - 3rem);

    & {
      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 100%;
    max-width: 1000px;
    max-height: 500px;
    margin: 0 auto;
    padding: 0;
  }

  @media screen and (max-width: 1280px) and (aspect-ratio: 16 / 10) {
    height: calc(45rem - 3rem);
    margin: 6rem auto;
  }

  @media (max-width: 1366px) {
    width: calc(100% - 5rem);
  }

  @media (max-width: 1024px) and (aspect-ratio: 16 / 9) {
    height: 70%;
    width: 80%;
    max-width: 800px;
    max-height: 400px;
    margin: 0 auto;

    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }
    }
  }

  @media (max-width: 1024px) {
    width: calc(100% - 7rem);
    //  height: calc(100% - 15rem);
  }

  /* galaxy tab */
  @media only screen and (max-width: 820px) and (orientation: portrait) {
    height: calc(90vh - 5rem);
    margin: 1rem auto;
  }

  /* air */
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    // height: calc(90vh - 10rem);
    height: auto;
    max-height: 100%;
    padding: 0 1rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  }

  @media screen and (max-width: 800px) and (orientation: portrait) {
    height: calc(100vh - 9rem);
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    padding: 0 1rem;
    margin: 2rem auto 0rem;
    // height: calc(100vh - 5rem);
    width: calc(100% - 5rem);
    min-width: auto;
    max-width: 100%;
  }
`;

// const burgerData = ['1', '2', '3', '4', '5'];

const ReadingTest = (props) => {
  const { data: dataUser } = useQuery(GET_USER_INFO);

  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 ? true : false;
  const { test, deadline } = props;
  // const history = useHistory();
  const [start_time, setStartTime] = useState(moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'));
  const [playing, setPlaying] = useState(false);
  const [playCount, setPlayCount] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxWrongCount, setMaxWrongCount] = useState(0);
  const [values, setValues] = useState([]);
  const testAnswers = test?.assigned_tests?.tests?.test_answers || [];
  const total_questions = testAnswers.length || 0;
  const testType = test?.assigned_tests?.tests?.test_type || '';
  const batchType = test?.assigned_tests?.tests?.batch_type || '';
  const formId = test?.assigned_tests?.tests?.form_id || '';
  const currentQuestion = testAnswers[currentIndex] ? testAnswers[currentIndex].questions : null;
  const currentOptions = currentQuestion?.options ? currentQuestion.options.split('|') : null;
  const [isOpenQuestionList, setIsOpenQuestionList] = useState(0);
  const [counter, setCounter] = useState(deadline);

  const maximizableElement = useRef(null);
  let isFullscreen, setIsFullscreen;
  let errorMessage;

  const handelRightClick = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    document.addEventListener('contextmenu', handelRightClick);
    return () => {
      document.removeEventListener('contextmenu', handelRightClick);
    };
  }, []);

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  } catch (e) {
    errorMessage = 'Fullscreen not supported';
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const [saveTestAnswers, { loading }] = useMutation(SAVE_TEST_ANSWERS);

  useEffect(() => {
    if (!isFullscreen && setIsFullscreen && !loading) {
      setIsFullscreen(true);
    }
  }, [isFullscreen, setIsFullscreen, loading]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      submitAnswer(true);
    }
  }, [counter]);

  useEffect(() => {
    if (currentIndex > 0) {
      const lastAnswer = values[currentIndex - 1];
      let answer = Array.isArray(lastAnswer) ? lastAnswer.join('|') : lastAnswer ? lastAnswer + '' : '';
      const t = testAnswers[currentIndex - 1];
      let sanitizeAnswer = answer.replaceAll('.', '');
      let corAns = t.answer
        .replaceAll('.', '')
        .split('|')
        .map((p) => p.trim())
        .join('|');
      const splitAns = corAns.split('|').map((p) => p.trim());
      let is_correct = false;

      if (t.condition === 'OR' && splitAns.includes(sanitizeAnswer)) {
        is_correct = true;
      } else if (corAns === sanitizeAnswer) {
        is_correct = true;
      }
      if (!is_correct && currentIndex > 14) {
        setMaxWrongCount(maxWrongCount + 1);
      } else {
        setMaxWrongCount(0);
      }
    }
    // if (maxWrongCount > 5) {
    //   message.info('You have reached the maximum number of wrong answers. Test will end now.');
    //   setTimeout(() => {
    //     submitAnswer(true);
    //   }, 1000);
    // }
  }, [currentIndex, currentIndex]);

  function onChange(val, idx) {
    values[idx] = val;
    // play();
    setValues(JSON.parse(JSON.stringify(values)));
  }

  function onChangePlayCount(idx) {
    playCount[idx] = playCount[idx] ? playCount[idx] + 1 : 1;
    // play();
    setPlayCount(JSON.parse(JSON.stringify(playCount)));
  }

  const submitAnswer = async (noCheck) => {
    if (!noCheck)
      if (window.confirm('답안지를 제출하시겠습니까') === false) {
        return false;
      }
    const end_time = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

    let score = 0;
    let wrongAnswers = [];

    const answers = testAnswers.map((t, i) => {
      let answer = Array.isArray(values[i]) ? values[i].join('|') : values[i] ? values[i] + '' : '';
      let sanitizeAnswer = answer.replaceAll('.', '');
      let corAns = t.answer
        .replaceAll('.', '')
        .split('|')
        .map((p) => p.trim())
        .join('|');
      const splitAns = corAns.split('|').map((p) => p.trim());
      let is_correct = false;

      if (t.condition === 'OR' && splitAns.includes(sanitizeAnswer)) {
        score += 1;
        is_correct = true;
      } else if (corAns === sanitizeAnswer) {
        score += 1;
        is_correct = true;
      }
      if (!is_correct) {
        wrongAnswers.push(t);
      }

      return { answer, code: t.code, is_correct };
    });

    let data = {
      start_time,
      end_time,
      assigned_test_user_idx: test?.idx,
      score,
      answers,
      test_type: testType,
      form_id: formId,
      user_idx: test?.user?.idx,
      batch_type: batchType,
    };

    try {
      await saveTestAnswers({ variables: data });
      if (setIsFullscreen) setIsFullscreen(false);
      window.location = '/MetaExam/MetaFinished';
    } catch (e) {
      console.log(e);
    }
  };

  const renderQuestions = () => {
    if (currentQuestion) {
      switch (testType) {
        case 'reading':
          return (
            <div className="readingtest-con">
              <div className="readingtest-wrap passage-con passage-con-real reading">
                <p dangerouslySetInnerHTML={{ __html: currentQuestion?.information_text || '' }}></p>
              </div>
              <div className="readingtest-wrap qna-real">
                <div className="reading-q-con">
                  <h1 style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ display: 'inline-block' }}>{currentIndex + 1}.&nbsp; </div>
                    <div
                      style={{ display: 'inline-block' }}
                      dangerouslySetInnerHTML={{ __html: ` ${currentQuestion.question}` || '' }}
                    ></div>
                  </h1>
                </div>
                <div className="reading-a-con">
                  {currentOptions &&
                    currentOptions.map((option, index) => (
                      <div key={index} className="reading-a-wrap" onClick={() => onChange(index + 1, currentIndex)}>
                        <div className="reading-a-num">
                          <h3>{String.fromCharCode(97 + index)}</h3>
                          {values[currentIndex] && values[currentIndex] === index + 1 && (
                            <img src="/images/check.png" alt="checked_img" className="checkedImg" />
                          )}
                        </div>
                        <div className="reading-a">
                          <h3>{option}</h3>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        case 'listening':
          return (
            <div className="readingtest-con">
              {currentQuestion?.image && (
                <div className="readingtest-wrap passage-con passage-con-real">
                  <img src={currentQuestion?.image} style={{ width: '100%' }} alt="burger_meta" className="vocabularyImg" />
                </div>
              )}
              <div className="readingtest-wrap qna-real" style={currentQuestion?.image ? {} : { width: '100%' }}>
                <div className="reading-q-con" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {(playCount[currentIndex] || 0) < 2 && (
                    <div style={{ marginRight: '10px' }}>
                      <>
                        {playing ? (
                          <PauseCircleOutlined onClick={() => setPlaying(false)} style={{ fontSize: '40px', width: '100%' }} />
                        ) : (
                          <PlayCircleFilled onClick={() => setPlaying(true)} style={{ fontSize: '40px', width: '100%' }} />
                        )}
                        <ReactPlayer
                          url={currentQuestion?.mp3}
                          playing={playing}
                          width="100%"
                          height="0px"
                          loop={false}
                          onEnded={() => {
                            setPlaying(false);
                            onChangePlayCount(currentIndex);
                          }}
                        />
                      </>
                    </div>
                  )}
                  <h1 style={{ display: 'flex', alignItems: 'flex-start', flex: 1 }}>
                    <div style={{ display: 'inline-block' }}>{currentIndex + 1}.&nbsp; </div>
                    <div
                      style={{ display: 'inline-block' }}
                      dangerouslySetInnerHTML={{ __html: ` ${currentQuestion.question}` || '' }}
                    ></div>
                  </h1>
                </div>
                <div className="reading-a-con">
                  {currentOptions &&
                    currentOptions.map((option, index) => (
                      <div key={index} className="reading-a-wrap" onClick={() => onChange(index + 1, currentIndex)}>
                        <div className="reading-a-num">
                          <h3>{String.fromCharCode(97 + index)}</h3>
                          {values[currentIndex] && values[currentIndex] === index + 1 && (
                            <img src="/images/check.png" alt="checked_img" className="checkedImg" />
                          )}
                        </div>
                        <div className="reading-a">
                          <h3>{option}</h3>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        case 'vocabulary':
        case 'grammar':
          return (
            <div className="readingtest-con">
              {currentQuestion?.image && (
                <div className="readingtest-wrap passage-con passage-con-real">
                  <img src={currentQuestion?.image} style={{ width: '100%' }} alt="burger_meta" className="vocabularyImg" />
                </div>
              )}
              <div className="readingtest-wrap qna-real" style={currentQuestion?.image ? {} : { width: '100%' }}>
                <div className="reading-q-con">
                  <h1 style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ display: 'inline-block' }}>{currentIndex + 1}.&nbsp; </div>
                    <div
                      style={{ display: 'inline-block' }}
                      dangerouslySetInnerHTML={{ __html: ` ${currentQuestion.question}` || '' }}
                    ></div>
                  </h1>
                </div>
                <div className="reading-a-con">
                  {currentOptions &&
                    currentOptions.map((option, index) => (
                      <div key={index} className="reading-a-wrap" onClick={() => onChange(index + 1, currentIndex)}>
                        <div className="reading-a-num">
                          <h3>{String.fromCharCode(97 + index)}</h3>
                          {values[currentIndex] && values[currentIndex] === index + 1 && (
                            <img src="/images/check.png" alt="checked_img" className="checkedImg" />
                          )}
                        </div>
                        <div className="reading-a">
                          <h3>{option}</h3>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        default:
          break;
      }
    }
  };

  console.log(values);

  return (
    <>
      <div
        className={
          isWEnglish ? 'meta-con meta-full-con disable-text-selection w-english-bg' : 'meta-con meta-full-con disable-text-selection'
        }
        ref={maximizableElement}
      >
        {isOpenQuestionList ? (
          <List
            size="large"
            className="meta-burger-list active"
            header={
              <div>
                Question List <CloseCircleOutlined onClick={() => setIsOpenQuestionList(false)} className="close-header" />
              </div>
            }
            dataSource={testAnswers}
            renderItem={(item, index) => (
              <List.Item className={values[index] ? 'done' : ''} onClick={() => setCurrentIndex(index)}>
                Question {index + 1}
              </List.Item>
            )}
          />
        ) : null}
        <div className="meta-header test-header">
          <div className="burger-wrap" onClick={() => setIsOpenQuestionList(true)}>
            <img src="/images/meta_hamburger.png" alt="burger_meta" />
            <h2>Question List</h2>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <div className="time-wrap">
              <h2>Time: </h2>
              <h2 className="num">{`${Math.floor(counter / 60)}:${counter % 60 < 10 ? `0${counter % 60}` : counter % 60}`}</h2>
            </div>
            <div className="question-wrap">
              <h2>Question: </h2>
              <h2 className="num">
                {currentIndex + 1}/{total_questions}
              </h2>
            </div>
          </div>
        </div>
        <InnerWrap>
          <div className="meta-header readingTest-header">
            <h1 className="main-tit" style={{ color: '#', textTransform: 'capitalize' }}>
              {testType}
            </h1>
          </div>

          {renderQuestions()}

          {/* bottom btn */}
          <div className="process-btn-con">
            <div onClick={() => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : currentIndex)}>
              <img src="/images/back_meta.png" alt="back_meta" />
            </div>

            <div
              onClick={() => {
                if (currentIndex === total_questions - 1) {
                  submitAnswer();
                } else {
                  setCurrentIndex(currentIndex + 1);
                }
              }}
              style={{ marginLeft: 'auto' }}
            >
              <img src="/images/next_meta.png" alt="next_meta" />
            </div>
          </div>
        </InnerWrap>
        <div className="report-footer-con">
          <h5>
            Powered by {'     '}
            <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
              Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
            </span>
          </h5>
          {testType === 'reading' && <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>}
        </div>
      </div>
    </>
  );
};

export default ReadingTest;
