import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;
const list = [
  {title:'Assign', currentMenu:'assign',url:'/elibrary/books',},
  {title:'Assigned List', currentMenu:'list',url:'/books/manage/action/result',},
  {title:'Results', currentMenu:'results',url:'/learning-elibrary',},
  {title:'Records', currentMenu:'records',url:'/elibrary/homeworkresult',},
]
const ELibraryMenuGroup = ({ currentMenu }) => {
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop:currentMenu!=='records'&& '10px' }}>
      {list.map((e)=> (<><MenuButton
        type={`${currentMenu === e.currentMenu ? 'primary' : 'default'} `}
        onClick={() => {
          history.push(e.url);
        }}
      >
        {e.title}
      </MenuButton>
      &nbsp;</>))}
      
      
    </div>
  );
};

export default ELibraryMenuGroup;
