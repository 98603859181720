import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Typography, Input, Button, Modal, Form, Tooltip, Space, Select, Upload, DatePicker } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { ShoppingCartOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download';
import { userTypVar } from 'src/apollo/cache';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { openNotification } from 'src/components/common/Notification';

import { useQueryProxy } from 'src/operations/proxy/user';
import { userInfoVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { phoneNumberCheck } from 'src/utils';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import { UploadOutlined } from '@ant-design/icons';
import { CloseSquareOutlined, DownloadOutlined } from '@ant-design/icons';
import { FileFromMimeType } from 'src/components/common/File';

const { TextArea } = Input;

const { Option } = Select;

const { Title, Text } = Typography;

const TodoBlock = styled.div``;

const NoticeBlock = styled.div``;

const { confirm } = Modal;

const Fake_data = [
  {
    date: '14:00',
    name: 123123123123,
    title: ' Zoom Class!',
    percent: 'Click to start',
    status: 5,
    isMeeting: true,
  },
];

const Campus = ({ onExpand, isExpandInvoice, bookCartChangedKey, handleFileValue }) => {
  const [tab, setTab] = useState('main');
  const history = useHistory();
  const [filterTable, setFilterTable] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const [text, setText] = useState(EditorState.createEmpty());
  const [visible, setVisible] = useState(false);
  const [visiblesecond, setVisiblesecond] = useState(false);

  const [searchValue, setSeachValue] = useState('');

  const [textLessonplan, settextLessonplan] = useState(EditorState.createEmpty());
  const [textHomework, settextHomework] = useState(EditorState.createEmpty());
  const [textExtraHomework, settextExtraHomework] = useState(EditorState.createEmpty());

  const [textActivityContent, settextActivityContent] = useState(EditorState.createEmpty());

  const [isReadOnly, setIsReadOnly] = useState(true);
  const { programidx, programlevel } = useParams();

  const [fileList, setFileList] = useState([]);
  const [Inputdatepick, setInputdatepick] = useState();

  const [InputActivityTitle, setInputActivityTitle] = useState();
  const [InputActivityName, setInputActivityName] = useState();
  const [InputActivityDate, setInputActivityDate] = useState('');
  const [InputValidation, setInputValidation] = useState('');

  const [InputLessonplanValidation, setInputLessonplanValidation] = useState('');

  const [InputLessonPlanTopic, setInputLessonPlanTopic] = useState('');
  const [InputLessonPlanDay, setInputLessonPlanDay] = useState('');
  const [InputLessonPlanLearnObj, setInputLessonPlanLearnObj] = useState('');

  const [ActivityObject, setActivityObject] = useState([]);
  const [ActivityObjectAdd, setActivityObjectAdd] = useState([]);

  const [currentprogramname, setcurrentprogramname] = useState('');
  const [currentprogramlevel, setcurrentprogramlevel] = useState('');
  const [currentprogramppturl, setcurrentprogramppturl] = useState('');
  const [editRecord, setEditRecord] = useState({});

  const [Inputbook1, setInputbook1] = useState('');
  const [Inputbook2, setInputbook2] = useState('');

  const [TextbookLevelForm, setTextbookLevelForm] = useState([]);
  const TextbookLevelFormFilter = TextbookLevelForm.map((Level) => Level);

  const { data: OfflinebookTitleWithLevel } = useQuery(queries.offlinesyllabus.OFFLINE_TEXTBOOK_TITLE_WITH_LEVEL, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: OfflineLessonList,
    offlinerefetch,
    offlineLessonloading,
  } = useQuery(queries.offlinesyllabus.OFFLINE_ADD_SYLLABUS_TEXTBOOK_LESSON_LIST_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      bookprogramrowid: parseInt(programidx),
      bookprogramlevel: programlevel,
    },
  });

  const {
    data: OfflineSyllabusActivityBoardFetch,
    refetch,
    Activityloading,
  } = useQuery(queries.offlinesyllabus.OFFLINE_SYLLABUS_ACTIVITY_BOARD, {
    fetchPolicy: 'no-cache',
    variables: {
      bookrowid: parseInt(programidx),
      booklevel: programlevel,
    },
  });

  const [deleteUser] = useMutation(mutations.offlinesyllabus.OFFLINE_SYLLABUS_ACTIVITY_LIST_BULK_DELETE, {
    onCompleted(data) {
      if (data) {
        refetch();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const {
    data: OfflineSyllabusLessonPlanFetch,
    LessonplanRefetch,
    LessonPlanloading,
  } = useQuery(queries.offlinesyllabus.OFFLINE_SYLLABUS_LESSON_PLAN, {
    fetchPolicy: 'no-cache',
    variables: {
      bookrowid: parseInt(programidx),
      booklevel: programlevel,
    },
  });

  const { data: OfflineSyllabusProgramLevelFetch } = useQuery(queries.offlinesyllabus.OFFLINE_SYLLABUS_PROGRAM_LEVEL, {
    fetchPolicy: 'no-cache',
    variables: {
      bookrowid: parseInt(programidx),
      booklevel: programlevel,
    },
  });

  useEffect(() => {
    if (OfflineSyllabusProgramLevelFetch?.offlineSyllabusProgramWithLevel) {
      OfflineSyllabusProgramLevelFetch?.offlineSyllabusProgramWithLevel.map((loopitem, loopkey) => {
        setcurrentprogramname(loopitem.programname);
        setcurrentprogramlevel(loopitem.level);
      });
    }
  }, [OfflineSyllabusProgramLevelFetch]);

  useEffect(() => {
    if (OfflineLessonList?.offlineAddTextbookSyllabusListID) {
      let lessonlistdata = OfflineLessonList?.offlineAddTextbookSyllabusListID.map((loopitem, loopkey) => {
        if (parseInt(programidx) == loopitem.idx) {
          setcurrentprogramname(loopitem.programname);
          setcurrentprogramlevel(loopitem.level);
          setInputLessonPlanTopic(loopitem.lesson_topic);
          let pptloopurl = loopitem.ppturl;
          if (loopitem.ppturl !== null && pptloopurl.search(/^http[s]?\:\/\//) == -1) {
            pptloopurl = 'http://' + pptloopurl;
            setcurrentprogramppturl(pptloopurl);
          } else {
            setcurrentprogramppturl(loopitem.ppturl);
          }
        }
      });
    }
  }, [OfflineLessonList]);

  const props = {
    multiple: true,
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      handleFileValue((prev) => {
        const index = prev.files.indexOf(file);
        const newFileList = prev.files.slice();
        newFileList.splice(index, 1);

        return {
          ...prev,
          files: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      setFileList((prev) => [...prev, file]);
      handleFileValue((prev) => ({
        ...prev,
        files: [...prev.files, file],
      }));
      return false;
    },
    fileList,
  };

  /*const {
    data,
    loading: getUserLoading,
    refetch,
  } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: { type: userTypVar().teacher },
  });*/

  /*const { data: fetchNoticeBoard, loading: noticeLoading } = useQueryProxy(queries.board.GET_BOARD_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      bo_table: 'campusNotice',
      company_idx: userInfoVar()?.campus?.company?.idx,
    },
  });*/

  useEffect(() => {
    if (OfflineSyllabusActivityBoardFetch?.offlineSyllabusActivityBoardList) {
      let resultobj = OfflineSyllabusActivityBoardFetch?.offlineSyllabusActivityBoardList.map((loopitem, loopkey) => {
        let obj = {
          No: loopkey + 1,
          key: loopitem.idx,
          Title: loopitem.enrolltitle,
          Name: loopitem.enrollname,
          Date: loopitem.enrolldate !== '' ? loopitem.enrolldate.split('T')[0] : '',
          File: loopitem.bf_fileName,
          Hit: loopitem.enrollhit,
          content: loopitem.content,
          bf_file: loopitem.bf_file,
          bf_fileName: loopitem.bf_fileName,
          path: loopitem.path,
        };
        return obj;
      });
      setActivityObject(resultobj);
      setActivityObjectAdd(resultobj);
    }

    if (OfflinebookTitleWithLevel?.offlineTextbookTitleWithLevel) {
      let levelformation = OfflinebookTitleWithLevel?.offlineTextbookTitleWithLevel.map((loopitem, loopkey) => {
        if (programlevel == loopitem.level) {
          setInputbook1(loopitem.bookcode);
          let obj = {
            levelname: `${loopitem.booktitle}`,
            //"levelkey" : `${loopitem.bookcode}/${loopitem.level}`
            levelkey: `${loopitem.bookcode}`,
          };
          return obj;
        }
      });
      setTextbookLevelForm(levelformation.filter((item) => item));
    }

    if (OfflineSyllabusLessonPlanFetch?.offlineSyllabusLessonPlanList) {
      if (OfflineSyllabusLessonPlanFetch?.offlineSyllabusLessonPlanList.length > 0) {
        let lessonobject = OfflineSyllabusLessonPlanFetch?.offlineSyllabusLessonPlanList;
        //setInputLessonPlanTopic(lessonobject[0].plantopic)
        setInputLessonPlanDay(lessonobject[0].planday);
        setInputLessonPlanLearnObj(lessonobject[0].planlearningobj);
        setInputbook1(lessonobject[0].text_bookcode1);
        setInputbook2(lessonobject[0].text_bookcode2);
        function inputblock(input) {
          let { contentBlocks, entityMap } = htmlToDraft(input);
          let content = ContentState.createFromBlockArray(contentBlocks, entityMap);
          return content;
        }
        settextLessonplan(EditorState.createWithContent(inputblock(lessonobject[0].lessonplancontent)));
        settextHomework(EditorState.createWithContent(inputblock(lessonobject[0].homeworkcontent)));
        if (lessonobject[0].extrahomeworkcontent !== null) {
          settextExtraHomework(EditorState.createWithContent(inputblock(lessonobject[0].extrahomeworkcontent)));
        }
      }
    }
  }, [OfflineSyllabusActivityBoardFetch, OfflinebookTitleWithLevel, OfflineSyllabusLessonPlanFetch]);

  /*const noticeData = useMemo(() => {
    if (fetchNoticeBoard) {
      let boaradNumber = fetchNoticeBoard?.getBoardList?.length || 0;
      return fetchNoticeBoard.getBoardList.reduce((acc, cur, index) => {
        const obj = {
          key: cur.idx,
          no: boaradNumber--,
          title: cur.wr_subject,
          name: cur.user.name,
          date: moment(cur.wr_datetime.replace('.000Z', '')).format('YYYY-MM-DD'),
          pageName: cur.board.bo_table,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchNoticeBoard]);*/

  const onLessonPlanChange = (text) => {
    settextLessonplan(text);
  };

  const onHomeWorkChange = (text) => {
    settextHomework(text);
  };

  const onExtraHomeWorkChange = (text) => {
    settextExtraHomework(text);
  };

  const onActivityWorkChange = (text) => {
    settextActivityContent(text);
  };

  const { value } = '';

  const handleEnrollmentCancel = (e) => {
    setInputValidation('');
    setVisible(false);
    //window.location.reload();
  };

  const handleEnrollment = (record) => {
    if (record.Title) {
      setInputValidation('');
      setInputActivityTitle(record.Title);
      setInputActivityName(record.Name);
      setInputActivityDate(record.Date);
      settextActivityContent(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(record.content))));
      setEditRecord(record);
    } else {
      setInputValidation('');
      setInputActivityTitle('');
      setInputActivityName('');
      setInputActivityDate('');
      settextActivityContent(EditorState.createEmpty());
      setEditRecord({});
    }
    setFileList([]);
    setVisible(true);
  };

  const handleDateChange = (date, dateString) => {
    setInputActivityDate(dateString);
  };

  const [enrollActivitySave] = useMutation(mutations.offlinesyllabus.OFFLINE_LESSON_ACTIVITY_BOARD, {
    onCompleted(data) {
      if (data) {
        setInputValidation('Activity Added Successfully');
        refetch();
        setVisible(false);
      } else {
        refetch();
        setVisible(false);
      }
      // window.location.reload();
    },
    onError(error) {
      refetch();
      setVisible(false);
      console.log('error');
      window.location.reload();
    },
  });

  const [lessonplanSave] = useMutation(mutations.offlinesyllabus.OFFLINE_LESSON_PLAN_ENTRY, {
    onCompleted(data) {
      if (data) {
        setInputLessonplanValidation('Lesson Plan Saved Successfully !!!');
        history.goBack();
      } else {
        setInputLessonplanValidation('Activity Already Exists like Title,Name,Date');
      }
    },
    onError(error) {
      console.log('error');
    },
  });

  const handleEnrollmentSave = (e) => {
    setInputValidation('');
    enrollActivitySave({
      variables: {
        programrowno: parseInt(programidx),
        booklevel: programlevel,
        enrolltitle: InputActivityTitle,
        enrollname: InputActivityName,
        enrolldate: InputActivityDate,
        content: draftToHtml(convertToRaw(textActivityContent.getCurrentContent())),
        files: fileList,
        activityid: editRecord.Title ? editRecord.key : 0,
      },
    });
  };

  const handleLessonPlanSave = (e) => {
    setInputLessonplanValidation('');
    lessonplanSave({
      variables: {
        programrowno: parseInt(programidx),
        booklevel: programlevel,
        plantopic: InputLessonPlanTopic,
        planday: InputLessonPlanDay,
        planlearningobj: InputLessonPlanLearnObj,
        plantextbook1: Inputbook1 == null ? '' : Inputbook1,
        plantextbook2: Inputbook2 == null ? '' : Inputbook2,
        lessonplancontent: draftToHtml(convertToRaw(textLessonplan.getCurrentContent())),
        homeworkcontent: draftToHtml(convertToRaw(textHomework.getCurrentContent())),
        extrahomeworkcontent: draftToHtml(convertToRaw(textExtraHomework.getCurrentContent())),
      },
    });
  };

  const ppthandleClick = (input, urlinput) => {
    window.open(urlinput, 'PPT_VIEW', 'popup');
  };

  const handleSearch = (e) => {
    if (e !== '') {
      let resultobj = ActivityObjectAdd.map((loopitem, loopkey) => {
        let findTitle = loopitem.Title.toLowerCase();
        let titlePattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');

        let findName = loopitem.Name.toLowerCase();
        let namePattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');

        let findDate = loopitem.Date.toLowerCase();
        let datePattern = new RegExp('(\\w*' + `${e.toLowerCase()}` + '\\w*)', 'gi');

        if (findTitle.match(titlePattern) !== null || findName.match(namePattern) !== null || findDate.match(datePattern) !== null) {
          let obj = {
            No: loopkey + 1,
            key: loopitem.key,
            Title: loopitem.Title,
            Name: loopitem.Name,
            Date: loopitem.Date,
            File: loopitem.File,
            Hit: loopitem.Hit,
          };
          return obj;
        }
      });
      setActivityObject(resultobj.filter((obj) => obj));
    } else {
      setActivityObject(ActivityObjectAdd);
    }
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      align: 'center',
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      align: 'center',
    },
    {
      title: 'File',
      dataIndex: 'File',
      key: 'File',
      align: 'center',
      render: (text, record) => (
        <a href={record.bf_file} target="_blank">
          {record.File}
        </a>
      ),
    },
    {
      title: 'Hit',
      dataIndex: 'Hit',
      key: 'Hit',
      align: 'center',
    },
    {
      title: 'Edit',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button
          onClick={() => {
            handleEnrollment(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  const handleDown = async (data, name) => {
    const blob = await fetch(data).then((r) => r.blob());
    fileDownload(blob, name);
  };
  const handleFileDelete = (file_idx) => {
    confirm({
      title: 'Are sure to delete?',
      okText: '예',
      okType: 'primary',
      cancelText: '아니오',
      onOk() {
        deleteBoardFile({
          variables: {
            boardFile_idx: Number(file_idx),
          },
        });
      },
      onCancel() {},
    });
  };
  const [deleteBoardFile] = useMutation(mutations.offlinesyllabus.DELETE_OFFLINE_BOARD_FILE, {
    onCompleted(data) {
      if (data) {
        // refetch();
        setEditRecord({ ...editRecord, bf_file: '' });
        openNotification('파일 삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handletextbook1Change = (e) => {
    setInputbook1(e);
  };

  const handletextbook2Change = (e) => {
    setInputbook2(e);
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col span={10} className="calendar-box">
          <span style={{ color: 'red' }}>{InputLessonplanValidation}</span>
          <HalfWrapper className="tblclass-list-height">
            <span style={{ width: 200, color: '#249fe9', fontSize: '20px', fontWeight: 'bold' }}>Program Name : </span>
            <span style={{ width: 200, fontSize: '20px', fontWeight: 'bold' }}>{currentprogramname}</span>
            <span style={{ width: 200, fontSize: '20px', fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;</span>
            <span style={{ width: 160, color: '#249fe9', fontSize: '20px', fontWeight: 'bold' }}>Level : </span>
            <span style={{ width: 160, fontSize: '20px', fontWeight: 'bold' }}>{currentprogramlevel}</span>
            <br />
            <br />
            <Form
              form={form}
              initialValues={{
                layout: formLayout,
              }}
            >
              <Space>
                <Form.Item label="Topic" style={{ border: '1px solid #249fe9', padding: '5px', width: '500px' }}>
                  <label>{InputLessonPlanTopic}</label>
                  {/*<Input style={{ width: 160 }} value={InputLessonPlanTopic} onInput={e => setInputLessonPlanTopic(e.target.value) }/>*/}
                </Form.Item>
                <Form.Item label="Day" style={{ border: '1px solid #249fe9', padding: '5px', width: '100px' }}>
                  <label>{InputLessonPlanDay}</label>
                  {/*<Input style={{ width: 160 }} value={InputLessonPlanDay} onInput={e => setInputLessonPlanDay(e.target.value) }/>*/}
                </Form.Item>
              </Space>
              <Form.Item label="학습목표" style={{ border: '1px solid #249fe9', padding: '5px', width: '600px' }}>
                <label>{InputLessonPlanLearnObj}</label>
                {/*<Input placeholder="" value={InputLessonPlanLearnObj} onInput={e => setInputLessonPlanLearnObj(e.target.value) }/>*/}
              </Form.Item>
              <Space>
                <Form.Item
                  name="교재 1"
                  label="교재 1"
                  rules={[
                    {
                      message: 'Please select gender!',
                    },
                  ]}
                >
                  <>
                    {Inputbook1}
                    {/*<Select style={{width:"150px"}} value={Inputbook1} onChange={handletextbook1Change}>
                  {
                    TextbookLevelFormFilter.map((loopitem,loopkey) => <option key={loopkey} value={loopitem.levelkey} >{loopitem.levelname}</option>)
                  }
              </Select>*/}
                  </>
                </Form.Item>

                <Form.Item
                  name="교재 2"
                  label="교재 2"
                  rules={[
                    {
                      message: 'Please select 교재 2',
                    },
                  ]}
                >
                  <>
                    {Inputbook2}
                    {/*<Select style={{width:"150px"}} value={Inputbook2} onChange={handletextbook2Change}>
                  {
                    TextbookLevelFormFilter.map((loopitem,loopkey) => <option key={loopkey} value={loopitem.levelkey} >{loopitem.levelname}</option>)
                  }
              </Select>*/}
                  </>
                </Form.Item>
              </Space>
            </Form>
            <NoticeBlock className="calendar-box-two-b" style={{ boxShadow: 'none', borderRadius: '10px', border: '1px solid #1890ff' }}>
              <Title level={5}>Homework</Title>
              <Editor
                editorState={textLessonplan}
                toolbarClassName="hide-print"
                wrapperClassName="essay-wrapper"
                editorClassName="essay-editor-offline"
                onEditorStateChange={onLessonPlanChange}
                toolbar={{
                  options: [],
                }}
                style={{ boxShadow: 'none', borderRadius: '10px', minHeight: '150px', border: '1px solid #1890ff' }}
                readOnly={isReadOnly}
              />
            </NoticeBlock>
          </HalfWrapper>
        </Col>
        <Col span={14} className="calendar-box-two">
          <HalfWrapper className="tblclass-list-height">
            <NoticeBlock
              className="calendar-box-two-b"
              style={{ boxShadow: 'none', borderRadius: '10px', minHeight: '250px', border: '1px solid #1890ff' }}
            >
              <Title level={5}>Activity Ideas( 게시판 )</Title>
              <div
                className="header-classroom-teacher"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                }}
              >
                <Title className="header-classroom-title" level={5}>
                  상세보기
                </Title>
                <div className="header-classroom-search">
                  <Input.Search
                    className="inputsearch-classroom"
                    placeholder="검색어를 입력해 주세요"
                    allowClear
                    style={{ width: 250, marginRight: 10 }}
                    enterButton
                    onSearch={handleSearch}
                    onChange={onSeachChange}
                    value={searchValue}
                  />
                  <CustomButton style={{ marginRight: 10 }}>
                    <RedoOutlined />
                  </CustomButton>
                  <CustomButton style={{ marginRight: 10 }} onClick={handleEnrollment}>
                    <PlusOutlined style={{ color: '#52c419' }} />
                    <Text type={'success'}>등록</Text>
                  </CustomButton>
                  {/*<CustomButton
                      danger
                      style={{
                        marginRight: 10,
                      }}
                      onClick={deleteConfirm}
                    >
                      삭제
                    </CustomButton>*/}
                </div>
              </div>
              <CustomTable
                className="classroom-table-table"
                loading={Activityloading}
                dataSource={ActivityObject}
                columns={columns}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                pagination={{ pageSize: 4 }}
                style={{ height: '240px' }}
                size="small"
                color="#edf3fb"
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {}, // click row
                  };
                }}
              />
            </NoticeBlock>
            <br />
            <Space>
              <Title level={5} style={{ width: '500px' }}>
                Today’s Homework
              </Title>
              {/*<Title level={5}>Extra Home Work</Title>*/}
            </Space>
            <br />
            <Space>
              <NoticeBlock
                className="calendar-box-two-b"
                style={{ boxShadow: 'none', borderRadius: '10px', width: '960px', minHeight: '200px', border: '1px solid #1890ff' }}
              >
                <Editor
                  editorState={textHomework}
                  style={{ width: '100%' }}
                  rootStyle={{ height: 'calc(100% - 50px)', width: '100%', minHeight: 300 }}
                  editorStyle={{ backgroundColor: '#fff' }}
                  toolbarClassName="hide-print"
                  wrapperClassName="essay-wrapper"
                  onEditorStateChange={onHomeWorkChange}
                  toolbar={{
                    options: [],
                  }}
                  readOnly={isReadOnly}
                />
              </NoticeBlock>
              {/*<NoticeBlock className="calendar-box-two-b" style={{ boxShadow: 'none', borderRadius: '10px',minHeight:"200px",width:"450px",border:"1px solid #1890ff" }}>
              <Editor
                editorState={textExtraHomework}
                style={{ width: '100%'}}
                rootStyle={{ height: 'calc(100% - 50px)', width: '100%', minHeight: 300 }}
                editorStyle={{ backgroundColor: '#fff',overFlowY:"hidden !important" }}
                toolbarClassName="hide-print"
                wrapperClassName="essay-wrapper"
                onEditorStateChange={onExtraHomeWorkChange}
                toolbar={{
                  options: ['inline','fontSize']
                }}
              />
            </NoticeBlock>*/}
            </Space>
            <br />
            <br />
            <CustomButton
              danger
              style={{
                marginRight: 10,
                marginTop: 35,
              }}
              size="large"
            >
              <a href={currentprogramppturl} target="_blank">
                PPT View
              </a>
            </CustomButton>
            <Button
              className="btn-schedule"
              type={'primary'}
              size="large"
              style={{ margin: 5, float: 'right', marginLeft: 20 }}
              onClick={(e) => handleLessonPlanSave(e)}
            >
              Save
            </Button>
            <Button
              className="btn-schedule"
              type="secondary"
              shape="round"
              size="large"
              style={{ marginRight: 5, float: 'right', marginTop: 5 }}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
          </HalfWrapper>
        </Col>
      </Row>

      <Modal
        title={!editRecord.Title ? 'Enrollement' : 'Edit Enrollement'}
        centered
        visible={visible}
        width={1000}
        onSave={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Row justify="center">
          <Col span={4}></Col>
          <Col span={18}>
            <span style={{ color: 'red' }}>{InputValidation}</span>
          </Col>
          <Col span={2}></Col>
          <Col span={1}></Col>
          <Col span={22}>
            <Form
              form={form}
              initialValues={{
                layout: formLayout,
              }}
              style={{ marginTop: '4%' }}
            >
              <Form.Item name="wr_title" label="Title" rules={[{ required: true }]}>
                <>
                  <Input value={InputActivityTitle} onInput={(e) => setInputActivityTitle(e.target.value)} />
                </>
              </Form.Item>
              <Form.Item name="wr_name" label="Name" rules={[{ required: true }]}>
                <>
                  <Input value={InputActivityName} onInput={(e) => setInputActivityName(e.target.value)} />
                </>
              </Form.Item>
              <Form.Item name="wr_date" label="Date" rules={[{ required: true }]}>
                <>
                  <DatePicker
                    value={InputActivityDate && moment(InputActivityDate, 'YYYY-MM-DD')}
                    onChange={(date, dateString) => {
                      handleDateChange(date, dateString);
                    }}
                  />
                </>
              </Form.Item>
              <Form.Item name="wr_date" label="Content Search">
                <NoticeBlock
                  className="calendar-box-two-b"
                  style={{ boxShadow: 'none', borderRadius: '10px', minHeight: '250px', border: '1px solid #d1d9e1' }}
                >
                  <Editor
                    editorState={textActivityContent}
                    style={{ width: '100%', minHeight: 200 }}
                    rootStyle={{ height: 'calc(100% - 50px)', width: '100%', minHeight: 300 }}
                    editorStyle={{ backgroundColor: '#fff' }}
                    toolbarClassName="hide-print"
                    wrapperClassName="essay-wrapper"
                    onEditorStateChange={onActivityWorkChange}
                    toolbar={{
                      options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'link'],
                    }}
                  />
                </NoticeBlock>
              </Form.Item>
              <br />
              <Form.Item label="Upload File" valuePropName="files" style={{ marginBottom: 5 }}>
                {!editRecord.bf_file && (
                  <Upload {...props} name="files">
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                )}
                {editRecord.bf_file && (
                  <>
                    <img src={editRecord.bf_file} width={100} />
                    <Text style={{ marginRight: 10 }}>{editRecord.bf_fileName}</Text>
                    <DownloadOutlined
                      style={{ fontSize: '1.2rem', marginRight: 10 }}
                      onClick={() => handleDown(editRecord.path, editRecord.bf_fileName)}
                    />
                    <CloseSquareOutlined style={{ fontSize: '1.2rem' }} onClick={() => handleFileDelete(editRecord.key)} />
                  </>
                )}
              </Form.Item>
            </Form>
          </Col>
          <Col span={2}></Col>
          <Col span={16}></Col>
          <Col span={2}>
            <Button
              className="btn-schedule"
              size="large"
              style={{ margin: 2, float: 'right', borderRadius: '5px', backgroundColor: 'orange' }}
              onClick={(e) => handleEnrollmentCancel(e)}
            >
              Cancel
            </Button>
          </Col>
          <Col span={2}>
            <Button
              className="btn-schedule"
              type={tab === 'main' && 'primary'}
              size="large"
              style={{ margin: 2, float: 'right', borderRadius: '5px' }}
              onClick={(e) => handleEnrollmentSave(e)}
              onClose={(e) => e.preventDefault()}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Campus;
