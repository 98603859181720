import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// import { SyllabusMain, SyllabusAdd, SyllabusEdit, SyllabusAssigned, SyllabusAssignedDetail } from 'src/components/Test';

import { DailySyllabusmain, DailySyllabusAdd, DailySyllabusEdit, DailySyllabusAssignedDetail, DailySyllabusAssign, DailyTestSmallZipUpload, TestResult } from 'src/components/Test';


const DailyTestSyllabus = (props) => {
  console.log("useParams()", useParams());
  const { type = 'main', id } = useParams();
  const [assignIdx, setAssignIdx] = useState()



  const getSelectedId = (idx) => {
    // console.log("get idx=====",idx);
    setAssignIdx(idx)
  }

  const getSwitchableRoute = (type, id = null) => {
    switch (type) {
      case 'main':
        return <DailySyllabusmain getSelectedIdx={getSelectedId} />;
      case 'add':
        return <DailySyllabusAdd />;
      case 'addsmallzip':
        return <DailyTestSmallZipUpload />;
      case 'edit':
        return <DailySyllabusEdit />;
      case 'assigned':
        return id ? <DailySyllabusAssignedDetail /> : assignIdx ? <DailySyllabusAssign selectedId={assignIdx} /> : <DailySyllabusmain getSelectedIdx={getSelectedId} />;
      // case 'quiz':
      //   <DailyTestGroupQuiz/>
      //   default:
      //     return <SyllabusMain />;
      case 'result':
      return <TestResult/>
    }
  };

  return (
    <>
      {getSwitchableRoute(type, id)}
      {/* {type === 'main' ? <SyllabusMain /> : type === 'add' ? <SyllabusAdd /> : <SyllabusAssigned />} */}
    </>
  );
};

export default DailyTestSyllabus;
