import { Divider } from 'antd';
import React, { useEffect } from 'react';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import MessageItem from '../MessageItem';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UPDATE_STATUS } from 'src/operations/mutations/messenger';
import { MESSSAGE_LIST } from 'src/operations/queries/messenger';
import { SEND_MESSAGE_SUBSCRIPTION, UPDATE_MESSAGE_SUBSCRIPTION } from 'src/operations/subscriptions/messenger';
import { userInfoVar } from 'src/apollo/cache';

//<Divider plain>여기까지 확인했습니다.</Divider>

function MessageList({ chat_room_idx }) {
  //const [isFirstUpdate, setIsFirstUpdate] = useState(false);

  const { data, subscribeToMore, fetchMore, refetch } = useQuery(MESSSAGE_LIST, {
    variables: { chat_room_idx },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SEND_MESSAGE_SUBSCRIPTION,
      variables: { chat_room_idx },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        const newMessage = subscriptionData.data.sendMessage;
        if (prev.chatRoomThreads) {
          if (newMessage.idx > 0) {
            if (!prev.chatRoomThreads.find((item) => item.idx === newMessage.idx)) {
              return Object.assign({}, prev, {
                chatRoomThreads: [newMessage, ...prev.chatRoomThreads],
              });
            }
          }
        }

        return prev;
      },
    });
    return () => unsubscribe();
    // eslint-disable-next-line
  }, [chat_room_idx]);

  useSubscription(UPDATE_MESSAGE_SUBSCRIPTION, {
    variables: { chat_room_idx },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data) {
        if (data?.updateMessage?.chat_room_idx === chat_room_idx && data?.updateMessage?.chat_user_idx !== parseInt(userInfoVar()?.idx)) {
          refetch({ variables: { chat_room_idx } });
        }
      }
    },
  });

  const loadMoreMessages = () => {
    data &&
      fetchMore({
        variables: {
          chat_room_idx,
          last_idx: data && data.chatRoomThreads ? data.chatRoomThreads[data.chatRoomThreads.length - 1]?.idx : 0,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.chatRoomThreads) {
            return prev;
          }
          return Object.assign({}, prev, {
            chatRoomThreads: [...prev.chatRoomThreads, ...fetchMoreResult.chatRoomThreads],
          });
        },
      });
  };

  const [updateStatus] = useMutation(UPDATE_STATUS, {
    onCompleted(data) {
      if (data) {
        console.log('onCompleted', data);
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (data) {
      if (userInfoVar()?.idx) {
        updateStatus({ variables: { chat_room_idx, chat_user_idx: parseInt(userInfoVar()?.idx) } });
      }
    }
  }, [data, chat_room_idx, updateStatus]);

  return (
    <InfiniteScroll
      scrollableTarget="MessageBox"
      style={{ display: 'flex', flexDirection: 'column-reverse' }}
      next={loadMoreMessages}
      inverse={true}
      hasMore={true}
      dataLength={data && data.chatRoomThreads ? data.chatRoomThreads : 0}
    >
      {data &&
        data.chatRoomThreads.map((thread, key) => {
          const isMe = Number(userInfoVar()?.idx) === thread.chat_user_idx;
          let unreadCount = 0;
          if (isMe) {
            const tempCount = thread.chat_room.chat_room_user.length - 1 - thread.read_count;
            if (tempCount > 0) {
              unreadCount = tempCount;
            }
          }
          return (
            <React.Fragment key={`message-item-key${key}`}>
              <MessageItem
                isMe={isMe}
                name={thread.chat_user_name}
                message={thread.content}
                date={thread.idate}
                readYN={thread.read_yn}
                unreadCount={unreadCount}
              />
              <DateDivider
                isShow={
                  key === data.chatRoomThreads.length - 1 ||
                  (key < data.chatRoomThreads.length - 1 && !moment(thread.idate).isSame(data.chatRoomThreads[key + 1].idate, 'day'))
                }
                date={`${moment(new Date(thread.idate)).format('YYYY-MM-DD')}`}
              />
            </React.Fragment>
          );
        })}
    </InfiniteScroll>
  );
}

export default MessageList;

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};
