import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useQuery } from '@apollo/client';
import { HalfWrapper } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import BookSettingsEditModal from 'src/components/Modal/BookSettingsEditModal';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import BookSettingsDetail from '../../pages/FcManager/BookSettingsDetail';

const { Title } = Typography;

function BookSettingModal({ visible, handleCancel, bookInfo }) {
  const [books, setBooks] = useState([]);
  const [selectBook, setSelectBook] = useState(null);
  const [rowSelect, setRowSelect] = useState(0);
  const { visible: bookSettingsEditVisible, showModal: bookSettingsEditShowModal, handleCancel: handleBookSettingsEditCancel } = useModal();
  const { data: fetchBooks, loading: booksLoading } = useQuery(queries.book.GET_BOOK_LIST_BY_PARENT_CODE, {
    variables: {
      parent_code: bookInfo.book_code,
    },
  });

  useEffect(() => {
    if (fetchBooks?.bookListByParentCode) {
      setBooks(
        fetchBooks?.bookListByParentCode.reduce((acc, cur, index) => {
          const obj = {
            key: cur.code,
            No: index + 1,
            code: cur.code,
            levelName: cur.level_name,
            volumeName: cur.volume_name,
            evine_level: cur.evine_level,
            sort_evine: cur.sort_evine,
            title: cur.book_sub[0]?.title,
          };
          return [...acc, obj];
        }, []),
      );

      if (!selectBook) {
        setSelectBook(fetchBooks.bookListByParentCode[0]);
        handleTableRowClick({
          key: fetchBooks?.bookListByParentCode[0]?.code,
        });
      }
    }
  }, [fetchBooks]);

  const handleTableRowClick = ({ key }) => {
    setRowSelect(key);
    setSelectBook(fetchBooks?.bookListByParentCode.filter((book) => book.code === key)[0]);
  };

  const setRowClassName = (record) => {
    return record.key === rowSelect ? 'clickRowStyle' : '';
  };

  return (
    <>
      <Modal
        entered
        title={`${bookInfo.title} 상품 관리`}
        visible={visible}
        onCancel={handleCancel}
        okText="확인"
        cancelText="취소"
        width={'90%'}
      >
        <Row gutter={[16, 16]}>
          <Col span={!selectBook ? 24 : 16}>
            <HalfWrapper>
              <Row justify="space-between" gutter={[8, 8]}>
                <Col>
                  <Title level={5}>LIST</Title>
                </Col>
              </Row>
              <CustomTable
                dataSource={books}
                pagination={{ pageSize: 10 }}
                columns={booksTable}
                size="small"
                scroll={{ y: 'calc(100vh - 254px)' }}
                color="#edf3fb"
                loading={booksLoading}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      handleTableRowClick(record);
                    }, // click row
                  };
                }}
                rowClassName={setRowClassName}
              />
            </HalfWrapper>
          </Col>

          {selectBook && (
            <Col span={8}>
              <HalfWrapper>
                <Row justify="space-between" gutter={[8, 16]}>
                  <Col>
                    <Title level={5}>도서 설정 세부정보</Title>
                  </Col>
                  <Col>
                    <Button type="primary" onClick={bookSettingsEditShowModal}>
                      볼륨 및 레벨 설정 수정
                    </Button>
                  </Col>
                </Row>
                <BookSettingsDetail bookInfo={selectBook} />
              </HalfWrapper>
            </Col>
          )}
        </Row>
      </Modal>
      {bookSettingsEditVisible && (
        <BookSettingsEditModal
          handleCancel={handleBookSettingsEditCancel}
          visible={bookSettingsEditVisible}
          bookInfo={selectBook}
          setSelectBook={setSelectBook}
        />
      )}
    </>
  );
}

const booksTable = [
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'left',
  },
  {
    title: '코드',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
  },
  {
    title: '레벨',
    dataIndex: 'levelName',
    key: 'levelName',
    align: 'center',
  },
  {
    title: '볼륨',
    dataIndex: 'volumeName',
    key: 'volumeName',
    align: 'center',
  },
];

BookSettingModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  bookInfo: PropTypes.objectOf.isRequired,
};

export default BookSettingModal;
