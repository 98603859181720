import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, Select, Spin, message, Modal, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import styled from 'styled-components';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { openNotification } from 'src/components/common/Notification';
import { useHistory } from 'react-router-dom';
import FullScreenButton from 'src/components/common/FullScreenButton';
import 'react-simple-keyboard/build/css/index.css';
import htmlToDraft from 'html-to-draftjs';
import * as axios from 'axios';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import ReactPlayer from 'react-player';
import * as diff_match_patch from 'diff-match-patch';
import { captureUserMedia } from 'src/utils';
const dmp = new diff_match_patch();
const { Title: TypoTitle } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const dummyData = [
  {
    page: 0,
    recording: '1',
    text: ``,
  },
];
const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled.div`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 550px;
  min-height: 650px;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const StageTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 20px 30px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const Body = styled(ColumnFlex)`
  width: 100%;
  padding: 0 30px;
  height: 100%;
  /* align-items: center; */
  /* justify-content: space-between; */
`;
const Image = styled.img`
  object-fit: scale-down;
  width: 99%;
  height: 99%;
  border-radius: 15px;
`;
const ContentBox = styled(ColumnFlex)`
  background-color: #daedeb;
  border-radius: 20px;
  font-size: 22px;
  font-weight: 600;
  padding: 0 20px 20px;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 2;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #443ec8;
  color: #fff;
  font-size: 25px;
  margin: 0 15px;
  box-shadow: 0 2px 1px 2px #090989;
`;
const QuestionBox = styled(RowFlex)`
  font-size: 25px;
  color: #000000;
  align-items: center;
  padding: 0px 10px;
  line-height: 75px;
  height: 75px;
  /* border-radius: 10px; */
  width: 90%;
  /* border: 1px solid #a1cec9; */
  /* background-color: #daedeb; */
  @media (max-height: 960px) {
    font-size: 23px;
    line-height: 65px;
    height: 65px;
    border-radius: 10px;
  }
`;
const ChoiceBox = styled(RowFlex)`
  padding: 8px 15px;
  width: 100%;
`;
const ChoiceOption = styled.div`
  color: #000000;
  background-color: #e8e8f4;
  border: 1px solid #a09de2;
  border-left: none;
  border-radius: 30px;
  height: 60px;
  font-size: 30px;
  width: 100%;
  line-height: 60px;
  padding-left: 30px;
  cursor: pointer;
  @media (max-height: 960px) {
    font-size: 25px;
    line-height: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;

const LeftBody = styled.div`
  width: 55%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
`;

const TextBox = styled.div`
  flex: 1;
  border: 2px #c2e5df solid;
  height: 50%;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  color: #797979;
  flex: 1;
  background-color: white;
  border-radius: 15px;
  overflow: auto;
`;

const Paginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* overflow: auto; */
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #daedeb;
`;

const PageButton = styled(RowFlex)`
  cursor: pointer;
  width: 2em;
  height: 2em;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 1.3em;
  font-weight: bold;
  color: ${(props) => props.color};
  background: ${(props) => props.back};
  border: 2px solid ${(props) => (props.isPage ? props.color : 'none')};
  margin: 0 10px;
`;
const ArrowIcon = styled.div`
  width: 23px;
  min-width: 23px;
  height: 20px;
  min-height: 20px;
  background-image: url(${(props) => props.src});
  background-size: 23px 20px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #fff;
  background: #ed7d31;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const LoadingScreen = styled.div`
  height: 100%;
  background: #ffffff88;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightBody = styled(ColumnFlex)`
  width: 45%;
  padding: 0 20px;
  height: 85%;
  justify-content: space-between;
`;
const InfoBox = styled(ColumnFlex)`
  color: #000000;
  align-items: center;
  font-weight: 600;
  border-radius: 15px;
  background-color: #daedeb;
  font-size: 25px;
  padding-bottom: 20px;
  `;
const InfoInnerBox = styled(ColumnFlex)`
  align-items: center;
  background-color: #ffffff;
  font-size: 25px;
  border-radius: 15px;
  width: 85%;
  padding: 20px 0;
`;
const InfoHead = styled.div`
  text-align: center;
  color: #000000;
  padding: 15px 0;
  font-size: 22px;
`;
const InfoContent = styled(RowFlex)`
  color: ${(props) => props.color};
  justify-content: space-between;
  align-items: center;
  padding: 15px 45px;
  width: 80%;
`;
const InfoNumber = styled.div`
  color: ${(props) => (props.color ? props.color : '#3e3a39')};
  border: 2px solid #b5b5b7;
  border-radius: 4px;
  padding: 5px;
  margin: 0 5px;
  background-color: #ffffff;
  width: 70px;
  text-align: center;
`;
const SoundBarWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0 15px;
`;
const SoundBar = styled.input`
  width: 90%;
  margin: 1rem;


  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  ::-webkit-slider-runnable-track {
    height: 8px;
    background: #106b5c;
    border: 1px solid #fff;
    background-size: var(--background-size, 0%) 100%;
    background-repeat: no-repeat;
    border-radius: 8px;
  }
  ::-webkit-slider-thumb {
    background-color:#49ada1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    /* background: #ccc; */
    border-radius: 50%;
    margin-top: -5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  }
  :-moz-range-progress {
    background-color: #293043;
    border-radius: 5px;
  }
  ::-moz-range-track {
    background-color: #d7d7d7;
    border-radius: 5px;
  }
  :-moz-range-thumb {
    width: 15px;
    height: 15px;
    cursor: pointer;
    background: #293043;
    border: solid white 1px;
    border-radius: 50%;
    margin-top: -6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  }
`;
const ButtonWrapper = styled(RowFlex)`
  margin: 10px auto;
  width: 55%;
  justify-content: space-between;
  @media(max-height:900px){
    margin: 0px auto;
  }
`;
const RecordButton = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  width:64px;
  height: 68px;
  background-size: 64px 68px;
  margin: 0 5px;
  cursor: pointer;
  @media(max-height:900px){
    width:55px;
    height: 58px;
    background-size: 55px 58px;
    
  }
`;
const Button = styled.div`
  cursor: pointer;
  border-radius: 20px;
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  background-color: #ff005c;
  box-shadow: 0 2px 1px 2px #bf004e;
  height: 40px;
  padding: 8px 45px;
  @media (max-width: 1280px) {
    height: 32px;
    font-size: 1.2em;
    padding: 6px 25px;
  }
`;
export default function EngStaWPM({ recordData = [], defaultLevel, defaultChatList, idx, onShowRecording }) {
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState(EditorState.createEmpty());
  const [textRest, setTextResp] = useState(EditorState.createEmpty());
  const [currentLessonText, setCurrentLessonText] = useState([]);
  const [firstPageNo, setFirstPageNo] = useState(0);
  const [isMobile, setIsMobile] = useState(useCheckMobileScreen());
  const [doneLessonText, setDoneLessonText] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [currentText, setCurrentText] = useState([]);
  const [lastText, setLastText] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState([]);
  const [startPageIndx, setStartPageIndx] = useState(-1);
  const [sttResp, setSttResp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSize, setImageSize] = useState('100%');
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [recordingData, setRecordignData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [lastBlob, setLastBlob] = useState(null);
  const recordAudio = useRef(null);
  const [blobChunks, setBlobChunks] = useState([]);
  const mainBlockRef = useRef(null);
  const currentSttRequest = useRef([]);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const currentSttText = useRef([]);
  const nextItemRef = useRef(null);
  const [currentProgressSlider, setCurrentProgressSlider] = useState(0);
  const [currentDoneValue, setCurrentDoneValue] = useState(null);
  const [processCallbackLoading, setProcessCallbackLoading] = useState([]);
  const [data, setData] = useState(dummyData);
  const currentTextValue = currentText.map((e) => e.text).join('\n');
  const [apiSuccess, setApiSuccess] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const word_count = recordData.length > 0 ? recordData[currentPage]['text'].split(' ').length : 0;
  return (
    <>
      <Modal
        title={'Match recording'}
        visible={showModal}
        width="100%"
        bodyStyle={{ height: 'calc(100vh - 200px)', overflowY: 'auto', padding: 10 }}
        style={{ top: 20, height: '80%' }}
        closable={true}
        footer={
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Title level={4} style={{ color: 'darkblue', marginTop: '10px', marginLeft: '20px' }}>
                Extra Text
              </Title>
              <Title level={4} style={{ color: 'brown', marginTop: '10px', marginLeft: '20px' }}>
                Missed Text
              </Title>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button type="primary" key="back" style={{ margin: 10 }} onClick={() => setShowModal(false)}>
                Done
              </Button>
              <Title level={3} style={{ margin: 10 }}>
                WPM:
              </Title>
              <Title level={3} style={{ margin: 10 }}>
                Accuracy: %
              </Title>
            </div>
          </div>
        }
        onCancel={() => setShowModal(false)}
      >
        <div style={{ display: 'flex', height: '100%', fontWeight: 600, color: '#383838' }}>
          <div style={{ flex: 1, background: 'beige', marginRight: 10 }}>
            <Title level={3} style={{ textAlign: 'center' }}>
              Content
            </Title>
            <Editor
              editorState={text}
              editorStyle={{
                width: '100%',
                height: 'calc(100% - 20px)',
                backgroundColor: 'beige',
                padding: 10,
                fontSize: '18px',
              }}
              toolbarClassName="hide-print"
              wrapperClassName="essay-wrapper"
              editorClassName="essay-editor"
              readOnly={true}
              toolbarHidden
            />
          </div>
          <div style={{ flex: 1, backgroundColor: '#93babb', fontWeight: 600, color: '#383838' }}>
            <Title level={3} style={{ textAlign: 'center' }}>
              Your Response
            </Title>
            <Editor
              editorState={textRest}
              rootStyle={{ backgroundColor: '#93babb' }}
              editorStyle={{
                width: '100%',
                height: 'calc(100% - 20px)',
                backgroundColor: '#93babb',
                padding: 10,
                fontSize: '18px',
              }}
              style={{ backgroundColor: '#93babb' }}
              toolbarClassName="hide-print"
              wrapperClassName="essay-wrapper"
              editorClassName="essay-editor"
              readOnly={true}
              toolbarHidden
            />
          </div>
        </div>
      </Modal>
      <Wrapper>
        <MainWrapper>
          <Main>
            <Title>
              <TitleSpan color={'#453ec8'}>BOOK Title</TitleSpan>
              <TitleSpan>Volume</TitleSpan>
              <TitleSpan>Unit</TitleSpan>
              <TitleSpan>Lesson</TitleSpan>
              <TitleSpan>Lesson Name</TitleSpan>
            </Title>
            <Body>
              <StageTitle>Fluency / Speed Reading WPM</StageTitle>
              <RowFlex style={{ height: '95%' }}>
                <LeftBody>
                  <TextBox></TextBox>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Paginate>
                      <PageButton
                        color={'#453ec8'}
                        // back={'#ed7d31'}
                        onClick={() => {
                          // if (page > 0) {
                          //   setPage(page - 1);
                          // }
                        }}
                      >
                        <ArrowIcon src={'/images/LevelUp/study/sub/arrow_left.png'} />
                      </PageButton>

                      {recordData?.length
                        ? recordData.map((chat, index) => {
                            return (
                              <PageButton
                                key={index}
                                // color={page === index ? '#ffffff' : '#000000'}
                                // back={page === index ? '#ed7d31' : '#ffffff'}
                                // onClick={() => setPage(index)}
                              >
                                {index + 1}
                              </PageButton>
                            );
                          })
                        : ''}

                      <PageButton
                        color={'#453ec8'}
                        // back={'#ed7d31'}
                        onClick={() => {
                          // if (page < recordData.length - 1) {
                          //   setPage(page + 1);
                          // }
                        }}
                      >
                        <ArrowIcon src={'/images/LevelUp/study/sub/arrow_right.png'} />
                      </PageButton>
                    </Paginate>
                  </div>
                </LeftBody>
                <RightBody>
                  <InfoBox>
                    <InfoHead>WPM / Accuracy</InfoHead>
                    <InfoInnerBox>
                      <InfoContent color={'#fd005b'}>
                        <span>Word Count </span>
                        <InfoNumber color={'#fd005b'}>80</InfoNumber>
                      </InfoContent>
                      <InfoContent color={'#010101'}>
                        <span>WPM </span>
                        <InfoNumber>112</InfoNumber>
                      </InfoContent>
                      <InfoContent color={'#010101'}>
                        <span>Accuracy </span>
                        <InfoNumber color={'#6e39bd'}>98</InfoNumber>
                      </InfoContent>
                    </InfoInnerBox>
                    <SoundBarWrapper>
                      <SoundBar type="range" min={0} max={100} step={1} value={currentProgressSlider} />
                    </SoundBarWrapper>
                    <ButtonWrapper>
                      <RecordButton url={'/images/LevelUp/study/wpm/record.png'} width={'64px'} height={'68px'} />
                      <RecordButton
                        url={playing ? '/images/LevelUp/study/wpm/pause.png' : '/images/LevelUp/study/wpm/play.png'}
                        width={'64px'}
                        height={'68px'}
                      />
                      <RecordButton url={'/images/LevelUp/study/wpm/upload.png'} width={'64px'} height={'68px'} />
                    </ButtonWrapper>
                  </InfoBox>
                  <RowFlex style={{ justifyContent: 'center', alignItems: 'center', margin: '0 auto', padding: '20px 0 5px' }}>
                    <Button onClick={{}}>Results</Button>
                  </RowFlex>
                </RightBody>
              </RowFlex>
            </Body>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
