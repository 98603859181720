import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Button, Modal, message } from 'antd';
import { ScanOutlined } from '@ant-design/icons';
import { withRouter, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { QrReader } from 'react-qr-reader';
import { ASSIGNED_TEST_SINGLE } from 'src/operations/queries/getTests';
import { userInfoVar } from 'src/apollo/cache';

const TestBlock = styled.div`
  margin-top: 12px;
`;

const TestSectionBlock = styled.div`
  background: #fff;
`;

const QrScanner = (props) => {
  const [open, setOpen] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [test, setTest] = useState(null);
  const lastScanId = useRef(null);

  const history = useHistory();

  const [load, { data }] = useLazyQuery(ASSIGNED_TEST_SINGLE, {
    fetchPolicy: 'no-cache',
  });

  const onScan = (data, error) => {
    if (!!error) {
      handleError(error);
    }

    if (!data) {
      return;
    }

    let value = data?.text ? JSON.parse(data.text) : data;

    if (value.type !== 'assigned_test') {
      message.error('Invalid QR Code');
      return;
    }
    if (value && value.id) {
      if (lastScanId.current === value.id) {
        return;
      }
      lastScanId.current = value.id;
      setScanned(true);
      load({ variables: { idx: parseInt(value.id) } });
    }
  };

  useEffect(() => {
    if (data && data.assignedTestsSingle && data.assignedTestsSingle.idx) {
      let user = data.assignedTestsSingle.assigned_test_users.reduce((acc, curr) => {
        if (curr.user.idx === parseInt(userInfoVar().idx)) {
          acc = curr;
        }
        return acc;
      }, null);
      if (user) {
        setTest(user);
      } else {
        message.error('You are not assigned to this test');
      }
    }
  }, [data]);

  useEffect(() => {
    if (test) {
      setTimeout(() => {
        history.push(`/test/new/${data.assignedTestsSingle?.idx}/take`);
      }, 1000);
    }
  }, [test]);

  const handleError = (err) => {
    if (err.toString().includes('NotAllowedError') || err.toString().includes('NotFoundError')) {
      message.error('Check your camera permission');
    }
    console.log(err);
  };

  return (
    <>
      <ScanOutlined style={{ fontSize: '1.6em', color: '#08c', verticalAlign: 'middle' }} onClick={() => setOpen(true)} />

      {open && (
        <Modal
          title="QR Scanner"
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          footer={null}
          bodyStyle={{ minHeight: '500px' }}
        >
          <div style={{ width: '100%', height: '100%', minHeight: '350px' }}>
            <QrReader onResult={onScan} style={{ width: '100%' }} constraints={{ facingMode: 'environment' }} />
          </div>
          {scanned && (
            <Row gutter={[24, 16]} style={{ justifyContent: 'space-around' }}>
              {test ? (
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(`/test/new/${data?.assignedTestsSingle?.idx}/take`);
                  }}
                >
                  Go to Test
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setTest(null);
                    lastScanId.current = null;
                    setScanned(false);
                  }}
                >
                  Scan Again
                </Button>
              )}
            </Row>
          )}
        </Modal>
      )}
    </>
  );
};

export default withRouter(QrScanner);
