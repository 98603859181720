import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Col, Row, Radio,Space, Input, DatePicker, Typography } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CustomTable from 'src/components/common/CustomTable';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { userInfoVar } from 'src/apollo/cache';
import PhonicsResults from "src/constants/PhonicsResults";
import LearningMenu from 'src/components/Phonics/LearningMenu';
import * as queries from 'src/operations/queries';
import PhonicsMenuGroup from 'src/components/common/PhonicsMenuGroup';
import PhonicsResultMenuGroup from 'src/components/common/PhonicsResultMenuGroup';
import PhonicsNoticePopup from 'src/popups/PhonicsNoticePopup';
const { Title } = Typography;
const columns = [
  /*{
      title: 'Code',
      dataIndex: 'idx',
      key: 'No',
      align: 'left',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{record.book_idx}</Space>;
      },
    },*/
    {
      title: 'No',
      dataIndex: 'idx',
      key: 'No',
      align: 'left',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{record.no}</Space>;
      },
    },
    {
      title: '학습일',
      dataIndex: 'start_date',
      key: 'No',
      align: 'left',
      width: 120,
      render: (text, record) => {
        return <Space size="middle">{record.start_date}</Space>;
      },
    },
    {
      title: '반명',
      dataIndex: 'class_name',
      key: 'No',
      align: 'left',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{record.class_name}</Space>;
      },
    },
    {
      title: 'Level',
      dataIndex: 'level',
      align: 'left',
      key: 'level',
      width: 60,
      render: (text, record) => {
        return <Space size="middle">{record.level}</Space>;
      },
    },
    {
      title: 'Lesson',
      dataIndex: 'idx',
      key: 'No',
      align: 'left',
      width: 70,
      render: (text, record) => {
        let recordbookcode = (record.book_idx).split("");
        return <Space size="middle">{recordbookcode[recordbookcode.length-1].toString()}</Space>;
      },
    },
    {
      title: 'Unit Title',
      dataIndex: 'idx',
      key: 'No',
      align: 'left',
      width: 250,
      render: (text, record) => {
        return <Space size="middle">{record.unit_title}</Space>;
      },
    },
  {
    key: 'complete',
    title: '완료/총학생',
    dataIndex: 'complete',
    align: 'center',
    render: (text, record) => {
        return <Space size="middle">{record.all_data.students_completed}/{record.all_data.totals_students}</Space>;
    }
  },
  {
    key: 'view',
    title: '확인',
    dataIndex: 'view',
    align: 'center',
    render: (text, record) => {
      return (
        <Link to={{ pathname: `/learningschedule/results/${record.book_idx}/${record.start_date}/${record.class_idx}` }}>
          <Button>View</Button>
        </Link>
      );
    },
  },
];

const Result = () => {
  const [results, setResult] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [localState, setLocalState] = UseHistoryState({
    page: 1,
    date: '',
    search: "",
    type: 1
  });
  const PER_PAGE = 10;
  const {
    data: fetchAssignedPhonicsStudentView,
    refetch: assginedPhonicsRefetch,
    loading,
  } = useQuery(queries.phonicsUnitList.FETCH_ASSIGNED_PHONICS_STUDENT_VIEW, {
    fetchPolicy: 'no-cache',
    variables: { 
      input: "phonicsstudentlist" ,
      date: localState.date,
      page: localState.page,
      search: localState.search,
      take: PER_PAGE,
      type: localState.type
    },
  });
  // filter results when search data changes
  useEffect(() => {
    let newresults = fetchAssignedPhonicsStudentView?.AssignedPhonicsStudentView?.user_lessons;
    let loopresult = fetchAssignedPhonicsStudentView?.AssignedPhonicsStudentView?.user_lessons.map((loopitem,loopkey)=>{
      loopitem["no"] = loopkey+1;
      return  loopitem;
    })
    setResult(loopresult);    
  }, [fetchAssignedPhonicsStudentView, localState]);
  const menuType  = useSelector(state => state.learningMenu.menu_type);
  const title = useSelector(state => state.learningMenu.title);
  const handleSearch = (e) => {
    setLocalState((prev) => {
      return {
        ...prev,
        page:1,
        search: e.target.value,
      };
    });
  }
  function handleDateSelect(date, dateString) {
      setLocalState((prev) => {
        return {
          ...prev,
          page:1,
          date: dateString,
        };
      });
  }
  const handleStatusChange = (e) => {
    setLocalState((prev) => {
      return {
        ...prev,
        page:1,
        type: Number(e.target.value)
      };
    });
  }
  const handleTableChange = (pagination, filters, sorter) => {
    setLocalState((prev) => {
      return {
        ...prev,
        page: pagination.current
      };
    });
  };
  return (
    <>
    <PhonicsNoticePopup/>
      <div className="learning-homework-res">
        
        <HeaderTitle className="learning-homework-haeding" level={4}>
          <LearningMenu />
        </HeaderTitle>
        {
            menuType.length > 0 && 
            <>
            <PhonicsMenuGroup currentMenu="result" />
            <PhonicsResultMenuGroup currentMenu="date" />
            </>
        }
      </div>
      
      <Row gutter={[16, 16]}>
      <Col className="orderadmin-top-title" span={8} style={{ textAlign: 'left' }}>
        {menuType.length > 0 && <Title level={5}>{title} Result</Title>}
        </Col>
        <Col span={24}>
          <HalfWrapper className="homeworkresult-halfwrapper resultpages-halfwrapper">
          { menuType.length > 0 && 
            <div className="learning-homework-top-inner" style={{textAlign: 'right'}}>
              <Input
                placeholder="Search"
                onChange = {handleSearch}
                value= {localState.search}
                style={{ width: '150px' }}
              />
              <Radio.Group value={localState.type.toString()} onChange={handleStatusChange} style={{ marginLeft: '10px' }}>
                <Radio.Button value="0">전체</Radio.Button>
                <Radio.Button value="1">진행</Radio.Button>
                <Radio.Button value="2">완료</Radio.Button>
              </Radio.Group>
              <DatePicker onChange={handleDateSelect} value={localState.date && moment(localState.date)} style={{ marginLeft: '10px' }}/>
            </div>
          }
            <div
              className="homeworkresult-halfwrapper-dv"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              
            </div>
            {
              menuType == 'phonics' && 
              <CustomTable
                dataSource={results}
                columns={columns}
                onChange={handleTableChange}
                pagination={{
                  showSizeChanger: false,
                  pageSize: PER_PAGE,
                  current: localState.page,
                  total: fetchAssignedPhonicsStudentView?.AssignedPhonicsStudentView?.total || 0,
                }}
                size="small"
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
              />
            }
            {
              menuType == '' && <h2 style={{textAlign:"center"}}> Please select an option</h2>
            }
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Result;
