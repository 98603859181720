import React, { useState, useEffect, useMemo } from 'react';
import SoundEffectPM from './SoundEffectPM';
import PageNum from './PageNum';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const ImageSentectWord = ({ steps, problem, onCorrect, onWrong, directionEnd }) => {
  const [isCorrect, setIsCorrect] = useState(null);
  const [wordSound, setWordSound] = useState();
  const [canSelectAnswer, setCanSelectAnswer] = useState(true);
  const answerWord = useMemo(() => {
    if (problem[steps].words) {
      for (let i = 0; i < problem[steps].words.length; i++) {
        if (problem[steps].words[i].isAnswer) {
          return problem[steps].words[i].name;
        }
      }
    }
    return undefined;
  }, [problem, steps]);

  const handleClick = (correct) => {
    setIsCorrect(correct);
  };
  const handleWordSoundEnd = () => {
    setWordSound(undefined);
    if (_.isNil(problem[steps].sound)) {
      onCorrect();
    }
  };
  useEffect(() => {
    if (!_.isNil(isCorrect)) {
      if (isCorrect) {
        if (_.isNil(problem[steps].sound)) {
          setWordSound(`https://cdn.cloubot.com/PM/audio/sounds/${answerWord}.mp3`);
        } else {
          onCorrect();
        }
        setCanSelectAnswer(false);
      } else if (canSelectAnswer) {
        onWrong();
        setIsCorrect(null);
      }
    }
  }, [isCorrect]);
  useEffect(() => {
    setIsCorrect(null);
    if (!_.isNil(problem[steps].sound) && directionEnd) {
      setWordSound(problem[steps].sound);
    }
    setCanSelectAnswer(true);
  }, [steps, directionEnd]);
  return (
    <>
      <S.ColumnFlex
        style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
        id={`${problem.length > 2 ? 'lv4_review6_content' : 'lv3_unit10_review3_content'}`}
        className={`main_content ${problem.length > 2 ? 'lv4_review6_content row2' : ''}`}
      >
        {problem.length > 2 ? (
          <TopBox className="top_box">
            <div className="img_box">
              <PageNum
                num={steps + 1}
                total={problem.length}
                style={{ top: '10px', position: 'absolute', right: '1rem', fontSize: '1.3rem', fontWeight: 'bold' }}
              />
              <img style={{ height: '30vh' }} src={problem[steps].image} alt="단어이미지" />
            </div>
          </TopBox>
        ) : (
          <div className="img_box">
            <img src={problem[steps].image} alt="단어이미지" />
            <PageNum
              num={steps + 1}
              total={problem.length}
              style={{ top: '10px', position: 'absolute', right: '1rem', fontSize: '1.3rem', fontWeight: 'bold' }}
            />
          </div>
        )}
        <S.ImageSelectBtnBox className="btn_box">
          {problem[steps].words &&
            problem[steps].words.map((item, key) => (
              <S.ImageSelectBtnLi key={`word-list-${key}`}>
                <S.ImageSelectBtn
                  type="button"
                  style={{ borderColor: isCorrect && item.isAnswer && '#ffb000' }}
                  className={`${isCorrect && item.isAnswer ? 'active' : ''}`}
                  onClick={() => {
                    handleClick(item.isAnswer);
                  }}
                >
                  {item.name}
                </S.ImageSelectBtn>
              </S.ImageSelectBtnLi>
            ))}
        </S.ImageSelectBtnBox>
      </S.ColumnFlex>
      {<SoundEffectPM url={wordSound} onEnd={handleWordSoundEnd} />}
    </>
  );
};

export default ImageSentectWord;
const TopBox = styled(S.ImageSelectBottomBox)`
  height: 75%;
  text-align: center;
  justify-content: center;
  position: relative;
  flex: none;
  @media (max-height: 500px){
	  height: 65%;
}
`;
