import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
const LevelUpExitPopUp = ({ visible, setVisible, idx,assigned_idx=null }) => {
  const history = useHistory();
  const [modal, contextHolder] = Modal.useModal();
  const handleOK = () => {
    setVisible(false);
    history.push(`/level-up/study/${idx}`,{ assigned_idx: assigned_idx });
  };
  const confirm = () => {
    modal.confirm({
      title: 'Exit Stage',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to exit the stage? Your study data will not be saved.',
      okText: 'OK',
      onOk: handleOK,
      onCancel: () => {
        setVisible(false);
      },
      cancelText: 'Cancel',
    });
  };
  useEffect(() => {
    if (visible) {
      confirm();
    }
  }, [visible]);

  return <>{contextHolder}</>;
};
export default LevelUpExitPopUp;
