import React from 'react';
import { Row } from 'antd';
import { withRouter } from 'react-router';
import  StarWordsSingleEdit  from './StarWordsSingleEdit';

const ManageSingleEdit = (props) => {



  return (
    <>
      <Row gutter={[24, 16]}><StarWordsSingleEdit /></Row>
    </>
  );
};

export default withRouter(ManageSingleEdit);
