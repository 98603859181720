import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Table, Input } from 'antd';
import styled from 'styled-components';
import ClassSelectBox from 'src/components/common/ClassSelectBox';
import { userInfoVar } from 'src/apollo/cache';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CLASS_STUDENT_LIST_FOR_SMS } from 'src/operations/queries/getClass';
import { GET_STUDENT_USERS_FOR_SMS } from 'src/operations/queries/getUser';
import { getHumanReadablePhoneNumber } from 'src/utils/index';
import { isAfterSchool } from 'src/utils/index';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const ReceiverTable = styled(Table)`
  .ant-table-body {
    height: 440px;
  }
`;

const SmsSendGroup = ({
  selectedClassIdx,
  onClassChange,
  rowSelection,
  isDirectInput,
  setIsDirectInput,
  destInfoListObj,
  onPhoneNumberChange,
  setInputMessage,
  setTemplatecode,
  isTalk,
  dataTalkTxt,
}) => {
  const campus_idx = userInfoVar()?.campus_idx;
  const [fetchClassStudent, { data, loading }] = useLazyQuery(CLASS_STUDENT_LIST_FOR_SMS);
  const [fetchAllStudent, { data: dataAll, loading: loadingAll }] = useLazyQuery(GET_STUDENT_USERS_FOR_SMS, { variables: { type: '0' } });
  const companyName = useSelector(classStoreData);
  useEffect(() => {
    fetchAllStudent();
  }, []);
  // const savedMessages = useMemo(() => {
  //   if (dataTalkTxt) {
  //     return dataTalkTxt.map((item) => item);
  //   }
  //   return [];
  // }, [dataTalkTxt]);
  const handleAfterSchoolMSG = (num) => {
    const tmp = dataTalkTxt[num];
    console.log(tmp?.txt, tmp?.code);
    setTemplatecode(tmp.code||'');
    setInputMessage(tmp.txt||'');
  };
  const smsGroupCol_data = useMemo(() => {
    const list = selectedClassIdx
      ? data?.classStudentList
      : dataAll?.getUsers?.map((item) => {
          const { idx, name, phone, parent_phone } = item;
          return {
            user: { idx, name, phone, parent_phone },
          };
        });

    if (list) {
      return list.map((item) => {
        const {
          user: { idx, name, phone, parent_phone },
        } = item;
        //console.log('name', name);
        return {
          key: `user-${idx}`,
          user_idx: idx,
          Name: name,
          phoneNum: parent_phone || '',
        };
      });
    }
    return [];
  }, [data, dataAll, selectedClassIdx]);
  const smsDirectInputData = useMemo(() => {
    return Array(10)
      .fill(0)
      .map((_, key) => {
        return {
          key: `user-${key}`,
          idx: key,
          Name: `받는사람 ${key + 1}`,
          phoneNum: '1234',
          isDirectInput,
          onPhoneNumberChange,
          destInfoListObj,
        };
      });
  }, [isDirectInput, onPhoneNumberChange, destInfoListObj]);

  function handleClassChange(idx) {
    onClassChange(idx);
    if (idx === 0) {
      fetchAllStudent();
    } else {
      fetchClassStudent({ variables: { class_idx: idx } });
    }

    setIsDirectInput(false);
  }
  function handleRemoveReceiverList(e) {
    e.preventDefault();
    handleClassChange(0);
  }

  function handleDirectInput(e) {
    e.preventDefault();
    handleClassChange(0);
    setIsDirectInput(true);
  }

  return (
    <>
      <ReceiverTable
        //className="SmsSendGroup_Wrap"
        rowSelection={isDirectInput ? false : { type: 'checkbox', ...rowSelection }}
        //dataSource={smsGroupCol_data}
        dataSource={isDirectInput ? smsDirectInputData : smsGroupCol_data}
        columns={smsGroupCol}
        pagination={false}
        loading={loading || loadingAll}
        scroll={{ y: '440px' }}
      />

      <div className="smsGroup_Btn_Wrap" style={{ textAlign: 'center' }}>
        <Row className="smsGroup_Btn_Row">
          <Col span={12} style={{ background: 'transparent', border: 'none' }}>
            <div className="smsGroup_Btn">
              {/* <Select className="toSendGrp" defaultValue="반 불러오기" style={{ textAlign: 'center' }}>
                <Option value="Group 1">Group 1</Option>
                <Option value="Group 2">Group 2</Option>
                <Option value="Group 3">Group 3</Option>
              </Select> */}
              <ClassSelectBox
                className="toSendGrp"
                firstRowDisplayValue="전체 반"
                //message={true}
                onClassChange={handleClassChange}
                selectedClassIdx={selectedClassIdx}
                campus_idx={campus_idx}
                style={{ width: '100%' }}
              />
            </div>
          </Col>

          <Col span={12}>
            <div className="smsGroup_Btn">
              <a href="#none" onClick={handleRemoveReceiverList}>
                초기화
              </a>
            </div>
          </Col>
          {isAfterSchool(companyName) && isTalk && dataTalkTxt.length > 0 && (
            <>
              <Col span={12}>
                <div className="smsGroup_Btn" onClick={()=>handleAfterSchoolMSG(1)}>
                  <a href="#none">출석</a>
                </div>
              </Col>
              <Col span={12}>
                <div className="smsGroup_Btn"  onClick={()=>handleAfterSchoolMSG(0)}>
                  <a href="#none">결석</a>
                </div>
              </Col>
              <Col span={12}>
                <div className="smsGroup_Btn" onClick={()=>handleAfterSchoolMSG(5)}>
                  <a href="#none">지각</a>
                </div>
              </Col>
            </>
          )}
          <Col span={12}>
            <div className="smsGroup_Btn" onClick={handleDirectInput}>
              <a href="#none">직접 입력</a>
            </div>
          </Col>

          {/* <Col span={12}>
            <div className="smsGroup_Btn">
              <a href="#none">최근 보낸 기록</a>
            </div>
          </Col> */}
        </Row>
        {/* <Row className="smsGroup_Btn_Row">
          <Col span={12}>
            <div className="smsGroup_Btn">
              <a href="#none">주소록에 번호저장</a>
            </div>
          </Col>

          <Col span={12}>
            <div className="smsGroup_Btn">
              <a href="#none" onClick={handleRemoveReceiverList}>
                목록지우기
              </a>
            </div>
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default SmsSendGroup;

const smsGroupCol = [
  // {
  //   title: '',
  //   dataIndex: 'choose',
  //   key: 'choose',
  //   align: 'center',
  // },
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'Name',
    align: 'center',
    width: 125,
  },
  {
    title: 'Ph.Number',
    dataIndex: 'phoneNum',
    key: 'phoneNum',
    align: 'center',
    render: (text, record) => {
      if (record.isDirectInput) {
        const input_value = record?.destInfoListObj[record.idx] || '';
        return (
          <Input
            style={{ margin: 0, width: '90%' }}
            maxLength={11}
            onChange={(e) => record.onPhoneNumberChange(record.idx, e.target.value?.replace(/[^0-9]+/gi, ''))}
            value={input_value}
            placeholder="번호만 입력"
          />
        );
      } else {
        return getHumanReadablePhoneNumber(text);
      }
    },
  },
];
