import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Row, Typography, Modal, Button, DatePicker, Radio,Input } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ClassRoomModal from 'src/components/Modal/ClassRoomModal';
import ClassRoomModalForPturn from 'src/components/Modal/ClassRoomModalForPturn';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import ClassAndMemberMenuGroup from 'src/components/common/ClassAndMemberMenuGroup';

import UseHistoryState from 'src/components/common/UseHistoryState';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek, getMonthDateRange, checkIfCampusUser } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { isPturn } from 'src/utils';
const { Title, Text } = Typography;
const { confirm } = Modal;

const options = [
  { label: '진행중', value: true },
  { label: '종료', value: false },
];

function ClassRoomManager() {
  const userType = userInfoVar()?.type;
  const [classList, setClassList] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  // const [inProgress, setInProgress] = useState(true);
  const [totalCurrentStudyingStudent, setTotalCurrentStudyingStudent] = useState(0);
  var company_name = userInfoVar()?.company_name;

  const { visible, showModal, handleCancel, popupMode } = useModal();

  const [localState, setLocalState] = UseHistoryState({
    page: 1,
    startDate: '',
    endDate: '',
    searchValue: '',
    className: '',
    teacher: '',
    start_date: '',
    end_date: '',
    searchClassName: '',
    state:true,
  });

  const {
    data,
    loading: getClassLoading,
    refetch,
  } = useQuery(queries.getClass.GET_TYPE_CLASS, {
    fetchPolicy: 'no-cache',
    variables: {
      start: localState.startDate,
      end: localState.endDate,
    },
  });
  const [deleteClass] = useMutation(mutations.classRoom.DELETE_CLASS, {
    onCompleted(data) {
      if (data) {
        openNotification('삭제 완료!');
        refetch();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });
  useEffect(() => {
    if (data) {
      const fileteredList = data.classList
        .reduce((acc, cur, index) => {
          const dayOfWeek = customDayOfWeek(cur);
          const obj = {
            key: cur.idx,
            No: data.classList.length-index,
            className: cur.name,
            bookInfo: cur?.book?.book_sub[0]?.title || cur.book_code || cur?.book_title,
            studentCount: `${cur?.class_student.filter((item) => item.stat === '1').length}명`,
            student_count_number: cur?.class_student.filter((item) => item.stat === '1').length || 0,
            teacher: cur.class_teacher[0]?.user?.name,
            yoil: dayOfWeek,
            time: !cur.begin_time || !cur.end_time ? '종일' : `${cur.begin_time} ~ ${cur.end_time}`,
            start_date: cur?.begin_date && moment(cur?.begin_date).format('YYYY-MM-DD'),
            end_date: cur?.end_date && moment(cur?.end_date).format('YYYY-MM-DD'),
          };
          return [...acc, obj];
        }, [])
        .filter((item) => {
          if (!localState.state) {
            if (item.end_date && moment().isAfter(item.end_date, 'day')) {
              return true;
            }
            return false;
          } else {
            if (!item.end_date || !moment().isAfter(item.end_date, 'day')) {
              return true;
            }
            return false;
          }
        })
        .map((item, key) => ({ ...item })).filter((list)=>{
          if (localState.searchClassName) {
            return list.className.toLowerCase().indexOf(localState.searchClassName.toLowerCase()) !== -1;
          }
          return true;
        });
      if (localState.state) {
        setTotalCurrentStudyingStudent(
          fileteredList.reduce((acc, cur) => {
            return acc + cur.student_count_number;
          }, 0),
        );
      }
      setClassList(fileteredList);
    }
    console.log('inProgress', localState.state);
  }, [data, localState.state,localState.searchClassName]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const handleYearMonthSelect = (date, dateString) => {
    const { start, end } = getMonthDateRange(dateString);

    setLocalState((prev) => {
      return {
        page: 1,
        startDate: start,
        endDate: end,
        searchValue: dateString,
      };
    });
  };

  const handleClassList = () => {
    setLocalState((prev) => {
      return {
        page: 1,
        startDate: '',
        endDate: '',
        searchValue: '',
      };
    });
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '해당 반 및 관련된 데이터가 전부 삭제됩니다. 정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteClass({
            variables: {
              class_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 반을 선택해 주세요.',
      okText: '확인',
    });
  }

  function handleTableChange(pagination, filters, sorter) {
    console.log('sorter', sorter);
    setLocalState((prev) => {
      return {
        ...prev,
        page: pagination.current,
        [sorter.field]: sorter.order,
      };
    });
  }
  const handleStateChange = (e) => {
    setLocalState((prev) => {
      return {
        ...prev,
        state: e.target.value,
        pageNumber: 0,
      };
    });
  };
  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
    },
    {
      title: 'Class(반명)',
      dataIndex: 'className',
      key: 'className',
      defaultSortOrder: localState.className,
      align: 'center',
      sorter: (a, b) => a.className.localeCompare(b.className),
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '교재정보',
      dataIndex: 'bookInfo',
      key: 'bookInfo',
      align: 'center',
    },
    {
      title: '학생수',
      dataIndex: 'studentCount',
      key: 'studentCount',
      defaultSortOrder: localState.studentCount,
      align: 'center',
      sorter: (a, b) => a.studentCount.localeCompare(b.studentCount),
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '강사',
      dataIndex: 'teacher',
      key: 'teacher',
      align: 'center',
      defaultSortOrder: localState.teacher,
      sorter: (a, b) => a.teacher && a.teacher.localeCompare(b.teacher),
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '요일',
      dataIndex: 'yoil',
      key: 'yoil',
      align: 'center',
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: '기간',
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      defaultSortOrder: localState.start_date,
      sorter: (a, b) => a.start_date && a.start_date.localeCompare(b.start_date),
      render: (text, record) => `${record.start_date || ''} ~ ${record.end_date || ''}`,
    },
    {
      title: '상세보기',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button>
          <Link to={`/classroom/${text.key}`}>View </Link>
        </Button>
      ),
    },
  ];

  return (
    <>
      <ClassAndMemberMenuGroup currentMenu="class" />
      <HeaderTitle level={4}>반관리</HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY: 'auto' }}>
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                Class List
              </Title>
              <div className="header-classroom-search">
                <span level={6} style={{ paddingRight: '10px' }} className="tblclass-list-height-a">
                  수강생 : {totalCurrentStudyingStudent}명
                </span>
                <span style={{ paddingRight: '10px' }} className="tblclass-list-height-b">
                  <Radio.Group
                    options={options}
                    optionType="button"
                    value={localState.state}
                    buttonStyle="solid"
                    onChange={handleStateChange
                      // (e) => {setInProgress(e.target.value);}
                  }
                  />
                </span>
                <Input
                  placeholder="반명 검색"
                  value={localState.searchClassName}
                  onChange={(e) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        searchClassName: e.target.value,
                      };
                    });
                  }}
                  style={{ width: '150px', marginRight:10 }}
                />
                <DatePicker
                  className="tblclass-list-height-c"
                  picker="month"
                  placeholder="전체"
                  onChange={handleYearMonthSelect}
                  value={localState.searchValue !== '' ? moment(localState.searchValue) : ''}
                />
                <CustomButton
                  className="tblclass-list-height-d"
                  onClick={() => handleClassList()}
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  <RedoOutlined />
                </CustomButton>

                <CustomButton style={{ marginRight: 10, border: '1px solid #52c419' }} onClick={() => showModal('create')}>
                  <PlusOutlined style={{ color: '#52c419' }} />
                  <Text type={'success'}>등록</Text>
                </CustomButton>
                {checkIfCampusUser(userType) && (
                  <>
                    <CustomButton
                      danger
                      style={{
                        marginRight: 10,
                      }}
                      onClick={deleteConfirm}
                    >
                      삭제
                    </CustomButton>
                  </>
                )}
              </div>
            </div>

            <CustomTable
              // className="classroom-table-table"
              className={company_name === "lucid"?"classroom-table-table common-class-bg":"classroom-table-table"}
              loading={getClassLoading}
              dataSource={classList}
              columns={columns}
              rowSelection={
                checkIfCampusUser(userType) && {
                  type: 'checkbox',
                  ...rowSelection,
                }
              }
              pagination={{
                pageSize: 10,
                current: localState.page,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && isPturn(company_name)?<ClassRoomModalForPturn visible={visible} popupMode={popupMode} handleCancel={handleCancel} refetch={refetch} />:<ClassRoomModal visible={visible} popupMode={popupMode} handleCancel={handleCancel} refetch={refetch} company_name={userInfoVar()?.company_name}/>}
    </>
  );
}

export default ClassRoomManager;
