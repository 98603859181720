import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Row, Button, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_USER_ASSIGNMENT_LIST } from 'src/operations/queries/aiTopic';
import { GET_TYPE_CLASS } from 'src//operations/queries/getClass';
import { useQuery } from '@apollo/client';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import BookReportAnswerModal from 'src/components/Modal/BookReportAnswerModal';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  min-height: 85vh;
`;

const StudentResults = (props) => {
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');

  const [classes, setClasses] = useState([]);
  const [assigned_Users, setAssignedUsers] = useState([]);
  const [class_idx, setClassIdx] = useState(null);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [currentChatList, setCurrentChatList] = useState([]);
  const [topic, setTopic] = useState();
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [topicAnswer, setTopicAnswer] = useState('');

  const { data, refetch } = useQuery(AI_TOPIC_USER_ASSIGNMENT_LIST, {
    variables: { class_idx, page, take, type: 'book-report' },
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
  }, [classQuery]);

  useEffect(() => {
    if (data?.aiTopicUserAssignmentList) {
      setAssignedUsers(
        data?.aiTopicUserAssignmentList.ai_topic_user_assignments.map((item, index) => {
          return {
            ...item,
            no: index + 1,
            start_date: data?.aiTopicDateAssignmentDetails?.start_date,
            ai_request_responses: item.ai_request_responses.map((item) => {
              return { ...item, promt: item.prompt_text, content: item.completion_text, data: JSON.parse(item.data), created: item.idate };
            }),
          };
        }),
      );
      setTotal(data?.aiTopicUserAssignmentList.total);
    }

    if (isRefresh) {
      refetch();
      props.client.clearStore();
      props.client.cache.gc();
      props.history.replace({ search: '' });
    }
  }, [data, isRefresh, props, refetch]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 50,
    },
    {
      title: 'Book',
      dataIndex: 'book',
      key: 'book',
      align: 'center',
      width: 300,
      render: (text, record) => {
        const data = record.ai_topic?.data ? JSON.parse(record.ai_topic?.data) : {};
        return <Space size="middle">{data?.book?.title || '-'}</Space>;
      },
    },
    {
      title: '주제',
      dataIndex: 'topic',
      key: 'topic',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic.topic}</Space>;
      },
    },
    {
      title: '날짜',
      dataIndex: 'date',
      align: 'center',
      key: 'date',
      width: 100,
      sorter:(a, b) => a.ai_topic_date_assignment.start_date && a.ai_topic_date_assignment.start_date.localeCompare(b.ai_topic_date_assignment.start_date),
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic_date_assignment.start_date.substr(0, 10)}</Space>;
      },
    },
    {
      title: '상태',
      dataIndex: 'status',
      width: 120,
      key: 'status',
      align: 'center',
      render: (text, { status }) => {
        return <Space size="middle">{status === 0 ? '시작하지 않음' : status === 1 ? '시작함' : status === 2 ? '답변함' : '적합함'}</Space>;
      },
    },
    {
      title: 'Original',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  if (record.ai_request_responses.length > 0) {
                    setCurrentChatList([
                      {
                        ...record.ai_request_responses[0],
                        content: record.ai_request_responses[0]?.data?.currentText,
                      },
                    ]);
                  }

                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Check',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList(record.ai_request_responses.filter((item) => item?.data?.type === 'check'));
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Rewrite',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setTopicAnswer(record.answer);
                  setCurrentChatList([
                    {
                      content: record.answer,
                      prompt: record.answer,
                      idate: record.idate,
                      data: '{}',
                    },
                  ]);
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Pt/Wpm',
      key: 'wpm',
      dataIndex: 'wpm',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button onClick={() => openRecordingDataModal({ ...record, accuracy: `${record.score}%` }, record?.user?.name)}>
                {text}
              </Button>
            </Space>
          );
        }
      },
    },
  ];

  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Col className="contenttest-wrapper" span={18} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock>
          <Row gutter={[24, 16]}>
            <Col className="contenttest-title-result" span={6} style={{ textAlign: 'left' }}>
              <Title level={4}>Book Topic Result</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Select
                style={{ width: 150, color: '#065aa9', marginRight: 10 }}
                placeholder="Select a Class"
                onChange={(idx) => setClassIdx(idx)}
              >
                {classes.map((item) => (
                  <Select.Option key={item.idx} value={item.idx} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            dataSource={assigned_Users.sort((a, b) => new Date(b.ai_topic_date_assignment.start_date) - new Date(a.ai_topic_date_assignment.start_date))}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            scroll={{ y: 'calc(100vh - 254px)' }}
            pagination={{ pageSize: take, total }}
            size="small"
            color="#edf3fb"
            onChange={(a) => setPage(a.current - 1)}
          />
        </MainBlock>
        {viewAnswerModal && (
          <BookReportAnswerModal
            visible={viewAnswerModal}
            chatList={currentChatList}
            handleCancel={() => {
              setViewAnswerModal(false);
            }}
            topic={topic}
          />
        )}
        <RecordingDataModal
          data={{ ...recordingData, currentUserName, className: '' }}
          isShow={isRecordingVisible}
          onPrint={null}
          componentRef={null}
          onOk={handleOkForRecordingData}
          hidePrint={true}
        />
      </Col>
    </div>
  );
};

export default withRouter(StudentResults);
