import { gql } from '@apollo/client';

export const FAVORITE_BOOK = gql`
  mutation toggleExternalStudyFavourite(
    $book_id:Int!
    $type:String!
  ) {
    toggleExternalStudyFavourite(
      book_id: $book_id
      type: $type
    )
  }
`;


export const GET_LIKE_LIST = gql`
  mutation getLikeBooks(
    $user_idx:Int!
    $type:String!
  ) {
    getLikeBooks(
      user_idx: $user_idx
      type: $type
    )
    }
`;
export const GET_CONTINUE_LIST = gql`
  mutation getContinueBooks(
    $user_idx:Int!
    $type:String!
  ) {
    getContinueBooks(
      user_idx: $user_idx
      type: $type
    )
    }
`;

export const GET_POPULAR_LIST = gql`
  mutation getPopularBooks(
    $user_idx:Int!
    $type:String!
  ) {
    getPopularBooks(
      user_idx: $user_idx
      type: $type
    )
    }
`;

export const SAVE_EXTERNAL_STUDY_GOAL = gql`
  mutation SaveExternalStudyGoal(
    $goal:Int!
    $ym:String!
    $type:String!
  ) {
    SaveExternalStudyGoal(
      goal: $goal,
      ym: $ym
      type: $type
    ){
        message
        success
    }
    }
`;
