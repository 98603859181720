import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';

const CustomCheckBox = (props) => {
  return <Checkbox {...props} />;
};

export default CustomCheckBox;

export const useCheckBox = (selKeys) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState(selKeys);
  const [totalKeys, setTotalKeys] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const onCheckAll = (e) => {
    setCheckedAll(e.target.checked);
    if (e.target.checked) {
      setSelectedRowKeys(totalKeys);
    } else {
      setSelectedRowKeys([]);
    }
    setIndeterminate(false);
  };
  const onCheckEach = (checked, selectedkey) => {
    if (checked) {
      if (!selectedRowKeys.includes(selectedkey)) {
        setSelectedRowKeys([...selectedRowKeys, selectedkey]);
      }
    } else {
      const findIdx = selectedRowKeys.indexOf(selectedkey);
      if (findIdx !== -1) {
        const copy = selectedRowKeys.slice();
        copy.splice(findIdx, 1);
        setSelectedRowKeys(copy);
      }
    }
  };
  const isEachChecked = (key) => {
    return selectedRowKeys.includes(key);
  };

  const onPageChange = (pagenation, _filter, _sorter, extra) => {
    console.log('page change');
    const { currentDataSource } = extra;
    setSelectedRowKeys(
      currentDataSource
        .filter((_, key) => {
          const start = (pagenation.current - 1) * pagenation.pageSize;
          const end = start + pagenation.pageSize;
          return key >= start && key < end;
        })
        .map((item) => item.key),
    );
    onCheckAll({ target: { checked: false } });
  };

  useEffect(() => {
    if (totalKeys.length === 0) {
      return;
    }
    if (selectedRowKeys.length === totalKeys.length) {
      setCheckedAll(true);
      setIndeterminate(false);
    } else if (selectedRowKeys.length > 0) {
      setIndeterminate(true);
    } else {
      setCheckedAll(false);
      setIndeterminate(false);
    }
  }, [selectedRowKeys, totalKeys]);

  return { onCheckAll, checkedAll, onCheckEach, setTotalKeys, isEachChecked, onPageChange, selectedRowKeys, indeterminate };
};
