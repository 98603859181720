import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PopUp from '../PopUp';
import PageNum from '../PageNum';
// import SoundButton from "./common/SoundButton";
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { useStudyCreate } from '../../../utils/pm/api';
import _ from 'lodash';
import reactStringReplace from 'react-string-replace';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
const SHOW_TIME_OUT = 2000;

const PhonemeAndShowImageStage = ({ unitInfo, bookInfo, stages, steps, setSteps, directionEnd }) => {
  const { problem } = stages;
  const [showPopUp, setShowPopUp] = useState(false);
  const [showImageAndWord, setShowImageAndWord] = useState(false);
  const [sound, setSound] = useState(undefined);

  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(false);
      history.replace({
        pathname: `${window.location.pathname.split('/stage')[0]}`,
        search: `${window.location.search}`,
        state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
      });
    },
  });

  const handleGoodJobSoundEnd = () => {
    handleCreateStudy({
      lesson_code: window.sessionStorage.getItem('lesson_code'),
      stage_no: parseInt(bookInfo.stage),
      user_idx: parseInt(userInfoVar()?.idx),
      user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
    });
  };

  const handleSoundEnd = () => {
    setShowImageAndWord(true);
    setSound(undefined);
  };

  useEffect(() => {
    if (!_.isNil(steps) && !_.isNil(problem)) {
      if (steps < problem.length) {
        if (!_.isNil(problem[steps].sound)) {
          if (directionEnd) {
            setSound(problem[steps].sound);
          }
        }
      }
    }
  }, [steps, problem, directionEnd]);

  useEffect(() => {
    if (showImageAndWord) {
      setTimeout(() => {
        if (steps === problem.length - 1) {
          setShowPopUp(true);
        } else {
          setShowImageAndWord(false);
          setSteps((prev) => prev + 1);
        }
      }, SHOW_TIME_OUT);
    }
  }, [showImageAndWord, steps, setSteps, problem.length]);

  if (_.isNil(problem[steps])) {
    return false;
  }

  let completeword = problem[steps].word.replace(/##/g, '');
  let wordsplit = problem[steps].word.split('');
  let splitwordcount = wordsplit.length - 1;
  let wordtemplate = '';

  if (wordsplit[splitwordcount] == '#' || (wordsplit[0] == '#' && wordsplit[splitwordcount] !== '#')) {
    if (wordsplit[0] == '#' && wordsplit[splitwordcount] !== '#') {
      {
        reactStringReplace(problem[steps].word, /##(\w+)##/g, (match) => (wordtemplate += match));
      }
      wordtemplate += '-';
    } else {
      wordtemplate += '-';
      {
        reactStringReplace(problem[steps].word, /##(\w+)##/g, (match) => (wordtemplate += match));
      }
    }
  } else {
    wordtemplate += '-';
    {
      reactStringReplace(problem[steps].word, /##(\w+)##/g, (match) => (wordtemplate += match));
    }
    wordtemplate += '-';
  }

  return (
    <>
      <MainWrap className="main_content onewrap">
        <PopWrap className="lv4_st2_pop_wrap">
          <div style={{ height: '50vh' }} className="card_wrap">
            <CardWrapTxt className="ltext">
              {problem[steps].phoneme.indexOf('|') !== -1 ? (
                <>
                  <div>{problem[steps].phoneme.split('|')[0]}</div>
                  <span style={{ color: '#2e86de' }} className="strong">{`${wordtemplate}`}</span>
                </>
              ) : (
                <span style={{ color: '#2e86de' }} className="strong">{`${wordtemplate}`}</span>
              )}
            </CardWrapTxt>
          </div>
          <CardWrap show={showImageAndWord} className={`card_wrap ${showImageAndWord ? '' : 'hidden'}`}>
            <CardWrapInner className="card_inner">
              <Image  src={problem[steps].image} className="imgtext" alt="" />
              <CardWrapImgTxt className="text">
                {reactStringReplace(problem[steps].word, /##(\w+)##/g, (match) => (
                  <span style={{ color: '#f368e0' }} className="strong">
                    {match}
                  </span>
                ))}
              </CardWrapImgTxt>
            </CardWrapInner>
          </CardWrap>

          <PopBtnArea className="pop_btn_area rt"></PopBtnArea>
        </PopWrap>
        <BottomBox className="bottom">
          <PageNum num={steps + 1} total={problem.length} style={{ fontSize: 20, fontWeight: 600, margin: 0, padding: 0 }} />
        </BottomBox>
      </MainWrap>
      {<SoundEffectPM url={sound} onEnd={handleSoundEnd} />}
      {showPopUp ? <PopUp popUpType={'correct'} setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
    </>
  );
};

export default PhonemeAndShowImageStage;
const MainWrap = styled(S.BorderColumnBox)`
  justify-content: space-around;
  flex: 1 1;
  position: relative;
  background-color: #fff;
  align-self: stretch;
  height: calc(100% - 70px);
`;
const PopWrap = styled(S.RowFlex)`
  justify-content: space-around;
  align-items: center;
`;
const CardWrap = styled.div`
  opacity: ${(props) => !props.show && 0};
  margin-left: 2vw;
  height: 50vh;
`;
const CardWrapInner = styled(S.ColumnFlex)`
  justify-content: space-around;
  height: 100%;
`;
const Image = styled.img`
height: 238px;
@media (max-height: 500px) {
  height: 170px;
}
`;
const CardWrapImgTxt = styled.div`
  font-size: 50px;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  @media (max-height: 500px) {
    font-size: 35px;
  }
`;
const CardWrapTxt = styled(S.RowFlex)`
  position: relative;
  padding-top: 16vh;
  width: 25vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: #eee;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  @media (max-height: 500px) {
    font-size: 70px;
    
  }
`;
const PopBtnArea = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0 auto;
  text-align: center;
`;
const BottomBox = styled.div`
  margin-top: 20px;
  text-align: center;
`;
