import { gql } from '@apollo/client';

export const FETCH_SCRIBO_SUBSCRIPTION_LIST = gql`
  query scriboSubscriptionList {
    scriboSubscriptionList {
      idx
      email
      license_start_date
      campus_idx
      assigned_user_idx
      assigned_date
      number_of_days
      campus {
        idx
        name
      }
      user {
        idx
        name
      }
    }
  }
`;

export const FETCH_SCRIBO_SUBSCRIPTION_LIST_FOR_CAMPUS = gql`
  query scriboSubscriptionListForCampus($campus_idx: Int!) {
    scriboSubscriptionList(campus_idx: $campus_idx) {
      idx
      email
      license_start_date
      campus_idx
      assigned_user_idx
      assigned_date
      number_of_days
      campus {
        idx
        name
      }
      user {
        idx
        name
      }
    }
  }
`;
