import React, { useMemo, useState, useRef } from 'react';
import { userInfoVar } from 'src/apollo/cache';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Col, Row, Typography, Button, Select, Anchor, DatePicker, Table } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
//import imagesearch from 'src/images/search.png';
import imageprinter from 'src/images/printer.png';
import imageexcel from 'src/images/xlsicon.png';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { MAX_SEARCH_INTERVAL_DAYS, DATE_SEARCH_OPTIONS } from 'src/constants/common';
import BookOrderAdminMenuGroup from 'src/components/common/BookOrderAdminMenuGroup';
import { GET_ADMIN_BOOK_RETURN_LIST, GET_ADMIN_BOOK_RETURN_LIST_BY_DURATIOIN } from 'src/operations/queries/bookReturn';
import { BOOK_RETURN_STATS } from 'src/constants/common';
import BookReturnAdminModal from 'src/components/Modal/BookReturn/BookReturnAdminModal';
import { ExportExcel } from 'src/utils/index';
import { useReactToPrint } from 'react-to-print';
import { PrintDummy } from 'src/components/common/Styles';
import { isAfterSchool } from 'src/utils';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;
const DEFAULT_DATE_RANGE = [moment().subtract(1, 'months'), moment()];

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const columns = [
  {
    title: '번호',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '날짜',
    dataIndex: 'idate',
    key: 'idate',
  },
  {
    title: '반품상품',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '주문자',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: '학원명',
    dataIndex: 'campus_name',
    key: 'campus_name',
  },
  {
    title: '상태',
    dataIndex: 'stat',
    key: 'stat',
    className: 'taglistcolumn',
    render: (stat, { onViewModal, book_list, user_info, comment, idx }) => (
      <Button
        size="small"
        type={stat === 0 ? 'danger' : 'primary'}
        onClick={() => {
          onViewModal(book_list, stat, user_info, comment, idx);
        }}
      >
        {BOOK_RETURN_STATS[stat]}
      </Button>
    ),
  },
];

const columnsForSmartEclass = [
  {
    title: '번호',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '날짜',
    dataIndex: 'idate',
    key: 'idate',
  },
  {
    title: '반품상품',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '주문자',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: '학원명',
    dataIndex: 'campus_name',
    key: 'campus_name',
  },
  {
    title: '소속 지사',
    dataIndex: 'branch',
    key: 'branch',
  },
  {
    title: '상태',
    dataIndex: 'stat',
    key: 'stat',
    className: 'taglistcolumn',
    render: (stat, { onViewModal, book_list, user_info, comment, idx }) => (
      <Button
        size="small"
        type={stat === 0 ? 'danger' : 'primary'}
        onClick={() => {
          onViewModal(book_list, stat, user_info, comment, idx);
        }}
      >
        {BOOK_RETURN_STATS[stat]}
      </Button>
    ),
  },
];

const Admin = () => {
  const companyName = useSelector(classStoreData);
  const [modalVisible, setModalVisibel] = useState(false);
  const [modalInfo, setModalInfo] = useState(undefined);
  const [dateSearchOption, setDateSearchOption] = useState(DATE_SEARCH_OPTIONS[0].value);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);

  const printRef = useRef();

  const company_idx = userInfoVar()?.campus?.company?.idx;
  const { data, loading, refetch } = useQuery(
    dateSearchOption === 0 ? GET_ADMIN_BOOK_RETURN_LIST : GET_ADMIN_BOOK_RETURN_LIST_BY_DURATIOIN,
    {
      variables:
        dateSearchOption === 0
          ? { ym: selectedMonth, company_idx }
          : { start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD'), company_idx },
      skip: !company_idx,
      fetchPolicy: 'no-cache',
    },
  );

  const dataSource = useMemo(() => {
    const list = data?.getAdminBookReturnList || data?.getAdminBookReturnListByDuration;
    if (list) {
      return list.map((item, key) => {
        return {
          key: `return-admin-list${key}`,
          idx: item.idx,
          no: key + 1,
          title: item?.title,
          user_name: item?.user?.name,
          campus_name: item?.campus?.name,
          branch: item?.campus?.type === '2' ? item?.campus?.name : item?.campus?.campus?.name,
          idate: moment(item?.idate).format('YYYY-MM-DD'),
          stat: item?.stat ? parseInt(item?.stat) : 0,
          book_list: item?.book_return_list,
          onViewModal: handleViewModal,
          user_info: { ...item?.user },
          comment: item?.comment,
        };
      });
    }
    return [];
  }, [data]);

  function handleDisabledDate(current) {
    return current && current > moment().endOf('day');
  }
  function handleViewModal(book_list, stat, user_info, comment, idx) {
    setModalVisibel(true);
    console.log();
    setModalInfo({ bookData: book_list, stat, user_info, comment, idx });
  }
  function handleRefetch() {
    refetch();
  }
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  function handleExportToExcel(e) {
    e.preventDefault();
    const realColumns = isAfterSchool(companyName) ? columnsForSmartEclass : columns;
    const fileName = `반품내역`;
    ExportExcel(realColumns, dataSource, fileName);
  }
  return (
    <>
      <Col className="orderadmin-wrapper-main" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <Row gutter={[24, 16]}>
          <Col className="orderadmin-wrapper-main-a" span={8} style={{ textAlign: 'left' }}>
            {/* <HeaderTitle level={4}>Purchase Invoice(주문 관리)</HeaderTitle> */}
            <BookOrderAdminMenuGroup currentMenu="return-admin" />
          </Col>
          <Col className="orderadmin-imagesec" span={16} style={{ textAlign: 'left' }}>
            <Anchor>
              {/* <Link>
                <img src={imagesearch} alt="" />
              </Link> */}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handlePrint();
                }}
              >
                <img src={imageprinter} alt="print" />
              </a>
              <a href="/" onClick={handleExportToExcel}>
                <img src={imageexcel} alt="download" />
              </a>
            </Anchor>
          </Col>
        </Row>
        <MainBlock className="orderadmin-wrapper-mainblock">
          <Row gutter={[24, 16]} className="orderadmin-top-head">
            <Col className="orderadmin-top-title" span={8} style={{ textAlign: 'left' }}>
              <Title level={5}>반품 관리</Title>
            </Col>
            <Col className="orderadmin-top-rightform" span={16} style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}>
              <div className="purchase-select-option">
                <Select placeholder="선택" value={dateSearchOption} onChange={setDateSearchOption}>
                  {DATE_SEARCH_OPTIONS.map((item) => (
                    <Option key={`search-month-option${item.value}`} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                {dateSearchOption === 0 ? (
                  <DatePicker
                    onChange={(m) => {
                      setSelectedMonth(m.format('YYYY-MM'));
                    }}
                    picker="month"
                    value={moment(`${selectedMonth}-01`)}
                    allowClear={false}
                  />
                ) : (
                  <RangePicker
                    value={dateRange}
                    allowClear={false}
                    disabledDate={handleDisabledDate}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      const intervalDays = end.diff(start, 'days');
                      if (intervalDays > MAX_SEARCH_INTERVAL_DAYS) {
                        alert('검색 기간은 최대 3개월입니다.');
                        return false;
                      }
                      setDateRange(dates);
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
          <CustomTable
            loading={loading}
            className="orderadmin-table-table"
            pagination={{ pageSize: 12, position: ['bottomCenter'] }}
            dataSource={dataSource}
            columns={isAfterSchool(companyName) ? columnsForSmartEclass : columns}
            size="small"
            color="#edf3fb"
            scroll={{ y: 'calc(100vh - 254px)' }}
          />

          <PrintDummy ref={printRef}>
            <Table
              pagination={false}
              dataSource={dataSource}
              columns={isAfterSchool(companyName) ? columnsForSmartEclass : columns}
              size="small"
            />
          </PrintDummy>
        </MainBlock>
      </Col>
      <BookReturnAdminModal
        {...modalInfo}
        visible={modalVisible}
        onCancel={() => {
          setModalVisibel(false);
          setModalInfo(undefined);
        }}
        onRefetch={handleRefetch}
      />
    </>
  );
};

export default Admin;
