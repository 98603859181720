import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, List, Form, Checkbox, DatePicker, Select, Space, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { EditOutlined } from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import moment from 'moment';

import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';
import FormField from 'src/components/common/FormField';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { customDayOfWeek } from 'src/utils';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

let assignedStartDate = null;
let assignedendDate = null;
let assigneduserList = [];
let assignedClasslist = [];
let assignedBookList = [];
let findstudentresult = [];
let onRangeChangePageload = false;
let initialText = [];
let isEdited = [];

const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];

const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

const AssignOfflineEdit = () => {
  const history = useHistory();
  const { id, booklevel, bookprogram, bookclassidx, rowbookclassidx, bookrono } = useParams();
  const [formControl] = Form.useForm();
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [threeForm] = Form.useForm();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [SelectedClassAdd, setSelectedClassAdd] = useState(-1);

  const [text, setText] = useState(EditorState.createEmpty());

  const [selectYoil, setSelectYoil] = useState([]);
  const [selectStudents, setSelectStudents] = useState([]);
  const [testTime, setTestTime] = useState('00:00');
  const [events, setEvents] = useState([]);
  const [listData, setListData] = useState([]);
  const [rangeDate, setRangeDate] = useState({ start: null, end: null });
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const { classIdx } = parseInt(bookclassidx);
  const [checkedListAll, setcheckedListAll] = useState(false);
  const [classValueSelected, setClassValueSelected] = useState(parseInt(bookclassidx));
  const [classNameSelected, setClassNameSelected] = useState('Class');
  const [visible, setVisible] = useState(false);
  const [popuplessonid, setpopuplessonid] = useState(0);

  const classQuery = useQuery(queries.getClass.GET_TYPE_CLASS, {
    variables: {
      state: 'ing',
    },
  });

  assignedBookList = [];
  findstudentresult = [];

  const [workBooks, setWorkBooks] = useState([]);
  const [workBooksAdd, setworkBooksAdd] = useState([]);

  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(queries.getClass.CLASS_STUDENT_LIST);

  const [offlineAssignSyllabusToStudents] = useMutation(mutations.offlinesyllabus.OFFLINE_ASSIGN_SYLLABUS_TO_STUDENTS, {
    onCompleted: () => {
      openNotification('등록 완료!');
      history.goBack();
    },
  });

  const { data: fetchAssignedOfflineSyllabusBookList, loading: loadingbooklist } = useQuery(
    queries.offlinesyllabus.OFFLINE_ASSIGNED_SYLLABUS_LESSON_PLAN,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input_param_idx: parseInt(bookrono),
        input_param_class_idx: parseInt(bookclassidx),
        input_param_title: rowbookclassidx,
      },
    },
  );

  assignedClasslist = [];

  useEffect(() => {
    if (fetchAssignedOfflineSyllabusBookList?.AssignedOfflineSyllabusFetch) {
      fetchAssignedOfflineSyllabusBookList?.AssignedOfflineSyllabusFetch.map((assigndata, key) => {
        assignedStartDate = assigndata.start_date.split('T')[0];
        assignedendDate = assigndata.end_date.split('T')[0];
        assigneduserList = assigndata.user_idx.split(',');
        assignedClasslist.push(assigndata.class_idx, assigndata.class_name);
        let assignbooklistcode = assigndata.code;
        let assignunittitle = assigndata.unit_title.split(',');
        let assignunit = assigndata.unit.split(',');
        let assignunitdate = assigndata.unit_assign_date.split(',');

        let assignunitdate_sorted = assignunitdate.sort((a, b) => Date.parse(b) - Date.parse(a)).reverse();
        let changedselectYoil = Object.keys(assignunitdate_sorted).map((key) => assignunitdate_sorted[key]);
        setTestTime(assigndata.start_time);
        let assignlessonid = assigndata.lessonidx.split(',');
        let assignextra_homework = assigndata.extra_homework.split('####');
        for (let loop = 0; loop < assignunittitle.length; loop++) {
          assignedBookList.push({
            No: loop + 1,
            lessonidx: parseInt(assignlessonid[loop]),
            extra_homework: assignextra_homework[loop],
            code: '',
            level: assigndata.level,
            no_of_students_complted: 0,
            stage: 0,
            stage_title: assignunittitle[loop],
            total_students: 0,
            unit: assigndata.unit,
            unit_title: assignunittitle[loop],
            unit_assign_date: changedselectYoil[loop],
            Extra: '',
          });
        }
      });
      //let selectedBooks = Object.values(assignedBookList.reduce((acc, cur) => Object.assign(acc, { [cur.stage_title]: cur }), {}));
      setListData(assignedBookList);
      setWorkBooks(assignedBookList);
      setworkBooksAdd(assignedBookList);
      let selectYoil = assignedBookList.map((loopdata) => loopdata.unit_assign_date.split('T')[0]);
      setSelectYoil(selectYoil);
      onCheckCalendareList(selectYoil);
      setRangeDate({
        start: assignedStartDate,
        end: assignedendDate,
      });

      if (students !== undefined) {
        students.map((findstudent, key) => {
          findstudentresult.push([...new Set(assigneduserList)].includes(findstudent.user.idx));
        });
      }
      setClassValueSelected(assignedClasslist[0]);
      setClassNameSelected(assignedClasslist[1]);
      handleChange(assignedClasslist[0]);
      setCheckedList(assigneduserList);
    }
  }, [fetchAssignedOfflineSyllabusBookList?.AssignedOfflineSyllabusFetch, classes, students]);

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }

    if (classIdx) {
      setCheckedList([]);
      setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(classIdx))[0]);
      getSelectClassStudents({
        variables: { class_idx: parseInt(classIdx) },
      });
      firstForm.setFieldsValue({
        class: parseInt(classIdx),
      });
    }
  }, [classQuery, fetchStudents, classIdx]);

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }
  }, [classQuery, fetchStudents, classIdx]);

  useEffect(() => {
    if (students !== undefined && students.length > 0) {
      let checkedStudentList = [];
      let selectedStudentsArray = [];
      students.map((findstudent, key) => {
        checkedStudentList.push(assigneduserList.includes(findstudent.user.idx.toString()));
        if (assigneduserList.includes(findstudent.user.idx.toString())) {
          selectedStudentsArray.push(findstudent.user);
        }
      });
      setCheckedList(checkedStudentList);
      setSelectStudents(selectedStudentsArray);
    }
  }, [students]);

  function handleDateSelect(selectInfo) {
    formControl.setFieldsValue({
      date: moment(selectInfo.dateStr),
    });
  }

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
    onCheckStudents();
  }

  function onCheckAllChange(e) {
    if (checkedList.length > 0) {
      let tempcheck = checkedList;
      tempcheck.map((loopdata, key) => {
        checkedList[key] = false;
      });
    }
    setCheckedList(new Array(checkedList.length).fill(false));
    if (e.target.checked == true) {
      setcheckedListAll(true);
      setCheckedList([]);
      onCheckStudents();
      setCheckedList(new Array(students.length).fill(true));
    } else {
      setcheckedListAll(false);
      setCheckedList([]);
      onCheckStudents();
    }
  }

  function onCheckCalendareList(selectYoil) {
    let selectYoilSorted = selectYoil.sort((a, b) => Date.parse(b) - Date.parse(a)).reverse();
    setEvents(
      selectYoilSorted.map((item, i) => {
        return {
          date: item,
          //color: '#ffbf32',
          color: '#378006',
          title: `${i + 1}회차`,
          key: i,
        };
      }),
    );
  }

  function onCheckGenerateList(yoil) {
    const checkStudents = onCheckStudents();
    let workbookdate = [];
    const newListData = workBooks?.map((item, index) => {
      let loopobject = {
        No: index + 1,
        lessonidx: item.lessonidx,
        extra_homework: item.extra_homework,
        code: item.code,
        level: item.level,
        no_of_students_complted: 0,
        stage: 0,
        stage_title: item.stage_title,
        total_students: 0,
        unit: item.unit,
        unit_assign_date: yoil[index] + 'T00:00:00+00:00',
        unit_title: item.unit_title,
        Extra: '',
      };
      workbookdate.push(`${moment(`${yoil[index]}`).format('YYYY-MM-DD')}`);
      return loopobject;
    });
    setRangeDate({
      start: assignedStartDate,
      end: assignedendDate,
    });

    setSelectYoil(workbookdate);
    setWorkBooks(newListData);
    setworkBooksAdd(newListData);
    setListData(newListData);
    checkStudents.length && setListData(newListData);
  }

  function handleChange(value) {
    setSelectedClassAdd(parseInt(value));
    if (checkedList.length > 0) {
      let tempcheck = checkedList;
      tempcheck.map((loopdata, key) => {
        checkedList[key] = false;
      });
    }
    setCheckedList(new Array(checkedList.length).fill(false));
    let tempchecklist = onCheckStudents();
    if (tempchecklist.length > 0) {
      setCheckedList([]);
      setcheckedListAll(false);
      onCheckStudents();
    } else {
      setCheckedList([]);
      setCheckedList([]);
      setcheckedListAll(false);
      onCheckStudents();
    }
    if (onRangeChangePageload == true) {
      if (fetchStudents?.classStudentList && classQuery?.data?.classList) {
        setClasses(classQuery?.data?.classList);
        setStudents(fetchStudents?.classStudentList);
      }
      setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === value)[0]);
      getSelectClassStudents({
        variables: { class_idx: value },
      });
    } else {
      setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === parseInt(bookclassidx))[0]);
      getSelectClassStudents({
        variables: { class_idx: value },
      });
    }
    setCheckedList([]);
  }

  const onCheckStudents = (data) => {
    const astAdd = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    setSelectStudents(students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user));
    return students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user);
  };

  function onRangeChange(dates, dateStrings) {
    assignedStartDate = dateStrings[0];
    assignedendDate = dateStrings[1];
    if (selectedClass) {
      let dayOfWeek = '';
      if (selectedClass.day_of_week) {
        dayOfWeek = customDayOfWeek(selectedClass);
      }
      if (secondForm.getFieldValue('day_of_week')) {
        dayOfWeek = secondForm.getFieldValue('day_of_week').join(', ');
      } else if (!dayOfWeek) {
        dayOfWeek = '일, 월, 화, 수, 목, 금, 토';
      }
      const selectYoil = getDateRangeData(dateStrings[0], dateStrings[1], dayOfWeek);
      setRangeDate({
        start: dateStrings[0],
        end: dateStrings[1],
      });
      setSelectYoil(selectYoil);
      onCheckCalendareList(selectYoil);
      onCheckGenerateList(selectYoil);
    }
  }

  function onTimeChange(time, TimeStrings) {
    setTestTime(TimeStrings);
  }

  function getDateRangeData(param1, param2, dayOfWeek) {
    //param1은 시작일, param2는 종료일이다.
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const res_day = [];
    const ss_day = new Date(param1);
    const ee_day = new Date(param2);

    let count = 0;
    while (ss_day.getTime() <= ee_day.getTime()) {
      let _mon_ = ss_day.getMonth() + 1;
      _mon_ = _mon_ < 10 ? '0' + _mon_ : _mon_;

      let _day_ = ss_day.getDate();
      _day_ = _day_ < 10 ? '0' + _day_ : _day_;

      let yoil = ss_day.getDay();

      if (dayOfWeek.includes(week[yoil]) && workBooks[count]) {
        res_day.push(ss_day.getFullYear() + '-' + _mon_ + '-' + _day_);
        count++;
      }

      ss_day.setDate(ss_day.getDate() + 1);
    }
    return res_day;
  }

  const handleFormSubmit = () => {
    if (selectYoil.length < workBooks.length) {
      var answer = window.confirm(`Do you want to go with ${selectYoil.length} books ?`);
      if (answer) {
        saveAssignedBooks();
      }
    } else {
      saveAssignedBooks();
    }
  };
  const saveAssignedBooks = () => {
    let studentlength = selectStudents.map((students) => students.idx);
    let currentselectionstudent = onCheckStudents();
    let selectedstudentlist = currentselectionstudent.map((stddata) => stddata.idx);
    let selectedWorkBooks = Array.from(workBooks);
    let finalized_extrahomework = Array.from(workBooks);
    let finalized_extra_homework = finalized_extrahomework.splice(0, selectYoil.length).map((book) => book.extra_homework);
    if (selectedstudentlist.length > 0) {
      formControl
        .validateFields()
        .then(async (formData) => {
          await offlineAssignSyllabusToStudents({
            variables: {
              lessonidx: selectedWorkBooks.splice(0, selectYoil.length).map((book) => book.lessonidx),
              extra_homework: finalized_extra_homework,
              class_idx: SelectedClassAdd,
              start_time: selectYoil,
              limit_time: 1440,
              user_idx_list: currentselectionstudent.map((stddata) => stddata.idx),
              books_list: [],
              booktitle: '',
              booklevel: booklevel,
              bookprogram: bookprogram,
              start_date: rangeDate?.start, // 달력 시작일
              end_date: rangeDate?.end, // 달력 마지막일
              elibrary_time: testTime,
              rowidx: parseInt(bookrono),
            },
          });
          firstForm.resetFields();
          formControl.resetFields();
          secondForm.resetFields();
          threeForm.resetFields();
          setClasses([]);
          setStudents([]);
          setSelectedClass(-1);
          setSelectYoil([]);
          setSelectStudents([]);
          setTestTime([]);
          setEvents([]);
          setListData([]);
          setRangeDate({ start: null, end: null });
        })
        .catch((errorInfo) => {
          console.error('errorInfo', errorInfo);
        });
    } else {
      alert('kindly Select Student list');
    }
  };

  function showUpdateConfirm(changeInfo) {
    handleEventChange(changeInfo);
  }

  function handleEventChange(changeInfo, type = '') {
    const {
      start,
      extendedProps: { key },
    } = changeInfo.event.toPlainObject();

    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item.date = start;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item = start;
        }
        return item;
      });
    });

    // updateClassLession({
    //   variables: {
    //     class_idx,
    //     class_lesson_idx: classLessonIdx,
    //     study_date: start,
    //     type,
    //   },
    // });
  }

  function handleDateChange(date, changeDate, index) {
    const name = `testDate-${index}`;
    formControl.setFieldsValue({
      [name]: changeDate && moment(changeDate, 'YYYY-MM-DD'),
    });
    // console.log('date', date);
    const searchIndex = index - 1;
    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item.date = changeDate;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item = changeDate;
        }
        return item;
      });
    });
    let handleworkbookdata = workBooks;
    handleworkbookdata.map((item, index) => {
      if (index === searchIndex) {
        handleworkbookdata[index].unit_assign_date = changeDate;
      }
    });
  }

  const Addhomework = (e, inputlessonid) => {
    setText(EditorState.createEmpty());
    setpopuplessonid(inputlessonid);
    workBooksAdd.map((loopitem, loopkey) => {
      if (loopitem.lessonidx == inputlessonid) {
        const { contentBlocks, entityMap } = htmlToDraft(loopitem.extra_homework);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setText(EditorState.createWithContent(contentState));
      }
    });
    setVisible(true);
  };

  const handleHomeWorkChange = (e) => {
    setText(e);
    let content = draftToHtml(convertToRaw(e.getCurrentContent()));
    let currentLesson = initialText.filter((item) => item.recordId === popuplessonid);
    let hasEditObj = isEdited.some((obj) => obj.recordId === popuplessonid);
    if (!hasEditObj) {
      isEdited.push({
        recordId: popuplessonid,
        isEdited: false,
      });
    }
    if (currentLesson[0]?.initext) {
      if (currentLesson[0].initext !== content) {
        isEdited.find((obj) => obj.recordId === popuplessonid).isEdited = true;
      }
    }
    let selectedBooksHomework = workBooksAdd.map((loopitem, loopkey) => {
      if (loopitem.lessonidx == popuplessonid) {
        let obj = {
          No: loopitem.No,
          lessonidx: loopitem.lessonidx,
          extra_homework: content,
          code: loopitem.code,
          level: loopitem.level,
          no_of_students_complted: loopitem.no_of_students_complted,
          stage: loopitem.stage,
          stage_title: loopitem.stage_title,
          total_students: loopitem.total_students,
          unit: loopitem.unit,
          unit_title: loopitem.unit_title,
          unit_assign_date: loopitem.unit_assign_date,
          Extra: loopitem.Extra,
        };
        return obj;
      } else {
        let obj = {
          No: loopitem.No,
          lessonidx: loopitem.lessonidx,
          extra_homework: loopitem.extra_homework,
          code: loopitem.code,
          level: loopitem.level,
          no_of_students_complted: loopitem.no_of_students_complted,
          stage: loopitem.stage,
          stage_title: loopitem.stage_title,
          total_students: loopitem.total_students,
          unit: loopitem.unit,
          unit_title: loopitem.unit_title,
          unit_assign_date: loopitem.unit_assign_date,
          Extra: loopitem.Extra,
        };
        return obj;
      }
    });
    setWorkBooks(selectedBooksHomework);
    setworkBooksAdd(selectedBooksHomework);
  };

  const setInitialText = (text, id) => {
    let textObj = {
      initext: text.extra_homework,
      recordId: id,
    };
    let hasItem = initialText.some((item) => item.recordId === id);
    if (!hasItem) {
      initialText.push(textObj);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: 50,
    },
    {
      title: 'Lessons',
      dataIndex: 'unit_title',
      key: 'unit_title',
      align: 'center',
    },
    {
      title: 'Extra - Homework',
      key: 'Extra',
      align: 'center',
      width: 100,
      render: (text, record) => {
        setInitialText(text, record.lessonidx);
        const Extranames = `testDate-${record.key}`;
        formControl.setFieldsValue({
          [Extranames]: text && moment(text, 'YYYY-MM-DD'),
        });
        let editFlag = isEdited.filter((obj) => obj.recordId === record.lessonidx);
        return (
          <>
            <Form.Item name={`testDate-${record.key}`}>
              <span
                type={'success'}
                name={`testDate-${record.key}`}
                style={{ border: '2px solid #52c41a', padding: '8px', marginTop: '12px', cursor: 'pointer' }}
                onClick={(e) => Addhomework(e, record.lessonidx)}
              >
                <Text type={'success'}>{editFlag[0]?.isEdited === true ? 'Edit' : 'Add'}</Text>
              </span>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '날짜',
      dataIndex: 'unit_assign_date',
      align: 'center',
      key: 'unit_assign_date',
      width: 200,
      render: (text, record) => {
        const names = `testDate-${record.key}`;
        formControl.setFieldsValue({
          [names]: text && moment(text, 'YYYY-MM-DD'),
        });
        return (
          <>
            <DatePicker
              name={`testDate-${record.code}`}
              value={text && moment(text, 'YYYY-MM-DD')}
              onChange={(date, dateString) => {
                handleDateChange(date, dateString, record.No);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Col className="test-syllabus-wrapper" span={24} style={{ width: '100%', marginTop: '10px' }}>
        <TestBlock>
          <Row style={{ padding: 20 }}></Row>
          <Row style={{ padding: 8, border: '3px solid #ebf2f7', background: '#ebf2f7' }}>
            <Title level={5}>
              <span style={{ backgroundcolor: '#ebf2f7' }}>&nbsp;&nbsp;&nbsp;Sneakers 4-2</span>
            </Title>
          </Row>
          <Row className="test-syllabus-box-listing" gutter={[24, 16]}>
            <Col className="test-syllabus-box-a" span={5} style={{ paddingLeft: 20 }}>
              <TestSectionBlock className="test-syllabus-box-a-testsectionblock">
                <Title level={5}>&nbsp;</Title>
                <Form
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="vertical"
                  form={firstForm}
                >
                  <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
                    <Select
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      style={{ width: '100%', minWidth: 100 }}
                      placeholder="Class"
                      onChange={handleChange}
                    >
                      {classes.map((classInfo) => (
                        <Option key={classInfo.idx} value={classInfo.idx}>
                          {classInfo.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <List
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>Name</label>
                        <div>
                          전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                        </div>
                      </div>
                    }
                    footer={<div></div>}
                    bordered
                    dataSource={students}
                    renderItem={(item, idx) => (
                      <List.Item style={{ display: 'flex' }}>
                        <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
                      </List.Item>
                    )}
                  />
                </Form>
              </TestSectionBlock>
            </Col>
            <Col className="test-syllabus-box-b" span={10} style={{ textAlign: 'center', paddingRight: 20 }}>
              <TestSectionBlock>
                <Form form={secondForm}>
                  <Title level={5}>Test Date / Time</Title>
                  <FormItem name="day_of_week">
                    <Checkbox.Group options={yoilOptions} name="day_of_week" />
                  </FormItem>
                  <RangePicker
                    style={{ marginBottom: 10 }}
                    onChange={onRangeChange}
                    name="rangeDate"
                    value={[moment(rangeDate.start), moment(rangeDate.end)]}
                  />
                </Form>
                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    contentHeight={250}
                    expandRows={false}
                    initialView="dayGridMonth"
                    selectable={true}
                    events={events}
                    editable={true}
                    eventContent={(eventInfo, index) => {
                      return (
                        <>
                          <div
                            style={{
                              backgroundColor: eventInfo.backgroundColor,
                              color: 'white',
                              borderRadius: 10,
                              width: 20,
                              textAlign: 'center',
                              marginRight: 5,
                            }}
                          >
                            {eventInfo.event.title}
                          </div>
                          {/* <div>{classType[eventInfo.textColor]}</div> */}
                        </>
                      );
                    }}
                    eventChange={showUpdateConfirm}
                  />
                </CalendarBlock>

                <Form
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  layout="horizontal"
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                  form={threeForm}
                >
                  <Form.Item label="Time">
                    <DatePicker
                      picker="time"
                      size="large"
                      format="HH:mm"
                      showTime={{ value: moment(`${testTime}`, 'HH:mm') }}
                      onChange={onTimeChange}
                    />
                  </Form.Item>

                  {/* <Form.Item>
                    <Button type="primary" onClick={() => onCheckGenerateList(selectYoil)}>
                      Done
                    </Button>
                  </Form.Item> */}
                </Form>
              </TestSectionBlock>
            </Col>

            <Col className="test-syllabus-box-c" span={9} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Title level={5}>Confirmation</Title>
              <Form form={formControl} component={false}>
                <CustomTable
                  dataSource={listData}
                  columns={columns}
                  bordered
                  scroll={{ y: 'calc(100vh - 244px)' }}
                  pagination={{ pageSize: 8 }}
                  size="small"
                  color="#edf3fb"
                />

                <div>
                  <Button
                    type="secondary"
                    shape="round"
                    size="large"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                  <Button type="primary" shape="round" size="large" htmlType="submit" onClick={handleFormSubmit}>
                    Save
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </TestBlock>
      </Col>

      <Modal
        title="Add Extra-Homework"
        centered
        visible={visible}
        width={700}
        onSave={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <Editor
          editorState={text}
          style={{ width: '100%' }}
          rootStyle={{ height: 'calc(100% - 50px)', width: '100%', minHeight: 300 }}
          editorStyle={{ backgroundColor: '#fff', minHeight: 120, border: '1px solid #d9ebd0' }}
          toolbarClassName="hide-print"
          wrapperClassName="essay-wrapper"
          onEditorStateChange={handleHomeWorkChange}
          toolbar={{
            options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'link'],
          }}
        />
      </Modal>
    </>
  );
};

export default AssignOfflineEdit;
