import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Modal, Typography } from 'antd';
import { PlayCircleFilled, SoundFilled, ExclamationCircleFilled } from '@ant-design/icons';

import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import VocaHeader from './VocaHeader';

const VocaFlashcard = () => {
  const wordbox = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <>
      <div className="flashcard-voca-con flashcard">
        <div className="question-con">
          <div className="num">
            <h3>
              <span>05</span>/<span>10</span>
            </h3>
          </div>
          <div className="inner top">
            <img src="https://culp.cloubot.com/images/lexilelogo.png" alt="word-img in flashcard" />
            {/* */}

            {/* hidden part - ko/en word box  */}
            <div className=" word">
              <div className="wordbox en">
                <h2> bed </h2>
              </div>
              <div className="wordbox kr">
                <h2> 침대 </h2>
              </div>
            </div>
            {/* */}
          </div>
          <div className="inner bottom">
            <div>
              <SoundFilled className="speaker-btn" />
            </div>
            <div>
              <PlayCircleFilled className="prev-btn" />{' '}
            </div>
            <div>
              <PlayCircleFilled className="play-btn" />{' '}
            </div>
            <div>
              <PlayCircleFilled />{' '}
            </div>
            <div>
              <ExclamationCircleFilled />
            </div>
          </div>
        </div>
        <div className="wordlist-con">
          <h2>Word List</h2>
          <div className="inner">
            <div className="word-box correct">
              <h3>answer</h3>
            </div>
            {wordbox.map((words) => {
              return (
                <>
                  <div className="word-box">
                    <h3>{words}</h3>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default VocaFlashcard;
