import React, { useMemo } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useQueryProxy } from 'src/operations/proxy/user';
import { ExternalRecording, ListPage } from 'src/components/Tool';
import { getCurrentToken } from 'src/utils';
import * as queries from 'src/operations/queries';
import queryString from 'query-string';

const Manage = (props) => {
  const { type = 'recording', code = undefined } = useParams();
  const params = queryString.parse(window.location.search);

  const token = getCurrentToken();
  const { data: userData } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token: params.token ? params.token : token },
  });
  props.onSetHideHeader(true);

  const dataSingle = JSON.parse(params.data) || [];

  const dataNew = useMemo(() => {
    if (dataSingle.length > 1) {
      return dataSingle;
    }
    const singleText = dataSingle[0]['text'];
    let singleTextArray = singleText.split('\n');
    singleTextArray = singleTextArray.filter((item) => item !== '');
    let tempData = [];
    for (let i = 0; i < singleTextArray.length; i += 3) {
      tempData.push({
        text: singleTextArray[i] + '\n\n' + singleTextArray[i + 1] + '\n\n' + singleTextArray[i + 2],
        page_no: parseInt(i / 3),
      });
    }
    const dataNew2 = JSON.parse(JSON.stringify(tempData));
    let remover = dataNew2[dataNew2.length - 1]?.text.replaceAll('\n\nundefined', '');
    dataNew2[dataNew2.length - 1].text = remover;
    return dataNew2;
  }, [dataSingle]);

  const getSwitchableRoute = (val) => {
    switch (val) {
      case 'recording':
        return (
          <ExternalRecording
            dataNew={dataNew}
            articleData={JSON.parse(params.article_data) || []}
            assignedIdx={params.assigned_idx || 0}
            userData={userData?.me}
            articleId={params.article_id || 0}
            code={params.article_id || 0}
          />
        );
      default:
        return <ListPage />;
    }
  };

  return (
    <>
      <Row gutter={[32, 16]}>{userData ? getSwitchableRoute(type) : null}</Row>
    </>
  );
};

export default withRouter(Manage);
