import React from 'react';

import { Button, Col, DatePicker, Input, Radio, Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { UploadOutlined } from '@ant-design/icons';

const CreatePopupForm = ({ onChange }) => {
  function onDateChange(name, dateString) {
    // setValues((prev) => ({
    //   ...prev,
    //   [name]: dateString,
    // }));
  }

  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Col span={12}>
        <FormItem
          name="title"
          rules={[{ required: true, message: '팝업제목을 입력해 주세요.' }]}
          label={`팝업 제목`}
          hasFeedback
          style={{ width: '90%' }}
        >
          <Input name="title" onChange={onChange} />
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          name="popup_type"
          label={`팝업 내용 형식`}
          rules={[{ required: true }]}
          style={{ width: '90%' }}
        >
          <Radio.Group>
            <Radio.Button value="이미지">이미지</Radio.Button>
            <Radio.Button value="텍스트">텍스트</Radio.Button>
          </Radio.Group>
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          name="popup_size_width"
          rules={[{ required: true }]}
          label={`팝업크기(가로)`}
          hasFeedback
          style={{ width: '90%' }}
        >
          <Input name="popup_size_width" />
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          name="popup_size_height"
          rules={[{ required: true }]}
          label={`팝업크기(세로)`}
          hasFeedback
          style={{ width: '90%' }}
        >
          <Input name="popup_size_height" />
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          name="begin_date"
          rules={[{ required: true }]}
          label={`시작일`}
          hasFeedback
          style={{ width: '90%' }}
        >
          <DatePicker
            name="begin_date"
            onChange={(_, d) => onDateChange('begin_date', d)}
            style={{ width: '100%' }}
          />
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          name="end_date"
          rules={[{ required: true }]}
          label={`종료일`}
          hasFeedback
          style={{ width: '90%' }}
        >
          <DatePicker
            name="end_date"
            onChange={(_, d) => onDateChange('end_date', d)}
            style={{ width: '100%' }}
          />
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          name="close_type"
          label={`창닫기 방법`}
          rules={[{ required: true }]}
          style={{ width: '90%' }}
        >
          <Radio.Group name="close_type">
            <Radio value="오늘 하루 열지 않음">오늘 하루 열지 않음</Radio>
            <Radio value="다시 열지 않음">다시 열지 않음</Radio>
          </Radio.Group>
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          name="location"
          label={`팝업 위치`}
          rules={[{ required: true }]}
          style={{ width: '90%' }}
        >
          <Radio.Group name="location">
            <Radio.Button value="가운데">가운데</Radio.Button>
            <Radio.Button value="직접 입력">직접 입력</Radio.Button>
          </Radio.Group>
        </FormItem>
      </Col>

      <Col span={12}>
        <FormItem
          name="upload"
          label="이미지 등록"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra=""
        >
          <Upload name="logo" action="/upload.do" listType="picture">
            <Button icon={<UploadOutlined />}>이미지 등록</Button>
          </Upload>
        </FormItem>
      </Col>
    </>
  );
};

export default CreatePopupForm;
