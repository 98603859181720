import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'antd';
import styled from 'styled-components';
// import { GET_ORDER_ITEM_LIST_META } from 'src/operations/queries/getLevelTest';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient, useQuery } from '@apollo/client';
import useSound from 'use-sound';
import clipStart from 'src/sounds/meta/0.mp3';
import clipComplete from 'src/sounds/meta/5.mp3';
import { GET_USER_INFO } from 'src/operations/queries/campus';
// const { Option } = Select;

const InnerWrap = styled.div`
  background-color: #fff;
  width: 1400px;
  height: 700px;
  padding: 2rem 1rem;
  margin: 0rem auto;
  border-radius: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;

  & {
    .meta-header .typeLogo {
      width: 50%;
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100% - 18rem);
    height: calc(100% - 20rem);
    padding: 2rem 1rem;
    margin: 0rem auto;

    & {
      .fixed_btm_btn {
        bottom: 10rem;
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
  }

  @media (max-width: 1920px) and (aspect-ratio: 16/10) {
    width: 75%;
    height: 70%;
  }

  @media (max-width: 1600px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 1200px;
    max-height: 650px;
    margin: 0 auto;

    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: -4rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .finished-con {
        margin-top: 0rem;
      }
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16/9) {
  }

  @media (max-width: 1280px) {
    width: calc(75rem - 10rem);
    height: calc(45rem - 10rem);

    & {
      .arrow-wrap {
        .guide-arrow {
        }
      }
    }
  }

  @media (max-width: 1280px) and (aspect-ratio: 16 / 9) {
    height: 80%;
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    margin: 0 auto;

    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: -3rem;
      }
    }
  }

  @media (max-width: 1200px) and (aspect-ratio: 16/9) {
    .onlyTxt-header {
      h1.main-tit {
        margin-top: 5rem;
      }
    }

    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: 1rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .finished-con {
        margin-top: -2rem;
      }

      .onlyTxt-header {
        h1.main-tit {
          margin-top: 0rem;
        }
      }
    }
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    width: 90%;
    height: 65%;
    .meta-header.onlyTxt-header {
      top: 6rem;
    }
  }

  @media (max-width: 1200px) and (max-height: 800px) {
    height: 70%;
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    margin: 0 auto;

    & {
      .finished-con.type-reading-con {
        width: 100%;
      }

      .arrow-wrap {
        .guide-arrow {
          right: -8%;
        }
      }
    }
  }

  @media (max-width: 1040px) {
    width: calc(75rem - 18rem);
  }

  @media (max-width: 1024px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    margin: 0 auto;

    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }
    }
  }

  @media (max-width: 960px) and (aspect-ratio: 16/9) {
    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: 1rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .finished-con {
        margin-top: 0rem;
      }

      .onlyTxt-header {
        h1.main-tit {
          margin-top: 1rem;
        }
      }
    }
  }
`;

const InstructionalGuideMeta = () => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 ? true : false;
  const history = useHistory();
  const client = useApolloClient();
  const location = useLocation();
  // const [userObj, setUserObj] = useState({});
  if (!location?.state?.idx_list && localStorage.getItem('meta_idx_list')) {
    location.state = { idx_list: JSON.parse(localStorage.getItem('meta_idx_list')) };
  }

  const [playStart, { stop: stopStart }] = useSound(clipStart, { volume: 0.5 });
  const [playComplete, { stop: stopComplete }] = useSound(clipComplete, { volume: 0.5 });

  const isCompleted = useRouteMatch('/MetaExam/InstructionalGuideMeta/complete');

  useEffect(() => {
    if (!isCompleted) {
      setTimeout(() => {
        playStart();
      }, 1000);
    }
  }, [isCompleted, playStart]);

  useEffect(() => {
    if (isCompleted) {
      setTimeout(() => {
        playComplete();
      }, 1000);
    }
  }, [isCompleted, playComplete]);

  // const { loading, data } = useQuery(GET_ORDER_ITEM_LIST_META, { variables: { idx_array: location?.state?.idx_list } });

  // const levels = useMemo(() => {
  //   let levels = {};
  //   if (data) {
  //     for (let i = 0; i < data?.orderItemListByIdx?.length; i++) {
  //       if (data?.orderItemListByIdx[i].order_leveltest) {
  //         for (let j = 0; j < data?.orderItemListByIdx[i]?.order_leveltest?.length; j++) {
  //           const item = data?.orderItemListByIdx[i]?.order_leveltest[j];
  //           let done = false;
  //           if (item?.assigned_tests?.assigned_test_users) {
  //             if (item?.assigned_tests?.assigned_test_users[0].status !== 1) {
  //               done = true;
  //             }
  //           }
  //           if (item?.assigned_tests?.tests?.test_type) {
  //             levels[item?.assigned_tests?.tests?.test_type] = {
  //               title: `${item?.assigned_tests?.tests?.title}`,
  //               assigned_tests_idx: item?.assigned_tests?.idx,
  //               done,
  //               batch_type: `${item?.assigned_tests?.tests?.batch_type}`,
  //             };
  //           }
  //           if (
  //             Object.keys(userObj).length === 0 &&
  //             item?.assigned_tests?.assigned_test_users?.length > 0 &&
  //             item?.assigned_tests?.assigned_test_users[0]?.user
  //           ) {
  //             const newUserObj = item?.assigned_tests?.assigned_test_users[0]?.user;
  //             setUserObj({
  //               id: getMetaExamId(newUserObj.birthday, newUserObj?.parent_phone),
  //               korean_name: newUserObj.english_name,
  //             });
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return levels;
  // }, [data]);

  if (!location?.state?.idx_list) {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/meta-login');
    return <Redirect to={`/meta-login`} />;
  }
  // const type = Object.keys(levels).length == 0 ? '' : 'Type-' + levels[Object.keys(levels)[0]].batch_type;
  // let allDone = false;
  // if (
  //   Object.keys(levels).length != 0 &&
  //   levels['vocabulary'].done &&
  //   levels['reading'].done &&
  //   levels['listening'].done &&
  //   levels['grammar'].done
  // ) {
  //   allDone = true;
  // }

  const stopSoundAndLeave = () => {
    stopStart();
    stopComplete();
    if (isCompleted) {
      history.push('/MetaExam/MetaExamType');
    } else {
      history.push('/MetaExam/InstructionalMetaExamType');
    }
  };

  return (
    <>
      <div className={isWEnglish ? 'meta-con meta-full-con guide-con w-english-bg' : 'meta-con meta-full-con guide-con'}>
        <InnerWrap>
          <div className="meta-header onlyTxt-header">
            {!isWEnglish && (
              <h1 className="main-tit" style={{ color: '#777' }}>
                <img src="../../images/metalogin_logo.png" alt="login_top_logo" style={{ width: '70%' }} className="login-top-logo" />
              </h1>
            )}
          </div>

          <div className="type-reading-con finished-con">
            <h1 style={{ color: '#1561a0' }}>Instructional Guide</h1>
            {isCompleted && <h1 style={{ color: '#1561a0' }}> Completed </h1>}
          </div>
          <Button className="btn confirm_btn fixed_btm_btn finished_btn">
            {/* {allDone && <Link to="./MetaReport">Completed</Link>} */}
            <a href="#" onClick={stopSoundAndLeave}>
              {isCompleted ? 'next' : 'Start'}
            </a>
          </Button>
          <div className="arrow-wrap">
            <p className="guide-arrow">
              <span class="arrow-body">Click here</span>
            </p>
          </div>
        </InnerWrap>
        {!isWEnglish && (
          <div className="report-footer-con">
            <h5>
              Powered by {'     '}
              <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
              </span>
            </h5>
            <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default InstructionalGuideMeta;
