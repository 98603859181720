import React from 'react';
import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

export default function RowItem({ title, value, span }) {
  return (
    <Row>
      <Col md={span} xs={12}>
        <Text style={{ size: 0.9, color: '#777777' }}>{title}</Text>
      </Col>
      <Col>
        <Text style={{ color: '#202020' }}>{value}</Text>
      </Col>
    </Row>
  );
}

RowItem.defaultProps = {
  span: 6,
};

RowItem.prototype = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  span: PropTypes.number,
};
