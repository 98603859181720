import React from 'react';
import { Form, Row } from 'antd';

import DefaultModal from './DefaultModal';
import CreateStudentForm from 'src/pages/Students/CreateStudentForm';
import UpdateStudentForm from 'src/pages/Students/UpdateStudentForm';
import { useForm } from 'src/utils/hooks';
import AssignTicketDetail from 'src/pages/Tickets/AssignTicket/AssignTicketDetail';

function AssignTicketModal({ userInfo, popupMode, handleCancel, ...rest }) {
  const [form] = Form.useForm();

  function formCallback() {
    //popupMode === 'create' ? mutate() : update();
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  return (
    <>
      <DefaultModal
        title="이용권 부여"
        form={form}
        ActionCancel={ActionCancel}
        popupMode={popupMode}
        width="80"
        {...rest}
      >
        <Form
          form={form}
          name="control-ref"
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
        >
          <Row gutter={[16, 16]}>
            <AssignTicketDetail />
          </Row>
        </Form>
      </DefaultModal>
    </>
  );
}

export default AssignTicketModal;
