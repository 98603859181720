import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO } from 'src/operations/queries/campus';

// const { Option } = Select;

const InnerWrap = styled.div`
  background-color: #fff;
  width: calc(95rem - 15rem);
  height: calc(50rem - 3rem);
  padding: 2rem 1rem;
  margin: 0rem auto;
  border-radius: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & {
    .fixed_btm_btn {
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
    width: calc(100vw - 25rem);
    height: calc(100vh - 30rem);
    padding: 2rem 1rem;
    margin: 0rem auto;

    & {
      h1.main-tit {
        font-size: 5rem;
      }

      .fixed_btm_btn {
        bottom: 0;
        width: 400px;
        padding: 4rem 1rem;
        border-radius: 20rem;
      }

      .meta-con {
        .btn {
          a {
            position: relative;
            top: -2rem;
          }
        }
      }
    }
  }

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    height: calc(100vh - 26rem);
    width: calc(90% - 3rem);
    max-width: 2000px;
    & {
      h1.main-tit {
        font-size: 5rem;
        margin-top: -2rem;
      }
    }
  }

  @media (max-width: 1920px) and (aspect-ratio: 16/10) {
    width: 75%;
    height: 70%;
  }

  @media screen and (max-width: 1400px) {
    width: calc(75rem - 15rem);
    height: calc(40rem - 3rem);
  }

  @media (max-width: 1280px) {
    width: calc(75rem - 10rem);
    height: calc(45rem - 10rem);
  }

  @media screen and (max-width: 1100px) {
    width: calc(55rem - 10rem);
    height: 80%;

    & {
      .finished-con.type-reading-con {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: calc(40rem - 10rem);
    & {
      .btn.fixed_btm_btn {
        bottom: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(45rem - 10rem);
    height: calc(35rem - 10rem);
  }

  @media screen and (max-width: 580px) {
    width: calc(35rem - 10rem);
    height: calc(40rem - 10rem);
  }

  @media screen and (max-width: 425px) and (orientation: portrait) {
    width: calc(30rem - 10rem);
    height: calc(35rem - 10rem);
  }

  /* 16/10 */
  @media screen and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
  }

  @media screen and (max-width: 1368px) {
    & {
      .fixed_btm_btn {
        bottom: 0;
      }
    }
  }

  @media screen and (max-width: 950px) and (orientation: landscape) {
    & {
      .meta-header {
        &.onlyTxt-header {
          h1 {
            &.main-tit {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }

  /********* 이하 비정상 **********/

  @media (max-width: 1200px) and (max-height: 800px) {
    margin: 0 auto;
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    .meta-header.onlyTxt-header {
      top: 3rem;
    }
  }

  @media (max-width: 1600px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 1200px;
    max-height: 650px;
    margin: 0 auto;

    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: -4rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .finished-con {
        margin-top: 0rem;
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 4rem;
      }
    }
  }

  @media (max-width: 1400px) and (aspect-ratio: 16/9) {
    & {
      .inner-header {
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 10rem;
      }
    }
  }

  @media (min-width: 1440px) and (aspect-ratio: 16 / 10) {
    & {
      .fixed_btm_btn {
        bottom: 2rem;
      }
    }
  }

  @media (max-width: 1280px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 900px;
    max-height: 500px;
    margin: 0 auto;

    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: -3rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 4rem;
      }
    }
  }

  @media (max-width: 1024px) and (aspect-ratio: 16/9) {
    height: 80%;
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    margin: 0 auto;

    & {
      .inner-header {
        top: 0rem;
        width: 100%;
        max-width: 730px;
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 1rem;
        padding: 0;
      }
    }
  }

  @media (max-width: 960px) and (aspect-ratio: 16/9) {
    & {
      h1.main-tit {
        font-size: 2.5rem;
        margin-top: 1rem;
      }

      .type-reading-con {
        width: 100%;
      }

      .finished-con {
        margin-top: 1rem;
      }

      .onlyTxt-header {
        h1.main-tit {
          margin-top: 1rem;
        }
      }

      .btn.confirm_btn {
        position: fixed;
        bottom: 0.5rem;
      }
    }
  }
`;

const ReadingInfo = (props) => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 ? true : false;
  return (
    <>
      <div className={isWEnglish ? 'meta-con meta-full-con w-english-bg' : 'meta-con meta-full-con'}>
        <InnerWrap>
          <div className="meta-header onlyTxt-header">
            <h1 className="main-tit" style={{ color: '#777' }}>
              Type {props?.batchType || ''}
            </h1>
          </div>

          <div className="type-reading-con">
            <h1 style={{ textTransform: 'capitalize' }}>{props?.testType || ''}</h1>
            <h3>You have {props?.timeLimit} minutes to complete the test.</h3>
            <h3>There are {props?.totalQuestions || '0'} questions for this section.</h3>
            {props?.testType === 'listening' ? <h3>You can listen to the audio for each question up to two times.</h3> : null}
            <h3>If you get five consecutive incorrect answers,</h3>
            <h3>the test will automatically stop.</h3>
          </div>
          <Button className="btn fixed_btm_btn" onClick={props.onClick}>
            Start
          </Button>
        </InnerWrap>
        {!isWEnglish && (
          <div className="report-footer-con">
            <h5>
              Powered by {'     '}
              <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
              </span>
            </h5>
            <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default ReadingInfo;
