import React, { useState } from 'react';
import { Row, Col, DatePicker, Input } from 'antd';
//import styled from 'styled-components';
import SmsChargeModal from 'src/components/Modal/SmsChargeModal';
import { koreanNumberFormat } from 'src/utils';

//const { Option } = Select;

const SmsChargeHistoryHeader = ({ selectedYm, onChange, remainedPoint = 0 }) => {
  return (
    <>
      <Row className="chargeHistory_Wrap">
        <Col span={2} push={1}>
          <h1>SMS 충전내역</h1>
        </Col>
        {/* <Col span={4} push={9}>
          <Select defaultValue="Search" className="chargeHistory_Select">
            <Option value="11">11</Option>
            <Option value="22">22</Option>
            <Option value="33">33</Option>
          </Select> 
        </Col>*/}
        <Col span={2} push={7}>
          <DatePicker onChange={onChange} picker="month" value={selectedYm} inputReadOnly={true} allowClear={false} />
        </Col>
        <Col span={4} push={8} className="remainCash_wrap">
          <span className="remainCash"> 총 잔여 금액 : </span>
          <Input placeholder="" value={koreanNumberFormat(remainedPoint)} style={{ textAlign: 'right' }} readOnly={true} />원
        </Col>
        <Col span={3} push={8}>
          <SmsChargeModal />
        </Col>
      </Row>
    </>
  );
};
export default SmsChargeHistoryHeader;
