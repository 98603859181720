import React from "react";
import styled from "styled-components";
const PopupCloseButton = ({onClose}) => {
	return (
		<PopupClose className="popup_close">
			<PopupCloseBtn className="btn-close-popup" onClick={onClose}>
				<PopupCloseTxt>팝업닫기</PopupCloseTxt>
			</PopupCloseBtn>
		</PopupClose>
	);
};

export default PopupCloseButton;
const PopupClose= styled.div`
position: absolute;
top: 1.1rem;
right: 1.1rem;
`;
const PopupCloseBtn= styled.button`
outline: none;
border: none;
color: #333;
margin: 0;
padding: 0;
width: 3.6rem;
height: 3.6rem;
background: url(https://cdn.cloubot.com/PM/image/btn_closepop.png);
background-size: contain;
background-repeat: no-repeat;
@media (max-height: 768px){
    width: 2.8rem;
    height: 2.8rem;
}
@media (max-height: 500px){
      width: 2.2rem;
      height: 2.2rem;
}
`;
const PopupCloseTxt = styled.span`
    position: absolute;
    margin: -1px;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
`;