import React from 'react';
import { Col, Row, Button } from 'antd';
import {
  BookCard,
  BookCover,
  BookMeta,
  BookTitle,
  BOOK_COL_STYLE,
  SMART_ECLASS_LINK_BUTTON_STYLE,
  SMART_ECLASS_CDN_BASE_URL,
  THUMBNAIL_BASE_URL,
} from './CommonComp';

const VOLUMES = [1, 2, 3];

const handleSetupFileDownload = (volume) => {
  //window.open(`http://admin.smart-eclass.co.kr/include/common/downFile3.php?filename=kids_tap_${volume}.zip`);
  window.open(`${SMART_ECLASS_CDN_BASE_URL}common/kids_tap/kids_tap_${volume}.zip`);
};

const KidsTap = () => {
  return (
    <div style={{ height: '72vh', overflowY: 'auto' }}>
      <Row gutter={[16, 16]} style={{ margin: '0 10px' }}>
        {VOLUMES.map((v, key) => (
          <Col span={4} key={`col-${key}`} style={{ ...BOOK_COL_STYLE, borderRight: `${v === 3 ? '1px solid #dedede' : ''}` }}>
            <BookTitle level={5}>Grammar House {key + 1}</BookTitle>
            <BookCard
              style={{ marginLeft: 5, marginRight: 5 }}
              onClick={() => {
                window.open(`https://cdn.cloubot.com/SM/ppt/grammar_house_${v}/index.html`, '_blank', 'width=1400, height=900');
              }}
              cover={
                <BookCover
                  style={{
                    backgroundImage: `url(${THUMBNAIL_BASE_URL}grammar_house_${key + 1}.jpg)`,
                  }}
                />
              }
            >
              <BookMeta />
            </BookCard>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default KidsTap;

const EbookLinkGroup = ({ volume }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, paddingBottom: 5 }}>
      {/* <Button
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.8em' }}
        onClick={(e) => {
          e.stopPropagation();
          handleSetupFileDownload(volume);
        }}
      >
        E-learning
      </Button> */}
      <Button
        style={{ ...SMART_ECLASS_LINK_BUTTON_STYLE, textAlign: 'center', fontSize: '0.8em' }}
        onClick={() => {
          window.open(`https://cdn.cloubot.com/SM/ppt/grammar_house_${volume}/index.html`, '_blank', 'width=1400, height=900');
        }}
      >
        GH
      </Button>
    </div>
  );
};
