import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Typography, Space, Row, Modal, Button, Input, Table,List,Checkbox } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';
import { userInfoVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { jsPDF } from "jspdf";
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcel } from 'src/utils/index';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const SyllabusAssigned = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [studentModalVisible, setStudentModalVisible] = useState(false);
    const [testData, setTestData] = useState({});
    const [testDetailList, setTestDetailList] = useState();
    const [students, setStudents] = useState([]);
    const [classIdx, setClassIdx] = useState();
    const [groupIdx, setGroupIdx] = useState();
    const [selectedStudentsIdx, setSelectedStudentsIdx] = useState([]);
    const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));

    const { idx } = useParams();
    // console.log("idx",idx);
    const { selectedClassIdx, onClassChange, searchClassIdx } = useCampusClass({
        // key: '전체',
        key: 'Class',
        title: 'we_assigned_search',
    });
    const [checkRowList, setcheckRowList] = useState([]);
    const { data: fetchGroupResultList, refetch } = useQuery(queries.getTests.DAILY_TEST_GROUP_RESULT_LIST, {
        fetchPolicy: 'no-cache',
        variables: {
            type: '',
            searchText: '',
            class_idx: selectedClassIdx,
            campus_idx: userInfoVar()?.campus_idx,
            parent_idx: parseInt(idx)
        },
    });
    const [getTestDetailsList] = useMutation(mutations.createTests.TEST_DETAIL_LIST, {
        onCompleted(data) {
            setTestDetailList(data?.getTestDetailsList)
        },
    });


    const [assignedTestDelete] = useMutation(mutations.createTests.ASSIGNED_TEST_DELETE, {
        onCompleted(data) {
            if (data) {
                refetch();
                openNotification('삭제 완료!');
            }
        },
    });
    const { data: fetchStudents } = useQuery(queries.getClass.CLASS_STUDENT_LIST, {
        variables: { class_idx: selectedClassIdx !== 0 ? selectedClassIdx :  parseInt(classIdx)},
      });
      useEffect(() => {
        if (fetchStudents?.classStudentList) {
          setStudents(fetchStudents?.classStudentList);
        }
      }, [fetchStudents])

    const handleModel = (param) => {
        setTestData(param)
        setModalVisible(true)
        const idx = param?.paper_test.map((ele)=> ele.idx)
        if (idx?.length > 0){
            getTestDetailsList({ variables: { idxs: idx } });
        }
    }

    const dailyTestGroupResultList = useMemo(() => {
        if (fetchGroupResultList?.dailyTestGroupResultList) {
            return fetchGroupResultList?.dailyTestGroupResultList?.reduce((acc, cur, index) => {
                const obj = {
                    assigned_date: `${moment(cur?.assigned_date).format('YYYY-MM-DD')}`,
                    calss: cur?.class_idx_details?.name,
                    test_series_title: cur?.test_group_detail?.name,
                    // start_date:`${moment(cur?.idate).format('YYYY-MM-DD')}`,
                    // end_date:`${moment(cur?.idate).format('YYYY-MM-DD')}`,
                    tests: cur?.test_group_detail?.no_of_test,
                    paper_test: cur?.test_group_detail?.testPdfDetail,
                    complete: cur?.test_group_detail?.no_of_test,
                    result: "",
                    No: index + 1,
                    key: cur.idx,
                    idx: cur.idx,
                    class_idx_details: cur?.class_idx_details
                };

                return [...acc, obj];
            }, []);
        }
    }, [fetchGroupResultList])

    useEffect(()=>{
        if(dailyTestGroupResultList?.length){
            setClassIdx(dailyTestGroupResultList[0].class_idx_details.idx)
            const idxValues = dailyTestGroupResultList?.map(item => item.idx);
            setGroupIdx(idxValues)
        }
        // 
    },[dailyTestGroupResultList])

    const columns = [
        {
            title: 'No',
            dataIndex: 'No',
            key: 'No',
            width: 50,
        },
        {
            title: 'Assigneed Date',
            dataIndex: 'assigned_date',
            key: 'assigned_date',
            width: 110,
            align: 'center',
        },
        {
            title: 'Class',
            dataIndex: 'calss',
            key: 'calss',
            width: 100,
            align: 'center',
            render: (text, record) => {
                return (
                    <Link to={`/test/syllabus/${record.test_group_idx}/assign?classIdx=${record?.class_idx}`}>
                        <Space size="middle" style={{ color: '#1890ff' }}>
                            {text}
                        </Space>
                    </Link>
                );
            },
        },
        {
            title: 'Test Series Title',
            dataIndex: 'test_series_title',
            key: 'test_series_title',
            width: 80,
            align: 'center',
        },
        // {
        //     title: 'Starting Date',
        //     dataIndex: 'start_date',
        //     key: 'start_date',
        //     width: 120,
        //     align: 'center',
        // },
        // {
        //     title: 'Ending Date',
        //     dataIndex: 'end_date',
        //     key: 'end_date',
        //     width: 120,
        //     align: 'center',
        // },
        {
            title: 'Tests',
            dataIndex: 'tests',
            key: 'tests',
            width: 120,
            align: 'center',
        },
        {
            title: 'Paper Tests',
            dataIndex: 'paper_test',
            key: 'paper_test',
            width: 120,
            align: 'center',
            render: (text, record) => {
                return (
                    <Button onClick={() => { handleModel(record) }}>Download</Button>
                );
            },
        },
        {
            title: 'Completed',
            dataIndex: 'complete',
            width: 100,
            key: 'complete',
            align: 'center',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            width: 120,
            align: 'center',
            render: (text, record) => {
                return (
                    <Button>
                        <Link to={`/daily/test/result/${record?.idx}/${record?.class_idx_details.idx}`}>View</Link>
                    </Button>
                );
            },
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setcheckRowList(selectedRows.map((data) => data.idx));
        },
    };

    const deleteConfirm = () => {
        if (checkRowList.length) {
            confirm({
                title: '정말 삭제하시겠습니까?',
                okText: '예',
                okType: 'primary',
                cancelText: '아니오',
                onOk() {
                    assignedTestDelete({ variables: { idxs: checkRowList } });
                },
                onCancel() { },
            });
        } else {
            infoConfirm();
        }
    };

    function infoConfirm() {
        Modal.warning({
            title: '삭제할 항목을 선택해 주세요.',
            okText: '확인',
        });
    }
    const generateData = () => {
        var result = [];
        testDetailList?.map((ele, indx) => {
            result.push({
                No: (indx + 1).toString(),
                Question: ele?.question,
                Answer: ele?.answer,
                TestName: ele?.test_name
            })
        })
        return result;
    };

    const createHeaders = (keys) => {
        var result = [];
        for (var i = 0; i < keys.length; i += 1) {
            result.push({
                id: keys[i],
                name: keys[i],
                prompt: keys[i],
                width: 65,
                align: "center",
                padding: 0
            });
        }
        return result;
    }
    const handleDownload = async (file) => {
        try {
            const headers = createHeaders([
                "No",
                "Question",
                "Answer",
                "TestName",
            ]);

            const res = generateData();
            const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
            doc.table(1, 1, res, headers, { autoSize: true });
            doc.save("Answer.pdf")

            for (const fileUrl of file.paper_test) {
                const response = await fetch(fileUrl.pdf_id);
                const blob = await response.blob();

                const tempLink = document.createElement('a');
                tempLink.href = URL.createObjectURL(blob);
                tempLink.download = fileUrl.test_folder_name;

                tempLink.click();
                URL.revokeObjectURL(tempLink.href);
            }
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    }
    function handleExportToExcel(e) {
        e.preventDefault();
        const fileName = `AssignedTestList`;
        ExportExcel(columns, dailyTestGroupResultList, fileName);
    }
    function onCheckAllChange(e) {
        const studentIdx = students?.map((ele) => ele.user?.idx)
        if (e.target.checked) {
          setCheckedList(new Array(students.length).fill(true));
          setSelectedStudentsIdx(studentIdx)
        } else {
          setCheckedList([]);
          setSelectedStudentsIdx([])
        }
      }
      function onChange(e, idx, userIdx) {
        checkedList[idx] = e.target.checked;
        setCheckedList(JSON.parse(JSON.stringify(checkedList)));
        if (e.target.checked === true) {
          setSelectedStudentsIdx([...selectedStudentsIdx, userIdx])
        } else {
          const studentId = selectedStudentsIdx;
          const idx = studentId?.filter((ele) => ele !== userIdx)
          setSelectedStudentsIdx(idx);
    
        }
      }
      const [addStudent] = useMutation(mutations.createTests.Add_STUDENT, {
        onCompleted: () => {
          setStudentModalVisible(false)
          setSelectedStudentsIdx([])
          setCheckedList([])
          openNotification('수정 완료!');
          refetch()
        },
      });
      const [deleteStudent] = useMutation(mutations.createTests.DELETE_STUDENT, {
        onCompleted: () => {
          setStudentModalVisible(false)
          openNotification('수정 완료!');
          setSelectedStudentsIdx([])
          setCheckedList([])
          refetch()
        },
      });
      const handleAdd = () => {
        addStudent({
          variables: {
            // daily_test_group_assigned_idx: parseInt(idx),
            daily_test_group_assigned_idx:groupIdx,
            user_idx: selectedStudentsIdx
          }
        })
      }
      const handleDelete = () => {
        deleteStudent({
          variables: {
            // daily_test_group_assigned_idx: parseInt(idx),
            daily_test_group_assigned_idx:groupIdx,
            user_idx: selectedStudentsIdx
          }
        })
      }
    return (
        <>
            <HeaderTitle level={4}>Daily Test Result Page</HeaderTitle>
            <Row gutter={[16, 16]} className='cust-assigned-list'>
                <Col span={24}>
                    <HalfWrapper className="assigned-syllabus-halfwrapper">
                        <div className='cust-header-content'
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Title level={4} style={{ marginBottom: '0' }}>Assigned List</Title>
                            <div className="assigned-syllabus-top-fields" style={{ display: 'inline-block', width: '80%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
                                <ClassSelectBox
                                    onClassChange={onClassChange}
                                    selectedClassIdx={searchClassIdx}
                                    campus_idx={userInfoVar()?.campus_idx}
                                    style={{ width: '20%', marginRight: 10 }}
                                />
                                <Search
                                    className="headitems-search"
                                    placeholder="Input search text"
                                    // defaultValue={searchValue.text}
                                    // onSearch={(val) =>
                                    //     setSearchValue((prev) => {
                                    //         return {
                                    //             ...prev,
                                    //             text: val,
                                    //         };
                                    //     })
                                    // }
                                    allowClear
                                    style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
                                />
                                <CustomButton danger onClick={deleteConfirm}>
                                    삭제
                                </CustomButton>
                                <CustomButton className="add-student-btn" onClick={() => setStudentModalVisible(true)}>
                                    + Add/&nbsp;<span class="del-text">Del</span>&nbsp;Students
                                </CustomButton>

                                <a href="/" onClick={handleExportToExcel} className='excel-img'>
                                    <img src={imageexcel} alt="download"/>
                                </a>
                            </div>
                        </div>
                        <CustomTable
                            bordered
                            dataSource={dailyTestGroupResultList}
                            columns={columns}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            scroll={{ y: 'calc(100vh - 254px)' }}
                            pagination={{ pageSize: 10 }}
                            size="small"
                            color="#edf3fb"
                        />
                    </HalfWrapper>
                </Col>
                {modalVisible ?
                    <Modal
                        title="Paper Test Download"
                        centered
                        visible={modalVisible}
                        onCancel={() => { setModalVisible(false) }}
                        footer={[
                        ]}
                        className='paperTestPopup'
                    >
                        <table >
                            <tr>
                                <th colspan="6" ><span style={{ color: "#da6425" }}>{testData?.test_series_title}</span>
                                </th>
                            </tr>
                            <tr>
                                <td>Answer</td>
                                {testData?.paper_test?.map((ele) => {
                                    return <>
                                        <td>{ele?.test_folder_name}</td>
                                    </>
                                })}
                            </tr>
                            <tr>
                                <td colspan="6">
                                    <button className="download-pdf-btn" onClick={() => handleDownload(testData)} >Click to Download</button>
                                </td>
                            </tr>
                        </table>
                    </Modal> : ""}
            {studentModalVisible ? (
              <Modal
                title="Add/Delete Student"
                centered
                visible={studentModalVisible}
                onCancel={() => {
                    setStudentModalVisible(false);
                }}
                footer={[]}
                className="paperTestPopup stud-add-del-modal"
              >
                <List
                  header={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <label>Name</label>
                      <div>
                        전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                      </div>
                    </div>
                  }
                  footer={<div></div>}
                  bordered
                  dataSource={students}
                  renderItem={(item, idx) => (
                    <List.Item style={{ display: 'flex' }}>
                      <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx, item.user?.idx)}></Checkbox>
                    </List.Item>
                  )}
                />
                <div className='stud-add-del-btn'>
                  <Button onClick={handleAdd} className="add-btn">Add</Button>
                  <Button onClick={handleDelete} className='del-btn'>Delete</Button>
                </div>
              </Modal>
            ) : (
              ''
            )}
            </Row>
        </>
    );
};

export default SyllabusAssigned;
