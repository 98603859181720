import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, Input, Radio } from 'antd';
import { ZoomOutOutlined, ZoomInOutlined, FullscreenOutlined, FullscreenExitOutlined, DownOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import File from 'src/components/common/File';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { TESTS_SINGLE } from 'src/operations/queries/getTests';
import { useQuery } from '@apollo/client';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Title } = Typography;

const TestBlock = styled.div`
  margin-top: 12px;
`;

const TestSectionBlock = styled.div`
  background: #fff;
`;

const QuestionBlock = styled.div`
  padding: 2px;
  background: #eaecf1f2;
`;

const Preview = (props) => {
  const [test, setTest] = useState({ test_answers: [] });
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [pageWidth, setPageWidth] = useState(400);
  const [fullScreen, setFullScreen] = useState(false);

  let myDocument = React.createRef();

  const { data } = useQuery(TESTS_SINGLE, {
    variables: { idx: parseInt(props.id) },
  });

  useEffect(() => {
    if (myDocument && myDocument.current) {
      setPageWidth(myDocument.current.offsetWidth);
    }

    if (data?.testsSingle) {
      setTest(data?.testsSingle);
    }
  }, [myDocument, data]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Col className="testpreview-wrapper" span={24} style={{ margin: 0 }}>
        <TestBlock>
          <Row gutter={[24, 16]}>
            <Col className="pdffullscreen active inactive" span={fullScreen ? 24 : 16}>
              <div className="openboxlayout">
                <Button type="primary" icon={<DownOutlined />}>
                  Open
                </Button>
              </div>
              <TestSectionBlock className="pdffullscreen-scroll" ref={myDocument}>
                <Document file={test.main_pdf ? test.main_pdf.path : ''} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                      <Page
                        key={page}
                        pageNumber={page}
                        scale={scale}
                        renderAnnotationLayer={true}
                        renderInteractiveForms={true}
                        renderTextLayer={true}
                        width={pageWidth}
                      />
                    ))}
                </Document>
                <Button
                  type="primary"
                  onClick={() => setScale(scale - 0.2)}
                  shape="circle"
                  style={{
                    background: '#3b99ff',
                    position: 'absolute',
                    bottom: '20px',
                    right: '60px',
                  }}
                  icon={<ZoomOutOutlined />}
                />
                <Button
                  type="primary"
                  onClick={() => setScale(scale + 0.2)}
                  shape="circle"
                  style={{
                    background: '#3b99ff',
                    position: 'absolute',
                    bottom: '20px',
                    right: '100px',
                  }}
                  icon={<ZoomInOutlined />}
                />

                <Button
                  type="primary"
                  onClick={() => {
                    setPageWidth(400);
                    setFullScreen(!fullScreen);
                  }}
                  shape="circle"
                  style={{
                    background: '#3b99ff',
                    position: 'absolute',
                    bottom: '60px',
                    right: '60px',
                  }}
                  icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                />
              </TestSectionBlock>
            </Col>

            {!fullScreen ? (
              <Col span={8} className="pdffullscreen-box inactive active">
                <TestSectionBlock className="pdffullscreen-box-scroll" style={{ background: '#eaecf1f2', padding: 5 }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: 10,
                    }}
                  >
                    <Button
                      size="large"
                      style={{
                        background: '#1890ff',
                        color: '#fff',
                        borderRadius: 5,
                      }}
                      onClick={() => props.history.goBack()}
                    >
                      Back
                    </Button>
                  </div>
                  <Row gutter={[24, 16]} style={{ background: '#fff', padding: 10 }}>
                    <Title
                      level={4}
                      style={{
                        textAlign: 'center',
                        color: '#3b99ff',
                        width: '100%',
                      }}
                    >
                      {test.title}
                    </Title>
                  </Row>
                  {test.extra_pdf ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col span={14}>
                        <Title level={5}>Explanation PDF</Title>
                      </Col>
                      <Col span={10}>
                        <File
                          file={test.extra_pdf}
                          thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {test.extra_audio ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col span={14}>
                        <Title level={5}>Audio</Title>
                      </Col>
                      <Col span={10}>
                        <File
                          file={test.extra_audio}
                          thumbStyle={{ fontSize: 15, width: '100%', color: '#887053', flatAudio: true }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {test.extra_video ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col span={16}>
                        <Title level={5}>Video</Title>
                      </Col>
                      <Col span={8}>
                        <File
                          file={test.extra_video}
                          thumbStyle={{ fontSize: 40, width: '100%', color: '#887053' }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  <div className="questionblock-section">
                    {test.test_answers.map((answer, indx) => {
                      return (
                        <QuestionBlock key={answer.idx}>
                          <Row gutter={[24, 16]} style={{ padding: 5 }}>
                            <Col span={5} style={{ paddingLeft: 20 }}>
                              <Title level={5}>{indx + 1}.</Title>
                            </Col>
                            {renderQuestions(answer, indx, 17)}
                            {/* <Col span={6} style={{ border: '1px solid #ADADAD' }}>
                                {answer.pdf ? (
                                  <File
                                    file={answer.pdf}
                                    thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
                                    textStyle={{
                                      textAlign: 'center',
                                      color: '#887053',
                                      fontSize: 12,
                                      display: 'inline-block',
                                      width: '100%',
                                    }}
                                  />
                                ) : null}
                              </Col>
                              <Col span={6} style={{ border: '1px solid #ADADAD' }}>
                                {answer.audio ? (
                                  <File
                                    file={answer.audio}
                                    thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
                                    textStyle={{
                                      textAlign: 'center',
                                      color: '#887053',
                                      fontSize: 12,
                                      display: 'inline-block',
                                      width: '100%',
                                    }}
                                  />
                                ) : null}
                              </Col>
                              <Col span={6} style={{ border: '1px solid #ADADAD' }}>
                                {answer.video ? (
                                  <File
                                    file={answer.video}
                                    thumbStyle={{ fontSize: 40, width: '100%', color: '#887053' }}
                                    textStyle={{
                                      textAlign: 'center',
                                      color: '#887053',
                                      fontSize: 12,
                                      display: 'inline-block',
                                      width: '100%',
                                    }}
                                  />
                                ) : null}
                              </Col> */}
                          </Row>
                        </QuestionBlock>
                      );
                    })}
                  </div>
                </TestSectionBlock>
              </Col>
            ) : null}
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default withRouter(Preview);

const renderQuestions = (question, idx, span) => {
  const answers = question.answer.split('|');
  if (answers[0] === '') {
    return (
      <Col span={span} style={{ paddingRight: 40, paddingLeft: 0 }}>
        <Input.TextArea disabled={true} key={idx} placeholder="Enter Answer" autoSize={{ minRows: 4, maxRows: 6 }} />
      </Col>
    );
  } else if (question.option_count) {
    let options = new Array(question.option_count).fill({});
    options = options.map((e, i) => ({ label: i + 1, value: i + 1 }));
    return (
      <Col span={span} style={{ padding: 0 }}>
        <Radio.Group disabled={true} options={options} optionType="button" buttonStyle="solid" style={{ background: '#eaecf1f2' }} />
      </Col>
    );
  } else if (question.condition === 'OR') {
    return (
      <Col span={span} style={{ paddingRight: 40, paddingLeft: 0 }}>
        <Input disabled={true} placeholder="Enter Answer" />
      </Col>
    );
  } else {
    return (
      <Col span={span} style={{ paddingRight: 40, paddingLeft: 0 }}>
        {answers.map((a, aix) => (
          <Input disabled={true} key={a} name={`${a}-a`} placeholder="Enter Answer" />
        ))}
      </Col>
    );
  }
};
