import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Form, Button } from 'antd';

import FormField from 'src/components/common/FormField';
import { openNotification } from '../common/Notification';
import { useForm } from 'src/utils/hooks';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const MenuItemDeleteModal = ({ visible, handleCancel, menuItem, fcInfo, selectedUserType }) => {
  const [form] = Form.useForm();
  const { onSubmit, values } = useForm(formCallback, {});

  const [deleteCompanyUserMenuItem, { loading }] = useMutation(mutations.company.DELETE_COMPANY_USER_MENU_ITEM, {
    variables: {
      menu_item_idx: menuItem.idx,
    },

    refetchQueries: [
      {
        query: queries.company.GET_COMPANY_USER_MENU_LIST,
        variables: {
          company_idx: fcInfo.idx,
          user_type: selectedUserType,
        },
      },
    ],

    onCompleted(data) {
      if (data) {
        openNotification('메뉴 항목이 삭제되었습니다!');
        handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function formCallback() {
    deleteCompanyUserMenuItem();
  }

  return (
    <>
      <Modal
        entered
        title={`메뉴 항목 삭제`}
        visible={visible}
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="button" loading={loading} type="primary" onClick={() => form.submit()}>
            등록
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          onFinish={onSubmit}
          layout="inline"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
          initialValues={menuItem}
        >
          <FormField>"{menuItem.item_name}" 를 정말로 삭제하시겠습니까?</FormField>
        </Form>
      </Modal>
    </>
  );
};

MenuItemDeleteModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default MenuItemDeleteModal;
