import React, { useState } from 'react';
import { Layout, Input, Select, Space, Button, Option } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

{
  /* ignore, isKeyword, similarReplace in second, third box  */
}
const ModalForExtend = ({ isOpen2, setIsOpen2 }) => {
  function closeee() {
    setIsOpen2(!setIsOpen2);
  }
  return (
    <>
      <div className="add-wrap" style={{ border: '1px solid #252525', width: '100%' }}>
        <div>
          <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
            <span className="closeBtn">
              <CloseOutlined />
            </span>

            <h3> ignore </h3>
            <div className="box" style={{ display: 'grid', gridTemplateColumns: '4fr 2fr' }}>
              <div className="multiple-input-wrap">
                <Input type="text" placeholder="type the words for lessonPhrase" />
                <Button type="danger">
                  <CloseOutlined />
                </Button>
                <Button type="primary" style={{ position: 'relative' }}>
                  <PlusOutlined />
                </Button>
              </div>
              <div className="multiple-btn-wrap"></div>

              <div className="multiple-input-wrap" style={{ marginTop: '1rem' }}>
                <Input type="text" placeholder="type the number for lessonPhraseActive" />
                <Button type="danger">
                  <CloseOutlined />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
            <span className="closeBtn">
              <CloseOutlined />
            </span>

            <h3> isKeyword </h3>

            <div className="box" style={{ display: 'grid', gridTemplateColumns: '4fr 2fr' }}>
              <div className="multiple-input-wrap">
                <Input type="text" placeholder="type the words for lessonPhrase" />
                <Button type="danger">
                  <CloseOutlined />
                </Button>
                <Button type="primary" style={{ position: 'relative' }}>
                  <PlusOutlined />
                </Button>
              </div>
              <div className="multiple-btn-wrap"></div>

              <div className="multiple-input-wrap" style={{ marginTop: '1rem' }}>
                <Input type="text" placeholder="type the number for lessonPhraseActive" />
                <Button type="danger">
                  <CloseOutlined />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="inner-wrap-new" style={{ gridTemplateColumns: '1fr 4fr' }}>
            <span className="closeBtn">
              <CloseOutlined />
            </span>

            <h3>similarReplace</h3>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input type="text" style={{ width: '100%', maxWidth: '300px' }} placeholder="Before" />
              <Input type="text" style={{ width: '100%', maxWidth: '300px' }} placeholder="After" />
              <Button type="danger">
                <CloseOutlined />
              </Button>
              <Button type="primary" style={{ position: 'relative' }}>
                <PlusOutlined />
              </Button>
            </div>

            <div className="multiple-btn-wrap"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalForExtend;
