import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Checkbox, Button, Dropdown, Menu, Space, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CustomTable from 'src/components/common/CustomTable';
import { useHistory, useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import { getLixileScoreObject } from 'src/utils/index';
import StarWordsMenuGroup from 'src/components/common/StarWordsMenuGroup';
import { EXTERNAL_STUDY_BOOK } from 'src/operations/queries/externalStudy';
import { useQuery } from '@apollo/client';
import { getCurrentToken } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import styled from 'styled-components';
const Wrapper = styled.div`
  width: 100%;
  // height: 95%;
  min-height: 800px;
  /* background-color: #ffda00; */
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const AssignButton = styled(RowFlex)`
  border: 2px solid #ffbe00;
  border-radius: 5px;
  padding: 5px 25px;
  color: #000000;
  background-color: #ffffff;
  height: 32px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
`;
const SkillTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #3b3838;
  /* padding-bottom: 20px; */
`;
const SkillOutWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  border-top: 4px solid #595959;
  height: 160px;
  padding: 3px 0;
`;
const SkillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  background-color: #fff;
  width: 100%;
  height: 150px;
`;
const SkillBox = styled(RowFlex)`
  width: 50%;
  background-color: ${(props) => (props.checked ? '#f8ecdc' : '#ffffff')};
  border: 1px solid #f3f2f2;
  font-size: 14px;
  padding: 5px 10px;
`;
const SkillCheckBox = styled.input`
  :checked + label::before {
    background-color: #fff;
    color: #000;
  }
`;
const StarWordsLessonListForAssign = () => {
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);
  const [filteredbook, setFilteredbook] = useState([]);
  const [searchValue, setSeachValue] = useState('');
  // const [bookIdx, setBookIdx] = useState();
  // const [selectedSkills, setSelectedSkills] = useState([]);
  const history = useHistory();
  const { state } = useLocation();
  const [level, setLevel] = useState('All');
  const [pg, setPg] = useState({ current: 1, pageSize: 10 });
  const [ch, setCh] = useState(
    books?.map((e, i) => {
      let obj;
      obj = {
        no: e.no,
        code: e.code,
        value: false,
        book_no: e.book_no,
      };
      return obj;
    }),
  );
  const token = getCurrentToken();
  // const [fromSkill, setFromSkill] = useState(null);
  const { data: userBook, loading } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      type: 'starwords',
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
  useEffect(() => {
    if (state) {
      if (state?.level) {
        setLevel(parseInt(state?.level));
      } else {
        setLevel('All');
      }
    }
  }, [state]);
  useEffect(() => {
    if (userBook?.getExternalUserBook?.length > 0) {
      try {
        let tmplist = _.sortBy(userBook?.getExternalUserBook, 'idx');
        let list = tmplist?.map((e, i) => {
          e.no = i + 1;
          e.level = e.code ? parseInt(e.code[4]) : null;
          // e.lexileVal = e.lexile_val ? e.lexile_val : setLexileValue(e.lexile);
          // e.mxObj = setMxLevel(e.lexileVal);
          // e.level = e.mx_level !== undefined ? (e.mx_level === 'PH' ? 0 : e.mx_level) : e.mxObj?.mx;
          return { book_no: e.book_no, code: e.code, no: i + 1, level: e.code ? parseInt(e.code[4]) : null, idx: e.idx, title: `${e.title} ${e.code[e.code.length-1]}` };
        });
        setBooks(list);
      } catch (e) {
        setError(e);
      }
    }
  }, [userBook]);
  
  useEffect(() => {
    if (!loading && books) {
      if (level === 'All' && searchValue === '') {
        let list = books.reduce((acc, book, key) => {
          // const bookNoMatch = bookIdx?.includes(book.book_no) ? true : false;
          acc.push(book);
          // const index = Math.floor(key);
          // book.no = key + 1;
          return acc;
        }, []);

        setFilteredbook(list);
      } else {
        let list2 = books?.reduce((acc, cur, key) => {
          const levelfilter = level !== 'All' ? String(cur.level) === String(level) : true;
          // const typefilter = type !== 'All' ? cur.type === type : true;
          const searchfilter = searchValue !== '' ? cur.title.toLowerCase().includes(searchValue.toLowerCase()) : true;
          // const bookNoMatch = state?bookIdx.includes(cur.book_no):true;
          // const bookNoMatch = bookIdx?.includes(cur.book_no) ? true : false;
          if (levelfilter && searchfilter) {
            // if (levelfilter && searchfilter) {
            // cur.no = key + 1;
            acc.push(cur);
          }
          return acc;
        }, []);

        setFilteredbook(list2);
      }
    }
  }, [books, level, searchValue, loading, userBook]);
  useEffect(() => {
    setPg({
      ...pg,
      current: 1,
    });
  }, [filteredbook, level]);

  useEffect(() => {
    setCh(
      filteredbook?.map((e, i) => {
        const uniqueBookIdx = new Set();
        state?.bookIdxArr?.forEach((item) => {
          uniqueBookIdx.add(item.book_idx);
          // item?.book_idx?.forEach((bookIdx) => {
          //   uniqueBookIdx.add(bookIdx);
          // });
        });
        const uniqueBookIdxArray = Array.from(uniqueBookIdx);
        const match = uniqueBookIdxArray.includes(e.book_no);
        // const match = [4194, 4303, 4231, 4179, 4253].includes(e.book_no)
        const obj = {
          no: e.no,
          code: e.code,
          value: match ? true : false,
          book_no: e.book_no,
        };
        return obj;
      }),
    );
  }, [books, filteredbook]);

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  function onChange(e, idx, book_no, code) {
    ch[ch.findIndex((element) => element.no === idx)] = { no: idx, code: code, value: e.target.checked, book_no: book_no };
    setCh(JSON.parse(JSON.stringify(ch)));
  }
  const currentPage = (i) => {
    return Math.floor(i / pg.pageSize) === pg.current - 1;
  };
  function onCheckAllChange(e) {
    if (e.target.checked) {
      const arr2 = _.cloneDeep(ch);
      const checked = filteredbook
        .filter((e, i) => {
          return currentPage(i);
        })
        .map((e) => {
          const obj = {
            no: e.no,
            code: e.code,
            value: true,
          };
          return obj;
        });
      const unChecked = arr2.filter((e, i) => {
        return !currentPage(i);
      });
      setCh([...checked, ...unChecked].sort((a, b) => a.id - b.id));
    } else {
      setCh(
        books?.map((e, i) => {
          let obj;
          obj = {
            no: e.no,
            code: e.code,
            value: false,
            book_no: e.book_no,
          };
          return obj;
        }),
      );
    }
  }
  if (loading) {
    return <div className="wait-comment">loading...</div>;
  }
  if (error) {
    return <div className="wait-comment">에러가 발생했습니다.</div>;
  }
  const handleAssignData = () => {
    let data = [];
    filteredbook?.forEach((item, index) => {
      ch?.forEach((checked, index2) => {
        if (item.no === checked.no && checked.value) {
          data.push(item);
        }
      });
    });
    if (data?.length > 0) {
      localStorage.setItem('selectedLessons', JSON.stringify(data));
      history.push(`/star-words/lessonlist/assign`);
    } else {
      alert('레슨을 선택해주세요');
    }
  };
  const lv = (
    <Menu>
      {level ? (
        <>
          <Menu.Item key="All">
            <a rel="noopener noreferrer" onClick={() => setLevel('All')}>
              All
            </a>
          </Menu.Item>
          {[1, 2, 3, 4, 5, 6].map((e) => (
            <Menu.Item key={e}>
              <a rel="noopener noreferrer" onClick={() => setLevel(e)}>
                LV {e}
              </a>
            </Menu.Item>
          ))}
        </>
      ) : (
        <></>
      )}
    </Menu>
  );
  const columns = [
    {
      title: () => {
        const tmp = ch
          ?.filter((e, i) => {
            return currentPage(i);
          })
          .map((e) => {
            return e.value;
          });
        return <Checkbox checked={tmp?.filter((s) => s).length === pg.pageSize} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox
              id={record.id}
              checked={ch?.length ? ch[ch?.findIndex((element) => element.no === record.no)]?.value : ''}
              onChange={(e) => onChange(e, record.no, record.book_no, record.code)}
            ></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 60,
      render: (text, record, idx) => {
        return <div>{(pg.current - 1) * pg.pageSize + idx + 1}</div>;
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      sorter: codeSorter,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => a.title && a.title.localeCompare(b.title),
    },

    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      align: 'center',
      width: 120,
      render: (_, record) => {
        // const { key, done_count, onDeleteAssignedExtenalGrp } = row;
        // console.log("key",key);
        return (
          <a
            style={{ backgroundColor: '#559bdb', color: '#fff', padding: '5px 10px' }}
            href={`starwords://login?id=${userInfoVar()?.id}&name=${userInfoVar()?.name}&lesson_code=${record.code}&token=${token}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            view
          </a>
        );
      },
    },
  ];

  return (
    <Wrapper className="cust-assign-book">
      <RowFlex style={{ justifyContent: 'center' }}>
        <StarWordsMenuGroup currentMenu={'assign'} />
      </RowFlex>
      <div className="library-con" style={{ margin: '0 auto', minHeight: '800px' }}>
        <div className="main-inner-wrap library float__box">
          <div className="section__inner">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="library__top float__box">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="float__right">
                  {/* <SubTitle>Assigned Page</SubTitle> */}
                  <Dropdown overlay={lv} trigger={['click']}>
                    <Link onClick={(e) => e.preventDefault()}>
                      <Space>
                        {level === 'All' ? 'Level' : level > 0 ? `MX ${String(level).padStart(2, '0')}` : `MX ${level}`}
                        <DownOutlined />
                      </Space>
                    </Link>
                  </Dropdown>
                </div>
                <Button style={{ marginLeft: 5 }} onClick={handleAssignData}>
                  Assign
                </Button>
                {/* </Link> */}
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input.Search
                  className="inputsearch-classroom inputse`arch-classroom-wrap"
                  placeholder="title search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  value={searchValue}
                />
                {/* <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                  <RedoOutlined />
                </CustomButton> */}
              </div>
            </div>
            <div className="library__content">
              <div className="library__box">
                <BookTable
                  locale={{ emptyText: 'No results' }}
                  key={(record) => record.book_no}
                  className="contenttest-table"
                  dataSource={filteredbook}
                  columns={columns}
                  loading={loading}
                  bordered
                  scroll={{ y: 'calc(100vh - 244px)' }}
                  pagination={pg}
                  onChange={(pagination) => {
                    setPg(pagination);
                  }}
                  size="big"
                  color="#edf3fb"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(StarWordsLessonListForAssign);
const BookTable = styled(CustomTable)`
  .ant-table-tbody {
    min-height: 600px;
  }
  .ant-table-body {
    min-height: 600px;
    table {
      min-height: 600px;
    }
  }
`;
function codeSorter(a, b) {
  const [l1, _, u1, d1] = a.code.match(/\d+/g).map(Number);
  const [l2, __, u2, d2] = b.code.match(/\d+/g).map(Number);

  if (l1 !== l2) return l1 - l2;

  if (u1 !== u2) return u1 - u2;

  return d1 - d2;
}
