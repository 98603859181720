import { gql } from '@apollo/client';

export const TEST_DOCUMENTS = gql`
  query testDocumentList($type: String, $q: String, $page: Int, $take: Int) {
    testDocumentList(type: $type, q: $q, page: $page, take: $take) {
      idx
      title
      code
      meta
      idate
      type
      level
      total_questions
      main_pdf
      extra_data
      questions {
        idx
        parent_id
        number
        question
        question_type
        information_text
        question_context
        question_direction
        answer
        answer_description
        options
        option_count
        condition
        idate
        meta
        is_group
      }
      user {
        idx
        name
      }
      campus {
        idx
        name
      }
      idate
    }
  }
`;

export const TEST_DOCUMENTS_SINGLE = gql`
  query testDocumentSingle($idx: Int!) {
    testDocumentSingle(idx: $idx) {
      idx
      title
      code
      meta
      idate
      type
      level
      total_questions
      main_pdf
      extra_data
      questions {
        idx
        parent_id
        number
        question
        question_type
        information_text
        question_context
        question_direction
        answer
        answer_description
        options
        option_count
        condition
        idate
        meta
        mp3
        image
      }
      user {
        idx
        name
      }
      campus {
        idx
        name
      }
      company {
        idx
        name
      }
      idate
    }
  }
`;
