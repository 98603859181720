import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const StatusCheckTh = styled.th`
  border: 1px solid #a4d0e9;
  background-color: #fafafa;
  font-size: 0.8em;
  @media print {
    font-size: 0.5em;
  }
`;
const StatusCheckTd = styled.td`
  border: 1px solid #a4d0e9;
  text-align: center;
  padding: 5px 0;
  font-size: 0.8em;
  @media print {
    font-size: 0.5em;
  }
`;

const STATUS_GRADE_LIST = [5, 4, 3, 2, 1];
const STATUS_TYPE_LIST = [
  ['attendance', 'Attendance(출결사항)'],
  ['attitude', 'Attitude(학습태도)'],
  ['participation', 'Participation(수업태도)'],
  ['homework', 'Homework(과제수행)	'],
];

const StatusCheckTable = ({ onChangeStatus, data, editable = false }) => {
  const [initialValues, setInitialValues] = useState({});
  console.log('StatusCheckTable', data);
  // const isSameStatus = useCallback(
  //   (status_type, grade) => {
  //     if (data?.study_status_result) {
  //       const dataJSON = JSON.parse(data?.study_status_result);
  //       console.log('dataJSON', dataJSON, status_type, grade);
  //       if (parseInt(dataJSON[status_type]) === grade) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [data],
  // );
  const isSameStatus = (status_type, grade) => {
    if (data?.study_status_result) {
      const dataJSON = JSON.parse(data?.study_status_result);
      console.log('dataJSON', dataJSON, status_type, grade);
      if (parseInt(dataJSON[status_type]) === grade) {
        return true;
      }
    }
    return false;
  };
  const isChecked = (status_type, grade) => {
    if (parseInt(initialValues[status_type]) === grade) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (editable && data) {
      if (data?.study_status_result) {
        const dataJSON = JSON.parse(data.study_status_result);
        onChangeStatus({ ...dataJSON });
        setInitialValues({ ...dataJSON });
      }
    }
  }, [data, editable]);
  return (
    <table style={{ width: '100%', borderTop: '3px solid #a4d0e9' }}>
      <thead>
        <tr>
          <StatusCheckTh></StatusCheckTh>
          <StatusCheckTh>Excellent</StatusCheckTh>
          <StatusCheckTh>Very Good</StatusCheckTh>
          <StatusCheckTh>Average</StatusCheckTh>
          <StatusCheckTh>Developing</StatusCheckTh>
          <StatusCheckTh>
            Needs
            <br />
            Improvement
          </StatusCheckTh>
        </tr>
      </thead>
      <tbody>
        {STATUS_TYPE_LIST.map(([name, title], key1) => (
          <tr>
            <StatusCheckTh style={{ textAlign: 'left', paddingLeft: 10 }}>{title}</StatusCheckTh>
            {STATUS_GRADE_LIST.map((val, key) => (
              <StatusCheckTd>
                {editable ? (
                  <Input
                    key={`${name}${key}`}
                    type="radio"
                    name={name}
                    value={val}
                    //defaultChecked={isSameStatus(name, val) ? true : false}
                    checked={isChecked(name, val) ? true : false}
                    onChange={() => {
                      const obj = {};
                      obj[name] = val;
                      onChangeStatus({ ...obj });
                      setInitialValues({ ...initialValues, ...obj });
                    }}
                  />
                ) : (
                  <>{isSameStatus(name, val) ? <span>O</span> : ``}</>
                )}
              </StatusCheckTd>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StatusCheckTable;
