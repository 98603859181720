import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Modal, Button, Typography, Input, Col, Row } from 'antd';
import StatusCheckTableAfterSchool from './components/StatusCheckTableAfterSchool';
import FinalTestInputTableAfterSchool from './components/FinalTestInputTableAfterSchool';
// import FinalTestInputTable from './components/FinalTestInputTable';
// import StudyCompleteTable from './components/StudyCompleteTable';
import BasicInfoTable from './components/BasicInfoTable';
import { GET_CLASSS_INFO_FOR_REPPORT } from 'src/operations/queries/getClass';
import { CREATE_REPORT_CARD } from 'src/operations/mutations/reportcard';
import { CLASS_STUDENT_LIST_FOR_REPORT_CARD } from 'src/operations/queries/getClass';
import styled from 'styled-components';
import StudyCompleteTableAfterSchool from './components/StudyCompleteTableAfterSchool';

const { Title } = Typography;
const { TextArea } = Input;
const CommentTextArea = styled(TextArea)`
  position: relative;
  .ant-input-textarea-show-count::after {
    position: absolute;
    top: -25px;
    right: 10px;
  }
`;

const ReportCardWriteModalAfterSchool = ({
  class_idx,
  user_name,
  user_idx,
  dateRange,
  visible,
  onCancel,
  refetchReport,
  reportCardItem,
  classBookCodes,
  classBooks,
}) => {
  const [refresh, setRefresh] = useState(0);
  const [score, setScore] = useState({});
  const [studyStatus, setStudyStatus] = useState({});
  const [teachersCommentKo, setTeachersCommentKo] = useState();
  const [loaded,setLoaded] = useState(false);
  const { data } = useQuery(GET_CLASSS_INFO_FOR_REPPORT, { variables: { class_idx }, skip: !class_idx });
  const [createRepotCard, { loading: loadingCreate }] = useMutation(CREATE_REPORT_CARD, {
    onCompleted: (res) => {
      if (res?.createCulpReportCard?.idx) {
        setScore({});
        setStudyStatus({});
        setTeachersCommentKo('');
        setRefresh((prev) => prev + 1);
        refetchReport();
        Modal.info({
          title: '저장했습니다.',
          onOk() {
            onCancel();
          },
        });
      }
    },
    onError: (e) => {
      alert('저장중 오류가 발생했습니다.');
      console.log('error===>', e);
    },
    refetchQueries: [
      {
        query: CLASS_STUDENT_LIST_FOR_REPORT_CARD,
        variables: { class_idx, hasReportCard: true },
      },
    ],
  });
  const basicInfo = useMemo(() => {
    if (data) {
      return {
        class_name: data?.classInfo?.name,
        book_title: data?.classInfo?.book?.book_sub[0]?.title,
        teacher_name: data?.classInfo?.class_teacher[0]?.user?.name,
        is_phonics: parseInt(data?.classInfo?.book?.level_no) > 0 ? false : true,
      };
    }
    return null;
  }, [data]);

  const handleSave = useCallback(() => {
    const [sdate, edate] = dateRange.map((d) => d.toDate());
    const score_result = {
      ...score,
    };
    const study_status_result = {
      ...studyStatus,
    };
    const comment_ko = teachersCommentKo;

    const dataForCreate = {
      class_idx: parseInt(class_idx),
      user_idx: parseInt(user_idx),
      sdate,
      edate,
      score_result,
      study_status_result,
      comment_ko,
    };
    createRepotCard({ variables: { ...dataForCreate } });
  }, [class_idx, user_idx, dateRange, score, studyStatus, teachersCommentKo]);

  const handleChangeScore = (scoreObj) => {
    setScore(scoreObj);
  };

  const handleChangeStatus = (status) => {
    setStudyStatus({ ...studyStatus, ...status });
  };

  const handleCancel = () => {
    setScore({});
    setStudyStatus({});
    setTeachersCommentKo('');
    setRefresh((prev) => prev + 1);
    onCancel();
  };

  useEffect(() => {
    if (reportCardItem&&reportCardItem?.comment_ko) {
      setTeachersCommentKo(reportCardItem?.comment_ko);
    }
    else{
      setTeachersCommentKo('')
    }
  }, [reportCardItem]);
  const eclassbooks =
    classBookCodes.length > 1
      ? `${classBooks[classBookCodes[0]]?.book_sub[0].title} 외 ${classBookCodes.length - 1}권`
      : classBooks[classBookCodes[0]]?.book_sub[0].title;

  return (
    <Modal
      title={
        <div style={{ textAlign: 'right', paddingRight: 40 }}>
          {!loaded&&<span>데이터를 불러오는 중입니다. 잠시만 기다려주세요.</span>}&nbsp;
          <Button type="primary" loading={loadingCreate||!loaded} onClick={handleSave} disabled={!loaded}>
            저장하기
          </Button>
        </div>
      }
      maskClosable={false}
      visible={visible}
      onCancel={handleCancel}
      width={'60%'}
      footer={null}
    >
      <BasicInfoTable user_name={user_name} dateRange={dateRange} basicInfo={basicInfo} eclassbooks={eclassbooks} />
      <Row gutter={[16, 16]}>
        <StudyCompleteTableAfterSchool
          bookCode={data?.classInfo?.book_code}
          class_idx={class_idx}
          user_idx={user_idx}
          book_codes={classBookCodes.map((v) => classBooks[v]?.book_code)}
          classBooks={classBookCodes.map((v) => classBooks[v])}
          study_range={null}
          isPhonics={basicInfo?.is_phonics}
        />
        <Col span={24}>
          <FinalTestInputTableAfterSchool
            key={`final-test-input-table${refresh}`}
            class_idx={class_idx}
            user_idx={user_idx}
            dateRange={dateRange}
            onChangeScore={handleChangeScore}
            bookTitle={basicInfo?.book_title}
            result={reportCardItem?.score_result}
            editable={true}
            setLoaded={setLoaded}
            classBooks={classBookCodes.map((v) => classBooks[v])}
          />
        </Col>
        <Col span={24}>
          <Title level={4}>수업현황</Title>
          <StatusCheckTableAfterSchool
            key={`status-check-table${refresh}`}
            onChangeStatus={handleChangeStatus}
            data={reportCardItem}
            editable={true}
            basicInfo={basicInfo}
          />
        </Col>
        <Col span={24}>
          <Title level={4}>
            Teacher's Comments <span style={{ fontWeight: 'normal', fontSize: '0.8em' }}>(400자 이상 입력시 2장으로 출력됩니다.)</span>
          </Title>
          <CommentTextArea
            value={teachersCommentKo}
            onChange={(e) => {
              setTeachersCommentKo(e.target.value);
            }}
            showCount={{ formatter: ({ count }) => `${count} bytes` }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ReportCardWriteModalAfterSchool;
