import React, { useState, useMemo, useEffect } from 'react';
// import { Switch, Route, Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { GET_ORDER_ITEM_LIST_META } from 'src/operations/queries/getLevelTest';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { getMetaExamId } from 'src/utils';
import useSound from 'use-sound';
import clipGuide from 'src/sounds/meta/1.mp3';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import dayjs from 'dayjs';
// const { Option } = Select;

const InnerWrap = styled.div`
  background-color: #fff;
  width: 75%;
  height: 70%;
  padding: 0.5rem 1rem;
  margin: 0rem auto;
  border-radius: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 2560px) and (aspect-ratio: 16/10) {
    width: 75%;
    height: calc(100vh - 35rem);
  }

  @media screen and (max-width: 1600px) {
    height: 65%;
  }

  @media screen and (max-width: 1440px) and (aspect-ratio: 16/10) {
    height: 60%;
  }

  @media screen and (max-width: 1336px) and (orientation: landscape) {
    height: 70%;
  }

  @media screen and (max-width: 1280px) {
    height: 65%;
    padding: 0rem 1rem;
    margin: 0rem auto;
  }

  @media screen and (max-width: 1024px) {
    height: 55%;
    padding: 0rem 1rem;
    margin: 0rem auto;
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    height: auto;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    height: 40%;
  }

  @media screen and (max-width: 950px) and (orientation: landscape) {
    width: 800px;
    height: 300px;
  }
`;

const InstructionalMetaExamType = () => {
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const isWEnglish = dataUser?.getUserData?.campus?.company?.idx === 1016 ? true : false;
  const history = useHistory();
  const client = useApolloClient();
  const location = useLocation();
  const [userObj, setUserObj] = useState({});
  if (!location?.state?.idx_list && localStorage.getItem('meta_idx_list')) {
    location.state = { idx_list: JSON.parse(localStorage.getItem('meta_idx_list')) };
  }

  const [playStart, { stop: stopPlay }] = useSound(clipGuide, { volume: 0.5 });
  const { loading, data } = useQuery(GET_ORDER_ITEM_LIST_META, { variables: { idx_array: location?.state?.idx_list } });

  const levels = useMemo(() => {
    let levels = {};
    if (data) {
      for (let i = 0; i < data?.orderItemListByIdx?.length; i++) {
        if (data?.orderItemListByIdx[i].order_leveltest) {
          for (let j = 0; j < data?.orderItemListByIdx[i]?.order_leveltest?.length; j++) {
            const item = data?.orderItemListByIdx[i]?.order_leveltest[j];
            let done = false;
            if (item?.assigned_tests?.assigned_test_users) {
              if (item?.assigned_tests?.assigned_test_users[0].status !== 1) {
                done = true;
              }
            }
            if (item?.assigned_tests?.tests?.test_type) {
              levels[item?.assigned_tests?.tests?.test_type] = {
                title: `${item?.assigned_tests?.tests?.title}`,
                assigned_tests_idx: item?.assigned_tests?.idx,
                done,
                batch_type: `${item?.assigned_tests?.tests?.batch_type}`,
              };
            }
            if (
              Object.keys(userObj).length === 0 &&
              item?.assigned_tests?.assigned_test_users?.length > 0 &&
              item?.assigned_tests?.assigned_test_users[0]?.user
            ) {
              const newUserObj = item?.assigned_tests?.assigned_test_users[0]?.user;
              setUserObj({
                id: getMetaExamId(newUserObj.birthday, newUserObj?.parent_phone),
                korean_name: newUserObj.english_name,
              });
            }
          }
        }
      }
    }
    return levels;
  }, [data]);
  useEffect(() => {
    if (playStart && Object.keys(levels).length !== 0) {
      setTimeout(() => {
        playStart();
      }, 1000);
    }
  }, [playStart, levels]);

  if (!location?.state?.idx_list) {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/meta-login');
    return <Redirect to={`/meta-login`} />;
  }
  const type = Object.keys(levels).length === 0 ? '' : 'Type-' + levels[Object.keys(levels)[0]].batch_type;
  const isPreTest = type.startsWith('Type-PRE');

  const handleClick = (data) => {
    if (data.done) {
      return;
    }
    // history.push(`/MetaExam/MetaTestStart/${data.assigned_tests_idx}`);
  };

  const stopSoundAndLeave = () => {
    if (stopPlay) {
      stopPlay();
    }
    history.push('/MetaExam/VocaTestSample');
  };

  // const menuText = <span>Click here to start the exam</span>;
  const chkText = <span>Completed then it will have green check mark.</span>;
  return (
    <>
      <div className={isWEnglish ? 'meta-con meta-full-con guide-con w-english-bg' : 'meta-con meta-full-con guide-con'}>
        <div className="meta-header type-header">
          <div>
            <h2 style={{ marginBottom: '0' }}>
              {' '}
              <span style={{ margin: '0 0.5rem' }}>{userObj?.korean_name}</span> {userObj?.id}
            </h2>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <h2>{dayjs().format('YYYY-MM-DD')}</h2>
          </div>
        </div>
        <InnerWrap>
          <div className="meta-header">
            <h1 className="main-tit">
              {isWEnglish ? '' : 'MetaExam'} {type}
            </h1>
          </div>
          {Object.keys(levels).length == 0 && (
            <div className="exam-type-con">
              <h2>No Test Assigned To You</h2>
            </div>
          )}
          {Object.keys(levels).length != 0 && (
            <div className="exam-type-con">
              {/* <Tooltip
                getPopupContainer={(trigger) => trigger.parentNode}
                defaultVisible="true"
                visible="true"
                trigger="focus"
                placement="top"
                title={menuText}
              > */}
              <div className="test-wrap">
                <div className="arrow-wrap">
                  <p className="guide-arrow">
                    <span class="arrow-body">Click here</span>
                  </p>
                </div>
                <div onClick={stopSoundAndLeave}>
                  <img
                    src="/images/metaIcn1.png"
                    style={{ border: '2px dashed orange', borderRadius: '50%', padding: '0 0.3rem' }}
                    alt="icn1"
                  />
                  <h3>Vocabulary</h3>
                </div>
                <Tooltip
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultVisible="true"
                  visible="true"
                  trigger="focus"
                  placement="bottom"
                  title={chkText}
                >
                  <div>
                    <img
                      src="/images/check_off.png"
                      alt="check_off"
                      style={{ border: '2px dashed orange', borderRadius: '50%', padding: '0.1rem' }}
                      className="meta-chkImg"
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="test-wrap">
                <div onClick={() => handleClick(levels['reading'])}>
                  <img src={isPreTest ? '/images/metaIcn2_2.png' : '/images/metaIcn2.png'} alt="icn2" />
                  <h3>Reading</h3>
                </div>
                {levels['reading'].done && <img src="/images/check_off.png" alt="check_off" className="meta-chkImg" />}
                {!levels['reading'].done && <img src="/images/check_on.png" alt="check_on" className="meta-chkImg" />}
              </div>

              <div className="test-wrap">
                <div onClick={() => handleClick(levels['listening'])}>
                  <img src="/images/metaIcn3.png" alt="icn3" />
                  <h3>Listening</h3>
                </div>
                {levels['listening'].done && <img src="/images/check_off.png" alt="check_off" className="meta-chkImg" />}
                {!levels['listening'].done && <img src="/images/check_on.png" alt="check_on" className="meta-chkImg" />}
              </div>

              {levels['grammar'] && (
                <div className="test-wrap">
                  <div onClick={() => handleClick(levels['grammar'])}>
                    <img src="/images/metaIcn4.png" alt="icn4" />
                    <h3>Grammar</h3>
                  </div>
                  {levels['grammar'].done && <img src="/images/check_off.png" alt="check_off" className="meta-chkImg" />}
                  {!levels['grammar'].done && <img src="/images/check_on.png" alt="check_on" className="meta-chkImg" />}
                </div>
              )}
            </div>
          )}
        </InnerWrap>
        {!isWEnglish && (
          <div className="report-footer-con">
            <h5>
              Powered by {'     '}
              <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
                Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> <span style={{ color: '#333' }}>AI</span>
              </span>
            </h5>
            <h5>Copyright &copy; MetaMetrics, Inc. All rights reserved.</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default InstructionalMetaExamType;
