import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Row } from 'antd';

import DefaultModal from './DefaultModal';
import CreateEmployeeForm from 'src/pages/Settings/Employee/CreateEmployeeForm';
import UpdateEmployeeForm from 'src/pages/Settings/Employee/UpdateEmployeeForm';
import { openNotification } from 'src/components/common/Notification';

import { useForm } from 'src/utils/hooks';
import { tearchIdxVar, tearchItemVar, userTypVar, userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

function EmployeeModal({ userInfo = null, popupMode, handleCancel, ...rest }) {
  const [form] = Form.useForm();

  const initialState = {
    name: userInfo?.name,
    id: userInfo?.id,
    pw: null,
    phone: userInfo?.phone,
    email: userInfo?.email,
    memo: userInfo?.memo,
    department: userInfo?.department,
    campus_idx: userInfoVar().campus_idx,
    type: userTypVar().employee,
  };

  const { onChange, onSubmit, values } = useForm(formCallback, initialState);

  const [createUser, { loading }] = useMutation(mutations.createUser.CREATE_USER, {
    variables: {
      ...values,
    },
    update(proxy, { data }) {
      const { getUsers } = proxy.readQuery({
        query: queries.getUser.GET_USERS,
        variables: {
          type: userTypVar().employee,
        },
      });

      proxy.writeQuery({
        query: queries.getUser.GET_USERS,
        variables: {
          type: userTypVar().employee,
        },
        data: {
          getUsers: [...getUsers, data.createUser],
        },
      });
    },
    onCompleted(data) {
      if (data) {
        tearchIdxVar(data.createUser.idx);
        tearchItemVar({ key: data.createUser.idx, id: data.createUser.id });
        openNotification('등록 완료!');
        ActionCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const [updateUser, { loading: editing }] = useMutation(mutations.updateUser.UPDATE_USER, {
    update(proxy, result) {
      const data = proxy.readQuery({
        query: queries.getUser.GET_USERS,
        variables: {
          type: userTypVar().employee,
        },
      });

      proxy.writeQuery({
        query: queries.getUser.GET_USERS,
        variables: {
          type: userTypVar().employee,
        },
        data: {
          getUsers: [
            ...data.getUsers.map((item) => {
              if (item.idx === userInfo?.idx) {
                return Object.assign({}, item, result.data.updateUser);
              }
              return item;
            }),
          ],
        },
      });

      tearchIdxVar(result.data.updateUser.idx);
      tearchItemVar({
        key: result.data.updateUser.idx,
        id: result.data.updateUser.id,
      });
    },
    onCompleted(data) {
      if (data) {
        openNotification('수정 완료!');
        ActionCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function formCallback() {
    const newValues = {
      ...values,
      phone: values.phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
      idx: userInfo?.idx,
    };
    popupMode === 'create'
      ? createUser({
          variables: {
            ...newValues,
          },
        })
      : updateUser({
          variables: {
            ...newValues,
          },
        });
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  return (
    <>
      <DefaultModal
        className="settingaddmodule"
        title="직원정보"
        form={form}
        loading={popupMode === 'create' ? loading : editing}
        ActionCancel={ActionCancel}
        popupMode={popupMode}
        width={60}
        {...rest}
      >
        <Form
          className="setting-popup-form"
          name="control-ref"
          layout="inline"
          form={form}
          onFinish={onSubmit}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          autoComplete="off"
        >
          {popupMode === 'create' ? (
            <CreateEmployeeForm onChange={onChange} formValues={values} form={form} />
          ) : (
            <UpdateEmployeeForm userInfo={userInfo} onChange={onChange} form={form} />
          )}
        </Form>
      </DefaultModal>
    </>
  );
}

export default EmployeeModal;
