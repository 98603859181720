import { useQuery } from '@apollo/client';
import { updateClassSlice } from 'src/operations/store';
import { useDispatch } from 'react-redux';

export const useQueryProxy = (query, data) => {
  const dispatch = useDispatch();
  let response = useQuery(query, data);

  if (response?.data?.me) {
    const tempD = response?.data?.me?.company_name || 'default';
    dispatch(updateClassSlice(tempD));
  }

  if (window.Android && response.data && window.Android.SaveUserData) {
    window.Android.SaveUserData(JSON.stringify(response.data));
  }

  return response;
};
