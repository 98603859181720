import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Cascader } from 'antd';
import { GET_COMPANY_BOOK_CONTENTS } from 'src/operations/queries/company';

const BookCreditSelectBox = ({ company_idx, onChange, initSelectedValue = [] }) => {
  const [selectedValue, setSelectedValue] = useState(initSelectedValue);
  const [disabled, setDisabled] = useState(false);
  const [selectedDays, setSelectedDays] = useState(0);
  const { data: dataBook } = useQuery(GET_COMPANY_BOOK_CONTENTS, {
    skip: !company_idx,
    variables: {
      company_idx,
    },
  });
  const selectBookOptions = useMemo(() => {
    if (dataBook) {
      return dataBook.getCompanyBookContentList.map((item) => {
        console.log(item.book);
        const {
          book: { code },
          book_list,
          credit_duration,
          prices,
        } = item;
        const days = prices?.length > 0 ? prices[0].days : credit_duration || 0;
        return {
          label: code,
          value: code,
          days,
          children: book_list.map(({ code }) => ({
            label: code,
            value: code,
            days,
          })),
        };
      });
    }
    return [];
  }, [dataBook]);

  const handleChangeBookOptions = (value, data) => {
    setSelectedValue(value);
    const days = data[data.length - 1].days;
    onChange([...value, days]);
  };
  useEffect(() => {
    if (initSelectedValue instanceof Array && initSelectedValue.length !== 0) {
      setSelectedValue(initSelectedValue);
      // setDisabled(true);
    } else {
      // setDisabled(false);
    }
  }, [initSelectedValue]);

  return (
    <Cascader
      size={`default`}
      disabled={disabled}
      options={selectBookOptions}
      placeholder="교재 선택"
      onChange={handleChangeBookOptions}
      style={{ width: '95%' }}
      value={selectedValue}
    />
  );
};

export default BookCreditSelectBox;
