import { gql } from '@apollo/client';

export const PHONICS_LIST_GROUP = gql`
  query PhonicsUnitListGroup($input: String!) {
    PhonicsUnitListGroup(input: $input) {
      idx
      code
      stagecode
      level
      unit
      unit_title
      stage
      stage_title
    }
  }
`;

export const ASSIGNED_PHONICS_LIST_GROUP = gql`
  query AssignedPhonicsGroupList($input: String!, $date: String!, $page: Int!, $take: Int!, $type: Int!) {
    AssignedPhonicsGroupList(input: $input, date: $date, page: $page, take: $take, type: $type) {
      total
      page
      take
      user_lessons {
        idx
        start_date
        end_date
        title
        idate
        start_time
        class_name
        class_idx
        all_data
        __typename
      }
      __typename
    }
  }
`;

export const FETCH_ASSIGNED_PHONICS_BOOK_LIST = gql`
  query AssignedPhonicsFetch($input_param_idx: Int!, $input_param_class_idx: Int!, $input_param_title: String!) {
    AssignedPhonicsFetch(
      input_param_idx: $input_param_idx
      input_param_class_idx: $input_param_class_idx
      input_param_title: $input_param_title
    ) {
      start_date
      end_date
      title
      class_idx
      class_name
      user_idx
      unit_assign_date
      book_idx
      code
      level
      unit
      unit_title
    }
  }
`;

export const FETCH_ASSIGNED_PHONICS_LIST = gql`
  query AssignedPhonicsList($assigned_phonics_group_idx: Int!) {
    AssignedPhonicsList(assigned_phonics_group_idx: $assigned_phonics_group_idx) {
      start_date
      limit_time
      idx
      title
      book_idx
      level
      unit
      user
    }
  }
`;

export const FETCH_ASSIGNED_PHONICS_STUDENT_VIEW = gql`
  query AssignedPhonicsStudentView($input: String!, $take: Int!, $page: Int!, $date: String!, $search: String!, $type: Int!) {
    AssignedPhonicsStudentView(input: $input, take: $take, page: $page, date: $date, search: $search, type: $type) {
      total
      page
      take
      user_lessons {
        book_idx
        level
        unit
        unit_title
        class_idx
        class_name
        start_date
        all_data
      }
    }
  }
`;

export const FETCH_ASSIGNED_PHONICS_STUDENT_DETAIL = gql`
  query AssignedPhonicsStudentDetail($input: String!, $input_start_date: String!, $input_class_idx: Int!) {
    AssignedPhonicsStudentDetail(input: $input, input_start_date: $input_start_date, input_class_idx: $input_class_idx) {
      id
      book_idx
      level
      unit
      unit_title
      stages_completed_status
      start_date
    }
  }
`;

export const FETCH_ASSIGNED_PHONICS_STUDENT_CLASS_DETAIL = gql`
  query AssignedPhonicsClassStudentDetail($user_idx: Int!, $class_idx: Int!) {
    AssignedPhonicsClassStudentDetail(user_idx: $user_idx, class_idx: $class_idx) {
      student_name
      class_name
      unit_details {
        book_idx
        start_date
        stages_completed_status
      }
    }
  }
`;

export const DASHBOARD_CLASS_PHONICS_DATEWISE_LIST = gql`
  query CamppusAssignedPhonicsDailySchedue($input: String!, $start: String, $end: String) {
    CamppusAssignedPhonicsDailySchedue(input: $input, start: $start, end: $end) {
      phonics_assign_date
      count
    }
  }
`;
