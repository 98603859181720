import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { AI_TOPIC_USER_ASSIGNMENT_DETAILS } from 'src/operations/queries/aiTopic';
import Loading from 'src/components/common/Loading';
import AiEssayCorrection from './aiEssayCorrection';
import LevelupRecording from './levelupRecording';

const BookReportStudy = () => {
  const { idx } = useParams();
  const history = useHistory();
  const [showRecording, setShowRecording] = useState(false);
  const [topicData, setTopicData] = useState(null);
  const [level, setLevel] = useState(-1);
  const [feedback, setFeedback] = useState(false);
  const [revise, setRevise] = useState(false);
  const [chatList, setChatList] = useState(null);
  const [answer, setAnswer] = useState('');

  const {
    data: dataHistory,
    loading: loadingHistory,
    error: errorHistory,
  } = useQuery(AI_TOPIC_USER_ASSIGNMENT_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      idx: parseInt(idx),
    },
  });

  const handleShowRecording = (ans) => {
    setAnswer(ans);
    setShowRecording(true);
  };

  useEffect(() => {
    if (dataHistory?.aiTopicUserAssignmentDetails) {
      setTopicData(dataHistory?.aiTopicUserAssignmentDetails?.ai_topic);

      setAnswer(dataHistory?.aiTopicUserAssignmentDetails?.answer);

      if (dataHistory?.aiTopicUserAssignmentDetails?.status === 2) {
        history.push('/');
      } else if (dataHistory?.aiTopicUserAssignmentDetails?.status === 1) {
        setShowRecording(true);
      }

      if (dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.data) {
        const data = JSON.parse(dataHistory?.aiTopicUserAssignmentDetails?.ai_topic?.data);
        if (data?.level) {
          setLevel(data?.level);
        }
        if (data?.feedback) {
          setFeedback(data?.feedback);
        }
        if (data?.revise) {
          setRevise(data?.revise);
        }
      }

      let resChats = [];
      dataHistory?.aiTopicUserAssignmentDetails?.ai_request_responses.forEach((item) => {
        resChats.push({
          user: userInfoVar()?.id,
          chat_user_name: userInfoVar()?.name,
          content: item.prompt_text,
          urls: [],
          created: item.idate,
          data: item?.data ? JSON.parse(item?.data) : {},
        });
        resChats.push({
          user: 'bot',
          chat_user_name: 'AI봇',
          content: item.completion_text,
          urls: item.image_urls ? JSON.parse(item.image_urls) : [],
          created: item.idate,
          data: item?.data ? JSON.parse(item?.data) : {},
        });
      });
      setChatList(resChats);
    }
  }, [dataHistory]);

  return (
    <>
      {loadingHistory || !chatList || !topicData ? (
        <Loading />
      ) : showRecording ? (
        answer && (
          <LevelupRecording
            dataNew={[
              {
                page: 1,
                text: answer,
              },
            ]}
            articleData={topicData}
            idx={idx || null}
            userData={userInfoVar()}
            code={topicData?.level_up_code}
          />
        )
      ) : (
        <AiEssayCorrection
          defautTopic={topicData?.topic}
          defaultLevel={level}
          defaultFeedback={feedback}
          defaultRevise={revise}
          defaultChatList={chatList}
          idx={idx}
          onShowRecording={handleShowRecording}
        />
      )}
    </>
  );
};

export default BookReportStudy;
