import React, { useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import reactStringReplace from 'react-string-replace';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import PopUp from './PopUp';
import { useParams } from 'react-router-dom';
import { getTextInPink } from '../../utils/pm/util';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
let bookNameUnitStage = undefined;
let urlbookname = '';
let urlbookunit = '';
let urlbookstage = '';
let bookunitstagechecklist = undefined;
let letterformation = { oa: true, ow: true, oo: true, ay: true, ai: true };
const ImgDnD3 = ({ question, showPopUp, setShowPopUp, handleGoodJobSoundEnd }) => {
  // upperWord : 부모컴포넌트에서 상태관리 되는 question 임. 하나씩 없어짐.
  // question: 원래 question (변하지 않음)
  const [soundName, setSoundName] = useState(undefined);
  const [dropSound, setDropSound] = useState(undefined);
  const [droppedMp3SoundName, setDroppedMp3SoundName] = useState(undefined);
  const [droppedImages, setDroppedImages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isDragDisabled, setIsDragDisabled] = useState(false);
  const { level, unit, stage } = useParams();

  urlbookname = level;
  urlbookunit = unit;
  urlbookstage = stage;

  if (level == '2a' && unit == 1 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      Dad: [{ loop1: 1, loop2: 2, loop3: 2 }],
      ham: [{ loop1: 1, loop2: 2, loop3: 2 }],
      yam: [{ loop1: 1, loop2: 2, loop3: 2 }],
      sad: [{ loop1: 1, loop2: 2, loop3: 2 }],
      jam: [{ loop1: 1, loop2: 2, loop3: 2 }],
    };
  }

  if (level == '3a' && unit == 1 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      case: [{ loop1: 1, loop2: 2, loop3: 3 }],
      vase: [{ loop1: 1, loop2: 2, loop3: 3 }],
      rake: [{ loop1: 1, loop2: 2, loop3: 3 }],
      cake: [{ loop1: 1, loop2: 2, loop3: 3 }],
      date: [{ loop1: 1, loop2: 2, loop3: 3 }],
      gate: [{ loop1: 1, loop2: 2, loop3: 3 }],
    };
  }

  if (level == '3a' && unit == 2 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      cave: [{ loop1: 1, loop2: 2, loop3: 3 }],
      mane: [{ loop1: 1, loop2: 2, loop3: 3 }],
      wave: [{ loop1: 1, loop2: 2, loop3: 3 }],
      cane: [{ loop1: 1, loop2: 2, loop3: 3 }],
    };
  }

  if (level == '3b' && unit == 9 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      Can: [{ loop1: -1, loop2: -1 }],
      cane: [{ loop1: 3, loop2: 3 }],
      Man: [{ loop1: -1, loop2: -1 }],
      mane: [{ loop1: 3, loop2: 3 }],
      Cap: [{ loop1: -1, loop2: -1 }],
      cape: [{ loop1: 3, loop2: 3 }],
      Tap: [{ loop1: -1, loop2: -1 }],
      tap: [{ loop1: -1, loop2: -1 }],
      tape: [{ loop1: 3, loop2: 3 }],
      Cub: [{ loop1: -1, loop2: -1 }],
      cube: [{ loop1: 3, loop2: 3 }],
      Tub: [{ loop1: -1, loop2: -1 }],
      tube: [{ loop1: 3, loop2: 3 }],
      e: [{ loop1: 0, loop2: 0 }],
    };
  }

  if (level == '3b' && unit == 8 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      cute: [{ loop1: 1, loop2: 2, loop3: 3 }],
      mute: [{ loop1: 1, loop2: 2, loop3: 3 }],
      cube: [{ loop1: 1, loop2: 2, loop3: 3 }],
      tube: [{ loop1: 1, loop2: 2, loop3: 3 }],
      tune: [{ loop1: 1, loop2: 2, loop3: 3 }],
    };
  }

  if (level == '3b' && unit == 7 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      rope: [{ loop1: 1, loop2: 2, loop3: 3 }],
      hose: [{ loop1: 1, loop2: 2, loop3: 3 }],
      note: [{ loop1: 1, loop2: 2, loop3: 3 }],
      rose: [{ loop1: 1, loop2: 2, loop3: 3 }],
      hope: [{ loop1: 1, loop2: 2, loop3: 3 }],
    };
  }

  if (level == '3b' && unit == 6 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      Mole: [{ loop1: 1, loop2: 2, loop3: 3 }],
      home: [{ loop1: 1, loop2: 2, loop3: 3 }],
      dome: [{ loop1: 1, loop2: 2, loop3: 3 }],
      cone: [{ loop1: 1, loop2: 2, loop3: 3 }],
      pole: [{ loop1: 1, loop2: 2, loop3: 3 }],
      hole: [{ loop1: 1, loop2: 2, loop3: 3 }],
      alone: [{ loop1: 2, loop2: 3, loop3: 4 }],
    };
  }

  if (level == '4b' && unit == 8 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      goat: [{ loop1: 1, loop2: 2 }],
      window: [{ loop1: 4, loop2: 5 }],
      blows: [{ loop1: 2, loop2: 3 }],
      coat: [{ loop1: 1, loop2: 2 }],
      snow: [{ loop1: 2, loop2: 3 }],
      road: [{ loop1: 1, loop2: 2 }],
      boat: [{ loop1: 1, loop2: 2 }],
      yellow: [{ loop1: 4, loop2: 5 }],
      pillow: [{ loop1: 4, loop2: 5 }],
    };
  }

  if (level == '4b' && unit == 10 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      moose: [{ loop1: 1, loop2: 2 }],
      cook: [{ loop1: 1, loop2: 2 }],
      moon: [{ loop1: 1, loop2: 2 }],
      spoon: [{ loop1: 2, loop2: 3 }],
      food: [{ loop1: 1, loop2: 2 }],
      looks: [{ loop1: 1, loop2: 2 }],
      good: [{ loop1: 1, loop2: 2 }],
      Goody: [{ loop1: 1, loop2: 2 }],
      goody: [{ loop1: 1, loop2: 2 }],
      cookie: [{ loop1: 1, loop2: 2 }],
      cookbook: [{ loop1: 1, loop2: 2, loop3: 5, loop4: 6 }],
    };
  }

  if (level == '4b' && unit == 6 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      crayon: [{ loop1: 2, loop2: 3 }],
      clay: [{ loop1: 2, loop2: 3 }],
      play: [{ loop1: 2, loop2: 3 }],
      gray: [{ loop1: 2, loop2: 3 }],
      snail: [{ loop1: 2, loop2: 3 }],
      waits: [{ loop1: 1, loop2: 2 }],
      rain: [{ loop1: 1, loop2: 2 }],
      train: [{ loop1: 2, loop2: 3 }],
      tray: [{ loop1: 2, loop2: 3 }],
      mail: [{ loop1: 1, loop2: 2 }],
      day: [{ loop1: 1, loop2: 2 }],
    };
  }

  if (level == '4a' && unit == 5 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      cage: [{ loop1: 0, loop2: 0 }],
      cold: [{ loop1: 0, loop2: 0 }],
      alligator: [{ loop1: 4, loop2: 4 }],
      girl: [{ loop1: 0, loop2: 0 }],
      glad: [{ loop1: 0, loop2: 0 }],
      bicycle: [{ loop1: 2, loop2: 2 }],
      cab: [{ loop1: 0, loop2: 0 }],
      city: [{ loop1: 0, loop2: 0 }],
      garden: [{ loop1: 0, loop2: 0 }],
      candy: [{ loop1: 0, loop2: 0 }],
      cake: [{ loop1: 0, loop2: 0 }],
    };
  }

  if (level == '4a' && unit == 4 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      whales: [{ loop1: 0, loop2: 1 }],
      teeth: [{ loop1: 3, loop2: 4 }],
      mouth: [{ loop1: 3, loop2: 4 }],
      white: [{ loop1: 0, loop2: 1 }],
      whisper: [{ loop1: 0, loop2: 1 }],
      thick: [{ loop1: 0, loop2: 1 }],
      bath: [{ loop1: 2, loop2: 3 }],
      Three: [{ loop1: 0, loop2: 1 }],
    };
  }

  if (level == '4a' && unit == 3 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      fish: [{ loop1: 2, loop2: 3 }],
      shoe: [{ loop1: 0, loop2: 1 }],
      chocolate: [{ loop1: 0, loop2: 1 }],
      cherry: [{ loop1: 0, loop2: 1 }],
      lunch: [{ loop1: 3, loop2: 4 }],
      Munch: [{ loop1: 3, loop2: 4 }],
      munch: [{ loop1: 3, loop2: 4 }],
      chin: [{ loop1: 0, loop2: 1 }],
      shirt: [{ loop1: 0, loop2: 1 }],
      wash: [{ loop1: 2, loop2: 3 }],
    };
  }

  if (level == '4a' && unit == 2 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      crab: [{ loop1: 0, loop2: 1 }],
      bride: [{ loop1: 0, loop2: 1 }],
      s: [{ loop1: 5, loop2: 5 }],
      dress: [{ loop1: 0, loop2: 1 }],
      grass: [{ loop1: 0, loop2: 1 }],
      grape: [{ loop1: 0, loop2: 1 }],
      crow: [{ loop1: 0, loop2: 1 }],
      drum: [{ loop1: 0, loop2: 1 }],
    };
  }

  if (level == '4a' && unit == 1 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      slim: [{ loop1: 0, loop2: 1 }],
      black: [{ loop1: 0, loop2: 1 }],
      blade: [{ loop1: 0, loop2: 1 }],
      cliff: [{ loop1: 0, loop2: 1 }],
      flag: [{ loop1: 0, loop2: 1 }],
      sled: [{ loop1: 0, loop2: 1 }],
      slide: [{ loop1: 0, loop2: 1 }],
      glass: [{ loop1: 0, loop2: 1 }],
      flame: [{ loop1: 0, loop2: 1 }],
      glad: [{ loop1: 0, loop2: 1 }],
    };
  }

  if (level == '1b' && unit == 9 && stage == 10) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      wolf: [{ loop1: 0, loop2: 0, loop3: 0 }],
      watch: [{ loop1: 0, loop2: 0, loop3: 0 }],
      fox: [{ loop1: 2, loop2: 2, loop3: 2 }],
      ax: [{ loop1: 1, loop2: 1, loop3: 1 }],
      yak: [{ loop1: 0, loop2: 0, loop3: 0 }],
      yo: [{ loop1: 0, loop2: 0, loop3: 0 }],
      zebra: [{ loop1: 0, loop2: 0, loop3: 0 }],
      zipper: [{ loop1: 0, loop2: 0, loop3: 0 }],
    };
  }

  if (level == '2a' && unit == 2 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      man: [{ loop1: 1, loop2: 2, loop3: 1 }],
      fan: [{ loop1: 1, loop2: 2, loop3: 1 }],
      can: [{ loop1: 1, loop2: 2, loop3: 1 }],
      fat: [{ loop1: 1, loop2: 2, loop3: 1 }],
      map: [{ loop1: 1, loop2: 2, loop3: 1 }],
    };
  }

  if (level == '2a' && unit == 3 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      pig: [{ loop1: 1, loop2: 2, loop3: 1 }],
      wig: [{ loop1: 1, loop2: 2, loop3: 1 }],
      fin: [{ loop1: 1, loop2: 2, loop3: 1 }],
      rip: [{ loop1: 1, loop2: 2, loop3: 1 }],
      big: [{ loop1: 1, loop2: 2, loop3: 1 }],
      s: [{ loop1: 1, loop2: 1, loop3: 1 }],
      pin: [{ loop1: 1, loop2: 2, loop3: 1 }],
    };
  }

  if (level == '2a' && unit == 4 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      kid: [{ loop1: 1, loop2: 2, loop3: 1 }],
      bib: [{ loop1: 1, loop2: 2, loop3: 1 }],
      kids: [{ loop1: 1, loop2: 2, loop3: 1 }],
      sit: [{ loop1: 1, loop2: 2, loop3: 1 }],
      mix: [{ loop1: 1, loop2: 2, loop3: 1 }],
      fix: [{ loop1: 1, loop2: 2, loop3: 1 }],
      rib: [{ loop1: 1, loop2: 2, loop3: 1 }],
    };
  }

  if (level == '2b' && unit == 6 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      Bug: [{ loop1: 1, loop2: 2 }],
      hut: [{ loop1: 1, loop2: 2 }],
      nut: [{ loop1: 1, loop2: 2 }],
      rug: [{ loop1: 1, loop2: 2 }],
    };
  }

  if (level == '2b' && unit == 7 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      pup: [{ loop1: 1, loop2: 2 }],
      cup: [{ loop1: 1, loop2: 2 }],
      cub: [{ loop1: 1, loop2: 2 }],
      mud: [{ loop1: 1, loop2: 2 }],
      bug: [{ loop1: 1, loop2: 2 }],
      gum: [{ loop1: 1, loop2: 2 }],
      up: [{ loop1: 0, loop2: 1 }],
      tub: [{ loop1: 1, loop2: 2 }],
    };
  }

  if (level == '2b' && unit == 8 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      hen: [{ loop1: 1, loop2: 2 }],
      red: [{ loop1: 1, loop2: 2 }],
      wet: [{ loop1: 1, loop2: 2 }],
      bed: [{ loop1: 1, loop2: 2 }],
      vet: [{ loop1: 1, loop2: 2 }],
    };
  }

  if (level == '2b' && unit == 9 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      box: [{ loop1: 1, loop2: 2 }],
      pot: [{ loop1: 1, loop2: 2 }],
      log: [{ loop1: 1, loop2: 2 }],
      ox: [{ loop1: 0, loop2: 1 }],
      fox: [{ loop1: 1, loop2: 2 }],
      dog: [{ loop1: 1, loop2: 2 }],
      hops: [{ loop1: 1, loop2: 2 }],
    };
  }

  if (level == '3a' && unit == 3 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      mice: [{ loop1: 1, loop2: 2, loop3: 3 }],
      pine: [{ loop1: 1, loop2: 2, loop3: 3 }],
      hike: [{ loop1: 1, loop2: 2, loop3: 3 }],
      bike: [{ loop1: 1, loop2: 2, loop3: 3 }],
      dice: [{ loop1: 1, loop2: 2, loop3: 3 }],
      rice: [{ loop1: 1, loop2: 2, loop3: 3 }],
      line: [{ loop1: 1, loop2: 2, loop3: 3 }],
      nine: [{ loop1: 1, loop2: 2, loop3: 3 }],
      Mike: [{ loop1: 1, loop2: 2, loop3: 3 }],
    };
  }

  if (level == '3a' && unit == 4 && stage == 11) {
    bookNameUnitStage = true;
    bookunitstagechecklist = true;
    letterformation = {
      five: [{ loop1: 1, loop2: 2, loop3: 3 }],
      kites: [{ loop1: 1, loop2: 2, loop3: 3 }],
      ride: [{ loop1: 1, loop2: 2, loop3: 3 }],
      bike: [{ loop1: 1, loop2: 2, loop3: 3 }],
      hive: [{ loop1: 1, loop2: 2, loop3: 3 }],
      hide: [{ loop1: 1, loop2: 2, loop3: 3 }],
      dive: [{ loop1: 1, loop2: 2, loop3: 3 }],
      bite: [{ loop1: 1, loop2: 2, loop3: 3 }],
    };
  }

  const suffledImages = useMemo(() => _.shuffle(_.cloneDeep(question)).map((item) => item.image), [question]);
  const isDropped = (imagePath) => {
    // console.log(droppedImages, 'droppedImages');
    if (droppedImages.some((item) => item === imagePath)) {
      return true;
    }
    return false;
  };

  const handleSoundNameEnd = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setSoundName(undefined);
    setIsDragDisabled(false);

    if (currentQuestionIndex >= question.length - 1) {
      setShowPopUp(true);
    }
  };

  const handleDropSoundEnd = () => {
    if (dropSound === 'mouse-click') {
      setSoundName(droppedMp3SoundName);
    }
    setDropSound(undefined);
  };
  const handleDragEnd = useCallback(
    (result) => {
      const { destination } = result;
      if (_.isNil(destination) || !destination.droppableId.includes('/image/')) {
        setDropSound('wrong_sound1');
        return;
      } else {
        setDropSound('mouse-click');
      }
      //destination.droppableId 가 이미지 경로다.
      setDroppedImages([...droppedImages, destination.droppableId]);
      setDroppedMp3SoundName(destination.droppableId.split('/image/')[1].split('.png')[0]);

      setIsDragDisabled(true);
    },
    [currentQuestionIndex],
  );
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <S.RowFlex
          className="main_content  stage11_content  col2_arrow"
          style={{ alignSelf: 'stretch', flex: '1 1', height: 'calc(100% - 70px)' }}
        >
          <S.ImgDndBox className="left_box">
            <DraggableImagesBox suffledImages={suffledImages} isDropped={isDropped} isDragDisabled={isDragDisabled} />
          </S.ImgDndBox>
          <img style={{ maxWidth: '3%', margin: '0 12px' }} src="/images/pm/i_arrow_right.svg" alt="화살표 아이콘" />
          <S.ImgDndBox className="right_box">
            {_.map(question, (item, index) => {
              if (currentQuestionIndex === index) {
                return (
                  <React.Fragment key={`right-box${index}`}>
                    <ImageDropZone image={item.image} isDropped={isDropped} />
                    <SentenceZone sentenceArray={item.sentence} />
                  </React.Fragment>
                );
              }
            })}
          </S.ImgDndBox>
        </S.RowFlex>
      </DragDropContext>
      {showPopUp ? <PopUp popUpType={`correct`} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
      <SoundEffectPM
        url={!_.isNil(dropSound) ? `https://cdn.cloubot.com/PM/audio/sound-effect/${dropSound}.mp3` : undefined}
        onEnd={handleDropSoundEnd}
      />
      <SoundEffectPM
        url={!_.isNil(soundName) ? `https://cdn.cloubot.com/PM/audio/sounds/${soundName}.mp3` : undefined}
        onEnd={handleSoundNameEnd}
      />
    </>
  );
};

export default ImgDnD3;

const DraggableImagesBox = ({ suffledImages, isDropped, isDragDisabled }) => {
  return (
    <S.ImgDndDragArea className="img_box drag_area">
      {_.map(suffledImages, (img, index) => (
        <Droppable key={`drop-key-${index}`} droppableId={`drop${index}`} type={img} isDropDisabled={true}>
          {(provided) =>
            isDropped(img) ? (
              <li>
                <S.RowFlex className="drag_item"></S.RowFlex>
              </li>
            ) : (
              <li style={{ justifyContent: 'center', display: 'flex' }} ref={provided.innerRef}>
                <Draggable draggableId={`drag${index}`} index={index} isDragDisabled={isDragDisabled}>
                  {(provided) => (
                    <S.RowFlex
                      style={{ justifyContent: 'center' }}
                      className="drag_item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <S.ImgDndDragItem src={img} alt="드래그 앤 드롭 이미지22" />
                    </S.RowFlex>
                  )}
                </Draggable>
                <div style={{ display: 'none' }}>{provided.placeholder}</div>
                {/* drag end  시에 덜컬거리는 것 방지, provided.placeholder 만 있을 경우 덜컹거림 */}
              </li>
            )
          }
        </Droppable>
      ))}
    </S.ImgDndDragArea>
  );
};
const ImageDropZone = ({ image, isDropped }) => {
  return (
    <S.ImgDndDropArea className="img_box drop_area">
      <Droppable droppableId={image} type={image}>
        {(provided, snapshot) => (
          <S.ImgDndDropLi ref={provided.innerRef} style={{ backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined }}>
            {isDropped(image) && (
              <S.RowFlex style={{ justifyContent: 'center' }} className="drag_item">
                <img src={image} style={{ width: '100%' }} alt="droppableImg" />
              </S.RowFlex>
            )}
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </S.ImgDndDropLi>
        )}
      </Droppable>
    </S.ImgDndDropArea>
  );
};

const SentenceZone = ({ sentenceArray }) => {
  var loopAnimalMatchvalue = [];
  var loopAnimalKeyValue = [];
  {
    reactStringReplace(sentenceArray.join(''), /##(\w+)##/g, (match) => loopAnimalMatchvalue.push(match));

    if (loopAnimalMatchvalue.length !== 0) {
      if (
        loopAnimalMatchvalue.length == 2 &&
        loopAnimalMatchvalue[0].constructor.toString().indexOf('Array') !== -1 &&
        loopAnimalMatchvalue[1].constructor.toString().indexOf('Array') !== -1
      ) {
        loopAnimalMatchvalue[0].split('').map((loopdata, key) => {
          loopAnimalKeyValue[loopAnimalMatchvalue[0] + key] = false;
        });
        spanform(loopAnimalMatchvalue[1]);
      } else if (urlbookname == '4b' && urlbookunit == 7 && urlbookstage == 11) {
        for (let loopnumber = 0; loopnumber < loopAnimalMatchvalue.length; loopnumber++) {
          loopAnimalMatchvalue[loopnumber].split('').map((loopdata, key) => {
            loopAnimalKeyValue[loopAnimalMatchvalue[loopnumber] + key] = true;
          });
        }
      } else {
        if (urlbookname == '1a') {
          spanform(loopAnimalMatchvalue);
        } else {
          if (loopAnimalMatchvalue.length == 1) {
            spanform(loopAnimalMatchvalue);
          } else {
            spanform(loopAnimalMatchvalue[0]);
            spanform(loopAnimalMatchvalue[1]);
          }
        }
      }
    }

    function spanform(inputAnimalMatchvalue) {
      for (let loopnumber = 0; loopnumber < inputAnimalMatchvalue.length; loopnumber++) {
        inputAnimalMatchvalue[loopnumber].split('').map((loopdata, key) => {
          if (key == 0) {
            loopAnimalKeyValue[loopAnimalMatchvalue[loopnumber] + key] = true;
          } else {
            loopAnimalKeyValue[loopAnimalMatchvalue[loopnumber] + key] = false;
          }
        });
      }
    }
  }
  const isPinkOrBlue = (word, index, textInPink, hex = false) => {
    const start = word.indexOf(textInPink);
    const end = start + textInPink.length - 1;
    if (index >= start && index <= end) {
      return hex ? '#f368e0' : 'pink';
    }
    return hex ? '#2e86de' : 'blue';
  };
  console.log('===================', sentenceArray);
  return (
    <S.ImgDndDropTxt className="txt_box">
      {_.map(sentenceArray, (item, key) => (
        <React.Fragment key={`sentence-array-key${key}`}>
          {reactStringReplace(item, /##([\w|-]+)##/g, (match, index) => (
            <React.Fragment key={`match-key-${index}`}>
              {/* 이게 뭔 짓이지? f 다음에 i 가 오면 마크업이 이상하게 동작한다. */}
              {getTextInPink(match, urlbookname, urlbookunit, urlbookstage).length > 0
                ? _.map(match, (item2, key2) => (
                    <span
                      data={match}
                      key={`word-item-${key}-${key2}`}
                      style={{ color: isPinkOrBlue(match, key2, getTextInPink(match, urlbookname, urlbookunit, urlbookstage), true) }}
                      className={`font_${isPinkOrBlue(match, key2, getTextInPink(match, urlbookname, urlbookunit, urlbookstage))}`}
                    >
                      {item2}
                    </span>
                  ))
                : bookNameUnitStage || bookunitstagechecklist
                ? bookunitstagechecklist
                  ? _.map(match, (item2, key2) => (
                      <span
                        datatype={key2}
                        key={`word-item-${key}-${key2}`}
                        style={
                          letterformation[match][0]['loop1'] == key2 ||
                          letterformation[match][0]['loop2'] == key2 ||
                          letterformation[match][0]['loop3'] == key2 ||
                          letterformation[match][0]['loop4'] == key2
                            ? { color: '#f368e0' }
                            : { color: '#2e86de' }
                        }
                      >
                        {item2}
                      </span>
                    ))
                  : _.map(match, (item2, key2) => (
                      <span
                        key={`word-item-${key}-${key2}`}
                        style={loopAnimalKeyValue[match + key2] ? { color: '#2e86de' } : { color: '#f368e0' }}
                      >
                        {item2}
                      </span>
                    ))
                : _.map(match, (item2, key2) => (
                    <span
                      data={match}
                      key={`word-item-${key}-${key2}`}
                      style={loopAnimalKeyValue[match + key2] ? { color: '#f368e0' } : { color: '#2e86de' }}
                    >
                      {item2}
                    </span>
                  ))}
            </React.Fragment>
          ))}
          <br />
        </React.Fragment>
      ))}
    </S.ImgDndDropTxt>
  );
};
