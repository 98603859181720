import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
const PhonicsNoticePopup = () => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (window.localStorage.getItem('phonics_notice') !== moment().format('YYYY-MM-DD')) {
      setVisible(true);
    }
  }, []);

  const handleDone = () => {
    window.localStorage.setItem('phonics_notice', moment().format('YYYY-MM-DD'));
    setVisible(false);
  };
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <Modal
      width="40vw"
      bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      visible={visible}
      title="Phonics Monster Assign Notice"
      onCancel={handleClose}
      maskClosable={false}
      okText="오늘 하루 그만보기"
      cancelText="닫기"
      closable={false}
      onOk={handleDone}
    >
      <div style={{ textAlign: 'center' }}>
        <h3>Phonics Monster Result 창에서 "removed student"가 보일 때 없애는 방법</h3>
        <div>Phonics Monster Assign List의 Edit으로 들어간 후 Save 버튼을 클릭합니다. </div>
        <div>(다른 버튼은 클릭하지 마세요!)</div>
        <br/>
        <Button
          onClick={() => {
            history.push('/learningschedule/levels/manage/action/result');
          }}
        >
          Assign List Page로 이동하기
        </Button>
      </div>
    </Modal>
  );
};

export default PhonicsNoticePopup;
