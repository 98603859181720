import React, { useState } from 'react';
import { Button, Slider, Tooltip } from 'antd';
import { FastForwardOutlined } from '@ant-design/icons';
import styled from 'styled-components';
const SpeedButton = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 23px;
  background-color: #fcf300;
  background-image: url('/images/LevelUp/study/main/speed.png');
  background-repeat: no-repeat;
  background-size: 45px 45px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  position: relative;
  filter: ${(props) => (props.disabled ? 'grayscale(100%)' : 'none')};
  /* margin: 0 5px; */
`;
const AudioRateSlider = ({ isMediaOverlay, rate, setRate, max, min, step, ...props }) => {
  const [show, setShow] = useState(false);

  const handelSetRate = (value) => {
    setRate(value);
    // setShow(false);
  };

  return (
    <>
      {show && (
        <div
          style={{
            // marginBottom: '20px',
            marginLeft: '6px',
            height: '200px',
            zIndex: 100,
            padding: '10px',
            bottom: '60px',
            // backgroundColor: '#0ba8ff',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            position: 'absolute',
          }}
        >
          <Slider
            vertical
            min={min}
            max={max}
            value={rate}
            onChange={handelSetRate}
            style={{ margin: 0 }}
            step={step}
            trackStyle={{ backgroundColor: '#ffa700' }}
            railStyle={{ backgroundColor: '#d4d4d4' }}
            handleStyle={{ borderColor: '#ffa700', backgroundColor: '#ffa700' }}
          />
        </div>
      )}
      <Tooltip title={show || !isMediaOverlay ? '' : 'Speed'} placement="top">
        <SpeedButton
          disabled={!isMediaOverlay}
          //  type="primary" shape="circle"
          onClick={() => {
            if (!isMediaOverlay) return;

            setShow(!show);
          }}
        >
          {/* <img src="/images/LevelUp/sound_rate.png" alt="rate" style={{ width: '25px', height: '25px' }} /> */}
        </SpeedButton>
      </Tooltip>
    </>
  );
};

export default AudioRateSlider;
