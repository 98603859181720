import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Select, DatePicker, Button, Input, Card } from 'antd';
import { userInfoVar } from 'src/apollo/cache';
import { MAX_SEARCH_INTERVAL_DAYS, DATE_SEARCH_OPTIONS } from 'src/constants/common';
import { BOOK_RETURN_STATS, DELIVERY_STATUS_STATUS_TEXT_LIST, DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_DASHBOARD } from 'src/constants/common';
import { getYearMonthArray } from 'src/utils';
import CustomTable from 'src/components/common/CustomTable';
import { GET_FC_BOOK_ORDER_AND_RETURN_LIST, GET_FC_BOOK_ORDER_AND_RETURN_LIST_BY_DURATION } from 'src/operations/queries/dashboard';
import imageexcel from 'src/images/xlsicon.png';
import { ExportExcel } from 'src/utils/index';

const months = getYearMonthArray('2021-11');

const { RangePicker } = DatePicker;
const { Option } = Select;

const SEARCH_OPTIONS = [
  {
    label: '전체',
    value: '',
  },
  // {
  //   label: '지사',
  //   value: 'branch',
  // },
  {
    label: '학교',
    value: 'school',
  },
  {
    label: '강사',
    value: 'name',
  },
];
const DEFAULT_DATE_RANGE = [moment().subtract(1, 'months'), moment()];
const BOX_HEAD_STYLE = { textAlign: 'center' };

const isExistItem = (sv, so, item) => {
  const { campus_name, branch, buyer_name } = item;
  if (sv) {
    switch (so) {
      case 'branch':
        return branch.includes(sv);
      case 'school':
        return campus_name.includes(sv);
      case 'name':
        return buyer_name.includes(sv);
      default:
        return branch.includes(sv) || campus_name.includes(sv) || buyer_name.includes(sv);
    }
  }
  return true;
};

const DashBoardAfterSchool = ({ companyName }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [dateSearchOption, setDateSearchOption] = useState(DATE_SEARCH_OPTIONS[0].value);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'));
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);

  const company_idx = userInfoVar()?.campus?.company?.idx;
  const { data, loading } = useQuery(
    dateSearchOption === 0 ? GET_FC_BOOK_ORDER_AND_RETURN_LIST : GET_FC_BOOK_ORDER_AND_RETURN_LIST_BY_DURATION,
    {
      variables:
        dateSearchOption === 0
          ? { ym: selectedMonth, company_idx }
          : { start: dateRange[0].format('YYYY-MM-DD'), end: dateRange[1].format('YYYY-MM-DD'), company_idx },
      skip: !company_idx,
      fetchPolicy: 'no-cache',
    },
  );

  const dataSource = useMemo(() => {
    const orderList = data?.MyFCOrderList || data?.MyFCOrderListByDuration;
    const returnList = data?.getAdminBookReturnList || data?.getAdminBookReturnListByDuration;

    const orderList2 = orderList
      ? orderList.reduce((acc, cur) => {
          const branchIdx = cur?.campus?.campus?.idx;
          if (branchIdx !== userInfoVar()?.campus?.idx) {
            return [...acc];
          }
          const list = cur.order_list.reduce((acc2, cur2, index) => {
            const foundindex = acc2.findIndex((ele) => {
              return ele.product_code === cur2.product_code;
            });
            if (foundindex !== -1) {
              if (companyName === 'englishtap') {
                acc2[foundindex].order_student_count += cur2.product_name ? cur2.quantity : 0;
              } else {
                acc2[foundindex].order_student_count += cur2.product_name.includes('학생용') ? cur2.quantity : 0;
                acc2[foundindex].order_teacher_count += cur2.product_name.includes('교사용') ? cur2.quantity : 0;
                return [...acc2];
              }
            }
            const item = {
              key: `order-list-${cur.idx}-${index}`,
              campus_name: cur?.campus?.name,
              product_name:companyName === 'englishtap'?cur2.product_name: cur2.product_name.replace(/학생용|교사용/gi, ''),
              product_code: cur2.product_code,
              branch: cur?.campus?.campus?.name,
              idate: moment(cur?.idate).format('YYYY-MM-DD'),
              buyer_name: cur?.order_payment[0]?.buyer_name,
              order_student_count:(companyName === 'englishtap'?cur2.product_name: cur2.product_name.includes('학생용')) ? cur2.quantity : 0,
              order_teacher_count: cur2.product_name.includes('교사용') ? cur2.quantity : 0,
              kind: DELIVERY_STATUS_STATUS_TEXT_LIST_FOR_DASHBOARD[cur?.order_payment[0]?.delivery_status || 0],
              checkdate: cur?.order_payment[0]?.invoice_check_date
                ? moment(cur?.order_payment[0]?.invoice_check_date).format('YYYY-MM-DD')
                : '-',
            };
            return [...acc2, item];
          }, []);
          return [...acc, ...list];
        }, [])
      : [];
    const returnList2 = returnList
      ? returnList.reduce((acc, cur) => {
          const branchIdx = cur?.campus?.campus?.idx;
          if (branchIdx !== userInfoVar()?.campus?.idx) {
            return [...acc];
          }
          const list = cur.book_return_list.reduce((acc2, cur2, index) => {
            const foundindex = acc2.findIndex((ele) => {
              return ele.product_code === cur2.product_code;
            });
            if (foundindex !== -1) {
              acc2[foundindex].order_student_count -= cur2.product_name.includes('학생용') ? cur2.quantity : 0;
              acc2[foundindex].order_teacher_count -= cur2.product_name.includes('교사용') ? cur2.quantity : 0;
              return [...acc2];
            }
            const item = {
              key: `return-list-${cur.idx}-${index}`,
              campus_name: cur?.campus?.name,
              product_name: cur2.product_name.replace(/학생용|교사용/gi, ''),
              product_code: cur2.product_code,
              branch: cur?.campus?.campus?.name,
              idate: moment(cur?.idate).format('YYYY-MM-DD'),
              buyer_name: cur?.user?.name,
              order_student_count: cur2.product_name.includes('학생용') ? cur2.quantity * -1 : 0,
              order_teacher_count: cur2.product_name.includes('교사용') ? cur2.quantity * -1 : 0,
              kind: BOOK_RETURN_STATS[cur?.stat || 0],
              checkdate: cur?.check_date ? moment(cur?.check_date).format('YYYY-MM-DD') : '-',
            };
            return [...acc2, item];
          }, []);
          return [...acc, ...list];
        }, [])
      : [];

    return orderList2
      ?.concat(returnList2)
      ?.filter((item) => isExistItem(searchValue, searchOption, item))
      ?.sort((a, b) => {
        if (a.idate === b.idate) {
          return 0;
        }
        return a.idate < b.idate ? 1 : -1;
      });
  }, [data, searchOption, searchValue]);

  function handleDisabledDate(current) {
    return current && current > moment().endOf('day');
  }

  function handleStatisDownload() {
    ExportExcel(columns, dataSource, `statis-${moment().format('YYYY-MM-DD')}`);
  }
  const columns = companyName === 'englishtap'
  ? [
      {
        title: '학원',
        dataIndex: 'campus_name',
        key: 'campus_name',
      },
      {
        title: '강사',
        dataIndex: 'buyer_name',
        key: 'buyer_name',
      },
      {
        title: '신청일',
        dataIndex: 'idate',
        key: 'idate',
        width: '100px',
      },
      {
        title: '교재',
        dataIndex: 'product_name',
        key: 'product_name',
      },
      {
        title: '권수',
        dataIndex: 'order_student_count',
        key: 'order_student_count',
      },

      {
        title: '구분',
        dataIndex: 'kind',
        key: 'kind',
      },
      {
        title: '처리일',
        dataIndex: 'checkdate',
        key: 'checkdate',
        width: '100px',
      },
    ]
  :[
    // {
    //   title: '지사',
    //   dataIndex: 'branch',
    //   key: 'branch',
    // },
    {
      title: '학교',
      dataIndex: 'campus_name',
      key: 'campus_name',
    },
    {
      title: '강사',
      dataIndex: 'buyer_name',
      key: 'buyer_name',
    },
    {
      title: '신청일',
      dataIndex: 'idate',
      key: 'idate',
      width: '100px',
    },
    {
      title: '교재',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: '학생용',
      dataIndex: 'order_student_count',
      key: 'order_student_count',
    },
    {
      title: '교사용',
      dataIndex: 'order_teacher_count',
      key: 'order_teacher_count',
    },
    {
      title: '구분',
      dataIndex: 'kind',
      key: 'kind',
    },
    {
      title: '처리일',
      dataIndex: 'checkdate',
      key: 'checkdate',
      width: '100px',
    },
  ];
  return (
    <>
      <Row gutter={[24, 16]}>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Select
            style={{ marginRight: '5px' }}
            placeholder="검색"
            defaultValue=""
            value={searchOption}
            onChange={setSearchOption}
            options={SEARCH_OPTIONS}
          />
          <Input.Search style={{ width: '150px', marginRight: 10 }} placeholder={`search`} maxLength={20} onSearch={setSearchValue} />
          <Select placeholder="선택" value={dateSearchOption} onChange={setDateSearchOption}>
            {DATE_SEARCH_OPTIONS.map((item) => (
              <Option key={`search-month-option${item.value}`} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
          {dateSearchOption === 0 ? (
            <Select placeholder="선택" value={selectedMonth} onChange={setSelectedMonth}>
              {months &&
                months.map((month, key) => (
                  <Option key={`search-month-option${key}`} value={month}>
                    {month}
                  </Option>
                ))}
            </Select>
          ) : (
            <RangePicker
              value={dateRange}
              allowClear={false}
              disabledDate={handleDisabledDate}
              onChange={(dates) => {
                const [start, end] = dates;
                const intervalDays = end.diff(start, 'days');
                if (intervalDays > MAX_SEARCH_INTERVAL_DAYS) {
                  alert('검색 기간은 최대 3개월입니다.');
                  return false;
                }
                setDateRange(dates);
              }}
            />
          )}
          &nbsp;
          <Button>오늘</Button>
          &nbsp; &nbsp;
          <img src={imageexcel} alt="download" style={{ width: 25, height: 25, cursor: 'pointer' }} onClick={handleStatisDownload} />
        </Col>
      </Row>

      <Row gutter={[24, 16]} style={{ marginTop: 10 }}>
        <Col span={24}>
          <CustomTable
            loading={loading}
            className="orderadmin-table-table"
            pagination={{ pageSize: 12, position: ['bottomCenter'] }}
            dataSource={dataSource}
            columns={columns}
            size="small"
            color="#edf3fb"
            scroll={{ y: 'calc(100vh - 254px)' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default DashBoardAfterSchool;


