import React from 'react';
import styled from 'styled-components';
const ScoreBox = ({ score }) => {
  return (
    <TestScoreArea className="score_area">
      <TestScoreAreaDt>Score</TestScoreAreaDt>
      <TestScoreAreaDd>{score}</TestScoreAreaDd>
    </TestScoreArea>
  );
};

export default ScoreBox;
const TestScoreArea = styled.dl`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.2rem;
  padding: 0.6rem 1.2rem;
  border-radius: 2.8rem;
  background-color: #f9f9f9;
  margin: 0;
  /* @media (max-height: 500px) {
    display: none;
  } */
`;
const TestScoreAreaDt = styled.dt`
  margin: 0;
  padding: 0;
  padding-left: 3.2rem;
  font-weight: 600;
  font-size: 1.4rem;
  background: url(/images/pm/bg_score.svg) no-repeat left;
`;

const TestScoreAreaDd = styled.dd`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.1rem;
  height: 100%;
  border-radius: 0.6rem;
  border: 3px solid #48dbfb;
  box-sizing: border-box;
  font-weight: 900;
  font-size: 1.4rem;
  color: #ff1be0;
  background-color: #fff;
`;
