import React from 'react';

function renderContent(eventInfo) {
  return (
    <>
      <>
        <div style={{ backgroundColor: eventInfo.backgroundColor, color: 'white', borderRadius: 10, width: 20, textAlign: 'center' }}>
          {eventInfo.event.title}
        </div>
      </>
    </>
  );
}

export default renderContent;
