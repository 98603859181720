import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
import styled from 'styled-components';

const LayoutWrapper = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormLayoutWrapper = styled(Layout)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;
const PopupLayoutWrapper = styled(Layout)`
  display: flex;
  align-items: center;
`;

const LoginLayoutWrapper = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/images/img-slider-5.jpg') center center;
  background-size: cover;
  overflow-y: hidden;
`;

function PublicLayout({ component: Component, logOut, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <LayoutWrapper style={{ minHeight: '100vh' }}>
            <Component {...matchProps} />
          </LayoutWrapper>
        </>
      )}
    />
  );
}

export default PublicLayout;

export function LoginLayout({ component: Component, logOut, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <LoginLayoutWrapper style={{ minHeight: '100vh' }}>
            <Component {...matchProps} />
          </LoginLayoutWrapper>
        </>
      )}
    />
  );
}

export function ApplicationFormLayout({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <FormLayoutWrapper style={{ minHeight: '100vh' }}>
            <Component {...matchProps} />
          </FormLayoutWrapper>
        </>
      )}
    />
  );
}

export function PopupLayout({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <PopupLayoutWrapper style={{ minHeight: '100vh' }}>
            <Component {...matchProps} />
          </PopupLayoutWrapper>
        </>
      )}
    />
  );
}
