import React, {useState, useEffect} from "react";
import * as S from 'src/pages/PhonicsMonster/style';
const ImageRowBox = ({steps, problem, onCorrect, onWrong}) => {
	const [active, setActive] = useState(false);
	// To avoid clicking on wrong anwser after selecting right answer 
	const [endStep, setEndStep] = useState(true);
	const handleClick = (e, index, isAnswer, word) => {
		if (isAnswer) {
			setActive(true);
			setEndStep(false);
			if (onCorrect instanceof Function) {
				onCorrect(word, index);
			}
		} else if(endStep) {
			onWrong();
		}
	};
	useEffect(() => {
		setActive(false);
		setEndStep(true);
	}, [steps]);
	return (
		<S.ImageSelectBottomBox className="bottom_box">
			<S.ImageSelectBottomImgBox className="img_box">
				{problem[steps] &&
					problem[steps].exImage &&
					problem[steps].exImage.map((item, key) => (
						<S.ImageSelectBottomImgLi key={`image-list-key${key}`}>
							<S.ImageSelectBottomImgBtn
								type="button"
								className={active && item.isAnswer ? "active" : ""}
								style={active && item.isAnswer ? {borderColor: "#ffb000"} : {}}
								onClick={e => {
									handleClick(e, key, item.isAnswer, item.word);
								}}
							>
								<img style={{objectFit:'scale-down',maxWidth:'60%'}} src={item.image} alt="문제 이미지" />
							</S.ImageSelectBottomImgBtn>
						</S.ImageSelectBottomImgLi>
					))}
			</S.ImageSelectBottomImgBox>
		</S.ImageSelectBottomBox>
	);
};

export default ImageRowBox;
