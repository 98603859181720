import { gql } from '@apollo/client';

export const FILES_LIST = gql`
  query filesList($folder_idx: Int, $mime_type: String, $q: String) {
    filesList(folder_idx: $folder_idx, mime_type: $mime_type, q: $q) {
      idx
      name
      path
      mime_type
      encoding
      is_public
      storage_type
      folders {
        idx
        name
        idate
        user {
          idx
          name
        }
      }
      user {
        idx
        name
      }
      videos {
        idx
        title
        video_id
        data
      }
      idate
    }
  }
`;
