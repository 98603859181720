import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form, Input, Button, Modal, Select, DatePicker } from 'antd';
import FormField from 'src/components/common/FormField';
import { CREATE_CONSULT_LEVELTEST_FOR_SMARTECLASS, ASSIGN_LEVEL_TEST } from 'src/operations/mutations/consult';
import { SMARTECLASS_PLACEMENT_TEST_LIST } from 'src/operations/queries/consult';
import moment from 'moment';

const { Option } = Select;

const GRADES = [1, 2, 3, 4, 5, 6];

const SmartEclassRegisterPlacementTestModal = ({ visible, testOptions, campus_idx, selectedMonth, onCancel, onRefetch }) => {
  const [form] = Form.useForm();

  const [assignPlacementTest, { loadingAssign }] = useMutation(ASSIGN_LEVEL_TEST, {
    onCompleted: () => {
      Modal.info({
        title: '등록했습니다.',
        onOk: () => {
          onRefetch();
          handleCancel();
        },
      });
    },
    onError: (e) => {
      console.log(e);
      alert('저장 중 오류가 발생했습니다.');
    },
    refetchQueries: [
      {
        query: SMARTECLASS_PLACEMENT_TEST_LIST,
        variables: { campus_idx, ym: selectedMonth },
      },
    ],
  });
  const [createLevelTest, { loading }] = useMutation(CREATE_CONSULT_LEVELTEST_FOR_SMARTECLASS, {
    onCompleted: (res) => {
      if (res.createConsultLevelTestForSmartEclass?.user) {
        // Modal.info({
        //   title: '등록했습니다.',
        //   onOk: () => {
        //     onRefetch();
        //     handleCancel();
        //   },
        // });
        const { school_info, order_leveltest, user } = res.createConsultLevelTestForSmartEclass;
        const findItem = testOptions?.find((ele) => {
          if (['초1', '초2'].includes(school_info)) {
            return ele.label === 'Type P';
          } else if (['초3', '초4'].includes(school_info)) {
            return ele.label === 'Type A';
          } else if (['초5'].includes(school_info)) {
            return ele.label === 'Type B';
          } else if (['초6'].includes(school_info)) {
            return ele.label === 'Type C';
          } else {
            return false;
          }
        });
        let test_idx = findItem?.value;
        if (!test_idx) {
          test_idx = testOptions[0].value;
        }
        const limit_time = 60;
        const title = `Placement Test ${findItem?.label}`;
        const start_time = moment(order_leveltest[0]?.reserve_date).format('YYYY-MM-DD');
        const user_idx_list = [user.idx];

        assignPlacementTest({
          variables: { title, start_time, limit_time, user_idx_list, test_idx, order_leveltest_idx: order_leveltest[0]?.idx },
        });
      } else {
        Modal.info({
          title: '등록하지 못했습니다.',
          onOk: () => {
            handleCancel();
          },
        });
      }
    },
    onError: (e) => {
      console.log(e);
      alert('저장 중 오류가 발생했습니다.');
    },
  });

  function handleSubmit(values) {
    const { phone, student_name, school_grade, english_name, student_phone, address, reserve_date } = values;
    //console.log(phone, student_name, school_grade, english_name, student_phone, address, moment(reserve_date).format('YYYY-MM-DD'));

    createLevelTest({
      variables: {
        phone,
        student_name,
        school_grade,
        english_name,
        student_phone,
        address,
        reserve_date: moment(reserve_date).format('YYYY-MM-DD'),
      },
    });
  }
  function handleCancel() {
    form.resetFields();
    onCancel();
  }
  return (
    <Modal
      wrapClassName="survey-modal"
      style={{ top: 20 }}
      visible={visible}
      title="시험등록"
      onCancel={handleCancel}
      width={'50%'}
      footer={[
        <Button
          type="primary"
          loading={loading || loadingAssign}
          key="save"
          onClick={() => {
            form.submit();
          }}
        >
          등록
        </Button>,
        <Button key="back" onClick={handleCancel}>
          취소
        </Button>,
      ]}
    >
      <Form layout="inline" style={{ width: '100%' }} form={form} onFinish={handleSubmit}>
        <FormField hasFeedback={true} title="학생이름">
          <Form.Item
            name="student_name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: '학생이름을 입력해 주세요.',
              },
            ]}
          >
            <Input placeholder="학생이름" size="middle" name="student_name" maxLength={10} />
          </Form.Item>
        </FormField>
        <FormField title="영어이름">
          <Form.Item name="english_name">
            <Input placeholder="영어이름" size="middle" name="english_name" maxLength={10} />
          </Form.Item>
        </FormField>
        <FormField hasFeedback={true} title="학부모전화">
          <Form.Item
            rules={[
              {
                required: true,
                whitespace: true,
                pattern: /[0-9]+/g,
                message: '숫자만 입력해 주세요.',
              },
            ]}
            name="phone"
          >
            <Input type="text" placeholder="숫자만 입력해 주세요." size="middle" name="phone" maxLength={13} />
          </Form.Item>
        </FormField>
        <FormField title="학생 휴대전화">
          <Form.Item
            name="student_phone"
            rules={[
              {
                required: false,
                whitespace: true,
                pattern: /[0-9]+/g,
                message: '숫자만 입력해 주세요.',
              },
            ]}
          >
            <Input type="text" placeholder="숫자만 입력해 주세요." size="middle" name="student_phone" maxLength={13} />
          </Form.Item>
        </FormField>

        <FormField hasFeedback={true} title="현재 학년">
          <Form.Item
            rules={[
              {
                required: true,
                message: '선택하세요.',
              },
            ]}
            name="school_grade"
          >
            <Select defaultValue={''} name="school_grade">
              <Option value="">선택</Option>
              {GRADES.map((item) => (
                <Option key={`grade${item}`} value={`초${item}`}>
                  초등학교{item}학년
                </Option>
              ))}
            </Select>
          </Form.Item>
        </FormField>

        <FormField hasFeedback={true} title="응시일">
          <Form.Item
            rules={[
              {
                required: true,
                message: '선택하세요.',
              },
            ]}
            name="reserve_date"
          >
            <DatePicker name="reserve_date" />
          </Form.Item>
        </FormField>

        <FormField title="집주소" span={24} subFirstSpan={3} subSecondSpan={21}>
          <Form.Item name="address">
            <Input type="text" placeholder="집주소" size="middle" name="address" maxLength={50} />
          </Form.Item>
        </FormField>
      </Form>
    </Modal>
  );
};

export default SmartEclassRegisterPlacementTestModal;
