import React, { useMemo, useRef } from 'react';
import { Form, Input, Button, Modal, Typography, Radio, Row, Col, Select, Checkbox } from 'antd';
import { PrinterTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import * as queries from 'src/operations/queries';

const { Title } = Typography;
const { Option } = Select;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const PrintWrapper = styled.div`
  width: 100%;
  @media print {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const PrintBanner = styled.div`
  display: none;
  @media print {
    display: block;
    background-image: url(/images/lt-app-form-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #eee;
  }
`;

function SurveyModal({ handleCancel, visible, orderIdx, surVeyIdx }) {
  const printAreaComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printAreaComponentRef.current,
  });

  const { data: fetchOrder } = useQuery(queries.order.GET_ORDER_ITEM2, {
    skip: !orderIdx,
    variables: { idx: parseInt(orderIdx) },
  });

  const orderInfo = useMemo(() => {
    if (fetchOrder) {
      const order = { ...fetchOrder.orderItemByIdx };
      return {
        idx: order.idx,
        name: order.name,
        parent_phone: order.parent_phone,
        school_grade: order.user.school_grade,
        school_name: order.campus.name,
        school_info: order.school_info,
        idate: order.idate,
      };
    }
    return {};
  }, [fetchOrder]);

  const orderSurveyAnswerList = useMemo(() => {
    if (fetchOrder) {
      if (fetchOrder.orderItemByIdx?.order_survey_answer) {
        const orderSurveyAnswers = fetchOrder.orderItemByIdx?.order_survey_answer.reduce((acc, cur) => {
          const { answer, memo } = cur;
          if (acc[cur.no]) {
            acc[cur.no].push({ answer, memo });
          } else {
            acc[cur.no] = [{ answer, memo }];
          }

          return { ...acc };
        }, {});
        return orderSurveyAnswers;
      }
    }
    return {};
  }, [fetchOrder]);

  //console.log('orderSurveyAnswerList', orderSurveyAnswerList, orderSurveyAnswerList[2][0]);

  if (!orderSurveyAnswerList[1]) {
    return false;
  }

  return (
    <>
      <Modal
        wrapClassName="survey-modal"
        style={{ top: 20 }}
        visible={visible}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 style={{ paddingTop: '20px', paddingBottom: '10px', color: '#efefef' }}>입학상담설문</h2>
            <Button
              onClick={handlePrint}
              style={{ marginTop: 20, marginRight: 100, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}
            >
              <PrinterTwoTone />
              Print
            </Button>
          </div>
        }
        onCancel={handleCancel}
        width={'50%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <PrintWrapper ref={printAreaComponentRef} className="survey-print-wrapper">
          <PrintBanner>
            <h2 style={{ paddingTop: '25px', paddingBottom: '25px', color: '#efefef', textAlign: 'center' }}>입학상담설문</h2>
          </PrintBanner>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CustomTable
                pagination={false}
                bordered
                style={{ width: '100%', marginBottom: 24 }}
                dataSource={[
                  {
                    key: 1,
                    school_name: orderInfo.school_name,
                    school_grade: orderInfo.school_grade,
                    school_info: orderInfo.school_info,
                    name: orderInfo.name,
                    parent_phone: orderInfo.parent_phone,
                  },
                ]}
                columns={columns}
                scroll={{ y: 'calc(100vh - 254px)' }}
                size="small"
                color="#edf3fb"
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>1. 방문하시게 된 계기를 입력해 주세요.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey1" value={orderSurveyAnswerList[1][0]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  지인의 소개(예: 학생이름){' '}
                  {orderSurveyAnswerList[1][0]?.answer === '1' ? (
                    <Input value={orderSurveyAnswerList[1][0]?.memo} style={{ display: 'inline-block', width: '40%' }} readOnly />
                  ) : null}
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  광고지
                </Radio>
                <Radio style={radioStyle} value={'3'}>
                  간판
                </Radio>
                <Radio style={radioStyle} value={'4'}>
                  인터넷
                </Radio>
                <Radio style={radioStyle} value={'5'}>
                  배너광고
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>2. 영어공부(학원, 개인교습 등) 경험이 있나요? 있다면 이름과 기간을 알려주세요.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey2" value={orderSurveyAnswerList[2][0]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  있다.
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  없다.
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          {orderSurveyAnswerList[2][0]?.answer==='1'&&<Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>2-a. '네'를 선택하신 경우 입력해 주세요.(중복선택가능)</Title>
            </Col>
            <Col>
              <Checkbox.Group
                name="survey3"
                value={orderSurveyAnswerList[3] ? orderSurveyAnswerList[3].map((item) => item.answer) : []}
                options={[
                  {
                    label: (
                      <>
                        영어유치원 (유치원명:{' '}
                        <Input
                          value={orderSurveyAnswerList[3] ? orderSurveyAnswerList[3][0]?.memo : ''}
                          style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
                          readOnly
                        />
                        )
                      </>
                    ),
                    value: '1',
                  },
                  {
                    label: (
                      <>
                        어학원 (학원명:{' '}
                        <Input
                          value={orderSurveyAnswerList[3] ? orderSurveyAnswerList[3][1]?.memo : ''}
                          style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
                          readOnly
                        />
                        )
                      </>
                    ),
                    value: '2',
                  },
                  {
                    label: (
                      <>
                        학습지 (학습지명{' '}
                        <Input
                          value={orderSurveyAnswerList[3] ? orderSurveyAnswerList[3][2]?.memo : ''}
                          style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
                          readOnly
                        />
                        )
                      </>
                    ),
                    value: '3',
                  },
                  {
                    label: (
                      <>
                        1:1(개인)교습{' '}
                        <Input
                          value={orderSurveyAnswerList[3] ? orderSurveyAnswerList[3][3]?.memo : ''}
                          style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
                          readOnly
                        />
                      </>
                    ),
                    value: '4',
                  },
                  {
                    label: (
                      <>
                        기타{' '}
                        <Input
                          value={orderSurveyAnswerList[3] ? orderSurveyAnswerList[3][4]?.memo : ''}
                          style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
                          readOnly
                        />
                      </>
                    ),
                    value: '5',
                  },
                ]}
              />
            </Col>
          </Row>}

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>3. 해외 체류 경험(어학연수, 유학 등)이 있나요? 있다면 장소와 기간을 알려주세요.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey4" value={orderSurveyAnswerList[4] ? orderSurveyAnswerList[4][0]?.answer : ''}>
                <Radio style={radioStyle} value={'1'}>
                  있다.
                  <Input
                    value={orderSurveyAnswerList[4] ? orderSurveyAnswerList[4][0]?.memo : ''}
                    style={{ display: 'inline-block', width: '70%', marginLeft: '10px' }}
                    readOnly
                  />
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  없다.
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>4. 자녀가 가장 어려워하거나 중점적으로 학습했으면 하는 영역은 무엇인가요?(중복 선택 가능)</Title>
            </Col>
            <Col>
              <Checkbox.Group
                value={orderSurveyAnswerList[5] ? orderSurveyAnswerList[5].map((item) => item.answer) : []}
                name="survey5"
                options={[
                  { label: 'Phonics', value: '1' },
                  { label: 'Grammar', value: '2' },
                  { label: 'Vocabulary', value: '3' },
                  { label: 'Listening', value: '4' },
                  { label: 'Speaking', value: '5' },
                  { label: 'Reading', value: '6' },
                  { label: 'Writing', value: '7' },
                  {
                    label: (
                      <>
                        기타{' '}
                        <Input
                          value={orderSurveyAnswerList[5]?.filter((item) => item.answer === '8')[0]?.memo}
                          style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
                          readOnly
                        />
                      </>
                    ),
                    value: '8',
                  },
                ]}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>5. 학부모님께서 생각하시는 자녀의 학습 성향을 선택해주세요. (중복 선택 가능)</Title>
            </Col>
            <Col>
              <Checkbox.Group
                name="survey6"
                value={orderSurveyAnswerList[6] ? orderSurveyAnswerList[6].map((item) => item.answer) : []}
                options={[
                  { label: '혼자 학습하는 것을 선호한다.', value: '1' },
                  { label: '그룹으로 학습하는 것을 선호한다.(경쟁심 동기부여)', value: '2' },
                  { label: '학습을 자기 주도적으로 한다.', value: '3' },
                  { label: '차분하고 꼼꼼한 성격이다.', value: '4' },
                  { label: '의욕은 있지만 꼼꼼하지 못하다.', value: '5' },
                  { label: '외향적인 성격으로 발표하는 것을 좋아한다.', value: '6' },
                  { label: '내성적인 성격으로 친구들 앞에서 이야기 하는 것을 어려워한다.', value: '7' },
                ]}
              />
            </Col>
          </Row>
        </PrintWrapper>
      </Modal>
    </>
  );
}

const columns = [
  {
    title: '학원',
    dataIndex: 'school_name',
    key: 'school_name',
    align: 'center',
  },
  {
    title: '학교/학년',
    dataIndex: 'school_info',
    key: 'school_info',
    align: 'center',
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '학부모 연락처',
    dataIndex: 'parent_phone',
    key: 'parent_phone',
    align: 'center',
  },
];

export default SurveyModal;
