import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Typography, Button, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useParams, useLocation } from 'react-router-dom';
import { classStoreData } from 'src/operations/store';
import { DownloadOutlined, PrinterTwoTone } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import * as queries from 'src/operations/queries';
import { settings } from 'src/dummy/settings';
const { Option } = Select;

const HomeWorkTable = styled(CustomTable)`
  border-top: 3px solid #a4d0e9;
`;

const { Title, Text } = Typography;

const sortByName = (a, b) => {
  //이름순 정렬
  if (a.name === b.name) {
    return 0;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
};

const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    align: 'center',
  },
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    align: 'center',
    sorter: sortByName,
  },
  {
    key: 'words',
    title: 'Words',
    dataIndex: 'words',
    align: 'center',
  },
  {
    key: 'reading',
    title: 'Reading',
    dataIndex: 'reading',
    align: 'center',
  },
  {
    key: 'quiz',
    title: 'Quiz',
    dataIndex: 'quiz',
    align: 'center',
  },
  {
    key: 'word_practice',
    title: 'Word Practice',
    dataIndex: 'word_practice',
    align: 'center',
  },
  {
    key: 'word_game',
    title: 'Word Game',
    dataIndex: 'word_game',
    align: 'center',
  },
  {
    key: 'recording',
    title: 'Recording',
    dataIndex: 'recording',
    align: 'center',
  },
];

const ElibraryLearning = () => {
  const { assigned_elibrary_group_idx, class_idx, idx } = useParams();
  const companyName = useSelector(classStoreData);

  const { state } = useLocation();
  const [isEssayVisible, setIsEssayVisible] = useState(false);
  const [currentIndx, setCurrentIndx] = useState(0);
  const [essayTitle, setEssayTitle] = useState('');
  const [currentAssignedBookList, setCurrentAssignedBookList] = useState(null);
  const [currentUserTitle, setCurrentUserTitle] = useState('');
  const [currentUserName, setCurrentUserName] = useState('');
  const [essayContent, setEssayContent] = useState('');
  const {
    data: fetchAssignedElibraryDetails,
    refetch: assginedRefetch,
    loading,
  } = useQuery(queries.elibraryBookList.ASSIGNED_ELIBRARY_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: { assigned_elibrary_group_idx: parseInt(assigned_elibrary_group_idx) },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function openEssayModal(essayInfo, name, nameStr) {
    setIsEssayVisible(true);
    setEssayTitle(essayInfo?.title);
    setEssayContent(essayInfo?.content);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }
  function handleOk() {
    setIsEssayVisible(false);
  }
  const className = state?.className || '';

  const assignedBookList = useMemo(() => {
    if (fetchAssignedElibraryDetails?.assignedElibrary) {
      let fetchData = fetchAssignedElibraryDetails?.assignedElibrary;
      return fetchData.reduce((acc, cur, index) => {
        const obj = {
          title: `${cur?.book_lesson.title}`,
          No: index + 1,
          key: cur.idx,
        };

        return [...acc, obj];
      }, []);
    }
  });

  const assignedBookData = useMemo(() => {
    if (fetchAssignedElibraryDetails?.assignedElibrary) {
      return fetchAssignedElibraryDetails?.assignedElibrary;
    }
  });

  const userLessons = useMemo(() => {
    if (assignedBookData && assignedBookData.length) {
      console.log(currentIndx);
      let fetchData = assignedBookData[currentIndx].assigned_elibrary_users;

      return fetchData.reduce((acc, cur, index) => {
        const words = assignedBookData[currentIndx].user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 1);
        const reading = assignedBookData[currentIndx].user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 2);
        const quiz = assignedBookData[currentIndx].user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 3);
        const word_practice = assignedBookData[currentIndx].user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 4);
        const word_game = assignedBookData[currentIndx].user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 5);
        const recording = assignedBookData[currentIndx].user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 6);
        const obj = {
          name: `${cur?.user.name}`,
          No: index + 1,
          key: cur.idx,
          words: words.length ? (
            <span style={{ color: '#289428' }}>{settings[companyName].perform}</span>
          ) : (
            <span style={{ color: '#ef811a' }}>{settings[companyName].notPerform}</span>
          ),
          reading: reading.length ? (
            <span style={{ color: '#289428' }}>{settings[companyName].perform}</span>
          ) : (
            <span style={{ color: '#ef811a' }}>{settings[companyName].notPerform}</span>
          ),
          quiz: quiz.length ? (
            <span style={{ color: '#289428' }}>{`${quiz[0]['exam_correct']}/${quiz[0]['exam_total']}`}</span>
          ) : (
            <span style={{ color: '#ef811a' }}>{settings[companyName].notPerform}</span>
          ),
          word_practice: word_practice.length ? (
            <span style={{ color: '#289428' }}>{settings[companyName].perform}</span>
          ) : (
            <span style={{ color: '#ef811a' }}>{settings[companyName].notPerform}</span>
          ),
          word_game: word_game.length ? (
            <span style={{ color: '#289428' }}>{`${word_game[0]['exam_correct'] ? word_game[0]['exam_correct'] : 100}/${
              word_game[0]['exam_total'] ? word_game[0]['exam_total'] : 100
            }`}</span>
          ) : (
            <span style={{ color: '#ef811a' }}>{settings[companyName].notPerform}</span>
          ),
          recording: recording.length ? (
            <span style={{ color: '#289428' }}>{`${recording[0]['wpm'] ? recording[0]['wpm'] : `${settings[companyName].perform}`}`}</span>
          ) : (
            <span style={{ color: '#ef811a' }}>{settings[companyName].notPerform}</span>
          ),
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [assignedBookData, currentIndx]);

  useEffect(() => {
    if (idx && assignedBookData) {
      console.log(assignedBookData.findIndex((a) => a.idx === parseInt(idx)));
      setCurrentIndx(assignedBookData.findIndex((a) => a.idx === parseInt(idx)));
    }
  }, [assignedBookData]);

  const lessonStudyDate = useMemo(() => {
    if (assignedBookData) {
      return moment(assignedBookData[currentIndx].start_date).format('YYYY-MM-DD');
    }
  }, [assignedBookData, currentIndx]);

  // const exportToExcel = () => {
  //   ExportExcel(realColumn, userLessons);
  // };

  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>Homework Result ({assignedBookList ? assignedBookList[currentIndx]['title'] : ''})</HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          {assignedBookList ? (
            <Select
              className="selectlwithback"
              size={`default`}
              loading={loading}
              placeholder="학생 선택"
              onChange={(val, i) => setCurrentIndx(val)}
            >
              {assignedBookList && assignedBookList.map((item, i) => <Option key={i}>{item.title}</Option>)}
            </Select>
          ) : null}
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="books-result-halfwrapper resultpages-halfwrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              <Title level={5}>
                {state?.lessonTitle}( <Text type="success">{lessonStudyDate}</Text> )
              </Title>
              {/* <Button style={{ color: '#289428' }}>+ Essay 출제</Button> */}
              {/* <Button style={{ color: '#289428' }} icon={<DownloadOutlined />} onClick={exportToExcel}>
                Excel
            </Button> */}
            </div>

            <HomeWorkTable
              loading={loading}
              dataSource={userLessons}
              columns={columns}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ x: 530, y: 390 }}
            />
          </HalfWrapper>
        </Col>
      </Row>
      <Modal
        title={essayTitle}
        width="60%"
        visible={isEssayVisible}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button onClick={handlePrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
            <PrinterTwoTone />
          </Button>,
          <Button type="primary" key="1" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <div ref={componentRef} className="show-print" style={{ padding: 20 }}>
          <div
            className="show-print-flex"
            style={{
              background: '#ebebeb',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: '10px',
            }}
          >
            <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" />
            <Title level={3} style={{ padding: '0 10px' }}>
              English Vine
            </Title>
          </div>
          <div
            className="show-print-flex"
            style={{
              background: '#ebebeb',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
            }}
          >
            <Title level={3} style={{ padding: '10px' }}>
              Student Name: {currentUserName}
            </Title>
            <Title level={3} style={{ padding: '10px', margin: 0 }}>
              Class: {className}
            </Title>
          </div>
          <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
            {`Title: ${essayTitle}`}
          </Title>
          <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: essayContent }} />
        </div>
        <div class="hide-print" dangerouslySetInnerHTML={{ __html: essayContent }} />
      </Modal>
    </>
  );
};

export default ElibraryLearning;
