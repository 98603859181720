import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as axios from 'axios';
import { Route } from 'react-router-dom';
import { Layout, Button } from 'antd';
import Loading from 'src/components/common/Loading';
import BookrHeader from 'src/components/Header/BookrHeader';
import Navigation from 'src/components/Navigation';
import { userInfoVar } from 'src/apollo/cache';
// import SimplePopup from 'src/popups/SimplePopup';
// import { isAfterSchool } from 'src/utils';
import { classStoreData } from 'src/operations/store';
import { useSelector } from 'react-redux';
import RankModal from 'src/components/Modal/RankModal';
import { EXTERNAL_STUDY_RANKING_LIST, MY_EXTERNAL_STUDY_GOAL } from 'src/operations/queries/study';
import { useQuery } from '@apollo/client';
import moment from 'moment';
const { Content } = Layout;
const { Sider } = Layout;

function BookrLayout({ component: Component, ...rest }) {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [level, setLevel] = useState('All');
  const [bookType, setBookType] = useState('All');
  const [sort, setSort] = useState('Newest');
  const [search, setSearch] = useState('');
  const [topic, setTopic] = useState('All');
  const [searchOption, setSearchOption] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchHistory, setSearchHistory] = useState([]);
  const [clickStatus, setClickStatus] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [monthGoal, setMonthGoal] = useState();
  const defaultCompany = useSelector(classStoreData);
  const companyIdx = userInfoVar()?.campus?.company?.idx;
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const currentPath = history.location.pathname;

  const isPathElibrary = currentPath.includes('/e-booklibrary');

  const { data, refetch, loading } = useQuery(EXTERNAL_STUDY_RANKING_LIST, {
    variables: {
      company_idx: companyIdx,
      type: 'bookr',
      start: `${moment(firstDay).format('YYYY-MM-DD')}`,
      end: `${moment(lastDay).format('YYYY-MM-DD')}`,
    },
    skip: !isPathElibrary,
  });

  const { data: mylist, refetch: refetchList } = useQuery(MY_EXTERNAL_STUDY_GOAL, {
    variables: { ym: moment(firstDay).format('YYYY-MM'), type: 'bookr' },
    skip: !isPathElibrary,
  });
  useEffect(() => {
    if (isPathElibrary) {
      refetchList();
    }
  }, [monthGoal, isPathElibrary]);

  useEffect(() => {
    if (companyIdx && isPathElibrary) {
      refetch();
    }
  }, [companyIdx, isPathElibrary]);

  const handleDrawer = () => {
    clickStatus ? setClickStatus(false) : setClickStatus(true);
  };

  const onCollapse = useCallback(() => {
    history.push('/');
  }, []);
  const onLevel = useCallback((v) => {
    setLevel(v);
  }, []);

  const onBookType = useCallback((v) => {
    setBookType(v);
  }, []);

  const onSort = useCallback((v) => {
    setSort(v);
  }, []);

  const onSearch = useCallback((v) => {
    setSearch(v);
  }, []);
  const onTopic = useCallback((v) => {
    setTopic(v);
  }, []);
  useEffect(() => {
    const recentSearch = JSON.parse(localStorage.getItem('searchHistory')) || [];
    setSearchHistory(recentSearch);
  }, []);
  const handleGoal = (val) => {
    setMonthGoal(val);
    setShowPopup(false);
  };
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          {/*  */}
          {/* {isAfterSchool(defaultCompany) && <SimplePopup />} */}
          {/*  */}
          <Layout className="elib-layout">
            <img src="/images/bg_banana2.png" className="partimg-bg banana" alt="banana" />
            <img src="/images/bg_butterfly2.png" className="partimg-bg butterfly" alt="butterfly" />
            <img src="/images/bg_bird.png" className="partimg-bg bird" alt="bird" />
            <button
              className={clickStatus ? 'rank__btn active' : 'rank__btn'}
              style={{ marginTop: '115px', zIndex: '99999', opacity: '1', cursor: 'pointer', display: 'none' }}
              onClick={(e) => {
                handleDrawer();
              }}
            ></button>
            {clickStatus && <div className="rank__layer" style={{ display: 'block' }}></div>}
            {clickStatus ? (
              <>
                <Sider
                  trigger={null}
                  className="company-sider"
                  style={{
                    height: '81%',
                    overflowY: 'auto',
                    right: 0,
                    position: 'fixed',
                    zIndex: '5',
                    backgroundColor: '#ffff',
                    bottom: '0',
                    width: '305px',
                  }}
                >
                  <div class="rank__wrap">
                    <button
                      type="button"
                      className="rank__close"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setClickStatus(false)}
                    ></button>
                    <div className="rank__box score_box">
                      <span className="rank__title float__left">총 읽은 책</span>
                      <span className="rank__span float__right">
                        <b className="blue" id="myElibraryGoal_cntTotal">
                          {mylist ? mylist?.myExternalStudyGoal?.cntRead : 0}
                        </b>
                        권
                      </span>
                    </div>
                    <div class="rank__box">
                      <div class="score_box">
                        <span class="rank__title float__left">이번 달 목표</span>
                        <span class="rank__span float__right">
                          <b class="red" id="myElibraryGoal_cntRead">
                            {mylist ? mylist?.myExternalStudyGoal?.cntRead : 0}
                          </b>
                          / {mylist ? mylist?.myExternalStudyGoal?.cntGoal : 0}
                        </span>
                      </div>
                      <div className="rank_progress_bar">
                        <div className="value_bar" style={{ width: `${mylist ? mylist?.myExternalStudyGoal?.cntGoal : 0}%` }}></div>
                      </div>

                      <Button
                        type="button"
                        id="btn-setting-goal"
                        className="btn btn__color--blue btn__size--normal popup__open"
                        data-popup="settingGoalModal"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowPopup(true)}
                      >
                        목표 설정
                      </Button>
                    </div>
                    <div className="rank__box">
                      <span className="rank__title rank__title--center">이달의 독서 랭킹</span>
                      <table className="rank__table">
                        <thead>
                          <tr>
                            <th>순위</th>
                            <th>이름</th>
                            <th>아이디</th>
                          </tr>
                        </thead>
                        <tbody id="rankBody">
                          {data?.externalStudyRankingList?.slice(0, 5).map((ele, i) => {
                            console.log('indx', i);
                            return (
                              <>
                                <tr>
                                  <td>{ele?.sum_grade}</td>
                                  <td>{ele?.user_name}</td>
                                  <td>{ele?.user_idx}</td>
                                </tr>
                              </>
                            );
                          })}
                          <tr>
                            <td class="noranking" colspan="3">
                              도전 독서은
                              <br />
                              오늘밤 12시에 반영됩니다.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Sider>
                {showPopup ? (
                  <div className="popup__layer">
                    <RankModal visible={true} handleClose={() => setShowPopup(false)} getGoal={(val) => handleGoal(val)} />
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
            <BookrHeader
              collapsed={collapsed}
              onCollapse={onCollapse}
              setLevel={onLevel}
              level={level}
              bookType={bookType}
              setBookType={onBookType}
              sort={sort}
              setSort={onSort}
              search={search}
              topic={topic}
              setTopic={onTopic}
              setSearch={onSearch}
              searchOption={searchOption}
              setSearchOption={setSearchOption}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              searchHistory={searchHistory}
              setSearchHistory={setSearchHistory}
            />

            <Content style={{ margin: '0 16px' }}>
              <Component
                {...matchProps}
                {...rest}
                level={level}
                bookType={bookType}
                sort={sort}
                topic={topic}
                search={search}
                setSearch={setSearch}
                searchOption={searchOption}
                setSearchOption={setSearchOption}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchHistory={searchHistory}
                setSearchHistory={setSearchHistory}
              />
            </Content>
          </Layout>
        </Layout>
      )}
    />
  );
}

export default BookrLayout;
