import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';

import { MainPage, AddPage, ListPage } from 'src/components/Zoom';
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_ZOOM_SCHEDULE } from 'src/operations/mutations/createZoom';
import { USER_ME, GET_STUDENT_USER, GET_TEACHER_USER } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { withRouter } from "react-router"

const Zoom = (props) => {
  const { type = 'list' } = useParams();

  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const token = getCurrentToken();
  const { data } = useQueryProxy(USER_ME, { variables: { token } });
  const [optionsList, setOptionsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({idx:0});

  let getUserType = GET_STUDENT_USER;

  if (data?.me && data?.me?.type !== '0') {
    getUserType = GET_TEACHER_USER;
  }

  const userDataObj = useQuery(getUserType, { variables: {} });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }

    if (userDataObj?.data?.getUserData) {
      let tempData = userDataObj?.data?.getUserData;
      if (tempData.class_teacher) {
        tempData = Object.assign({}, tempData, {classes:tempData.class_teacher.map(i => i.class)})
      }else{
        tempData = Object.assign({}, tempData, {classes:tempData.class_teacher.map(i => i.class)})
      }
      let tempList = tempData.classes.map(item => ({idx:`cal-${item.idx}`, name:item.name}));
      tempList.push({idx:`cam-${tempData.campus.idx}`, name:tempData.campus.name})
      setOptionsList(tempList)
      setSelectedItem({idx:`cam-${tempData.campus.idx}`, name:tempData.campus.name});
      setUserData(tempData);
    }
  }, [data, userDataObj]);


  const handleSelectedValueChange = (e, v) => {
    setSelectedItem({idx:e, name:v.children});
  }

  const [createZoomSchedule, {loading}] = useMutation(CREATE_ZOOM_SCHEDULE);

  const handleZoomScheduleCreate = async (data) => {
    try {
      await createZoomSchedule({ variables: data });

      props.history.push('/zoom/manage/main')
    } catch (e) {
      console.log(e);
    }
}

  return (
    <>
      <Row gutter={[24, 16]}>
        {type === 'main' ? <MainPage user={user} userData={userData} optionsList={optionsList} selectedItem={selectedItem} onSelectedValueChange={handleSelectedValueChange} /> : (type === 'add' ? <AddPage onClickSave={handleZoomScheduleCreate} /> : <ListPage user={user} userData={userData} optionsList={optionsList} selectedItem={selectedItem} onSelectedValueChange={handleSelectedValueChange}/>)}
      </Row>
    </>
  );
};
export default withRouter(Zoom);
