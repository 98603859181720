import React, { useMemo } from 'react';
import { Col, Typography, Row, Card, Collapse } from 'antd';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useMobileOrientation, isMobile } from 'react-device-detect';
import TodayStudy from 'src/pages/DashBoard/TodayStudy';
import CustomTable from 'src/components/common/CustomTable';
import StudentCalendar from './StudentCalendar';

import { useQueryProxy } from 'src/operations/proxy/user';
import { userInfoVar, rootStateVar } from 'src/apollo/cache';
import { BOOK_LIST_FOR_DASHBOARD } from 'src/operations/queries/book';
import { classStoreData } from 'src/operations/store';
import * as queries from 'src/operations/queries';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Meta } = Card;
const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;
const CONTENTS_BASE_URL = 'https://culp-api.cloubot.com';
const TodoBlock = styled.div``;

const NoticeBlock = styled.div``;

const BookCard = styled(Card)`
  .ant-card-body {
    padding: 5px;
    height: 40px;
  }
`;
const BookMeta = styled(Meta)`
  .ant-card-body {
    padding: 5px;
  }

  .ant-card-meta-title {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 1px;
  }
`;

const Panel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-header-text,
  .ant-collapse-expand-icon {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
  }
`;

const Fake_data = [
  {
    date: '14:00',
    name: 123123123123,
    title: ' Zoom Class!',
    percent: 'zoom',
    status: 5,
    isMeeting: true,
  },
];

const Student = () => {
  const { isPortrait } = useMobileOrientation();
  const [activeCalendar, setActiveCalendar] = React.useState(isPortrait && isMobile ? false : true);
  const defaultClass = useSelector(classStoreData);
  const { data: fetchNoticeBoard, loading: noticeLoading } = useQueryProxy(queries.board.GET_BOARD_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      bo_table: 'notice',
      company_idx: userInfoVar()?.campus?.company?.idx,
    },
  });

  const noticeData = useMemo(() => {
    if (fetchNoticeBoard) {
      let boaradNumber = fetchNoticeBoard?.getBoardList?.length || 0;
      return fetchNoticeBoard.getBoardList.reduce((acc, cur, index) => {
        const obj = {
          key: cur.idx,
          no: boaradNumber--,
          title: cur.wr_subject,
          name: cur.user.name,
          date: moment(cur.wr_datetime.replace('.000Z', '')).format('YYYY-MM-DD'),
          pageName: cur.board.bo_table,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchNoticeBoard]);

  // console.log(userInfoVar());
  const culp_token = window.localStorage.getItem('culp_token');

  const handleMoveDate = (side) => {
    if (side === 'prev') {
      rootStateVar({
        ...rootStateVar(),
        calendar: {
          ...rootStateVar().calendar,
          selectDay: moment(rootStateVar().calendar.selectDay).subtract(1, 'days').format('YYYY-MM-DD'),
        },
      });
    } else {
      rootStateVar({
        ...rootStateVar(),
        calendar: {
          ...rootStateVar().calendar,
          selectDay: moment(rootStateVar().calendar.selectDay).add(1, 'days').format('YYYY-MM-DD'),
        },
      });
    }
  };

  const extra = (
    <div>
      <ArrowLeftOutlined
        style={{ marginRight: '10px', fontSize: '20px', padding: '10px' }}
        onClick={(e) => {
          e.stopPropagation();
          handleMoveDate('prev');
        }}
      />
      <ArrowRightOutlined
        style={{ fontSize: '20px', padding: '10px' }}
        onClick={(e) => {
          e.stopPropagation();
          handleMoveDate('next');
        }}
      />
    </div>
  );

  return (
    <>
      <Col span={11} className="calendar-box">
        {isPortrait && isMobile && (
          <Collapse defaultActiveKey={activeCalendar ? ['1'] : []} onChange={(key) => setActiveCalendar(key.includes('1') ? true : false)}>
            <Panel header="Open Calendar" key="1" style={{ padding: '0px' }} extra={extra}>
              <StudentCalendar />
            </Panel>
          </Collapse>
        )}
        {!(isPortrait && isMobile) && <StudentCalendar />}
      </Col>

      <Col span={13} className="calendar-box-two">
        <TodoBlock className="calendar-box-two-a" style={{ boxShadow: 'none', borderRadius: '10px' }}>
          {userInfoVar()?.type >= 4 ? <div>일정</div> : <TodayStudy Fake_data={[]} />}
        </TodoBlock>
        <NoticeBlock className="dashboard-listing calendar-box-two-b" style={{ boxShadow: 'none', borderRadius: '10px' }}>
          <Title level={5}>Notice</Title>
          <div className={defaultClass === 'lucid' ? 'cmp-notice' : ''}>
            <CustomTable
              pagination={{ pageSize: 5 }}
              dataSource={noticeData}
              loading={noticeLoading}
              scroll={{ y: 'calc(100vh - 254px)' }}
              columns={noticeColumns}
              size="small"
              color="#edf3fb"
            />
          </div>
        </NoticeBlock>
        {/* <MyBookList /> */}
      </Col>
    </>
  );
};

const noticeColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'left',
    width: '7%',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (text, record) => {
      return <Link to={`/board/${record.pageName}/${record.key}`}>{text}</Link>;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '20%',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: '20%',
  },
];

export default Student;

const MyBookList = () => {
  const user_idx = useMemo(() => {
    return parseInt(userInfoVar()?.idx);
  }, []);

  const { data, loading } = useQuery(BOOK_LIST_FOR_DASHBOARD, { variables: { user_idx } });
  const bookList = useMemo(() => {
    if (data?.bookListByUserIdx) {
      const levelBook = data?.bookListByUserIdx[0];
      if (levelBook?.book_sub) {
        return levelBook.book_sub.map(({ title, cover_path, ebook_path }) => {
          return {
            title,
            ebook_path: ebook_path?.replace('/teacher/', '/student/').trim(),
            cover_path: cover_path?.replace('/teacher/', '/student/').trim(),
          };
        });
      }
    }
    return [];
  }, [data]);

  return (
    <NoticeBlock>
      <Title level={5}>Contents</Title>
      <Row gutter={[16, 16]}>
        {bookList &&
          bookList.map(({ title, cover_path, ebook_path }, key) => (
            <Col span={4} key={`my-book-list-key${key}`}>
              <BookCard
                onClick={() => {
                  window.open(`${CONTENTS_BASE_URL}${ebook_path}`, '_blank');
                }}
                hoverable
                cover={
                  <div
                    style={{
                      width: '100%',
                      height: '100px',
                      backgroundColor: '#fff',
                      backgroundImage: `url(${CONTENTS_BASE_URL}${cover_path})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      border: '1px solid #eee',
                    }}
                  />
                }
              >
                <BookMeta title={title} />
              </BookCard>
            </Col>
          ))}
      </Row>
    </NoticeBlock>
  );
};
