import React from 'react';
import { Col, Row,Card } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import "react-simple-keyboard/build/css/index.css";

const MainBlock = styled.div`
  padding: 10px;
  background: #d9e2f1;
  margin-top: 10px;
  height: calc(100vh - 94px);
`;

const ListPage = (props) => {
  return (
    <>
      <Col span={24} style={{textAlign: "left", margin:0}}>
        <MainBlock>
        <Row gutter={[24, 16]} style={{height:"100%"}}>
          <Col span={8}>
            <Link to={`/tool/word/all`} >
              <Card bordered={false}>
                VOCA
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`/tool/quiz/all`} >
              <Card bordered={false}>
                QUIZ
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`/tool/listen/all`} >
              <Card bordered={false}>
                LISTEN
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`/tool/listen-repeat/all`} >
              <Card bordered={false}>
                LISTEN Repeat
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`/tool/translate/all`} >
              <Card bordered={false}>
                TRANSALATE
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`/tool/translate-word/all`} >
              <Card bordered={false}>
                TRANSALATE WORD
              </Card>
            </Link>
          </Col>
          <Col span={8}>
            <Link to={`/tool/essay/all`} >
              <Card bordered={false}>
                ESSAY
              </Card>
            </Link>
          </Col>
        </Row>
        </MainBlock>
      </Col>
      
    </>
  );
};

export default ListPage;
