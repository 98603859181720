import React, { useMemo, useState } from 'react';
import SmsChargeHistory from './SmsChargeHistory';
import SmsChargeHistoryHeader from './SmsChargeHistoryHeader';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { CAMPUS_SMS_CHARGED_HISTORY } from 'src/operations/queries/message';
import { userInfoVar } from 'src/apollo/cache';
import { koreanNumberFormat } from 'src/utils';

const SmsChargeHistoryContainer = ({ smsRemainedPoint }) => {
  const [selectedYm, setSelectedYm] = useState(moment());
  const { data, loading } = useQuery(CAMPUS_SMS_CHARGED_HISTORY, {
    variables: { campus_idx: userInfoVar()?.campus_idx, ym: selectedYm.format('YYYY-MM') },
    skip: !userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });

  const chargeSmsData = useMemo(() => {
    const list = data?.campusSmsPayHistoryList;
    if (list) {
      return list.map((item, key) => {
        return {
          Num: key + 1,
          sentDate: moment(item?.idate).format('YYYY-MM-DD'),
          paidNum: koreanNumberFormat(item?.price),
          paidUser: item?.user?.name,
        };
      });
    }
    return [];
  }, [data]);
  function onChange(date) {
    setSelectedYm(date);
  }
  return (
    <>
      <SmsChargeHistoryHeader selectedYm={selectedYm} remainedPoint={smsRemainedPoint} onChange={onChange} />
      <SmsChargeHistory data={chargeSmsData} loading={loading} />
    </>
  );
};

export default SmsChargeHistoryContainer;
