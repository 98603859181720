import React, { useState, useEffect, useMemo } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, Modal, Radio } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
import { TEST_DOCUMENTS } from 'src/operations/queries/testDocument';
import { FOLDERS_LIST } from 'src/operations/queries/getFolders';
import { CREATE_FOLDERS } from 'src/operations/mutations/createFolders';
import { CREATE_TEST } from 'src/operations/mutations/createTests';
import { CREATE_FILES } from 'src/operations/mutations/createFiles';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { getCurrentToken } from 'src/utils';
import { classStoreData } from 'src/operations/store';
import moment from 'moment';

const { Title } = Typography;
const { Search } = Input;

const CONTENT_CATEGORY = {
  me: '개인',
  publisher: '본사',
};

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const MainPage = (props) => {
  const query = new URLSearchParams(props.location.search);
  const searchPage = query.get('page');
  const isRefresh = query.get('refresh');

  const companyName = useSelector(classStoreData);

  const [tests, setTests] = useState([]);
  const [selectType, setSelectType] = useState('me');
  const [modalVisible, setModalVisible] = useState(false);
  const [filename, setFileName] = useState('data.pdf');
  const [q, setQ] = useState(null);
  const [page, setPage] = useState(searchPage ? parseInt(searchPage) : 0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [checkedList, setCheckedList] = useState(new Array(tests.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      type: 'me',
      text: null,
      page: 0,
    },
    'we_search',
  );
  const [folder_idx, setFolderIdx] = useState(null);
  const [file_folder_idx, setFileFolderIdx] = useState(null);
  const [selectedTestDocument, setSelectedTestDocument] = useState(null);
  const [folders, setFolders] = useState([]);
  const [is_default, setIsDefault] = useState(null);
  const [user, setUser] = useState({});

  const token = getCurrentToken();
  const { data: userData } = useQueryProxy(USER_ME, {
    variables: { token },
  });

  useEffect(() => {
    if (userData?.me) {
      setUser(userData.me);
      if (userData.me.type > 3) {
        setSelectType('admin');
        setSearchValue({ ...searchValue, type: 'admin' });
      }
    }
  }, [userData]);

  const { data: folderData } = useQuery(FOLDERS_LIST, { variables: { folder_idx: null, is_default } });
  const [folderRefetch, { data: subFolderData }] = useLazyQuery(FOLDERS_LIST, { variables: { folder_idx, is_default } });

  const [createFile] = useMutation(CREATE_FILES);

  const [createFolder] = useMutation(CREATE_FOLDERS);

  const [createTest, { loading }] = useMutation(CREATE_TEST);

  const { data, refetch } = useQuery(TEST_DOCUMENTS, {
    fetchPolicy: 'no-cache',
    variables: { type: searchValue.type, q: searchValue.text, page: searchValue.page, take },
  });
  console.log('searchValue', searchValue);
  //pageSize: take, total, current: searchValue.page + 1
  useEffect(() => {
    if (data?.testDocumentList) {
      setTests(
        data?.testDocumentList.map((item, key) => {
          return { ...item, item_no: total - take * searchValue.page - key };
        }),
      );
      setTotal(data?.testDocumentList?.length);
      //props.history.replace({ search: `?page=${page}` });
    }

    if (isRefresh) {
      //refetch();
      //props.history.replace({ search: '' });
    }
  }, [data, page, props.history, searchPage, isRefresh]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(tests.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  const orgColumns = [
    {
      title: 'No',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'idate',
      align: 'center',
      key: 'date',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{text.substr(0, 10)}</Space>;
      },
    },
    {
      title: 'Test Number',
      dataIndex: 'code',
      key: 'number',
      width: 300,
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: 'Edit',
      key: '',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              style={{ color: '#1890ff' }}
              onClick={() => {
                props.history.push(`/question/extractor/edit/${record.idx}`);
              }}
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
    {
      title: 'Create',
      key: '',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              style={{ color: '#1890ff' }}
              onClick={() => {
                setSelectedTestDocument(record);
                setModalVisible(true);
              }}
            >
              Creat Test
            </Button>
          </Space>
        );
      },
    },
    {
      title: () => {
        return <Checkbox onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'right',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
  ];

  const handleFolderCreate = async (name, is_public) => {
    try {
      await createFolder({ variables: { folder_idx, name, is_public, is_default: true } });

      folderRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (subFolderData?.foldersList) {
      const singleFolder = subFolderData?.foldersList?.filter((a) => a.name === 'Test Center');
      if (singleFolder.length) {
        setFileFolderIdx(singleFolder[0].idx);
      } else {
        handleFolderCreate('Test Center', true);
      }
    }
  }, [subFolderData]);

  const selectFolder = (val) => {
    setSelectType(val.target.value);
    selectFolderType(folders, val.target.value);
  };

  const selectFolderType = (mFolders, val) => {
    switch (val) {
      case 'me': {
        const singleFolder = mFolders.filter((a) => a.campus && a.user && !a.company);
        if (singleFolder.length) {
          setFolderIdx(singleFolder[0].idx);
          folderRefetch();
        }
        break;
      }
      case 'campus': {
        const singleFolder = mFolders.filter((a) => a.campus && !a.user && !a.company);
        if (singleFolder.length) {
          setFolderIdx(singleFolder[0].idx);
          folderRefetch();
        }
        break;
      }
      case 'publisher': {
        const singleFolder = mFolders.filter((a) => !a.campus && !a.user && a.company);
        if (singleFolder.length) {
          setFolderIdx(singleFolder[0].idx);
          folderRefetch();
        }
        break;
      }
    }
  };

  const handleCreateTest = async () => {
    const {
      data: {
        createFiles: { idx },
      },
    } = await createFile({
      variables: {
        name: selectedTestDocument.title || filename,
        path: selectedTestDocument?.main_pdf || '',
        mime_type: 'application/pdf',
        encoding: '7bit',
        folder_idx: file_folder_idx,
        video_idx: null,
        is_public: true,
      },
    });

    let testData = {
      title: selectedTestDocument?.title || '',
      meta: selectedTestDocument?.meta.split(',') || [],
      code: `T-${moment().format('YYMMDDHHMMSS')}`,
      main_pdf_idx: idx,
      answers: selectedTestDocument.questions
        .filter((q) => !q.is_group)
        .map((item) => ({
          code: item.number,
          answer: item.answer.split('|'),
          option_count: item.option_count || 0,
          condition: item.condition,
          question_idx: item.idx,
        })),
      total_questions: selectedTestDocument.total_questions || 0,
      folder_type: selectType,
      test_document_idx: selectedTestDocument.idx,
      // type: 2,
    };

    let extra_data = JSON.parse(selectedTestDocument.extra_data);
    if (extra_data.batch_type && extra_data.batch_type !== '') {
      testData.batch_type = extra_data.batch_type;
    }
    if (extra_data.test_type && extra_data.test_type !== '') {
      testData.test_type = extra_data.test_type;
    }
    if (extra_data.form_id && extra_data.form_id !== '') {
      testData.form_id = extra_data.form_id;
    }

    await createTest({
      variables: testData,
    });
    props.history.push('/test/manage/list?refresh=true');
  };

  const handleTestDelete = () => {
    const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    const idxs = tests.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
    props.onTestDelete({ idxs });
    setTimeout(() => {
      setCheckedList([]);
      refetch();
    }, 1000);
  };

  function handleTableChange(pagination, filters) {
    console.log('pagination', pagination);
    console.log('filters', filters);
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }
  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testmanagement-mailblock">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>Question Extractor</Title>
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Form.Item label="Folder" className="headitems-select">
                <Select
                  style={{ width: 150, color: '#065aa9' }}
                  defaultValue={searchValue.type}
                  placeholder="Select a folder"
                  optionFilterProp="children"
                  //onChange={(val) => setSelectType(val)}
                  onChange={(val) =>
                    setSearchValue((prev) => {
                      return {
                        ...prev,
                        type: val,
                      };
                    })
                  }
                >
                  {user?.type > 4 && <Select.Option value="admin">Admin</Select.Option>}
                  <Select.Option value="publisher">본사 컨텐츠</Select.Option>
                  <Select.Option value="campus">학원 컨텐츠</Select.Option>
                  <Select.Option value="me">개인 컨텐츠</Select.Option>
                </Select>
              </Form.Item>
              <Search
                className="headitems-search"
                placeholder="Input search text"
                //onSearch={(val) => setQ(val)}
                defaultValue={searchValue.text}
                onSearch={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      text: val,
                    };
                  })
                }
                allowClear
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
              />

              <Link to="/question/extractor/add" className="headitems-add">
                <Button style={{ color: '#289428' }}>Add +</Button>
              </Link>
              <Button className="testmanagmentdelete-btn" danger onClick={handleTestDelete}>
                Delete
              </Button>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            dataSource={tests}
            columns={orgColumns}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: take, total, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            //onChange={(a) => setPage(a.current - 1)}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => onHandleRowClick(record, rowIndex),
            //   };
            // }}
            onChange={handleTableChange}
          />
        </MainBlock>
      </Col>
      <Modal
        title="Choose Folder to save"
        centered
        visible={modalVisible}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleCreateTest}>
            Submit
          </Button>,
        ]}
      >
        <Radio.Group name="radiogroup" defaultValue="me" onChange={selectFolder}>
          <Radio value="me">내 개인 컨텐츠</Radio>
          {[5, 6].includes(parseInt(user.type)) ? <Radio value="admin">Admin</Radio> : null}
          {[3, 2].includes(parseInt(user.type)) ? <Radio value="campus">우리 학원 컨텐츠</Radio> : null}
          {[4, 5].includes(parseInt(user.type)) ? <Radio value="publisher">FC 본사</Radio> : null}
        </Radio.Group>
      </Modal>
    </>
  );
};

export default withRouter(MainPage);
