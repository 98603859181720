import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { CaretLeftFilled, CaretRightFilled, SoundFilled } from '@ant-design/icons';
import Timer from 'src/components/LevelUp/Timer';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: #5b9bd5;
  color: #fff;
  font-size: 25px;
  margin: 0 20px;
`;
const StageTitle = styled.div`
  border-bottom: 1px solid #ed7d31;
  width: 100%;
  padding: 10px 30px;
`;
const StageTitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#0070c0')};
  font-size: 25px;
  font-weight: 600;
  padding: 0 45px;
  @media (max-width: 1280px) {
    font-size: 22px;
    padding: 40px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #2f55a2;
  padding: 20px 30px 15px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const BlueNumber = styled.span`
  color: #4472c5;
`;
const RedNumber = styled.span`
  color: #ff0000;
`;
const OrangeNumber = styled.span`
  color: #ed7d31;
`;
const PurpleNumber = styled.span`
  color: #7030a0;
`;
const GreenNumber = styled.span`
  color: #70ad47;
`;
const BlackNumber = styled.span`
  color: #000000;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  padding: 15px 45px;
  align-items: center;
`;
const Main = styled(ColumnFlex)`
  height: 100%;
  width: 80%;
  border-radius: 13px;
  padding: 5px 30px 15px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 550px;
  min-height: 650px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;

const PassageBox = styled.div`
  color: #000000;
  /* align-items: center; */
  /* justify-content: center; */
  font-weight: 400;
  border: 2px #b0c9e7 solid;
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
  height: 420px;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #fff;
  /* cursor: pointer; */
  @media (max-height: 880px) {
    height: 400px;
  }
`;
const CheckButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #589cdb;
  color: #fff;
  border-radius: 20px;
  height: 38px;
  font-size: 25px;
  padding: 5px 20px;
  margin-left: 15px;
  width: 160px;
  cursor: pointer;
`;
export default function LevelUpSentenceDictation({ space = 30 }) {
  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <StageTitle>
              <StageTitleSpan>BOOK Title</StageTitleSpan>
              <StageTitleSpan>Unit</StageTitleSpan>
              <StageTitleSpan>Lesson</StageTitleSpan>
              <StageTitleSpan color={'#7030a0'}>Topic, title</StageTitleSpan>
            </StageTitle>

            <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <Title>Passage Dictation</Title>

              <RowFlex>
                Page Number:&nbsp;
                <BlueNumber>{`03`}</BlueNumber>
              </RowFlex>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between', width: '100%', height: '70%', alignItems: 'center' }}>
              <ImgButton>
                <SoundFilled />
              </ImgButton>
              <ColumnFlex style={{ width: '92%' }}>
                <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center', padding: '5px 10px',fontSize:'16px',color:'#0070c0' }}>
                  <div>Space: <PurpleNumber>{`30`}%</PurpleNumber></div>
                  <RowFlex style={{ justifyContent: 'space-between', width: '300px', alignItems: 'center' }}>
                    <div>Tries:&nbsp;<GreenNumber>02</GreenNumber><BlackNumber>/{`05`}</BlackNumber></div>
                    <div>Score:&nbsp;<RedNumber>{`80`}</RedNumber></div>
                    <div>Time:&nbsp;<OrangeNumber><Timer/></OrangeNumber></div>
                  </RowFlex>
                </RowFlex>
                <PassageBox>
                    text **** text ****
                </PassageBox>
              </ColumnFlex>
            </RowFlex>
            <CheckButton>Check</CheckButton>
            <RowFlex style={{ justifyContent: 'space-between', fontSize: '50px', height: '50px', width: '100%' }}>
              <CaretLeftFilled style={{ color: '#bfbfbf', cursor: 'pointer' }} />
              <CaretRightFilled style={{ color: '#eaaa3e', cursor: 'pointer' }} />
            </RowFlex>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
