import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Card, Image } from 'antd';
import styled from 'styled-components';
import clip from 'src/sounds/clip-sound.mp3';
import clipGood from 'src/sounds/Good_job.mp3';
import clipTry from 'src/sounds/Try Again.mp3';
import 'react-simple-keyboard/build/css/index.css';
import useSound from 'use-sound';
import ReactPlayer from 'react-player';
import { StarOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  margin-top: 10px;
  background: #fff;
  height: calc(100vh - 94px);
`;

const TotalBox = styled.span`
  color: rgb(255, 122, 27);
  border: 1px solid #6b5c5c;
  padding: 5px 10px;
  border-radius: 5px;
`;

const ScoreBox = styled.span`
  color: #de3333;
  border: 1px solid #de3333ab;
  padding: 5px 10px;
  border-radius: 5px;
`;

const QuizPage = ({ gotoNext, data = [] }) => {
  const [total, setTotal] = useState(data.length);
  const [score, setScore] = useState(0);
  const [done, setDone] = useState(0);
  const [complete, setComplete] = useState(0);
  const [stageType, setStageType] = useState(1);
  const [revStageType, setRevStageType] = useState(1);
  const [ans, setAns] = useState('');
  const [ques, setQues] = useState('');
  const [error, setError] = useState(-1);
  const [errorList, setErrorList] = useState([]);
  const [correct, setCorrect] = useState(-1);
  const [playing, setPlaying] = useState(false);
  const [nowSound, setNowSound] = useState(false);
  const [options, setOptions] = useState([]);
  const [play] = useSound(clip);
  const [playGood] = useSound(clipGood, {
    onend: () => {
      setNowSound(false);
    },
  });
  const [playTry] = useSound(clipTry);

  useEffect(() => {
    if (data && data[done] && !nowSound) {
      let qu = 'kor';
      let an = 'eng';
      if (stageType === 2) {
        qu = 'eng';
        an = 'kor';
      } else if (stageType === 3) {
        qu = 'eng';
        an = 'kor';

        if (revStageType === 1) {
          let qu = 'kor';
          let an = 'eng';
        }
      }
      setQues(data[done][qu]);
      setAns(data[done][an]);
      let restQ = data
        .filter((d, i) => i !== done)
        .map((q) => q[an])
        .sort(() => Math.random() - 0.5)
        .splice(0, 3);
      restQ.push(data[done][an]);
      restQ = restQ.sort(() => Math.random() - 0.5);
      setOptions(restQ);
      setError(-1);
      setCorrect(-1);
      setPlaying(false);
    }
  }, [done, nowSound, data, stageType]);

  const handleAnswerClick = (val, i) => {
    if (error !== -1 || correct !== -1) return;
    if (val === ans) {
      setNowSound(true);
      playGood();
      setError(-1);
      setCorrect(i);
      let newscore = score;
      if (stageType != 3) {
        newscore += Math.round((1 / (total * 2)) * 100);
        setScore(newscore);
      }
      goNext(newscore);
    } else {
      setError(i);
      if (stageType !== 3) {
        errorList.push({ done, stageType });
        setErrorList(errorList);
      }
      // playTry()
      goNext();
    }
  };

  const goNext = (newscore = null) => {
    setTimeout(() => {
      if (done < data.length - 1 && stageType !== 3) {
        setDone(done < data.length ? done + 1 : data.length);
        setComplete(complete + 1);
      } else if (stageType === 1) {
        setStageType(2);
        setDone(0);
        setComplete(complete + 1);
      } else if ((stageType === 2 || stageType === 3) && errorList.length) {
        setStageType(3);
        const curval = errorList.pop();
        setRevStageType(curval.stageType);
        setDone(curval.done);
        setErrorList(errorList);
      } else {
        if (gotoNext) {
          gotoNext({
            exam_total: 100,
            exam_correct: newscore ? newscore : score,
            message: `Your total score is ${newscore ? newscore : score}`,
          });
        }
      }
    }, 1000);
  };

  return (
    <>
      <Col className="quiz-fluid-container" span={24} style={{ textAlign: 'left', margin: 0 }}>
        <MainBlock className="quiz-fluid-scroll">
          <Row gutter={[24, 16]} style={{ padding: 10 }}>
            <Col className="quiz-heading" span={16}>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col className="quiz-heading-containt" span={24} style={{ padding: 10, paddingLeft: 10 }}>
                  <Title level={2} ellipsis>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Choose the right answer.</span>{' '}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col
              className="quiz-heading-listing"
              span={8}
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 10 }}
            >
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                <ScoreBox>Score : {score}</ScoreBox>{' '}
              </Title>
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                <TotalBox>
                  {complete + 1} / {total * 2}
                </TotalBox>
              </Title>
            </Col>
          </Row>
          <Row gutter={[24, 16]} style={{ padding: 10 }}>
            <Col
              className="word-board imagequizspeaker"
              span={10}
              style={{ padding: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'flex-start' }}
            >
              <Image
                height={40}
                width={40}
                style={{ cursor: 'pointer', marginLeft: 10 }}
                src="/images/speak.png"
                preview={false}
                onClick={() => setPlaying(true)}
              />
              {data[done]['eng_audio'] ? (
                <ReactPlayer
                  url={`https://cdn.cloubot.com${data[done]['eng_audio']}`}
                  width="0"
                  height="0"
                  playing={playing}
                  onEnded={() => setPlaying(false)}
                />
              ) : null}
              <Card
                style={{ width: '100%', borderRadius: 20, boxShadow: '10px 10px 5px 5px rgba(95, 77, 99, 0.5)', display: 'felx' }}
                bodyStyle={{ background: '#fff', borderRadius: 20, minHeight: '300px', display: 'flex' }}
                hoverable
                loading={false}
              >
                <p
                  style={{
                    width: '100%',
                    borderRadius: 20,
                    background: '#efefef',
                    fontSize: 40,
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {ques}
                </p>
              </Card>
            </Col>
            <Col
              className="word-board-option"
              span={14}
              style={{
                padding: '0 40px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Title level={4} style={{ width: '100%', background: '#c0daf1', marginLeft: 10, padding: 10, borderRadius: 10 }}>
                <span>
                  {error != -1 ? (
                    <StarOutlined
                      style={{ color: '#de3333', fontSize: '95px', position: 'absolute', marginTop: '-30px', marginLeft: '-30px' }}
                    />
                  ) : null}{' '}
                  {correct != -1 ? (
                    <svg
                      height="95"
                      width="95"
                      style={{ color: '#4dde33', fontSize: '95px', position: 'absolute', marginTop: '-30px', marginLeft: '-30px' }}
                    >
                      <circle cx="50" cy="50" r="40" stroke="#4dde33" strokeWidth="10" fill="transparent" />
                    </svg>
                  ) : null}{' '}
                  {stageType === 3 ? '다시 풀기:' : `Q${complete + 1}. `}
                </span>
                {` ${ques}`}
              </Title>

              {options.map((v, i) => (
                <Title
                  key={i}
                  level={4}
                  style={{
                    width: 'calc(100% - 20px)',
                    border: `3px solid #${error === i ? 'de3333' : correct === i ? '4dde33' : 'c0daf1'}`,
                    marginLeft: 30,
                    padding: 10,
                    borderRadius: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleAnswerClick(v, i)}
                >{`${i + 1}. ${v}`}</Title>
              ))}
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default QuizPage;
