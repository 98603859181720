import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import styled from 'styled-components';
import { getHumanReadablePhoneNumber } from 'src/utils/';
import { UPDATE_BOOK_RETURN_STATUS, DELETE_BOOK_RETURN } from 'src/operations/mutations/bookReturn';

const InfoLabel = styled.label`
  ::after {
    content: ': ';
  }
`;

const BookReturnAdminModal = ({ visible, bookData, comment, stat, user_info, idx, onCancel, onRefetch }) => {
  const dataSource = useMemo(() => {
    if (bookData) {
      return bookData.map((item, key) => {
        return {
          key: `book-list${key}`,
          no: key + 1,
          name: item?.product_name || item?.product_code,
          count: item?.quantity,
          idate: moment(item?.idate).format('YYYY-MM-DD'),
        };
      });
    }
    return [];
  }, [bookData]);

  const [updateStatus, { loadingUpdate }] = useMutation(UPDATE_BOOK_RETURN_STATUS, {
    onCompleted: ({ updateBookReturnStatus: res }) => {
      if (res.success) {
        Modal.info({
          title: '완료처리 되었습니다.',
          onOk: () => {
            onRefetch();
            onCancel();
          },
        });
      } else {
        alert(res.message);
      }
    },
    onError: (e) => {
      console.log(e);
      alert('오류가 발생했습니다.');
    },
  });

  const [deleteReturn, { loading: loadingDelete }] = useMutation(DELETE_BOOK_RETURN, {
    onCompleted: ({ deleteBookReturn: res }) => {
      if (res.success) {
        Modal.info({
          title: '삭제 되었습니다.',
          onOk: () => {
            onRefetch();
            onCancel();
          },
        });
      } else {
        alert(res.message);
      }
    },
    onError: (e) => {
      console.log();
      alert('삭제중 오류가 발생했습니다.');
    },
  });

  return (
    <Modal
      visible={visible}
      title={`반품 교재 리스트`}
      onCancel={onCancel}
      width={'60%'}
      footer={[
        stat === 0 && (
          <Button
            type="primary"
            loading={loadingUpdate}
            onClick={() => {
              updateStatus({ variables: { idx, stat: '1' } });
            }}
          >
            반품확인
          </Button>
        ),
        stat === 0 && (
          <Button
            type="danger"
            loading={loadingDelete}
            onClick={() => {
              Modal.confirm({
                title: '정말 삭제하시겠습니까?',
                onOk: () => {
                  deleteReturn({ variables: { idx } });
                },
                onCancel: () => {
                  //do nothing
                },
              });
            }}
          >
            삭제
          </Button>
        ),
        <Button key="back" onClick={() => onCancel()}>
          닫기
        </Button>,
      ]}
    >
      <CustomTable
        pagination={{ pageSize: 12, position: ['bottomCenter'] }}
        dataSource={dataSource}
        columns={columns}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
      />
      <p>
        <ul>
          <li>
            <InfoLabel>이름</InfoLabel>
            {user_info?.name}
          </li>
          <li>
            <InfoLabel>전화번호</InfoLabel>
            {getHumanReadablePhoneNumber(user_info?.phone)}
          </li>
          <li>
            <InfoLabel>이메일</InfoLabel>
            {user_info?.email}
          </li>
          <li>
            <InfoLabel>comment</InfoLabel>
            {comment}
          </li>
        </ul>
      </p>
    </Modal>
  );
};

export default BookReturnAdminModal;

const columns = [
  {
    title: '번호',
    dataIndex: 'no',
    key: 'no',
    width: '60px',
  },
  {
    title: '교재',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '권 수',
    dataIndex: 'count',
    key: 'count',
  },
  {
    title: '접수날짜',
    dataIndex: 'idate',
    key: 'idate',
  },
];
