import React, { useEffect } from 'react';
import _ from 'lodash';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
const isExistSameWord = (checkWord, wordArray) => {
  if (wordArray.indexOf(checkWord) !== -1) return true;
  return false;
};
const CrossWordsImageBox = ({ imageBaseUrl, words, answerWordArr, onFinish }) => {
  useEffect(() => {
    if (!_.isNil(words) && words.length > 0 && !_.isNil(answerWordArr) && answerWordArr.length > 0) {
      const isFinish = words.every((item) => {
        return isExistSameWord(item, answerWordArr);
      });
      if (isFinish && onFinish instanceof Function) {
        console.log('fin');
        onFinish();
      }
    }
    // eslint-disable-next-line
  }, [words, answerWordArr]);
  return (
    // <ul className="images_box">
    // 	{words &&
    // 		words.map((item, key) => (
    // 			<li key={`word-image-${key}`} className={isExistSameWord(item, answerWordArr) ? "active" : ""}>
    // 				<img style={{width: "50%"}} src={`${imageBaseUrl}image_${item}.png`} alt={item} />
    // 			</li>
    // 		))}
    // </ul>
    <ImgBox className="img_box">
      {words &&
        words.map((item, key) => (
          <ImgLi
            active={isExistSameWord(item, answerWordArr)}
            key={`word-image-${key}`}
            className={isExistSameWord(item, answerWordArr) ? 'active' : ''}
          >
            <img style={{ height: '10vh' }} src={`${imageBaseUrl}image_${item}.png`} alt={item} />
          </ImgLi>
        ))}
    </ImgBox>
  );
};

export default CrossWordsImageBox;
const ImgBox = styled.ul`
  width: 47%;
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  grid-gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
`;
const ImgLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 1rem;
  border: 3px solid #e5e5e5;
  ${(props) =>
    props.active &&
    `
  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(/images/pm/line_diagonal.svg) no-repeat 50%;
  }`}
`;
