import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Col, Typography, Space, Row, Button, Select, Tooltip, DatePicker } from 'antd';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { AI_TOPIC_USER_ASSIGNMENT_LIST } from 'src/operations/queries/aiTopic';
import { UPDATE_AI_TOPIC_USER_ASSIGNMENT } from 'src/operations/mutations/aiTopic';
import { useQuery, useMutation } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import AITalkingReportAnswerModal from 'src/components/Modal/AITalkingReportAnswerModal';
import { MESSAGE_LIST } from 'src/operations/mutations/aiGroupTopic';
import dayjs from 'dayjs';
import BackButton from 'src/components/common/BackButton';
import queryString from 'query-string';
const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const CampusResults = (props) => {
  const { idx } = useParams();
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');
  const history = useHistory();
  const [assignedAITopics, setAssignedAITopics] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [take, setTake] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [topic, setTopic] = useState();
  const [currentChatList, setCurrentChatList] = useState([]);
  const [userTopicId, setUserTopicId] = useState();
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [threadIdx, setThreadIdx] = useState();
  const [curUser, setCurUser] = useState();
  const [scoreClick, setScoreClick] = useState(false);
  const [lastMsgId, setLastMsgId] = useState(null);
  const [lastMsg, setLastMsg] = useState(null);
  const [aiTopic, setAiTopic] = useState();
  const { date } = queryString.parse(window.location.search.replace('?', ''));

  const [localState, setLocalState] = UseHistoryState(
    {
      page: 0,
      start_date: dayjs(date ? date : Date())
        .startOf('month')
        .format('YYYY-MM-DD'),
      end_date: dayjs(date ? date : Date())
        .endOf('month')
        .format('YYYY-MM-DD'),
    },
    'ai_topic_result',
  );
  const parseMessage = (content) => {
    let value = '';
    let valueObj = {};
    if (content.length > 0) {
      for (let i = 0; i < content.length; i++) {
        if (content[i]?.text?.value.replace(/(\r\n|\n|\r)/gm, '').startsWith('{')) {
          let temp = JSON.parse(content[i]?.text?.value);
          for (let key in temp) {
            if (key.endsWith('image_url') || key.endsWith('audio_url')) {
              valueObj[key] = temp[key];
            } else if (key in valueObj) {
              valueObj[key] = valueObj[key] === temp[key] ? valueObj[key] : valueObj[key] + temp[key];
            } else {
              valueObj[key] = temp[key];
            }
          }
        } else {
          value += content[i]?.text?.value;
        }
      }
    }

    return value === '' ? valueObj : value;
  };

  const mapContentData = (contentText, data) => {
    if (typeof contentText === 'string') {
      return [{ ...data, content: contentText }];
    }
    if (typeof contentText === 'object') {
      let result = [];
      let keys = Object.keys(contentText);
      let text = '';
      for (let i = 0; i < keys.length; i++) {
        if (typeof contentText[keys[i]] === 'object') {
          continue;
        }
        if (keys[i].endsWith('image_url') || keys[i].endsWith('audio_url')) {
          result.push({
            ...data,
            content: text,
            urls: keys[i].endsWith('image_url')
              ? [{ type: 'image', url: contentText[keys[i]] }]
              : [{ type: 'audio', url: contentText[keys[i]] }],
          });
          text = '';
          continue;
        }
        text += contentText[keys[i]];
      }
      if (text !== '') {
        result.push({ ...data, content: text });
      }
      return result;
    }
  };

  const [updateAITopicUserAssignment, { data: updatedData, loading: loadingUpdate }] = useMutation(UPDATE_AI_TOPIC_USER_ASSIGNMENT, {
    onError(error) {
      console.log('error', error);
    },
  });

  const { data, loading, refetch } = useQuery(AI_TOPIC_USER_ASSIGNMENT_LIST, {
    // variables: { class_idx, page, take },
    variables: {
      type: 'ai-talking-tutor',
      start_date: localState.start_date ? localState.start_date : undefined,
      end_date: localState.end_date ? localState.end_date : undefined,
      student_idx: idx ? parseInt(idx) : undefined,
    },
    fetchPolicy: 'no-cache',
  });
  const [messageList, { data: msgList, loading: msgListLoading }] = useMutation(MESSAGE_LIST);

  useEffect(() => {
    if (data?.aiTopicUserAssignmentList) {
      const tmp = data?.aiTopicUserAssignmentList.ai_topic_user_assignments.sort(
        (a, b) => new Date(b.ai_topic_date_assignment.start_date) - new Date(a.ai_topic_date_assignment.start_date),
      );
      setAssignedAITopics(
        tmp.map((item, index) => {
          let ai_topic_date_assignmentData = JSON.parse(item?.ai_topic_date_assignment?.data || '{}');
          return {
            ...item,
            ai_topic: {
              ...item.ai_topic,
              data: JSON.parse(item.ai_topic.data || '{}'),
              topic: ai_topic_date_assignmentData?.revisedPrompt || item.ai_topic.topic,
            },
            no: tmp.length - index,
            start_date: data?.aiTopicDateAssignmentDetails?.start_date,
            rubric_data: JSON.parse(item.rubric_data),
            ai_request_responses: item.ai_request_responses.map((item) => {
              return {
                ...item,
                promt: item.prompt_text,
                content: item.completion_text,
                data: JSON.parse(item.data),
                created: item.idate,
              };
            }),
          };
        }),
      );

      setTotal(data?.aiTopicUserAssignmentList.total);
    }

    if (isRefresh) {
      refetch();
      props.history.replace({ search: '' });
    }
  }, [data, isRefresh]);

  useEffect(() => {
    if (lastMsg !== null && scoreClick) {
      if (lastMsg !== null) {
        const dateVal = new Date(lastMsg?.created_at * 1000);
        const summryMsg = {
          user: lastMsg?.role === 'user' ? curUser : 'AI봇',
          chat_user_name: lastMsg?.role === 'user' ? curUser : 'AI봇',
          content: lastMsg?.content[0]?.text?.value,
          urls: [],
          created: dateVal.toISOString(),
        };
        setCurrentChatList([summryMsg]);
      }
    }
  }, [lastMsg, scoreClick]);

  const fetchMessages = async () => {
    messageList({
      variables: {
        thread_id: threadIdx,
        last_msg_id: lastMsgId,
        order: 'desc',
      },
    }).then((res) => {
      if (res?.data?.messageList?.has_more == true) {
        setHasMore(true);
        setLastMsgId(res?.data?.messageList?.last_id);
        if (lastMsgId == null) {
          setLastMsg(res?.data?.messageList?.data[0]);
        }
      } else {
        // setLastMsg(res?.data?.messageList?.data[res?.data?.messageList?.data?.length - 1])
        setLastMsgId(null);
      }
      if (res?.data?.messageList?.data?.length > 0) {
        // const chatList = [];
        const promptMetadataArray = res?.data?.messageList?.data?.filter((item) => item.metadata && item.metadata.type !== 'prompt');
        const rearrangedList = promptMetadataArray?.reverse();
        const chatList =
          rearrangedList?.length > 0
            ? rearrangedList?.reduce((acc, ele) => {
                const filtered_data = parseMessage(ele?.content);
                const newData = mapContentData(filtered_data, {
                  user: ele?.role === 'user' ? curUser : 'AI봇',
                  chat_user_id: ele?.role === 'user' ? curUser : 'AI봇',
                  chat_user_name: ele?.role === 'user' ? curUser : 'AI봇',
                  created: new Date(ele.created_at * 1000).toISOString(),
                });
                return [...acc, ...newData];
              }, [])
            : [];

        if (scoreClick) {
          const lastValue = chatList[chatList.length - 1];
          setCurrentChatList([lastValue]);
          setHasMore(false);
        } else {
          if (lastMsg == null) {
            let finalChatList = chatList?.slice(0, -2);
            setCurrentChatList((prevChatList) => [...finalChatList, ...prevChatList]);
          } else {
            setCurrentChatList((prevChatList) => [...chatList, ...prevChatList]);
          }
          // if (!res?.data?.messageList?.has_more) {
          //   let finalChatList = chatList?.slice(0, -2);
          //   setCurrentChatList(prevChatList => [...prevChatList, ...finalChatList]);
          // } else {
          //   setCurrentChatList(prevChatList => [...prevChatList, ...chatList]);
          // }
        }
      }
    });
  };

  useEffect(() => {
    if (hasMore && lastMsgId !== null && scoreClick && lastMsg == null) {
      fetchMessages();
    }
  }, [hasMore, lastMsgId, scoreClick, lastMsg]);

  useEffect(() => {
    if (viewAnswerModal && threadIdx) {
      fetchMessages();
    }
  }, [viewAnswerModal, threadIdx]);
  const onChangeDate = (a, date) => {
    let clearCheck = a === null;
    if (clearCheck) {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: undefined,
          end_date: undefined,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          start_date: dayjs(date).startOf('month').format('YYYY-MM-DD'),
          end_date: dayjs(date).endOf('month').format('YYYY-MM-DD'),
        };
      });
    }
  };
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
    },
    {
      title: 'Assigned Date',
      dataIndex: 'ai_topic_date_assignment',
      align: 'center',
      key: 'date',
      width: 150,
      sorter: (a, b) =>
        a.ai_topic_date_assignment.start_date && a.ai_topic_date_assignment.start_date.localeCompare(b.ai_topic_date_assignment.start_date),
      render: (text, record) => {
        return (
          <Space size="middle">
            <span style={{ fontWeight: 'bold' }}>{record.ai_topic_date_assignment.start_date.substr(0, 10)}</span>
          </Space>
        );
      },
    },
    {
      title: 'Completed Date',
      dataIndex: 'ai_topic_date_assignment',
      align: 'center',
      key: 'date',
      width: 150,
      // sorter: (a, b) =>
      //   a.ai_topic_date_assignment.start_date && a.ai_topic_date_assignment.start_date.localeCompare(b.ai_topic_date_assignment.start_date),
      render: (text, record) => {
        if (record?.end_time) {
          let time = record?.end_time.split('T');
          return (
            <Space size="middle">
              <span>
                {time[0]} {time[1].split('.')[0]}
              </span>
            </Space>
          );
        } else {
          return <Space size="middle">-</Space>;
        }
      },
    },
    {
      title: 'Class',
      key: '',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record?.class?.name}</Space>;
      },
    },
    {
      title: 'Assignment / Title',
      key: '',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.ai_topic.data?.book?.title}</Space>;
      },
    },
    {
      title: 'Duration Time',
      dataIndex: 'duration_time',
      key: 'duration_time',
      align: 'center',
      render: (text, record) => {
        const start_time = new Date(record?.start_time?.replace('Z', '+00:00'));
        const end_time = new Date(record?.end_time?.replace('Z', '+00:00'));
        const time_difference_ms = end_time - start_time;
        const hours = Math.floor(time_difference_ms / (1000 * 60 * 60));
        const minutes = Math.floor((time_difference_ms % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time_difference_ms % (1000 * 60)) / 1000);
        if (record?.status == 1) {
          return (
            <Space size="middle">
              {hours}:{minutes}:{seconds}
            </Space>
          );
        } else {
          return <Space size="middle">-</Space>;
        }
      },
    },
    {
      title: 'Dialogue',
      key: '',
      width: 100,
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setAiTopic(record.ai_topic);
                  setCurUser(record?.user?.id);
                  // setCurrentChatList(resChats);
                  setScoreClick(false);
                  setThreadIdx(record?.thread_id);
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
    {
      title: 'Feedback',
      key: '',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status === 0) {
          return <Space size="middle">-</Space>;
        } else {
          return (
            <Space size="middle">
              <Button
                onClick={() => {
                  setTopic(record.ai_topic.topic);
                  setAiTopic(record.ai_topic);
                  setCurUser(record?.user?.id);
                  setScoreClick(true);
                  setThreadIdx(record?.thread_id);
                  setViewAnswerModal(true);
                }}
              >
                보기
              </Button>
            </Space>
          );
        }
      },
    },
  ];

  function handleTableChange(pagination, filters) {
    setLocalState((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  const handleMoreMsg = () => {
    if (hasMore && lastMsgId !== null) {
      fetchMessages();
    }
  };

  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>
                AI Speaking Coach : <span style={{ color: '#00B0F0' }}>Total Homework Results</span>
              </Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              {idx && (
                <Col span={12} style={{ textAlign: 'right' }}>
                  <BackButton />
                </Col>
              )}
              <DatePicker
                style={{ padding: '0 10px', height: '32px', marginRight: 5 }}
                placeholder="Select Month"
                format={'YYYY-MM'}
                picker="month"
                onChange={onChangeDate}
                value={localState.start_date && dayjs(localState.start_date)}
              />
              {/* </div> */}
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={assignedAITopics}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            pagination={{ pageSize: take, current: localState.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
          />
        </MainBlock>
        {viewAnswerModal && (
          <AITalkingReportAnswerModal
            visible={viewAnswerModal}
            chatList={currentChatList}
            hasMore={hasMore}
            handleCancel={() => {
              setViewAnswerModal(false);
              setCurrentChatList([]);
              setLastMsg(null);
              setLastMsgId(null);
            }}
            topic={topic}
            aiTopic={aiTopic}
            loadMoreMsg={handleMoreMsg}
            lastMsgId={lastMsgId}
            showButton={scoreClick}
            msgListLoading={msgListLoading}
          />
        )}
        <RecordingDataModal
          data={{ ...recordingData, currentUserName, className: '' }}
          isShow={isRecordingVisible}
          onPrint={null}
          componentRef={null}
          onOk={handleOkForRecordingData}
          hidePrint={true}
        />
      </Col>
    </>
  );
};

export default withRouter(CampusResults);
