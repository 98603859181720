import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const Field = styled(Col)`
  padding-bottom: 15px;
`;

function BookSettingsDetail({ bookInfo }) {
  return (
    <>
      <Row style={{ padding: '20px 30px', background: '#F5F5F5' }}>
        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>제목 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].title}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>짧은 제목 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].short_title}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>코드 :</Text>
            </Col>
            <Col>{bookInfo?.code}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>가격 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].price}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>표지 이미지 :</Text>
            </Col>
            <Col>
              <img
                src={bookInfo?.book_sub[0]?.cover_path ? bookInfo?.book_sub[0].cover_path : `/images/menu-icon/content.png`}
                alt={bookInfo?.book_sub[0].title}
              />
            </Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>레벨명 :</Text>
            </Col>
            <Col>{bookInfo?.level_name}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>볼륨명 :</Text>
            </Col>
            <Col>{bookInfo?.volume_name}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>Evine 레벨 :</Text>
            </Col>
            <Col>{bookInfo?.evine_level}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>Evine 분류하기 :</Text>
            </Col>
            <Col>{bookInfo?.sort_evine}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>소개 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].introduction}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>저자 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].author}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>출판사 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].publisher}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>장르 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].genre}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>페이지 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].pages}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>렉사일 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].lexile}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>렉사일 분류하기 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].sort_lexile}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>GLR :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].glr}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>GLR 분류하기 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].sort_glr}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>이북 경로 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].ebook_path}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>출판사 코드 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].cooidx}</Col>
          </Row>
        </Field>
        
        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>책번호 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].bidx}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>플래그 추천 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].recommend_flag}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>순서 정렬 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].order_no}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>쉬움 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].easy}</Col>
          </Row>
        </Field>

        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>레벨 2 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].level2}</Col>
          </Row>
        </Field>
        
        <Field span={24}>
          <Row>
            <Col span={10}>
              <Text strong>추가 데이터 :</Text>
            </Col>
            <Col>{bookInfo?.book_sub[0].additional_data}</Col>
          </Row>
        </Field>
    
      </Row>
      
    </>
  );
}

BookSettingsDetail.prototype = {
  bookInfo: PropTypes.objectOf.isRequired,
};

export default BookSettingsDetail;
