import React, { useMemo, useEffect, useState } from 'react';
import { Button, Modal, Form, DatePicker, Select } from 'antd';
import FormField from 'src/components/common/FormField';
import { useQuery } from '@apollo/client';
import * as queries from 'src/operations/queries';
import { DAY_WISE_SCHEDULE_DATA, GET_SCHEDULE_OR_NOT } from 'src/operations/queries/getScheduleData';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import moment from 'moment';
const { Option } = Select;

const classOptions = {
  creo: ['초등 1학년', '초등 2학년', '초등 3학년', '초등 4학년', '초등 5학년', '초등 6학년'],
  default: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
};

const AgainMetaExamForm = ({ form,onCreate, campus_idx, orderIdx, againData }) => {
  const defaultClass = useSelector(classStoreData);
  const { data: fetchOrder } = useQuery(queries.order.GET_ORDER_ITEM2, {
    skip: !orderIdx,
    variables: { idx: parseInt(orderIdx) },
  });
  const [displaySchedule, setDisplaySchedule] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(-1);
  const [schoolinfo, setschoolinfo] = useState();
  const [dayname, setDayname] = useState('');
  const classOptionList = classOptions[defaultClass] || classOptions.default;
  const { data: campusSchedule } = useQuery(GET_SCHEDULE_OR_NOT, {
    variables: {
      campus_idx: campus_idx,
    },
    skip: !campus_idx,
  });
  useEffect(() => {
    if (campusSchedule?.getScheduleornot === true || campusSchedule?.getScheduleornot === 'true') {
      setDisplaySchedule(true);
    } else {
      setDisplaySchedule(false);
    }
  }, [campusSchedule]);
  const { data: daywiseslot, refetch: daywiseslotRefetch } = useQuery(DAY_WISE_SCHEDULE_DATA, {
    variables: {
      campus_idx: campus_idx,
      // campus_idx: 487,
      // day_name: dayname
      date: dayname,
    },
    skip: !dayname,
  });
  console.log(againData, 'againData');
  const slots = daywiseslot?.getDayWiseMetaSchedule || [];
  // const orderInfo = useMemo(() => {
  //   if (fetchOrder) {
  //     const order = { ...fetchOrder.orderItemByIdx };
  //     return {
  //       idx: order.idx,
  //       name: order.name,
  //       parent_phone: order.parent_phone,
  //       school_grade: order.user.school_grade,
  //       school_name: order.campus.name,
  //       school_info: order.school_info,
  //       idate: order.idate,
  //     };
  //   }
  //   return {};
  // }, [fetchOrder]);
  const orderSurveyAnswerList = useMemo(() => {
    if (fetchOrder) {
      if (fetchOrder.orderItemByIdx?.order_survey_answer) {
        const orderSurveyAnswers = fetchOrder.orderItemByIdx?.order_survey_answer.reduce((acc, cur) => {
          const { answer, memo } = cur;
          if (acc[cur.no]) {
            acc[cur.no].push({ answer, memo });
          } else {
            acc[cur.no] = [{ answer, memo }];
          }

          return { ...acc };
        }, {});
        return orderSurveyAnswers;
      }
    }
    return {};
  }, [fetchOrder]);


  function handleDateChange(_, changeDate) {
    setDayname(changeDate);
  }
  

   
  const handleSubmit = (values) => {
    const variables = {};
    const keys = Object.keys(values);

    if (campus_idx && againData) {
      variables['campus_idx'] = campus_idx;
      variables['birth_date'] = againData.dob.slice(0, 10);
      variables['korean_name'] = againData.english_name;
      variables['student_name'] = againData.name;
      variables['student_name1'] = againData.name.split(' ')[0];
      variables['student_name2'] = againData.name.split(' ')[1];
      variables['phone'] = againData.phone_number;
      for (let i = 1; i < 7; i++) {
        variables[`survey${i}`] = orderSurveyAnswerList[i][0]?.answer;
      }
    }
    for (const key of keys) {
        if (key === 'reserve_date') {
          variables[key] = moment(values[key]).format('YYYY-MM-DD');
        } else if (key === 'campus_name') {
          //pass
        } else {
          variables[key] = values[key];
        }
      }
    console.log(slots);
    console.log(scheduleTime);
    setDayname('')
    if (scheduleTime !== -1) {
      variables['schedule_idx'] = slots[scheduleTime].idx;
      variables['days'] = slots[scheduleTime].days;
    }
    onCreate({variables})
    // console.log(variables, 'variables!');
    return false;
  }
  return (
    <Form name="control-ref" layout="inline" form={form} onFinish={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
    <FormField hasFeedback={true} title="학교/학년">
      <Form.Item name="school_info">
        <Select className="plainInput gradeInput" placeholder="Select " value={schoolinfo} onChange={(v) => setschoolinfo(v)}>
          {classOptionList.map((item, k) => {
            return (
              <Option key={k} value={item}>
                {item}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </FormField>
    <FormField hasFeedback={true} title="상담희망일">
      <Form.Item
        name="reserve_date"
        rules={[
          {
            required: true,
            message: '예약날짜를 선택해 주세요.',
          },
        ]}
      >
        <DatePicker
          size="large"
          name="reserve_date"
          onChange={(date, dateString) => {
            handleDateChange(date, dateString);
          }}
          inputReadOnly
        />
      </Form.Item>
    </FormField>
    {displaySchedule ? (
      <FormField hasFeedback={true} title="테스트 신청 예약 날짜">
        <Form.Item className="time-dropdown">
          <Select
            className="plainInput gradeInput"
            placeholder="사용 가능한 일정 선택"
            value={dayname &&scheduleTime !== -1 ? scheduleTime : '사용 가능한 일정 선택'}
            onChange={(v) => setScheduleTime(v)}
          >
            {slots.map((item, k) => {
              return (
                <Option key={k} value={k}>
                  {item.start_time} to {item.end_time}
                </Option>
              );
            })}
          </Select>
          {daywiseslot?.getDayWiseMetaSchedule ? (
            slots.length == 0 ? (
              <label style={{ color: 'red' }}>There is no slot available please choose different date</label>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </Form.Item>
      </FormField>
    ) : (
      ''
    )}
  </Form>
  );
};

export default AgainMetaExamForm;
