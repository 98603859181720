import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
import { HalfWrapper, HeaderTitle } from 'src/components/common/Styles';
import { Col, Row, Select } from 'antd';
import * as queries from 'src/operations/queries';
import { getYearMonthArray } from 'src/utils';

const { Option } = Select;

const months = getYearMonthArray('2023-01');

function OpenAiUsage() {
  const [dateStr, setDateStr] = useState(months[0]);
  const [openAiUsageList, setOpenAiUsageList] = useState([]);

  const [getCompanyOpenAiUsageList, { data: fetchOpenAiUsageData, loading: fetchOpenAiUsageDataLoading }] = useLazyQuery(
    queries.company.GET_COMPANY_OPEN_AI_USAGE_LIST,
  );

  useEffect(() => {
    getCompanyOpenAiUsageList({
      variables: {
        dateStr: dateStr,
      },
    });
  }, [dateStr]);

  useEffect(() => {
    if (fetchOpenAiUsageData?.getCompanyOpenAiUsageList) {
      setOpenAiUsageList(
        fetchOpenAiUsageData.getCompanyOpenAiUsageList.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            no: index + 1,
            name: cur.name,
            prompt_token_count: cur.prompt_token_count,
            completion_token_count: cur.completion_token_count,
            total_token_count: cur.total_token_count,
            image_count: cur.image_count,
            total_cost: cur.total_cost,
          };

          return [...acc, obj];
        }, []),
      );
    }
  }, [fetchOpenAiUsageData]);

  return (
    <>
      <HeaderTitle level={4}>AI 사용 기록 열기</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper>
            <Row justify="space-between" gutter={[16, 16]}>
              <Col span={24} style={{ paddingBottom: '2px' }}>
                <Select
                  size={`default`}
                  value={dateStr}
                  placeholder="월 선택하기"
                  onChange={(val) => {
                    setDateStr(val);
                  }}
                >
                  {months &&
                    months.map((month, key) => (
                      <Option key={`search-month-option${key}`} value={month}>
                        {month}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
            <CustomTable
              dataSource={openAiUsageList}
              pagination={{ pageSize: 5 }}
              columns={OpenAiUsageTable}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              color="#edf3fb"
              loading={fetchOpenAiUsageDataLoading}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
}

const OpenAiUsageTable = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: '5%',
  },
  {
    title: '본사명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Token Count 요청',
    dataIndex: 'prompt_token_count',
    key: 'prompt_token_count',
    align: 'center',
  },
  {
    title: 'Token Count 응답',
    dataIndex: 'completion_token_count',
    key: 'completion_token_count',
    align: 'center',
  },
  {
    title: '전체 Token Count',
    dataIndex: 'total_token_count',
    key: 'total_token_count',
    align: 'center',
  },
  {
    title: '이미지 Count',
    dataIndex: 'image_count',
    key: 'image_count',
    align: 'center',
  },
  {
    title: '총비용',
    dataIndex: 'total_cost',
    key: 'total_cost',
    align: 'center',
  },
];

export default OpenAiUsage;
